import { useState, useCallback, useEffect } from 'react';
import {
  useNavigate,
  useParams,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Loader, TabGroup } from '../../../../components';
import Teams from './tabGroupPages/teams';
import Projects from './tabGroupPages/projects';
import { Members } from './tabGroupPages/Members/index';
import Icon from '../../../../utlils/Icons/Icon';

import { DEPARTMENT_TAB_LIST } from '../../constants';
import './index.scss';
import { PublishedProblems } from '../components';

const ViewDepartment = () => {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [department, setDepartment] = useState({});
  const [activeTab, setActiveTab] = useState(DEPARTMENT_TAB_LIST[0]);

  const tabSwitchHandler = (value) => {
    setActiveTab(value);
    let tab = value.toLowerCase().replace(' ', '-');
    navigate(`/enterprise/departments/view/${departmentId}/${tab}`, {
      replace: true,
    });
  };

  let { departmentId } = useParams();
  const location = useLocation();

  const getDepartment = useCallback(async () => {
    setIsLoading(true);
    const session = JSON.parse(localStorage.getItem('session'));
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/getDepartmentDetails/${departmentId}`,
          {
            headers: {
              'x-access-token': `${session.access_token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.statusCode === 200) {
            setDepartment(res.data.data);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
          setIsLoading(false);
        });
    } catch (error) {
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      setIsLoading(false);
    }
  }, [departmentId]);

  useEffect(() => {
    getDepartment();
  }, [getDepartment]);

  useEffect(() => {
    let pathItems = location.pathname.split('/');
    DEPARTMENT_TAB_LIST.forEach((tab) => {
      if (
        tab.toLowerCase().replace(' ', '-') === pathItems[pathItems.length - 1]
      ) {
        setActiveTab(tab);
      }
    });
  });

  return (
    <>
      {isLoading ? (
        <Loader loading={isLoading} />
      ) : (
        <div className="view-department-wrapper">
          <div>
            <span
              className="back-btn"
              onClick={() =>
                navigate('/enterprise/departments/', { replace: true })
              }>
              <Icon
                color="rgba(146, 157, 175, 1)"
                name="arrow-back"
                size={18}
                leftIcon
              />
              <span className="back-btn__text">Back to Departments</span>
            </span>
          </div>
          <div className="header-txt">
            {department && department.departmentName}
          </div>
          <TabGroup
            tabLists={DEPARTMENT_TAB_LIST}
            tabClick={tabSwitchHandler}
            activeTab={activeTab}
          />
          <Routes>
            <Route path="">
              <Route
                path="published-problems"
                element={
                  <PublishedProblems enterpriseId={department?.enterpriseId} />
                }
              />
              <Route path="teams" element={<Teams />} />
              <Route path="projects/*" element={<Projects />} />
              <Route path="members" element={<Members />} />
              <Route path="" element={<PublishedProblems />} />
            </Route>
          </Routes>
        </div>
      )}
    </>
  );
};

export default ViewDepartment;
