import React from 'react';
import { HeroContentWrapper } from './style';
import Icon from 'utlils/Icons/Icon';
import { HomeNavbar } from '../HomeNavbar';
import { HOME_PAGE_DATA } from 'pages/HomePageV2/constant';
import { useNavigate } from 'react-router-dom';

export const HeroContainer = (props) => {
  const { content } = props;

  const navigate = useNavigate();

  return (
    <HeroContentWrapper>
      <div className="container">
        <div className="row">
          <HomeNavbar options={HOME_PAGE_DATA.navData} />

          <div className="hero-container">
            <div className="main-content">
              <div className="content">
                <div className="content_header">
                  <div className="line" />
                  <div className="sub-content">{content.subHeading}</div>
                  <div className="line" />
                </div>
              </div>
              <div className="content_header">
                <div className="content_header_heading">
                  Create a{' '}
                  <span style={{ color: '#FFFFFF' }}>source of truce</span> for
                  your <span style={{ color: '#FFFFFF' }}>Ideas</span>,{' '}
                  <span style={{ color: '#FFFFFF' }}>Teams</span>, and{' '}
                  <span style={{ color: '#FFFFFF' }}>Projects</span>{' '}
                  <span style={{ color: '#FFFFFF' }}>with us</span>
                </div>
              </div>
              <div
                className="content_button"
                onClick={() => navigate(content.primaryButton.action)}>
                <div className="content_button_title">
                  {content.primaryButton.title}
                </div>
                <div className="content_button_icon">
                  <Icon name={content.primaryButton.icon} color="#219fff" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </HeroContentWrapper>
  );
};
