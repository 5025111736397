import cx from 'classname';
import dayjs from 'dayjs';
import { string } from 'prop-types';
import styled from 'styled-components';

var duration = require('dayjs/plugin/duration');
dayjs.extend(duration);

export const DueDate = (props) => {
  let { className, color = '#7e8f9a', label, dueDate, format } = props;
  dueDate = dayjs(dueDate);
  const duration = dueDate.diff(dayjs(), 'milliseconds');
  const lessThanTwoDays = duration <= 172800000;
  return (
    <Wrp
      className={cx('dd-wrp', className)}
      color={color}
      lessThanTwoDays={lessThanTwoDays}>
      {label && <span className="dd-wrp__label">{label}: </span>}
      <span className="dd-wrp__value">
        {dayjs(dueDate).format(format ? format : 'DD MMM YYYY')}
      </span>
    </Wrp>
  );
};

const Wrp = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  & .dd-wrp__ {
    &label {
      color: #7e8f9a;
    }
    &value {
      color: ${({ lessThanTwoDays, color }) =>
        lessThanTwoDays ? '#FB7574' : color};
    }
  }
`;

DueDate.propTypes = {
  className: string,
  color: string,
  label: string,
  dueDate: string,
  format: string,
};
