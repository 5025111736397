import React from 'react';
import { EnterpriseCareerWrapper } from './style';

export const EnterprisePublicCareer = () => {
  return (
    <EnterpriseCareerWrapper>
      <div className="sub-container">
        <div className="main-wrapper">
          <div className="heading">career</div>
        </div>
      </div>
    </EnterpriseCareerWrapper>
  );
};
