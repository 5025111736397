import { React, useState, useEffect, useRef } from 'react';
import { ConversationUser } from '../../components';
import { Input, ScrollableDiv, CustomButton, DropDown } from 'components';
import tokenService from 'services/token.service';
import Icon from 'utlils/Icons/Icon';
import { ConversationUsersListWrapper } from './style';
import { useParams } from 'react-router-dom';
import { CONVERSATION_TYPES } from 'pages/TwilioChat/constants';

export const ConversationUsersList = ({
  openChat,
  conversationsClient,
  conversationsList,
  handleConversationClick,
  connectionState,
}) => {
  const { conversationId } = useParams();
  const [listOfConversations, setListOfConversations] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filterParams, setFilterParams] = useState([]);
  const [searchConversation, setSearchConversation] = useState('');
  const loggedInUser = tokenService.getSession();
  const selectedConversationRef = useRef(null);
  const onApplyFilter = () => {
    const filteredValue =
      filterParams?.conversationType?.type === 'ENTERPRISE'
        ? conversationsList.filter(
            (item) => item?.attributes?.isEnterprise === true
          )
        : filterParams?.conversationType?.type === '' ||
          filterParams?.conversationType?.type === 'ALL'
        ? conversationsList
        : conversationsList.filter(
            (item) =>
              item?.attributes?.conversationType ===
              filterParams?.conversationType?.type
          );
    setListOfConversations(filteredValue);
    setIsFilterOpen(false);
  };

  const onCancelFilter = () => {
    setListOfConversations(conversationsList);
    setIsFilterOpen(false);
  };

  const onSearchConversation = (value) => {
    setSearchConversation(value.toLowerCase());
  };
  const onCloseSearchConversation = () => {
    setSearchConversation('');
  };

  useEffect(() => {
    setListOfConversations(conversationsList);
    // Scroll to the bottom when the conversation list changes
    if (selectedConversationRef.current) {
      selectedConversationRef.current.scrollIntoView({
        behavior: 'smooth', // Optional: Add smooth scrolling
      });
    }
  }, [conversationsList]);

  return (
    <ConversationUsersListWrapper>
      <div className="messages">
        <p>Messages</p>
      </div>

      <div className="participants-list">
        <ScrollableDiv
          scrollOnHeight="calc(100vh - 40vh)"
          scrollOnHeightMob="calc(100vh - 110px)">
          <div className="search-people">
            <Input
              iconLeft={conversationsList.length === 0}
              iconName="search"
              iconRight={conversationsList.length !== 0}
              iconRightName="cross"
              placeholder="Search Conversations"
              value={searchConversation}
              customStyle={{ height: '53px' }}
              onChange={(e) => onSearchConversation(e.target.value)}
              iconRightClassName="close-search-team"
              iconRightOnClickHandler={onCloseSearchConversation}
            />
            <CustomButton
              className="filter-search-btn"
              // customPadding="10px 10px"
              variant="light"
              onClick={() => setIsFilterOpen(!isFilterOpen)}
              color={isFilterOpen ? 'themeSecondary' : 'themeGray2'}>
              <Icon
                name="filter"
                size={20}
                color={isFilterOpen ? 'rgba(33, 159, 255, 1)' : '#404f67'}
              />
            </CustomButton>
          </div>
          {isFilterOpen && (
            <div className="chat-filter">
              <DropDown
                label="Conversation Type"
                items={CONVERSATION_TYPES}
                objectKey="value"
                placeholder="Select"
                onClick={(item) => {
                  setFilterParams((state) => ({
                    ...state,
                    conversationType: { type: item?.type, value: item?.value },
                  }));
                }}
                value={filterParams?.conversationType}
                // hasSearchBox
              />
              <div className="chat-filter-button d-flex gap-3 align-items-center">
                <CustomButton
                  label="Apply"
                  className="w-100"
                  color="themeSecondary"
                  onClick={onApplyFilter}
                />
                <CustomButton
                  label="Cancel"
                  className="w-100"
                  color="themeSecondary"
                  variant="light"
                  onClick={onCancelFilter}
                />
              </div>
            </div>
          )}
          <div>
            {connectionState?.loading && listOfConversations?.length === 0 ? (
              <div className="conversations-loader">
                <Icon color="#7c8b9f" name="loader" size={42} />
                Loading Conversations ...
              </div>
            ) : null}
            {listOfConversations?.length > 0 &&
              listOfConversations?.map((conversation, index) => (
                <ConversationUser
                  key={`${conversation?.sid}${index}`}
                  handleConversationClick={handleConversationClick}
                  conversationDetails={conversation}
                  loggedInUser={loggedInUser}
                  isSelected={conversationId === conversation?.sid}
                  ref={
                    conversationId === conversation?.sid
                      ? selectedConversationRef
                      : null
                  }
                  searchCriteria={searchConversation}
                />
              ))}
          </div>
        </ScrollableDiv>
      </div>
    </ConversationUsersListWrapper>
  );
};
