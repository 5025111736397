export const MAX_VIDEO_SIZE = 20;
export const MAX_IMAGE_SIZE = 5;
export const MAX_DOCUMENT_SIZE = 30;

export const CONVERSATION_TYPES = [
  { type: 'ALL', value: 'ALL' },
  { type: 'ONE_TO_ONE', value: 'INDIVIDUAL' },
  { type: 'TEAM', value: 'TEAMS' },
  { type: 'ENTERPRISE', value: 'ENTERPRISE' },
];
