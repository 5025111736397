import { useState, useEffect, useContext, useCallback } from 'react';
import {
  setProgressStep,
  setProjectDetails,
  clearState,
} from 'context/Projects/CreateProject/createProjectAction';
import { useNavigate, useParams } from 'react-router-dom';
import { CreateProjectContext } from 'context/Projects/CreateProject/createProjectContext';
import ProjectCard from 'pages/IndividualDashboard/components/projectCard/projectCard';
import { StatusCard } from 'pages/IndividualDashboard/components/statusCard';
import {
  CustomButton,
  Loader,
  ModalPopup,
  ScrollableDiv,
  SearchField,
} from 'components';
import RadioGroup from 'pages/IndividualDashboard/components/radioGroup';
import { LinkProjectWrapper } from './style';
import { TYPES } from 'pages/IndividualDashboard/constants';
import {
  FETCH_ENTERPRISE_PROJECTS,
  FETCH_EXISTING_PROJECTS,
  FETCH_PUBLIC_PROJECTS,
  FETCH_PROJECT_SERVICES,
  useAxiosWrapper,
  CREATE_PROJECT,
  UPDATE_DRAFT_PROJECT,
} from 'services';
import Icon from 'utlils/Icons/Icon';

const enterpriseRadioLabels = [
  { text: 'Link to your existing Projects', value: '1' },
  { text: 'Link to your Enterprise projects', value: '2' },
  { text: 'Explore Projects', value: '3' },
];

const personalRadioLabels = [
  { text: 'Link to your existing Projects', value: '1' },
  { text: 'Explore Projects', value: '3' },
];

export const LinkProject = ({
  onLeavingEditor,
  type = '',
  isDraft = true,
  draftProjectId = '',
  baseUrl = '',
}) => {
  const [fnWrapper, context] = useAxiosWrapper();
  const { createProjectState, createProjectDispatch } =
    useContext(CreateProjectContext);
  const [searchProject, setSearchProject] = useState('');
  const [isDraftPopupOpen, setIsDraftPopupOpen] = useState(false);
  const [customData, setCustomData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [radioType, setRadioType] = useState('1');
  const [warningPopup, setWarningPopup] = useState({
    isRemove: false,
    warningOpen: false,
    item: {},
  });
  const [previousLinkedProject, setPreviousLinkedProject] = useState('');
  const { id, departmentId, projectId, action } = useParams();
  const navigate = useNavigate();

  const radioLabels =
    type === TYPES.ENTERPRISE ? enterpriseRadioLabels : personalRadioLabels;

  const searchProjectByNameHandler = (value) => {
    setSearchProject(value);

    let temp = [];
    if (value) {
      temp = customData.filter(
        (ele) => ele.name.toLowerCase().indexOf(value.toLowerCase()) > -1
      );
      setFilteredData(temp);
    } else {
      setFilteredData(customData);
    }
  };
  const closeSearchProjectByName = () => {
    setSearchProject('');
    setFilteredData(customData);
  };

  const getEnterpriseProjects = useCallback(async () => {
    const deptId = id ? id : departmentId;
    const res = await fnWrapper({
      url: `${FETCH_ENTERPRISE_PROJECTS}/${deptId}`,
      method: 'GET',
      type: 'FETCH_ENTERPRISE_PROJECT_LIST',
    });

    if (res.data.statusCode === 200) {
      let enterpriseProjectsList = res.data.data
        ?.map((project) => ({
          id: project.id,
          createdBy: {
            id:
              project.projectType === TYPES.ENTERPRISE
                ? project?.enterprise?.id
                : project?.user?.id,
            name:
              project.projectType === TYPES.ENTERPRISE
                ? project?.enterprise?.enterpriseName
                : project?.user?.individual?.name,
          },
          description: project.problemStatement,
          name: project.title,
          projectImgSrc: project.profilePicture,
          userType: project.projectType,
        }))
        .filter((project) => project.id !== projectId);
      setCustomData(enterpriseProjectsList);
      setFilteredData(enterpriseProjectsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getExistingProjects = useCallback(async () => {
    const url = `${FETCH_EXISTING_PROJECTS}?type=${type.toLowerCase()}`;
    const res = await fnWrapper({
      url: url,
      method: 'GET',
      type: 'FETCH_EXISTING_PROJECTS',
    });

    if (res.data.statusCode === 200) {
      let existingProjectsList = res.data.data
        .map((project) => ({
          id: project.id,
          createdBy: {
            id:
              project.projectType === TYPES.ENTERPRISE
                ? project?.enterprise?.id
                : project?.user?.id,
            name:
              project.projectType === TYPES.ENTERPRISE
                ? project?.enterprise?.enterpriseName
                : project?.user?.individual?.name,
          },
          description: project.problemStatement,
          name: project.title,
          projectImgSrc: project.profilePicture,
          userType: project.projectType,
        }))
        .filter((project) => project.id !== projectId);
      setCustomData(existingProjectsList);
      setFilteredData(existingProjectsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPublicProjects = useCallback(async () => {
    const res = await fnWrapper({
      url: FETCH_PUBLIC_PROJECTS,
      method: 'GET',
      type: 'FETCH_PUBLIC_PROJECTS',
    });

    if (res.data.statusCode === 200) {
      let publicProjectsList = res.data.data
        ?.map((project) => ({
          id: project.id,
          createdBy: {
            id:
              project.projectType === TYPES.ENTERPRISE
                ? project?.enterprise?.id
                : project?.user?.id,
            name:
              project.projectType === TYPES.ENTERPRISE
                ? project?.enterprise?.enterpriseName
                : project?.user?.individual?.name,
          },
          description: project.problemStatement,
          name: project.title,
          projectImgSrc: project.profilePicture,
          userType: project.projectType,
        }))
        .filter((project) => project.id !== projectId);
      setCustomData(publicProjectsList);
      setFilteredData(publicProjectsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRadioButtons = (e) => {
    const { value } = e.target;
    setSearchProject('');
    setRadioType(value);
    if (value === '1') {
      getExistingProjects();
    } else if (value === '2') {
      getEnterpriseProjects();
    } else if (value === '3') {
      getPublicProjects();
    }
  };

  const handleLinkClick = async (project, isApproved = true) => {
    if (!isApproved) {
      setWarningPopup({
        isRemove: false,
        warningOpen: true,
        item: project,
      });
    } else {
      await setProjectDetails(createProjectDispatch, 'isEdited', true);
      await setProjectDetails(
        createProjectDispatch,
        'linkedProjectId',
        project.id
      );
      const temp = {
        img: project.projectImgSrc,
        projectName: project.name,
        isLinked: true,
      };
      await setProjectDetails(createProjectDispatch, 'selectedProject', temp);
    }
  };

  const removeHandler = async (cardId, isApproved = true) => {
    if (!isApproved) {
      setWarningPopup({
        ...warningPopup,
        isRemove: true,
        warningOpen: true,
      });
    } else {
      await setProjectDetails(createProjectDispatch, 'isEdited', true);
      await setProjectDetails(createProjectDispatch, 'linkedProjectId', '');
      await setProjectDetails(createProjectDispatch, 'selectedProject', {});
    }
  };

  const onCloseHandler = () => {
    if (action && action === 'edit') {
      onLeavingEditor();
    } else {
      setIsDraftPopupOpen(true);
    }
  };

  const leaveWithoutSave = async () => {
    await clearState(createProjectDispatch);
    navigate(`${baseUrl}`, { replace: true });
  };

  const saveAndLeave = async () => {
    let formData = new FormData();
    for (var i of createProjectState.attachments) {
      formData.append('attachments', i);
    }
    formData.append('title', createProjectState.projectTitle);
    formData.append('problemStatement', createProjectState.problemStatement);
    formData.append('linkedProject', createProjectState.linkedProjectId);
    formData.append('link', createProjectState.link);
    formData.append('profilePicture', createProjectState.projectAvatar);
    formData.append('solution', createProjectState.solution);
    if (type === TYPES.ENTERPRISE) {
      const deptId = id ? id : departmentId;
      formData.append('departmentId', deptId);
    }
    formData.append('projectStatus', 'DRAFT');
    formData.append(
      'projectType',
      type === TYPES.ENTERPRISE ? 'ENTERPRISE' : 'PERSONAL'
    );
    for (var j of createProjectState.linkedTeams) {
      formData.append('teamId', j);
    }
    if (isDraft) {
      for (var f of createProjectState.linkAttachments) {
        formData.append('linkAttachments', f);
      }
      for (var k of createProjectState.deletedTeams) {
        formData.append('deleteTeamId', k);
      }
    }

    const url = isDraft
      ? `${UPDATE_DRAFT_PROJECT}/${draftProjectId}`
      : CREATE_PROJECT;

    const res = await fnWrapper({
      url: url,
      method: 'POST',
      type: 'CREATE_PROJECT',
      payload: formData,
    });

    if (res.data.statusCode === 200) {
      await clearState(createProjectDispatch);
      navigate(`${baseUrl}`, { replace: true });
    }
  };

  const getLinkedProjectsList = useCallback(async (projId) => {
    const res = await fnWrapper({
      url: `${FETCH_PROJECT_SERVICES}/${projId}?st=linkedProjects`,
      method: 'GET',
      type: 'FETCH_LINKED_PROJECTS',
    });

    if (res.data.statusCode === 200) {
      const result = res.data.data;
      if (result.data[0]) {
        setProjectDetails(
          createProjectDispatch,
          'linkedProjectId',
          result.data[0]?.id
        );
        setPreviousLinkedProject(result.data[0]?.id);
        let temp = {};
        temp = {
          img: result.data[0]?.profilePicture,
          projectName: result.data[0]?.title,
          isLinked: true,
        };
        setProjectDetails(createProjectDispatch, 'selectedProject', temp);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const continueHandler = async () => {
    setWarningPopup({
      ...warningPopup,
      warningOpen: false,
    });
    setPreviousLinkedProject('');
    if (warningPopup.isRemove) {
      removeHandler('', true);
    } else {
      handleLinkClick(warningPopup.item, true);
    }
  };

  useEffect(() => {
    if (action && action === 'edit') {
      getLinkedProjectsList(projectId);
    } else if (isDraft) {
      getLinkedProjectsList(draftProjectId);
    }
  }, [getLinkedProjectsList, action, isDraft, projectId, draftProjectId]);

  useEffect(() => {
    getExistingProjects();
  }, [getExistingProjects]);

  useEffect(() => {
    if (createProjectState.progressStep === 1) {
      if (action && action === 'edit') {
        navigate(`${baseUrl}/${projectId}/edit/details`, { replace: true });
      } else {
        navigate(`${baseUrl}/create/details`, { replace: true });
      }
    } else {
      setProgressStep(createProjectDispatch, 2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LinkProjectWrapper>
      <div className="main-container">
        {createProjectState.selectedProject &&
          Object.keys(createProjectState.selectedProject).length !== 0 && (
            <div className="status-card-container">
              <StatusCard
                isImg={
                  createProjectState.selectedProject.img !== null &&
                  createProjectState.selectedProject.img?.length !== 0
                }
                cardImg={createProjectState.selectedProject.img}
                cardTitle={createProjectState.selectedProject.projectName}
                iconName="project"
                isCardOpen={createProjectState.selectedProject.isLinked}
                onRemove={(id) =>
                  removeHandler(id, previousLinkedProject === '')
                }
              />
            </div>
          )}
        <RadioGroup
          currChecked={radioType}
          labels={radioLabels}
          handleChange={handleRadioButtons}
        />
        <div className="row">
          <div className={radioType === '3' ? 'col-lg-10' : 'col-lg-12'}>
            <SearchField
              searchField={searchProject}
              onChangeSearchHandler={searchProjectByNameHandler}
              closeSearchHandler={closeSearchProjectByName}
              placeholder="Search by Project Name or Keyword"
            />
          </div>
          {radioType === '3' && (
            <div className="col-lg-2">
              <CustomButton
                className="w-100"
                variant="light"
                color="themeGray"
                // disabled={teamsData.length === 0 && isFilterDisabled}
                // onClick={() => setIsFilterOpen(!isFilterOpen)}
              >
                <Icon
                  name="filter"
                  className="manage-arrow"
                  color="#929DAF"
                  size={20}
                />
                Filter
              </CustomButton>
            </div>
          )}
        </div>
        {context.busy.status ? (
          <Loader isLoading={context.busy.status} />
        ) : customData.length === 0 ? (
          <div className="no-project">No projects available!</div>
        ) : (
          <>
            {searchProject !== '' && filteredData.length === 0 ? (
              <div className="no-project">
                <p>
                  Oh, looks like you don’t have any Project's name starting by
                  <strong>{` ${searchProject}.`}</strong>
                </p>
              </div>
            ) : (
              <div style={{ marginTop: '20px' }}>
                <ScrollableDiv scrollOnHeight="320px" scrollOnHeightMob="500px">
                  <div className="cards-container">
                    {filteredData.map((item, index) => {
                      return (
                        <div className="card-wrapper" key={index}>
                          <ProjectCard
                            item={item}
                            buttonLabel={
                              previousLinkedProject === item.id
                                ? 'Linked'
                                : createProjectState.linkedProjectId === item.id
                                ? 'Requested'
                                : 'Request Link'
                            }
                            isRequestDisabled={
                              createProjectState.linkedProjectId === item.id
                            }
                            btnLeftIcon
                            btnTextColor={
                              previousLinkedProject === item.id
                                ? '#ffffff'
                                : '#0abd00'
                            }
                            btnIconName={
                              previousLinkedProject === item.id
                                ? 'tickIcon'
                                : 'link'
                            }
                            viewHandler={() =>
                              window.open(`/public/project/${item.id}/details`)
                            }
                            requestHandler={() =>
                              handleLinkClick(
                                item,
                                previousLinkedProject === ''
                              )
                            }
                            type={'Project'}
                          />
                        </div>
                      );
                    })}
                  </div>
                </ScrollableDiv>
              </div>
            )}
          </>
        )}

        <div className="bottom-buttons">
          <CustomButton
            label="Close"
            onClick={onCloseHandler}
            variant="textBtn"
            color="themeSecondary"
          />
          <CustomButton
            className="customNextBtnLinkProject"
            label="Next"
            // disabled={disabled}
            onClick={async () => {
              await setProgressStep(createProjectDispatch, 3);
              if (action && action === 'edit') {
                navigate(`${baseUrl}/${projectId}/edit/add-team`);
              } else {
                navigate(`${baseUrl}/create/add-team`);
              }
            }}
            color="themeSecondary"
          />
          <div className="right-button">
            <CustomButton
              label="Skip>>"
              color="themeSecondary"
              variant="textBtn"
              onClick={async () => {
                await setProgressStep(createProjectDispatch, 3);
                if (action && action === 'edit') {
                  navigate(`${baseUrl}/${projectId}/edit/add-team`);
                } else {
                  navigate(`${baseUrl}/create/add-team`);
                }
              }}
            />
          </div>
        </div>
      </div>
      {isDraftPopupOpen && (
        <ModalPopup
          open={false}
          onClose={() => setIsDraftPopupOpen(false)}
          hasCloseIcon>
          <p className="text-center mt-5 w-75 mx-auto">
            You have some unsaved changes. Do you want to save it as a draft or
            leave?
          </p>
          <div className="draft-buttons mx-auto d-flex flex-sm-row flex-column justify-content-evenly align-items-center my-5">
            <CustomButton
              label="Leave"
              variant="outline"
              onClick={leaveWithoutSave}
              color="themeTertiary"
              customPadding="16px 52px"
            />
            <CustomButton
              loading={context.busy.status}
              variant="outline"
              label="Save as Draft"
              onClick={saveAndLeave}
              customPadding="16px 30px"
            />
          </div>
        </ModalPopup>
      )}
      {warningPopup.warningOpen && (
        <ModalPopup
          open={false}
          onClose={() =>
            setWarningPopup({ ...warningPopup, warningOpen: false })
          }
          hasCloseIcon>
          <p className="text-center mt-5 w-75 mx-auto">
            {warningPopup.isRemove
              ? 'Removing this project will un-link it. '
              : 'Linking projects allows selecting up to one project. Requesting to link another project will un-link your previously linked project. '}{' '}
            Would you like to continue?
          </p>
          <div className="draft-buttons mx-auto d-flex flex-sm-row flex-column justify-content-evenly align-items-center my-5">
            <CustomButton
              variant="outline"
              label="Cancel"
              color="themeOrange"
              onClick={() =>
                setWarningPopup({ ...warningPopup, warningOpen: false })
              }
              customPadding="16px 30px"
            />
            <CustomButton
              loading={context.busy.status}
              variant="outline"
              label="Continue"
              onClick={continueHandler}
              customPadding="16px 30px"
            />
          </div>
        </ModalPopup>
      )}
    </LinkProjectWrapper>
  );
};
