import React from 'react';
import { HomeNavbar } from '../HomeNavbar';
import { HeroContentWrapper } from './style';
import { ModalPopup } from 'components';
import { HOME_PAGE_DATA } from 'pages/HomePageV2/constant';

export const InfoModal = ({ open, title, onClose, content }) => {
  return (
    <HeroContentWrapper>
      <div className="container">
        <div className="row">
          <HomeNavbar options={HOME_PAGE_DATA.navData} />
        </div>
        <ModalPopup open={open} title={title} onClose={onClose}>
          <div className="pricing-content">{content}</div>
        </ModalPopup>
      </div>
    </HeroContentWrapper>
  );
};
