import './checkbox.scss';
import cn from 'classnames';
export const Checkbox = ({
  id,
  title,
  isCheck = false,
  disabled,
  onClick,
  onChange,
  className,
}) => {
  return (
    <div
      className={cn('checkbox-container mb-4', className)}
      onClick={onClick}
      onChange={onChange}>
      <input type="checkbox" id={id} checked={isCheck} disabled={disabled} />
      <label for={id} className="checkbox-title">
        {title}
      </label>
    </div>
  );
};
