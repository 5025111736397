import React, { useState, useContext } from 'react';
import {
  useParams,
  useNavigate,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import './style.scss';
import {
  Details,
  LinkProject,
  AddTeams,
  Streams,
  Success,
} from '../Components';
import { CreateProjectContext } from 'context/Projects/CreateProject/createProjectContext';
import { clearState } from 'context/Projects/CreateProject/createProjectAction';
import { CustomButton, ModalPopup } from 'components';
import ProgressBar from 'pages/IndividualDashboard/components/progressBar';
import { TYPES } from 'pages/IndividualDashboard/constants';
import {
  CREATE_PROJECT,
  UPDATE_DRAFT_PROJECT,
  useAxiosWrapper,
} from 'services';
import { PROJECT_CATEGORY } from 'constants/constants';

export const CreateProject = ({ type = '', baseUrl = '' }) => {
  const [fnWrapper, context] = useAxiosWrapper();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { createProjectState, createProjectDispatch } =
    useContext(CreateProjectContext);
  const [isDraftPopupOpen, setIsDraftPopupOpen] = useState(false);
  const [draftState] = useState({
    isDraft: location.state === null ? false : location.state.isDraft,
    projectId: location.state === null ? '' : location.state.projectId,
  });

  const createProjectProgressData = [
    {
      id: 1,
      title: 'Details',
      url: `${baseUrl}/create/details`,
    },
    {
      id: 2,
      title: 'Link Project',
      url: `${baseUrl}/create/link-project`,
    },
    {
      id: 3,
      title: 'Add Team',
      url: `${baseUrl}/create/add-team`,
    },
    {
      id: 4,
      title: 'Streams',
      url: `${baseUrl}/create/streams`,
    },
  ];

  const projectCategories = {
    project_category: {
      label: 'ProjectCategory',
      dataType: PROJECT_CATEGORY,
      key: 'label',
      placeholder: 'Select Category',
    },
  };

  const leaveWithoutSave = async () => {
    await clearState(createProjectDispatch);
    navigate(`${baseUrl}`, { replace: true });
  };

  const saveAndLeave = async () => {
    let formData = new FormData();
    for (var i of createProjectState.attachments) {
      formData.append('attachments', i);
    }
    formData.append('title', createProjectState.projectTitle);
    formData.append('problemStatement', createProjectState.problemStatement);
    formData.append('linkedProject', createProjectState.linkedProjectId);
    formData.append('link', createProjectState.link);
    formData.append('profilePicture', createProjectState.projectAvatar);
    formData.append('solution', createProjectState.solution);
    if (type === TYPES.ENTERPRISE) {
      formData.append('departmentId', id);
    }
    formData.append('projectStatus', 'DRAFT');
    formData.append(
      'projectType',
      type === TYPES.ENTERPRISE ? 'ENTERPRISE' : 'PERSONAL'
    );
    for (var j of createProjectState.linkedTeams) {
      formData.append('teamId', j);
    }
    if (draftState.isDraft) {
      for (var f of createProjectState.linkAttachments) {
        formData.append('linkAttachments', f);
      }
      for (var k of createProjectState.deletedTeams) {
        formData.append('deleteTeamId', k);
      }
    }

    const url = draftState.isDraft
      ? `${UPDATE_DRAFT_PROJECT}/${draftState.projectId}`
      : CREATE_PROJECT;

    const res = await fnWrapper({
      url: url,
      method: 'POST',
      payload: formData,
      type: 'CREATE_PROJECT',
    });

    if (res.data.statusCode === 200) {
      await clearState(createProjectDispatch);
      navigate(`${baseUrl}`, { replace: true });
    }
  };

  return (
    <div className="create-project-container">
      <div className="create-project-heading">
        <span className="heading-text">Create project</span>
      </div>
      <div className="create-project-progress-bar">
        <ProgressBar
          progressData={createProjectProgressData}
          step={createProjectState.progressStep}
          isRoute
        />
        <Routes>
          <Route
            element={
              <Details
                projectCategories={projectCategories}
                type={type}
                isDraft={draftState.isDraft}
                draftProjectId={draftState.projectId}
                baseUrl={baseUrl}
              />
            }
            path="details"
          />
          <Route
            element={
              <LinkProject
                type={type}
                isDraft={draftState.isDraft}
                draftProjectId={draftState.projectId}
                baseUrl={baseUrl}
              />
            }
            path="link-project"
          />
          <Route
            element={
              <AddTeams
                type={type}
                isDraft={draftState.isDraft}
                draftProjectId={draftState.projectId}
                baseUrl={baseUrl}
              />
            }
            path="add-team"
          />
          <Route
            element={
              <Streams
                type={type}
                isDraft={draftState.isDraft}
                draftProjectId={draftState.projectId}
                baseUrl={baseUrl}
              />
            }
            path="streams"
          />
          <Route
            element={<Success type={type} baseUrl={baseUrl} />}
            path="success"
          />
        </Routes>
      </div>
      {isDraftPopupOpen && (
        <ModalPopup
          open={false}
          onClose={() => setIsDraftPopupOpen(false)}
          hasCloseIcon>
          <p className="text-center mt-5 w-75 mx-auto">
            You have some unsaved changes. Do you want to save it as a draft or
            leave?
          </p>
          <div className="draft-buttons mx-auto d-flex flex-sm-row flex-column justify-content-evenly align-items-center my-5">
            <CustomButton
              label="Leave"
              variant="outline"
              onClick={leaveWithoutSave}
              color="themeTertiary"
              customPadding="16px 52px"
            />
            <CustomButton
              loading={context.busy.status}
              variant="outline"
              label="Save as Draft"
              onClick={saveAndLeave}
              customPadding="16px 30px"
            />
          </div>
        </ModalPopup>
      )}
    </div>
  );
};
