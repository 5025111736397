import { useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import createInitialTeams from 'assests/images/createInitialTeams.png';
import styled from 'styled-components';
import axios from 'axios';
import dayjs from 'dayjs';
import { getPlaceholderItems } from 'utlils/utils';
import Icon from 'utlils/Icons/Icon';
import 'utlils/Icons/Icon';
import {
  teamsTableData,
  TEAMTABLEHEADERS,
} from 'pages/IndividualDashboard/IndividualPersonalSection/individualTeams/helper';
import WithDrawTeams from 'pages/IndividualDashboard/IndividualPersonalSection/individualTeams/viewTeams/withDrawTeams/index';
import {
  Box,
  CustomButton,
  CustomTable,
  Input,
  Loader,
  ModalPopup,
  ScrollableDiv,
} from 'components';
import TeamsFilter from '../../../../components/teamFilter';
import { FETCH_PENDING_DEPARTMENT_TEAMS, useAxiosWrapper } from 'services';
import TeamApprovalModal from './teamApprovalNextModal';
import teamsListIcon from '../../../../../../assests/images/teamApproveListIcon.svg';
import { getDateTime } from 'utlils/utils';

const TeamListWrapper = styled.div`
  position: relative;
  & .header-buttons-wrapper {
    margin-top: 20px;
    gap: 50px;

    & .search-bar {
      width: 100%;
      margin-top: 20px;
    }
    & .buttons {
      display: flex;
      gap: 16px;
      justify-content: flex-end;
      @media (max-width: 767px) {
        flex-direction: column;
      }
    }

    @media (max-width: 767px) {
      flex-direction: column;
      gap: 10px;
    }
  }
  & .filter-arrow {
    margin-left: 5px;
    margin-top: 6px;
  }
  .no-team {
    width: 75%;
    text-align: center;
    margin: 10% auto;
    font-weight: 500;
    color: #929daf;
  }
  .header-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
    font-size: 25px;
    color: #17191d;
    padding: 5px;
    @media only screen and (max-width: 992px) and (min-width: 320px) {
      display: grid;
      gap: 15px;
    }
  }
  .add-team-icon {
    margin-right: 6px;
    @media only screen and (max-width: 1204px) and (min-width: 999px) {
      margin-right: 2px;
      margin-left: -7px;
    }
  }
`;
const TeamApprovalContainer = styled.div`
  padding: 15px;
  & .team-approve_list-item {
    display: grid;
    grid-template-columns: 6fr 1fr;
    column-gap: 10px;

    & .team-approve_list-text-container {
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 10px;

      & .team-approve_list-text-container-content {
        & .team-approve_list-item__text__para {
          & span {
            font-weight: 600;
          }
        }
        & .team-approve_list-item__time {
          color: #929daf;
        }
      }

      & .team-approve_list-item__text {
        display: flex;
        & img {
          padding-right: 10px;
        }
      }
    }

    & .team-approve_list-item__btn {
      padding: 0;
      /* gap: 16px; */

      @media (max-width: 400px) {
        flex-direction: column;
      }
    }

    @media (max-width: 1250px) {
      flex-direction: column;
    }
  }
`;

export const TeamList = ({
  isTeamListUpdate,
  teamApprovalPermission,
  userDepRole,
}) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [searchTeams, setSearchTeams] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [teamsData, setTeamsData] = useState([]);
  const [isWithdrawPopupOpen, setWithdrawPopupOpen] = useState(false);
  const [withDrawStep, setWithdrawStep] = useState('');
  const [modalOpen, setModalOpen] = useState({
    openTeamApprove: false,
    openViewTeamInTeamApprove: false,
  });
  const [teamApprovalList, setTeamApprovalList] = useState([]);
  const [teamId, setTeamId] = useState();
  const [userId, setUserId] = useState();

  const [filteredTeams, setFilteredTeams] = useState({
    filteredTeamName: '',
    filteredDate: {},
    filteredRole: {},
    filteredStatus: {},
    fromDate: null,
    toDate: null,
  });

  let navigate = useNavigate();
  let { id } = useParams();
  const [fnWrapper, context] = useAxiosWrapper();

  const onRowCheckedHandler = (element) => {
    if (selectedRow.length) {
      const index = selectedRow.filter((ele) => ele.id === element.id);
      if (index.length) {
        const filteredItems = selectedRow.filter(
          (ele) => ele.id !== element.id
        );
        setSelectedRow(filteredItems);
      } else {
        setSelectedRow([...selectedRow, element]);
      }
    } else {
      setSelectedRow([...selectedRow, element]);
    }
  };

  const onAllRowCheckedHandler = (element) => {
    setSelectedRow(element);
  };

  const getListOfTeams = useCallback(async () => {
    setIsLoading(true);
    const session = JSON.parse(localStorage.getItem('session'));
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/getTeamsByDepartmentId/${id}`,
          {
            headers: {
              'x-access-token': `${session.access_token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.statusCode === 200) {
            let modifiedData = res.data.data.teams.reduce((array, team) => {
              let currentUser = team.teamMembers.filter((ele) => {
                return ele.memberUserId === session.id;
              });
              let modifiedObject = {
                id: team.id,
                teamName: team.teamName,
                teamMembers: team.teamMembers,
                createdAt: team.createdAt,
                designation: currentUser[0].designation,
                role: currentUser[0].role,
                userId: team.userId,
                status: currentUser[0].status,
                currentMemberId: currentUser[0].id,
                teamStatus: team?.status,
                isEnterpriseVerified: team?.isEnterpriseVerified,
              };

              // Filter teams based on the user's ID and status
              if (team.userId === session.id || team.status === 'APPROVED') {
                array.push(modifiedObject);
              }

              return array;
            }, []);
            setTableData(modifiedData);
            setTeamsData(modifiedData);
            setFilteredData(modifiedData);
            setIsLoading(false);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          toast.error(error, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        });
    } catch (error) {
      setIsLoading(false);
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  }, [id]);

  const teamListData = isLoading
    ? getPlaceholderItems(
        TEAMTABLEHEADERS.map(({ sortKey }) => sortKey),
        4,
        isLoading
      )
    : teamsTableData(tableData);

  const withDrawTeamsHandler = async () => {
    const session = JSON.parse(localStorage.getItem('session'));
    const memberIds = selectedRow.map((row) => row.currentMemberId);
    let data = {
      memberIds: memberIds,
    };
    try {
      await axios
        .put(`${process.env.REACT_APP_API_BASE_URL}/leaveTeam`, data, {
          headers: {
            'x-access-token': `${session.access_token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          if (res.data.statusCode === 200) {
            setWithdrawPopupOpen(false);
            getListOfTeams();
            setSelectedRow([]);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
          setWithdrawPopupOpen(false);
          setSelectedRow([]);
        });
    } catch (error) {
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      setWithdrawPopupOpen(false);
      setSelectedRow([]);
    }
  };
  const closeWithdrawPopup = () => {
    setSelectedRow([]);
    setWithdrawPopupOpen(false);
  };
  const openWithdrawPopup = () => {
    setWithdrawStep(1);
    setWithdrawPopupOpen(true);
  };

  const teamFilterSaveHandler = (type) => {
    if (type === 'apply' && teamsData.length !== 0) {
      setSearchTeams('');
      let result = teamsData;

      let filterByTeamName = (item) => {
        if (filteredTeams.filteredTeamName === '') {
          return item;
        } else {
          return item.filter(
            (ele) =>
              ele.teamName.toLowerCase() ===
              filteredTeams.filteredTeamName.toLowerCase()
          );
        }
      };
      let filterByRole = (item) => {
        if (Object.keys(filteredTeams.filteredRole).length === 0) {
          return item;
        } else {
          return item.filter(
            (ele) => ele.role === filteredTeams.filteredRole.value
          );
        }
      };
      let filterByStatus = (item) => {
        if (Object.keys(filteredTeams.filteredStatus).length === 0) {
          return item;
        } else {
          return item.filter(
            (ele) => ele.status === filteredTeams.filteredStatus.value
          );
        }
      };
      let filterByDate = (item) => {
        if (Object.keys(filteredTeams.filteredDate).length === 0) {
          return item;
        }
        if (filteredTeams.filteredDate.value === 'YD') {
          return item.filter(
            (ele) =>
              dayjs(ele.createdAt).format('DD MMM YYYY') ===
              dayjs(new Date()).subtract(1, 'day').format('DD MMM YYYY')
          );
        }
        if (filteredTeams.filteredDate.value === 'TD') {
          return item.filter(
            (ele) =>
              dayjs(ele.createdAt).format('DD MMM YYYY') ===
              dayjs(new Date()).format('DD MMM YYYY')
          );
        }
        if (filteredTeams.filteredDate.value === 'CD') {
          return item.filter(
            (ele) =>
              new Date(dayjs(ele.createdAt).format('DD MMM YYYY')) >=
                new Date(dayjs(filteredTeams.fromDate).format('DD MMM YYYY')) &&
              new Date(dayjs(ele.createdAt).format('DD MMM YYYY')) <=
                new Date(dayjs(filteredTeams.toDate).format('DD MMM YYYY'))
          );
        }
      };
      if (filteredTeams.filteredTeamName !== '') {
        result = filterByTeamName(result);
      }
      if (Object.keys(filteredTeams.filteredDate).length) {
        result = filterByDate(result);
      }
      if (Object.keys(filteredTeams.filteredRole).length) {
        result = filterByRole(result);
      }
      if (Object.keys(filteredTeams.filteredStatus).length) {
        result = filterByStatus(result);
      }
      setTableData(result);
      setIsFilterOpen(false);
    } else if (type === 'cancel') {
      setFilteredTeams({
        filteredTeamName: '',
        filteredDate: {},
        filteredRole: {},
        filteredStatus: {},
        fromDate: null,
        toDate: null,
      });
      setTableData(teamsData);
      setIsFilterOpen(false);
    }
  };

  const teamFilterOnChangeHandler = (field = '', value = null) => {
    setFilteredTeams({
      ...filteredTeams,
      [field]: value,
    });
  };

  const searchTeamByNameHandler = (value) => {
    setSearchTeams(value);
    let temp = [];
    if (value) {
      setIsFilterOpen(false);
      temp = teamsData.filter(
        (ele) => ele.teamName.toLowerCase().indexOf(value.toLowerCase()) > -1
      );
      setFilteredData(temp);
      setTableData(temp);
    } else {
      setFilteredData(teamsData);
      setTableData(teamsData);
    }
  };

  const closeSearchTeamByName = () => {
    setSearchTeams('');
    setFilteredData(teamsData);
    setTableData(teamsData);
  };

  const getPendingDepartmentTeamsBasedOnDepartmentId = async () => {
    let data = [];
    const res = await fnWrapper({
      url: `${FETCH_PENDING_DEPARTMENT_TEAMS}/${id}`,
      method: 'GET',
      type: 'FETCH_PENDING_DEPARTMENT_TEAM',
    });

    if (res.data.statusCode === 200) {
      if (res?.data?.data?.length > 0) {
        res?.data?.data?.map((item) =>
          data.push({
            teamName: item?.teamName,
            userName: item?.user?.individual?.name,
            createdAt: item?.createdAt,
            teamId: item?.id,
            userId: item?.userId,
          })
        );
      }
      setTeamApprovalList(data);
    }
  };

  const openTeamApprovalModalHandler = async () => {
    setModalOpen((prev) => ({ ...prev, openTeamApprove: true }));
  };

  const openTaskAprrovalModalNextPageHandler = (teamId, userId) => {
    setTeamId(teamId);
    setUserId(userId);
    setModalOpen({
      openTeamApprove: false,
      openViewTeamInTeamApprove: true,
    });
  };

  useEffect(() => {
    getListOfTeams();
    if (isTeamListUpdate) {
      getListOfTeams();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getListOfTeams, isTeamListUpdate]);

  useEffect(() => {
    if (modalOpen.openTeamApprove) {
      getPendingDepartmentTeamsBasedOnDepartmentId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen.openTeamApprove]);

  return (
    <TeamListWrapper>
      <div className="header-buttons-wrapper">
        <div className="buttons">
          {userDepRole && userDepRole === 'MEMBER' ? null : (
            <>
              <CustomButton
                color="themeSecondary"
                variant="light"
                onClick={() =>
                  navigate(
                    `/individual/profile/organization/enterprise/department/${id}/teams/create/information`
                  )
                }>
                <Icon name="add" size={18} color="#219FFF" />
                New Team
              </CustomButton>
              <CustomButton
                color="themeTertiary"
                variant="light"
                disabled={selectedRow.length === 0}
                onClick={openWithdrawPopup}>
                <Icon name="log-out" color="rgba(255, 30, 38, 0.5)" size={18} />
                Withdraw
              </CustomButton>
            </>
          )}

          {isWithdrawPopupOpen && (
            <WithDrawTeams
              isPopupOpen={isWithdrawPopupOpen}
              closePopupHandler={closeWithdrawPopup}
              withDrawStep={withDrawStep}
              withDrawTeamsHandler={withDrawTeamsHandler}
              selectedRows={selectedRow}
              setWithdrawStep={setWithdrawStep}
            />
          )}
          <CustomButton
            color={isFilterOpen ? 'themeSecondary' : 'themeGray'}
            variant="light"
            customPadding="16px 28px"
            onClick={() => setIsFilterOpen(!isFilterOpen)}>
            <Icon
              name="filter"
              color={isFilterOpen ? 'rgba(33, 159, 255, 1)' : '#929DAF'}
              size={18}
            />
            Filter
          </CustomButton>
          {teamApprovalPermission && (
            <CustomButton
              label="Team Approvals"
              onClick={openTeamApprovalModalHandler}
              variant={'light'}
            />
          )}
        </div>
        <div className="search-bar">
          <Input
            iconLeft={searchTeams.length === 0}
            iconName="search"
            iconRight={searchTeams.length !== 0}
            iconRightName="cross"
            placeholder="Search Teams"
            customStyle={{ height: '53px' }}
            value={searchTeams}
            onChange={(e) => searchTeamByNameHandler(e.target.value)}
            iconRightClassName="close-search-team"
            iconRightOnClickHandler={closeSearchTeamByName}
          />
        </div>
      </div>
      {isFilterOpen && (
        <>
          <TeamsFilter
            filterTitle="Filter Teams"
            state={filteredTeams}
            teamFilterSaveHandler={teamFilterSaveHandler}
            teamFilterOnChangeHandler={teamFilterOnChangeHandler}
          />
        </>
      )}

      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : teamsData.length === 0 ? (
        <>
          <Box
            mt="50px"
            display="flex"
            justifyContent="center"
            alignItems="center">
            <img src={createInitialTeams} alt="" width="320px" />
          </Box>
          <Box
            mt="30px"
            display="flex"
            textAlign="center"
            justifyContent="center">
            <Box width="70%">
              <span>
                Oh, looks like you don’t have any Team. Create your own Team
                now!
              </span>
            </Box>
          </Box>
          <Box mt="30px" mb="30px" display="flex" justifyContent="center">
            <Box width="  250px">
              <CustomButton
                label="Create Team"
                onClick={() =>
                  navigate(
                    `/individual/profile/organization/enterprise/department/${id}/teams/create/information`
                  )
                }
                className="w-100"
              />
            </Box>
          </Box>
        </>
      ) : (
        <>
          {searchTeams !== '' && filteredData.length === 0 ? (
            <div className="no-team">
              <p>
                Oh, looks like you don’t have any Team's name starting by
                <strong>{` ${searchTeams}.`}</strong>
              </p>
            </div>
          ) : (
            <CustomTable
              isLoading={isLoading}
              rows={teamListData}
              headers={TEAMTABLEHEADERS}
              hasCheckbox={true}
              selectedRow={selectedRow}
              rowCheckedHandler={(val) => onRowCheckedHandler(val)}
              allRowCheckedHandler={(val) => onAllRowCheckedHandler(val)}
            />
          )}
        </>
      )}

      {modalOpen?.openViewTeamInTeamApprove && (
        <TeamApprovalModal
          backBtnHandler={() =>
            setModalOpen((prev) => ({ ...prev, openTeamApprove: false }))
          }
          open={modalOpen}
          handleOpen={(value) =>
            setModalOpen((prev) => ({
              ...prev,
              openViewTeamInTeamApprove: value,
            }))
          }
          teamId={teamId}
          userId={userId}
          updateTeamList={getListOfTeams}
        />
      )}

      {modalOpen.openTeamApprove && (
        <ModalPopup
          open={false}
          onClose={() =>
            setModalOpen((prev) => ({ ...prev, openTeamApprove: false }))
          }
          title="Team Approvals"
          hasTitle
          hasCloseIcon
          minWidth="650px"
          maxContentWidth="650px">
          {context.busy.status ? (
            <div className=" main-wrapper d-flex justify-content-center align-items-center w-100">
              <Loader loading={context.busy.status} />
            </div>
          ) : teamApprovalList.length > 0 ? (
            <ScrollableDiv>
              {teamApprovalList.map((item, index) => (
                <TeamApprovalContainer key={index}>
                  <div className="team-approve_list-item">
                    <div className="team-approve_list-text-container">
                      <div className="team-approve_list-text-container-img">
                        <img
                          src={teamsListIcon}
                          alt="team-approval-list-icon"
                        />
                      </div>
                      <div className="team-approve_list-text-container-content">
                        <div className="team-approve_list-item__text__para">
                          <span>{item.userName}</span> requested you to approve
                          Team <span>{item.teamName}</span>
                        </div>
                        <div className="team-approve_list-item__time">
                          {getDateTime(item.createdAt)}
                        </div>
                      </div>
                    </div>
                    <div className="team-approve_list-item__btn ">
                      <CustomButton
                        className="text-decoration-underline p-0"
                        label="View team"
                        variant="textBtn"
                        color="themeSecondary"
                        onClick={() =>
                          openTaskAprrovalModalNextPageHandler(
                            item.teamId,
                            item.userId
                          )
                        }
                        loading={context.busy.status}
                      />
                    </div>
                  </div>
                </TeamApprovalContainer>
              ))}
            </ScrollableDiv>
          ) : (
            <div className="d-flex align-items-center justify-content-center mt-3">
              <div>
                <p className="no-approvals ">
                  {'Sorry! no request for team approval sent yet !'}
                </p>
                <img src={createInitialTeams} width="320px" alt="noData" />
              </div>
            </div>
          )}
        </ModalPopup>
      )}
    </TeamListWrapper>
  );
};
