import dayjs from 'dayjs';
import { useState } from 'react';
import styled from 'styled-components';
import Icon from 'utlils/Icons/Icon';
import { ContentCardWrapper } from './style';

const ProfessionalDropDownContentWrapper = styled.div`
  background: #e9eef4;
  margin: 0 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 2px solid #e9eef4;
  border-right: 2px solid #e9eef4;
  border-left: 2px solid #e9eef4;
  border-radius: 0px 0px 10px 10px;

  & .content-header-class {
    .circle-class {
      position: absolute;
      top: 0;
      left: -8px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #404f67;
    }

    .card-heading {
      padding-left: 16px;
      color: #404f67;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .title-class {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
      }

      .sub-title-class {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
      }

      .total-duration-class {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
      }

      .content-description {
        margin: 8px 0 16px 0;
      }
    }

    & .last-item-wrapper {
      position: relative;
      padding-left: 10px;
    }
    & .description-item-wrapper {
      padding-left: 10px;
      position: relative;
      border-left: 2px dashed #404f67;
    }
  }
`;

export const SummaryContentCard = ({
  summaryType = '',
  title,
  subTitle,
  startDate,
  endDate,
  iconName,
  credentialLink = '',
  isEndDate = false,
  description = '',
  professionalDescription = [],
  isDropDown = false,
}) => {
  let fromDate = dayjs(startDate).format('YYYY');
  const [isDropDownExpand, setIsDropDownExpand] = useState(false);

  return (
    <>
      <ContentCardWrapper isDropDown={isDropDownExpand}>
        <div className="content-class">
          <div className="content-header-class">
            <div className="icon-style-class">
              <Icon name={iconName} size={30} color="#17191d" />
            </div>
            <div className="card-heading">
              <div className="title-class">{title}</div>
              <div className="sub-title-class">{subTitle}</div>
              {summaryType === 'achievement' || summaryType === 'license' ? (
                <div>Issue Date - {dayjs(startDate).format('MMM YYYY')}</div>
              ) : (
                <div className="total-duration-class">
                  <span>{fromDate}</span>-
                  <span>
                    {isEndDate ? dayjs(endDate).format('YYYY') : 'present'}
                  </span>
                </div>
              )}
              {description !== '' && (
                <div className="content-description">{description}</div>
              )}
              {credentialLink !== '' && (
                <a
                  href={credentialLink}
                  target="_blank"
                  without
                  rel="noreferrer"
                  className="content-link">
                  View Credential
                </a>
              )}
            </div>
          </div>
          {isDropDown && (
            <div>
              <Icon
                name={isDropDownExpand ? 'arrow-drop-up' : 'arrow-down'}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setIsDropDownExpand(!isDropDownExpand);
                }}
              />
            </div>
          )}
        </div>
      </ContentCardWrapper>
      {isDropDownExpand && (
        <ProfessionalContentDropDown description={professionalDescription} />
      )}
    </>
  );
};

const ProfessionalContentDropDown = ({ description }) => {
  return (
    <ProfessionalDropDownContentWrapper>
      {description?.length === 1 ? (
        <div className="task-detail">{description[0]?.description}</div>
      ) : (
        <div className="content-header-class">
          {description
            ?.sort(function (a, b) {
              return new Date(b.from) - new Date(a.from);
            })
            .map((data, index) => (
              <div
                className={
                  index === description.length - 1
                    ? 'last-item-wrapper'
                    : 'description-item-wrapper'
                }
                key={index}>
                <span className="circle-class"></span>
                <div className="card-heading">
                  <div className="title-class">{data.role}</div>
                  <div className="sub-title-class">{data.companyName}</div>

                  <div className="total-duration-class">
                    <span>{dayjs(data.from).format('YYYY')}</span>-
                    <span>
                      {data.to ? dayjs(data.to).format('YYYY') : 'present'}
                    </span>
                  </div>

                  {description !== '' && (
                    <div className="content-description">
                      {data.description}
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
      )}
    </ProfessionalDropDownContentWrapper>
  );
};
