import { noop } from 'lodash';
import { CustomButton, CustomDropDown, StatusButton } from 'components';
import {
  otherTasksStatus,
  STATUS_DROPDOWN_LIST_VIEW_TASK,
  TASK_STATUS,
  TASK_TOGGLE_LABEL,
} from 'pages/enterpriseDashboard/constants';
import Icon from 'utlils/Icons/Icon';

export const ActionSection = (props) => {
  const {
    ctx,
    dropDownStyle,
    onClickAssignToTeamButton,
    onClickReAssignDepartmentButton,
    taskStatus,
    taskType,
    task,
    updateTaskStatusHandler,
  } = props;
  return (
    <>
      {taskType === TASK_TOGGLE_LABEL.MY_TASKS ? (
        <div className="task-card__content-wrapper__footer col-md-3 col-lg-4 col-xl-4 col-xxl-3">
          <div className="task-card__content-wrapper__footer--action-wrapper">
            <>
              {task?.assignedToTeam === null ? (
                <CustomButton
                  customPadding="12px"
                  loading={
                    ctx.busy.status && ctx.busy.type === 'FETCH_TEAM_BY_DEPT_ID'
                  }
                  disabled={
                    ctx.busy.status && ctx.busy.type === 'FETCH_TEAM_BY_DEPT_ID'
                  }
                  onClick={onClickAssignToTeamButton}
                  color={'themeSecondary'}>
                  Assign to team
                  <Icon name="arrow-forward" color={'#ffffff'} size={20} />
                </CustomButton>
              ) : (
                <CustomButton
                  customPadding="12px 8px"
                  label={
                    task?.assignedToTeam?.teamName?.length > 12
                      ? `${task?.assignedToTeam.teamName.slice(0, 15)} ...`
                      : task?.assignedToTeam.teamName
                  }
                  disabled
                  onClick={noop}
                  color={'themeSecondary'}
                />
              )}
              <CustomDropDown
                optionLists={STATUS_DROPDOWN_LIST_VIEW_TASK}
                placeholder="Select"
                selectedItem={taskStatus}
                setSelectedItem={(val) => updateTaskStatusHandler(val.value)}
                customStyle={{
                  backgroundColor: dropDownStyle.backgroundColor,
                  color: dropDownStyle.color,
                  padding: '10px',
                  justifyContent: 'space-between',
                }}
                width="100%"
              />
            </>
          </div>
        </div>
      ) : (
        <div className="task-card__content-wrapper__footer col-md-3 col-lg-3">
          <div className="task-card__content-wrapper__footer--action-wrapper ">
            {task?.status !== TASK_STATUS.COMPLETED &&
              task?.status !== TASK_STATUS.IN_PROGRESS && (
                <CustomButton
                  label="Re-Assign"
                  customPadding="12px"
                  onClick={onClickReAssignDepartmentButton}
                  color={'themeSecondary'}
                  variant="outline"
                />
              )}
            <div className="other-status">
              <StatusButton status={otherTasksStatus[task?.status]} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
