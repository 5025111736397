import styled from 'styled-components';

export const ViewEnterpriseMemberWrapper = styled.div`
  & .title-header-section {
    display: flex;
    margin: 20px 0 20px 0;
    & .title-user-info {
      margin-left: 20px;
      margin-top: 8px;
    }
    & .title-user-name {
      font-size: 22px;
    }
    & .title-user-email-remove {
      display: flex;
    }
    & .title-email-text {
      font-size: 14px;
      color: #929daf;
    }
    & .title-remove-button {
      margin-top: -20px;
      margin-left: 50%;
    }
  }
  & .teams-section {
    & .team-info-cards {
      display: flex;
      flex-wrap: wrap;
    }
  }
  & .projects-section {
    margin-top: 5%;
    & .project-info-cards {
      display: flex;
      flex-wrap: wrap;
    }
  }
  & .section-title {
    color: #404f67;
    font-weight: 500;
  }
`;
