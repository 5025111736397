import { Box } from '../../../../components';
import './index.scss';
import cn from 'classname';
import BlueLine from '../../../../assests/images/blueLine.png';
import greyLine from '../../../../assests/images/greyLine.png';
import whiteTick from '../../../../assests/images/whitetick.png';
import { useNavigate } from 'react-router-dom';
const ProgressBar = ({
  step,
  progressData,
  onClickProgressHandler,
  isRoute,
  isLastStepDone,
}) => {
  const navigate = useNavigate();

  return (
    <Box className="custom-progress-bar">
      {progressData.map((item, index) => (
        <div className="progress-item-container" key={index}>
          <div className="d-flex align-items-center">
            <div
              className={cn(
                'tick-root',
                step < item.id && 'inactive',
                step === item.id && 'active',
                (step > item.id ||
                  (step === progressData.length && isLastStepDone)) &&
                  'complete'
              )}>
              {step <= item.id && item.id}
              {(step > item.id ||
                (step === progressData.length && isLastStepDone)) && (
                <img src={whiteTick} alt="" width="15px" height="15px" />
              )}
            </div>
            {isRoute ? (
              <div
                className={cn(
                  step >= item.id ? 'title-active' : 'title-disactive'
                )}
                onClick={() => step >= item.id && navigate(item.url)}>
                {item.title}
              </div>
            ) : (
              <div
                className={cn(
                  step >= item.id ? 'title-active' : 'title-disactive'
                )}
                onClick={() =>
                  step >= item.id &&
                  step !== progressData.length &&
                  onClickProgressHandler(item.id)
                }>
                {item.title}
              </div>
            )}
          </div>
          {index !== progressData.length - 1 && (
            <div className="progress-line">
              {step > item.id ? (
                <img
                  src={BlueLine}
                  alt=""
                  width={`${300 / progressData.length}%`}
                />
              ) : (
                <img
                  src={greyLine}
                  alt=""
                  width={`${300 / progressData.length}%`}
                />
              )}
            </div>
          )}
        </div>
      ))}
    </Box>
  );
};

export default ProgressBar;
