import {
  Box,
  CustomButton,
  CustomFileUploadButton,
  DropDown,
  Input,
  ScrollableDiv,
  Textarea,
} from 'components';
import Icon from 'utlils/Icons/Icon';
import './style.scss';
import { useState } from 'react';
import { toast } from 'react-toastify';
import {
  TASK_ATTACHMENTS_TYPES,
  TASK_URGENCY_LIST,
} from 'pages/enterpriseDashboard/constants';
import { CREATE_PUBLISH_TASK, useAxiosWrapper } from 'services';
import tokenService from 'services/token.service';
import ReactDatePicker from 'react-datepicker';

const DEFAULT_TASK_STATE = {
  taskTitle: '',
  taskDescription: '',
  assignTo: {},
  urgency: {},
  taskAttachments: [],
  dueDate: '',
};

export const PublishTaskPopup = ({
  teamList,
  closePopupHandler,
  activePost,
  closeMenuHandler,
  projectId,
}) => {
  const [fnWrapper, context] = useAxiosWrapper();
  const [publishTaskState, setPublishTaskState] = useState(DEFAULT_TASK_STATE);

  const onPublishTaskHandler = async () => {
    if (activePost) {
      const loggedInUser = tokenService.getSession();

      let formData = new FormData();
      formData.append('taskTitle', publishTaskState.taskTitle);
      formData.append('taskDescription', publishTaskState.taskDescription);
      formData.append('assignedToTeamId', publishTaskState.assignTo.id);
      formData.append('assignedTeamByUserId', loggedInUser.id);
      formData.append('createdByUserId', loggedInUser.id);
      formData.append('projectId', projectId);
      formData.append('postId', activePost);
      formData.append('urgency', publishTaskState.urgency.value);
      formData.append('dueDate', publishTaskState.dueDate);

      for (var i of publishTaskState.taskAttachments) {
        formData.append('attachments', i);
      }

      const res = await fnWrapper({
        url: CREATE_PUBLISH_TASK,
        method: 'POST',
        payload: formData,
        type: 'CREATE_PUBLISH_TASK',
      });

      if (res.data.statusCode === 200) {
        closeMenuHandler(activePost);
        closePopupHandler(false);
      }
    }
  };

  const isValidTaskAttachmentFile = (file) => {
    return TASK_ATTACHMENTS_TYPES.includes(file.type)
      ? true
      : toast.error(`${file.type} is not a valid file`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
  };

  const onChangeHandler = (field = '', value = null) => {
    if (field === 'taskAttachments') {
      Array.from(value).forEach((ele) => {
        if (isValidTaskAttachmentFile(ele)) {
          setPublishTaskState({
            ...publishTaskState,
            taskAttachments: [
              ...publishTaskState.taskAttachments,
              ...Array.from(value),
            ],
          });
        }
      });
    } else {
      setPublishTaskState({
        ...publishTaskState,
        [field]: value,
      });
    }
  };

  const removeAttachmentsHandler = (file) => {
    setPublishTaskState({
      ...publishTaskState,
      taskAttachments: publishTaskState.taskAttachments.filter(
        (e) => e !== file
      ),
    });
  };

  const publishDisabled =
    publishTaskState.taskTitle === '' ||
    Object.keys(publishTaskState.assignTo).length === 0;

  return (
    <div className="create-task-container">
      <ScrollableDiv
        scrollOnHeight="520px"
        scrollOnHeightMob="calc(100vh - 110px)">
        <div className="row">
          <div className="col-lg-12">
            <Input
              label="Task Title"
              type="text"
              required
              value={publishTaskState.taskTitle}
              onChange={(value) =>
                onChangeHandler('taskTitle', value.target.value)
              }
            />
          </div>
          <div className="col-lg-12">
            <Textarea
              label="Task Description"
              value={publishTaskState.taskDescription}
              onChange={(value) =>
                onChangeHandler('taskDescription', value.target.value)
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <DropDown
              label="Assign to"
              placeholder="Select Team Name"
              objectKey="teamName"
              required
              items={teamList}
              onClick={(e) => onChangeHandler('assignTo', e)}
              value={publishTaskState.assignTo}
            />
          </div>
          <div className="col-lg-6">
            <DropDown
              label="Urgency"
              placeholder="Select"
              objectKey="label"
              required
              items={TASK_URGENCY_LIST}
              onClick={(e) => onChangeHandler('urgency', e)}
              value={publishTaskState.urgency}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 task-due-date-wrapper">
            <label className="task-due-date-label form-label">
              Due Date
              <span style={{ color: '#FF1E26' }}>*</span>
            </label>
            <ReactDatePicker
              className="task-due-date"
              dateFormat="dd/MM/yyyy"
              onChange={(date) => onChangeHandler('dueDate', date)}
              minDate={new Date()}
              placeholderText="Select due date"
              selectsStart
              selected={publishTaskState.dueDate}
            />
          </div>
        </div>
        <Box display="flex" alignItems="baseline" mt="32px">
          <Box mr="20px">Add Attachments</Box>
          <CustomFileUploadButton
            borderWidth="0px 0px 1px 0px"
            borderStyle="solid"
            borderColor="#219FFF"
            background="none"
            fontFamily="'Poppins', 'Open Sans'"
            fontSize="15px"
            fontWeight="500"
            textColor="#219FFF"
            handleFile={(file) => onChangeHandler('taskAttachments', file)}
            label="Add"
            iconColor="#219FFF"
            iconName="add"
            iconSize="18"
            isMultiple={true}
          />
        </Box>
        <Box mt="20px" mb="40px" className="align-attachments">
          {publishTaskState.taskAttachments.length
            ? publishTaskState.taskAttachments.map((file, index) => (
                <div className="file-text" key={index}>
                  {file.name}
                  <span
                    className="remove-icon"
                    onClick={() => removeAttachmentsHandler(file)}>
                    <Icon color="rgba(146, 157, 175, 1)" name="small-close" />
                  </span>
                </div>
              ))
            : null}
        </Box>
        <div className="publish-task">
          <Box width="110px">
            <CustomButton
              label="Publish"
              disabled={publishDisabled}
              onClick={onPublishTaskHandler}
              color="themeSecondary"
              className="w-100"
              loading={context.busy.status}
            />
          </Box>
        </div>
      </ScrollableDiv>
    </div>
  );
};
