import styled from 'styled-components';

export const DocCardWrapper = styled.div`
  margin: 20px 24px 12px 0;
  & .doc-link {
    color: black;
    font-size: 14px;
    margin-left: 8px;
  }
`;
