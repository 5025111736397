import { Box, CustomButton, ModalPopup } from 'components';
import './withdraw.scss';
import ProfileDropDown from '../../../../components/profileDropdown/index';
import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const WithDrawTeams = ({
  isPopupOpen = false,
  closePopupHandler,
  withDrawStep,
  withDrawTeamsHandler,
  selectedRows = [],
  setWithdrawStep,
}) => {
  const [teamForAssign, setTeamForAssign] = useState([]);
  const [selectedAdmin, setSelectedAdmin] = useState({});
  const [loading, setLoading] = useState(false);

  const selectedTeam = selectedRows.map((ele) => ele.currentTeamName);

  const proceedToWithdrawTeam = async () => {
    const alreadyLeft = selectedRows.filter(
      (ele) => ele.currentMemberStatus !== '4'
    );
    if (alreadyLeft.length !== 0) {
      toast.error(
        `Either you have not accepted invitation for ${selectedTeam.toString()} ${
          selectedTeam.length > 1 ? 'teams' : 'team'
        } or you already left!`,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        }
      );
      closePopupHandler();
    } else {
      const x = selectedRows.filter((ele) => ele.currentMemberRole === 'ADMIN');
      if (x.length) {
        let singleMember = x.filter(
          (item) =>
            item.team_members.length === 1 &&
            item.team_members[0].id === item.currentMemberId
        );
        if (singleMember.length !== 0) {
          await withDrawTeamsHandler();
        } else {
          let leftAlone = x.filter((item) => {
            const otherMembers = item.team_members.filter(
              (mem) => mem.id !== item.currentMemberId
            );
            return otherMembers.every((member) => member.status !== '4');
          });
          if (leftAlone.length !== 0) {
            await withDrawTeamsHandler();
          } else {
            setWithdrawStep(2);
            setTeamForAssign(x);
          }
        }
      } else {
        await withDrawTeamsHandler();
      }
    }
  };

  const onSelectMemberHandler = (data, teamId) => {
    setSelectedAdmin({ ...selectedAdmin, [teamId]: data });
  };

  const leaveTeamWithAssignHandler = async () => {
    const session = JSON.parse(localStorage.getItem('session'));
    let y = Object.values(selectedAdmin).map((ele) => {
      let memberId = ele.memberId;
      return {
        memberId,
        role: 'ADMIN',
      };
    });
    let data = {
      items: y,
    };
    setLoading(true);
    try {
      await axios
        .put(`${process.env.REACT_APP_API_BASE_URL}/changeRole`, data, {
          headers: {
            'x-access-token': `${session.access_token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          if (res.data.statusCode === 200) {
            withDrawTeamsHandler();
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        });
    } catch (error) {
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="withdraw-teams">
      {isPopupOpen && withDrawStep === 1 && (
        <ModalPopup open={false} hasTitle={false}>
          <Box display="flex" justifyContent="center">
            <div className="withdraw-popup-layout">
              <Box mb="30px">
                <span>
                  Are you sure you want to Leave{' '}
                  <strong>{`${selectedTeam.toString()}`}</strong> Team?
                </span>
              </Box>
              <div className="withdraw-button">
                <Box width="150px">
                  <CustomButton
                    label="Yes"
                    onClick={proceedToWithdrawTeam}
                    variant="outline"
                    className="w-100"
                  />
                </Box>
                <Box width="150px">
                  <CustomButton
                    label="No"
                    onClick={closePopupHandler}
                    variant="textBtn"
                    className="w-100"
                  />
                </Box>
              </div>
            </div>
          </Box>
        </ModalPopup>
      )}
      {isPopupOpen && withDrawStep === 2 && (
        <ModalPopup open={false} onClose={closePopupHandler}>
          <div className="withdraw-popup-layout">
            {teamForAssign.length &&
              teamForAssign.map((item) => {
                let remainingMembers = item.team_members.filter(
                  (ele) => ele.id !== item.currentMemberId && ele.status === '4'
                );
                let userlist = remainingMembers.map((ele) => {
                  return {
                    name: ele.memberName,
                    memberId: ele.id,
                  };
                });
                return (
                  <Box key={item.id}>
                    <Box mb="30px">
                      <span>
                        Looks like you are the admin of{' '}
                        <strong>{`${item.team_name.props.label}`}</strong> Team.
                        Please select another admin before leaving
                      </span>
                    </Box>
                    <Box mt="30px" mb="30px" p="20px">
                      <ProfileDropDown
                        list={userlist}
                        selected={selectedAdmin[item.id]}
                        onClick={(index) =>
                          onSelectMemberHandler(userlist[index], item.id)
                        }
                      />
                    </Box>
                  </Box>
                );
              })}
            <div className="withdraw-button">
              <Box width="150px">
                <CustomButton
                  label="Leave"
                  disabled={Object.keys(selectedAdmin).length === 0}
                  onClick={() => leaveTeamWithAssignHandler()}
                  className="w-100"
                  loading={loading}
                />
              </Box>
            </div>
          </div>
        </ModalPopup>
      )}
    </div>
  );
};

export default WithDrawTeams;
