import { Box, CustomButton, DropDown, Input, Textarea } from 'components';
import { onChangeGroupCredential } from 'context/Groups/CreateGroup/createGroupAction';
import { CreateGroupContext } from 'context/Groups/CreateGroup/createGroupContext';
import { checkForSpacesInStartEnd } from 'helpers';
import RadioGroup from 'pages/IndividualDashboard/components/radioGroup';
import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { groupCategories } from '../../../../constants/constants';

export default function Information() {
  const navigate = useNavigate();
  const params = useParams();
  const GROUP_TYPE_OPTIONS = [
    {
      text: 'Public',
      value: 'PUBLIC',
    },
    { text: 'Private', value: 'PRIVATE' },
  ];

  const { createGroupState, createGroupDispatch } =
    useContext(CreateGroupContext);

  let disabled =
    !createGroupState.groupName ||
    checkForSpacesInStartEnd(createGroupState.groupName) ||
    !createGroupState.description ||
    checkForSpacesInStartEnd(createGroupState.description) ||
    !createGroupState.category ||
    !createGroupState.groupType;
  return (
    <InformationWrapper>
      <Box mt={'32px'}>
        <div>
          <div className="row">
            <div className="col-lg-4">
              <Input
                value={createGroupState.groupName}
                label="Group Name"
                onChange={(e) =>
                  onChangeGroupCredential(
                    createGroupDispatch,
                    'groupName',
                    e.target.value
                  )
                }
                type="text"
                required
                placeholder={'Enter group name'}
              />
            </div>
            <div className="col-lg-4">
              <DropDown
                items={groupCategories}
                objectKey="label"
                value={createGroupState.category}
                onClick={(e) => {
                  onChangeGroupCredential(createGroupDispatch, 'category', e);
                }}
                label="Category"
                required
                placeholder={'Select category'}
              />
            </div>
            <div className="col-lg-4">
              <p className="group-type-text">
                Group Type<span className="red-star">*</span>
              </p>
              <RadioGroup
                currChecked={createGroupState.groupType}
                handleChange={(e) =>
                  onChangeGroupCredential(
                    createGroupDispatch,
                    'groupType',
                    e.target.value
                  )
                }
                labels={GROUP_TYPE_OPTIONS}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <Textarea
                onChange={(e) =>
                  onChangeGroupCredential(
                    createGroupDispatch,
                    'description',
                    e.target.value
                  )
                }
                value={createGroupState.description}
                label={'Description'}
                required
              />
            </div>
          </div>
          <div className="continue-button">
            <CustomButton
              customPadding="16px 38px"
              color={'themeSecondary'}
              disabled={disabled}
              label="Continue"
              onClick={() =>
                navigate(
                  `/individual/profile/groups/${params.action}/profile-picture`
                )
              }
            />
          </div>
        </div>
      </Box>
    </InformationWrapper>
  );
}

const InformationWrapper = styled.div`
  .continue-button {
    margin-top: 32px;
    display: flex;
    justify-content: center;
  }
  .group-type-text {
    font-weight: 600;
    font-size: 14px;
    color: #404f67;
  }
  .red-star {
    color: red;
  }
`;
