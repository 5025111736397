import React from 'react';
import { EnterprisePublicCardWrapper } from './style';
import Icon from 'utlils/Icons/Icon';
import { CustomButton } from 'components';

export const EnterprisePublicCard = ({
  profileCardButtons,
  infoItems,
  userProfile,
}) => {
  const info = {
    enterpriseCategory: userProfile?.enterpriseCategory?.title,
    type: userProfile?.enterpriseType?.title,
    enterpriseName: userProfile?.enterpriseName,
    profilePicture: userProfile?.EnterpriseProfilePicture,
    id: userProfile?.id,
  };
  return (
    <EnterprisePublicCardWrapper numBtns={profileCardButtons.length}>
      <div className="upper-image-container" />
      <div className="content-container">
        <div className="sub-container">
          <div className="level-wrapper">
            <div className="level--sub-wrapper">
              <div className="profile-img-wrapper">
                {info?.profilePicture ? (
                  <img alt="profile" src={info?.profilePicture} />
                ) : (
                  <div className="profile-icon">
                    <Icon name={'enterprise-two'} color="#404F67" size={40} />
                  </div>
                )}
              </div>
              <div className="level-btn">
                <span>Enterprise</span>
              </div>
            </div>
          </div>
        </div>
        <div className="sub-container">
          <div className="info-wrapper">
            <div className="upper-half">
              <div className="title-class">
                <div className="title-name">{info?.enterpriseName}</div>
                <div className="sub-title-wrapper">
                  <div className="sub-title-name">
                    {info?.enterpriseCategory}
                  </div>
                </div>
              </div>
              <div
                className={
                  profileCardButtons.length === 1 ? '' : 'buttons-wrapper'
                }>
                {profileCardButtons?.map((btn, index) => (
                  <div style={{ width: '100%' }} key={index}>
                    <CustomButton
                      key={index}
                      color={btn.color}
                      variant={btn.variant}
                      onClick={btn.onClick}
                      className="w-100">
                      {btn.label}
                      <Icon
                        name={btn.iconName}
                        size={18}
                        color={btn.fontColor}
                      />
                    </CustomButton>
                  </div>
                ))}
              </div>
            </div>
            <div className="lower-half">
              {infoItems?.map((item, index) => (
                <div className="info-item" key={index}>
                  <Icon
                    name={item.iconName}
                    color={item.iconColor ? item.iconColor : '#929DAF'}
                  />
                  <div
                    onClick={item.onClick}
                    style={item.style ? item.style : null}>
                    {item.value}
                  </div>
                </div>
              ))}
              <div className="info-item">
                <Icon name="building" color="#929DAF" />
                <span>{info?.type}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </EnterprisePublicCardWrapper>
  );
};
