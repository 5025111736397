import styled from 'styled-components';

export const RequestNetworkWrapperStyle = styled.div`
  & .request-network {
    &__table {
      margin-top: 40px;
      &__row {
        padding: 25px 0;
        border-bottom: 1px solid #929daf;
        &:last-child {
          border: none;
        }
        &__footer--button {
          display: flex;
          align-items: center;
          gap: 30px;
          @media (max-width: 992px) {
            margin-top: 30px;
            width: 100%;
            justify-content: center;
            align-items: center;
            gap: 10px;
          }
        }
      }
    }
  }
  & .outline-request-card {
    text-decoration: underline;
    text-underline-offset: 6px;
    text-decoration-thickness: 1px;
  }
`;
