import PropTypes from 'prop-types';
import { ProfilePhotoStyle } from './style';
import { getProfileText } from 'utlils/utils';

export const ProfilePhoto = ({ profilePicture, name = '', size = 50 }) => {
  return (
    <ProfilePhotoStyle size={size}>
      {profilePicture ? (
        <img
          src={profilePicture}
          alt="profileImage"
          width="100%"
          height="100%"
          className="profile-img"
        />
      ) : (
        <div className="profile-text">{`${getProfileText(name)}`}</div>
      )}
    </ProfilePhotoStyle>
  );
};

ProfilePhoto.propTypes = {
  profilePicture: PropTypes.string,
  name: PropTypes.string.isRequired,
  size: PropTypes.number,
};
