import styled from 'styled-components';

export const CreateEnterpriseProfileWrapper = styled.div`
  & .profile-edit {
    border-radius: 100%;
    background-color: #0abd00;
    padding: 9px 12px;
    cursor: pointer;

    svg {
      margin-bottom: 2px;
    }
  }
  & .label-style-2 {
    font-weight: 600;
    font-size: 16px;
    color: #404f67;
  }
  .enterprise-detail-container {
    min-height: 100vh;
    background-color: #f4f7fc;
    .layout-container {
      padding: 80px 8% 20px 8%;
      .layout-sub-container {
        display: grid;
        grid-template-columns: minmax(250px, 300px) auto;
        grid-gap: 40px;
        @media (max-width: 1199px) {
          display: block;
        }
        .sub-container-child {
          @media (max-width: 1199px) {
            margin: 25px 0;
          }
          .main-wrapper {
            border-radius: 12px;
            padding: 30px;
            @media (max-width: 992px) {
              padding: 15px;
            }
          }
        }
      }
      @media (max-width: 1600px) {
        padding: 80px 6% 20px 6%;
      }
      @media (max-width: 992px) {
        padding: 80px 4% 20px 4%;
      }
    }
  }
`;
