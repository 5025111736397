import NoDataImg from '../../../../../../assests/images/nodata.png';
import { NoDataWrapper } from './style';

const NoData = () => {
  return (
    <NoDataWrapper>
      <div className="empty-data-section">
        <img
          src={NoDataImg}
          alt="No Data"
          className="empty-data-section--img"
        />
        <div className="empty-data-section--text">
          There is nothing to display at this moment. It seems that no files
          have been uploaded yet.
        </div>
      </div>
    </NoDataWrapper>
  );
};

export default NoData;
