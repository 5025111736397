import styled from 'styled-components';

export const RequestsListWrapper = styled.div`
  padding: 30px 0;
  & .request-item {
    display: flex;
    justify-content: space-between;
    padding: 12px 4px;
    & .left-side-class {
      display: flex;
      align-items: center;
      gap: 10px;

      & .profile-pic-container,
      .profile-pic-container img {
        width: 45px;
        height: 45px;
        object-fit: cover;
        background: #d0e5ff;
        border-radius: 50%;
        & .pfp-alt-circle {
          border-radius: 50%;
          width: 45px;
          height: 45px;
          background-color: #d6f3f9;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        & .user-icon-class {
          margin-top: 10px;
          margin-left: 8px;
        }
      }

      & .user-name {
        font-family: 'Poppins';
        font-style: normal;
        font-size: 16px;
        color: #040d20;

        & .user-name-style {
          margin-bottom: 1px;

          & .admin-style {
            font-weight: 600;
          }

          & .stage-style {
            font-weight: 600;
            color: #219fff;
          }
        }

        & .user-posted-time {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          padding-top: 0px;
          color: #929daf;
        }
      }

      @media (max-width: 767px) {
        align-items: flex-start;
        flex-direction: column;
      }
    }
    & .right-side-class {
      display: flex;
      justify-content: center;
      align-items: center;
      & .border-btn {
        padding: 5px 20px 5px 0;
        margin-right: 14px;
        border-right: 1px solid #c4c4c4;
      }
      & .underline-btn {
        text-decoration: underline;
      }
      @media (max-width: 890px) {
        margin-top: 20px;
        justify-content: flex-end;
      }
    }
    @media (max-width: 890px) {
      flex-direction: column;
    }
  }
  & .join-popup-wrapper {
    position: relative;
    padding: 50px 0;
    & .cross-button {
      position: absolute;
      top: 20px;
      cursor: pointer;
      right: 20px;
    }
    & .join-img {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & .join-text {
      margin-top: 30px;
      margin-bottom: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;
      font-size: 18px;
    }
    & .join-okay-btn {
      width: 180px;
      margin: 0 auto;
    }
  }
  & .no-data {
    & .no-data-img {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & .no-data-text {
      margin: 50px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 18px;
    }
  }
  & .deny-popup-wrapper {
    position: relative;
    padding: 50px 0;
    & .cross-button {
      position: absolute;
      top: 20px;
      cursor: pointer;
      right: 20px;
    }
    & .deny-buttons {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }
  }
`;
