import { CustomButton, ModalPopup, ScrollableDiv, Textarea } from 'components';
import RadioGroup from 'pages/IndividualDashboard/components/radioGroup';
import {
  CREATE_PROJECT_BIDDING,
  UPDATE_BIDDING,
  useAxiosWrapper,
} from 'services';
import {
  biddingCategory,
  biddingProjectAquisitionFAQ,
  biddingProjectCopyFAQ,
  biddingSubscriptionFAQ,
  biddingTypes,
  serviceFrequency,
} from '../../../ProjectCollaboration/constant';
import { useCallback, useState } from 'react';
import { ProjectCopy } from './ProjectCopy';
import { ProjectServiceSubscription } from './ProjectServiceSubscription';
import { CommonFieldSection } from './CommonFieldSection';
import { startCase } from 'lodash';
import Icon from 'utlils/Icons/Icon';
import ProjectAquisition from './ProjectAquisition';
import { arrayKeyValueReducer } from 'pages/TeamDashboard/TeamCareer/helper';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

export const CreateBidding = (props) => {
  const { biddingActionState, projectId, onClose, getBiddingProjectsList } =
    props;
  const [fnWrapper, context] = useAxiosWrapper();
  const { type, data } = biddingActionState;
  const isUpdate = type === 'EDIT-BIDDING';
  const [step, setStep] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const questionData =
    isUpdate && data?.questionsToBidder ? data?.questionsToBidder : null;

  const bidFaqData =
    isUpdate && data?.bidTypeFAQ ? JSON.parse(data?.bidTypeFAQ) : {};

  const questionArray = questionData
    ? Object.values(questionData).map((item) => ({ question: item }))
    : [
        {
          question: '',
        },
      ];

  const isApplicant = data !== null && data?.biddingApplicants.length > 0;
  const isDisabled = isUpdate && isApplicant;

  const biddingForms =
    isUpdate && data?.signedDocuments?.length
      ? data?.signedDocuments?.map((item) => {
          const parsedItem = JSON.parse(item);
          return {
            files: parsedItem?.location,
            isMandatory: parsedItem?.isMandatory,
          };
        })
      : [];

  const [createBiddingState, setCreateBiddingState] = useState({
    description: isUpdate ? data?.biddingDescription : '',
    offerings: isUpdate ? data?.biddingOfferings : '',
    type: isUpdate ? data?.biddingType : null,
    category: isUpdate ? data?.biddingCategory : null,
    paymentType: isUpdate ? data?.paymentType : null,
    questions: questionArray,
    documents:
      isUpdate && data?.rulesAndDocuments?.length
        ? data?.rulesAndDocuments
        : [],
    bidderForms: biddingForms,
    biddingRateType: isUpdate ? data.bidRateType : null,
    fixedRate:
      isUpdate && data?.bidRateType === 'Fixed rate'
        ? { currency: data?.currencyType, value: Number(data?.fixedBidRate) }
        : { currency: 'USD', value: 0 },
    rangeRate:
      isUpdate && data?.bidRateType === 'Bidding range'
        ? {
            minRange: Number(data?.minBidRange),
            maxRange: Number(data?.maxBidRange),
            currency: data?.currencyType,
          }
        : {
            minRange: null,
            maxRange: null,
            currency: 'USD',
          },
    serviceFrequency: isUpdate
      ? serviceFrequency.find((item) => item.value === data?.serviceFrequency)
      : null,
    videoAttachment:
      isUpdate && data?.videoAttachment?.length ? [data?.videoAttachment] : [],
    projectLastUpdatedDate: isUpdate
      ? new Date(data?.projectLastUpdatedDate)
      : null,
    biddingFaq: bidFaqData,
  });

  const onContinueBtnHandler = () => {
    setCreateBiddingState((prevState) => ({
      ...prevState,
      biddingFaq:
        createBiddingState.type === 'PROJECT_COPY'
          ? isUpdate
            ? bidFaqData
            : biddingProjectCopyFAQ
          : createBiddingState.type === 'PROJECT_SERVICE_SUBSCRIPTION'
          ? isUpdate
            ? bidFaqData
            : biddingSubscriptionFAQ
          : createBiddingState.type === 'PROJECT_ACQUISTION'
          ? isUpdate
            ? bidFaqData
            : biddingProjectAquisitionFAQ
          : {},
    }));
    setStep(createBiddingState.type);
  };

  const onChangeHandler = (fieldName, value) => {
    setCreateBiddingState((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  let isExistFAQAns = Object.values(createBiddingState.biddingFaq).filter(
    (ele) => {
      return ele === '';
    }
  );

  const isDisabledForRules =
    isExistFAQAns.length > 1 ||
    !createBiddingState.paymentType ||
    !createBiddingState.biddingRateType ||
    (createBiddingState.biddingRateType === 'Fixed rate' &&
      createBiddingState.fixedRate.value === 0) ||
    (createBiddingState.biddingRateType === 'Bidding range' &&
      !createBiddingState.rangeRate.minRange &&
      !createBiddingState.rangeRate.maxRange);

  const isDisabledContinueBtn =
    !createBiddingState.description ||
    !createBiddingState.offerings ||
    !createBiddingState.type;

  const onUpdateBidding = () => {
    setShowConfirm(true);
  };

  const onCreateBidding = useCallback(async () => {
    const questionArray =
      createBiddingState.questions[0].question !== ''
        ? arrayKeyValueReducer(createBiddingState.questions, 'question')
        : null;

    const bidderFormsData =
      createBiddingState.bidderForms.length !== 0
        ? createBiddingState.bidderForms
            .filter((item) => typeof item.files === 'object')
            .map((item) => ({
              fileName: item?.files?.name,
              isMandatory: item?.isMandatory,
            }))
        : [];

    let biddingRateType =
      createBiddingState.biddingRateType === 'Fixed rate'
        ? createBiddingState.fixedRate
        : createBiddingState.rangeRate;

    let formData = new FormData();
    formData.append('biddingDescription', createBiddingState.description);
    formData.append('biddingOfferings', createBiddingState.offerings);
    if (!isUpdate) {
      formData.append('biddingType', createBiddingState.type);
    }
    formData.append('biddingCategory', createBiddingState.category);
    formData.append('paymentType', createBiddingState.paymentType);
    if (createBiddingState.type === 'PROJECT_COPY') {
      formData.append(
        'projectLastUpdatedDate',
        dayjs(createBiddingState.projectLastUpdatedDate).format('YYYY-MM-DD')
      );
    }
    if (createBiddingState.type === 'PROJECT_SERVICE_SUBSCRIPTION') {
      formData.append(
        'serviceFrequency',
        createBiddingState.serviceFrequency.value
      );
    }
    formData.append('questionsToBidder', questionArray);
    formData.append('projectId', projectId);
    formData.append('signedDocumentsData', JSON.stringify(bidderFormsData));
    for (let i of createBiddingState.bidderForms) {
      formData.append('signedDocuments', i?.files);
    }
    for (let i of createBiddingState.documents) {
      formData.append('rulesAndDocuments', i);
    }

    for (let i of createBiddingState.videoAttachment) {
      formData.append('videoAttachment', i);
    }
    formData.append('isOpen', true);
    formData.append(
      'bidTypeFAQ',
      JSON.stringify(createBiddingState.biddingFaq)
    );
    if (createBiddingState?.biddingRateType === 'Fixed rate') {
      formData.append('fixedBidRate', biddingRateType?.value);
    }
    if (createBiddingState?.biddingRateType === 'Bidding range') {
      formData.append('minBidRange', createBiddingState?.rangeRate?.minRange);
      formData.append('maxBidRange', createBiddingState?.rangeRate?.maxRange);
    }
    formData.append('currencyType', biddingRateType?.currency);
    formData.append('bidRateType', createBiddingState?.biddingRateType);
    if (isUpdate) {
      formData.append('biddingId', data?.id);
      const linkSignedDocuments = createBiddingState.bidderForms?.filter(
        (item) => typeof item?.files === 'string'
      );
      const linkRulesAndDocuments = createBiddingState.documents?.filter(
        (item) => typeof item === 'string'
      );
      const linkVideoAttachment = createBiddingState.videoAttachment?.filter(
        (item) => typeof item === 'string'
      );
      formData.append(
        'linkSignedDocuments',
        JSON.stringify(linkSignedDocuments)
      );
      formData.append(
        'linkRulesAndDocuments',
        JSON.stringify(linkRulesAndDocuments)
      );
      formData.append(
        'linkVideoAttachment',
        JSON.stringify(linkVideoAttachment)
      );
    }

    const res = await fnWrapper({
      url: isUpdate ? UPDATE_BIDDING : CREATE_PROJECT_BIDDING,
      method: isUpdate ? 'PUT' : 'POST',
      type: isUpdate ? 'UPDATE_BIDDING' : 'CREATE_PROJECT_BIDDING',
      payload: formData,
    });

    if (res.data.statusCode === 200) {
      await getBiddingProjectsList();
      await onClose();
      toast.success(res.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createBiddingState, projectId]);

  return (
    <>
      {biddingActionState?.isModalOpen ? (
        <ModalPopup
          open={false}
          onClose={onClose}
          minWidth="1000px"
          maxContentWidth="1000px"
          hasTitle
          title={
            <>
              {step ? (
                <Icon
                  className="back-button"
                  name="arrow-back"
                  color={'#7E8F9A'}
                  size={28}
                  style={{
                    position: 'relative',
                    top: '-1px',
                    left: '-5px',
                    cursor: 'pointer',
                  }}
                  onClick={() => setStep(null)}
                />
              ) : null}
              {step ? 'Set rules' : isUpdate ? 'Edit bidding' : 'New bidding'}
            </>
          }>
          <div className="publish_wrp">
            <ScrollableDiv
              scrollOnHeight="calc(100vh - 210px)"
              scrollOnHeightMob="300px">
              {step === null ? (
                <>
                  <div className="publish-checkbox">
                    <Textarea
                      rows={4}
                      value={createBiddingState?.description}
                      label={'1. Describe the bididing'}
                      required
                      onChange={(e) =>
                        onChangeHandler('description', e.target.value)
                      }
                      disabled={data?.biddingApplicants?.length > 0}
                    />
                  </div>
                  <div className="publish-checkbox">
                    <Textarea
                      rows={4}
                      value={createBiddingState?.offerings}
                      label={'2. Offerings of this bidding'}
                      required
                      onChange={(e) =>
                        onChangeHandler('offerings', e.target.value)
                      }
                      disabled={data?.biddingApplicants?.length > 0}
                    />
                  </div>
                  <div className="publish-checkbox mb-text">
                    <div className="sub-heading">
                      3. Type of bidding <span style={{ color: 'red' }}>*</span>
                    </div>
                    <div className="radio-btn-label">
                      <RadioGroup
                        labels={biddingTypes}
                        currChecked={createBiddingState?.type}
                        handleChange={(e) =>
                          onChangeHandler('type', e.target.value)
                        }
                        disabled={isUpdate}
                      />
                    </div>
                  </div>
                  <div className="publish-checkbox mb-text">
                    <div className="sub-heading">4. Category of bidding</div>
                    <div className="radio-btn-label">
                      <RadioGroup
                        labels={biddingCategory}
                        currChecked={createBiddingState?.category}
                        handleChange={(e) =>
                          onChangeHandler('category', e.target.value)
                        }
                        disabled={data?.biddingApplicants?.length > 0}
                      />
                    </div>
                  </div>
                  <div className="popup-button">
                    <CustomButton
                      className="w-100"
                      label="Continue"
                      color="themeSecondary"
                      onClick={onContinueBtnHandler}
                      disabled={isDisabledContinueBtn}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="bidding-header mt-2 mb-4">
                    Set bidding order delivery rules for{' '}
                    <span className="bidding-header-fw">
                      {step
                        ? startCase(step.split('_').join(' ').toLowerCase())
                        : ''}
                    </span>
                  </div>
                  {step === 'PROJECT_COPY' && (
                    <ProjectCopy
                      state={createBiddingState}
                      setCreateBiddingState={setCreateBiddingState}
                      onChangeHandler={onChangeHandler}
                      isDisabled={isDisabled}
                    />
                  )}
                  {step === 'PROJECT_SERVICE_SUBSCRIPTION' && (
                    <ProjectServiceSubscription
                      state={createBiddingState}
                      setCreateBiddingState={setCreateBiddingState}
                      isDisabled={isDisabled}
                    />
                  )}
                  {step === 'PROJECT_ACQUISTION' && (
                    <ProjectAquisition
                      setCreateBiddingState={setCreateBiddingState}
                      state={createBiddingState}
                      isDisabled={isDisabled}
                    />
                  )}
                  <CommonFieldSection
                    onChangeHandler={onChangeHandler}
                    setCreateBiddingState={setCreateBiddingState}
                    state={createBiddingState}
                    isUpdate={isUpdate}
                    isDisabled={isDisabled}
                  />
                  <div className="popup-button mt-5 mb-4">
                    <CustomButton
                      className="w-100"
                      label="Save"
                      color="themeSecondary"
                      loading={
                        context.busy.status &&
                        context.busy.type === 'CREATE_PROJECT_BIDDING'
                      }
                      disabled={
                        (isUpdate && data.biddingApplicants.length > 0) ||
                        (createBiddingState.type === 'PROJECT_COPY'
                          ? isDisabledForRules ||
                            !createBiddingState.projectLastUpdatedDate
                          : createBiddingState.type ===
                            'PROJECT_SERVICE_SUBSCRIPTION'
                          ? isDisabledForRules ||
                            !createBiddingState.serviceFrequency
                          : createBiddingState.type === 'PROJECT_ACQUISTION'
                          ? isDisabledForRules
                          : false)
                      }
                      onClick={isUpdate ? onUpdateBidding : onCreateBidding}
                    />
                  </div>
                </>
              )}
            </ScrollableDiv>
            {showConfirm && (
              <ModalPopup
                open={false}
                onClose={() => setShowConfirm(false)}
                minWidth="800px"
                maxContentWidth="800px"
                hasTitle
                title="Edit Bidding">
                <div className="edit-wrapper">
                  <p className="edit-wrapper__text text-center mt-4">
                    Do you want to save these changes?
                  </p>
                  <div className="edit-wrapper__confirm-content d-flex justify-content-center">
                    <CustomButton
                      variant="textBtn"
                      label="No"
                      backgroundColor="transparent"
                      onClick={() => setShowConfirm(false)}
                    />
                    <div className="edit-wrapper__yes-btn">
                      <CustomButton
                        className="w-100"
                        label="Yes"
                        onClick={onCreateBidding}
                      />
                    </div>
                  </div>
                </div>
              </ModalPopup>
            )}
          </div>
        </ModalPopup>
      ) : null}
    </>
  );
};
