import { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAxiosWrapper } from 'services';
import { AppliedJobWrapper } from './style';
import { CustomTable, ScrollableDiv, ModalPopup } from 'components';
import {
  AppliedJobTableHeaders,
  appliedJobsTableData,
} from 'pages/Explore/constants';
import { getPlaceholderItems } from 'utlils/utils';
import { FETCH_APPLIED_JOBS_BASED_ON_USERID } from 'services';
import TokenService from 'services/token.service';
import { ReviewApplication } from '../ReviewApplication';

export const AppliedJobs = () => {
  const [fnWrapper, context] = useAxiosWrapper();
  const [jobsData, setJobsData] = useState(null);
  const [reviewPopup, setReviewPopup] = useState(false);
  const [applicationDetails, setApplicationDetails] = useState(null);
  const navigate = useNavigate();
  const getAllAppliedJobs = useCallback(
    async () => {
      const loggedInUser = TokenService.getSession();
      const res = await fnWrapper({
        url: `${FETCH_APPLIED_JOBS_BASED_ON_USERID}/${loggedInUser.id}`,
        method: 'GET',
        type: 'FETCH_APPLIED_JOBS_BASED_ON_USERID',
      });
      if (res.data.statusCode === 200) {
        setJobsData(res.data.data);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const getReviewApplicationDetails = (item = []) => {
    setReviewPopup(!reviewPopup);
    setApplicationDetails(item);
  };

  useEffect(() => {
    getAllAppliedJobs();
  }, [getAllAppliedJobs]);

  const appliedJobsData = context.busy.status
    ? getPlaceholderItems(
        AppliedJobTableHeaders.map(({ sortKey }) => sortKey),
        5,
        context.busy.status
      )
    : appliedJobsTableData({
        data: jobsData,
        getReviewApplicationDetails: getReviewApplicationDetails,
      });

  return (
    <AppliedJobWrapper>
      <div className="main-jobs-wrapper">
        <div className="heading">
          <h5 className="jobs-count">Applied Jobs</h5>
        </div>
        {appliedJobsData?.length >= 1 ? (
          <div>
            <ScrollableDiv scrollOnHeight="420px" scrollOnHeightMob="300px">
              <CustomTable
                isLoading={context.busy.status}
                rows={appliedJobsData}
                headers={AppliedJobTableHeaders}
              />
            </ScrollableDiv>
            {reviewPopup && (
              <ModalPopup
                title="Review Application"
                open={false}
                onClose={() => setReviewPopup(false)}
                maxContentWidth={'750px'}>
                <ReviewApplication applicationDetails={applicationDetails} />
              </ModalPopup>
            )}
          </div>
        ) : (
          <div className="no-jobs-message">
            {'OOPS! looks like you have not applied for any jobs yet '}
            <span
              className="click-here"
              onClick={() =>
                navigate(`/explore/jobs/search-job`, { replace: true })
              }>
              {'Click here '}
            </span>
            {'to explore more jobs'}
          </div>
        )}
      </div>
    </AppliedJobWrapper>
  );
};
