import { createContext, useReducer } from 'react';
import { CreateProjectReducer } from './createProjectReducer';

// Declaring the state object globally.
export const initialState = {
  category: {},
  projectTitle: '',
  problemStatement: '',
  solution: '',
  link: '',
  selectedProject: {},
  linkedProjectId: '',
  selectedTeams: [],
  linkedTeams: [],
  deletedTeams: [],
  attachments: [],
  linkAttachments: [],
  projectAvatar: null,
  editStreamId: '',
  stream: [],
  isEdited: false,
  loading: false,
  progressStep: 1,
};

export const CreateProjectContext = createContext({
  createProjectState: initialState,
  createProjectDispatch: () => null,
});

export const CreateProjectProvider = ({ children }) => {
  const [createProjectState, createProjectDispatch] = useReducer(
    CreateProjectReducer,
    initialState
  );

  return (
    <CreateProjectContext.Provider
      value={{
        createProjectState,
        createProjectDispatch,
      }}>
      {children}
    </CreateProjectContext.Provider>
  );
};
