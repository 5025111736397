import styled from 'styled-components';

export const ViewMediaWrapper = styled.div`
  & .main-content {
    margin-top: 24px;
  }
  & .media-list {
    flex-shrink: 0;
    flex-flow: row wrap;
    display: flex;
  }
  & .month-label {
    font-size: 14px;
    font-weight: 500;
    color: #9a9c9b;
  }
`;
