import styled from 'styled-components';

export const RecievedRequestsWrapper = styled.div`
  height: 400px;
  .request-popup {
    padding: 16px 0px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .question {
      font-size: 15px;
      font-weight: 500;
    }
    .answer {
      background-color: #e7eef9;
      padding: 16px 16px 16px 8px;
      border-radius: 10px;
      font-size: 13px;
    }
  }
  .modal-btns-container {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    justify-content: center;
    align-items: center;
    padding-top: 6px;
    height: 40px;
    .modal-btn {
      width: 45%;
    }
  }
  .request-card-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-wrap: wrap;
  }
`;
