import styled from 'styled-components';

export const PermissionWrapper = styled.div`
  font-family: Poppins;
  letter-spacing: 0em;
  text-align: left;
  .department-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 10px;
    .department-name {
      font-size: 25px;
      font-weight: 700;
      line-height: 38px;
    }
  }
  .permissions-disclaimer {
    padding: 12px;
    background-color: #f4f7fc;
    font-weight: 400;
    line-height: 24px;
    .diclaimer-info {
      font-size: 16px;
      color: #404f67;
    }
    .disclaimer-text {
      margin-top: 4px;
      font-size: 14px;
      color: #929daf;
    }
  }
  .section-cards-list {
    margin: 10px 0px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;
