import { FETCH_DEPARTMENT_LIST, FETCH_PROJECTS_LIST } from 'services';
import tokenService from 'services/token.service';

export const getDepartmentsList = async (fnWrapper) => {
  const loggedInUser = tokenService.getSession();
  let list = [];
  const res = await fnWrapper({
    url: `${FETCH_DEPARTMENT_LIST}/${loggedInUser?.id}`,
    method: 'GET',
    type: 'FETCH_DEPARTMENT',
  });
  if (res?.data?.statusCode === 200) {
    res?.data?.data?.items?.map((item) =>
      list.push({
        id: item.id,
        label: item.departmentName,
        value: item.departmentName,
      })
    );
    return list;
  }
  return [];
};

export const getProjectList = async (fnWrapper, selectedDepartment) => {
  const loggedInUser = tokenService.getSession();
  const customId = selectedDepartment ? selectedDepartment.id : loggedInUser.id;
  const type = selectedDepartment ? 'enterprise' : 'personal';
  const url = `${FETCH_PROJECTS_LIST}/${customId}?type=${type}`;

  const res = await fnWrapper({
    url: url,
    method: 'GET',
    type: 'FETCH_PROJECTS_LIST',
  });

  if (res.data.statusCode === 200) {
    const projectTableList = [];
    const result = selectedDepartment
      ? res?.data?.data[0]?.projects
      : res?.data?.data;
    result.map((item) => {
      const newProjectData = {
        id: `${item.id}`,
        label: `${item.title}`,
        value: `${item.id}`,
        linkedTeams: `${item.teams.length}`,
        createdOn: `${item.createdAt}`,
        status: `${item.projectStatus}`,
      };
      projectTableList.push(newProjectData);
      return projectTableList;
    });
    return projectTableList;
  }
  return [];
};
