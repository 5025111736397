export const getFormattedDate = (date) => {
  if (date) {
    const options = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    return new Date(date).toLocaleDateString('en-US', options);
  }
  return 'N/A';
};
