import PfdLogo from '../../../../assests/images/pdf-logo.png';
import ImgLogo from '../../../../assests/images/img-logo.png';
import WordLogo from '../../../../assests/images/ms_word.png';
import ExcelLogo from '../../../../assests/images/ms_excel.png';
import AILogo from '../../../../assests/images/adobe_illustrator.png';
import VideoLogo from '../../../../assests/images/video.png';

export const breadCrumbListStorage = (list) => {
  localStorage.setItem('breadCrumb', JSON.stringify(list));
};

export const getBreadCrumbList = () => {
  let data = localStorage.getItem('breadCrumb');
  return JSON.parse(data);
};

export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0).toUpperCase() + string?.slice(1);
};

export const linkedTeamStatus = {
  1: {
    label: 'Pending',
    bgColor: 'transparent',
    textColor: '#fec313',
  },
  2: {
    label: 'Rejected',
    bgColor: 'transparent',
    textColor: '#ff1b1b',
  },
  3: {
    label: 'Accepted',
    bgColor: 'transparent',
    textColor: '#0ABD00',
  },
};

export const PROJECT_VIEW_TAB_LIST = [
  'Details',
  'Linked Teams',
  'Linked Projects',
  'Streams',
  'Collab/Bids',
];

export const LINKED_TEAM_LIST_HEADERS = [
  {
    title: 'Team Name',
    sortable: false,
    sortKey: 'teamName',
  },
  {
    title: 'Members',
    sortable: true,
    sortKey: 'members',
  },
  {
    title: 'Connected To',
    sortable: false,
    sortKey: 'connectedTo',
  },

  {
    title: 'Status',
    sortable: true,
    sortKey: 'status',
  },
];

export const LINKED_PROJECTS_LIST_HEADERS = [
  {
    title: 'Project Name',
    sortable: false,
    sortKey: 'projectName',
  },
  {
    title: 'Paid/Collaboration/Public',
    sortable: false,
    sortKey: 'paidCollabPublic',
  },
  {
    title: 'Connected To',
    sortable: false,
    sortKey: 'connectedTo',
  },

  {
    title: 'Status',
    sortable: false,
    sortKey: 'status',
  },
];

export const STREAM_STATUS = [
  { label: 'Local Stream', value: '0' },
  { label: 'Linked Stream', value: '1' },
];

export const checkForFileType = (elem) => {
  const type = elem.split('.');
  if (type[type.length - 1] === 'ai') return AILogo;
  else if (type[type.length - 1] === 'xlsx') return ExcelLogo;
  else if (type[type.length - 1] === 'doc' || type[type.length - 1] === 'docx')
    return WordLogo;
  else if (
    type[type.length - 1] === 'png' ||
    type[type.length - 1] === 'jpg' ||
    type[type.length - 1] === 'jpeg'
  )
    return ImgLogo;
  else if (type[type.length - 1] === 'pdf') return PfdLogo;
  else if (
    type[type.length - 1] === 'mp4' ||
    type[type.length - 1] === 'mpeg' ||
    type[type.length - 1] === 'quicktime' ||
    type[type.length - 1] === 'x-ms-wmv'
  )
    return VideoLogo;
  else return 'others';
};
