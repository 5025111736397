import { OnBoardPopUp } from './OnBoardPopUp';
import {
  CustomButton,
  CustomTable,
  Loader,
  ModalPopup,
  SearchInput,
} from 'components';
import React, { useCallback, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  UPDATE_SHORTLIST_CANDIDATE,
  FETCH_SHORTLISTED_APPLICANTS,
  UPDATE_CANDIDATE_CONTACTED_STATUS,
  SEND_INVITE_TO_SELECTED_JOB_APPLICANT,
  useAxiosWrapper,
} from 'services';
import Icon from 'utlils/Icons/Icon';
import noDataImg from 'assests/images/noProjects.png';
import AirPlane from 'assests/images/paper-plane.png';
import { SHORTLISTED_APPLICANT_LIST_TABLE_HEADERS } from '../constant';
import { getPlaceholderItems } from 'utlils/utils';
import { shortlistedApplicantListTableData } from '../helper';
import tokenService from 'services/token.service';
import { toast } from 'react-toastify';
import { ViewApplicant } from '../PostedJobs/ViewApplicant/viewApplicant';
import { useContext } from 'react';
import { RoleBasedAccessContext } from 'context/RoleBasedAccess/roleBasedAccessContext';
import { IsPermitted, userScopes } from 'utlils';
import styled from 'styled-components';

export const Shortlisted = () => {
  const { roleBasedAccessState } = useContext(RoleBasedAccessContext);

  const [fnWrapper, context] = useAxiosWrapper();
  const [applicantList, setApplicantList] = useState([]);
  const [viewApplicantPopup, setViewApplicantPopup] = useState({
    isModalOpen: false,
    data: null,
  });
  const [isOnboardingPopup, setIsOnboardingPopup] = useState(false);
  const [viewContactPopup, setViewContactPopup] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedApplicants, setSelectedApplicants] = useState([]);
  const [applicantsFromOnboard, setApplicantsFromOnboard] = useState([]);
  const [contactDetails, setContactDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  let pageSize = 6;
  const [searchParam, setSearchParam] = useSearchParams();
  const jobName = searchParam.get('jobName');
  const teamId = searchParam.get('teamId');
  const jobId = searchParam.get('jobId');

  const onRowCheckedHandler = (element) => {
    if (selectedRow.length) {
      const index = selectedRow.filter((ele) => ele.id === element.id);
      if (index.length) {
        const filteredItems = selectedRow.filter(
          (ele) => ele.id !== element.id
        );
        const filteredApplicants = selectedApplicants.filter(
          (appl) => appl.id !== element.id
        );
        setSelectedRow(filteredItems);
        setSelectedApplicants(filteredApplicants);
      } else {
        setSelectedRow([...selectedRow, element]);
        const current = applicantList.filter((a) => a.id === element.id);
        setSelectedApplicants([...selectedApplicants, ...current]);
      }
    } else {
      const current = applicantList.filter((a) => a.id === element.id);
      setSelectedApplicants([...selectedApplicants, ...current]);
      setSelectedRow([...selectedRow, element]);
    }
  };

  const hasItem = (arr, item) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].id === item.id) return true;
    }
    return false;
  };

  const onAllRowCheckedHandler = (element) => {
    const data = element.filter((a) => a.isCheckDisabled !== true);
    const currentApplicants = applicantList.filter((a) => hasItem(data, a));
    setSelectedRow(data);
    setSelectedApplicants(currentApplicants);
  };

  /* get Applicant List */
  const getShortlistedApplicantList = useCallback(
    async (args) => {
      const { search } = args || {};
      const res = await fnWrapper({
        url: `${FETCH_SHORTLISTED_APPLICANTS}/${teamId}?jobId=${jobId}&page=${currentPage}&size=${pageSize}&search=${
          search ? search : ''
        }`,
        method: 'GET',
        type: 'FETCH_APPLICANT_DETAILS_BASED_ON_JOBID',
      });
      if (res.data.statusCode === 200) {
        const result = res.data.data;
        if (result.items.length === 0 && currentPage !== 1) {
          setCurrentPage(currentPage - 1);
        } else {
          setCurrentPage(result.currentPage);
          const newResult = result?.items?.sort((a, b) =>
            a.applicant?.userName > b.applicant?.userName ? 1 : -1
          );
          setApplicantList(newResult);
        }
      } else {
        setApplicantList([]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage, pageSize]
  );

  useEffect(() => {
    getShortlistedApplicantList();
  }, [getShortlistedApplicantList]);

  /* shortlist applicant */
  const shortlistCandidate = async (id, isShortlisted) => {
    const loggedInUser = tokenService.getSession();
    const payload = {
      status: 'SHORTLISTED',
      shortlistedBy: loggedInUser.id,
    };
    const res = await fnWrapper({
      url: `${UPDATE_SHORTLIST_CANDIDATE}/${id}`,
      method: 'PUT',
      type: `UPDATE_SHORTLIST_STATUS`,
      payload: payload,
    });
    if (res.data.statusCode === 200) {
      toast.success(
        `${
          !isShortlisted ? 'Shortlisted applicant' : 'Removed from shortlist'
        }`,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        }
      );
      getShortlistedApplicantList();
    }
  };
  /* ---- */

  // Update condidate mark as contacted status
  const markCandidateAsContacted = async (id) => {
    const res = await fnWrapper({
      url: `${UPDATE_CANDIDATE_CONTACTED_STATUS}/${id}`,
      method: 'PUT',
      type: `UPDATE_CONTACTED_STATUS`,
    });
    if (res.data.statusCode === 200) {
      getShortlistedApplicantList();
      const data = applicantList.filter((ele) => ele.id === id)[0];
      if (!data?.markAsContacted) {
        const filtereddata = selectedRow.filter((ele) => ele.id !== data.id);
        const filteredApplicants = selectedApplicants.filter(
          (appl) => appl.id !== data.id
        );
        setSelectedRow(filtereddata);
        setSelectedApplicants(filteredApplicants);
      } else {
        const filtereddata = selectedRow.filter((ele) => ele.id !== data.id);
        const filteredApplicants = selectedApplicants.filter(
          (appl) => appl.id !== data.id
        );
        setSelectedRow(filtereddata);
        setSelectedApplicants(filteredApplicants);
      }
    }
  };

  /*-view applicant-- */
  const viewApplicant = async (id) => {
    const loggedInUser = tokenService.getSession();
    const payload = {
      status: 'VIEWED',
      shortlistedBy: loggedInUser.id,
    };
    const res = await fnWrapper({
      url: `${UPDATE_SHORTLIST_CANDIDATE}/${id}`,
      method: 'PUT',
      type: `UPDATE_SHORTLIST_STATUS`,
      payload: payload,
    });
    if (res.data.statusCode === 200) {
      getShortlistedApplicantList();
    }
  };

  /* send invite to selected job applicants*/
  const sendInvite = async () => {
    const loggedInUser = tokenService.getSession();

    let selectedCandidate = [];
    applicantsFromOnboard.map((ele) => {
      const obj = {
        id: ele?.id,
        designation: ele?.designation,
        role: ele?.selectedDropItem?.title,
      };
      selectedCandidate.push(obj);
      return selectedCandidate;
    });
    const payload = {
      applicantData: selectedCandidate,
      selectedBy: loggedInUser.id,
    };
    const res = await fnWrapper({
      url: `${SEND_INVITE_TO_SELECTED_JOB_APPLICANT}/${jobId}`,
      method: 'PUT',
      type: `SEND_INVITE_TO_SELECTED_JOB_APPLICANT`,
      payload: payload,
    });
    if (res.data.statusCode === 200) {
      setSuccessPopup(false);
      getShortlistedApplicantList();
      setSelectedApplicants([]);
      setSelectedRow([]);
      toast.success('Applicants on boarded successfully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  const applicantListData =
    context.busy.type === 'FETCH_JOBS_BASED_ON_TEAM' ||
    context.busy.type === 'UPDATE_SHORTLIST_STATUS'
      ? getPlaceholderItems(
          SHORTLISTED_APPLICANT_LIST_TABLE_HEADERS.map(
            ({ sortKey }) => sortKey
          ),
          6,
          context.busy.type === 'FETCH_JOBS_BASED_ON_TEAM' ||
            'UPDATE_SHORTLIST_STATUS'
        )
      : shortlistedApplicantListTableData({
          list: applicantList,
          shortlistCandidate,
          openOrCloseViewApplicant: ({
            id,
            shouldChangeApplicantStatusToView,
          }) => {
            if (shouldChangeApplicantStatusToView) {
              viewApplicant(id);
            }
            const applicantData = applicantList.map((applicant) => {
              if (applicant.id === id) {
                return {
                  userId: applicant?.applicant?.id,
                  name: applicant?.applicant?.individual?.name,
                  eRecord: applicant?.eRecord,
                  profileUrl: applicant?.profileUrl,
                  question: applicant?.question ? applicant?.question : null,
                  resume: applicant?.resume,
                  technology: applicant?.specialization,
                };
              }
              return null;
            });
            setViewApplicantPopup((prevState) => ({
              isModalOpen: !prevState.isModalOpen,
              data: applicantData ? applicantData[0] : null,
            }));
          },
          viewContact: (id) => {
            const data = applicantList.filter(
              (applicant) => applicant.id === id
            );
            const dataObj = [
              {
                iconName: 'sms',
                label: 'Email',
                value:
                  data[0]?.applicant?.email !== null &&
                  data[0]?.applicant?.email !== ''
                    ? data[0]?.applicant?.email
                    : 'N/A',
              },
              {
                iconName: 'contact',
                label: 'Contact',
                value:
                  data[0]?.applicant?.individual?.contact !== null
                    ? data[0]?.applicant?.individual?.contact
                    : 'N/A',
              },
            ];
            setContactDetails(dataObj);
            setViewContactPopup(!viewContactPopup);
          },
          markCandidateAsContacted,
          permissions: roleBasedAccessState.personalTeamRBAC,
        });

  const searchHandler = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value === '') {
      getShortlistedApplicantList();
    }
  };

  const closeSearchHandler = () => {
    setSearchTerm('');
    getShortlistedApplicantList();
  };

  const onSubmitHandler = () => {
    setSuccessPopup(true);
    setIsOnboardingPopup(false);
    sendInvite();
  };

  return (
    <ShortlistedWrapper>
      <div className="main-wrapper mb-4">
        <div className="main-wrapper__shortlisted">
          <div className="header-container">
            <div className="onboard-title"> {`${jobName} >> Shortlisted`} </div>
          </div>
          <div className="main-wrapper__shortlisted__content__header search-onboard-button-container">
            <SearchInput
              value={searchTerm}
              onSearchange={searchHandler}
              onCloseSearch={closeSearchHandler}
              onSearch={() => {
                getShortlistedApplicantList({
                  search: searchTerm,
                });
              }}
            />
            <CustomButton
              className="onboard-btn"
              disabled={selectedRow.length === 0}
              onClick={() => setIsOnboardingPopup(true)}>
              Onboard Applicants
              <Icon name="arrow-forward" color="#ffffff" />
            </CustomButton>
          </div>

          <div className="main-wrapper__shortlisted__content">
            {context.busy.status ? (
              <Loader loading={context.busy.status} />
            ) : applicantList.length !== 0 ? (
              <>
                <div className="main-wrapper__shortlisted__content__table">
                  <CustomTable
                    isLoading={context.busy.status}
                    rows={applicantListData}
                    headers={SHORTLISTED_APPLICANT_LIST_TABLE_HEADERS}
                    hasCheckbox={true}
                    isAllCheckDisabled={false}
                    selectedRow={selectedRow}
                    rowCheckedHandler={(val) => onRowCheckedHandler(val)}
                    allRowCheckedHandler={(val) => onAllRowCheckedHandler(val)}
                  />
                </div>
                <OnBoardPopUp
                  applicants={selectedApplicants}
                  isOpen={isOnboardingPopup}
                  onSubmitHandler={onSubmitHandler}
                  setSelectedApplicants={setApplicantsFromOnboard}
                  setIsOpen={() => setIsOnboardingPopup(false)}
                />
                {viewApplicantPopup.isModalOpen && (
                  <ModalPopup
                    open={false}
                    maxContentWidth={'650px'}
                    title={'Applicant Details'}
                    onClose={() => {
                      setViewApplicantPopup({
                        isModalOpen: false,
                        data: null,
                      });
                    }}>
                    <ViewApplicant applicantDetails={viewApplicantPopup.data} />
                  </ModalPopup>
                )}
                {viewContactPopup && (
                  <ModalPopup
                    open={false}
                    maxContentWidth={'650px'}
                    title={'Contact Info'}
                    onClose={() => {
                      setViewContactPopup(false);
                    }}>
                    <div className="contact-popup">
                      {contactDetails?.map((contact, index) => (
                        <div key={index} className="contact-popup-content">
                          <Icon name={contact?.iconName} color="#263238" />
                          <span>{contact?.value}</span>
                        </div>
                      ))}
                    </div>
                  </ModalPopup>
                )}
                {successPopup && (
                  <ModalPopup
                    open={false}
                    maxContentWidth={'650px'}
                    title={'Invitation Sent'}
                    onClose={() => {
                      setSuccessPopup(false);
                    }}>
                    <div className="success-popup">
                      <img src={AirPlane} alt="plane" height={'200px'} />
                      <p>
                        Team joining invitation is sent to the selected
                        candidates
                      </p>
                    </div>
                  </ModalPopup>
                )}
              </>
            ) : (
              applicantList.length === 0 && (
                <div className="main-wrapper__shortlisted__content__no-applicants">
                  <div>
                    <img src={noDataImg} alt="no-applicants" height="300px" />
                    <p>No Candidates Available!</p>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </ShortlistedWrapper>
  );
};

const ShortlistedWrapper = styled.div`
  .main-wrapper {
    .shortlisted-text {
      color: #17191d;
      font-weight: 700;
      font-size: 30px;
    }
    .onboard-title {
      font-family: Poppins;
      font-weight: 500;
      font-size: 22px;
      line-height: 31.5px;
      font-weight: 600;
    }
    .search-onboard-button-container {
      display: flex;
      align-items: center;
      gap: 4px;
      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
      .onboard-btn {
        margin-right: auto;
      }
    }

    &__shortlisted {
      display: flex;
      flex-direction: column;
      .header-container {
        padding: 1.2rem 0;
        line-height: 16px;
      }
      &__content__table {
        align-self: center;
        width: 100%;
        @media (min-width: 1200px) and (max-width: 1800px) {
          width: 700px;
        }
      }
      &__content__no-applicants {
        display: flex;
        justify-content: center;
        padding: 16px 0;
        p {
          text-align: center;
          color: Grey;
          padding: 10px;
        }
      }
    }
  }
`;
