import ProtectedRoute from 'components/protectedRoute';
import { Route, Routes, useParams } from 'react-router-dom';
import { IsPermitted, userScopes } from 'utlils';
import { CreateJob } from './CreateJobs';
import { PostedJobs } from './PostedJobs';
import { Shortlisted } from './Shortlisted';

export const TeamCareer = (props) => {
  const { userProfile, teamInfo } = props;
  const { userType } = useParams();

  return (
    <Routes>
      <Route
        element={
          <ProtectedRoute allowedPermissions={[userScopes.CREATE_JOB]}>
            <IsPermitted permissions={[userScopes.CREATE_JOB]}>
              <CreateJob teamInfo={teamInfo} userType={userType} />
            </IsPermitted>
          </ProtectedRoute>
        }
        path="create-job"
      />
      <Route
        element={
          <ProtectedRoute allowedPermissions={[userScopes.EDIT_POSTED_JOB]}>
            <IsPermitted permissions={[userScopes.EDIT_POSTED_JOB]}>
              <CreateJob teamInfo={teamInfo} userType={userType} />
            </IsPermitted>
          </ProtectedRoute>
        }
        path="edit-job/:jobId"
      />
      {/* using for the duplicate job post functionality */}
      <Route
        element={
          <ProtectedRoute
            allowedPermissions={[userScopes.DUPLICATE_POSTED_JOB]}>
            <IsPermitted permissions={[userScopes.DUPLICATE_POSTED_JOB]}>
              <CreateJob teamInfo={teamInfo} userType={userType} />
            </IsPermitted>
          </ProtectedRoute>
        }
        path="create-job/:jobId"
      />
      <Route
        element={
          <ProtectedRoute allowedPermissions={[userScopes.ACCESS_POSTED_JOB]}>
            <IsPermitted permissions={[userScopes.ACCESS_POSTED_JOB]}>
              <PostedJobs userProfile={userProfile} userType={userType} />
            </IsPermitted>
          </ProtectedRoute>
        }
        path="posted-jobs"
      />
      <Route
        element={
          <ProtectedRoute
            allowedPermissions={[userScopes.ACCESS_JOB_SHORTLISTED]}>
            <IsPermitted permissions={[userScopes.ACCESS_JOB_SHORTLISTED]}>
              <Shortlisted />
            </IsPermitted>
          </ProtectedRoute>
        }
        path="shortlisted"
      />
    </Routes>
  );
};
