import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
class TokenService {
  getAccessToken() {
    const session = JSON.parse(localStorage.getItem('session'));
    return session?.access_token;
  }
  getRefreshToken() {
    const session = JSON.parse(localStorage.getItem('session'));
    return session?.refresh_token;
  }
  getSession() {
    const session = JSON.parse(localStorage.getItem('session'));
    return session;
  }
  setAccessToken(token) {
    let session = JSON.parse(localStorage.getItem('session'));
    session.access_token = token;
    localStorage.setItem('session', JSON.stringify(session));
  }
  setSession(session) {
    localStorage.setItem('session', JSON.stringify(session));
  }
  getFcmTokenData() {
    const tokenDetails = JSON.parse(
      localStorage.getItem('fcmTokenAndDeviceInfo')
    );
    return tokenDetails;
  }
  async onLogout() {
    if ('serviceWorker' in navigator) {
      await navigator.serviceWorker.ready.then(async (registration) => {
        if (registration.active) {
          await registration.unregister();
        }
        if (caches) {
          // Service worker cache should be cleared with caches.delete()
          await caches.keys().then(async (names) => {
            await Promise.all(names.map((name) => caches.delete(name)));
          });
        }
      });
    }
    try {
      if (Notification.permission === 'granted') {
        const messaging = firebase.messaging();
        const currentToken = await messaging.getToken({
          vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
        });
        if (currentToken) {
          // Service worker token should be cleared
          await messaging.deleteToken(currentToken);
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
    localStorage.clear();
  }
}
export default new TokenService();
