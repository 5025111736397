import { Route, Routes } from 'react-router-dom';
import { SavedTeams } from './SavedTeams';
import { SearchTeam } from './SearchTeam';

export const ExploreTeams = () => {
  return (
    <Routes>
      <Route path="">
        <Route element={<SearchTeam />} path="search-team" />
        <Route element={<SavedTeams />} path="saved-teams" />
      </Route>
    </Routes>
  );
};
