import classname from 'classname';
import { Tooltip } from 'components';
import { string } from 'prop-types';
import styled from 'styled-components';
import Icon from 'utlils/Icons/Icon';

export const StageBlock = (props) => {
  const { className, stage } = props;
  return (
    <>
      <Wrp className={classname('stage-block', className)}>
        <Icon name="connect-flow" color={'#219FFF'} size={22} />
        <Tooltip content={`${stage} stage`}>
          {stage.length >= 19 ? `${stage.slice(0, 19)} ...` : stage}
        </Tooltip>
      </Wrp>
    </>
  );
};

const Wrp = styled.div`
  display: flex;
  align-items: center;
  column-gap: 6px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #219fff;
`;

StageBlock.propTypes = {
  className: string,
  stage: string,
};
