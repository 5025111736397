import { useState, useEffect, useContext, useCallback } from 'react';
import {
  setProgressStep,
  setProjectDetails,
  clearState,
} from 'context/Projects/CreateProject/createProjectAction';
import { useNavigate, useParams } from 'react-router-dom';
import { CreateProjectContext } from 'context/Projects/CreateProject/createProjectContext';
import Icon from 'utlils/Icons/Icon';
import StreamStages from 'pages/IndividualDashboard/components/streamStages';
import { CustomButton, Loader, ModalPopup } from 'components';
import { capitalizeFirstLetter } from 'pages/IndividualDashboard/individualOrganizations/enterprise/helper';
import RadioGroup from 'pages/IndividualDashboard/components/radioGroup';
import { StreamsWrapper } from './style';
import { TYPES } from 'pages/IndividualDashboard/constants';
import {
  CREATE_PROJECT,
  UPDATE_DRAFT_PROJECT,
  UPDATE_PROJECT,
  FETCH_DEFAULT_STREAM,
  FETCH_PREVIOUS_STREAM,
  FETCH_PROJECT_SERVICES,
  useAxiosWrapper,
  GET_STREAM_MEDIA,
} from 'services';
import { checkForSpacesInStartEnd } from 'helpers';

const radioLabels = [
  { text: 'Default Stream', value: '1' },
  { text: 'Choose last Stream', value: '2' },
  { text: 'Create new Stream', value: '3' },
];

const DEFAULT_NEW_STREAM = [{ number: 1, value: '' }];

export const Streams = ({
  onLeavingEditor,
  type = '',
  isDraft = false,
  draftProjectId = '',
  baseUrl = '',
}) => {
  const [fnWrapper, context] = useAxiosWrapper();
  const { createProjectState, createProjectDispatch } =
    useContext(CreateProjectContext);
  const [isDraftPopupOpen, setIsDraftPopupOpen] = useState(false);
  const [radioType, setRadioType] = useState('1');
  const [currentStage, setCurrentStage] = useState(0);
  const [defaultStream, setDefaultStream] = useState([]);
  const [lastStream, setLastStream] = useState([]);
  const [editStream, setEditStream] = useState([]);
  const [editProjectName, setEditProjectName] = useState('');
  const [isProjectDropdown, setIsProjectDropdown] = useState(false);
  const [allProjectStreams, setAllProjectStreams] = useState([]);
  const [selectedProjectStream, setSelectedProjectStream] = useState('');
  const [newStream, setNewStream] = useState(DEFAULT_NEW_STREAM);
  const [deleteNotAllowed, setDeleteNotAllowed] = useState(false);
  const { id, departmentId, projectId, action } = useParams();
  const navigate = useNavigate();

  const getDefaultStream = useCallback(async () => {
    const res = await fnWrapper({
      url: FETCH_DEFAULT_STREAM,
      method: 'GET',
      type: 'FETCH_DEFAULT_STREAM',
    });
    if (res.data.statusCode === 200) {
      const defaultStreamStages = res?.data?.data
        ?.map((stage, index) => ({
          id: stage.id,
          number: index + 1,
          value: capitalizeFirstLetter(stage?.streamName),
        }))
        .sort((a, b) => a.number - b.number);

      setDefaultStream(defaultStreamStages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPreviousStream = useCallback(async () => {
    const url = `${FETCH_PREVIOUS_STREAM}?type=${type.toLowerCase()}`;
    const res = await fnWrapper({
      url: url,
      method: 'GET',
      type: 'FETCH_PREVIOUS_STREAM',
    });

    if (res.data.statusCode === 200) {
      setAllProjectStreams(res.data.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const createOrEditProjectHandler = async () => {
    let finalStream = [];
    if (radioType === '1') {
      finalStream = defaultStream;
    } else if (radioType === '2') {
      finalStream = lastStream;
    } else if (radioType === '3') {
      finalStream = newStream;
    }

    let formData = new FormData();
    for (var i of createProjectState.attachments) {
      formData.append('attachments', i);
    }
    formData.append('title', createProjectState.projectTitle);
    formData.append('problemStatement', createProjectState.problemStatement);
    formData.append('category', createProjectState.category.value);
    formData.append('linkedProject', createProjectState.linkedProjectId);
    formData.append('link', createProjectState.link);
    formData.append('profilePicture', createProjectState.projectAvatar);
    formData.append('solution', createProjectState.solution);
    formData.append(
      'projectType',
      type === TYPES.ENTERPRISE ? 'ENTERPRISE' : 'PERSONAL'
    );
    if ((action && action === 'edit') || isDraft) {
      for (var f of createProjectState.linkAttachments) {
        formData.append('linkAttachments', f);
      }
      for (var k of createProjectState.deletedTeams) {
        formData.append('deleteTeamId', k);
      }
    }
    if (action && action === 'edit') {
      const streamData = JSON.stringify({
        streamId: createProjectState.editStreamId,
        stages: createProjectState.stream.map((stage) => ({
          id: stage.id,
          stageName: stage.value,
          stageNumber: stage.number,
          isActive: stage.isActive,
          isStarted: stage.isStarted,
        })),
      });
      formData.append('stream', streamData);
    } else {
      if (type === TYPES.ENTERPRISE) {
        const deptId = id ? id : departmentId;
        formData.append('departmentId', deptId);
      }
      formData.append('projectStatus', 'ONGOING');
      const streamData = JSON.stringify({
        streamName: '',
        type: 'CUSTOM',
        stages: finalStream.map((stage) => {
          return {
            stageName: stage.value,
            stageNumber: stage.number,
            isStarted: stage.number === 1,
          };
        }),
      });
      formData.append('stream', streamData);
    }
    for (var j of createProjectState.linkedTeams) {
      formData.append('teamId', j);
    }

    const createUrl = isDraft
      ? `${UPDATE_DRAFT_PROJECT}/${draftProjectId}`
      : CREATE_PROJECT;

    const url =
      action && action === 'edit'
        ? `${UPDATE_PROJECT}/${projectId}`
        : createUrl;

    const loadingType =
      action && action === 'edit' ? 'UPDATE_PROJECT' : 'CREATE_PROJECT';

    const res = await fnWrapper({
      url: url,
      method: 'POST',
      type: loadingType,
      payload: formData,
    });

    if (res.data.statusCode === 200) {
      await clearState(createProjectDispatch);
      await setProgressStep(createProjectDispatch, 5);
      if (action && action === 'edit') {
        navigate(`${baseUrl}/${projectId}/view/details`);
      } else {
        navigate(`${baseUrl}/create/success`);
      }
    }
  };

  const handleRadioButtons = (e) => {
    const { value } = e.target;
    setRadioType(value);
    if (value === '1') {
      setCurrentStage(0);
    } else if (value === '2') {
      setCurrentStage(0);
      getPreviousStream();
    } else if (value === '3') {
      if (newStream.length === 1 && newStream[0].value === '') {
        setCurrentStage(1);
      } else {
        setCurrentStage(0);
      }
    }
  };

  const onCloseHandler = () => {
    if (action && action === 'edit') {
      onLeavingEditor();
    } else {
      setIsDraftPopupOpen(true);
    }
  };

  const leaveWithoutSave = async () => {
    await clearState(createProjectDispatch);
    navigate(`${baseUrl}`, { replace: true });
  };

  const saveAndLeave = async () => {
    let formData = new FormData();
    for (var i of createProjectState.attachments) {
      formData.append('attachments', i);
    }
    formData.append('title', createProjectState.projectTitle);
    formData.append('problemStatement', createProjectState.problemStatement);
    formData.append('linkedProject', createProjectState.linkedProjectId);
    formData.append('link', createProjectState.link);
    formData.append('profilePicture', createProjectState.projectAvatar);
    formData.append('solution', createProjectState.solution);
    if (type === TYPES.ENTERPRISE) {
      const deptId = id ? id : departmentId;
      formData.append('departmentId', deptId);
    }
    formData.append('projectStatus', 'DRAFT');
    formData.append(
      'projectType',
      type === TYPES.ENTERPRISE ? 'ENTERPRISE' : 'PERSONAL'
    );
    for (var j of createProjectState.linkedTeams) {
      formData.append('teamId', j);
    }
    if (isDraft) {
      for (var f of createProjectState.linkAttachments) {
        formData.append('linkAttachments', f);
      }
      for (var k of createProjectState.deletedTeams) {
        formData.append('deleteTeamId', k);
      }
    }

    const url = isDraft
      ? `${UPDATE_DRAFT_PROJECT}/${draftProjectId}`
      : CREATE_PROJECT;

    const res = await fnWrapper({
      url: url,
      method: 'POST',
      type: 'CREATE_PROJECT',
      payload: formData,
    });

    if (res.data.statusCode === 200) {
      await clearState(createProjectDispatch);
      navigate(`${baseUrl}`, { replace: true });
    }
  };

  const stageNameChangeHandler = async (value, number) => {
    let temp = [];
    if (action && action === 'edit') {
      temp = createProjectState.stream;
    } else {
      if (radioType === '1') temp = defaultStream;
      else if (radioType === '2') temp = lastStream;
      else if (radioType === '3') temp = newStream;
    }

    const newState = temp.map((stage) => {
      if (number === stage.number) {
        return { ...stage, value: value };
      }
      return stage;
    });

    if (action && action === 'edit') {
      await setProjectDetails(createProjectDispatch, 'isEdited', true);
      await setProjectDetails(createProjectDispatch, 'stream', newState);
      setEditStream(newState);
    } else {
      if (radioType === '1') {
        setDefaultStream(newState);
      } else if (radioType === '2') {
        setLastStream(newState);
      } else if (radioType === '3') {
        setNewStream(newState);
      }
    }
  };

  const addStageHandler = async (number) => {
    let newState = [];
    if (action && action === 'edit') {
      newState = createProjectState.stream;
    } else {
      if (radioType === '1') newState = defaultStream;
      else if (radioType === '2') newState = lastStream;
      else if (radioType === '3') newState = newStream;
    }

    let predecessors = newState.filter((stage) => stage.number <= number);
    let successors = newState.filter((stage) => stage.number > number);
    if (action && action === 'edit') {
      predecessors.push({
        number: number + 1,
        value: '',
        isActive: 1,
        isStarted: false,
      });
    } else {
      predecessors.push({ number: number + 1, value: '', isStarted: false });
    }
    successors = successors.map((stage) => {
      return {
        ...stage,
        number: stage.number + 1,
      };
    });
    newState = [...predecessors, ...successors];
    setCurrentStage(number + 1);

    if (action && action === 'edit') {
      await setProjectDetails(createProjectDispatch, 'isEdited', true);
      await setProjectDetails(createProjectDispatch, 'stream', newState);
      setEditStream(newState);
    } else {
      if (radioType === '1') {
        setDefaultStream(newState);
      } else if (radioType === '2') {
        setLastStream(newState);
      } else if (radioType === '3') {
        setNewStream(newState);
      }
    }
  };

  const getStagePostData = useCallback(
    async (number) => {
      const stageId = createProjectState?.stream?.find(
        (stage) => stage?.number === number
      )?.id;
      if (!stageId) {
        return;
      }
      const res = await fnWrapper({
        url: `${GET_STREAM_MEDIA}/${stageId}`,
        method: 'GET',
        type: 'GET_STREAM_MEDIA',
      });
      if (res.data.statusCode === 200) {
        if (res?.data?.data && res?.data?.data?.length > 0) {
          return true;
        }
      }
      return false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createProjectState?.stream]
  );

  const removeStageHandler = async (number) => {
    const havePostData = await getStagePostData(number);
    if (havePostData) {
      setDeleteNotAllowed(true);
      return;
    }
    let newState = [];
    if (action && action === 'edit') {
      await setProjectDetails(createProjectDispatch, 'isEdited', true);
      newState = createProjectState.stream;
      newState = newState.map((stage) => {
        if (stage.number === number) {
          return {
            ...stage,
            isActive: 0,
          };
        } else {
          return stage;
        }
      });
      let predecessors = newState.filter((stage) => stage.number <= number);
      let successors = newState.filter((stage) => stage.number > number);
      successors = successors.map((stage) => {
        return {
          ...stage,
          number: stage.number - 1,
        };
      });
      newState = [...predecessors, ...successors];
      await setProjectDetails(createProjectDispatch, 'stream', newState);
      newState = newState
        .filter((stage) => stage.isActive !== 0)
        .sort((a, b) => a.number - b.number);
      setEditStream(newState);
    } else {
      if (radioType === '1') newState = defaultStream;
      else if (radioType === '2') newState = lastStream;
      else if (radioType === '3') newState = newStream;

      let predecessors = newState.filter((stage) => stage.number < number);
      let successors = newState.filter((stage) => stage.number > number);
      successors = successors.map((stage) => {
        return {
          ...stage,
          number: stage.number - 1,
        };
      });
      newState = [...predecessors, ...successors];

      if (radioType === '1') {
        setDefaultStream(newState);
      } else if (radioType === '2') {
        setLastStream(newState);
      } else if (radioType === '3') {
        setNewStream(newState);
      }
    }

    if (newState.length === 1 && newState[0].value === '') {
      setCurrentStage(1);
    } else {
      setCurrentStage(0);
    }
  };

  const dropdownHandler = async (proj) => {
    await setProjectDetails(createProjectDispatch, 'isEdited', true);
    setSelectedProjectStream(proj.title);
    let previousStreamStages = [];
    proj.streams[0].stages.map((stage) => {
      const item = {
        number: Number(stage.stageNumber),
        value: capitalizeFirstLetter(stage.stageName),
      };
      previousStreamStages.push(item);
      return previousStreamStages;
    });
    previousStreamStages.sort((a, b) => a.number - b.number);
    setLastStream(previousStreamStages);
  };

  const stages =
    radioType === '1'
      ? defaultStream
      : radioType === '2'
      ? lastStream
      : newStream;

  useEffect(() => {
    getDefaultStream();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStreamsList = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_PROJECT_SERVICES}/${projectId}?st=streams`,
      method: 'GET',
      type: 'FETCH_PROJECT_STREAMS',
    });

    if (res.data.statusCode === 200) {
      const result = res.data.data.data[0];
      const editStreamStages = [];
      setEditProjectName(result.project.title);
      setProjectDetails(createProjectDispatch, 'editStreamId', result.id);
      result.stages.map((stage) => {
        const item = {
          id: stage.id,
          isActive: stage.isActive,
          number: Number(stage.stageNumber),
          value: capitalizeFirstLetter(stage.stageName),
          isStarted: stage.isStarted,
        };
        editStreamStages.push(item);
        return editStreamStages;
      });
      editStreamStages.sort((a, b) => a.number - b.number);
      setProjectDetails(createProjectDispatch, 'stream', editStreamStages);
      setEditStream(editStreamStages);
      setCurrentStage(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const checkIsAllStreamAdded =
    newStream.length &&
    newStream?.filter(
      (stream) => !stream.value || checkForSpacesInStartEnd(stream.value)
    ).length === 0;

  const streamFinishDisabled =
    action && action === 'edit'
      ? editStream.length === 0
      : radioType === '1'
      ? defaultStream.length === 0
      : radioType === '2'
      ? lastStream.length === 0
      : !checkIsAllStreamAdded;

  useEffect(() => {
    if (action && action === 'edit') {
      getStreamsList();
    }
  }, [getStreamsList, action]);

  useEffect(() => {
    if (createProjectState.progressStep === 1) {
      if (action && action === 'edit') {
        navigate(`${baseUrl}/${projectId}/edit/details`, { replace: true });
      } else {
        navigate(`${baseUrl}/create/details`, { replace: true });
      }
    } else {
      setProgressStep(createProjectDispatch, 4);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StreamsWrapper>
      <div className="main-container">
        {action && action === 'edit' ? (
          <div className="edit-stream-heading">
            <span className="your-stream">Your Stream - </span>
            {editProjectName}
          </div>
        ) : (
          <RadioGroup
            currChecked={radioType}
            labels={radioLabels}
            handleChange={handleRadioButtons}
          />
        )}
        {context.busy.status ? (
          <Loader isLoading={context.busy.status} />
        ) : action && action === 'edit' ? (
          <StreamStages
            stagesList={editStream}
            currentStage={currentStage}
            onChangeHandler={stageNameChangeHandler}
            isEditable={radioType !== '2'}
            addHandler={addStageHandler}
            removeHandler={removeStageHandler}
          />
        ) : (
          <div className="stream-container">
            {radioType === '2' && allProjectStreams.length !== 0 && (
              <div className="project-name-text">
                <div style={{ paddingTop: '10px' }}>
                  Select project to copy stream
                </div>
                <div className="dropdown-wrapper">
                  <div
                    className="select"
                    onClick={() => setIsProjectDropdown(!isProjectDropdown)}>
                    {selectedProjectStream !== ''
                      ? selectedProjectStream
                      : 'Project Name'}
                    {isProjectDropdown ? (
                      <Icon color="#929daf" name="arrow-drop-up" size={22} />
                    ) : (
                      <Icon color="#929daf" name="arrow-down" size={22} />
                    )}
                  </div>
                  <div
                    className={isProjectDropdown ? 'options' : 'd-none'}
                    onClick={() => setIsProjectDropdown(!isProjectDropdown)}>
                    {isProjectDropdown &&
                      allProjectStreams?.map((item, idx) => (
                        <div
                          className="option-item"
                          onClick={() => dropdownHandler(item)}
                          key={idx}>
                          {item.title}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            )}
            {stages.length === 0 ? (
              <div className="no-stream">No stream available!</div>
            ) : (
              <StreamStages
                stagesList={stages}
                currentStage={currentStage}
                onChangeHandler={stageNameChangeHandler}
                isEditable={radioType !== '2'}
                addHandler={addStageHandler}
                removeHandler={removeStageHandler}
              />
            )}
          </div>
        )}

        <div className="bottom-buttons">
          <CustomButton
            label="Close"
            onClick={onCloseHandler}
            variant="textBtn"
            color="themeSecondary"
          />
          <CustomButton
            className="customBtnStreams"
            label="Finish"
            loading={context.busy.status}
            disabled={streamFinishDisabled}
            onClick={createOrEditProjectHandler}
            color="themeSecondary"
          />
        </div>
      </div>
      {isDraftPopupOpen && (
        <ModalPopup
          open={false}
          onClose={() => setIsDraftPopupOpen(false)}
          hasCloseIcon>
          <p className="text-center mt-5 w-75 mx-auto">
            You have some unsaved changes. Do you want to save it as a draft or
            leave?
          </p>
          <div className="draft-buttons mx-auto d-flex flex-sm-row flex-column justify-content-evenly align-items-center my-5">
            <CustomButton
              label="Leave"
              variant="outline"
              onClick={leaveWithoutSave}
              color="themeTertiary"
              customPadding="16px 52px"
            />
            <CustomButton
              loading={context.busy.status}
              variant="outline"
              label="Save as Draft"
              onClick={saveAndLeave}
              customPadding="16px 30px"
            />
          </div>
        </ModalPopup>
      )}
      {deleteNotAllowed && (
        <ModalPopup
          open={false}
          onClose={() => setDeleteNotAllowed(false)}
          hasTitle
          title="Cannot remove this stage"
          hasCloseIcon>
          <p className="text-center mt-2 w-75 mx-auto">
            This stage comprises a list of posts and, as a result, cannot be
            removed.
          </p>
        </ModalPopup>
      )}
    </StreamsWrapper>
  );
};
