import { Input } from '../inputBox';
import { Box } from '../box';
import './index.scss';
import { ClickOutside } from '../ClickOutside';
import { useState } from 'react';

export const SearchBoxWithDropDown = ({
  searchInputValue,
  searchData,
  onSelectSearchItem,
  onInputChangeHandler,
  placeholder = '',
}) => {
  let [is_open, set_open] = useState(false);

  const onChangeHandler = (e) => {
    set_open(true);
    onInputChangeHandler(e.target.value);
  };

  const onSelectItemHandler = (item) => {
    set_open(false);
    onSelectSearchItem(item);
  };

  return (
    <div className="search-member-container">
      <Input
        iconLeft
        iconName="search"
        placeholder={placeholder}
        value={searchInputValue}
        onChange={(e) => onChangeHandler(e)}
      />
      {is_open && searchData && searchData.length ? (
        <ClickOutside active={is_open} onClickOutside={() => set_open(false)}>
          <div className="options--wrapper">
            {searchData.map((item, index) => (
              <div
                key={index}
                className="prof-options"
                onClick={() => onSelectItemHandler(item)}>
                <Box display="flex" colGap="20px" alignItems="center">
                  {item.profilePicture !== null &&
                  item.profilePicture !== '' ? (
                    <img
                      alt="profile-pic"
                      src={item.profilePicture}
                      className="prof-drop-img"
                    />
                  ) : (
                    <div className="prof-text-wrapper">
                      <span className="prof-text-wrapper--text">
                        {item.profileText}
                      </span>
                    </div>
                  )}
                  <div className="prof-drop-name">{item.name}</div>
                </Box>
              </div>
            ))}
          </div>
        </ClickOutside>
      ) : null}
    </div>
  );
};
