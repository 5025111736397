import {
  CustomButton,
  CustomCheckBox,
  DropDown,
  SearchBoxWithDropDown,
} from 'components';
import { EXPLORE_TEAM_FILTERS } from 'pages/Explore/constants';
import { ExploreTeamFilterWrapper } from './style';
import { useState, useCallback, useEffect } from 'react';
import { useAxiosWrapper, FETCH_TEAM_TYPES } from 'services';

export const ExploreTeamFilter = ({
  state,
  filterTitle,
  enterpriseList,
  filterSaveHandler,
  filterOnChangeHandler,
}) => {
  const [fnWrapper] = useAxiosWrapper();
  const [teamType, setTeamType] = useState([]);

  const getTeamTypes = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_TEAM_TYPES}`,
      method: 'GET',
      type: 'FETCH_TEAM_TYPES',
    });
    if (res.data.statusCode === 200) {
      setTeamType(res.data.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getTeamTypes();
  }, [getTeamTypes]);

  function renameEnterpriseData(obj) {
    obj['label'] = obj['enterpriseName'];
    obj['value'] = obj['enterpriseName'].toUpperCase().split(' ').join('_');
    return obj;
  }
  let EnterpriseListData = [];
  enterpriseList.forEach((element) => {
    EnterpriseListData.push(renameEnterpriseData(element.enterprise));
  });

  return (
    <ExploreTeamFilterWrapper>
      <div className="filter-title-wrapper">
        <div className="filter-title">{filterTitle}</div>
      </div>
      <div className="filter-input-container">
        <div className="row">
          <div className="col-lg-6">
            <DropDown
              label={EXPLORE_TEAM_FILTERS.members_count.label}
              value={state.filteredMembersCount}
              objectKey={EXPLORE_TEAM_FILTERS.members_count.key}
              items={EXPLORE_TEAM_FILTERS.members_count.dataType}
              placeholder={EXPLORE_TEAM_FILTERS.members_count.placeholder}
              onClick={(e) => filterOnChangeHandler('filteredMembersCount', e)}
            />
          </div>
          <div className="col-lg-6">
            <DropDown
              label={EXPLORE_TEAM_FILTERS.team_type.label}
              value={state.filteredTeamType}
              objectKey="title"
              items={teamType}
              placeholder={EXPLORE_TEAM_FILTERS.team_type.placeholder}
              onClick={(e) => filterOnChangeHandler('filteredTeamType', e)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <DropDown
              label={EXPLORE_TEAM_FILTERS.team_category.label}
              items={EXPLORE_TEAM_FILTERS.team_category.dataType}
              objectKey={EXPLORE_TEAM_FILTERS.team_category.key}
              value={state.filteredCategory}
              placeholder={EXPLORE_TEAM_FILTERS.team_category.placeholder}
              onClick={(e) => filterOnChangeHandler('filteredCategory', e)}
            />
          </div>
          <div className="col-lg-6">
            <DropDown
              label={EXPLORE_TEAM_FILTERS.enterprise_name.label}
              items={EnterpriseListData}
              objectKey={EXPLORE_TEAM_FILTERS.enterprise_name.key}
              value={state.filteredEnterpriseName}
              placeholder={EXPLORE_TEAM_FILTERS.enterprise_name.placeholder}
              onClick={(e) =>
                filterOnChangeHandler('filteredEnterpriseName', e)
              }
            />
          </div>
        </div>
        <div className="row py-2">
          <div className="check-filter-item">
            <CustomCheckBox
              onCheckBg="rgba(33, 159, 255, 0.1)"
              check={state.hiringBasedFilter}
              tickColor="#219FFF"
              onClick={() =>
                filterOnChangeHandler(
                  'hiringBasedFilter',
                  !state.hiringBasedFilter
                )
              }
              checkBorder="1px solid #219FFF"
            />
            <div>Show teams that are hiring</div>
          </div>
        </div>
        <div className="row py-2">
          <div className="check-filter-item">
            <CustomCheckBox
              onCheckBg="rgba(33, 159, 255, 0.1)"
              check={state.connectionBasedFilter}
              tickColor="#219FFF"
              onClick={() =>
                filterOnChangeHandler(
                  'connectionBasedFilter',
                  !state.connectionBasedFilter
                )
              }
              checkBorder="1px solid #219FFF"
            />
            <div>Show teams based on my connections</div>
          </div>
        </div>
        {state.connectionBasedFilter && (
          <div className="row py-3 px-2">
            <SearchBoxWithDropDown placeholder="Search your connections" />
          </div>
        )}
        <div className="row mt-4 gy-2">
          <div className="col-lg-6">
            <CustomButton
              label="Apply"
              className="w-100"
              color="themeSecondary"
              onClick={() => filterSaveHandler('apply')}
            />
          </div>
          <div className="col-lg-6">
            <CustomButton
              label="Cancel"
              className="w-100"
              variant="light"
              color="themeSecondary"
              onClick={() => filterSaveHandler('cancel')}
            />
          </div>
        </div>
      </div>
    </ExploreTeamFilterWrapper>
  );
};
