import React from 'react';
import { TeamPublicCardWrapper } from './style';
import Icon from 'utlils/Icons/Icon';
import { CustomButton } from 'components';
import { TYPES } from 'pages/IndividualDashboard/constants';

export const TeamPublicCard = ({
  teamName,
  teamOwner,
  bgImgSrc,
  profileImgSrc = '',
  buttons = [],
  infoItems,
  teamCategory,
}) => {
  return (
    <TeamPublicCardWrapper bgImgSrc={bgImgSrc} numBtns={buttons.length}>
      <div className="upper-image-container" />
      <div className="content-container">
        <div className="sub-container">
          <div className="level-wrapper">
            <div className="level--sub-wrapper">
              <div className="profile-img-wrapper">
                {profileImgSrc && profileImgSrc.length > 0 ? (
                  <img alt="" src={profileImgSrc} />
                ) : (
                  <div className="profile-icon">
                    <Icon name="people" color="#404F67" size={40} />
                  </div>
                )}
              </div>
              <div className="level-btn">
                <span>Team</span>
              </div>
            </div>
          </div>
        </div>
        <div className="sub-container">
          <div className="info-wrapper">
            <div className="upper-half">
              <div className="title-class">
                <div className="title-name">{teamName}</div>
                <div className="sub-title-wrapper">
                  <Icon
                    name={
                      teamCategory === TYPES.ENTERPRISE
                        ? 'enterprise-two'
                        : 'user'
                    }
                    color="#404F67"
                    size={20}
                  />
                  <div className="sub-title-name">{teamOwner}</div>
                </div>
              </div>
              <div className={buttons.length === 1 ? '' : 'buttons-wrapper'}>
                {buttons &&
                  buttons.length > 0 &&
                  buttons.map((btn, index) => (
                    <div key={index} style={{ width: '100%' }}>
                      <CustomButton
                        key={index}
                        className="w-100"
                        onClick={btn.onClick}
                        variant="outline"
                        color="themeSecondary">
                        <Icon name={btn.iconName} color={btn.color} />
                        {btn.label}
                      </CustomButton>
                    </div>
                  ))}
              </div>
            </div>
            <div className="lower-half">
              {infoItems?.map((item, index) => (
                <div className="info-item" key={index}>
                  <Icon
                    name={item.iconName}
                    color={item.iconColor ? item.iconColor : '#929DAF'}
                  />
                  <div
                    onClick={item.onClick}
                    style={item.style ? item.style : null}>
                    {item.value}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </TeamPublicCardWrapper>
  );
};
