const getExtensionFromUrl = (url) => {
  const extension = url.split('.').pop();
  return extension.toLowerCase();
};

const getFileNameFromUrl = (url) => {
  const parts = url.split('/');
  return parts.pop();
};

const getTimeFromAttachmentUrl = (url) => {
  const fileName = getFileNameFromUrl(url);
  const parts = fileName.split('_');
  const time = parts.pop().split('.');
  return convertTimestampToDate(parseInt(time[0]));
};

const convertTimestampToDate = (timestamp) => {
  const date = new Date(timestamp);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  return { month, day, year };
};

export const convertData = (input) => {
  const output = {
    media: [],
    docs: [],
  };

  for (const item of input) {
    const attachments = item.attachments || [];

    for (const attachment of attachments) {
      const date = getTimeFromAttachmentUrl(attachment);
      const extension = getExtensionFromUrl(attachment);
      const fileName = getFileNameFromUrl(attachment);

      if (
        extension === 'jpg' ||
        extension === 'png' ||
        extension === 'gif' ||
        extension === 'mp4' ||
        extension === 'mpeg'
      ) {
        output.media.push({
          location: attachment,
          fileName: fileName,
          date: date,
          type: extension === 'mp4' || extension === 'mpeg' ? 'video' : 'image',
        });
      } else {
        output.docs.push({
          location: attachment,
          fileName: fileName,
          date: date,
          type: extension,
        });
      }
    }
  }

  return output;
};

export const getMonthNameFromNumber = (yearMonth) => {
  const yearMonthParts = yearMonth.split('-');
  const year = parseInt(yearMonthParts[0]);
  const monthNumber = parseInt(yearMonthParts[1]);

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const currentYear = new Date().getFullYear();
  if (monthNumber >= 1 && monthNumber <= 12) {
    if (year === currentYear) {
      return months[monthNumber - 1];
    } else {
      return `${months[monthNumber - 1]} ${year}`;
    }
  } else {
    return 'Miscellaneous';
  }
};
