import React from 'react';
import ProtectedRoute from 'components/protectedRoute';
import { Privacy } from './Privacy';
import { ProfileSetting } from './ProfileSetting';
import { Route, Routes } from 'react-router-dom';

export const TeamSettings = (props) => {
  const { teamInfo, setTeamData } = props;
  return (
    <Routes>
      <Route
        element={
          <ProtectedRoute>
            <Privacy teamInfo={teamInfo} setTeamData={setTeamData} />
          </ProtectedRoute>
        }
        path="/privacy"
      />
      <Route
        element={
          <ProtectedRoute>
            <ProfileSetting teamInfo={teamInfo} setTeamData={setTeamData} />
          </ProtectedRoute>
        }
        path="/profile-setting"
      />
    </Routes>
  );
};
