import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomButton, Loader, Pagination, SearchInput } from 'components';
import {
  ExploreTeamProjectCard,
  ExploreJobFilter,
} from 'pages/Explore/Components';
import { SearchJobWrapper } from './style';
import { useAxiosWrapper, FETCH_ALL_JOBS, SAVE_JOB } from 'services';
import tokenService from 'services/token.service';
import dayjs from 'dayjs';
import { getCurrencySymbol } from 'components/PriceInput/helper';
import Icon from 'utlils/Icons/Icon';

const DEFAULT_FILTER_STATE = {
  filteredExperienceLevel: {},
  filteredJobType: {},
  filteredRegion: {},
};

export const SearchJobs = () => {
  const [fnWrapper, context] = useAxiosWrapper();
  const [searchTerm, setSearchTerm] = useState('');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [jobList, setJobList] = useState({});
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  let pageSize = 6;
  const [filterState, setFilterState] = useState(DEFAULT_FILTER_STATE);
  const navigate = useNavigate();

  const getAllJobs = useCallback(
    async (search = '', filter = filterState) => {
      const loggedInUser = await tokenService.getSession();
      const res = await fnWrapper({
        url: `${FETCH_ALL_JOBS}/${
          loggedInUser.id
        }?page=${currentPage}&size=${pageSize}&search=${search}&jobType=${
          Object.keys(filter.filteredJobType).length
            ? filter.filteredJobType.value
            : ''
        }&experience=${
          Object.keys(filter.filteredExperienceLevel).length
            ? filter.filteredExperienceLevel.value
            : ''
        }&country=${
          Object.keys(filter.filteredRegion).length
            ? filter.filteredRegion.code
            : ''
        }`,
        method: 'GET',
        type: 'FETCH_ALL_JOBS',
      });

      if (res.data.statusCode === 200) {
        const result = res.data.data;
        if (result.items.length === 0 && currentPage !== 1) {
          setCurrentPage(currentPage - 1);
        } else {
          setCurrentPage(result.currentPage);
          setJobList(result);
          const allJobs = [];
          //console.log(result);

          result.items?.map((job) => {
            let profileImg;
            if (job?.team?.enterprise) {
              profileImg =
                job?.team?.enterprise?.profilePicture !== null
                  ? job?.team?.enterprise?.profilePicture
                  : '';
            } else {
              profileImg =
                job?.team?.user?.individual?.profilePicture !== null
                  ? job?.team?.user?.individual?.profilePicture
                  : '';
            }
            const item = {
              id: job?.id,
              jobTitle: job?.title,
              owner: job?.team?.enterprise
                ? job?.team?.enterprise?.enterpriseName
                : job?.team?.teamName,
              profileImgSrc: profileImg,
              profileIcon: 'project',
              isJobSaved: job?.isJobSaved,
              type: 'job',
              postedTime: dayjs(job?.createdAt).format('DD MMM YYYY'),
              description: job?.jobDescription,
              summary: job?.summary,
              feature: [
                {
                  iconName: 'location',
                  iconName2: 'location-two',
                  iconLabel: 'Job Location',
                  label:
                    job?.regionCountry !== '' && job?.regionCountry !== null
                      ? job?.regionCountry
                      : 'N/A',
                },
                {
                  iconName: 'brief-case',
                  iconName2: 'brief-case-two',
                  iconLabel: 'Job Type',
                  label: job?.jobType,
                },
                {
                  iconName: 'dollar-circle',
                  iconName2: 'empty-wallet',
                  iconLabel: 'Pay Range',
                  label:
                    job?.currencyType && job?.payRangeFrom && job?.payRangeTo
                      ? `${getCurrencySymbol(job?.currencyType)}${
                          job?.payRangeFrom
                        } - ${job?.payRangeTo}`
                      : 'N/A',
                },
                {
                  iconName: 'clock',
                  iconName2: 'calender',
                  iconLabel: 'Posted on',
                  label: dayjs(job?.createdAt).format('DD MMM YYYY'),
                },
              ],
            };
            allJobs.push(item);
            return allJobs;
          });
          setFilteredJobs(allJobs);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage, pageSize]
  );
  const saveJob = async (jobId) => {
    const loggedInUser = tokenService.getSession();
    const res = await fnWrapper({
      url: `${SAVE_JOB}`,
      method: 'POST',
      payload: {
        jobId: jobId,
        userId: loggedInUser.id,
      },
      type: 'SAVE_JOBS',
    });
    if (res.data.statusCode === 200) {
      await getAllJobs();
    }
  };

  const teamFilterSaveHandler = async (type) => {
    if (type === 'apply' && filteredJobs.length !== 0) {
      setSearchTerm('');
      getAllJobs('', filterState);
    } else if (
      type === 'cancel' &&
      JSON.stringify(filterState) !== JSON.stringify(DEFAULT_FILTER_STATE)
    ) {
      setFilterState(DEFAULT_FILTER_STATE);
      getAllJobs('', DEFAULT_FILTER_STATE);
    }
    setIsFilterOpen(false);
  };

  const teamFilterOnChangeHandler = (field = '', value = null) => {
    setFilterState({
      ...filterState,
      [field]: value,
    });
  };

  const onChangeSearchHandler = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value === '') {
      getAllJobs();
    }
  };

  const onCloseSearchHandler = () => {
    setSearchTerm('');
    getAllJobs();
  };

  const viewButtonHandler = (index) => {
    const data = filteredJobs.filter((item) => item.id === index);
    navigate(`/explore/jobs/view-job/${data[0].id}`);
  };

  useEffect(() => {
    getAllJobs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllJobs]);

  return (
    <SearchJobWrapper>
      <div className="search-container">
        <div className="search-bar">
          <SearchInput
            placeHolder="Search Jobs by Keyword"
            value={searchTerm}
            onSearchange={onChangeSearchHandler}
            onCloseSearch={onCloseSearchHandler}
            onSearch={() => getAllJobs(searchTerm)}
          />
        </div>
        <CustomButton
          variant="light"
          color={isFilterOpen ? 'themeSecondary' : 'themeGray2'}
          onClick={() => setIsFilterOpen(!isFilterOpen)}>
          <Icon
            name="filter"
            size={20}
            className="filter-arrow"
            color={isFilterOpen ? 'rgba(33, 159, 255, 1)' : '#404f67'}
          />
          Filter
        </CustomButton>
      </div>
      <div className="main-jobs-wrapper">
        <div className="heading">
          <h5 className="jobs-count">Jobs ({filteredJobs.length})</h5>
        </div>
        {context.busy.status ? (
          <Loader loading={context.busy.status} />
        ) : filteredJobs?.length > 0 ? (
          <div className="cards-container">
            {filteredJobs?.map((job, index) => (
              <ExploreTeamProjectCard
                key={index}
                title={job.jobTitle}
                type={job.type}
                profileIcon={job.profileIcon}
                owner={job.owner}
                description={job?.summary}
                features={job.feature}
                viewHandler={() => viewButtonHandler(job.id)}
                isSaved={job.isJobSaved}
                onSaveHandler={() => saveJob(job.id)}
              />
            ))}
          </div>
        ) : (
          <div className="no-data-class">No Jobs Found!</div>
        )}
        {isFilterOpen && (
          <>
            <ExploreJobFilter
              filterTitle="Filter Jobs"
              state={filterState}
              filterSaveHandler={teamFilterSaveHandler}
              filterOnChangeHandler={teamFilterOnChangeHandler}
            />
          </>
        )}
      </div>
      <div className="pagination-container">
        {(filteredJobs.length >= 6 || jobList.currentPage >= 1) && (
          <Pagination
            className="pagination-bar"
            currentPage={jobList.currentPage ? jobList.currentPage : 0}
            totalCount={jobList.totalItems ? jobList.totalItems : 0}
            pageSize={pageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        )}
      </div>
    </SearchJobWrapper>
  );
};
