import { JourneyContentWrapper } from './style';
import Icon from 'utlils/Icons/Icon';

export const HomeJourney = ({ content }) => {
  return (
    <JourneyContentWrapper id="home-journey" className="home-journey">
      <div className="container">
        <div className="row">
          <div className="home-journey__header">
            <Icon name={content.header_icon} color="#219fff" size={35} />
            <div className="home-journey__header--main">{content.heading}</div>
            <div className="home-journey__header--sub">
              {content.sub_heading}
            </div>
          </div>
          {content.capsules?.map((key, i) => (
            <div
              className="container home-journey__info-section"
              key={key.id + '--' + i}>
              <div className="row justify-content-start justify-content-md-end">
                <div className="col-lg-5 d-none d-md-block">
                  <img
                    src={key.imagePath}
                    alt={key.id}
                    className="home-journey__image"
                  />
                </div>
                <div className="col-1 order-first order-md-0 home-journey__middle">
                  <div className="home-journey__middle--wrap" />
                </div>
                <div className="col-11 d-block d-md-none">
                  <div className="row">
                    <img
                      src={key.imagePath}
                      alt={key.id}
                      className="home-journey__image"
                    />
                    <div className="home-journey__info">
                      <div className="home-journey__info--title">
                        {key.title}
                      </div>
                      <div className="home-journey__info--content">
                        {key.content}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 d-none d-md-block home-journey__info">
                  <div className="home-journey__info--title">{key.title}</div>
                  <div className="home-journey__info--content">
                    {key.content}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </JourneyContentWrapper>
  );
};
