import User from '../assests/images/user.png';
import Educational from '../assests/images/educational.png';
import SmallE from '../assests/images/smallE.png';
import LargeE from '../assests/images/largeE.png';
import bgImg from '../assests/images/Signup.png';

export const signupItems = [
  {
    type: 'individual',
    imgSrc: User,
    label: 'Individual User',
    subLabel: 'School and University',
    bannerImg: bgImg,
  },
  {
    type: 'educational',
    imgSrc: Educational,
    label: 'Educational Institute',
    subLabel: 'School and University',
    bannerImg: bgImg,
  },
  {
    type: 'smallE',
    imgSrc: SmallE,
    label: 'Small enterprise',
    subLabel: 'Fewer than 200 employees',
    bannerImg: bgImg,
  },
  {
    type: 'largeE',
    imgSrc: LargeE,
    label: 'Large enterprise',
    subLabel: 'More than 200 employees',
    bannerImg: '',
  },
];
export const loginItems = [
  {
    type: 'individual',
    imgSrc: User,
    label: 'Individual User account',
    subLabel: '',
    bannerImg: bgImg,
  },
  {
    type: 'educational',
    imgSrc: Educational,
    label: 'Educational Institute account',
    subLabel: '',
    bannerImg: bgImg,
  },
  {
    type: 'enterprise',
    imgSrc: SmallE,
    label: 'Enterprise account',
    subLabel: '',
    bannerImg: bgImg,
  },
];
