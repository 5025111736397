import styled from 'styled-components';

export const DeactivateAccountWrapper = styled.div`
  & .heading {
    color: #17191d;
    font-size: 25px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  & .link {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #0abd00 !important;
    text-decoration: underline;
  }

  & .link:hover {
    cursor: pointer;
  }

  & .deactivate-summary {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #404e67c4;
    padding: 20px 10px;
    margin-bottom: 20px;
  }

  & .deactivate-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & .deactivate-button-container {
      width: 222px;
      height: 55px;
    }
  }

  & .verify-email-text {
    font-size: 14px;
    height: 100%;
    display: flex;
    align-items: center;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    text-decoration: underline;
    color: #0abd00;
    cursor: pointer;
  }

  & .disable-btn {
    cursor: not-allowed;
  }

  & .popup-layout {
    & .popup-buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 20px 150px;
      gap: 25px;
    }
    & .teams-section {
      & .team-info-cards {
        display: grid;
        grid-template-columns: 0.5fr 0.5fr;
        gap: 10px;
      }
    }
    & .projects-section {
      margin-top: 5%;
      & .project-info-cards {
        display: grid;
        grid-template-columns: 0.5fr 0.5fr;
        gap: 10px;
      }
    }
    & .section-title {
      color: #404f67;
      font-weight: 500;
    }

    .popup-confirmation-message {
      color: #404f67;
      text-align: center;
      font-family: Open Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 30px;
    }

    & .delete-account-summary {
      color: #404f67c4;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
    }

    & .delete-account-summary {
      color: #404f67c4;
      font-family: Poppins;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
    }
  }
`;
