import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Box, CustomButton, DropDown, Input, Textarea } from 'components';
import { checkForSpacesInStartEnd } from 'helpers';

export const Information = ({
  createTeamState,
  createTeamStateHandler,
  onCloseHandler,
}) => {
  const [teamType, setTeamType] = useState([]);
  const [teamSize, setTeamSize] = useState([]);

  let ContinueButtonDisabled =
    createTeamState.teamName === '' ||
    createTeamState.description === '' ||
    createTeamState.topic === '' ||
    createTeamState.departmentId === '' ||
    checkForSpacesInStartEnd(createTeamState.teamName) ||
    checkForSpacesInStartEnd(createTeamState.description) ||
    checkForSpacesInStartEnd(createTeamState.topic) ||
    Object.keys(createTeamState.teamSize).length === 0 ||
    Object.keys(createTeamState.teamType).length === 0;

  const getTeamType = useCallback(async () => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/userType/team`)
      .then((response) => {
        if (response.data.statusCode === 200) {
          setTeamType(response.data.data);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      });
  }, []);

  const getTeamSize = useCallback(async () => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/getTeamSize`)
      .then((response) => {
        if (response.data.statusCode === 200) {
          setTeamSize(response.data.data);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      });
  }, []);

  useEffect(() => {
    getTeamType();
    getTeamSize();
  }, [getTeamType, getTeamSize]);
  return (
    <Box mt="32px">
      <div className="row">
        <div className="col-lg-6">
          <Input
            label="Team Name"
            type="text"
            required
            value={createTeamState.teamName}
            onChange={(evt) => {
              createTeamStateHandler('teamName', evt.target.value);
            }}
          />
        </div>
        <div className="col-lg-6">
          <Input
            label="Topic"
            type="text"
            required
            value={createTeamState.topic}
            onChange={(evt) => {
              createTeamStateHandler('topic', evt.target.value);
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <DropDown
            label="Team Type"
            required
            items={teamType}
            objectKey="title"
            placeholder="Select Team Type"
            value={createTeamState.teamType}
            onClick={(evt) => {
              createTeamStateHandler('teamType', evt);
            }}
          />
        </div>
        <div className="col-lg-6">
          <DropDown
            label="Team Size"
            required
            placeholder="Select Team Size"
            items={teamSize}
            objectKey="teamSize"
            value={createTeamState.teamSize}
            onClick={(evt) => {
              createTeamStateHandler('teamSize', evt);
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <Textarea
            label="Description"
            required
            value={createTeamState.description}
            onChange={(evt) => {
              createTeamStateHandler('description', evt.target.value);
            }}
          />
        </div>
      </div>
      <Box
        mt="32px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        colGap="15px">
        <CustomButton
          label="Continue"
          disabled={ContinueButtonDisabled}
          onClick={() => {
            createTeamStateHandler('progressStep', 2);
          }}
          color={'themeSecondary'}
        />
        <CustomButton
          label="Close"
          onClick={onCloseHandler}
          color={'themeSecondary'}
          variant="textBtn"
        />
      </Box>
    </Box>
  );
};
