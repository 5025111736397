import { CustomButton } from 'components';
import Icon from 'utlils/Icons/Icon';
import { useNavigate } from 'react-router-dom';
import { getFormattedDate } from '../utils';

const JobInvite = ({ context, invite, handleDeclineInvitation }) => {
  const navigate = useNavigate();

  return (
    <div className="invitation-wrapper">
      <div className="invitation-content">
        <div className="invitation-icon-container">
          <div className="invitation-icon-wrapper">
            <Icon
              name="brief-case"
              className="invitation-icon"
              color="#0ABD00"
            />
          </div>
        </div>
        <div className="invitation-highlight-wrapper">
          <span className="invitation-highlight">
            {`${
              invite?.invitedBy?.individual
                ? invite?.invitedBy?.individual?.name
                : invite?.invitedBy?.smallEnterprise?.enterpriseName
            } `}
          </span>
          invited you to apply for the position of{' '}
          <span className="invitation-highlight">
            {invite?.invitedJob?.title}
          </span>{' '}
          at
          <span className="invitation-highlight">{` ${invite?.invitedTeam?.teamName}`}</span>
          {invite?.description ? (
            <p>
              <span className="invitation-description">{` ${invite?.description} `}</span>
            </p>
          ) : null}
        </div>
        <div className="invitation-time-container">
          <span className="invitation-time">
            {getFormattedDate(invite?.createdAt)}
          </span>
        </div>
      </div>
      <div className="invitation-button">
        <div className="apply-button">
          <CustomButton
            loading={context.busy.status}
            label="Apply"
            className="w-100"
            onClick={() => {
              if (invite?.invitedJob?.id) {
                navigate(`/explore/jobs/view-job/${invite?.invitedJob?.id}`);
              }
            }}
          />
        </div>
        <div className="apply-button">
          <CustomButton
            color="themeTertiary"
            variant="light"
            loading={context.busy.status}
            label="Decline"
            onClick={() => handleDeclineInvitation(invite?.id)}
            className="w-100"
          />
        </div>
      </div>
    </div>
  );
};

export default JobInvite;
