import styled from 'styled-components';
import cn from 'classname';
import { useState } from 'react';

const CommonDropDownContainer = styled.div`
  font-family: Poppins;
  position: absolute;
  background-color: ${({ bgColor }) => bgColor};
  width: 157px;
  border: 1px solid #f4f7fc;
  border-top: 13px solid ${({ borderTopColor }) => borderTopColor};
  border-radius: 14px;
  z-index: 999;
  color: #404f67;
  top: ${({ top }) => top};
  right: ${({ right }) => right};
  @media (max-width: 700px) {
    top: ${({ mobileTop }) => mobileTop};
    right: ${({ mobileRight }) => mobileRight};
  }
  &::before {
    content: '';
    position: absolute;
    top: -33px;
    left: 80px;
    border: 11px solid transparent;
    border-bottom-color: ${({ borderTopColor }) => borderTopColor};
  }
  & .dropdown-wrapper__ {
    &list {
      border-bottom: 1px solid #e9eef4;
      padding: 15px 20px;
      cursor: pointer;
    }
  }
`;
const CommonDropDown = ({
  accessiblePermissions = [],
  top,
  right,
  mobileTop,
  mobileRight,
  dropItem,
  active,
  isDropdown,
  objectKey,
  onClick,
  bgColor = '#ffffff',
  borderTopColor = '#219fff',
  className,
}) => {
  const [activeElement, setActiveElement] = useState('');

  return (
    <CommonDropDownContainer
      top={top}
      right={right}
      mobileTop={mobileTop}
      mobileRight={mobileRight}
      bgColor={bgColor}
      borderTopColor={borderTopColor}
      className={cn(active ? 'dropdown-wrapper' : 'd-none', className)}
      onClick={isDropdown}>
      {active &&
        dropItem &&
        dropItem.length !== 0 &&
        dropItem
          .filter((item) =>
            item?.permission
              ? accessiblePermissions.includes(item?.permission)
              : true
          )
          .map((item, id) => (
            <div
              className="dropdown-wrapper__list"
              onClick={() => onClick(item)}
              style={{
                color: activeElement === item.id ? '#219fff' : '#404f67',
              }}
              onMouseOver={() => setActiveElement(item.id)}
              onMouseLeave={() => setActiveElement('')}
              key={id}>
              {item[objectKey]}
            </div>
          ))}
    </CommonDropDownContainer>
  );
};

export default CommonDropDown;
