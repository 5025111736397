import { useState, useCallback, useEffect } from 'react';
import Icon from 'utlils/Icons/Icon';
import ProjectContentDropdown from 'pages/IndividualDashboard/components/projectContentDropDown';
import { Loader } from 'components';
import './style.scss';
import { checkForFileType } from 'pages/IndividualDashboard/individualOrganizations/enterprise/helper';
import { useParams } from 'react-router-dom';
import { FETCH_PROJECT_SERVICES, useAxiosWrapper } from 'services';

export const ViewDetails = () => {
  const [fnWrapper, context] = useAxiosWrapper();
  const [projectDetails, setProjectDetails] = useState({});
  const { projectId } = useParams();

  const getProjectDetails = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_PROJECT_SERVICES}/${projectId}?st=details`,
      method: 'GET',
      type: 'FETCH_PROJECT_DETAILS',
    });

    if (res.data.statusCode === 200) {
      const result = res.data.data.data;
      const detailsData = {
        title: result.title,
        problemStatement: result.problemStatement,
        solution: result.solution,
        link: result.link,
        attachments: result.attachments,
        profilePicture: result.profilePicture,
      };
      setProjectDetails(detailsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const getFileName = (file) => {
    const setFileName = file.split('/');
    return setFileName[setFileName.length - 1];
  };

  useEffect(() => {
    getProjectDetails();
  }, [getProjectDetails]);

  return (
    <div>
      {context.busy.status ? (
        <Loader loading={context.busy.status} />
      ) : (
        <div className="view-project-container">
          <div className="project-sub-heading">
            <div className="sub-heading-left">
              <div className="project-img">
                {projectDetails.profilePicture !== null &&
                projectDetails.profilePicture !== '' ? (
                  <img
                    src={projectDetails.profilePicture}
                    alt="project-img"
                    width={'100%'}
                    height={'100%'}
                    style={{ borderRadius: '50%' }}
                  />
                ) : (
                  <Icon name="project" className="projectImg-style" />
                )}
              </div>
              <span>{projectDetails.title}</span>
            </div>
          </div>

          {/* drop down */}
          <div>
            <ProjectContentDropdown title={'Problems'}>
              <div>
                {projectDetails.problemStatement !== '' ? (
                  projectDetails.problemStatement
                ) : (
                  <div className="no-attachments">
                    You didn't mention problem statment.
                  </div>
                )}
              </div>
            </ProjectContentDropdown>

            <ProjectContentDropdown title={'Solution'}>
              <div>
                {projectDetails.solution !== '' ? (
                  projectDetails.solution
                ) : (
                  <div className="no-attachments">
                    You didn't provide any solution.
                  </div>
                )}
              </div>
            </ProjectContentDropdown>
            <ProjectContentDropdown title={'Others'}>
              <div className="project-others-container">
                <div className="project-link-wrapper ">
                  {projectDetails.link !== '' ? (
                    <span className="project-link-style">
                      Link-{' '}
                      <a
                        href={projectDetails.link}
                        target="_blank"
                        rel="noreferrer">
                        {projectDetails.link}
                      </a>
                    </span>
                  ) : (
                    <div className="no-attachments">No Link available.</div>
                  )}
                </div>

                {/* attachments */}
                <div>
                  <span className="project-link-style">Attachments</span>

                  {projectDetails.attachments?.length !== 0 &&
                  projectDetails.attachments !== null ? (
                    <div className="post-attachment">
                      {projectDetails.attachments?.map((elem, index) => {
                        const fileName = getFileName(elem);
                        return (
                          <div className="img-wrapper" key={index}>
                            <div className="img-container">
                              {checkForFileType(fileName) === 'others' ? (
                                <Icon name="attachment" />
                              ) : (
                                <img
                                  src={checkForFileType(fileName)}
                                  alt="attachment"
                                />
                              )}
                            </div>
                            <a
                              className="img-text"
                              href={elem}
                              target="_blank"
                              rel="noreferrer">
                              {fileName}
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div className="no-attachments">
                      you don't have attachments!
                    </div>
                  )}
                </div>
              </div>
            </ProjectContentDropdown>
          </div>
        </div>
      )}
    </div>
  );
};
