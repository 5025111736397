export const DEFAULT_CREATE_DETAILS_STATE = {
  enterpriseName: '',
  enterpriseType: {},
  enterpriseCategory: {},
  enterpriseLogo: null,
  link: '',
  contactNumber: '',
  email: '',
  aboutEnterprise: '',
  error: {},
};

export const DEFAULT_CREATE_LOCATION_STATE = [
  {
    id: '',
    uid: Date.now(),
    locationType: 'Primary_Location',
    country: null,
    state: null,
    city: null,
    address: '',
    isActive: 1,
  },
];

export const ENTERPRISE_CATEGORIES = [
  {
    type: 'Health and wellness',
    value: 'Health and wellness',
  },
  { type: 'IT services & consulting', value: 'IT services & consulting' },
  { type: 'Luxury', value: 'Luxury' },
  { type: 'Automobile', value: 'Automobile' },
  { type: 'Ethnic or cultural', value: 'Ethnic or cultural' },
];

export const ENTERPRISE_TYPES = [
  {
    type: 'Private Limited Companies',
    value: 'Private Limited Companies',
  },
  { type: 'Public Limited Companies', value: 'Public Limited Companies' },
  { type: 'One-Person Companies', value: 'One-Person Companies' },
  {
    type: 'Limited Liability Partnership',
    value: 'Limited Liability Partnership',
  },
  { type: 'Sole Proprietorship', value: 'Sole Proprietorship' },
];
