import { useCallback, useEffect, useState } from 'react';
import ViewMedia from './ViewMedia';
import ViewDocs from './ViewDocs';
import { convertData } from './utils';
import { GET_STREAM_MEDIA, useAxiosWrapper } from 'services';
import NoData from './NoData';
import { TabGroup, Loader, ScrollableDiv } from 'components';

const ViewMediaDocs = ({ stageId }) => {
  const [activeTab, setActiveTab] = useState('Media');
  const [mediaDocList, setMediaDocList] = useState({});
  const [fnWrapper, { busy }] = useAxiosWrapper();

  const getStageMediaDocs = useCallback(async () => {
    const res = await fnWrapper({
      url: `${GET_STREAM_MEDIA}/${stageId}`,
      method: 'GET',
      type: 'GET_STREAM_MEDIA',
    });
    if (res.data.statusCode === 200) {
      if (res?.data?.data && res?.data?.data?.length > 0) {
        const response = convertData(res?.data?.data);
        setMediaDocList(response);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTabList = () => {
    if (mediaDocList?.docs?.length > 0 && mediaDocList?.media?.length > 0) {
      return ['Media', 'Docs'];
    }
    if (mediaDocList?.docs?.length > 0) {
      if (activeTab !== 'Docs') {
        setActiveTab('Docs');
      }
      return ['Docs'];
    }
    if (mediaDocList?.media?.length > 0) {
      return ['Media'];
    }
    return [];
  };

  useEffect(() => {
    getStageMediaDocs();
  }, [getStageMediaDocs]);
  return (
    <>
      {busy?.status ? (
        <Loader loading={true} />
      ) : (
        <div>
          {Object.keys(mediaDocList).length === 0 ||
          (mediaDocList?.docs?.length === 0 &&
            mediaDocList?.media?.length === 0) ? (
            <NoData />
          ) : (
            <TabGroup
              tabLists={getTabList()}
              tabClick={(tabName) => {
                setActiveTab(tabName);
              }}
              activeTab={activeTab}
            />
          )}
          {Object.keys(mediaDocList).length === 0 ||
          (mediaDocList?.docs?.length === 0 &&
            mediaDocList?.media?.length === 0) ? null : (
            <ScrollableDiv>
              {activeTab === 'Media' ? (
                <ViewMedia mediaList={mediaDocList?.media} />
              ) : null}
              {activeTab === 'Docs' ? (
                <ViewDocs docList={mediaDocList?.docs} />
              ) : null}
            </ScrollableDiv>
          )}
        </div>
      )}
    </>
  );
};

export default ViewMediaDocs;
