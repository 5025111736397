import React, { useState } from 'react';
import Icon from 'utlils/Icons/Icon';
import { TooltipWrapper } from './style';

export const CustomTooltip = ({
  children,
  onHoverColor,
  iconSize,
  top = '0',
  left = '32px',
  mobileTop = '32px',
  mobileLeft = '-12px',
}) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <TooltipWrapper
      hoverColor={onHoverColor}
      top={top}
      left={left}
      mobileTop={mobileTop}
      mobileLeft={mobileLeft}>
      <div
        onMouseOver={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        className="hover-icon">
        <Icon
          name="info-circle"
          color={isHover ? onHoverColor : '#7E8F9A'}
          size={iconSize}
        />
      </div>
      {isHover && <div className="hover-card-container">{children}</div>}
    </TooltipWrapper>
  );
};
