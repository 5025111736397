import { NavigationalRowItem, CustomButton } from 'components';
import React from 'react';
import Icon from 'utlils/Icons/Icon';
import { MemberCardWrapper } from './style';

export const MemberCard = ({
  member,
  isIndividualUserRole,
  connectHandler,
}) => {
  return (
    <MemberCardWrapper
      bgImgSrc={member?.coverImgSrc}
      designation={member?.title}>
      <div className="member-wrapper">
        <div className="cover-image"></div>
        <div className="profile-image-container">
          {member?.profileImgSrc && !member?.isPrivateUser ? (
            <img src={member?.profileImgSrc} alt="member-profile" />
          ) : (
            <Icon name="user-icon" size={30} color="#000000" />
          )}
        </div>
        {!member?.isPrivateUser ? (
          <div className="info-wrapper">
            <div className="user-name">
              <NavigationalRowItem
                label={member?.name}
                navigateTo={
                  member?.isIndividual
                    ? `/public/individual/${member?.id}/summary/basic-details`
                    : ''
                }
                isIndividualUser={member?.isIndividual}
                isOpenInNewTab
                customStyle={{ color: '#17191d' }}
              />
            </div>
            {member?.title && (
              <p className="user-designation">{member?.title}</p>
            )}
            {member?.mutualConnections && isIndividualUserRole ? (
              <div className="sub-info-wrapper">
                <Icon name="link" color="#404f67" size={16} />
                <span>{member?.mutualConnections} mutual connections</span>
              </div>
            ) : null}
          </div>
        ) : (
          <div className="info-wrapper">
            <div className="user-name">Private member</div>
          </div>
        )}
      </div>
      {isIndividualUserRole &&
      member?.connectButton &&
      !member?.isPrivateUser ? (
        <div className="connect-button">
          <CustomButton
            label={member?.connectButton?.label}
            disabled={member?.connectButton?.disabled}
            className="w-100"
            variant="outline"
            color={
              member?.connectButton?.color === '#219fff'
                ? 'themeSecondary'
                : 'themeOrange'
            }
            onClick={connectHandler}
          />
        </div>
      ) : null}
    </MemberCardWrapper>
  );
};
