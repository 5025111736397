import styled from 'styled-components';

export const UpcomingFeatureWrapper = styled.div`
  .tltle {
    color: #404f67;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
