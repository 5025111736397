import { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classname';
import { SearchAndAddWrapper } from './style';
import { Input } from 'components/inputBox';
import { OptionWrapper } from 'components/OptionWrapper';
import Icon from 'utlils/Icons/Icon';

export const SearchAndAdd = ({
  itemList,
  selectedItems = [],
  label = '',
  onSearchAndAddHandler,
  className = '',
  placeHolder = 'Search...',
  required,
  ...rest
}) => {
  const [searchString, setSearchString] = useState('');
  const filteredItems = searchString
    ? itemList.filter(
        (item) =>
          item.title.toLowerCase().match(searchString.toLowerCase()) &&
          !selectedItems.includes(item.value)
      )
    : [];
  return (
    <SearchAndAddWrapper className={cn('searchAdd-wrapper', className)}>
      <Input
        label={label}
        placeholder={placeHolder}
        iconRight
        iconRightName="search"
        value={searchString}
        required={required}
        onChange={(e) => {
          setSearchString(e.target.value);
        }}
        hasError={selectedItems.length === 0}
        isSkipDefaultError
      />
      {filteredItems.length ? (
        <div className="searchAdd-wrapper__options">
          <OptionWrapper
            options={filteredItems}
            className="searchAdd-wrapper__options__list"
            onClick={(item) => {
              onSearchAndAddHandler([...selectedItems, item]);
              setSearchString('');
            }}
          />
        </div>
      ) : null}
      <div className="searchAdd-wrapper__selected">
        {selectedItems.length
          ? selectedItems.map((item, index) => (
              <div
                className="searchAdd-wrapper__selected__value"
                key={item?.id + index}>
                <div>{item?.title}</div>
                <Icon
                  name="cross"
                  onClick={() => {
                    onSearchAndAddHandler(
                      selectedItems.filter((val) => val?.id !== item?.id)
                    );
                    setSearchString('');
                  }}
                  color="#fffff"
                  size={14}
                />
              </div>
            ))
          : null}
      </div>
    </SearchAndAddWrapper>
  );
};

SearchAndAdd.propTypes = {
  itemList: PropTypes.array.isRequired,
  selectedItems: PropTypes.array.isRequired,
  label: PropTypes.string,
  onSearchAndAddHandler: PropTypes.func.isRequired,
  placeHolder: PropTypes.string,
  className: PropTypes.string,
};
