import { CustomButton, DropDown, Input, ModalPopup } from 'components';
import { ActionModalWrp } from './styles';

export const ReAssignDepartmentModal = (props) => {
  const { ctx, departmentList, onReAssign, onClose, onChange, state } = props;
  const isLoading =
    ctx.busy.status &&
    (ctx.busy.type === 'TASK_REASSIGN_DEPARTMENT' ||
      ctx.busy.type === 'FETCH_TASK_BY_ID');
  return (
    <div>
      <ModalPopup
        open={state.isModalOpen}
        onClose={() => onClose('TASK_REASSIGN_DEPARTMENT')}
        title={'Re-Assign task'}>
        <ActionModalWrp className="amw-assign-team">
          <Input
            label="Current department"
            type="text"
            value={state.selectedDepartment?.departmentName}
            disabled
          />
          <DropDown
            label={'Select department'}
            placeholder="Select"
            objectKey="departmentName"
            required
            value={state.reAssignedDepartment}
            items={departmentList}
            onClick={(value) =>
              onChange(
                'TASK_REASSIGN_DEPARTMENT',
                'reAssignedDepartment',
                value
              )
            }
          />
          <div className="amw-assign-team__action">
            <CustomButton
              disabled={isLoading}
              label={'Close'}
              onClick={() => onClose('TASK_REASSIGN_DEPARTMENT')}
              variant="textBtn"
              color={'themeSecondary'}
            />
            <CustomButton
              disabled={isLoading}
              label={'Re-Assign'}
              loading={isLoading}
              onClick={onReAssign}
              color={'themeSecondary'}
            />
          </div>
        </ActionModalWrp>
      </ModalPopup>
    </div>
  );
};
