import styled from 'styled-components';

export const MembershipWrapper = styled.div`
  & .membership-tab-styling {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 10px 0;
  }
  & .heading {
    color: #17191d;
    font-size: 25px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 30px;
  }

  & .membership-info {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 30px;

    & .star-icon {
      color: #ffba34;
    }
  }

  & .membership-text {
    padding: 23px 200px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #404f67;
  }

  @media (max-width: 900px) {
    & .membership-text {
      padding: 20px 100px;
    }
  }
`;
