import { CustomButton } from 'components';
import { useRef } from 'react';
import { isMobile } from 'utlils';
import Icon from 'utlils/Icons/Icon';

export const DocumentContainer = ({
  document,
  setDataToSubmit,
  addedDocuments,
}) => {
  const hiddenFileInput = useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  //Move these out if you want to reuse this component
  const handleFileInput = (event) => {
    const fileUploaded = event.target.files[0];
    //Replace the selected file
    setDataToSubmit((data) => {
      return {
        ...data,
        collaboratorForms: [
          ...data.collaboratorForms.filter(
            (doc) => doc?.docName !== document?.name
          ),
          {
            docName: document?.name,
            file: fileUploaded,
            isMandatory: document?.isMandatory,
            location: document?.location,
          },
        ],
      };
    });
    event.target.value = null;
  };

  const handleRemoveDocument = () => {
    setDataToSubmit((data) => {
      return {
        ...data,
        collaboratorForms: data?.collaboratorForms?.filter(
          (doc) => doc?.docName !== document?.name
        ),
      };
    });
  };

  return (
    <div
      className={
        isMobile() ? 'document-container-mobile' : 'document-container'
      }>
      <div>
        <Icon name="attachment" size={18} color={'#404F67'} />
        <span className="sub-heading-document">{document?.name}</span>
        {document?.isMandatory ? (
          <span style={{ color: '#FF1E26' }}>*</span>
        ) : null}
        <div style={{ marginLeft: '30px', marginTop: '10px' }}>
          <div>
            {addedDocuments?.find((doc) => doc?.docName === document?.name)
              ?.file?.name ? (
              <div>
                <span className="attached-doc-text">
                  {
                    addedDocuments?.find(
                      (doc) => doc?.docName === document?.name
                    )?.file?.name
                  }
                </span>
                <Icon
                  onClick={handleRemoveDocument}
                  name="cross"
                  size={18}
                  color={'#219FFF'}
                  style={{ marginLeft: '10px', cursor: 'pointer' }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="document-container-buttons">
        <a
          className="download-link"
          href={document?.location}
          target="_blank"
          rel="noreferrer">
          <CustomButton
            className="download-btn"
            variant="textBtn"
            customPadding="0 10px">
            Download
            <Icon name="download-box" color="#0ABD00" size={16} />
          </CustomButton>
        </a>
        <div>
          <CustomButton
            className="upload-btn"
            color="themeSecondary"
            customPadding="0 10px"
            variant="textBtn"
            onClick={handleClick}
            disabled={addedDocuments?.find(
              (doc) => doc?.docName === document?.name
            )}>
            Upload
            <Icon name="upload-box" color="#219FFF" size={16} />
          </CustomButton>
        </div>
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleFileInput}
          style={{ display: 'none' }}
          multiple={false}
        />
      </div>
    </div>
  );
};
