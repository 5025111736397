import styled from 'styled-components';

import { DropDown, Loader } from 'components';

const SelectDepartmentAndTeamWrapper = styled.div`
  .enterprise_posted_jobs_choose_department {
    &__title {
      color: #17191d;
      font-weight: 700;
      font-size: 30px;
    }
  }
`;

export const SelectDepartmentAndTeam = ({
  title = 'Careers',
  teamList,
  context,
  departmentAndTeam,
  setDepartmentAndTeam,
  getPostedJobsOnTeamClick,
}) => {
  return (
    <SelectDepartmentAndTeamWrapper className="enterprise_posted_jobs_choose_department">
      <span className="enterprise_posted_jobs_choose_department__title">
        {title}
      </span>
      <div className="d-flex align-items-center gap-5 mt-4">
        <div className="w-50">
          {context.busy.type === 'FETCH_TEAMS' ? (
            <div className=" main-wrapper d-flex justify-content-center align-items-center w-100">
              <Loader loading={context.busy.type === 'FETCH_TEAMS'} />
            </div>
          ) : (
            <DropDown
              label="Select Team"
              hasSearchBox
              placeholder="Select Team"
              items={teamList}
              value={departmentAndTeam.team}
              onClick={(value) => {
                setDepartmentAndTeam({
                  ...departmentAndTeam,
                  team: value,
                });
                getPostedJobsOnTeamClick({ teamIdFromProps: value.id });
              }}
            />
          )}
        </div>
      </div>
    </SelectDepartmentAndTeamWrapper>
  );
};
