import { Route, Routes } from 'react-router-dom';
import { TeamRequestsList } from './TeamRequestsList';

export const TeamRequests = () => {
  return (
    <>
      <div className="heading">Requests</div>
      <Routes>
        <Route path="">
          <Route element={<TeamRequestsList />} path="" />
        </Route>
      </Routes>
    </>
  );
};
