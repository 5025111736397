import { useState, useEffect, useCallback } from 'react';
import TokenService from 'services/token.service';
import { useAxiosWrapper, FETCH_USER_PROFILE } from 'services';
import { ReviewApplicationWrapper } from './style';
import { appliedByHelper } from 'pages/TeamDashboard/TeamCareer/helper';
import { ProfilePhoto } from 'pages/Explore/Components';
import Icon from 'utlils/Icons/Icon';
import { CustomButton, ScrollableDiv } from 'components';

export const ReviewApplication = ({ applicationDetails }) => {
  const [user, setUser] = useState(null);
  const [fnWrapper] = useAxiosWrapper();
  const appliedBy = appliedByHelper({
    isProfile: applicationDetails?.profileUrl,
    isERecord: applicationDetails?.eRecord,
    isResume: applicationDetails?.resume,
  });

  const questionAnswerObj = applicationDetails?.question
    ? JSON.parse(applicationDetails?.question)
    : null;
  const getUserProfile = useCallback(async () => {
    const loggedInUser = TokenService.getSession();
    const res = await fnWrapper({
      url: `${FETCH_USER_PROFILE}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_USER_PROFILE',
    });
    if (res.data.statusCode === 200) {
      const response = res.data.data;
      setUser({
        name: response.name,
        title: response.title,
        profilePicture: response.profilePicture,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (appliedBy === 'Profile') {
      getUserProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserProfile]);

  return (
    <ReviewApplicationWrapper>
      <div className="main-wrp">
        <div className="title">{'Applied with'}</div>
        <div className="applied-with-container">
          {appliedBy === 'Profile' && (
            <div className="applied-with-profile align-items-center">
              <ProfilePhoto
                profilePicture={user?.profilePicture}
                name={user?.name}
                size={70}
              />
              <div>
                <div className="profile-title">{user?.name}</div>
                {<div className="profile-sub-title">{user?.title}</div>}
              </div>
              <CustomButton
                label="View Profile"
                variant="outline"
                color="themeSecondary"
                className="view-profile"
                onClick={() => window.open(applicationDetails?.profileUrl)}
              />
            </div>
          )}
          {(appliedBy === 'E-Record' || appliedBy === 'Resume') && (
            <div className="d-flex  gap-4">
              <Icon name="personalCard" size={30} color="#404F67" />
              <div className="d-flex flex-column align-items-baseline">
                <div className="profile-title">{appliedBy}</div>
                <CustomButton
                  customPadding="2px 3px"
                  label={appliedBy === 'E-Record' ? 'View' : 'Resume.pdf'}
                  variant="textBtn"
                  color="themeSecondary"
                  onClick={() =>
                    appliedBy === 'E-Record'
                      ? window.open(applicationDetails?.eRecord)
                      : window.open(applicationDetails?.resume)
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <ScrollableDiv scrollOnHeight="400px">
        {questionAnswerObj &&
          Object.keys(questionAnswerObj).map(
            (items, index) =>
              questionAnswerObj[items] && (
                <div className="main-wrp" key={index}>
                  <div className="title">
                    {index + 1}: {items}
                  </div>
                  <div className="applied-answer">
                    {questionAnswerObj[items]}
                  </div>
                </div>
              )
          )}
        <div className="title">Selected technology</div>
        <div className="technology">
          {applicationDetails?.specialization?.map((tech) => (
            <div className="item">{tech?.name}</div>
          ))}
        </div>
      </ScrollableDiv>
    </ReviewApplicationWrapper>
  );
};
