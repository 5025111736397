import { useState } from 'react';
import { sortBy, toNumber } from 'lodash';
import dayjs from 'dayjs';
import { CustomCheckBox } from '../customCheckBox';
import './table.scss';
import SORTIMG from '../../assests/images/polygon.svg';
import classname from 'classname';

export const CustomTable = (props) => {
  let {
    isLoading = false,
    headers,
    rows,
    hasCheckbox = false,
    selectedRow = [],
    rowCheckedHandler,
    allRowCheckedHandler,
    customSortBy,
    className,
    isAllCheckDisabled = false,
    handleRadioSelection,
    radioCheckHandler,
    isRadioDisabled = false,
  } = props;
  let [sort, set_sort] = useState(null);

  // recursive function to get value from jsx object like <div>10</div>
  const get_jsx_value = (obj) => {
    if (obj['children'] && typeof obj['children'] === 'object') {
      return get_jsx_value(obj['children']);
    }
    return obj['children'];
  };
  let sort_rows = (rows, property, ascending = true, is_date_type) => {
    let sortedRows = sortBy(rows, (x) => {
      if (
        is_date_type ||
        dayjs(x[property], 'DD-MM-YYYY', true).isValid() ||
        dayjs(x[property], 'DD MMM, YYYY', true).isValid()
      ) {
        return dayjs(x[property]).valueOf();
      }
      const is_child_object_type =
        x[property] && typeof x[property] === 'object';
      const is_child_number_type =
        x[property] && !is_child_object_type && typeof x[property] === 'number';

      if (is_child_number_type) {
        return x[property];
      }
      if (is_child_object_type) {
        return toNumber(get_jsx_value(x[property]));
      }
      return (x[property] ? x[property] : '').toLowerCase();
    });

    return ascending ? sortedRows : sortedRows.reverse();
  };

  let sortRows = sort
    ? typeof customSortBy === 'function' && sort.key.includes('date')
      ? customSortBy(rows, sort.key, sort.asc)
      : sort_rows(rows, sort.key, sort.asc, sort.key.includes('date'))
    : rows;

  const onAllRowCheckedHandler = () => {
    if (selectedRow.length === rows.length) {
      allRowCheckedHandler([]);
    } else {
      allRowCheckedHandler(rows);
    }
  };

  return (
    <div className={classname('custom-table table-responsive py-2', className)}>
      <table className="table custom-table">
        <thead>
          <tr className="header-tr">
            {hasCheckbox && (
              <td>
                <CustomCheckBox
                  disabled={isLoading || isAllCheckDisabled}
                  check={selectedRow.length === rows.length}
                  onClick={() => onAllRowCheckedHandler()}
                />
              </td>
            )}

            {headers.map((headerItem, index) => (
              <td
                style={hasCheckbox && index === 0 ? { textAlign: 'left' } : {}}
                key={index}
                onClick={
                  headerItem.sortable
                    ? () =>
                        set_sort({
                          key: headerItem.sortKey,
                          asc:
                            headerItem.sortKey === (sort && sort.key)
                              ? !sort.asc
                              : true,
                        })
                    : null
                }>
                {headerItem.title}
                {headerItem.sortable && (
                  <img
                    src={SORTIMG}
                    alt="Change sort"
                    className={
                      (sort ? sort.asc : true) &&
                      ((sort && sort.key) || '') === headerItem.sortKey
                        ? 'sort-img active'
                        : 'sort-img'
                    }
                  />
                )}
              </td>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortRows.map((item, index) => (
            <tr key={index}>
              {hasCheckbox && (
                <td>
                  <CustomCheckBox
                    disabled={isLoading || item?.isCheckDisabled}
                    check={Boolean(selectedRow.find((e) => e.id === item.id))}
                    onClick={() => rowCheckedHandler(item)}
                  />
                </td>
              )}
              {headers.map((header, key) => (
                <td
                  style={hasCheckbox && key === 0 ? { textAlign: 'left' } : {}}
                  key={key}>
                  {header.hasRadioButton ? (
                    <input
                      className="radioBtn"
                      type="radio"
                      name={`${item.id}`}
                      value={item.id}
                      disabled={isRadioDisabled}
                      checked={radioCheckHandler(item, header)}
                      onChange={() => handleRadioSelection(item, header)}
                    />
                  ) : (
                    item[header.sortKey]
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
