import { Loader } from 'components';
import { EnterpriseTeamProjectCard } from '../Components';
import { useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EnterpriseTeamsWrapper } from './style';
import { useAxiosWrapper, FETCH_PUBLIC_ENTERPRISE_TEAMS } from 'services';

export const EnterprisePublicTeams = () => {
  const [fnWrapper, context] = useAxiosWrapper();
  const [enterpriseTeams, setEnterpriseTeams] = useState([]);
  const { enterpriseId } = useParams();
  const navigate = useNavigate();

  const getEnterpriseTeamsList = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_PUBLIC_ENTERPRISE_TEAMS}/${enterpriseId}`,
      method: 'GET',
      type: 'FETCH_PUBLIC_ENTERPRISE_TEAMS',
    });

    if (res.data.statusCode === 200) {
      const result = res.data.data;
      const teamsList = [];
      result.map((team) => {
        const listData = {
          id: team?.id,
          teamName: team?.teamName,
          members: `${team?.teamMembers?.length} ${
            team?.teamMembers?.length === 1 ? 'Member' : 'Members'
          }`,
          subTitleIcon: 'people',
          type: 'team',
          profileImgSrc: '',
          profileIcon: 'people',
          description: team?.description,
          teamFeature: [
            {
              iconName: 'enterprise-two',
              label: team?.department?.departmentName,
            },
          ],
        };
        teamsList.push(listData);
        return teamsList;
      });
      setEnterpriseTeams(teamsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterpriseId]);

  useEffect(() => {
    getEnterpriseTeamsList();
  }, [getEnterpriseTeamsList]);

  return (
    <EnterpriseTeamsWrapper>
      <div className="sub-container">
        <div className="main-wrapper">
          <div className="heading">
            Teams
            <span>({enterpriseTeams.length})</span>
          </div>
          {context.busy.status ? (
            <Loader loading={context.busy.status} />
          ) : (
            <div className="content">
              {enterpriseTeams?.length > 0 ? (
                <div className="cards-container">
                  {enterpriseTeams?.map((team, index) => (
                    <EnterpriseTeamProjectCard
                      key={index}
                      title={team.teamName}
                      profileImgSrc={team.profileImgSrc}
                      profileIcon={team.profileIcon}
                      type={team.type}
                      subTitle={team.members}
                      subTitleIcon={team.subTitleIcon}
                      description={team.description}
                      features={team.teamFeature}
                      viewHandler={() =>
                        navigate(`/public/team/${team.id}/details`)
                      }
                    />
                  ))}
                </div>
              ) : (
                <div className="no-data-class">No Teams Found!</div>
              )}
            </div>
          )}
        </div>
      </div>
    </EnterpriseTeamsWrapper>
  );
};
