import { React, useState, useEffect, useCallback, useRef } from 'react';
import { CustomButton, ScrollableDiv } from 'components';
import Icon from 'utlils/Icons/Icon';
import classnames from 'classnames';
import {
  RecieveTextMessage,
  SendTextMessage,
  FileAttachmentPreview,
  SelectedConversationHeader,
  ConversationUsersList,
} from '../../components';
import { ConversationViewerWrapper, SendMessagesInputWrapper } from './style';
import { useParams } from 'react-router-dom';
import { chatErrorMsg } from 'services/twilioService/helper';
import tokenService from 'services/token.service';
import {
  MAX_DOCUMENT_SIZE,
  MAX_VIDEO_SIZE,
  MAX_IMAGE_SIZE,
} from '../../constants';
import { convertFileSizeToMb } from '../../../../services/twilioService/helper';

export const ConversationViewer = ({
  openChat,
  setOpenChat,
  messages,
  setMessages,
  connectionStatus,
  conversationsList,
  handleConversationClick,
  connectionState,
  conversationsClient,
}) => {
  const { conversationId } = useParams();
  const [conversationDetails, setConversationDetails] = useState(null);
  const [messageText, setMessageText] = useState('');
  const loggedInUser = tokenService.getSession();
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const imageInputRef = useRef(null);
  const videoInputRef = useRef(null);
  const [selectedFileType, setSelectedFileType] = useState(null);
  const messagesEndRef = useRef(null);

  const scrollToBottom = (behavior) => {
    messagesEndRef.current?.scrollIntoView({ behavior: behavior });
  };

  const fetchConversationDetails = useCallback(async () => {
    try {
      const foundConversation = conversationsList.find(
        (conv) => conv?.sid === conversationId
      );
      if (foundConversation) {
        setConversationDetails(foundConversation);
        const fetchedMessages = await foundConversation?.getMessages();
        setMessages(fetchedMessages?.items);
        scrollToBottom('auto');
      }
    } catch (error) {
      chatErrorMsg(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId, conversationsList]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  const sendMessage = async () => {
    try {
      if (selectedFile) {
        const fileReader = new FileReader();

        fileReader.onload = async function () {
          const blob = new Blob([new Uint8Array(fileReader.result)], {
            type: selectedFile.type,
          });

          try {
            if (
              selectedFileType === 'video' &&
              convertFileSizeToMb(selectedFile?.size) > MAX_VIDEO_SIZE
            ) {
              chatErrorMsg(
                `Video cannot be more than ${MAX_VIDEO_SIZE} MB in size`
              );
            } else if (
              selectedFileType === 'image' &&
              convertFileSizeToMb(selectedFile?.size) > MAX_IMAGE_SIZE
            ) {
              chatErrorMsg(
                `Images cannot be more than ${MAX_IMAGE_SIZE} MB in size`
              );
            } else if (
              selectedFileType === 'attachment' &&
              convertFileSizeToMb(selectedFile?.size) > MAX_DOCUMENT_SIZE
            ) {
              chatErrorMsg(
                `Documents cannot be more than ${MAX_DOCUMENT_SIZE} MB in size`
              );
            } else {
              await conversationDetails.sendMessage({
                media: blob,
                contentType: selectedFile.type,
                filename: selectedFile.name,
              });
            }
          } catch (error) {
            chatErrorMsg('Error sending attachment');
          }

          // Clear the message input field and reset the selected file
          setMessageText('');
          setSelectedFile(null);
        };

        fileReader.readAsArrayBuffer(selectedFile);
      } else {
        const regex = /^\S.*$/;
        if (regex.test(messageText)) {
          // Send a text message only
          await conversationDetails.sendMessage(messageText);

          // Clear the message input field
          setMessageText('');
        } else {
          chatErrorMsg('Message cannot be empty');
        }
      }
    } catch (error) {
      chatErrorMsg('Error sending message', error);
    }
  };

  const handleMessageInputChange = (e) => {
    setMessageText(e.target.value);

    // Send typing indicator to the conversation
    if (conversationDetails) {
      conversationDetails.typing();
    }
  };

  const handleFileChange = (event, fileType) => {
    const file = event?.target?.files[0];
    setSelectedFile(file);
    setSelectedFileType(fileType);
    // Send typing indicator to the conversation
    if (conversationDetails) {
      conversationDetails.typing();
    }
  };

  const discardSelectedFileHandler = () => {
    setSelectedFile(null);
    setSelectedFileType(null);
  };

  const sendSelectedFileHandler = () => {
    if (selectedFile) {
      sendMessage();
      setSelectedFile(null);
      setSelectedFileType(null);
    }
  };

  useEffect(() => {
    fetchConversationDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId]);

  useEffect(() => {
    scrollToBottom('smooth');
  }, [messages]);

  return (
    <ConversationViewerWrapper>
      {conversationDetails != null ? (
        <div>
          <div className="right__section">
            <SelectedConversationHeader
              openChat={openChat}
              setOpenChat={setOpenChat}
              selectedConversationDetails={conversationDetails}
              loggedInUser={loggedInUser}
              connectionStatus={connectionStatus}
            />
            {openChat ? (
              <ConversationUsersList
                conversationsClient={conversationsClient}
                conversationsList={conversationsList}
                handleConversationClick={handleConversationClick}
                connectionState={connectionState}
              />
            ) : (
              <ScrollableDiv
                scrollOnHeight="calc(100vh - 40vh)"
                scrollOnHeightMob="calc(100vh - 110px)">
                <div className="conversation-body">
                  {messages.length > 0 ? (
                    messages
                      .filter(
                        (message) =>
                          message?.conversation?.sid === conversationId
                      )
                      .map((messageInfo, index) => (
                        <div
                          key={`${messageInfo?.sid}${index}`}
                          className="message">
                          {messageInfo?.state?.author === loggedInUser.id ? (
                            <SendTextMessage message={messageInfo} />
                          ) : (
                            <RecieveTextMessage message={messageInfo} />
                          )}
                          <div ref={messagesEndRef} />
                        </div>
                      ))
                  ) : (
                    <div className="no-conversation-message">
                      <p>Send your first message.</p>
                    </div>
                  )}
                </div>
              </ScrollableDiv>
            )}
          </div>

          {!openChat && (
            <SendMessagesInputWrapper>
              <div className="send-message">
                <div className="icon-section">
                  {selectedFile && (
                    <FileAttachmentPreview
                      selectedFile={selectedFile}
                      onDiscard={discardSelectedFileHandler}
                      onSend={sendSelectedFileHandler}
                      selectedFileType={selectedFileType}
                    />
                  )}

                  <Icon
                    name="attachment"
                    size={30}
                    onClick={() => fileInputRef?.current?.click()}
                    color={selectedFileType === 'attachment' ? '#219FFF' : null}
                  />
                  <Icon
                    name="image"
                    size={30}
                    onClick={() => imageInputRef?.current?.click()}
                    color={selectedFileType === 'image' ? '#219FFF' : null}
                  />
                  <Icon
                    name="video"
                    size={30}
                    onClick={() => videoInputRef?.current?.click()}
                    color={selectedFileType === 'video' ? '#219FFF' : null}
                  />
                </div>
                <div className="text-input-box">
                  <input
                    type="text"
                    placeholder="Type a message..."
                    value={messageText}
                    onChange={handleMessageInputChange}
                    onKeyDown={handleKeyDown}
                  />
                </div>
                <div className="send-btn">
                  <CustomButton
                    disabled={!messageText}
                    label="Send"
                    color="themeSecondary"
                    customPadding="16px 32px"
                    className="rounded-pill"
                    onClick={sendMessage}
                  />
                </div>
              </div>
              {/* Hidden file input */}
              <input
                ref={fileInputRef}
                type="file"
                accept=".pdf, .doc, .docx, .xls, .xlsx"
                style={{ display: 'none' }}
                onChange={(e) => handleFileChange(e, 'attachment')}
              />
              <input
                ref={imageInputRef}
                type="file"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={(e) => handleFileChange(e, 'image')}
              />
              <input
                ref={videoInputRef}
                type="file"
                accept="video/*"
                style={{ display: 'none' }}
                onChange={(e) => handleFileChange(e, 'video')}
              />
            </SendMessagesInputWrapper>
          )}
        </div>
      ) : (
        <div>
          <div className="no-conversation-selected">
            <div
              className="chats-icon"
              onClick={() => {
                setOpenChat(!openChat);
              }}>
              <Icon name={openChat ? 'arrow-left' : 'arrow-right'} />
            </div>
            <div className="empty-div-for-center"></div>
            <div className="no-conversation-heading">
              <span>No Conversation Selected!</span>
            </div>
            <div className="conversation-status">
              <div
                className={classnames({
                  'red-dot': connectionStatus !== 'You are connected',
                  'green-dot': connectionStatus === 'You are connected',
                })}></div>
              {connectionStatus}
            </div>
          </div>
          {openChat && (
            <ConversationUsersList
              conversationsClient={conversationsClient}
              conversationsList={conversationsList}
              handleConversationClick={handleConversationClick}
              connectionState={connectionState}
            />
          )}
        </div>
      )}
    </ConversationViewerWrapper>
  );
};
