import { useState, useEffect, useCallback } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import {
  FETCH_USER_PROFILE,
  FETCH_DEPARTMENT_TASKS_BASED_ON_TEAMID,
  FETCH_PROJECTS_BASED_ON_TEAM,
  FETCH_PROJECT_BOARD_FIELDS,
  FETCH_DEPARTMENT_BOARD_FIELDS,
  FETCH_PROJECTS_BASED_ON_USER,
  FETCH_DEPARTMENTS_BASED_ON_USER,
  useAxiosWrapper,
  FETCH_PROJECT_TASK_LIST_BASED_ON_PROJECT,
  FETCH_ALL_DEPARTMENT_TASKS,
  UPDATE_TASK_STATUS,
} from 'services';
import TokenService from 'services/token.service.js';
import { TaskBoard, TaskBoardFilterRow } from './components/index.js';
import { NavbarBrand1 } from 'components/index.js';
import {
  TASK_MANAGEMENT_NAVBAR_LIST,
  TASK_TYPE_FILTER_ITEMS,
} from './constants.js';
import { TaskManagementLayoutWrapper } from './style.js';
import { getProfileText } from 'utlils/utils.js';
import { INDIVIDUAL_DASHBOARD_NAV_LIST } from 'pages/IndividualDashboard/constants.js';
import { toast } from 'react-toastify';
import tokenService from 'services/token.service.js';

export const TaskManagement = (props) => {
  const [searchParams] = useSearchParams();
  const { state } = useLocation();
  const { handleLocaleChange, currentLocale } = props || {};
  const [fnWrapper, context] = useAxiosWrapper();
  const [taskTypeValueData, setTaskTypeValueData] = useState([]);
  const [boardFields, setBoardFields] = useState({});
  const [userProfile, setUserProfile] = useState({});
  const [taskTypeFilter, setTaskTypeFilter] = useState({
    isOpen: false,
    selectedItem: {},
  });
  const [taskTypeValueFilter, setTaskTypeValueFilter] = useState({
    isOpen: false,
    selectedItem: {},
  });
  const [openTaskId, setOpenTaskId] = useState(null);
  const [assignedToMe, setAssignedToMe] = useState(false);
  const loggedInUser = tokenService.getSession();
  const { dashboardType, dashboard, teamId } = useParams();

  // move task function for drop functionality
  const onMoveTaskHandler = async (item, status) => {
    const prevStatus = item?.status;
    if (prevStatus !== status) {
      const payload = {
        taskId: item?.id,
        status: status,
      };

      const res = await fnWrapper({
        url: `${UPDATE_TASK_STATUS}`,
        method: 'PUT',
        type: 'UPDATE_TASK_STATUS',
        payload: payload,
      });

      if (res.data.statusCode === 200) {
        setBoardFields((prevState) => ({
          ...prevState,
          [prevStatus]: prevState[prevStatus].filter(
            (ele) => ele?.id !== item?.id
          ),
          [status]: [...prevState[status], res.data.data],
        }));
      }
    }
  };

  // fetch board fields list based on project id
  const getProjectBoardFields = useCallback(
    async (projectId, userId = null) => {
      let fieldsList = {};
      const payload = {
        isPaginated: false,
        projectId: projectId,
        ...(userId && { userId: userId }),
      };

      const taskStatusRes = await fnWrapper({
        url: `${FETCH_PROJECT_BOARD_FIELDS}/${projectId}`,
        method: 'GET',
        type: 'FETCH_PROJECT_BOARD_FIELDS',
      });
      if (taskStatusRes.data.statusCode === 200) {
        const result = taskStatusRes.data.data;
        await result?.status?.forEach((field) => {
          fieldsList = { ...fieldsList, [field?.value]: [] };
        });
      }
      const taskListRes = await fnWrapper({
        url: `${FETCH_PROJECT_TASK_LIST_BASED_ON_PROJECT}`,
        method: 'GET',
        type: 'FETCH_TASKS',
        params: payload,
      });
      if (
        taskListRes.data.statusCode === 200 &&
        taskListRes.data.data?.items?.length
      ) {
        const result = taskListRes.data.data;
        Object.keys(fieldsList).forEach((field) => {
          fieldsList[field] = result?.items?.filter(
            (item) => item.status === field
          );
        });
        setBoardFields(fieldsList);
      } else {
        setBoardFields(fieldsList);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fnWrapper]
  );

  // fetch board fields list based on department id
  const getDepartmentBoardFields = useCallback(
    async (deptId) => {
      let fieldsList = {};
      const payload = {
        isPaginated: false,
        isFilterByTaskType: false,
        departmentId: deptId,
      };
      const taskStatusRes = await fnWrapper({
        url: `${FETCH_DEPARTMENT_BOARD_FIELDS}/${deptId}`,
        method: 'GET',
        type: 'FETCH_DEPARTMENT_BOARD_FIELDS',
      });
      if (taskStatusRes.data.statusCode === 200) {
        const result = taskStatusRes.data.data;
        await result?.status?.forEach((field) => {
          fieldsList = { ...fieldsList, [field?.value]: [] };
        });
      }
      const taskListRes = await fnWrapper({
        url: `${FETCH_ALL_DEPARTMENT_TASKS}`,
        method: 'GET',
        type: 'FETCH_TASKS',
        params: payload,
      });
      if (
        taskListRes.data.statusCode === 200 &&
        taskListRes?.data?.data?.items?.length
      ) {
        const result = taskListRes?.data?.data;
        Object.keys(fieldsList).forEach((field) => {
          fieldsList[field] = result?.items?.filter(
            (item) => item?.status === field
          );
        });
        setBoardFields(fieldsList);
      } else {
        setBoardFields(fieldsList);
      }
    },
    [fnWrapper]
  );

  // fetch userprofile
  const getUserProfile = useCallback(async () => {
    const loggedInUser = TokenService.getSession();
    const res = await fnWrapper({
      url: `${FETCH_USER_PROFILE}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_USER_PROFILE',
    });
    if (res.data.statusCode === 200) {
      const response = res.data.data;
      setUserProfile({
        ...response,
        profileText: getProfileText(
          response?.role === 'INDIVIDUAL'
            ? response?.name
            : response?.enterpriseName
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch departments based on teamId
  const getTeamDepartmentList = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_DEPARTMENT_TASKS_BASED_ON_TEAMID}/${teamId}`,
      method: 'GET',
      type: 'FETCH_DEPARTMENT_TASKS',
    });
    if (res.data.statusCode === 200) {
      const response = res.data.data;
      let departmentTasksData = [];
      response.map((item) => {
        if (item.department === null) {
          departmentTasksData = [];
          return departmentTasksData;
        }
        const data = {
          id: item.department?.id,
          title: item.department?.departmentName,
        };
        departmentTasksData.push(data);
        return departmentTasksData;
      });
      setTaskTypeValueData(departmentTasksData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch projects based on teamId
  const getTeamProjectList = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_PROJECTS_BASED_ON_TEAM}/${teamId}`,
      method: 'GET',
      type: 'FETCH_PROJECTS_BASED_ON_TEAM',
    });
    if (res.data.statusCode === 200) {
      const response = res.data.data?.filter(
        (project) => project.teamStatus === '3'
      );
      let projectTasksData = [];
      response?.map((item) => {
        const data = {
          id: item?.projects?.id,
          title: item?.projects?.title,
        };
        projectTasksData.push(data);
        return projectTasksData;
      });
      setTaskTypeValueData(projectTasksData);
      return response;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch projects based on userId
  const getUserProjectList = useCallback(async () => {
    const loggedInUser = TokenService.getSession();
    const res = await fnWrapper({
      url: `${FETCH_PROJECTS_BASED_ON_USER}/${loggedInUser.id}?projectType=all`,
      method: 'GET',
      type: 'FETCH_PROJECTS_BASED_ON_USER',
    });

    if (res.data.statusCode === 200) {
      const result = res.data.data;
      const projectDetails = [];
      result?.map((project) => {
        const details = {
          id: project?.id,
          title: project?.title,
        };
        projectDetails.push(details);
        return projectDetails;
      });
      setTaskTypeValueData(projectDetails);
      return result;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch departments based on userId
  const getUserDepartmentList = useCallback(async () => {
    const loggedInUser = TokenService.getSession();
    const res = await fnWrapper({
      url: `${FETCH_DEPARTMENTS_BASED_ON_USER}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_DEPARTMENTS_BASED_ON_USER',
    });
    if (res.data.statusCode === 200) {
      const result = res.data.data;
      let deptList = [];
      result?.map((dept) => {
        const item = {
          id: dept?.department?.id,
          title: dept?.department?.departmentName,
        };
        deptList.push(item);
        return deptList;
      });
      setTaskTypeValueData(deptList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onOpenTaskTypeFilter = useCallback((type) => {
    if (type === 'TASK_TYPE') {
      setTaskTypeFilter((prev) => ({
        ...prev,
        isOpen: !prev.isOpen,
      }));
    }
    if (type === 'TASK_TYPE_VALUE') {
      setTaskTypeValueFilter((prev) => ({
        ...prev,
        isOpen: !prev.isOpen,
      }));
    }
  }, []);

  const onSelectTaskTypeHandler = async (item) => {
    setTaskTypeFilter((prev) => ({
      ...prev,
      selectedItem: item,
    }));
    if (item.id === 'PROJECT_TASK') {
      setTaskTypeValueData([]);
      setTaskTypeValueFilter({
        isOpen: false,
        selectedItem: {},
      });
      setBoardFields([]);
      if (dashboard === 'task-management') {
        getTeamProjectList();
      } else {
        getUserProjectList();
      }
    } else {
      setTaskTypeValueData([]);
      setBoardFields([]);
      setTaskTypeValueFilter({
        isOpen: false,
        selectedItem: {},
      });
      if (dashboard === 'task-management') {
        getTeamDepartmentList();
      } else {
        getUserDepartmentList();
      }
    }
    setTaskTypeFilter((prev) => ({
      ...prev,
      isOpen: !prev.isOpen,
    }));
  };

  const onCameFromLinkHandler = async (selectedType, selectedValue) => {
    setTaskTypeFilter((prev) => ({
      ...prev,
      selectedItem: selectedType,
    }));
    if (selectedType.id === 'PROJECT_TASK') {
      setTaskTypeValueData([]);
      setTaskTypeValueFilter({
        isOpen: false,
        selectedItem: {},
      });
      setBoardFields([]);
      let res;
      if (dashboard === 'task-management') {
        res = await getTeamProjectList();
      } else {
        res = await getUserProjectList();
      }
      if (!res.find((item) => item.id === selectedValue.id)) {
        toast.error(`You don't have the required permission`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        return;
      }
    } else {
      setTaskTypeValueData([]);
      setBoardFields([]);
      setTaskTypeValueFilter({
        isOpen: false,
        selectedItem: {},
      });
      if (dashboard === 'task-management') {
        getTeamDepartmentList();
      } else {
        getUserDepartmentList();
      }
    }
    setTaskTypeFilter((prev) => ({
      ...prev,
      isOpen: false,
    }));
    setTaskTypeValueFilter((prev) => ({
      ...prev,
      selectedItem: selectedValue,
    }));
    if (selectedType?.id === 'PROJECT_TASK') {
      await getProjectBoardFields(selectedValue.id);
    } else {
      getDepartmentBoardFields(selectedValue.id);
    }
    setTaskTypeValueFilter((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };
  const onSelectTaskTypeValueHandler = async (item) => {
    setTaskTypeValueFilter((prev) => ({
      ...prev,
      selectedItem: item,
    }));
    if (taskTypeFilter?.selectedItem?.id === 'PROJECT_TASK') {
      await getProjectBoardFields(item.id);
    } else {
      getDepartmentBoardFields(item.id);
    }
    setTaskTypeValueFilter((prev) => ({
      ...prev,
      isOpen: !prev.isOpen,
    }));
  };

  const handleAssignedToMeFilter = () => {
    setAssignedToMe((prevAssignedToMe) => !prevAssignedToMe);
    // If assignedToMe is true, fetch data with userId, otherwise, fetch data without userId
    if (!assignedToMe) {
      // If assignedToMe is true, fetch data with userId
      getProjectBoardFields(
        state ? state.openedCard.id : taskTypeValueFilter.selectedItem.id,
        loggedInUser.id
      );
    } else {
      // If assignedToMe is false, fetch data without userId
      getProjectBoardFields(
        state ? state.openedCard.id : taskTypeValueFilter.selectedItem.id
      );
    }
  };

  const copyLinkHandler = (taskId) => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_BASE_URL}/individual/task-management?taskType=${taskTypeFilter?.selectedItem?.id}&taskTypeValueTitle=${taskTypeValueFilter?.selectedItem?.title}&taskTypeValue=${taskTypeValueFilter?.selectedItem?.id}&taskId=${taskId}`
    );
    toast.success('Link copied to your clipboard', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'colored',
    });
  };

  const handleTaskFromLink = useCallback(async () => {
    const type = searchParams.get('taskType');
    const title = searchParams.get('taskTypeValueTitle');
    const value = searchParams.get('taskTypeValue');
    const taskId = searchParams.get('taskId');
    if (!type || !title || !value || !taskId) {
      return;
    }
    setOpenTaskId(taskId);
    onCameFromLinkHandler(
      type === 'PROJECT_TASK'
        ? {
            id: 'PROJECT_TASK',
            title: 'Project task',
          }
        : {
            id: 'DEPARTMENT_TASK',
            title: 'Department task',
          },
      {
        id: value,
        title: title,
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    handleTaskFromLink();
  }, [searchParams, handleTaskFromLink]);

  useEffect(() => {
    getUserProfile();
  }, [getUserProfile]);

  useEffect(() => {
    if (state !== null || undefined) {
      if (state.taskType === 'project-tasks') {
        setTaskTypeFilter((prev) => ({
          ...prev,
          selectedItem: TASK_TYPE_FILTER_ITEMS[0],
        }));
        setTaskTypeValueData([]);
        getProjectBoardFields(state.openedCard.id);
        setBoardFields([]);
        if (dashboard === 'task-management') {
          getTeamProjectList();
        } else {
          getUserProjectList();
        }
        setTaskTypeValueFilter({
          isOpen: false,
          selectedItem: state.openedCard,
        });
      } else {
        setTaskTypeFilter((prev) => ({
          ...prev,
          selectedItem: TASK_TYPE_FILTER_ITEMS[1],
        }));
        setTaskTypeValueData([]);
        setBoardFields([]);
        getDepartmentBoardFields(state.openedCard.id);
        if (dashboard === 'task-management') {
          getTeamDepartmentList();
        } else {
          getUserDepartmentList();
        }
        setTaskTypeValueFilter({
          isOpen: false,
          selectedItem: state.openedCard,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TaskManagementLayoutWrapper className="task-management">
      <div className="task-management__navbar">
        <NavbarBrand1
          isTaskManagementDashboard
          navMenu={
            teamId
              ? TASK_MANAGEMENT_NAVBAR_LIST(dashboardType, teamId)
              : INDIVIDUAL_DASHBOARD_NAV_LIST(userProfile?.role)
          }
          currentLocale={currentLocale}
          handleLocaleChange={handleLocaleChange}
          userProfile={userProfile}
        />
      </div>
      <div className="task-management__wrapper">
        <TaskBoardFilterRow
          onOpenTaskTypeFilter={onOpenTaskTypeFilter}
          onSelectTaskTypeHandler={onSelectTaskTypeHandler}
          onSelectTaskTypeValueHandler={onSelectTaskTypeValueHandler}
          taskTypeValueData={taskTypeValueData}
          taskTypeFilter={taskTypeFilter}
          taskTypeValueFilter={taskTypeValueFilter}
          teamId={teamId}
          handleAssignedToMeFilter={handleAssignedToMeFilter}
          assignedToMe={assignedToMe}
        />
        <TaskBoard
          boardFields={boardFields}
          ctx={context}
          onMoveTaskHandler={onMoveTaskHandler}
          getProjectBoardFields={getProjectBoardFields}
          getDepartmentBoardFields={getDepartmentBoardFields}
          selectedTaskType={taskTypeFilter?.selectedItem}
          selectedTaskTypeValue={taskTypeValueFilter?.selectedItem}
          setBoardFields={setBoardFields}
          dashboardType={dashboardType}
          copyLinkHandler={copyLinkHandler}
          openTaskId={openTaskId}
          setOpenTaskId={setOpenTaskId}
        />
      </div>
    </TaskManagementLayoutWrapper>
  );
};
