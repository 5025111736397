import { Route, Routes } from 'react-router-dom';
import { MyJobs } from './MyJobs';
import { ViewJob } from './ViewJob';
import { SearchJobs } from './SearchJobs';

export const ExploreJobs = () => {
  return (
    <Routes>
      <Route path="">
        <Route element={<SearchJobs />} path="search-job" />
        <Route element={<MyJobs />} path="my-jobs/*" />
        <Route element={<ViewJob />} path="view-job/:jobId" />
      </Route>
    </Routes>
  );
};
