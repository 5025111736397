import classname from 'classname';
import { Tooltip } from 'components';
import { string } from 'prop-types';
import styled from 'styled-components';
import Icon from 'utlils/Icons/Icon';

export const PriorityBlock = (props) => {
  const { className, priority } = props;
  const status = {
    1: {
      label: 'High',
      color: '#FB7574',
    },
    2: {
      label: 'Medium',
      color: '#FE9320',
    },
    3: {
      label: 'Low',
      color: '#404F67',
    },
  };
  return (
    <Wrp
      className={classname('priority-block', className)}
      status={status[priority]}>
      <Icon name="warning" color={status[priority]?.color} />
      <Tooltip content={`${status[priority]?.label} priority`}>
        {status[priority]?.label} priority
      </Tooltip>
    </Wrp>
  );
};

const Wrp = styled.div`
  display: flex;
  align-items: center;
  column-gap: 6px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: ${({ status }) => status?.color};
`;

PriorityBlock.propTypes = {
  className: string,
  priority: string,
};
