import './textarea.scss';
import cn from 'classname';
import { useState } from 'react';
import { Box } from '../box';
import { checkForSpacesInStartEnd } from 'helpers';

export const Textarea = ({
  label,
  placeholder,
  value,
  rows,
  cols,
  onChange,
  onBlur,
  disabled = false,
  customStyle,
  errorMessage,
  required = false,
}) => {
  const [showError, setShowError] = useState(false);
  const onBlurHandler = (e) => {
    typeof onBlur === 'function' && onBlur(e);
    setShowError(true);
  };
  const isErrors = showError
    ? required &&
      (value === '' || value === null || checkForSpacesInStartEnd(value))
    : false;

  return (
    <div className="textarea-wrapper">
      {label !== '' && (
        <label className="error-input label-style">
          {label}
          <span style={{ color: '#FF1E26' }}>{`${required ? '*' : ''}`}</span>
        </label>
      )}
      <Box position="relative">
        <textarea
          style={customStyle}
          className={cn(
            'textarea-style',
            isErrors && 'error-input',
            disabled && 'disabled'
          )}
          rows={rows}
          cols={cols}
          disabled={disabled}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlurHandler}
          value={value}></textarea>
      </Box>
      {((required && value === '' && showError) || isErrors) && (
        <small className="has-error">
          {errorMessage
            ? errorMessage
            : checkForSpacesInStartEnd(value)
            ? 'The value should not have leading or trailing whitespace. Please remove any extra spaces.'
            : label && typeof label === 'string'
            ? `Please enter ${label.toLowerCase()}`
            : 'Please enter valid value'}
        </small>
      )}
    </div>
  );
};
