import { isValidPhoneNumber } from 'react-phone-number-input';
import { Box, Input, PhoneNumberInputBox } from '../../../components';
import 'react-phone-number-input/style.css';
import './largeE.scss';

const LargeInterprise = ({
  handleInputChange,
  state,
  setState,
  handleInputBlur,
}) => {
  const { name, link, contact, email, error } = state;

  const onBlur = async () => {
    await setState({
      ...state,
      error: {
        ...error,
        contact:
          (!contact || !isValidPhoneNumber(contact)) &&
          'Please enter a valid mobile phone number.',
      },
    });
  };

  return (
    <div className="LargeE">
      {/* Form Fields */}
      <div className="row">
        <div className="col-md-6 col-lg-6 col-12">
          <Box mt="20px">
            <Input
              label="Enterprise Name"
              iconLeft={true}
              iconName="enterprise"
              placeholder="Full Name"
              type="text"
              required
              value={name}
              onChange={(value) =>
                handleInputChange('name', value.target.value)
              }
              hasError={error?.name}
              errorMessage={error?.name}
            />
          </Box>
        </div>
        <div className="col-md-6 col-lg-6 col-12">
          <Box mt="20px">
            <Input
              label="Link (Website)"
              iconLeft={true}
              iconName="global"
              placeholder="www.example.com"
              type="text"
              required
              value={link}
              onChange={(value) =>
                handleInputChange('link', value.target.value)
              }
              hasError={error?.link}
              errorMessage={error?.link}
            />
          </Box>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-6 col-12">
          <Box mt="20px">
            <Input
              label="Email"
              iconLeft={true}
              iconName="sms"
              placeholder="Email"
              type="email"
              required
              value={email}
              onBlur={(value) => handleInputBlur('email', value.target.value)}
              onChange={(value) =>
                handleInputChange('email', value.target.value)
              }
              hasError={error?.email}
              errorMessage={error?.email}
            />
          </Box>
        </div>
        <div className="col-md-6 col-lg-6 col-12">
          <Box mt="20px">
            <PhoneNumberInputBox
              label={'Contact Number'}
              placeholder="Contact Number"
              required
              value={contact}
              onChange={(value) => handleInputChange('contact', value)}
              onBlur={onBlur}
              hasError={error?.contact}
              errorMessage={error?.contact}
            />
          </Box>
        </div>
      </div>
    </div>
  );
};

export default LargeInterprise;
