import { userScopes } from 'utlils';

export const getLeftSideData = (type, teamId) => {
  let data = [
    {
      type: 'members',
      iconName: 'people',
      title: 'Members',
      url: `/${type}/team-dashboard/${teamId}/members`,
      hasSubMenu: false,
      permission: userScopes.ACCESS_MEMBER_PAGE,
    },
    {
      type: 'projects',
      iconName: 'project',
      title: 'Projects',
      url: `/${type}/team-dashboard/${teamId}/projects`,
      hasSubMenu: false,
      permission: userScopes.ACCESS_PROJECT_PAGE,
    },
    {
      type: 'tasks',
      iconName: 'task',
      title: 'Tasks',
      url: `/${type}/team-dashboard/${teamId}/tasks/project-tasks`,
      hasSubMenu: false,
      permission: userScopes.ACCESS_TASK_PAGE,
    },
    {
      type: 'requests',
      iconName: 'request',
      title: 'Requests',
      url: `/${type}/team-dashboard/${teamId}/requests`,
      hasSubMenu: false,
      permission: userScopes.ACCESS_REQUEST_PAGE,
    },
    {
      type: 'messaging',
      iconName: 'comment-btn',
      title: 'Messaging',
      url: `/${type}/team-dashboard/${teamId}/messaging`,
      hasSubMenu: false,
      permission: userScopes.ACCESS_REQUEST_PAGE,
    },
    {
      type: 'career',
      iconName: 'brief-case',
      title: 'Career',
      url: `/${type}/team-dashboard/${teamId}/career/`,
      hasSubMenu: true,
      elements: [
        {
          type: 'create-job',
          title: 'Post a Job',
          url: `/${type}/team-dashboard/${teamId}/career/create-job`,
          permission: userScopes.CREATE_JOB,
        },
        {
          type: 'posted-jobs',
          title: 'Posted Jobs',
          url: `/${type}/team-dashboard/${teamId}/career/posted-jobs`,
          permission: userScopes.ACCESS_POSTED_JOB,
        },
        {
          type: 'shortlisted',
          title: 'Shortlisted',
          url: `/${type}/team-dashboard/${teamId}/career/shortlisted`,
          permission: userScopes.ACCESS_JOB_SHORTLISTED,
        },
      ],
      permission: userScopes.ACCESS_CAREER_PAGE,
    },
    {
      type: 'setting',
      iconName: 'setting',
      title: 'Settings',
      url: `/${type}/team-dashboard/${teamId}/teamSettings/`,
      hasSubMenu: true,
      elements: [
        {
          type: 'profile-setting',
          title: 'Profile Setting',
          url: `/${type}/team-dashboard/${teamId}/teamSettings/profile-setting`,
        },
        {
          type: 'privacy',
          title: 'Privacy',
          url: `/${type}/team-dashboard/${teamId}/teamSettings/privacy`,
        },
      ],
      permission: userScopes.ACCESS_TEAM_SETTINGS,
    },
    {
      type: 'publications',
      iconName: 'book',
      title: 'Publications',
      url: `/${type}/team-dashboard/${teamId}/publications`,
      hasSubMenu: false,
      permission: userScopes.ACCESS_PUBLICATION_PAGE,
    },
  ];

  return data;
};

export const TEAM_DASHBOARD_NAV_LIST = (type) => {
  return [
    {
      id: 'home',
      title: 'Home',
      url:
        type === 'enterprise' || type === 'SMALL_ENTERPRISE'
          ? '/enterprise/profile/view/details'
          : '/individual/profile/info/basic_details',
      isDropdown: false,
    },
    type?.toLowerCase() === 'individual' && {
      id: 'explore',
      title: 'Explore',
      isDropdown: true,
      dropDownItems: [
        {
          id: 'explore_teams',
          title: 'Explore Teams',
          url: '/explore/jobs/search-job',
        },
        {
          id: 'explore_projects',
          title: 'Explore Projects',
          url: '/explore/teams/search-team',
        },
        {
          id: 'explore_jobs',
          title: 'Explore Jobs',
          url: '/explore/jobs/search-job',
        },
        {
          id: 'explore_networks',
          title: 'Explore Networks',
          url: '/explore/networks/search-people',
        },
        {
          id: 'explore_groups',
          title: 'Explore Groups',
          url: '/explore/groups/search-groups',
        },
      ],
    },
    {
      id: 'messages',
      title: 'Messages',
      url:
        type === 'individual'
          ? '/individual/messages/conversation/all'
          : '/enterprise/messages/conversation/all',
      isDropdown: false,
      isIcon: true,
      iconName: 'message',
      iconColor: '#2AC173',
    },
  ];
};

export const EXPLORE_TEAM_PAGE_VISIBILITY_OPTIONS = [
  { text: 'Yes', value: 'true' },
  { text: 'No', value: 'false' },
];

export const LINK_REQUEST_OPTIONS = [
  { text: 'All users', value: 'ALL_USERS' },
  { text: 'No-one', value: 'NO_ONE' },
];

export const ENTERPRISE_TEAM_LINK_REQUEST_OPTIONS = [
  { text: 'All users', value: 'ALL_USERS' },
  { text: 'No-one', value: 'NO_ONE' },
  { text: 'Enterprise', value: 'ENTERPRISE' },
];
