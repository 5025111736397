import { Route, Routes } from 'react-router-dom';
import CreateProfile from './createProfile';
import ViewProfile from './viewProfile';

const EnterpriseProfile = ({ userProfile, setUserProfile }) => {
  return (
    <div>
      <Routes>
        <Route path="">
          <Route
            element={
              <CreateProfile
                userProfile={userProfile}
                setUserProfile={setUserProfile}
              />
            }
            exact
            path="create/:step"
          />
          <Route
            element={<ViewProfile userProfile={userProfile} />}
            exact
            path="view/:step"
          />
        </Route>
      </Routes>
    </div>
  );
};

export default EnterpriseProfile;
