import { CustomButton, ModalPopup, ScrollableDiv, Textarea } from 'components';
import { checkForFileType } from 'pages/IndividualDashboard/individualOrganizations/enterprise/helper';
import { currencyLists } from 'pages/Projects/ViewProject/ProjectCollaboration/constant';
import React from 'react';
import { toast } from 'react-toastify';
import { ACCEPT_REJECT_BIDDER_APPLICATION, useAxiosWrapper } from 'services';
import tokenService from 'services/token.service';
import Icon from 'utlils/Icons/Icon';

export default function ViewBidderApplication(props) {
  const {
    setViewApplication,
    setViewApplicationData,
    getBiddingApplicantList,
  } = props;
  const [fnWrapper, context] = useAxiosWrapper();
  const loggedInUser = tokenService.getSession();

  const acceptRejectApplication = async (status) => {
    let payload = {
      applicantBidderId: setViewApplicationData?.id,
      status: status,
      approvedByUserId: loggedInUser.id,
    };
    const res = await fnWrapper({
      url: ACCEPT_REJECT_BIDDER_APPLICATION,
      method: 'PUT',
      type: 'ACCEPT_REJECT_APPLICATION',
      payload: payload,
    });
    if (res.data.statusCode === 200) {
      await getBiddingApplicantList();
      setViewApplication(false);
      toast.success(res.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  const isProjectAdmin =
    setViewApplicationData?.appliedBidding?.project?.userId === loggedInUser.id;

  const getFileName = (file) => {
    const setFileName = file.split('/');
    return setFileName[setFileName.length - 1];
  };

  return (
    <div>
      <ModalPopup
        open={false}
        onClose={() => setViewApplication(false)}
        minWidth="1000px"
        maxContentWidth="1000px"
        hasTitle
        title="Bidder Application">
        <ScrollableDiv
          scrollOnHeight="calc(100vh - 290px)"
          scrollOnHeightMob="300px">
          {setViewApplicationData && (
            <div className="applicants-wrapper">
              {setViewApplicationData?.questionsToBidder !== null &&
              setViewApplicationData?.questionsToBidder
                ? Object.keys(setViewApplicationData?.questionsToBidder).map(
                    (key, index) => (
                      <div className="question_wrapper" key={index}>
                        <div className="sub-heading">
                          <span>{index + 1}. </span>
                          {key}
                        </div>
                        <div className="pb-textarea">
                          <Textarea
                            rows={4}
                            disabled={true}
                            value={
                              setViewApplicationData?.questionsToBidder[key]
                            }
                          />
                        </div>
                      </div>
                    )
                  )
                : null}
              <div className="sub-heading mt-3 mb-2">
                {setViewApplicationData?.questionsToBidder !== null
                  ? Object.keys(setViewApplicationData?.questionsToBidder)
                      .length +
                    1 +
                    '. Bid placed'
                  : '1. Bid placed'}
              </div>
              <div className="pb-textarea">
                <div className="bid-item">
                  {currencyLists[setViewApplicationData.currencyType] +
                    setViewApplicationData.bidderAmount}
                </div>
              </div>
              <div className="sub-heading mt-3 mb-2">
                {setViewApplicationData?.questionsToBidder !== null
                  ? Object.keys(setViewApplicationData?.questionsToBidder)
                      .length +
                    2 +
                    '. Forms attached'
                  : null}
              </div>
              <div className="pb-textarea">
                {
                  <div className="post-attachment">
                    {setViewApplicationData?.signedDocuments?.map(
                      (elem, index) => {
                        let element = JSON.parse(elem);
                        const fileName = getFileName(element.location);
                        return (
                          <div className="img-wrapper" key={index}>
                            <div className="img-container">
                              {checkForFileType(fileName) === 'others' ? (
                                <Icon name="attachment" />
                              ) : (
                                <img
                                  src={checkForFileType(fileName)}
                                  alt="attachment"
                                />
                              )}
                            </div>
                            <a
                              className={
                                element.applicantLocation
                                  ? 'img-text'
                                  : 'file-not-uploaded'
                              }
                              href={element.applicantLocation}
                              target="_blank"
                              rel="noreferrer">
                              {fileName}
                            </a>
                            <div className="icon-content">
                              <Icon
                                name={
                                  element.applicantLocation
                                    ? 'tickIcon'
                                    : 'small-close'
                                }
                                color={
                                  element.applicantLocation
                                    ? '#0ABD00'
                                    : 'rgba(146, 157, 175, 1)'
                                }
                                size={18}
                              />
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                }
              </div>
              <div className="button-content">
                <div className="reject-btn">
                  <CustomButton
                    label={'Reject Request'}
                    color="themeTertiary"
                    variant="textBtn"
                    loading={
                      context.busy.status &&
                      context.busy.type === 'ACCEPT_REJECT_APPLICATION'
                    }
                    disabled={
                      setViewApplicationData?.status === 'ACCEPTED' ||
                      setViewApplicationData?.status === 'REJECTED' ||
                      !isProjectAdmin
                    }
                    onClick={() => acceptRejectApplication('rejected')}
                  />
                </div>
                <div className="approve-btn">
                  <CustomButton
                    label={'Approve Request'}
                    onClick={() => acceptRejectApplication('accepted')}
                    loading={
                      context.busy.status &&
                      context.busy.type === 'ACCEPT_REJECT_APPLICATION'
                    }
                    disabled={
                      setViewApplicationData?.status === 'ACCEPTED' ||
                      setViewApplicationData?.status === 'REJECTED' ||
                      !isProjectAdmin
                    }
                    className="w-100"
                  />
                </div>
              </div>
            </div>
          )}
        </ScrollableDiv>
      </ModalPopup>
    </div>
  );
}
