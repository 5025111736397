export const enterpriseInfoData = [
  {
    type: 'profile/view/details',
    iconName: 'info',
    title: 'Info',
    url: '/enterprise/profile/view/details',
    hasSubMenu: false,
  },
  {
    type: 'departments',
    iconName: 'data',
    title: 'Departments',
    url: '/enterprise/departments',
    hasSubMenu: false,
  },
  {
    type: 'People',
    iconName: 'people',
    title: 'People',
    url: '/enterprise/people',
    hasSubMenu: false,
  },
  {
    type: 'career',
    iconName: 'brief-case',
    title: 'Career',
    url: '',
    hasSubMenu: true,
    elements: [
      {
        type: 'postedJobs',
        title: 'Posted Jobs',
        url: `/enterprise/career/postedJobs`,
      },
      {
        type: 'shortlisted',
        title: 'Shortlisted',
        url: `/enterprise/career/shortlisted`,
      },
    ],
  },
];

export const DEPARTMENTS_LIST = [
  { value: '1', label: 'HR' },
  { value: '2', label: 'Electrical' },
  { value: '3', label: 'Development' },
  { value: '4', label: 'Quality Testing' },
  { value: '5', label: 'Maintenance' },
  { value: '5', label: 'Network Support' },
  { value: '5', label: 'React Development' },
  { value: '5', label: 'Construction and Civil' },
];

export const TEAM_TYPES = [
  { value: '1', label: 'Type1' },
  { value: '2', label: 'Type2' },
  { value: '3', label: 'Type3' },
  { value: '4', label: 'Type4' },
];

export const TEAM_MEMBER_ROLES = [
  { value: '1', label: 'Member' },
  { value: '2', label: 'Team Leader' },
];

export const DEPARTMENT_TAB_LIST = [
  'Published Problems',
  'Teams',
  'Projects',
  'Members',
];

export const TASK_STATUS = {
  ALL: '0',
  PENDING: '1',
  IN_PROGRESS: '2',
  DECLINED: '3',
  COMPLETED: '4',
};
export const TASK_TOGGLE_FLAG = {
  MY_TASKS: '0',
  OTHER_TASKS: '1',
};
export const TASK_TOGGLE_LABEL = {
  MY_TASKS: 'My Tasks',
  OTHER_TASKS: 'Other Tasks',
};
export const TEAMS_PROJECTS_TOGGLE_LABEL = {
  TEAMS: 'Teams',
  PROJECTS: 'Projects',
};
export const STATUS_DROPDOWN_LIST = [
  { label: 'All', value: '0' },
  { label: 'Pending', value: '1' },
  { label: 'In Progress', value: '2' },
  { label: 'Declined', value: '3' },
  { label: 'Completed', value: '4' },
];

export const STATUS_DROPDOWN_LIST_VIEW_TASK = [
  // { label: 'Pending', value: '1' },
  { label: 'In Progress', value: '2' },
  { label: 'Declined', value: '3' },
  { label: 'Completed', value: '4' },
];

export const TASK_URGENCY_LIST = [
  { value: '3', label: 'Low' },
  { value: '2', label: 'Medium' },
  { value: '1', label: 'High' },
];
export const TASK_PRIORITY_LIST = [
  { value: '3', label: 'Low' },
  { value: '2', label: 'Medium' },
  { value: '1', label: 'High' },
];
export const ASSIGN_TEAM_LIST = [
  { value: '1', label: 'Team 1' },
  { value: '2', label: 'Team 2' },
  { value: '3', label: 'Team 3' },
  { value: '4', label: 'Team 4' },
];
export const MY_TASK_ACTION_OPTIONS = [
  {
    id: 'COPY_LINK',
    label: 'Copy link',
  },
];
export const OTHER_TASK_ACTION_OPTIONS = [
  {
    id: 'EDIT',
    label: 'Edit',
  },
  {
    id: 'REMOVE',
    label: 'Remove',
  },
  {
    id: 'COPY_LINK',
    label: 'Copy link',
  },
];

export const otherTasksStatus = {
  1: {
    label: 'Pending',
    bgColor: 'white',
    textColor: '#7E8F9A',
    borderRadius: '10px',
    fontWeight: '600',
    fontSize: '14px',
  },
  2: {
    label: 'In Progress',
    bgColor: 'white',
    textColor: '#FFA412',
    borderRadius: '10px',
    fontWeight: '600',
    fontSize: '14px',
  },
  3: {
    label: 'Declined',
    bgColor: 'white',
    textColor: '#E66657',
    borderRadius: '10px',
    fontWeight: '600',
    fontSize: '14px',
  },
  4: {
    label: 'Completed',
    bgColor: 'white',
    textColor: '#0ABD00',
    borderRadius: '10px',
    fontWeight: '600',
    fontSize: '14px',
  },
};
export const ENTERPRISE_ACTION_OPTIONS = [
  {
    id: 0,
    label: 'Edit',
    // department: item,
  },
  {
    id: 1,
    label: 'Remove',
    // departmentId: item.id,
  },
];

export const TASK_ATTACHMENTS_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'application/ms-excel',
  'application/msword',
  'application/pdf',
  'application/vnd.ms-excel',
  'text/plain',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const TASK_PRIORITY_VALUES = {
  1: 'High Priority',
  2: 'Medium Priority',
  3: 'Low Priority',
};

export const ENTERPRISETEAMTABLEHEADERS = [
  {
    title: 'Team Name',
    sortable: true,
    sortKey: 'teamName',
  },
  {
    title: 'Created On',
    sortable: true,
    sortKey: 'createdOn',
  },
  {
    title: 'Admin',
    sortable: false,
    sortKey: 'admin',
  },
  {
    title: 'Members',
    sortable: true,
    sortKey: 'members',
  },
  {
    title: '',
    sortable: false,
    sortKey: 'action',
  },
];

export const progressData = [
  {
    id: 1,
    title: 'Information',
  },
  {
    id: 2,
    title: 'Member',
  },
  {
    id: 3,
    title: 'Done',
  },
];

export const INVITEMEMBERROLE = [
  { id: 1, label: 'Admin', value: 'ADMIN' },
  { id: 2, label: 'Co-Admin', value: 'COADMIN' },
  { id: 3, label: 'Member', value: 'MEMBER' },
];

export const VIEW_TASK_POPUP_MENU_OPTIONS = [
  {
    id: 'SET_PRIORITY',
    label: 'Set Priority',
  },
  {
    id: 'CHANGE_TEAM',
    label: 'Change Team',
  },
  {
    id: 'COPY_LINK',
    label: 'Copy Link',
  },
];

export const ENTERPRISE_PROFILE_OPTIONS = [
  {
    id: 'details',
    icon: { name: 'user' },
    title: 'Details',
  },
  {
    id: 'location',
    icon: { name: 'location' },
    title: 'Location',
  },
];

export const ENTERPRISE_SIDE_BAR_PROFILE_OPTIONS = [
  {
    type: 'details',
    iconName: 'user',
    title: 'Details',
  },
  {
    type: 'location',
    iconName: 'location',
    title: 'Location',
  },
];

export const ENTERPRISE_DASHBOARD_NAV_LIST = [
  {
    id: 'home',
    title: 'Home',
    url: '/enterprise/profile/view/details',
    isDropdown: false,
  },
  {
    id: 'messages',
    title: 'Messages',
    url: '/enterprise/messages/conversation/all',
    isDropdown: false,
    isIcon: true,
    iconName: 'message',
    iconColor: '#2AC173',
  },
];
