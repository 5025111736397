import { CustomButton } from 'components';
import Icon from 'utlils/Icons/Icon';
import { getFormattedDate } from '../utils';

const CollaborationInvite = ({
  context,
  invite,
  collaboration,
  setSelectedCollaboration,
  handleDeclineInvitation,
}) => {
  return (
    <div className="invitation-wrapper">
      <div className="invitation-content">
        <div className="invitation-icon-container">
          <div className="invitation-icon-wrapper">
            <Icon name="celo" className="invitation-icon" color="#0ABD00" />
          </div>
        </div>
        <div className="invitation-highlight-wrapper">
          <span className="invitation-highlight">
            {`${
              invite?.invitedBy?.individual
                ? invite?.invitedBy?.individual?.name
                : invite?.invitedBy?.smallEnterprise?.enterpriseName
            } `}
          </span>
          invited you to collaborate on
          <span className="invitation-highlight">{` ${collaboration?.collaborationType} `}</span>
          {invite?.description ? (
            <p>
              <span className="invitation-description">{` ${invite?.description} `}</span>
            </p>
          ) : null}
        </div>
        <div className="invitation-time-container">
          <span className="invitation-time">
            {getFormattedDate(invite?.createdAt)}
          </span>
        </div>
      </div>
      <div className="invitation-button">
        <div className="apply-button">
          <CustomButton
            loading={context.busy.status}
            label="Collaborate"
            className="w-100"
            onClick={() => setSelectedCollaboration(collaboration)}
          />
        </div>
        <div className="apply-button">
          <CustomButton
            color="themeTertiary"
            variant="light"
            loading={context.busy.status}
            label="Decline"
            onClick={() => {
              handleDeclineInvitation(invite?.id);
            }}
            className="w-100"
          />
        </div>
      </div>
    </div>
  );
};

export default CollaborationInvite;
