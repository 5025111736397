import cx from 'classname';
import styled from 'styled-components';
import { arrayOf, shape, string } from 'prop-types';
import { getProfileText } from 'utlils/utils';
import { Tooltip } from 'components';

export const MultiUserProfile = (props) => {
  const {
    bgColor = '#f4f7fc',
    curveColor = '#ffffff',
    className,
    height = '40px',
    width = '40px',
    usersInfo,
  } = props;
  return (
    <Wrp
      bgColor={bgColor}
      curveColor={curveColor}
      className={cx('mu mu-user-list', className)}
      height={height}
      data-tooltip-id="usersInfoTooltip"
      width={width}>
      {usersInfo?.length ? (
        usersInfo?.map((user, index) => (
          <Tooltip
            key={index}
            content={usersInfo
              .map((user) => user?.name || user?.enterpriseName)
              .join(', ')}
            placement="right">
            <div
              className={cx(
                'mu__user-info',
                user?.profilePicture && 'hasProfilePhoto',
                usersInfo?.length >= 1 &&
                  index !== usersInfo?.length - 1 &&
                  'mu__curve-block'
              )}
              key={index}>
              {user?.profilePicture ? (
                <img
                  src={user?.profilePicture}
                  alt="profileImage"
                  width="100%"
                  height="100%"
                  className="profile-img"
                />
              ) : (
                <div className="profile-text">{`${getProfileText(
                  user?.name || user?.enterpriseName
                )}`}</div>
              )}
            </div>
          </Tooltip>
        ))
      ) : (
        <Tooltip content={'No user assigned'}>
          <div
            data-tooltip-id="noUsersInfoTooltip"
            className="profile-text no-profile"
          />
        </Tooltip>
      )}
    </Wrp>
  );
};

const Wrp = styled.div`
  display: flex;
  align-items: center;
  margin: -5px;
  & .mu__ {
    &user-info {
      border-radius: 50%;
      border: ${({ curveColor }) => `5px solid ${curveColor}`};
      background: ${({ bgColor }) => bgColor};
      &.hasProfilePhoto {
        background: unset;
      }
    }
    &curve-block {
      margin-right: -14px;
    }
  }
  & .profile-img {
    border-radius: 50%;
    height: ${({ height }) => height};
    width: ${({ width }) => width};
  }
  & .profile-text {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    height: ${({ height }) => height};
    width: ${({ width }) => width};
    &.no-profile {
      background: #f4f7fc;
      border-radius: 50%;
    }
  }
`;

MultiUserProfile.propTypes = {
  bgColor: string,
  curveColor: string,
  className: string,
  height: string,
  width: string,
  usersInfo: arrayOf(
    shape({
      profilePicture: string,
      name: string,
      enterpriseName: string,
    })
  ),
};
