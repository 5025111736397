import { StatusButton, NavigationalRowItem } from 'components';
import { useNavigate } from 'react-router-dom';
import arrowCircle from '../../../src/assests/images/arrow-circle-right.png';
import dayjs from 'dayjs';

export const MEMBER_STATUS_TYPE = [
  { id: 1, label: 'Pending', value: '1' },
  //{ id: 2, label: 'Left', value: '2' },
  { id: 3, label: 'Rejected', value: '3' },
  { id: 4, label: 'Accepted', value: '4' },
  { id: 5, label: 'Removed', value: '5' },
];

export const FILTERDATETYPE = [
  {
    label: 'Today',
    value: 'TD',
  },
  {
    label: 'Yesterday',
    value: 'YD',
  },
  {
    label: 'Custom',
    value: 'CD',
  },
];

export const MEMBER_FILTERS = {
  Date: {
    label: 'Date',
    dateType: FILTERDATETYPE,
    key: 'label',
    placeholder: 'Select Date',
  },
  Designation: {
    label: 'Designation',
  },
  Status: {
    label: 'Status',
    statusType: MEMBER_STATUS_TYPE,
    key: 'label',
    placeholder: 'Select Status',
  },
};

let memberStatus = {
  1: {
    label: 'Pending',
    bgColor: 'transparent',
    textColor: '#219FFF',
  },
  2: {
    label: 'Left',
    bgColor: 'transparent',
    textColor: '#FF1E26',
  },
  3: {
    label: 'Rejected',
    bgColor: 'transparent',
    textColor: '#ff1b1b',
  },
  4: {
    label: 'Accepted',
    bgColor: 'transparent',
    textColor: '#0ABD00',
  },
  5: {
    label: 'Removed',
    bgColor: 'transparent',
    textColor: '#FF1E26',
  },
};

let projectStatus = {
  PUBLISHED: {
    label: 'Published',
    bgColor: 'transparent',
    textColor: '#0ABD00',
  },
  ONGOING: {
    label: 'Ongoing',
    bgColor: 'transparent',
    textColor: '#219FFF',
  },
  LEFT: {
    label: 'Left',
    bgColor: 'transparent',
    textColor: '#ff1b1b',
  },
};

let teamRoleStatus = {
  ADMIN: {
    label: 'Admin',
    bgColor: '#E2FFE0',
    textColor: '#0ABD00',
  },
  COADMIN: {
    label: 'Co-Admin',
    bgColor: 'rgba(33, 159, 255, 0.25);',
    textColor: '#219FFF',
  },
  MEMBER: {
    label: 'Member',
    bgColor: '#F0F0F0',
    textColor: '#929DAF',
  },
};

export const MEMBERSTABLEHEADERS = [
  {
    title: 'Member Name',
    sortable: false,
    sortKey: 'memberName',
  },
  {
    title: 'Email id',
    sortable: false,
    sortKey: 'emailId',
  },
  {
    title: 'Designation',
    sortable: false,
    sortKey: 'designation',
  },
  {
    title: 'Invited On',
    sortable: true,
    sortKey: 'joinedOn',
  },
  {
    title: 'Role',
    sortable: false,
    sortKey: 'role',
  },
  {
    title: 'Status',
    sortable: true,
    sortKey: 'status',
  },
];

export const TEAM_PROJECTS_TABLE_HEADERS = [
  {
    title: 'Project Name',
    sortable: false,
    sortKey: 'projectName',
  },
  {
    title: 'Admin',
    sortable: false,
    sortKey: 'admin',
  },
  {
    title: 'Status',
    sortable: true,
    sortKey: 'status',
  },
  {
    title: 'Added On',
    sortable: true,
    sortKey: 'addedOn',
  },
  {
    title: '',
    sortKey: 'action',
  },
];

export const membersTableData = (data) => {
  let newData = [];
  if (data.length) {
    newData = data.map((item) => {
      return {
        id: item.id,
        memberName: (
          <NavigationalRowItem
            label={item.memberName}
            navigateTo={
              item.isIndividual
                ? `/public/individual/${item.memberUserId}/summary/basic-details`
                : ''
            }
            isOpenInNewTab
            isIndividualUser={item.isIndividual}
            customStyle={{ color: '#404f67' }}
          />
        ),
        emailId: item.emailId,
        designation: item.designation,
        joinedOn: dayjs(item.joinedOn).format('DD MMM YYYY'),
        role: <StatusButton status={teamRoleStatus[item.role]} />,
        status: <StatusButton status={memberStatus[item.status]} />,
        memberUserId: item.memberUserId,
      };
    });
  } else {
    newData = [];
  }

  return newData;
};

export const teamProjectsTableData = (data, teamId, userType) => {
  let newData = [];
  if (data.length) {
    newData = data.map((item) => {
      return {
        id: item.id,
        projectName: (
          <NavigationalRowItem
            label={item.projectName}
            navigateTo={`/public/project/${item.id}/details`}
            isOpenInNewTab
            isIndividualUser={true}
            customStyle={{ color: '#404f67' }}
          />
        ),
        admin: item.admin,
        status: <StatusButton status={projectStatus[item.status]} />,
        addedOn: dayjs(item.addedOn).format('DD MMM YYYY'),
        action: (
          <ProjectListAction
            projectId={item.id}
            teamId={teamId}
            type={userType}
          />
        ),
      };
    });
  } else {
    newData = [];
  }

  return newData;
};

export const ProjectListAction = ({ projectId, teamId, type }) => {
  const navigate = useNavigate();
  return (
    <img
      role="button"
      key={projectId}
      src={arrowCircle}
      alt=" arrow-right "
      onClick={() =>
        navigate(
          `/${type}/team-dashboard/${teamId}/projects/${projectId}/view/details`
        )
      }
    />
  );
};

export const TASK_STATUS = {
  IN_PROGRESS: '2',
  COMPLETED: '4',
};

export const TASK_PRIORITY = {
  1: {
    label: 'High Priority',
    textColor: '#E66657',
  },
  2: {
    label: 'Medium Priority',
    textColor: '#FFA412',
  },
  3: {
    label: 'Low Priority',
    textColor: '#7E8F9A',
  },
};
export const TASK_CARD_ACTION_OPTIONS = [
  {
    id: 1,
    label: 'Re-assign Member',
  },
];
export const PUBLICATION_CARD_ACTION_OPTIONS = [
  {
    id: 1,
    label: 'Edit',
  },
  {
    id: 2,
    label: 'Remove',
  },
];
