import PropTypes from 'prop-types';
import { SearchInputStyle } from './style';
import Icon from 'utlils/Icons/Icon';
import { dummyFn } from 'utlils/utils';
import cn from 'classname';
export const SearchInput = ({
  placeHolder = 'Search...',
  onSearch,
  onBlur,
  className = '',
  value = null,
  onSearchange,
  onCloseSearch,
  ...rest
}) => {
  return (
    <SearchInputStyle
      className={cn('search-box', `${className}`)}
      disabled={value?.length <= 2}>
      <div className="search-box__inputContainer">
        <div className="search-box__inputContainer__search--icon">
          <Icon name="search" color="#929daf" />
        </div>
        <input
          className="search-box__inputContainer__input--box"
          value={value}
          onChange={onSearchange}
          placeholder={placeHolder}
          onBlur={onBlur}
        />
        {value && (
          <div className="search-box__inputContainer__cancel--search">
            <Icon
              name="cross"
              color="black"
              className="search-box__inputContainer__cancel--search__crossButton"
              onClick={onCloseSearch}
            />
          </div>
        )}
      </div>
      <div
        className="search-box__icon"
        onClick={() => (value.length > 2 ? onSearch() : dummyFn())}>
        <Icon name="search" color="white" />
        <div>{'Search'}</div>
      </div>
    </SearchInputStyle>
  );
};

SearchInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  placeHolder: PropTypes.string,
  className: PropTypes.string,
  onSearchange: PropTypes.func,
  onCloseSearch: PropTypes.func,
};
