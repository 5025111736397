import { useState, useEffect } from 'react';
import { getData } from 'country-list';
import { NetworkProfileCard } from 'pages/Explore/Components';
import { FETCH_ALL_PUBLIC_USERS, useAxiosWrapper } from 'services';
import TokenService from 'services/token.service';
import { Loader, Pagination } from 'components';
import { CREATE_USER_CONNECTION } from 'services';
import { CustomButton, DropDown } from 'components';
import { SearchInput } from 'components';
import { truncateString } from 'utlils/utils';
import { isMobile } from 'utlils';
import Icon from 'utlils/Icons/Icon';
export const SearchPeople = () => {
  const [publicUsers, setPublicUsers] = useState([]);
  const [fnWrapper, context] = useAxiosWrapper();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const countryList = getData();
  const [country, setCountry] = useState({
    name: '',
    value: '',
  });
  const [filterName, setfilterName] = useState('');
  let pageSize = 15;

  const paramsOnChangeHandler = (field, value) => {
    if (field === 'search') {
      setfilterName(value);
    }
    if (field === 'country') {
      setCountry(value);
    }
  };
  const getAllPublicUsers = async (args) => {
    const {
      clearParams = '',
      isSearchOrFilter = false,
      pageNumber,
    } = args || {};
    let filter = filterName;
    let countryFilter = country?.value;
    let pageFilter = isSearchOrFilter
      ? ''
      : pageNumber
      ? pageNumber
      : currentPage;
    if (clearParams === 'search') {
      setfilterName('');
      filter = '';
    } else if (clearParams === 'filter') {
      setCountry({
        name: '',
        value: '',
      });
      countryFilter = '';
    }
    const loggedInUser = TokenService.getSession();
    const res = await fnWrapper({
      url: `${FETCH_ALL_PUBLIC_USERS}/${loggedInUser.id}?page=${pageFilter}&size=${pageSize}&search=${filter}&country=${countryFilter}`,
      method: 'GET',
      type: 'FETCH_GET_ALL_PUBLIC_USERS',
    });
    if (res.data.statusCode === 200) {
      setPublicUsers(res.data.data);
    } else {
      setPublicUsers([]);
    }
  };
  useEffect(() => {
    getAllPublicUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const connectWithProfileHandler = async (userId) => {
    const payload = {
      connectWithId: userId,
      type: 'CONNECT',
    };
    const res = await fnWrapper({
      url: CREATE_USER_CONNECTION,
      method: 'POST',
      type: 'CREATE_USER_CONNECTION',
      payload: payload,
    });
    if (res.data.statusCode === 200) {
      getAllPublicUsers();
    }
  };

  return (
    <div className="position-relative">
      <div className="explore-searchBox-container">
        <SearchInput
          onSearch={() => {
            getAllPublicUsers({ isSearchOrFilter: true });
            setIsFilterOpen(false);
          }}
          onSearchange={(e) => {
            paramsOnChangeHandler('search', e.target.value);
            if (e.target.value === '') {
              getAllPublicUsers({
                clearParams: 'search',
                isSearchOrFilter: true,
              });
            }
          }}
          value={filterName}
          onCloseSearch={() => {
            getAllPublicUsers({
              clearParams: 'search',
              isSearchOrFilter: true,
            });
          }}
        />
        <CustomButton
          variant="light"
          onClick={() => setIsFilterOpen(!isFilterOpen)}
          color={
            isFilterOpen || country?.name ? 'themeSecondary' : 'themeGray2'
          }>
          <Icon
            name="filter"
            size={20}
            color={
              isFilterOpen || country?.name
                ? 'rgba(33, 159, 255, 1)'
                : '#404f67'
            }
          />
          Filter
        </CustomButton>
      </div>
      {isFilterOpen && (
        <div className="country-filter">
          <DropDown
            label="Country"
            items={countryList}
            objectKey="name"
            placeholder="Select"
            onClick={(item) => {
              paramsOnChangeHandler('country', {
                name: !isMobile()
                  ? truncateString(item.name, 30)
                  : truncateString(item.name, 20),
                value: item.code,
              });
            }}
            value={country.value ? country : ''}
            hasSearchBox
          />
          <div className="country-filter-button d-flex gap-3 align-items-center">
            <CustomButton
              label="Apply"
              className="w-100"
              color="themeSecondary"
              onClick={() => {
                setIsFilterOpen(false);
                getAllPublicUsers({ isSearchOrFilter: true });
              }}
            />
            <CustomButton
              label="Cancel"
              className="w-100"
              color="themeSecondary"
              variant="light"
              onClick={() => {
                getAllPublicUsers({
                  clearParams: 'filter',
                  isSearchOrFilter: true,
                });
                setIsFilterOpen(false);
              }}
            />
          </div>
        </div>
      )}

      <div className="main-wrapper">
        {context.busy.status ? (
          <Loader loading={context.busy.status} />
        ) : publicUsers?.items?.length >= 1 ? (
          <div className="row gy-4">
            {publicUsers.items.map((item) => (
              <div
                className="col-xl-3 col-lg-4 col-md-6 col-sm-12"
                key={item.id}>
                <NetworkProfileCard
                  networkProfileDetails={item}
                  connectWithProfileHandler={connectWithProfileHandler}
                  status={item.connectionStatus}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="no--data">{`No Data Available`}</div>
        )}
      </div>
      {publicUsers?.totalItems > pageSize && (
        <div className="pagination-wrapper">
          <Pagination
            currentPage={currentPage}
            totalCount={publicUsers.totalItems}
            pageSize={pageSize}
            onPageChange={(page) => {
              setCurrentPage(page);
              getAllPublicUsers({ pageNumber: page });
            }}
          />
        </div>
      )}
    </div>
  );
};
