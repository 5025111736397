import { useState, useEffect, useCallback } from 'react';
import cx from 'classname';
import { FETCH_EDUCATIONAL_DETAILS, useAxiosWrapper } from 'services';
import { Box, Loader } from 'components';
import NoDataImg from '../../../../../assests/images/nodata.png';
import './educationDetailsView.scss';
import TokenService from 'services/token.service';

const DEFAULTSTATE = [
  {
    itemId: '',
    instituteName: '',
    degree: '',
    course: '',
    location: '',
    isPersue: false,
    from: new Date(),
    to: new Date(),
  },
];

export const EducationDetailsView = () => {
  const [fnWrapper, context] = useAxiosWrapper();

  const [state, setState] = useState(DEFAULTSTATE);

  const getEducationDetails = useCallback(async () => {
    const loggedInUser = TokenService.getSession();
    const res = await fnWrapper({
      url: `${FETCH_EDUCATIONAL_DETAILS}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_EDUCATIONAL_INFO',
    });
    if (res.data.statusCode === 200) {
      const response = res.data.data;
      let dataArray = [];
      response.forEach((ele) => {
        let data = {
          itemId: ele.id,
          instituteName: ele.instituteName,
          degree: ele.degreeType,
          course: ele.course,
          location: ele.location,
          isPersue: ele.to === null ? true : false,
          from: new Date(ele.from),
          to: ele.to === null ? new Date() : new Date(ele.to),
        };
        dataArray = [...dataArray, data];
      });
      setState(dataArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getEducationDetails();
  }, [getEducationDetails]);

  return (
    <>
      {context.busy.status ? (
        <Loader loading={context.busy.status} />
      ) : (
        <div className="row mb-2 profile-view-container">
          {state.length === 0 ? (
            <div className="empty-data-section">
              <img
                src={NoDataImg}
                alt="No Data"
                className="empty-data-section--img"
              />
              <div className="empty-data-section--text">No data available</div>
            </div>
          ) : (
            state.map((ele, index) => {
              const options = { year: 'numeric', month: 'short' };
              let from = new Date(ele.from).toLocaleString('default', options);
              let to = new Date(ele.to).toLocaleString('default', options);
              return (
                <div
                  key={index}
                  className={cx(
                    'content-wrapper',
                    state.length !== index + 1 ? 'border-below' : ''
                  )}>
                  <div className="row">
                    <div className="col-xl-6 col-xxl-4 col-6">
                      <Box mt="10px">
                        <p className="label">Institute Name</p>
                        <p className="value">{ele.instituteName}</p>
                      </Box>
                    </div>
                    <div className="col-xl-6 col-xxl-4 col-6">
                      <Box mt="10px">
                        <p className="label">Degree Type</p>
                        <p className="value">{ele.degree}</p>
                      </Box>
                    </div>

                    <div className="col-xl-6 col-xxl-4 col-6">
                      <Box mt="10px">
                        <p className="label">Course</p>
                        <p className="value">{ele.course}</p>
                      </Box>
                    </div>
                    <div className="col-xl-6 col-xxl-4 col-6">
                      <Box mt="10px">
                        <p className="label">Location</p>
                        <p className="value">{ele.location}</p>
                      </Box>
                    </div>

                    <div className="col-xl-6 col-xxl-4 col-6">
                      <Box mt="10px">
                        <p className="label">From</p>
                        <p className="value">{from}</p>
                      </Box>
                    </div>
                    <div className="col-xl-6 col-xxl-4 col-6">
                      <Box mt="10px">
                        <p className="label">To</p>
                        {ele.isPersue ? (
                          <p className="value">I am currently pursuing</p>
                        ) : (
                          <p className="value">{to}</p>
                        )}
                      </Box>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      )}
    </>
  );
};
