import { useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classname';
import { ManageBoardWrapper } from './style.js';
import Icon from 'utlils/Icons/Icon.js';
import { CustomButton, Loader, ScrollableDiv } from 'components/index.js';
import { ManageBoardInput } from './manageBoardInput.js';
import { replaceSpaceWithUnderscore } from 'utlils/utils.js';
import { useAxiosWrapper } from 'services/useAxiosWrapper.js';
import {
  FETCH_DEPARTMENT_MANAGETASK_LIST,
  FETCH_PROJECT_MANAGETASK_LIST,
} from 'services/index.js';

export const ManageBoard = (props) => {
  const { className, departmentId, projectId, onSaveFields, isUpdating, type } =
    props || {};
  const [fnWrapper, context] = useAxiosWrapper();
  const [boardField, setBoardField] = useState({});

  const isDisabled = boardField?.length
    ? boardField.map((field) => field.label === '').includes(true)
    : true;

  /* --DRAG & DROP Functionality--- */

  let itemToDrag = useRef();
  let itemDragOver = useRef();
  let inputRef = useRef();
  const handleDragStart = (index) => {
    itemToDrag.current = index;
  };

  const onDragEnter = (index) => {
    itemDragOver.current = index;
  };

  const handleDragEnd = (event) => {
    if (event.dataTransfer.dropEffect === 'move') {
      const copyFieldArr = [...boardField];
      const moved_item = copyFieldArr[itemToDrag.current];
      copyFieldArr.splice(itemToDrag.current, 1);
      copyFieldArr.splice(itemDragOver.current, 0, moved_item);
      setBoardField(copyFieldArr);
    }
    itemToDrag.current = null;
    itemDragOver.current = null;
  };

  const onChangeField = (value, index) => {
    const fields = [...boardField];
    fields[index].label = value.toUpperCase();
    fields[index].value = replaceSpaceWithUnderscore(value);
    setBoardField(fields);
  };

  const onAddOrDeleteField = (value) => setBoardField(value);

  const getProjectManageTaskList = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_PROJECT_MANAGETASK_LIST}/${projectId}`,
      method: 'GET',
      type: 'FETCH_MANAGE_TASK_LIST',
    });

    if (res.data.statusCode === 200) {
      setBoardField(res.data.data.status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const getDepartmentManageTaskList = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_DEPARTMENT_MANAGETASK_LIST}/${departmentId}`,
      method: 'GET',
      type: 'FETCH_MANAGE_TASK_LIST',
    });

    if (res.data.statusCode === 200) {
      setBoardField(res.data.data.status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentId]);

  useEffect(() => {
    if (type === 'DEPARTMENT') {
      getDepartmentManageTaskList();
    }
    if (type === 'PROJECT') {
      getProjectManageTaskList();
    }
  }, [getDepartmentManageTaskList, getProjectManageTaskList, type]);

  return context.busy.status &&
    context.busy.type === 'FETCH_MANAGE_TASK_LIST' ? (
    <Loader
      loading={
        context.busy.status && context.busy.type === 'FETCH_MANAGE_TASK_LIST'
      }
    />
  ) : (
    <ManageBoardWrapper className={cn('manage-board', className)}>
      <div className="manage-board__header mt-2">
        <div className="title">{'Your Fields'}</div>
        <div className="add-field">
          <Icon
            name="add"
            color="#0ABD00"
            onClick={() => {
              inputRef.current = boardField?.length;
              onAddOrDeleteField([
                ...boardField,
                {
                  value: '',
                  label: '',
                  isDefaultStatus: false,
                },
              ]);
            }}
          />
        </div>
      </div>

      <ScrollableDiv>
        <div
          className="manage-board__body"
          onDragOver={(e) => {
            e.dataTransfer.dropEffect = 'move';
            e.preventDefault();
          }}>
          {boardField?.length >= 1 &&
            boardField.map((field, index) => (
              <ManageBoardInput
                key={`manage-board${field?.label}${index}`}
                field={field}
                handleDragStart={() => handleDragStart(index)}
                onDragEnter={() => onDragEnter(index)}
                handleDragEnd={(e) => handleDragEnd(e)}
                isActiveField={field?.isDefaultStatus}
                onChangeField={(e) => onChangeField(e.target.value, index)}
                deleteFieldHandler={() =>
                  onAddOrDeleteField(
                    boardField.filter((item) => item !== field)
                  )
                }
                shouldAutoFocus={index === inputRef.current}
              />
            ))}
        </div>
      </ScrollableDiv>
      <div className="manage-board__footer">
        <CustomButton
          label="Save"
          onClick={() => onSaveFields(boardField)}
          disabled={isDisabled || isUpdating}
          loading={isUpdating}
          className="w-100"
        />
      </div>
    </ManageBoardWrapper>
  );
};
