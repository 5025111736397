import { useNavigate, useParams } from 'react-router-dom';
import { OptionWrapper } from 'components';
import { TASK_TYPE_FILTER_ITEMS } from 'pages/TaskManagement/constants';
import Icon from 'utlils/Icons/Icon';
import { CustomCheckBox } from 'components/index.js';
import tokenService from 'services/token.service';

export const TaskBoardFilterRow = (props) => {
  const { dashboardType } = useParams();
  const navigate = useNavigate();
  const {
    onOpenTaskTypeFilter,
    onSelectTaskTypeHandler,
    onSelectTaskTypeValueHandler,
    taskTypeValueData,
    taskTypeFilter,
    taskTypeValueFilter,
    teamId,
    handleAssignedToMeFilter,
    assignedToMe,
  } = props;

  const isIndividual = tokenService.getSession()?.role === 'INDIVIDUAL';

  return (
    <div className="task-management__wrapper__header">
      <span
        className="back_to_home"
        onClick={() => {
          teamId
            ? navigate(
                `/${dashboardType}/team-dashboard/${teamId}/tasks/project-tasks`
              )
            : dashboardType === 'individual'
            ? navigate(`/individual/profile/info/basic_details`)
            : navigate(`/enterprise/profile/view/details`);
        }}>
        {'Back to home'}
      </span>
      <div className="select-project-department">
        <div className="title">Task Management</div>
        <div className="task-dropdown-container">
          {isIndividual ? (
            <div className="task-check-filter">
              <CustomCheckBox
                disabled={!taskTypeValueFilter?.selectedItem?.id}
                title="Assigned to me"
                onClick={handleAssignedToMeFilter}
                check={assignedToMe}
              />
            </div>
          ) : null}
          <div className="dropdown-wrapper">
            <div
              className="d-flex justify-content-between align-items-center dropdown-wrapper__title"
              onClick={() => onOpenTaskTypeFilter('TASK_TYPE')}>
              <span className="heading">
                {Object.keys(taskTypeFilter?.selectedItem).length !== 0
                  ? taskTypeFilter?.selectedItem?.title
                  : 'Select '}
              </span>
              <Icon
                name={
                  taskTypeFilter?.isOpen
                    ? 'arrow-drop-up-two'
                    : 'arrow-down-three'
                }
                color="#404F67"
                size={15}
              />
            </div>
            {taskTypeFilter?.isOpen && (
              <OptionWrapper
                className="dropdown-wrapper__option-wrapper"
                onClick={onSelectTaskTypeHandler}
                options={TASK_TYPE_FILTER_ITEMS}
                selectedOption={taskTypeFilter?.selectedItem?.id}
              />
            )}
          </div>
          <div className="dropdown-wrapper">
            <div
              onClick={() => onOpenTaskTypeFilter('TASK_TYPE_VALUE')}
              className="d-flex justify-content-between align-items-center dropdown-wrapper__title">
              <span className="heading">
                {Object.keys(taskTypeValueFilter?.selectedItem)?.length !== 0
                  ? taskTypeValueFilter?.selectedItem?.title
                  : 'Select '}
              </span>
              <Icon
                name={
                  taskTypeValueFilter?.isOpen
                    ? 'arrow-drop-up-two'
                    : 'arrow-down-three'
                }
                color="#404F67"
                size={15}
              />
            </div>
            {taskTypeValueFilter?.isOpen && (
              <OptionWrapper
                className="dropdown-wrapper__option-wrapper"
                onClick={onSelectTaskTypeValueHandler}
                options={taskTypeValueData}
                selectedOption={taskTypeValueFilter?.selectedItem?.id}
              />
            )}
          </div>
        </div>
      </div>
      <div className="underline" />
    </div>
  );
};
