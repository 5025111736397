import { AcceptButton } from '../components/AcceptButton';

export const WATILISTTABLEHEADERS = [
  {
    title: 'User Name',
    sortable: true,
    sortKey: 'user_name',
  },
  {
    title: 'Email',
    sortable: true,
    sortKey: 'email',
  },
  {
    title: 'Country',
    sortable: true,
    sortKey: 'country',
  },
  {
    title: 'Mobile No.',
    sortable: true,
    sortKey: 'mobile_no',
  },
  {
    title: 'Occupation',
    sortable: true,
    sortKey: 'occupation',
  },
  {
    title: 'Status',
    sortable: false,
    sortKey: 'status',
  },
  {
    title: 'Action',
    sortable: false,
    sortKey: 'action',
  },
];

export const teamsTableData = (data, setWaitListStatus) => {
  let newData = [];
  if (data.length) {
    newData = data.map((item) => {
      return {
        user_name: item?.userDetails?.userName,
        email: item?.email,
        country: item?.userDetails?.individual?.country,
        mobile_no: item?.userDetails?.individual?.contact,
        occupation: item?.occupation,
        status: <strong style={{ color: '#fecc6c' }}>{item?.status}</strong>,
        userId: item?.userId,
        action: (
          <AcceptButton
            label="Approve"
            userId={item?.userId}
            setWaitListStatus={setWaitListStatus}
          />
        ),
      };
    });
  } else {
    newData = [];
  }

  return newData;
};

export const getIdsWaitListUsers = (data) => {
  let newData = [];
  if (data.length) {
    newData = data.map((item) => {
      return item?.userId;
    });
  } else {
    newData = [];
  }
  return newData;
};

export const FilterTheWaitingList = (data, value) => {
  let filtered = data.filter(
    (ele) =>
      ele?.userDetails?.userName.toLowerCase().includes(value.toLowerCase()) &&
      ele
  );
  return filtered;
};
