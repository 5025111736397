/* eslint-disable no-console */
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  CustomButton,
  DropDown,
  Input,
  Textarea,
} from '../../../../../components';
import { CreateTeamContext } from '../../../../../context/Teams/CreateTeam/createTeamContext';
import {
  onChangeTeamsCredential,
  setProgressStep,
} from '../../../../../context/Teams/CreateTeam/createTeamAction';
import { checkForSpacesInStartEnd } from 'helpers';

const Information = ({ teamType, isEnterpriseTeam }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [groupType, setGroupType] = useState([]);
  const [teamSize, setTeamSize] = useState([]);
  const { createTeamState, createTeamDispatch } = useContext(CreateTeamContext);

  let buttonDisabled =
    !createTeamState.teamName ||
    !createTeamState.topic ||
    !createTeamState.description ||
    checkForSpacesInStartEnd(createTeamState.teamName) ||
    checkForSpacesInStartEnd(createTeamState.topic) ||
    checkForSpacesInStartEnd(createTeamState.description) ||
    Object.keys(createTeamState.teamType).length === 0 ||
    Object.keys(createTeamState.teamSize).length === 0;

  const getTeamType = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/userType/team`)
      .then((response) => {
        if (response.data.statusCode === 200) {
          setGroupType(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  };

  const getTeamSize = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/getTeamSize`)
      .then((response) => {
        if (response.data.statusCode === 200) {
          setTeamSize(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  };

  const handleContinue = async () => {
    await setProgressStep(createTeamDispatch, 2);
    if (isEnterpriseTeam) {
      navigate(
        `/individual/profile/organization/enterprise/department/${id}/teams/create/inviteMember`
      );
    } else {
      navigate(`/individual/profile/personal/teams/create/inviteMember`);
    }
  };

  const handleClose = () => {
    if (isEnterpriseTeam) {
      navigate(
        `/individual/profile/organization/enterprise/department/${id}/teams`,
        { replace: true }
      );
    } else {
      navigate(`/individual/profile/personal/teams`);
    }
  };

  useEffect(() => {
    setProgressStep(createTeamDispatch, 1);
    getTeamType();
    getTeamSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box mt="32px">
      <div className="row">
        <div className="col-lg-6">
          <Input
            label="Team Name"
            type="text"
            required
            value={createTeamState.teamName}
            onChange={(e) =>
              onChangeTeamsCredential(
                createTeamDispatch,
                'teamName',
                e.target.value
              )
            }
          />
        </div>
        <div className="col-lg-6">
          <Input
            label="Topic"
            type="text"
            required
            value={createTeamState.topic}
            onChange={(e) =>
              onChangeTeamsCredential(
                createTeamDispatch,
                'topic',
                e.target.value
              )
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <DropDown
            hasSearchBox
            label="Team Type"
            required
            items={groupType}
            objectKey="title"
            placeholder="Select Team Type"
            value={createTeamState?.teamType}
            onClick={(value) => {
              onChangeTeamsCredential(createTeamDispatch, 'teamType', value);
            }}
          />
        </div>
        <div className="col-lg-6">
          <DropDown
            label="Team Size"
            required
            placeholder="Select Team Size"
            items={teamSize}
            objectKey="teamSize"
            value={createTeamState?.teamSize}
            onClick={(value) => {
              onChangeTeamsCredential(createTeamDispatch, 'teamSize', value);
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <Textarea
            label="Description"
            required
            value={createTeamState.description}
            onChange={(e) =>
              onChangeTeamsCredential(
                createTeamDispatch,
                'description',
                e.target.value
              )
            }
          />
        </div>
      </div>
      <Box
        mt="32px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        colGap="15px">
        <Box width="150px">
          <CustomButton
            className="w-100"
            label="Continue"
            disabled={buttonDisabled}
            color="themeSecondary"
            onClick={handleContinue}
          />
        </Box>
        <CustomButton
          label="Close"
          onClick={handleClose}
          variant="textBtn"
          color="themeSecondary"
        />
      </Box>
    </Box>
  );
};

export default Information;
