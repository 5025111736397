import { toast } from 'react-toastify';
import axios from 'axios';
import {
  FETCH_USER_PROFILE,
  FETCH_TEAM_DETAILS_BASED_ON_TEAM_ID,
} from 'services';
import tokenService from 'services/token.service';
import { getProfileText } from 'utlils/utils';
import { initializeTwilioConversations } from './twilioClient';
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// Function to initialize the participant if not subscribed
const initializeParticipantIfNotSubscribed = async (
  client,
  participantIdentities
) => {
  const identities = Array.isArray(participantIdentities)
    ? participantIdentities
    : [participantIdentities];
  let delayValue = 0;
  // Check if any of the participants are not subscribed and initialize if needed
  for (const participantIdentity of identities) {
    const subscribedUsers = await client.getSubscribedUsers();
    const isParticipantSubscribed = subscribedUsers.some(
      (user) => user?.state?.identity === participantIdentity
    );

    if (!isParticipantSubscribed) {
      await initializeTwilioConversations(participantIdentity);
    }
    delayValue = delayValue + 1000;
  }
  await delay(delayValue);
};

// Function to create or join a conversation
export const createOrJoinConversation = async (
  client,
  participantIdentities,
  friendlyName,
  uniqueName,
  attributes = {}
) => {
  try {
    const conversations = await client.getSubscribedConversations();
    const existingConversation =
      attributes?.conversationType === 'ONE_TO_ONE'
        ? conversations?.items.find(
            (convo) =>
              convo?.uniqueName?.split('-').sort().join('') ===
              uniqueName?.split('-').sort().join('')
          )
        : conversations?.items.find(
            (conv) => conv?.friendlyName === friendlyName
          );

    if (existingConversation) {
      return existingConversation; // Return the existing conversation
    }
    // Check if participant is subscribed and initialize if not
    await initializeParticipantIfNotSubscribed(client, participantIdentities);

    // Create a new conversation
    const newConversation = await client.createConversation({
      friendlyName: friendlyName,
      uniqueName: uniqueName,
      attributes: {
        ...attributes,
      },
    });

    // Join the current user to the new conversation
    await newConversation.join();

    // Add participants to the conversation (handle both single and multiple participants)
    const participantsToAdd = Array.isArray(participantIdentities)
      ? participantIdentities
      : [participantIdentities];

    for (const participantIdentity of participantsToAdd) {
      try {
        // Attempt to add the participant to the conversation
        await newConversation.add(participantIdentity);
      } catch (participantError) {
        // Handle errors when adding a participant
        chatErrorMsg(`Error adding participant ${participantIdentity}`);
      }
    }

    return newConversation;
  } catch (error) {
    chatErrorMsg(`Error creating or joining conversation`);
    return null;
  }
};

export const formatTimestamp = (timestamp, formatOptions = 'both') => {
  const date = new Date(timestamp);

  if (formatOptions === 'date') {
    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    return date.toLocaleDateString(undefined, options).replace(/\//g, '-'); // Replace slashes with hyphens
  } else if (formatOptions === 'time') {
    const options = {
      hour: '2-digit',
      minute: '2-digit',
    };
    return date.toLocaleTimeString(undefined, options);
  } else if (formatOptions === 'both') {
    const dateOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    const timeOptions = {
      hour: '2-digit',
      minute: '2-digit',
    };
    const formattedDate = date
      .toLocaleDateString(undefined, dateOptions)
      .replace(/\//g, '-');
    const formattedTime = date.toLocaleTimeString(undefined, timeOptions);
    return `${formattedDate} ${formattedTime}`;
  } else {
    // Handle invalid formatOptions
    return 'Invalid formatOptions';
  }
};

export const chatErrorMsg = (message) => {
  toast.error(message, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'colored',
  });
};

export const getMessageMediaUrl = async (message) => {
  try {
    const url = await message.media.getContentTemporaryUrl();
    return url;
  } catch (error) {
    // Handle any potential errors
    chatErrorMsg('Error while fetching media URL:');
    return null; // Or throw an error as needed
  }
};

export const convertFileSizeToMb = (fileSize) => {
  if (typeof fileSize === 'number') {
    return (fileSize / (1024 * 1024)).toFixed(2);
  } else if (typeof fileSize === 'string') {
    const fileSizeInBytes = parseFloat(fileSize);
    if (!isNaN(fileSizeInBytes)) {
      return (fileSizeInBytes / (1024 * 1024)).toFixed(2);
    }
  }
  return 'Invalid input';
};

export const handleDownload = async (mediaUrl, setter, getter) => {
  try {
    const result = await fetch(mediaUrl, {
      method: 'GET',
      headers: {},
    });
    const blob = await result.blob();
    const url = URL.createObjectURL(blob);
    setter(url);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `document.${blob?.type.split('/')[1]}`);
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(getter);
  } catch (error) {
    chatErrorMsg('error downloading');
  }
};

export const fetchUserDetailsById = async (userId) => {
  try {
    const loggedInUser = tokenService.getSession();
    const config = {
      headers: {
        'x-access-token': loggedInUser?.access_token,
      },
    };
    const res = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/${FETCH_USER_PROFILE}/${userId}`,
      config
    );
    if (res.data.statusCode === 200) {
      const response = res.data.data;
      const userData = {
        ...response,
        profileText: getProfileText(
          response?.role === 'INDIVIDUAL'
            ? response?.name
            : response?.enterpriseName
        ),
      };

      return userData;
    }
  } catch (error) {
    chatErrorMsg(error);
  }
};

export const fetchTeamDetailsById = async (teamId) => {
  try {
    const loggedInUser = tokenService.getSession();
    const config = {
      headers: {
        'x-access-token': loggedInUser?.access_token,
      },
    };
    const res = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/${FETCH_TEAM_DETAILS_BASED_ON_TEAM_ID}/${teamId}`,
      config
    );
    if (res?.data?.statusCode === 200) {
      const response = res?.data?.data;
      return response;
    }
  } catch (error) {
    chatErrorMsg(error);
  }
};
