export const collaborationType = [
  { text: 'Collaborate to learn', value: 'Collaborate to learn' },
  { text: 'Collaborate to develop', value: 'Collaborate to develop' },
  { text: 'Looking for investors', value: 'Looking for investors' },
  { text: 'Looking to invest', value: 'Looking to invest' },
];

export const collaborationCategory = [
  { text: 'Software', value: 'Software' },
  { text: 'Design', value: 'Design' },
  { text: 'Research', value: 'Research' },
  { text: 'Finance', value: 'Finance' },
  { text: 'Healthcare', value: 'Healthcare' },
  { text: 'AI/Machine learning', value: 'AI/Machine learning' },
  { text: 'Bio', value: 'Bio' },
  { text: 'Engineering', value: 'Engineering' },
];

export const collaborationGoal = [
  { text: 'Non-Profit', value: 'Non-Profit' },
  { text: 'Profit', value: 'Profit' },
  { text: 'Public good', value: 'Public good' },
  { text: 'Innovation', value: 'Innovation' },
];

export const COLLABORATION_TABLE_HEADERS = [
  {
    title: 'Collaboration Type',
    sortable: false,
    sortKey: 'collaborationType',
  },
  {
    title: 'Category',
    sortable: false,
    sortKey: 'collaborationCategory',
  },
  {
    title: 'Opened on',
    sortable: false,
    sortKey: 'openDate',
  },

  {
    title: 'Requests',
    sortable: false,
    sortKey: 'requests',
  },
  {
    title: 'Edit',
    sortable: false,
    sortKey: 'edit',
  },
  {
    title: 'Open/Close',
    sortable: false,
    sortKey: 'isOpen',
  },
  {
    title: '',
    sortable: false,
    sortKey: 'action',
  },
];

export const APPLICANTS_TABLE_HEADERS = [
  {
    title: 'Name',
    sortable: false,
    sortKey: 'name',
  },
  {
    title: 'Applied on',
    sortable: false,
    sortKey: 'appliedOn',
  },
  {
    title: 'Application completion (%)',
    sortable: false,
    sortKey: 'completionPercentage',
  },

  {
    title: 'Application',
    sortable: false,
    sortKey: 'application',
  },
  {
    title: 'Status',
    sortable: false,
    sortKey: 'status',
  },
  {
    title: '',
    sortable: false,
    sortKey: 'action',
  },
];

export const collaboarationApplicantStatus = {
  PENDING: {
    label: '--',
    bgColor: 'transparent',
    textColor: '#0ABD00',
  },
  ACCEPTED: {
    label: 'Approved',
    bgColor: 'transparent',
    textColor: '#0ABD00',
  },
  REJECTED: {
    label: 'Rejected',
    bgColor: 'transparent',
    textColor: '#F7A483',
  },
  null: {
    label: '--',
    bgColor: 'transparent',
    textColor: '#0ABD00',
  },
};

export const applicantStatus = [
  {
    label: 'Pending',
    value: 'PENDING',
  },
  {
    label: 'Approved',
    value: 'ACCEPTED',
  },
  {
    label: 'Rejected',
    value: 'REJECTED',
  },
];

export const projectAccessType = {
  BIDDING: 'BIDDING',
  COLLABORATION: 'COLLABORATION',
};

export const biddingTypes = [
  { text: 'Project Copy', value: 'PROJECT_COPY' },
  {
    text: 'Project Service Subscription',
    value: 'PROJECT_SERVICE_SUBSCRIPTION',
  },
  { text: 'Project Acquistion', value: 'PROJECT_ACQUISTION' },
];

export const biddingCategory = [
  { text: 'Software', value: 'SOFTWARE' },
  { text: 'Design', value: 'DESIGN' },
  { text: 'Research', value: 'RESEARCH' },
  { text: 'Finance', value: 'FINANCE' },
  { text: 'Healthcare', value: 'HEALTHCARE' },
  { text: 'AI/Machine learning', value: 'AI/MACHINE_LEARNING' },
  { text: 'Bio', value: 'BIO' },
  { text: 'Engineering', value: 'ENGINEERING' },
];

export const biddingPaymentTypes = [
  { text: 'All needed Upfront', value: 'ALL_NEEDED_UPFRONT' },
  {
    text: 'Guaranteed Payment [Won’t receive money till buyer approves, money would be held by MiHint]',
    value: 'GURANTEED PAYMENT',
  },
  {
    text: 'Select the percentage needed upfront and the remaining amount at the end of the transaction',
    value: 'PERCENTAGE_NEEDED_UPFRONT',
  },
];

export const biddingPaymentForSubscription = [
  {
    text: 'Per Milestone: Default milestone and stages',
    value: 'DEFAULT_MILESTONE',
  },
  {
    text: 'Beforehand',
    value: 'BEFOREHAND',
  },
  {
    text: 'Per Service (Per frequency of the service, every week, month, year)',
    value: 'PER_FRQUENCY_SERVICE',
  },
];

export const biddingFixedRates = [{ text: 'Fixed Rate', value: 'Fixed rate' }];
export const biddingRange = [{ text: 'Bidding Range', value: 'Bidding range' }];

export const biddingSubscriptionFAQ = {
  '1. Type of services that will be provided with this subscription': '',
  '2. Requirements to be eligible': '',
  '3. What kind of proof of concept will be provided?': '',
  '4. Anything required from the customer end?': '',
};

export const biddingProjectCopyFAQ = {
  '1. The project copy will contain the following-': '',
  '2. What demo and proof of concept would be provided?': '',
};

export const biddingProjectAquisitionFAQ = {
  '1. Would the bidder receive a demo & what type of demo would be provided?':
    '',
  '2. Requirements from bidder': '',
};

export const serviceFrequency = [
  { label: 'Weekly', value: 'Weekly' },
  { label: 'Monthly', value: 'Monthly' },
  { label: 'Yearly', value: 'Yearly' },
];

export const BIDDING_TABLE_HEADERS = [
  {
    title: 'Bidding Type',
    sortable: false,
    sortKey: 'biddingType',
  },
  {
    title: 'Bid Rate Type',
    sortable: false,
    sortKey: 'bidRateType',
  },
  {
    title: 'Opened on',
    sortable: false,
    sortKey: 'openDate',
  },

  {
    title: 'Bidders',
    sortable: false,
    sortKey: 'requests',
  },
  {
    title: 'Edit',
    sortable: false,
    sortKey: 'edit',
  },
  {
    title: 'Open/Close',
    sortable: false,
    sortKey: 'isOpen',
  },
  {
    title: '',
    sortable: false,
    sortKey: 'action',
  },
];

export const BIDDING_APPLICANTS_TABLE_HEADERS = [
  {
    title: 'Name',
    sortable: false,
    sortKey: 'name',
  },
  {
    title: 'Applied on',
    sortable: false,
    sortKey: 'appliedOn',
  },
  {
    title: 'Bid Placed',
    sortable: false,
    sortKey: 'bidderAmount',
  },

  {
    title: 'Application',
    sortable: false,
    sortKey: 'application',
  },
  {
    title: 'Status',
    sortable: false,
    sortKey: 'status',
  },
  {
    title: '',
    sortable: false,
    sortKey: 'action',
  },
];

export const biddingApplicantStatus = {
  PENDING: {
    label: '--',
    bgColor: 'transparent',
    textColor: '#0ABD00',
  },
  ACCEPTED: {
    label: 'Approved',
    bgColor: 'transparent',
    textColor: '#0ABD00',
  },
  REJECTED: {
    label: 'Rejected',
    bgColor: 'transparent',
    textColor: '#F7A483',
  },
  null: {
    label: '--',
    bgColor: 'transparent',
    textColor: '#0ABD00',
  },
};

export const currencyLists = {
  USD: '$',
  INR: '₹',
  EUR: '€',
  GBP: '£',
  CRC: '₡',
  ILS: '₪',
  JPY: '¥',
  KRW: '₩',
  NGN: '₦',
  PHP: '₱',
  PYG: '₲',
  THB: '฿',
  UAH: '₫',
  VND: '₫',
};
