import React from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import { CreateProjectProvider } from 'context/Projects/CreateProject/createProjectContext';
import { TYPES } from 'pages/IndividualDashboard/constants';
import {
  CreateProject,
  EditProject,
  ProjectList,
  ViewProject,
} from 'pages/Projects';

function Projects() {
  const { departmentId } = useParams();
  return (
    <div>
      <Routes>
        <Route path="">
          <Route
            element={
              <ProjectList
                type={TYPES.ENTERPRISE}
                baseUrl={`/enterprise/departments/view/${departmentId}/projects`}
              />
            }
            path=""
          />
          <Route
            element={
              <ViewProject
                type={TYPES.ENTERPRISE}
                baseUrl={`/enterprise/departments/view/${departmentId}/projects`}
              />
            }
            path=":projectId/view/*"
          />
          <Route
            element={
              <CreateProjectProvider>
                <EditProject
                  type={TYPES.ENTERPRISE}
                  baseUrl={`/enterprise/departments/view/${departmentId}/projects`}
                />
              </CreateProjectProvider>
            }
            path=":projectId/:action/*"
          />
          <Route
            element={
              <CreateProjectProvider>
                <CreateProject
                  type={TYPES.ENTERPRISE}
                  baseUrl={`/enterprise/departments/view/${departmentId}/projects`}
                />
              </CreateProjectProvider>
            }
            path="create/*"
          />
        </Route>
      </Routes>
    </div>
  );
}

export default Projects;
