import styled from 'styled-components';

export const PublicationFilterWrapper = styled.div`
  .publication-filter {
    position: absolute;
    top: 60px;
    left: 0;
    background: #ffffff;
    border: 1px solid #f4f7fc;
    box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    z-index: 1000;
    padding: 20px;
    .filter-options {
      padding: 12px 6px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      .filter-date-wrapper {
        label {
          color: #5a5a5a;
        }
        .filter-custom-date {
          height: 52px;
          border-radius: 12px;
          border: 2px solid #e9eef4;
          padding: 17px;
          width: 100%;

          @media (max-width: 991px) {
            margin-bottom: 12px;
          }
        }

        .filter-custom-date:focus {
          outline: none;
        }
      }
    }
    .publication-filter-button {
      margin-top: 50%;
      div {
        width: 50%;
      }
    }
  }
`;
