import React, { useState, useCallback, useEffect } from 'react';
import {
  CustomButton,
  DropDown,
  Input,
  ModalPopup,
  ScrollableDiv,
  Textarea,
  SearchBoxWithDropDown,
} from 'components';
import styled from 'styled-components';
import Icon from 'utlils/Icons/Icon';
import EditIcon from '../../../../../../assests/images/edit.png';
import tokenService from 'services/token.service';
import { getProfileText } from 'utlils/utils';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import {
  FETCH_TEAM_DETAILS_BASED_ON_TEAM_ID,
  ACCEPT_REJECT_TEAM_APPROVAL,
  FETCH_TEAM_TYPES,
  FETCH_TEAM_SIZE,
  SEARCH_USER,
  useAxiosWrapper,
} from 'services';
import { INVITEMEMBERROLE } from 'pages/IndividualDashboard/IndividualPersonalSection/individualTeams/helper';

const DEFAULT_TEAM_INFO_STATE = {
  teamName: 'Demo Team',
  teamType: { title: 'Demo type' },
  teamSize: '0-10',
  topic: 'Demo Topic',
  description: 'This is a default demo description for team approvals.',
  teamMembers: [
    {
      memberName: 'Name1',
      email: 'demo@brenolabs.com',
      designation: 'Demo1',
      role: { label: 'Demo role' },
    },
    {
      memberName: 'Name2',
      email: 'demo@brenolabs.com',
      designation: 'Demo2',
      role: { label: 'Demo role' },
    },
    {
      memberName: 'Name3',
      email: 'demo@brenolabs.com',
      designation: 'Demo3',
      role: { label: 'Demo role' },
    },
  ],
};

const TeamApprovalNextModal = ({
  backBtnHandler,
  open,
  handleOpen,
  teamId,
  userId,
  updateTeamList,
}) => {
  const [fnWrapper] = useAxiosWrapper();
  const [teamInfo, setTeamInfo] = useState(DEFAULT_TEAM_INFO_STATE);
  const [isEditable, setIsEditable] = useState(false);
  // search
  const [searchMember, setSearchMember] = useState('');
  const [filteredMemberData, setFilteredMemberData] = useState([]);
  const [selectedTeamTypeId, setSelectedTeamTypeId] = useState('');
  const [selectedTeamSizeId, setSelectedTeamSizeId] = useState('');
  const [teamSize, setTeamSize] = useState([]);
  const [teamType, setTeamType] = useState([]);
  const [isButtonsDisabled, setIsButtonsDisabled] = useState(false);

  const loggedInUser = tokenService.getSession();
  let { departmentId } = useParams();

  const getTeamSize = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_TEAM_SIZE}`,
      method: 'GET',
      type: 'FETCH_TEAM_SIZE',
    });

    if (res.data.statusCode === 200) {
      setTeamSize(res.data.data);
    }
  }, [fnWrapper]);

  const getTeamType = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_TEAM_TYPES}`,
      method: 'GET',
      type: 'FETCH_TEAM_TYPES',
    });

    if (res.data.statusCode === 200) {
      setTeamType(res.data.data);
    }
  }, [fnWrapper]);

  const getTeamDetails = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_TEAM_DETAILS_BASED_ON_TEAM_ID}/${teamId}`,
      method: 'GET',
      type: 'FETCH_TEAM_DETAILS_BASED_ON_TEAM_ID',
    });
    if (res.data.statusCode === 200) {
      if (res?.data?.data) {
        const teamDetails = res.data.data;
        setTeamInfo((prev) => ({
          ...prev,
          teamName: teamDetails?.teamName,
          teamType: { title: teamDetails?.type?.title },
          teamSize: teamDetails?.teamSize,
          topic: teamDetails?.topic,
          description: teamDetails?.description,
          teamCategory: teamDetails?.teamCategory,
          teamMembers: teamDetails?.teamMembers.map((member) => ({
            id: member?.id,
            memberName: member?.memberName,
            email: member?.email,
            designation: member?.designation,
            role: { label: member?.role },
            invitedByUserId: member?.invitedByUserId,
            isActive: member?.isActive,
          })),
        }));
        // setTeamId and teamSize
        setSelectedTeamSizeId(teamDetails?.teamSize?.id);
        setSelectedTeamTypeId(teamDetails?.type?.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId]);

  useEffect(() => {
    getTeamDetails();
    getTeamType();
    getTeamSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTeamDetails]);

  // function to approve reject
  const approveOrRejectTeamRequest = async (action) => {
    // Map teamMembers to extract the role labels
    const teamMembersPayload = teamInfo.teamMembers.map((member) => ({
      id: member?.id,
      memberName: member.memberName,
      email: member.email,
      designation: member.designation,
      role: member.role.value, // Extract the role label
      invitedByUserId: member.invitedByUserId,
      isActive: member.isActive,
    }));
    const res = await fnWrapper({
      url: `${ACCEPT_REJECT_TEAM_APPROVAL}/${userId}`,
      method: 'POST',
      type: 'ACCEPT_REJECT_TEAM_APPROVAL',
      payload: {
        teamName: teamInfo.teamName,
        topic: teamInfo.topic,
        description: teamInfo.description,
        teamSizeId: selectedTeamSizeId,
        teamTypeId: selectedTeamTypeId,
        departmentId: departmentId,
        teamCategory: teamInfo.teamCategory,
        status: action,
        teamId: teamId,
        teamMembers: teamMembersPayload,
      },
    });
    if (res.data.statusCode === 200) {
      if (res.data.data.isActioned) {
        toast.warning(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.success(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    }
    handleOpen(false);
    updateTeamList();
  };

  // delete teamMember
  const handleDeleteMember = (index) => {
    setTeamInfo((prev) => {
      const updatedTeamMembers = prev.teamMembers.map((member, i) => {
        if (i === index) {
          return {
            ...member,
            isActive: 0,
          };
        }

        return member;
      });

      return {
        ...prev,
        teamMembers: updatedTeamMembers,
      };
    });
  };

  // add teamMembers
  const handleAddMember = () => {
    // Prompt the user to enter details of the new team member
    const newMember = {
      id: '',
      memberName: '',
      email: '',
      invitedByUserId: loggedInUser.id,
      isActive: 1,
      designation: '',
      role: { label: 'Select Role' },
    };

    setTeamInfo((prev) => {
      return {
        ...prev,
        teamMembers: [...prev.teamMembers, newMember],
      };
    });
  };

  // search teamMembers
  const onChangeSearchMember = async (value) => {
    setSearchMember(value);
    if (value.length >= 2) {
      let data = {
        q: value,
      };
      const res = await fnWrapper({
        url: SEARCH_USER,
        method: 'POST',
        type: SEARCH_USER,
        payload: data,
      });
      if (res.data.statusCode === 200) {
        const searchData = res.data.data;
        let finalArray = [];
        if (searchData.length) {
          finalArray = searchData
            .map((ele) => {
              return {
                id: ele.id,
                userId: ele.userId,
                name: ele.name,
                email: ele.user.email,
                userName: ele.user.userName,
                profilePicture: ele.profilePicture,
                profileText: getProfileText(ele.name),
              };
            })
            .filter((ele) => ele.userId !== loggedInUser.id);
        }
        setFilteredMemberData(finalArray);
      }
    }
  };

  const onSelectSearchMember = async (member) => {
    // already exist or not
    const isExistingMember = teamInfo.teamMembers.some(
      (existingMember) => existingMember.email === member.email
    );
    if (!isExistingMember) {
      let searchedMember = {
        id: '',
        memberName: member.name,
        email: member.email,
        invitedByUserId: loggedInUser.id,
        isActive: 1,
        designation: '',
        role: { label: 'Select Role' },
      };

      setTeamInfo((prev) => ({
        ...prev,
        teamMembers: [...prev.teamMembers, searchedMember],
      }));

      setSearchMember('');
    } else {
      // Show a toast message if the member already exists
      toast.warning('Selected member is already part of the team.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  const editIconHandler = () => {
    setIsEditable(true);
  };

  const areRequiredFieldsFilled = () => {
    // Check if all required fields in teamInfo are filled
    const requiredFieldsFilled =
      teamInfo.teamName?.trim() !== '' &&
      teamInfo.teamType.title.trim() !== '' &&
      teamInfo.teamSize !== '' &&
      teamInfo.topic.trim() !== '' &&
      teamInfo.description.trim() !== '' &&
      teamInfo.teamMembers.every(
        (member) =>
          member.memberName.trim() !== '' &&
          member.email.trim() !== '' &&
          member.designation.trim() !== '' &&
          member.role.label !== 'Select Role'
      );

    return requiredFieldsFilled;
  };

  const handleChange = (field = '', value) => {
    if (field === 'teamType') {
      setSelectedTeamTypeId(value.id);
    }
    if (field === 'teamSize') {
      setSelectedTeamSizeId(value.id);
    }
    setTeamInfo((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  useEffect(() => {
    setIsButtonsDisabled(false);
    const requiredFieldsFilled = areRequiredFieldsFilled();
    setIsButtonsDisabled(!requiredFieldsFilled);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamInfo]);

  const handleTeamMembers = (name, value, i) => {
    setTeamInfo((prev) => {
      const updatedTeamMembers = prev.teamMembers.map((member, idx) => {
        if (idx === i) {
          return {
            ...member,
            [name]: value,
          };
        }

        return member;
      });

      return {
        ...prev,
        teamMembers: updatedTeamMembers,
      };
    });
  };

  const CustomTitle = () => {
    return (
      <div>
        <CustomSpan onClick={backBtnHandler}>
          <Icon color="#292D32" name="arrow-back" size={30} leftIcon />
        </CustomSpan>
        <span>Team Approvals</span>
      </div>
    );
  };

  return (
    <>
      {open.openViewTeamInTeamApprove && (
        <ModalPopup
          open={false}
          onClose={() => handleOpen(false)}
          hasTitle
          hasCloseIcon
          title={<CustomTitle />}
          minWidth="650px"
          maxContentWidth="650px">
          <ScrollableDiv scrollOnHeight="75vh" scrollOnHeightMob="100vh">
            <TeamApprovalNextContainer>
              <div className="ta-next_upper-half">
                <div className="ta-next_upper_heading">
                  <span>Team Information</span>
                  <img
                    src={EditIcon}
                    alt="edit-icon"
                    onClick={editIconHandler}
                  />
                </div>
                <div className="ta-next_upper_grid-container">
                  <div className="ta_team-name">
                    <Input
                      label="Team Name"
                      required
                      type="text"
                      placeholder="Enter Team Name"
                      value={teamInfo.teamName}
                      disabled={!isEditable}
                      onChange={(e) => handleChange('teamName', e.target.value)}
                    />
                  </div>
                  <div className="ta_team-type">
                    <DropDown
                      label="Team Type"
                      required
                      items={teamType}
                      objectKey="title"
                      placeholder="Select"
                      disabled={!isEditable}
                      value={teamInfo.teamType}
                      onClick={(value) => handleChange('teamType', value)}
                    />
                  </div>
                  <div className="ta_team-size">
                    <DropDown
                      label="Team Size"
                      required
                      items={teamSize}
                      objectKey="teamSize"
                      placeholder="Select Team Size"
                      disabled={!isEditable}
                      value={teamInfo.teamSize}
                      onClick={(value) => handleChange('teamSize', value)}
                    />
                  </div>
                  <div className="ta_topic">
                    <Input
                      label="Topic"
                      required
                      type="text"
                      placeholder="Enter Topic"
                      value={teamInfo.topic}
                      disabled={!isEditable}
                      onChange={(e) => handleChange('topic', e.target.value)}
                    />
                  </div>
                  <div className="ta_description">
                    <Textarea
                      label="Description"
                      required
                      placeholder="Enter Description"
                      value={teamInfo.description}
                      onChange={(e) =>
                        handleChange('description', e.target.value)
                      }
                      disabled={!isEditable}
                      customStyle={{ color: 'black' }}
                    />
                  </div>
                </div>
              </div>
              <div className="ta-next_lower-half">
                <div className="ta-next_lower_heading">
                  <div>Team Members</div>
                  <div className="add-field-section">
                    <CustomButton
                      variant="textBtn"
                      customPadding="0"
                      disabled={!isEditable}
                      onClick={handleAddMember}>
                      <Icon name="add" size={18} color="primary" />
                      Add Member
                    </CustomButton>
                  </div>
                </div>

                <div className="search-content">
                  <SearchBoxWithDropDown
                    searchInputValue={searchMember}
                    searchData={filteredMemberData}
                    placeholder="Search for members in your organization"
                    onSelectSearchItem={onSelectSearchMember}
                    onInputChangeHandler={onChangeSearchMember}
                  />
                </div>
                {teamInfo.teamMembers &&
                  teamInfo.teamMembers.map((member, i) => {
                    // console.log(member);
                    if (member.isActive === 1) {
                      return (
                        <div key={i}>
                          <div className="d-flex align-items-center justify-content-between delete-member mb-3">
                            <div className="member-count">Member {i + 1}</div>
                            <CustomButton
                              variant="textBtn"
                              color="themeTertiary"
                              customPadding="0"
                              disabled={!isEditable}
                              onClick={() => handleDeleteMember(i)}>
                              <Icon name="delete" size={18} color="#ff5157" />
                              Delete Member
                            </CustomButton>
                          </div>
                          <div className="ta-next_lower_grid-container">
                            <div className="member_name">
                              <Input
                                label="Name"
                                required
                                type="text"
                                placeholder="Enter Name"
                                value={member.memberName}
                                disabled={!isEditable}
                                onChange={(e) =>
                                  handleTeamMembers(
                                    'memberName',
                                    e.target.value,
                                    i
                                  )
                                }
                              />
                            </div>
                            <div className="member_email">
                              <Input
                                label="Email"
                                required
                                type="text"
                                placeholder="xyz@example.com"
                                value={member.email}
                                disabled={!isEditable}
                                onChange={(e) =>
                                  handleTeamMembers('email', e.target.value, i)
                                }
                              />
                            </div>
                            <div className="member_designation">
                              <Input
                                label="Designation"
                                required
                                type="text"
                                placeholder="Enter Designation"
                                value={member.designation}
                                disabled={!isEditable}
                                onChange={(e) =>
                                  handleTeamMembers(
                                    'designation',
                                    e.target.value,
                                    i
                                  )
                                }
                              />
                            </div>
                            <div className="member_role">
                              <DropDown
                                label="Role"
                                required
                                items={INVITEMEMBERROLE}
                                objectKey="label"
                                placeholder="Select"
                                value={member.role}
                                disabled={!isEditable}
                                onClick={(value) =>
                                  handleTeamMembers('role', value, i)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
              </div>
              <div className="ta-next_buttons">
                <CustomButton
                  label="Approve"
                  onClick={() => approveOrRejectTeamRequest('APPROVED')}
                  disabled={isButtonsDisabled}
                />
                <CustomButton
                  label="Reject"
                  customPadding="16px 20px"
                  variant="light"
                  color="themeTertiary"
                  onClick={() => approveOrRejectTeamRequest('REJECTED')}
                  disabled={isButtonsDisabled}
                />
              </div>
            </TeamApprovalNextContainer>
          </ScrollableDiv>
        </ModalPopup>
      )}
    </>
  );
};
// styled components
const TeamApprovalNextContainer = styled.div`
  padding: 5px;
  & .ta-next_upper-half {
    & .ta-next_upper_heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      padding: 10px 0;
      margin-bottom: 10px;
      border-bottom: 1.5px dashed #c4c4c4;
      & img:hover {
        cursor: pointer;
      }
      @media (max-width: 576px) {
        font-size: 16px;
      }
    }
    & .ta-next_upper_grid-container {
      display: grid;
      gap: 20px;
      grid-template-areas:
        'col1 col1 col1 col2 col2 col2'
        'col3 col3 col3 col4 col4 col4'
        'col5 col5 col5 col5 col5 col5';
      padding: 5px;
      & .ta_team-name {
        grid-area: col1;
      }
      & .ta_team-type {
        grid-area: col2;
      }
      & .ta_team-size {
        grid-area: col3;
      }
      & .ta_topic {
        grid-area: col4;
      }
      & .ta_description {
        grid-area: col5;
      }
      @media (max-width: 576px) {
        display: block;
      }
    }
  }
  & .ta-next_lower-half {
    & .ta-next_lower_heading {
      display: flex;
      justify-content: space-between;
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      padding: 20px 0 10px 0;
      margin-bottom: 10px;
      border-bottom: 1.5px solid #c4c4c4;
      @media (max-width: 576px) {
        font-size: 16px;
      }
    }
    & .delete-member {
      border-bottom: 1.5px dashed #c4c4c4;
      padding-bottom: 10px;
      font-size: 14px;
    }
    & .ta-next_lower_grid-container {
      display: grid;
      grid-template-columns: auto auto;
      gap: 20px;
      padding: 5px;

      @media (max-width: 576px) {
        display: block;
      }
    }
  }
  & .ta-next_buttons {
    display: flex;
    margin: 25px auto;
    justify-content: center;
    gap: 16px;
    align-items: center;
  }
`;

const CustomSpan = styled.span`
  padding: 0 10px;
  &:hover {
    cursor: pointer;
  }
`;

export default TeamApprovalNextModal;
