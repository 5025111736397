import styled from 'styled-components';

export const PostListWrapper = styled.div`
  padding-top: 20px;
  & .no-update {
    width: 75%;
    text-align: center;
    margin: 8% auto;
    font-weight: 500;
    color: #929daf;
  }
`;
