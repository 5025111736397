import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import { firebaseCredentials } from './credentials';

// Initialize Firebase with your configuration
firebase.initializeApp(firebaseCredentials);

const registerServiceWorker = async () => {
  try {
    const registration = await navigator.serviceWorker.register(
      '/firebase-messaging-sw.js'
    );
    // eslint-disable-next-line no-console
    console.log('Service worker registered');
    return registration; // Return the registration object
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error registering service worker:', error);
    return null;
  }
};

// Get device information from the browser
const getDeviceInfo = () => {
  const deviceName = navigator.userAgent;
  return { deviceName };
};

const notifyMe = async () => {
  const storageData = localStorage.getItem('fcmTokenAndDeviceInfo');
  let storedData = storageData ? JSON.parse(storageData) : null;
  if (!('Notification' in window)) {
    // Check if the browser supports notifications
    alert('This browser does not support desktop notification');
  } else if (Notification.permission === 'granted') {
    // Check whether notification permissions have already been granted;
    const token = await firebase
      .messaging()
      .getToken({ vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY });
    // if so, create a notification
    if (!storedData) {
      // If no stored data, request a new token and store it
      const deviceInfo = getDeviceInfo();
      storedData = { fcmToken: token, deviceInfo };
      localStorage.setItem('fcmTokenAndDeviceInfo', JSON.stringify(storedData));
    } else {
      // Data exists, check if the token needs to be refreshed
      if (token !== storedData.fcmToken) {
        // Token has changed, update the stored token
        storedData.fcmToken = token;
        localStorage.setItem(
          'fcmTokenAndDeviceInfo',
          JSON.stringify(storedData)
        );
      }
    }
    return storedData;
  } else if (Notification.permission !== 'denied') {
    // We need to ask the user for permission
    await Notification.requestPermission().then(async (permission) => {
      // If the user accepts, let's create a notification
      if (permission === 'granted') {
        // Check whether notification permissions have already been granted;
        // if so, create a notification
        const token = await firebase
          .messaging()
          .getToken({ vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY });
        if (!storedData) {
          // If no stored data, request a new token and store it
          const deviceInfo = getDeviceInfo();
          storedData = { fcmToken: token, deviceInfo };
          localStorage.setItem(
            'fcmTokenAndDeviceInfo',
            JSON.stringify(storedData)
          );
        } else {
          // Data exists, check if the token needs to be refreshed
          if (token !== storedData.fcmToken) {
            // Token has changed, update the stored token
            storedData.fcmToken = token;
            localStorage.setItem(
              'fcmTokenAndDeviceInfo',
              JSON.stringify(storedData)
            );
          }
        }
        return storedData;
      }
    });
  }
  return storedData;
  // At last, if the user has denied notifications, and you
  // want to be respectful there is no need to bother them anymore.
};

export const generateFcmToken = async () => {
  try {
    await registerServiceWorker();
    // const permission = await Notification.requestPermission();
    return await notifyMe();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('Error getting FCM token:', error);
    return null;
  }
};
