import styled from 'styled-components';
export const TaskContentWrapper = styled.div`
  .task-wrapper {
    & .tasks-header {
      display: flex;
      justify-content: space-between;
      padding: 20px 10px;
      border-radius: 18.1281px;
      border-bottom: ${({ expandTask }) =>
        expandTask ? '5px groove #e9eef4' : '2px solid #e9eef4'};
      border-top: ${({ expandTask }) =>
        expandTask ? '2px solid #e9eef4' : 'none'};
      border-right: ${({ expandTask }) =>
        expandTask ? '2px solid #e9eef4' : 'none'};
      border-left: ${({ expandTask }) =>
        expandTask ? '2px solid #e9eef4' : 'none'};

      & .left-side-class {
        display: flex;
        align-items: center;
        gap: 10px;

        & .profile-pic-container,
        .profile-pic-container img {
          width: 40px;
          height: 40px;
          object-fit: cover;
          background: #d0e5ff;
          border-radius: 50%;
          & .pfp-alt-circle {
            border-radius: 50%;
            width: 40px;
            height: 40px;
            background-color: #d6f3f9;
            display: flex;
            align-items: center;
            justify-content: center;
            & .pfp-alt {
              border-radius: 50%;
              font-weight: bold;
            }
          }

          & .user-icon-class {
            margin-top: 10px;
            margin-left: 8px;
          }
        }

        & .user-name {
          font-family: 'Poppins';
          font-style: normal;
          font-size: 16px;
          color: #040d20;

          & .user-name-style {
            margin-bottom: 1px;

            & .admin-style {
              font-weight: 600;
            }

            & .stage-style {
              font-weight: 600;
              color: #219fff;
            }
          }

          & .user-posted-time {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            padding-top: 0px;
            color: #929daf;
          }
        }

        @media (max-width: 767px) {
          align-items: flex-start;
          flex-direction: column;
        }
      }

      & .right-side-class {
        display: flex;
        align-items: center;
        gap: 20px;

        & .assigned-class {
          display: flex;
          align-items: center;
          gap: 10px;
          border: 1px solid #404f67;
          border-radius: 66px;
          padding: 7px 13px;
          background: #f4f7fc;
          font-weight: 600;
          font-size: 16px;
          cursor: pointer;

          & .user-class {
            width: 40px;
            height: 40px;
            background: #929daf;
            border-radius: 50%;

            & .user-icon-class {
              margin-top: 10px;
              margin-left: 8px;
            }
          }
        }
        & .assign-button {
          border: 1px solid #404f67;
          color: #404f67;
          border-radius: 66px;
        }

        & .completed-class {
          display: flex;
          align-items: center;
          gap: 10px;
          border: 1px solid #0abd00;
          border-radius: 66px;
          padding: 7px 13px;
          background: rgba(10, 189, 0, 0.1);
          font-weight: 600;
          font-size: 16px;

          & span {
            color: #0abd00;
          }

          & .user-class,
          .user-class img {
            width: 40px;
            height: 40px;
            background: #929daf;
            border-radius: 50%;

            & .user-icon-class {
              margin-top: 10px;
              margin-left: 8px;
            }
          }
        }

        & .rightmost-class {
          display: flex;
          gap: 20px;
        }

        @media (max-width: 890px) {
          margin-top: 20px;
          justify-content: flex-end;
        }

        @media (max-width: 350px) {
          flex-direction: column;
          align-items: flex-start;
        }
      }

      & .options-class {
        margin: 0 20px 5px 0;
        padding: 5px;
        align-items: flex-end;
        cursor: ${({ isCompleted, isTaskAssigned }) =>
          isCompleted || !isTaskAssigned ? 'not-allowed' : 'pointer'};
      }

      @media (max-width: 890px) {
        flex-direction: column;
      }
    }
  }
`;
