import styled from 'styled-components';

export const IndividualERecordWrapper = styled.div`
  margin: 100px 100px;

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    .download-pdf {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;
      font-size: 16px;
      font-weight: 500;
      .download-icon {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #219fff;
      }
    }
  }

  @media (max-width: 660px) {
    display: flex;
    margin: 20px 10px;
    text-align: center;
  }

  .ewrp__ {
    margin: 15px 10px;

    &qr-title-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0px 30px 40px 30px;
    }

    &title {
      color: #219fff;
      font-family: Poppins;
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
    }

    &title-box {
      margin-top: 20px;
      @media (max-width: 660px) {
        margin: 0 auto;
      }
    }

    &name {
      color: #404f67;
      font-family: Poppins;
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }

    &horizontal-line {
      background-color: #929daf;
      width: 100%;
      height: 2px;
    }

    &technology-qr-container {
      display: grid;
      grid-template-columns: 3fr 1fr;
      column-gap: 20px;
      margin-top: 50px;
      position: relative;

      @media (max-width: 660px) {
        display: flex;
        flex-direction: column;
      }
      .technology__ {
        &title {
          color: #404f67;
          font-size: 18px;
          font-weight: 700;
          text-decoration-line: underline;
        }
        &list {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
          gap: 16px;
          margin: 50px 0;
          padding: 0 6px;
          overflow: hidden;
          &-container {
            width: 164px;
            height: 174.188px;
            flex-shrink: 0;
            border-radius: 12px;
            border: 1px solid var(--grays-gray-4, #ccd2dd);
            display: grid;
            justify-content: center;
            align-items: center;
            &-img {
              width: 65px;
              height: 20px;
              flex-shrink: 0;
              img {
                width: 100%;
                height: auto;
              }
            }
            &-name {
              color: #292d32;
              text-align: center;
              font-size: 16px;
              font-weight: 600;
            }
          }
        }
      }
      .qr__ {
        display: grid;
        justify-self: flex-end;

        @media (max-width: 660px) {
          order: -1;
          margin-bottom: 50px;
        }

        &code {
          &-share-btn {
            margin-top: 10px;
            color: #219fff;
            font-size: 16px;
            font-weight: 500;
            text-decoration-line: underline;
            display: flex;
            justify-content: center;
          }
        }
      }
    }
    &projects-teams-container {
      margin: 50px 0;

      position: relative;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: stretch;
      @media (max-width: 1412px) {
        flex-direction: column;
        align-self: center;
      }

      .teams__ {
        flex-wrap: wrap;
        &title {
          color: #404f67;
          font-size: 18px;
          font-weight: 700;
          text-decoration-line: underline;
        }
        &eTeams {
          display: flex;
          gap: 16px;
          margin-top: 30px;
          &-wrp {
            width: 280px;
            height: 160px;
            flex-shrink: 0;
            border-radius: 12px;
            border: 1px solid #ccd2dd;
            background: #fff;
            padding: 16px;
            &-title {
              display: flex;
              gap: 16px;
              color: #404f67;
              font-size: 16px;
              font-weight: 600;
            }
            &-count {
              color: #219fff;
              span {
                width: 75px;
                height: 50px;
                flex-shrink: 0;
                color: #219fff;
                font-size: 60px;
                font-weight: 600;
              }
            }
          }
        }
      }
      .vertical-line {
        align-self: flex-end;
        justify-self: center;
        width: 1px;
        height: 165px;
        background-color: #929daf;
        margin: 0 20px;

        @media (max-width: 1412px) {
          display: none;
        }
      }

      .projects__ {
        &title {
          color: #404f67;
          font-size: 18px;
          font-weight: 700;
          text-decoration-line: underline;
        }
        &eProjects {
          display: flex;
          gap: 16px;
          margin-top: 30px;
          &-wrp {
            width: 280px;
            height: 160px;
            flex-shrink: 0;
            border-radius: 12px;
            border: 1px solid #ccd2dd;
            background: #fff;
            padding: 16px;
            &-title {
              display: flex;
              gap: 16px;
              color: #404f67;
              font-size: 16px;
              font-weight: 600;
            }
            &-count {
              color: #219fff;
              span {
                width: 75px;
                height: 50px;
                flex-shrink: 0;
                color: #219fff;
                font-size: 60px;
                font-weight: 600;
              }
            }
          }
        }

        @media (max-width: 1256px) {
          margin-top: 50px;
        }
      }
    }
    &statistics-task-container {
      margin: 50px 0;

      .statistics-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 660px) {
          margin-top: 20px;
          justify-content: center;
        }
        .statistics-title {
          color: #404f67;
          font-size: 18px;
          font-weight: 700;
          text-decoration-line: underline;
        }
        .show-hide-button {
          .button-title {
            color: #7e8f9a;
            font-size: 13px;
            font-weight: 500;
          }
        }
      }
      .statistics-circles-container {
        margin-top: 50px;
        padding-right: 50px;
        border-radius: 12px;
        border: 2px solid #ccd2dd;
        width: 100%;
        height: 500px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      @media (max-width: 1256px) {
        .statistics-circles-container {
          width: auto;
          height: 100%;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }
`;

export const SemiCircleContainer = styled.div`
  background-color: ${(props) => props.color};
  width: 250px;
  height: 500px;
  border-radius: 12px 250px 250px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .semi-circle-count {
    color: #fff;
    font-size: 52.235px;
    font-weight: 600;
  }

  .semi-circle-label {
    color: #fff;
    font-size: 14.924px;
    font-weight: 400;
  }
  @media (max-width: 1256px) {
    width: 400px;
    height: 400px;
    border-radius: 100%;
  }
`;

export const CircleContainer = styled.div`
  background-color: ${(props) => props.color};
  width: 260px;
  height: 260px;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .count {
    color: #fff;
    font-size: 44.41px;
    font-weight: 600;
  }

  .label {
    color: #fff;
    font-size: 12.689px;
    font-weight: 400;
  }
`;
