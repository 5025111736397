import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { EditorWrapper } from './style';

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
const modules = {
  toolbar: [
    [
      'bold',
      'italic',
      'underline',
      'strike',
      { script: 'sub' },
      { script: 'super' },
    ],
    ['clean'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }],
    ['blockquote'],
    [
      { align: '' },
      { align: 'center' },
      { align: 'right' },
      { align: 'justify' },
    ],
    [{ direction: 'rtl' }],
    ['link', 'image', 'video'],
    ['code-block'],
    [{ font: [] }],
    [{ size: [] }],
    [{ color: [] }],
    [{ background: [] }],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const formats = [
  'bold',
  'italic',
  'underline',
  'strike',
  'script',
  'clean',
  'list',
  'bullet',
  'indent',
  'blockquote',
  'align',
  'direction',
  'link',
  'image',
  'video',
  'code-block',
  'font',
  'size',
  'color',
  'background',
];

export const Editor = ({
  placeholder,
  value,
  onChange,
  hasError,
  label = '',
  required,
  onBlur,
}) => {
  const [showError, setShowError] = useState(false);
  const onBlurHandler = (e) => {
    // Run custom logic passed to input on blur
    typeof onBlur === 'function' && onBlur(e);
    // Show error to trigger error evaluation logic
    setShowError(true);
  };
  const isError =
    // check if dynamic error state passed from parent component
    hasError ||
    // if not then proceed with internal error logic💪
    (showError ? !value : false);
  return (
    <EditorWrapper className="editor-wrapper">
      {label && (
        <div className="editor-wrapper__label">
          {label}
          <span style={{ color: '#FF1E26' }}>{`${required ? '*' : ''}`}</span>
        </div>
      )}
      <ReactQuill
        theme="snow"
        value={value}
        onChange={(html) => onChange(html)}
        modules={modules}
        formats={formats}
        placeholder={placeholder}
        onBlur={onBlurHandler}
      />
      {isError && (
        <p className="editor-wrapper__errorMessage">
          {`Please enter valid ${label ? label : 'value'}`}
        </p>
      )}
    </EditorWrapper>
  );
};
