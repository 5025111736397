import styled from 'styled-components';

export const PeopleWrapper = styled.div`
  & .people-main {
    margin-top: 20px;
    margin-bottom: 22px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & .title {
      font-weight: 700;
      font-size: 30px;
      color: black;
    }
  }
  & .search-container {
    display: flex;
    & .search-text-box {
      margin-top: 8px;
      margin-right: 8px;
      width: 70%;
    }
    & .select-department-box {
      width: 30%;
      margin-bottom: -80px;
    }
  }
  & .pagination-bar {
    margin-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  & .empty-data-section {
    margin-top: 30px;
    text-align: center;
    &--img {
      width: 50%;
    }
    &--text {
      font-size: 18px;
      font-weight: 600;
      color: #404f67;
      margin-top: 1rem;
    }
  }
  & .no-member {
    margin: 24px;
  }
`;
