export const SettingInfoData = (isChangePasswordEnabled) => [
  {
    type: 'account-setting',
    iconName: 'tag-user',
    title: 'Account Setting',
    url: '',
    hasSubMenu: true,
    elements: isChangePasswordEnabled
      ? [
          {
            type: 'account-setting/change-password',
            title: 'Change Password',
            url: '/individual/settings/account-setting/change-password',
          },
          {
            type: 'account-setting/deactivate-account',
            title: 'Deactivate Account',
            url: '/individual/settings/account-setting/deactivate-account',
          },
        ]
      : [
          {
            type: 'account-setting/deactivate-account',
            title: 'Deactivate Account',
            url: '/individual/settings/account-setting/deactivate-account',
          },
        ],
  },
  {
    type: 'membership',
    iconName: 'membership',
    title: 'Membership',
    url: '/individual/settings/membership',
    hasSubMenu: false,
  },
  {
    type: 'privacy',
    iconName: 'shield-security',
    title: 'Privacy',
    url: '',
    hasSubMenu: true,
    elements: [
      {
        type: 'privacy/profile-visibility',
        title: 'Profile Visibility',
        url: '/individual/settings/privacy/profile-visibility',
      },
      {
        type: 'privacy/blocked-accounts',
        title: 'Blocked Accounts',
        url: '/individual/settings/privacy/blocked-accounts',
      },
    ],
  },
  {
    type: 'linked-accounts',
    iconName: 'linked',
    title: 'Linked Accounts',
    url: '/individual/settings/linked-accounts',
    hasSubMenu: false,
  },
];
export const EnterpriseSettingInfoData = [
  {
    type: 'change-password',
    iconName: 'lock',
    title: 'Change Password',
    url: '/enterprise/settings/account-setting/change-password',
    hasSubMenu: false,
  },
  {
    type: 'privacy',
    iconName: 'shield-security',
    title: 'Privacy',
    url: '/enterprise/settings/privacy',
    hasSubMenu: false,
  },
  {
    type: 'services',
    iconName: 'achievements',
    title: 'Services',
    url: '/enterprise/settings/services',
    hasSubMenu: false,
  },
];

export const INFO_TEXT = {
  MEMBER_INFO_DEACTIVATE: `Looks like you are a admin of some Teams/Projects, before deactivating/deleting your account you need to first assign the required roles to the other members for that team/project.`,
};

export const ProfileVisibilityLabels = [
  { text: 'All', value: 'ALL' },
  { text: 'My Network', value: 'MY_NETWORK' },
  {
    text: 'Nobody',
    value: 'NO_BODY',
  },
];
