import styled from 'styled-components';

export const PublicationBlockWrapper = styled.div`
  min-height: 100vh;
  background-color: #f4f7fc;
  padding-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
`;

export const PublicationContentWrapper = styled.div`
  width: 55%;
`;

export const PublicationViewerWrapper = styled.div`
  margin: 0 auto;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

export const CoverImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 12px 12px 0 0;
  margin-bottom: 16px;

  img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    display: block;
    border-radius: 12px 12px 0 0;
  }

  p {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 28px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  .category {
    border-radius: 5px;
    background: #219fff;
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    padding: 10px;
  }
  .copy-link-category {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    gap: 10px;
  }
`;

export const Title = styled.p`
  font-size: 24px;
  font-weight: bold;
  color: #000;
  text-align: center;
  margin-top: 10px;
`;

export const Description = styled.p`
  font-size: 16px;
  color: #555;
  line-height: 1.5;
  margin: 20px 40px;
`;

export const CollaboratorsWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 16px;
  .collaborator-items {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #219fff1a;
    border-radius: 10px;
    padding: 10px;
    flex-wrap: wrap;
    cursor: pointer;
    .profile-image {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      overflow: hidden;
    }

    .profile-text {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #219fff;
      color: #fff;
      font-weight: 500;
    }

    .user-name {
      color: #219fff;
      font-size: 14px;
      font-weight: 600;
    }
  }
`;

export const ContentWrapper = styled.div`
  font-size: 18px;
  color: #333;
  margin-bottom: 20px;
  padding: 40px 60px;
  margin-top: 16px;
  .title {
    color: #404f67;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    a {
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }

  .publisher-content {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    .publisher-image-name {
      display: flex;
      gap: 16px;
      align-items: center;
    }
    .profile-pic-class {
      img {
        width: 54px;
        height: 54px;
        border-radius: 50%;
      }
    }
    .publisher-details {
      font-weight: 400;
      .published-by-user {
        font-style: italic;
        color: grey;
      }
    }
    .published-time {
      font-style: italic;
      color: grey;
    }
  }
`;
