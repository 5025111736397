import { CustomButton, Input, ModalPopup, ScrollableDiv } from 'components';
import { INFO_TEXT } from 'pages/Settings/constants';
import MemberInfoBox from 'pages/enterpriseDashboard/people/Components/memberInfoBox';
import MemberInfoCard from 'pages/enterpriseDashboard/people/Components/memberInfoCard';
import React from 'react';
import { toast } from 'react-toastify';
import { VERIFY_USER_EMAIL, useAxiosWrapper } from 'services';
import tokenService from 'services/token.service';

export const DeleteAccountModal = (props) => {
  const {
    isDeactivatingOrDeleting,
    deleteAccountState,
    onClose,
    onContinueVerification,
    onDeactivateDeleteHandler,
    teamAdminList,
    projectAdminList,
  } = props;
  const [isEmailVerified, setIsEmailVerified] = React.useState(false);
  const loggedInUser = tokenService.getSession();
  const [fnWrapper] = useAxiosWrapper();

  const onVerifyEmailHandler = async () => {
    const res = await fnWrapper({
      url: VERIFY_USER_EMAIL,
      method: 'GET',
      type: 'VERIFY_USER_EMAIL',
      params: {
        userId: loggedInUser.id,
        email: loggedInUser?.email,
      },
    });
    if (res.data.statusCode === 200) {
      setIsEmailVerified(true);

      toast.success(res.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  return (
    <ModalPopup
      hasTitle
      open={false}
      minWidth="700px"
      maxContentWidth="700px"
      title={`Delete Account`}
      onClose={onClose}>
      <div className="popup-layout">
        {deleteAccountState.step === 'CONFIRMATION' && (
          <>
            <p className="delete-account-summary mt-3 mb-2">
              If you delete your account, you will permanently lost your all
              previous data including your teams, projects and other things.
              Once you delete your account, you won’t be able to login again to
              the same account.
            </p>
            <div className="popup-confirmation-message mt-3 mb-4">
              Are you sure you want to Delete your account?
            </div>
            <div className="popup-buttons">
              <CustomButton
                label="No"
                variant="textBtn"
                customPadding="14px 20px"
                onClick={onClose}
              />
              <CustomButton
                label="Yes"
                customPadding="16px 36px"
                onClick={onContinueVerification}
              />
            </div>
          </>
        )}
        {deleteAccountState.step === 'NEED_TO_EMAIL_VERIFY' && (
          <div className="popup-content">
            <div className="popup-confirmation-message mt-3 mb-5">
              Please verify your connected email before deleting this account.
            </div>
            <Input
              placeholder="Enter your mail id"
              height="45px"
              required
              value={loggedInUser?.email}
              disabled
              rightText={
                <span
                  className={
                    !isEmailVerified
                      ? 'verify-email-text'
                      : 'verify-email-text disable-btn'
                  }
                  onClick={onVerifyEmailHandler}>
                  Verify
                </span>
              }
            />
            <div className="popup-buttons mt-5 mb-2">
              <CustomButton
                label="Delete Account"
                customPadding="14px 30px"
                disabled={!isEmailVerified}
                loading={isDeactivatingOrDeleting}
                onClick={() => onDeactivateDeleteHandler('delete')}
              />
            </div>
          </div>
        )}
        {deleteAccountState.step === 'ADMIN_LIST_FOUND' && (
          <ScrollableDiv
            scrollOnHeight={'calc(100vh - 210px)'}
            scrollOnHeightMob={'300px'}>
            <MemberInfoBox
              text={INFO_TEXT.MEMBER_INFO_DEACTIVATE}
              iconName={'info-circle'}
              type={'WARNING'}
            />
            <div className="teams-section">
              <p className="section-title">Teams ({teamAdminList?.length})</p>
              <div className="team-info-cards">
                {teamAdminList?.map((team, index) => (
                  <MemberInfoCard
                    iconName="group-user"
                    key={index}
                    name={team?.teamName}
                    role={team.role}
                  />
                ))}
              </div>
            </div>
            <div className="projects-section">
              <p className="section-title">
                Projects ({projectAdminList?.length})
              </p>
              <div className="project-info-cards">
                {projectAdminList?.map((project, index) => (
                  <MemberInfoCard
                    iconName="project"
                    key={index}
                    name={project?.title}
                    role={project?.projectRole}
                    designation={project?.teams?.map((team, index) =>
                      +index === project?.teams?.length - 1
                        ? team?.teamName
                        : team?.teamName + ', '
                    )}
                  />
                ))}
              </div>
            </div>
          </ScrollableDiv>
        )}
      </div>
    </ModalPopup>
  );
};
