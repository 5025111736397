import { useState, useCallback, useEffect } from 'react';
import { CustomButton, TabGroup } from 'components';
import {
  Route,
  Routes,
  useParams,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { TasksList } from './TasksList';
import { ViewTasks } from './ViewTasks';
import {
  FETCH_PROJECTS_BASED_ON_TEAM,
  FETCH_DEPARTMENT_TASKS_BASED_ON_TEAMID,
  useAxiosWrapper,
} from 'services';
import Icon from 'utlils/Icons/Icon';
import EnterPriseImg from 'assests/images/enterpriseImg.png';

const TASKS_TAB_LIST = ['Project Tasks', 'Department Tasks'];

export const TeamTasks = () => {
  const [activeTab, setActiveTab] = useState(TASKS_TAB_LIST[0]);
  const [tasksListsData, setTasksListsData] = useState([]);
  const [fnWrapper, context] = useAxiosWrapper();
  const { teamId, userType } = useParams();
  const navigate = useNavigate();
  const [openedCard, setOpenedCard] = useState({});
  const location = useLocation();

  const tabSwitchHandler = (value) => {
    setActiveTab(value);
    let tab = value.toLowerCase().replace(' ', '-');
    if (value === TASKS_TAB_LIST[0]) {
      getProjectTasksData();
    } else {
      getDepartmentTasksData();
    }
    navigate(`/${userType}/team-dashboard/${teamId}/tasks/${tab}`);
  };

  const getDepartmentTasksData = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_DEPARTMENT_TASKS_BASED_ON_TEAMID}/${teamId}`,
      method: 'GET',
      type: 'FETCH_DEPARTMENT_TASKS',
    });
    if (res.data.statusCode === 200) {
      const response = res.data.data;
      let departmentTasksData = [];
      response.map((item) => {
        if (item.department === null) {
          departmentTasksData = [];
          return departmentTasksData;
        }
        const data = {
          id: item.department?.id,
          title: item.department?.departmentName,
          isImg: true,
          iconName: '',
          navigateItem: '',
          profilePicture:
            item.department?.enterprise?.profilePicture === null
              ? EnterPriseImg
              : item.department?.enterprise?.profilePicture,
        };
        departmentTasksData.push(data);
        return departmentTasksData;
      });
      setTasksListsData(departmentTasksData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProjectTasksData = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_PROJECTS_BASED_ON_TEAM}/${teamId}`,
      method: 'GET',
      type: 'FETCH_PROJECTS_BASED_ON_TEAM',
    });
    if (res.data.statusCode === 200) {
      const response = res.data.data?.filter(
        (project) => project.teamStatus === '3'
      );
      let projectTasksData = [];
      response?.map((item) => {
        const data = {
          id: item?.projects?.id,
          title: item?.projects?.title,
          isImg: item?.projects?.profilePicture !== null,
          iconName: 'project',
          navigateItem: `/public/project/${item?.projects?.id}/details`,
          profilePicture: item?.projects?.profilePicture,
        };
        projectTasksData.push(data);
        return projectTasksData;
      });
      setTasksListsData(projectTasksData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let pathItems = location.pathname.split('/');
    TASKS_TAB_LIST.forEach((tab) => {
      if (tab.toLowerCase().replace(' ', '-') === pathItems[5]) {
        setActiveTab(tab);
        if (tab === TASKS_TAB_LIST[0]) {
          getProjectTasksData();
        } else {
          getDepartmentTasksData();
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center"
        onClick={() => {
          const taskType = activeTab.split(' ').join('-').toLowerCase();
          openedCard.id
            ? navigate(
                `/${userType}/task-management/${teamId}`,

                { state: { openedCard, taskType } }
              )
            : navigate(`/${userType}/task-management/${teamId}`, {
                replace: true,
              });
        }}>
        <div className="header-text ">Tasks</div>

        <CustomButton>
          Go to Board <Icon name="arrow-right" color="#FFFFFF" />
        </CustomButton>
      </div>
      <div>
        <TabGroup
          tabLists={TASKS_TAB_LIST}
          tabClick={tabSwitchHandler}
          activeTab={activeTab}
        />
      </div>
      <Routes>
        <Route path="">
          <Route
            element={
              <TasksList
                setOpenedCard={setOpenedCard}
                loading={context.busy.status}
                data={tasksListsData}
                taskType={activeTab.toLowerCase().replace(' ', '-')}
              />
            }
            path="project-tasks"
          />
          <Route
            element={
              <TasksList
                setOpenedCard={setOpenedCard}
                loading={context.busy.status}
                data={tasksListsData}
                taskType={activeTab.toLowerCase().replace(' ', '-')}
              />
            }
            path="department-tasks"
          />
          <Route
            element={
              <ViewTasks taskType={activeTab.toLowerCase().replace(' ', '-')} />
            }
            path=":taskType/:cardId/view"
          />
        </Route>
      </Routes>
    </>
  );
};
