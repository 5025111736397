import './title.scss';

export const Title = ({ title, subTitle }) => {
  return (
    <div className="title-box">
      <div className="title">
        <span>{title}</span>
      </div>
      {subTitle && (
        <div className="sub-title">
          <span>{subTitle}</span>
        </div>
      )}
    </div>
  );
};
