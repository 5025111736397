import { useContext } from 'react';
import { Navigate, useSearchParams, useLocation } from 'react-router-dom';
import { RoleBasedAccessContext } from 'context/RoleBasedAccess/roleBasedAccessContext';
import { Unauthorized } from 'components/Unauthorized';

const ProtectedRoute = ({ allowedPermissions = [], children }) => {
  const { roleBasedAccessState } = useContext(RoleBasedAccessContext);
  const teamPermissions = roleBasedAccessState.personalTeamRBAC;
  const projectPermissions = roleBasedAccessState.projectRBAC;
  const allPermissionsContext = [...teamPermissions, ...projectPermissions];

  const [searchParams] = useSearchParams();
  const location = useLocation();
  const session = localStorage.getItem('session');
  let token;
  if (session) {
    const sessionParse = JSON.parse(session);
    token = sessionParse.access_token;
  } else {
    token = null;
  }
  if (!token) {
    const paramsList = Object.fromEntries([...searchParams]);
    const redirectUri = location.pathname;
    if (Object.keys(paramsList).length > 0) {
      return (
        <Navigate
          to="/login/individual"
          replace
          state={{ redirectUri: redirectUri, type: 'INDIVIDUAL' }}
        />
      );
    } else {
      return (
        <Navigate
          to="/login/selectOrganization"
          replace
          state={{ redirectUri: redirectUri }}
        />
      );
    }
  }

  let arrayOfPermissions = allowedPermissions.map((allowedItem) => {
    return allPermissionsContext?.includes(allowedItem);
  });
  let isAllowed = allowedPermissions.length
    ? arrayOfPermissions.some(Boolean)
    : true;

  return <>{isAllowed ? children : <Unauthorized />}</>;
};

export default ProtectedRoute;
