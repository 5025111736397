import PropTypes from 'prop-types';
import { YourNetworkOrRequestStyle } from './style';
import { ProfilePhoto } from '../ProfilePhoto';
import { NavigationalRowItem } from 'components';
import { SCREEN } from 'utlils/Theme/screen';
import { capitalizeFirstLetter } from 'pages/IndividualDashboard/individualOrganizations/enterprise/helper';
import Icon from 'utlils/Icons/Icon';

export const YourNetworkOrRequestList = ({
  networkDetails,
  footerElement,
  mutualConnections,
  userId,
  ...rest
}) => {
  const isIndividualUserRole = networkDetails?.role === 'INDIVIDUAL';
  const { userConnectionsId } = networkDetails;
  const name = isIndividualUserRole
    ? networkDetails?.individual?.name
    : networkDetails?.smallEnterprise?.enterpriseName;

  const profilePicture = isIndividualUserRole
    ? networkDetails?.individual?.profilePicture
    : networkDetails?.smallEnterprise?.profilePicture;

  const title = isIndividualUserRole
    ? networkDetails?.individual?.title
    : networkDetails?.smallEnterprise?.title;

  const userProfileNavigation = isIndividualUserRole
    ? `/public/individual/${
        userConnectionsId ? userConnectionsId : networkDetails?.id
      }/summary/basic-details`
    : `/public/enterprise/${
        userConnectionsId
          ? userConnectionsId
          : networkDetails?.smallEnterprise?.id
      }/details`;

  return (
    <YourNetworkOrRequestStyle className="yourNetworkRequest">
      <div className="yourNetworkRequest__network--details">
        <div className="yourNetworkRequest__network--details__profile">
          <ProfilePhoto
            profilePicture={profilePicture}
            name={name}
            size={SCREEN.width.medium962 ? 90 : 50}
          />
        </div>
        <div className="yourNetworkRequest__network--details__name--title">
          <div
            title={name ? `${capitalizeFirstLetter(name)}` : ''}
            className="text-truncate">
            <NavigationalRowItem
              label={name ? `${capitalizeFirstLetter(name)}` : ''}
              customStyle={{
                fontWeight: 500,
                fontSize: '18px',
                color: '#17191D',
                cursor: 'pointer',
              }}
              navigateTo={userProfileNavigation}
            />
          </div>
          <div
            className="title"
            title={title ? `${capitalizeFirstLetter(title)}` : ''}>
            {title ? `${capitalizeFirstLetter(title)}` : '--'}
          </div>
        </div>
        {mutualConnections ? (
          mutualConnections !== 0 && (
            <div className="mutual-connection">
              <Icon name="connection" color="#404F67" />
              <div>{`${mutualConnections} mutual connections`}</div>
            </div>
          )
        ) : (
          <div className="mutual-connection">
            <Icon name="connection" color="#404F67" />
            <div>----</div>
          </div>
        )}
      </div>
      {footerElement}
    </YourNetworkOrRequestStyle>
  );
};

YourNetworkOrRequestList.propTypes = {
  networkDetails: PropTypes.shape({
    profilePicture: PropTypes.string,
    title: PropTypes.string,
  }),
  footerElement: PropTypes.element,
  mutualConnections: PropTypes.number,
};
