const privacyPolicyContent = `
__MIHINT TECHNOLOGIES LLC PRIVACY POLICY__

Last updated March 12, 2024 
	
All Media Solutions, Inc. is committed to protecting the privacy and security of your Personal Information, and complying with its obligations under applicable data protection law(s), including, but not limited to, the United States Privacy Act of 1974 (“US Privacy Act”) and other state privacy laws, such as the California Consumer Privacy Act (“CCPA”), the Virginia Consumer Data Protection Act (“VCDPA”), and the Colorado Privacy Act (“CPA”).

This privacy policy for MIHINT TECHNOLOGIES LLC (doing business as ___miHint___) ("we," "us," or "our"), describes how and why we might collect, store, use, share, handle or otherwise Process ("Process") Personal Information when using our services, such as when you visit and use our website, or any website of ours that links to this privacy policy ("Services").

In this policy, “Personal Information” means information or an opinion about an identified individual, or an individual who is reasonably identifiable, directly or indirectly, and any other information that is deemed Personal Information under applicable data protection law.
Questions or concerns? Reading this privacy policy will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at ____legal@miHint.ai____.

1.WHAT PERSONAL INFORMATION DO WE COLLECT ?

Personal Information You Disclose To Us
We Process Personal Information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.

Personal Information Provided By You. The Personal Information that we Process depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The Personal Information we Process may include the following:

    • names;

    • phone numbers;

    • email addresses;

    • mailing addresses;

    • usernames;

    • contact preferences;

    • billing addresses;

    • contact or authentication information;

    • passwords; and/or

    • debit/credit card numbers.

Sensitive Information. When necessary, with your consent or as otherwise permitted by applicable data law, we may Process Personal Information financial information. We may Process Personal Information necessary to Process your payment if you make purchases, such as your payment instrument number, and the security code associated with your payment instrument. All payment information is stored by ____encryption______. 

Social Media Login Data. We may provide you with the option to register with us using your existing social media account details, like your Facebook, Twitter, or other social media account. If you choose to register in this way, we will Process the information described in the applicable section(s) below or otherwise permitted by applicable data protection law(s).

Application Information. If you use our application(s), we also may Process the following information if you choose to provide us with access or permission, or otherwise permitted by applicable data protection law(s):

   • Geolocation Information. We may request access or permission to track location-based information from your mobile device, either continuously or while you are using our mobile application(s), to provide certain location-based services. If you wish to change our access or permissions, you may do so in your device's settings.

   • Mobile Device Access. We may request access or permission to certain features from your mobile device, including your mobile device's calendar, social media accounts, and other features. If you wish to change our access or permissions, you may do so in your device's settings.

   • Mobile Device Data. We automatically Process device information (such as your mobile device ID, model, and manufacturer), operating system, version information and system configuration information, device and application identification numbers, browser type and version, hardware model Internet service provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If you are using our application(s), we may also Process information about the phone network associated with your mobile device, your mobile device’s operating system or platform, the type of mobile device you use, your mobile device’s unique device ID, and information about the features of our application(s) you accessed.

   • Push Notifications. We may request to send you push notifications regarding your account or certain features of the application(s) as permitted by applicable data protection laws.

This information is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and for our internal analytics and reporting purposes.

All Personal Information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such Personal Information.



Personal Information Automatically Processed

We may automatically Process certain information when you visit, use, or navigate the Services in accordance with applicable data protection laws. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.

The information we automatically Process in accordance with applicable data protection law(s) includes:

   • Log and Usage Data. Log and usage information is service-related, diagnostic, usage, and performance information our servers automatically Process when you access or use our Services and which we record in log files. Depending on how you interact with us, this log information may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called "crash dumps"), and hardware settings).

   • Device Data. We may Process device information such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device Personal Information may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.

   • Location Data. We Process location Personal Information such as information about your device’s location, which can be either precise or imprecise. How much information we Process depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to Process geolocation Personal Information that tells us your current location (based on your IP address). 


2. HOW DO WE PROCESS YOUR INFORMATION?

We may Process Your Personal Information for a variety of reasons, depending on how you interact with our Services, in a manner that is compliant with applicable data protection laws, including:

   • To facilitate account creation and authentication and otherwise manage user accounts. We may Process your information so you can create and login to your account, as well as keep your account in working order.

   • To deliver and facilitate delivery of services to the user. We may Process your Personal Information to provide you with the requested service.

   • To respond to user inquiries/offer support to users. We may Process your Personal Information to respond to your inquiries and solve any potential issues you might have with the requested service.


   • To send administrative information to you. We may Process your Personal Information to send you details about our products and services, changes to our terms and policies, and other similar information.

   • To fulfill and manage your orders. We may Process your information to fulfill and manage your orders, payments, returns, and exchanges made through the Services.

   • To enable user-to-user communications. We may Process your information if you choose to use any of our offerings that allow for communication with another user.

   • To request feedback. We may Process your Personal Information when necessary to request feedback and to contact you about your use of our Services.

   • To post testimonials. We post testimonials on our Services that may contain Personal Information.

   • To evaluate and improve our Services, products, marketing, and your experience. We may Process your information when we believe it is necessary to identify usage trends, determine the effectiveness of our promotional campaigns, and to evaluate and improve our Services, products, marketing, and your experience.

   • To identify usage trends. We may Process information about how you use our Services to better understand how they are being used so we can improve them.

   • To comply with our legal obligations. We may Process your information to comply with our legal obligations, respond to legal requests, and exercise, establish, or defend our legal rights.


3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?

We may share your Personal Information with third-party vendors, service providers, contractors, or agents ("third parties") who perform services for us or on our behalf and require access to such information to do that work. We will only do so in a manner permitted by applicable data protection laws. We have contracts in place with our third parties, which are designed to help safeguard your Personal Information. This means that they cannot do anything with your Personal Information unless we have instructed them to do it. They will also not share your Personal Information with any organization apart from us. They also commit to protect the Personal Information they hold on our behalf and to retain it for the period we instruct. The categories of third parties we may share Personal Information with are as follows:

    • Payment Processors;

    • User Account Registration & Authentication Services;

    • Social Networks;

    • Sales & Marketing Tools;

    • Product Engineering & Design Tools;

    • Data Analytics Services;

    • Order Fulfillment Service Providers;

    • Cloud Computing Services;

    • Communication & Collaboration Tools; and/or

    • Website Hosting Service Providers

We also may need to share your Personal Information in the following situations and in accordance with applicable data protection laws:

   • Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.

   • Other Users. When you share Personal Information (for example, by posting comments, contributions, or other content to the Services) or otherwise interact with public areas of the Services, such Personal Information may be viewed ball users and may be publicly made available outside the Services in perpetuity. If you interact with other users of our Services and register for our Services through a social network (such as Facebook), your contacts on the social network will see your name, profile photo, and descriptions of your activity. Similarly, other users will be able to view descriptions of your activity, communicate with you within our Services, and view your profile.

   • Offer Wall. Our application(s) may display a third-party hosted "offer wall.” Such an offer wall allows third-party advertisers to offer virtual currency, gifts, or other items to users in return for the acceptance and completion of an advertisement offer. Such an offer may appear in our application(s) and be displayed to you based on certain Personal Information, such as your geographic area or demographic information. When you click on an offer wall, you will be brought to an external website belonging to other persons and will leave our application(s). A unique identifier, such as your user ID, will be shared with the offer wall provider in order to prevent fraud and properly credit your account with the relevant reward.


4. DO WE USE COOKIES AND OTHER TRACKINGTECHNOLOGIES?

We do not use may cookies and similar tracking technologies (like web beacons and pixels) to access or store information. We will only do so in a manner compliant with applicable data protection laws. 


5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?

Our Services may offer you the ability to register and log in using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform.

We may use the information we receive only for the purposes that are described in this privacy policy or that are otherwise made clear to you on the relevant Services. We will do so in a manner compliant with applicable data protection laws. Please note that we do not control, and are not responsible for, other uses of your Personal Information by your third-party social media provider. We recommend that you review their privacy policy to understand how they Process, use, and share your Personal Information, and how you can set your privacy preferences on their sites and apps.

6. HOW LONG DO WE KEEP YOUR PERSONAL INFORMATION?

We will only keep your Personal Information for as long as it is necessary for the purposes set out in this privacy policy and in a manner compliant with applicable data protection laws. No purpose in this privacy policy will require us keeping your Personal Information for longer than 6 months past the termination of the user's account. 

When we have no ongoing legitimate business need to Process your Personal Information, we will either delete, anonymize, pseudonymize or otherwise encrypt such information.

7. HOW DO WE KEEP YOUR PERSONAL INFORMATION SAFE?

We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any Personal Information we Process in a manner compliant with applicable data protection laws. However, despite our safeguards and efforts to secure your information, nonelectronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your Personal Information, transmission of Personal Information to and from our Services is at your own risk. You should only access the Services within a secure environment.

8. DO WE COLLECT PERSONAL INFORMATION FROM MINORS?

We do not knowingly Process Personal Information from or market to children under 16 years of age. Where 16 years of age is incompatible with applicable data protection laws, we will abide by the age prescribed by applicable data protection laws. 

By using the Services, you represent that you are at least 16 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that Personal Information from users less than 16 years of age has been Processed or is otherwise incompatible with applicable data protection laws, we will deactivate the account and take reasonable measures to promptly delete such Personal Information from our records. If you become aware of any Personal Information we may have Processed from children under age 16 or that is incompatible with applicable data protection laws, please email us at ______legal@mihit.ai____.

9. WHAT ARE YOUR PRIVACY RIGHTS?

MiHint respects your control over your Personal Information. You may have the following rights in relation to the Personal Information  we hold about you, depending on your jurisdiction:

   • Your right to access – If you ask us, we will confirm whether we are Processing your Personal Information and, if necessary, provide you with a copy of that Personal Information (along with certain other details). If you require additional copies, we may need to charge a reasonable fee.

   • Your right to correction (rectification) – If the Personal Information we hold about you is inaccurate or incomplete, you are entitled to request to have it corrected. If you are entitled to have the information corrected and if we have shared your Personal Information with others, we will let them know about the rectification where possible. If you ask us, we will also tell you, where possible and lawful to do so, with whom we have shared your Personal Information so that you can contact them directly.

   • Your right to erasure – You can ask us to delete or remove your Personal Information in some circumstances, such as where we no longer need it or if you withdraw your consent (where applicable). If you are entitled to erasure and if we have shared your Personal Information with others, we will let them know about the erasure where possible. If you ask us, we will also tell you, where it is possible and lawful for us to do so, with whom we have shared your Personal Information so that you can contact them directly.

   • Your right to restrict (block) Processing – You can ask us to restrict the Processing of your Personal Information in certain circumstances, such as where you contest the accuracy of that Personal Information or you object to us. If you are entitled to restriction and if we have shared your Personal Information with others, we will let them know about the restriction where it is possible for us to do so. If you ask us, we will also tell you, where it is possible and lawful for us to do so, with whom we have shared your Personal Information so that you can contact them directly.

   • Your right to data portability – You have the right, in certain circumstances, to receive a copy of Personal Information we’ve obtained from you in a structured, commonly used and machine-readable format and to reuse it elsewhere or to ask us to transfer this to a third party of your choice.

   • Your rights in relation to automated decision-making and profiling – You have the right not to be subject to a decision when it’s based on automatic Processing, including profiling, if it produces a legal effect or similarly significantly affects you, unless such profiling is necessary for entering into, or the performance of, a contract between you and us.

   • Your right to withdraw consent – If we rely on your consent (or explicit consent) as our legal basis for Processing your Personal Information, you have the right to withdraw that consent at any time by contacting us.

   • Your right to lodge a complaint with the supervisory authority – if you have a concern about any aspect of our privacy practices, including the way we’ve handled your Personal Information, you can report it to the relevant supervisory authority, but in any event, we will respond to your request within a reasonable timeframe and provide you an explanation. To make such a request of us, please contact us using one of the methods outlined below.

   • Opting-out of marketing and promotional communications – In order to receive marketing and promotional communications, our default position is that you must opt-in to receive such communications unless applicable data protection laws provide otherwise. Should you choose to opt-in, you can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we send, or by contacting us using the details provide below. You will then be removed from the marketing lists.


Please note that some of these rights may be limited where we have an overriding legitimate interest or legal obligation to continue to Process the Personal Information or where the Personal Information may be exempt from disclosure due to applicable law, the applicable rules of professional conduct, attorney-client privilege, legal professional privilege, other applicable privileges or protections or professional secrecy obligations.

Upon your request to terminate your account, we will deactivate or delete your account and information from our active Personal Information bases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.


10. SPECIFIC PRIVACY RIGHTS 
If you are a resident of Virginia, Utah, California, Colorado or Connecticut, you are granted specific rights regarding access to your Personal Information.

Categorical Examples and Retention 

We may collect the following categories of Personal Information from time to time and in accordance with applicable data protection laws. In all cases, we keep the Personal Information for as long as the user has an account with us, and never for more than 6 months after in cases permitted by applicable data protection law. Should applicable data protection prescribe otherwise, we abide by the law in that jurisdiction. 

Category
Examples
A. Identifiers
Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name
B. Personal Information as defined
Name, contact information, education, employment, employment history, and financial information
C. Protected classification characteristics under state or federal law
Gender and date of birth
D. Commercial information
Transaction information, purchase history, financial details, and payment information
E. Internet or other similar network activity
Browsing history, search history, online
Behavior, interest data, and interactions with our and other websites, applications, systems, and advertisements
F. Audio, electronic, visual, thermal, olfactory, or similar information
Images and audio, video or call recordings created in connection with our business activities
G. Professional or employment related information 
Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply fora job with  us
H. Education Information 
Student records and directory information
I. Inferences drawn from collected Personal Information 
Inferences drawn from any of the collected Personal Information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics
J. Sensitive Personal Information 
Account login information, debit or credit card number, contents of email or text messages, drivers' licenses, and citizenship or immigration status



10.1 California Residents

California Civil Code Section 1798.83, also known as the "Shine the Light" law permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of Personal Information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared Personal Information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.

If you are under 18 years of age, reside in California, and have a registered account with the Services, you have the right to request removal of unwanted Personal Information that you publicly post on the Services. To request removal of such Personal Information, please contact us using the contact information provided below and include the email address associated with your account and a statement that you reside in California. We will make sure the Personal Information is not publicly displayed on the Services, but please be aware that the Personal Information may not be completely or comprehensively removed from all our systems (e.g., backups, etc.).

This section applies only to California residents. Under the California Consumer Privacy Act (CCPA), you have the rights listed below. 

The California Code of Regulations defines a “resident” as:
    • every individual who is in the State of California for other than a temporary or transitory purpose and
    • every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose

All other individuals are defined as "non-residents."
If this definition of "resident" applies to you, we must adhere to certain rights and obligations regarding your Personal Information.

Your Privacy Rights

   • Right to request deletion of the Personal Information — Request to delete: You can ask for the deletion of your Personal Information. If you ask us to delete your Personal Information, we will respect your request and delete your Personal Information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech, our compliance requirements resulting from a legal obligation, or any Processing that may be required to protect against illegal activities.

   • Right to be informed — Request to know: Depending on the circumstances, you have a right to know:
        ◦ whether we Process and use your Personal Information;
        ◦ the categories of Personal Information that we Process;
        ◦ the purposes for which the Processed Personal Information is used;
        ◦ whether we sell or share Personal Information to third parties;
        ◦ the categories of Personal Information that we sold, shared, or disclosed for a business purpose;
        ◦ the categories of third parties to whom the Personal Information was sold, shared, or disclosed for a business purpose;
        ◦ the business or commercial purpose for Processing Personal Information; and
        ◦ the specific pieces of Personal Information we Process about you.

In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-identify individual Personal Information to verify a consumer request.

   • Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights: We will not discriminate against you if you exercise your privacy rights.

   • Right to Limit Use and Disclosure of Sensitive Personal Information: You have the right to direct us to limit our use of your sensitive Personal Information if we Process any of the following:
        ◦ social security information, drivers' licenses, state ID cards, passport numbers;
        ◦ account login information;
        ◦ credit card numbers, financial account information, or credentials allowing access to such accounts;
        ◦ precise geolocation;
        ◦ racial or ethnic origin, religious or philosophical beliefs, union membership;
        ◦ the contents of email and text, unless the business is the intended recipient of the communication;
        ◦ genetic, biometric, and/or health data; and/or
        ◦ Personal Information concerning sexual orientation and sex life; 

   • Other rights:
        ◦ You may object to the Processing of your Personal Information.
        ◦ You may request correction of your Personal Information if it is incorrect or no longer relevant, or ask to restrict the Processing of the information.
        ◦ You can designate an authorized agent to make a request under the CCPA on your behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly authorized to act on your behalf in accordance with the CCPA.
        ◦ You may request to opt out from future selling or sharing of your Personal Information to third parties. Upon receiving an opt-out request, we will act upon the request as soon as feasibly possible, but no later than fifteen (15) days from the date of the request submission.

Once a business receives your request, they are no longer allowed to use or disclose your sensitive Personal Information for any other purpose unless you provide consent for the use or disclosure of sensitive Personal Information for additional purposes.

Please note that sensitive Personal Information that is Processed without the purpose of inferring characteristics about a consumer is not covered by this right, as well as the publicly available information.

To exercise your right to limit use and disclosure of sensitive Personal Information, please email us at: ________legal@mihint.ai__.

Verification Process

Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These verification efforts require us to ask you to provide information so that we can match it with information you have previously provided us. For instance, depending on the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we already have on file, or we may contact you through a communication method (e.g., phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate.

We will only use Personal Information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes of verification. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying you.

Financial Incentives

"Financial incentive" means a program, benefit, or other offering, including payments to consumers as compensation, for the disclosure, deletion, sale, or sharing of Personal Information.

The law permits financial incentives or a price or service difference if it is reasonably related to the value of the consumer’s Personal Information. A business must be able to explain how the financial incentive or price or service difference is reasonably related to the value of the consumer’s Personal Information. The explanation must include: 
    • a good-faith estimate of the value of the consumer’s Personal Information that forms the basis for offering the financial incentive or price or service difference; and
    • a description of the method the business used to calculate the value of the consumer’s Personal Information.

We may decide to offer a financial incentive (e.g., price or service difference) in exchange for the retention, sale, or sharing of a consumer’s Personal Information.

If we decide to offer a financial incentive, we will notify you of such financial incentive and explain the price difference, as well as material terms of the financial incentive or price of service difference, including the categories of Personal Information that are implicated by the financial incentive or price or service difference.

If you choose to participate in the financial incentive you can withdraw from the financial incentive at any time or by referring to the contact details at the bottom of this document.


10.2 Colorado Residents

This section applies only to Colorado residents. Under the Colorado Privacy Act (“CPA”), you have the rights listed below. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law. 

    • Right to be informed whether or not we are Processing your Personal Information

    • Right to access your Personal Information

    • Right to correct inaccuracies in your Personal Information

    • Right to request deletion of your Personal Information

    • Right to obtain a copy of the Personal Information you previously shared with us

   • Right to opt out of the Processing of your Personal Information if it is used for targeted advertising, the sale of Personal Information, or profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")

To submit a request to exercise these rights described above, please email___legal@mihint.ai_______ .

If we decline to take action regarding your request and you wish to appeal our decision, please email us at ___legal@mihint.ai_______. Within forty-five (45) days of receipt of an appeal, we will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions.

10.3 Connecticut Residents

This section applies only to Connecticut residents. Under the Connecticut Data Privacy Act (“CTDPA”), you have the rights listed below. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law.

   • Right to be informed whether or not we are Processing your Personal Information

   • Right to access your Personal Information

   • Right to correct inaccuracies in your Personal Information

   • Right to request deletion of your Personal Information

   • Right to obtain a copy of the Personal Information you previously shared with us

   • Right to opt out of the Processing of your Personal Information if it is used for targeted advertising, the sale of Personal Information, or profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")

To submit a request to exercise these rights described above, please email____ legal@mihint.ai ______.

If we decline to take action regarding your request and you wish to appeal our decision, please email us at ____ legal@mihint.ai______. Within sixty (60) days of receipt of an appeal, we will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions.

10.4 Utah Residents

This section applies only to Utah residents. Under the Utah Consumer Privacy Act(UCPA), you have the rights listed below. However, these rights are not absolute, Andin certain cases, we may decline your request as permitted by law.

   • Right to be informed whether or not we are Processing your Personal Information

   • Right to access your Personal Information

   • Right to request deletion of your Personal Information

   • Right to obtain a copy of the Personal Information you previously shared with us

   • Right to opt out of the Processing of your Personal Information if it is used for targeted advertising or the sale of Personal Information

To submit a request to exercise these rights described above, please email____ legal@mihint.ai.


10.5 Virginia Residents

Under the Virginia Consumer Data Protection Act (VCDPA):

"Consumer" means a natural person who is a resident of the Commonwealth acting only in an individual or household context. It does not include a natural person acting in a commercial or employment context.

"Personal Data" means any information that is linked or reasonably linkable to an identified or identifiable natural person. "Personal Information" does not include de-identified Personal Information or publicly available information.

"Sale of Personal Information" means the exchange of Personal Information for monetary consideration.

If this definition of "consumer" applies to you, we must adhere to certain rights and obligations regarding your Personal Information.

Your Privacy Rights

   • Right to be informed whether or not we are Processing your Personal Information

   • Right to access your Personal Information

   • Right to correct inaccuracies in your Personal Information

   • Right to request deletion of your Personal Information

   • Right to obtain a copy of the Personal Information you previously shared with us

   • Right to opt out of the Processing of your Personal Information if it is used for targeted advertising, the sale of Personal Information, or profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")

You may contact us by email at _____ legal@mihint.ai .

If you are using an authorized agent to exercise your rights, we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on your behalf.

Verification Process

We may request that you provide additional information reasonably necessary to verify you and your consumer's request. If you submit the request through unauthorized agent, we may need to Process additional information to verify your identity before Processing your request. 

Upon receiving your request, we will respond without undue delay, but in all cases, within forty-five (45) days of receipt. The response period may be extended once by forty-five (45) additional days when reasonably necessary. We will inform you of any such extension within the initial 45-day response period, together with the reason for the extension.

Right to Appeal

If we decline to take action regarding your request, we will inform you of our decision and reasoning behind it. If you wish to appeal our decision, please email us at____ legal@mihint.ai . Within sixty (60) days of receipt of an appeal, we will inform you inwriting of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If your appeal is denied, you may contact the Attorney General to submit a complaint.

11. UPDATES

We may update this privacy policy from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy policy, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy policy frequently to be informed of how we are protecting your information.

`;

export default privacyPolicyContent;
