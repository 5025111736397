const { default: styled } = require('styled-components');

export const TeamPublicationsWrapper = styled.div`
  padding: 10px;
  font-family: Poppins;
  display: flex;
  flex-direction: column;

  .popup-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: 25px;
    font-weight: 500;
  }
  .new-publication-modal {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
    .privacy-n-category {
      width: 100%;
      padding: 10px 20px;
      display: flex;
      justify-content: space-between;
      .privacy-radio-group {
        display: flex;
        gap: 30px;
      }
    }
    .Upload-publication-image {
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      color: #404f67;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      margin-top: 10px;

      .Upload-btn {
        width: 85px;
        color: #219fff;
        font-size: 15px;
      }
    }
    .add-publication-attachment {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 50px;
    }
    .btn-group {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }

    .upload-containers {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    .image-info {
      /* width: 300px; */
      font-weight: 400;
      color: #219fff;
      font-size: 12px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .publication-container {
    padding: 30px 5px 80px 5px;
    .no-publications-img {
      width: 333px;
      height: 235.71px;
    }

    .no-publications-txt {
      color: #404f67;
      font-family: Poppins;
      font-size: 15px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
    }
    .publication-cards {
      display: flex;
      flex-direction: column;
      gap: 30px;
      .no-filter-data {
        color: grey;
        height: 100%;
        text-align: center;
        padding-top: 100px;
      }
    }
  }

  .search-container {
    display: grid;
    width: 100%;
    grid-template-columns: auto minmax(250px, 270px);
    gap: 20px;
    margin-bottom: 20px;
    & .search-bar {
      background-color: white;
      border-radius: 12px;
    }
    .filter-btn-group {
      position: relative;
      display: flex;
      gap: 15px;
    }
  }
  .pagination-wrapper {
    align-self: center;
  }
`;
