import React, { useState } from 'react';
import styled from 'styled-components';
import { noop } from 'lodash';
import { useClickOutside } from 'utlils/Hooks';

const DropDownContainer = styled.div`
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ height }) => (height ? height : '100%')};
  margin: ${({ margin }) => (margin ? margin : '0 auto')};
  position: relative;
  & .dd-wrapper__ {
    &main {
      cursor: pointer;
      font-family: 'Poppins', 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #929daf;
      background: #f4f7fc;
      white-space: nowrap;
      border-radius: 6px;
      height: ${({ height }) => (height ? height : '100%')};

      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: '20px';
      padding-right: '10px';
      &:after {
        ${({ open }) => (open ? 'content: "\u25B4"' : 'content: "\u25BE"')};
        font-size: 25px;
      }
      &:disabled {
        cursor-pointer: not-allowed;
      }
    }
    &list {
      padding: 0;
      margin: 2px 0 0;
      width: 100%;
      position: absolute;
      z-index: 1;
      font-family: 'Poppins', 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #404f67;
      background: #ffffff;
      border: 1px solid #f4f7fc;
      box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.06);
      border-radius: 6px;

      &--item {
        cursor: pointer;
        list-style: none;
        border-bottom: 1px solid #e9eef4;
        padding: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &--itemClicked {
        cursor: pointer;
        list-style: none;
        border-bottom: 1px solid #e9eef4;
        padding: 14px;
        color: #219fff;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    &selected {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

export const CustomDropDown = ({
  optionLists = [],
  placeholder = '',
  selectedItem = null,
  setSelectedItem,
  height,
  width,
  margin,
  customStyle,
  labelStyle,
  disabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState();

  const toggling = () => setIsOpen(!isOpen);

  const ref = useClickOutside(() => {
    setIsOpen(false);
  });

  const onOptionClicked = async (value) => {
    await setSelectedItem(value);
    setIsOpen(false);
  };

  //Get the longest label from options list
  const longestLabelWidth = () => {
    let longestLabel = placeholder ?? '';
    optionLists.forEach((option) => {
      if (option.label.length > longestLabel.length) {
        longestLabel = option.label;
      }
    });
    const element = document.createElement('span');
    element.innerHTML = longestLabel;
    document.body.appendChild(element);
    const width = element.offsetWidth;
    document.body.removeChild(element);
    const newWidth = width + 35; // add padding and margin
    //To make sure width does not get too large
    if (newWidth > 175) {
      return 175 + 35;
    }
    return newWidth;
  };

  return (
    <DropDownContainer
      ref={ref}
      className="dd-wrapper"
      open={isOpen}
      height={height}
      disabled={disabled}
      margin={margin}
      width={width ?? longestLabelWidth() + 'px'}>
      <div
        className={disabled ? 'dd-wrapper__main disabled' : 'dd-wrapper__main'}
        onClick={disabled ? noop : toggling}
        style={customStyle}>
        <div className="dd-wrapper__selected" style={labelStyle}>
          {selectedItem !== null ? selectedItem.label : placeholder}
        </div>
      </div>
      {isOpen && (
        <ul className="dd-wrapper__list">
          {optionLists.map((option, index) => (
            <li
              onClick={() => onOptionClicked(option)}
              key={index}
              onMouseOver={(evt) => {
                if (evt.target.outerText === option.label)
                  setActiveIndex(index);
              }}
              className={`${
                activeIndex === index
                  ? 'dd-wrapper__list--itemClicked'
                  : 'dd-wrapper__list--item'
              }`}>
              {option?.label}
            </li>
          ))}
        </ul>
      )}
    </DropDownContainer>
  );
};
