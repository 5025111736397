import React from 'react';
import './feedbackFooter.scss';

export const FeedBackFooter = () => {
  return (
    <div className="feedback-section">
      <h2 className="help-txt">Need help</h2>
      <p className="feedback-txt">
        Please send feedback or bug info
        <br />
        to
        <span className="feedback-email-txt">info@linkteamsup.com</span>
      </p>
    </div>
  );
};
