import styled from 'styled-components';

export const ViewApplicationListWrapper = styled.div`
  .application-list-popup-content {
    & .list-content {
      margin-top: 15px;
      align-items: center;
      .content-text {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #404f67;
        .text-bold {
          font-weight: 600;
        }
      }
      & .text-alignment {
        align-items: center;
      }
      .date-text {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #929daf;
      }
      .application-img {
        height: 35px;
        width: 35px;
      }
    }
    & .button-content {
      margin-left: 24px;
      margin-top: 25px;
      margin-bottom: 28px;

      & .popup-btn {
        width: 150px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        .w-btn {
          width: 130px;
        }
      }
    }
    & .underline {
      height: 2.5px;
      background: #c4c4c4;
    }
  }
  .applicants-wrapper {
    & .question_wrapper {
      margin-bottom: 15px;
    }
    & .applicant-sub-header{
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 16px;
      color: #040D20;
      margin-bottom: 25px;
    }
    & .sub-heading {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #404f67;
    }
    .bidding-type{
      width: 250px;
      height: 50px;
      background: #F4F7FC;
      border-radius: 12px;
      display: flex;
      justify-content: left;
      align-items: center;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #929DAF;
      margin-bottom: 20px;
      padding-left: 20px;

    }

      .bid-item {
      background: #F4F7FC;
      border-radius: 10px;
      width: 250px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: left;
      padding-left: 20px;
      padding-right: 20px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #929DAF;
      margin-bottom: 25px;
      margin-top: 20px;
    }
    & .post-attachment {
      margin-bottom: 30px;

      & .img-wrapper {
        display: flex;
        width: 100%;
        word-break: break-all;

        & span {
          color: #929daf;
        }

        & .img-container {
          width: 30px;
          height: 30px;
          margin-top: 10px;
        }

        & .img-text {
          margin-top: 10px;
          margin-left: 5px;
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          color: rgba(64, 79, 103, 0.8);
        }
        & .file-not-uploaded{
          pointer-events: none;
          color: #7E8F9A;
          margin-top: 10px;
        }

        & .remove-icon {
          padding-left: 2px;
          cursor: pointer;
          align-self: center;
        }
        & .icon-content{
          display: flex;
          align-items: center;
          margin-left: 25px;
        }
      }
      & .img-text-file {
        margin-top: 10px;
        margin-left: 4.5%;
        text-decoration: underline;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: rgba(64, 79, 103, 0.8);
        display: flex;
        justify-content: left;
      }
  }
`;
