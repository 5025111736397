import { useEffect, useState } from 'react';
import { getMonthNameFromNumber } from '../utils';
import { sortByDate } from 'helpers';
import { ViewDocsWrapper } from './styles';
import DocCard from './Components/DocCard';

const ViewDocs = ({ docList }) => {
  const [groupedData, setGroupedData] = useState({});

  useEffect(() => {
    if (docList) {
      setGroupedData(
        docList?.reduce((acc, obj) => {
          const year = obj.date.year;
          const month = obj.date.month;
          const key = `${year}-${month}`;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(obj);
          return acc;
        }, {})
      );
    }
  }, [docList]);

  return (
    <ViewDocsWrapper>
      {groupedData &&
        Object.keys(groupedData)
          .sort(sortByDate)
          .map((yearMonth) => (
            <div key={yearMonth} className="main-content">
              <p className="month-label">{getMonthNameFromNumber(yearMonth)}</p>
              <div className="doc-list">
                {groupedData[yearMonth]?.map((docItem, index) => (
                  <DocCard key={index} docItem={docItem} />
                ))}
              </div>
            </div>
          ))}
    </ViewDocsWrapper>
  );
};

export default ViewDocs;
