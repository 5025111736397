import styled from 'styled-components';

export const MembersListContainer = styled.div`
  padding: 10px;
`;

export const MemberCard = styled.div`
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
  display: flex;
  gap: 20px;
`;

export const MemberImage = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .prof-text-wrapper {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    border-radius: 50%;
    background-color: #d6f3f9;
    display: flex;
    justify-content: center;
    align-items: center;
    .prof-text {
      font-size: 20px;
      font-weight: 500;
    }
  }
`;

export const MemberInfo = styled.div``;

export const MemberName = styled.div`
  font-weight: bold;
`;

export const MemberEmail = styled.div`
  color: #17191d;
  font-size: 14px;
`;
