import styled, { css } from 'styled-components';

export const ConversationWrapper = styled.div`
  ${(props) =>
    props.isSelected &&
    css`
      background-color: #b8e2f2;
    `}

  display: flex;
  align-items: center;
  gap: 16px;
  padding: 12px;
  border: 1px solid #d3d3d3;
  border-radius: 8px;
  margin-top: 10px;
  cursor: pointer;

  .conversation__img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .prof-text-wrapper {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      overflow: hidden;
      border-radius: 50%;
      background-color: #d6f3f9;
      display: flex;
      justify-content: center;
      align-items: center;
      .prof-text {
        font-size: 22px;
        font-weight: 500;
      }
    }
  }

  .conversation__info {
    flex: 1;
    display: flex;
    flex-direction: column;

    .conversation__name {
      color: #17191d;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      font-weight: bold;
      margin-bottom: 4px;
    }

    .conversation__action {
      color: #555;
      font-size: 14px;
      color: #258c60;
    }

    .conversation__last-message {
      font-size: 14px;
      color: #616365;
    }
  }

  .conversation__last-message-time {
    font-size: 14px;
    color: #616365;
  }
`;
