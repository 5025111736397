import styled from 'styled-components';
export const ProjectPublicWrapper = styled.div`
  .main-container {
    min-height: 100vh;
    background-color: #f4f7fc;
    & .layout-container {
      padding: 40px 8% 20px 8%;
      & .layout-upper-container {
        display: grid;
        grid-template-columns: auto minmax(420px, 450px);
        grid-gap: 40px;
        @media (max-width: 1299px) {
          display: block;
        }
        & .upper-container-child {
          border-radius: 12px;
          padding: 30px;
          background-color: white;
          @media (max-width: 1299px) {
            margin: 25px 0;
          }
        }
      }
      @media (max-width: 1600px) {
        padding: 40px 6% 20px 6%;
      }
      @media (max-width: 992px) {
        padding: 40px 4% 20px 4%;
      }

      & .layout-lower-container {
        margin-top: 40px;

        display: grid;
        grid-template-columns: minmax(200px, 220px) auto;
        grid-gap: 40px;
        @media (max-width: 1199px) {
          display: block;
        }
        & .sub-container {
          @media (max-width: 1199px) {
            margin: 25px 0;
          }
          & .main-wrapper {
            border-radius: 12px;
            padding-bottom: 28px;
            background-color: white;
            position: relative;

            & .heading {
              padding: 28px;
              border-bottom: 1px solid #e9eef4;
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 700;
              font-size: 24px;
              color: #17191d;

              & span {
                font-weight: 500;
                font-size: 20px;
                color: #7e8f9a;
              }
            }
            & .content {
              padding: 28px;
              & .details-item {
                margin: 24px 0;
                & .details-item-label {
                  font-weight: 700;
                  font-size: 14px;
                  line-height: 20px;
                  color: #404f67;
                }
                & .details-item-value {
                  min-height: 56px;
                  line-height: 30px;
                  color: #404f67;
                  text-align: left;
                  padding: 16px;
                  background: #f4f7fc;
                  border-radius: 5px;
                  word-wrap: break-word;
                }
                & .post-attachment {
                  padding: 0 10px;
                  display: grid;
                  grid-template-columns: repeat(2, 1fr);
                  gap: 10px 20px;

                  & .img-wrapper {
                    display: flex;
                    width: 100%;
                    word-break: break-all;

                    & a {
                      color: #17191d;
                    }

                    & .img-container {
                      width: 30px;
                      height: 30px;
                      margin-top: 10px;
                      &:hover {
                        cursor: pointer;
                      }
                    }

                    & .img-text {
                      margin-top: 10px;
                      text-decoration: underline;

                      &:hover {
                        cursor: pointer;
                      }
                    }
                  }

                  @media (max-width: 767px) {
                    grid-template-columns: 1fr;
                    gap: 5px;
                  }
                }
              }
              & .no-attachments {
                padding: 10px;
                color: #929daf;
              }
              & .cards-container {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 20px;

                @media (min-width: 900px) and (max-width: 1499px) {
                  grid-template-columns: repeat(2, 1fr);
                }

                @media (max-width: 899px) {
                  display: block;
                }
              }
              & .no-data-class {
                width: 50%;
                margin: 50px auto;
                text-align: center;
                color: #404f67;
              }
            }

            & .no-content-wrapper {
              margin: 50px 0;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              gap: 16px;
              & .no-access-img {
                display: flex;
                justify-content: center;
                align-items: center;
              }
              & .no-access-title {
                width: 75%;
                margin: 0 auto;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                color: #404f67;
                text-align: center;
              }
              & .no-access-text {
                width: 75%;
                margin: 0 auto;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #404f67;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
`;
