import './index.scss';
import { isMobile } from '../../utlils/isMobile';
import { forwardRef } from 'react';

export const ScrollableDiv = forwardRef(
  (
    {
      scrollOnHeight = '400px',
      scrollOnHeightMob = '500px',
      maxWidth = '100%',
      marginRight = '16px',
      children,
    },
    ref
  ) => {
    return (
      <div className="scroll-container">
        <div
          ref={ref}
          style={{
            height: !isMobile() ? scrollOnHeight : scrollOnHeightMob,
          }}
          className={`scrollable-div ${
            isMobile() ? 'scrollable-div-mobile' : 'scrollable-div-desktop'
          }`}>
          <div
            className="scrollable-div__wrp"
            style={{ maxWidth: maxWidth, marginRight: marginRight }}>
            {children}
          </div>
        </div>
      </div>
    );
  }
);
