export const setLoading = (dispatch, status) =>
  dispatch({ type: 'SET_LOADING', payload: status });

export const setProgressStep = (dispatch, key) =>
  dispatch({ type: 'SET_PROGRESS', payload: key });

export const setTeamOwnerInformation = async (dispatch, res) => {
  await setLoading(dispatch, true);
  await dispatch({
    type: 'SET_TEAM_OWNER_INFO',
    teamOwnerName: res.name,
    teamOwnerDesignation: '',
    teamOwnerRole: 'ADMIN',
    teamOwnerEmail: res.email,
  });
  await setLoading(dispatch, false);
};

export const onChangeTeamsCredential = async (dispatch, fieldName, value) => {
  await dispatch({
    type: 'SET_TEAM_INFO',
    payload1: fieldName,
    payload2: value,
  });
};

export const onChangeInviteMembers = async (dispatch, value) => {
  await dispatch({
    type: 'SET_TEAM_MEMBER_INFO',
    teamMembers: value,
  });
};

export const onAddNewMembers = async (dispatch, value) => {
  await dispatch({
    type: 'ADD_NEW_MEMBER',
    teamMembers: value,
  });
};

export const onDeleteMembers = async (dispatch, value) => {
  await dispatch({
    type: 'DELETE_MEMBER',
    teamMembers: value,
  });
};

export const onAddSearchedMembers = async (dispatch, value) => {
  await dispatch({
    type: 'ADD_SEARCHED_MEMBER',
    teamMembers: value,
  });
};

export const clearState = async (dispatch) => {
  await dispatch({
    type: 'CLEAR_STATE',
  });
};
