import styled from 'styled-components';

export const PrivateTeamProjectCardWrapper = styled.div`
  border-radius: 10px;
  padding: 12px;
  border: 1px solid #c4c4c4;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & .content-header-class {
    margin: 12px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 12px;

    & .profile-pic-class,
    .profile-pic-class img {
      align-self: flex-start;
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }

    & .icon-style-class {
      align-self: flex-start;
      background: #e9fbf6;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & .card-heading {
      color: #404f67;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;

      & .title-class {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #17191d;
      }

      & .sub-title-wrapper {
        display: flex;
        align-items: center;
        column-gap: 50px;

        & .sub-title-class {
          display: flex;
          align-items: center;
          column-gap: 8px;
          & span {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
          }
        }

        @media (max-width: 647px) {
          flex-wrap: wrap;
          row-gap: 8px;
        }
      }
    }
  }

  @media (max-width: 647px) {
    flex-direction: column;
    align-items: flex-start;

    & :nth-child(2) {
      align-self: flex-end;
    }
  }
`;
