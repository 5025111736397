import React from 'react';
import './individual.scss';
import { Box, DropDown, Input, PhoneNumberInputBox } from '../../../components';
import { ReCaptcha } from 'components/ReCaptcha';
import Google from '../../../../src/assests/images/google.png';
import Linkedin from '../../../../src/assests/images/linkedin.png';
import { Country } from 'country-state-city';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { useLocation } from 'react-router-dom';

const IndividualUser = ({
  waitingListStatus,
  handleInputChange,
  state,
  setState,
  onSignup,
  showLinkedinPopup,
  handleInputBlur,
  passwordError,
  occupations,
  handleCaptchaVerify,
}) => {
  const {
    name,
    email,
    userName,
    countryData,
    password,
    confirmPassword,
    contactNo,
    occupation,
    hasPasswordEntered,
    hasCPasswordEntered,
    error,
  } = state;

  const allCountryLists = Country.getAllCountries();
  const location = useLocation();

  const onBlur = async () => {
    await setState({
      ...state,
      error: {
        ...error,
        contactNo:
          (!contactNo || !isValidPhoneNumber(contactNo)) &&
          'Please enter a valid mobile phone number.',
      },
    });
  };

  return (
    <Box className="individual-sign-up">
      {/* social login button */}
      {location.pathname === '/signup/individual'
        ? !waitingListStatus && (
            <div className="row">
              <div className="col-md-6 col-lg-6 col-12">
                <Box width="100%" mb="30px" rowGap="25px" colGap="25px">
                  <button className="google-signup-btn" onClick={onSignup}>
                    <img src={Google} alt="google-img" />
                    <span className="socials-btn-span-individual-signup">
                      Continue with Google
                    </span>
                  </button>
                </Box>
              </div>
              <div className="col-md-6 col-lg-6 col-12">
                <Box width="100%" mb="30px" rowGap="25px" colGap="25px">
                  <button
                    className="linkdin-signup-btn"
                    onClick={showLinkedinPopup}>
                    <img src={Linkedin} alt="linkedin-img" />
                    <span className="socials-btn-span-individual-signup">
                      Continue with Linkedin
                    </span>
                  </button>
                </Box>
              </div>
            </div>
          )
        : null}
      {/* Form Fields */}
      <div className=" row">
        <div className="col-md-6 col-lg-6 col-12">
          <Box mt="20px">
            <Input
              label="Full Name"
              iconLeft={true}
              iconName="user"
              placeholder="Full Name"
              type="text"
              required
              value={name}
              onChange={(value) =>
                handleInputChange('name', value.target.value)
              }
              hasError={error?.name}
              errorMessage={error?.name}
            />
          </Box>
        </div>
        <div className="col-md-6 col-lg-6 col-12">
          <Box mt="20px">
            <Input
              label="Username"
              iconLeft={true}
              iconName="people"
              placeholder="Username"
              type="text"
              required
              value={userName}
              onBlur={(value) =>
                handleInputBlur('userName', value.target.value)
              }
              onChange={(value) =>
                handleInputChange('userName', value.target.value)
              }
              hasError={error?.userName}
              errorMessage={error?.userName}
            />
          </Box>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-6 col-12">
          <Box mt="20px">
            <Input
              label="Email"
              iconLeft={true}
              iconName="sms"
              placeholder="Email"
              type="email"
              required
              value={email}
              onBlur={(value) => handleInputBlur('email', value.target.value)}
              onChange={(value) =>
                handleInputChange('email', value.target.value)
              }
              hasError={error?.email}
              errorMessage={error?.email}
            />
          </Box>
        </div>
        <div className="col-md-6 col-lg-6 col-12">
          <Box mt="20px">
            <DropDown
              label="Country"
              items={allCountryLists}
              objectKey="name"
              placeholder="Select"
              hasSearchBox
              required
              value={countryData}
              onClick={(value) => handleInputChange('countryData', value)}
            />
          </Box>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-6 col-12">
          <Box mt="20px">
            <PhoneNumberInputBox
              label={'Contact Number'}
              placeholder="Contact Number"
              required={true}
              value={contactNo}
              onChange={(value) => handleInputChange('contactNo', value)}
              onBlur={onBlur}
              hasError={error?.contactNo}
              errorMessage={error?.contactNo}
            />
          </Box>
        </div>
        <div className="col-md-6 col-lg-6 col-12">
          <Box mt="20px">
            <DropDown
              label={'Occupation'}
              placeholder="Select"
              objectKey="value"
              items={occupations}
              value={occupation}
              onClick={(value) => handleInputChange('occupation', value)}
            />
          </Box>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-6 col-12">
          <Box mt="20px">
            <Input
              label="Password"
              iconLeft={true}
              iconName="lock"
              placeholder="Password"
              type="password"
              required
              value={password}
              onChange={(value) =>
                handleInputChange('password', value.target.value)
              }
              onBlur={() =>
                setState({
                  ...state,
                  hasPasswordEntered: true,
                })
              }
              showErrorBorderOnly={error?.password}
            />
          </Box>
        </div>
        <div className="col-md-6 col-lg-6 col-12">
          <Box mt="20px">
            <Input
              label="Confirm Password"
              iconLeft={true}
              iconName="lock"
              placeholder="Confirm Password"
              type="password"
              required
              value={confirmPassword}
              onChange={(value) =>
                handleInputChange('confirmPassword', value.target.value)
              }
              onBlur={() =>
                setState({
                  ...state,
                  hasCPasswordEntered: true,
                })
              }
              hasError={
                hasPasswordEntered &&
                hasCPasswordEntered &&
                password !== confirmPassword
              }
              errorMessage={
                !confirmPassword
                  ? ''
                  : 'Confirm password should be match with password'
              }
            />
          </Box>
        </div>
        <div className="recaptcha-container">
          <ReCaptcha
            siteKey={process.env.REACT_APP_RE_CAPTCHA_SITE_KEY}
            onVerify={handleCaptchaVerify}
          />
        </div>
      </div>
      {passwordError !== null && (
        <div className="row">
          <div className="col-12">
            <Box mt="20px" className="has-error">
              {passwordError}
            </Box>
          </div>
        </div>
      )}
    </Box>
  );
};

export default IndividualUser;
