import { CustomButton } from 'components';
import Icon from 'utlils/Icons/Icon';
import { EnterpriseDetailsWrapper } from './style';
import { startCase } from 'lodash';
import { Country } from 'country-state-city';

export const EnterprisePublicDetails = ({
  enterpriseLocationDetails,
  details,
}) => {
  return (
    <EnterpriseDetailsWrapper>
      <div className="sub-container">
        <div className="main-wrapper">
          <div className="heading">Details</div>
          <div className="content">
            <div className="content-heading">
              <Icon name="building" color="929DAF" size={30} />
              About Us
            </div>
            {details && <p className="content-content">{details}</p>}
          </div>
          <div className="location--wrapper">
            {enterpriseLocationDetails?.length ? (
              <>
                <Icon name="location" size={28} color="#17191D" />
                <span className="location-title">Location</span>
              </>
            ) : null}
            <div className="row">
              {enterpriseLocationDetails?.length
                ? enterpriseLocationDetails?.map((item, index) => (
                    <div className="col-md-4 location-content" key={index}>
                      <div className="map-location-class">
                        <p className="location-text-style">
                          {startCase(item.locationType.split('_').join(' '))}
                        </p>
                        <div className="details-item-value mb-1">
                          <span>{item.address}</span>
                        </div>
                        <div className="details-item-value mb-1">
                          <span>{item.city}</span>
                          {','} <span>{item.state}</span>
                          {','}{' '}
                          <span>
                            {Country.getCountryByCode(item?.country).name}
                          </span>
                        </div>
                        <CustomButton
                          color="themeSecondary"
                          variant="textBtn"
                          customPadding="0 0"
                          className="text-decoration-underline">
                          Locate on map
                          <Icon name="export-2" color="#219cdf" size={18} />
                        </CustomButton>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </div>
    </EnterpriseDetailsWrapper>
  );
};
