import { useEffect, useState, useCallback } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import './signupVerification.scss';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Box, CustomButton, Loader, Title } from '../../../components';
import Icon from 'utlils/Icons/Icon';

const SignupVerification = () => {
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loading, setloading] = useState(true);
  const [alreadyVerified, setAlreadyVerified] = useState(false);
  const [isError, setIsError] = useState(false);
  const [userEmail, setUserEmail] = useState(null);
  const [errors, setErrors] = useState(null);

  const verifyEmailHandler = useCallback(async (ele) => {
    try {
      setUserEmail(ele.email);
      const data = {
        authenticationToken: ele.tk,
        hashKey: ele.hsh,
        userId: ele.id,
        isVerifyByLink: true,
      };
      await axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/verifyEmail`, data)
        .then((data) => {
          if (data.data.statusCode === 200) {
            setloading(false);
          }
        })
        .catch((err) => {
          if (err.response.data.message === 'User has been already verified') {
            setAlreadyVerified(true);
          } else {
            setErrors(err.response.data.message);
            setIsError(true);
          }
          setloading(false);
        });
    } catch (error) {
      setIsError(true);
      setloading(false);
    }
  }, []);

  useEffect(() => {
    const paramsList = Object.fromEntries([...searchParams]);
    verifyEmailHandler(paramsList);
  }, [searchParams, verifyEmailHandler]);

  const navigateToLoginHandler = () => {
    navigate('/login/selectOrganization', {
      replace: true,
    });
  };

  const resendEmailHandler = async () => {
    try {
      const paramsList = Object.fromEntries([...searchParams]);
      const data = {
        id: paramsList.id,
      };
      await axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/resendEmailVerification`,
          data
        )
        .then((data) => {
          if (data.data.statusCode === 200) {
            toast.success('Email sent successfully', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored',
            });
          }
        })
        .catch((err) => {
          if (
            err.response.data.message === 'Your email has been already verified'
          ) {
            navigate('/login/selectOrganization', {
              replace: true,
            });
            localStorage.removeItem('signup-user-details');
          }
          toast.error(err.response.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  return (
    <Box className="container-fluid">
      <div className="row verify-email-container">
        <div className=" col-lg-7 ">
          <div className="container">
            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-7">
                <Box>
                  <Box className="verify-email-wrapper">
                    {/* logo */}
                    <Box mb="78px" mt="33px">
                      <img
                        src={process.env.PUBLIC_URL + '/new-logo.png'}
                        className="img common-navbar-logo"
                        alt="logo"
                      />
                    </Box>
                    {/* title and subtitle */}
                    {loading ? (
                      <Loader loading={loading} />
                    ) : (
                      <>
                        <Box mb="40px">
                          <Title
                            title={
                              isError
                                ? 'Email verification failed'
                                : 'Email verified'
                            }
                            subTitle={
                              isError
                                ? [
                                    <strong className="verify-email-txt">
                                      {errors}.
                                    </strong>,
                                    ` Please click on resend for verify your Email.`,
                                  ]
                                : [
                                    'Your mail id ',
                                    <strong className="verify-email-txt">
                                      {userEmail}
                                    </strong>,
                                    ` is ${
                                      alreadyVerified
                                        ? ' already'
                                        : ' successfully'
                                    } verified. You can now login to your account.`,
                                  ]
                            }
                          />
                        </Box>

                        <Box width={136} mb="40px" mt="40px">
                          <CustomButton
                            onClick={
                              isError
                                ? resendEmailHandler
                                : navigateToLoginHandler
                            }
                            className="w-100"
                            customPadding="12px 16px">
                            {isError ? 'Resend' : 'Log in'}
                            <Icon
                              name="icon-right"
                              color="#fffff"
                              className="mt-2"
                            />
                          </CustomButton>
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
        </div>
        <div className="col-lg-5 d-none d-sm-none d-lg-block image-wrapper ">
          {/* right side image */}
          <div className="right-img"></div>
        </div>
      </div>
    </Box>
  );
};

export default SignupVerification;
