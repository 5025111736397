import { CustomButton, Loader, ModalPopup } from 'components';
import React, { useState, useCallback, useEffect } from 'react';
import { RequestsListWrapper } from './style';
import noDataImg from 'assests/images/nodata.png';
import EnterPriseImg from 'assests/images/enterpriseImg.png';
import HighFivePana from 'assests/images/high-five-pana.png';
import Icon from 'utlils/Icons/Icon';
import { getDateTime } from 'utlils/utils';
import { useParams } from 'react-router-dom';
import { TYPES } from 'pages/IndividualDashboard/constants';

import {
  FETCH_TEAM_REQUESTS,
  UPDATE_PROJECT_INVITATION,
  useAxiosWrapper,
} from 'services';

export const TeamRequestsList = () => {
  const [fnWrapper, context] = useAxiosWrapper();
  const [requests, setRequests] = useState([]);
  const [isJoinOpen, setIsJoinOpen] = useState(false);
  const [isDenyOpen, setIsDenyOpen] = useState(false);
  const [currentProject, setCurrentProject] = useState('');
  const { teamId } = useParams();

  const denyHandler = () => {
    updateInvitation(currentProject, 'reject');
    setIsDenyOpen(false);
  };

  const joinHandler = async (projectId) => {
    setCurrentProject(projectId);
    setIsJoinOpen(true);
    updateInvitation(projectId, 'accept');
  };

  const updateInvitation = async (projId, status) => {
    const res = await fnWrapper({
      url: `${UPDATE_PROJECT_INVITATION}`,
      method: 'POST',
      payload: {
        teamId: teamId,
        projectId: projId,
        status: status,
      },
      type: 'UPDATE_PROJECT_INVITATION',
    });

    if (res.data.statusCode === 200) {
      getTeamRequests();
    }
  };

  const getTeamRequests = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_TEAM_REQUESTS}/${teamId}`,
      method: 'GET',
      type: 'FETCH_TEAM_REQUESTS',
    });

    if (res.data.statusCode === 200) {
      const result = res.data.data?.filter((req) => req.teamStatus === '1');
      let requestsList = [];
      result.map((request, index) => {
        const item = {
          id: index + 1,
          isImg:
            request.projects.projectType === TYPES.ENTERPRISE
              ? true
              : request.projects.user.individual.profilePicture !== null,
          imgSrc:
            request.projects.projectType === TYPES.ENTERPRISE
              ? request.projects.enterprise.profilePicture !== null
                ? request.projects.enterprise.profilePicture
                : EnterPriseImg
              : request.projects.user.individual.profilePicture,
          invitedBy:
            request.projects.projectType === TYPES.ENTERPRISE
              ? request.projects.enterprise.enterpriseName
              : request.projects.user.individual.name,
          invitationTime: request.createdAt,
          invitedFor: request.projects.title,
          invitedForId: request.projects.id,
        };
        requestsList.push(item);
        return requestsList;
      });
      requestsList.sort(function (a, b) {
        return new Date(b.invitationTime) - new Date(a.invitationTime);
      });
      setRequests(requestsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getTeamRequests();
  }, [getTeamRequests]);

  return (
    <RequestsListWrapper>
      {context.busy.status ? (
        <Loader loading={context.busy.status} />
      ) : requests.length > 0 ? (
        <>
          {requests.map((request, index) => {
            return (
              <div key={index} className="request-item">
                <div className="left-side-class">
                  <div className="profile-pic-container">
                    {!request.isImg ? (
                      <div className="pfp-alt-circle">
                        <Icon name={'user-icon'} color="#292D32" />
                      </div>
                    ) : (
                      <img alt="" src={request.imgSrc} width={'100%'} />
                    )}
                  </div>
                  <div className="user-name">
                    <p className="user-name-style">
                      <span className="admin-style">{request.invitedBy}</span>{' '}
                      invited your team to join{' '}
                      <span className="stage-style">{request.invitedFor}</span>{' '}
                      project.
                    </p>
                    <span className="user-posted-time">
                      {getDateTime(request.invitationTime)}
                    </span>
                  </div>
                </div>
                <div className="right-side-class">
                  <div className="border-btn">
                    <CustomButton
                      label="Join"
                      variant="textBtn"
                      onClick={() => joinHandler(request.invitedForId)}
                      className="underline-btn"
                    />
                  </div>
                  <CustomButton
                    label="Deny"
                    variant="textBtn"
                    color="themeTertiary"
                    onClick={() => {
                      setCurrentProject(request.invitedForId);
                      setIsDenyOpen(true);
                    }}
                    className="underline-btn"
                  />
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <div className="no-data">
          <div className="no-data-img">
            <img src={noDataImg} alt="" width="320px" />
          </div>
          <div className="no-data-text">
            <div>Oh, looks like you don’t have any Requests.</div>
          </div>
        </div>
      )}
      {isJoinOpen && (
        <ModalPopup
          open={false}
          onClose={() => {
            setCurrentProject('');
            setIsJoinOpen(false);
          }}>
          {context.busy.status ? (
            <Loader loading={context.busy.status} />
          ) : (
            <div className="join-popup-wrapper">
              <div className="join-img">
                <img src={HighFivePana} alt="" width="320px" />
              </div>
              <div className="join-text">
                <div>Hurray! You've successfully joined now.</div>
              </div>
              <CustomButton
                label="Okay"
                onClick={() => {
                  setCurrentProject('');
                  setIsJoinOpen(false);
                }}
                className="join-okay-btn"
              />
            </div>
          )}
        </ModalPopup>
      )}
      {isDenyOpen && (
        <ModalPopup
          open={false}
          onClose={() => {
            setCurrentProject('');
            setIsDenyOpen(false);
          }}>
          <div className="deny-popup-wrapper">
            <p className="text-center w-75 mx-auto">
              Are you sure you want to reject the joining invitation for this
              project?
            </p>
            <div className="deny-buttons">
              <CustomButton
                label="No"
                color="themeTertiary"
                customPadding="16px 22px"
                variant="outline"
                onClick={() => {
                  setCurrentProject('');
                  setIsDenyOpen(false);
                }}
              />

              <CustomButton
                label="Yes"
                customPadding="16px 20px"
                variant="outline"
                onClick={denyHandler}
              />
            </div>
          </div>
        </ModalPopup>
      )}
    </RequestsListWrapper>
  );
};
