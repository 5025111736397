import React, { useState, useContext } from 'react';
import { useParams, useNavigate, Routes, Route } from 'react-router-dom';
import './style.scss';
import { CustomButton, ModalPopup } from 'components';
import { Details, LinkProject, Streams, AddTeams } from '../Components';
import { CreateProjectContext } from 'context/Projects/CreateProject/createProjectContext';
import { clearState } from 'context/Projects/CreateProject/createProjectAction';
import ProgressBar from 'pages/IndividualDashboard/components/progressBar';
import { UPDATE_PROJECT, useAxiosWrapper } from 'services';
import { PROJECT_CATEGORY } from 'constants/constants';

export const EditProject = ({ type = '', baseUrl = '' }) => {
  const [fnWrapper, context] = useAxiosWrapper();
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { createProjectState, createProjectDispatch } =
    useContext(CreateProjectContext);
  const [editPopupOpen, setEditPopupOpen] = useState(false);

  const createProjectProgressData = [
    {
      id: 1,
      title: 'Details',
      url: `${baseUrl}/${projectId}/edit/details`,
    },
    {
      id: 2,
      title: 'Link Project',
      url: `${baseUrl}/${projectId}/edit/link-project`,
    },
    {
      id: 3,
      title: 'Add Team',
      url: `${baseUrl}/${projectId}/edit/add-team`,
    },
    {
      id: 4,
      title: 'Streams',
      url: `${baseUrl}/${projectId}/edit/streams`,
    },
  ];

  const projectCategories = {
    project_category: {
      label: 'Project Category',
      dataType: PROJECT_CATEGORY,
      key: 'label',
      placeholder: 'Select Category',
    },
  };

  const exitHandler = () => {
    if (createProjectState.isEdited) {
      setEditPopupOpen(true);
    } else {
      leaveWithoutSave();
    }
  };

  const leaveWithoutSave = async () => {
    await clearState(createProjectDispatch);
    navigate(`${baseUrl}/${projectId}/view/details`, { replace: true });
  };

  const saveAndLeave = async () => {
    let formData = new FormData();

    for (var i of createProjectState.attachments) {
      formData.append('attachments', i);
    }
    for (var f of createProjectState.linkAttachments) {
      formData.append('linkAttachments', f);
    }
    formData.append('title', createProjectState.projectTitle);
    formData.append('problemStatement', createProjectState.problemStatement);
    formData.append('category', createProjectState.category.value);
    formData.append('linkedProject', createProjectState.linkedProjectId);
    formData.append('link', createProjectState.link);
    formData.append('profilePicture', createProjectState.projectAvatar);
    formData.append('solution', createProjectState.solution);
    for (var j of createProjectState.linkedTeams) {
      formData.append('teamId', j);
    }
    for (var k of createProjectState.deletedTeams) {
      formData.append('deleteTeamId', k);
    }
    if (createProjectState.stream.length !== 0) {
      const streamData = JSON.stringify({
        streamId: createProjectState.editStreamId,
        stages: createProjectState.stream.map((stage) => {
          return {
            id: stage.id,
            stageName: stage.value,
            stageNumber: stage.number,
            isActive: stage.isActive,
            isStarted: stage.isStarted,
          };
        }),
      });
      formData.append('stream', streamData);
    }

    const res = await fnWrapper({
      url: `${UPDATE_PROJECT}/${projectId}`,
      method: 'POST',
      payload: formData,
      type: 'UPDATE_PROJECT',
    });

    if (res.data.statusCode === 200) {
      setEditPopupOpen(false);
      await clearState(createProjectDispatch);
      navigate(`${baseUrl}/${projectId}/view/details`, { replace: true });
    }
  };

  return (
    <div className="edit-project-container">
      <div className="edit-project-heading">
        <span className="heading-text">Edit project</span>
      </div>
      <div className="edit-project-progress-bar">
        <ProgressBar
          progressData={createProjectProgressData}
          step={createProjectState.progressStep}
          isRoute
        />
        <Routes>
          <Route
            element={
              <Details
                projectCategories={projectCategories}
                onLeavingEditor={exitHandler}
                type={type}
                baseUrl={baseUrl}
              />
            }
            path="details"
          />
          <Route
            element={
              <LinkProject
                onLeavingEditor={exitHandler}
                type={type}
                baseUrl={baseUrl}
              />
            }
            path="link-project"
          />
          <Route
            element={
              <AddTeams
                onLeavingEditor={exitHandler}
                type={type}
                baseUrl={baseUrl}
              />
            }
            path="add-team"
          />
          <Route
            element={
              <Streams
                onLeavingEditor={exitHandler}
                type={type}
                baseUrl={baseUrl}
              />
            }
            path="streams"
          />
        </Routes>
      </div>
      {editPopupOpen && (
        <ModalPopup
          open={false}
          onClose={() => setEditPopupOpen(false)}
          hasCloseIcon>
          <p className="text-center mt-5 w-75 mx-auto">
            You have some unsaved changes. Do you want to save and update your
            project or leave?
          </p>
          <div className="draft-buttons mx-auto d-flex flex-sm-row flex-column justify-content-evenly align-items-center my-5">
            <CustomButton
              label="Leave"
              onClick={leaveWithoutSave}
              color="themeTertiary"
              variant="outline"
              customPadding="16px 30px"
            />
            <CustomButton
              loading={context.busy.status}
              label="Update"
              onClick={saveAndLeave}
              variant="outline"
              customPadding="16px 30px"
            />
          </div>
        </ModalPopup>
      )}
    </div>
  );
};
