import Icon from '../../utlils/Icons/Icon';
import cn from 'classname';
import { ButtonContainerWrapper } from './buttonStyle';

export const Button = ({
  label,
  outlined,
  leftIcon,
  rightIcon,
  textButton,
  backgroundColor = '#0abd00',
  iconName = 'arrow-up',
  color = '#ffffff',
  size = 'lg',
  onClick,
  isImage = false,
  imgSrc,
  boxShadow = '',
  hoverBackground = '',
  hoverColor = '',
  iconClass = '',
  disabled = false,
  loading = false,
  buttonStyle,
  labelStyle,
  className,
}) => {
  const s = size === 'lg' ? 20 : 14;
  return (
    <ButtonContainerWrapper
      backgroundColor={backgroundColor}
      color={color}
      boxShadow={boxShadow}
      hoverBg={hoverBackground}
      hoverColor={hoverColor}>
      {textButton ? (
        <button
          className={cn('textButton primary-button', className)}
          onClick={onClick}
          disabled={disabled || loading}
          style={buttonStyle}>
          {loading ? (
            <>
              <Icon color={cn('#ffffff', color)} name={'loader'} size={22} />
              <span>Please wait</span>
            </>
          ) : (
            <>
              {leftIcon && (
                <Icon
                  color={cn('#ffffff', color)}
                  name={iconName}
                  size={s}
                  className={iconClass}
                />
              )}
              {isImage && <img src={imgSrc} className="leftImg" alt="" />}
              <span style={labelStyle}>{label}</span>
              {rightIcon && (
                <Icon
                  color={cn('#ffffff', color)}
                  name={iconName}
                  size={s}
                  className={iconClass}
                />
              )}
            </>
          )}
        </button>
      ) : (
        <button
          className={cn(
            'custom-button primary-button lg',
            outlined ? 'outlined-button' : null,
            size,
            className
          )}
          disabled={disabled || loading}
          onClick={onClick}
          style={buttonStyle}>
          {loading ? (
            <>
              <Icon color={cn('#ffffff', color)} name={'loader'} size={22} />
              <span>Please wait</span>
            </>
          ) : (
            <>
              {leftIcon && (
                <Icon
                  color={cn('#ffffff', color)}
                  name={iconName}
                  size={s}
                  className={iconClass}
                />
              )}
              {isImage && <img src={imgSrc} className="leftImg" alt="" />}
              <span style={labelStyle}>{label}</span>
              {rightIcon && (
                <Icon
                  color={cn('#ffffff', color)}
                  name={iconName}
                  size={s}
                  className={iconClass}
                />
              )}
            </>
          )}
        </button>
      )}
    </ButtonContainerWrapper>
  );
};
