export const parseJson = (questions) => {
  try {
    return JSON.parse(questions);
  } catch (error) {
    return null;
  }
};

export const replaceUnderscoresWithSpaces = (str) => {
  let newStr = str.replace(/_/g, ' ');
  newStr = newStr.replace(/\w\S*/g, function (txt) {
    return txt?.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

  return newStr;
};
