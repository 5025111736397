import React from 'react';
import { EnterprisePublicationsWrapper } from './style';

export const EnterprisePublicPublications = () => {
  return (
    <EnterprisePublicationsWrapper>
      <div className="sub-container">
        <div className="main-wrapper">
          <div className="heading">Publications</div>
        </div>
      </div>
    </EnterprisePublicationsWrapper>
  );
};
