import { useState, useEffect, useCallback, useContext } from 'react';
import { toast } from 'react-toastify';
import Icon from 'utlils/Icons/Icon';
import { ViewDetails } from './ViewDetails';
import { ViewLinkedProject } from './ViewLinkedProject';
import { ViewLinkedTeams } from './ViewLinkedTeams';
import { ViewStreams } from './ViewStreams';
import {
  Box,
  CopyWithIcon,
  CustomButton,
  CustomCheckBox,
  CustomTooltip,
  Loader,
  ModalPopup,
  ScrollableDiv,
  TabGroup,
  Textarea,
} from 'components';
import RadioGroup from 'pages/IndividualDashboard/components/radioGroup';
import PublishImg from 'assests/images/publish-rocket.png';
import { isMobile } from 'utlils/isMobile';
import JiraImg from 'assests/images/jira-img.png';
import SlackImg from 'assests/images/slack-img.png';
import { PROJECT_VIEW_TAB_LIST } from 'pages/IndividualDashboard/individualOrganizations/enterprise/helper';
import {
  useNavigate,
  useParams,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';
import { SlidingButton } from 'components';
import { ViewProjectWrapper } from './style';
import {
  FETCH_PROJECT_STATUS,
  PUBLISH_PROJECT,
  FETCH_PROJECT_SETTINGS,
  useAxiosWrapper,
  UPDATE_PROJECT_SETTINGS,
  UPDATE_PROJECT_MANAGETASK_LIST,
  GET_RBAC_FOR_PROJECT,
} from 'services';
import { TYPES } from 'pages/IndividualDashboard/constants';
import tokenService from 'services/token.service';
import { ManageBoard } from 'pages/TaskManagement/components';
import { ProjectCollaboration } from './ProjectCollaboration';
import { ChangeProjectAdmin } from './ChangeProjectAdmin';
import { setProjectRBAC } from 'context/RoleBasedAccess/roleBasedAccessAction';
import { RoleBasedAccessContext } from 'context/RoleBasedAccess/roleBasedAccessContext';
import { IsPermitted, userScopes } from 'utlils';
import ProtectedRoute from 'components/protectedRoute';

const DEFAULT_SETTINGS_STATE = {
  isActivated: false,
  isPublic: false,
  isAuthorized: false,
  isJiraConnected: false,
  isSlackConnected: false,
};

const tooltipData = [
  {
    label: 'Private Project',
    text: 'Other Users are not allowed to view your project',
  },
  {
    label: 'Public Project',
    text: 'Other Users are allowed to view your project',
  },
];

const FAQ = [
  {
    id: 1,
    ques: 'If you have used any technologies, tools, or software for this project, please mention them',
    answer: '',
  },
  {
    id: 2,
    ques: 'When working on this project, what challenges did you face?',
    answer: '',
  },
  {
    id: 3,
    ques: 'What solutions did you come up with to overcome those challenges?',
    answer: '',
  },
];

const radioLabels = [
  { text: 'Yes', value: '1' },
  { text: 'No', value: '0' },
];

export const ViewProject = ({ type = '', baseUrl = '' }) => {
  const [fnWrapper, context] = useAxiosWrapper();
  const { roleBasedAccessState, roleBasedAccessDispatch } = useContext(
    RoleBasedAccessContext
  );
  const [loading, setLoading] = useState(true);
  const [projectName, setProjectName] = useState('Default Project');
  const [radioType, setRadioType] = useState('0');
  const [activeTab, setActiveTab] = useState(PROJECT_VIEW_TAB_LIST[0]);
  const [isPublished, setIsPublished] = useState(false);
  const [isProjectPublished, setIsProjectPublished] = useState(false);
  const [projectSettingsId, setProjectSettingsId] = useState('');
  const [faqs, setFaqs] = useState(FAQ);
  const [isAccess, setIsAccess] = useState(false);
  const [projectType, setProjectType] = useState({
    isBidding: {
      bool: false,
      value: 'BIDDING',
    },
    isCollaboration: {
      bool: false,
      value: 'COLLABORATION',
    },
  });
  const [projectDisplayType, setProjectDisplayType] = useState('');
  const [deptId, setDeptId] = useState(null);
  const [isPublishedSuccess, setIsPublishedSuccess] = useState(false);
  const [isSettingOpen, setIsSettingOpen] = useState(false);
  const [slidingButtonContent, setSlidingButtonContent] = useState(
    DEFAULT_SETTINGS_STATE
  );
  const { userType, projectId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [isShowTaskField, setShowTaskField] = useState(false);
  const [projectSettings, setProjectSettings] = useState(null);
  const [showChangeAdmin, setShowChangeAdmin] = useState(false);
  const { id: userId, role } = tokenService.getSession();

  const projectPermissions = roleBasedAccessState.projectRBAC;

  const bckBtnHandler = () => {
    navigate(`${baseUrl}`, { replace: true });
  };

  const tabSwitchHandler = (value) => {
    setActiveTab(value);
    let tab = value.toLowerCase().replace(' ', '-').replace('/', '-');
    navigate(`${baseUrl}/${projectId}/view/${tab}`, {
      replace: true,
    });
  };

  const handlePublishClick = async () => {
    const faqData = faqs.reduce(
      (acc, cur) => ({ ...acc, [cur.ques]: cur.answer }),
      {}
    );

    let data = {
      projectId: projectId,
      isBidding: projectType.isBidding.bool,
      isCollaboration: projectType.isCollaboration.bool,
      accessibility: radioType === '1',
      FAQ: faqData,
    };

    const res = await fnWrapper({
      url: PUBLISH_PROJECT,
      method: 'POST',
      payload: data,
      type: 'PUBLISH_PROJECT',
    });

    if (res.data.statusCode === 200) {
      setIsPublished(false);
      setIsPublishedSuccess(true);
    }
  };

  const getProjectSettings = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_PROJECT_SETTINGS}/${projectSettingsId}`,
      method: 'GET',
      type: 'FETCH_PROJECT_SETTINGS',
    });

    if (res.data.statusCode === 200) {
      const result = res.data.data;
      setSlidingButtonContent({
        isActivated:
          result?.isProjectActive !== null ? result?.isProjectActive : false,
        isPublic:
          result?.accessibility !== null ? result?.accessibility : false,
        isAuthorized:
          result?.postAuthorization !== null
            ? result?.postAuthorization
            : false,
        isJiraConnected: false,
        isSlackConnected: false,
      });
      setProjectType({
        isBidding: {
          ...projectType.isBidding,
          bool: result?.isBidding !== null ? result?.isBidding : false,
        },
        isCollaboration: {
          ...projectType.isCollaboration,
          bool:
            result?.isCollaboration !== null ? result?.isCollaboration : false,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectSettingsId]);

  const updateSettingsHandler = async () => {
    const res = await fnWrapper({
      url: `${UPDATE_PROJECT_SETTINGS}/${projectSettingsId}`,
      method: 'PUT',
      payload: {
        projectId: projectId,
        accessibility: slidingButtonContent.isPublic,
        isProjectActive: slidingButtonContent.isActivated,
        postAuthorization: slidingButtonContent.isAuthorized,
        isBidding: projectType.isBidding.bool,
        isCollaboration: projectType.isCollaboration.bool,
      },
      type: 'UPDATE_PROJECT_SETTINGS',
    });

    if (res.data.statusCode === 200) {
      setIsSettingOpen(false);
      getProjectStatus();
    }
  };

  const getProjectStatus = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_PROJECT_STATUS}/${projectId}`,
      method: 'GET',
      type: 'FETCH_PROJECT_STATUS',
    });

    if (res.data.statusCode === 200) {
      const result = res.data?.data;
      if (result?.department !== null) {
        setDeptId(result?.department?.id);
      }
      setProjectDisplayType(result?.projectType);
      setProjectName(result?.title);
      setIsProjectPublished(result?.projectStatus === 'PUBLISHED');
      setProjectSettingsId(result?.projectSettings?.id);
      setProjectSettings(result?.projectSettings);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProjectRoleBasedAccess = useCallback(async () => {
    const projectRbacRes = await fnWrapper({
      url: `${GET_RBAC_FOR_PROJECT}`,
      method: 'GET',
      type: 'GET_RBAC_FOR_PROJECT',
      params: {
        projectId,
        userId: userId,
      },
    });
    if (projectRbacRes.data.statusCode === 200) {
      await setProjectRBAC(roleBasedAccessDispatch, projectRbacRes.data.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const handleRadioButtons = (e) => {
    const { value } = e.target;
    setRadioType(value);
    if (value === '1') {
      setIsAccess(true);
    } else if (value === '0') {
      setIsAccess(false);
    }
  };

  const projectTypeCheckHandler = (field = '') => {
    let temp = { ...projectType };
    temp[field].bool = !temp[field].bool;
    setProjectType(temp);
  };

  const slidingButtonContentHandler = (field = '') => {
    let temp = { ...slidingButtonContent };
    temp[field] = !temp[field];
    setSlidingButtonContent(temp);
  };

  const handleFaq = (event, faqId) => {
    let temp = faqs.map((faq) => {
      if (faq.id === faqId) {
        return {
          ...faq,
          answer: event.target.value,
        };
      } else {
        return { ...faq };
      }
    });
    setFaqs(temp);
  };

  const handleEditBtnClick = () => {
    let navigateTo;
    if (type === TYPES.TEAM_DASHBOARD) {
      navigateTo =
        userType === 'enterprise'
          ? `/enterprise/departments/view/${deptId}/projects/${projectId}/edit/details`
          : projectDisplayType === TYPES.ENTERPRISE
          ? `/individual/profile/organization/enterprise/department/${deptId}/projects/${projectId}/edit/details`
          : `/individual/profile/personal/projects/${projectId}/edit/details`;
    } else {
      navigateTo = `${baseUrl}/${projectId}/edit/details`;
    }
    navigate(navigateTo);
  };

  useEffect(() => {
    let pathItems = location.pathname.split('/');
    PROJECT_VIEW_TAB_LIST.forEach((tab) => {
      if (
        tab.toLowerCase().replace(' ', '-').replace('/', '-') ===
          pathItems[pathItems.length - 1] ||
        pathItems.includes(
          tab.toLowerCase().replace(' ', '-').replace('/', '-')
        )
      ) {
        setActiveTab(tab);
      }
    });
  }, [location.pathname]);

  useEffect(() => {
    setLoading(true);
    getProjectRoleBasedAccess();
    getProjectStatus();
    setLoading(false);
  }, [getProjectRoleBasedAccess, getProjectStatus]);

  const updateProjectManageTaskList = async (fields) => {
    const payload = {
      projectId: projectId,
      status: fields,
    };
    const res = await fnWrapper({
      url: `${UPDATE_PROJECT_MANAGETASK_LIST}`,
      method: 'PUT',
      type: `UPDATE_PROJECT_MANAGETASK_LIST`,
      payload: payload,
    });
    if (res.data.statusCode === 200) {
      toast.success('Updated task status successfully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  const componentsArray = [
    {
      pathName: 'details',
      permissions: [userScopes.ACCESS_PROJECT_DETAILS],
      components: <ViewDetails />,
    },
    {
      pathName: 'linked-teams',
      permissions: [userScopes.ACCESS_PROJECT_LINKED_TEAMS],
      components: <ViewLinkedTeams />,
    },
    {
      pathName: 'linked-projects',
      permissions: [userScopes.ACCESS_PROJECT_LINKED_PROJECTS],
      components: <ViewLinkedProject />,
    },
    {
      pathName: 'streams',
      permissions: [userScopes.ACCESS_PROJECT_STREAM],
      components: <ViewStreams isPublished={isProjectPublished} />,
    },
    {
      pathName: 'collab-bids',
      permissions: [userScopes.ACCESS_PROJECT_COLLABORATION_BIDDING],
      components: (
        <ProjectCollaboration
          baseUrl={baseUrl}
          projectSettings={projectSettings}
          isProjectPublished={isProjectPublished}
        />
      ),
    },
  ];

  const renderComponents = () => {
    return componentsArray.map((item) => (
      <Route
        element={
          <ProtectedRoute
            allowedPermissions={item.permissions}
            key={item.pathName}>
            <IsPermitted permissions={item.permissions}>
              {item.components}
            </IsPermitted>
          </ProtectedRoute>
        }
        path={`${item.pathName}/*`}
        key={item.pathName}
      />
    ));
  };

  return loading ? (
    <Loader loading={loading} />
  ) : (
    <ViewProjectWrapper>
      <div className="view-project-container">
        {type === TYPES.TEAM_DASHBOARD && (
          <div className="team-dashboard-heading">Projects</div>
        )}
        <div className="view-project-heading">
          <div className="heading-left">
            <span className="heading-back-btn" onClick={bckBtnHandler}>
              <Icon color="#292D32" name="arrow-back" size={30} leftIcon />
            </span>
            <p className="heading-text">{projectName}</p>
          </div>
          {showChangeAdmin ? (
            <ChangeProjectAdmin
              projectId={projectId}
              onClose={() => setShowChangeAdmin(false)}
            />
          ) : null}
          <div className="right-buttons">
            {projectPermissions.includes(userScopes.PROJECT_MANAGE_BOARD) && (
              <div className="small-button">
                <CustomButton
                  variant="light"
                  color="themeGray"
                  customPadding="12px"
                  loading={context.busy.status}
                  className="w-100"
                  onClick={() => setShowTaskField(!isShowTaskField)}>
                  <Icon name="manageTask" color="#CCD2DD" />
                </CustomButton>
              </div>
            )}
            {projectPermissions.includes(userScopes.EDIT_PROJECT) && (
              <div className="small-button">
                <CustomButton
                  className="w-100"
                  variant="light"
                  color="themeGray"
                  customPadding="12px"
                  loading={context.busy.status}
                  onClick={handleEditBtnClick}>
                  <Icon name="edit" color="#CCD2DD" />
                </CustomButton>
              </div>
            )}
            {projectPermissions.includes(
              userScopes.ACCESS_PROJECT_SETTINGS
            ) && (
              <div className="small-button">
                <CustomButton
                  className="w-100"
                  variant="light"
                  color="themeGray"
                  loading={context.busy.status}
                  disabled={!isProjectPublished}
                  customPadding="12px"
                  onClick={() => {
                    getProjectSettings();
                    setIsSettingOpen(!isSettingOpen);
                  }}>
                  <Icon
                    disabled={!isProjectPublished}
                    name="setting"
                    color={isSettingOpen ? '#219FFF' : '#CCD2DD'}
                  />
                </CustomButton>
              </div>
            )}
            {isProjectPublished && (
              <CopyWithIcon
                id={projectId}
                textToCopy={`${process.env.REACT_APP_BASE_URL}/public/project/${projectId}/details`}
              />
            )}
            {projectPermissions.includes(userScopes.PUBLISH_PROJECT) && (
              <CustomButton
                variant="light"
                loading={context.busy.status}
                disabled={isProjectPublished}
                customPadding="16px 18px"
                onClick={() => setIsPublished(true)}>
                {isProjectPublished ? 'Published' : 'Publish'}
                {!isProjectPublished && (
                  <Icon name="arrow-up-two" color="#0ABD00" size={20} />
                )}
              </CustomButton>
            )}
            {role !== 'INDIVIDUAL' ? (
              <CustomButton
                variant="light"
                loading={context.busy.status}
                customPadding="16px 18px"
                onClick={() => setShowChangeAdmin(true)}>
                Change admin
                <Icon name="invite-user" color="#0ABD00" size={20} />
              </CustomButton>
            ) : null}
          </div>
          {isShowTaskField &&
            projectPermissions.includes(userScopes.PROJECT_MANAGE_BOARD) && (
              <ModalPopup
                open={false}
                onClose={() => setShowTaskField(false)}
                minWidth="650px"
                maxContentWidth="650px"
                hasTitle
                title="Manage Board"
                popupTitleClass={'manage-board--title'}>
                <ManageBoard
                  isUpdating={
                    context.busy.status &&
                    context.busy.type === 'UPDATE_PROJECT_MANAGETASK_LIST'
                  }
                  onSaveFields={updateProjectManageTaskList}
                  projectId={projectId}
                  type="PROJECT"
                />
              </ModalPopup>
            )}
        </div>

        {/* tabs */}
        <div className="my-5">
          <TabGroup
            tabLists={PROJECT_VIEW_TAB_LIST}
            tabClick={tabSwitchHandler}
            activeTab={activeTab}
          />
        </div>

        <Routes>{renderComponents()}</Routes>
      </div>

      {/* setting popup */}
      {isSettingOpen && (
        <ModalPopup
          open={false}
          onClose={setIsSettingOpen}
          minWidth="650px"
          maxContentWidth="650px"
          hasTitle
          title="Project Settings">
          <div className="publish-popup">
            <ScrollableDiv scrollOnHeight="500px" scrollOnHeightMob="350px">
              <div className="setting-popup-content">
                <p className="setting-text">Deactivate/Activate your project</p>
                <div className="setting-toggle-btn">
                  <div
                    className="btn-text-style"
                    style={{
                      color: `${
                        slidingButtonContent.isActivated ? '#2B9D1D' : '#7e8f9a'
                      }`,
                    }}>
                    {slidingButtonContent.isActivated
                      ? 'Activated'
                      : 'De-Activated'}
                  </div>

                  <SlidingButton
                    isSliding={slidingButtonContent.isActivated}
                    onClick={() => slidingButtonContentHandler('isActivated')}
                  />
                </div>
              </div>
              <div className="setting-popup-content">
                <p className="setting-text">
                  Private/Public project
                  <CustomTooltip onHoverColor={'#219fff'} iconSize={18}>
                    {tooltipData.map((item) => {
                      return (
                        <div className="tooltip-card-item">
                          <div className="tooltip-card-item-heading">
                            {item.label}
                          </div>
                          <p className="tooltip-card-item-text">{item.text}</p>
                        </div>
                      );
                    })}
                  </CustomTooltip>
                </p>
                <div className="setting-toggle-btn">
                  <div
                    className="btn-text-style"
                    style={{
                      color: `${
                        slidingButtonContent.isPublic ? '#2B9D1D' : '#7e8f9a'
                      }`,
                    }}>
                    {slidingButtonContent.isPublic ? 'Public' : 'Private'}
                  </div>
                  <SlidingButton
                    isSliding={slidingButtonContent.isPublic}
                    onClick={() => slidingButtonContentHandler('isPublic')}
                  />
                </div>
              </div>
              <div className="public-options">
                <div className="public-options-item">
                  <div
                    className={
                      slidingButtonContent.isPublic
                        ? 'public-options-item-show'
                        : 'public-options-item-disabled'
                    }>
                    Open for Collaboration
                  </div>
                  <CustomCheckBox
                    onCheckBg="rgba(33, 159, 255, 0.1)"
                    check={projectType.isCollaboration.bool}
                    tickColor="#219FFF"
                    onClick={() => projectTypeCheckHandler('isCollaboration')}
                    checkBorder="1px solid #219FFF"
                    disabled={!slidingButtonContent.isPublic}
                  />
                </div>
                <div className="public-options-item">
                  <div
                    className={
                      slidingButtonContent.isPublic
                        ? 'public-options-item-show'
                        : 'public-options-item-disabled'
                    }>
                    Open for Bidding
                  </div>
                  <CustomCheckBox
                    onCheckBg="rgba(33, 159, 255, 0.1)"
                    tickColor="#219FFF"
                    checkBorder="1px solid #219FFF"
                    check={projectType.isBidding.bool}
                    onClick={() => projectTypeCheckHandler('isBidding')}
                    disabled={!slidingButtonContent.isPublic}
                  />
                </div>
              </div>
              <div className="setting-popup-content">
                <p className="setting-text">
                  Are Team Members authorized to Post Updates in Stream
                </p>
                <div className="setting-toggle-btn">
                  <div
                    className="btn-text-style"
                    style={{
                      color: `${
                        slidingButtonContent.isAuthorized
                          ? '#2B9D1D'
                          : '#7e8f9a'
                      }`,
                    }}>
                    {slidingButtonContent.isAuthorized
                      ? 'Authorized'
                      : 'Un-Authorized'}
                  </div>
                  <SlidingButton
                    isSliding={slidingButtonContent.isAuthorized}
                    onClick={() => slidingButtonContentHandler('isAuthorized')}
                  />
                </div>
              </div>

              <p className="sync-text-style">Sync Accounts -</p>
              <div className="setting-popup-content">
                <div>
                  <img src={JiraImg} alt="" style={{ marginRight: '10px' }} />
                  Jira
                </div>

                <div className="setting-toggle-btn">
                  {slidingButtonContent.isJiraConnected ? (
                    <CustomButton
                      variant="textBtn"
                      customPadding="0 0"
                      onClick={() =>
                        slidingButtonContentHandler('isJiraConnected')
                      }>
                      Synced
                      <Icon name="sync" size={18} color="#2B9D1D" />
                    </CustomButton>
                  ) : (
                    <CustomButton
                      variant="textBtn"
                      color="themeSecondary"
                      customPadding="0 0"
                      onClick={() =>
                        slidingButtonContentHandler('isJiraConnected')
                      }
                      className="text-decoration-underline">
                      <Icon
                        name="connect-account-arrow"
                        size={18}
                        color="#219FFF"
                      />
                      Connect Account
                    </CustomButton>
                  )}
                </div>
              </div>
              <div className="setting-popup-content">
                <div>
                  <img src={SlackImg} alt="" style={{ marginRight: '10px' }} />
                  Slack
                </div>
                <div className="setting-toggle-btn">
                  {slidingButtonContent.isSlackConnected ? (
                    <CustomButton
                      variant="textBtn"
                      customPadding="0 0"
                      onClick={() =>
                        slidingButtonContentHandler('isSlackConnected')
                      }>
                      Synced
                      <Icon name="sync" size={18} color="#2B9D1D" />
                    </CustomButton>
                  ) : (
                    <CustomButton
                      variant="textBtn"
                      color="themeSecondary"
                      customPadding="0 0"
                      onClick={() =>
                        slidingButtonContentHandler('isSlackConnected')
                      }
                      className="text-decoration-underline">
                      <Icon
                        name="connect-account-arrow"
                        size={18}
                        color="#219FFF"
                      />
                      Connect Account
                    </CustomButton>
                  )}
                </div>
              </div>
            </ScrollableDiv>
            <div className="settings-btn">
              <CustomButton
                label={'Save'}
                color="themeSecondary"
                customPadding="16px 30px"
                onClick={updateSettingsHandler}
              />
            </div>
          </div>
        </ModalPopup>
      )}

      {isPublished && (
        <ModalPopup
          onClose={() => setIsPublished(false)}
          open={false}
          maxContentWidth="650px"
          minWidth="650px"
          hasTitle
          title="Publish Project">
          <div className="publish-popup">
            <ScrollableDiv scrollOnHeight="500px" scrollOnHeightMob="500px">
              <p className="publish-sub-heading">
                Hey! looks like you’re trying to take your project Live. Before
                doing that, it would be great if you can answer to these
                questions...
              </p>
              {faqs.map((faq, index) => {
                return (
                  <div className="publish-textarea" key={index}>
                    <Textarea
                      rows={4}
                      label={`${index + 1}. ${faq.ques}`}
                      value={faq.answer}
                      onChange={(e) => handleFaq(e, faq.id)}
                    />
                  </div>
                );
              })}
              <div className="publish-checkbox">
                <p>
                  4. Can other users access your project?{' '}
                  <span style={{ color: 'red' }}>*</span>
                </p>
                <div>
                  <RadioGroup
                    isBoldLabel={false}
                    currChecked={radioType}
                    labels={radioLabels}
                    handleChange={handleRadioButtons}
                  />
                </div>
              </div>

              {isAccess && (
                <div className="publish-checkbox">
                  <p>
                    5. How do you want your project to be displayed?{' '}
                    <span style={{ color: 'red' }}>*</span>
                  </p>
                  <Box mt="10px" mb="40px" className="row gy-2">
                    <div className="col-lg-5">
                      <Box display="flex" alignItems="center" colGap="7px">
                        <CustomCheckBox
                          onCheckBg="rgba(33, 159, 255, 0.1)"
                          check={projectType.isCollaboration.bool}
                          tickColor="#219FFF"
                          onClick={() =>
                            projectTypeCheckHandler('isCollaboration')
                          }
                          checkBorder="1px solid #219FFF"
                        />
                        <div>
                          <span style={{ fontWeight: '500' }}>
                            Open for Collaboration
                          </span>
                        </div>
                      </Box>
                    </div>
                    <div className="col-lg-5">
                      <Box display="flex" alignItems="center" colGap="7px">
                        <CustomCheckBox
                          onCheckBg="rgba(33, 159, 255, 0.1)"
                          tickColor="#219FFF"
                          checkBorder="1px solid #219FFF"
                          check={projectType.isBidding.bool}
                          onClick={() => projectTypeCheckHandler('isBidding')}
                        />
                        <div>
                          <span style={{ fontWeight: '500' }}>
                            Open for Bidding
                          </span>
                        </div>
                      </Box>
                    </div>
                  </Box>
                </div>
              )}
            </ScrollableDiv>
            <div className="popup-button">
              <CustomButton
                label={'Publish'}
                loading={context.busy.status}
                color="themeSecondary"
                onClick={handlePublishClick}
                className="w-100"
              />
            </div>
          </div>
        </ModalPopup>
      )}
      {isPublishedSuccess && (
        <ModalPopup
          open={false}
          onClose={() => {
            setIsPublishedSuccess(false);
            getProjectStatus();
          }}
          maxContentWidth="650px"
          minWidth="650px"
          hasTitle
          title="Publish Project">
          <div className="publish-popup">
            <div className="popup-success-img">
              <img
                src={PublishImg}
                alt="publish-img"
                width={isMobile() ? '300px' : ''}
              />
            </div>
            <div className="popup-bottom-text">
              <p className="m-0">
                Hurray! your Project is Live now. You can check it out here.
              </p>
              <a
                href={`${process.env.REACT_APP_BASE_URL}/public/project/${projectId}/details`}
                target="_blank"
                rel="noreferrer">
                {`${process.env.REACT_APP_BASE_URL}/public/project/${projectId}/details`}
              </a>
              <div>
                <CopyWithIcon
                  id={projectId}
                  label={'Copy project link'}
                  textToCopy={`${process.env.REACT_APP_BASE_URL}/public/project/${projectId}/details`}
                />
              </div>
            </div>
          </div>
        </ModalPopup>
      )}
    </ViewProjectWrapper>
  );
};
