import { CustomButton, ModalPopup, ScrollableDiv } from 'components';
import { INFO_TEXT } from 'pages/Settings/constants';
import MemberInfoBox from 'pages/enterpriseDashboard/people/Components/memberInfoBox';
import MemberInfoCard from 'pages/enterpriseDashboard/people/Components/memberInfoCard';
import React from 'react';

export const AdminDetailsPopup = (props) => {
  const {
    isDeactivatingOrDeleting,
    deactivateAccountState,
    onDeactivateDeleteHandler,
    teamAdminList,
    projectAdminList,
    onClose,
  } = props;

  const isConfirmationPart = deactivateAccountState.step === 'CONFIRMATION';
  return (
    <ModalPopup
      hasTitle
      open={false}
      minWidth={!isConfirmationPart ? `800px` : '600px'}
      maxContentWidth={!isConfirmationPart ? `800px` : '600px'}
      title={!isConfirmationPart ? `Oops!` : `Deactivate Account`}
      onClose={onClose}>
      <div className="popup-layout">
        {deactivateAccountState.step === 'CONFIRMATION' && (
          <>
            <div className="popup-confirmation-message mt-4 mb-3">
              Are you sure you want to Deactivate your account?
            </div>
            <div className="popup-buttons mb-3">
              <CustomButton
                label="No"
                variant="textBtn"
                customPadding="14px 20px"
                className="w-100"
                onClick={onClose}
              />
              <CustomButton
                label="Yes"
                customPadding="14px 20px"
                className="w-100"
                loading={isDeactivatingOrDeleting}
                onClick={async () =>
                  await onDeactivateDeleteHandler('deactivate')
                }
              />
            </div>
          </>
        )}
        {deactivateAccountState.step === 'ADMIN_LIST_FOUND' && (
          <ScrollableDiv
            scrollOnHeight={!isConfirmationPart ? `calc(100vh - 210px)` : '0px'}
            scrollOnHeightMob={!isConfirmationPart ? `300px` : '0px'}>
            <MemberInfoBox
              text={INFO_TEXT.MEMBER_INFO_DEACTIVATE}
              iconName={'info-circle'}
              type={'WARNING'}
            />
            <div className="teams-section">
              <p className="section-title">Teams ({teamAdminList?.length})</p>
              <div className="team-info-cards">
                {teamAdminList?.map((team, index) => (
                  <MemberInfoCard
                    iconName="group-user"
                    key={index}
                    name={team?.teamName}
                    role={team.role}
                  />
                ))}
              </div>
            </div>
            <div className="projects-section">
              <p className="section-title">
                Projects ({projectAdminList?.length})
              </p>
              <div className="project-info-cards">
                {projectAdminList?.map((project, index) => (
                  <MemberInfoCard
                    iconName="project"
                    key={index}
                    name={project?.title}
                    role={project?.projectRole}
                    designation={project?.teams?.map((team, index) =>
                      +index === project?.teams?.length - 1
                        ? team?.teamName
                        : team?.teamName + ', '
                    )}
                  />
                ))}
              </div>
            </div>
          </ScrollableDiv>
        )}
      </div>
    </ModalPopup>
  );
};
