import { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { NavbarBrand1, Sidebar } from 'components';
import { MIHINT_ADMIN_DASHBOARD_NAV_LIST, leftSidebarData } from './constants';
import { WaitList } from './WaitList';
import { MiHintAdminWrapper } from './styles';

export const MiHintAdmin = ({ currentLocale, handleLocaleChange }) => {
  const USER_DETAILS = {
    name: 'ADMIN',
    role: 'MIHINT_SUPERADMIN',
  };
  const [leftActiveItem, setLeftActiveItem] = useState('');

  const setLeftSidebar = (type) => {
    setLeftActiveItem(type);
  };

  return (
    <>
      <MiHintAdminWrapper>
        <NavbarBrand1
          currentLocale={currentLocale}
          handleLocaleChange={handleLocaleChange}
          navMenu={[MIHINT_ADMIN_DASHBOARD_NAV_LIST(USER_DETAILS?.role)]}
          userProfile={USER_DETAILS}
          hasNotificationBar={false}
        />
        <div className="individual-dashboard-container">
          <div className="layout-container">
            <div className="layout-sub-container">
              <div className="sub-container-child">
                <div style={{ marginTop: '1.5rem' }}>
                  <Sidebar
                    sidebarData={leftSidebarData}
                    activeType={leftActiveItem}
                    setActiveType={setLeftSidebar}
                  />
                </div>
              </div>
              <div className="sub-container-child">
                <Routes>
                  <Route path="">
                    <Route element={<WaitList />} path="/waitlist" />
                  </Route>
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </MiHintAdminWrapper>
    </>
  );
};
