import { Loader } from 'components';
import { useState, useCallback, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAxiosWrapper, FETCH_ACHIEVEMENT_DETAILS } from 'services';
import styled from 'styled-components';
import Icon from 'utlils/Icons/Icon';
import Edit from '../../../../assests/images/edit.png';
import { SummaryContentCard } from '../../Components/SummaryContentCard';

const ViewAchievementsWrapper = styled.div`
  padding-left: 15px;
  .heading-class {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin: 60px 0 50px 0;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 34px;
    color: #17191d;

    & .icon-class {
      margin-right: 10px;
    }

    & .edit-icon-class {
      cursor: pointer;
    }
  }

  & .no-data {
    width: 75%;
    text-align: center;
    margin: 2% auto;
    font-weight: 500;
    color: #929daf;
  }

  & .achievement-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;

    @media (max-width: 899px) {
      display: block;

      & .achievement-card-class {
        margin-top: 15px;
      }
    }
  }
`;

export const ViewAchievements = ({ taskType }) => {
  const { userId } = useParams();
  const [fnWrapper, context] = useAxiosWrapper();
  const [achievementData, setAchievementData] = useState([]);
  const navigate = useNavigate();

  const getAchievementDetails = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_ACHIEVEMENT_DETAILS}/${userId}`,
      method: 'GET',
      type: 'FETCH_ACHIEVEMENT_DETAILS',
    });

    if (res.data.statusCode === 200) {
      const result = res.data.data;
      const achievementDetails = [];
      result.map((item) => {
        const details = {
          title: item.title,
          issuedBy: item.issuedBy,
          issuedDate: item.issuedDate,
          description: item.description,
        };
        achievementDetails.push(details);
        return achievementDetails;
      });
      setAchievementData(achievementDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAchievementDetails();
  }, [getAchievementDetails]);

  return (
    <ViewAchievementsWrapper>
      <div className="heading-class">
        <div>
          <Icon name="star" color="#17191d" size={26} className="icon-class" />
          <span>Achievements</span>
        </div>
        {taskType === 'Private' && (
          <div
            className="edit-icon-class"
            onClick={() =>
              navigate(`/individual/profile/edit/achievements`, {
                state: {
                  from: 'public_page',
                  redirectTo: `/public/individual/${userId}/summary/achievements`,
                },
              })
            }>
            <img src={Edit} alt="" className="edit-icon-class" />
          </div>
        )}
      </div>
      {context.busy.status ? (
        <Loader isLoading={context.busy.status} />
      ) : achievementData.length === 0 ? (
        <div className="no-data">No data Available!</div>
      ) : (
        <div className="achievement-list">
          {achievementData.map((item, index) => (
            <div className="achievement-card-class" key={index}>
              <SummaryContentCard
                title={item.title}
                subTitle={item.issuedBy}
                startDate={item.issuedDate}
                description={item.description}
                iconName={'star-filled'}
                summaryType="achievement"
              />
            </div>
          ))}
        </div>
      )}
    </ViewAchievementsWrapper>
  );
};
