import React from 'react';
import { RequestCardWrapper } from './styles';
import { CustomButton } from 'components';
import { Link, useNavigate } from 'react-router-dom';

export const RequestCard = (props) => {
  const {
    type = 'recieved',
    requestStatus = 'pending',
    userName = 'user',
    userId,
    onClick = () => {},
  } = props;
  const navigate = useNavigate();

  const textColorCondition = {
    pending: 'themeGray',
    accepted: 'themePrimary',
    rejected: 'themeSecondary',
  };

  const requestStatusText = {
    pending: 'Pending',
    accepted: 'Accepted',
    rejected: 'Rejected',
  };
  return (
    <RequestCardWrapper>
      <div>
        <Link
          to={`/public/individual/${userId}/summary/basic-details`}
          target="_blank"
          className="user-name">
          {userName}
        </Link>
        {type === 'recieved'
          ? ' has requested to join your group'
          : ` has been invited to join your group`}
      </div>
      <div className="buttons-wrapper">
        {type === 'recieved' ? (
          <>
            <CustomButton
              onClick={onClick}
              label="View"
              variant="outline"
              color="themeSecondary"
            />
          </>
        ) : (
          <CustomButton
            onClick={onClick}
            className="request-btn"
            variant="textBtn"
            color={
              textColorCondition[requestStatus]
                ? textColorCondition[requestStatus]
                : 'themeGray'
            }
            label={
              requestStatusText[requestStatus]
                ? requestStatusText[requestStatus]
                : 'Unknown'
            }
          />
        )}
      </div>
    </RequestCardWrapper>
  );
};
