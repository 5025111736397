import styled from 'styled-components';

export const BiddingDetailsWrapper = styled.div`
  & .section {
    margin-bottom: 24px;
  }
  & .text-content {
    margin: 12px 0;
    font-size: 16px;
    font-family: Poppins;
  }
  & .table-heading {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #404f67;
    .table-element {
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border-radius: 6px;
      padding: 16px;
      text-align: center;
      background: #219fff33;
      color: #219fff;
    }
  }
  & .tc-container {
    margin: 12px 0;
    display: flex;
  }
  & .sub-heading-tc {
    margin-left: 12px;
    font-size: 14px;
  }
  & .section-date {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #404f67;
    .date-text {
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border-radius: 12px;
      padding: 16px;
      text-align: center;
      background: #219fff33;
      color: #219fff;
    }
  }
`;
