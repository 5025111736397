import { capitalize } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import Icon from '../../utlils/Icons/Icon';
import { ClickOutside } from '../ClickOutside/index';

let StyledSelect = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 16px;
  color: ${(p) => p.color};
  cursor: pointer;
`;

let OptionWrapper = styled.div`
  position: absolute !important;
  background: #ffffff;
  border: 1px solid #f5f5f5;
  box-shadow: 0 3px 15px rgba(22, 22, 22, 0.05);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  top: 34px;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
  z-index: 3;
  display: ${(p) => (p.display === 'true' ? 'block' : 'none')};
`;
let StyledOption = styled.div`
  height: 30px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 12.4941px;
  line-height: 20px;
  color: #404f67;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 15px;
  &:hover {
    background-color: #0abd00;
    color: #ffffff;
  }
  &:active {
    background-color: #0abd00;
    color: #ffffff;
  }
`;

let StyledSpan = styled.span`
  cursor: pointer;
  display: flex;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 25px;
  justify-content: flex-end;
  span {
    border-color: ${(p) => `${p.color} transparent transparent`};
    border-style: solid;
    border-width: 5px 5px 2.5px;
    display: inline-block;
    height: 0;
    width: 0;
    position: relative;
    box-sizing: border-box;
  }
`;

export const LanguageSelect = ({
  option_items = [],
  value = {},
  onChange,
  extra_actions,
  color,
}) => {
  let [is_open, set_open] = React.useState(false);
  return (
    <ClickOutside active={is_open} onClickOutside={() => set_open(false)}>
      <div style={{ height: 'auto', position: 'relative' }}>
        <StyledSelect
          color={color}
          onClick={() => {
            set_open(!is_open);
          }}>
          <Icon
            size="28px"
            style={{ paddingTop: 2 }}
            color={color}
            name="langSelection"
          />
          {capitalize(value)}
          <StyledSpan color={color}>
            <span />
          </StyledSpan>
        </StyledSelect>

        <OptionWrapper display={`${is_open}`}>
          {option_items.map((item) => (
            <StyledOption
              key={item.title}
              onClick={(e) => {
                e.preventDefault();
                onChange(item.title);
                set_open(false);
              }}>
              {capitalize(item.title)}
            </StyledOption>
          ))}

          {extra_actions}
        </OptionWrapper>
      </div>
    </ClickOutside>
  );
};
