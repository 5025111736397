import { useState, useCallback, useEffect } from 'react';
import { Loader } from 'components';
import { useParams } from 'react-router-dom';
import { MemberCard } from '../Components';
import {
  FETCH_TEAM_MEMBERS,
  CREATE_USER_CONNECTION,
  useAxiosWrapper,
  FETCH_ALL_MY_CONNECTED_USERS,
} from 'services';
import TokenService from 'services/token.service';

export const TeamPublicMembers = ({ userRole }) => {
  const [fnWrapper, context] = useAxiosWrapper();
  const [members, setMembers] = useState([]);

  const { teamId } = useParams();
  const isIndividualUserRole = userRole === 'INDIVIDUAL';

  const connectBtnHandler = (userId) => {
    if (userId) createUserConnection('CONNECT', userId);
  };

  const connectionButtonStatus = (status) => {
    if (status === null || status === '3' || status === '5') {
      return {
        label: 'Connect',
        bgColor: '#fff',
        color: '#219fff',
        hoverBackground: '#219fff',
        hoverColor: '#ffffff',
        iconName: 'profile-add',
        disabled: false,
      };
    } else if (status === '1') {
      return {
        label: 'Pending',
        bgColor: '#fff',
        color: '#FE9320',
        iconName: 'profile-add',
        disabled: true,
      };
    } else if (status === '4') {
      return {
        label: 'Connected',
        bgColor: '#fff',
        color: '#0ABD00',
        iconName: 'profile-add',
        disabled: true,
      };
    }
  };

  const getMyConnectionList = async () => {
    let myConnectionList = [];
    const loggedInUser = TokenService.getSession();
    const res = await fnWrapper({
      url: `${FETCH_ALL_MY_CONNECTED_USERS}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_GET_ALL_PUBLIC_USERS',
      payload: {
        hasPaginated: false,
      },
    });
    if (res.data.statusCode === 200) {
      myConnectionList = res?.data?.data?.items;
    }
    return myConnectionList;
  };

  const getMembersList = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_TEAM_MEMBERS}/${teamId}`,
      method: 'GET',
      type: 'FETCH_TEAM_MEMBERS_LIST',
    });

    if (res.data.statusCode === 200) {
      const result = res.data.data;
      const loggedInUser = TokenService.getSession();
      const membersList = [];
      const myConnectionList = await getMyConnectionList();

      result[0]?.teamMembers?.map(async (member) => {
        if (member?.status === '4') {
          const profileVisibility =
            member?.memberUser?.individualSetting?.profileVisibility;
          const isPrivateUser =
            profileVisibility === 'NO_BODY'
              ? true
              : profileVisibility === 'ALL'
              ? false
              : myConnectionList.filter(
                  (i) => i?.connectedUsers?.id === member?.memberUserId
                ).length === 0;
          const item = {
            id: member?.memberUserId,
            name: member?.memberName,
            designation: member?.designation,
            mutualConnections: member?.mutualConnection?.length,
            profileImgSrc: member?.memberUser?.individual?.profilePicture,
            coverImgSrc: member?.memberUser?.individual?.coverProfilePicture,
            isIndividual:
              member?.memberUser?.smallEnterprise === null &&
              member?.memberUser?.individual !== null,
            connectionStatus: member?.connectionStatus,
            connectButton:
              loggedInUser.id !== member?.memberUserId
                ? connectionButtonStatus(member?.connectionStatus)
                : null,
            isPrivateUser,
          };
          membersList.push(item);
        }
      });
      setMembers(membersList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createUserConnection = async (connectionType, userId) => {
    if (userId && userId !== '') {
      const connectWith = {
        connectWithId: userId,
        type: connectionType,
      };

      const res = await fnWrapper({
        url: `${CREATE_USER_CONNECTION}`,
        method: 'POST',
        type: 'CREATE_USER_CONNECTION',
        payload: connectWith,
      });
      if (res.data.statusCode === 200) {
        getMembersList();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    getMembersList();
  }, [getMembersList]);

  return (
    <div className="main-wrapper">
      <div className="heading">
        Members<span>({members.length})</span>
      </div>
      <div className="content">
        {context.busy.status ? (
          <Loader loading={context.busy.status} />
        ) : (
          <div className="people-cards-container">
            {members &&
              members.length > 0 &&
              members.map((member, index) => {
                return (
                  <MemberCard
                    key={index}
                    member={member}
                    connectHandler={() => connectBtnHandler(member.id)}
                    isIndividualUserRole={isIndividualUserRole}
                  />
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};
