/* eslint-disable no-unused-vars */
import styled from 'styled-components';
import { any, func, number, string, object } from 'prop-types';
import { useState } from 'react';
import Icon from 'utlils/Icons/Icon';
import { memo } from 'react';
//
// Component Styles
// ----------------------------------------------------------------------

const AsDiv = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  &.copy_clipboard {
    display: inline-flex;
    align-items: center;
  }

  .copy_clipboard__icon {
    margin-left: ${({ margin }) => margin.left};
    margin-bottom: ${({ margin }) => margin.bottom};
    margin-top: ${({ margin }) => margin.top};
    margin-right: ${({ margin }) => margin.right};
    cursor: pointer;
    &.active {
      path {
        fill: green;
      }
    }
  }
`;
const propTypes = {
  className: string,
  // can pass a id to track clipboard
  clipboardId: string.isRequired,
  copyHintText: string,
  height: string,
  maxLength: number,
  margin: object,
  onCopyClick: func,
  placement: string,
  textToCopy: string.isRequired,
  // string or jsx
  textToShow: any,
  title: string,
  width: string,
  icon: object,
};

// Using memo as CopyText to avoid unnecessary renders caused by sidebar
export const CopyText = memo((props) => {
  const [{ clipboardId }, set_clipboardId] = useState({ clipboardId: '' });
  const {
    className = '',
    copyHintText = 'Click to copy',
    height = '12',
    clipboardId: clipboardID = '',
    onCopyClick,
    placement = 'top',
    textToCopy,
    textToShow = '',
    title = '',
    maxLength = 20,
    width = '14',
    margin = { left: '5px', bottom: '4px', top: '0', right: '0' },
    icon = { name: 'copy', color: '#bdbdbd' },
  } = props;

  const copyValue = async (e, textToCopy) => {
    if (e) {
      // stop default click propagation
      e.preventDefault();
      e.stopPropagation();
    }

    const is_clipboard_api_supported = navigator.clipboard;
    if (is_clipboard_api_supported) {
      await is_clipboard_api_supported.writeText(textToCopy);
    } else {
      // only for older browsers ~1%!!
      // creat a input & copy the text and remove it
      let inp = document.createElement('INPUT');
      inp.value = textToCopy;
      document.body.appendChild(inp);
      inp.select();
      document.execCommand('copy');
      document.body.removeChild(inp);
    }
  };
  const handleCopyText = async (e) => {
    await copyValue(e, textToCopy);
    // to highlight which clipboard is active
    set_clipboardId({ clipboardId: clipboardID });

    typeof onCopyClick === 'function' && onCopyClick(textToCopy);
  };
  // once icon is out of focus reset the state (only if it was clicked)
  const resetClipboard = () =>
    clipboardId && set_clipboardId({ clipboardId: '' });

  const is_active_clipboard = clipboardID === clipboardId;
  const isValidString = textToShow && typeof textToShow === 'string';

  return (
    <AsDiv
      className={`copy_clipboard ${className}`}
      id={clipboardID}
      title={title || ''}
      margin={margin}>
      {isValidString ? (
        textToShow.length > maxLength ? (
          <span title={textToShow}>
            {textToShow.substring(0, maxLength)}...
          </span>
        ) : (
          <span>{textToShow}</span>
        )
      ) : (
        textToShow || ''
      )}
      <Icon
        height={height}
        width={width}
        className={`copy_clipboard__icon ${
          className ? className + '__icon' : ''
        } ${(is_active_clipboard && 'active') || ''}`}
        name={icon.name}
        color={icon.color}
        hoverColor="#2d3c5c"
        role="button"
        onClick={handleCopyText}
        onMouseOut={resetClipboard}
        title={textToCopy || ''}
      />
    </AsDiv>
  );
});

CopyText.propTypes = propTypes;
