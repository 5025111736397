//Journey1 Images
import FeatureJourney1_1 from '../../../assests/images/homepage/featurepage/journey-1/journey-image-1.png';
import FeatureJourney1_2 from '../../../assests/images/homepage/featurepage/journey-1/journey-image-2.png';
import FeatureJourney1_3 from '../../../assests/images/homepage/featurepage/journey-1/journey-image-3.png';
import FeatureJourney1_4 from '../../../assests/images/homepage/featurepage/journey-1/journey-image-4.png';
import FeatureJourney1_5 from '../../../assests/images/homepage/featurepage/journey-1/journey-image-5.png';
//Journey2 Images
import FeatureJourney2_1 from '../../../assests/images/homepage/featurepage/journey-2/journey-2-image-1.png';
import FeatureJourney2_2 from '../../../assests/images/homepage/featurepage/journey-2/journey-2-image-2.png';
import FeatureJourney2_3 from '../../../assests/images/homepage/featurepage/journey-2/journey-2-image-3.png';
//Journey3 Images
import FeatureJourney3_1 from '../../../assests/images/homepage/featurepage/journey-3/journey-3-image-1.png';
import FeatureJourney3_2 from '../../../assests/images/homepage/featurepage/journey-3/journey-3-image-2.png';
import FeatureJourney3_3 from '../../../assests/images/homepage/featurepage/journey-3/journey-3-image-3.png';
import FeatureJourney3_4 from '../../../assests/images/homepage/featurepage/journey-3/journey-3-image-4.png';
//Journey4 Images
import FeatureJourney4_1 from '../../../assests/images/homepage/featurepage/journey-4/journey-4-image-1.png';
import FeatureJourney4_2 from '../../../assests/images/homepage/featurepage/journey-4/journey-4-image-2.png';
import FeatureJourney4_3 from '../../../assests/images/homepage/featurepage/journey-4/journey-4-image-3.png';
//Journey5 Images
import FeatureJourney5_1 from '../../../assests/images/homepage/featurepage/journey-5/journey-5-image-1.png';
import FeatureJourney5_2 from '../../../assests/images/homepage/featurepage/journey-5/journey-5-image-2.png';
import FeatureJourney5_3 from '../../../assests/images/homepage/featurepage/journey-5/journey-5-image-3.png';
import FeatureJourney5_4 from '../../../assests/images/homepage/featurepage/journey-5/journey-5-image-4.png';
import FeatureJourney5_5 from '../../../assests/images/homepage/featurepage/journey-5/journey-5-image-5.png';
//Journey6 Images
import FeatureJourney6_2 from '../../../assests/images/homepage/featurepage/journey-6/journey-6-image-2.png';
import FeatureJourney6_3 from '../../../assests/images/homepage/featurepage/journey-6/journey-6-image-3.png';
//Header image
import TeamWorkImage from '../../../assests/images/homepage/featurepage/header-images/team-work.png';
import BarChartImage from '../../../assests/images/homepage/featurepage/header-images/bar-chart.png';
import DeskWorkImage from '../../../assests/images/homepage/featurepage/header-images/desk-work.png';
//Extras
import DoubleQuotes from '../../../assests/images/homepage/featurepage/extra/double-quotes.png';

export const FEATURE_PAGE_DATA = {
  heroContent: {
    heading: (
      <div className="content_header_heading">
        <span style={{ color: '#FFFFFF' }}>Unleash the Possibilities</span> with
        our <span style={{ color: '#FFFFFF' }}>Feature-Packed World.</span>
      </div>
    ),
    subHeading: 'Explore a world of cutting edge features',
    primaryButton: {
      title: '',
      icon: 'arrow-forward',
      action: '',
    },
  },
  subFooter: {
    heading: 'And Many other features are waiting for you...',
    buttonText: 'Get Started',
    action: '/signup/selectOrganization',
  },
  journeyContent: {
    header_icon: 'equal',
    heading: 'Work on big ideas together with your team',
    sub_heading: 'Develop and evolve in cycles',
    message_box: `From the moment your product is a concept to the moment it becomes a reality, we'll help you plan your flow, track your development status, and make a whole load of choices to achieve what you've always envisioned.`,
    capsules: [
      {
        id: 'section-1',
        imagePath: FeatureJourney1_1,
        title: 'Stages: Defining and Mapping the Development Stages',
        content: `Define what your product will be. 
        Establish a blueprint of the stages involved in the development of your product.`,
      },
      {
        id: 'section-2',
        imagePath: FeatureJourney1_2,
        title: 'Streams: Track Every Detail for Seamless Progress',
        content: `Our simple yet effective record-keeping system allows you to keep accurate and absolute track of all behind-the-scenes details for each stage.`,
      },
      {
        id: 'section-3',
        imagePath: FeatureJourney1_3,
        title:
          'Merge & Evolve: Meeting Your Requirements and Advancing Your Vision',
        content: `Combine with projects that meet your requirements and further advance them as you please.
        Experience first-of-a-kind feature where you can bid for a project, then customize it as per your preferences.
        Collaborate with different projects in any stage of its development.
        Evolve your current project using our top-of-the-line features.`,
      },
      {
        id: 'section-4',
        imagePath: FeatureJourney1_4,
        title:
          'Team-Up: Harnessing the Power of Teams for Simultaneous Project Development',
        content: `Collaborate with other teams of similar interests for developing and working simultaneously on the project.
        Create teams of your own to multiply your efforts.`,
      },
      {
        id: 'section-5',
        imagePath: FeatureJourney1_5,
        title:
          'Task Management: Customized Scrum Boards for Continuous Updates',
        content: `Create tasks and manage your task in a customized scrum board.
        Share updates on the stream and create additional tasks based on your updates. 
        An innovative way to maintain track of every task assignment, update, and inclusion in the project using our streams feature.`,
      },
    ],
  },
  journeyContent2: {
    header_icon: 'bezier',
    heading: 'Manage and customize every detail',
    sub_heading: 'Making task management more human',
    message_box: `What product or project without proper management? But how to manage? Well, let us help you provide with the most eloquent and leading work management techniques to help you manage your project and team better.We serve management strategy for Teams, Project-Users, and even Enterprises!`,
    image_box: {
      imagePath: TeamWorkImage,
      title: 'Team’s: Collaborate, Innovate, Succeed.',
      content: `With our best-in-class management techniques, you can easily manage your team. From assigning them suitable roles to assigning their exclusive tasks, we’ve made all that cumbersome parts look seamless for you.`,
      extraImage: DoubleQuotes,
    },
    capsules: [
      {
        id: 'section-1',
        imagePath: FeatureJourney2_1,
        title: 'Manage the team’s goal:',
        content: `Prepare a proper plan for meeting the end-product.
        Describe the overall task for the team in stages and map out a master-plan for achieving that using task management and streams in a best way`,
      },
      {
        id: 'section-2',
        imagePath: FeatureJourney2_2,
        title: 'Manage roles for members:',
        content: `Specify roles for different members of the team.
        Work out the best role for each individual team member ranging from the Admin, Co-Admin, to the fellow associates.`,
      },
      {
        id: 'section-3',
        imagePath: FeatureJourney2_3,
        title: 'Designate Tasks:',
        content: `Allocate proper tasks to the various team members.
        From developer to tester, map-out each role.
        Designate tasks keeping in mind the skills of each member.`,
      },
    ],
  },
  journeyContent3: {
    header_icon: null,
    heading: null,
    sub_heading: null,
    image_box: {
      imagePath: BarChartImage,
      title: 'Empowering Enterprises for Endless Growth',
      content: `Manage your enterprise be it either big or small, as effectively as possible with us. From creating different departments to establishing their communication spectrum, we’ve got it all covered for you.`,
      extraImage: DoubleQuotes,
    },
    capsules: [
      {
        id: 'section-1',
        imagePath: FeatureJourney3_1,
        title: 'Upload and Manage users:',
        content: `Batch upload the users' list for your enterprise.
        Provide users with the necessary roles such as administrator, co-admin, and members.`,
      },
      {
        id: 'section-2',
        imagePath: FeatureJourney3_2,
        title: 'Departments:',
        content: `Incorporate specific departments into your enterprise and manage them all from one epicenter.
        Include the action territory for each department, ranging from the HR to the Publication departments.`,
      },
      {
        id: 'section-3',
        imagePath: FeatureJourney3_3,
        title: 'Communication:',
        content: `Establish communication channels for facilitating the flow of information from one department to another, and within itself.`,
      },
      {
        id: 'section-4',
        imagePath: FeatureJourney3_4,
        title: 'Hiring and quality of hiring process:',
        content: `Find people matching your project and team based on personality compatibility and experience.
        Employ part-time, full-time, or freelance employees.
        Be able to manage all kinds of employees from overseas in one simple management tool.
        Making use of the talent pool and shortlist feature allows HR and team members to set better hiring goals.
        Better solution for head hunts and finding talents directly from network to pool of candidates`,
      },
    ],
  },
  journeyContent4: {
    header_icon: null,
    heading: null,
    sub_heading: null,
    image_box: {
      imagePath: DeskWorkImage,
      title: 'Project User’s: Putting Users at the Heart of Innovation',
      content: `In the project, we have implemented various access levels, privacy levels, and collaboration levels to ensure efficient and secure collaboration among team members. It ensures that users have appropriate access rights, maintain data privacy and security, and can collaborate effectively based on their roles and requirements.`,
      extraImage: DoubleQuotes,
    },
    capsules: [
      {
        id: 'section-1',
        imagePath: FeatureJourney4_1,
        title: 'Access & Privacy:',
        content: `Assign and manage the teams working on the project with access levels that correspond to their roles.
        Analyze the privacy risks for various users and team members and control projects privacy level.`,
      },
      {
        id: 'section-2',
        imagePath: FeatureJourney4_2,
        title: 'Bidding and Collaboration process:',
        content: `Manage your enterprise rules for opening a project for collaboration and bidding by:
        Setting departments that can open a project for bid and collaboration.
        Oversee the bidding and collaboration process for every project.
        Manage legal documents being used in bid/col agreements.
        Be it Bidding requirements, jobs, or even collaborations, manage them all together at once through the agreements section.`,
      },
      {
        id: 'section-3',
        imagePath: FeatureJourney4_3,
        title: 'Publication:',
        content: `Lastly, lead your achievements by managing your project publication options and strategies.`,
      },
    ],
  },
  journeyContent5: {
    header_icon: 'command-btn',
    heading: 'Unlock Your Potential',
    sub_heading: 'Empowering Networking with Experts',
    message_box:
      'We’ve the best community of project developers and experts who can assist you with almost anything to everything. We provide you the gateway to connect with a network of people you could potentially collaborate with, partner with the finest teams, reach out for collaboration in projects that you want to be a part of, and much more.',
    image_box: null,
    capsules: [
      {
        id: 'section-1',
        imagePath: FeatureJourney5_1,
        title: 'Individual networking: Building Portfolio',
        content: `With us, building a pleasing portfolio is effortless. Using our expertise, we'll help you build your portfolio efficiently and effectively. Moreover, a step-to-step guide will be provided.
        Showcase and elaborate the various projects you have worked on and in what capacities.
        Mention the teams that you have worked and currently working with and what’s been your role in each of them.
        Your portfolio should highlight your accomplishments, inturn increasing your chances of leaving a positive impression.`,
      },
      {
        id: 'section-2',
        imagePath: FeatureJourney5_2,
        title: 'E-Records:',
        content: `No more traditional two-page resumes, and we invented the first ever digital resume that you can include:
        Include your optional performance metrics through your performance on miHint project management tool calculated by our AI model.
        Include optional personality metrics calculated by our AI model analysing your behavior confronting different task
        Customize and share E-records on all other career platforms.`,
      },
      {
        id: 'section-3',
        imagePath: FeatureJourney5_3,
        title: 'Team Networking:',
        content: `Explore other teams, collaborate with others, manage your team's portfolio and much more all under one roof.
        Get the ability to customize your team's public page.
        Showcase the projects your team has accomplished.
        Indicate how your team wants to collaborate with others- Open Jobs, Partnership model, etc.`,
      },
      {
        id: 'section-4',
        imagePath: FeatureJourney5_4,
        title: 'Project Networking:',
        content: `First, ever an environment to socialize based on projects. Make changes to your own projects and utilize our networking option for projects to connect to other projects on our platform.
        Ensure that your project details are tailored to the finest detail within your knowledge to enhance your chances of standing out.
        Certify the specific accessibility levels assigned to the various team members as well as users.
        Specify the collaboration style of your project like whether it’s just collaboration, or bidding system, or just open for new jobs.`,
      },
      {
        id: 'section-5',
        imagePath: FeatureJourney5_5,
        title: 'Enterprise Networking:',
        content: `Build your own enterprise page and evolve it on our platform.From hiring new people to creating new projects, do all in one place.
        Build and customize your enterprise portfolio using our customization tools.
        Expand your enterprise team by hiring talented employees, creating a competent team, and undertaking projects listed on our website
        List out various job openings for your enterprise. Hire quality people from our very own huge network.`,
      },
    ],
  },
  journeyContent6: {
    header_icon: 'arrange-circle',
    heading: 'Embrace New Possibilities',
    sub_heading: 'Trade for Products or Projects that you are interested in.',
    message_box: null,
    image_box: null,
    capsules: [
      {
        id: 'section-1',
        imagePath: FeatureJourney1_1,
        title: 'Create Bidding/Collab:',
        content: `Project owners can showcase their projects/products for bidding purposes through a seamless process:
        Set up the bidding application form with a set of explanations of rules, questions for bidders and upload needed documents
        You may prepare your own NDA (Non-Disclosure Agreement) and templates or Use our exclusive in-house miHint legal document templates`,
      },
      {
        id: 'section-2',
        imagePath: FeatureJourney6_2,
        title: 'Bidding system:',
        content: `Using our bidding system, you can bid on projects in a variety of ways:
        · Bid for a copy of project.
        · Bid for buying a project entirely.
          Bid for availing a periodic project service`,
      },
      {
        id: 'section-3',
        imagePath: FeatureJourney6_3,
        title: 'Possession:',
        content: `Upon acceptance of the potential Non-Disclosure Agreement, answering the questions, the application would be submitted.
        All the submitted applications would be visible to the project’s owner through the project dashboard and enterprise dashboard for enterprise projects
        After close examination, if any of the applications are approved, a 1-1 chat will be initiated and agreement will be made after with any of the approved cases
        Agreements and terms and conditions would be accessible through the agreement page`,
      },
    ],
  },
};
