import { Textarea } from 'components';
import ReactDatePicker from 'react-datepicker';

export const ProjectCopy = (props) => {
  const { onChangeHandler, setCreateBiddingState, state, isDisabled } = props;

  const onChangeFaq = (key, value) => {
    setCreateBiddingState((prevState) => ({
      ...prevState,
      biddingFaq: { ...prevState.biddingFaq, [key]: value },
    }));
  };
  return (
    <div>
      {Object.keys(state.biddingFaq).map((item, index) => {
        return (
          <div className="publish-checkbox" key={index}>
            <Textarea
              rows={4}
              label={item}
              value={state.biddingFaq[item]}
              required
              onChange={(e) => onChangeFaq(item, e.target.value)}
              disabled={isDisabled}
            />
          </div>
        );
      })}
      <div className="publish-checkbox mt-4">
        <div className="sub-heading mb-3">
          3. Updates will be included in this project up to the selected date
          <span style={{ color: 'red' }}>*</span>
        </div>
        <div className="pb-textarea">
          <ReactDatePicker
            dateFormat="MMM dd, yyyy"
            selectsStart
            placeholderText="DD/MM/YYYY"
            className="bidding-date"
            selected={state.projectLastUpdatedDate}
            onChange={(date) => onChangeHandler('projectLastUpdatedDate', date)}
            disabled={isDisabled}
          />
        </div>
      </div>
    </div>
  );
};
