import { useCallback, useEffect, useState, useRef } from 'react';
import dayjs from 'dayjs';
import Icon from 'utlils/Icons/Icon';
import {
  CustomButton,
  CustomDropDown,
  Loader,
  ModalPopup,
  MultiUserProfile,
  OptionMenuWithDot,
  ScrollableDiv,
} from 'components';
import {
  FETCH_DEPARTMENT_BOARD_FIELDS,
  FETCH_PROJECT_BOARD_FIELDS,
  FETCH_TASK_BY_ID,
  FETCH_TASK_COMMENTS,
  POST_TASK_COMMENTS,
  UPDATE_TASK_STATUS,
  UPDATE_TASK_DETAILS,
  useAxiosWrapper,
} from 'services';
import { ViewTaskWrapper } from './style.js';
import { StageBlock } from '../TaskManageCard/stageBlock.js';
import { PriorityBlock } from '../TaskManageCard/priorityBlock.js';
import { DueDate } from '../TaskManageCard/dueDate.js';
import { TaskComments } from 'pages/Projects/ViewProject/ViewStreams/Posts/PostTasks/taskComments.js';
import { getProfileText } from 'utlils/utils.js';
import tokenService from 'services/token.service.js';
import { checkForFileType } from 'pages/IndividualDashboard/individualOrganizations/enterprise/helper.js';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const optionMenu = [
  {
    id: 'EDIT_TASK',
    label: 'Edit',
  },
  {
    id: 'PUBLISH_TO_STREAM',
    label: 'Publish to stream',
  },
  {
    id: 'COPY_LINK',
    label: 'Copy link',
  },
];

export const ViewTaskPopup = (props) => {
  const {
    activeBoardFields,
    onCloseViewTaskModal,
    selectedTaskTypeValue,
    taskId,
    taskType,
    ticketNo,
    dashboardType,
    copyLinkHandler,
    setOpenTaskId,
  } = props;
  const navigate = useNavigate();
  const [fnWrapper, context] = useAxiosWrapper();
  const [taskInfo, setTaskInfo] = useState(null);
  const [allStatus, setAllStatus] = useState([]);
  const [isTaskFetching, setIsTaskFetching] = useState(true);
  const [commentText, setCommentText] = useState('');
  const [taskComments, setTaskComments] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [boardFields, setBoardFields] = useState(activeBoardFields);
  const [isTaskEditable, setIsTaskEditable] = useState(false);
  const [editableValues, setEditableValues] = useState({
    taskTitle: 'Title',
    taskDescription: 'Description',
  });
  const inputRefs = {
    taskTitle: useRef(null),
    taskDescription: useRef(null),
  };

  const getTaskCommentsList = useCallback(async () => {
    const payload = {
      taskId: taskId,
    };
    const res = await fnWrapper({
      url: `${FETCH_TASK_COMMENTS}`,
      method: 'GET',
      type: 'FETCH_TASK_COMMENTS',
      params: payload,
    });

    if (res.data.statusCode === 200) {
      const result = res.data.data;
      const commentsList = result?.map((comment) => {
        const userName =
          comment?.user?.individual === null
            ? comment?.user?.smallEnterprise?.enterpriseName
            : comment?.user?.individual?.name;
        const pfp =
          comment?.user?.individual === null
            ? comment?.user?.smallEnterprise?.profilePicture
            : comment?.user?.individual?.profilePicture;

        const mapComments = {
          id: comment.id,
          commenterPic: pfp,
          commenterText: getProfileText(userName),
          commenterName: `${userName}`,
          commentedAt: comment.createdAt,
          text: comment.commentMessage,
        };
        return mapComments;
      });
      setTaskComments(commentsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId]);

  const onPostTaskCommentHandler = async (comments) => {
    const loggedInUser = await tokenService.getSession();

    const payload = {
      taskId: taskId,
      commentMessage: comments,
      [taskInfo?.taskCategory === 'PROJECT_TASK'
        ? 'projectId'
        : 'departmentId']:
        taskInfo?.taskCategory === 'PROJECT_TASK'
          ? taskInfo?.projectId
          : taskInfo?.departmentId,
      taskType: taskInfo?.taskCategory,
      userId: loggedInUser.id,
    };

    const res = await fnWrapper({
      url: `${POST_TASK_COMMENTS}`,
      method: 'POST',
      type: 'POST_TASK_COMMENTS',
      payload: payload,
    });

    if (res.data.statusCode === 200) {
      await getTaskCommentsList();
      setCommentText('');
    }
  };

  const onOptionMenuClicked = async (item) => {
    if (item.id === 'COPY_LINK') {
      copyLinkHandler(taskId);
    }
    if (item.id === 'PUBLISH_TO_STREAM') {
      const text = `Changed the status to ${taskInfo?.status}`;
      await onPostTaskCommentHandler(text);
    }
    if (item.id === 'EDIT_TASK') {
      // enble edit functionality
      setIsTaskEditable(!isTaskEditable);
    }
  };

  const getStatusFields = useCallback(async (taskType) => {
    const taskStatusRes =
      taskType === 'PROJECT_TASK'
        ? await fnWrapper({
            url: `${FETCH_PROJECT_BOARD_FIELDS}/${selectedTaskTypeValue?.id}`,
            method: 'GET',
            type: 'FETCH_PROJECT_BOARD_FIELDS',
          })
        : await fnWrapper({
            url: `${FETCH_DEPARTMENT_BOARD_FIELDS}/${selectedTaskTypeValue?.id}`,
            method: 'GET',
            type: 'FETCH_DEPARTMENT_BOARD_FIELDS',
          });
    return taskStatusRes;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTaskById = useCallback(async () => {
    setIsTaskFetching(true);
    const payload = {
      taskId: taskId,
      taskType: taskType,
    };
    const taskStatusResponse = await getStatusFields(taskType);
    await getTaskCommentsList();
    if (taskStatusResponse?.data?.statusCode === 200) {
      const res = await fnWrapper({
        url: `${FETCH_TASK_BY_ID}`,
        method: 'GET',
        type: 'FETCH_TASK_BY_ID',
        params: payload,
      });

      if (res.data.statusCode === 200) {
        if (taskStatusResponse?.data?.data?.status?.length) {
          const selectedStatus = taskStatusResponse?.data?.data?.status.find(
            (status) => status.value === res?.data?.data?.status
          );
          setSelectedStatus(selectedStatus);
          setAllStatus(taskStatusResponse?.data?.data?.status);
        }

        setTaskInfo(res.data.data);
        setEditableValues({
          taskTitle: res?.data?.data?.taskTitle,
          taskDescription: res?.data?.data?.taskDescription,
        });
      }
    }
    setIsTaskFetching(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId, taskType]);

  const onUpdateTaskStatusHandler = async (status) => {
    const payload = {
      taskId: taskId,
      status: status,
    };

    const res = await fnWrapper({
      url: `${UPDATE_TASK_STATUS}`,
      method: 'PUT',
      type: 'UPDATE_TASK_STATUS',
      payload: payload,
    });

    if (res.data.statusCode === 200) {
      setBoardFields((prevState) => ({
        ...prevState,
        [taskInfo?.status]: prevState[taskInfo?.status].filter(
          (ele) => ele?.id !== taskId
        ),
        [status]: [...prevState[status], res.data.data],
      }));
    }
  };

  const onUpdateTaskDetails = async () => {
    const payload = {
      taskId: taskId,
      taskTitle: editableValues.taskTitle,
      taskDescription: editableValues.taskDescription,
    };
    const res = await fnWrapper({
      url: `${UPDATE_TASK_DETAILS}`,
      method: 'PATCH',
      type: 'UPDATE_TASK_DETAILS',
      payload: payload,
    });

    if (res.data.statusCode === 200) {
      setTaskInfo((prevTaskInfo) => ({
        ...prevTaskInfo,
        taskTitle: editableValues.taskTitle,
        taskDescription: editableValues.taskDescription,
      }));
      toast.success('Updated task status successfully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  const getFileName = (file) => {
    const setFileName = file.split('/');
    return setFileName[setFileName.length - 1];
  };

  // handle editable input values
  const handleEditableValueChange = (field, value) => {
    setEditableValues((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleOutsideClick = async () => {
    if (isTaskEditable) {
      await onUpdateTaskDetails();
      setIsTaskEditable(false);
    }
  };

  useEffect(() => {
    getTaskById();
  }, [getTaskById]);

  useEffect(() => {
    if (isTaskEditable) {
      inputRefs.taskTitle.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTaskEditable]);

  return (
    <ModalPopup
      onClose={() => {
        setOpenTaskId(null);
        onCloseViewTaskModal(boardFields);
      }}
      maxContentWidth="900px"
      title={
        <div className="d-flex align-items-center gap-2">
          <Icon color="black" name="ticket" size={20} />
          <span className="task-card-wrapper__row-3__ticket-no">
            {ticketNo}
          </span>
        </div>
      }>
      <ScrollableDiv scrollOnHeight="600px">
        <ViewTaskWrapper className="view-task">
          {isTaskFetching ? (
            <Loader isLoading={isTaskFetching} />
          ) : (
            <>
              {taskInfo ? (
                <div onClick={handleOutsideClick}>
                  <div className="view-task__top-card">
                    <div className="row mb-2">
                      <div className="col-lg-4 d-flex align-items-center justify-content-start">
                        <div className="view-task__top-card--label">
                          Assigned on-{' '}
                        </div>
                        <div className="view-task__top-card--value">
                          {dayjs(taskInfo?.createdAt).format('DD MMM YYYY')}
                        </div>
                      </div>
                      <div className="col-lg-4 d-flex align-items-center justify-content-start">
                        <div className="view-task__top-card--label">
                          Due Date-{' '}
                        </div>
                        <DueDate
                          className="view-task__top-card--value"
                          color={'#404f67'}
                          dueDate={taskInfo?.dueDate}
                        />
                      </div>
                      <div className="col-lg-4 d-flex align-items-center justify-content-start">
                        <div className="view-task__top-card--label">
                          Assigned by-{' '}
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <MultiUserProfile
                            bgColor={'#ffffff'}
                            curveColor={'#f4f7fc'}
                            usersInfo={[
                              taskInfo?.assignedTeamByUser?.individual
                                ? taskInfo?.assignedTeamByUser?.individual
                                : taskInfo?.assignedTeamByUser?.smallEnterprise,
                            ]}
                          />
                          <div className="view-task__top-card--value">
                            {taskInfo?.assignedTeamByUser?.individual
                              ? taskInfo?.assignedTeamByUser?.individual?.name
                              : taskInfo?.assignedTeamByUser?.smallEnterprise
                                  ?.enterpriseName}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-lg-4 d-flex align-items-center justify-content-start">
                        <div className="view-task__top-card--label">
                          Assigned to-{' '}
                        </div>
                        <MultiUserProfile
                          bgColor={'#ffffff'}
                          curveColor={'#f4f7fc'}
                          usersInfo={taskInfo?.assignToMemberDetails}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-2 mb-3">
                    <div className="d-flex align-items-center justify-content-between view-task__gap">
                      {taskInfo?.taskCategory === 'PROJECT_TASK' && (
                        <StageBlock
                          stage={taskInfo?.posts?.stages?.stageName}
                        />
                      )}
                      <PriorityBlock priority={taskInfo?.priority} />
                    </div>
                    <div className="d-flex align-items-center justify-content-between view-task__gap">
                      <CustomDropDown
                        optionLists={allStatus}
                        selectedItem={selectedStatus}
                        setSelectedItem={async (item) => {
                          await onUpdateTaskStatusHandler(item?.value);
                          setSelectedStatus(item);
                        }}
                        height="36px"
                        customStyle={{
                          backgroundColor: '#EBEBEB',
                          color: '#404F67',
                          padding: '10px 8px 10px 16px',
                          whiteSpace: 'normal',
                          width: '150px',
                        }}
                        width={'100%'}
                      />
                      <OptionMenuWithDot
                        arrowPosition={{
                          left: '50%',
                          right: '50%',
                          top: '-25px',
                        }}
                        onSelectMenu={onOptionMenuClicked}
                        optionMenu={optionMenu}
                        wrapperPosition={{ left: '-81px', top: '20px' }}
                      />
                    </div>
                  </div>
                  <div className="view-task__task-title">
                    {isTaskEditable ? (
                      <textarea
                        rows="3"
                        cols="30"
                        className="view-task__edit-input"
                        ref={inputRefs.taskTitle}
                        value={editableValues.taskTitle}
                        onChange={(e) =>
                          handleEditableValueChange('taskTitle', e.target.value)
                        }
                        onClick={(e) => e.stopPropagation()}
                      />
                    ) : (
                      taskInfo?.taskTitle
                    )}
                  </div>
                  <div className="view-task__task-description">
                    {isTaskEditable ? (
                      <textarea
                        rows="6"
                        cols="30"
                        className="view-task__edit-input"
                        ref={inputRefs.taskDescription}
                        value={editableValues.taskDescription}
                        onChange={(e) =>
                          handleEditableValueChange(
                            'taskDescription',
                            e.target.value
                          )
                        }
                        onClick={(e) => e.stopPropagation()}
                      />
                    ) : (
                      taskInfo?.taskDescription
                    )}
                  </div>
                  <ScrollableDiv
                    maxWidth="100%"
                    scrollOnHeight={'calc(100vh - 55vh)'}
                    scrollOnHeightMob="400px">
                    <div className="view-task__attachment">
                      {taskInfo?.attachments &&
                        taskInfo?.attachments?.length !== 0 && (
                          <div className="view-task__attachments">
                            {taskInfo?.attachments.map((elem, index) => {
                              const fileName = getFileName(elem);
                              return (
                                <div
                                  className="view-task__attachments__img-wrapper"
                                  key={index}>
                                  <div className="view-task__attachments__img-container">
                                    {checkForFileType(fileName) === 'others' ? (
                                      <Icon name="attachment" />
                                    ) : (
                                      <img
                                        src={checkForFileType(fileName)}
                                        alt="word"
                                      />
                                    )}
                                  </div>
                                  <a
                                    className="view-task__attachments__text"
                                    href={elem}
                                    target="_blank"
                                    rel="noreferrer">
                                    {fileName}
                                  </a>
                                </div>
                              );
                            })}
                          </div>
                        )}
                    </div>
                    {taskInfo?.taskCategory === 'PROJECT_TASK' && (
                      <div className="view-task__go-to-stream-btn">
                        <CustomButton
                          variant="textBtn"
                          customPadding="5px 5px"
                          onClick={() =>
                            navigate(
                              `/${dashboardType}/profile/personal/projects/${taskInfo?.projectId}/view/streams`
                            )
                          }>
                          Go to Stream
                          <Icon
                            name="arrow-forward"
                            color="#0ABD00"
                            size={18}
                          />
                        </CustomButton>
                      </div>
                    )}
                    <div className="mt-3 view-task__task-comment">
                      <TaskComments
                        commentText={commentText}
                        isLoading={
                          context.busy.status &&
                          context.busy.type === 'FETCH_TASK_COMMENTS'
                        }
                        onPostTaskComment={() =>
                          onPostTaskCommentHandler(commentText)
                        }
                        setCommentText={setCommentText}
                        taskComments={taskComments}
                      />
                    </div>
                  </ScrollableDiv>
                </div>
              ) : (
                <div>No task details found</div>
              )}
            </>
          )}
        </ViewTaskWrapper>
      </ScrollableDiv>
    </ModalPopup>
  );
};
