import { toast } from 'react-toastify';

export const NotificationToaster = ({ notification, onClose }) => {
  return toast(notification?.title, {
    onClose: onClose,
    onClick: onClose,
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    theme: 'light',
  });
};
