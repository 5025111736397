import styled from 'styled-components';

export const TeamProjectsListWrapper = styled.div`
  & .heading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    color: #17191d;
    & span {
      font-weight: 600;
      font-size: 23px;
      color: #404f67;
    }
  }

  & .header-buttons-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 40px 0px;
    gap: 30px;

    & .left-buttons {
      width: 100%;
    }
    & .right-buttons {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 10px;

      @media (max-width: 767px) {
        grid-template-columns: 1fr;
      }
    }

    @media (max-width: 767px) {
      flex-direction: column;
      gap: 10px;
    }
  }
  & .manage-arrow {
    margin-top: 4px;
  }
  & .filter-arrow {
    margin-left: 5px;
    margin-top: 6px;
  }
  .no-project {
    width: 75%;
    text-align: center;
    margin: 10% auto;
    font-weight: 500;
    color: #929daf;
  }
  @media (max-width: 992px) {
    padding: 15px;
  }
`;
