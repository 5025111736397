import { useCallback, useEffect, useState } from 'react';
import cx from 'classname';
import { FETCH_PROFESSIONAL_DETAILS, useAxiosWrapper } from 'services';
import { Box, Loader } from 'components';
import NoDataImg from '../../../../../assests/images/nodata.png';
import './profDetailsView.scss';
import TokenService from 'services/token.service';

const DEFAULTSTATE = [
  {
    itemId: '',
    companyName: '',
    role: '',
    location: '',
    from: '',
    to: '',
    description: '',
  },
];

export const ProfessionalDetailsView = () => {
  const [state, setState] = useState(DEFAULTSTATE);
  const [fnWrapper, context] = useAxiosWrapper();

  const getProfileDetails = useCallback(async () => {
    const loggedInUser = TokenService.getSession();
    const res = await fnWrapper({
      url: `${FETCH_PROFESSIONAL_DETAILS}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_ACHIEVEMENT_INFO',
    });
    if (res.data.statusCode === 200) {
      const response = res.data.data;
      let dataArray = [];
      response.forEach((ele) => {
        let data = {
          itemId: ele.id,
          companyName: ele.companyName,
          role: ele.role,
          description: ele.description,
          location: ele.location,
          isPersue: ele.to === null ? true : false,
          from: new Date(ele.from),
          to: ele.to === null ? new Date() : new Date(ele.to),
        };
        dataArray = [...dataArray, data];
      });
      setState(dataArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getProfileDetails();
  }, [getProfileDetails]);

  return (
    <>
      {context.busy.status ? (
        <Loader loading={context.busy.status} />
      ) : (
        <div className="row mb-2 profile-view-container">
          {state.length === 0 ? (
            <div className="empty-data-section">
              <img
                src={NoDataImg}
                alt="No Data"
                className="empty-data-section--img"
              />
              <div className="empty-data-section--text">No data available</div>
            </div>
          ) : (
            state.map((ele, index) => {
              const options = { year: 'numeric', month: 'short' };
              let from = new Date(ele.from).toLocaleString('default', options);
              let to = new Date(ele.to).toLocaleString('default', options);
              return (
                <div
                  key={index}
                  className={cx(
                    'content-wrapper',
                    state.length !== index + 1 ? 'border-below' : ''
                  )}>
                  <div className="row">
                    <div className="col-xl-6 col-xxl-4 col-6">
                      <Box mt="10px">
                        <p className="label">Company Name</p>
                        <p className="value">{ele.companyName}</p>
                      </Box>
                    </div>
                    <div className="col-xl-6 col-xxl-4 col-6">
                      <Box mt="10px">
                        <p className="label">Location</p>
                        <p className="value">
                          {ele.location ? ele.location : 'NA'}
                        </p>
                      </Box>
                    </div>
                    <div className="col-xl-6 col-xxl-4 col-6">
                      <Box mt="10px">
                        <p className="label">Role</p>
                        <p className="value">{ele.role}</p>
                      </Box>
                    </div>
                    <div className="col-xl-6 col-xxl-4 col-6">
                      <Box mt="10px">
                        <p className="label">From</p>
                        <p className="value">{from}</p>
                      </Box>
                    </div>
                    <div className="col-xl-6 col-xxl-4 col-6">
                      <Box mt="10px">
                        <p className="label">To</p>
                        {ele.isPersue ? (
                          <p className="value">Currently working here</p>
                        ) : (
                          <p className="value">{to}</p>
                        )}
                      </Box>
                    </div>

                    <div className="col-12">
                      <Box mt="10px">
                        <p className="label">Description</p>
                        <p className="value">
                          {ele.description ? ele.description : 'NA'}
                        </p>
                      </Box>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      )}
    </>
  );
};
