import ReactDatePicker from 'react-datepicker';
import { FilterPostedJobsWrapper } from './style';
import { CustomButton, DropDown, Input, CustomCheckBox } from 'components';
import { DEFAULT_JOB_FILTER_STATE } from '../../constant';

export const FilterPostedJobs = ({
  onApplyFilter,
  filterJob,
  setFilterJob,
  filterButtonDisable,
  projectList,
}) => {
  return (
    <FilterPostedJobsWrapper className="filtered_postedjobs">
      <div className="title">{'Filter posted jobs'}</div>
      <div className="underline" />
      <div className="filtered_postedjobs__wrapper">
        <Input
          label="Job Title"
          value={filterJob.search}
          onChange={(e) =>
            setFilterJob({
              ...filterJob,
              search: e.target.value,
            })
          }
        />
        <div className="row mb-3">
          <div className="col-lg-6 col-sm-12">
            <ReactDatePicker
              dateFormat="MMM dd, yyyy"
              selected={filterJob.startDate}
              onChange={(date) =>
                setFilterJob({
                  ...filterJob,
                  startDate: date,
                })
              }
              selectsStart
              placeholderText="From"
              className="custom-date"
            />
          </div>
          <div className="col-lg-6 col-sm-12">
            <ReactDatePicker
              dateFormat="MMM dd, yyyy"
              selected={filterJob.endDate}
              onChange={(date) =>
                setFilterJob({
                  ...filterJob,
                  endDate: date,
                })
              }
              selectsStart
              placeholderText="To"
              className="custom-date"
              startDate={filterJob.startDate}
              endDate={filterJob.endDate}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <DropDown
              label="Select Project"
              placeholder="Select Project"
              value={filterJob.project}
              items={projectList}
              onClick={(value) =>
                setFilterJob({
                  ...filterJob,
                  project: value,
                })
              }
            />
          </div>
          <div className="col-lg-6 col-sm-12 isHiring">
            <CustomCheckBox
              check={filterJob.isHiring === 'isHiring'}
              title="Is Hiring"
              onClick={() => {
                if (!filterJob.isHiring || filterJob.isHiring === 'notHiring') {
                  setFilterJob({
                    ...filterJob,
                    isHiring: 'isHiring',
                  });
                } else {
                  setFilterJob({
                    ...filterJob,
                    isHiring: 'notHiring',
                  });
                }
              }}
            />
          </div>
        </div>
        <div className="d-flex gap-3 align-items-center mt-3">
          <div className="w-50">
            <CustomButton
              label="Apply"
              color="themeSecondary"
              onClick={() => onApplyFilter('apply')}
              disabled={filterButtonDisable}
              className="w-100"
            />
          </div>
          <div className="w-50">
            <CustomButton
              label="Cancel"
              color="themeSecondary"
              variant="light"
              onClick={() => {
                setFilterJob(DEFAULT_JOB_FILTER_STATE);
                onApplyFilter('cancel');
              }}
              className="w-100"
            />
          </div>
        </div>
      </div>
    </FilterPostedJobsWrapper>
  );
};
