import React from 'react';
import { FileAttachmentPreviewWrapper } from './styles';
import { CustomButton } from 'components';
import Icon from 'utlils/Icons/Icon';
import { convertFileSizeToMb } from 'services/twilioService/helper';
import {
  MAX_DOCUMENT_SIZE,
  MAX_VIDEO_SIZE,
  MAX_IMAGE_SIZE,
} from 'pages/TwilioChat/constants';

export const FileAttachmentPreview = ({
  selectedFile,
  onDiscard,
  onSend,
  selectedFileType,
}) => {
  const max_size = {
    video: MAX_VIDEO_SIZE,
    image: MAX_IMAGE_SIZE,
    attachment: MAX_DOCUMENT_SIZE,
  };
  return (
    <FileAttachmentPreviewWrapper>
      <div className="filePreview__container">
        <div className="attachment-icon">
          <Icon name={selectedFileType} size={30} color="grey" />
        </div>
        <div className="attachment-details">
          <div className="attachment-title">{selectedFile?.name}</div>
          <div className="attachment-size">
            {convertFileSizeToMb(selectedFile?.size)} MB
          </div>
          <div className="attachment-max-size">{`File size cannot be more than ${max_size[selectedFileType]} MB`}</div>
        </div>
      </div>
      <div className="attachment-action-btns">
        <CustomButton
          label="Discard"
          color="themeTertiary"
          customPadding="10px 14px"
          onClick={onDiscard}
        />
        <CustomButton color="themeSecondary" onClick={onSend}>
          Send
          <Icon name="arrow-right" color="white" />
        </CustomButton>
      </div>
    </FileAttachmentPreviewWrapper>
  );
};
