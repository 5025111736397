import styled from 'styled-components';

export const ProjectSuccessWrapper = styled.div`
  .teams-created {
    width: 50%;
    @media (max-width: 992px) {
      width: 60%;
    }
    @media (max-width: 576px) {
      width: 80%;
    }
  }
  .teams-created-message {
    width: 70%;
    .teams-created-title {
      color: #404f67;
      font-weight: 600;
      font-size: 20px;
    }
    @media (max-width: 992px) {
      width: 80%;
    }
    @media (max-width: 576px) {
      width: 100%;
    }
  }
`;
