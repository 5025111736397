import { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  LINKED_PROJECTS_LIST_HEADERS,
  linkedTeamStatus,
} from 'pages/IndividualDashboard/individualOrganizations/enterprise/helper';
import { CustomTable, Loader, StatusButton } from 'components';
import { ViewLinkedProjectWrapper } from './style';
import { FETCH_PROJECT_SERVICES, useAxiosWrapper } from 'services';
import { TYPES } from 'pages/IndividualDashboard/constants';

export const ViewLinkedProject = () => {
  const [fnWrapper, context] = useAxiosWrapper();
  const [linkedProjectData, setLinkedProjectData] = useState([]);
  const { projectId } = useParams();

  const getLinkedProjectsList = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_PROJECT_SERVICES}/${projectId}?st=linkedProjects`,
      method: 'GET',
      type: 'FETCH_LINKED_PROJECTS',
    });

    if (res.data.statusCode === 200) {
      const result = res.data.data;
      const linkedData = [];
      if (result.data[0]) {
        const item = {
          projectName: result.data[0]?.title,
          paidCollabPublic: 'Default',
          connectedTo:
            result.data[0]?.projectType === TYPES.ENTERPRISE
              ? result.data[0]?.enterprise.enterpriseName
              : result.data[0]?.user?.individual?.name,
          status: <StatusButton status={linkedTeamStatus[3]} />,
        };
        linkedData.push(item);
      }
      setLinkedProjectData(linkedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    getLinkedProjectsList();
  }, [getLinkedProjectsList]);

  return (
    <ViewLinkedProjectWrapper>
      <div className="view-container">
        {/* table */}
        {context.busy.status ? (
          <Loader isLoading={context.busy.status} />
        ) : linkedProjectData && Object.keys(linkedProjectData).length !== 0 ? (
          <div className="table-style">
            <CustomTable
              isLoading={context.busy.status}
              rows={linkedProjectData}
              headers={LINKED_PROJECTS_LIST_HEADERS}
            />
          </div>
        ) : (
          <div className="no-linked-project">You didn't link any Project!</div>
        )}
      </div>
    </ViewLinkedProjectWrapper>
  );
};
