export const userScopes = {
  //team
  ACCESS_CAREER_PAGE: 'ACCESS_CAREER_PAGE',
  ACCESS_JOB_APPLICANTS: 'ACCESS_JOB_APPLICANTS',
  ACCESS_JOB_SHORTLISTED: 'ACCESS_JOB_SHORTLISTED',
  ACCESS_MEMBER_PAGE: 'ACCESS_MEMBER_PAGE',
  ACCESS_POSTED_JOB: 'ACCESS_POSTED_JOB',
  ACCESS_PROJECT_PAGE: 'ACCESS_PROJECT_PAGE',
  ACCESS_PUBLICATION_PAGE: 'ACCESS_PUBLICATION_PAGE',
  ACCESS_REQUEST_PAGE: 'ACCESS_REQUEST_PAGE',
  ACCESS_TASK_PAGE: 'ACCESS_TASK_PAGE',
  ADD_TEAM_MEMBERS: 'ADD_TEAM_MEMBERS',
  ASSIGN_TASK_TO_TEAM_MEMBER: 'ASSIGN_TASK_TO_TEAM_MEMBER',
  CREATE_JOB: 'CREATE_JOB',
  CREATE_TEAM: 'CREATE_TEAM',
  DELETE_TEAM: 'DELETE_TEAM',
  DUPLICATE_POSTED_JOB: 'DUPLICATE_POSTED_JOB',
  EDIT_POSTED_JOB: 'EDIT_POSTED_JOB',
  ONBOARD_JOB_APPLICANTS: 'ONBOARD_JOB_APPLICANTS',
  OPEN_CLOSE_POSTED_JOB: 'OPEN_CLOSE_POSTED_JOB',
  REMOVE_TEAM_MEMBERS: 'REMOVE_TEAM_MEMBERS',
  SHORTLIST_JOB_APPLICANTS: 'SHORTLIST_JOB_APPLICANTS',
  UPDATE_TEAM_DETAILS: 'UPDATE_TEAM_DETAILS',
  MARK_AS_CONTACTED_JOB_APPLICANTS: 'MARK_AS_CONTACTED_JOB_APPLICANTS',
  ACCESS_TEAM_SETTINGS: 'ACCESS_TEAM_SETTINGS',
  //project
  ACCESS_PROJECT_DETAILS: 'ACCESS_PROJECT_DETAILS',
  ACCESS_PROJECT_LINKED_TEAMS: 'ACCESS_PROJECT_LINKED_TEAMS',
  ACCESS_PROJECT_LINKED_PROJECTS: 'ACCESS_PROJECT_LINKED_PROJECTS',
  ACCESS_PROJECT_STREAM: 'ACCESS_PROJECT_STREAM',
  ACCESS_PROJECT_COLLABORATION_BIDDING: 'ACCESS_PROJECT_COLLABORATION_BIDDING',
  ACCESS_PROJECT_SETTINGS: 'ACCESS_PROJECT_SETTINGS',
  VIEW_DRAFT_PROJECT: 'VIEW_DRAFT_PROJECT',
  PROJECT_MANAGE_BOARD: 'PROJECT_MANAGE_BOARD',
  EDIT_PROJECT: 'EDIT_PROJECT',
  PUBLISH_PROJECT: 'PUBLISH_PROJECT',
  FINISH_STAGE: 'FINISH_STAGE',
  START_NEW_STAGE: 'START_NEW_STAGE',
  RE_OPEN_STAGE: 'RE_OPEN_STAGE',
  PROJECT_PUBLISH_TASK: 'PROJECT_PUBLISH_TASK',
  PROJECT_PUBLISH_PUBLICATION: 'PROJECT_PUBLISH_PUBLICATION',
  PROJECT_TASK_SHARE_ON_SLACK: 'PROJECT_TASK_SHARE_ON_SLACK',
  PROJECT_TASK_COPY_LINK: 'PROJECT_TASK_COPY_LINK',
};
