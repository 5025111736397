import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import sortBy from 'lodash/sortBy';
import EnterPriseList from '../components/enterpriseList/index';
import noDepartments from '../../../assests/images/noDepartments.png';
import CreateDepartment from './createDepartment';
import '../index.scss';
import uniqBy from 'lodash/uniqBy';
import { isMobile } from '../../../utlils/isMobile';
import {
  Box,
  CustomButton,
  Loader,
  ModalPopup,
  Pagination,
  SearchField,
} from 'components';
import { INVITEMEMBERROLE, DEPARTMENTSETTINGS } from '../helper';
import {
  FETCH_DEPARTMENT_LIST,
  FETCH_DEPARTMENT_TYPE,
  SEARCH_DEPARTMENT,
  CREATE_DEPARTMENT,
  UPDATE_DEPARTMENT,
} from 'services';
import { useAxiosWrapper } from 'services';
import { REMOVE_DEPARTMENT } from 'services';
import Icon from 'utlils/Icons/Icon';

const Departments = () => {
  let navigate = useNavigate();
  const DEFAULTSTATE = {
    departmentName: '',
    departmentType: {},
    departmentSettings: {
      canHire: false,
      canPublish: false,
    },
    departmentMembers: [
      {
        id: '',
        memberName: '',
        email: '',
        role: {},
        taskPermission: false,
        careerPermission: false,
        teamApprovalPermission: false,
        isActive: 1,
      },
    ],
  };

  const [fnWrapper, context] = useAxiosWrapper();
  const [activeEnterprise, setActiveEnterPrise] = useState(null);
  const [searchDepartment, setSearchDepartment] = useState('');
  const [isCreateDepartment, setIsCreateDepartment] = useState(false);
  const [departmentTypes, setDepartmentTypes] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  let pageSize = 10;
  const [departmentList, setDepartmentList] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [departmentMembersList, setDepartmentMembersList] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [toggleSort, setToggleSort] = useState(true);
  const [createEnterpriseState, setCreateEnterpriseState] =
    useState(DEFAULTSTATE);

  const activeEnterprisehandler = (enterpriseId) => {
    if (activeEnterprise !== enterpriseId) {
      setActiveEnterPrise(enterpriseId);
    } else {
      setActiveEnterPrise(null);
    }
  };
  const viewDepartmentHandler = (id) => {
    navigate(`/enterprise/departments/view/${id}/published-problems`, {
      replace: true,
    });
  };

  const editOrRemoveHandler = (evt, item) => {
    setActiveEnterPrise(null);
    switch (evt.id) {
      case 0:
        setIsEdit(true);
        displayDepartmentDetailsToEdit(item);
        setIsCreateDepartment(true);
        // code block
        break;
      case 1:
        removeDepartment(item.id);
        getDepartmentsList();
        break;
      default:
      // code block
    }
  };

  const displayDepartmentDetailsToEdit = async (item) => {
    const session = JSON.parse(localStorage.getItem('session'));
    const {
      id,
      departmentName,
      departmentMembers,
      departmentType,
      departmentSettings,
    } = item;
    let alteredDepartmentMembers = departmentMembers.reduce((newArray, ele) => {
      if (
        ele?.email !== session.email &&
        ele?.memberName !== session.name &&
        ele?.isActive === 1 &&
        ele?.status !== '3' &&
        ele?.role !== 'MEMBER'
      ) {
        ele = {
          id: ele.id,
          memberName: ele.memberName,
          email: ele.email,
          isActive: ele.isActive,
          taskPermission: ele.taskPermission,
          careerPermission: ele.careerPermission,
          teamApprovalPermission: ele.teamApprovalPermission,
          role:
            ele.role === 'ADMIN' ? INVITEMEMBERROLE[0] : INVITEMEMBERROLE[1],
          invitedByUserId: session.id,
        };
        newArray.push(ele);
      }
      return newArray;
    }, []);
    const alteredStateObject = {
      id: id,
      departmentName: departmentName,
      departmentType: departmentType,
      departmentSettings: {
        canHire: departmentSettings.includes(DEPARTMENTSETTINGS.CAN_HIRE)
          ? true
          : false,
        canPublish: departmentSettings.includes(DEPARTMENTSETTINGS.CAN_PUBLISH)
          ? true
          : false,
      },
      departmentMembers: alteredDepartmentMembers,
    };
    setCreateEnterpriseState(alteredStateObject);
  };

  let queryPayload = {
    page: currentPage,
    size: pageSize,
  };

  const getDepartmentsList = useCallback(
    async () => {
      const session = JSON.parse(localStorage.getItem('session'));
      const res = await fnWrapper({
        url: `${FETCH_DEPARTMENT_LIST}/${session.id}`,
        method: 'GET',
        type: 'FETCH_DEPARTMENT_LIST',
        params: queryPayload,
      });
      if (res.data.statusCode === 200) {
        if (res.data.data.items.length === 0 && currentPage !== 1) {
          setCurrentPage(currentPage - 1);
        } else {
          setDepartmentList(res.data.data);
          setFilteredData(res.data.data.items);
        }
      }
      setIsLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage, pageSize]
  );

  const removeDepartment = async (deptId) => {
    const res = await fnWrapper({
      url: `${REMOVE_DEPARTMENT}/${deptId}`,
      method: 'PUT',
      type: 'REMOVE DEPARTMENT',
    });

    if (res.data.statusCode === 200) {
      toast.success(res.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      getDepartmentsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const getDepartmentType = useCallback(
    async () => {
      const res = await fnWrapper({
        url: `${FETCH_DEPARTMENT_TYPE}`,
        method: 'GET',
        type: 'FETCH_DEPARTMENT_TYPE',
      });
      if (res.data.statusCode === 200) {
        setDepartmentTypes(res.data.data);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    getDepartmentsList();
    getDepartmentType();
  }, [getDepartmentsList, getDepartmentType]);

  // createDepartment Code
  const onChangeSearchHandler = async (value) => {
    const session = JSON.parse(localStorage.getItem('session'));
    setSearchDepartment(value);
    if (value) {
      const res = await fnWrapper({
        url: `${SEARCH_DEPARTMENT}?q=${value}&eid=${session.enterpriseId}`,
        method: 'GET',
        type: 'SEARCH_DEPARTMENT',
      });
      if (res.data.statusCode === 200) {
        setFilteredData(res.data.data);
      }
    } else {
      setFilteredData(departmentList.items);
    }
  };
  const closeSearchHandler = () => {
    setSearchDepartment('');
    setFilteredData(departmentList.items);
  };
  const createDepartmentInputChangeHandler = (field = '', value = null) => {
    setCreateEnterpriseState({
      ...createEnterpriseState,
      [field]: value,
    });
  };

  const createDepartmentCheckHandler = (
    field = '',
    value = null,
    type,
    index
  ) => {
    let temp = { ...createEnterpriseState };
    if (type === 'membersPermissions') {
      temp.departmentMembers[index][field] = !value;
      setCreateEnterpriseState(temp);
    }
    if (type === 'settings') {
      temp.departmentSettings[field] = !value;
      setCreateEnterpriseState(temp);
    }
  };

  const createDepartmentAddAdminChangeHandler = (
    field = '',
    value = '',
    index,
    item
  ) => {
    let temp = { ...createEnterpriseState };
    temp.departmentMembers[index][field] = value;
    setCreateEnterpriseState(temp);
  };

  const addAdminHandler = (e) => {
    const temp = { ...createEnterpriseState };
    temp.departmentMembers.push({
      id: '',
      memberName: '',
      email: '',
      isActive: 1,
      taskPermission: false,
      careerPermission: false,
      teamApprovalPermission: false,
      role: [],
    });
    setDepartmentMembersList(temp);
  };

  const deleteAdminHandler = (index, item) => {
    const temp = [...createEnterpriseState.departmentMembers].filter(
      (e, i) => i !== index
    );
    setCreateEnterpriseState({
      ...createEnterpriseState,
      departmentMembers: temp,
    });
  };

  const validateUniqueMembers = (members) => {
    const emails = members.map((member) => member.email.trim());
    const uniqueEmails = uniqBy(emails);

    return emails.length === uniqueEmails.length;
  };

  const createOrUpdateDepartment = async (createOrUpdate) => {
    const session = JSON.parse(localStorage.getItem('session'));

    // Check if there are any duplicate emails in the departmentMembers array
    const hasUniqueMembers = validateUniqueMembers(
      createEnterpriseState.departmentMembers
    );

    if (!hasUniqueMembers) {
      // Show an error if there are duplicates
      toast.error(
        'You cannot add the same user multiple times. Please correct the input.',
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        }
      );
      return; // Prevent creating/updating the department
    }

    let data = {
      departmentName: createEnterpriseState.departmentName,
      departmentTypeId: createEnterpriseState.departmentType.id,
      // departmentPermissions: [],
      departmentSettings: [],
      departmentMembers: [
        {
          memberName: session.name,
          email: session.email,
          role: 'SUPERADMIN',
          isActive: 1,
          taskPermission: false,
          careerPermission: false,
          teamApprovalPermission: false,
          invitedByUserId: session.id,
        },
        ...createEnterpriseState.departmentMembers.reduce((newArray, ele) => {
          if (ele.email !== '' && ele.memberName !== '' && ele.isActive === 1) {
            ele = {
              memberName: ele.memberName,
              email: ele.email,
              isActive: ele.isActive,
              role: ele.role.value,
              taskPermission: ele.taskPermission,
              careerPermission: ele.careerPermission,
              teamApprovalPermission: ele.teamApprovalPermission,
              invitedByUserId: session.id,
            };
            newArray.push(ele);
          }
          return newArray;
        }, []),
      ],
    };
    const { canHire, canPublish } = createEnterpriseState.departmentSettings;
    if (canHire) data.departmentSettings.push(DEPARTMENTSETTINGS.CAN_HIRE);
    if (canPublish)
      data.departmentSettings.push(DEPARTMENTSETTINGS.CAN_PUBLISH);
    if (createOrUpdate === 'create') {
      const res = await fnWrapper({
        url: `${CREATE_DEPARTMENT}`,
        method: 'POST',
        type: 'CREATE_DEPARTMENT',
        payload: data,
      });
      if (res.data.statusCode === 200) {
        toast.success(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        setCreateEnterpriseState(DEFAULTSTATE);
        setIsCreateDepartment(false);
        getDepartmentsList();
        setIsLoading(false);
      }
    } else {
      const { id } = createEnterpriseState;
      data.departmentMembers = createEnterpriseState.departmentMembers.reduce(
        (newArray, ele) => {
          if (ele.memberName !== session.name && ele.role.value !== 'MEMBER') {
            ele = {
              id: ele.id,
              memberName: ele.memberName,
              email: ele.email,
              role: ele.role.value,
              isActive: ele.isActive,
              taskPermission: ele.taskPermission,
              careerPermission: ele.careerPermission,
              teamApprovalPermission: ele.teamApprovalPermission,
              invitedByUserId: session.id,
            };
            newArray.push(ele);
          }
          return newArray;
        },
        []
      );
      const res = await fnWrapper({
        url: `${UPDATE_DEPARTMENT}/${id}`,
        method: 'PUT',
        type: 'UPDATE_DEPARTMENT',
        payload: data,
      });
      if (res.data.statusCode === 200) {
        toast.success(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        setCreateEnterpriseState(DEFAULTSTATE);
        setIsCreateDepartment(false);
        getDepartmentsList();
        setIsLoading(false);
      }
    }
  };

  const sortHandler = () => {
    setToggleSort(!toggleSort);
    let temp = toggleSort
      ? sortBy(departmentList.items, 'departmentName')
      : sortBy(departmentList.items, 'departmentName').reverse();
    setFilteredData(temp);
  };

  const createOrCloseDepartmenthandler = (type) => {
    if (type === 'update') {
      createOrUpdateDepartment('update');
    }
    if (type === 'create') {
      createOrUpdateDepartment('create');
    }
    if (type === 'close') {
      setIsCreateDepartment(false);
      setIsEdit(false);
      setCreateEnterpriseState(DEFAULTSTATE);
    }
  };

  return (
    <div className="view-department" key={1}>
      <Box mt="30px" mb="30px" className="add-department">
        <div>
          <span className="title">Departments</span>
        </div>
        {!(
          departmentList &&
          Object.keys(departmentList).length !== 0 &&
          departmentList.items.length === 0
        ) && (
          <CustomButton
            onClick={() => {
              setIsEdit(false);
              setIsCreateDepartment(true);
            }}
            variant="light"
            color={'themePrimary'}>
            <Icon name="add" color={'#0ABD00'} size={20} />
            New Department
          </CustomButton>
        )}
      </Box>
      {departmentList &&
      Object.keys(departmentList).length !== 0 &&
      departmentList.items.length === 0 &&
      departmentList.currentPage === 1 ? (
        <div>
          <Box
            mt="50px"
            display="flex"
            justifyContent="center"
            alignItems="center">
            <img
              src={noDepartments}
              alt=""
              width={isMobile() ? '100%' : '320px'}
              style={{ objectFit: 'cover' }}
            />
          </Box>
          <Box
            mt="30px"
            display="flex"
            textAlign="center"
            justifyContent="center">
            <Box width="70%">
              <p>Oh, looks like you don’t have any Departments as of now.</p>
              <p>Create a Department now </p>
            </Box>
          </Box>
          <Box mt="30px" mb="30px" display="flex" justifyContent="center">
            <CustomButton onClick={() => setIsCreateDepartment(true)}>
              <Icon name="add" color={'#ffffff'} size={20} />
              New Department
            </CustomButton>
          </Box>
        </div>
      ) : (
        <>
          <div className="row">
            <div className="col-lg-10 col-md-10 col-12 mb-3">
              <SearchField
                placeholder="Search Department"
                searchField={searchDepartment}
                onChangeSearchHandler={onChangeSearchHandler}
                closeSearchHandler={closeSearchHandler}
              />
            </div>
            <div className="col-lg-2 col-md-2 mb-3">
              <CustomButton
                className="w-100"
                onClick={sortHandler}
                color="themeGray"
                variant="light">
                <Icon name="sort" color={'#929DAF'} size={20} />
                Sort
              </CustomButton>
            </div>
          </div>
          {filteredData &&
            filteredData.length === 0 &&
            searchDepartment !== '' && (
              <div>
                <Box
                  mt="50px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center">
                  <img src={noDepartments} alt="" width="320px" />
                </Box>
                <Box
                  mt="30px"
                  display="flex"
                  textAlign="center"
                  justifyContent="center">
                  <Box width="70%">
                    <p>
                      Oh, looks like you don’t have any Department's name
                      starting by
                      <strong>{` ${searchDepartment}.`}</strong>
                    </p>
                    <p>Create a Department now </p>
                  </Box>
                </Box>
                <Box mt="30px" mb="30px" display="flex" justifyContent="center">
                  <CustomButton onClick={() => setIsCreateDepartment(true)}>
                    <Icon name="add" color={'#ffffff'} size={20} />
                    New Department
                  </CustomButton>
                </Box>
              </div>
            )}
          {isLoading ? (
            <Loader loading={isLoading} />
          ) : (
            <EnterPriseList
              viewDepartmentHandler={viewDepartmentHandler}
              active={activeEnterprise}
              onSelectEnterprise={activeEnterprisehandler}
              list={filteredData}
              editOrRemoveHandler={editOrRemoveHandler}
            />
          )}
          {searchDepartment === '' &&
            (filteredData.length >= 10 || departmentList.currentPage >= 1) && (
              <Pagination
                className="pagination-bar"
                currentPage={
                  departmentList.currentPage ? departmentList.currentPage : 0
                }
                totalCount={
                  departmentList.totalItems ? departmentList.totalItems : 0
                }
                pageSize={pageSize}
                onPageChange={(page) => setCurrentPage(page)}
              />
            )}
        </>
      )}
      {isCreateDepartment && (
        <ModalPopup
          open={false}
          onClose={() => setIsCreateDepartment(false)}
          minWidth="800px"
          maxContentWidth="800px"
          hasTitle={false}>
          <CreateDepartment
            createOrCloseDepartmenthandler={createOrCloseDepartmenthandler}
            state={createEnterpriseState}
            createDepartmentInputChangeHandler={
              createDepartmentInputChangeHandler
            }
            createDepartmentCheckHandler={createDepartmentCheckHandler}
            addAdminHandler={addAdminHandler}
            deleteAdminHandler={deleteAdminHandler}
            roleTypes={INVITEMEMBERROLE}
            departmentTypes={departmentTypes}
            createDepartmentAddAdminChangeHandler={
              createDepartmentAddAdminChangeHandler
            }
            isEdit={isEdit}
            context={context}
          />
        </ModalPopup>
      )}
    </div>
  );
};

export default Departments;
