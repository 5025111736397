import { CustomButton } from 'components';
import React from 'react';
import Icon from 'utlils/Icons/Icon';
import { TeamProjectCardWrapper } from './style';

export const TeamProjectCard = ({
  isImg,
  imgSrc = '',
  profileIcon,
  title,
  subTitle,
  type,
  typeIcon,
  cardType,
  viewHandler,
  isProjectPublished,
}) => {
  return (
    <TeamProjectCardWrapper>
      <div className="content-header-class">
        {isImg ? (
          <div className="profile-pic-class">
            <img src={imgSrc} alt="" />
          </div>
        ) : (
          <div className="icon-style-class">
            <Icon name={profileIcon} size={24} color="#404f67" />
          </div>
        )}
        <div className="card-heading">
          <div className="title-class">{title}</div>
          <div className="sub-title-class">
            <Icon name="people" color="#404F67" size={22} />
            <span>
              {subTitle}
              {cardType === 'project' ? ' Teams' : ' Members'}
            </span>
          </div>
          <div className="sub-title-class">
            <Icon name={typeIcon} color="#404F67" size={22} />
            <span>{type}</span>
          </div>
        </div>
      </div>
      <div className="view-button">
        <CustomButton
          className="w-100"
          variant="outline"
          onClick={viewHandler}
          disabled={!isProjectPublished && cardType === 'project'}
          color={
            isProjectPublished || cardType === 'team'
              ? 'themeSecondary'
              : 'themeGray'
          }>
          {!isProjectPublished && cardType === 'project' ? (
            <Icon name="lock-two" />
          ) : null}
          {!isProjectPublished && cardType === 'project' ? 'Private' : 'View'}
        </CustomButton>
      </div>
    </TeamProjectCardWrapper>
  );
};
