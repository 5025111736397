import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { isMobile } from 'utlils';
import Icon from 'utlils/Icons/Icon';
import {
  ACCEPT_OR_REJECT_DEPARTMENT_INVITATION,
  useAxiosWrapper,
} from 'services';
import { CustomButton, LabelWithLink, ModalPopup } from 'components';
import tokenService from 'services/token.service';

export const DepartmentInvitationActionModal = (props) => {
  const { actionState, onClose, redirectUri, DepartmentDetails, userSession } =
    props;
  const [fnWrapper, context] = useAxiosWrapper();
  const navigate = useNavigate();
  const onJoinWithOtherAccount = useCallback(() => {
    tokenService.onLogout();
    navigate('/proceed-with', {
      replace: true,
      state: { redirectUri: redirectUri },
    });
  }, [navigate, redirectUri]);

  const onJoinWithCurrentAccount = useCallback(async () => {
    const res = await fnWrapper({
      url: ACCEPT_OR_REJECT_DEPARTMENT_INVITATION,
      method: 'POST',
      type: 'ACCEPT_OR_REJECT_DEPARTMENT_INVITATION',
      payload: {
        newEmail: userSession?.email,
        invitationToken: DepartmentDetails?.inviteToken,
        memberId: DepartmentDetails?.id,
        status: actionState.step === 'ACCEPT' ? '4' : '3',
      },
    });

    if (res.data.statusCode === 200) {
      if (res.data.data.isActioned) {
        toast.warning(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.success(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
      navigate('/individual/profile/organization/enterprise', {
        replace: true,
      });
    }
  }, [
    actionState.step,
    fnWrapper,
    navigate,
    DepartmentDetails?.id,
    DepartmentDetails?.inviteToken,
    userSession?.email,
  ]);

  const logoutFromCurrentAccount = useCallback(() => {
    tokenService.onLogout();
    navigate('/login/individual', {
      replace: true,
      state: { redirectUri: redirectUri, type: 'INDIVIDUAL' },
    });
  }, [navigate, redirectUri]);

  return (
    <ModalPopup
      minWidth={'650px'}
      maxContentWidth={'650px'}
      onClose={onClose}
      open={actionState?.showModal}
      title={`${
        actionState.step === 'ACCEPT' ? 'Accept' : 'Reject'
      } Invitation`}>
      <Wrp className="ti">
        {actionState.step === 'ACCEPT' ? (
          <>
            <div className="ti__message-text">
              This invitation was shared on{' '}
              <span className="ti__bold-text">
                “{DepartmentDetails?.email}”
              </span>{' '}
              but your current account is logged in with{' '}
              <span className="ti__bold-text">“{userSession?.email}”</span>.
            </div>
            <div className="ti__message-text">
              Do you want to join this department with your current logged in
              mail?
            </div>
            <div className="ti__action-area">
              <CustomButton
                label="Yes, Join with logged in email id"
                onClick={onJoinWithCurrentAccount}
                loading={context.busy.status}
                customPadding="12px 14px"
              />
              <LabelWithLink
                color="#0ABD00"
                hasIcon={false}
                label="No, I would like to join with other account"
                onClick={onJoinWithOtherAccount}
                disabled={context.busy.status}
              />
            </div>
          </>
        ) : (
          <>
            {DepartmentDetails?.email !== userSession?.email ? (
              <>
                <div className="ti__message-text">
                  Your current logged in account is not authorized to reject
                  this invitation. In order to reject this invitation you’ve to
                  first logout from your current account and then log in with
                  the authorized account{' '}
                  <span className="ti__bold-text">
                    “{DepartmentDetails?.email}”
                  </span>
                  .
                </div>
                <div className="ti__action-area">
                  <CustomButton onClick={logoutFromCurrentAccount}>
                    {
                      <div>
                        <span>Logout from my account</span>
                        <Icon
                          color={'#ffffff'}
                          name={'log-out'}
                          size={24}
                          className="ti__action-area__icon"
                        />
                      </div>
                    }
                  </CustomButton>
                </div>
              </>
            ) : (
              <>
                <div className="ti__message-text">
                  Are you sure you want to reject this department invitation?
                </div>
                <div className="ti__reject__action-area">
                  <CustomButton
                    label="No"
                    onClick={onClose}
                    loading={context.busy.status}
                    variant="outline"
                  />

                  <CustomButton
                    label="Yes"
                    onClick={onJoinWithCurrentAccount}
                    loading={context.busy.status}
                  />
                </div>
              </>
            )}
          </>
        )}
      </Wrp>
    </ModalPopup>
  );
};

const Wrp = styled.div`
  margin-top: 16px;
  & .ti__ {
    &message-text {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 30px;
      text-align: center;
      color: #404f67;
      padding-top: 8px;
    }
    &bold-text {
      font-weight: 600;
    }
    &action-area {
      text-align: center;
      display: grid;
      row-gap: 28px;
      padding-top: ${() => !isMobile() && '36px'};
      width: ${() => !isMobile() && '60%'};
      margin: ${() => !isMobile() && '0 auto'};
      position: ${() => isMobile() && 'absolute'};
      bottom: ${() => isMobile() && '0'};
      padding-bottom: ${() => isMobile() && '36px'};
      left: ${() => isMobile() && '16px'};
      right: ${() => isMobile() && '16px'};

      &__icon {
        margin-left: 8px;
      }
    }

    &reject {
      &__action-area {
        display: flex;
        column-gap: 28px;
        justify-content: center;
        align-items: center;

        padding-top: ${() => !isMobile() && '36px'};
        width: ${() => !isMobile() && '50%'};
        margin: ${() => !isMobile() && '0 auto'};
        position: ${() => isMobile() && 'absolute'};
        bottom: ${() => isMobile() && '0'};
        padding-bottom: ${() => isMobile() && '36px'};
        left: ${() => isMobile() && '16px'};
        right: ${() => isMobile() && '16px'};

        &__icon {
          margin-left: 8px;
        }

        & > div {
          width: 100%;
        }
      }
    }
  }
`;
