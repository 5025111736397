import React, { useCallback, useEffect } from 'react';
import {
  APPLICANTS_TABLE_HEADERS,
  applicantStatus,
  collaboarationApplicantStatus,
} from '../constant';
import { LoaderIcon } from './LoaderIcon';
import {
  CopyWithIcon,
  CustomButton,
  CustomDropDown,
  CustomTable,
  ModalPopup,
  NavigationalRowItem,
  ScrollableDiv,
  StatusButton,
  Textarea,
} from 'components';
import {
  ACCEPT_REJECT_APPLICATION,
  GET_COLLABORATION_APPLICANT_LIST,
  GET_PROJECT_COLLABORATION_BY_ID,
  useAxiosWrapper,
} from 'services';
import { getPlaceholderItems } from 'utlils/utils';
import { useState } from 'react';
import dayjs from 'dayjs';
import Icon from 'utlils/Icons/Icon';
import { useNavigate, useParams } from 'react-router-dom';
import { ViewApplicationWrapper } from '../style';
import { checkForFileType } from 'pages/IndividualDashboard/individualOrganizations/enterprise/helper';
import { toast } from 'react-toastify';
import tokenService from 'services/token.service';
import ViewBiddingApplicants from '../../ProjectBidding/components/ViewBiddingApplicants/ViewBiddingApplicants';

export const ViewCollaborationApplicants = (props) => {
  let { id, projectId, type, departmentId } = useParams();
  const [fnWrapper, context] = useAxiosWrapper();
  const [applicantsTableData, setApplicantsTableData] = useState([]);
  const [viewApplication, setViewApplication] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [viewapplicationData, setViewApplicationData] = useState(null);
  const [header, setHeaders] = useState(null);
  const navigate = useNavigate();
  const loggedInUser = tokenService.getSession();

  const getCollaborationApplicantList = useCallback(async (status) => {
    let payload = {
      collaborationId: id,
      status: status,
    };
    const res = await fnWrapper({
      url: GET_COLLABORATION_APPLICANT_LIST,
      method: 'GET',
      type: 'GET_COLLABORATION_APPLICANT_LIST',
      params: payload,
    });
    if (res.data.statusCode === 200) {
      const result = res.data.data;
      setApplicantsTableData(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCollaborationDetailsById = useCallback(async () => {
    const res = await fnWrapper({
      url: `${GET_PROJECT_COLLABORATION_BY_ID}/${id}`,
      method: 'GET',
      type: 'GET_PROJECT_COLLABORATION_BY_ID',
    });
    if (res.data.statusCode === 200) {
      setHeaders(res.data.data?.collaborationType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCollaborationDetailsById();
    getCollaborationApplicantList();
  }, [getCollaborationApplicantList, getCollaborationDetailsById]);

  const openPopupHandler = (applicationData) => {
    let finalData = {
      ...applicationData,
      question: applicationData.question
        ? JSON.parse(applicationData.question)
        : null,
    };
    setViewApplicationData(finalData);
    setViewApplication(true);
  };
  const isProjectAdmin =
    viewapplicationData?.appliedCollaboration?.projects?.userId ===
    loggedInUser.id;

  const acceptRejectApplication = async (status) => {
    let payload = {
      applicantId: viewapplicationData?.id,
      status: status,
      userId: loggedInUser.id,
    };
    const res = await fnWrapper({
      url: ACCEPT_REJECT_APPLICATION,
      method: 'PUT',
      type: 'ACCEPT_REJECT_APPLICATION',
      payload: payload,
    });
    if (res.data.statusCode === 200) {
      await getCollaborationApplicantList();
      setViewApplication(false);
      toast.success(res.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    getCollaborationDetailsById();
    getCollaborationApplicantList();
  }, [getCollaborationApplicantList, getCollaborationDetailsById]);

  const applicantsListTableData = (data) => {
    let newData = [];
    if (data.length) {
      newData = data.map((item) => {
        const isIndividualUser =
          item.collaborationApplicant?.individual !== null;
        const applicantCollaboratorForms = item?.collaboratorForms?.length
          ? item?.collaboratorForms?.map((item) => JSON.parse(item))
          : [];
        const completionPercentage =
          applicantCollaboratorForms.length === 0
            ? 0
            : Math.round(
                (applicantCollaboratorForms.filter(
                  (item) => item?.applicantLocation !== null
                ).length /
                  applicantCollaboratorForms.length) *
                  100
              );
        return {
          id: item.id,
          name: (
            <NavigationalRowItem
              label={
                isIndividualUser
                  ? item.collaborationApplicant?.individual?.name
                  : item.collaborationApplicant?.smallEnterprise?.enterpriseName
              }
              navigateTo={
                isIndividualUser
                  ? `/public/individual/${item?.collaborationApplicant?.id}/summary/basic-details`
                  : ''
              }
              isOpenInNewTab
              isIndividualUser={isIndividualUser}
              customStyle={{ color: '#404f67' }}
            />
          ),
          appliedOn: dayjs(item.createdAt).format('DD MMM YYYY'),
          completionPercentage: completionPercentage + '%',
          application: (
            <div className="view-btn">
              <CustomButton
                label="View"
                variant="textBtn"
                onClick={() => openPopupHandler(item)}
                className="view-btn-font"
              />
            </div>
          ),
          status: (
            <StatusButton status={collaboarationApplicantStatus[item.status]} />
          ),
          action: (
            <LoaderIcon
              isEnterprise={departmentId?.length > 0}
              item={item}
              applicantId={item?.applicantId}
              name="message-icon"
              color="rgb(33, 159, 255)"
            />
          ),
        };
      });
    } else {
      newData = [];
    }

    return newData;
  };

  const allApplicantsData =
    context.busy.status &&
    context.busy.type === 'GET_COLLABORATION_APPLICANT_LIST'
      ? getPlaceholderItems(
          APPLICANTS_TABLE_HEADERS.map(({ sortKey }) => sortKey),
          6,
          context.busy.status &&
            context.busy.type === 'GET_COLLABORATION_APPLICANT_LIST'
        )
      : applicantsListTableData(applicantsTableData);

  const getFileName = (file) => {
    const setFileName = file.split('/');
    return setFileName[setFileName.length - 1];
  };

  return (
    <>
      {type === 'collaboration' ? (
        <ViewApplicationWrapper>
          <div className="applicants-container">
            <div className="d-flex justify-content-between">
              <div className="applicants-container__header">
                <span className="heading-back-btn">
                  <Icon
                    color="#292D32"
                    name="arrow-back"
                    size={30}
                    leftIcon
                    onClick={() => {
                      navigate(
                        `${props.baseUrl}/${projectId}/view/collab-bids`,
                        {
                          state: { type: type.toUpperCase() },
                        }
                      );
                    }}
                  />
                </span>
                <span className="heading-text">{header}</span>
              </div>
              <div>
                <div className="d-flex">
                  <CopyWithIcon
                    id={projectId}
                    className="copy-link"
                    textToCopy={`${process.env.REACT_APP_BASE_URL}/public/project/${projectId}/details?collaborationId=${id}`}
                  />
                  <CustomDropDown
                    optionLists={applicantStatus}
                    selectedItem={selectedItem}
                    setSelectedItem={async (item) => {
                      setSelectedItem(item);
                      await getCollaborationApplicantList(item?.value);
                    }}
                    height="36px"
                    customStyle={{
                      backgroundColor: '#EBEBEB',
                      color: '#404F67',
                      padding: '10px 8px 10px 16px',
                      whiteSpace: 'normal',
                      width: '200px',
                    }}
                    width={'100%'}
                    placeholder={'Status'}
                  />
                </div>
              </div>
            </div>
          </div>
          {!applicantsTableData.length ? (
            <div className="no-records">No Records Found!</div>
          ) : (
            <div className="table-style">
              <CustomTable
                isLoading={context.busy.status}
                rows={allApplicantsData}
                headers={APPLICANTS_TABLE_HEADERS}
              />
            </div>
          )}
          {viewApplication && (
            <ModalPopup
              open={false}
              onClose={() => setViewApplication(false)}
              minWidth="1000px"
              maxContentWidth="1000px"
              hasTitle
              title="Collaborator Application">
              <ScrollableDiv
                scrollOnHeight="calc(100vh - 290px)"
                scrollOnHeightMob="300px">
                {viewapplicationData && (
                  <div className="applicants-wrapper">
                    {viewapplicationData?.question
                      ? Object.keys(viewapplicationData?.question).map(
                          (key, index) => (
                            <div className="question_wrapper" key={index}>
                              <div className="sub-heading">
                                <span>{index + 1}. </span>
                                {key}
                              </div>
                              <div className="pb-textarea">
                                <Textarea
                                  rows={4}
                                  disabled={true}
                                  value={viewapplicationData?.question[key]}
                                />
                              </div>
                            </div>
                          )
                        )
                      : null}
                    <div className="sub-heading mt-3 mb-2">
                      {viewapplicationData?.question
                        ? Object.keys(viewapplicationData?.question).length +
                          1 +
                          '. Forms attached'
                        : null}
                    </div>
                    <div className="pb-textarea">
                      {
                        <div className="post-attachment">
                          {viewapplicationData?.collaboratorForms?.map(
                            (elem, index) => {
                              let element = JSON.parse(elem);
                              const fileName = getFileName(element.location);
                              return (
                                <div className="img-wrapper" key={index}>
                                  <div className="img-container">
                                    {checkForFileType(fileName) === 'others' ? (
                                      <Icon name="attachment" />
                                    ) : (
                                      <img
                                        src={checkForFileType(fileName)}
                                        alt="attachment"
                                      />
                                    )}
                                  </div>
                                  <a
                                    className={
                                      element.applicantLocation
                                        ? 'img-text'
                                        : 'file-not-uploaded'
                                    }
                                    href={element.applicantLocation}
                                    target="_blank"
                                    rel="noreferrer">
                                    {fileName}
                                  </a>
                                  <div className="icon-content">
                                    <Icon
                                      name={
                                        element.applicantLocation
                                          ? 'tickIcon'
                                          : 'small-close'
                                      }
                                      color={
                                        element.applicantLocation
                                          ? '#0ABD00'
                                          : 'rgba(146, 157, 175, 1)'
                                      }
                                      size={18}
                                    />
                                  </div>
                                </div>
                              );
                            }
                          )}
                        </div>
                      }
                    </div>
                    <div className="button-content">
                      <div className="reject-btn">
                        <CustomButton
                          label={'Reject Request'}
                          color="themeTertiary"
                          variant="textBtn"
                          loading={
                            context.busy.status &&
                            context.busy.type === 'ACCEPT_REJECT_APPLICATION'
                          }
                          disabled={
                            viewapplicationData?.status === 'ACCEPTED' ||
                            viewapplicationData?.status === 'REJECTED' ||
                            !isProjectAdmin
                          }
                          onClick={() => acceptRejectApplication('rejected')}
                        />
                      </div>
                      <div className="approve-btn">
                        <CustomButton
                          label={'Approve Request'}
                          onClick={() => acceptRejectApplication('accepted')}
                          loading={
                            context.busy.status &&
                            context.busy.type === 'ACCEPT_REJECT_APPLICATION'
                          }
                          disabled={
                            viewapplicationData?.status === 'ACCEPTED' ||
                            viewapplicationData?.status === 'REJECTED' ||
                            !isProjectAdmin
                          }
                          className="w-100"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </ScrollableDiv>
            </ModalPopup>
          )}
        </ViewApplicationWrapper>
      ) : (
        <>
          <ViewBiddingApplicants {...props} />
        </>
      )}
    </>
  );
};
