import cn from 'classname';
import Icon from 'utlils/Icons/Icon';
import { useNavigate } from 'react-router-dom';
import { ModalPopup, Textarea, CustomButton } from 'components';
import RadioGroup from 'pages/IndividualDashboard/components/radioGroup';
import { ProfilePhoto } from 'pages/Explore/Components';
import { Success } from './sucessfullyApplied';

export const PopupApplyWithOptions = ({
  applyStep,
  setApplyStep,
  radioType,
  viewData,
  setRadioType,
  label,
  user,
  applicantDatahandler,
  applicantData,
  submitApplication,
  isLoading,
  onCloseHandler,
}) => {
  const { resume, questionAndAnswers, specialization } = applicantData;
  const disabled = radioType === null || (radioType === '3' && !resume);
  const isquesOrTechnologyNull =
    questionAndAnswers?.length && questionAndAnswers[0]?.question !== ''
      ? questionAndAnswers.map((ques) => ques.answer === '').includes(true) ||
        !specialization?.length
      : !specialization?.length;
  const navigate = useNavigate();

  return (
    <>
      {applyStep && (
        <ModalPopup
          open={false}
          maxContentWidth={'900px'}
          title={
            applyStep !== 3 && (
              <span>
                Apply to{' '}
                <span className="pcw-jobtitle">{viewData?.jobTitle} </span>
                <span className="pcw-owner-name">{viewData?.owner}</span>
              </span>
            )
          }
          popupTitleClass={'popup-title-class'}
          onClose={onCloseHandler}>
          {applyStep === 1 && (
            <div className="popup-content">
              <RadioGroup
                currChecked={radioType}
                handleChange={(e) => setRadioType(e.target.value)}
                customClassName={'radio-btn-class'}
                labels={label}
              />
              {radioType && (
                <div className="apply-with-container">
                  {radioType === '1' && (
                    <ProfilePhoto
                      name={user.name}
                      profilePicture={user.profilePicture}
                      size={100}
                    />
                  )}
                  <div className="d-flex align-items-center gap-2">
                    {radioType === '2' && (
                      <Icon
                        className="E-record"
                        name="personalCard"
                        size={70}
                        color="#404F67"
                      />
                    )}
                    <div className="profile-details">
                      <div className="name">
                        {radioType === '1'
                          ? user.name
                          : `${
                              radioType === '3'
                                ? 'Upload your resume'
                                : 'Apply with E-record'
                            }`}
                      </div>
                      {radioType === '1' && (
                        <div className="title">{user?.title}</div>
                      )}
                      <div className="button-upload">
                        {radioType === '3' && (
                          <>
                            <CustomButton
                              className="upload-button"
                              color="themeGray"
                              variant="light">
                              Upload
                              <Icon name="arrow-up-two" color="#404F67" />
                            </CustomButton>
                            <input
                              className="upload"
                              type="file"
                              onChange={(event) =>
                                applicantDatahandler({
                                  type: 'resume',
                                  value: event.target.files[0],
                                })
                              }
                            />
                            {resume?.name && (
                              <div className="resume-name">{resume?.name}</div>
                            )}
                          </>
                        )}
                        {radioType === '2' && (
                          <CustomButton
                            customPadding="0px"
                            className="view-button"
                            variant="textBtn"
                            color="themeSecondary"
                            label="View"
                            onClick={() =>
                              window.open(
                                `/public/individual/${user.id}/summary/basic-details`
                              )
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <CustomButton
                    label="Continue"
                    className="continue"
                    disabled={disabled}
                    onClick={() => setApplyStep(2)}
                  />
                </div>
              )}
            </div>
          )}
          {applyStep === 2 && (
            <div className="question-technology">
              {questionAndAnswers[0].question !== '' &&
                questionAndAnswers.map((ques, index) => (
                  <div className="answers">
                    <Textarea
                      rows={3}
                      value={ques.answer}
                      errorMessage="Please type apropriate answer"
                      key={`${ques}+${index}`}
                      label={`${ques.question}`}
                      required
                      onChange={(e) =>
                        applicantDatahandler({
                          type: 'questionAndAnswers',
                          value: [
                            ...questionAndAnswers.slice(0, index),
                            {
                              ...questionAndAnswers[index],
                              answer: e.target.value,
                            },
                            ...questionAndAnswers.slice(index + 1),
                          ],
                        })
                      }
                    />
                  </div>
                ))}
              <div className="specialization">
                <span className="title">
                  {'Select your specializations in-'}
                </span>
                <div className="technology">
                  {viewData?.technology?.map((tech) => (
                    <div
                      className={cn(
                        `item ${
                          specialization.some((e) => e?.id === tech?.id)
                            ? 'active'
                            : ''
                        }`
                      )}
                      onClick={() =>
                        applicantDatahandler({
                          type: 'specialization',
                          value: specialization.some((e) => e?.id === tech?.id)
                            ? specialization.filter(
                                (item) => item?.id !== tech?.id
                              )
                            : [...specialization, tech],
                        })
                      }>
                      {tech?.name}
                    </div>
                  ))}
                </div>
              </div>
              <div className="apply-submit">
                <CustomButton
                  onClick={() => setApplyStep(1)}
                  variant="textBtn"
                  color="themeSecondary">
                  <Icon name="arrow-back" color="#219FFF" />
                  Back
                </CustomButton>
                <CustomButton
                  label="Submit"
                  customPadding="18px 28px"
                  disabled={isquesOrTechnologyNull}
                  onClick={submitApplication}
                  loading={isLoading}
                />
              </div>
            </div>
          )}
          {applyStep === 3 && (
            <Success
              viewApplicationHandler={() =>
                navigate('/explore/jobs/my-jobs/applied-jobs', {
                  replace: true,
                })
              }
            />
          )}
        </ModalPopup>
      )}
    </>
  );
};
