export const navBarData = [
  {
    id: 1,
    title: 'Home',
    url: '/individual/profile/info/basic_details',
    isDropdown: false,
  },
  {
    id: 2,
    title: 'Explore',
    url: '',
    isDropdown: true,
    dropDownItems: [
      {
        subTitle: 'Explore Objects',
      },
      {
        subTitle: 'Explore Jobs',
      },
      {
        subTitle: 'Explore Teams',
      },
    ],
  },
];

export const getTeamPublicLeftSideData = (teamId) => {
  return [
    {
      type: 'details',
      iconName: 'text-align-left',
      title: 'Details',
      url: `/public/team/${teamId}/details`,
      hasSubMenu: false,
    },
    {
      type: 'members',
      iconName: 'people',
      title: 'Members',
      url: `/public/team/${teamId}/members`,
      hasSubMenu: false,
    },
    {
      type: 'projects',
      iconName: 'project',
      title: 'Projects',
      url: `/public/team/${teamId}/projects`,
      hasSubMenu: false,
    },
    {
      type: 'publications',
      iconName: 'book',
      title: 'Publications',
      url: `/public/team/${teamId}/publications`,
      hasSubMenu: false,
    },
    {
      type: 'openings',
      iconName: 'brief-case',
      title: 'Openings',
      url: `/public/team/${teamId}/openings`,
      hasSubMenu: false,
    },
  ];
};
