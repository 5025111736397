export const memberRole = {
  admin: {
    label: 'Admin',
    bgColor: '#E2FFE0',
    textColor: '#0ABD00',
    fontSize: '14px',
    padding: '8px',
  },
  coadmin: {
    label: 'Co-Admin',
    bgColor: 'rgba(255, 164, 18, 0.25)',
    textColor: '#FFA412',
    fontSize: '14px',
    padding: '8px',
  },
  member: {
    label: 'Member',
    bgColor: '#F0F0F0',
    textColor: '#929DAF',
    fontSize: '14px',
    padding: '8px',
  },
};

export const SECTION_CARD_DATA = {
  Teams: {
    label: 'Teams & Projects',
    path: 'teams',
    permissions: [
      { label: 'Create', isChecked: false },
      { label: 'Edit', isChecked: false },
      { label: 'Team Approval', isChecked: true },
    ],
  },
  Career: {
    label: 'Career',
    path: 'career',
    permissions: [
      { label: 'Approve', isChecked: false },
      { label: 'Onboard', isChecked: true },
    ],
  },
  Tasks: {
    label: 'Tasks',
    path: 'tasks',
    permissions: [
      { label: 'Create', isChecked: false },
      { label: 'Manage', isChecked: true },
    ],
  },
};
