import { CustomButton, DropDown, Textarea } from 'components';
import { useCallback, useEffect, useState } from 'react';
import {
  FETCH_POSTED_JOBS,
  FETCH_TEAM_BY_DEPT_ID,
  GET_TEAMS_BY_USER_ID,
  useAxiosWrapper,
} from 'services';
import tokenService from 'services/token.service';
import { getDepartmentsList } from '../Common';
import { INVITATION_TYPES } from 'pages/IndividualPublic/constants';

export const InviteJob = ({ handleSendInvite }) => {
  const [departmentData, setDepartmentData] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const [jobData, setJobData] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [description, setDescription] = useState('');
  const [fnWrapper, { busy }] = useAxiosWrapper();
  const { role } = tokenService.getSession();

  const getTeamList = useCallback(async (selectedDepartment) => {
    if (role !== 'INDIVIDUAL') {
      const res = await fnWrapper({
        url: `${FETCH_TEAM_BY_DEPT_ID}/${selectedDepartment?.id}`,
        method: 'GET',
        type: 'FETCH_TEAM_BY_DEPT_ID',
      });
      if (res.data.statusCode === 200) {
        const result = res.data.data;
        setTeamData(
          result.teams.map((team) => ({
            ...team,
            label: team?.teamName,
            value: team?.id,
          }))
        );
      }
      return;
    }
    const res = await fnWrapper({
      url: `${GET_TEAMS_BY_USER_ID}/${tokenService.getSession()?.id}`,
      method: 'GET',
      params: { type: 'personal' },
      type: 'GET_PROJECT_COLLABORATION',
    });
    if (res.data.statusCode === 200) {
      const result = res.data.data;
      setTeamData(
        result.map((team) => ({
          ...team,
          label: team?.teamName,
          value: team?.id,
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getJobsPerTeam = useCallback(async (teamId) => {
    if (teamId) {
      const res = await fnWrapper({
        url: `${FETCH_POSTED_JOBS}/${teamId}`,
        method: 'GET',
        type: 'FETCH_JOBS_BASED_ON_TEAM',
      });
      if (res.data.statusCode === 200) {
        const result = res.data.data;
        setJobData(
          result?.items?.map((job) => ({
            ...job,
            label: job?.title,
            value: job?.id,
          }))
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Get department list (if enterprise), load teams if individual
  useEffect(() => {
    if (role !== 'INDIVIDUAL') {
      getDepartmentsList(fnWrapper).then((departmentData) =>
        setDepartmentData(departmentData)
      );
      return;
    }
    getTeamList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTeamList, role]);

  //Get team list on department change
  useEffect(() => {
    if (selectedDepartment) {
      getTeamList(selectedDepartment);
    }
  }, [selectedDepartment, getTeamList]);

  //Get jobs posted on teams change
  useEffect(() => {
    if (selectedTeam) {
      setSelectedJob(null);
      getJobsPerTeam(selectedTeam?.id);
    }
  }, [selectedTeam, getJobsPerTeam]);

  //Send data to submit
  const handleSubmit = async () => {
    const data = {
      invitationType: INVITATION_TYPES.JOB,
      teamId: selectedTeam?.id,
      jobId: selectedJob?.id,
      description: description,
      invitedByUserId: tokenService.getSession()?.id,
    };
    await handleSendInvite(data);
  };

  const isDisabled = () => {
    if (role === 'INDIVIDUAL') {
      if (!selectedTeam || !selectedJob) {
        return true;
      }
      return false;
    }
    if (!selectedDepartment || !selectedTeam || !selectedJob) {
      return true;
    }
    return false;
  };

  return (
    <>
      {role !== 'INDIVIDUAL' ? (
        <div className="drop-down-item">
          <DropDown
            items={departmentData}
            label="Department"
            objectKey="label"
            placeholder={busy?.status ? 'Loading' : 'Select'}
            value={selectedDepartment}
            required
            onClick={(department) => {
              setSelectedDepartment(department);
            }}
          />
        </div>
      ) : null}
      <div className="drop-down-item">
        <DropDown
          items={teamData}
          label="Team"
          objectKey="label"
          placeholder={busy?.status ? 'Loading' : 'Select'}
          value={selectedTeam}
          required
          onClick={(team) => {
            setSelectedTeam(team);
          }}
        />
      </div>
      <div className={role !== 'INDIVIDUAL' ? 'designation' : 'drop-down-item'}>
        <DropDown
          items={jobData}
          label="Posted Job"
          objectKey="label"
          placeholder={busy?.status ? 'Loading' : 'Select'}
          value={selectedJob}
          required
          onClick={(job) => {
            setSelectedJob(job);
          }}
        />
      </div>
      <div className="designation">
        <DropDown
          disabled
          label="Designation"
          placeholder={selectedJob?.designation ?? 'N/A'}
        />
      </div>
      <div className="describe-text">
        <Textarea
          rows={4}
          cols={50}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          label="Description"
          placeholder="Describe the invite"
        />
      </div>
      <div className="invite-popup-btn">
        <CustomButton
          disabled={isDisabled() || busy?.status}
          label={busy?.status ? 'Working on it' : 'Send Invitation'}
          onClick={handleSubmit}
          className="w-100"
        />
      </div>
    </>
  );
};
