import { DropDown } from 'components';
import { INVITE_TYPE } from 'pages/IndividualPublic/constants';
import { useCallback, useState } from 'react';
import { InviteJob } from './InviteJob';
import { InviteIndividualWrapper } from './style';
import { InviteCollaboration } from './InviteCollaboration';
import { InviteBidding } from './InviteBidding';
import { SEND_INDIVIDUAL_INVITATION, useAxiosWrapper } from 'services';
import { toast } from 'react-toastify';

export const InviteIndividual = ({ userId, onClose }) => {
  const [selectedType, setSelectedType] = useState(null);
  const [fnWrapper] = useAxiosWrapper();

  const handleSendInvite = useCallback(async (data) => {
    const res = await fnWrapper({
      url: SEND_INDIVIDUAL_INVITATION,
      method: 'POST',
      type: 'SEND_INDIVIDUAL_INVITATION',
      payload: { ...data, invitedToUserId: userId },
    });
    if (res.data.statusCode === 200) {
      toast.success(res.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InviteIndividualWrapper>
      <div className="drop-down-wrapper">
        <div
          className={
            selectedType ? 'drop-down-item' : 'drop-down-item-unselected'
          }>
          <div className="invite-class">
            <DropDown
              items={INVITE_TYPE}
              label="Type of Invite"
              objectKey="label"
              placeholder="Select"
              value={selectedType}
              required
              onClick={(type) => {
                setSelectedType(type);
              }}
            />
          </div>
        </div>
        {selectedType?.label === 'Job' ? (
          <InviteJob handleSendInvite={handleSendInvite} />
        ) : null}
        {selectedType?.label === 'Collaboration' ? (
          <InviteCollaboration handleSendInvite={handleSendInvite} />
        ) : null}
        {selectedType?.label === 'Bidding' ? (
          <InviteBidding handleSendInvite={handleSendInvite} />
        ) : null}
      </div>
    </InviteIndividualWrapper>
  );
};
