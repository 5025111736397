import { useState, useCallback, useEffect } from 'react';
import { DepartmentTeamMembersWrapper } from './style';
import {
  SearchField,
  CustomTable,
  ScrollableDiv,
  CustomButton,
} from 'components';
import { MembersFilter } from './MembersFilter';
import {
  MEMBERSTABLEHEADERS,
  membersTableData,
} from 'pages/enterpriseDashboard/helper';
import { getPlaceholderItems } from 'utlils/utils';
import { useParams } from 'react-router-dom';
import { capitalizeFirstLetter } from 'pages/IndividualDashboard/individualOrganizations/enterprise/helper';
import { FETCH_DEPARTMENT_MEMBERS, useAxiosWrapper } from 'services';
import Icon from 'utlils/Icons/Icon';

export const Members = () => {
  const [fnWrapper, context] = useAxiosWrapper();
  const [searchMember, setSearchMember] = useState('');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [membersData, setMembersData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState({
    filteredDesignation: '',
    filteredTeamName: '',
    filteredProjectName: '',
  });
  const { departmentId } = useParams();

  const getMembersList = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_DEPARTMENT_MEMBERS}/${departmentId}`,
      method: 'GET',
      type: 'FETCH_TEAM_MEMBERS_LIST',
    });

    if (res.data.statusCode === 200) {
      const membersList = [];
      res.data.data?.map((item) => {
        if (item.memberUser !== null) {
          let teamList =
            item.team?.length > 0
              ? item.team?.filter((team) => team !== null)
              : [];
          let projectsList =
            teamList?.length > 0
              ? teamList?.map((team) =>
                  team?.projects?.filter((project) => project?.length !== 0)
                )
              : [];
          let memberDesignation =
            teamList?.length > 0
              ? teamList?.map((team) =>
                  capitalizeFirstLetter(team?.teamMembers[0]?.designation)
                )
              : '';
          const newMemberData = {
            id: item.id,
            userType:
              item?.memberUser?.individual !== null
                ? 'individual'
                : 'enterprise',
            memberName:
              item?.memberUser?.individual !== null
                ? item?.memberUser?.individual?.name
                : item?.memberUser?.smallEnterprise?.enterpriseName,
            profilePicture:
              item?.memberUser?.individual !== null
                ? item?.memberUser?.individual?.profilePicture
                : item?.memberUser?.smallEnterprise?.profilePicture,
            designation:
              memberDesignation.length > 0 ? memberDesignation : 'N/A',
            teams: teamList.length > 0 ? teamList : [],
            projects: projectsList.length > 0 ? projectsList.flat() : [],
            expandable:
              (item.team?.length !== 0 && item.team?.length > 1) ||
              (projectsList.length !== 0 && projectsList.length > 1),
            memberUserId:
              item?.memberUser?.individual !== null
                ? item?.memberUser?.id
                : item?.memberUser?.smallEnterprise?.id,
            isIndividual: item.team?.length > 0,
          };
          membersList.push(newMemberData);
          // return membersList;
        }
        return membersList;
      });
      setMembersData(membersList);
      setFilteredData(membersList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchMemberByNameHandler = (value) => {
    setSearchMember(value);
    let temp = [];
    if (value) {
      temp = membersData.filter(
        (ele) => ele.memberName.toLowerCase().indexOf(value.toLowerCase()) > -1
      );
      setFilteredData(temp);
    } else {
      setFilteredData(membersData);
    }
  };
  const closeSearchMemberByName = () => {
    setSearchMember('');
    setFilteredData(membersData);
  };

  const memberFilterSaveHandler = (type) => {
    if (type === 'apply' && membersData.length !== 0) {
      setSearchMember('');
      let result = membersData;

      let filterByDesignation = (item) => {
        if (filteredMembers.filteredDesignation === '') {
          return item;
        } else {
          return item.filter(
            (ele) =>
              ele.designation[0].toLowerCase() ===
              filteredMembers.filteredDesignation.toLowerCase()
          );
        }
      };
      let filterByTeamName = (item) => {
        if (filteredMembers.filteredTeamName === '') {
          return item;
        } else {
          return item.filter((ele) =>
            ele.teams.some((team) =>
              team.teamName
                .toLowerCase()
                .includes(filteredMembers.filteredTeamName.toLowerCase())
            )
          );
        }
      };
      let filterByProjectName = (item) => {
        if (filteredMembers.filteredProjectName === '') {
          return item;
        } else {
          return item.filter((ele) =>
            ele.projects.some((project) =>
              project.title
                .toLowerCase()
                .includes(filteredMembers.filteredProjectName.toLowerCase())
            )
          );
        }
      };

      if (filteredMembers.filteredDesignation !== '') {
        result = filterByDesignation(result);
      }
      if (filteredMembers.filteredTeamName !== '') {
        result = filterByTeamName(result);
      }
      if (filteredMembers.filteredProjectName !== '') {
        result = filterByProjectName(result);
      }
      setFilteredData(result);
      setIsFilterOpen(false);
    } else if (type === 'cancel') {
      setFilteredMembers({
        filteredDesignation: '',
        filteredTeamName: '',
        filteredProjectName: '',
      });
      setFilteredData(membersData);
      setIsFilterOpen(false);
    }
  };

  const membersFilterOnChangeHandler = (field = '', value = null) => {
    setFilteredMembers({
      ...filteredMembers,
      [field]: value,
    });
  };

  const membersListData = context.busy.status
    ? getPlaceholderItems(
        MEMBERSTABLEHEADERS.map(({ sortKey }) => sortKey),
        4,
        context.busy.status
      )
    : membersTableData(filteredData);

  useEffect(() => {
    getMembersList();
  }, [getMembersList]);

  return (
    <DepartmentTeamMembersWrapper>
      <div className="main-wrapper--class">
        <div className="header-btns-wrapper">
          <div className="left-button">
            <SearchField
              searchField={searchMember}
              onChangeSearchHandler={searchMemberByNameHandler}
              closeSearchHandler={closeSearchMemberByName}
              placeholder="Search Member"
            />
          </div>

          <CustomButton
            onClick={() => setIsFilterOpen(!isFilterOpen)}
            color={isFilterOpen ? 'themeSecondary' : 'themeGray'}
            variant="light">
            <Icon
              name="filter"
              color={isFilterOpen ? 'rgba(33, 159, 255, 1)' : '#929daf'}
              size={18}
            />
            Filter
          </CustomButton>
        </div>
        {isFilterOpen && (
          <>
            <MembersFilter
              filterTitle="Filter Members"
              state={filteredMembers}
              memberFilterSaveHandler={memberFilterSaveHandler}
              memberFilterOnChangeHandler={membersFilterOnChangeHandler}
            />
          </>
        )}
        {searchMember !== '' && filteredData.length === 0 ? (
          <div className="no-member">
            <p>
              Oh, looks like you don’t have any Member's name starting by
              <strong>{` ${searchMember}.`}</strong>
            </p>
          </div>
        ) : (
          <ScrollableDiv scrollOnHeight="420px" scrollOnHeightMob="300px">
            <CustomTable
              isLoading={context.busy.status}
              rows={membersListData}
              headers={MEMBERSTABLEHEADERS}
            />
          </ScrollableDiv>
        )}
      </div>
    </DepartmentTeamMembersWrapper>
  );
};
