import styled from 'styled-components';

export const SearchInputStyle = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 54px;
  border: 1px solid #e9eef4;
  border-radius: 12px;
  font-family: 'Poppins', 'Open Sans';
  background-color: white;
  .search-box {
    &__inputContainer {
      display: flex;
      align-items: center;
      width: 95%;
      gap: 10px;
      height: 100%;
      &__search--icon {
        margin-left: 12px;
      }
      &__input--box {
        width: 94%;
        height: 100%;
        border: none;
        &:focus,
        active {
          border: none;
          outline: none;
        }
      }
      &__cancel--search {
        display: flex;
        align-items: center;
        &__crossButton {
          color: #929daf;
          height: 18px;
          width: 18px;
          margin-right: 8px;
          cursor: pointer;
        }
      }
    }
    &__icon {
      display: flex;
      justify-content: center;
      cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
      align-items: center;
      background-color: ${({ disabled }) => (disabled ? '#929daf' : '#219FFF')};
      width: 155px;
      font-size: 16px;
      font-weight: 600;
      height: 100%;
      border-radius: 0 8px 8px 0;
      color: white;
      gap: 3px;
      padding: 5px;
    }
  }
`;
