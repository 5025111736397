import { useRef } from 'react';
import { useEffect } from 'react';

export const useClickOutside = (cb) => {
  const optionRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (optionRef?.current && !optionRef?.current?.contains(event.target)) {
        cb();
      }
    };

    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [cb, optionRef]);

  return optionRef;
};
