import { useNavigate } from 'react-router-dom';
import cn from 'classname';
import { noop } from 'lodash';

export const SubMenu = ({ subMenuData, accessiblePermissions }) => {
  const navigate = useNavigate();
  return (
    <div className="sidebar__wrapper__container__subData">
      {subMenuData.length
        ? subMenuData
            .filter((e) =>
              e?.permission
                ? accessiblePermissions.includes(e?.permission)
                : true
            )
            .map((subData, index) => (
              <div
                key={subData.type + '-' + index}
                className={cn(
                  'sidebar__wrapper__container__subData__sub-button d-flex align-items-center gap-2',
                  `${
                    subData?.url
                      ? window.location.href.includes(subData?.type)
                        ? 'activeSubButton'
                        : 'disActiveButton'
                      : 'disActiveButton'
                  }`
                )}
                onClick={() => (subData?.url ? navigate(subData?.url) : noop)}>
                <div>{subData?.title}</div>
              </div>
            ))
        : null}
    </div>
  );
};
