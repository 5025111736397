import styled from 'styled-components';

export const YourNotificationWrapper = styled.div`
  position: absolute;
  display: block;
  height: calc(100vh - 40vh);
  top: 45px;
  right: -7%;
  bottom: 0;
  z-index: 2;
  cursor: auto;
  width: 420px;
  border: 1px solid #f4f7fc;
  border-top: 7px solid #219fff;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 10px 36px 0px rgba(0, 0, 0, 0.08);
  padding: 12px;
  ::before {
    content: '';
    position: absolute;
    top: -27px;
    right: 13px;
    border: 11px solid transparent;
    border-bottom-color: #219fff;
  }
  .notification-container-wrp__ {
    position: relative;
    height: 100%;
    &header-content {
      display: grid;
      grid-template-columns: 1.8fr 1fr;
      margin-top: 8px;
    }
    &header-text {
      color: #17191d;
      font-size: 16px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
    &header-btn {
      font-size: 16px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
    &notification-content__ {
      margin-top: 20px;
      height: calc(100vh - 55vh);
      &line {
        height: 1.5px;
        background-color: #e7eff0;
      }
      &username {
        color: #219fff;
        font-size: 14px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      &title {
        color: #404f67;
        font-size: 13px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      &time-duration {
        color: #929daf;
        font-size: 10px;
        font-family: Open Sans;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    &footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 16px 0;
      &__all-notification-btn {
        width: 100%;
        font-size: 12px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
    & .profile-round-img {
      border-radius: 50%;
      height: 40px;
      width: 40px;
      background-color: #d6f3f9;
      display: flex;
      justify-content: center;
      align-items: center;
      .prof-img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
      .prof-txt {
        font-size: 14px;
        font-weight: 700;
      }
    }

    .no-notification-section {
      display: grid;
      justify-items: center;
      height: 100%;
      align-content: center;
      gap: 20px;
      color: #929daf;
      font-family: Open Sans;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0em;
    }
  }
`;
