/* eslint-disable no-console */
import { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Box, CustomButton, Title } from '../../components';
import IndividualUser from '../../pages/signup/Individual/index';
import Educational from './Educational';
import SmallEnterprise from './smallE/index';
import LargeInterprise from '../../pages/signup/largeE/index';
import './signup.scss';
import Icon from 'utlils/Icons/Icon';
import { useGoogleLogin } from '@react-oauth/google';

import {
  checkForSpacesInStartEnd,
  isEmailExist,
  isUserNameExist,
  isValidEmail,
  isValidName,
  isValidPassword,
} from '../../helpers';
import {
  FETCH_WAITINGLIST_STATUS,
  VERIFY_GOOGLE_RECAPTCHA,
  useAxiosWrapper,
} from 'services';

const Signup = () => {
  let { type } = useParams();
  let navigate = useNavigate();
  let location = useLocation();

  const [fnWrapper] = useAxiosWrapper();
  const [state, setState] = useState({
    name: null,
    email: null,
    occupation: null,
    country: null,
    contactNo: null,
    userName: null,
    password: null,
    confirmPassword: null,
    link: null,
    organizationType: {},
    topic: null,
    contact: null,
    hasPasswordEntered: false,
    hasCPasswordEntered: false,
    error: {},
  });
  const [grouptype, setGroupType] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [passwordError, setPasswordError] = useState(null);
  const [waitingListStatus, setWaitingListStatus] = useState(null);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const {
    name,
    password,
    confirmPassword,
    email,
    userName,
    occupation,
    countryData,
    contactNo,
    link,
    organizationType,
    topic,
    contact,
    error,
  } = state;

  const OCCUPATION_TYPE = [
    {
      id: 1,
      label: 'Student',
      value: 'Student',
    },
    {
      id: 2,
      label: 'Full time employee',
      value: 'Full time employee',
    },
    {
      id: 3,
      label: 'Part time employee',
      value: 'Part time employee',
    },
    {
      id: 4,
      label: 'Self Employed',
      value: 'Self Employed',
    },
    {
      id: 5,
      label: 'Other',
      value: 'Other',
    },
  ];

  useEffect(() => {
    if (type === 'educational' || type === 'smallE') {
      let x =
        type === 'smallE'
          ? 'enterprise'
          : type === 'educational'
          ? 'institute'
          : '';
      getTeamType(x);
    }
  }, [type]);

  const getTeamType = async (type) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/userType/${type}`)
      .then((response) => {
        if (response.data.statusCode === 200) {
          setGroupType(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err.response.data.message);
      });
  };

  const titleConfig = () => {
    switch (type) {
      case 'individual':
        return 'Sign up';
      case 'educational':
        return 'Educational Institute Sign Up';
      case 'smallE':
        return 'Small Enterprise Sign-Up';
      case 'largeE':
        return 'Large Enterprise Sign-Up';
      default:
    }
  };

  const handleInputChange = async (field = '', value = null) => {
    setState({
      ...state,
      [field]: field === 'contact' ? value || '' : value,
      error: {
        ...error,
        [field]:
          (field === 'name' && !isValidName(value) && 'Enter a valid name') ||
          (field === 'password' &&
            !isValidPassword(value) &&
            'Password should contain atleast 8 characters and containing uppercase, lowercase, special characters and numbers') ||
          (field === 'contact' &&
            (!contact || !isValidPhoneNumber(value || '')) &&
            'Enter a valid mobile phone number.'),
      },
    });
    if (field === 'password' && !isValidPassword(value)) {
      setPasswordError(
        'Password should contains atleast 8 characters and containing uppercase, lowercase, special characters and numbers'
      );
    } else {
      setPasswordError(null);
    }
  };

  const handleInputBlur = async (field = '', value = null) => {
    setState({
      ...state,
      error: {
        ...error,
        [field]:
          (field === 'email' &&
            isValidEmail(value) &&
            (await isEmailExist(value)) &&
            'Email already exist') ||
          (field === 'userName' &&
            (await isUserNameExist(value)) &&
            'User name already exist'),
      },
    });
  };

  const continueDisableHandler = () => {
    let isValidated =
      (type === 'individual' &&
        (!userName || !countryData || checkForSpacesInStartEnd(userName))) ||
      (type === 'educational' &&
        (!topic ||
          checkForSpacesInStartEnd(topic) ||
          !link ||
          checkForSpacesInStartEnd(link) ||
          Object.keys(organizationType).length === 0)) ||
      (type === 'smallE' &&
        (!link ||
          checkForSpacesInStartEnd(link) ||
          Object.keys(organizationType).length === 0)) ||
      (type === 'largeE' &&
        (!link ||
          checkForSpacesInStartEnd(link) ||
          !contact ||
          !isValidPhoneNumber(contact || ''))) ||
      (type !== 'largeE' &&
        (!isValidPassword(password) ||
          password !== confirmPassword ||
          !password ||
          !confirmPassword ||
          !userName ||
          checkForSpacesInStartEnd(userName))) ||
      !isValidName(name) ||
      checkForSpacesInStartEnd(name) ||
      !isValidEmail(email) ||
      error?.email ||
      error?.userName ||
      !name ||
      !email;
    return isValidated;
  };

  const verifyRecaptcha = async (responseToken) => {
    const res = await fnWrapper({
      url: `${VERIFY_GOOGLE_RECAPTCHA}`,
      method: 'POST',
      payload: {
        responseToken: responseToken,
      },
      type: 'VERIFY_GOOGLE_RECAPTCHA',
    });
    if (res.data.statusCode === 200) {
      return true;
    } else {
      return false;
    }
  };

  // captcha
  const handleCaptchaVerify = async (userResponseToken) => {
    const result = await verifyRecaptcha(userResponseToken);

    if (result) {
      setIsCaptchaVerified(true);
    } else {
      setIsCaptchaVerified(false);
    }
  };

  const disabled =
    (type === 'individual' && !isCaptchaVerified) || continueDisableHandler();

  const waitingListStatusCheck = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_WAITINGLIST_STATUS}`,
      method: 'GET',
      type: 'FETCH_WAITINGLIST_STATUS',
    });
    if (res.data.statusCode === 200) {
      const response = res.data.data;
      setWaitingListStatus(response.isWatingListEnabled);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    waitingListStatusCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onContinueButtonHandler = async () => {
    setIsLoading(true);
    let data = {};
    if (type === 'individual') {
      data = {
        name: name,
        userName: userName,
        country: countryData.isoCode,
        contact: contactNo,
        occupation: occupation.value,
        email: email,
        password: password,
        role: 'individual',
        isSocialUser: false,
      };
    } else if (type === 'educational') {
      data = {
        instituteName: name,
        userName: userName,
        email: email,
        password: password,
        link: link,
        instituteType: organizationType.title,
        title: topic,
        role: 'institute',
        isSocialUser: false,
      };
    } else if (type === 'smallE') {
      data = {
        enterpriseName: name,
        userName: userName,
        email: email,
        password: password,
        link: link,
        type: organizationType.id,
        title: topic,
        role: 'SMALL_ENTERPRISE',
        isSocialUser: false,
      };
    } else if (type === 'largeE') {
      data = {
        enterpriseName: name,
        email: email,
        contact: String(contact),
        link: link,
        role: 'LARGE_ENTERPRISE',
        isSocialUser: false,
      };
    }
    try {
      await axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/register`, data)
        .then((res) => {
          if (res.data.statusCode === 201) {
            localStorage.setItem(
              'signup-user-details',
              JSON.stringify(res.data.data)
            );
            navigate('/email-confirmation', {
              replace: true,
              state: location.state,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        });
      setIsLoading(false);
    } catch (error) {
      console.log('error', error);
      setIsLoading(false);
    }
  };

  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
    redirectUri: process.env.REACT_APP_LINKEDIN_REDIRECT_URL,
    scope: process.env.REACT_APP_LINKEDIN_SCOPE,
    onSuccess: async (code) => {
      handleLinkedinSuccess(code);
    },
    onError: (error) => {
      if (error.error === 'user_cancelled_authorize') {
        toast.error('You have cancelled the authorization', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    },
  });

  const handleLinkedinSuccess = async (code) => {
    const getUserDetails = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/getLinkedinCredentials?code=${code}`
    );
    if (getUserDetails.data.statusCode === 200) {
      const data = {
        name:
          getUserDetails.data.data.firstName +
          ' ' +
          getUserDetails.data.data.lastName,
        email: getUserDetails.data.data.email,
        role: 'individual',
        isSocialUser: true,
        signupType: 'linkedin',
        profilePicture: getUserDetails.data.data.profileImageURL,
      };
      try {
        await axios
          .post(`${process.env.REACT_APP_API_BASE_URL}/register`, data)
          .then((data) => {
            if (data.data.statusCode === 201) {
              localStorage.setItem(
                'signup-user-details',
                JSON.stringify(data.data.data)
              );
              navigate('/email-confirmation', {
                replace: true,
                state: location.state,
              });
            }
          })
          .catch((err) => {
            toast.error(err.response.data.message, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored',
            });
          });
      } catch (error) {
        console.log('error', error);
      }
    } else {
      alert('There is some issue while signing up');
    }
  };

  /* Google signup  handler */
  const onSignup = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const res = await axios.get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${tokenResponse.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`,
              Accept: 'application/json',
            },
          }
        );
        if (res.status === 200) {
          const data = {
            name: res.data.name,
            email: res.data.email,
            role: 'individual',
            isSocialUser: true,
            signupType: 'google',
          };

          await axios
            .post(`${process.env.REACT_APP_API_BASE_URL}/register`, data)
            .then((data) => {
              if (data.data.statusCode === 201) {
                localStorage.setItem(
                  'signup-user-details',
                  JSON.stringify(data.data.data)
                );
                navigate('/email-confirmation', {
                  replace: true,
                  state: location.state,
                });
              }
            })
            .catch((err) => {
              toast.error(err.response.data.message, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
              });
            });
        }
      } catch (err) {
        toast.error(err, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    },
    onError: (error) => alert(error),
  });

  return (
    <Box className="container-fluid">
      <div className="row sign-up-container">
        <div className=" col-lg-7 ">
          {/* new code */}
          <div className="container">
            <div className="row">
              <div className="col-md-3 "></div>
              <div className="col-md-8 ">
                <Box>
                  <Box className="sign-up-wrapper">
                    {/* logo */}
                    <Box mb="78px" mt="33px">
                      <img
                        src={process.env.PUBLIC_URL + '/new-logo.png'}
                        className="img common-navbar-logo"
                        alt="logo"
                      />
                    </Box>
                    {/* title and subtitle */}
                    <Box mb="40px">
                      {waitingListStatus &&
                      location.pathname === '/signup/individual' ? (
                        <Title title={'Join the waitlist'} />
                      ) : (
                        <Title title={titleConfig(type)} />
                      )}
                    </Box>
                    <h2>
                      {waitingListStatus &&
                      location.pathname === '/signup/individual' ? (
                        <span>Please fill out the info below</span>
                      ) : (
                        <span>Sign up with</span>
                      )}
                    </h2>
                    {/* signup types */}
                    {type === 'individual' && (
                      <IndividualUser
                        waitingListStatus={waitingListStatus}
                        handleInputChange={handleInputChange}
                        handleInputBlur={handleInputBlur}
                        state={state}
                        setState={setState}
                        onSignup={onSignup}
                        showLinkedinPopup={linkedInLogin}
                        passwordError={passwordError}
                        occupations={OCCUPATION_TYPE}
                        handleCaptchaVerify={handleCaptchaVerify}
                      />
                    )}
                    {type === 'educational' && (
                      <Educational
                        handleInputChange={handleInputChange}
                        handleInputBlur={handleInputBlur}
                        state={state}
                        setState={setState}
                        type={grouptype}
                        passwordError={passwordError}
                      />
                    )}
                    {type === 'smallE' && (
                      <SmallEnterprise
                        handleInputChange={handleInputChange}
                        handleInputBlur={handleInputBlur}
                        state={state}
                        setState={setState}
                        type={grouptype}
                        passwordError={passwordError}
                      />
                    )}
                    {type === 'largeE' && (
                      <LargeInterprise
                        handleInputChange={handleInputChange}
                        handleInputBlur={handleInputBlur}
                        state={state}
                        setState={setState}
                      />
                    )}

                    {/* continue and back btn */}
                    <Box
                      mt="66px"
                      mb="20px"
                      colGap="25px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center">
                      <CustomButton
                        variant="textBtn"
                        className="btn-text-color"
                        onClick={() =>
                          navigate('/signup/selectOrganization', {
                            replace: true,
                            state: location?.state,
                          })
                        }>
                        <Icon name="arrow-back" color="#404f67" size={20} />
                        Back
                      </CustomButton>

                      <CustomButton
                        label="Continue"
                        disabled={disabled}
                        onClick={onContinueButtonHandler}
                        loading={isLoading}
                        customPadding="16px 28px"
                      />
                    </Box>
                  </Box>
                </Box>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
          {/* new code end */}
        </div>
        <div className="col-lg-5  d-none d-sm-none d-lg-block image-wrapper ">
          {/* right side image */}
          <div className="right-img"></div>
        </div>
      </div>
    </Box>
  );
};

export default Signup;
