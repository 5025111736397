import { CustomButton, DropDown, Input, ModalPopup } from 'components';
import { ActionModalWrp } from './styles';

export const AssignToTeamModal = (props) => {
  const { assignToTeam, ctx, onAssignToTeam, onClose, onChange, teamList } =
    props;
  const isLoading =
    ctx.busy.status &&
    (ctx.busy.type === 'ASSIGN_TASK_TO_TEAM' ||
      ctx.busy.type === 'FETCH_TASK_BY_ID');
  const isChangeTeam = assignToTeam.type === 'CHANGE_TEAM';
  return (
    <div>
      <ModalPopup
        open={assignToTeam.isModalOpen}
        onClose={() => onClose('ASSIGN_TO_TEAM')}
        title={isChangeTeam ? 'Change team' : 'Assign to team'}>
        <ActionModalWrp className="amw-assign-team">
          {teamList?.length ? (
            <>
              {assignToTeam.type === 'CHANGE_TEAM' && (
                <Input
                  label="Current team"
                  type="text"
                  value={assignToTeam.selectedTeam?.teamName}
                  disabled
                />
              )}
              <DropDown
                label={'Select team'}
                placeholder="Select"
                objectKey="teamName"
                required
                value={assignToTeam.team}
                items={teamList}
                onClick={(value) => onChange('ASSIGN_TEAM', 'team', value)}
              />
              <div className="amw-assign-team__action">
                <CustomButton
                  disabled={isLoading}
                  label={'Close'}
                  onClick={() => onClose('ASSIGN_TO_TEAM')}
                  variant="textBtn"
                  color={'themeSecondary'}
                />
                <CustomButton
                  disabled={isLoading}
                  label={'Assign'}
                  loading={isLoading}
                  onClick={onAssignToTeam}
                  color={'themeSecondary'}
                />
              </div>
            </>
          ) : (
            <>
              <div>
                No teams available on this department. Please create a new team
                on this department.
              </div>
              <CustomButton
                disabled={isLoading}
                label={'Close'}
                onClick={() => onClose('ASSIGN_TO_TEAM')}
                color={'themeSecondary'}
              />
            </>
          )}
        </ActionModalWrp>
      </ModalPopup>
    </div>
  );
};
