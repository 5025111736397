import { toast } from 'react-toastify';
import Icon from 'utlils/Icons/Icon';
import {
  CustomCheckBox,
  CustomFileUploadButton,
  Input,
  PriceInput,
  PriceRange,
} from 'components';
import RadioGroup from 'pages/IndividualDashboard/components/radioGroup';
import {
  AttachmentsTypes,
  VideoAttachmentsTypes,
} from 'pages/IndividualDashboard/constants';
import { checkForFileType } from 'pages/IndividualDashboard/individualOrganizations/enterprise/helper';
import {
  biddingFixedRates,
  biddingPaymentForSubscription,
  biddingPaymentTypes,
  biddingRange,
} from 'pages/Projects/ViewProject/ProjectCollaboration/constant';

export const CommonFieldSection = (props) => {
  const { onChangeHandler, state, setCreateBiddingState, isDisabled } = props;

  const questionOnChangeHandler = (index, value) => {
    let temporaryQuesArray = state.questions;
    temporaryQuesArray[index].question = value;
    setCreateBiddingState({
      ...state,
      questions: temporaryQuesArray,
    });
  };

  const onPriceChangeHandler = (fieldName, value) => {
    if (fieldName === 'fixedRate') {
      setCreateBiddingState((prevState) => ({
        ...prevState,
        [fieldName]: value,
      }));
    } else {
      setCreateBiddingState((prevState) => ({
        ...prevState,
        [fieldName]: value,
      }));
    }
  };

  const onAttachmentsHandler = async (type, files, isMandatory) => {
    let isCorrectFileType;
    if (type === 'videoAttachment') {
      isCorrectFileType = [...files].reduce(
        (prev, curr) => prev && VideoAttachmentsTypes.includes(curr.type),
        true
      );
      // kept file size to 10MB
      if (files[0].size > 10e6) {
        toast.error('File size cannot be greater than 10MB.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        return false;
      }
    } else {
      isCorrectFileType = [...files].reduce(
        (prev, curr) => prev && AttachmentsTypes.includes(curr.type),
        true
      );
    }

    if (isCorrectFileType) {
      if (type === 'bidderForms') {
        setCreateBiddingState((prevState) => ({
          ...prevState,
          [type]: [
            ...state[type],
            { files: files[0], isMandatory: isMandatory },
          ],
        }));
      } else {
        setCreateBiddingState((prevState) => ({
          ...prevState,
          [type]: [...state[type], ...files],
        }));
      }
    } else {
      toast.error('Type of File/Files uploaded are not supported.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  const onRemoveAttachmentsHandler = async (type, file) => {
    setCreateBiddingState((prevState) => ({
      ...prevState,
      [type]: state[type].filter((e) =>
        type === 'bidderForms' ? e?.files !== file : e !== file
      ),
    }));
  };

  return (
    <div>
      <div className="publish-checkbox mb-text">
        <div className="sub-heading">
          {state?.type === 'PROJECT_COPY'
            ? '4.'
            : state?.type === 'PROJECT_SERVICE_SUBSCRIPTION'
            ? '6.'
            : '3.'}{' '}
          Payment type<span style={{ color: 'red' }}>*</span>
          <span className="icon-mw">
            <Icon name="info-circle" color={'#404F67'} />
          </span>
        </div>
        <div className="radio-btn-label mt-3">
          <RadioGroup
            labels={
              state?.type === 'PROJECT_SERVICE_SUBSCRIPTION'
                ? biddingPaymentForSubscription
                : biddingPaymentTypes
            }
            currChecked={state?.paymentType}
            customClassName={'radio-payment'}
            handleChange={(e) => onChangeHandler('paymentType', e.target.value)}
            disabled={isDisabled}
          />
        </div>
      </div>
      <div className="publish-checkbox mb-text">
        <div className="question-wrapper__form__askQuestion">
          <div className="sub-heading mb-3">
            {state?.type === 'PROJECT_COPY'
              ? '5.'
              : state?.type === 'PROJECT_SERVICE_SUBSCRIPTION'
              ? '7.'
              : '4.'}{' '}
            Ask questions to bidders
          </div>
          {state?.questions.map((ques, index) => (
            <div
              className="d-flex align-items-center gap-3 input-content"
              key={`question${index + 1}`}>
              <Input
                value={ques.question}
                onChange={(e) => questionOnChangeHandler(index, e.target.value)}
                required={state?.questions.length > 1}
                disabled={isDisabled}
              />
              {!isDisabled && (
                <>
                  {index === 0 ? (
                    <Icon
                      name="add"
                      className="question-wrapper__form__askQuestion__icon"
                      color="#0ABD00"
                      onClick={() =>
                        setCreateBiddingState((prevState) => ({
                          ...prevState,
                          questions: [...prevState.questions, { question: '' }],
                        }))
                      }
                    />
                  ) : (
                    <Icon
                      name="delete"
                      color="red"
                      className="question-wrapper__form__askQuestion__icon"
                      onClick={() =>
                        setCreateBiddingState({
                          ...state,
                          questions: state.questions.filter(
                            (val) => val !== ques
                          ),
                        })
                      }
                    />
                  )}
                </>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="publish-checkbox">
        <div className="sub-heading">
          {state?.type === 'PROJECT_COPY'
            ? '6.'
            : state?.type === 'PROJECT_SERVICE_SUBSCRIPTION'
            ? '8.'
            : '5.'}{' '}
          Select bidding rate<span style={{ color: 'red' }}>*</span>
          <span className="icon-mw">
            <Icon name="info-circle" color={'#404F67'} />
          </span>
        </div>
        <div className="radio-btn-label mt-3">
          <RadioGroup
            labels={biddingFixedRates}
            currChecked={state?.biddingRateType}
            customClassName={'radio-payment'}
            handleChange={(e) =>
              onChangeHandler('biddingRateType', e.target.value)
            }
            name="range"
            disabled={isDisabled}
          />
          {state?.biddingRateType === 'Fixed rate' && (
            <div className="publish-checkbox">
              <PriceInput
                value={state?.fixedRate?.value}
                selectedCurrency={state.fixedRate.currency}
                onPriceChange={(e) => onPriceChangeHandler('fixedRate', e)}
                disabled={isDisabled}
              />
            </div>
          )}
        </div>
        <div className="radio-btn-label mt-3">
          <RadioGroup
            labels={biddingRange}
            currChecked={state?.biddingRateType}
            customClassName={'radio-payment'}
            handleChange={(e) =>
              onChangeHandler('biddingRateType', e.target.value)
            }
            name="range"
            disabled={isDisabled}
          />
          {state?.biddingRateType === 'Bidding range' && (
            <div className="publish-checkbox">
              <PriceRange
                labelRequired={false}
                onRangeChange={(e) => onPriceChangeHandler('rangeRate', e)}
                range={state.rangeRate}
                disabled={isDisabled}
              />
            </div>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-between mb-text mb-3">
        <div className="sub-heading">
          {state?.type === 'PROJECT_COPY'
            ? '7.'
            : state?.type === 'PROJECT_SERVICE_SUBSCRIPTION'
            ? '9.'
            : '6.'}{' '}
          Select bidding attach video of demo (if any)
        </div>
        <div className="popup-attachment">
          <CustomFileUploadButton
            borderWidth="0px 0px 1px 0px"
            borderStyle="solid"
            borderColor="#219FFF"
            background="none"
            fontFamily="'Poppins', 'Open Sans'"
            fontSize="15px"
            fontWeight="500"
            textColor="#219FFF"
            handleFile={(file) => onAttachmentsHandler('videoAttachment', file)}
            label="Add"
            iconColor="#219FFF"
            iconName="add"
            iconSize="18"
            isMultiple={true}
            disabled={isDisabled}
          />
        </div>
      </div>
      <div className="post-attachment">
        {state?.videoAttachment?.length !== 0
          ? state?.videoAttachment?.map((file, index) => {
              const filePath = typeof file === 'string' ? file : file.name;
              const fileName =
                typeof file === 'string' ? file.split('/').at(-1) : file.name;
              return (
                <div className="img-wrapper" key={index}>
                  <div className="img-container">
                    {checkForFileType(filePath) === 'others' ? (
                      <Icon name="attachment" />
                    ) : (
                      <img
                        src={checkForFileType(filePath)}
                        alt="videoAttachment"
                      />
                    )}
                  </div>
                  <span className="img-text">{fileName}</span>
                  <span
                    className={
                      isDisabled ? 'remove-icon disable-content' : 'remove-icon'
                    }
                    onClick={() =>
                      onRemoveAttachmentsHandler('videoAttachment', file, false)
                    }>
                    <Icon color="rgba(146, 157, 175, 1)" name="small-close" />
                  </span>
                </div>
              );
            })
          : null}
      </div>
      <div className="d-flex justify-content-between mb-text mb-3">
        <div className="sub-heading">
          {state?.type === 'PROJECT_COPY'
            ? '8.'
            : state?.type === 'PROJECT_SERVICE_SUBSCRIPTION'
            ? '10.'
            : '7.'}{' '}
          Bidding rules and documents
        </div>
        <div className="popup-attachment">
          <CustomFileUploadButton
            borderWidth="0px 0px 1px 0px"
            borderStyle="solid"
            borderColor="#219FFF"
            background="none"
            fontFamily="'Poppins', 'Open Sans'"
            fontSize="15px"
            fontWeight="500"
            textColor="#219FFF"
            handleFile={(file) =>
              onAttachmentsHandler('documents', file, false)
            }
            label="Add"
            iconColor="#219FFF"
            iconName="add"
            iconSize="18"
            isMultiple={true}
            disabled={isDisabled}
          />
        </div>
      </div>
      <div className="post-attachment">
        {state?.documents?.length !== 0
          ? state?.documents?.map((file, index) => {
              const filePath = typeof file === 'string' ? file : file.name;
              const fileName =
                typeof file === 'string' ? file.split('/').at(-1) : file.name;
              return (
                <div className="img-wrapper" key={index}>
                  <div className="img-container">
                    {checkForFileType(filePath) === 'others' ? (
                      <Icon name="attachment" />
                    ) : (
                      <img src={checkForFileType(filePath)} alt="attachment" />
                    )}
                  </div>
                  <span className="img-text">{fileName}</span>
                  <span
                    className={
                      isDisabled ? 'remove-icon disable-content' : 'remove-icon'
                    }
                    onClick={() =>
                      onRemoveAttachmentsHandler('documents', file)
                    }>
                    <Icon color="rgba(146, 157, 175, 1)" name="small-close" />
                  </span>
                </div>
              );
            })
          : null}
      </div>
      <div className="d-flex justify-content-between mb-text mb-3">
        <div className="sub-heading">
          {state?.type === 'PROJECT_COPY'
            ? '9.'
            : state?.type === 'PROJECT_SERVICE_SUBSCRIPTION'
            ? '11.'
            : '8.'}{' '}
          Upload documents to be signed by the bidder
        </div>
        <div className="popup-attachment">
          <CustomFileUploadButton
            borderWidth="0px 0px 1px 0px"
            borderStyle="solid"
            borderColor="#219FFF"
            background="none"
            fontFamily="'Poppins', 'Open Sans'"
            fontSize="15px"
            fontWeight="500"
            textColor="#219FFF"
            handleFile={(file) =>
              onAttachmentsHandler('bidderForms', file, true)
            }
            label="Add"
            iconColor="#219FFF"
            iconName="add"
            iconSize="18"
            isMultiple={true}
            disabled={isDisabled}
          />
        </div>
      </div>
      <div className="post-attachment">
        {state?.bidderForms?.length !== 0 ? (
          <>
            <div className="mandatory-field">Mandatory to fill</div>
            {state?.bidderForms.map((item, index) => {
              const filePath =
                typeof item.files === 'string' ? item.files : item.files.name;
              const fileName =
                typeof item.files === 'string'
                  ? item.files.split('/').at(-1)
                  : item.files.name;
              return (
                <div className="d-flex justify-content-between" key={index}>
                  <div className="img-wrapper">
                    <div className="img-container">
                      {checkForFileType(filePath) === 'others' ? (
                        <Icon name="attachment" />
                      ) : (
                        <img
                          src={checkForFileType(filePath)}
                          alt="attachment"
                        />
                      )}
                    </div>
                    <span className="img-text">{fileName}</span>
                    <span
                      className={
                        isDisabled
                          ? 'remove-icon disable-content'
                          : 'remove-icon'
                      }
                      onClick={() =>
                        onRemoveAttachmentsHandler('bidderForms', item?.files)
                      }>
                      <Icon color="rgba(146, 157, 175, 1)" name="small-close" />
                    </span>
                  </div>
                  <div>
                    <CustomCheckBox
                      tickColor="#0ABD00"
                      check={item?.isMandatory}
                      onCheckBg="white"
                      checkBorder="1.5px solid #0ABD00"
                      onClick={() => {
                        let prevItems = state.bidderForms;
                        const objIndex = prevItems.findIndex(
                          (item, i) => i === index
                        );
                        const newValue = {
                          ...prevItems[objIndex],
                          isMandatory: !item?.isMandatory,
                        };
                        prevItems[objIndex] = newValue;
                        setCreateBiddingState((prevState) => ({
                          ...prevState,
                          bidderForms: prevItems,
                        }));
                      }}
                      disabled={isDisabled}
                    />
                  </div>
                </div>
              );
            })}
          </>
        ) : null}
      </div>
    </div>
  );
};
