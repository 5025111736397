import styled from 'styled-components';

export const DetailsWrapper = styled.div`
  .customBtnStyleDetails {
    width: 109px !important;
  }

  .create-container {
    margin: 25px 0;
    display: flex;
    flex-direction: column;
    width: 100%;

    & .file-upload {
      margin-bottom: 15px;

      & .text-style {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        margin-right: 40px;
      }
    }

    & .post-attachment {
      padding: 30px 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px 20px;

      & .img-wrapper {
        display: flex;
        width: 100%;
        word-break: break-all;

        & span {
          color: #929daf;
        }

        & .img-container {
          width: 30px;
          height: 30px;
          margin-top: 10px;
        }

        & .img-text {
          margin-top: 10px;
          text-decoration: underline;
        }

        & .remove-icon {
          padding-left: 2px;
          cursor: pointer;
          align-self: center;
        }
      }

      @media (max-width: 767px) {
        grid-template-columns: 1fr;
        gap: 5px;
      }
    }

    & .image-upload {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      & span {
        padding-bottom: 20px;
      }
    }

    & .project-buttons {
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 576px) {
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        height: 20vh;
      }
    }
  }
  .draft-popup-container {
    position: relative;
    padding: 20px 0;
    .close-cross {
      position: absolute;
      top: 15px;
      right: 25px;
      &:hover {
        cursor: pointer;
      }
    }
    .draft-buttons {
      width: 50%;
      @media (max-width: 1199px) {
        width: 60%;
      }
      @media (max-width: 992px) {
        width: 70%;
      }
      @media (max-width: 576px) {
        gap: 15px;
      }
    }
  }
`;
