import styled from 'styled-components';

export const ChangePasswordWrapper = styled.div`
  & .heading {
    color: #17191d;
    font-size: 25px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;
  }

  & .link {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;

    color: #0abd00 !important;
  }

  & .change-password-main-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 30px 0;

    & .input-box-container {
      margin-top: 20px;
    }

    & .button-container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
      margin-top: 30px;
      width: 222px;
      height: 55px;
    }
  }
`;
