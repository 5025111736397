import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import Icon from 'utlils/Icons/Icon';
import {
  CustomButton,
  CustomCheckBox,
  CustomFileUploadButton,
  Input,
  ModalPopup,
  ScrollableDiv,
  Textarea,
} from 'components';
import RadioGroup from 'pages/IndividualDashboard/components/radioGroup';
import { AttachmentsTypes } from 'pages/IndividualDashboard/constants';
import { checkForFileType } from 'pages/IndividualDashboard/individualOrganizations/enterprise/helper';
import {
  collaborationCategory,
  collaborationGoal,
  collaborationType,
} from '../constant';
import {
  CREATE_PROJECT_COLLABORATION,
  UPDATE_PROJECT_COLLABORATION,
  useAxiosWrapper,
} from 'services';
import { arrayKeyValueReducer } from 'pages/TeamDashboard/TeamCareer/helper';

export const CreateCollaboration = (props) => {
  const {
    setIsCollaborationSuccess,
    collaborationActionState,
    projectId,
    setId,
    onClose,
    getCollaborationProjectsList,
  } = props;
  const [fnWrapper, context] = useAxiosWrapper();

  const { type, data } = collaborationActionState;
  const isUpdate = type === 'EDIT-COLLABORATION';
  const questionData =
    isUpdate && data?.question ? JSON.parse(data?.question) : null;
  const questionArray = questionData
    ? Object.values(questionData).map((item) => ({ question: item }))
    : [
        {
          question: '',
        },
      ];

  const collaborationForms =
    isUpdate && data?.collaboratorForms?.length
      ? data?.collaboratorForms?.map((item) => {
          const parsedItem = JSON.parse(item);
          return {
            files: parsedItem?.location,
            isMandatory: parsedItem?.isMandatory,
          };
        })
      : [];

  const [createCollaborationState, setCreateCollaborationState] = useState({
    description: isUpdate ? data?.collaborationDescription : '',
    offerings: isUpdate ? data?.collaborationOfferings : '',
    documents: isUpdate && data?.documents?.length ? data?.documents : [],
    type: isUpdate ? data?.collaborationType : null,
    category: isUpdate ? data?.collaborationCategory : null,
    goal: isUpdate ? data?.collaborationGoal : null,
    requirements: isUpdate ? data?.collaborationRequirements : '',
    questions: questionArray,
    forms: collaborationForms,
  });
  const [showConfirm, setShowConfirm] = useState(false);

  const questionOnChangeHandler = (index, value) => {
    let temporaryQuesArray = createCollaborationState.questions;
    temporaryQuesArray[index].question = value;
    setCreateCollaborationState({
      ...createCollaborationState,
      questions: temporaryQuesArray,
    });
  };

  const onAttachmentsHandler = async (type, files, isMandatory) => {
    const isCorrectFileType = [...files].reduce(
      (prev, curr) => prev && AttachmentsTypes.includes(curr.type),
      true
    );
    if (isCorrectFileType) {
      if (type === 'forms') {
        setCreateCollaborationState((prevState) => ({
          ...prevState,
          [type]: [
            ...createCollaborationState[type],
            { files: files[0], isMandatory: isMandatory },
          ],
        }));
      } else {
        setCreateCollaborationState((prevState) => ({
          ...prevState,
          [type]: [...createCollaborationState[type], ...files],
        }));
      }
    } else {
      toast.error('Type of File/Files uploaded are not supported.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  const onRemoveAttachmentsHandler = async (type, file) => {
    setCreateCollaborationState((prevState) => ({
      ...prevState,
      [type]: createCollaborationState[type].filter((e) =>
        type === 'forms' ? e?.files !== file : e !== file
      ),
    }));
  };

  const onChangeHandler = (fieldName, value) => {
    setCreateCollaborationState((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const onCreateCollaboration = useCallback(async () => {
    const questionArray =
      createCollaborationState.questions[0].question !== ''
        ? arrayKeyValueReducer(createCollaborationState.questions, 'question')
        : null;

    const collaboratorFormsData =
      createCollaborationState.forms.length !== 0
        ? createCollaborationState.forms
            .filter((item) => typeof item.files === 'object')
            .map((item) => ({
              fileName: item?.files?.name,
              isMandatory: item?.isMandatory,
            }))
        : [];

    let formData = new FormData();
    formData.append(
      'collaborationDescription',
      createCollaborationState.description
    );
    formData.append(
      'collaborationOfferings',
      createCollaborationState.offerings
    );
    formData.append('collaborationType', createCollaborationState.type);
    formData.append('collaborationCategory', createCollaborationState.category);
    formData.append('collaborationGoal', createCollaborationState.goal);
    formData.append(
      'collaborationRequirements',
      createCollaborationState.requirements
    );
    formData.append('question', questionArray);
    formData.append('projectId', projectId);
    formData.append('isOpen', isUpdate ? data?.isOpen : true);

    if (isUpdate) {
      const linkCollaboratorForms = createCollaborationState.forms?.filter(
        (item) => typeof item?.files === 'string'
      );
      const linkDocuments = createCollaborationState.documents?.filter(
        (item) => typeof item === 'string'
      );
      formData.append('collaborationId', data?.id);
      for (let i of createCollaborationState.documents) {
        if (typeof i === 'object') {
          formData.append('documents', i);
        }
      }
      formData.append('linkDocuments', JSON.stringify(linkDocuments));
      formData.append(
        'collaboratorFormsData',
        JSON.stringify(collaboratorFormsData)
      );
      for (let i of createCollaborationState.forms) {
        if (typeof i?.files === 'object') {
          formData.append('collaboratorForms', i?.files);
        }
      }
      formData.append(
        'linkCollaboratorForms',
        JSON.stringify(linkCollaboratorForms)
      );
      const res = await fnWrapper({
        url: UPDATE_PROJECT_COLLABORATION,
        method: 'PUT',
        type: 'UPDATE_PROJECT_COLLABORATION',
        payload: formData,
      });

      if (res.data.statusCode === 200) {
        await getCollaborationProjectsList();
        setShowConfirm(false);
        await onClose();
        toast.success(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } else {
      for (let i of createCollaborationState.documents) {
        formData.append('documents', i);
      }
      formData.append(
        'collaboratorFormsData',
        JSON.stringify(collaboratorFormsData)
      );
      for (let i of createCollaborationState.forms) {
        formData.append('collaboratorForms', i?.files);
      }
      const res = await fnWrapper({
        url: CREATE_PROJECT_COLLABORATION,
        method: 'POST',
        type: 'CREATE_PROJECT_COLLABORATION',
        payload: formData,
      });

      if (res.data.statusCode === 200) {
        await getCollaborationProjectsList();
        await onClose();
        toast.success(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
      setId(res.data.data.id);
    }
    setIsCollaborationSuccess(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createCollaborationState.category,
    createCollaborationState.description,
    createCollaborationState.documents,
    createCollaborationState.forms,
    createCollaborationState.goal,
    createCollaborationState.offerings,
    createCollaborationState.questions,
    createCollaborationState.requirements,
    createCollaborationState.type,
    data?.id,
    isUpdate,
    onClose,
    projectId,
  ]);

  const onUpdateCollaboration = () => {
    setShowConfirm(true);
  };
  const isDisabled =
    !createCollaborationState.description ||
    !createCollaborationState.offerings ||
    !createCollaborationState.requirements ||
    !createCollaborationState.goal ||
    !createCollaborationState.type ||
    !createCollaborationState.documents.length ||
    !createCollaborationState.forms.length;

  return (
    <div className="publish_wrp">
      <ScrollableDiv
        scrollOnHeight="calc(100vh - 210px)"
        scrollOnHeightMob="300px">
        <div className="publish-checkbox">
          <div className="sub-heading">
            1. Describe the collaboration{' '}
            <span style={{ color: 'red' }}>*</span>
          </div>
          <div className="pb-textarea">
            <Textarea
              rows={4}
              value={createCollaborationState?.description}
              onChange={(e) => onChangeHandler('description', e.target.value)}
            />
          </div>
        </div>
        <div className="publish-checkbox">
          <div className="sub-heading mt-3">
            2. Offerings of this collaboration{' '}
            <span style={{ color: 'red' }}>*</span>
          </div>
          <div className="pb-textarea">
            <Textarea
              rows={4}
              value={createCollaborationState?.offerings}
              onChange={(e) => onChangeHandler('offerings', e.target.value)}
            />
          </div>
        </div>
        <div className="d-flex justify-content-between mt-3 mb-3">
          <div className="sub-heading">
            3. Collaboration rules and documents{' '}
            <span style={{ color: 'red' }}>*</span>
          </div>
          <div className="popup-attachment">
            <CustomFileUploadButton
              borderWidth="0px 0px 1px 0px"
              borderStyle="solid"
              borderColor="#219FFF"
              background="none"
              fontFamily="'Poppins', 'Open Sans'"
              fontSize="15px"
              fontWeight="500"
              textColor="#219FFF"
              handleFile={(file) =>
                onAttachmentsHandler('documents', file, false)
              }
              label="Add"
              iconColor="#219FFF"
              iconName="add"
              iconSize="18"
              isMultiple={true}
            />
          </div>
        </div>
        <div className="post-attachment">
          {createCollaborationState?.documents?.length !== 0
            ? createCollaborationState?.documents?.map((file, index) => {
                const filePath = typeof file === 'string' ? file : file.name;
                const fileName =
                  typeof file === 'string' ? file.split('/').at(-1) : file.name;
                return (
                  <div className="img-wrapper" key={index}>
                    <div className="img-container">
                      {checkForFileType(filePath) === 'others' ? (
                        <Icon name="attachment" />
                      ) : (
                        <img
                          src={checkForFileType(filePath)}
                          alt="attachment"
                        />
                      )}
                    </div>
                    <span className="img-text">{fileName}</span>
                    <span
                      className="remove-icon"
                      onClick={() =>
                        onRemoveAttachmentsHandler('documents', file)
                      }>
                      <Icon color="rgba(146, 157, 175, 1)" name="small-close" />
                    </span>
                  </div>
                );
              })
            : null}
        </div>
        <div className="publish-checkbox mt-5">
          <div className="sub-heading">
            4. Type of collaboration <span style={{ color: 'red' }}>*</span>
          </div>
          <div className="radio-btn-label">
            <RadioGroup
              labels={collaborationType}
              currChecked={createCollaborationState?.type}
              handleChange={(e) => onChangeHandler('type', e.target.value)}
            />
          </div>
        </div>
        <div className="publish-checkbox mt-5">
          <div className="sub-heading">5. Category of collaboration</div>
          <div className="radio-btn-label">
            <RadioGroup
              labels={collaborationCategory}
              currChecked={createCollaborationState?.category}
              handleChange={(e) => onChangeHandler('category', e.target.value)}
            />
          </div>
        </div>
        <div className="publish-checkbox mt-5">
          <div className="sub-heading">
            6. Goal of collaboration <span style={{ color: 'red' }}>*</span>
          </div>
          <div className="radio-btn-label">
            <RadioGroup
              labels={collaborationGoal}
              currChecked={createCollaborationState?.goal}
              handleChange={(e) => onChangeHandler('goal', e.target.value)}
            />
          </div>
        </div>
        <div className="publish-checkbox mt-5">
          <div className="sub-heading">
            7. Collaboration requirement <span style={{ color: 'red' }}>*</span>
          </div>
          <div className="pb-textarea">
            <Textarea
              rows={4}
              value={createCollaborationState?.requirements}
              onChange={(e) => onChangeHandler('requirements', e.target.value)}
            />
          </div>
        </div>
        <div className="publish-checkbox mt-5">
          <div className="question-wrapper__form__askQuestion">
            <div className="sub-heading mb-3">
              8. Ask questions to collaborators
            </div>
            {createCollaborationState?.questions.map((ques, index) => (
              <div
                className="d-flex align-items-center gap-3"
                key={`question${index + 1}`}>
                <Input
                  value={ques.question}
                  onChange={(e) =>
                    questionOnChangeHandler(index, e.target.value)
                  }
                  required={createCollaborationState?.questions.length > 1}
                />
                {index === 0 ? (
                  <Icon
                    name="add"
                    className="question-wrapper__form__askQuestion__icon"
                    color="#0ABD00"
                    onClick={() =>
                      setCreateCollaborationState((prevState) => ({
                        ...prevState,
                        questions: [...prevState.questions, { question: '' }],
                      }))
                    }
                  />
                ) : (
                  <Icon
                    name="delete"
                    color="red"
                    className="question-wrapper__form__askQuestion__icon"
                    onClick={() =>
                      setCreateCollaborationState({
                        ...createCollaborationState,
                        questions: createCollaborationState.questions.filter(
                          (val) => val !== ques
                        ),
                      })
                    }
                  />
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="d-flex justify-content-between mt-3 mb-3">
          <div className="sub-heading">
            9. Add forms/NDA which needs to be signed by collaborator{' '}
            <span style={{ color: 'red' }}>*</span>
          </div>
          <div className="popup-attachment">
            <CustomFileUploadButton
              borderWidth="0px 0px 1px 0px"
              borderStyle="solid"
              borderColor="#219FFF"
              background="none"
              fontFamily="'Poppins', 'Open Sans'"
              fontSize="15px"
              fontWeight="500"
              textColor="#219FFF"
              handleFile={(file) => onAttachmentsHandler('forms', file, true)}
              label="Add"
              iconColor="#219FFF"
              iconName="add"
              iconSize="18"
              isMultiple={true}
            />
          </div>
        </div>
        <div className="post-attachment">
          {createCollaborationState?.forms?.length !== 0 ? (
            <>
              <div className="mandatory-field">Mandatory to fill</div>
              {createCollaborationState?.forms.map((item, index) => {
                const filePath =
                  typeof item.files === 'string' ? item.files : item.files.name;
                const fileName =
                  typeof item.files === 'string'
                    ? item.files.split('/').at(-1)
                    : item.files.name;
                return (
                  <div className="d-flex justify-content-between" key={index}>
                    <div className="img-wrapper">
                      <div className="img-container">
                        {checkForFileType(filePath) === 'others' ? (
                          <Icon name="attachment" />
                        ) : (
                          <img
                            src={checkForFileType(filePath)}
                            alt="attachment"
                          />
                        )}
                      </div>
                      <span className="img-text">{fileName}</span>
                      <span
                        className="remove-icon"
                        onClick={() =>
                          onRemoveAttachmentsHandler('forms', item?.files)
                        }>
                        <Icon
                          color="rgba(146, 157, 175, 1)"
                          name="small-close"
                        />
                      </span>
                    </div>
                    <div>
                      <CustomCheckBox
                        tickColor="#0ABD00"
                        check={item?.isMandatory}
                        onCheckBg="white"
                        checkBorder="1.5px solid #0ABD00"
                        onClick={() => {
                          let prevItems = createCollaborationState.forms;
                          const objIndex = prevItems.findIndex(
                            (item, i) => i === index
                          );
                          const newValue = {
                            ...prevItems[objIndex],
                            isMandatory: !item?.isMandatory,
                          };
                          prevItems[objIndex] = newValue;
                          setCreateCollaborationState((prevState) => ({
                            ...prevState,
                            forms: prevItems,
                          }));
                        }}
                      />
                    </div>
                  </div>
                );
              })}
            </>
          ) : null}
        </div>
        <div className="popup-button">
          <CustomButton
            className="w-100"
            label="Save"
            color="themeSecondary"
            loading={
              context.busy.status &&
              context.busy.type === 'CREATE_PROJECT_COLLABORATION'
            }
            onClick={isUpdate ? onUpdateCollaboration : onCreateCollaboration}
            disabled={isDisabled}
          />
        </div>
      </ScrollableDiv>
      {showConfirm && (
        <ModalPopup
          open={false}
          onClose={() => setShowConfirm(false)}
          minWidth="800px"
          maxContentWidth="800px"
          hasTitle
          title="Edit Collaboration">
          <div className="edit-wrapper">
            <p className="edit-wrapper__text text-center mt-4">
              Do you want to save these changes?
            </p>
            <div className="edit-wrapper__confirm-content d-flex justify-content-center">
              <CustomButton
                label="No"
                variant="textBtn"
                onClick={() => setShowConfirm(false)}
              />
              <div className="edit-wrapper__yes-btn">
                <CustomButton
                  className="w-100"
                  label="Yes"
                  onClick={onCreateCollaboration}
                />
              </div>
            </div>
          </div>
        </ModalPopup>
      )}
    </div>
  );
};
