import { TeamProjectsListWrapper } from './style';
import { useState, useCallback, useEffect } from 'react';
import cx from 'classname';
import {
  SearchField,
  CustomButton,
  CustomTable,
  ScrollableDiv,
} from 'components';
import { ProjectsFilter } from 'pages/TeamDashboard/components';
import {
  TEAM_PROJECTS_TABLE_HEADERS,
  teamProjectsTableData,
} from '../../helper';
import { getPlaceholderItems } from 'utlils/utils';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { FETCH_PROJECTS_BASED_ON_TEAM, useAxiosWrapper } from 'services';
import Icon from 'utlils/Icons/Icon';

export const TeamProjectsList = () => {
  const [fnWrapper, context] = useAxiosWrapper();
  const [searchProject, setSearchProject] = useState('');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [teamProjectsData, setTeamProjectsData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState({
    filteredAdminName: '',
    filteredDate: {},
    filteredStatus: {},
    fromDate: null,
    toDate: null,
  });
  const { teamId, userType } = useParams();

  const getProjectsList = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_PROJECTS_BASED_ON_TEAM}/${teamId}`,
      method: 'GET',
      type: 'FETCH_PROJECTS_LIST_BASED_ON_TEAM',
    });

    if (res.data.statusCode === 200) {
      const projectsList = [];
      res.data.data.map((item) => {
        const newProjectData = {
          id: item.projects?.id,
          projectName: item.projects?.title,
          admin:
            item.projects?.user?.individual !== null
              ? item.projects?.user?.individual?.name
              : item.projects?.user?.smallEnterprise?.enterpriseName,
          status: item.projects?.projectStatus,
          addedOn: item.projects?.createdAt,
        };
        projectsList.push(newProjectData);
        return projectsList;
      });
      setTeamProjectsData(projectsList);
      setFilteredData(projectsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchProjectByNameHandler = (value) => {
    setSearchProject(value);
    let temp = [];
    if (value) {
      temp = teamProjectsData.filter(
        (ele) => ele.projectName.toLowerCase().indexOf(value.toLowerCase()) > -1
      );
      setFilteredData(temp);
    } else {
      setFilteredData(teamProjectsData);
    }
  };
  const closeSearchProjectByName = () => {
    setSearchProject('');
    setFilteredData(teamProjectsData);
  };

  const onRowCheckedHandler = (element) => {
    if (selectedRow.length) {
      const index = selectedRow.filter((ele) => ele.id === element.id);
      if (index.length) {
        const filteredItems = selectedRow.filter(
          (ele) => ele.id !== element.id
        );
        setSelectedRow(filteredItems);
      } else {
        setSelectedRow([...selectedRow, element]);
      }
    } else {
      setSelectedRow([...selectedRow, element]);
    }
  };

  const onAllRowCheckedHandler = (element) => {
    setSelectedRow(element);
  };

  const projectFilterSaveHandler = (type) => {
    if (type === 'apply' && teamProjectsData.length !== 0) {
      setSearchProject('');
      let result = teamProjectsData;

      let filterByAdminName = (item) => {
        if (filteredProjects.filteredAdminName === '') {
          return item;
        } else {
          return item.filter(
            (ele) =>
              ele.admin.toLowerCase() ===
              filteredProjects.filteredAdminName.toLowerCase()
          );
        }
      };

      let filterByStatus = (item) => {
        if (Object.keys(filteredProjects.filteredStatus).length === 0) {
          return item;
        } else {
          return item.filter(
            (ele) =>
              ele.status.toLowerCase() ===
              filteredProjects.filteredStatus.label.toLowerCase()
          );
        }
      };
      let filterByDate = (item) => {
        if (Object.keys(filteredProjects.filteredDate).length === 0) {
          return item;
        }
        if (filteredProjects.filteredDate.value === 'YD') {
          return item.filter(
            (ele) =>
              dayjs(ele.addedOn).format('DD MMM YYYY') ===
              dayjs(new Date()).subtract(1, 'day').format('DD MMM YYYY')
          );
        }
        if (filteredProjects.filteredDate.value === 'TD') {
          return item.filter(
            (ele) =>
              dayjs(ele.addedOn).format('DD MMM YYYY') ===
              dayjs(new Date()).format('DD MMM YYYY')
          );
        }
        if (filteredProjects.filteredDate.value === 'CD') {
          return item.filter(
            (ele) =>
              new Date(dayjs(ele.addedOn).format('DD MMM YYYY')) >=
                new Date(
                  dayjs(filteredProjects.fromDate).format('DD MMM YYYY')
                ) &&
              new Date(dayjs(ele.addedOn).format('DD MMM YYYY')) <=
                new Date(dayjs(filteredProjects.toDate).format('DD MMM YYYY'))
          );
        }
      };
      if (filteredProjects.filteredAdminName !== '') {
        result = filterByAdminName(result);
      }
      if (Object.keys(filteredProjects.filteredDate).length) {
        result = filterByDate(result);
      }
      if (Object.keys(filteredProjects.filteredStatus).length) {
        result = filterByStatus(result);
      }
      setFilteredData(result);
      setIsFilterOpen(false);
    } else if (type === 'cancel') {
      setFilteredProjects({
        filteredAdminName: '',
        filteredDate: {},
        filteredStatus: {},
        fromDate: null,
        toDate: null,
      });
      setFilteredData(teamProjectsData);
      setIsFilterOpen(false);
    }
  };

  const projectFilterOnChangeHandler = (field = '', value = null) => {
    setFilteredProjects({
      ...filteredProjects,
      [field]: value,
    });
  };

  const projectsListData = context.busy.status
    ? getPlaceholderItems(
        TEAM_PROJECTS_TABLE_HEADERS.map(({ sortKey }) => sortKey),
        4,
        context.busy.status
      )
    : teamProjectsTableData(filteredData, teamId, userType);

  useEffect(() => {
    getProjectsList();
  }, [getProjectsList]);

  return (
    <TeamProjectsListWrapper>
      <div className="heading">
        Projects<span>({teamProjectsData.length})</span>
      </div>
      <div className="header-buttons-wrapper d-flex gap-3">
        <div className="left-buttons">
          <SearchField
            searchField={searchProject}
            onChangeSearchHandler={searchProjectByNameHandler}
            closeSearchHandler={closeSearchProjectByName}
            placeholder="Search Project"
          />
        </div>
        <CustomButton
          color={isFilterOpen ? 'themeSecondary' : 'themeGray'}
          variant="light"
          customPadding="16px"
          disabled={teamProjectsData.length === 0}
          onClick={() => setIsFilterOpen(!isFilterOpen)}
          className={cx(!isFilterOpen && 'filter-button')}>
          <Icon
            name="filter"
            color={isFilterOpen ? 'rgba(33, 159, 255, 1)' : '#929DAF'}
            size={18}
          />
          Filter
        </CustomButton>
      </div>
      {isFilterOpen && (
        <>
          <ProjectsFilter
            filterTitle="Filter Projects"
            state={filteredProjects}
            projectFilterSaveHandler={projectFilterSaveHandler}
            projectFilterOnChangeHandler={projectFilterOnChangeHandler}
          />
        </>
      )}
      {searchProject !== '' && filteredData.length === 0 ? (
        <div className="no-project">
          <p>
            Oh, looks like you don’t have any Project's name starting by
            <strong>{` ${searchProject}.`}</strong>
          </p>
        </div>
      ) : teamProjectsData.length === 0 ? (
        <div className="no-project">You don't have Projects!</div>
      ) : (
        <ScrollableDiv scrollOnHeight="420px" scrollOnHeightMob="300px">
          <CustomTable
            isLoading={context.busy.status}
            rows={projectsListData}
            headers={TEAM_PROJECTS_TABLE_HEADERS}
            selectedRow={selectedRow}
            rowCheckedHandler={(val) => onRowCheckedHandler(val)}
            allRowCheckedHandler={(val) => onAllRowCheckedHandler(val)}
          />
        </ScrollableDiv>
      )}
    </TeamProjectsListWrapper>
  );
};
