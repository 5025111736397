import cn from 'classname';
import styled from 'styled-components';

export const ToggleSwitch = ({
  activeLabel,
  toggleLabel = ['My Tasks', 'Other Tasks'],
  onClick,
  activeBGColor = '#0abd00',
  inactiveBGColor = '#e9eef4',
  activeTextColor = '#ffffff',
  inactiveTextColor = '#404f67',
  customPadding = '12px 0',
  width,
  height,
}) => {
  return (
    <SwitchWrapper
      activeBGColor={activeBGColor}
      inactiveBGColor={inactiveBGColor}
      activeTextColor={activeTextColor}
      inactiveTextColor={inactiveTextColor}
      customPadding={customPadding}
      width={width}
      height={height}>
      <div
        className={cn(
          'switch-button',
          activeLabel === toggleLabel[0] ? 'switch-active' : ''
        )}
        onClick={() => {
          onClick(toggleLabel[0]);
        }}>
        <span>{toggleLabel[0]}</span>
      </div>
      <div
        onClick={() => {
          onClick(toggleLabel[1]);
        }}
        className={cn(
          'switch-button',
          activeLabel === toggleLabel[1] ? 'switch-active' : ''
        )}>
        <span>{toggleLabel[1]}</span>
      </div>
    </SwitchWrapper>
  );
};

const SwitchWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  border-radius: 30px;
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ height }) => (height ? height : '100%')};
  background-color: ${({ inactiveBGColor }) => inactiveBGColor};
  padding: 7px 10px;
  & .switch-button {
    overflow: hidden;
    font-family: 'Poppins', 'Open Sans';
    font-weight: 400;
    font-size: 14px;
    color: ${({ inactiveTextColor }) => inactiveTextColor};
    border-radius: 30px;
    padding: ${({ customPadding }) => customPadding};
    text-align: center;
    cursor: pointer;
  }
  & .switch-active {
    background-color: ${({ activeBGColor }) => activeBGColor};
    color: ${({ activeTextColor }) => activeTextColor};
  }
`;
