import styled from 'styled-components';

export const GroupPublicationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 14px 10px;

  .no-permission {
    width: 100%;
    padding: 16px 18px;
    font-weight: 500;
    background-color: #f4e7db;
    color: #af814e;
    display: flex;
    gap: 8px;
    font-size: 14px;
    border-radius: 10px;
  }
  .search-container {
    padding: 16px 12px;
    display: grid;
    width: 100%;
    gap: 20px;
    margin-bottom: 8px;
    flex-wrap: none;
    grid-template-columns: minmax(auto, 90%) auto;
    & .search-bar {
      background-color: white;
      border-radius: 12px;
    }
    .filter-btn-group {
      position: relative;
      display: flex;
      gap: 15px;
      @media (max-width: 350px) {
        flex-direction: column;
        gap: 16px;
      }
    }

    @media (max-width: 550px) {
      display: flex;
      flex-direction: column;
    }
  }
  .publications-section {
    padding: 20px 16px;
    .publication-cards {
      display: flex;
      flex-direction: column;
      gap: 30px;
      .no-filter-data {
        color: grey;
        height: 100%;
        text-align: center;
      }
    }
    .no-publications {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #808080;
      img {
        width: 300px;
        aspect-ratio: initial;
      }
    }
  }

  .pagination-wrapper {
    margin-top: 16px;
    align-self: center;
  }
`;
