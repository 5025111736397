import React from 'react';
import styled from 'styled-components';
import cn from 'classname';
import { noop } from 'lodash';

const RadioGroupWrapper = styled.div`
  & .radio-buttons {
    display: ${(p) => (p.length < 3 ? 'grid' : 'flex')};
    grid-template-columns: repeat(${(p) => p.length}, 1fr);
    justify-items: start;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0px 16px 0px;

    @media (max-width: 780px) {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-start;
      gap: 12px;
    }
    & .radio-label {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2px;
      cursor: pointer;
      &.disabled-radio {
        color: #c4c4c4;
        cursor: not-allowed;
      }
      & input[type='radio'] {
        appearance: none;
        margin: 0 5px;
        width: 20px;
        height: 20px;
        border: 2px solid #555555;
        border-radius: 50%;
        background-clip: content-box;
        padding: 3px;
        cursor: pointer;
        &:disabled {
          cursor: not-allowed;
        }
      }
      & input[type='radio']:checked {
        background-color: #219fff;
        border-color: #219fff;
      }
    }
  }
`;

const RadioGroup = ({
  labels,
  handleChange,
  currChecked,
  isBoldLabel = true,
  className,
  labelClassName,
  customClassName,
  disabled = false,
}) => {
  return (
    <RadioGroupWrapper length={labels.length} className={className}>
      <div
        className={cn('radio-buttons', customClassName, disabled && 'disabled')}
        onChange={disabled ? noop : handleChange}>
        {labels.map((item, index) => (
          <label
            className={cn(
              'radio-label',
              labelClassName,
              item.isDisabled || disabled ? 'disabled-radio' : ''
            )}
            key={index}
            style={!isBoldLabel ? { fontWeight: '400' } : {}}>
            <input
              readOnly
              disabled={item?.isDisabled || disabled}
              className="radio-color"
              type="radio"
              style={{
                borderColor: item?.isDisabled || (disabled && '#C4C4C4'),
                backgroundColor:
                  (item?.isDisabled || disabled) &&
                  currChecked === item.value &&
                  '#C4C4C4',
              }}
              value={item.value}
              name={item.value}
              checked={currChecked === item.value}
            />
            {item.text}
          </label>
        ))}
      </div>
    </RadioGroupWrapper>
  );
};

export default RadioGroup;
