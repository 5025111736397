import styled from 'styled-components';
export const ProjectPublicPublicationWrapper = styled.div`
  & .content {
    & .cards-container {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;
      @media (max-width: 699px) {
        flex-direction: column;
      }
    }
    & .people-cards-container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 16px;
      @media (max-width: 699px) {
        grid-template-columns: auto;
      }
    }
    & .opening-cards-container {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;

      @media (min-width: 900px) and (max-width: 1499px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: 899px) {
        grid-template-columns: 1fr;
      }
    }
    & .no-data-class {
      width: 50%;
      margin: 50px auto;
      text-align: center;
      color: #404f67;
    }
  }
  .fixed-width-content {
    width: 250px !important;
  }
`;
