import styled from 'styled-components';

export const TooltipWrapper = styled.div`
  position: relative;
  & .hover-icon {
    display: inline-block;
    cursor: pointer;
  }
  & .hover-card-container {
    width: 220px;
    position: absolute;
    top: ${({ top }) => top};
    left: ${({ left }) => left};
    z-index: 1000;
    padding: 0 12px 0 16px;
    background: #ffffff;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    border-top: 12px solid ${({ hoverColor }) => hoverColor};
    @media (max-width: 700px) {
      top: ${({ mobileTop }) => mobileTop};
      left: ${({ mobileLeft }) => mobileLeft};
      width: 160px;
    }
  }
`;
