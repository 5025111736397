import React, { useEffect } from 'react';
import { PublicationCardWrapper } from 'pages/TeamDashboard/TeamPublications/Components/PublicationCard/style';
import Icon from 'utlils/Icons/Icon';
import { CustomButton } from 'components';
import { ReactComponent as ThreeDotsNew } from 'assests/images/three-dots.svg';
import { PublicationsPopUp } from '../PublicationsPopUp';
import { useState } from 'react';
import { PUBLICATION_CARD_ACTION_OPTIONS } from 'pages/TeamDashboard/helper';
import CommonDropDown from 'pages/enterpriseDashboard/components/commonDropDown';
import { useNavigate } from 'react-router-dom';
import { FETCH_USER_PROFILE, useAxiosWrapper } from 'services';
import BgImg from 'assests/images/bgImgPublic.png';
import { getProfileText } from 'utlils/utils';
import { PUBLICATION_CATEGORY_OPTIONS } from 'pages/TeamDashboard/TeamPublications/constants';
import dayjs from 'dayjs';

const DEFAULT_SHOW_PUBLICATION_EDIT_DATA = {
  selectedCollaboratorMembers: [],
  isDataFetched: false,
};

export const PublicationCard = (props) => {
  const {
    isFeedCard,
    id,
    title,
    description,
    privacy,
    category,
    updatedAt,
    referenceLink,
    coverImage,
    user,
    attachments,
    collaboratorMemberId,
    publishedByUser,
    onUpdate,
    onRemove,
  } = props;

  const navigate = useNavigate();

  const [isActive, setIsActive] = useState(false);
  const [isEditPublicationOpen, setIsEditPublicationOpen] = useState(false);
  const [fnWrapper] = useAxiosWrapper();
  const [showPublicationEditData, setShowPublicationEditData] = useState({
    ...DEFAULT_SHOW_PUBLICATION_EDIT_DATA,
  });
  let updateDate = dayjs(updatedAt).format('DD MMM YYYY');

  const fetchEditPublicationData = async () => {
    let updatedPublicationData = { ...DEFAULT_SHOW_PUBLICATION_EDIT_DATA };

    if (category !== '') {
      const findCategory = PUBLICATION_CATEGORY_OPTIONS.find(
        (item) => item?.value === category
      );
      updatedPublicationData.category = findCategory;
    }

    if (collaboratorMemberId && collaboratorMemberId.length > 0) {
      const usersList = await Promise.all(
        collaboratorMemberId.map(async (userId) => {
          const res = await fnWrapper({
            url: `${FETCH_USER_PROFILE}/${userId}`,
            method: 'GET',
            type: 'FETCH_USER_PROFILE',
          });

          if (res.data.statusCode === 200) {
            const response = res.data.data;
            return {
              memberName: response?.name,
              profilePicture: response?.profilePicture,
              profileText: getProfileText(response?.name),
              userId: response?.userId,
            };
          }
        })
      );

      updatedPublicationData.selectedCollaboratorMembers = usersList;
    }

    return { ...updatedPublicationData, isDataFetched: true };
  };

  useEffect(() => {
    const fetchData = async () => {
      if (isEditPublicationOpen) {
        const res = await fetchEditPublicationData();
        if (res.isDataFetched) {
          setShowPublicationEditData(res);
        }
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditPublicationOpen, category, collaboratorMemberId]);

  const onSelectPublication = () => {
    setIsActive((curr) => !curr);
  };

  const onUpdatePublication = async (publicationData) => {
    onUpdate(publicationData);
    setIsEditPublicationOpen(false);
  };

  const removePublicationHandler = async () => {
    onRemove(id);
  };

  const optionsHandler = (data) => {
    if (data.label === 'Edit') {
      setShowPublicationEditData((prev) => ({ ...prev, isDataFetched: false }));

      setIsEditPublicationOpen(true);
      setIsActive(false);
    }
    if (data.label === 'Remove') {
      removePublicationHandler();
      setIsActive(false);
    }
  };

  const viewBtnClickHandler = () => {
    navigate(`/public/publication/${id}`);
  };
  return (
    <PublicationCardWrapper>
      <div className="publication-header">
        <div className="header-image-and-title">
          <div className="image-container">
            <img
              src={coverImage && coverImage !== '' ? coverImage : BgImg}
              className="publication-image"
              alt="publication"
            />
          </div>
          <h6 className="publication-title">{title}</h6>
        </div>
        <div className="publication-card-options">
          {!isFeedCard && (
            <div style={{ position: 'relative' }}>
              <div className="options-class" onClick={onSelectPublication}>
                <ThreeDotsNew color={isActive ? '#219fff' : '#c4c4c4'} alt="" />
              </div>
              <CommonDropDown
                dropItem={PUBLICATION_CARD_ACTION_OPTIONS}
                active={isActive}
                onClick={(data) => {
                  optionsHandler(data);
                }}
                objectKey="label"
                top="50px"
                right="-35px"
              />
            </div>
          )}
          {showPublicationEditData?.isDataFetched && isEditPublicationOpen && (
            <PublicationsPopUp
              closePopup={() => setIsEditPublicationOpen(false)}
              beingEdited={true}
              initialProps={{
                id: id,
                name: title,
                description: description,
                privacy: privacy,
                referenceLink: referenceLink,
                coverImage: coverImage,
                attachments: attachments,
                selectedCollaboratorMembers:
                  showPublicationEditData?.selectedCollaboratorMembers,
                category: showPublicationEditData.category,
              }}
              onUpdate={onUpdatePublication}
            />
          )}
        </div>
      </div>
      <p
        className="publication-card-description"
        dangerouslySetInnerHTML={{
          __html: description,
        }}></p>
      <div className="publication-card-footer">
        <div className="publisher-info">
          <p>Published By -</p>
          <div className="publisher-identity">
            {publishedByUser?.profilePicture &&
            publishedByUser?.profilePicture !== '' ? (
              <div className="profile-pic-class">
                <img src={publishedByUser?.profilePicture} alt="" />
              </div>
            ) : (
              <div className="publisher-image">
                <Icon name="user-icon" color="#404f67" size={24} />
              </div>
            )}
            <div className="username-update-data">
              <div
                className="user-name"
                onClick={() =>
                  navigate(
                    `/public/individual/${user?.id}/summary/basic-details`
                  )
                }>
                {publishedByUser?.name
                  ? publishedByUser?.name
                  : publishedByUser?.enterpriseName}
              </div>
              {isFeedCard && (
                <div className="update-date">
                  Updated at- <span className="date"> {updateDate}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="navigational-btns">
          <CustomButton
            className="view-btn"
            variant="light"
            color="themeSecondary"
            customPadding="10px 45px"
            onClick={viewBtnClickHandler}>
            View
            <Icon name="arrow-right" size={18} color="#219fff" />
          </CustomButton>
        </div>
      </div>
    </PublicationCardWrapper>
  );
};
