import PropTypes from 'prop-types';
import { CustomTable, ScrollableDiv } from 'components';

export const PostedJobList = (props) => {
  const { list, isLoading, postedTableHeaders } = props;
  return (
    <ScrollableDiv>
      <CustomTable
        rows={list}
        headers={postedTableHeaders}
        hasCheckbox={false}
        isLoading={isLoading}
      />
    </ScrollableDiv>
  );
};
PostedJobList.propTypes = {
  list: PropTypes.array.isRequired,
  getApplicantList: PropTypes.func,
  onDuplicateHandler: PropTypes.func,
  activeApplicant: PropTypes.string,
};
