import styled from 'styled-components';

export const SectionCardWrapper = styled.div`
  height: auto;
  border: 2px solid #e9eef4;
  .card-title {
    font-size: 14px;
    padding: 8px 16px;
    font-weight: 400;
    line-height: 24px;
    border-bottom: 2px solid #e9eef4;
  }
  .permissions-lists {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 16px;
    .permission-checkboxes {
      display: flex;
      gap: 24px;
      .permissions-context {
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
        color: #404f67;
      }
      @media (max-width: 460px) {
        flex-direction: column;
        align-items: start;
      }
    }
    .arrow-btn {
      border: 1.5px solid #0abd00;
      justify-content: center;
      align-items: center;
      width: 28px;
      height: 28px;
      display: flex;
      border-radius: 50%;
    }
  }
`;
