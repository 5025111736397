import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ChangePasswordWrapper } from './style';
import { Input, CustomButton } from 'components';
import { isValidPassword } from 'helpers';
import tokenService from 'services/token.service';
import { CHANGE_PASSWORD, useAxiosWrapper } from 'services';
import { toast } from 'react-toastify';

export const ChangePassword = (props) => {
  const DEFAULT_STATE = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
    error: {},
  };
  const [passwordState, setPasswordState] = useState(DEFAULT_STATE);
  const { currentPassword, newPassword, confirmPassword, error } =
    passwordState;
  const [fnWrapper, context] = useAxiosWrapper();
  const loggedInUser = tokenService.getSession();

  const onChangeHandler = (field = '', value) => {
    if (field === 'currentPassword') {
      return setPasswordState((state) => ({
        ...state,
        [field]: value,
        error: {
          newPassword:
            value === newPassword &&
            'confirm password should match with new password',
        },
      }));
    }
    if (field === 'newPassword') {
      return setPasswordState((state) => ({
        ...state,
        [field]: value,
        error: {
          ...error,
          [field]:
            (!isValidPassword(value) &&
              'Password should contain 1 uppercase, 1 number,1 special character and 8 minimum characters') ||
            (currentPassword === value &&
              'New password cannot be same as the old one'),
          confirmPassword:
            value !== confirmPassword &&
            'confirm password should match with new password',
        },
      }));
    }
    if (field === 'confirmPassword') {
      return setPasswordState((state) => ({
        ...state,
        [field]: value,
        error: {
          ...error,
          [field]:
            value !== newPassword &&
            'confirm password should match with new password',
        },
      }));
    }
  };

  const onChangePasswordHandler = async () => {
    const res = await fnWrapper({
      url: CHANGE_PASSWORD,
      method: 'POST',
      type: 'CHANGE_PASSWORD',
      payload: {
        userId: loggedInUser.id,
        currentPassword: passwordState?.currentPassword,
        newPassword: passwordState?.newPassword,
        confirmPassword: passwordState?.confirmPassword,
      },
    });
    if (res.data.statusCode === 200) {
      toast.success(res.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  const disabled =
    !Object.keys(error).every((key) => !error[key]) ||
    currentPassword.length === 0 ||
    newPassword.length === 0 ||
    confirmPassword.length === 0;

  return (
    <ChangePasswordWrapper>
      <div className="heading">
        <span>Change Password</span>
        <Link className="link" replace to="/forgot-password">
          Forgot Password
        </Link>
      </div>
      <div className="change-password-main-wrapper">
        <div className="col-12">
          <div className="input-box-container">
            <Input
              label="Current Password"
              placeholder="Current Password"
              type="password"
              height="79px"
              required
              value={currentPassword}
              onChange={(value) => {
                onChangeHandler('currentPassword', value.target.value);
              }}
              hasError={error?.currentPassword}
              errorMessage={error?.currentPassword}
            />
          </div>
        </div>
        <div className="col-12">
          <div className="input-box-container">
            <Input
              label="New Password"
              placeholder="New Password"
              type="password"
              height="79px"
              required
              value={newPassword}
              onChange={(value) =>
                onChangeHandler('newPassword', value.target.value)
              }
              hasError={error?.newPassword}
              errorMessage={error?.newPassword}
            />
          </div>
        </div>
        <div className="col-12">
          <div className="input-box-container">
            <Input
              label="Confirm New Password"
              placeholder="Confirm Password"
              type="password"
              height="79px"
              required
              value={confirmPassword}
              onChange={(value) =>
                onChangeHandler('confirmPassword', value.target.value)
              }
              hasError={error?.confirmPassword}
              errorMessage={error?.confirmPassword}
            />
          </div>
        </div>
        <div className="button-container">
          <CustomButton
            className="w-100 h-100 "
            label="Change Password"
            disabled={disabled}
            onClick={onChangePasswordHandler}
            loading={
              context?.busy?.status && context?.busy?.type === 'CHANGE_PASSWORD'
            }
          />
        </div>
      </div>
    </ChangePasswordWrapper>
  );
};
