import React, { useState, useEffect } from 'react';
import {
  SendTextMessageWrapper,
  AttachmentWrapper,
  ImageAttachmentWrapper,
  VideoMessageWrapper,
} from './styles';
import Icon from 'utlils/Icons/Icon';
import {
  formatTimestamp,
  getMessageMediaUrl,
  convertFileSizeToMb,
  handleDownload,
} from 'services/twilioService/helper';
import { CustomButton } from 'components';
import {
  chatErrorMsg,
  fetchUserDetailsById,
} from 'services/twilioService/helper';
import { getProfileText } from 'utlils/utils';

export const SendTextMessage = ({ message }) => {
  const [mediaUrl, setMediaUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [receiverDetails, setReceiverDetails] = useState({
    userName: '',
    profilePicture: '',
  });

  useEffect(() => {
    const fetchMediaUrl = async () => {
      if (message.media) {
        try {
          const url = await getMessageMediaUrl(message);
          setMediaUrl(url);
        } catch (error) {
          chatErrorMsg('Error fetching media URL');
        }
      }
    };

    fetchMediaUrl();
  }, [message]);

  useEffect(() => {
    if (message) {
      const fetchData = async () => {
        try {
          const userData = await fetchUserDetailsById(message?.author);

          setReceiverDetails({
            userName:
              userData?.role === 'INDIVIDUAL'
                ? userData?.name
                : userData?.enterpriseName,
            profilePicture: userData?.profilePicture,
          });
        } catch (error) {
          chatErrorMsg('Error fetching user details', error);
        }
      };

      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  const isImageMessage =
    message?.media && message?.media?.contentType?.startsWith('image');

  const isVideoMessage =
    message?.media && message?.media?.contentType?.startsWith('video');

  return (
    <SendTextMessageWrapper>
      <div className="message-container">
        <div className="message-author">You</div>
        <div className="message-text-wrapper">
          <div className="message-timeStamp">
            {formatTimestamp(message?.state?.timestamp)} :
          </div>
          {message.media && mediaUrl ? (
            isImageMessage ? (
              <ImageAttachmentWrapper>
                <div className="image-wrapper">
                  {isLoading ? <div className="skeleton-loader" /> : null}
                  <img
                    onLoad={() => setIsLoading(false)}
                    src={mediaUrl}
                    alt={message?.media?.filename}
                  />
                </div>
                <CustomButton
                  onClick={() =>
                    handleDownload(mediaUrl, setDownloadUrl, downloadUrl)
                  }
                  className="image-download">
                  <Icon name="download" size={24} color="ffffff" />
                </CustomButton>
              </ImageAttachmentWrapper>
            ) : isVideoMessage ? (
              <VideoMessageWrapper>
                <div className="video-wrapper">
                  {isLoading ? (
                    <div className="recieved-video-loader">
                      <Icon color="#fff" name="loader" size={40} />
                      Loading ...
                    </div>
                  ) : null}
                  <video
                    onLoadedMetadata={() => setIsLoading(false)}
                    width="400"
                    controls>
                    <source src={mediaUrl} type={message.media.contentType} />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <a
                  href={mediaUrl}
                  download={message?.media?.filename}
                  rel="noreferrer"
                  target="_blank"
                  className="video-download">
                  <Icon name="download" size={24} color="ffffff" />
                </a>
              </VideoMessageWrapper>
            ) : (
              <AttachmentWrapper>
                <div className="attachment-icon">
                  <Icon name="attachment" size={30} color="#fffff" />
                </div>
                <div className="attachment-details">
                  <span className="attachment-filename">
                    {message.media.filename}
                  </span>
                  <p className="attachment-size">
                    {convertFileSizeToMb(message?.media?.size)} MB
                  </p>
                </div>
                <a
                  href={mediaUrl}
                  download={message?.media?.filename}
                  target="_blank"
                  rel="noreferrer"
                  className="attachment-download">
                  <Icon name="download" size={24} color="ffffff" />
                </a>
              </AttachmentWrapper>
            )
          ) : (
            <div className="message-text">{message?.state?.body}</div>
          )}
        </div>
      </div>
      <div className="profile-circle">
        {receiverDetails?.profilePicture === null ||
        receiverDetails?.profilePicture === '' ? (
          <span className="prof-text">
            {getProfileText(receiverDetails?.userName)}
          </span>
        ) : (
          <img alt="profile-pic" src={receiverDetails?.profilePicture} />
        )}
      </div>
    </SendTextMessageWrapper>
  );
};
