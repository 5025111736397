import { useState, useCallback, useEffect } from 'react';
import noProject from 'assests/images/noProjects.png';
import { getPlaceholderItems } from 'utlils/utils';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  CustomButton,
  CustomTable,
  Loader,
  ScrollableDiv,
  SearchField,
} from 'components';
import {
  projectsTableData,
  PROJECTTABLEHEADERS,
} from 'pages/IndividualDashboard/IndividualPersonalSection/individualTeams/helper';
import { TYPES } from 'pages/IndividualDashboard/constants';
import { ProjectViewWrapper } from './style';
import { FETCH_PROJECTS_LIST, useAxiosWrapper } from 'services';
import { ProjectsFilter } from '../Components';
import TokenService from 'services/token.service';
import Icon from 'utlils/Icons/Icon';

export const ProjectList = ({ type = '', baseUrl = '', userDepRole }) => {
  const [fnWrapper, context] = useAxiosWrapper();
  const [searchProject, setSearchProject] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [projectData, setProjectData] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState({
    filteredProjectName: '',
    filteredDate: {},
    filteredNumberOfTeams: -1,
    filteredStatus: {},
    fromDate: null,
    toDate: null,
  });
  const { id, departmentId } = useParams();
  const navigate = useNavigate();

  const getProjectList = useCallback(async () => {
    const loggedInUser = TokenService.getSession();
    const deptId = id ? id : departmentId;
    const customId = type === TYPES.ENTERPRISE ? deptId : loggedInUser.id;
    const url = `${FETCH_PROJECTS_LIST}/${customId}?type=${type.toLowerCase()}`;

    const res = await fnWrapper({
      url: url,
      method: 'GET',
      type: 'FETCH_PROJECTS_LIST',
    });

    if (res.data.statusCode === 200) {
      const projectTableList = [];
      if (type === TYPES.ENTERPRISE) {
        res?.data?.data[0]?.projects.map((item) => {
          const newProjectData = {
            id: `${item.id}`,
            departmentId: `${id}`,
            projectName: `${item.title}`,
            linkedTeams: `${item.teams.length}`,
            createdOn: `${item.createdAt}`,
            status: `${item.projectStatus}`,
          };
          projectTableList.push(newProjectData);
          return projectTableList;
        });
      } else {
        res.data.data.map((item) => {
          const newProjectData = {
            id: `${item.id}`,
            projectName: `${item.title}`,
            linkedTeams: `${item.teams.length}`,
            createdOn: `${item.createdAt}`,
            status: `${item.projectStatus}`,
          };
          projectTableList.push(newProjectData);
          return projectTableList;
        });
      }
      setProjectData(projectTableList);
      setFilteredData(projectTableList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const projectFilterSaveHandler = (type) => {
    if (type === 'apply' && projectData.length !== 0) {
      setSearchProject('');
      let result = projectData;

      let filterByProjectName = (item) => {
        if (filteredProjects.filteredProjectName === '') {
          return item;
        } else {
          return item.filter(
            (ele) =>
              ele.projectName.toLowerCase() ===
              filteredProjects.filteredProjectName.toLowerCase()
          );
        }
      };
      let filterByNumberOfTeams = (item) => {
        if (Number(filteredProjects.filteredNumberOfTeams) < 0) {
          return item;
        } else {
          return item.filter(
            (ele) =>
              Number(ele.linkedTeams) ===
              Number(filteredProjects.filteredNumberOfTeams)
          );
        }
      };
      let filterByStatus = (item) => {
        if (Object.keys(filteredProjects.filteredStatus).length === 0) {
          return item;
        } else {
          return item.filter(
            (ele) =>
              ele.status.toLowerCase() ===
              filteredProjects.filteredStatus.label.toLowerCase()
          );
        }
      };
      let filterByDate = (item) => {
        if (Object.keys(filteredProjects.filteredDate).length === 0) {
          return item;
        }
        if (filteredProjects.filteredDate.value === 'YD') {
          return item.filter(
            (ele) =>
              dayjs(ele.createdOn).format('DD MMM YYYY') ===
              dayjs(new Date()).subtract(1, 'day').format('DD MMM YYYY')
          );
        }
        if (filteredProjects.filteredDate.value === 'TD') {
          return item.filter(
            (ele) =>
              dayjs(ele.createdOn).format('DD MMM YYYY') ===
              dayjs(new Date()).format('DD MMM YYYY')
          );
        }
        if (filteredProjects.filteredDate.value === 'CD') {
          return item.filter(
            (ele) =>
              new Date(dayjs(ele.createdOn).format('DD MMM YYYY')) >=
                new Date(
                  dayjs(filteredProjects.fromDate).format('DD MMM YYYY')
                ) &&
              new Date(dayjs(ele.createdOn).format('DD MMM YYYY')) <=
                new Date(dayjs(filteredProjects.toDate).format('DD MMM YYYY'))
          );
        }
      };
      if (filteredProjects.filteredProjectName !== '') {
        result = filterByProjectName(result);
      }
      if (Object.keys(filteredProjects.filteredDate).length) {
        result = filterByDate(result);
      }
      if (Number(filteredProjects.filteredNumberOfTeams) >= 0) {
        result = filterByNumberOfTeams(result);
      }
      if (Object.keys(filteredProjects.filteredStatus).length) {
        result = filterByStatus(result);
      }
      setFilteredData(result);
      setIsFilterOpen(false);
    } else if (type === 'cancel') {
      setFilteredProjects({
        filteredProjectName: '',
        filteredDate: {},
        filteredNumberOfTeams: -1,
        filteredStatus: {},
        fromDate: null,
        toDate: null,
      });
      setFilteredData(projectData);
      setIsFilterOpen(false);
    }
  };

  const projectFilterOnChangeHandler = (field = '', value = null) => {
    setFilteredProjects({
      ...filteredProjects,
      [field]: value,
    });
  };

  const handleCreate = () => {
    navigate(`${baseUrl}/create/details`);
  };

  const searchProjectByNameHandler = (value) => {
    setSearchProject(value);
    let temp = [];
    if (value) {
      temp = projectData.filter(
        (ele) => ele.projectName.toLowerCase().indexOf(value.toLowerCase()) > -1
      );
      setFilteredData(temp);
    } else {
      setFilteredData(projectData);
    }
  };
  const closeSearchProjectByName = () => {
    setSearchProject('');
    setFilteredData(projectData);
  };

  const projectListData = context.busy.status
    ? getPlaceholderItems(
        PROJECTTABLEHEADERS.map(({ sortKey }) => sortKey),
        4,
        context.busy.status
      )
    : projectsTableData(filteredData, type, baseUrl);

  useEffect(() => {
    getProjectList();
  }, [getProjectList]);

  return (
    <ProjectViewWrapper>
      {context.busy.status ? (
        <Loader isLoading={context.busy.status} />
      ) : projectData.length === 0 ? (
        <>
          <div className="project-container">
            <img width="292.45px" src={noProject} alt="no projects" />
            <div className="project-text">
              <span className="text-style">
                Oh, looks like you don’t have any Projects Be a part of Project
                now.
              </span>
            </div>
            {userDepRole && userDepRole === 'MEMBER' ? null : (
              <div className="project-button">
                <CustomButton label="Create Project" onClick={handleCreate} />
                <CustomButton
                  variant="light"
                  label="Explore Projects"
                  onClick={() => navigate(`/explore/projects/search-project`)}
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="header-buttons-wrapper">
            <div className="left-buttons">
              <SearchField
                searchField={searchProject}
                onChangeSearchHandler={searchProjectByNameHandler}
                closeSearchHandler={closeSearchProjectByName}
                placeholder="Search Projects"
              />
            </div>

            <div className="right-buttons">
              <CustomButton
                className="w-100"
                color="themeSecondary"
                variant="light"
                onClick={handleCreate}>
                <Icon name="add" color="#219FFF" size={20} />
                New Project
              </CustomButton>
              <CustomButton
                className="w-100"
                variant="light"
                color={isFilterOpen ? 'themeSecondary' : 'themeGray'}
                onClick={() => setIsFilterOpen(!isFilterOpen)}>
                <Icon
                  name="filter"
                  size={20}
                  className="filter-arrow"
                  color={isFilterOpen ? 'rgba(33, 159, 255, 1)' : '#929DAF'}
                />
                Filter
              </CustomButton>
            </div>
          </div>
          {isFilterOpen && (
            <>
              <ProjectsFilter
                filterTitle="Filter Projects"
                state={filteredProjects}
                projectFilterSaveHandler={projectFilterSaveHandler}
                projectFilterOnChangeHandler={projectFilterOnChangeHandler}
              />
            </>
          )}

          {searchProject !== '' && filteredData.length === 0 ? (
            <div className="no-project">
              <p>
                Oh, looks like you don’t have any Project's name starting by
                <strong>{` ${searchProject}.`}</strong>
              </p>
            </div>
          ) : (
            <ScrollableDiv scrollOnHeight="420px" scrollOnHeightMob="300px">
              <CustomTable
                isLoading={context.busy.status}
                rows={projectListData}
                headers={PROJECTTABLEHEADERS}
              />
            </ScrollableDiv>
          )}
        </>
      )}
    </ProjectViewWrapper>
  );
};
