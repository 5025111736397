import Icon from 'utlils/Icons/Icon';
import { INFO_TEXT_TYPE_COLOR } from './constants';
import { MemberInfoBoxWrapper } from './style';

const MemberInfoBox = ({ text, type, iconName }) => {
  return (
    <MemberInfoBoxWrapper>
      <div
        className="info-box"
        style={{
          border: `1px solid ${INFO_TEXT_TYPE_COLOR[type]}`,
          background: ` ${INFO_TEXT_TYPE_COLOR[type]}25`,
        }}>
        <div className="info-icon-wrapper">
          <Icon name={iconName} size={24} color={INFO_TEXT_TYPE_COLOR[type]} />
        </div>
        <div>
          <span style={{ color: INFO_TEXT_TYPE_COLOR[type] }}>{text}</span>
        </div>
      </div>
    </MemberInfoBoxWrapper>
  );
};

export default MemberInfoBox;
