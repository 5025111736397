import { React, useState, useEffect, useCallback, forwardRef } from 'react';
import { ConversationWrapper } from './styles';
import { getProfileText } from 'utlils/utils';
import {
  chatErrorMsg,
  fetchUserDetailsById,
  fetchTeamDetailsById,
  formatTimestamp,
} from 'services/twilioService/helper';

export const ConversationUser = forwardRef(
  (
    {
      handleConversationClick,
      conversationDetails,
      loggedInUser,
      isSelected,
      searchCriteria,
    },
    ref
  ) => {
    const convoAttributes = conversationDetails?.attributes;
    const [userToShow, setUserToShow] = useState(null);
    const [lastMessageToShow, setLastMessageToShow] = useState(null);
    const [typingIndicatorUser, setTypingIndicatorUser] = useState(null);

    const isCurrentUserSender = loggedInUser?.id === convoAttributes?.senderId;
    const userToFind = isCurrentUserSender
      ? convoAttributes?.receiverId
      : convoAttributes?.senderId;

    const convoType = convoAttributes?.conversationType;

    const fetchUserDetails = useCallback(async () => {
      try {
        const userData = await fetchUserDetailsById(userToFind);
        setUserToShow(userData);
      } catch (error) {
        chatErrorMsg('Error fetching user details', error);
      }
    }, [userToFind]);

    const fetchTeamDetails = useCallback(async () => {
      try {
        const userData = await fetchTeamDetailsById(convoAttributes.teamId);
        setUserToShow(userData);
      } catch (error) {
        chatErrorMsg('Error fetching user details', error);
      }
    }, [convoAttributes.teamId]);

    const findLastChatMessage = useCallback(async () => {
      const lastMessage = await conversationDetails.getMessages();
      const lastMessageIndex = conversationDetails?.lastMessage?.index
        ? conversationDetails?.lastMessage?.index
        : null;

      if (lastMessageIndex) {
        const maxMessageLength = 20;
        let message = lastMessage?.items[lastMessageIndex]?.state?.body;
        if (message && message.length > maxMessageLength) {
          message = message.substring(0, maxMessageLength) + '...';
        }

        setLastMessageToShow(message);
      }
    }, [conversationDetails]);

    useEffect(() => {
      if (conversationDetails) {
        conversationDetails.on('typingStarted', async (participant) => {
          const messageUser = await fetchUserDetailsById(participant?.identity);
          setTypingIndicatorUser(messageUser);
        });

        conversationDetails.on('typingEnded', () => {
          setTypingIndicatorUser(null);
        });
      }
    }, [conversationDetails]);

    useEffect(() => {
      if (convoAttributes && convoType === 'ONE_TO_ONE') {
        fetchUserDetails();
      }
      if (convoAttributes && convoType === 'TEAM') {
        fetchTeamDetails();
      }
      findLastChatMessage();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const currUserNameToShow =
      convoType === 'ONE_TO_ONE'
        ? userToShow?.role === 'INDIVIDUAL'
          ? userToShow?.name
          : userToShow?.enterpriseName
        : userToShow?.teamName;

    // Check if the current user matches the search criteria
    const isMatchingSearch =
      !searchCriteria ||
      currUserNameToShow.toLowerCase().includes(searchCriteria.toLowerCase());

    // If search criteria provided and the user doesn't match, return null
    if (!isMatchingSearch) {
      return null;
    }

    const currUserProfilePictureToShow = userToShow?.profilePicture;
    const conversationCreatedDate = formatTimestamp(
      conversationDetails?.dateCreated,
      'date'
    );

    const conversationLastMessageTime = conversationDetails?.lastMessage
      ?.dateCreated
      ? formatTimestamp(conversationDetails?.lastMessage?.dateCreated, 'time')
      : undefined;

    const typingIndicatorMssg =
      convoType === 'ONE_TO_ONE'
        ? 'Typing...'
        : typingIndicatorUser?.role === 'SMALL_ENTERPRISE'
        ? typingIndicatorUser?.userName + ' typing...'
        : typingIndicatorUser?.name + ' typing...';
    return (
      <ConversationWrapper
        onClick={() => handleConversationClick(conversationDetails)}
        isSelected={isSelected}
        ref={ref}>
        <div className="conversation__img">
          {currUserProfilePictureToShow === null ||
          currUserProfilePictureToShow === '' ? (
            <div className="prof-text-wrapper">
              <span className="prof-text">
                {getProfileText(currUserNameToShow)}
              </span>
            </div>
          ) : (
            <img alt="profile-pic" src={currUserProfilePictureToShow} />
          )}
        </div>
        <div className="conversation__info">
          <p className="conversation__name">{currUserNameToShow}</p>
          {typingIndicatorUser && (
            <p className="conversation__action">{typingIndicatorMssg}</p>
          )}

          {typingIndicatorUser === null && lastMessageToShow && (
            <p className="conversation__last-message">{lastMessageToShow}</p>
          )}
        </div>
        <div>
          <p className="conversation__last-message-time">
            {conversationLastMessageTime
              ? conversationLastMessageTime
              : conversationCreatedDate}
          </p>
        </div>
      </ConversationWrapper>
    );
  }
);
