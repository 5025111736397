import { useState, useCallback, useEffect } from 'react';
import noEnterprise from '../../../../assests/images/no-enterprise.png';
import enterpriseImg from '../../../../assests/images/enterpriseImg.png';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  InfoCard,
  Loader,
  Pagination,
  SearchField,
} from '../../../../components';
import { breadCrumbListStorage } from './helper';

const EnterpriseListWrapper = styled.div`
  .no-enterprise {
    width: 75%;
    text-align: center;
    margin: 10% auto;
    font-weight: 500;
    color: #929daf;
  }
  .teams-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    row-gap: 20px;
    padding: 5px;
    @media (max-width: 576px) {
      flex-direction: column;
    }
    & .page-title {
      font-weight: 700;
      font-size: 30px;
      color: #17191d;
    }
  }
  .invitation-button {
    @media (max-width: 576px) {
      margin-bottom: 15px;
    }
    @media (max-width: 912px) {
      margin-bottom: 15px;
    }
  }
  .background-image {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .no-team-section {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    text-align: center;
    & .no-team-text {
      width: 70%;
    }
  }
  .no-team-section-button {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    align-items: center;
  }
  .explore-enterprise {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    & .explore-enterprise-button {
      width: 250px;
    }
  }
  .enterprise-list {
    margin-top: -25px;
    @media (max-width: 576px) {
      margin-top: 25px;
    }
    @media (max-width: 912px) {
      margin-top: 25px !important;
    }
  }
  .pagination-bar {
    width: 100%;
    justify-content: center;
    padding-top: 2rem;
  }
`;

export const EnterpriseList = ({ isEnterpriseListUpdate }) => {
  const [enterpriseList, setEnterpriseList] = useState({});
  const [enterpriseListData, setEnterpriseListData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchEnterprise, setSearchEnterprise] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  let pageSize = 5;
  const navigate = useNavigate();

  const getEnterpriseList = useCallback(async () => {
    const session = JSON.parse(localStorage.getItem('session'));
    try {
      setIsLoading(true);
      await axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/getEnterpriseList/${session.id}?page=${currentPage}&size=${pageSize}`,
          {
            headers: {
              'x-access-token': `${session.access_token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.statusCode === 200) {
            if (res.data.data.items.length === 0 && currentPage !== 1) {
              setCurrentPage(currentPage - 1);
            } else {
              setEnterpriseList(res.data.data);
              setEnterpriseListData(res.data.data.items);
            }
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err.response.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        });
    } catch (error) {
      setIsLoading(false);
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  const searchHandler = (value) => {
    setSearchEnterprise(value);

    let temp = [];
    if (value) {
      temp = enterpriseList.items.filter(
        (ele) =>
          ele.enterpriseName.toLowerCase().indexOf(value.toLowerCase()) > -1
      );
      setEnterpriseListData(temp);
    } else {
      setEnterpriseListData(enterpriseList.items);
    }
  };

  const closeSearchHandler = () => {
    setSearchEnterprise('');
    setEnterpriseListData(enterpriseList.items);
  };

  const viewDepartmentListHandler = (id) => {
    let crumb = [
      {
        id: 1,
        label: 'Enterprise',
        url: '/individual/profile/organization/enterprise',
      },
      {
        id: 2,
        label: 'Department',
        url: `/individual/profile/organization/enterprise/${id}/departments`,
      },
    ];
    breadCrumbListStorage(crumb);
    navigate(`/individual/profile/organization/enterprise/${id}/departments`, {
      replace: false,
    });
  };

  useEffect(() => {
    getEnterpriseList();
  }, [getEnterpriseList]);

  useEffect(() => {
    if (isEnterpriseListUpdate) {
      getEnterpriseList();
    }
  }, [isEnterpriseListUpdate, getEnterpriseList]);

  return (
    <EnterpriseListWrapper>
      {/* header */}
      <div className="teams-header">
        <div className="page-title">
          <span>Enterprise</span>
        </div>
      </div>
      {/* when no data available in enterprise section */}
      {enterpriseList &&
      Object.keys(enterpriseList).length !== 0 &&
      enterpriseList.items.length === 0 &&
      enterpriseList.currentPage === 1 ? (
        <>
          <div className=" background-image">
            <img src={noEnterprise} alt="" width="320px" />
          </div>
          <div className=" no-team-section">
            <div className="no-team-text">
              <span>
                Oh, looks like you are not a part of any Enterprise.
                <p>Become a part of Enterprise!</p>
              </span>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="row mt-4 mb-2">
            <div className="col-lg-12">
              <SearchField
                placeholder="Search Enterprise"
                searchField={searchEnterprise}
                onChangeSearchHandler={searchHandler}
                closeSearchHandler={closeSearchHandler}
              />
            </div>
          </div>
          {searchEnterprise !== '' && enterpriseListData.length === 0 ? (
            <div className="no-enterprise">
              <p>
                Oh, looks like there doesn't exist any Enterprise's name
                starting by
                <strong>{` ${searchEnterprise}.`}</strong>
              </p>
            </div>
          ) : isLoading ? (
            <Loader isLoading={isLoading} />
          ) : (
            <div className="row enterprise-list mt-5">
              {enterpriseListData.length !== 0 &&
                enterpriseListData.map((item, index) => (
                  <div className="col-md-6 my-2" key={index}>
                    <InfoCard
                      key={index}
                      leftIcon={true}
                      label={item.enterpriseName}
                      iconName={enterpriseImg}
                      onClick={() => viewDepartmentListHandler(item.id)}
                    />
                  </div>
                ))}
            </div>
          )}
          {searchEnterprise === '' &&
            (enterpriseListData.length >= 5 ||
              enterpriseList.currentPage >= 1) && (
              <Pagination
                className="pagination-bar"
                currentPage={
                  enterpriseList.currentPage ? enterpriseList.currentPage : 0
                }
                totalCount={
                  enterpriseList.totalItems ? enterpriseList.totalItems : 0
                }
                pageSize={pageSize}
                onPageChange={(page) => setCurrentPage(page)}
              />
            )}
        </>
      )}
    </EnterpriseListWrapper>
  );
};
