import { initialState } from './createTeamContext';

export const CreateTeamReducer = (state, action) => {
  switch (action.type) {
    case 'SET_DEFAULT_DATA':
      return {
        ...state,
        defaultData: action.defaultData,
      };
    case 'SET_PROGRESS':
      return {
        ...state,
        progressStep: action.payload,
      };
    case 'SET_TEAM_INFO':
      return {
        ...state,
        [action.payload1]: action.payload2,
      };
    case 'SET_TEAM_MEMBER_INFO':
      return {
        ...state,
        teamMembers: action.teamMembers,
      };
    case 'SET_TEAM_OWNER_INFO':
      return {
        ...state,
        teamOwnerName: action.teamOwnerName,
        teamOwnerDesignation: action.teamOwnerDesignation,
        teamOwnerRole: action.teamOwnerRole,
        teamOwnerEmail: action.teamOwnerEmail,
      };
    case 'ADD_NEW_MEMBER':
      return {
        ...state,
        teamMembers: action.teamMembers,
      };
    case 'DELETE_MEMBER':
      return {
        ...state,
        teamMembers: action.teamMembers,
      };
    case 'ADD_SEARCHED_MEMBER':
      return {
        ...state,
        teamMembers: action.teamMembers,
      };
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.payload,
      };
    case 'CLEAR_STATE':
      return initialState;
    default:
      return state;
  }
};
