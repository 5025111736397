import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

export const TabGroup = (props) => {
  const {
    tabLists = [],
    inactiveBGColor = '#ffffff',
    activeBGColor = '#ffffff',
    inactiveTextColor = '#7e8f9a',
    activeTextColor = '#0ABD00',
    inactiveBorderColor = '#E9EEF4',
    activeBorderColor = '#0ABD00',
    tabClick,
    activeTab,
  } = props;
  const [active, setActive] = useState(activeTab);

  const handleClick = (type) => {
    setActive(type);
    tabClick(type);
  };

  useEffect(() => {
    setActive(activeTab);
  }, [activeTab]);

  return (
    <ButtonGroup tabListLength={tabLists.length}>
      {tabLists.map((type) => (
        <Tab
          inactiveBGColor={inactiveBGColor}
          activeBGColor={activeBGColor}
          inactiveTextColor={inactiveTextColor}
          activeTextColor={activeTextColor}
          inactiveBorderColor={inactiveBorderColor}
          activeBorderColor={activeBorderColor}
          key={type}
          active={active === type}
          onClick={() => handleClick(type)}>
          {type}
        </Tab>
      ))}
    </ButtonGroup>
  );
};

const Tab = styled.button`
  background: ${(p) => (p.active ? p.activeBGColor : p.inactiveBGColor)};
  color: ${(p) => (p.active ? p.activeTextColor : p.inactiveTextColor)};
  border: 0;
  border-bottom: ${(p) =>
    `2px solid ${p.active ? p.activeBorderColor : p.inactiveBorderColor}`};
  font-family: 'Poppins', 'Open Sans';
  font-style: normal;
  font-weight: ${({ active }) => (active ? 600 : 400)};
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  outline: 0;
  padding: 15px 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const ButtonGroup = styled.div`
  display: grid;
  @media (min-width: 799px) {
    grid-template-columns: ${({ tabListLength }) =>
      tabListLength === 0 ? '1fr' : `repeat(${tabListLength}, 1fr)`};
  }

  align-items: center;
`;
