import React, { useState, useCallback, useEffect } from 'react';
import { MiHintAdminDashBoardWrapper } from './style';
import { SlidingButton, CustomTable } from 'components';
import {
  WATILISTTABLEHEADERS,
  teamsTableData,
  getIdsWaitListUsers,
  FilterTheWaitingList,
} from './helpers';
import { FETCH_SUPERADMIN_REQUESTS, useAxiosWrapper } from 'services';
import { CustomButton, Input } from 'components';
import { toast } from 'react-toastify';

export const WaitList = () => {
  const [waitList, setWaitList] = useState([]);
  const [filteredWaitingList, setFilteredWaitingList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [adminProfile, setAdminProfile] = useState({});
  const [isWaitingListEnabled, setIsWaitingListEnabled] = useState(false);
  const [searchState, setSearchState] = useState('');
  const [fnWrapper] = useAxiosWrapper();

  const getWaitlist = useCallback(async () => {
    setIsLoading(true);
    const res = await fnWrapper({
      url: `${FETCH_SUPERADMIN_REQUESTS}/waitingUsersList`,
      method: 'GET',
      type: 'FETCH_SUPERADMIN_REQUESTS',
    });
    if (res?.data?.statusCode === 200) {
      let response = res?.data?.data;
      setWaitList(response);
      setFilteredWaitingList(response);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAdminProfile = useCallback(async () => {
    setIsLoading(true);
    const res = await fnWrapper({
      url: `${FETCH_SUPERADMIN_REQUESTS}/getMiHintAdminSettings`,
      method: 'GET',
      type: 'FETCH_USER_PROFILE',
    });
    if (res.data.statusCode === 200) {
      const response = res.data.data;
      setIsLoading(false);
      setIsWaitingListEnabled((val) => response?.isWatingListEnabled);
      setAdminProfile(response);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setWaitlistStatus = useCallback(
    async (adminProfile, isWaitingListEnabled) => {
      await fnWrapper({
        url: `${FETCH_SUPERADMIN_REQUESTS}/updateSuperAminSetting/${adminProfile?.id}`,
        method: 'PUT',
        type: 'FETCH_SUPERADMIN_REQUESTS',
        payload: {
          isWaitingList: !isWaitingListEnabled,
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const WaitListUsersIds = getIdsWaitListUsers(waitList);

  const setWaitListStatus = useCallback(async (WaitListUsersIds) => {
    const res = await fnWrapper({
      url: `${FETCH_SUPERADMIN_REQUESTS}/acceptAndRejectWaitListUser`,
      method: 'PUT',
      type: 'FETCH_SUPERADMIN_REQUESTS',
      payload: {
        userId: WaitListUsersIds,
        status: 'ACCEPTED',
      },
    });
    if (res.data.statusCode === 200) {
      toast.success(res.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      getWaitlist();
    } else {
      toast.error(res.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getWaitlist();
    getAdminProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const slidingButtonHandler = () => {
    setIsWaitingListEnabled((val) => !val);
    setWaitlistStatus(adminProfile, isWaitingListEnabled);
  };

  const onSearchHandler = (value) => {
    setSearchState(value);
    if (value.length !== 0) {
      let res = FilterTheWaitingList(filteredWaitingList, value);
      setFilteredWaitingList(res);
    } else {
      setFilteredWaitingList(waitList);
    }
  };

  const waitListData = teamsTableData(filteredWaitingList, setWaitListStatus);

  return (
    <MiHintAdminDashBoardWrapper>
      {isLoading ? (
        <div>Loading ... </div>
      ) : (
        <div className="main-wrapper">
          <div className="waitlist-toggle-section">
            <p className="toggle-text">Waitlist</p>
            <SlidingButton
              onClick={slidingButtonHandler}
              isSliding={isWaitingListEnabled}
              disabled={isLoading}
            />
          </div>
          <div
            className={`waitlist-text ${
              isWaitingListEnabled ? 'enabled' : 'disabled'
            }`}>
            {isWaitingListEnabled
              ? `Waitlist is enabled. You need to approve the profile of signed up
              users`
              : `The waitlist has been disabled. Your approval is not required to
              sign up new users`}
          </div>
          <div className="header">
            <div className="search-wrapper">
              <Input
                height="0"
                iconLeft={searchState.length === 0}
                iconName="search"
                iconRightName="cross"
                placeholder="Search Teams"
                value={searchState}
                onChange={(e) => onSearchHandler(e.target.value)}
                iconRightClassName="close-search-team"
              />
            </div>
            <CustomButton
              label="ACCEPT ALL"
              className="accept-all-btn"
              onClick={() => setWaitListStatus(WaitListUsersIds)}
            />
          </div>
          {!filteredWaitingList.length > 0 ? (
            <div className="search-result-nill">
              {searchState ? (
                <p>
                  Oh, looks like you don’t have any User's name containing
                  <strong>{` ${searchState}.`}</strong>
                </p>
              ) : (
                <p>No users in the Waiting List</p>
              )}
            </div>
          ) : (
            <CustomTable
              isLoading={isLoading}
              rows={waitListData}
              headers={WATILISTTABLEHEADERS}
            />
          )}
        </div>
      )}
    </MiHintAdminDashBoardWrapper>
  );
};
