import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Country } from 'country-state-city';
import { Box, CustomButton, Title } from 'components';
import { DEFAULT_CREATE_LOCATION_STATE } from '../../constants';
import AddressSelect from './Components/AddressSelect';
import tokenService from 'services/token.service';
import {
  GET_ENTERPRISE_LOCATION,
  UPDATE_ENTERPRISE_LOCATION,
  useAxiosWrapper,
} from 'services';
import { sortLocations } from '../../utils';
import Icon from 'utlils/Icons/Icon';

const CreateLocation = () => {
  const [locations, setLocations] = useState(DEFAULT_CREATE_LOCATION_STATE);
  const [fnWrapper, { busy }] = useAxiosWrapper();
  const navigate = useNavigate();

  const onFetchEnterpriseLocations = useCallback(async () => {
    const res = await fnWrapper({
      url: `${GET_ENTERPRISE_LOCATION}?userId=${tokenService.getSession()?.id}`,
      method: 'GET',
      type: 'GET_ENTERPRISE_LOCATION',
    });
    if (res.data.statusCode === 200) {
      const data = res?.data?.data;
      if (data.length > 0) {
        setLocations(
          data.sort(sortLocations).map((address) => ({
            ...address,
            uid: address?.id,
            country: {
              name: Country.getCountryByCode(address?.country)?.name,
              value: address?.country,
            },
            state: { name: address?.state, value: address?.stateCode },
            city: { name: address?.city, value: address?.city },
          }))
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onFetchEnterpriseLocations();
  }, [onFetchEnterpriseLocations]);

  const handleInputChange = (fieldValue, value, uid) => {
    if (uid) {
      setLocations((locations) =>
        locations.map((address) => {
          if (address.uid === uid) {
            //Reset state and city based on country or state change
            if (
              fieldValue === 'country' &&
              address?.country?.value !== value?.value
            ) {
              return {
                ...address,
                [fieldValue]: value,
                state: null,
                city: null,
              };
            }
            if (
              fieldValue === 'state' &&
              address?.state?.value !== value?.value
            ) {
              return {
                ...address,
                [fieldValue]: value,
                city: null,
              };
            }
            return { ...address, [fieldValue]: value };
          }
          return address;
        })
      );
      return;
    }
  };

  const handleAddOtherAddress = () => {
    setLocations((locations) => [
      ...locations,
      {
        id: '',
        uid: Date.now(),
        locationType: 'Other_Location',
        country: null,
        state: null,
        city: null,
        address: '',
        isActive: 1,
      },
    ]);
  };

  const handleRemoveOtherAddress = (removeAddress) => {
    setLocations((locations) =>
      locations
        .map((address) => {
          if (address.uid === removeAddress.uid) {
            return { ...address, isActive: 0 };
          }
          return address;
        })
        .filter((address) => address.isActive === 1 || address.id)
    );
  };

  const handleSubmit = async () => {
    const locationArray = locations.map((address) => {
      return {
        id: address?.id,
        locationType: address?.locationType,
        country: address?.country?.value,
        state: address?.state?.name,
        stateCode: address?.state?.value,
        city: address?.city?.name,
        address: address?.address,
        isActive: address?.isActive,
        userId: tokenService?.getSession()?.id,
      };
    });
    const res = await fnWrapper({
      url: UPDATE_ENTERPRISE_LOCATION,
      method: 'PUT',
      type: 'UPDATE_ENTERPRISE_LOCATION',
      payload: { location: locationArray },
    });
    if (res.data.statusCode === 200) {
      //You can setLocation here if backend sends the updated id
      onFetchEnterpriseLocations();
      navigate('/enterprise/profile/view/location');
    }
  };

  const isDisabled = () => {
    let disabled = false;
    locations.forEach((address) => {
      if (!address?.state || !address?.city || !address?.country) {
        disabled = true;
        return;
      }
    });
    return disabled;
  };

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}>
        <Title title={'Location'} />
        <CustomButton
          className="align-self-start"
          onClick={handleAddOtherAddress}
          variant="light"
          color={'themePrimary'}>
          Add
          <Icon name="add" color={'#0ABD00'} size={16} />
        </CustomButton>
      </div>
      {locations
        ?.filter((address) => address?.isActive === 1)
        ?.map((address) => (
          <AddressSelect
            key={address?.uid}
            address={address}
            handleInputChange={handleInputChange}
            handleRemoveOtherAddress={handleRemoveOtherAddress}
          />
        ))}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap="15px"
        mt="30px">
        <Box width="120px">
          <CustomButton
            className="w-100"
            label={busy?.status ? 'Saving' : 'Save'}
            disabled={isDisabled() || busy?.status}
            onClick={handleSubmit}
          />
        </Box>
      </Box>
    </div>
  );
};

export default CreateLocation;
