import React, { useCallback, useEffect, useState } from 'react';
import { SavedProjectsWrapper } from './style';
import { useNavigate } from 'react-router-dom';
import {
  CREATE_SAVE_PUBLIC_PROJECT,
  FETCH_SAVED_PROJECTS_BASED_ON_USER,
  useAxiosWrapper,
} from 'services';
import { ExploreTeamProjectCard } from 'pages/Explore/Components';
import tokenService from 'services/token.service';
import { Loader } from 'components';
import { getDateTime } from 'utlils/utils';

export const SavedProjects = () => {
  const [fnWrapper, context] = useAxiosWrapper();
  const [projectsList, setProjectsList] = useState([]);
  const navigate = useNavigate();

  const getSavedProjects = useCallback(async () => {
    const loggedInUser = await tokenService.getSession();
    const res = await fnWrapper({
      url: `${FETCH_SAVED_PROJECTS_BASED_ON_USER}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_SAVED_TEAMS_PER_USER',
    });
    if (res.data.statusCode === 200) {
      const result = res.data.data;
      const allTeams = [];
      result?.map((project) => {
        const item = {
          id: project?.projectId,
          title: project?.project?.title,
          owner:
            project?.project?.projectType === 'ENTERPRISE' &&
            project?.project?.enterprise
              ? project?.project?.enterprise?.enterpriseName
              : project?.project?.user?.individual?.name,
          type: 'project',
          profileImgSrc: project?.project?.profilePicture,
          profileIcon: 'project',
          description: project?.project?.problemStatement,
          isProjectSaved: true,
          isPublished: project?.project?.projectStatus === 'PUBLISHED',
          projectFeature: [
            {
              iconName: 'people',
              label: `${project?.project?.teams.length} Teams`,
            },
            {
              iconName: 'clock',
              label: getDateTime(project?.updatedAt),
            },
          ],
        };
        project?.project?.projectSettings !== null &&
          item.projectFeature.push({
            iconName: 'shield-security',
            label:
              project?.project?.projectSettings?.accessibility === true
                ? 'Public'
                : 'Private',
          });
        project?.project?.projectSettings !== null &&
          project?.project?.projectSettings?.accessibility === true &&
          item.projectFeature.push({
            iconName: 'tag-user',
            label:
              project?.project?.projectSettings?.isBidding === true &&
              project?.project?.projectSettings?.isCollaboration === true
                ? 'Open for Collab & Bid'
                : project?.project?.projectSettings?.isBidding
                ? 'Open for Bid'
                : 'Open for Collab',
          });
        allTeams.push(item);
        return allTeams;
      });
      setProjectsList(allTeams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveProject = async (projectId) => {
    const loggedInUser = tokenService.getSession();
    const res = await fnWrapper({
      url: `${CREATE_SAVE_PUBLIC_PROJECT}`,
      method: 'PUT',
      payload: {
        projectId: projectId,
        userId: loggedInUser.id,
      },
      type: 'CREATE_SAVE_PUBLIC_PROJECT',
    });
    if (res.data.statusCode === 200) {
      await getSavedProjects();
    }
  };

  useEffect(() => {
    getSavedProjects();
  }, [getSavedProjects]);

  return (
    <SavedProjectsWrapper>
      <div className="main-wrapper">
        <div className="heading">
          <h5 className="teams-count">Projects ({projectsList.length})</h5>
        </div>
        {context.busy.status ? (
          <Loader loading={context.busy.status} />
        ) : projectsList?.length > 0 ? (
          <div className="cards-container">
            {projectsList?.map((project, index) => (
              <ExploreTeamProjectCard
                key={index}
                title={project.title}
                type={project.type}
                profileImgSrc={project.profileImgSrc}
                profileIcon={project.profileIcon}
                owner={project.owner}
                description={project.description}
                features={project.projectFeature}
                isPublished={project.isPublished}
                viewHandler={() =>
                  navigate(`/public/project/${project.id}/details`)
                }
                isSaved={project.isProjectSaved}
                onSaveHandler={() => saveProject(project.id)}
              />
            ))}
          </div>
        ) : (
          <div className="no-data-class">
            Looks like you didn't save any Project!
          </div>
        )}
      </div>
    </SavedProjectsWrapper>
  );
};
