import React, { useCallback, useEffect, useState } from 'react';
import { EnterprisePrivacyWrp } from './style';
import Icon from 'utlils/Icons/Icon';
import { CustomButton, SlidingButton, Tooltip } from 'components';
import tokenService from 'services/token.service';
import {
  GET_ENTERPRISE_PRIVACY,
  UPDATE_ENTERPRISE_PRIVACY,
  useAxiosWrapper,
} from 'services';
import { toast } from 'react-toastify';

export const EnterprisePrivacy = () => {
  const [hideShowPrivacy, setHideShowPrivacy] = useState({
    peoplePrivacy: false,
    teamsPrivacy: false,
    projectPrivacy: false,
  });
  const loggedInUser = tokenService.getSession();
  const [fnWrapper, context] = useAxiosWrapper();

  const getPrivacyDetails = useCallback(async () => {
    const res = await fnWrapper({
      url: `${GET_ENTERPRISE_PRIVACY}/${loggedInUser.enterpriseId}`,
      method: 'GET',
      type: 'GET_ENTERPRISE_PRIVACY',
    });
    if (res.data.statusCode === 200) {
      const response = res.data.data;
      setHideShowPrivacy({
        peoplePrivacy: response?.isShowPeople,
        teamsPrivacy: response?.isShowTeams,
        projectPrivacy: response?.isShowProjects,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updatePrivacyDetails = async () => {
    const res = await fnWrapper({
      url: UPDATE_ENTERPRISE_PRIVACY,
      method: 'PUT',
      type: 'UPDATE_ENTERPRISE_PRIVACY',
      payload: {
        enterpriseId: loggedInUser.enterpriseId,
        isShowPeople: hideShowPrivacy?.peoplePrivacy,
        isShowTeams: hideShowPrivacy?.teamsPrivacy,
        isShowProjects: hideShowPrivacy?.projectPrivacy,
      },
    });
    if (res.data.statusCode === 200) {
      getPrivacyDetails();
      toast.success(res.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    getPrivacyDetails();
  }, [getPrivacyDetails]);

  return (
    <EnterprisePrivacyWrp>
      <div className="epwrp__">
        <div className="epwrp__heading">Privacy</div>
        <div className="epwrp__label mt-4 mb-4 d-flex">
          Hide/ Show to other users{' '}
          <Tooltip
            placement={'right'}
            content={
              <div className="epwrp__tooltipcontent">
                <div>Show</div>
                <br />
                <div>
                  It will allow you to hide the particular option from your
                  public profile.
                </div>
                <br />
                <div>Hide</div>
                <br />
                <div>
                  It will allow you to show the particular option in your public
                  profile.
                </div>
              </div>
            }>
            <span className="epwrp__icon">
              <Icon name="info-circle" color={'#404f67'} size={22} />
            </span>
          </Tooltip>
        </div>
        <div className="epwrp__hideshowwrp__">
          <div className="epwrp__hideshowwrp__label">
            People of my enterprise
          </div>
          <div className="epwrp__hideshowwrp__btn">
            <div className="epwrp__hideshowwrp__content">
              <div
                className={
                  hideShowPrivacy.peoplePrivacy
                    ? 'epwrp__hideshowwrp__show-text'
                    : 'epwrp__hideshowwrp__hstext'
                }>
                {hideShowPrivacy.peoplePrivacy ? 'Show' : 'Hide'}
              </div>
              <SlidingButton
                isSliding={hideShowPrivacy.peoplePrivacy}
                onClick={() =>
                  setHideShowPrivacy((prevState) => ({
                    ...prevState,
                    peoplePrivacy: !hideShowPrivacy.peoplePrivacy,
                  }))
                }
              />
            </div>
          </div>
        </div>
        <div className="epwrp__hideshowwrp__">
          <div className="epwrp__hideshowwrp__label">
            Teams of my enterprise
          </div>
          <div className="epwrp__hideshowwrp__btn">
            <div className="epwrp__hideshowwrp__content">
              <div
                className={
                  hideShowPrivacy.teamsPrivacy
                    ? 'epwrp__hideshowwrp__show-text'
                    : 'epwrp__hideshowwrp__hstext'
                }>
                {hideShowPrivacy.teamsPrivacy ? 'Show' : 'Hide'}
              </div>
              <SlidingButton
                isSliding={hideShowPrivacy.teamsPrivacy}
                onClick={() =>
                  setHideShowPrivacy((prevState) => ({
                    ...prevState,
                    teamsPrivacy: !hideShowPrivacy.teamsPrivacy,
                  }))
                }
              />
            </div>
          </div>
        </div>
        <div className="epwrp__hideshowwrp__">
          <div className="epwrp__hideshowwrp__label">
            Projects of my enterprise
          </div>
          <div className="epwrp__hideshowwrp__btn">
            <div className="epwrp__hideshowwrp__content">
              <div
                className={
                  hideShowPrivacy.projectPrivacy
                    ? 'epwrp__hideshowwrp__show-text'
                    : 'epwrp__hideshowwrp__hstext'
                }>
                {hideShowPrivacy.projectPrivacy ? 'Show' : 'Hide'}
              </div>
              <SlidingButton
                isSliding={hideShowPrivacy.projectPrivacy}
                onClick={() =>
                  setHideShowPrivacy((prevState) => ({
                    ...prevState,
                    projectPrivacy: !hideShowPrivacy.projectPrivacy,
                  }))
                }
              />
            </div>
          </div>
        </div>
        <div className="epwrp__submit-button">
          <CustomButton
            customPadding="16px 38px"
            label="Save"
            variant="solid"
            color="themePrimary"
            loading={
              context.busy.type === 'UPDATE_ENTERPRISE_PRIVACY' &&
              context.busy.status
            }
            onClick={() => updatePrivacyDetails()}
          />
        </div>
      </div>
    </EnterprisePrivacyWrp>
  );
};
