const { Box } = require('components');

const Location = ({ address }) => {
  return (
    <div className="row mb-2 enterprise-details-container">
      <div className="col-12">
        <Box mt="16px">
          <p className="label">
            {address?.locationType === 'Primary_Location'
              ? 'Primary Location'
              : 'Other Location'}
          </p>
        </Box>
      </div>
      <div className="col-xl-6 col-xxl-5 col-sm-6 col-12">
        <Box mt="12px">
          <p className="label">Country</p>
          <p className="value">{address?.country ? address.country : 'NA'}</p>
        </Box>
      </div>
      <div className="col-xl-6 col-xxl-4 col-sm-6 col-12">
        <Box mt="12px">
          <p className="label">State</p>
          <p className="value">{address?.state ? address?.state : 'NA'}</p>
        </Box>
      </div>
      <div className="col-xl-6 col-xxl-3 col-sm-6 col-12">
        <Box mt="12px">
          <p className="label">City</p>
          <p className="value">{address?.city ? address?.city : 'NA'}</p>
        </Box>
      </div>
      <div className="col-12">
        <Box mt="12px">
          <p className="label">Address</p>
          <p className="value">{address?.address ? address?.address : 'NA'}</p>
        </Box>
      </div>
    </div>
  );
};

export default Location;
