import { useCallback, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import cx from 'classname';
import { LanguageSelect } from 'components';
import { NavMenu } from './NavMenu';
import hamBurgerBlack from '../../assests/images/hamBurgerBlack.png';
import './navBarWhite.scss';
import { LanguageData } from 'constants';

export const NavbarBrand1 = (props) => {
  let {
    navMenu,
    userProfile,
    handleLocaleChange,
    currentLocale,
    hasNotificationBar = true,
  } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const ACTIVE_STATE = location?.state?.active
    ? location?.state?.active
    : navMenu[0].id;

  const isSmallEnterpriseUserRole = userProfile.role === 'SMALL_ENTERPRISE';

  const [active, setActive] = useState(ACTIVE_STATE);
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const [selectedDropdownMenu, setSelectedDropdownMenu] = useState('');
  const [openDropdown, setOpenDropdown] = useState(false);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  const onNavMenuClick = useCallback(
    (item) => {
      setActive(item.id);
      setOpenDropdown(!openDropdown);
      if (item?.url && !item?.isDropdown) {
        navigate(`${item.url}`, { state: { active: item.id } });
      }
    },
    [navigate, openDropdown]
  );

  const onClickDropdownMenu = useCallback(
    (item) => {
      setSelectedDropdownMenu(item.id);
      setOpenDropdown(false);
      if (item?.url) {
        navigate(`${item.url}`, { replace: true });
      }
    },
    [navigate]
  );

  return (
    <>
      <nav className="navbar navbar-expand-lg brand1-navbar-wrapper">
        <div className="container-fluid brand1-navbar-wrapper_container">
          <div className="logo-section">
            <a className="navbar-brand" href={process.env.REACT_APP_BASE_URL}>
              <img
                src={process.env.PUBLIC_URL + '/new-logo.png'}
                alt="Logo"
                className="team-logo homepage-logo"
              />
            </a>
            {isSmallEnterpriseUserRole && <div className="stick" />}
            {isSmallEnterpriseUserRole && userProfile?.enterpriseLogo ? (
              <div className="image-wrapper">
                <img
                  src={userProfile.enterpriseLogo}
                  alt="Enterprise logo"
                  className="enterprise-logo"
                />
              </div>
            ) : null}
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerBrand1"
            aria-controls="navbarTogglerBrand1"
            aria-label="Toggle navigation"
            data-toggle="collapse"
            data-target="#navbarTogglerBrand1"
            aria-expanded={!isNavCollapsed ? true : false}
            onClick={handleNavCollapse}>
            <img src={hamBurgerBlack} className="ham-burger" alt="ham-burger" />
          </button>
          <div
            className={cx(
              isNavCollapsed && 'collapse',
              'navbar-collapse',
              (!userProfile || Object.keys(userProfile).length === 0) &&
                'no-logged-in'
            )}
            id="navbarTogglerBrand1">
            <NavMenu
              setOpenDropdown={setOpenDropdown}
              hasNotificationBar={hasNotificationBar}
              active={active}
              navMenuList={navMenu}
              onClickDropdownMenu={onClickDropdownMenu}
              onNavMenuClick={onNavMenuClick}
              openDropdown={openDropdown}
              selectedDropdownMenu={selectedDropdownMenu}
              userProfile={userProfile}
            />
            <LanguageSelect
              option_items={LanguageData}
              color="#404F67"
              value={currentLocale}
              onChange={handleLocaleChange}
            />
          </div>
        </div>
      </nav>
    </>
  );
};
