import styled from 'styled-components';

export const ViewTaskWrapper = styled.div`
  .scroll-container .scrollable-div {
    padding: 0;
  }
  .view-task {
    margin-top: 20px;
    margin-bottom: 10px;

    .scrollable-div {
      padding: 0;
    }
    &__top-card {
      background: #f4f7fc;
      border-radius: 10px;
      padding: 20px;
      margin-top: 20px;
      &--label {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #404f67;
        white-space: nowrap;
        margin-right: 7px;
      }
      &--value {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #404f67;
      }
    }

    &__task-title {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: auto;
      color: #17191d;
      margin-bottom: 20px;
    }
    &__task-description {
      color: #404f67;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
    }
    &__attachment {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #404f67;
      margin-bottom: 30px;
      margin-top: 20px;
    }

    &__go-to-stream-btn {
      width: 16%;
      text-decoration: underline;
      color: #0abd00;
    }

    &__users {
      margin-bottom: 35px;
      margin-top: 35px;
      display: flex;
      margin-left: 1%;
    }

    &__gap {
      gap: 20px;
      margin-top: 12px;
      margin-bottom: 5px;
    }
    &__task-comment {
      & .comments-wrapper .comment-input_area .comment-input_box {
        border-bottom: 1px solid #929daf;
        background: #ffffff;
        border-radius: unset;
      }
    }
    &__attachments {
      display: grid;
      gap: 12px;
      align-items: center;

      &__img-wrapper {
        display: flex;
        align-items: center;
        column-gap: 4px;
        width: 100%;
      }
      &__text {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #404f67;
        text-decoration: underline;
        &:hover {
          color: #219fff;
          cursor: pointer;
        }
      }
    }
    &__edit-input {
      width: 100%;
      background-color: #f4f7fc;
      border: none;
      outline: none;
      padding: 10px;
      border-radius: 6px;
      &:focus {
        border: none;
        outline: none;
      }
    }
  }
`;
