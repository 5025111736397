import React from 'react';
import { InfoModal } from '../Components/InfoModal';
import { useNavigate } from 'react-router-dom';

export const SolutionsPage = () => {
  const navigate = useNavigate();

  const handleCloseModal = () => {
    navigate('/');
  };

  return (
    <InfoModal
      open={true}
      title="Exciting Update Coming Soon!"
      onClose={handleCloseModal}
      content="Something exciting is on the horizon! We're preparing to unveil a new update that will enhance your experience with us. Stay tuned for more details."
    />
  );
};
