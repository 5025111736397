import { useState } from 'react';
import styled from 'styled-components';
import { CustomButton, ScrollableDiv, Loader } from 'components';
import createInitialTeams from 'assests/images/createInitialTeams.png';
import Icon from 'utlils/Icons/Icon';
import { CreateJob } from '../CreateJobs';

const ViewJobWrapper = styled.div`
  .invitation-row {
    display: flex;
    justify-content: space-between;
  }
  .title {
    color: #404f67;
    font-weight: 600;
    font-size: 15px;
    display: inline;
  }
  .accept-reject-button {
    margin-top: 10px;
    padding: auto;
  }
  .invitation-icon {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #e2ffe0;
    .icon {
      margin-left: 5px;
    }
    .no-approvals {
      color: #404f67;
      font-weight: 500;
      font-size: 15px;
    }
  }
`;
export const ViewJobApproval = ({
  jobApprovalList,
  userProfile,
  userType,
  context,
  setViewJobApproval,
  teamIdFromProps,
  getPostedJobsList,
  jobId,
  setJobid,
}) => {
  const [viewJob, setViewJob] = useState(false);

  return (
    <ViewJobWrapper>
      {context.busy.status ? (
        <div className=" main-wrapper d-flex justify-content-center align-items-center w-100">
          <Loader loading={context.busy.status} />
        </div>
      ) : !viewJob ? (
        jobApprovalList?.length ? (
          <ScrollableDiv>
            {jobApprovalList?.map((jobs, index) => (
              <div className="invitation-row" key={index}>
                <div className="d-flex align-items-center gap-3 mt-3">
                  <div className="invitation-icon">
                    <Icon
                      name="invitation"
                      size={25}
                      className="icon"
                      color="#0ABD00"
                    />
                  </div>
                  <div>
                    <span className="title">{jobs.name}</span>
                    {' requested you to approve Job Opening of '}
                    <span className="title">{jobs.title}</span>
                    {' for project'}&nbsp;
                    <span className="title">{jobs.project}</span>
                  </div>
                </div>
                <div className="accept-reject-button mt-3">
                  <CustomButton
                    label="View Opening"
                    color="themeSecondary"
                    onClick={() => {
                      setViewJob(true);
                      setJobid(jobs.jobId);
                    }}
                  />
                </div>
              </div>
            ))}
          </ScrollableDiv>
        ) : (
          <div className="d-flex align-items-center justify-content-center mt-3">
            <div>
              <p className="no-approvals ">
                {'Sorry! no request for job approval sent yet !'}
              </p>
              <img src={createInitialTeams} width="320px" alt="noData" />
            </div>
          </div>
        )
      ) : (
        <div>
          {!context.busy.status && (
            <>
              <ScrollableDiv>
                <CreateJob
                  userProfile={userProfile}
                  userType={userType}
                  jobIdFromProps={jobId}
                  setViewJobApproval={setViewJobApproval}
                  teamIdFromProps={teamIdFromProps}
                  getPostedJobsList={getPostedJobsList}
                />
              </ScrollableDiv>
            </>
          )}
        </div>
      )}
    </ViewJobWrapper>
  );
};
