import React from 'react';
import { DocumentEvent } from './DocumentEvent';

export class ClickOutside extends React.Component {
  render() {
    let {
      active,
      children,
      onClickOutside,
      style,
      evt = 'click',
      passive,
      className,
    } = this.props;

    return (
      <div
        ref={(ref) => (this.container = ref)}
        style={style}
        className={className}>
        {active && (
          <DocumentEvent
            name={evt}
            passive={passive}
            handler={(e) => {
              if (
                !this.container.contains(e.target) &&
                e.target.contains(this.container)
              ) {
                onClickOutside();
              }
            }}
          />
        )}
        {children}
      </div>
    );
  }
}
