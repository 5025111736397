import { useState, useEffect, useCallback } from 'react';
import { CustomTable, Loader } from 'components';
import { FETCH_ERECORD_TECH_STACK, useAxiosWrapper } from 'services';
import tokenService from 'services/token.service';
import { SKILLS_TECHNOLOGY_HEADERS } from 'pages/IndividualDashboard/IndividualPersonalSection/individualTeams/helper';
import NoDataImg from '../../../../../assests/images/nodata.png';
import './viewSkillsTechnology.scss';

export const ViewSkillsAndTechnology = () => {
  const [fnWrapper, context] = useAxiosWrapper();
  const [selectedTech, setSelectedTech] = useState({ technology: [] });
  const [selectedRadios, setSelectedRadios] = useState([]);

  const loggedInUser = tokenService.getSession();

  const getERecordTechnologyList = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_ERECORD_TECH_STACK}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_ERECORD_TECH_STACK',
    });
    if (res.data.statusCode === 200) {
      let result = [];

      if (res.data.data.technology) {
        for (const item of res.data.data.technology) {
          const obj = {
            id: item?.id,
            title: item?.title,
            value: item?.id,
            experience: item?.experience,
          };
          result.push(obj);
        }
      }
      setSelectedTech({ technology: result });
      setSelectedRadios(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const radioCheckHandler = (item, header) => {
    const isChecked = selectedRadios.some(
      (radio) => radio.value === item.id && radio.experience === header.title
    );

    return isChecked;
  };

  useEffect(() => {
    getERecordTechnologyList();
  }, [getERecordTechnologyList]);

  return (
    <>
      {context.busy.status ? (
        <Loader loading={context.busy.status} />
      ) : (
        <div className="skills-tech-container">
          <div>
            {selectedTech.technology.length > 0 ? (
              <div>
                <CustomTable
                  isLoading={context.busy.status}
                  rows={selectedTech.technology.map((item) => ({
                    id: item?.id,
                    technologyName: item?.title,
                  }))}
                  headers={SKILLS_TECHNOLOGY_HEADERS}
                  selectedRadios={selectedRadios}
                  isRadioDisabled={true}
                  radioCheckHandler={radioCheckHandler}
                />
              </div>
            ) : (
              <div className="empty-data-section">
                <img
                  src={NoDataImg}
                  alt="No Data"
                  className="empty-data-section--img"
                />
                <div className="empty-data-section--text">
                  No data available
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
