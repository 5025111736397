import React from 'react';
import classnames from 'classnames';

import { usePagination, DOTS } from './usePagination';
import './pagination.scss';
import { Box } from '../box';
import Icon from '../../utlils/Icons/Icon';

export const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul
      className={classnames('pagination-container', {
        [className]: className,
      })}>
      <li
        className={classnames('pagination-item', {
          disabled: currentPage === 1,
        })}
        onClick={onPrevious}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-around"
          padding="10px">
          <Icon
            color={'#219fff'}
            name={'arrow-right'}
            className={'icon-rotate'}
            size={15}
          />{' '}
          Previous
        </Box>
      </li>
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return (
            <li className="pagination-item dots" key={index}>
              &#8230;
            </li>
          );
        }

        return (
          <li
            key={index}
            className={classnames('pagination-item', {
              selected: pageNumber === currentPage,
            })}
            onClick={() => onPageChange(pageNumber)}>
            {pageNumber}
          </li>
        );
      })}
      <li
        className={classnames('pagination-item', {
          disabled: currentPage === lastPage,
        })}
        onClick={onNext}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-around"
          padding="10px">
          Next{' '}
          <Icon color={classnames('#219fff')} name={'arrow-right'} size={15} />
        </Box>
      </li>
    </ul>
  );
};
