import React, { useContext } from 'react';
import { AllNotificationsWrapper } from './style';
import { INDIVIDUAL_DASHBOARD_NAV_LIST } from 'pages/IndividualDashboard/constants';
import { CustomButton, Loader, NavbarBrand1, ScrollableDiv } from 'components';
import { useCallback } from 'react';
import tokenService from 'services/token.service';
import NO_NOTIFICATION_IMAGE from '../../assests/images/Notification-image.png';
import {
  CLEAR_NOTIFICATIONS,
  FETCH_ALL_NOTIFICATIONS,
  FETCH_USER_PROFILE,
  MARK_ALL_NOTIFICATIONS_AS_READ,
  useAxiosWrapper,
} from 'services';
import { useState } from 'react';
import { getDateTime, getProfileText } from 'utlils/utils';
import { useEffect } from 'react';
import Icon from 'utlils/Icons/Icon';
import { SettingsFilter } from './settingsFilter';
import { setAllNotifications } from 'context/Notifications/notificationAction';
import { NotificationContext } from 'context/Notifications/notificationContext';
import { NOTIFICATION_ICON } from './helper';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { createNotificationRedirects } from './constant';

export const AllNotifications = (props) => {
  const [fnWrapper, context] = useAxiosWrapper();
  const navigate = useNavigate();
  const { notificationState, notificationDispatch } =
    useContext(NotificationContext);
  const allNotifications = notificationState.notifications;

  const { handleLocaleChange, currentLocale } = props || {};
  const [userProfile, setUserProfile] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const loggedInUser = tokenService.getSession();

  // fetch userprofile
  const getUserProfile = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_USER_PROFILE}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_USER_PROFILE',
    });
    if (res.data.statusCode === 200) {
      const response = res.data.data;
      setUserProfile({
        ...response,
        profileText: getProfileText(
          response?.role === 'INDIVIDUAL'
            ? response?.name
            : response?.enterpriseName
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserNotifications = useCallback(async () => {
    const loggedInUser = tokenService.getSession();
    await fnWrapper({
      url: MARK_ALL_NOTIFICATIONS_AS_READ,
      method: 'PUT',
      type: 'MARK_ALL_NOTIFICATIONS_AS_READ',
      payload: {
        userId: loggedInUser?.id,
      },
    });
    const res = await fnWrapper({
      url: FETCH_ALL_NOTIFICATIONS,
      method: 'GET',
      type: 'FETCH_ALL_NOTIFICATIONS',
      params: {
        userId: loggedInUser.id,
      },
    });
    if (res.data.statusCode === 200) {
      const response = res.data.data;
      await setAllNotifications(notificationDispatch, response);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAllService = useCallback(async () => {
    setIsLoading(true);
    await getUserProfile();
    await getUserNotifications();
    setIsLoading(false);
  }, [getUserNotifications, getUserProfile]);

  useEffect(() => {
    fetchAllService();
  }, [fetchAllService]);

  const clearNotificationsHandler = async (type, messageId) => {
    const loggedInUser = tokenService.getSession();
    const payload = {
      isClearAll: type === 'CLEAR_ALL',
      userId: loggedInUser?.id,
      messageId,
    };
    const res = await fnWrapper({
      url: CLEAR_NOTIFICATIONS,
      method: 'PUT',
      type: 'CLEAR_NOTIFICATIONS',
      payload,
    });
    if (res?.data?.statusCode === 200) {
      setAllNotifications(notificationDispatch, res?.data?.data);
    }
  };
  const notificationClick = (type, id) => {
    const role = loggedInUser.role;
    if (
      type ===
      createNotificationRedirects('', role)?.joinDepartmentRequestOnCreate?.type
    ) {
      navigate(
        createNotificationRedirects('', role)?.joinDepartmentRequestOnCreate
          ?.url
      );
    } else if (
      type ===
      createNotificationRedirects('', role)?.joinDepartmentRequestOnUpdate?.type
    ) {
      navigate(
        createNotificationRedirects('', role)?.joinDepartmentRequestOnUpdate
          ?.url
      );
    } else if (
      type ===
      createNotificationRedirects('', role)?.joinConnectionRequest?.type
    ) {
      navigate(
        createNotificationRedirects('', role)?.joinConnectionRequest?.url
      );
    } else if (
      type === createNotificationRedirects('', role)?.joinGroupRequest?.type
    ) {
      navigate(createNotificationRedirects('', role)?.joinGroupRequest?.url);
    } else if (
      type ===
      createNotificationRedirects('', role)?.acceptConnectionRequest?.type
    ) {
      navigate(
        createNotificationRedirects('', role)?.acceptConnectionRequest?.url
      );
    } else if (
      type === createNotificationRedirects(id, role)?.acceptTeamInvitation?.type
    ) {
      navigate(
        createNotificationRedirects(id, role)?.acceptTeamInvitation?.url
      );
    } else if (
      type === createNotificationRedirects(id, role)?.rejectTeamInvitation?.type
    ) {
      navigate(
        createNotificationRedirects(id, role)?.rejectTeamInvitation?.url
      );
    } else if (
      type === createNotificationRedirects(id, role)?.joinTeamRequest?.type
    ) {
      navigate(createNotificationRedirects(id, role)?.joinTeamRequest?.url);
    } else if (
      type ===
      createNotificationRedirects('', role)?.joinTeamRequestOnCreate?.type
    ) {
      navigate(
        createNotificationRedirects('', role)?.joinTeamRequestOnCreate?.url
      );
    } else if (
      type === createNotificationRedirects('', role)?.postCreate?.type
    ) {
      navigate(createNotificationRedirects('', role)?.postCreate?.url);
    } else if (
      type ===
      createNotificationRedirects(id, role)?.acceptDepartmentInvitation?.type
    ) {
      navigate(
        createNotificationRedirects(id, role)?.acceptDepartmentInvitation?.url
      );
    } else if (
      type ===
      createNotificationRedirects(id, role)?.joinProjectRequestTeam?.type
    ) {
      navigate(
        createNotificationRedirects(id, role)?.joinProjectRequestTeam?.url
      );
    } else if (
      type ===
      createNotificationRedirects(id, role)?.acceptJoinGroupRequest?.type
    ) {
      navigate(
        createNotificationRedirects(id, role)?.acceptJoinGroupRequest?.url
      );
    } else if (
      type === createNotificationRedirects(id, role)?.applyJobRequest?.type
    ) {
      navigate(createNotificationRedirects(id, role)?.applyJobRequest?.url);
    } else if (
      type === createNotificationRedirects(id, role)?.joinProjectRequest?.type
    ) {
      navigate(createNotificationRedirects(id, role)?.joinProjectRequest?.url);
    } else if (
      type ===
      createNotificationRedirects(id, role)?.joinProjectRequestOnUpdate?.type
    ) {
      navigate(
        createNotificationRedirects(id, role)?.joinProjectRequestOnUpdate?.url
      );
    } else if (
      type === createNotificationRedirects('', role)?.joinJobRequest?.type
    ) {
      navigate(createNotificationRedirects('', role)?.joinJobRequest?.url);
    } else if (
      type ===
      createNotificationRedirects(id, role)?.accpetProjectTeamJoiningInfo?.type
    ) {
      navigate(
        createNotificationRedirects(id, role)?.accpetProjectTeamJoiningInfo?.url
      );
    } else if (
      type ===
      createNotificationRedirects(id, role)?.rejectProjectTeamJoiningInfo?.type
    ) {
      navigate(
        createNotificationRedirects(id, role)?.rejectProjectTeamJoiningInfo?.url
      );
    } else if (
      type === createNotificationRedirects(id, role)?.acceptProjectRequest?.type
    ) {
      navigate(
        createNotificationRedirects(id, role)?.acceptProjectRequest?.url
      );
    } else if (
      type ===
      createNotificationRedirects(id, role)?.createPublicationForGroup?.type
    ) {
      navigate(
        createNotificationRedirects(id, role)?.createPublicationForGroup?.url
      );
    } else if (
      type ===
      createNotificationRedirects(id, role)?.createPublicationForTeam?.type
    ) {
      navigate(
        createNotificationRedirects(id, role)?.createPublicationForTeam?.url
      );
    } else if (
      type === createNotificationRedirects(id, role)?.createDepartmentTask?.type
    ) {
      navigate(
        createNotificationRedirects(id, role)?.createDepartmentTask?.url
      );
    } else if (
      type ===
      createNotificationRedirects(id, role)?.teamApprovalForDepartmentAdmin
        ?.type
    ) {
      navigate(
        createNotificationRedirects(id, role)?.teamApprovalForDepartmentAdmin
          ?.url
      );
    } else if (
      type ===
      createNotificationRedirects(id, role)
        ?.approveTeamRequestOfDepartmentBySuperAdmin?.type
    ) {
      navigate(
        createNotificationRedirects(id, role)
          ?.approveTeamRequestOfDepartmentBySuperAdmin?.url
      );
    } else if (
      type ===
      createNotificationRedirects(id, role)?.joinTeamRequestBySuperAdmin?.type
    ) {
      navigate(
        createNotificationRedirects(id, role)?.joinTeamRequestBySuperAdmin?.url
      );
    }
    if (type === createNotificationRedirects(id, role)?.createPost?.type) {
      navigate(createNotificationRedirects(id, role)?.createPost?.url);
    }
    if (
      type === createNotificationRedirects(id, role)?.createPostComment?.type
    ) {
      navigate(createNotificationRedirects(id, role)?.createPostComment?.url);
    }
    if (
      type ===
      createNotificationRedirects(id, role)?.createPostCommentForAdmin?.type
    ) {
      navigate(
        createNotificationRedirects(id, role)?.createPostCommentForAdmin?.url
      );
    }
    if (
      type ===
      createNotificationRedirects(id, role)?.createProjectTaskToTeam?.type
    ) {
      navigate(
        createNotificationRedirects(id, role)?.createProjectTaskToTeam?.url
      );
    }
    if (
      type === createNotificationRedirects(id, role)?.assignDeptTaskToTeam?.type
    ) {
      navigate(
        createNotificationRedirects(id, role)?.assignDeptTaskToTeam?.url
      );
    }
    if (
      type ===
      createNotificationRedirects(id, role)?.assignDeptTaskToYourTeam?.type
    ) {
      navigate(
        createNotificationRedirects(id, role)?.assignDeptTaskToYourTeam?.url
      );
    }
    if (
      type ===
      createNotificationRedirects(id, role)?.assignProjectTaskToMember?.type
    ) {
      navigate(
        createNotificationRedirects(id, role)?.assignProjectTaskToMember?.url
      );
    }
    if (
      type ===
      createNotificationRedirects(id, role)?.assignDeptTaskToMember?.type
    ) {
      navigate(
        createNotificationRedirects(id, role)?.assignDeptTaskToMember?.url
      );
    }
    if (
      type === createNotificationRedirects(id, role)?.commentOnDeptTask?.type
    ) {
      navigate(createNotificationRedirects(id, role)?.commentOnDeptTask?.url);
    }
    if (
      type ===
      createNotificationRedirects(id, role)?.commentOnDeptTaskMember?.type
    ) {
      navigate(
        createNotificationRedirects(id, role)?.commentOnDeptTaskMember?.url
      );
    }
    if (
      type === createNotificationRedirects(id, role)?.commentOnProjectTask?.type
    ) {
      navigate(
        createNotificationRedirects(id, role)?.commentOnProjectTask?.url
      );
    }
  };

  return isLoading ? (
    <Loader loading={isLoading} />
  ) : (
    <AllNotificationsWrapper className="all-notifications-wrp">
      <div className="all-notifications_navbar">
        <NavbarBrand1
          navMenu={INDIVIDUAL_DASHBOARD_NAV_LIST(userProfile?.role)}
          currentLocale={currentLocale}
          handleLocaleChange={handleLocaleChange}
          userProfile={userProfile}
        />
      </div>
      <div className="all-notifications-wrp d-flex justify-content-center">
        <div className="all-notifications-wrp content__">
          <div className="row align-items-center mb-2">
            <div className="col-sm-6 col-lg-6 col-md-6">
              <div className="all-notifications-wrp content__header">
                All Notifications
              </div>
            </div>
            <div className="col-sm-6 col-lg-6 col-md-6">
              <div className="all-notifications-wrp content__btn d-flex justify-content-end align-items-center">
                {/* <CustomButton
                  color={isSettingsOpen ? 'themeSecondary' : 'themeGray'}
                  customPadding="12px"
                  variant="light"
                  onClick={() => setIsSettingsOpen(!isSettingsOpen)}>
                  <Icon
                    name="setting"
                    color={isSettingsOpen ? '#219fff' : '#929daf'}
                    size={22}
                  />
                </CustomButton> */}
                <CustomButton
                  label="Clear all"
                  variant="textBtn"
                  customPadding="0px"
                  className="notification-container-wrp__header-btn"
                  onClick={() => clearNotificationsHandler('CLEAR_ALL', null)}
                  disabled={allNotifications.length === 0}
                  loading={
                    context?.busy?.status &&
                    context?.busy?.type === 'CLEAR_NOTIFICATIONS'
                  }
                />
              </div>
            </div>
          </div>
          <div className="all-notifications-wrp content__line" />
          <ScrollableDiv scrollOnHeight="calc(100vh - 250px)">
            {allNotifications.length ? (
              allNotifications.map((notification, index) => {
                const isSmallEnterpriseRole =
                  notification?.sender?.role === 'SMALL_ENTERPRISE';
                const senderName = isSmallEnterpriseRole
                  ? notification?.sender?.smallEnterprise?.enterpriseName
                  : notification?.sender?.individual?.name;

                const iconConfig = NOTIFICATION_ICON[
                  notification?.notificationType
                ] || {
                  name: 'invitations',
                  size: 20,
                  color: '#0ABD00',
                  background: {
                    type: 'rounded',
                    color: '#E2FFE0',
                    size: 28,
                  },
                };

                return (
                  <div key={notification?.id + '--' + index}>
                    <div
                      className={classNames(
                        'row align-items-center mx-0 pt-4 pb-4 notification-card',
                        notification?.status === 'UNREAD' && 'unread-block'
                      )}>
                      <div className="col-sm-1 col-lg-1 col-md-1 align-self-start">
                        <Icon {...iconConfig} />
                      </div>
                      <div
                        role="button"
                        className="col-sm-9 col-lg-9 col-md-9"
                        onClick={() =>
                          notificationClick(
                            notification?.notificationType,
                            notification?.navigateId
                          )
                        }>
                        <div className="notify-items">
                          {senderName}{' '}
                          <span className="notification-title">
                            {notification?.message}{' '}
                          </span>
                        </div>
                        <div className="all-notifications-wrp time-duration">
                          {getDateTime(notification?.createdAt)}
                        </div>
                      </div>
                      <div className="col-sm-2 col-lg-2 col-md-2">
                        <div className="all-notifications-wrp content__btn d-flex justify-content-end align-items-center">
                          <CustomButton
                            color="themeGray"
                            variant="textBtn"
                            customPadding="0px"
                            onClick={() =>
                              clearNotificationsHandler(
                                'CLEAR_ONE',
                                notification?.id
                              )
                            }
                            disabled={
                              context?.busy?.status &&
                              context?.busy?.type === 'CLEAR_NOTIFICATIONS'
                            }
                            loading={
                              context?.busy?.status &&
                              context?.busy?.type === 'CLEAR_NOTIFICATIONS'
                            }>
                            <Icon name="cross" color={'#0abd00'} size={24} />
                          </CustomButton>
                        </div>
                      </div>
                    </div>
                    <div className="all-notifications-wrp content__line" />
                  </div>
                );
              })
            ) : (
              <div className="no-all-notification-section">
                <img
                  src={NO_NOTIFICATION_IMAGE}
                  className="No-notification-image"
                  alt="no-notification"
                />
                No notification available
              </div>
            )}
          </ScrollableDiv>
        </div>
      </div>
      {isSettingsOpen ? <SettingsFilter></SettingsFilter> : null}
    </AllNotificationsWrapper>
  );
};
