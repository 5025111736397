import { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CustomButton } from 'components';
import { useAxiosWrapper, FETCH_USER_PROFILE } from 'services';
import Icon from 'utlils/Icons/Icon';
import { appliedByHelper } from '../../helper';
import { ProfilePhoto } from 'pages/Explore/Components';
import { ViewApplicantWrapper } from './style';

export const ViewApplicant = (props) => {
  const { applicantDetails } = props;
  const questions = JSON.parse(applicantDetails?.question);
  const [fnWrapper] = useAxiosWrapper();
  const [user, setUser] = useState(null);
  const appliedBy = appliedByHelper({
    isProfile: applicantDetails?.profileUrl,
    isERecord: applicantDetails?.eRecord,
    isResume: applicantDetails?.resume,
  });
  const getUserProfile = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_USER_PROFILE}/${applicantDetails?.userId}`,

      method: 'GET',
      type: 'FETCH_USER_PROFILE',
    });
    if (res.data.statusCode === 200) {
      const response = res.data.data;
      setUser({
        name: response.name,
        title: response.title,
        profilePicture: response.profilePicture,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (appliedBy === 'Profile' && applicantDetails?.userId) {
      getUserProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUserProfile]);

  return (
    <ViewApplicantWrapper className="view-application">
      <span className="title">{'Applied with'}</span>
      <div className="view-application__profile--details">
        {appliedBy === 'Profile' && (
          <div className="d-flex align-items-center gap-3">
            <ProfilePhoto
              profilePicture={user?.profilePicture}
              name={user?.name}
              size={70}
            />
            <div className="userDetails">
              <div className="user-name">{user?.name}</div>
              <div className="user-title">{user?.title}</div>
            </div>
            <div className="view-profile-button">
              <CustomButton
                label="View Profile"
                color="themeSecondary"
                variant="outline"
                onClick={() => window.open(applicantDetails?.profileUrl)}
              />
            </div>
          </div>
        )}
        {(appliedBy === 'E-Record' || appliedBy === 'Resume') && (
          <div className="d-flex align-items-center gap-3">
            <Icon
              name={appliedBy === 'E-Record' ? 'personalCard' : 'note'}
              size={45}
              color="#404F67"
            />
            <div className="d-flex flex-column">
              <div className="applied-by">{appliedBy}</div>
              <CustomButton
                color="themeSecondary"
                customPadding="2px 0"
                variant="textBtn"
                label={appliedBy === 'E-Record' ? 'View' : 'Resume.pdf'}
                onClick={() =>
                  appliedBy === 'E-Record'
                    ? window.open(applicantDetails?.eRecord)
                    : window.open(applicantDetails?.resume)
                }
              />
            </div>
          </div>
        )}
      </div>
      <div className="view-application__question">
        {applicantDetails?.question !== null &&
          Object.keys(questions).map(
            (items, index) =>
              questions[items] && (
                <div className="main-wrp" key={index}>
                  <div className="title">
                    {index + 1}: {`${items}`}
                  </div>
                  <div className="applied-answer">{questions[items]}</div>
                </div>
              )
          )}
      </div>
      <div className="view-application__specialiazation d-flex align-items-center gap-2">
        {applicantDetails?.technology?.length
          ? applicantDetails?.technology?.map((tech) => (
              <div className="tech-stack d-flex align-items-center justify-content-center">
                {tech?.name}
              </div>
            ))
          : applicantDetails?.specialization?.map((tech) => (
              <div className="tech-stack d-flex align-items-center justify-content-center">
                {tech?.name}
              </div>
            ))}
      </div>
    </ViewApplicantWrapper>
  );
};

ViewApplicant.propTypes = {
  applicantDetails: PropTypes.shape({
    userId: PropTypes.string,
    profileUrl: PropTypes.string,
    eRecord: PropTypes.string,
    resume: PropTypes.string,
    question: PropTypes.object,
    technology: PropTypes.array,
  }).isRequired,
};
