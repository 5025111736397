export const LOGIN = `${process.env.REACT_APP_API_BASE_URL}/login`;
export const FETCH_USER_PROFILE = `getUserProfile`;
export const FETCH_WAITINGLIST_STATUS = `miHint-admin/getMiHintAdminSettings`;
export const FETCH_BASIC_DETAILS = `individualProfile/getBasicDetails`;
export const FETCH_ACHIEVEMENT_DETAILS = `individualProfile/getAchievementDetails`;
export const FETCH_EDUCATIONAL_DETAILS = `individualProfile/getEducationalDetails`;
export const FETCH_LICENSE_DETAILS = `individualProfile/getLicenseAndCertificationDetails`;
export const FETCH_PROFESSIONAL_DETAILS = `individualProfile/getProfessionalDetails`;
export const FETCH_INVITATIONS = `getInvitations`;
export const FETCH_SUPERADMIN_REQUESTS = `miHint-admin`;
export const FETCH_PROJECTS_LIST = `getProjectByDepartment`;
export const FETCH_PROJECT_SERVICES = `getProjectServices`;
export const FETCH_TEAMS_BASED_ON_ENTERPRISE = `getTeamListBasedOnEnterpriseId`;
export const FETCH_TEAMS_BASED_ON_DEPARTMENT_ID = `getTeamsByDepartmentId`;
export const FETCH_POSTED_JOBS_BASEDON_ENTERPRISEID_AND_DEPARTMENTID = `career/job/getPostedJobsBasedOnEnterpriseId`;
export const FETCH_EXISTING_TEAMS = `getExistingTeamList`;
export const FETCH_ENTERPRISE_LIST = `getEnterpriseName`;
export const GET_DEPARTMENT_MEMBER_DETAILS = `getDepartmentMember`;
export const FETCH_TEAMS_BASED_ON_PROJECT = `getTeamListBasedOnProject`;
export const FETCH_PUBLIC_TEAMS = `getPublicTeamList`;
export const FETCH_ENTERPRISE_PROJECTS = `getEnterpriseProjectList`;
export const FETCH_EXISTING_PROJECTS = `getExistingProjectList`;
export const FETCH_PUBLIC_PROJECTS = `getPublicProjectList`;
export const FETCH_DEFAULT_STREAM = `getDefaultStream`;
export const FETCH_PREVIOUS_STREAM = `getPreviousStream`;
export const FETCH_PROJECT_STATUS = `getProjectStatus`;
export const FETCH_POSTS = `getPost`;
export const FETCH_COMMENTS_LIST = `getComment`;
export const FETCH_PROJECT_TASKS_BASED_ON_POST = `task/get/projectTaskBasedOnPost`;
export const FETCH_POST_LIKES = `getPostLikes`;
export const FETCH_DEPARTMENT_DETAILS = `getDepartmentDetails`;
export const FETCH_DEPARTMENT_LIST = `getDepartment`;
export const FETCH_DEPARTMENT_DATA_BASED_ON_MEMBER_ID = `getDepartmentDataByMemberId`;
export const FETCH_PROJECTS_BASED_ON_TEAM = `getProjectDetails`;
export const FETCH_TEAM_DETAILS_BASED_ON_TEAM_ID = `getTeamInfoByTeamId`;
export const FETCH_DEPARTMENT_TASKS = `getDepartmentTasks`;
export const FETCH_PROJECT_TASKS = `getProjectTasks`;
export const FETCH_DEPARTMENT_TASKS_BASED_ON_TEAMID = `getDepartmentByTeamId`;
export const FETCH_PROJECT_TASK_LIST_BASED_ON_PROJECT = `task/get/projectTasks`;
export const FETCH_TEAM_MEMBERS = `getTeamMembers`;
export const FETCH_ASSIGNED_MEMBER_LIST = `task/get/assignedMembers`;
export const FETCH_TEAMS_BASED_ON_USER = `publicProfile/individual/getIndividualTeams`;
export const FETCH_PROJECTS_BASED_ON_USER = `publicProfile/individual/getIndividualProjects`;
export const FETCH_TEAM_REQUESTS = `getProjectInvitation`;
export const FETCH_INDIVIDUAL_PUBLIC_PROFILE_SETTING = `publicProfile/individual/getIndividualSettings`;
export const FETCH_TEAM_PUBLIC_PROFILE_DETAILS = `publicProfile/team/getTeamDetails`;
export const FETCH_PUBLIC_PROJECT_DETAILS = `publicProfile/project/getProjectDetails`;
export const FETCH_PUBLIC_ENTERPRISE_DETAILS = `/publicProfile/enterprise/getEnterpriseDetails`;
export const FETCH_PUBLIC_ENTERPRISE_TEAMS = `/publicProfile/enterprise/getEnterpriseTeam`;
export const FETCH_PUBLIC_ENTERPRISE_PROJECTS = `/publicProfile/enterprise/getEnterpriseProject`;
export const FETCH_PUBLIC_ENTERPRISE_MEMBERS = `/publicProfile/enterprise/getDepartmentMember`;
export const FETCH_PUBLIC_PROJECT_SERVICE = `publicProfile/project/getProjectServices`;
export const FETCH_INDIVIDUAL_PUBLIC_USER_DETAILS = `publicProfile/individual/getUserDetails`;
export const FETCH_PROJECT_SETTINGS = `getProjectSettingData`;
export const FETCH_ALL_PUBLIC_USERS = `explore/network/getAllPublicUsers`;
export const FETCH_ALL_PUBLIC_TEAMS = `explore/team/getAllTeams`;
export const FETCH_ALL_PUBLIC_PROJECTS = `explore/project/getAllProjects`;
export const FETCH_SAVED_TEAMS_BASED_ON_USER = `explore/team/getSavedTeams`;
export const FETCH_SAVED_PROJECTS_BASED_ON_USER = `explore/project/getSavedProjects`;
export const FETCH_TEAM_TYPES = `userType/team`;
export const FETCH_ENTERPRISE_CATEGORY = `getEnterpriseType`;
export const FETCH_CONNECTION_REQUEST_SENT_RECEIVED = `explore/network/getConnectionRequests`;
export const FETCH_ALL_MY_CONNECTED_USERS = `explore/network/getAllMyConnections`;
export const FETCH_DEPARTMENT_MEMBERS = `getTeamMemembers`;
export const FETCH_TEAM_BASED_ON_MEMBER = `getTeamByMid`;
export const FETCH_POSTED_JOBS = `career/job/getPostedJobs`;
export const FETCH_ALL_JOBS = `career/job/getAllJobs`;
export const FETCH_JOB_DETAILS_BASED_ON_JOBID = `career/job/getJob`;
export const FETCH_SAVED_JOBS_BASED_ON_USERID = `career/job/getAllSavedJobs`;
export const FETCH_APPLIED_JOBS_BASED_ON_USERID = `career/job/getAppliedJobs`;
export const FETCH_SHORTLISTED_APPLICANTS = `career/job/getShortlistedApplicants`;
export const FETCH_TEAM_SIZE = `/getTeamSize`;
export const FETCH_USER_ENTERPRISE_LIST = `/getEnterpriseList`;

export const CREATE_BASIC_DETAILS = 'individualProfile/basicDetails';
export const CREATE_SKILLS_AND_TECHNOILOGY =
  'publicProfile/individual/saveTechnologies';
export const FETCH_APPLICANT_DETAILS_BASED_ON_JOBID =
  'career/job/getApplicantDetailsBasedOnJob';
export const FETCH_JOBS_BASED_ON_USER_ID = `publicProfile/individual/getPostedJobsByUserId`;
export const FETCH_JOBS_BASED_ON_PROJECT_ID = `publicProfile/project/getPostedJobs`;
export const FETCH_JOBS_BASED_ON_TEAM_ID = `publicProfile/team/getPostedJobs`;

export const FETCH_PUBLICATIONS = `publication/getPublication`;
export const CREATE_PUBLICATION = `publication/createPublications`;
export const UPDATE_PUBLICATION = `publication/updatePublication`;
export const DELETE_PUBLICATION = `publication/deletePublication`;
export const FETCH_PUBLICATION_BASED_ON_TEAMID = `publication/getPublicationDetails`;
export const GET_TEAMS_BASED_ON_ENTERPRISE_ID = 'getTeamsByEnterprise';
export const FETCH_PUBLICATION_BASED_ON_PUBLICATIONID = `publication/getPublicationDetailBasedOnProject`;
export const FETCH_PUBLICATIONS_BASED_ON_USER_ID = `publication/getPublicationsBasedOnUserId`;
export const USER_RELATED_PUBLICAIONS =
  '/publication/getAllUserRelatedPublications';
export const FETCH_FEED_PUBLICATION = `publication/getAllPublication`;
export const FETCH_PUBLICATION_COMMENTS_LIST = 'publication/commentsList';
export const CREATE_PUBLICATION_COMMENT = 'publication/postComment';
export const DELETE_PUBLICATION_COMMENT = 'publication/deleteComment';

export const CREATE_EDUCATIONAL_DETAILS =
  'individualProfile/educationalDetails';
export const CREATE_LICENSE_DETAILS =
  'individualProfile/licenseAndCertifications';
export const CREATE_PROFESSIONAL_DETAILS =
  'individualProfile/professionalDetails';
export const CREATE_ACHIEVEMENT_DETAILS = 'individualProfile/achievements';
export const CREATE_PROJECT = `createProject`;
export const PUBLISH_PROJECT = `publishProject`;
export const CREATE_POST = `createPost`;
export const CREATE_PUBLISH_TASK = `task/create/projectTask`;
export const CREATE_POST_LIKE = `postLike`;
export const CREATE_POST_COMMENT = `postComment`;
export const CREATE_USER_CONNECTION = `publicProfile/individual/createUserConnections`;
export const CREATE_SAVE_PUBLIC_TEAM = `explore/team/saveTeam`;
export const CREATE_SAVE_PUBLIC_PROJECT = `explore/project/saveProject`;
export const CREATE_JOB_POST = `career/job/create`;
export const CREATE_APPLICANT = 'career/job/createApplicant';
export const SAVE_JOB = `career/job/saveJob`;
export const SEARCH_USER = `searchUsers`;
export const SEND_INVITE_TO_SELECTED_JOB_APPLICANT =
  'career/job/selectApplicants';

export const ACCEPT_DENY_CONNECTION_REQUEST = `explore/network/acceptConnectionRequests`;
export const WITHDRAW_CONNECTION_REQUEST = `explore/network/withdrawRequest`;
export const SET_FINISHED_STAGE = `finishedStage`;
export const SET_STARTED_STAGE = `startStage`;

export const CREATE_GROUP = `group/createGroup`;
export const GROUP_DETAILS = `group/groupDetails`;
export const LEAVE_REMOVE_GROUP = `group/leaveMember`;
export const UPDATE_GROUP_DETAILS = `group/updateGroupDetails`;
export const GET_USER_GROUPS = `group/groupsList`;
export const GET_GROUP_CREATED_BY_USER = `group/getYourGroup`;
export const GET_PUBLIC_GROUPS = `group/publicGroupsList`;
export const SEND_GROUP_INVITATION = `group/addMembersToGroup`;
export const ACCEPT_REJECT_GROUP_INVITATION = `group/acceptOrRejectGroupInvitation`;
export const REQUEST_JOIN_GROUP = 'group/requestToJoinGroup';
export const FETCH_GROUP_MEMBER = 'group/groupMember';
export const GET_GROUP_INVITES_LIST = 'group//sentRequestsList';
export const GET_GROUP_JOIN_REQUESTS = 'group/joinRequestsList';
export const ACCEPT_DENY_GROUP_JOIN_REQUEST = `group/acceptOrRejectJoinRequest`;

export const UPDATE_PROJECT = 'updateProject';
export const UPDATE_DRAFT_PROJECT = `updateDraftProject`;
export const UPDATE_TEAM_MEMBERS = `removeTeamMembers`;
export const UPDATE_ASSIGNED_DEPARTMENT_MEMBERS = `assignDepartmentTaskToMember`;
export const UPDATE_ASSIGNED_PROJECT_MEMBERS = `assignProjectTaskToMember`;
export const UPDATE_DEPARTMENT_TASK_STATUS_AS_COMPLETED = `changeDepartmentTaskStatus`;
export const UPDATE_PROJECT_TASK_STATUS_AS_COMPLETED = `changeProjectTaskStatus`;
export const UPDATE_PROJECT_INVITATION = `acceptProjectTeamInvitation`;
export const UPDATE_USER_PROFILE = `publicProfile/individual/updateIndividualProfile`;
export const UPDATE_HIDDEN_TEAM_STATUS = `publicProfile/individual/hideIndividualTeam`;
export const UPDATE_HIDDEN_PROJECT_STATUS = `publicProfile/individual/hideIndividualProject`;
export const UPDATE_PROJECT_SETTINGS = `updateprojectSettingData`;
export const ACCEPT_OR_REJECT_TEAM_INVITATION = 'acceptOrRejectInvitation';
export const ACCEPT_OR_REJECT_DEPARTMENT_INVITATION =
  'acceptAndRejectDepartmentInvitation';
export const UPDATE_HIRING_STATUS = 'career/job/updateHiringStatus';

export const FETCH_ALL_DEPARTMENT_LIST = 'getDepartmentDropdownData';
export const FETCH_ALL_DEPARTMENT_TASKS = 'task/get/departmentTask';
export const FETCH_TASK_BY_ID = 'task/get/taskById';
export const FETCH_TEAM_BY_DEPT_ID = 'getTeamsByDepartmentId';
export const FETCH_PROJECT_MANAGETASK_LIST = 'task/get/projectTaskStatus';
export const FETCH_DEPARTMENT_MANAGETASK_LIST = 'task/get/departmentTaskStatus';
export const FETCH_PROJECT_BOARD_FIELDS = `task/get/projectTaskStatus`;
export const FETCH_DEPARTMENT_BOARD_FIELDS = `task/get/departmentTaskStatus`;
export const FETCH_DEPARTMENTS_BASED_ON_USER = `task/get/departmentListBasedOnUserId`;

export const UPDATE_DEPARTMENT_TASK_STATUS = 'task/update/departmentTaskStatus';
export const CREATE_DEPARTMENT_TASKS = 'task/create/departmentTask';
export const UPDATE_DEPARTMENT_TASKS = 'task/update/departmentTask';
export const REMOVE_DEPARTMENT = 'task/removeDepartment';
export const DELETE_TASKS = `/task/remove/task`;
export const ACCEPT_REJECT_TEAM_APPROVAL = 'acceptAndRejectTeamApproval';

export const UPDATE_TASK_STATUS = 'task/update/taskStatus';
export const POST_TASK_COMMENTS = 'task/create/taskComment';
export const FETCH_TASK_COMMENTS = 'task/get/taskComment';
export const ASSIGN_TASK_TO_TEAM = 'task/assignToTeam';
export const ASSIGN_TASK_TO_MEMBER = `task/assignTaskToMember`;
export const SET_TASK_PRIORITY = 'task/setTaskPriority';
export const TASK_REASSIGN_DEPARTMENT = 'task/reAssign/departmentTask';
export const UPDATE_TASK_DETAILS = 'task/update/taskDetails';

export const UPDATE_JOB_APPROVAL = `career/job/acceptJobApproval`;
export const UPDATE_JOB = `career/job/updateJob`;

export const UPDATE_SHORTLIST_CANDIDATE = 'career/job/shortlistApplicant';
export const UPDATE_CANDIDATE_CONTACTED_STATUS = 'career/job/markAsContacted';
export const UPDATE_PROJECT_MANAGETASK_LIST = 'task/update/projectTaskStatus';

export const CREATE_PROJECT_COLLABORATION = `collaboration/create/collaboration`;
export const UPDATE_PROJECT_COLLABORATION = `collaboration/update/collaboration`;
export const GET_PROJECT_COLLABORATION = `collaboration/get/collaborationDetails`;
export const GET_PROJECT_COLLABORATION_BY_ID = `collaboration/get/collaborationDetailBasedOnCollaborationId`;
export const UPDATE_COLLABORATION_STATUS = `collaboration/update/updateIsOpenStatus`;
export const GET_COLLABORATION_APPLICANT_LIST = `collaboration/get/applicantList`;
export const CREATE_COLLABORATION_APPLICANT = `collaboration/create/collaborationApplicant`;
export const ACCEPT_REJECT_APPLICATION = `collaboration/update/acceptAndRejectApplicant`;
export const GET_APPLICATION_LIST = `application/myApplication`;
export const GET_COLLABORATION_APPLICANTS_DETAILS_BY_ID = `collaboration/get/applicantDetails`;
export const WITHDRAW_COLLABORATION_APPLICATION = `collaboration/update/withdrawProjectCollaborationApplicant`;
export const WITHDRAW_BIDDING_APPLICATION = `bidding/update/withdrawBiddingRequest`;
export const GET_BIDDING_APPLICANTS_DETAILS_BY_ID = `bidding/get/bidderDetails`;

export const UPDATE_ENTERPRISE_DETAILS = `update/enterpriseDetails`;
export const GET_ENTERPRISE_LOCATION = `getEnterpriseLocation`;
export const UPDATE_ENTERPRISE_LOCATION = `update/enterpriseLocation`;

export const CREATE_PROJECT_BIDDING = `bidding/create/bidding`;
export const GET_PROJECT_BIDDING_LIST = `bidding/get/biddingList`;
export const UPDATE_PROJECT_BIDDING_STATUS = `bidding/update/isBiddngOpenStatus`;
export const GET_PROJECT_BIDDING_BY_ID = `bidding/get/biddingDetails`;
export const GET_BIDDING_APPLICANTS_LIST = `bidding/get/biddersList`;
export const ACCEPT_REJECT_BIDDER_APPLICATION = `bidding/update/acceptAndRejectBidder`;
export const CREATE_BIDDING_APPLICANT = `bidding/create/biddingApplicant`;
export const UPDATE_BIDDING = `bidding/update/biddingDetails`;

export const GET_STREAM_MEDIA = `getProjectMedia`;

export const FETCH_INDIVIDUAL_INVITATIONS = `career/job/getUserInvitation`;
export const FETCH_DEPARTMENT_INVITATIONS = `getDepartmentInvitation`;
export const SEND_INDIVIDUAL_INVITATION = `career/job/inviteUser`;
export const DECLINE_INDIVIDUAL_INVITATION = `career/job/declineRequest`;
export const GET_TEAMS_BY_USER_ID = `getTeamsByUserId`;
export const CREATE_TEAM_MEMBER = `addTeamMembers`;

export const FETCH_ENTERPRISE_MEMBERS = `getEnterpriseMembers`;
export const REMOVE_DEPARTMENT_MEMBER = `removeDepartmentMember`;
export const FETCH_PROJECT_MEMBERS = `getProjectMembers`;
export const UPDATE_PROJECT_ADMIN = `updateProjectAdmin`;

export const FETCH_DEPARTMENT_TYPE = `getDepartmentType`;
export const SEARCH_DEPARTMENT = `searchDepartment`;
export const CREATE_DEPARTMENT = 'createDepartment';
export const UPDATE_DEPARTMENT = 'updateDepartment';
export const REINVITE_DEPARTMENT_MEMBER = `reInviteDepartmentMember`;
export const FETCH_DEPARTMENT_NAMES = `getAllDepartments`;
export const FORGOT_PASSWORD = `${process.env.REACT_APP_API_BASE_URL}/forgotPassword`;
export const RESET_PASSWORD = `${process.env.REACT_APP_API_BASE_URL}/resetPassword`;
export const VERIFY_EMAIL = `${process.env.REACT_APP_API_BASE_URL}/verifyEmail`;
export const FETCH_PENDING_DEPARTMENT_TEAMS = `getDepartmentPendingTeam`;

export const GET_RBAC_FOR_TEAM = `roleAccess/team`;
export const GET_RBAC_FOR_PROJECT = `roleAccess/project`;
export const FETCH_TECHNOLOGY_STACKS = `technologyStacks`;
export const CREATE_FCM_TOKEN = '/notification/saveFcmToken';
export const FETCH_ALL_NOTIFICATIONS = '/notification/notificationList';
export const MARK_ALL_NOTIFICATIONS_AS_READ =
  '/notification/updateNotificationStatus';
export const CLEAR_NOTIFICATIONS = '/notification/removeNotification';

export const CHANGE_PASSWORD = '/changePassword';
export const VERIFY_USER_EMAIL = '/validateUserEmail';
export const DEACTIVATE_ACCOUNT = '/deactivateUserAccount';
export const DELETE_ACCOUNT = '/deleteUserAccount';
export const UPDATE_PROFILE_VISIBILITY = '/updateProfileVisibility';
export const GET_PROFILE_VISIBILITY = `/publicProfile/individual/getIndividualSettings`;
export const GET_ENTERPRISE_PRIVACY = `/getEnterpriseSettings`;
export const UPDATE_ENTERPRISE_PRIVACY = `/updateEnterpriseSettings`;

export const FETCH_ERECORD_DETAILS = `/publicProfile/individual/getERecord`;
export const FETCH_QR_ERECORD_DETAILS = `/publicProfile/individual/get/QrERecord`;
export const UPDATE_TEAM_PRIVACY = `/updateTeamProfileVisibilty`;
export const UPDATE_TEAM_PROFILE_PICTURE = `/updateTeamProfile`;

export const FETCH_ERECORD_TECH_STACK = `/publicProfile/individual/getERecordDetails`;

export const VERIFY_GOOGLE_RECAPTCHA = '/verifyRecaptcha';

export const FETCH_TWILIO_ACCESS_TOKEN = '/getTwilioToken';
