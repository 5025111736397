import { CustomButton } from 'components';

export const AcceptOrDenyRequest = ({
  className,
  acceptOrDenyRequestHandler,
  connectWithId,
}) => {
  return (
    <div className={className}>
      <CustomButton
        className="outline-request-card"
        label="Accept"
        variant="textBtn"
        onClick={() => acceptOrDenyRequestHandler('ACCEPTED', connectWithId)}
      />
      <CustomButton
        className="outline-request-card"
        customPadding="2px 0px"
        label="Remove"
        color="themeTertiary"
        variant="textBtn"
        onClick={() => acceptOrDenyRequestHandler('REJECTED', connectWithId)}
      />
    </div>
  );
};
