import React from 'react';
import styled from 'styled-components';
import { isMobile } from 'utlils';
import SendIcon from 'assests/images/send.png';
import { Loader } from 'components';
import Icon from 'utlils/Icons/Icon';
import { getDateTime } from 'utlils/utils';

export const TaskComments = (props) => {
  const {
    commentText,
    hideUserProfile = false,
    isLoading = false,
    isUserCommonMember = false,
    isCurrentStageFinished = false,
    linkedStream = false,
    onPostTaskComment,
    setCommentText,
    taskComments,
    userProfile,
  } = props;
  return (
    <Wrp className="comments-wrapper">
      {!linkedStream && !isCurrentStageFinished && (
        <div className="comment-input_area">
          {hideUserProfile && (
            <div>
              {userProfile.profilePicture !== null &&
              userProfile.profilePicture !== '' ? (
                <img
                  src={userProfile.profilePicture}
                  alt="pfp"
                  width={'100%'}
                  className="commenter-profile-pic"
                />
              ) : (
                <div className="pfp-alt-circle">
                  <span className="pfp-alt">{userProfile?.profileText}</span>
                </div>
              )}
            </div>
          )}
          <div className="comment-input_box">
            <input
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
              className="comment-input"
              placeholder={
                isMobile() ? 'Comment here..' : 'Type your comment here'
              }
            />
            <img
              src={SendIcon}
              alt="send"
              className="send-comment-icon"
              onClick={onPostTaskComment}
            />
          </div>
        </div>
      )}
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : taskComments.length > 0 ? (
        taskComments.map((comment, index) => {
          return (
            <div className="display-comment-wrapper">
              <div className="display-comment-header">
                <div className="commenter-info">
                  {linkedStream && !isUserCommonMember ? (
                    <Icon
                      name={'user-icon'}
                      className="user-icon-class"
                      color="#292D32"
                    />
                  ) : comment.commenterPic !== null &&
                    comment.commenterPic !== '' ? (
                    <img
                      src={comment.commenterPic}
                      alt="pfp"
                      className="commenter-pic"
                    />
                  ) : (
                    <div className="pfp-text-circle">
                      <span className="pfp-text">{comment.commenterText}</span>
                    </div>
                  )}
                  <span className="commenter-name">
                    {linkedStream && !isUserCommonMember
                      ? 'Anonymous'
                      : comment.commenterName}
                  </span>
                </div>
                {(!linkedStream || isUserCommonMember) && (
                  <div className="comment-options">
                    <span>{getDateTime(comment.commentedAt)}</span>
                  </div>
                )}
              </div>
              <div className="display-comment-content">{comment.text}</div>
            </div>
          );
        })
      ) : (
        <div style={{ color: '#929daf' }}>
          {linkedStream || isCurrentStageFinished
            ? 'No comments to show!'
            : 'No comments. Post the first comment now!'}
        </div>
      )}
    </Wrp>
  );
};

const Wrp = styled.div`
  &.comments-wrapper {
    margin: 0 8px;
    & .comment-input_area {
      margin-top: 15px;
      display: flex;
      align-items: center;
      gap: 20px;
      padding-bottom: 25px;

      & .pfp-alt-circle {
        border-radius: 50%;
        width: 45px;
        height: 45px;
        background-color: #d6f3f9;
        display: flex;
        align-items: center;
        justify-content: center;
        & .pfp-alt {
          border-radius: 50%;
          font-weight: bold;
        }
      }

      & .commenter-profile-pic {
        object-fit: cover;
        border-radius: 50%;
        width: 45px;
        height: 45px;
        background: #c4c4c4;
      }

      & .comment-input_box {
        width: 100%;
        padding: 2px 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #e9eef4;
        border-radius: 60px;

        & .comment-input {
          flex-grow: 1;
          color: ##929daf;
          font-size: 14px;
          font-weight: 400;
          border: none;
          background: none;
          padding: 0 10px;
          &:focus {
            outline: none;
          }
          @media (max-width: 576px) {
            width: 100%;
          }
        }
        & .send-comment-icon {
          &:hover {
            cursor: pointer;
          }
        }
        @media (max-width: 576px) {
          width: 85%;
        }
      }
      @media (max-width: 576px) {
        gap: 2px;
      }
    }
    @media (max-width: 576px) {
      margin: 0 2px;
    }
    & .display-comment-wrapper {
      padding: 15px;
      margin: 10px 0;
      background: #e9eef4;
      border-radius: 10px;
      & .display-comment-header {
        display: flex;
        align-items: center;
        @media (max-width: 576px) {
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
        }
        & .commenter-info {
          flex-grow: 1;
          display: flex;
          align-items: center;
          gap: 20px;
          justify-content: flex-start;
          & .pfp-text-circle {
            border-radius: 50%;
            width: 45px;
            height: 45px;
            background-color: #d6f3f9;
            display: flex;
            align-items: center;
            justify-content: center;
            & .pfp-text {
              border-radius: 50%;
              font-weight: bold;
            }
          }
          & .commenter-pic {
            object-fit: cover;
            border-radius: 50%;
            width: 35px;
            height: 35px;
            background: #c4c4c4;
          }
          & .commenter-name {
            color: #17191d;
            font-weight: 600;
            font-size: 14px;
          }
        }
        & .comment-options {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 20px;
          color: '#929daf';
          font-size: 12px;
          @media (max-width: 576px) {
            width: 100%;
          }
        }
      }
      & .display-comment-content {
        padding: 15px 10px 10px 10px;
        color: #929daf;
      }
    }
  }
`;
