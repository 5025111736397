import { useNavigate } from 'react-router-dom';
import cx from 'classname';
import styled from 'styled-components';
import Icon from 'utlils/Icons/Icon';
import { CustomButton, OptionWrapper, Notification } from 'components';
import { UserSettingDropDown } from './UserSettingDropdown';

export const NavMenu = ({
  setOpenDropdown,
  active,
  navMenuList = [],
  openDropdown,
  onNavMenuClick,
  onClickDropdownMenu,
  selectedDropdownMenu,
  userProfile,
  hasNotificationBar,
}) => {
  let navigate = useNavigate();

  return (
    <NavMenuWrp className="nmw" totalMenuItems={navMenuList.length + 2}>
      {userProfile && Object.keys(userProfile).length ? (
        <>
          {navMenuList.map((item) => {
            const isDropdownItem = item?.isDropdown;
            return (
              <div key={item.id} className="nmw_menu-item">
                <div
                  className={cx(
                    'nmw_menu-item_list',
                    active === item.id ? 'nmw_menu_active' : ''
                  )}
                  onClick={() => onNavMenuClick(item)}>
                  <span
                    className={cx(
                      'nmw_menu-item_label',
                      active === item.id ? 'nmw_menu-item_list_active' : ''
                    )}>
                    {item.title}{' '}
                    {item.isIcon && (
                      <Icon
                        name={item.iconName}
                        color={
                          active === item.id ? 'nmw_menu-item_list_active' : ''
                        }
                        size={30}
                      />
                    )}
                  </span>
                  {isDropdownItem && (
                    <Icon
                      name={
                        active === item.id && openDropdown
                          ? 'arrow-drop-up'
                          : 'arrow-down'
                      }
                      color="#404F67"
                      className="dropdown-icon"
                      size={active === item.id && openDropdown ? 18 : 22}
                    />
                  )}
                </div>

                {active === item.id && openDropdown && isDropdownItem && (
                  <OptionWrapper
                    setOpenDropdown={setOpenDropdown}
                    className="option-wrapper"
                    onClick={onClickDropdownMenu}
                    options={item.dropDownItems}
                    selectedOption={selectedDropdownMenu}
                  />
                )}
              </div>
            );
          })}
          {hasNotificationBar && <Notification />}
          <UserSettingDropDown userProfile={userProfile} />
        </>
      ) : (
        <CustomButton
          className={'nmw_sign-up'}
          label={'Signup'}
          variant="outline"
          onClick={() =>
            navigate('/signup/selectOrganization', { replace: true })
          }
        />
      )}
    </NavMenuWrp>
  );
};

const NavMenuWrp = styled.div`
  display: grid;
  gap: 16px;
  margin-top: 16px;

  @media (min-width: 992px) {
    display: flex;
    align-items: center;
    gap: 45px;
    margin-top: 0;
  }

  .nmw_ {
    &menu-item {
      display: flex;
      align-items: center;
      position: relative;
      & .option-wrapper {
        width: max-content;
      }
      &_list {
        font-size: 16px;
        font-weight: 500;
        color: #404f67;
        cursor: pointer;
        &_active {
          color: #0abd00 !important;
          border-bottom: 2px solid #0abd00;
        }
        & .dropdown-icon {
          position: relative;
          top: 2px;
          left: 6px;
        }

        & .dropdown-icon.rotate-icon-clock {
          color: #0abd00 !important;
          transform: rotate(180deg);
          transition: 300ms transform;
        }

        &:hover {
          color: #0abd00 !important;
          & .nmw_menu-item_label {
            border-bottom: 2px solid #0abd00;
          }
          & .dropdown-icon {
            color: #0abd00 !important;
          }
        }
      }
    }
    &sign-up {
      width: 124px;
      height: 44px;
      margin-right: 16px;
    }
  }
`;
