import styled from 'styled-components';

export const ButtonContainerWrapper = styled.div`
  & .textButton {
    display: flex;
    cursor: pointer;
    font-weight: 600;
    width: 100%;
    gap: 5px;
    font-family: 'Open Sans', 'Poppins';
    background: transparent;
    align-items: center;
    justify-content: center;
    border: none;
  }
  & .custom-button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 600;
    width: 100%;
    border: none;
    gap: 5px;
    font-family: 'Open Sans', 'Poppins';
    background: ${(p) => p.backgroundColor};
  }

  & .primary-button {
    box-shadow: ${(c) => c.boxShadow};
    color: ${(c) => c.color};
    & span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    &:hover {
      background: ${(p) => p.hoverBg};
      color: ${(p) => p.hoverColor};
    }
    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }

  & .light-button {
    background: #e2ffe0;
    color: #0abd00;
  }

  & .outlined-button {
    background: transparent;
    color: ${(p) => p.color};
    border: ${(p) => `1px solid ${p.color}`};
  }
  & .lg {
    height: 54px;
    border-radius: 10px;
    font-size: 16px;
  }
  & .md {
    height: 40px;
    border-radius: 8px;
    font-size: 14px;
  }
  & .sm {
    font-size: 12px;
    height: 32px;
    border-radius: 6px;
  }
`;

export const ButtonWrapper = styled.button`
  cursor: pointer;
  font-family: 'Open Sans', 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 10px;
  padding: ${({ customPadding }) => customPadding};
  outline: none;
  box-shadow: unset !important;
  white-space: nowrap;

  &.btn:disabled {
    cursor: not-allowed;
    pointer-events: auto;
  }

  &.solid__ {
    &themePrimary {
      background-color: #0abd00;
      color: #ffffff;
    }
    &themeSecondary {
      background-color: #219fff;
      color: #ffffff;
    }
    &themeTertiary {
      background-color: #ff5157;
      color: #ffffff;
    }
    &themeOrange {
      background-color: #fe9320;
      color: #ffffff;
    }
  }

  &.light__ {
    &themePrimary {
      background-color: #e2ffe0;
      color: #0abd00;
    }
    &themeSecondary {
      background-color: #e9f6ff;
      color: #219fff;
    }
    &themeGray {
      background-color: #f4f7fc;
      color: #929daf;
    }
    &themeGray-2 {
      background-color: #e9eef4;
      color: #404f67;
    }
    &themeTertiary {
      background-color: rgba(255, 81, 87, 0.17);
      color: #ff5157;
    }
  }

  &.outline__ {
    &themePrimary {
      border: 2px solid #0abd00;
      background-color: #ffffff;
      color: #0abd00;
    }
    &themeSecondary {
      border: 2px solid #219fff;
      background-color: #ffffff;
      color: #219fff;
    }
    &themeTertiary {
      border: 2px solid #ff5157;
      background-color: #ffffff;
      color: #ff5157;
    }
    &themeGray {
      border: 2px solid #929daf;
      background-color: #ffffff;
      color: #929daf;
    }
    &themeOrange {
      border: 2px solid #fe9320;
      background-color: #ffffff;
      color: #fe9320;
    }
  }

  &.textBtn__ {
    &themePrimary {
      border: none;
      background-color: transparent;
      color: #0abd00;
    }
    &themeSecondary {
      border: none;
      background-color: transparent;
      color: #219fff;
    }
    &themeTertiary {
      border: none;
      background-color: transparent;
      color: #ff5157;
    }
    &themeGray {
      border: none;
      background-color: transparent;
      color: #929daf;
    }
    &themeOrange {
      border: none;
      background-color: transparent;
      color: #fe9320;
    }
  }
`;
