import cx from 'classname';
import { noop } from 'lodash';
import { bool, number, string, shape, func } from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Icon from 'utlils/Icons/Icon';

export const LabelWithLink = (props) => {
  const {
    classNames,
    color = '#219fff',
    hasIcon = true,
    icon,
    label,
    linkTo,
    disabled,
    onClick,
  } = props;
  const defaultIcon = {
    name: 'export-link',
    size: 20,
    color: color,
  };
  const iconConfig = icon && Object.keys(icon).length ? icon : defaultIcon;
  return (
    <Wrp className="lwl" color={color} hasIcon={hasIcon}>
      {linkTo && typeof onClick === 'function' && (
        <span className="lwl__text-link__label">
          Please pass either linkTo or onClick⚔️
        </span>
      )}
      {typeof onClick !== 'function' && !linkTo && (
        <div className={cx('lwl__text-link no-action', classNames)}>
          <span className="lwl__text-link__label">{label}</span>
          {hasIcon && <Icon {...iconConfig} />}
        </div>
      )}
      {linkTo && (
        <Link
          className={cx('lwl__text-link', classNames)}
          to={linkTo}
          target={'_blank'}
          aria-disabled={disabled}>
          <span className="lwl__text-link__label">{label}</span>
          {hasIcon && <Icon {...iconConfig} />}
        </Link>
      )}
      {typeof onClick === 'function' && (
        <div
          className={cx('lwl__text-link', disabled && 'no-action', classNames)}
          onClick={!disabled ? onClick : noop}>
          <span className="lwl__text-link__label">{label}</span>
          {hasIcon && <Icon {...iconConfig} />}
        </div>
      )}
    </Wrp>
  );
};

const Wrp = styled.div`
  & .lwl__ {
    &text-link {
      cursor: pointer;
      &__label {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-decoration-line: underline;
        color: ${({ color }) => color};
        margin-right: ${({ hasIcon }) => hasIcon && '4px'};
      }
      &.no-action {
        cursor: not-allowed;
      }
    }
  }
`;

LabelWithLink.propTypes = {
  className: string,
  color: string,
  icon: shape({
    color: string,
    name: string,
    size: number,
  }),
  hasIcon: bool,
  label: string.isRequired,
  linkTo: string,
  disabled: bool,
  onClick: func,
};
