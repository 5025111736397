import styled from 'styled-components';

export const ViewGroupWrapper = styled.div`
  background-color: white;
  padding: 10px;
  border-radius: 10px;

  .header {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }

  .content {
    padding: 20px;
  }

  .group-profile-pic {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }

  .group-info {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h2 {
      margin: 0;
    }

    .actions {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .group-description {
    margin-top: 20px;
    h3 {
      margin-bottom: 10px;
    }
  }

  .group-members {
    margin-top: 20px;
    h3 {
      margin-bottom: 10px;
    }
  }

  @media (max-width: 768px) {
    .group-info {
      flex-direction: column;
      align-items: center;
    }

    .group-profile-pic {
      width: 60px;
      height: 60px;
    }

    .group-description {
      text-align: center;
    }

    .actions {
      flex-direction: column;
      align-items: center;
    }
  }
`;

export const DetailsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding-top: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const InfoBox = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #a9a9a9;
  border-radius: 5px;
  flex: 1;
  min-width: 100px;
  margin: 10px 0;

  .icon {
    flex-shrink: 0;
    margin-right: 10px;
  }

  .info {
    flex-direction: column;
    text-align: left;

    span {
      font-weight: bold;
      font-size: 14px;
    }

    p {
      margin: 0;
      font-size: 12px;
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
  }
`;

export const MemberGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }

  .member-card {
    border: 1px solid #a9a9a9;
    padding: 10px;
    border-radius: 5px;
    text-align: center;

    .member-user-name {
      cursor: pointer;
      text-decoration: underline;
    }

    .member-profile-pic {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    span {
      display: block;
      margin-top: 5px;
    }
  }
`;
