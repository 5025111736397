import { CustomButton } from 'components';
import RadioGroup from 'pages/IndividualDashboard/components/radioGroup';
import { SelectBiddingWrapper } from './style';

export const SelectBidding = ({
  biddings,
  nextPage,
  selectedBidding,
  setSelectedBidding,
}) => {
  const handleSelectBiddingOption = (e) => {
    const { value } = e.target;
    setSelectedBidding(biddings.find((singleBid) => singleBid.id === value));
  };

  return (
    <SelectBiddingWrapper>
      <div>
        <p className="sub-heading">
          Please select one of the active biddings types you would like to
          continue with-
        </p>
      </div>
      <div className="radio-btn-label">
        <RadioGroup
          customClassName="radio-group"
          labelClassName="radio-group-label"
          labels={biddings}
          currChecked={selectedBidding?.id}
          handleChange={(e) => {
            handleSelectBiddingOption(e);
          }}
        />
      </div>
      <div className="popup-button">
        <CustomButton
          disabled={!selectedBidding}
          label={'Continue'}
          color="themeSecondary"
          onClick={nextPage}
          className="w-100"
        />
      </div>
    </SelectBiddingWrapper>
  );
};
