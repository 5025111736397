import { Box, Input, MonthYearPicker } from 'components';
import Icon from 'utlils/Icons/Icon';

export const LicenseItem = ({
  data,
  index,
  onInputChangeHandler,
  onDelete,
}) => {
  return (
    <div className="row">
      {index !== 0 && (
        <Box
          cursor="pointer"
          display="flex"
          justifyContent="space-between"
          alignItems="center">
          <div
            style={{ width: '90%', borderBottom: '1px solid #929DAF' }}></div>
          <Icon name="delete" onClick={() => onDelete(index)} />
        </Box>
      )}
      <div className="row">
        <div className="col-md-6 col-lg-6 col-xl-4 col-12">
          <Box mt="10px">
            <Input
              label="Title"
              placeholder="Title of Certificate"
              type="text"
              required
              onChange={(e) =>
                onInputChangeHandler(e.target.value, index, 'title')
              }
              value={data?.title}
            />
          </Box>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-4 col-12">
          <Box mt="10px">
            <Input
              label="Issued by"
              placeholder="Name of Institution/Organisation"
              type="text"
              required
              onChange={(e) =>
                onInputChangeHandler(e.target.value, index, 'issuedBy')
              }
              value={data?.issuedBy}
            />
          </Box>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-4 col-12">
          <Box
            display="flex"
            width="100%"
            alignItems="base"
            colGap="10px"
            mt="10px">
            <Box display="flex" width="100%" alignItems="base" colGap="10px">
              <MonthYearPicker
                label={'Issued on'}
                required
                onDateChangeHandler={(date, type) => {
                  onInputChangeHandler(date, index, `from-${type}`);
                }}
                selected={data?.issuedDate}
              />
            </Box>
          </Box>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-4 col-12">
          <Box mt="10px">
            <Input
              label="Credential Link"
              placeholder="Paste Link Here"
              type="text"
              required
              onChange={(e) =>
                onInputChangeHandler(e.target.value, index, 'credentialLink')
              }
              value={data?.credentialLink}
            />
          </Box>
        </div>
      </div>
    </div>
  );
};
