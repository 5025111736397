import styled from 'styled-components';

export const EnterpriseTeamProjectCardWrapper = styled.div`
  padding: 16px;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  & .content-header {
    display: flex;
    align-items: center;
    gap: 12px;
    & .profile-pic-class,
    .profile-pic-class img {
      width: 54px;
      height: 54px;
      border-radius: 50%;
    }
    & .icon-style-class {
      width: 54px;
      height: 54px;
      border-radius: 50%;
      background: #e9fbf6;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    & .title-wrapper {
      &-title {
        font-size: 16px;
        color: #17191d;
        font-weight: 500;
      }
      &-subtitle {
        display: flex;
        align-items: center;
        gap: 8px;
        &-text {
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          color: #404f67;
        }
      }
    }
  }
  & .feature-class {
    margin: 12px 0;
    display: flex;
    align-items: center;

    justify-items: start;
    & .feature-style {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      @media (max-width: 575px) {
        justify-content: flex-start;
      }
    }

    @media (min-width: 576px) and (max-width: 899px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 575px) {
      display: block;
    }
  }
  & .description {
    margin: 4px;
    word-wrap: break-word;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #404f67;
  }
  & .view-button {
    margin-top: 28px;
  }
`;
