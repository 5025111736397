import { CustomButton, ScrollableDiv } from 'components';
import Icon from 'utlils/Icons/Icon';
import { LinkedCardWrapper } from './style';
import cn from 'classname';

export const LinkedCard = ({
  profileImgSrc,
  profileIcon,
  title,
  type,
  owner,
  description,
  viewHandler,
  features = [],
  isPublished,
  hideActionButton = false,
}) => {
  return (
    <LinkedCardWrapper type={type}>
      <div className="content-header">
        {profileImgSrc && profileImgSrc !== '' ? (
          <div className="profile-pic-class">
            <img src={profileImgSrc} alt="" />
          </div>
        ) : (
          <div className="icon-style-class">
            <Icon name={profileIcon} size={24} color="#404f67" />
          </div>
        )}
        <div className="title-wrapper">
          <div className="title-wrapper-title">{title}</div>
          <div className="title-wrapper-subtitle">
            <span className="title-wrapper-subtitle-text">{owner}</span>
          </div>
        </div>
      </div>
      <div className="feature-class">
        {features.length !== 0 &&
          features.map((feature, i) => (
            <div className="feature-style" key={i}>
              <Icon name={feature?.iconName} size={20} color="#404f67" />
              <span>{feature.label}</span>
            </div>
          ))}
      </div>
      {type === 'job' ? (
        <div className={cn('description', 'other-description')}>
          {description}
        </div>
      ) : (
        <ScrollableDiv scrollOnHeight={isPublished ? '50px' : '74px'}>
          <div className="description">{description}</div>
        </ScrollableDiv>
      )}
      {!hideActionButton && (
        <div className="view-button">
          <CustomButton
            className="w-100"
            onClick={viewHandler}
            disabled={!isPublished && type === 'project'}
            color={
              isPublished ||
              type === 'team' ||
              type === 'job' ||
              type === 'publication'
                ? 'themeSecondary'
                : 'themeGray'
            }
            variant="outline">
            {!isPublished && type === 'project' && (
              <Icon
                name="lock-two"
                color={
                  isPublished || type === 'team' || type === 'job'
                    ? '#219fff'
                    : '#929DAF'
                }
              />
            )}
            {!isPublished && type === 'project' ? 'Private' : 'View'}
          </CustomButton>
        </div>
      )}
    </LinkedCardWrapper>
  );
};
