import React, { useEffect, useState, useCallback } from 'react';
import { initializeTwilioConversations } from 'services/twilioService/twilioClient';
import { NavbarBrand1 } from 'components';
import { INDIVIDUAL_DASHBOARD_NAV_LIST } from 'pages/IndividualDashboard/constants';
import { ChatContainer, ChatWrapper } from './chat';
import tokenService from 'services/token.service';
import { FETCH_USER_PROFILE, useAxiosWrapper } from 'services';
import { getProfileText } from 'utlils/utils';
import { ConversationUsersList, ConversationViewer } from './components';
import { chatErrorMsg } from '../../services/twilioService/helper.js';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { TEAM_DASHBOARD_NAV_LIST } from 'pages/TeamDashboard/constants';

export const Chat = (props) => {
  const [fnWrapper] = useAxiosWrapper();
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState({});
  const [connectionState, setConnectionState] = useState({
    loading: true,
    connectionStatus: null,
  });
  const [conversationsClient, setConversationsClient] = useState(null);
  const [conversationsList, setConversationsList] = useState([]);
  const [messages, setMessages] = useState([]);
  const loggedInUser = tokenService.getSession();
  const [openChat, setOpenChat] = useState(false);

  // fetch userprofile
  const getUserProfile = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_USER_PROFILE}/${loggedInUser?.id}`,
      method: 'GET',
      type: 'FETCH_USER_PROFILE',
    });
    if (res?.data?.statusCode === 200) {
      const response = res?.data?.data;
      setUserProfile({
        ...response,
        profileText: getProfileText(
          response?.role === 'INDIVIDUAL'
            ? response?.name
            : response?.enterpriseName
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // initialize conversation
  const conversationInitialization = useCallback(async () => {
    try {
      // Initialize the Conversations Client with the access token
      const client = await initializeTwilioConversations(loggedInUser.id);
      setConversationsClient(client);
      // Listen for connection state changes
      client.on('connectionStateChanged', async (state) => {
        if (state === 'connecting') {
          setConnectionState((state) => ({
            ...state,
            connectionStatus: 'Connecting to chat...',
          }));
        }
        if (state === 'connected') {
          const conversations = await client.getSubscribedConversations();
          setConnectionState({
            loading: false,
            connectionStatus: 'You are connected',
          });
          setConversationsList(conversations?.items);
        }
        if (state === 'disconnecting') {
          setConnectionState((state) => ({
            loading: false,
            connectionStatus: 'Disconnecting from chat...',
          }));
        }
        if (state === 'denied') {
          setConnectionState((state) => ({
            loading: false,
            connectionStatus: 'Failed to connect',
          }));
        }
      });

      client.on('conversationJoined', (conversation) => {
        setConversationsList((prev) => [...prev, conversation]);
      });

      client.on('messageAdded', (message) => {
        setMessages((prevMessages) => [...prevMessages, message]);
      });

      client.on('conversationLeft', (thisConversation) => {
        // eslint-disable-next-line no-console
        console.log('conversation left', thisConversation);
      });

      client.on('tokenAboutToExpire', async () => {
        // Fetch a new token when the existing token is about to expire
        await initializeTwilioConversations();
      });
    } catch (error) {
      chatErrorMsg('Error initializing Twilio Conversations');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUser.id]);

  useEffect(() => {
    conversationInitialization();
    return () => {
      // Cleanup function to remove event listeners when the component unmounts
      if (conversationsClient) {
        conversationsClient.removeAllListeners();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //functions
  const handleConversationClick = async (conversationDetails) => {
    const url = `/individual/messages/conversation/${conversationDetails.sid}`;
    // Navigate to the URL
    navigate(url);
    setOpenChat(false);
  };

  return (
    <ChatWrapper>
      <div className="chat-navbar">
        <NavbarBrand1
          navMenu={
            userProfile?.role === 'INDIVIDUAL'
              ? INDIVIDUAL_DASHBOARD_NAV_LIST(userProfile?.role)
              : TEAM_DASHBOARD_NAV_LIST(userProfile?.role)
          }
          currentLocale={props.currentLocale}
          handleLocaleChange={props.handleLocaleChange}
          userProfile={userProfile}
        />
      </div>
      <ChatContainer>
        <Routes>
          <Route
            path="/conversation/:conversationId"
            element={
              <>
                <div className="left-section">
                  {/* left section */}
                  <ConversationUsersList
                    openChat={openChat}
                    conversationsClient={conversationsClient}
                    conversationsList={conversationsList}
                    handleConversationClick={handleConversationClick}
                    connectionState={connectionState}
                  />
                </div>
                {/* right section */}
                <ConversationViewer
                  openChat={openChat}
                  setOpenChat={setOpenChat}
                  conversationsClient={conversationsClient}
                  messages={messages}
                  setMessages={setMessages}
                  connectionStatus={connectionState?.connectionStatus}
                  conversationsList={conversationsList}
                  handleConversationClick={handleConversationClick}
                  connectionState={connectionState}
                />
              </>
            }
          />
        </Routes>
      </ChatContainer>
    </ChatWrapper>
  );
};
