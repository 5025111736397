import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ExploreTeamProjectCard } from 'pages/Explore/Components';
import { SavedTeamsWrapper } from './style';
import {
  CREATE_SAVE_PUBLIC_TEAM,
  FETCH_SAVED_TEAMS_BASED_ON_USER,
  useAxiosWrapper,
} from 'services';
import tokenService from 'services/token.service';
import { Loader } from 'components';

export const SavedTeams = () => {
  const [fnWrapper, context] = useAxiosWrapper();
  const [teamsList, setTeamsList] = useState([]);
  const navigate = useNavigate();

  const getSavedTeams = useCallback(async () => {
    const loggedInUser = await tokenService.getSession();
    const res = await fnWrapper({
      url: `${FETCH_SAVED_TEAMS_BASED_ON_USER}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_SAVED_TEAMS_PER_USER',
    });
    if (res.data.statusCode === 200) {
      const result = res.data.data;
      const allTeams = [];
      result?.map((teamObj) => {
        const item = {
          id: teamObj?.teamId,
          teamName: teamObj?.team?.teamName,
          owner:
            teamObj?.team?.teamCategory === 'ENTERPRISE' &&
            teamObj?.team?.enterprise
              ? teamObj?.team?.enterprise?.enterpriseName
              : teamObj?.team?.user?.individual?.name,
          type: 'team',
          profileImgSrc: '',
          profileIcon: 'people',
          description: teamObj?.team?.description,
          isTeamSaved: true,
          teamFeature: [
            {
              iconName: 'people',
              label: `${
                teamObj?.team?.teamMembers.filter(
                  (member) => member.status === '4'
                ).length
              } Members`,
            },
            {
              iconName: 'brief-case',
              label: `${teamObj?.isHiring ? 'Hiring' : '--'}`,
            },
            {
              iconName: 'tag-user',
              label: teamObj?.team?.type?.title,
            },
            {
              iconName: 'user',
              label: `${teamObj?.connections?.length} Connections`,
            },
          ],
        };
        allTeams.push(item);
        return allTeams;
      });
      setTeamsList(allTeams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveTeam = async (teamId) => {
    const loggedInUser = tokenService.getSession();
    const res = await fnWrapper({
      url: `${CREATE_SAVE_PUBLIC_TEAM}`,
      method: 'PUT',
      payload: {
        teamId: teamId,
        userId: loggedInUser.id,
      },
      type: 'CREATE_SAVE_PUBLIC_TEAM',
    });
    if (res.data.statusCode === 200) {
      await getSavedTeams();
    }
  };

  useEffect(() => {
    getSavedTeams();
  }, [getSavedTeams]);

  return (
    <SavedTeamsWrapper>
      <div className="main-wrapper">
        <div className="heading">
          <h5 className="teams-count">Teams ({teamsList.length})</h5>
        </div>
        {context.busy.status ? (
          <Loader loading={context.busy.status} />
        ) : teamsList?.length > 0 ? (
          <div className="cards-container">
            {teamsList?.map((team, index) => (
              <ExploreTeamProjectCard
                key={index}
                title={team.teamName}
                type={team.type}
                profileImgSrc={team.profileImgSrc}
                profileIcon={team.profileIcon}
                owner={team.owner}
                description={team.description}
                features={team.teamFeature}
                viewHandler={() => navigate(`/public/team/${team.id}/details`)}
                isSaved={true}
                onSaveHandler={() => saveTeam(team.id)}
              />
            ))}
          </div>
        ) : (
          <div className="no-data-class">
            Looks like you didn't save any Team!
          </div>
        )}
      </div>
    </SavedTeamsWrapper>
  );
};
