import styled from 'styled-components';
import Icon from 'utlils/Icons/Icon';
import { useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomDropDown, Loader, Pagination } from 'components';
import {
  AssignToMemberPopUp,
  TaskCardContent,
} from 'pages/TeamDashboard/components';
import {
  FETCH_ALL_DEPARTMENT_TASKS,
  FETCH_PROJECT_TASK_LIST_BASED_ON_PROJECT,
  FETCH_PROJECT_SERVICES,
  FETCH_DEPARTMENT_DETAILS,
  useAxiosWrapper,
  FETCH_PROJECT_BOARD_FIELDS,
  FETCH_DEPARTMENT_BOARD_FIELDS,
} from 'services';
import { TASK_PRIORITY } from 'pages/TeamDashboard/helper';
import EnterPriseImg from 'assests/images/enterpriseImg.png';

const ViewTasksWrapper = styled.div`
  .view-container {
    & .heading {
      display: flex;
      justify-content: space-between;
      margin: 40px 0;

      & .heading-left {
        display: flex;
        align-items: center;

        & .heading-back-btn {
          padding-right: 10px;
          &:hover {
            cursor: pointer;
          }
        }
        & .heading-text {
          font-weight: 700;
          font-size: 25px;
          color: #17191d;
          word-break: break-word;
        }
      }
      @media (max-width: 590px) {
        flex-direction: column;
      }
    }

    & .task-list-style {
      margin-top: 20px;
    }

    & .no-tasks {
      width: 75%;
      text-align: center;
      margin: 10% auto;
      font-weight: 500;
      color: #929daf;
    }
  }

  .pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    background: white;
    border-radius: 12px;
    & .pagination-bar {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
`;

export const ViewTasks = () => {
  const navigate = useNavigate();
  const { teamId, cardId, taskType, userType } = useParams();

  const [fnWrapper, context] = useAxiosWrapper();
  // const [selectedDropDown, setSelectedDropDown] = useState(null);
  const [selectedDropDown, setSelectedDropDown] = useState({
    label: 'TO DO',
    value: 'TO_DO',
  });
  const [activeTask, setActiveTask] = useState(null);
  const [tasksList, setTasksList] = useState([]);
  const [tasksData, setTasksData] = useState({});
  const [isPopUp, setIsPopUp] = useState(false);
  const [title, setTitle] = useState('');
  const [currentTaskId, setCurrentTaskId] = useState('');
  const [currentProjectPage, setCurrentProjectPage] = useState(1);
  const [departmentPage, setDepartmentPage] = useState(1);
  const [taskStatus, setTaskStatus] = useState([]);

  let projectPageSize = 10;
  let departmentPageSize = 10;

  const handleOptionsClick = (item, taskId) => {
    switch (item.id) {
      case 1:
        popUpHandler(taskId, true);
        setActiveTask('');
        break;
      default:
    }
  };

  const activeTaskHandler = (taskId) => {
    if (activeTask !== taskId) {
      setActiveTask(taskId);
    } else {
      setActiveTask(null);
    }
  };

  const popUpHandler = (taskId, bool) => {
    setCurrentTaskId(taskId);
    setIsPopUp(bool);
  };

  const bckBtnHandler = () => {
    let tab = taskType.toLowerCase().replace(' ', '-');
    navigate(`/${userType}/team-dashboard/${teamId}/tasks/${tab}`, {
      replace: true,
    });
  };

  // fetch project details for project Title
  const getProjectTitle = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_PROJECT_SERVICES}/${cardId}`,
      method: 'GET',
      type: 'FETCH_PROJECT_DETAILS',
      params: {
        st: 'details',
      },
    });

    if (res.data.statusCode === 200) {
      let arr = res.data?.data?.data?.title;
      setTitle(arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fetch project details for project Title
  const getDepartmentTitle = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_DEPARTMENT_DETAILS}/${cardId}`,
      method: 'GET',
      type: 'FETCH_DEPARTMENT_DETAILS',
    });

    if (res.data.statusCode === 200) {
      let arr = res?.data?.data?.departmentName;
      setTitle(arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProjectTaskList = useCallback(
    async (status) => {
      const payload = {
        page: currentProjectPage,
        size: projectPageSize,
        query: {
          status: status,
        },
        projectId: cardId,
        isPaginated: true,
        teamId: teamId,
      };
      const res = await fnWrapper({
        url: `${FETCH_PROJECT_TASK_LIST_BASED_ON_PROJECT}`,
        method: 'GET',
        type: 'FETCH_TASKS',
        params: payload,
      });
      if (res.data.statusCode === 200) {
        const result = res.data.data;
        if (result.items.length === 0 && currentProjectPage !== 1) {
          setCurrentProjectPage(currentProjectPage - 1);
        } else {
          setCurrentProjectPage(result.currentPage);
          setTasksData(result);

          const response = res.data?.data?.items;
          let taskData = [];
          response?.map((task) => {
            const data = {
              id: task?.id,
              userName: task?.assignedByDepartment?.departmentName
                ? task?.assignedByDepartment?.departmentName
                : task?.assignedTeamByUser?.individual?.name
                ? task?.assignedTeamByUser?.individual?.name
                : task?.assignedTeamByUser?.smallEnterprise?.enterpriseName,
              assignedFor: {
                label: task?.posts?.stages?.stageName,
                textColor: '#219fff',
              },
              time: task?.createdAt,
              taskTitle: task?.taskTitle,
              isImg:
                task?.assignedByTeamUser?.individual === null
                  ? true
                  : task?.assignedByTeamUser?.individual?.profilePicture !==
                    null,
              imgSrc: task?.assignedTeamByUser?.individual?.profilePicture
                ? task?.assignedTeamByUser?.individual?.profilePicture
                : task?.assignedTeamByUser?.smallEnterprise?.profilePicture
                ? task?.assignedTeamByUser?.smallEnterprise?.profilePicture
                : EnterPriseImg,
              taskDescription: task?.taskDescription,
              isTaskAssigned: task?.assignToMember?.length > 0,
              isCompleted: task?.completedByUserId !== null,
              completedBy:
                task?.completedByUser?.individual === null
                  ? task?.completedByUser?.smallEnterprise.profilePicture
                  : task?.completedByUser?.individual.profilePicture,
            };
            taskData.sort(function (a, b) {
              return new Date(b.time) - new Date(a.time);
            });
            taskData.push(data);
            return taskData;
          });
          setTasksList(taskData);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentProjectPage, projectPageSize, cardId]
  );

  const getDepartmentTaskList = useCallback(
    async (status) => {
      const payload = {
        departmentId: cardId,
        isFilterByTaskType: true,
        isPaginated: true,
        page: departmentPage,
        size: departmentPageSize,
        query: {
          status: status,
        },
      };
      const res = await fnWrapper({
        url: `${FETCH_ALL_DEPARTMENT_TASKS}`,
        method: 'GET',
        type: 'FETCH_DEPARTMENT_TASKS',
        params: payload,
      });
      if (res.data.statusCode === 200) {
        const result = res.data.data;
        if (result.items.length === 0 && departmentPage !== 1) {
          setDepartmentPage(departmentPage - 1);
        } else {
          setDepartmentPage(result.currentPage);
          setTasksData(result);

          const response = result?.items;
          let taskData = [];
          response?.map((task) => {
            const data = {
              id: task?.id,
              userName: task?.assignedByDepartment?.departmentName
                ? task?.assignedByDepartment?.departmentName
                : task?.assignedTeamByUser?.individual?.name
                ? task?.assignedTeamByUser?.individual?.name
                : task?.assignedTeamByUser?.smallEnterprise?.enterpriseName,
              assignedFor: TASK_PRIORITY[task?.urgency],
              time: task?.createdAt,
              taskTitle: task?.taskTitle,
              isImg: true,
              imgSrc: task?.assignedTeamByUser?.smallEnterprise?.profilePicture
                ? task?.assignedTeamByUser?.smallEnterprise?.profilePicture
                : EnterPriseImg,
              taskDescription: task?.taskDescription,
              isTaskAssigned: task?.assignToMember?.length > 0,
              isCompleted: task?.completedByUser !== null,
              completedBy:
                task?.completedByUser?.individual === null
                  ? task?.completedByUser?.smallEnterprise?.profilePicture
                  : task?.completedByUser?.individual?.profilePicture,
            };
            taskData.sort(function (a, b) {
              return new Date(b.time) - new Date(a.time);
            });
            taskData.push(data);
            return taskData;
          });
          setTasksList(taskData);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cardId, departmentPage, departmentPageSize]
  );

  // fetch board fields list based on project id / department id
  const getStatusFields = useCallback(async () => {
    const taskStatusRes =
      taskType === 'project-tasks'
        ? await fnWrapper({
            url: `${FETCH_PROJECT_BOARD_FIELDS}/${cardId}`,
            method: 'GET',
            type: 'FETCH_PROJECT_BOARD_FIELDS',
          })
        : await fnWrapper({
            url: `${FETCH_DEPARTMENT_BOARD_FIELDS}/${cardId}`,
            method: 'GET',
            type: 'FETCH_DEPARTMENT_BOARD_FIELDS',
          });
    if (taskStatusRes.data.statusCode === 200) {
      const result = taskStatusRes.data.data;
      if (result?.status && result?.status?.length) {
        setSelectedDropDown(result?.status[0]);
        setTaskStatus(result?.status);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardId]);

  useEffect(() => {
    if (taskType === 'project-tasks') {
      getProjectTitle();
      getStatusFields();
      getProjectTaskList(selectedDropDown?.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProjectTaskList, taskType, getProjectTitle, getStatusFields]);

  useEffect(() => {
    if (taskType === 'department-tasks') {
      getDepartmentTitle();
      getStatusFields();
      getDepartmentTaskList(selectedDropDown?.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDepartmentTaskList, taskType, getDepartmentTitle, getStatusFields]);

  const onCloseAssignToMemberModal = () => {
    setIsPopUp(false);
    setCurrentTaskId('');
    if (taskType !== 'project-tasks')
      getDepartmentTaskList(selectedDropDown?.value);
    else getProjectTaskList(selectedDropDown?.value);
  };

  return (
    <ViewTasksWrapper>
      {context.busy.status ? (
        <Loader isLoading={context.busy.status} />
      ) : (
        <div className="view-container">
          <div className="heading">
            <div className="heading-left">
              <span className="heading-back-btn" onClick={bckBtnHandler}>
                <Icon color="#292D32" name="arrow-back" size={30} leftIcon />
              </span>
              <div className="heading-text">{title}</div>
            </div>
            <div>
              <CustomDropDown
                optionLists={taskStatus}
                selectedItem={selectedDropDown}
                setSelectedItem={(item) => {
                  setSelectedDropDown(item);
                  if (taskType !== 'project-tasks')
                    getDepartmentTaskList(item?.value);
                  else getProjectTaskList(item?.value);
                }}
                placeholder={selectedDropDown?.label}
                height="54px"
                customStyle={{
                  backgroundColor: 'rgba(255, 164, 18, 0.2)',
                  color: 'rgba(255, 164, 18, 1)',
                  padding: '10px',
                  justifyContent: 'center',
                  whiteSpace: 'normal',
                }}
                width={'100%'}
              />
            </div>
          </div>
          {/*  task list*/}
          {tasksList.length === 0 ? (
            <div className="no-tasks">No Tasks Available!</div>
          ) : (
            tasksList.map((task, index) => (
              <div className="task-list-style" key={index}>
                <TaskCardContent
                  taskId={task.id}
                  taskType={taskType.toLowerCase().split(' ')[0]}
                  isImg={task.isImg}
                  imgSrc={task.imgSrc}
                  userName={task.userName}
                  postedTime={task.time}
                  assignedFor={task.assignedFor}
                  taskTitle={task.taskTitle}
                  taskDescription={task.taskDescription}
                  onSelectTask={() => activeTaskHandler(task.id)}
                  popUpHandler={popUpHandler}
                  isActive={activeTask === task.id}
                  isTaskAssigned={task.isTaskAssigned}
                  isCompleted={task.isCompleted}
                  completedBy={task.completedBy}
                  optionsHandler={handleOptionsClick}
                />
              </div>
            ))
          )}
        </div>
      )}
      {isPopUp && (
        <AssignToMemberPopUp
          onClose={onCloseAssignToMemberModal}
          taskId={currentTaskId}
          teamId={teamId}
          type={taskType}
        />
      )}
      <div className="pagination-container">
        {(tasksList?.length >= 0 || tasksData?.currentPage >= 1) && (
          <Pagination
            className="pagination-bar"
            currentPage={tasksData?.currentPage ? tasksData?.currentPage : 0}
            totalCount={tasksData?.totalItems ? tasksData?.totalItems : 0}
            pageSize={
              taskType === 'project-tasks'
                ? projectPageSize
                : departmentPageSize
            }
            onPageChange={(page) =>
              taskType === 'project-tasks'
                ? setCurrentProjectPage(page)
                : setDepartmentPage(page)
            }
          />
        )}
      </div>
    </ViewTasksWrapper>
  );
};
