import './smallE.scss';
import { Box, DropDown, Input } from '../../../components';

const SmallEnterprise = ({
  handleInputChange,
  handleInputBlur,
  state,
  setState,
  type = [],
  passwordError,
}) => {
  const {
    name,
    userName,
    link,
    topic,
    email,
    password,
    organizationType,
    confirmPassword,
    hasPasswordEntered,
    hasCPasswordEntered,
    error,
  } = state;
  return (
    <Box>
      {/* Form Fields */}
      <div className="row">
        <div className="col-md-6 col-lg-6 col-12">
          <Box>
            <Input
              label="Enterprise Name"
              iconLeft={true}
              iconName="enterprise"
              placeholder="Full Name"
              type="text"
              required
              value={name}
              onChange={(value) =>
                handleInputChange('name', value.target.value)
              }
              hasError={error?.name}
              errorMessage={error?.name}
            />
          </Box>
        </div>
        <div className="col-md-6 col-lg-6 col-12">
          <Box>
            <Input
              label="Link (Website)"
              iconLeft={true}
              iconName="global"
              placeholder="www.example.com"
              type="text"
              required
              value={link}
              onChange={(value) =>
                handleInputChange('link', value.target.value)
              }
              hasError={error?.link}
              errorMessage={error?.link}
            />
          </Box>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 col-lg-6 col-12">
          <Box mt="15px">
            <DropDown
              items={type}
              label="Enterprise Type"
              onClick={(value) => handleInputChange('organizationType', value)}
              placeholder="Enterprise Type"
              objectKey="title"
              value={organizationType}
              required
            />
          </Box>
        </div>
        <div className="col-md-6 col-lg-6 col-12">
          <Box mt="15px">
            <Input
              label="Title"
              iconLeft={true}
              iconName="note"
              placeholder="Title"
              type="text"
              value={topic}
              onChange={(value) =>
                handleInputChange('topic', value.target.value)
              }
            />
          </Box>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-6 col-12">
          <Box mt="15px">
            <Input
              label="Enterprise Email"
              iconLeft={true}
              iconName="sms"
              placeholder="xyz@officialmail.com"
              type="email"
              required
              value={email}
              onBlur={(value) => handleInputBlur('email', value.target.value)}
              onChange={(value) =>
                handleInputChange('email', value.target.value)
              }
              hasError={error?.email}
              errorMessage={error?.email}
            />
          </Box>
        </div>
        <div className="col-md-6 col-lg-6 col-12">
          <Box mt="15px">
            <Input
              label="Enterprise Username"
              iconLeft={true}
              iconName="enterprise"
              placeholder="Enterprise Usernamee"
              type="text"
              required
              value={userName}
              onBlur={(value) =>
                handleInputBlur('userName', value.target.value)
              }
              onChange={(value) =>
                handleInputChange('userName', value.target.value)
              }
              hasError={error?.userName}
              errorMessage={error?.userName}
            />
          </Box>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-6 col-12">
          <Box mt="15px">
            <Input
              label="Password"
              iconLeft={true}
              iconName="lock"
              placeholder="Enter password"
              type="password"
              required
              value={password}
              onChange={(value) =>
                handleInputChange('password', value.target.value)
              }
              onBlur={() =>
                setState({
                  ...state,
                  hasPasswordEntered: true,
                })
              }
              showErrorBorderOnly={error?.password}
            />
          </Box>
        </div>
        <div className="col-md-6 col-lg-6 col-12">
          <Box mt="15px">
            <Input
              label="Confirm Password"
              iconLeft={true}
              iconName="lock"
              placeholder="Re-type password"
              type="password"
              required
              value={confirmPassword}
              onChange={(value) =>
                handleInputChange('confirmPassword', value.target.value)
              }
              onBlur={() =>
                setState({
                  ...state,
                  hasCPasswordEntered: true,
                })
              }
              hasError={
                hasPasswordEntered &&
                hasCPasswordEntered &&
                password !== confirmPassword
              }
              errorMessage={
                !confirmPassword
                  ? ''
                  : 'Confirm password should be match with password'
              }
            />
          </Box>
        </div>
      </div>
      {passwordError !== null && (
        <div className="row">
          <div className="col-12">
            <Box mt="20px" className="has-error">
              {passwordError}
            </Box>
          </div>
        </div>
      )}
    </Box>
  );
};

export default SmallEnterprise;
