import { CustomButton, DropDown, ModalPopup, Textarea } from 'components';
import { TASK_PRIORITY_LIST } from 'pages/enterpriseDashboard/constants';
import { ActionModalWrp } from './styles';

export const SetPriorityModal = (props) => {
  const { ctx, onSetPriority, onClose, onChange, priorityState } = props;
  const isLoading =
    ctx.busy.status &&
    (ctx.busy.type === 'SET_PRIORITY' || ctx.busy.type === 'FETCH_TASK_BY_ID');
  return (
    <div>
      <ModalPopup
        open={priorityState.isModalOpen}
        onClose={() => onClose('SET_PRIORITY')}
        title={'Set priority'}>
        <ActionModalWrp className="amw-assign-team">
          <DropDown
            label={'Select priority'}
            placeholder="Select"
            objectKey="label"
            required
            value={priorityState.priority}
            items={TASK_PRIORITY_LIST}
            onClick={(value) => {
              onChange('SET_PRIORITY', 'priority', value);
            }}
          />
          <Textarea
            required
            label="Message"
            value={priorityState.remarkPriority}
            onChange={(e) => {
              onChange('SET_PRIORITY', 'remarkPriority', e.target.value);
            }}
          />
          <div className="amw-assign-team__action">
            <CustomButton
              disabled={isLoading}
              label={'Close'}
              onClick={() => onClose('SET_PRIORITY')}
              variant="textBtn"
              color={'themeSecondary'}
            />
            <CustomButton
              disabled={isLoading}
              label={'Set'}
              loading={isLoading}
              onClick={onSetPriority}
              color={'themeSecondary'}
            />
          </div>
        </ActionModalWrp>
      </ModalPopup>
    </div>
  );
};
