import styled from 'styled-components';
export const ReviewApplicationWrapper = styled.div`
  .title {
    color: #404f67;
    margin-bottom: 16px;
    margin-top: 10px;
  }
  .technology {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
    .item {
      padding: 12px;
      border-radius: 12px;
      font-weight: 500;
      font-size: 15px;
      background: #219fff;
      color: #ffffff;
    }
  }
  .applied-with-container {
    .profile-title {
      font-weight: 600;
      font-size: 18px;
      color: #040d20;
    }
    .profile-sub-title {
      font-weight: 500;
      font-size: 15px;
    }
    .view-profile {
      border-radius: 50px;
    }
    background: #ffffff;
    border: 1px solid #7e8f9a;
    border-radius: 10px;
    min-height: 93px;
    width: 75%;
    padding: 15px 20px;
    .applied-with-profile {
      display: grid !important;
      grid-template-columns: 0.5fr 1fr 1fr;
      gap: 10px;
    }
  }
  .main-wrp {
    .applied-answer {
      background: #ffffff;
      border: 2px solid #e9eef4;
      border-radius: 12px;
      padding: 12px;
    }
  }
`;
