import styled from 'styled-components';
import { isMobile } from 'utlils';

export const ModalPopupWrp = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: rgba(9, 11, 29, 0.6);
  z-index: 21;
`;

export const ModalPopupContainer = styled.div`
  display: ${() => (isMobile() ? 'block' : 'flex')};
  align-items: ${() => !isMobile() && 'center'};
  justify-content: ${() => !isMobile() && 'center'};
  height: 100%;

  & .row {
    margin-left: 0;
    margin-right: 0;
  }

  & .mpc__ {
    &main-wrapper {
      background: #ffffff;
      border-radius: ${() => !isMobile() && '20px'};
      border-top: 20px solid #219fff;
      box-shadow: ${() => !isMobile() && 'rgb(10 10 20 / 10%) 0px 2px 6px'};
      min-width: ${({ minWidth }) => !isMobile() && minWidth};
      padding: 16px;
      height: ${() => isMobile() && '100%'};

      &__title {
        padding: ${() => (isMobile() ? '0' : '0 12px')};
      }
      &__content-area {
        padding: ${() => (isMobile() ? 0 : '12px')};
        width: ${({ maxContentWidth }) => !isMobile() && maxContentWidth};
      }
    }
  }
`;
