import styled from 'styled-components';

export const ViewStreamsWrapper = styled.div`
  .CustomBtnUnderLineViewStreams {
    text-decoration: underline;
  }
  & .no-stream {
    width: 75%;
    text-align: center;
    margin: 8% auto;
    font-weight: 500;
    color: #929daf;
  }
  & .streams-wrapper {
    & .buttons-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      & .right-button {
        @media (max-width: 767px) {
          margin-top: 20px;
        }
      }

      & .left-buttons {
        display: grid;
        grid-template-columns: 1.5fr 2fr;
        gap: 20px;

        @media (max-width: 767px) {
          grid-template-columns: 1fr;
        }
        @media (min-width: 1200px) and (max-width: 1350px) {
          grid-template-columns: 0.5fr 0.8fr;
          gap: 10px;
        }
      }
      @media (max-width: 767px) {
        display: block;
      }
    }
    & .posts-wrapper {
      margin: 0px 8px;
      & .comment_area {
        margin-top: 40px;
        display: flex;
        gap: 20px;
        padding-bottom: 25px;
        border-bottom: 1px solid #c4c4c4;

        & .pfp-alt-circle {
          border-radius: 50%;
          width: 45px;
          height: 45px;
          background-color: #d6f3f9;
          display: flex;
          align-items: center;
          justify-content: center;
          & .pfp-alt {
            border-radius: 50%;
            font-weight: bold;
          }
        }

        & .post-profile-pic {
          object-fit: cover;
          border-radius: 50%;
          width: 45px;
          height: 45px;
          background: #c4c4c4;
        }

        & .comment_box {
          width: 75%;
          height: 50px;
          background: #f7f6ff;
          border-radius: 31.7243px;

          & .comment-box-text {
            margin-top: 14px;
            margin-left: 10px;
            color: #929daf;
            font-size: 14px;
            font-weight: 400;
          }
          &:hover {
            cursor: ${({ isCurrentStageFinished }) =>
              isCurrentStageFinished ? 'not-allowed' : 'pointer'};
          }
        }
      }
    }
  }
  & .post-content-wrapper {
    padding-top: 30px;
  }

  & .check-for-previous-stage-container {
    max-width: 500px;
    margin: 50px auto;
    text-align: center;

    & .check-stage-buttons {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      &__new-stage-btn {
        width: 50%;
        align-self: center;
        margin-bottom: 10px;
      }
    }

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  & .other-stream {
    margin-top: 30px;
  }

  & .post-update-popup {
    position: relative;
    padding: 8px 4px;

    & .popup-comment {
      margin-top: 12px;
      & .pop-up-textarea {
        width: 100%;
        min-height: 120px;
        background: #f4f7fc;
        border-radius: 4px;
        border: none;
        padding: 10px;
        &:focus {
          outline: none;
        }
      }
    }

    & .popup-attachment {
      margin: 20px 0px;
      display: flex;
      align-items: flex-start;
      gap: 20px;
    }

    & .post-attachment {
      margin-bottom: 30px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px 20px;

      & .img-wrapper {
        display: flex;
        width: 100%;
        word-break: break-all;

        & span {
          color: #929daf;
        }

        & .img-container {
          width: 30px;
          height: 30px;
          margin-top: 10px;
        }

        & .img-text {
          margin-top: 10px;
          text-decoration: underline;
        }

        & .remove-icon {
          padding-left: 2px;
          cursor: pointer;
          align-self: center;
        }
      }

      @media (max-width: 767px) {
        grid-template-columns: 1fr;
        gap: 5px;
      }
    }

    & .popup-button {
      margin: 0px auto;
      align-items: center;
      width: 150px;
    }
  }
  & .comment-media-wrap {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  & .view-media-label {
    margin-top: 12px;
  }
  & .view-media-label > p {
    font-size: 14px;
    cursor: pointer;
    text-decoration: underline;
  }
`;
