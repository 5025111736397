import { useState, useCallback, useContext, useEffect, useMemo } from 'react';
import { oneOf, func, string } from 'prop-types';
import styled from 'styled-components';
import { CustomButton, Loader, ModalPopup, SearchField } from 'components';
import Icon from 'utlils/Icons/Icon';
import { capitalizeFirstLetter } from 'pages/IndividualDashboard/individualOrganizations/enterprise/helper';
import {
  FETCH_ASSIGNED_MEMBER_LIST,
  ASSIGN_TASK_TO_MEMBER,
  useAxiosWrapper,
  FETCH_TEAM_MEMBERS,
  GET_RBAC_FOR_TEAM,
} from 'services';
import { IsPermitted, userScopes } from 'utlils';
import tokenService from 'services/token.service';
import { RoleBasedAccessContext } from 'context/RoleBasedAccess/roleBasedAccessContext';
import { setPersonalTeamRBAC } from 'context/RoleBasedAccess/roleBasedAccessAction';

export const AssignToMemberPopUp = ({ onClose, taskId, teamId, type }) => {
  const [fnWrapper, context] = useAxiosWrapper();
  const [searchMember, setSearchMember] = useState('');
  const [assignedMembers, setAssignedMembers] = useState([]);
  const [filteredMembers, setFilteredMembers] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);

  const loggedInUser = useMemo(() => tokenService.getSession(), []);
  const { roleBasedAccessState, roleBasedAccessDispatch } = useContext(
    RoleBasedAccessContext
  );
  const teamPermissions = roleBasedAccessState.personalTeamRBAC;

  const getTeamRBACPermissions = useCallback(async () => {
    const teamRbacRes = await fnWrapper({
      url: `${GET_RBAC_FOR_TEAM}`,
      method: 'GET',
      type: 'GET_RBAC_FOR_TEAM',
      params: {
        teamId,
        userId: loggedInUser.id,
      },
    });
    if (teamRbacRes.data.statusCode === 200) {
      await setPersonalTeamRBAC(roleBasedAccessDispatch, teamRbacRes.data.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUser.id, roleBasedAccessDispatch, teamId]);

  const searchMemberHandler = (value) => {
    setSearchMember(value);
    if (value && teamMembers?.length) {
      const temp = teamMembers.filter((ele) => {
        let str = ele.name + ' ' + ele.designation;
        return str.toLowerCase().indexOf(value.toLowerCase()) > -1;
      });
      setFilteredMembers(temp);
    } else {
      setFilteredMembers(teamMembers);
    }
  };
  const closeSearchMember = () => {
    setSearchMember('');
    setFilteredMembers(teamMembers);
  };

  const selectOrRemoveMember = (memberId) => {
    let temp = [...assignedMembers];
    if (!assignedMembers.includes(memberId)) {
      temp.push(memberId);
      setAssignedMembers(temp);
    } else {
      temp = assignedMembers.filter((id) => id !== memberId);
      setAssignedMembers(temp);
    }
  };

  const getAssignedMembersList = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_ASSIGNED_MEMBER_LIST}/${taskId}`,
      method: 'GET',
      type: 'FETCH_ASSIGNED_MEMBER_LIST',
    });

    if (res.data.statusCode === 200) {
      let arr =
        res.data?.data[0]?.assignToMember &&
        res.data?.data[0]?.assignToMember?.length !== 0
          ? res.data?.data[0]?.assignToMember
          : [];
      setAssignedMembers(arr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const assignTaskToMembers = async () => {
    const res = await fnWrapper({
      url: `${ASSIGN_TASK_TO_MEMBER}`,
      method: 'PUT',
      payload: { memberId: assignedMembers, taskId: taskId },
      type: 'ASSIGN_TASK_TO_MEMBERS',
    });

    if (res.data.statusCode === 200) {
      await onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const getTeamMembersList = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_TEAM_MEMBERS}/${teamId}`,
      method: 'GET',
      type: 'FETCH_TEAM_MEMBERS_LIST',
    });

    if (res.data.statusCode === 200) {
      let membersList = [];
      membersList = res.data?.data[0]?.teamMembers
        .filter((item) => !item?.memberUser?.smallEnterprise)
        .map((item) => {
          let memberData = null;
          if (
            item.status !== '1' &&
            item.status !== '3' &&
            item.status !== '5'
          ) {
            memberData = {
              id: item.id,
              memberUserId: item.memberUserId,
              name: item.memberName,
              designation: capitalizeFirstLetter(item.designation),
              profilePicture:
                item.memberUser?.individual === null
                  ? item.memberUser?.smallEnterprise.profilePicture
                  : item.memberUser?.individual.profilePicture,
            };
          }
          return memberData;
        })
        .filter(Boolean);
      setTeamMembers(membersList);
      setFilteredMembers(membersList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId]);

  useEffect(() => {
    if (type === 'project-tasks' && teamId) {
      getTeamRBACPermissions();
      getTeamMembersList();
      getAssignedMembersList('project');
    }
  }, [
    getAssignedMembersList,
    getTeamMembersList,
    getTeamRBACPermissions,
    teamId,
    type,
  ]);

  useEffect(() => {
    if (type === 'department-tasks' && teamId) {
      getTeamRBACPermissions();
      getTeamMembersList();
      getAssignedMembersList('department');
    }
  }, [
    getAssignedMembersList,
    getTeamMembersList,
    getTeamRBACPermissions,
    teamId,
    type,
  ]);

  return (
    <ModalPopup
      onClose={onClose}
      open={false}
      hasTitle
      title="Assign to Member">
      <AssignWrapper>
        {!teamId ? (
          <div className="text-center mt-5 mb-5">
            Please assign to the team before assigning members.
          </div>
        ) : (
          <>
            {context.busy.status ? (
              <Loader isLoading={context.busy.status} />
            ) : (
              <>
                <SearchField
                  searchField={searchMember}
                  onChangeSearchHandler={searchMemberHandler}
                  closeSearchHandler={closeSearchMember}
                  placeholder="Search Members or designation in your Team"
                />
                {(searchMember !== '' && filteredMembers?.length === 0) ||
                (searchMember === '' && filteredMembers?.length === 0) ? (
                  <div className="no-member">No member found</div>
                ) : (
                  <>
                    <div className="member-list">
                      {filteredMembers?.map((member, index) => (
                        <div className="member-list-wrapper" key={index}>
                          <div className="left-side-style">
                            <div className="img-style">
                              {member.profilePicture &&
                              member.profilePicture !== '' ? (
                                <img
                                  src={member.profilePicture}
                                  alt="project-img"
                                  width="100%"
                                  className="project-img"
                                />
                              ) : (
                                <div className="project-alt-img">
                                  <Icon name="user-icon" color="#404F67" />
                                </div>
                              )}
                            </div>
                            <div className="member-details">
                              <div className="name">{member.name}</div>
                              <div>{member.designation}</div>
                            </div>
                          </div>

                          <CustomButton
                            label={
                              assignedMembers.includes(member.id)
                                ? 'Remove'
                                : 'Select'
                            }
                            customPadding="16px 20px"
                            variant="outline"
                            color="themeSecondary"
                            disabled={
                              !teamPermissions.includes(
                                userScopes.ASSIGN_TASK_TO_TEAM_MEMBER
                              )
                            }
                            className={
                              assignedMembers.includes(member.id) &&
                              'remove-member-btn'
                            }
                            onClick={() => selectOrRemoveMember(member.id)}
                          />
                        </div>
                      ))}
                    </div>
                    <IsPermitted
                      permissions={[userScopes.ASSIGN_TASK_TO_TEAM_MEMBER]}>
                      <CustomButton
                        label="Assign Task"
                        disabled={assignedMembers?.length === 0}
                        color="themeSecondary"
                        onClick={() => assignTaskToMembers(type)}
                        className="assignTask-btn"
                      />
                    </IsPermitted>
                  </>
                )}
              </>
            )}
          </>
        )}
      </AssignWrapper>
    </ModalPopup>
  );
};

const AssignWrapper = styled.div`
  padding-top: 12px;

  & .no-member {
    width: 75%;
    text-align: center;
    margin: 10% auto;
    font-weight: 500;
    color: #929daf;
  }
  .assign-member-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 0;
    border-bottom: 1px solid #c4c4c4;
    margin-bottom: 45px;
    & .header {
      color: #17191d;
      font-weight: 700;
      font-size: 30px;
    }
    & .cross-button {
      margin-top: -12px;
      cursor: pointer;
      margin-right: 10px;
    }
  }

  .member-list {
    margin: 30px 15px;
    & .member-list-wrapper {
      padding: 20px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #929daf;

      & .left-side-style {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;

        & .img-style {
          & .project-img {
            object-fit: cover;
            border-radius: 50%;
            width: 48px;
            height: 48px;
          }

          & .project-alt-img {
            border-radius: 50%;
            width: 48px;
            height: 48px;
            background-color: #e9fbf6;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        & .member-details {
          & .name {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #17191d;
          }
        }
      }
    }
    & .remove-member-btn {
      border: 1px solid #929daf;
      color: #929daf;
    }
  }

  .assignTask-btn {
    width: 50%;
    margin: 20px auto;
  }
`;

AssignToMemberPopUp.propTypes = {
  taskId: string.isRequired,
  teamId: string.isRequired,
  type: oneOf[('project-tasks', 'department-tasks')],
  onClose: func.isRequired,
};
