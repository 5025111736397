import { Route, Routes } from 'react-router-dom';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import FormExample from '../components/formExample';
import SelectOrganization from '../pages/selectOrganization';
import Signup from '../pages/signup';
import { Login } from '../pages/Login';
import EmailConfirmation from '../pages/otp/index';
import ForgotPassword from '../pages/forgotPassword';
import FpEmailInstructions from '../pages/Email/fpEmailInstruction';
import ResetPassword from '../pages/resetPassword';
import SignupVerification from '../pages/Email/SignupVerification/signupVerification';
import ProtectedRoute from '../components/protectedRoute';
import { TeamInvitation } from '../pages/Email/TeamInvitation/teamInvitation';
import { DepartmentInvitation } from 'pages/Email/DepartmentInvitation';
import EnterpriseHomePage from '../pages/enterpriseDashboard/enterpriseDashboard';
import { NetworkIssue } from '../pages/networkIssue';
import { Settings } from 'pages/Settings';
import { MiHintAdmin } from 'pages/MiHintAdmin';
import { MiHintAdminLogin } from 'pages/MiHintAdmin/MiHintAdminLogin';
import { ViewPublication } from 'pages/ViewPublication';
import {
  IndividualProfile,
  IndividualPublic,
  TeamDashboard,
  TeamPublic,
  ProjectPublic,
  Explore,
  EnterprisePublic,
  TaskManagement,
  HomePageV2,
  IndividualERecord,
  Chat,
} from 'pages';
import { AuthorizationSelectionProcess } from 'pages/AuthorizationSelectionProcess';
import { AllNotifications } from 'pages/AllNotifications';
import SuperAdminProtectedRoute from 'components/superAdminProtectedRoute';

const RouterComponent = (props) => {
  return (
    <Routes>
      <Route exact path="/linkedin" element={<LinkedInCallback />} />
      <Route element={<FormExample />} exact path="/form" />
      <Route
        element={
          <HomePageV2
            currentLocale={props.currentLocale}
            handleLocaleChange={props.handleLocaleChange}
          />
        }
        exact
        path="/*"
      />
      <Route element={<EmailConfirmation />} exact path="/email-confirmation" />
      <Route
        element={<SelectOrganization />}
        exact
        path="/:type/selectOrganization"
      />
      {/* route added to test the network issue page */}
      <Route
        element={
          <NetworkIssue
            currentLocale={props.currentLocale}
            handleLocaleChange={props.handleLocaleChange}
          />
        }
        exact
        path="/not-found"
      />
      <Route element={<Signup />} exact path="/signup/:type" />
      <Route
        element={<IndividualERecord />}
        exact
        path="public/individual/e-record/:userId"
      />
      <Route element={<Login />} exact path="/login/:type" />
      <Route element={<MiHintAdminLogin />} exact path="/login/miHintAdmin" />
      <Route element={<SignupVerification />} exact path="/verifyEmail" />
      <Route element={<ForgotPassword />} exact path="/forgot-password" />
      <Route
        element={<FpEmailInstructions />}
        exact
        path="/resetpassword-instructions/:email"
      />
      <Route element={<ResetPassword />} exact path="/resetPassword" />
      <Route
        element={
          <TeamInvitation
            currentLocale={props.currentLocale}
            handleLocaleChange={props.handleLocaleChange}
          />
        }
        exact
        path="/teamInvitation"
      />
      <Route
        element={
          <DepartmentInvitation
            currentLocale={props.currentLocale}
            handleLocaleChange={props.handleLocaleChange}
          />
        }
        exact
        path="/departmentInvitation"
      />
      <Route
        element={<AuthorizationSelectionProcess />}
        exact
        path="/proceed-with"
      />
      <Route
        exact
        path="/individual/profile/*"
        element={
          <ProtectedRoute>
            <IndividualProfile
              currentLocale={props.currentLocale}
              handleLocaleChange={props.handleLocaleChange}
            />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/:dashboardType/settings/*"
        element={
          <ProtectedRoute>
            <Settings
              currentLocale={props.currentLocale}
              handleLocaleChange={props.handleLocaleChange}
            />
          </ProtectedRoute>
        }
      />

      <Route
        exact
        path="/miHintAdmin/*"
        element={
          <SuperAdminProtectedRoute>
            <MiHintAdmin
              currentLocale={props.currentLocale}
              handleLocaleChange={props.handleLocaleChange}
            />
          </SuperAdminProtectedRoute>
        }
      />

      <Route
        exact
        path="/:userType/team-dashboard/:teamId/*"
        element={
          <TeamDashboard
            currentLocale={props.currentLocale}
            handleLocaleChange={props.handleLocaleChange}
          />
        }
      />
      <Route
        exact
        path="/enterprise/*"
        element={
          <ProtectedRoute>
            <EnterpriseHomePage
              currentLocale={props.currentLocale}
              handleLocaleChange={props.handleLocaleChange}
            />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/public/individual/:userId/*"
        element={
          <ProtectedRoute>
            <IndividualPublic
              currentLocale={props.currentLocale}
              handleLocaleChange={props.handleLocaleChange}
            />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/public/publication/:publicationId"
        element={
          <ProtectedRoute>
            <ViewPublication
              currentLocale={props.currentLocale}
              handleLocaleChange={props.handleLocaleChange}
            />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/public/project/:projectId/*"
        element={
          <ProtectedRoute>
            <ProjectPublic
              currentLocale={props.currentLocale}
              handleLocaleChange={props.handleLocaleChange}
            />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/public/team/:teamId/*"
        element={
          <ProtectedRoute>
            <TeamPublic
              currentLocale={props.currentLocale}
              handleLocaleChange={props.handleLocaleChange}
            />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/explore/*"
        element={
          <ProtectedRoute>
            <Explore
              currentLocale={props.currentLocale}
              handleLocaleChange={props.handleLocaleChange}
            />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/public/enterprise/:enterpriseId/*"
        element={
          <ProtectedRoute>
            <EnterprisePublic
              currentLocale={props.currentLocale}
              handleLocaleChange={props.handleLocaleChange}
            />
          </ProtectedRoute>
        }
      />
      <Route
        exact
        path="/:dashboardType/task-management/:id"
        element={
          <ProtectedRoute>
            <TaskManagement
              currentLocale={props.currentLocale}
              handleLocaleChange={props.handleLocaleChange}
            />
          </ProtectedRoute>
        }
      />
      <Route
        path="/:dashboardType/task-management"
        element={
          <ProtectedRoute>
            <TaskManagement
              currentLocale={props.currentLocale}
              handleLocaleChange={props.handleLocaleChange}
            />
          </ProtectedRoute>
        }
      />
      <Route
        path="/allNotifications"
        element={
          <ProtectedRoute>
            <AllNotifications
              currentLocale={props.currentLocale}
              handleLocaleChange={props.handleLocaleChange}
            />
          </ProtectedRoute>
        }
      />
      <Route
        path="/:type/messages/*"
        element={
          <ProtectedRoute>
            <Chat
              currentLocale={props.currentLocale}
              handleLocaleChange={props.handleLocaleChange}
            />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};
export default RouterComponent;
