import { Link, useLocation } from 'react-router-dom';
import { Box, SelectionButton, Title } from 'components';
import '../selectOrganization/selectOrganization.scss';

export const AuthorizationSelectionProcess = (props) => {
  let location = useLocation();

  const processStep = [
    {
      linkTo: '/login/individual',
      label: 'Log in with your existing account',
    },
    {
      linkTo: '/signup/individual',
      label: 'Create a New Account',
    },
  ];

  return (
    <div className="container-fluid">
      <div className="row sign-up">
        <div className="col-lg-7">
          <div className="container">
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-8">
                <div className="d-flex align-items-center justify-content-center">
                  <div className="sign-up-form">
                    {/* logo */}
                    <Box mb="78px" mt="33px">
                      <img
                        src={process.env.PUBLIC_URL + '/new-logo.png'}
                        className="img common-navbar-logo"
                        alt="Logo"
                      />
                    </Box>
                    {/* title and subtitle */}
                    <Box mb="40px">
                      <Title title={'How would you like to proceed?'} />
                    </Box>
                    {processStep.map((item, key) => (
                      <Box mb="20px" key={key}>
                        <Link
                          to={item.linkTo}
                          state={{ redirectUri: location?.state?.redirectUri }}
                          className="text-decoration-none">
                          <SelectionButton
                            rightIcon="arrow-right"
                            iconRight
                            label={item.label}
                            className="proceed-with-selection-btn"
                          />
                        </Link>
                      </Box>
                    ))}
                  </div>
                </div>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
        </div>
        <div className="col-lg-5 d-none d-sm-none d-lg-block image-wrapper ">
          {/* right side image */}
          <div className="right-img"></div>
        </div>
      </div>
    </div>
  );
};
