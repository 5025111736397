import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Policy } from './Components/Policy';
import { policyDetails } from './constant';

export const PolicySection = () => {
  return (
    <Routes>
      {policyDetails.map((policy) => (
        <Route
          key={policy.id}
          path={policy.id}
          element={<Policy title={policy.title} content={policy.content} />}
        />
      ))}
    </Routes>
  );
};
