import { CustomDropDown, Textarea } from 'components';
import { serviceFrequency } from 'pages/Projects/ViewProject/ProjectCollaboration/constant';
import React from 'react';

export const ProjectServiceSubscription = (props) => {
  const { state, setCreateBiddingState, isDisabled } = props;

  const onChangeFaq = (key, value) => {
    setCreateBiddingState((prevState) => ({
      ...prevState,
      biddingFaq: { ...prevState.biddingFaq, [key]: value },
    }));
  };

  return (
    <div>
      {Object.keys(state.biddingFaq).map((item, index) => {
        return (
          <div className="publish-checkbox" key={index}>
            <Textarea
              rows={4}
              label={item}
              value={state.biddingFaq[item]}
              required
              onChange={(e) => onChangeFaq(item, e.target.value)}
              disabled={isDisabled}
            />
          </div>
        );
      })}
      <div className="publish-checkbox mb-text">
        <div className="sub-heading">
          5. The frequency of the service-
          <span style={{ color: 'red' }}>*</span>
        </div>
        <div className="pb-textarea mt-3">
          <CustomDropDown
            optionLists={serviceFrequency}
            selectedItem={state?.serviceFrequency}
            setSelectedItem={(val) =>
              setCreateBiddingState((prevState) => ({
                ...prevState,
                serviceFrequency: val,
              }))
            }
            height="36px"
            customStyle={{
              backgroundColor: '#EBEBEB',
              color: '#404F67',
              padding: '10px 8px 10px 16px',
              whiteSpace: 'normal',
              width: '200px',
              margin: '20px 0px 0px 0px',
            }}
            width={'100%'}
            placeholder={'Select frequency'}
            disabled={isDisabled}
          />
        </div>
      </div>
    </div>
  );
};
