import styled from 'styled-components';

export const MemberInfoCardWrapper = styled.div`
  border: 1px solid #7e8f9a;
  border-radius: 10px;
  padding: 20px 10px 10px 20px;
  display: flex;
  flex: 48% 0 0;
  width: 100%;
  max-width: 400px;
  margin-right: 12px;
  margin-top: 8px;
  justify-content: space-between;
  & .card-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #e9eef4;
    margin: 6px 20px 0 0;
  }
  & .card-icon-pad {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #e9eef4;
    padding-left: 5px;
    padding-top: 3px;
    margin: 6px 20px 0 0;
  }
  & .team-name-wrapper {
    max-width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    & .team-name {
      font-size: 16px;
      font-weight: 600;
    }
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      & .team-designation {
        fontsize: 14px;
      }
    }
  }
  & .team-role {
    margin-left: auto;
    padding: 6px 15px 0 15px;
    height: 40px;
    border-radius: 5px;
    background: #f6f5f5;
  }
  & .team-role-text {
    color: #929daf;
    font-size: 12px;
  }
`;
