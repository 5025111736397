import React from 'react';
import { GroupRequestsWrapper } from './styles';
import { REQUESTS_TABS_LIST } from './constants';
import { TabGroup } from 'components';
import { SentRequests } from './SentRequests';
import { RecievedRequests } from './RecievedRequests';
import Icon from 'utlils/Icons/Icon';
import { Routes, Route, useParams, useNavigate } from 'react-router-dom';

export const Requests = () => {
  const [activeTab, setActiveTab] = React.useState(REQUESTS_TABS_LIST[0]);
  const params = useParams();
  const navigate = useNavigate();

  const tabSwitchHandler = (val) => {
    setActiveTab((prev) => val);
    navigate(
      `/individual/profile/groups/${
        params.groupId
      }/requests/${val?.toLowerCase()}`
    );
  };

  const navigateToGroup = () => {
    navigate(`/individual/profile/groups/${params.groupId}/view/description`);
  };

  return (
    <GroupRequestsWrapper>
      <div className="top-navigator" onClick={() => navigateToGroup()}>
        <Icon name="arrow-back" color="#7E8F9A" size={24} />
        <span>go back to group</span>
      </div>

      <div className="tabs mt-2">
        <TabGroup
          tabLists={REQUESTS_TABS_LIST}
          tabClick={tabSwitchHandler}
          activeTab={activeTab}
        />
      </div>
      <div className="tab-content mt-3">
        <Routes>
          <Route path="">
            <Route path="/sent" element={<SentRequests />} />
            <Route path="/recieved" element={<RecievedRequests />} />
          </Route>
        </Routes>
      </div>
    </GroupRequestsWrapper>
  );
};
