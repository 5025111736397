import { CustomButton } from '../../components';
import { Box } from '../box';
import { useRef } from 'react';

export const UploadButton = ({
  label = '',
  id,
  disabled,
  onChange,
  isLoading,
  color = 'themeGray',
  variant = 'light',
  customPadding,
  children,
}) => {
  const fileRef = useRef();

  return (
    <div className="upload-button-container">
      <form>
        <input
          type="file"
          accept=".png, .jpg, .jpeg"
          className="d-none"
          onChange={onChange}
          id={id}
          disabled={disabled}
          ref={fileRef}
        />
      </form>
      <Box width="100%">
        {!children ? (
          <CustomButton
            label={label}
            loading={isLoading}
            color={color}
            variant={variant}
            customPadding={customPadding}
            disabled={disabled}
            onClick={() => fileRef.current.click()}
            className="w-100 text-muted"
          />
        ) : (
          <CustomButton
            loading={isLoading}
            color={color}
            variant={variant}
            customPadding={customPadding}
            disabled={disabled}
            onClick={() => fileRef.current.click()}
            className="w-100">
            {children}
          </CustomButton>
        )}
      </Box>
    </div>
  );
};
