import styled from 'styled-components';
import HeroBg from '../../../../assests/images/homepage/hero-bg.png';

export const HeroContentWrapper = styled.div`
  height: calc(100vh - 0px);
  background: url(${HeroBg}),
    linear-gradient(252.69deg, #001542 -0.53%, #070f1f 101.52%);
  background-position: center;
  background-repeat: no-repeat;
  .pricing-content {
    font-size: 18px;
    font-weight: normal;
    color: #333;
    line-height: 1.6;
    text-align: center;
    margin: 20px 0;
  }
`;
