import { Box } from '../../../../components';
import Icon from '../../../../utlils/Icons/Icon';
import './profileDropdown.scss';
import { useState } from 'react';

const ProfileDropDown = ({ selected, list, onClick }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className="prof-drop-container">
      <div className="prof-drop-wrapper" onClick={() => setOpen(!open)}>
        <Box display="flex" colGap="20px" alignItems="center">
          {selected?.src && (
            <div>
              <img alt="" src={selected?.src} className="prof-drop-img" />
            </div>
          )}
          <div className="prof-drop-name">{selected?.name}</div>
        </Box>
        <Icon color="black" name="arrow-down-three" size={18} />
      </div>
      {open && (
        <div className="options-wrapper">
          {list.map((list, index) => (
            <div
              key={index}
              className="prof-options"
              onClick={() => {
                onClick(index);
                setOpen(false);
              }}>
              <Box display="flex" colGap="20px" alignItems="center">
                {list?.src && (
                  <div>
                    <img alt="" src={list?.src} className="prof-drop-img" />
                  </div>
                )}
                <div className="prof-drop-name">{list.name}</div>
              </Box>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProfileDropDown;
