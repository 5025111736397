import { createContext, useReducer } from 'react';
import { NotificationReducer } from './notificationReducer';

// Declaring the state object globally.
export const initialState = {
  notifications: [],
  loading: true,
};

export const NotificationContext = createContext({
  notificationState: initialState,
  notificationDispatch: () => null,
});

export const NotificationDispatchContext = createContext(null);

export const NotificationProvider = ({ children }) => {
  const [notificationState, notificationDispatch] = useReducer(
    NotificationReducer,
    initialState
  );
  return (
    <NotificationContext.Provider
      value={{
        notificationState,
        notificationDispatch,
      }}>
      <NotificationDispatchContext.Provider value={notificationDispatch}>
        {children}
      </NotificationDispatchContext.Provider>
    </NotificationContext.Provider>
  );
};
