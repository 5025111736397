export const setLoading = (dispatch, status) =>
  dispatch({ type: 'SET_LOADING', payload: status });

export const setAllNotifications = async (dispatch, res) => {
  await setLoading(dispatch, true);
  await dispatch({
    type: 'SET_NOTIFICATIONS',
    payload: res,
  });
  await setLoading(dispatch, false);
};

export const updateNewNotifications = async (dispatch, res) => {
  await setLoading(dispatch, true);
  await dispatch({
    type: 'UPDATE_NEW_NOTIFICATIONS',
    payload: res,
  });
  await setLoading(dispatch, false);
};

export const clearState = async (dispatch) => {
  await dispatch({
    type: 'CLEAR_STATE',
  });
};
