import styled from 'styled-components';
import { useState } from 'react';
import Icon from '../../../../utlils/Icons/Icon';

const DropDownWrapper = styled.div`
  margin: 30px 0px;
  & .drop-down-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    border-bottom: 2px solid #929daf;
    color: #7e8f9a;
    &:hover {
      cursor: pointer;
    }
  }

  & .sub-elements-wrapper {
    text-align: left;
    margin-top: 20px;
    margin-bottom: 25px;
    color: #404f67;
  }
`;

const ElementsDropdown = ({ open, children }) => {
  return <>{open && <div className="sub-elements-wrapper">{children}</div>}</>;
};

const ProjectContentDropdown = ({ title, children }) => {
  const [isDropdown, setIsDropdown] = useState(false);
  return (
    <DropDownWrapper>
      <div>
        <div
          className="drop-down-header"
          onClick={() => setIsDropdown(!isDropdown)}>
          <div>{title}</div>
          <div>
            {isDropdown ? (
              <Icon color="#404F67" name="arrow-drop-up" size={23} />
            ) : (
              <Icon color="#404F67" name="arrow-down" size={23} />
            )}
          </div>
        </div>
        <ElementsDropdown open={isDropdown}>{children}</ElementsDropdown>
      </div>
    </DropDownWrapper>
  );
};

export default ProjectContentDropdown;
