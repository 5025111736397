import React, { useEffect, useState } from 'react';
import { PermissionWrapper } from './styles';
import { StatusButton } from 'components';
import Icon from 'utlils/Icons/Icon';
import { memberRole } from './constants';
import { SectionCard } from './SectionCard';
import { useParams } from 'react-router-dom';
import { useAxiosWrapper } from 'services';
import { GET_DEPARTMENT_MEMBER_DETAILS } from 'services';
import tokenService from 'services/token.service';

const DEFAULT_CARD_DATA_STATE = [
  {
    label: 'Teams & Projects',
    path: 'teams',
    permissions: [
      { label: 'Create', isChecked: false },
      { label: 'Edit', isChecked: false },
      { label: 'Team Approval', isChecked: true },
    ],
  },
  {
    label: 'Career',
    path: 'career/postedJobs',
    permissions: [
      { label: 'Approve', isChecked: false },
      { label: 'Onboard', isChecked: true },
    ],
  },
  {
    label: 'Tasks',
    path: 'tasks',
    permissions: [
      { label: 'Create', isChecked: false },
      { label: 'Manage', isChecked: true },
    ],
  },
];

export const Permissions = () => {
  const { id } = useParams();
  const [cardsData, setCardsData] = useState(DEFAULT_CARD_DATA_STATE);
  const [fnWrapper] = useAxiosWrapper();
  const [role, setRole] = useState('');

  const userId = tokenService.getSession()?.id;

  const getPermissionsData = async () => {
    const res = await fnWrapper({
      url: `${GET_DEPARTMENT_MEMBER_DETAILS}`,
      method: 'GET',
      type: 'UPDATE_DEPARTMENT_TASKS',
      params: {
        userId: userId,
        departmentId: id,
      },
    });
    const response = res?.data?.data;
    setRole(memberRole[response?.role.toLowerCase()]);
    const card_data = [
      {
        label: 'Teams & Projects',
        path: 'teams',
        permissions: [
          { label: 'Create', isChecked: true },
          { label: 'Edit', isChecked: true },
          {
            label: 'Team Approval',
            isChecked: response?.teamApprovalPermission,
          },
        ],
      },
    ];
    if (response?.careerPermission) {
      card_data.push({
        label: 'Career',
        path: 'career/postedJobs',
        permissions: [
          { label: 'Approve', isChecked: response?.careerPermission },
          { label: 'Onboard', isChecked: response?.careerPermission },
        ],
      });
    }
    if (response?.taskPermission) {
      card_data.push({
        label: 'Tasks',
        path: 'tasks',
        permissions: [
          { label: 'Create', isChecked: response?.taskPermission },
          { label: 'Manage', isChecked: response?.taskPermission },
        ],
      });
    }
    setCardsData(card_data);
  };

  useEffect(() => {
    getPermissionsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PermissionWrapper>
      <div className="department-header">
        <h1 className="department-name">Information Technology</h1>
        <div>
          <StatusButton status={role} />
        </div>
      </div>
      <div className="permissions-disclaimer">
        <div className="disclaimer-info">
          <Icon name="info" color="404f67" size={18} />
          Permissions
        </div>
        <p className="disclaimer-text">
          The account owner has {role.label} privileges and access to these
          sections. For any other permission contact with enterprise superAdmin.
        </p>
      </div>
      <br />
      <div className="section-cards-list">
        {cardsData.map((item, index) => {
          return (
            <SectionCard
              key={index}
              cardTitle={item?.label}
              path={`/individual/profile/organization/enterprise/department/${id}/${item?.path}`}
              permissionsList={item?.permissions}
            />
          );
        })}
      </div>
    </PermissionWrapper>
  );
};
