import styled from 'styled-components';

export const ConversationUsersListWrapper = styled.div`
  position: relative;
  background: #fff;
  box-shadow: -6px 4px 15px 0px rgba(0, 0, 0, 0.05);
  height: 80vh;
  flex-shrink: 0;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;

  .chat-filter {
    position: absolute;
    top: 10;
    right: 0;
    background: #ffffff;
    border: 1px solid #f4f7fc;
    box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    z-index: 1000;
    padding: 20px;
    .chat-filter-button {
      margin-top: 50%;
      div {
        width: 50%;
      }
    }
  }

  .search-people {
    display: flex;
    gap: 5px;
    justify-content: center;
    .filter-search-btn {
      align-self: flex-start;
      height: 100% I !important;
    }
  }

  .messages {
    color: #17191d;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    @media (max-width: 992px) {
      display: none;
    }
  }

  .participants-list {
    overflow-y: auto;
    .conversations-loader {
      margin-top: 20px;
      color: #a4a0a6;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
    }
  }
`;
