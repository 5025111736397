import { ModalPopup } from 'components';
import { getProfileText } from 'utlils/utils';
import {
  MembersListContainer,
  MemberCard,
  MemberImage,
  MemberInfo,
  MemberName,
  MemberEmail,
} from './styles';

export const ConversationMembersListPopup = ({
  handleModalClose,
  convoParticipantsList,
}) => {
  return (
    <>
      <ModalPopup
        onClose={handleModalClose}
        open={false}
        hasTitle
        title="List of members in conversation">
        <MembersListContainer>
          {convoParticipantsList.map((member, index) => (
            <MemberCard key={index}>
              <MemberImage>
                {member?.profilePicture === null ||
                member?.profilePicture === '' ? (
                  <div className="prof-text-wrapper">
                    <span className="prof-text">
                      {getProfileText(
                        member?.role === 'INDIVIDUAL'
                          ? member?.name
                          : member?.enterpriseName
                      )}
                    </span>
                  </div>
                ) : (
                  <img alt="profile-pic" src={member?.profilePicture} />
                )}
              </MemberImage>
              <MemberInfo>
                <MemberName>
                  {member?.role === 'INDIVIDUAL'
                    ? member?.name
                    : member?.enterpriseName}
                </MemberName>
                <MemberEmail>{member?.email}</MemberEmail>
              </MemberInfo>
            </MemberCard>
          ))}
        </MembersListContainer>
      </ModalPopup>
    </>
  );
};
