import styled from 'styled-components';
export const EnterprisePublicWrapper = styled.div`
  .main-container {
    min-height: 100vh;
    background-color: #f4f7fc;
    & .layout-container {
      padding: 40px 8% 20px 8%;
      & .layout-upper-container {
        display: grid;
        grid-template-columns: auto minmax(420px, 450px);
        grid-gap: 40px;
        @media (max-width: 1299px) {
          display: block;
        }
        & .upper-container-child {
          border-radius: 12px;
          padding: 30px;
          background-color: white;
          @media (max-width: 1299px) {
            margin: 25px 0;
          }
        }
      }

      & .layout-lower-container {
        margin-top: 40px;

        & .left-side-layout {
          display: grid;
          grid-template-columns: minmax(200px, 220px) auto;
          grid-gap: 40px;
          @media (max-width: 1199px) {
            display: block;
          }
        }

        & .sub-container {
          @media (max-width: 1199px) {
            margin: 25px 0;
          }
        }
      }
      @media (max-width: 1600px) {
        padding: 40px 6% 20px 6%;
      }
      @media (max-width: 992px) {
        padding: 40px 4% 20px 4%;
      }
    }
  }
`;
