import { createContext, useReducer } from 'react';
import { RoleBasedAccessReducer } from './roleBasedAccessReducer';

// Declaring the state object globally.
export const initialState = {
  personalTeamRBAC: [],
  projectRBAC: [],
  loading: true,
};

export const RoleBasedAccessContext = createContext({
  roleBasedAccessState: initialState,
  roleBasedAccessDispatch: () => null,
});

export const RoleBasedAccessProvider = ({ children }) => {
  const [roleBasedAccessState, roleBasedAccessDispatch] = useReducer(
    RoleBasedAccessReducer,
    initialState
  );
  return (
    <RoleBasedAccessContext.Provider
      value={{
        roleBasedAccessState,
        roleBasedAccessDispatch,
      }}>
      {children}
    </RoleBasedAccessContext.Provider>
  );
};
