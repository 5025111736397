import cn from 'classname';
import { arrayOf, func, shape, string } from 'prop-types';
import styled from 'styled-components';
import { useClickOutside } from 'utlils/Hooks';
import Icon from 'utlils/Icons/Icon';

export const OptionWrapper = (props) => {
  const { className, onClick, options, selectedOption, setOpenDropdown } =
    props;
  const ref = useClickOutside(() => {
    setOpenDropdown(false);
  });
  return (
    <OptionWrp ref={ref} className={cn('ow', className)}>
      {options.length >= 1 ? (
        options.map((item, index) => (
          <div
            key={item.id + index}
            className={cn(
              'ow__options',
              selectedOption === item.id && 'ow__options__active'
            )}
            onClick={() => onClick(item)}>
            {item?.icon && (
              <Icon
                name={item?.icon?.name}
                color={
                  item?.icon?.color
                    ? item?.icon?.color
                    : selectedOption === item.id
                    ? '#ffffff'
                    : '#17191d'
                }
                size={item?.icon?.size || 18}
              />
            )}
            <div>{item.title}</div>
          </div>
        ))
      ) : (
        <div className="no-options">No options available!</div>
      )}
    </OptionWrp>
  );
};

const OptionWrp = styled.div`
  position: absolute;
  top: 50px;
  background: white;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  width: 100%;
  z-index: 10000;
  max-height: 300px;
  overflow: auto;

  & .ow__ {
    &options {
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 15px;

      cursor: pointer;
      border-bottom: 1px solid #e9eef4;
      padding-left: 15px;
      font-weight: 500;
      color: #404f67;
      font-size: 14px;
      &:hover {
        background: #e2ffe0;
        color: #404f67;
      }
      &__active {
        background: #0abd00;
        color: #ffffff;
        font-weight: 700;
        pointer-events: none;
      }
    }
  }

  & .no-options {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
  }
`;

OptionWrapper.propTypes = {
  className: string,
  onClick: func,
  options: arrayOf(
    shape({
      id: string.isRequired,
      icon: shape({
        color: string,
        name: string,
        size: string,
      }),
      title: string.isRequired,
    })
  ).isRequired,
  selectedOption: string,
};
