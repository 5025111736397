import styled from 'styled-components';
export const ViewApplicantWrapper = styled.div`
  .view-application {
    .title {
      font-weight: 500;
      font-size: 16px;
      color: #404f67;
    }
    &__profile--details {
      display: flex;
      align-items: center;
      margin-top: 10px;
      background: #ffffff;
      border: 1px solid #7e8f9a;
      border-radius: 10px;
      width: 478px;
      min-height: 120px;
      padding: 10px 15px;
      .applied-by {
        font-weight: 600;
        font-size: 18px;
        color: #040d20;
      }
      .userDetails {
        .user-name {
          font-weight: 600;
          font-size: 18px;
          color: #040d20;
        }
        .user-title {
          color: #404f67;
          font-weight: 500;
          font-size: 15px;
        }
      }
      .view-profile-button {
        align-self: end;
        margin-left: 30px;
        margin-bottom: 8px;
      }
    }
    &__question {
      margin-top: 20px;
      .main-wrp {
        .title {
          color: #404f67;
          font-weight: 500;
          font-size: 16px;
        }
        .applied-answer {
          margin-top: 10px;
          margin-bottom: 10px;
          background: #ffffff;
          border: 2px solid #e9eef4;
          border-radius: 12px;
          padding: 12px;
        }
      }
    }
    &__specialiazation {
      flex-wrap: wrap;
      margin-top: 30px;
      .tech-stack {
        border-radius: 5px;
        background: #219fff;
        color: white;
        padding: 10px;
      }
    }
  }
`;
