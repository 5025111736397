import styled from 'styled-components';

export const IndividualSettingsWrapper = styled.div`
  & .settings-dashboard-container {
    min-height: 100vh;
    background-color: #f4f7fc;

    & .layout-container {
      padding: 80px 8% 20px 8%;

      & .layout-sub-container {
        display: grid;
        grid-template-columns: minmax(220px, 250px) minmax(auto, 1279px);
        grid-gap: 40px;

        @media (max-width: 1199px) {
          display: block;
        }

        & .sub-container-child {
          @media (max-width: 1199px) {
            margin: 25px 0;
          }
        }

        & .main-wrapper {
          border-radius: 12px;
          padding: 30px;
          background-color: white;

          @media (max-width: 992px) {
            padding: 15px;
          }

          & .header-text {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5px;

            @media only screen and (max-width: 992px) {
              display: grid;
              gap: 15px;
            }

            & .heading {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 700;
              font-size: 25px;
              color: #17191d;

              & span {
                font-weight: 600;
                font-size: 23px;
                color: #c4c4c4;
              }
            }
          }
        }
      }
    }
  }
`;
