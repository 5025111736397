import styled from 'styled-components';
export const InviteIndividualWrapper = styled.div`
  & .drop-down-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  & .drop-down-item {
    flex: 1 1 30%;
    margin: 12px;
  }

  & .drop-down-item-unselected {
    flex-basis: 30%;
    margin: 12px;
  }

  & .designation {
    flex-basis: 30%;
    margin: 12px;
  }

  & .invite-popup-btn {
    margin: 12px auto;
    width: 160px;
  }

  & .describe-text {
    width: 100%;
    margin: 12px;
  }
`;
