import React from 'react';
import { MembershipWrapper } from './style';
import Icon from 'utlils/Icons/Icon';

export const Membership = () => {
  return (
    <MembershipWrapper>
      <div className="membership-tab-styling">
        <div className="heading">
          <span>Membership</span>
        </div>
        <div className="membership-info">
          <Icon name="star" className="star-icon" size={50} />
          <div className="membership-text">
            <p>{`For now, a free version of this product is available to all users.
            When other membership programs are launched, you'll be notified.`}</p>
          </div>
        </div>
      </div>
    </MembershipWrapper>
  );
};
