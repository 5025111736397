import { CustomButton, DropDown } from 'components';
import { EXPLORE_PROJECT_FILTERS } from 'pages/Explore/constants';
import ReactDatePicker from 'react-datepicker';
import { ExploreProjectFilterWrapper } from './style';
import { PROJECT_CATEGORY } from 'constants/constants';

export const ExploreProjectFilter = ({
  state,
  filterTitle,
  filterSaveHandler,
  filterOnChangeHandler,
  enterpriseList,
}) => {
  function renameEnterpriseData(obj) {
    obj['label'] = obj['enterpriseName'];
    obj['value'] = obj['enterpriseName'].toUpperCase().split(' ').join('_');
    return obj;
  }
  let EnterpriseListData = [];
  enterpriseList.forEach((element) => {
    EnterpriseListData.push(renameEnterpriseData(element.enterprise));
  });

  return (
    <ExploreProjectFilterWrapper>
      <div className="filter-title-wrapper">
        <div className="filter-title">{filterTitle}</div>
      </div>
      <div className="filter-input-container">
        <div className="row">
          <div className="col-lg-6">
            <DropDown
              label={EXPLORE_PROJECT_FILTERS.teams_count.label}
              value={state.filteredTeamsCount}
              objectKey={EXPLORE_PROJECT_FILTERS.teams_count.key}
              items={EXPLORE_PROJECT_FILTERS.teams_count.dataType}
              placeholder={EXPLORE_PROJECT_FILTERS.teams_count.placeholder}
              onClick={(e) => filterOnChangeHandler('filteredTeamsCount', e)}
            />
          </div>
          <div className="col-lg-6">
            <DropDown
              label={EXPLORE_PROJECT_FILTERS.collab_Bid.label}
              value={state.filteredCollabBid}
              objectKey={EXPLORE_PROJECT_FILTERS.collab_Bid.key}
              items={EXPLORE_PROJECT_FILTERS.collab_Bid.dataType}
              placeholder={EXPLORE_PROJECT_FILTERS.collab_Bid.placeholder}
              onClick={(e) => filterOnChangeHandler('filteredCollabBid', e)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <DropDown
              label={EXPLORE_PROJECT_FILTERS.project_category.label}
              items={EXPLORE_PROJECT_FILTERS.project_category.dataType}
              objectKey={EXPLORE_PROJECT_FILTERS.project_category.key}
              value={state.filteredCategory}
              placeholder={EXPLORE_PROJECT_FILTERS.project_category.placeholder}
              onClick={(e) => filterOnChangeHandler('filteredCategory', e)}
            />
          </div>
          <div className="col-lg-6">
            <DropDown
              label={EXPLORE_PROJECT_FILTERS.privacy.label}
              items={EXPLORE_PROJECT_FILTERS.privacy.dataType}
              objectKey={EXPLORE_PROJECT_FILTERS.privacy.key}
              value={state.filteredPrivacy}
              placeholder={EXPLORE_PROJECT_FILTERS.privacy.placeholder}
              onClick={(e) => filterOnChangeHandler('filteredPrivacy', e)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <DropDown
              label={EXPLORE_PROJECT_FILTERS.enterprise_name.label}
              items={EnterpriseListData}
              objectKey={EXPLORE_PROJECT_FILTERS.enterprise_name.key}
              value={state.filteredEnterpriseName}
              placeholder={EXPLORE_PROJECT_FILTERS.enterprise_name.placeholder}
              onClick={(e) =>
                filterOnChangeHandler('filteredEnterpriseName', e)
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <DropDown
              hasSearchBox
              label={EXPLORE_PROJECT_FILTERS.category.label}
              items={PROJECT_CATEGORY}
              objectKey={EXPLORE_PROJECT_FILTERS.category.key}
              value={state.filteredCategoryName}
              placeholder={EXPLORE_PROJECT_FILTERS.category.placeholder}
              onClick={(e) => filterOnChangeHandler('filteredCategoryName', e)}
            />
          </div>
        </div>
        <div className="row">
          <label className="date-label">Last Activity (Select Range)</label>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <ReactDatePicker
              dateFormat="MMM dd, yyyy"
              selected={state.fromDate}
              onChange={(date) => filterOnChangeHandler('fromDate', date)}
              selectsStart
              placeholderText="From"
              className="custom-date"
            />
          </div>
          <div className="col-lg-6">
            <ReactDatePicker
              dateFormat="MMM dd, yyyy"
              selected={state.toDate}
              onChange={(date) => filterOnChangeHandler('toDate', date)}
              selectsEnd
              startDate={state.fromDate}
              endDate={state.toDate}
              minDate={state.fromDate}
              placeholderText="To"
              className="custom-date"
            />
          </div>
        </div>
        <div className="row mt-4 gy-2">
          <div className="col-lg-6">
            <CustomButton
              label="Apply"
              className="w-100"
              color="themeSecondary"
              onClick={() => filterSaveHandler('apply')}
            />
          </div>
          <div className="col-lg-6">
            <CustomButton
              label="Cancel"
              variant="light"
              className="w-100"
              color="themeSecondary"
              onClick={() => filterSaveHandler('cancel')}
            />
          </div>
        </div>
      </div>
    </ExploreProjectFilterWrapper>
  );
};
