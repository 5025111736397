import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Sidebar } from 'components';
import ProfileCard from 'pages/IndividualDashboard/components/profileCard';
import { ENTERPRISE_SIDE_BAR_PROFILE_OPTIONS } from 'pages/enterpriseDashboard/constants';
import CreateDetails from './createDetails';
import CreateLocation from './createLocation';
import { CreateEnterpriseProfileWrapper } from './styles';
import { toast } from 'react-toastify';
import axios from 'axios';

function byteToMB(byteValue) {
  return byteValue / 1024 ** 2;
}
const CreateProfile = (props) => {
  let { userProfile, setUserProfile } = props;
  let { type = 'create', step = 'details' } = useParams();
  let navigate = useNavigate();
  const [currType, setCurrType] = useState(step);
  const [profilePicUploading, setProfilePicUploaded] = useState(false);

  const setEditPage = (value) => {
    setCurrType(value);
    navigate(`/enterprise/profile/${type}/${value}`);
  };

  //Taken directly from individual create profile
  const uploadProfilePictureHandler = async (e) => {
    try {
      setProfilePicUploaded(true);
      const session = JSON.parse(localStorage.getItem('session'));
      const formData = new FormData();
      if (byteToMB(e.target.files[0].size) > byteToMB(1000000)) {
        setProfilePicUploaded(false);
        toast.warning('Image size should be less than 1MB', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        formData.append('profilePicture', e.target.files[0]);

        await axios
          .post(
            `${process.env.REACT_APP_API_BASE_URL}/upload/profilePicture/${userProfile.userId}`,
            formData,
            {
              headers: {
                'x-access-token': `${session.access_token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.statusCode === 200) {
              setProfilePicUploaded(false);
              setUserProfile({
                ...userProfile,
                profilePicture: res.data.data,
              });
              toast.success(res.data.message, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
              });
            }
          })
          .catch((err) => {
            setProfilePicUploaded(false);
            toast.error(err.response.data.message, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored',
            });
          });
      }
    } catch (error) {
      setProfilePicUploaded(false);
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  return (
    <CreateEnterpriseProfileWrapper>
      <div className="enterprise-detail-container">
        <div className="layout-container">
          <Box className="layout-sub-container">
            <Box className="sub-container-child">
              <ProfileCard
                isUpload
                profilePicUploading={profilePicUploading}
                uploadProfilePicture={uploadProfilePictureHandler}
                userProfile={{
                  ...userProfile,
                  name: userProfile?.enterpriseName,
                }}
              />
              <Box mt="1.5rem">
                <Sidebar
                  sidebarData={ENTERPRISE_SIDE_BAR_PROFILE_OPTIONS}
                  activeType={currType}
                  setActiveType={setEditPage}
                />
              </Box>
            </Box>
            <Box className="sub-container-child">
              <Box
                bg="white"
                className="main-wrapper"
                display={currType === null && 'none'}>
                {currType === 'details' && (
                  <CreateDetails
                    userProfile={userProfile}
                    setUserProfile={setUserProfile}
                    setEditPage={setEditPage}
                  />
                )}
                {currType === 'location' && <CreateLocation />}
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    </CreateEnterpriseProfileWrapper>
  );
};

export default CreateProfile;
