const { default: styled } = require('styled-components');

export const PublicationCardWrapper = styled.div`
  font-family: Poppins;
  padding: 15px 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  height: 281px;
  border-radius: 10px;
  border: 0.48px #ccd2dd solid;
  .publication-header {
    display: flex;
    justify-content: space-between;
    .header-image-and-title {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      .publication-image {
        width: 50px;
        height: 50px;
        border-radius: 10px;
        background-color: #c4c4c4;
      }
      .publication-title {
        font-size: 15px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #404f67;
      }
    }
    .publication-card-options {
      .options-class {
        cursor: pointer;
      }
    }
  }
  .publication-card-description {
    max-width: 900px;
    height: 50px;
    font-size: 14px;
    font-weight: 400;
    line-height: 23px;
    color: #7e8f9a;
    text-overflow: ellipsis;
    overflow: hidden;
    /* white-space: nowrap; */
  }
  .publication-card-footer {
    display: flex;
    align-items: flex-end;
    .publisher-info {
      display: flex;
      flex-direction: column;
      width: 100%;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      .publisher-identity {
        display: flex;
        gap: 5px;
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        align-items: center;

        .username-update-data {
          .update-date {
            color: #7e8f9a;
            font-size: 12px;
            font-style: italic;
          }

          .user-name {
            text-decoration: underline;
            cursor: pointer;
          }
        }

        .publisher-image {
          overflow: hidden;
          font-size: 2px;
          scale: inherit;
          background-color: #e9fbf6;
          padding: 6px;
          border-radius: 50%;
        }
        .profile-pic-class {
          img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
          }
        }
      }
    }
    .navigational-btns {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
`;
