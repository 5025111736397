import styled from 'styled-components';

export const MediaCardWrapper = styled.div`
  margin: 12px 12px 12px 0;
  & .media-card-content {
    border-radius: 10px;
    max-width: 100px;
    max-height: 80px;
  }
  & .image-container {
    position: relative;
    display: inline-block;
  }

  & .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    cursor: pointer;
  }

  & .play-button::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-40%, -50%);
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 16px solid #fff;
  }

  & .play-button:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
  & .black-image {
    background-color: black;
    width: 100px;
    height: 100px;
  }
`;
