import React from 'react';
import NetIssueIcon from '../../assests/images/network-issue.svg';
import { LanguageSelect } from '../../components';
import styled from 'styled-components';
import { LanguageData } from 'constants';

const StyledWrapper = styled.div`
  background-color: #f4f7fc;
  height: 100vh;
  & .not-found_nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #ffffff;
    height: 10vh;
  }
  & .not-found_container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 90vh;
    font-size: 22px;
    @media (max-width: 1200px) {
      flex-direction: column;
    }
    & img {
      max-width: 100%;
      height: auto;
      @media (max-width: 1400px) {
        width: 50%;
      }
      @media (max-width: 1200px) {
        width: 60%;
        padding: 3vh 0 0 0;
      }
    }
    & .not-found_container__text {
      width: 40%;
      @media (max-width: 1200px) {
        width: 75%;
        margin: auto;
      }
      & p,
      & li {
        line-height: 30px;
        padding: 20px 0;
      }
      & .linkpara {
        line-height: 34px;
        padding: 34px 0;
        & a {
          color: #404f67;
          font-style: italic;
        }
      }
    }
  }
`;

export const NetworkIssue = ({ currentLocale, handleLocaleChange }) => {
  return (
    <StyledWrapper>
      <div className="not-found_nav">
        <img
          src={process.env.PUBLIC_URL + '/new-logo.png'}
          alt="logo-black"
          className="common-navbar-logo"
        />
        <LanguageSelect
          option_items={LanguageData}
          color="#404F67"
          value={currentLocale}
          onChange={handleLocaleChange}
        />
      </div>

      <div className="not-found_container">
        <img src={NetIssueIcon} alt="404-not-found" />
        <div className="not-found_container__text">
          <p>
            Oh! looks like we’re having some trouble while loading this page.
          </p>
          <ul>
            <li>Refresh this page.</li>
            <li>Please check your network connection.</li>
            <li>Or, come back after sometime.</li>
          </ul>
          <div className="linkpara">
            If this issue doesn’t resolve, please let us know at &nbsp;
            <a href="mailto:info@linkteamsup.com">info@linkteamsup.com</a>
          </div>
        </div>
      </div>
    </StyledWrapper>
  );
};
