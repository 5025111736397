import styled from 'styled-components';

export const GroupRequestsWrapper = styled.div`
  background-color: white;
  position: relative;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding: 10px 20px;
  .top-navigator {
    margin-top: 16px;
    color: #7e8f9a;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
  }
  .heading {
    font-size: 23px;
    margin-top: 8px;
  }

  .no-requests {
    margin-top: 8px;
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    color: #808080;
  }
`;
