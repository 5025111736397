import { HomePageWrapper } from './style';
import { Route, Routes } from 'react-router-dom';
import { FeaturesPage } from './FeaturesPage';
import { LandingPage } from './LandingPage/index.js';
import { ScrollToTop } from './utils';
import { PolicySection } from './PolicySection';
import { PricingPage } from './PricingPage';
import { SolutionsPage } from './SolutionsPage';

export const HomePageV2 = () => {
  return (
    <HomePageWrapper>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="features" element={<FeaturesPage />} />
          <Route path="solutions" element={<SolutionsPage />} />
          <Route path="pricing" element={<PricingPage />} />
          <Route path="policies/*" element={<PolicySection />} />
        </Routes>
      </ScrollToTop>
    </HomePageWrapper>
  );
};
