import Icon from 'utlils/Icons/Icon';
import {
  Box,
  CustomCheckBox,
  DropDown,
  Input,
  MonthYearPicker,
} from 'components';

export const EducationFieldItems = ({
  degrees,
  data,
  index,
  onInputChangeHandler,
  onDeleteRow,
}) => {
  return (
    <div className="row">
      {index !== 0 && (
        <Box
          cursor="pointer"
          display="flex"
          justifyContent="space-between"
          alignItems="center">
          <div
            style={{ width: '90%', borderBottom: '1px solid #929DAF' }}></div>
          <Icon name="delete" onClick={() => onDeleteRow(index)} />
        </Box>
      )}
      <div className="row">
        <div className="col-md-6 col-lg-6 col-xl-4 col-12">
          <Box mt="10px">
            <Input
              label="Institute Name"
              iconLeft={true}
              iconName="building"
              placeholder="Name of the Institution"
              type="text"
              required
              value={data?.instituteName}
              onChange={(e) =>
                onInputChangeHandler(e.target.value, index, 'instituteName')
              }
            />
          </Box>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-4 col-12">
          <Box mt="10px">
            <DropDown
              label="Degree Type"
              placeholder="Select"
              objectKey="value"
              required
              items={degrees}
              value={data?.degree}
              onClick={(value) => onInputChangeHandler(value, index, 'degree')}
            />
          </Box>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-4 col-12">
          <Box mt="10px">
            <Input
              label="Course"
              placeholder="i.e, Bachelors in Designing"
              type="text"
              value={data?.course}
              onChange={(e) =>
                onInputChangeHandler(e.target.value, index, 'course')
              }
              required
            />
          </Box>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-4 col-12">
          <Box mt="10px">
            <Input
              label="Location"
              iconLeft={true}
              iconName="location"
              placeholder="Location"
              type="text"
              required
              onChange={(e) =>
                onInputChangeHandler(e.target.value, index, 'location')
              }
              value={data?.location}
            />
          </Box>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-4 col-12">
          <Box
            mt="10px"
            display="flex"
            width="100%"
            alignItems="base"
            colGap="10px">
            <MonthYearPicker
              label={'From'}
              required
              onDateChangeHandler={(date, type) => {
                onInputChangeHandler(date, index, `from-${type}`);
              }}
              selected={data?.from}
            />
          </Box>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-4 col-12">
          {!data?.isPersue && (
            <Box
              mt="10px"
              display="flex"
              width="100%"
              alignItems="base"
              colGap="10px">
              <MonthYearPicker
                label={'To'}
                required
                onDateChangeHandler={(date, type) => {
                  onInputChangeHandler(date, index, `to-${type}`);
                }}
                index={index}
                selected={data?.to}
              />
            </Box>
          )}
        </div>
        <div className="col-md-12 col-12">
          <Box display="flex" alignItems="center" colGap="7px" mb="20px">
            <CustomCheckBox
              onCheckBg="white"
              check={data.isPersue}
              tickColor="#0abd00"
              onClick={(e) =>
                onInputChangeHandler(e.target.value, index, 'isPersue')
              }
              checkBorder="1px solid #0abd00"
            />
            <Box ml="20px">
              <span className="label">I am currently pursuing</span>
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
};
