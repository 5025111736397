import privacyPolicyContent from './content/privacyPolicy';
import termsAndConditionContent from './content/termsAndCondition';

export const policyDetails = [
  {
    id: 'privacy_policy',
    title: 'Privacy Policy',
    content: privacyPolicyContent,
  },
  {
    id: 'terms_and_conditions',
    title: 'Terms and Conditions',
    content: termsAndConditionContent,
  },
];
