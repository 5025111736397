import styled from 'styled-components';
import { Country, State } from 'country-state-city';
import classname from 'classname';
import { DropDown } from 'components/dropDown';

export const CountryWithCity = (props) => {
  const {
    className,
    countryLabel = 'Country',
    cityLabel = 'City',
    isCountryRequired = false,
    isCityRequired = false,
    onCountrySelect,
    onCitySelect,
    selectedCountry,
    selectedCity,
  } = props;
  const allCountryLists = Country.getAllCountries();

  return (
    <>
      <Wrp className={classname('country-wrp', className)}>
        <DropDown
          label={countryLabel}
          items={allCountryLists}
          objectKey="name"
          placeholder="Select"
          onClick={(item) =>
            onCountrySelect({
              name: item.name,
              value: item.isoCode,
            })
          }
          value={selectedCountry}
          hasSearchBox
          required={isCountryRequired}
        />
      </Wrp>
      <Wrp className={classname('city-wrp', className)}>
        <DropDown
          disabled={!selectedCountry}
          label={cityLabel}
          items={State.getStatesOfCountry(selectedCountry?.value)}
          objectKey="name"
          placeholder="Select"
          onClick={(item) => {
            onCitySelect({
              name: item.name,
              value: item.name,
            });
          }}
          value={selectedCity}
          hasSearchBox
          required={isCityRequired}
        />
      </Wrp>
    </>
  );
};

const Wrp = styled.div`
  margin-top: 10px;
`;
