import styled from 'styled-components';

export const ViewJobWrapper = styled.div`
  .upper-container {
    background-color: white;
    border-radius: 12px;
    margin-bottom: 20px;
    padding: 30px;
    & .heading {
      padding-bottom: 8px;
      border-bottom: 1px solid #e9eef4;
      margin-bottom: 20px;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #17191d;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;
      & .heading-back-btn {
        padding-right: 10px;
        &:hover {
          cursor: pointer;
        }
      }
    }
    & .title-class {
      font-size: 24px;
      line-height: 16px;
      font-weight: 500;
      color: #040d20;
      display: flex;
      align-items: center;
      justify-content: space-between;
      & .left-title {
        display: flex;
        align-items: center;
        gap: 12px;
        & .profile-pic-class,
        .profile-pic-class img {
          width: 82px;
          height: 82px;
          border-radius: 50%;
        }
        & .icon-style-class {
          width: 82px;
          height: 82px;
          border-radius: 50%;
          background: #e9fbf6;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &-text {
          line-height: 28px;
        }
        & .subTitle-class {
          display: flex;
          align-items: center;
          gap: 12px;
          font-weight: 400;
          font-size: 16px;
          line-height: 16px;
          color: #404f67;
          & .right-border {
            border-right: 2px solid #404f67;
            padding-right: 12px;
          }
        }

        @media (max-width: 450px) {
          flex-direction: column;
          align-items: center;
        }
      }
      & .right-title {
        display: grid;
        grid-template-columns: ${({ isSameUser }) =>
          !isSameUser ? '1fr 1fr' : ' 1fr 0.5fr 0.5fr'};
        grid-gap: 10px;
        @media (max-width: 467px) {
          width: 100%;
          grid-template-columns: 1fr;
        }
      }
      @media (max-width: 950px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
      }
    }
    & .main-content-class {
      margin-top: 28px;
      display: grid;
      ${'' /* align-items: center; */}
      grid-template-columns: 1fr 4fr;
      & .leftside-class {
        margin-right: 10%;
        align-self: flex-start;
        & .main--feature {
          & .feature-wrapper {
            margin-bottom: 28px;
            & .feature-style {
              display: flex;
              align-items: center;
              gap: 8px;
            }
            & p {
              margin-left: 0px;
              margin-top: 16px;
              font-weight: 600;
              font-size: 16px;
              line-height: 16px;
              color: #040d20;
              margin-bottom: 0;
            }
            & .feature-value {
              margin-top: 16px;
              display: flex;
              align-items: center;
              gap: 8px;
              & .value-img-class,
              img {
                width: 45px;
                height: 45px;
                border-radius: 50%;
              }
              & .value-icon-class {
                width: 45px;
                height: 45px;
                border-radius: 50%;
                background: #e9fbf6;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              &-text {
                margin-top: 0px;
              }
            }
          }
          @media (max-width: 767px) {
            display: grid;
            grid-template-columns: 1fr 1fr;
          }
          @media (max-width: 400px) {
            display: grid;
            grid-template-columns: 1fr;
          }
        }
      }
      & .rightside-class {
        border-left: 1px solid #404f67;
        padding-left: 28px;
        & .details-item {
          margin-bottom: 12px;
          color: #404f67;

          &-labels {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #040d20;
            text-decoration: underline;
            margin-bottom: 0;
          }
          & ul {
            margin-bottom: 0;
          }
        }

        @media (max-width: 767px) {
          border-left: none;
          padding-left: 0;
        }
      }

      @media (max-width: 767px) {
        grid-template-columns: 1fr;
      }
    }
  }
  .lower-container {
    border-radius: 12px;
    padding: 30px;
    background-color: white;
    margin-bottom: 20px;
    &-title {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #404f67;
      margin-bottom: 28px;
    }
    & .project-team-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 20px;
      @media (max-width: 899px) {
        display: block;
        & .team-card-class {
          margin-top: 12px;
        }
      }
    }
  }
  .pcw-jobtitle {
    font-family: 'Poppins', 'Open Sans';
    font-size: 20px;
    font-weight: 500;
    line-height: 16px;
    color: #0abd00;
  }
  .pcw-owner-name {
    font-family: 'Poppins', 'Open Sans';
    font-size: 20px;
    font-weight: 500;
    line-height: 16px;
    color: #219fff;
  }
  .popup-title-class {
    font-weight: 500;
    font-size: 25px;
    line-height: 16px;
    color: green;
  }
  .popup-content {
    display: flex;
    gap: 70px;
    justify-content: left;
    margin-top: 20px;
    text-align: left;
    align-items: center;
    @media (max-width: 576px) {
      flex-direction: column;
      gap: 30px;
    }
    .apply-with-container {
      min-height: 147px;
      padding: 25px;
      display: flex;
      align-items: center;
      gap: 25px;
      border: 1px solid #7e8f9a;
      border-radius: 10px;
      @media (max-width: 576px) {
        flex-direction: column;
        width: 100%;
      }
      .profile-details {
        @media (max-width: 576px) {
          display: flex;
          align-items: center;
          flex-direction: column;
        }
        .name {
          font-weight: 600;
          font-size: 18px;
          color: #040d20;
        }
        .title {
          font-weight: 500;
          font-size: 15px;
        }
        .button-upload {
          margin-top: 15px;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          @media (max-width: 576px) {
            flex-direction: column;
          }
          .upload-button {
            height: 35px;
            width: 106px;
            cursor: pointer;
            color: black !important;
          }
          .view-button {
            margin-top: -15px;
            text-decoration: underline;
          }
          input[type='file'] {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            width: 106px;
            height: 35px;
            font-weight: 500;
            font-size: 15px;
            margin-top: 10px;
          }
          .resume-name {
            color: #219fff;
          }
        }
      }
      .continue {
        width: 120px;
      }
    }
  }
  .question-technology {
    margin-top: 20px;
    .apply-submit {
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: center;
      div {
        width: 120px;
      }
    }
    .specialization {
      margin-top: 20px;
      margin-bottom: 30px;
      .title {
      }
      .technology {
        display: flex;
        align-items: center;
        gap: 8px;
        flex-wrap: wrap;
        .item {
          margin-top: 20px;
          min-width: 100px;
          padding: 16px 27px;
          background: #e9eef4;
          border-radius: 12px;
          color: #e9eef4;
          font-weight: 500;
          font-size: 15px;
          color: #7e8f9a;
          cursor: pointer;
        }
        .active {
          background: #219fff;
          color: #ffffff;
        }
      }
    }
  }
  .successfully-applied {
    text-align: center;
    .success-message {
      font-weight: 500;
      font-size: 17px;
      color: #404f67;
    }
    .click-here {
      font-weight: 500;
      font-size: 17px;
      color: #0abd00;
      text-decoration: underline;
    }
  }
  .radio-btn-class {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
    margin-top: 0;
  }
`;
