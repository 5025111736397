import { EnterprisePublicWrapper } from './style';
import { useState, useEffect, useCallback } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import {
  FETCH_USER_PROFILE,
  FETCH_PUBLIC_ENTERPRISE_DETAILS,
  useAxiosWrapper,
} from 'services';
import TokenService from 'services/token.service';
import { getProfileText } from 'utlils/utils';
import { EnterprisePublicDetails } from './EnterprisePublicDetails';
import { EnterprisePublicTeams } from './EnterprisePublicTeams';
import { EnterprisePublicProjects } from './EnterprisePublicProjects';
import { EnterprisePublicPeople } from './EnterprisePublicPeople';
import { EnterprisePublicPublications } from './EnterprisePublicPublications';
import { EnterprisePublicCareer } from './EnterprisePublicCareer';
import { EnterprisePublicCard } from './Components';
import { Loader, NavbarBrand1, Sidebar } from 'components';
import Icon from 'utlils/Icons/Icon';
import { getEnterprisePublicLeftSideData } from './constants';
import { INDIVIDUAL_DASHBOARD_NAV_LIST } from 'pages/IndividualDashboard/constants';

export const EnterprisePublic = ({ currentLocale, handleLocaleChange }) => {
  const [fnWrapper] = useAxiosWrapper();
  const [userProfile, setUserProfile] = useState({});
  const { enterpriseId } = useParams();
  const [enterpriseInfo, setEnterpriseInfo] = useState([]);
  const [leftActiveItem, setLeftActiveItem] = useState('');
  const [enterpriseLocationDetails, setEnterpriseLocationDetails] = useState(
    []
  );
  const [profileSettings, setProfileSettings] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const setLeftSidebar = (type) => {
    setLeftActiveItem(type);
  };

  const loggedInUser = TokenService.getSession();

  const getUserProfile = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_USER_PROFILE}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_USER_PROFILE',
    });
    if (res.data.statusCode === 200) {
      const response = res.data.data;
      const isEnterprise = 'enterpriseId' in loggedInUser;
      const profileText = isEnterprise
        ? getProfileText(response?.enterpriseName)
        : getProfileText(response?.name);
      setUserProfile({
        ...response,
        profileText: profileText,
      });
      setProfileSettings(response?.enterpriseSetting);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getEnterpriseDetails = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_PUBLIC_ENTERPRISE_DETAILS}`,
      method: 'GET',
      params: { enterpriseId: enterpriseId },
      type: 'FETCH_PUBLIC_ENTERPRISE_DETAILS',
    });
    if (res.data.statusCode === 200) {
      const response = res.data.data[0];
      if (loggedInUser?.enterpriseId) {
        setUserProfile((prevState) => ({
          ...prevState,
          name: response?.enterpriseName,
          EnterpriseProfilePicture: response?.profilePicture,
        }));
      } else {
        setUserProfile((prevState) => ({
          ...prevState,
          enterpriseCategory: response?.enterpriseCategory,
          enterpriseName: response?.enterpriseName,
          enterpriseType: response?.enterpriseType,
          EnterpriseProfilePicture: response?.profilePicture,
          id: response?.id,
        }));
      }

      const item = {
        id: response?.id,
        enterpriseName: response?.enterpriseName,
        profileImg: response?.profilePicture,
        enterpriseCategory: response?.enterpriseCategory?.title,
        totalEmployees: '3',
        link: response?.link,
        details: response?.aboutEnterprise,
        location: response?.user?.userLocations[0]?.city || 'N/A',
      };

      setEnterpriseInfo(item);

      setEnterpriseLocationDetails(response?.user?.userLocations);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialApiCall = useCallback(async () => {
    setIsLoading(true);
    await getUserProfile();
    await getEnterpriseDetails();
    setIsLoading(false);
  }, [getEnterpriseDetails, getUserProfile]);

  useEffect(() => {
    initialApiCall();
  }, [initialApiCall]);

  const profileCardButtons = [
    {
      label: 'View Website',
      variant: 'light',
      color: 'themeSecondary',
      iconName: 'export-2',
      fontColor: '#219fff',
      onClick: () => window.open(`http://${enterpriseInfo.link}`, '_blank'),
    },
    // {
    //   label: 'Contact Us',
    //   color: 'themeSecondary',
    //   variant: 'solid',
    //   iconName: 'arrow-forward',
    //   fontColor: '#ffffff',
    //   onClick: () => {
    //     // editBtnHandler(!isEditPopupOpen);
    //     // updateOnProfileEdit(false);
    //   },
    // },
  ];

  const infoItems = [
    {
      label: 'Website',
      value: enterpriseInfo?.location,
      iconName: 'location',
    },
    {
      label: 'Members',
      value: `${enterpriseInfo?.totalEmployees} Employees`,
      iconName: 'people',
    },
  ];

  return isLoading ? (
    <Loader loading={isLoading} />
  ) : (
    <>
      <NavbarBrand1
        currentLocale={currentLocale}
        handleLocaleChange={handleLocaleChange}
        navMenu={INDIVIDUAL_DASHBOARD_NAV_LIST(userProfile?.role)}
        userProfile={userProfile}
      />
      <EnterprisePublicWrapper>
        <div className="main-container">
          <div className="layout-container">
            <div className="layout-upper-container">
              <div className="upper-container-child">
                <EnterprisePublicCard
                  userProfile={userProfile}
                  profileCardButtons={profileCardButtons}
                  infoItems={infoItems}
                />
              </div>
              <div className="upper-container-child">
                <div className="d-flex align-items-center">
                  <Icon name="stats" color="#404f67" size={24} />
                  <span className="px-2" style={{ color: '#404f67' }}>
                    <strong>E-Record</strong>
                  </span>
                </div>
                <div className="d-flex justify-content-center align-items-center h-100 flex-column">
                  <Icon name="warning" size={40} color="#929daf" />
                  <div className="py-3 text-center">Stats not available</div>
                </div>
              </div>
            </div>
            <div className="layout-lower-container">
              <div className="left-side-layout">
                <div className="sub-container">
                  <Sidebar
                    sidebarData={getEnterprisePublicLeftSideData(
                      enterpriseId,
                      profileSettings
                    )}
                    activeType={leftActiveItem}
                    setActiveType={setLeftSidebar}
                  />
                </div>
                <Routes>
                  <Route path="">
                    <Route
                      element={
                        <EnterprisePublicDetails
                          currentLocale={currentLocale}
                          handleLocaleChange={handleLocaleChange}
                          enterpriseLocationDetails={enterpriseLocationDetails}
                          details={enterpriseInfo?.details}
                        />
                      }
                      path="details/*"
                    />
                    <Route
                      element={
                        <EnterprisePublicTeams
                          currentLocale={currentLocale}
                          handleLocaleChange={handleLocaleChange}
                        />
                      }
                      path="teams/*"
                    />
                    <Route
                      element={
                        <EnterprisePublicProjects
                          currentLocale={currentLocale}
                          handleLocaleChange={handleLocaleChange}
                        />
                      }
                      path="projects/*"
                    />
                    <Route
                      element={
                        <EnterprisePublicPeople
                          currentLocale={currentLocale}
                          handleLocaleChange={handleLocaleChange}
                          userRole={userProfile?.role}
                        />
                      }
                      path="people/*"
                    />
                    <Route
                      element={
                        <EnterprisePublicPublications
                          currentLocale={currentLocale}
                          handleLocaleChange={handleLocaleChange}
                        />
                      }
                      path="publications/*"
                    />
                    <Route
                      element={
                        <EnterprisePublicCareer
                          currentLocale={currentLocale}
                          handleLocaleChange={handleLocaleChange}
                        />
                      }
                      path="career/*"
                    />
                  </Route>
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </EnterprisePublicWrapper>
    </>
  );
};
