import { CustomButton, ScrollableDiv } from 'components';
import React from 'react';
import Icon from 'utlils/Icons/Icon';
import { EnterpriseTeamProjectCardWrapper } from './style';

export const EnterpriseTeamProjectCard = ({
  profileImgSrc,
  profileIcon,
  title,
  type,
  subTitle,
  subTitleIcon,
  description,
  viewHandler,
  features = [],
  isPublished,
}) => {
  return (
    <EnterpriseTeamProjectCardWrapper type={type}>
      <div className="content-header">
        {profileImgSrc && profileImgSrc !== '' ? (
          <div className="profile-pic-class">
            <img src={profileImgSrc} alt="profile-pic" />
          </div>
        ) : (
          <div className="icon-style-class">
            <Icon name={profileIcon} size={24} color="#404f67" />
          </div>
        )}
        <div className="title-wrapper">
          <div className="title-wrapper-title">{title}</div>
          <div className="title-wrapper-subtitle">
            {subTitleIcon && subTitleIcon !== '' && (
              <Icon name={subTitleIcon} size={24} color="#404f67" />
            )}
            <span className="title-wrapper-subtitle-text">{subTitle}</span>
          </div>
        </div>
      </div>
      <div className="feature-class">
        {features.length !== 0 &&
          features.map((feature, index) => (
            <div className="feature-style" key={index}>
              <Icon name={feature.iconName} size={20} color="#404f67" />
              <span>{feature.label}</span>
            </div>
          ))}
      </div>
      <ScrollableDiv scrollOnHeight="50px">
        <div className="description">{description}</div>
      </ScrollableDiv>
      <div className="view-button">
        <CustomButton
          color={
            isPublished || type === 'team' ? 'themeSecondary' : 'themeGray'
          }
          variant="outline"
          disabled={!isPublished && type === 'project'}
          onClick={viewHandler}
          className="w-100"
          customPadding="14px 14px">
          {!isPublished && type === 'project' && <Icon name="lock-two" />}
          {!isPublished && type === 'project' ? 'Private' : 'View'}
        </CustomButton>
      </div>
    </EnterpriseTeamProjectCardWrapper>
  );
};
