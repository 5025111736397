import { Route, Routes, useParams } from 'react-router-dom';
import { ViewProject } from 'pages/Projects';
import { TeamProjectsList } from './TeamProjectsList';
import { TYPES } from 'pages/IndividualDashboard/constants';

export const TeamProjects = () => {
  const { teamId, userType } = useParams();

  return (
    <Routes>
      <Route path="">
        <Route element={<TeamProjectsList />} path="" />
        <Route
          element={
            <ViewProject
              type={TYPES.TEAM_DASHBOARD}
              baseUrl={`/${userType}/team-dashboard/${teamId}/projects`}
            />
          }
          path=":projectId/view/*"
        />
      </Route>
    </Routes>
  );
};
