import styled from 'styled-components';

export const ProfilePhotoStyle = styled.div`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border-radius: 50%;
  background: ${({ hasProfilePhoto }) => (!hasProfilePhoto ? '#F4F7FC' : '')};
  & .profile-img {
    border-radius: 50%;
  }
  & .profile-text {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 20px;
  }
`;
