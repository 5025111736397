import { Route, Routes } from 'react-router-dom';
import { Requests } from './Requests';
import { SearchPeople } from './SearchPeople';
import { YourNetwork } from './YourNetwork';

export const ExploreNetworks = () => {
  return (
    <Routes>
      <Route path="">
        <Route element={<SearchPeople />} path="search-people" />
        <Route element={<YourNetwork />} path="your-network" />
        <Route element={<Requests />} path="requests" />
      </Route>
    </Routes>
  );
};
