import { createContext, useReducer } from 'react';
import { CreateTeamReducer } from './createTeamReducer';

// Declaring the state object globally.
export const initialState = {
  teamName: '',
  topic: '',
  description: '',
  teamSize: {},
  teamType: {},
  teamUserName: '',
  teamPassword: '',
  teamCategory: '',
  teamMembers: [
    {
      memberName: '',
      designation: '',
      role: {},
      memberEmail: '',
    },
  ],
  teamOwnerName: '',
  teamOwnerDesignation: '',
  teamOwnerRole: '',
  teamOwnerEmail: '',
  loading: false,
  progressStep: 1,
};

export const CreateTeamContext = createContext({
  createTeamState: initialState,
  createTeamDispatch: () => null,
});

export const CreateTeamProvider = ({ children }) => {
  const [createTeamState, createTeamDispatch] = useReducer(
    CreateTeamReducer,
    initialState
  );
  return (
    <CreateTeamContext.Provider
      value={{
        createTeamState,
        createTeamDispatch,
      }}>
      {children}
    </CreateTeamContext.Provider>
  );
};
