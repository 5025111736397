import { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useLinkedIn } from 'react-linkedin-login-oauth2';
import { toast } from 'react-toastify';
import { Box, CustomButton, Input, Title } from 'components';
import { LOGIN, useAxiosWrapper } from 'services';
import TokenService from 'services/token.service';
import Google from '../../assests/images/google.png';
import Linkedin from '../../assests/images/linkedin.png';
import './login.scss';
import Icon from 'utlils/Icons/Icon';
import { routeTypes } from './constants';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
const DEFAULTSTATE = {
  userOrEmail: '',
  password: '',
};

export const Login = () => {
  let { type } = useParams();
  let navigate = useNavigate();
  let location = useLocation();
  const [fnWrapper, context] = useAxiosWrapper();
  const [state, setState] = useState(DEFAULTSTATE);
  const { userOrEmail, password } = state;

  const handleInputChange = (field = '', value = null) => {
    setState({
      ...state,
      [field]: value,
    });
  };

  useEffect(() => {
    if (!routeTypes.includes(type)) {
      navigate('/');
    }
  }, [type, navigate]);

  /* Disable button */
  const disabled = !userOrEmail || !password;

  /* LinkedIn sign in process */
  const { linkedInLogin } = useLinkedIn({
    clientId: process.env.REACT_APP_LINKEDIN_CLIENT_ID,
    redirectUri: process.env.REACT_APP_LINKEDIN_REDIRECT_URL,
    scope: process.env.REACT_APP_LINKEDIN_SCOPE,
    onSuccess: async (code) => {
      handleLinkedinSuccess(code);
    },
    onError: (error) => {
      if (error.error === 'user_cancelled_authorize') {
        toast.error('You have cancelled the authorization', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    },
  });

  /* LinkedIn sign in handler */
  const handleLinkedinSuccess = async (code) => {
    const res = await fnWrapper({
      isSkippedInterceptor: true,
      url: LOGIN,
      method: 'POST',
      type: 'LINKEDIN_LOGIN',
      payload: {
        linkedinCode: code,
        isSocialUser: true,
        loginType: 'linkedin',
      },
    });
    if (res.data.statusCode === 200) {
      TokenService.setSession(res.data.data);
      if (location.state !== null && location?.state?.redirectUri) {
        navigate(location.state?.redirectUri, {
          replace: true,
        });
      } else {
        if (res.data.data.lastLoginTime === null) {
          navigate('/individual/profile/create/basic_details');
        } else {
          navigate('/individual/profile/info/basic_details');
        }
      }
    }
  };

  /* Google login  handler */
  const onLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const res = await axios.get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${tokenResponse.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${tokenResponse.access_token}`,
              Accept: 'application/json',
            },
          }
        );
        if (res.status === 200) {
          const apiRes = await fnWrapper({
            isSkippedInterceptor: true,
            url: LOGIN,
            method: 'POST',
            type: 'GOOGLE_LOGIN',
            payload: {
              email: res.data.email,
              isSocialUser: true,
              loginType: 'google',
            },
          });
          if (apiRes.data.statusCode === 200) {
            TokenService.setSession(apiRes.data.data);
            if (location.state !== null && location?.state?.redirectUri) {
              navigate(location.state?.redirectUri, {
                replace: true,
              });
            } else {
              if (apiRes.data.data.lastLoginTime === null) {
                navigate('/individual/profile/create/basic_details');
              } else {
                navigate('/individual/profile/info/basic_details');
              }
            }
          }
        }
      } catch (err) {
        toast.error(err, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    },
    onError: (error) => alert(error),
  });

  /* Sign in with email and password handler */
  const onContinueButtonHandler = async () => {
    const res = await fnWrapper({
      isSkippedInterceptor: true,
      url: LOGIN,
      method: 'POST',
      type: 'EMAIL_LOGIN',
      payload: {
        email: userOrEmail.toLowerCase(),
        password: password,
        loginType: 'email',
      },
    });

    if (res.data.statusCode === 200) {
      if (
        (type === 'individual' && res.data.data.role === 'INDIVIDUAL') ||
        (type === 'educational' && res.data.data.role === 'INSTITUTE') ||
        (type === 'enterprise' && res.data.data.role === 'SMALL_ENTERPRISE')
      ) {
        TokenService.setSession(res.data.data);
        if (location.state !== null && location?.state?.redirectUri) {
          navigate(location.state?.redirectUri, {
            replace: true,
          });
        } else {
          if (res.data.data.role === 'SMALL_ENTERPRISE') {
            navigate('/enterprise/profile/view/details');
          } else if (res.data.data.role === 'MIHINT_SUPERADMIN') {
            TokenService.onLogout();
            navigate('/');
          } else {
            if (res.data.data.lastLoginTime === null) {
              navigate('/individual/profile/create/basic_details');
            } else {
              navigate('/individual/profile/info/basic_details');
            }
          }
        }
      } else {
        toast.error('Please login from correct profile', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    }
    // individual user is in waiting list
    if (res.data.statusCode === 201) {
      toast.info(
        'You are currently on our waitlist. We appreciate your patience. ',
        {
          icon: <Icon name="info" color="#BA6000" />,
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,

          style: {
            backgroundColor: '#F5E7D9',
            color: '#BA6000',
          },
        }
      );
    }
  };

  const onNavigateToSignup = () => {
    if (
      location.state !== null &&
      location.state?.type &&
      location.state?.type === 'INDIVIDUAL'
    ) {
      navigate(`/signup/${location.state.type.toLowerCase()}`, {
        replace: true,
        state: location?.state,
      });
    } else {
      navigate('/signup/selectOrganization', {
        replace: true,
        state: location?.state,
      });
    }
  };

  return (
    <Box className="container-fluid">
      <div className="row sign-in-container">
        <div className=" col-lg-7 ">
          <div className="container">
            <div className="row">
              <div className="col-md-3 "></div>
              <div className="col-md-8 ">
                <Box>
                  <Box className="sign-in-wrapper">
                    {/* logo */}
                    <Box mb="78px" mt="33px">
                      <img
                        src={process.env.PUBLIC_URL + '/new-logo.png'}
                        className="img common-navbar-logo"
                        alt="logo"
                      />
                    </Box>
                    {/* title and subtitle */}
                    <Box mb="40px">
                      <Title title="Welcome" />
                    </Box>
                    <h2>
                      <span>Sign in with</span>
                    </h2>

                    {/* Social button */}
                    {type === 'individual' && (
                      <div className="row">
                        <div className="col-md-6 col-lg-6 col-12">
                          <Box
                            width="100%"
                            mb="30px"
                            rowGap="25px"
                            colGap="25px">
                            <button
                              className="google-signup-btn"
                              onClick={onLogin}>
                              {context.busy.status &&
                              context.busy.type === 'GOOGLE_LOGIN' ? (
                                <>
                                  <Icon
                                    color="#404F67"
                                    name="loader"
                                    size={20}
                                  />
                                  <span>Please wait</span>
                                </>
                              ) : (
                                <>
                                  <img src={Google} alt="google-img" />
                                  <span className="socials-btn-span-login">
                                    Continue with Google
                                  </span>
                                </>
                              )}
                            </button>
                          </Box>
                        </div>
                        <div className="col-md-6 col-lg-6 col-12">
                          <Box
                            width="100%"
                            mb="30px"
                            rowGap="25px"
                            colGap="25px">
                            <button
                              className="linkdin-signup-btn"
                              onClick={linkedInLogin}>
                              {context.busy.status &&
                              context.busy.type === 'LINKEDIN_LOGIN' ? (
                                <>
                                  <Icon
                                    color="#ffffff"
                                    name="loader"
                                    size={20}
                                  />
                                  <span>Please wait</span>
                                </>
                              ) : (
                                <>
                                  <img src={Linkedin} alt="linkedin-img" />
                                  <span className="socials-btn-span-login">
                                    Continue with Linkedin
                                  </span>
                                </>
                              )}
                            </button>
                          </Box>
                        </div>
                      </div>
                    )}
                    {/* Show Input based on type */}

                    <div className="row">
                      <div className="col-md-10 col-lg-10 col-12">
                        <Box mt="20px">
                          <Input
                            label="Email Address"
                            iconLeft={true}
                            iconName="sms"
                            placeholder="Enter your email address"
                            type="text"
                            required
                            value={userOrEmail}
                            onChange={(value) =>
                              handleInputChange(
                                'userOrEmail',
                                value.target.value
                              )
                            }
                          />
                        </Box>
                        <Box>
                          <Input
                            label="Password"
                            iconLeft={true}
                            iconName="lock"
                            placeholder="Enter your password"
                            type="password"
                            required
                            value={password}
                            onChange={(value) =>
                              handleInputChange('password', value.target.value)
                            }
                          />
                        </Box>
                      </div>
                      <div className="col-md- col-lg-2 col-12"></div>
                    </div>
                    {/* Sign-In-Button */}

                    <Box width={136} mb="40px" mt="40px">
                      <CustomButton
                        className="w-100"
                        customPadding="10px 14px"
                        disabled={disabled}
                        onClick={onContinueButtonHandler}
                        loading={
                          context.busy.status &&
                          context.busy.type === 'EMAIL_LOGIN'
                        }>
                        Sign in
                        <Icon
                          name="icon-right"
                          color="#fff"
                          className="icon-align"
                          size={20}
                        />
                      </CustomButton>
                    </Box>
                    <>
                      <Box>
                        <h6>
                          <span className="confirm-email-txt">
                            <span
                              className="resend-txt"
                              onClick={() =>
                                navigate('/forgot-password', {
                                  replace: true,
                                  state: location?.state,
                                })
                              }>
                              Forgot password?
                            </span>
                          </span>
                        </h6>
                      </Box>
                      <Box mb="15px">
                        <h6>
                          <span className="confirm-email-txt">
                            Don't have an account?
                            <span
                              className="resend-txt"
                              onClick={onNavigateToSignup}>
                              &nbsp;Sign up
                            </span>
                          </span>
                        </h6>
                      </Box>
                    </>
                  </Box>
                </Box>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
        </div>
        <div className="col-lg-5 d-none d-sm-none d-lg-block image-wrapper ">
          {/* right side image */}
          <div className="right-img"></div>
        </div>
      </div>
    </Box>
  );
};
