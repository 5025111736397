import React from 'react';
import { HeroContentWrapper } from './style';
import { HomeNavbar } from '../HomeNavbar';
import ScrollDownImage from '../../../../assests/images/homepage/featurepage/extra/scroll-down-arrow.png';
import { HOME_PAGE_DATA } from 'pages/HomePageV2/constant';

export const FeatureHeroContainer = (props) => {
  const { content, handleScroll } = props;
  return (
    <HeroContentWrapper>
      <div className="container">
        <div className="row">
          <HomeNavbar options={HOME_PAGE_DATA.navData} />

          <div className="hero-container">
            <div className="main-content">
              <div className="content">
                <div className="content_header">
                  <div className="line" />
                  <div className="sub-content">{content.subHeading}</div>
                  <div className="line" />
                </div>
              </div>
              <div className="content_header">
                <div className="content_header_heading">
                  <span style={{ color: '#FFFFFF' }}>
                    Unleash the Possibilities
                  </span>{' '}
                  with our{' '}
                  <span style={{ color: '#FFFFFF' }}>
                    Feature-Packed World.
                  </span>
                </div>
              </div>
              <div className="content-button" onClick={handleScroll}>
                <img
                  className="content-button-image"
                  src={ScrollDownImage}
                  alt={'Scroll Down'}
                  style={{
                    width: '40%',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </HeroContentWrapper>
  );
};
