import React from 'react';
import { Route, Routes } from 'react-router-dom';
import SearchGroups from './SearchGroups';

import { ViewGroup } from './ViewGroup';

export default function ExploreGroups() {
  return (
    <Routes>
      <Route path="">
        <Route path="search-groups" element={<SearchGroups />} />
        <Route path="view-group/:groupId" element={<ViewGroup />} />
      </Route>
    </Routes>
  );
}
