import {
  AllFeatures,
  BorderCard,
  HeroContainer,
  HomeFooter,
  HomeJourney,
  ModernFeature,
  Poster,
} from '../Components';
import { HOME_PAGE_DATA } from '../constant';

export const LandingPage = () => {
  return (
    <div>
      <HeroContainer content={HOME_PAGE_DATA.heroContent} />
      <HomeJourney content={HOME_PAGE_DATA.journeyContent} />
      <ModernFeature content={HOME_PAGE_DATA.modernFeatureContent} />
      <BorderCard content={HOME_PAGE_DATA.modernFeature1} />
      <BorderCard content={HOME_PAGE_DATA.modernFeature2} />
      <BorderCard content={HOME_PAGE_DATA.modernFeature3} />
      <BorderCard content={HOME_PAGE_DATA.modernFeature4} />
      <BorderCard content={HOME_PAGE_DATA.modernFeature5} noSmMb />
      <AllFeatures content={HOME_PAGE_DATA.allFeatures} />
      <Poster content={HOME_PAGE_DATA.subFooterCard} />
      <HomeFooter options={HOME_PAGE_DATA.footerData} />
    </div>
  );
};
