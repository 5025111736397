import styled from 'styled-components';

export const BorderCardWrapper = styled.div`
  margin: calc(300px + 5em) 0 0;
  @media (max-width: 991px) {
    margin: ${({ noSmMb }) => `calc(80px + 5em) 0 ${noSmMb ? '0' : '215px'}`};
    margin-left: 8px;
    margin-right: 8px;
  }
  .wrapper {
    position: relative;
    border: 3px solid #219fff;
    border-radius: 44px;
    @media (max-width: 991px) {
      padding-bottom: 50px;
    }
    .circle {
      position: absolute;
      top: -45px;
      left: 50%;
      transform: translateX(-50%);
      width: 80px;
      height: 80px;
      border-radius: 50%;
      border: 2px solid #219fff;
      background-color: #219fff;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 991px) {
        top: -32px;
        width: 60px;
        height: 60px;
      }
    }

    .line {
      position: absolute;
      top: -225px;
      left: 50%;
      transform: translateX(-50%);
      width: 2px;
      height: 225px;
      background-image: linear-gradient(
        180deg,
        #219fff 48.18%,
        rgba(33, 159, 255, 0) 95.99%
      );
      @media (max-width: 991px) {
        top: -125px;
        height: 100px;
      }
    }
    .circle-top {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: #ffffff;
      border: 6px solid #219fff;
      position: absolute;
      top: -254px;
      left: 50%;
      transform: translateX(-50%);
      @media (max-width: 991px) {
        top: -154px;
      }
    }

    & .main-heading {
      padding: 38px 0;
      margin: 0;
      margin-top: 35px;
      text-align: center;
      font-weight: 600;
      font-size: 32px;
      line-height: 47px;
      color: #404f67;
      @media (max-width: 991px) {
        font-size: 18px;
        line-height: 26px;
        padding: 12px 0;
      }
    }

    & .card-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 20px 50px 60px 50px;
      gap: 20px;
      @media (max-width: 991px) {
        grid-template-columns: auto;
        padding: 0;
      }
    }
    .btn-bottom {
      position: absolute;
      left: 50%;
      bottom: -28px;
      transform: translateX(-50%);
      width: 170px;
    }
  }
`;
