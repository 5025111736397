import Icon from 'utlils/Icons/Icon';
import { useState, useCallback, useEffect, useContext } from 'react';
import {
  capitalizeFirstLetter,
  STREAM_STATUS,
  checkForFileType,
} from 'pages/IndividualDashboard/individualOrganizations/enterprise/helper';
import { toast } from 'react-toastify';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  CustomButton,
  CustomDropDown,
  CustomFileUploadButton,
  Loader,
  ModalPopup,
  Textarea,
} from 'components';
import { isMobile } from 'utlils/isMobile';
import { PostList } from './Posts';
import { AttachmentsTypes } from 'pages/IndividualDashboard/constants';
import { getProfileText } from 'utlils/utils';
import { ViewStreamsWrapper } from './style';
import {
  CREATE_POST,
  FETCH_PROJECT_SERVICES,
  SET_FINISHED_STAGE,
  SET_STARTED_STAGE,
  FETCH_POSTS,
  useAxiosWrapper,
  FETCH_USER_PROFILE,
} from 'services';
import TokenService from 'services/token.service';
import ViewMediaDocs from './ViewMediaDocs';
import { RoleBasedAccessContext } from 'context/RoleBasedAccess/roleBasedAccessContext';
import { userScopes } from 'utlils';

export const ViewStreams = ({ isPublished }) => {
  const [fnWrapper, context] = useAxiosWrapper();
  const { departmentId, projectId } = useParams();
  const { roleBasedAccessState } = useContext(RoleBasedAccessContext);

  const [linkedProjectId, setLinkedProjectId] = useState('');
  const [streamList, setStreamList] = useState([]);
  const [isPopUp, setIsPopUp] = useState(false);
  const [userProfile, setUserProfile] = useState({
    userId: '',
    name: '',
    email: '',
    profilePicture: '',
    profileText: '',
  });
  const [selectedDropDown, setSelectedDropDown] = useState({
    id: '',
    stageName: '',
    stageNumber: '',
  });
  const [attachment, setAttachment] = useState([]);
  const [selectedStatusDropDown, setSelectedStatusDropDown] = useState(
    STREAM_STATUS[0]
  );
  const [searchParam, setSearchParams] = useSearchParams();
  const [disableCommentButton, setDisabledCommentButton] = useState(true);
  const [postComment, setPostComment] = useState('');
  const [posts, setPosts] = useState([]);
  const [isFinishedStages, setIsFinishedStages] = useState([]);
  const [linkedTeamsList, setLinkedTeamsList] = useState([]);
  const [activePost, setActivePost] = useState(null);
  const [isTaskPopupOpen, setIsTaskPopupOpen] = useState(false);
  const [isUserCommonMember, setIsUserCommonMember] = useState(false);
  const [viewMediaPopup, setViewMediaPopup] = useState(false);
  const [scrollPost, setScrollPost] = useState(null);

  const projectPermissions = roleBasedAccessState.projectRBAC;

  const commonTeamsCompare = async (linkedProjectId) => {
    const loggedInUser = TokenService.getSession();
    let currentProjectTeams = [];
    let linkedProjectTeams = [];
    await getLinkedTeams(projectId).then((result) => {
      currentProjectTeams = [...result];
    });
    await getLinkedTeams(linkedProjectId).then((result) => {
      linkedProjectTeams = [...result];
    });
    const commonTeams = currentProjectTeams.filter((team) => {
      return linkedProjectTeams.some((t) => {
        return t.id === team.id;
      });
    });
    let commonMembers = [];
    commonTeams.map((team) => {
      commonMembers = [...commonMembers, ...team.members];
      return commonMembers;
    });
    const boolRes = commonMembers.includes(loggedInUser.id);
    setIsUserCommonMember(boolRes);
  };

  const getLinkedTeams = async (pid) => {
    const res = await fnWrapper({
      url: `${FETCH_PROJECT_SERVICES}/${pid}?st=linkedTeams`,
      method: 'GET',
      type: 'FETCH_LINKED_TEAMS',
    });

    if (res.data.statusCode === 200) {
      const result = res.data.data.data[0];
      let linkedTeamsList = [];
      result.teams
        .filter((team) => team.TeamProject.teamStatus === '3')
        .map((team) => {
          const listData = {
            id: team.TeamProject.teamId,
            teamName: `${team.teamName}`,
            members: team.teamMembers.map((member) => {
              return member.memberUserId;
            }),
          };
          linkedTeamsList.push(listData);
          return linkedTeamsList;
        });
      return linkedTeamsList;
    }
  };

  const getLinkedProjectsList = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_PROJECT_SERVICES}/${projectId}?st=linkedProjects`,
      method: 'GET',
      type: 'FETCH_LINKED_PROJECTS',
    });

    if (res.data.statusCode === 200) {
      const result = res.data.data;
      if (result.data[0]) {
        setLinkedProjectId(result.data[0]?.id);
        commonTeamsCompare(result.data[0]?.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    getLinkedProjectsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getStreamsList = useCallback(
    async (projId, isLinkedProjectCall = false) => {
      const res = await fnWrapper({
        url: `${FETCH_PROJECT_SERVICES}/${projId}?st=streams`,
        method: 'GET',
        type: 'FETCH_PROJECT_STREAMS',
      });

      if (res.data.statusCode === 200) {
        const result = res.data.data.data[0];
        const dropDownList = [];
        result.stages?.map((stage) => {
          const mapStage = {
            id: stage.id,
            label: capitalizeFirstLetter(stage.stageName),
            value: Number(stage.stageNumber),
            isFinishedStage: stage.isFinished,
            startNewStage: stage.isStarted,
          };
          dropDownList.push(mapStage);
          return dropDownList;
        });
        dropDownList.sort((a, b) => a.value - b.value);
        setStreamList(dropDownList);
        setSelectedDropDown(dropDownList[0]);
        setIsFinishedStages(dropDownList);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const postUpdate = async () => {
    let formData = new FormData();
    formData.append('postMessage', postComment);
    formData.append('stageId', selectedDropDown?.id);
    for (var i of attachment) {
      formData.append('attachments', i);
    }

    const res = await fnWrapper({
      url: CREATE_POST,
      method: 'POST',
      payload: formData,
      type: 'CREATE_POST',
    });

    if (res.data.statusCode === 200) {
      setIsPopUp(false);
      setAttachment([]);
      setPostComment('');
      getPostsList(selectedDropDown.id);
    }
  };

  const finishStage = async (stageId, isFinished) => {
    const res = await fnWrapper({
      url: `${SET_FINISHED_STAGE}/${stageId}`,
      method: 'PUT',
      payload: {
        isFinished: isFinished,
      },
      type: 'SET_FINISHED_STAGE',
    });

    if (res.data.statusCode === 200) {
      // eslint-disable-next-line no-console
      console.log('stage finished');
    }
  };

  const setStartStage = async (stageId, isStarted) => {
    const res = await fnWrapper({
      url: `${SET_STARTED_STAGE}/${stageId}`,
      method: 'PUT',
      payload: {
        isStarted: isStarted,
      },
      type: 'SET_STARTED_STAGE',
    });

    if (res.data.statusCode === 200) {
      // eslint-disable-next-line no-console
      console.log('stage started');
    }
  };

  const getPostsList = useCallback(
    async (stageId) => {
      const res = await fnWrapper({
        url: `${FETCH_POSTS}/${stageId}`,
        method: 'GET',
        type: 'FETCH_POSTS',
      });

      if (res.data.statusCode === 200) {
        const result = res.data.data[0];
        const postsList = [];
        result.posts?.map((post) => {
          const userName =
            post.users.individual === null
              ? post.users.smallEnterprise.enterpriseName
              : post.users.individual.name;
          const pfp =
            post.users.individual === null
              ? post.users.smallEnterprise.profilePicture
              : post.users.individual.profilePicture;

          const mapPosts = {
            id: post.id,
            imgSrc: pfp,
            profileText: `${userName
              .split(' ')
              .map((item) => item.toUpperCase().substring(0, 1))
              .join('')}`,
            userName: `${userName}`,
            postedTime: post.createdAt,
            text: post.postMessage,
            attachments: post.attachments,
            users: post.users,
          };
          postsList.push(mapPosts);
          return postsList;
        });
        postsList.sort(function (a, b) {
          return new Date(b.postedTime) - new Date(a.postedTime);
        });
        setPosts(postsList);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedDropDown]
  );
  console.log(TokenService.getSession());
  const getLinkedTeamsList = useCallback(async (projId) => {
    const loggedInUser = TokenService.getSession();
    const res = await fnWrapper({
      url: `${FETCH_PROJECT_SERVICES}/${projId}?st=linkedTeams`,
      method: 'GET',
      type: 'FETCH_LINKED_TEAMS',
    });
    if (res.data.statusCode === 200) {
      const result = res.data.data?.data[0]?.teams;

      const teamsName = [];
      result
        .filter((team) => team.TeamProject.teamStatus === '3')
        .map((team) => {
          const item = team?.teamMembers.find(
            (member) => member?.memberUserId === loggedInUser.id
          );
          if (item) {
            const dataObj = {
              id: team?.TeamProject?.teamId,
              teamName: team?.teamName,
            };
            teamsName.push(dataObj);
          }
          return teamsName;
        });

      if (teamsName.length === 0) {
        result.map((team) => {
          const dataObj = {
            id: team?.TeamProject?.teamId,
            teamName: team?.teamName,
          };
          teamsName.push(dataObj);
          return teamsName;
        });
      }
      setLinkedTeamsList(teamsName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCopyText = async (e, textToCopy) => {
    await copyValue(e, textToCopy);
    // to highlight which clipboard is active
    // set_clipboardId({ clipboardId: clipboardID });
  };

  const copyValue = async (e, textToCopy) => {
    if (e) {
      // stop default click propagation
      e.preventDefault();
      e.stopPropagation();
    }

    const is_clipboard_api_supported = navigator.clipboard;
    if (is_clipboard_api_supported) {
      await is_clipboard_api_supported.writeText(textToCopy);
    } else {
      // only for older browsers ~1%!!
      // creat a input & copy the text and remove it
      let inp = document.createElement('INPUT');
      inp.value = textToCopy;
      document.body.appendChild(inp);
      inp.select();
      document.execCommand('copy');
      document.body.removeChild(inp);
    }
  };

  useEffect(() => {
    getLinkedTeamsList(projectId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLinkedTeamsList]);

  useEffect(() => {
    getStreamsList(projectId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getStreamsList]);

  useEffect(() => {
    if (searchParam.get('stage') && selectedDropDown.id !== '') {
      let stream = STREAM_STATUS.filter(
        (ele) => ele.value === searchParam.get('stream')
      );
      let stage = streamList.filter(
        (ele) => ele.value === Number(searchParam.get('stage'))
      );
      setSelectedStatusDropDown(stream[0]);
      setSelectedDropDown(stage[0]);
      getPostsList(stage[0]?.id);
      searchParam.delete('stage');
      searchParam.delete('stream');
      setSearchParams(searchParam);
    } else {
      if (selectedDropDown.id !== '') {
        getPostsList(selectedDropDown?.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDropDown?.id]);

  useEffect(() => {
    if (posts?.length > 0 && !scrollPost) {
      let postId = posts.filter((ele) => ele.id === searchParam.get('post'));
      setScrollPost(postId[0]?.id);
      searchParam.delete('post');
      setSearchParams(searchParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posts]);

  const handleOptionsClick = (item, post) => {
    switch (item.id) {
      case 0:
        setIsTaskPopupOpen(true);
        break;
      case 2:
        toast.success('Link copied successfully', {
          position: 'top-right',
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        handleCopyText(
          '',
          TokenService.getSession()?.role === 'INDIVIDUAL'
            ? `${process.env.REACT_APP_BASE_URL}/individual/profile/personal/projects/${projectId}/view/streams?stream=${selectedStatusDropDown?.value}&stage=${selectedDropDown?.value}&post=${post?.id}`
            : `${process.env.REACT_APP_BASE_URL}/enterprise/departments/view/${departmentId}/projects/${projectId}/view/streams?stream=${selectedStatusDropDown?.value}&stage=${selectedDropDown?.value}&post=${post?.id}`
        );
        break;
      default:
    }
  };

  const activePostHandler = (postId) => {
    if (activePost !== postId) {
      setActivePost(postId);
    } else {
      setActivePost(null);
    }
  };

  const findIndexMethod = (elemArray, selectedDropDownElem) => {
    const indexArray = elemArray.findIndex(
      (el) => el.id === selectedDropDownElem.id
    );
    return indexArray;
  };

  const updateStreamStagesHandler = async (status) => {
    let statusObject = streamList.filter((ele) => ele.value === status);
    setSelectedDropDown(statusObject[0]);
  };

  const updateStreamStatusHandler = async (status) => {
    let statusObject = STREAM_STATUS.filter((ele) => ele.value === status);
    setSelectedStatusDropDown(statusObject[0]);
    if (statusObject[0].label === 'Linked Stream' && linkedProjectId !== '') {
      getStreamsList(linkedProjectId, true);
    } else {
      getStreamsList(projectId, false);
    }
  };

  const textContentChangeHandler = (evt) => {
    evt.target.value.length !== 0
      ? setDisabledCommentButton(false)
      : setDisabledCommentButton(true);
    setPostComment(evt.target.value);
  };

  const handleFinishedStage = async () => {
    let nextStageIndex = isFinishedStages.findIndex(
      (stage) => stage.value === selectedDropDown.value + 1
    );
    await finishStage(selectedDropDown.id, true);
    await setStartStage(isFinishedStages[nextStageIndex].id, true);
    const newState = isFinishedStages.map((ele) => {
      if (ele.id === selectedDropDown.id) {
        return { ...ele, isFinishedStage: true };
      }
      return ele;
    });
    setIsFinishedStages(newState);
  };

  const handleReopenStage = async () => {
    await finishStage(selectedDropDown.id, false);
    const newState = isFinishedStages.map((ele) => {
      if (ele.id === selectedDropDown.id) {
        return { ...ele, isFinishedStage: false };
      }
      return ele;
    });
    setIsFinishedStages(newState);
    setDisabledCommentButton(false);
  };

  const isPreviousFinished = () => {
    const currentIndex = findIndexMethod(isFinishedStages, selectedDropDown);
    if (currentIndex === 0) return true;
    else return isFinishedStages[currentIndex - 1]?.isFinishedStage;
  };

  const isCurrentStageFinished = () => {
    const currentIndex = findIndexMethod(isFinishedStages, selectedDropDown);
    return isFinishedStages[currentIndex]?.isFinishedStage;
  };

  const isNewStage = () => {
    const currentIndex = findIndexMethod(isFinishedStages, selectedDropDown);
    if (currentIndex === 0) return true;
    else return isFinishedStages[currentIndex]?.startNewStage;
  };

  const isFinishedBtnStatus = () => {
    const currentIndex = findIndexMethod(isFinishedStages, selectedDropDown);
    return isFinishedStages[currentIndex]?.isFinishedStage;
  };

  const goBackToPreviousStage = () => {
    const currentIndex = findIndexMethod(isFinishedStages, selectedDropDown);
    setSelectedDropDown(isFinishedStages[currentIndex - 1]);
  };

  const startNewStage = async () => {
    await setStartStage(selectedDropDown.id, true);
    const newState = isFinishedStages.map((ele) => {
      if (ele.value === selectedDropDown.value) {
        return { ...ele, startNewStage: true };
      }
      return ele;
    });
    setIsFinishedStages(newState);
  };

  const attachmentsHandler = async (files) => {
    const isCorrectFileType = [...files].reduce(
      (prev, curr) => prev && AttachmentsTypes.includes(curr.type),
      true
    );
    if (isCorrectFileType) {
      const temp = [...attachment, ...files];
      setAttachment(temp);
    } else {
      toast.error('Type of File/Files uploaded are not supported.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  const removeAttachmentsHandler = async (file) => {
    let temp = [];
    temp = attachment.filter((e) => e !== file);
    setAttachment(temp);
  };

  const getUserProfile = useCallback(async () => {
    const loggedInUser = TokenService.getSession();
    const res = await fnWrapper({
      url: `${FETCH_USER_PROFILE}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_USER_PROFILE',
    });
    if (res.data.statusCode === 200) {
      const response = res.data.data;
      setUserProfile({
        userId: response.userId,
        name: response.name,
        email: response.email,
        profilePicture: response.profilePicture,
        profileText: getProfileText(
          response.name ? response.name : response.enterpriseName
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUserProfile();
  }, [getUserProfile]);

  return (
    <ViewStreamsWrapper
      isPublished={isPublished}
      isCurrentStageFinished={isCurrentStageFinished()}>
      {context.busy.status ? (
        <Loader isLoading={context.busy.status} />
      ) : streamList.length === 0 ? (
        <div className="no-stream">No Stream available!</div>
      ) : (
        <div className="streams-wrapper">
          {/* buttons */}
          <div className=" buttons-container">
            <div className="left-buttons">
              <CustomDropDown
                optionLists={STREAM_STATUS.filter(
                  (ele) => ele.value !== selectedStatusDropDown?.value
                )}
                selectedItem={selectedStatusDropDown}
                setSelectedItem={(val) => updateStreamStatusHandler(val.value)}
                placeholder={STREAM_STATUS[0].label}
                height="54px"
                customStyle={{
                  backgroundColor: 'rgba(33, 159, 255, 0.15)',
                  color: '#219FFF',
                  paddingRight: '12px',
                  paddingLeft: '8px',
                  justifyContent: 'center',
                  whiteSpace: 'normal',
                }}
                width={'100%'}
              />

              <CustomDropDown
                optionLists={streamList.filter(
                  (ele) => ele.value !== selectedDropDown?.value
                )}
                selectedItem={selectedDropDown}
                setSelectedItem={(val) => updateStreamStagesHandler(val.value)}
                placeholder={streamList[0]?.label}
                height="54px"
                customStyle={{
                  backgroundColor: 'rgba(33, 159, 255, 0.15)',
                  color: '#219FFF',
                  paddingRight: '12px',
                  paddingLeft: '8px',
                  justifyContent: 'center',
                  whiteSpace: 'normal',
                }}
                width={'100%'}
              />
            </div>

            {!context.busy.status &&
              selectedStatusDropDown?.label === 'Local Stream' &&
              !isFinishedBtnStatus() &&
              projectPermissions.includes(userScopes.FINISH_STAGE) && (
                <CustomButton
                  label="Finish Stage"
                  variant="light"
                  disabled={!isPreviousFinished() && !isNewStage()}
                  color={
                    isPreviousFinished() || isNewStage()
                      ? 'themePrimary'
                      : 'themeGray'
                  }
                  onClick={handleFinishedStage}
                />
              )}

            {!context.busy.status &&
              selectedStatusDropDown?.label === 'Local Stream' &&
              isFinishedBtnStatus() &&
              projectPermissions.includes(userScopes.RE_OPEN_STAGE) && (
                <CustomButton
                  label="Re-Open Stage"
                  variant="outline"
                  onClick={handleReopenStage}
                />
              )}

            {isFinishedBtnStatus() &&
              selectedStatusDropDown?.label === 'Local Stream' &&
              !projectPermissions.includes(userScopes.FINISH_STAGE) &&
              !projectPermissions.includes(userScopes.RE_OPEN_STAGE) && (
                <CustomButton
                  variant="outline"
                  disabled={true}
                  label="Stage Finished"
                />
              )}
          </div>

          {/* posts header*/}
          {selectedStatusDropDown?.label === 'Local Stream' ? (
            isPreviousFinished() || isNewStage() ? (
              <>
                <div className="posts-wrapper">
                  <div className="comment_area">
                    <div>
                      {userProfile.profilePicture !== null &&
                      userProfile.profilePicture !== '' ? (
                        <img
                          src={userProfile.profilePicture}
                          alt="pfp"
                          width={'100%'}
                          className="post-profile-pic"
                        />
                      ) : (
                        <div className="pfp-alt-circle">
                          <span className="pfp-alt">
                            {userProfile?.profileText}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="comment-media-wrap">
                      <div
                        className="comment_box"
                        onClick={() => {
                          if (!isCurrentStageFinished()) {
                            setAttachment([]);
                            setPostComment('');
                            setIsPopUp(!isPopUp);
                          }
                        }}>
                        <p className="comment-box-text">
                          {isMobile()
                            ? 'Click here to post'
                            : 'Click here to post updates'}
                        </p>
                      </div>
                      <div
                        className="view-media-label"
                        onClick={() => setViewMediaPopup(true)}>
                        <p>View Media & Docs</p>
                      </div>
                    </div>
                  </div>
                </div>

                <PostList
                  scrollTo={scrollPost}
                  isCurrentStageFinished={isCurrentStageFinished()}
                  isPublished={isPublished}
                  posts={posts}
                  activePost={activePost}
                  activePostHandler={activePostHandler}
                  isTaskOpen={isTaskPopupOpen}
                  setIsTaskOpen={setIsTaskPopupOpen}
                  optionsHandler={handleOptionsClick}
                  userProfile={userProfile}
                  teamsList={linkedTeamsList}
                  projectId={projectId}
                />
              </>
            ) : (
              <div className="check-for-previous-stage-container">
                <p>
                  It appears that you didn't finish your previous Stage. Would
                  you really like to start a new stage?
                </p>
                <div className="check-stage-buttons">
                  {projectPermissions.includes(userScopes.START_NEW_STAGE) && (
                    <CustomButton
                      className="check-stage-buttons__new-stage-btn"
                      label="Start New Stage"
                      onClick={startNewStage}
                      color="themeSecondary"
                    />
                  )}
                  <CustomButton
                    className="CustomBtnUnderLineViewStreams"
                    variant="textBtn"
                    onClick={goBackToPreviousStage}
                    label="Go Back to Previous Stage"
                    color="themeSecondary"
                    customPadding="12px 0px"
                  />
                </div>
              </div>
            )
          ) : linkedProjectId === '' ? (
            <div className="no-stream">No linked project found!</div>
          ) : isPreviousFinished() || isNewStage() ? (
            <PostList
              isCurrentStageFinished={isCurrentStageFinished()}
              posts={posts}
              isPublished={isPublished}
              activePost={activePost}
              activePostHandler={activePostHandler}
              isTaskOpen={isTaskPopupOpen}
              setIsTaskOpen={setIsTaskPopupOpen}
              optionsHandler={handleOptionsClick}
              userProfile={userProfile}
              isLinkedStream
              isUserCommonMember={isUserCommonMember}
              teamsList={linkedTeamsList}
              projectId={projectId}
            />
          ) : (
            <div className="check-for-previous-stage-container">
              <p>This stage is not started yet!</p>
              <div className="check-stage-buttons">
                <CustomButton
                  className="CustomBtnUnderLineViewStreams"
                  label="Go Back to Previous Stage"
                  color="themeSecondary"
                  variant="textBtn"
                  onClick={goBackToPreviousStage}
                  customPadding="12px 0px"
                />
              </div>
            </div>
          )}
        </div>
      )}
      {isPopUp && (
        <ModalPopup
          open={false}
          onClose={() => setIsPopUp(false)}
          hasTitle
          title="Post Updates">
          <div className="post-update-popup">
            <div className="popup-comment">
              <Textarea
                label={`What's your update?`}
                placeholder={'Share your update here'}
                onChange={textContentChangeHandler}
                rows={4}
              />
            </div>
            <div className="popup-attachment">
              <p>Add Attachments</p>
              <CustomFileUploadButton
                borderWidth="0px 0px 1px 0px"
                borderStyle="solid"
                borderColor="#219FFF"
                background="none"
                fontFamily="'Poppins', 'Open Sans'"
                fontSize="15px"
                fontWeight="500"
                textColor="#219FFF"
                handleFile={(file) => attachmentsHandler(file)}
                label="Add"
                iconColor="#219FFF"
                iconName="add"
                iconSize="18"
                isMultiple={true}
              />
            </div>
            <div className="post-attachment">
              {attachment.length !== 0
                ? attachment.map((file, index) => {
                    return (
                      <div className="img-wrapper" key={index}>
                        <div className="img-container">
                          {checkForFileType(file.name) === 'others' ? (
                            <Icon name="attachment" />
                          ) : (
                            <img
                              src={checkForFileType(file.name)}
                              alt="attachment"
                            />
                          )}
                        </div>
                        <span className="img-text">{file.name}</span>
                        <span
                          className="remove-icon"
                          onClick={() => removeAttachmentsHandler(file)}>
                          <Icon
                            color="rgba(146, 157, 175, 1)"
                            name="small-close"
                          />
                        </span>
                      </div>
                    );
                  })
                : null}
            </div>
            <div className="popup-button">
              <CustomButton
                className="w-100"
                label="Post"
                loading={context.busy.status}
                color="themeSecondary"
                onClick={postUpdate}
                disabled={disableCommentButton && attachment.length === 0}
              />
            </div>
          </div>
        </ModalPopup>
      )}
      {viewMediaPopup && selectedDropDown?.id ? (
        <ModalPopup
          open={false}
          onClose={() => setViewMediaPopup(false)}
          hasTitle
          maxContentWidth="650px"
          title="Media & Docs">
          <ViewMediaDocs stageId={selectedDropDown?.id} />
        </ModalPopup>
      ) : null}
    </ViewStreamsWrapper>
  );
};
