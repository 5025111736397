import React, { useEffect, useCallback } from 'react';
import createInitialTeams from '../../../assests/images/createInitialTeams.png';
import { useState } from 'react';
import { CustomButton, SearchInput, Pagination } from 'components';
import { PublicationForm } from './Components/PublicationForm';
import {
  FETCH_PUBLICATIONS,
  CREATE_PUBLICATION,
  UPDATE_PUBLICATION,
  DELETE_PUBLICATION,
  FETCH_PROJECTS_BASED_ON_TEAM,
  GET_TEAMS_BASED_ON_ENTERPRISE_ID,
} from 'services';
import Icon from 'utlils/Icons/Icon';
import { PublicationCard } from './Components/PublicationCard';
import { TeamPublicationsWrapper } from './style';
import { useAxiosWrapper } from 'services';
import tokenService from 'services/token.service';
import { PublicationFilter } from './Components/PublicationsFilter';
import { toast } from 'react-toastify';

const DFAULT_SEARCH_AND_QUERY_STATE = {
  startDate: '',
  endDate: '',
  search: '',
};

const PAGINATION_DEFAULT_STATE = {
  size: 3,
  totalPages: 5,
  totalItems: 3,
};

export const TeamPublications = ({ teamDetails }) => {
  const { teamId, teamCategory, enterpriseId } = teamDetails;
  const [publicationsList, setPublicationsList] = useState([]);
  const [filteredPublicationsList, setFilteredPublicationsList] = useState([]);
  const [searchAndFilterQuery, setSearchAndFilterQuery] = useState(
    DFAULT_SEARCH_AND_QUERY_STATE
  );
  const [paginationData, setPaginationData] = useState(
    PAGINATION_DEFAULT_STATE
  );
  const [projectList, setProjectList] = useState([]);
  const [enterpriseTeamList, setEnterpriseTeamList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [fnWrapper, context] = useAxiosWrapper();
  const userId = tokenService.getSession()?.id;

  const getPublicationDetails = async () => {
    const res = await fnWrapper({
      url: FETCH_PUBLICATIONS,
      method: 'GET',
      type: 'FETCH_PUBLICATIONS',
      params: {
        teamId: teamId,
        size: paginationData?.size,
        page: currentPage,
        hasPaginated: true,
      },
    });

    setPaginationData((state) => ({
      ...state,
      totalItems: res?.data?.data?.totalItems,
      totalPages: res?.data?.data?.totalPages,
    }));
    setPublicationsList(res?.data?.data?.items);
    setFilteredPublicationsList(res?.data?.data?.items);
  };

  const getProjectsList = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_PROJECTS_BASED_ON_TEAM}/${teamId}`,
      method: 'GET',
      type: 'FETCH_PROJECTS_LIST_BASED_ON_TEAM',
    });

    if (res.data.statusCode === 200) {
      const projectsList = [];
      res.data.data.map((item) => {
        const newProjectData = {
          id: item.projects?.id,
          projectName: item.projects?.title,
          status: item.projects?.projectStatus,
        };
        if (newProjectData?.status === 'PUBLISHED') {
          projectsList.push({
            projectName: newProjectData?.projectName,
            id: newProjectData?.id,
          });
        }
        return projectsList;
      });
      setProjectList(projectsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId]);

  const getEnterpriseTeams = useCallback(async () => {
    const res = await fnWrapper({
      url: `${GET_TEAMS_BASED_ON_ENTERPRISE_ID}/${enterpriseId}`,
      method: 'GET',
      type: 'FETCH_PROJECTS_LIST_BASED_ON_TEAM',
    });
    if (res.data.statusCode === 200) {
      const team = res.data.data.map((item) => ({
        id: item?.id,
        title: item?.teamName,
        value: item?.id,
      }));
      setEnterpriseTeamList(team);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterpriseId]);

  const createPublication = async (publicationData) => {
    const publicationAttachments = publicationData?.attachments;
    const selectedCollaboratorMembers =
      publicationData?.selectedCollaboratorMembers;
    const selectedTeams = publicationData?.selectedEnterpriseTeams;
    const form = new FormData();
    form.append('title', publicationData?.name);
    form.append('description', publicationData?.description);
    form.append('privacy', publicationData?.privacy);
    form.append('referenceLink', publicationData?.referenceLink);
    form.append(
      'coverImage',
      publicationData?.coverImage && publicationData?.coverImage[0]
    );

    for (const key in publicationAttachments) {
      form.append('attachments', publicationAttachments[key]);
    }
    if (selectedCollaboratorMembers && selectedCollaboratorMembers.length > 0) {
      for (const member of selectedCollaboratorMembers) {
        form.append('collaboratorMemberId', member?.userId);
      }
    }
    if (selectedTeams && selectedTeams.length > 0) {
      for (const team of selectedTeams) {
        form.append('collaboratorTeamId', team?.id);
      }
    }
    if (
      publicationData?.project &&
      Object.keys(publicationData.project).length > 0
    ) {
      form.append('projectId', publicationData?.project?.id);
    }

    form.append('teamId', teamId);
    form.append('userId', userId);
    form.append('category', publicationData?.category?.value);

    const res = await fnWrapper({
      url: `${CREATE_PUBLICATION}`,
      method: 'POST',
      type: 'CREATE_PUBLICATION',
      payload: form,
    });

    if (res.data.statusCode === 200) {
      toast.success(res.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }

    setIsPopUpOpen(false);
    getPublicationDetails();
  };

  const updatePublication = async (publicationData) => {
    const { id } = publicationData;
    const publicationAttachments = publicationData?.attachments || [];
    const linkAttachments = publicationData?.linkedAttachments || [];
    const selectedCollaboratorMembers =
      publicationData?.selectedCollaboratorMembers || [];
    const selectedTeams = publicationData?.selectedEnterpriseTeams || [];
    const form = new FormData();
    form.append('title', publicationData?.name);
    form.append('description', publicationData?.description);
    form.append('privacy', publicationData?.privacy);
    form.append('referenceLink', publicationData?.referenceLink);
    form.append('category', publicationData?.category?.value);

    if (publicationData?.coverImage && publicationData?.coverImage[0]) {
      form.append('coverImage', publicationData?.coverImage[0]);
    }

    if (selectedCollaboratorMembers.length > 0) {
      for (const member of selectedCollaboratorMembers) {
        form.append('collaboratorMemberId', member?.userId);
      }
    }

    if (selectedTeams.length > 0) {
      for (const team of selectedTeams) {
        form.append('collaboratorTeamId', team?.id);
      }
    }

    if (Object.keys(publicationData?.project).length !== 0) {
      form.append('projectId', publicationData?.project?.id);
    }

    // Attachments
    if (publicationAttachments.length > 0) {
      for (const key in publicationAttachments) {
        form.append('attachments', publicationAttachments[key]);
      }
    }

    // Link Attachments
    if (linkAttachments.length > 0) {
      for (const key in linkAttachments) {
        form.append('linkAttachments', linkAttachments[key]);
      }
    }

    const res = await fnWrapper({
      url: `${UPDATE_PUBLICATION}/${id}`,
      method: 'PUT',
      type: 'UPDATE_PUBLICATION',
      payload: form,
    });

    if (res.data.statusCode === 200) {
      toast.success(res.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }

    getPublicationDetails();
  };

  const removePublication = async (id) => {
    const res = await fnWrapper({
      url: `${DELETE_PUBLICATION}/${id}`,
      method: 'PUT',
      type: 'DELETE_PUBLICATION',
    });
    if (res.data.statusCode === 200) {
      toast.success(res.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
    getPublicationDetails();
  };

  const searchQueryHandler = (value = '', label = 'search') => {
    setSearchAndFilterQuery((state) => ({
      ...state,
      [label]: value,
    }));
  };

  const onFilterApply = (dates) => {
    setSearchAndFilterQuery((state) => ({
      ...state,
      ...dates,
    }));
    setCurrentPage(1);
  };

  const clearFilterHanlder = () => {
    setSearchAndFilterQuery(DFAULT_SEARCH_AND_QUERY_STATE);
    setFilteredPublicationsList(publicationsList);
    setIsFilterOpen(false);
    setCurrentPage(1);
  };

  const onSearchHandler = async () => {
    const hasSearchParam =
      searchAndFilterQuery?.search.length > 0
        ? searchAndFilterQuery?.search
        : null;

    const hasStartDate =
      searchAndFilterQuery?.startDate.length > 0
        ? searchAndFilterQuery?.startDate
        : null;

    const hasEndDate =
      searchAndFilterQuery?.endDate.length > 0
        ? searchAndFilterQuery?.endDate
        : null;

    const res = await fnWrapper({
      url: FETCH_PUBLICATIONS,
      method: 'GET',
      type: 'FETCH_PUBLICATIONS',
      params: {
        teamId: teamId,
        size: paginationData?.size,
        page: currentPage,
        hasPaginated: true,
        search: hasSearchParam ? hasSearchParam : null,
        startDate: hasStartDate ? hasStartDate : null,
        endDate: hasEndDate ? hasEndDate : null,
      },
    });

    setFilteredPublicationsList(res?.data?.data?.items);

    // Update totalItems and totalPages based on the filtered data
    setPaginationData((state) => ({
      ...state,
      totalItems: res?.data?.data?.totalItems,
      totalPages: res?.data?.data?.totalPages,
    }));
  };

  const onCloseSearchHandler = () => {
    setFilteredPublicationsList(publicationsList);
    setSearchAndFilterQuery((state) => ({ ...state, search: '' }));

    // Reset pagination based on the backend's default pagination data
    setCurrentPage(1);
    getPublicationDetails();
  };

  const onPageChange = async (page) => {
    if (currentPage !== page) {
      setCurrentPage(page);
    }
  };

  useEffect(() => {
    getPublicationDetails();
    getProjectsList();
    if (enterpriseId) {
      getEnterpriseTeams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onSearchHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    searchAndFilterQuery.startDate,
    searchAndFilterQuery.endDate,
  ]);

  return (
    <TeamPublicationsWrapper>
      {isPopUpOpen && (
        <PublicationForm
          onPublish={createPublication}
          projectList={projectList}
          enterpriseTeamList={enterpriseTeamList}
          teamCategory={teamCategory}
          context={context}
          closePopup={() => setIsPopUpOpen(false)}
        />
      )}

      <div className="heading">Publications</div>
      <div className="publication-container">
        {!publicationsList?.length ? (
          <div className="d-flex flex-column justify-content-center align-items-center gap-3">
            <img
              className="no-publications-img"
              src={createInitialTeams}
              alt={'no-publications-text'}
            />
            <p className="no-publications-txt">
              It looks like you haven't published anything yet
            </p>
            <CustomButton
              label="Create Publication"
              onClick={() => setIsPopUpOpen(true)}
            />
          </div>
        ) : (
          <>
            <div className="search-container">
              <SearchInput
                value={searchAndFilterQuery?.search}
                onSearch={onSearchHandler}
                placeholder="Search Publications"
                onSearchange={(e) => searchQueryHandler(e?.target?.value)}
                onCloseSearch={onCloseSearchHandler}
              />
              <div className="filter-btn-group">
                <CustomButton
                  variant="light"
                  color={isFilterOpen ? 'themeSecondary' : 'themeGray2'}
                  onClick={(e) => {
                    setIsFilterOpen((state) => !state);
                  }}>
                  <Icon
                    name="filter"
                    size={20}
                    className="filter-arrow"
                    color={isFilterOpen ? 'rgba(33, 159, 255, 1)' : '#404f67'}
                  />
                </CustomButton>
                <CustomButton
                  className="d-flex gap-3"
                  customPadding="0px 15px"
                  onClick={() => setIsPopUpOpen(true)}>
                  <Icon name="add" color="#fff" size={22} />
                  New Publication
                </CustomButton>
                {isFilterOpen && (
                  <PublicationFilter
                    setState={onFilterApply}
                    onClear={clearFilterHanlder}
                    isFilterOpen={isFilterOpen}
                  />
                )}
              </div>
            </div>
            <div className="publication-cards">
              {filteredPublicationsList.length > 0 ? (
                filteredPublicationsList.map((item, index) => {
                  return (
                    <PublicationCard
                      key={index}
                      {...item}
                      publishedByUser={
                        item?.user?.individual
                          ? item?.user?.individual
                          : item?.user?.smallEnterprise
                      }
                      onUpdate={updatePublication}
                      onRemove={removePublication}
                      teamCategory={teamCategory}
                      enterpriseTeamList={enterpriseTeamList}
                      projectList={projectList}
                    />
                  );
                })
              ) : (
                <div className="no-filter-data">
                  No such publications found!
                </div>
              )}
            </div>
          </>
        )}
      </div>
      {paginationData?.totalItems > paginationData?.size && (
        <div className="pagination-wrapper">
          <Pagination
            currentPage={currentPage}
            totalCount={paginationData?.totalItems}
            pageSize={paginationData?.size}
            onPageChange={(page) => onPageChange(page)}
          />
        </div>
      )}
    </TeamPublicationsWrapper>
  );
};
