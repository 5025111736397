import { CustomButton, DropDown, Input } from 'components';
import { MEMBER_FILTERS } from 'pages/TeamDashboard/helper';
import { MembersFilterWrapper } from './style';
import DatePicker from 'react-datepicker';

export const MembersFilter = ({
  state,
  filterTitle,
  disabled,
  memberFilterSaveHandler,
  memberFilterOnChangeHandler,
}) => {
  return (
    <MembersFilterWrapper>
      <div className="filter-title-wrapper">
        <div className="filter-title">{filterTitle}</div>
      </div>
      <div className="filter-input-container">
        <div className="row">
          <div className="col-lg-6">
            <Input
              label={MEMBER_FILTERS.Designation.label}
              value={state.filteredDesignation}
              onChange={(e) =>
                memberFilterOnChangeHandler(
                  'filteredDesignation',
                  e.target.value
                )
              }
            />
          </div>
          <div className="col-lg-6">
            <DropDown
              label={MEMBER_FILTERS.Date.label}
              value={state.filteredDate}
              objectKey={MEMBER_FILTERS.Date.key}
              items={MEMBER_FILTERS.Date.dateType}
              placeholder={MEMBER_FILTERS.Date.placeholder}
              onClick={(e) => memberFilterOnChangeHandler('filteredDate', e)}
            />
          </div>
        </div>
        {state.filteredDate.value === 'CD' && (
          <div className="row">
            <div className="col-lg-6 filter-date-wrapper">
              <label className="filter-date-label form-label">
                Select From Date
              </label>
              <DatePicker
                dateFormat="MMM dd, yyyy"
                selected={state.fromDate}
                onChange={(date) =>
                  memberFilterOnChangeHandler('fromDate', date)
                }
                selectsStart
                placeholderText="From"
                className="filter-custom-date"
              />
            </div>
            <div className="col-lg-6 filter-date-wrapper">
              <label className="filter-date-label form-label">
                Select To Date
              </label>
              <DatePicker
                dateFormat="MMM dd, yyyy"
                selected={state.toDate}
                onChange={(date) => memberFilterOnChangeHandler('toDate', date)}
                selectsEnd
                startDate={state.fromDate}
                endDate={state.toDate}
                minDate={state.fromDate}
                placeholderText="To"
                className="filter-custom-date"
              />
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-lg-6">
            <DropDown
              label={MEMBER_FILTERS.Status.label}
              items={MEMBER_FILTERS.Status.statusType}
              objectKey={MEMBER_FILTERS.Status.key}
              value={state.filteredStatus}
              placeholder={MEMBER_FILTERS.Status.placeholder}
              onClick={(e) => memberFilterOnChangeHandler('filteredStatus', e)}
            />
          </div>
        </div>
        <div className="row gy-3">
          <div className="col-lg-6">
            <CustomButton
              label="Apply"
              color="themeSecondary"
              disabled={disabled}
              className="w-100"
              onClick={() => memberFilterSaveHandler('apply')}
            />
          </div>
          <div className="col-lg-6">
            <CustomButton
              label="Cancel"
              variant="light"
              color="themeSecondary"
              className="w-100"
              onClick={() => memberFilterSaveHandler('cancel')}
            />
          </div>
        </div>
      </div>
    </MembersFilterWrapper>
  );
};
