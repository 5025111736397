import { Loader } from 'components';
import { EnterpriseTeamProjectCard } from '../Components';
import { useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { EnterpriseProjectsWrapper } from './style';
import { useAxiosWrapper, FETCH_PUBLIC_ENTERPRISE_PROJECTS } from 'services';

export const EnterprisePublicProjects = () => {
  const [fnWrapper, context] = useAxiosWrapper();
  const [enterpriseProjects, setEnterpriseProjects] = useState([]);
  const { enterpriseId } = useParams();
  const navigate = useNavigate();

  const getEnterpriseTeamsList = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_PUBLIC_ENTERPRISE_PROJECTS}/${enterpriseId}`,
      method: 'GET',
      type: 'FETCH_PUBLIC_ENTERPRISE_PROJECTS',
    });

    if (res.data.statusCode === 200) {
      const result = res.data.data;
      const projectList = [];
      result.map((project) => {
        const listData = {
          id: project?.id,
          projectName: project?.title,
          teams: `${project?.teams?.length} ${
            project?.teams?.length === 1 ? 'Team' : 'Teams'
          }`,
          subTitleIcon: 'people',
          type: 'project',
          profileImgSrc: project?.profilePicture,
          profileIcon: 'project',
          description: project?.problemStatement,
          isPublished: project?.projectStatus === 'PUBLISHED',
          projectFeature: [
            {
              iconName: 'enterprise-two',
              label: project?.department?.departmentName,
            },
          ],
        };
        projectList.push(listData);
        return projectList;
      });
      setEnterpriseProjects(projectList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterpriseId]);

  useEffect(() => {
    getEnterpriseTeamsList();
  }, [getEnterpriseTeamsList]);

  return (
    <EnterpriseProjectsWrapper>
      <div className="sub-container">
        <div className="main-wrapper">
          <div className="heading">
            Projects
            <span>({enterpriseProjects.length})</span>
          </div>
          {context.busy.status ? (
            <Loader loading={context.busy.status} />
          ) : (
            <div className="content">
              {enterpriseProjects?.length > 0 ? (
                <div className="cards-container">
                  {enterpriseProjects?.map((project, index) => (
                    <EnterpriseTeamProjectCard
                      key={index}
                      title={project.projectName}
                      profileImgSrc={project.profileImgSrc}
                      profileIcon={project.profileIcon}
                      type={project.type}
                      subTitle={project.teams}
                      subTitleIcon={project.subTitleIcon}
                      description={project.description}
                      features={project.projectFeature}
                      isPublished={project.isPublished}
                      viewHandler={() =>
                        navigate(`/public/project/${project.id}/details`)
                      }
                    />
                  ))}
                </div>
              ) : (
                <div className="no-data-class">No Projects Found!</div>
              )}
            </div>
          )}
        </div>
      </div>
    </EnterpriseProjectsWrapper>
  );
};
