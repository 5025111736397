import React from 'react';
import {
  Document,
  Page,
  Text,
  StyleSheet,
  View,
  Link,
} from '@react-pdf/renderer';

const styles = StyleSheet.create({
  page: {
    paddingTop: 20,
    paddingLeft: 25,
    paddingRight: 25,
  },
  titleContainer: {
    marginTop: 20,
    marginBottom: 40,
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    color: '#219fff',
    fontWeight: 'bold',
    marginRight: 10,
  },
  name: {
    marginLeft: 10,
    textDecoration: 'underline',
  },
  techListFont: {
    fontSize: 12,
    textDecoration: 'underline',
  },
  view: {
    width: '50px',
    height: '50px',
    padding: 0,
    backgroundColor: 'white',
  },
  techContainer: {
    backgroundColor: '#DEF1FF',
    margin: 2,
    padding: 10,
    borderRadius: 10,
  },
  techName: {
    color: '#219FFF',
    fontSize: 10,
  },
  emptyTechnologyContainer: {
    width: '100%',
    padding: '40px 0',
    backgroundColor: '#f4f7fc',
  },
  emptyTechnologyText: {
    color: '#404f67',
    fontSize: 10,
    textAlign: 'center',
  },
  icon: {
    marginRight: 10,
  },
  allImageContainer: {
    marginTop: 30,
    marginBottom: 30,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: 10,
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10,
    borderWidth: '1px',
    borderColor: '#E0E0E0',
    borderRadius: 10,
    width: '100px',
    textAlign: 'center',
    gap: 5,
  },

  horizontalLine: {
    borderBottomWidth: 1,
    borderBottomColor: '#E0E0E0',
  },

  teamProjectMainContainer: {
    marginTop: 30,
    marginBottom: 30,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  teamProjectTitle: {
    fontSize: 12,
    textDecoration: 'underline',
    paddingBottom: 12,
  },
  teamProjectSubContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
  },
  teamProjectContainer: {
    padding: 12,
    borderWidth: 1,
    borderColor: '#E0E0E0',
    borderRadius: 8,
  },
  teamProjectCount: {
    display: 'flex',
    flexDirection: 'row',
    gap: '5',
    alignItems: 'flex-end',
  },
  teamProjectRowValue: {
    fontSize: 20,
    color: '#219FFF',
    fontWeight: 'bold',
  },
  teamProjectRowLabel: {
    fontSize: 10,
    color: '#219FFF',
  },
  teamProjectRowTitle: {
    fontSize: 10,
    marginBottom: 10,
  },

  statisticsContainer: {
    marginTop: 30,
    marginBottom: 30,
    marginLeft: 10,
    marginRight: 10,
  },
  circleContainer: {
    marginTop: 30,
    marginBottom: 30,
    flexDirection: 'row',
    gap: 10,
    alignItems: 'center',
    justifyContent: 'space-between',
    borderWidth: 1,
    borderColor: '#E0E0E0',
    borderRadius: 8,
    padding: 6,
    flexWrap: 'wrap',
  },
  semiCircle: {
    width: 150,
    height: 150,
    borderTopLeftRadius: 100,
    borderTopRightRadius: 100,
    borderBottomRightRadius: 100,
    borderBottomLeftRadius: 100,
    display: 'flex',
    gap: 2,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fullCircle: {
    width: 100,
    height: 100,
    borderTopLeftRadius: 100,
    borderTopRightRadius: 100,
    borderBottomRightRadius: 100,
    borderBottomLeftRadius: 100,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  semiCircleCount: {
    fontSize: 12,
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
  semiCircleLabel: {
    fontSize: 12,
    color: '#FFFFFF',
  },
  circleCount: {
    fontSize: 10,
    color: '#FFFFFF',
    fontWeight: 'bold',
  },
  circleLabel: {
    fontSize: 9,
    color: '#FFFFFF',
    textAlign: 'center',
  },
});

// PDF content component to define the content of the PDF
const ErecordAsPdf = ({ userId, erecordAsPdfProps, baseUrl }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>E-Record</Text>
        <Text>of</Text>
        <Link
          style={styles.name}
          src={`http://${baseUrl}/public/individual/${userId}/summary/basic-details`}>
          {erecordAsPdfProps?.publicUserProfile?.name}
        </Link>
      </View>
      <View>
        <Text style={styles.techListFont}>
          List of Software & Technologies used -
        </Text>
        <View style={styles.allImageContainer}>
          {erecordAsPdfProps.eTechnology.length > 0 ? (
            erecordAsPdfProps?.eTechnology.map((tech, index) => (
              <View style={styles.techContainer} key={index}>
                <Text style={styles.techName}>{tech.title}</Text>
              </View>
            ))
          ) : (
            <View style={styles.emptyTechnologyContainer}>
              <Text style={styles.emptyTechnologyText}>
                You have not added any technology yet!
              </Text>
            </View>
          )}
        </View>
      </View>

      {/* Horizontal Line */}
      <View style={styles.horizontalLine} />

      <View style={styles.teamProjectMainContainer}>
        <View>
          <View>
            <Text style={styles.teamProjectTitle}>Teams</Text>
          </View>
          <View style={styles.teamProjectSubContainer}>
            {erecordAsPdfProps?.eTeamsData.map((team, index) => (
              <View style={styles.teamProjectContainer} key={index}>
                <Text style={styles.teamProjectRowTitle}>{team.label}</Text>
                <View style={styles.teamProjectCount}>
                  <Text style={styles.teamProjectRowValue}>{team.value}</Text>
                  <Text style={styles.teamProjectRowLabel}>Teams</Text>
                </View>
              </View>
            ))}
          </View>
        </View>
        {/* project list */}
        <View>
          <View>
            <Text style={styles.teamProjectTitle}>Projects</Text>
          </View>
          <View style={styles.teamProjectSubContainer}>
            {erecordAsPdfProps?.eProjectsData.map((team, index) => (
              <View style={styles.teamProjectContainer} key={index}>
                <Text style={styles.teamProjectRowTitle}>{team.label}</Text>
                <View style={styles.teamProjectCount}>
                  <Text style={styles.teamProjectRowValue}>{team.value}</Text>
                  <Text style={styles.teamProjectRowLabel}>Projects</Text>
                </View>
              </View>
            ))}
          </View>
        </View>
      </View>

      {/* Horizontal Line */}
      <View style={styles.horizontalLine} />

      <View style={styles.statisticsContainer}>
        <View>
          <Text style={styles.techListFont}>Tasks Statistics</Text>
        </View>
        <View style={styles.circleContainer}>
          {erecordAsPdfProps?.taskStatisticsDetails.map((task, index) => (
            <View style={styles} key={index}>
              {task.circleType === 'semi-circle' ? (
                <View
                  style={[
                    styles.semiCircle,
                    {
                      backgroundColor: task.color,
                    },
                  ]}>
                  <Text style={styles.semiCircleCount}>{task.value}</Text>
                  <Text style={styles.semiCircleLabel}>{task.label}</Text>
                </View>
              ) : (
                <View
                  style={[
                    styles.fullCircle,
                    {
                      backgroundColor: task.color,
                    },
                  ]}>
                  <Text style={styles.circleCount}>{task.value}</Text>
                  <Text style={styles.circleLabel}>{task.label}</Text>
                </View>
              )}
            </View>
          ))}
        </View>
      </View>
    </Page>
  </Document>
);

export default ErecordAsPdf;
