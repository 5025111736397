import { IndividualPublicWrapper } from './style';
import { useState, useEffect, useCallback } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import {
  FETCH_USER_PROFILE,
  FETCH_INDIVIDUAL_PUBLIC_PROFILE_SETTING,
  useAxiosWrapper,
} from 'services';
import TokenService from 'services/token.service';
import { ERecord, IndividualPublicCard } from './Components';
import { IndividualPublicJobPosts } from './IndividualPublicJobPosts';
import { IndividualPublicProjects } from './IndividualPublicProjects';
import { IndividualPublicSummary } from './IndividualPublicSummary';
import { IndividualPublicTeams } from './IndividualPublicTeams';
import { getProfileText } from 'utlils/utils';
import { PUBLIC_PRIVATE_TOGGLE_LABEL, getLeftSideData } from './constants';
import { NavbarBrand1, Sidebar } from 'components';
import { INDIVIDUAL_DASHBOARD_NAV_LIST } from 'pages/IndividualDashboard/constants';
import { IndividualPublicPublication } from './IndividualPublicPublication/index';

export const IndividualPublic = ({ currentLocale, handleLocaleChange }) => {
  const [fnWrapper] = useAxiosWrapper();
  const [userProfile, setUserProfile] = useState({});
  const [taskType, setTaskType] = useState(PUBLIC_PRIVATE_TOGGLE_LABEL.PUBLIC);
  const [hiddenTeams, setHiddenTeams] = useState([]);
  const [hiddenProjects, setHiddenProjects] = useState([]);
  const [refreshBasicDetails, setRefreshBasicDetails] = useState(false);
  const [isContactInfoEnabled, setIsContactInfoEnabled] = useState(false);
  const [isNotLoggedInUser, setNotLoggedInUser] = useState(false);
  const [leftActiveItem, setLeftActiveItem] = useState('');
  const [hideERecord, setHideERecord] = useState(false);

  const { userId } = useParams();

  const setLeftSidebar = (type) => {
    setLeftActiveItem(type);
  };

  const onToggleHandler = (value) => {
    setTaskType(value);
  };

  const getUserProfile = useCallback(async () => {
    const loggedInUser = TokenService.getSession();
    const res = await fnWrapper({
      url: `${FETCH_USER_PROFILE}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_USER_PROFILE',
    });
    if (res.data.statusCode === 200) {
      const response = res.data.data;
      setUserProfile({
        ...response,
        profileText: getProfileText(
          response?.role === 'INDIVIDUAL'
            ? response?.name
            : response?.enterpriseName
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPublicProfileSetting = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_INDIVIDUAL_PUBLIC_PROFILE_SETTING}/${userId}`,
      method: 'GET',
      type: 'FETCH_INDIVIDUAL_PUBLIC_PROFILE_SETTING',
    });
    if (res.data.statusCode === 200) {
      const response = res.data.data;
      if (response.hideTeamId) setHiddenTeams(response.hideTeamId);
      if (response.hideProjectId) setHiddenProjects(response.hideProjectId);
      setIsContactInfoEnabled(response.contactInfoEnabled);
      setHideERecord(response.hideERecord);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshBasicDetails]);

  useEffect(() => {
    getPublicProfileSetting();
  }, [getPublicProfileSetting]);

  useEffect(() => {
    const loggedInUser = TokenService.getSession();
    if (loggedInUser) {
      setNotLoggedInUser(false);
      getUserProfile(loggedInUser);
    } else {
      setNotLoggedInUser(true);
    }
  }, [getUserProfile]);

  return (
    <>
      <NavbarBrand1
        navMenu={INDIVIDUAL_DASHBOARD_NAV_LIST(userProfile?.role)}
        currentLocale={currentLocale}
        handleLocaleChange={handleLocaleChange}
        userProfile={userProfile}
      />
      <IndividualPublicWrapper>
        <div className="teams-container">
          <div className="layout-container">
            <div className="layout-upper-container">
              <div className="upper-container-child">
                <IndividualPublicCard
                  onToggleHandler={onToggleHandler}
                  taskType={taskType}
                  updateOnProfileEdit={setRefreshBasicDetails}
                  isContactInfoEnabled={isContactInfoEnabled}
                  userRole={userProfile?.role}
                />
              </div>
              <div className="upper-container-child">
                <ERecord taskType={taskType} hideERecord={hideERecord} />
              </div>
            </div>
            <div className="layout-lower-container">
              <div className="sub-container">
                <Sidebar
                  sidebarData={getLeftSideData(userId)}
                  activeType={leftActiveItem}
                  setActiveType={setLeftSidebar}
                />
              </div>
              <div className="sub-container">
                <Routes>
                  <Route path="">
                    <Route
                      element={
                        <IndividualPublicSummary
                          currentLocale={currentLocale}
                          handleLocaleChange={handleLocaleChange}
                          taskType={taskType}
                          refreshBasicDetails={refreshBasicDetails}
                          isContactInfoEnabled={isContactInfoEnabled}
                        />
                      }
                      path="summary/*"
                    />
                    <Route
                      element={
                        <IndividualPublicTeams
                          currentLocale={currentLocale}
                          handleLocaleChange={handleLocaleChange}
                          hiddenTeams={hiddenTeams}
                          updateHiddenTeams={getPublicProfileSetting}
                          taskType={taskType}
                        />
                      }
                      path="teams/*"
                    />
                    <Route
                      element={
                        <IndividualPublicProjects
                          currentLocale={currentLocale}
                          handleLocaleChange={handleLocaleChange}
                          hiddenProjects={hiddenProjects}
                          updateHiddenProjects={getPublicProfileSetting}
                          taskType={taskType}
                        />
                      }
                      path="projects/*"
                    />
                    <Route
                      element={
                        <IndividualPublicJobPosts
                          currentLocale={currentLocale}
                          handleLocaleChange={handleLocaleChange}
                          isNotLoggedInUser={isNotLoggedInUser}
                        />
                      }
                      path="job-posts/*"
                    />
                    <Route
                      element={
                        <IndividualPublicPublication
                          currentLocale={currentLocale}
                          handleLocaleChange={handleLocaleChange}
                          isNotLoggedInUser={isNotLoggedInUser}
                        />
                      }
                      path="publications"
                    />
                  </Route>
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </IndividualPublicWrapper>
    </>
  );
};
