export const Box = ({
  bg,
  size,
  display,
  alignItems,
  justifyContent,
  m,
  z,
  mt,
  mb,
  mr,
  ml,
  p,
  pt,
  pb,
  pr,
  pl,
  color,
  width,
  height = 'auto',
  minWidth,
  maxWidth,
  minHeight,
  maxHeight,
  gap,
  children,
  position,
  textAlign,
  colGap,
  rowGap,
  className,
  onClick,
  cursor,
  bRadius,
  weight,
  border,
  boxShadow,
  right,
  top,
  left,
}) => {
  return (
    <div
      onClick={onClick}
      style={{
        backgroundColor: bg,
        fontSize: size,
        position: position,
        display: display,
        alignItems: alignItems,
        justifyContent: justifyContent,
        width: width,
        margin: m,
        marginTop: mt,
        marginLeft: ml,
        marginBottom: mb,
        marginRight: mr,
        paddingTop: pt,
        paddingLeft: pl,
        right: right,
        left: left,
        top: top,
        paddingRight: pr,
        paddingBottom: pb,
        padding: p,
        color: color,
        textAlign: textAlign,
        height: height,
        gap: gap,
        maxWidth: maxWidth,
        maxHeight: maxHeight,
        rowGap: rowGap,
        columnGap: colGap,
        zIndex: z,
        minHeight: minHeight,
        minWidth: minWidth,
        cursor: cursor,
        borderRadius: bRadius,
        fontWeight: weight,
        border: border,
        boxShadow: boxShadow,
      }}
      className={className}>
      {children}
    </div>
  );
};
