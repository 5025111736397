import React, { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { IndividualERecordWrapper } from './style';
import { css } from 'styled-components';
import {
  FETCH_QR_ERECORD_DETAILS,
  FETCH_ERECORD_TECH_STACK,
  useAxiosWrapper,
} from 'services';
import { useEffect } from 'react';
import ErecordAsPdf from '../Components/ERecord/ErecordAsPdf';
import { pdf } from '@react-pdf/renderer';
import BounceLoader from 'react-spinners/BounceLoader';
import Icon from 'utlils/Icons/Icon';

const eTeams = [
  { label: 'No. of Teams managed', value: 0, key: 'teamsManaged' },
  { label: 'No. of Teams enrolled', value: 0, key: 'teamsEnrolled' },
];

const eProjects = [
  { label: 'No. of Projects managed', value: 0, key: 'projectsManaged' },
  { label: 'No. of Projects enrolled', value: 0, key: 'projectsEnrolled' },
];

const statisticsTasks = [
  {
    label: 'Tasks assigned',
    value: 0,
    circleType: 'semi-circle',
    color: '#219FFF',
    key: 'taskAssigned',
  },
  {
    label: 'Tasks Successfully Completed',
    value: 0,
    circleType: 'circle',
    color: '#0ABD00',
    key: 'completedTasks',
  },
  {
    label: 'Tasks completed On-Time',
    value: 0,
    circleType: 'circle',
    color: '#404F67',
    key: 'taskCompletedOnTime',
  },
  {
    label: 'Tasks Delayed',
    value: 0,
    circleType: 'circle',
    color: '#E66657',
    key: 'taskDelayed',
  },
];

const DEFAULT_STATE = {
  enrolledTeamsCount: 0,
  managedTeamsCount: 0,
  enrolledProjectsCount: 0,
  managedProjectsCount: 0,
};
export const IndividualERecord = (props) => {
  const [eRecordData, setERecordData] = useState(DEFAULT_STATE);
  const { userId } = useParams();
  const [fnWrapper] = useAxiosWrapper();
  const [publicUserProfile, setPublicUserProfile] = useState({});
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [isDownloadError, setIsDownloadError] = useState(false);
  const [isDownloadSuccessfull, setIsDownloadSuccessfull] = useState(false);
  const [taskStatisticsData, setTaskStatisticsData] = useState({});
  const [eTechnology, setEtechnology] = useState([]);

  // api to fetch eRecords
  const fetchErecordDetails = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_QR_ERECORD_DETAILS}/${userId}`,
      method: 'GET',
      type: 'FETCH_QR_ERECORD_DETAILS',
    });

    if (res.data.statusCode === 200) {
      const result = res.data.data;
      // get user details
      const newPublicUserProfile = {
        name:
          result?.userDetailsData?.role === 'INDIVIDUAL'
            ? result?.userDetailsData?.individual?.name
            : result?.userDetailsData?.smallEnterprise?.enterpriseName,
      };

      setPublicUserProfile(newPublicUserProfile);
      setTaskStatisticsData(result.taskStatistics);

      // Calculate managed teams count
      const managedTeams = result.enrolledTeamList.filter((team) =>
        team?.teamMembers.some(
          (member) => member.memberUserId === userId && member.role === 'ADMIN'
        )
      );

      // Calculate managed projects count
      const managedProjects = result.enrolledProjectList.filter(
        (project) => project?.userId === userId
      );

      const newERecordData = {
        ...DEFAULT_STATE,
        enrolledTeamsCount: result.enrolledTeamList.length,
        managedTeamsCount: managedTeams.length,
        enrolledProjectsCount: result.enrolledProjectList.length,
        managedProjectsCount: managedProjects.length,
      };
      setERecordData(newERecordData);
      // check is data is fetched
      setIsDataFetched(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // api to fetch e record technology list
  const fetchErecordTechnology = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_ERECORD_TECH_STACK}/${userId}`,
      method: 'GET',
      type: 'FETCH_ERECORD_TECH_STACK',
    });

    if (res.data.statusCode === 200) {
      const result = res.data.data;
      if (result && result.technology && result.technology.length > 0) {
        setEtechnology(result.technology);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchErecordDetails();
    fetchErecordTechnology();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const eTeamsData = eTeams.map((record) => {
    if (record.key === 'teamsManaged') {
      return { ...record, value: eRecordData?.managedTeamsCount };
    } else if (record.key === 'teamsEnrolled') {
      return { ...record, value: eRecordData?.enrolledTeamsCount };
    }
    return record;
  });

  const eProjectsData = eProjects.map((record) => {
    if (record.key === 'projectsManaged') {
      return { ...record, value: eRecordData?.managedProjectsCount };
    } else if (record.key === 'projectsEnrolled') {
      return { ...record, value: eRecordData?.enrolledProjectsCount };
    }
    return record;
  });

  const taskStatisticsDetails = statisticsTasks.map((stats) => {
    if (stats.key === 'taskAssigned') {
      return { ...stats, value: taskStatisticsData.taskAssigned };
    } else if (stats.key === 'completedTasks') {
      return { ...stats, value: taskStatisticsData.completedTasks };
    } else if (stats.key === 'taskCompletedOnTime') {
      return { ...stats, value: taskStatisticsData.taskCompletedOnTime };
    } else if (stats.key === 'taskDelayed') {
      return { ...stats, value: taskStatisticsData.taskDelayed };
    }
    return stats;
  });

  const erecordAsPdfProps = {
    eTechnology,
    eProjectsData,
    eTeamsData,
    userId,
    taskStatisticsDetails,
    publicUserProfile,
  };

  useEffect(() => {
    let a;
    const generatePDF = async () => {
      if (isDataFetched) {
        try {
          // Generate the PDF content
          const pdfBlob = await pdf(
            <ErecordAsPdf erecordAsPdfProps={erecordAsPdfProps} />
          ).toBlob();
          // Create a temporary link element and trigger download
          a = document.createElement('a');
          a.href = URL.createObjectURL(pdfBlob);
          a.download = 'E-Record.pdf';
          a.click();
          setIsDownloadSuccessfull(true);
        } catch (error) {
          setIsDownloadError(true);
        }
      }
    };

    generatePDF();

    return () => {
      if (a) {
        URL.revokeObjectURL(a.href); // Clean up the URL
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataFetched]);

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  return (
    <IndividualERecordWrapper>
      <div className="overlay">
        {isDownloadError ? (
          <div className="download-pdf">
            <Icon name="warning" color="red" size={50} />
            <p>Error in generating or downloading PDF !</p>
          </div>
        ) : (
          <div>
            {isDownloadSuccessfull ? (
              <div className="download-pdf">
                <div className="download-icon">
                  <Icon name="tickIcon" color="#ffffff" />
                </div>
                <p>Successfully downloaded PDF.</p>
              </div>
            ) : (
              <div className="download-pdf">
                <div>
                  <BounceLoader
                    color={'#219FFF'}
                    loading={true}
                    css={override}
                    height={35}
                    margin={20}
                  />
                </div>
                <p>Please wait, Generating e-record PDF...</p>
              </div>
            )}
          </div>
        )}
      </div>
    </IndividualERecordWrapper>
  );
};
