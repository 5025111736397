import styled from 'styled-components';

export const SearchProjectWrapper = styled.div`
  .search-container {
    display: grid;
    grid-template-columns: auto minmax(100px, 120px);
    gap: 20px;
    margin-bottom: 20px;
    & .search-bar {
      background-color: white;
      border-radius: 12px;
    }
  }
  .main-wrapper {
    border-radius: 12px;
    padding: 30px;
    background-color: white;
    margin-bottom: 20px;
    position: relative;
    & .heading {
      padding-bottom: 30px;
      border-bottom: 1px solid #e9eef4;
      & .teams-count {
        margin: 0;
      }
    }
    & .cards-container {
      padding-top: 30px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;

      @media (min-width: 900px) and (max-width: 1499px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: 899px) {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }
    & .no-data-class {
      width: 50%;
      margin: 50px auto;
      text-align: center;
      color: #404f67;
    }
  }

  .pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    background: white;
    border-radius: 12px;
    & .pagination-bar {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
`;
