import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { ClickOutside, OptionWrapper } from 'components';
import Icon from 'utlils/Icons/Icon';
import tokenService from 'services/token.service';

export const UserSettingDropDown = ({ userProfile }) => {
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  let navigate = useNavigate();
  const loggedInUser = tokenService.getSession();

  const isIndividualUserRole = userProfile.role === 'INDIVIDUAL';

  const profilePicture = isIndividualUserRole
    ? userProfile?.profilePicture
    : userProfile?.enterpriseLogo;

  const name = isIndividualUserRole
    ? userProfile?.name
    : userProfile?.enterpriseName;
  const isChangePasswordEnabled = loggedInUser?.signupType === 'EMAIL';

  const PROFILE_OPTIONS =
    userProfile.role === 'MIHINT_SUPERADMIN'
      ? [
          {
            id: 'logout',
            title: 'Log out',
            icon: { name: 'log-out' },
          },
        ]
      : [
          {
            id: 'setting',
            title: 'Settings',
            icon: { name: 'setting' },
          },
          {
            id: 'logout',
            title: 'Log out',
            icon: { name: 'log-out' },
          },
        ];

  const onClickOptionHandler = useCallback(
    (item) => {
      setSelectedOption(item.id);
      if (item.id === 'logout') {
        tokenService.onLogout();
        navigate('/', { replace: true });
      }
      if (item.id === 'setting') {
        if (isIndividualUserRole) {
          navigate(
            `/individual/settings/account-setting/${
              isChangePasswordEnabled ? 'change-password' : 'deactivate-account'
            }`,
            { replace: true }
          );
        } else {
          navigate('/enterprise/settings/account-setting/change-password', {
            replace: true,
          });
        }
      }
    },
    [isChangePasswordEnabled, isIndividualUserRole, navigate]
  );

  return (
    <Wrp className="nvd">
      <div className="nvd__user-profile">
        {profilePicture ? (
          <img
            className="nvd__user-profile__profile-img"
            src={profilePicture}
            alt="profile-img"
          />
        ) : (
          <div className="nvd__user-profile__no-profile-img">
            {userProfile.role === 'MIHINT_SUPERADMIN' ? (
              <Icon name="user-icon" color="#000" />
            ) : (
              <span>{userProfile?.profileText}</span>
            )}
          </div>
        )}
        <ClickOutside active={open} onClickOutside={() => setOpen(false)}>
          <div onClick={() => setOpen(!open)}>
            <div className="nvd__header">
              <div width="80px" className="nvd__header__title">
                {name}
              </div>
              <Icon
                name={open ? 'arrow-drop-up' : 'arrow-down'}
                color="#404F67"
                className="nvd__header__icon"
                size={open ? 18 : 22}
              />
            </div>
            {open && (
              <OptionWrapper
                onClick={onClickOptionHandler}
                options={PROFILE_OPTIONS}
                selectedOption={selectedOption}
              />
            )}
          </div>
        </ClickOutside>
      </div>
    </Wrp>
  );
};

const Wrp = styled.div`
  position: relative;

  & .nvd__ {
    &user-profile {
      display: flex;
      align-items: center;
      column-gap: 8px;

      &__profile-img {
        height: 40px;
        width: 40px;
        border-radius: 50%;
      }
      &__no-profile-img {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        background: #d6f3f9;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 20px;
      }
    }

    &header {
      position: relative;
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 2px;
      cursor: pointer;

      &__icon {
        margin-top: 6px;
      }

      &__title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .header-drop-wrapper {
    .header-drop-container {
    }
    .drop-items {
      cursor: pointer;
      border-bottom: 1px solid #e9eef4;
      .drop-item-title {
        font-weight: $fw_400;
        color: $gray_2;
        margin-left: 12px;
      }
    }
  }
`;
