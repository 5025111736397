import styled from 'styled-components';

export const EditorWrapper = styled.div`
  .ql-toolbar.ql-snow {
    color: #7e8f9a;
    background-color: #f0f0f0;
    border: 2px solid #e9eef4;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    .ql-formats {
      color: #7e8f9a;
      border-right: 1px solid #7e8f9a;
      padding: 0 4px;
      margin: 0;
    }
  }
  .ql-container {
    border: 2px solid #e9eef4;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  .ql-editor {
    min-height: 8em;
    height: 10em;
    overflow: auto;
  }
  .editor-wrapper {
    &__label {
      font-weight: 600;
      font-size: 14px;
      color: #404f67;
      margin-bottom: 8px;
    }
    &__errorMessage {
      margin-top: 10px;
      color: #ff1e26;
      font-size: 14px;
      font-weight: 400;
    }
  }
`;
