import { useState, useCallback, useEffect } from 'react';
import { EnterprisePeopleWrapper } from './style';
import { useParams } from 'react-router-dom';
import { MemberCard } from 'pages/TeamPublic/Components';
import {
  FETCH_PUBLIC_ENTERPRISE_MEMBERS,
  CREATE_USER_CONNECTION,
  useAxiosWrapper,
} from 'services';
import TokenService from 'services/token.service';
import { Loader } from 'components';
import { unionBy } from 'lodash';
import { getProfileText } from 'utlils/utils';

export const EnterprisePublicPeople = (props) => {
  const { userRole } = props;
  const isIndividualUserRole = userRole === 'INDIVIDUAL';
  const [fnWrapper, context] = useAxiosWrapper();
  const [members, setMembers] = useState([]);
  const { enterpriseId } = useParams();

  const connectBtnHandler = (userId) => {
    if (userId) createUserConnection('CONNECT', userId);
  };

  const connectionButtonStatus = (status) => {
    if (status === null || status === '3' || status === '5') {
      return {
        label: 'Connect',
        bgColor: '#fff',
        color: '#219fff',
        hoverBackground: '#219fff',
        hoverColor: '#ffffff',
        iconName: 'profile-add',
        disabled: false,
      };
    } else if (status === '1') {
      return {
        label: 'Pending',
        bgColor: '#fff',
        color: '#FE9320',
        iconName: 'profile-add',
        disabled: true,
      };
    } else if (status === '4') {
      return {
        label: 'Connected',
        bgColor: '#fff',
        color: '#0ABD00',
        iconName: 'profile-add',
        disabled: true,
      };
    }
  };

  const getMembersList = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_PUBLIC_ENTERPRISE_MEMBERS}/${enterpriseId}`,
      method: 'GET',
      type: 'FETCH_PUBLIC_ENTERPRISE_MEMBERS',
    });

    if (res.data.statusCode === 200) {
      const result = res.data.data;
      const loggedInUser = TokenService.getSession();
      const membersList = [];
      result?.map((member) => {
        let userName = member?.memberUser?.individual
          ? member?.memberUser?.individual?.name
          : member?.memberName;
        const item = {
          id: member?.memberUserId,
          name: userName,
          title:
            member?.memberUser?.individual?.title !== null
              ? member?.memberUser?.individual?.title
              : '',
          mutualConnections: member?.memberUser?.individual
            ? member?.mutualConnection?.length
            : '',
          profileImgSrc: member?.memberUser?.individual?.profilePicture,
          profileText: getProfileText(userName),
          coverImgSrc: member?.memberUser?.individual?.coverProfilePicture,
          isIndividual: member?.memberUser?.individual,
          connectionStatus: member?.connectionStatus,
          connectButton:
            loggedInUser.id !== member?.memberUserId &&
            member?.memberUser?.individual !== null
              ? connectionButtonStatus(member?.connectionStatus)
              : null,
        };
        membersList.push(item);
        return membersList;
      });
      const uniqueMembers = unionBy(membersList, 'id');
      setMembers(uniqueMembers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createUserConnection = async (connectionType, userId) => {
    if (userId && userId !== '') {
      const connectWith = {
        connectWithId: userId,
        type: connectionType,
      };

      const res = await fnWrapper({
        url: `${CREATE_USER_CONNECTION}`,
        method: 'POST',
        type: 'CREATE_USER_CONNECTION',
        payload: connectWith,
      });
      if (res.data.statusCode === 200) {
        getMembersList();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    getMembersList();
  }, [getMembersList]);

  return (
    <EnterprisePeopleWrapper>
      <div className="sub-container">
        <div className="main-wrapper">
          <div className="heading">
            People<span>({members.length})</span>
          </div>
          <div className="content">
            {context.busy.status ? (
              <Loader loading={context.busy.status} />
            ) : (
              <div className="cards-container">
                {members?.map((member, index) => {
                  return (
                    <MemberCard
                      key={index}
                      member={member}
                      connectHandler={() => connectBtnHandler(member?.id)}
                      isIndividualUserRole={isIndividualUserRole}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </EnterprisePeopleWrapper>
  );
};
