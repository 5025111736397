import {
  Box,
  CustomButton,
  CustomFileUploadButton,
  DropDown,
  Input,
  ScrollableDiv,
  Textarea,
} from 'components';
import { TASK_URGENCY_LIST } from 'pages/enterpriseDashboard/constants';
import ReactDatePicker from 'react-datepicker';
import Icon from 'utlils/Icons/Icon';
import './styles.scss';
import { checkForSpacesInStartEnd } from 'helpers';

export const CreateTask = ({
  departmentList,
  isLoading,
  onChange,
  onCreateTask,
  onClose,
  onRemoveAttachments,
  state,
}) => {
  const isDisabled =
    state.taskTitle === '' ||
    state.taskDescription === '' ||
    !state.dueDate ||
    checkForSpacesInStartEnd(state.taskTitle) ||
    checkForSpacesInStartEnd(state.taskDescription) ||
    Object.keys(state.assignTo).length === 0 ||
    Object.keys(state.urgency).length === 0;

  return (
    <div className="create-task-container">
      <span className="header">Publish Task</span>
      <ScrollableDiv
        scrollOnHeight="calc(100vh - 40vh)"
        scrollOnHeightMob="calc(100vh - 110px)">
        <div className="row">
          <div className="col-lg-12">
            <Input
              label="Task Title"
              type="text"
              required
              value={state.taskTitle}
              onChange={(value) => onChange('taskTitle', value.target.value)}
            />
          </div>
          <div className="col-lg-12">
            <Textarea
              label="Task Description"
              required
              value={state.taskDescription}
              onChange={(value) =>
                onChange('taskDescription', value.target.value)
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <DropDown
              label="Assign to"
              placeholder="Select"
              objectKey="departmentName"
              required
              items={departmentList}
              onClick={(e) => onChange('assignTo', e)}
              value={state.assignTo}
            />
          </div>
          <div className="col-lg-6">
            <DropDown
              label="Urgency"
              placeholder="Select"
              objectKey="label"
              required
              items={TASK_URGENCY_LIST}
              onClick={(e) => onChange('urgency', e)}
              value={state.urgency}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 task-due-date-wrapper">
            <label className="task-due-date-label form-label">
              Due Date
              <span style={{ color: '#FF1E26' }}>*</span>
            </label>
            <ReactDatePicker
              className="task-due-date"
              dateFormat="dd/MM/yyyy"
              onChange={(date) => onChange('dueDate', date)}
              minDate={new Date()}
              placeholderText="Select due date"
              selectsStart
              selected={state.dueDate}
            />
          </div>
        </div>
        <Box display="flex" alignItems="baseline" mt="32px">
          <Box mr="20px">Add Attachments</Box>
          <CustomFileUploadButton
            borderWidth="0px 0px 1px 0px"
            borderStyle="solid"
            borderColor="#219FFF"
            background="none"
            fontFamily="'Poppins', 'Open Sans'"
            fontSize="15px"
            fontWeight="500"
            textColor="#219FFF"
            handleFile={(file) => onChange('taskAttachments', file)}
            label="Add"
            iconColor="#219FFF"
            iconName="add"
            iconSize="18"
            isMultiple={true}
          />
        </Box>
        <Box mt="20px" mb="40px" className="align-attachments">
          {state.taskAttachments.length
            ? state.taskAttachments.map((file, index) => (
                <div className="file-text" key={index}>
                  {file.name}
                  <span
                    className="remove-icon"
                    onClick={() =>
                      onRemoveAttachments('CREATE', 'taskAttachments', file)
                    }>
                    <Icon color="rgba(146, 157, 175, 1)" name="small-close" />
                  </span>
                </div>
              ))
            : null}
        </Box>
      </ScrollableDiv>

      <div className="create-close-department" mt="83px">
        <CustomButton
          customPadding="16px 36px"
          label="Create"
          disabled={isDisabled}
          onClick={onCreateTask}
          loading={isLoading}
          color={'themeSecondary'}
        />
        <CustomButton
          label="Close"
          disabled={isLoading}
          onClick={onClose}
          color={'themeSecondary'}
          variant="textBtn"
        />
      </div>
    </div>
  );
};
