import styled from 'styled-components';

export const RequestCardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border: 2px solid #0000001d;
  border-radius: 12px;
  padding: 18px 10px;
  align-items: center;
  gap: 10px;
  width: 100%;
  flex-wrap: wrap;
  font-size: 14px;
  .user-name {
    font-weight: 600;
    font-size: 16px;
    color: #0abd00;
    text-decoration: underline;
    cursor: pointer;
  }
  .buttons-wrapper {
    display: flex;
    height: 50px;
    gap: 10px;
    flex-wrap: wrap;
    .request-btn {
      cursor: default;
    }
  }
  .request-status {
    font-size: 14px;
    margin-left: 18px;
  }
`;
