import dayjs from 'dayjs';
import { NavigationalRowItem, StatusButton } from 'components';
import { useNavigate } from 'react-router-dom';
import arrowCircle from '../../../../assests/images/arrow-circle-right.png';

export const INVITEMEMBERROLE = [
  { id: 1, label: 'Admin', value: 'ADMIN' },
  { id: 2, label: 'Co-Admin', value: 'COADMIN' },
  { id: 3, label: 'Member', value: 'MEMBER' },
];

export const TEAMSTATUSTYPE = [
  { id: 1, label: 'Active', value: '4' },
  { id: 2, label: 'Pending', value: '1' },
  { id: 3, label: 'Left', value: '2' },
  { id: 4, label: 'Rejected', value: '3' },
];

export const PROJECT_STATUS_TYPE = [
  { id: 1, label: 'Ongoing', value: '1' },
  { id: 2, label: 'Published', value: '2' },
  { id: 3, label: 'Draft', value: '3' },
];

export const FILTERDATETYPE = [
  {
    label: 'Today',
    value: 'TD',
  },
  {
    label: 'Yesterday',
    value: 'YD',
  },
  {
    label: 'Custom',
    value: 'CD',
  },
];

export const TEAMTABLEHEADERS = [
  {
    title: 'Team Name',
    sortable: true,
    sortKey: 'team_name',
  },
  {
    title: 'Date Added',
    sortable: true,
    sortKey: 'date_added',
  },
  {
    title: 'Designation',
    sortable: false,
    sortKey: 'designation',
  },
  {
    title: 'Role',
    sortable: true,
    sortKey: 'role',
  },
  {
    title: 'Status',
    sortable: true,
    sortKey: 'status',
  },
  {
    title: '',
    sortable: false,
    sortKey: 'action',
  },
];

export const PROJECTTABLEHEADERS = [
  {
    title: 'Project Name',
    sortable: false,
    sortKey: 'projectName',
  },
  {
    title: 'No. of Teams',
    sortable: false,
    sortKey: 'linkedTeams',
  },
  {
    title: 'Created on',
    sortable: true,
    sortKey: 'createdOn',
  },
  {
    title: 'Status',
    sortable: true,
    sortKey: 'status',
  },
  {
    title: '',
    sortKey: 'action',
  },
];
// department list in individual dashboard
export const ENTERPRISE_DEPARTMENT_TABLE_HEADERS = [
  {
    title: 'Department Name',
    sortable: false,
    sortKey: 'departmentName',
  },
  {
    title: 'Date Added',
    sortable: true,
    sortKey: 'dateAdded',
  },
  {
    title: 'Role',
    sortable: false,
    sortKey: 'role',
  },
  {
    title: '',
    sortable: false,
    sortKey: 'action',
  },
];

export const SKILLS_TECHNOLOGY_HEADERS = [
  {
    title: 'Technology Name',
    sortable: false,
    sortKey: 'technologyName',
  },
  {
    title: 'Beginner',
    sortable: false,
    sortKey: 'beginner',
    hasRadioButton: true,
  },
  {
    title: 'Intermediate',
    sortable: false,
    sortKey: 'intermediate',
    hasRadioButton: true,
  },
  {
    title: 'Advanced',
    sortable: false,
    sortKey: 'advanced',
    hasRadioButton: true,
  },
  {
    title: 'Expert',
    sortable: false,
    sortKey: 'expert',
    hasRadioButton: true,
  },
];

let teamRoleStatus = {
  ADMIN: {
    label: 'Admin',
    bgColor: '#E2FFE0',
    textColor: '#0ABD00',
  },
  COADMIN: {
    label: 'Co-Admin',
    bgColor: 'rgba(33, 159, 255, 0.25);',
    textColor: '#219FFF',
  },
  MEMBER: {
    label: 'Member',
    bgColor: '#F0F0F0',
    textColor: '#929DAF',
  },
};

let teamStatus = {
  1: {
    label: 'Pending',
    bgColor: 'transparent',
    textColor: '#fec313',
  },
  2: {
    label: 'Left',
    bgColor: 'transparent',
    textColor: '#FF1E26',
  },
  3: {
    label: 'Rejected',
    bgColor: 'transparent',
    textColor: '#ff1b1b',
  },
  4: {
    label: 'Active',
    bgColor: 'transparent',
    textColor: '#0ABD00',
  },
  5: {
    label: 'Removed',
    bgColor: 'transparent',
    textColor: '#FF1E26',
  },
};

let teamApprovalStatus = {
  APPROVED: {
    label: 'Active',
    bgColor: 'transparent',
    textColor: '#0ABD00',
  },
  PENDING: {
    label: 'Pending',
    bgColor: 'transparent',
    textColor: '#fec313',
  },
  REJECTED: {
    label: 'Rejected',
    bgColor: 'transparent',
    textColor: '#ff1b1b',
  },
};

let projectStatus = {
  PUBLISHED: {
    label: 'Published',
    bgColor: 'transparent',
    textColor: '#0ABD00',
  },
  ONGOING: {
    label: 'Ongoing',
    bgColor: 'transparent',
    textColor: '#219FFF',
  },
  DRAFT: {
    label: 'Draft',
    bgColor: 'transparent',
    textColor: '#ff1b1b',
  },
};

export const TEAMFILTERS = {
  Team: {
    label: 'Team',
  },
  Date: {
    label: 'Date',
    dateType: FILTERDATETYPE,
    key: 'label',
    placeholder: 'Select Date',
  },
  Role: {
    label: 'Role',
    roleType: INVITEMEMBERROLE,
    key: 'label',
    placeholder: 'Select Role',
  },
  Status: {
    label: 'Status',
    statusType: TEAMSTATUSTYPE,
    key: 'label',
    placeholder: 'Select Status',
  },
};

export const PROJECT_FILTERS = {
  Project: {
    label: 'Project',
  },
  Date: {
    label: 'Date',
    dateType: FILTERDATETYPE,
    key: 'label',
    placeholder: 'Select Date',
  },
  Number_of_Teams: {
    label: 'No. of Teams',
  },
  Status: {
    label: 'Status',
    statusType: PROJECT_STATUS_TYPE,
    key: 'label',
    placeholder: 'Select Status',
  },
};

let departmentRoleStatus = {
  ADMIN: {
    label: 'Admin',
    bgColor: '#E2FFE0',
    textColor: '#0ABD00',
  },
  COADMIN: {
    label: 'CoAdmin',
    bgColor: 'rgba(33, 159, 255, 0.25);',
    textColor: '#219FFF',
  },
  MEMBER: {
    label: 'Member',
    bgColor: '#F0F0F0',
    textColor: '#929DAF',
  },
};

export const teamsTableData = (data) => {
  let newData = [];
  if (data.length) {
    newData = data.map((item) => {
      return {
        id: item.id,
        team_name: (
          <NavigationalRowItem
            label={item.teamName}
            navigateTo={`/public/team/${item.id}/details`}
            isOpenInNewTab
            isIndividualUser={true}
            customStyle={{ color: '#404f67' }}
          />
        ),
        team_members: item.teamMembers,
        date_added: dayjs(item.createdAt).format('DD MMM YYYY'),
        designation: item.designation,
        role: <StatusButton status={teamRoleStatus[item.role]} />,
        status:
          item?.teamStatus === 'PENDING' || item?.teamStatus === 'REJECTED' ? (
            <StatusButton status={teamApprovalStatus[item.teamStatus]} />
          ) : (
            <StatusButton status={teamStatus[item.status]} />
          ),

        action: (
          <TeamListAction
            status={item.status}
            teamId={item.id}
            role={item.role}
            type="individual"
          />
        ),
        currentMemberId: item.currentMemberId,
        currentMemberRole: item.role,
        currentMemberStatus: item.status,
        currentTeamName: item.teamName,
      };
    });
  } else {
    newData = [];
  }

  return newData;
};

export const TeamListAction = ({ status, teamId, role, type }) => {
  const navigate = useNavigate();
  return (
    <img
      style={{
        cursor: status === '4' ? 'pointer' : 'not-allowed',
      }}
      key={teamId}
      src={arrowCircle}
      alt=" arrow-right "
      onClick={() => {
        if (status === '4')
          navigate(`/${type}/team-dashboard/${teamId}/members`);
      }}
    />
  );
};

export const departmentTableData = (data) => {
  let newData = [];
  if (data.length) {
    newData = data.map((item) => {
      return {
        id: item.id,
        departmentName: item.departmentName,
        dateAdded: dayjs(item.dateAdded).format('DD MMM YYYY'),
        role: <StatusButton status={departmentRoleStatus[item.role]} />,
        action: (
          <DepartmentListAction
            departmentId={item.action}
            role={departmentRoleStatus[item.role]}
          />
        ),
      };
    });
  } else {
    newData = [];
  }

  return newData;
};

export const projectsTableData = (data, type, baseUrl) => {
  let newData = [];
  if (data.length) {
    newData = data.map((item) => {
      return {
        id: item.id,
        departmentId: item.departmentId,
        projectName: (
          <NavigationalRowItem
            label={item.projectName}
            navigateTo={`/public/project/${item.id}/details`}
            isOpenInNewTab
            isIndividualUser={true}
            customStyle={{ color: '#404f67' }}
          />
        ),
        linkedTeams: item.linkedTeams,
        createdOn: dayjs(item.createdOn).format('DD MMM YYYY'),
        status: <StatusButton status={projectStatus[item.status]} />,
        action: (
          <ProjectListAction
            projectId={item.id}
            type={type}
            baseUrl={baseUrl}
            isDraft={item.status === 'DRAFT'}
          />
        ),
      };
    });
  } else {
    newData = [];
  }

  return newData;
};

export const DepartmentListAction = ({ departmentId, role }) => {
  const userDepRole = role?.label.toLowerCase();
  const navigate = useNavigate();
  return (
    <img
      key={departmentId}
      style={{ cursor: 'pointer' }}
      src={arrowCircle}
      alt=" arrow-right "
      onClick={() => {
        if (userDepRole === 'member') {
          navigate(
            `/individual/profile/organization/enterprise/department/${departmentId}/teams`
          );
        } else {
          navigate(
            `/individual/profile/organization/enterprise/department/${departmentId}/permissions`
          );
        }
      }}
    />
  );
};

export const ProjectListAction = ({
  projectId,
  type,
  isDraft = false,
  baseUrl,
}) => {
  const navigate = useNavigate();
  const navigateOnDraft = `${baseUrl}/create/details`;
  const navigateWhenNotDraft = `${baseUrl}/${projectId}/view/details`;
  return (
    <img
      role="button"
      key={projectId}
      style={{ cursor: 'pointer' }}
      src={arrowCircle}
      alt="arrow-right"
      onClick={() => {
        if (isDraft) {
          navigate(navigateOnDraft, {
            state: { isDraft: isDraft, projectId: projectId },
          });
        } else {
          navigate(navigateWhenNotDraft);
        }
      }}
    />
  );
};
// team list in individual dashboard
export const ENTERPRISETEAMSTABLEHEADERS = [
  {
    title: 'Team Name',
    sortable: false,
    sortKey: 'teamName',
  },
  {
    title: 'Date Added',
    sortable: true,
    sortKey: 'dateAdded',
  },
  {
    title: 'Topic',
    sortable: false,
    sortKey: 'topic',
  },
  {
    title: 'Role',
    sortable: false,
    sortKey: 'role',
  },
  {
    title: 'Status',
    sortable: false,
    sortKey: 'status',
  },
];
