import { Route, Routes } from 'react-router-dom';
import { EnterpriseList } from './enterprise/enterpriseList';
import { Institution } from './institution';
import { DepartmentList } from './enterprise/departmentList';
import Department from './enterprise/department';
import { Box } from 'components';

const IndividualProfileOrganization = (props) => {
  return (
    <Box bg="white" className="main-wrapper">
      <Routes>
        <Route
          element={
            <EnterpriseList isEnterpriseListUpdate={props.isUpdateList} />
          }
          path="enterprise"
        />
        <Route
          path="enterprise/:enterpriseId/departments"
          element={
            <DepartmentList isDepartmentListUpdate={props.isUpdateList} />
          }
        />
        <Route
          path="enterprise/department/:id/*"
          element={<Department isListUpdate={props.isUpdateList} />}
        />
        <Route element={<Institution />} path="institution" />
      </Routes>
    </Box>
  );
};

export default IndividualProfileOrganization;
