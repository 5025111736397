import { initialState } from './roleBasedAccessContext';

export const RoleBasedAccessReducer = (state, action) => {
  switch (action.type) {
    case 'SET_DEFAULT_DATA':
      return {
        ...state,
        defaultData: action.defaultData,
      };
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.payload,
      };
    case 'CLEAR_STATE':
      return initialState;
    case 'SET_PERSONAL_TEAM_RBAC':
      return {
        ...state,
        [action.payload1]: action.payload2,
      };
    case 'SET_PROJECT_RBAC':
      return {
        ...state,
        [action.payload1]: action.payload2,
      };
    default:
      return state;
  }
};
