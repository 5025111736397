import { useState, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router';
import { getData } from 'country-list';
import Loader from 'react-spinners/FadeLoader';
import {
  FETCH_PROJECT_TASKS,
  CREATE_JOB_POST,
  useAxiosWrapper,
  FETCH_JOB_DETAILS_BASED_ON_JOBID,
  UPDATE_JOB_APPROVAL,
  UPDATE_JOB,
  FETCH_TECHNOLOGY_STACKS,
} from 'services';
import {
  Input,
  CustomButton,
  PriceRange,
  Editor,
  DropDown,
  Textarea,
  CustomCheckBox as Checkbox,
  SearchAndAdd,
  CountryCityState,
} from 'components';
import Icon from 'utlils/Icons/Icon';
import RadioGroup from 'pages/IndividualDashboard/components/radioGroup';
import {
  JobType,
  WorkEnvironment,
  ExperienceOptions,
  Recommendation,
  DEFAULT_CREATE_JOB_STATE,
} from '../constant';
import { arrayKeyValueReducer, helperFunction } from '../helper';
import TokenService from 'services/token.service';
export const CreateJob = (props) => {
  const {
    teamInfo,
    userType,
    jobIdFromProps,
    teamIdFromProps,
    setViewJobApproval,
    getPostedJobsList,
    isEnterpriseEdit,
    setOpenEnterpriseJobEdit,
  } = props;

  const {
    getDropDownValue,
    getIsReferAllowed,
    getCountry,
    getQuestion,
    getPriceNumberFormat,
  } = helperFunction;
  const [createJob, setCreateJob] = useState(DEFAULT_CREATE_JOB_STATE);
  const [technologyStacks, setTechnologyStacks] = useState([]);

  const isEditJob = window.location.href.includes('edit-job');
  const countryList = getData();
  let navigate = useNavigate();
  /* disable logic for create job */
  const JobQuestionErrorCheck =
    createJob.questions.length > 1 &&
    createJob.questions.map((item) => item.question !== '').includes(false);
  const isDisableCreateJob =
    !createJob.project ||
    !createJob.title ||
    !createJob.experience ||
    !createJob.jobType ||
    !createJob.workEnvironment ||
    !createJob.isReferAllowed ||
    createJob.technology.length === 0 ||
    !createJob.jobDescription ||
    JobQuestionErrorCheck ||
    createJob.applyWith.length === 0 ||
    (createJob.payRangeFrom &&
      createJob.payRangeTo &&
      createJob.payRangeFrom > createJob.payRangeTo);
  const [fnWrapper, context] = useAxiosWrapper();
  const { teamId, jobId } = useParams();
  const [projectList, setProjectList] = useState([]);

  /* get project list API */
  const getProjectListByTeamId = useCallback(
    async () => {
      const team_id = teamIdFromProps ? teamIdFromProps : teamId;
      const job_id = jobIdFromProps ? jobIdFromProps : jobId;
      const res = await fnWrapper({
        url: `${FETCH_PROJECT_TASKS}/${team_id}`,
        method: 'GET',
        type: 'FETCH_PROJECT_TASKS_BY_TEAM_ID',
      });
      if (res.data.statusCode === 200) {
        let list = [...projectList];
        res?.data?.data[0]?.projects.map((project) =>
          list.push({ label: project.title, value: project.id })
        );
        setProjectList(list);
        if (job_id) {
          await getJobPostDetailsById(list);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  /* ---------- */

  /* Get job post by id API */
  const getJobPostDetailsById = useCallback(
    async (projectList) => {
      const loggedInUser = TokenService.getSession();
      const id = jobIdFromProps ? jobIdFromProps : jobId;
      const res = await fnWrapper({
        url: `${FETCH_JOB_DETAILS_BASED_ON_JOBID}/${id}?userId=${loggedInUser.id}`,
        method: 'GET',
        type: 'FETCH_JOB_BY_ID',
      });
      if (res.data.statusCode === 200) {
        // This implementation looks very bad but we have to do this way because of react-quill editor issue
        setCreateJob((prevState) => ({
          ...prevState,
          jobDescription: res.data.data.jobDescription,
        }));
        setCreateJob((prevState) => ({
          ...prevState,
          project: getDropDownValue(projectList, res.data.data.projectId),
          title: res.data.data.title,
          experience: getDropDownValue(
            ExperienceOptions,
            res.data.data.experience
          ),
          jobType: getDropDownValue(JobType, res.data.data.jobType),
          workEnvironment: getDropDownValue(
            WorkEnvironment,
            res.data.data.workEnvironment
          ),
          designation: res.data.data.designation,
          regionCountry: {
            name: getCountry(countryList, res.data.data.regionCountry)?.name,
            value: getCountry(countryList, res.data.data.regionCountry)?.code,
          },
          regionState: res?.data?.data?.state
            ? {
                name: res.data.data.state,
                value: res.data.data.stateCode,
              }
            : null,
          regionCity: res?.data?.data?.regionCity
            ? {
                name: res?.data?.data?.regionCity,
                value: res?.data?.data?.regionCity,
              }
            : null,
          currencyType: res.data.data.currencyType,
          payRangeFrom: getPriceNumberFormat(res.data.data.payRangeFrom),
          payRangeTo: getPriceNumberFormat(res.data.data.payRangeTo),
          summary: res.data.data.summary,
          technology: res.data.data.technology.map((item) => ({
            id: item?.id,
            title: item?.name,
            value: item?.id,
          })),
          questions: res.data.data.question
            ? getQuestion(res.data.data.question)
            : [
                {
                  question: '',
                },
              ],
          applyWith: res.data.data.applyWith,
          isReferAllowed: getIsReferAllowed(res.data.data.isReferAllowed),
          status: res.data.data.status,
        }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      countryList,
      createJob,
      getCountry,
      getDropDownValue,
      getPriceNumberFormat,
      getQuestion,
      getIsReferAllowed,
      jobId,
      jobIdFromProps,
    ]
  );
  /* ---------- */

  const getTechnologyStacks = useCallback(
    async () => {
      const res = await fnWrapper({
        url: `${FETCH_TECHNOLOGY_STACKS}`,
        method: 'GET',
        type: 'FETCH_TECHNOLOGY_STACKS',
      });
      if (res.data.statusCode === 200 && Array.isArray(res.data.data)) {
        const stacks = res.data.data.map((item) => ({
          id: item?.id,
          title: item?.name,
          value: item?.id,
        }));
        setTechnologyStacks(stacks);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    getTechnologyStacks();
    getProjectListByTeamId();
  }, [getProjectListByTeamId, getTechnologyStacks]);

  /* Functions for onChange questions*/
  const questionOnChangeHandler = (index, value) => {
    let temporaryQuesArray = [...createJob.questions];
    temporaryQuesArray[index].question = value;
    setCreateJob({
      ...createJob,
      questions: temporaryQuesArray,
    });
  };
  /* checkbox handler function */
  const handleCheckBox = (value) => {
    const tempCheckBoxArray = [...createJob.applyWith];
    if (tempCheckBoxArray.includes(value)) {
      let foundIndex = tempCheckBoxArray.indexOf(value);
      if (foundIndex > -1) {
        tempCheckBoxArray.splice(foundIndex, 1);
      }
    } else {
      tempCheckBoxArray.push(value);
    }
    setCreateJob({
      ...createJob,
      applyWith: tempCheckBoxArray,
    });
  };

  /*Create Job API*/
  const postJobHandler = async (acceptorRejectStatus) => {
    const loggedInUser = TokenService.getSession();
    const team_id = teamIdFromProps ? teamIdFromProps : teamId;
    const job_id = jobIdFromProps ? jobIdFromProps : jobId;
    let payload = {};
    const message =
      typeof acceptorRejectStatus === 'string'
        ? `Job ${acceptorRejectStatus} successfully`
        : isEditJob || isEnterpriseEdit
        ? 'Job edited successfully'
        : 'Job created successfully';
    payload = {
      teamId: team_id,
      projectId: createJob.project?.value,
      userId: loggedInUser.id,
      title: createJob.title,
      experience: createJob.experience?.value,
      jobType: createJob.jobType?.value,
      workEnvironment: createJob.workEnvironment?.value,
      regionCountry: createJob.regionCountry?.value,
      state: createJob?.regionState?.name ?? null,
      stateCode: createJob?.regionState?.value ?? null,
      designation: createJob.designation,
      regionCity: createJob.regionCity?.value ?? null,
      technology: createJob.technology.map((item) => item?.id).filter(Boolean),
      currencyType: createJob.currencyType,
      payRangeFrom: createJob.payRangeFrom,
      payRangeTo: createJob.payRangeTo,
      summary: createJob.summary,
      jobDescription: createJob.jobDescription,
      question:
        createJob.questions[0].question !== null
          ? arrayKeyValueReducer(createJob.questions, 'question')
          : null,
      applyWith: createJob.applyWith,
      isReferAllowed: createJob.isReferAllowed,
      status:
        typeof acceptorRejectStatus === 'string'
          ? acceptorRejectStatus
          : isEnterpriseEdit || isEditJob
          ? createJob.status
          : null,
    };
    const res = await fnWrapper({
      url:
        typeof acceptorRejectStatus === 'string'
          ? `${UPDATE_JOB_APPROVAL}/${job_id}`
          : isEditJob || isEnterpriseEdit
          ? `${UPDATE_JOB}/${job_id}`
          : CREATE_JOB_POST,
      method:
        typeof acceptorRejectStatus === 'string' ||
        isEditJob ||
        isEnterpriseEdit
          ? 'PUT'
          : 'POST',
      type: 'CREATE_JOB_POST',
      payload: payload,
    });
    if (res.data.statusCode === 200) {
      toast.success(message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      /*Redirect to posted Jobs page*/
      if (!teamIdFromProps) {
        navigate(`/${userType}/team-dashboard/${teamId}/career/posted-jobs`, {
          replace: true,
        });
      }
      if (teamIdFromProps) {
        if (!isEnterpriseEdit) {
          setViewJobApproval(false);
        }
        if (isEnterpriseEdit) {
          setOpenEnterpriseJobEdit(false);
        }
        getPostedJobsList();
      }
    }
  };

  return (
    <div className="main-wrapper">
      <div className="createJob-wrapper">
        {context.busy.status &&
        (context?.busy?.type === 'FETCH_TECHNOLOGY_STACKS' ||
          context?.busy?.type === 'FETCH_PROJECT_TASKS_BY_TEAM_ID') ? (
          <div className="d-flex justify-content-center align-items-center w-100">
            <Loader loading={context.busy.status} />
          </div>
        ) : projectList?.length ? (
          <>
            {!teamIdFromProps &&
              (isEditJob ? (
                <div className="createJob-wrapper__title">
                  <div className="d-flex align-items-center gap-2">
                    <Icon
                      name="arrow-back"
                      color="#292D32"
                      className="mb-3"
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        navigate(
                          `/${userType}/team-dashboard/${teamId}/career/posted-jobs`,
                          {
                            replace: true,
                          }
                        )
                      }
                    />
                    <p>{'Edit Job'}</p>
                  </div>
                  <div className="createJob-wrapper__title__underline mt-3 w-100" />
                </div>
              ) : (
                <div className="createJob-wrapper__title">
                  {'Post a Job'}
                  <div className="createJob-wrapper__title__underline mt-3 w-100" />
                </div>
              ))}
            <div className="createJob-wrapper__form">
              <div className="row mb-3">
                <div className="col-lg-4 md-6 sm-12">
                  <DropDown
                    label="Select Project"
                    items={projectList}
                    placeholder="Select"
                    required
                    value={createJob?.project}
                    onClick={(item) =>
                      setCreateJob({ ...createJob, project: item })
                    }
                  />
                </div>
                <div className="col-lg-4 md-6 sm-12">
                  <Input
                    label="Job Post title"
                    required
                    value={createJob?.title}
                    onChange={(e) =>
                      setCreateJob({
                        ...createJob,
                        title: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="col-lg-4 md-6 sm-12">
                  <Input
                    label="Designation"
                    required
                    value={createJob?.designation}
                    onChange={(e) =>
                      setCreateJob({
                        ...createJob,
                        designation: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-lg-4 md-6 sm-12">
                  <DropDown
                    label="Experience Level"
                    items={ExperienceOptions}
                    placeholder="Select"
                    required
                    onClick={(item) =>
                      setCreateJob({ ...createJob, experience: item })
                    }
                    value={createJob?.experience}
                  />
                </div>
                <div className="col-lg-4 md-6 sm-12">
                  <DropDown
                    label="Remote/ Hybrid/ In-Office"
                    items={WorkEnvironment}
                    placeholder="Select"
                    required
                    onClick={(item) =>
                      setCreateJob({ ...createJob, workEnvironment: item })
                    }
                    value={createJob?.workEnvironment}
                  />
                </div>
                <div className="col-lg-4 md-6 sm-12">
                  <DropDown
                    label="Job Type"
                    items={JobType}
                    placeholder="Select"
                    required
                    onClick={(item) =>
                      setCreateJob({ ...createJob, jobType: item })
                    }
                    value={createJob?.jobType}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <CountryCityState
                  className="col-md-6 col-lg-6 col-xl-4 col-sm-12"
                  countryLabel="Hiring for the region"
                  stateLabel="Region state"
                  cityLabel="Region city"
                  selectedCountry={createJob.regionCountry}
                  selectedState={createJob.regionState}
                  selectedCity={createJob.regionCity}
                  onCountrySelect={(item) =>
                    setCreateJob({
                      ...createJob,
                      regionCity: '',
                      regionState: '',
                      regionCountry: item,
                    })
                  }
                  onStateSelect={(item) =>
                    setCreateJob({
                      ...createJob,
                      regionCity: '',
                      regionState: item,
                    })
                  }
                  onCitySelect={(item) =>
                    setCreateJob({
                      ...createJob,
                      regionCity: item,
                    })
                  }
                />
                <div className="col-lg-4 md-6 sm-12">
                  <PriceRange
                    minValue={0}
                    onRangeChange={(range) => {
                      setCreateJob({
                        ...createJob,
                        payRangeFrom: range.minRange,
                        payRangeTo: range.maxRange,
                        currencyType: range.currency,
                      });
                    }}
                    range={{
                      minRange: createJob?.payRangeFrom,
                      maxRange: createJob?.payRangeTo,
                      currency: createJob?.currencyType,
                    }}
                  />
                </div>
              </div>
              <div className="mb-5">
                <Textarea
                  label="Summary"
                  value={createJob?.summary}
                  onChange={(e) =>
                    setCreateJob({
                      ...createJob,
                      summary: e.target.value,
                    })
                  }
                />
              </div>
              <div className="mb-5">
                <Editor
                  label="Full Job Description"
                  placeholder="Description of Job roles & responsibilities"
                  value={createJob?.jobDescription}
                  required
                  onChange={(html) => {
                    setCreateJob({
                      ...createJob,
                      jobDescription: html,
                    });
                  }}
                />
              </div>
              <div className="createJob-wrapper__form__applyWith mb-5">
                {'Candidate can apply with'}
                <span className="span-asterisk">{'*'}</span>
                <div className="d-flex flex-wrap mt-3 gap-5">
                  <Checkbox
                    title="E-record"
                    className="createJob-wrapper__form__applyWith__checkBox"
                    check={createJob?.applyWith?.includes('E-RECORD')}
                    onClick={() => handleCheckBox('E-RECORD')}
                  />
                  <Checkbox
                    title="Resume"
                    className="createJob-wrapper__form__applyWith__checkBox"
                    check={createJob?.applyWith?.includes('RESUME')}
                    onClick={() => handleCheckBox('RESUME')}
                  />
                  <Checkbox
                    title="Profile"
                    className="createJob-wrapper__form__applyWith__checkBox"
                    check={createJob?.applyWith?.includes('PROFILE')}
                    onClick={() => handleCheckBox('PROFILE')}
                  />
                </div>
              </div>
              <div className="mb-4">
                <SearchAndAdd
                  label="Specify if any Technologies/Skills are required"
                  itemList={technologyStacks}
                  selectedItems={createJob?.technology}
                  onSearchAndAddHandler={(item) => {
                    setCreateJob({
                      ...createJob,
                      technology: item,
                    });
                  }}
                  required
                />
              </div>
              <div className="mb-5">
                <div className="createJob-wrapper__form__recommendation">
                  {
                    'Would you like to open this position for recommendations and references'
                  }
                  <span className="span-asterisk">{'*'}</span>
                </div>
                <RadioGroup
                  labels={Recommendation}
                  currChecked={createJob?.isReferAllowed}
                  handleChange={(e) =>
                    setCreateJob({
                      ...createJob,
                      isReferAllowed: e.target.value,
                    })
                  }
                  className="createJob-wrapper__form__recommendation__radioButton"
                />
              </div>
              <div className="mb-4 createJob-wrapper__form__askQuestion">
                <span>{'Ask Question to Candidate'}</span>
                <div className="d-flex align-items-center gap-3 mt-2 mb-3">
                  <Input
                    value={createJob?.questions[0]?.question}
                    onChange={(e) => questionOnChangeHandler(0, e.target.value)}
                    height="50px"
                  />
                  <Icon
                    name="add"
                    className="createJob-wrapper__form__askQuestion__icon"
                    color="#0ABD00"
                    onClick={() =>
                      setCreateJob({
                        ...createJob,
                        questions: [...createJob.questions, [{ question: '' }]],
                      })
                    }
                  />
                </div>
                {createJob?.questions.map(
                  (ques, index) =>
                    index !== 0 && (
                      <div
                        className="d-flex align-items-center gap-3 mt-2 mb-3"
                        key={`question${index + 1}`}>
                        <Input
                          value={ques.question}
                          onChange={(e) =>
                            questionOnChangeHandler(index, e.target.value)
                          }
                          height="50px"
                          required
                        />
                        <Icon
                          name="delete"
                          color="red"
                          className="createJob-wrapper__form__askQuestion__icon"
                          onClick={() =>
                            setCreateJob({
                              ...createJob,
                              questions: createJob.questions.filter(
                                (val) => val !== ques
                              ),
                            })
                          }
                        />
                      </div>
                    )
                )}
              </div>
              {jobIdFromProps ? (
                !isEnterpriseEdit ? (
                  <div className="d-flex justify-content-center align-items-center gap-2">
                    <div className="accept-reject-button mt-3">
                      <CustomButton
                        label="Approve"
                        customPadding="14px 36px"
                        onClick={() => postJobHandler('accepted')}
                      />
                    </div>
                    <div className="accept-reject-button mt-3">
                      <CustomButton
                        label="Reject"
                        customPadding="14px 44px"
                        color="themeTertiary"
                        variant="light"
                        onClick={() => postJobHandler('reject')}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center align-items-center gap-2">
                    <div className="mt-3" style={{ width: '120px' }}>
                      <CustomButton
                        label="Save"
                        color="themeSecondary"
                        customPadding="14px 42px"
                        onClick={postJobHandler}
                        disabled={isDisableCreateJob}
                        loading={
                          context.busy.status &&
                          context.busy.type === 'CREATE_JOB_POST'
                        }
                      />
                    </div>
                  </div>
                )
              ) : (
                <div className="d-flex justify-content-center">
                  <div className="createJob-wrapper__button">
                    <CustomButton
                      label={isEditJob ? 'Save' : 'Post Job'}
                      onClick={postJobHandler}
                      disabled={isDisableCreateJob}
                      color="themeSecondary"
                      customPadding="14px 68px"
                      loading={
                        context.busy.status &&
                        context.busy.type === 'CREATE_JOB_POST'
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="createJob-wrapper__no--project">
            {`OOPS! cannot create job as no project has been assigned to ${teamInfo?.teamName}`}
          </div>
        )}
      </div>
    </div>
  );
};
