import { useState, useCallback, useEffect } from 'react';
import { CustomDropDown, CustomTooltip, Loader } from 'components';
import {
  TEAM_AND_PROJECT_TYPE,
  PUBLIC_PRIVATE_TOGGLE_LABEL,
} from '../constants';
import {
  FETCH_PROJECTS_BASED_ON_USER,
  UPDATE_HIDDEN_PROJECT_STATUS,
  useAxiosWrapper,
} from 'services';
import { useParams, useNavigate } from 'react-router-dom';
import { TeamProjectCard } from '../Components';
import TokenService from 'services/token.service';
import NoData from 'assests/images/nodata.png';
import { PrivateTeamProjectCard } from '../Components/PrivateTeamProjectCard';

const tooltipData = [
  {
    label: 'Show',
    text: 'The selected Teams/Projects would be visible to everyone.',
  },
  {
    label: 'Hide',
    text: 'The selected Teams/Projects wouldn’t be visible to anyone.',
  },
];

export const IndividualPublicProjects = ({
  taskType,
  updateHiddenProjects,
  hiddenProjects,
}) => {
  const [fnWrapper, context] = useAxiosWrapper();
  const [projectData, setProjectData] = useState([]);
  const [publicProjectsData, setPublicProjectsData] = useState([]);
  const { userId } = useParams();
  const navigate = useNavigate();

  const [selectedDropDown, setSelectedDropDown] = useState(
    TEAM_AND_PROJECT_TYPE[0]
  );

  const updateDropDownHandler = async (status) => {
    let statusObject = TEAM_AND_PROJECT_TYPE.filter(
      (ele) => ele.value === status
    );
    setSelectedDropDown(statusObject[0]);
  };

  const updateProjectStatus = async (projectId) => {
    const loggedInUser = TokenService.getSession();
    const data = {
      projectId: projectId,
      userId: loggedInUser.id,
    };

    const res = await fnWrapper({
      url: `${UPDATE_HIDDEN_PROJECT_STATUS}`,
      method: 'PUT',
      type: 'UPDATE_HIDDEN_PROJECT_STATUS',
      payload: data,
    });
    if (res.data.statusCode === 200) {
      updateHiddenProjects();
      getProjectList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const getProjectList = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_PROJECTS_BASED_ON_USER}/${userId}?projectType=${selectedDropDown.label.toLowerCase()}`,
      method: 'GET',
      type: 'FETCH_PROJECTS_BASED_ON_USER',
    });

    if (res.data.statusCode === 200) {
      const result = res.data.data;
      const projectDetails = [];
      result?.map((project) => {
        const details = {
          id: project?.id,
          isImg: project?.profilePicture !== null,
          imgSrc:
            project.profilePicture !== null ? project?.profilePicture : '',
          profileIcon:
            project?.enterprise !== null ? 'enterprise-two' : 'project',
          projectName: project?.title,
          num_teams: project?.teams?.length,
          isPublished: project?.projectStatus === 'PUBLISHED',
          type:
            project?.enterprise !== null
              ? project?.enterprise?.enterpriseName
              : project?.user?.individual.name,
          typeIcon: project?.enterprise === null ? 'user' : 'enterprise-two',
          isProjectHidden: hiddenProjects.includes(project.id),
        };
        projectDetails.push(details);
        return projectDetails;
      });
      setProjectData(projectDetails);
      setPublicProjectsData(
        projectDetails.filter((project) => project.isProjectHidden === false)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDropDown, hiddenProjects]);

  useEffect(() => {
    getProjectList();
  }, [getProjectList, selectedDropDown]);

  return (
    <div className="main-wrapper">
      <div className="heading">
        <p>Projects</p>
        <div className="drop-down-class">
          <CustomDropDown
            optionLists={TEAM_AND_PROJECT_TYPE}
            selectedItem={selectedDropDown}
            setSelectedItem={(val) => updateDropDownHandler(val.value)}
            placeholder={TEAM_AND_PROJECT_TYPE[0].label}
            customStyle={{
              backgroundColor: '#E9EEF4',
              border: '1px solid rgba(64, 79, 103, 0.7)',
              color: '#17191d',
              padding: '10px',
              justifyContent: 'space-between',
              whiteSpace: 'normal',
            }}
            width={'100%'}
          />
        </div>
      </div>
      <div className="sub-heading-wrapper">
        <div className="sub-heading">
          {selectedDropDown.label}
          <span>
            (
            {PUBLIC_PRIVATE_TOGGLE_LABEL.PRIVATE === taskType
              ? projectData.length
              : publicProjectsData.length}
            )
          </span>
        </div>
        {PUBLIC_PRIVATE_TOGGLE_LABEL.PRIVATE === taskType && (
          <div className="sub-heading-tooltip">
            <div>Show/Hide</div>
            <CustomTooltip
              onHoverColor="#219fff"
              iconSize={18}
              top="32px"
              left="-160px"
              mobileLeft="-80px">
              {tooltipData.map((item) => {
                return (
                  <div className="tooltip-card-item">
                    <div className="tooltip-card-item-heading">
                      {item.label}
                    </div>
                    <p className="tooltip-card-item-text">{item.text}</p>
                  </div>
                );
              })}
            </CustomTooltip>
          </div>
        )}
      </div>
      {(publicProjectsData.length === 0 &&
        taskType === PUBLIC_PRIVATE_TOGGLE_LABEL.PUBLIC) ||
      (projectData.length === 0 && PUBLIC_PRIVATE_TOGGLE_LABEL.PRIVATE) ? (
        <div className="no-data">
          <img src={NoData} alt="" />
          <p>Oops, nothing to show here!</p>
        </div>
      ) : context.busy.status ? (
        <Loader isLoading={context.busy.status} />
      ) : PUBLIC_PRIVATE_TOGGLE_LABEL.PRIVATE === taskType ? (
        <div className="private-project-list">
          {projectData.map((item, index) => (
            <div key={index}>
              <PrivateTeamProjectCard
                isImg={item.isImg}
                imgSrc={item.imgSrc}
                profileIcon={item.profileIcon}
                title={item.projectName}
                subTitle={item.num_teams}
                type={item.type}
                typeIcon={item.typeIcon}
                cardType="project"
                isItemHidden={!item.isProjectHidden}
                slidingButtonHandler={() => updateProjectStatus(item.id)}
              />
            </div>
          ))}
        </div>
      ) : (
        <div className="projects-list">
          {publicProjectsData.map((item, index) => (
            <div className="team-card-class" key={index}>
              <TeamProjectCard
                viewHandler={() =>
                  navigate(`/public/project/${item.id}/details`)
                }
                isImg={item.isImg}
                imgSrc={item.imgSrc}
                profileIcon={item.profileIcon}
                title={item.projectName}
                subTitle={item.num_teams}
                type={item.type}
                typeIcon={item.typeIcon}
                cardType="project"
                isProjectPublished={item.isPublished}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
