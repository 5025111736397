import React, { useState } from 'react';
import { PrivacyProfileVisibility } from './style';
import { CustomButton, SlidingButton } from 'components';
import RadioGroup from 'pages/IndividualDashboard/components/radioGroup';
import { ProfileVisibilityLabels } from 'pages/Settings/constants';
import {
  GET_PROFILE_VISIBILITY,
  UPDATE_PROFILE_VISIBILITY,
  useAxiosWrapper,
} from 'services';
import tokenService from 'services/token.service';
import { toast } from 'react-toastify';
import { useCallback } from 'react';
import { useEffect } from 'react';

export const ProfileVisibility = () => {
  const [profileVisibilityOption, setProfileVisibilityOption] = useState(null);
  const [hideERecords, setHideERecords] = useState(false);
  const [fnWrapper, context] = useAxiosWrapper();
  const loggedInUser = tokenService.getSession();

  const handleProfileVisibilityRadioButtons = (e) => {
    const { value } = e.target;
    setProfileVisibilityOption(value);
  };

  const onProfileVisibilityHandler = async () => {
    const res = await fnWrapper({
      url: UPDATE_PROFILE_VISIBILITY,
      method: 'PUT',
      type: 'UPDATE_PROFILE_VISIBILITY',
      params: { userId: loggedInUser.id },
      payload: {
        profileVisibility: profileVisibilityOption,
        hideERecord: hideERecords,
      },
    });
    if (res.data.statusCode === 200) {
      await getProfileVisibilityDetails();
      toast.success(res.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  const getProfileVisibilityDetails = useCallback(async () => {
    const response = await fnWrapper({
      url: `${GET_PROFILE_VISIBILITY}/${loggedInUser.id}`,
      method: 'GET',
      type: 'GET_PROFILE_VISIBILITY',
    });
    if (response.data.statusCode === 200) {
      setProfileVisibilityOption(response.data.data?.profileVisibility);
      setHideERecords(response.data.data?.hideERecord);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getProfileVisibilityDetails();
  }, [getProfileVisibilityDetails]);

  return (
    <PrivacyProfileVisibility>
      <div className="privacy-profile-visibility">
        <div className="heading">
          <span>Profile Visibility</span>
        </div>
        <div className="options-text pt-5">
          <p>Make my profile visible to-</p>
        </div>
        <form>
          <div className="radio-buttons">
            <RadioGroup
              handleChange={handleProfileVisibilityRadioButtons}
              labels={ProfileVisibilityLabels}
              currChecked={profileVisibilityOption}
            />
          </div>
          <hr className="horizontal-line my-4" />
          <div className="hide-option-container">
            <div className="hide-show">
              <span className="hide-show-record-text">
                Hide/ Show my E-records
              </span>
            </div>
            <div className="switch-tab">
              <span className="hide-text">
                {hideERecords ? 'Hide' : 'Show'}
              </span>
              <SlidingButton
                isSliding={!hideERecords}
                onClick={() => setHideERecords(!hideERecords)}
              />
            </div>
          </div>
          <div className="submit-button">
            <CustomButton
              customPadding="16px 38px"
              label="Save"
              variant="solid"
              color="themePrimary"
              loading={
                context.busy.type === 'UPDATE_PROFILE_VISIBILITY' &&
                context.busy.status
              }
              disabled={profileVisibilityOption === null}
              onClick={() => onProfileVisibilityHandler()}
            />
          </div>
        </form>
      </div>
    </PrivacyProfileVisibility>
  );
};
