import styled from 'styled-components';

export const TeamInvitationWrapper = styled.div`
  height: calc(100vh - 65px);
  background-color: #f4f7fc;
  display: flex;
  align-items: center;
  & .tiw__ {
    &main-wrapper {
      background: #ffffff;
      border-radius: 25px;
      padding: 20px 20px 0;
      border-top: 25px solid;
      border-top-color: #219fff;
    }
    &page-title {
      border-bottom: 1px solid #404f67 !important;
      font-weight: 500;
      font-size: 26px;
      color: #000000;
    }
    &details {
      display: grid;
      justify-items: center;
      row-gap: 20px;
      margin: 20px 0;

      &__container {
        display: grid;
        align-items: center;
        justify-items: center;
        row-gap: 14px;

        &__subtitle-1 {
          font-weight: 500;
          font-size: 18px;
          color: #404f67;
        }
        &__subtitle-2 {
          font-weight: 700;
          font-size: 30px;
          color: #17191d;
        }
        &__subtitle-3 {
          font-weight: 700;
          font-size: 18px;
          color: #17191d;
        }
        &__subtitle-4 {
          font-weight: 500;
          font-size: 16px;
          color: #404f67;
        }
        &__subtitle-5 {
          font-weight: 600;
          font-size: 16px;
          color: #17191d;
        }
        &__subtitle-6 {
          font-weight: 800;
          font-size: 16px;
          color: #17191d;
        }
      }
    }
    &footer {
      display: grid;
      justify-items: center;
      row-gap: 30px;
      padding-bottom: 30px;
      &__action {
        display: flex;
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr;
        margin: 0 auto;
        column-gap: 12px;
        width: 60%;
      }
    }
  }
`;
