export const numbers = '0123456789';
export const upperCaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
export const lowerCaseLetters = 'abcdefghijklmnopqrstuvwxyz';
export const specialCharacters = "!'^+%&/()=?_$#{[]}|;:>÷`<.*-@";

export const CURRENCY_SYMBOLS = {
  USD: '$', // US Dollar
  EUR: '€', // Euro
  CRC: '₡', // Costa Rican Colón
  GBP: '£', // British Pound Sterling
  ILS: '₪', // Israeli New Sheqel
  INR: '₹', // Indian Rupee
  JPY: '¥', // Japanese Yen
  KRW: '₩', // South Korean Won
  NGN: '₦', // Nigerian Naira
  PHP: '₱', // Philippine Peso
  PLN: 'zł', // Polish Zloty
  PYG: '₲', // Paraguayan Guarani
  THB: '฿', // Thai Baht
  UAH: '₴', // Ukrainian Hryvnia
  VND: '₫', // Vietnamese Dong
};

export const LanguageData = [
  {
    id: 1,
    title: 'ENGLISH',
  },
  {
    id: 2,
    title: 'ARABIC',
  },
];

export const PROJECT_CATEGORY = [
  {
    label: 'Software Development Project',
    value: 'Software Development Project',
  },
  {
    label: 'Marketing Campaign Project',
    value: 'Marketing Campaign Project',
  },
  {
    label: 'Website Redesign Project',
    value: 'Website Redesign Project',
  },
  {
    label: 'Product Launch Project',
    value: 'Product Launch Project',
  },
  {
    label: 'Event Planning Project',
    value: 'Event Planning Project',
  },
  {
    label: 'Market Research Project',
    value: 'Market Research Project',
  },
  {
    label: 'Business Process Improvement Project',
    value: 'Business Process Improvement Project',
  },
  {
    label: 'IT Infrastructure Upgrade Project',
    value: 'IT Infrastructure Upgrade Project',
  },
  {
    label: 'Social Media Strategy Project',
    value: 'Social Media Strategy Project',
  },
  {
    label: 'Customer Relationship Management (CRM) Implementation Project',
    value: 'Customer Relationship Management (CRM) Implementation Project',
  },
  {
    label: 'E-commerce Platform Development Project',
    value: 'E-commerce Platform Development Project',
  },
  {
    label: 'Mobile App Development Project',
    value: 'Mobile App Development Project',
  },
  {
    label: 'Branding and Identity Project',
    value: 'Branding and Identity Project',
  },
  {
    label: 'Sales Strategy Project',
    value: 'Sales Strategy Project',
  },
  {
    label: 'Data Analysis and Reporting Project',
    value: 'Data Analysis and Reporting Project',
  },
  {
    label: 'Educational Curriculum Development Project',
    value: 'Educational Curriculum Development Project',
  },
  {
    label: 'Environmental Sustainability Project',
    value: 'Environmental Sustainability Project',
  },
  {
    label: 'Construction and Renovation Project',
    value: 'Construction and Renovation Project',
  },
  {
    label: 'HR Policy Development Project',
    value: 'HR Policy Development Project',
  },
  {
    label: 'Financial Systems Overhaul Project',
    value: 'Financial Systems Overhaul Project',
  },
  {
    label: 'Supply Chain Optimization Project',
    value: 'Supply Chain Optimization Project',
  },
  {
    label: 'Corporate Training Program Project',
    value: 'Corporate Training Program Project',
  },
  {
    label: 'Health and Safety Initiative Project',
    value: 'Health and Safety Initiative Project',
  },
  {
    label: 'Legal Compliance Project',
    value: 'Legal Compliance Project',
  },
  {
    label: 'Artificial Intelligence Implementation Project',
    value: 'Artificial Intelligence Implementation Project',
  },
  {
    label: 'Machine Learning Model Development Project',
    value: 'Machine Learning Model Development Project',
  },
  {
    label: 'Cybersecurity Enhancement Project',
    value: 'Cybersecurity Enhancement Project',
  },
  {
    label: 'Operations Research Analysis Project',
    value: 'Operations Research Analysis Project',
  },
  {
    label: 'Employee Engagement Initiative Project',
    value: 'Employee Engagement Initiative Project',
  },
  {
    label: 'Quality Assurance and Testing Project',
    value: 'Quality Assurance and Testing Project',
  },
  {
    label: 'Non-Profit Fundraising Project',
    value: 'Non-Profit Fundraising Project',
  },
  {
    label: 'Public Awareness Campaign Project',
    value: 'Public Awareness Campaign Project',
  },
  {
    label: 'Customer Service Improvement Project',
    value: 'Customer Service Improvement Project',
  },
  {
    label: 'Content Creation and Management Project',
    value: 'Content Creation and Management Project',
  },
  {
    label: 'Digital Transformation Project',
    value: 'Digital Transformation Project',
  },
  {
    label: 'Online Course Development Project',
    value: 'Online Course Development Project',
  },
  {
    label: 'Educational Technology Integration Project',
    value: 'Educational Technology Integration Project',
  },
  {
    label: 'Student Retention Improvement Project',
    value: 'Student Retention Improvement Project',
  },
  {
    label: 'Academic Research Project',
    value: 'Academic Research Project',
  },
  {
    label: 'Distance Learning Program Project',
    value: 'Distance Learning Program Project',
  },
  {
    label: 'Scholarship Fund Initiative Project',
    value: 'Scholarship Fund Initiative Project',
  },
  {
    label: 'Educational Workshop and Seminar Series Project',
    value: 'Educational Workshop and Seminar Series Project',
  },
  {
    label: 'School Infrastructure Improvement Project',
    value: 'School Infrastructure Improvement Project',
  },
  {
    label: 'Community Education Outreach Project',
    value: 'Community Education Outreach Project',
  },
  {
    label: 'Special Education Program Development Project',
    value: 'Special Education Program Development Project',
  },
  {
    label: 'Library Expansion Project',
    value: 'Library Expansion Project',
  },
  {
    label: 'Educational Policy Reform Project',
    value: 'Educational Policy Reform Project',
  },
  {
    label: 'Language Learning Program Project',
    value: 'Language Learning Program Project',
  },
  {
    label: 'STEM Education Initiative Project',
    value: 'STEM Education Initiative Project',
  },
  {
    label: 'Arts Education Enhancement Project',
    value: 'Arts Education Enhancement Project',
  },
];

export const groupCategories = [
  { label: 'Social', value: 'SOCIAL' },
  { label: 'Professional', value: 'PROFESSIONAL' },
  { label: 'Educational', value: 'EDUCATIONAL' },
  { label: 'Creative', value: 'CREATIVE' },
  { label: 'Support', value: 'SUPPORT' },
];
