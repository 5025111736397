import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Icon from 'utlils/Icons/Icon';
import { CustomButton, CopyWithIcon } from 'components';
import { useAxiosWrapper } from 'services';
import {
  GROUP_DETAILS,
  FETCH_USER_PROFILE,
  REQUEST_JOIN_GROUP,
  FETCH_GROUP_MEMBER,
} from 'services/constants';
import dayjs from 'dayjs';
import { ViewGroupWrapper, DetailsRow, InfoBox, MemberGrid } from './style';
import { QuestionsModal } from './QuestionsModal';
import tokenService from 'services/token.service';

export const ViewGroup = () => {
  const { groupId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [fnWrapper] = useAxiosWrapper();
  const [groupData, setGroupData] = useState(null);
  const [members, setMembers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRequested, setIsRequested] = useState(false);
  const loggedInUser = tokenService.getSession();

  // Function to open the modal
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleDataRefresh = async () => {
    await getGroupDetails();
    await checkIfRequested();
  };

  const handleSubmit = async (answers) => {
    const payload = {
      userId: loggedInUser.id,
      question: answers,
    };

    const res = await fnWrapper({
      url: `${REQUEST_JOIN_GROUP}/${groupId}`,
      method: 'POST',
      payload,
      type: 'REQUEST_JOIN_GROUP',
    });

    if (res && res?.data?.statusCode === 200) {
      toast.success('Request submitted successfully');
      setIsRequested(true);
    }
    handleDataRefresh();
    setIsModalOpen(false);
  };

  const checkIfRequested = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_GROUP_MEMBER}?groupId=${groupId}&userId=${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_GROUP_MEMBER',
    });

    if (res?.data?.statusCode === 200) {
      if (res?.data?.data.length === 0) {
        setIsRequested(false);
      } else {
        setIsRequested(true);
      }
    }
  }, [groupId, loggedInUser.id, fnWrapper]);

  const getGroupDetails = useCallback(async () => {
    const res = await fnWrapper({
      url: `${GROUP_DETAILS}/${groupId}`,
      method: 'GET',
      type: 'GROUP_DETAILS',
    });

    if (res.data.statusCode === 200) {
      const data = res.data.data;

      // Filter the `membersInfo` array to only include members with `status` 4
      const filteredMembersInfo = data?.membersInfo?.filter(
        (member) => member.status === '4'
      );

      setGroupData({
        id: data?.id,
        groupName: data?.groupName,
        description: data?.description,
        groupType: data?.groupType,
        category: data?.category,
        profilePicture: data?.profilePicture,
        creatorName: data?.user?.individual?.name || 'Unknown',
        createdAt: dayjs(data?.createdAt).format('DD MMM YYYY'),
        membersInfo: filteredMembersInfo,
        questions: data?.question,
      });

      if (filteredMembersInfo?.length) {
        fetchMemberProfiles(filteredMembersInfo);
      }
    } else {
      toast.error('Failed to fetch group details');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId, fnWrapper]);

  const fetchMemberProfiles = async (membersInfo) => {
    const memberDetails = await Promise.all(
      membersInfo.map(async (member) => {
        const res = await fnWrapper({
          url: `${FETCH_USER_PROFILE}/${member.userId}`,
          method: 'GET',
          type: 'FETCH_USER_PROFILE',
        });

        if (res.data.statusCode === 200) {
          const user = res.data.data;
          return {
            id: member.userId,
            name: user?.name || 'Unknown',
            profilePicture: user?.profilePicture || null,
            role: member.role,
          };
        } else {
          return { id: member.userId, name: 'Unknown', role: member.role };
        }
      })
    );
    setMembers(memberDetails);
  };

  useEffect(() => {
    getGroupDetails();
    checkIfRequested();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  return (
    <ViewGroupWrapper>
      <div className="header">
        <Icon name="arrow-back" onClick={() => navigate(-1)} />
        <span>Back to groups</span>
      </div>

      {groupData ? (
        <div className="content">
          {/* Top Row: Profile Picture, Group Name, Request Button, Copy Button */}
          <DetailsRow>
            {groupData.profilePicture ? (
              <img
                src={groupData.profilePicture}
                alt={`${groupData.groupName} Profile`}
                className="group-profile-pic"
              />
            ) : (
              <Icon name="user" size={50} />
            )}

            <div className="group-info">
              <h2>{groupData.groupName}</h2>
              <div className="actions">
                <CustomButton onClick={handleOpenModal} disabled={isRequested}>
                  {isRequested ? 'Requested' : 'Request to Join'}
                </CustomButton>
                <CopyWithIcon
                  id={groupId}
                  textToCopy={`${process.env.REACT_APP_BASE_URL}${location.pathname}`}
                />
              </div>
            </div>
          </DetailsRow>

          {/* Second Row: Created by, Category, Type, Created on */}
          <DetailsRow>
            <InfoBox>
              <div className="icon">
                <Icon name="user" size={22} />
              </div>
              <div className="info">
                <span>Created by</span>
                <p>{groupData.creatorName}</p>
              </div>
            </InfoBox>
            <InfoBox>
              <div className="icon">
                <Icon name="message" size={22} />
              </div>
              <div className="info">
                <span>Category</span>
                <p>{groupData.category}</p>
              </div>
            </InfoBox>
            <InfoBox>
              <div className="icon">
                <Icon name="global" size={22} />
              </div>
              <div class="info">
                <span>Type</span>
                <p>{groupData.groupType}</p>
              </div>
            </InfoBox>
            <InfoBox>
              <div className="icon">
                <Icon name="history" size={22} />
              </div>
              <div className="info">
                <span>Created on</span>
                <p>{groupData.createdAt}</p>
              </div>
            </InfoBox>
          </DetailsRow>

          {/* Group Description */}
          <div className="group-description">
            <h5>About this Group :</h5>
            <p>{groupData.description}</p>
          </div>

          {/* Group Members */}
          {members.length > 0 && (
            <div className="group-members">
              <h5>Members ({members.length})</h5>
              <MemberGrid>
                {members.map((member) => (
                  <div key={member.id} className="member-card">
                    {member.profilePicture ? (
                      <img
                        src={member.profilePicture}
                        alt={member.name}
                        className="member-profile-pic"
                      />
                    ) : (
                      <Icon name="user" size={22} />
                    )}
                    <span
                      className="member-user-name"
                      onClick={() =>
                        navigate(
                          `/public/individual/${member.id}/summary/basic-details`
                        )
                      }>
                      {member.name}
                    </span>
                    <span>{member.role}</span>
                  </div>
                ))}
              </MemberGrid>
            </div>
          )}
        </div>
      ) : (
        <p>No group data found</p>
      )}

      {isModalOpen && (
        <QuestionsModal
          isOpen={false}
          questions={
            JSON.parse(groupData?.questions)
              ? Object.values(JSON.parse(groupData.questions))
              : []
          }
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleSubmit}
        />
      )}
    </ViewGroupWrapper>
  );
};
