import { CustomButton } from 'components';
import Icon from 'utlils/Icons/Icon';
import { JobPostCardWrapper } from './style';

export const JobPostCard = ({
  profileImgSrc,
  profileIcon,
  title,
  owner,
  description,
  viewHandler,
  features = [],
}) => {
  return (
    <JobPostCardWrapper>
      <div className="content-header">
        {profileImgSrc && profileImgSrc !== '' ? (
          <div className="profile-pic-class">
            <img src={profileImgSrc} alt="" />
          </div>
        ) : (
          <div className="icon-style-class">
            <Icon name={profileIcon} size={24} color="#404f67" />
          </div>
        )}
        <div className="title-wrapper">
          <div className="title-wrapper-title">{title}</div>
          <div className="title-wrapper-subtitle">
            <span className="title-wrapper-subtitle-text">{owner}</span>
          </div>
        </div>
      </div>
      <div className="feature-class">
        {features.length !== 0 &&
          features.map((feature, i) => (
            <div className="feature-style" key={i}>
              <Icon name={feature.iconName} size={20} color="#404f67" />
              <span>{feature.label}</span>
            </div>
          ))}
      </div>
      <div className="description">{description}</div>
      <div className="view-button">
        <CustomButton
          label="View"
          className="w-100"
          color="themeSecondary"
          onClick={viewHandler}
          variant="outline"
        />
      </div>
    </JobPostCardWrapper>
  );
};
