import { useState, useEffect } from 'react';
import { TabGroup } from 'components';
import { SUMMARY_TAB_LIST } from '../constants';
import {
  Route,
  Routes,
  useParams,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { ViewAchievements } from './ViewAchievements';
import { ViewBasicDetails } from './ViewBasicDetails';
import { ViewEducation } from './ViewEducation';
import { ViewLicenseCertifications } from './ViewLicenseCertifications';
import { ViewProfessionalDetails } from './ViewProfessionalDetails';

export const IndividualPublicSummary = ({
  taskType,
  refreshBasicDetails,
  isContactInfoEnabled,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(SUMMARY_TAB_LIST[0]);
  const { userId } = useParams();

  const tabSwitchHandler = (value) => {
    setActiveTab(value);
    let tab = value.toLowerCase().replaceAll(' ', '-');
    navigate(`${`/public/individual/${userId}/summary/${tab}`}`, {
      replace: true,
    });
  };
  useEffect(() => {
    let pathItems = location.pathname.split('/');
    SUMMARY_TAB_LIST.forEach((tab) => {
      if (tab.toLowerCase().replaceAll(' ', '-') === pathItems[5]) {
        setActiveTab(tab);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="main-wrapper">
      <div className="heading">Summary</div>
      <div style={{ marginTop: '30px' }}>
        <TabGroup
          tabLists={SUMMARY_TAB_LIST}
          tabClick={tabSwitchHandler}
          activeTab={activeTab}
        />
      </div>
      <Routes>
        <Route
          path="basic-details"
          element={
            <ViewBasicDetails
              taskType={taskType}
              refreshBasicDetails={refreshBasicDetails}
              isContactInfoEnabled={isContactInfoEnabled}
            />
          }
        />
        <Route
          path="education"
          element={<ViewEducation taskType={taskType} />}
        />
        <Route
          path="professional-details"
          element={<ViewProfessionalDetails taskType={taskType} />}
        />
        <Route
          path="achievements"
          element={<ViewAchievements taskType={taskType} />}
        />
        <Route
          path="license-&-certifications"
          element={<ViewLicenseCertifications taskType={taskType} />}
        />
      </Routes>
    </div>
  );
};
