import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AddGroup from './AddGroup';
import ViewGroups from './ViewGroups/ViewGroups';
import GroupInfo from './ViewGroupInfo/GroupInfo';
import { CreateGroupProvider } from 'context/Groups/CreateGroup/createGroupContext';
import { Requests } from './Requests';

export default function IndividualGroups() {
  return (
    <Routes>
      <Route path="">
        <Route path="" element={<ViewGroups />} />
        <Route
          path="/:action/*"
          element={
            <CreateGroupProvider>
              <AddGroup />
            </CreateGroupProvider>
          }
        />
        <Route path="/:groupId/view/*" element={<GroupInfo />} />
        <Route path="/:groupId/requests/*" element={<Requests />} />
      </Route>
    </Routes>
  );
}
