import { StatusButton } from 'components';
import { ReactComponent as ThreeDots } from 'assests/images/three-dots.svg';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import tokenService from 'services/token.service';
import styled from 'styled-components';
import userLogo from '../../../../../assests/images/user.png';
import CommonDropDown from 'pages/enterpriseDashboard/components/commonDropDown';
let memberRole = {
  ADMIN: {
    label: 'Admin',
    bgColor: '#E2FFE0',
    textColor: '#0ABD00',
    padding: '5px 15px',
  },
  COADMIN: {
    label: 'Co-Admin',
    bgColor: 'rgba(33, 159, 255, 0.25);',
    textColor: '#219FFF',
    padding: '5px 15px',
  },
  MEMBER: {
    label: 'Member',
    bgColor: '#F0F0F0',
    textColor: '#929DAF',
    padding: '5px 15px',
  },
};

let OPTIONS = [{ label: 'Remove' }];
export default function GroupMemberCard(props) {
  const [optionsOpen, setOptionsOpen] = useState(false);
  const { member, removePopupHandler, groupDetails } = props;
  let admin = groupDetails.membersInfo.filter(
    (member) => member.role === 'ADMIN'
  );
  const loggedInUser = tokenService.getSession();
  function handleOptionsClick(item, member) {
    switch (item.label) {
      case 'Remove':
        removePopupHandler(member);
        break;
      default:
        break;
    }
  }
  return (
    <GroupMemberCardWrapper>
      <div className="info">
        <div className="image-container">
          {member?.user?.individual?.profilePicture ? (
            <img
              className="image"
              src={member?.user?.individual?.profilePicture}
              alt=""
            />
          ) : (
            <img src={userLogo} alt="" />
          )}
        </div>
        <div className="userName-email">
          <Link
            to={`/public/individual/${member?.user?.id}/summary/basic-details`}
            className="user-name"
            target="_blank">
            {member?.user?.individual?.name}
          </Link>
          <div className="email">{member?.user?.email}</div>
        </div>
      </div>
      <div className="role-options">
        <div className="role">
          <StatusButton status={memberRole[member?.role]} />
        </div>
        {member?.role !== 'ADMIN' && admin[0].user.id === loggedInUser.id ? (
          <div className="option-dots">
            <div
              className="options"
              onClick={() => setOptionsOpen(!optionsOpen)}>
              <ThreeDots
                width={22}
                height={22}
                color={optionsOpen ? '#219fff' : '#c4c4c4'}
                alt=""
              />
            </div>
            <CommonDropDown
              dropItem={OPTIONS}
              active={optionsOpen}
              objectKey="label"
              onClick={(item) => {
                handleOptionsClick(item, member);
              }}
              top="35px"
              right="-52px"
            />
          </div>
        ) : (
          <div className="dummyClass"></div>
        )}
      </div>
    </GroupMemberCardWrapper>
  );
}
const GroupMemberCardWrapper = styled.div`
  border-bottom: 1px solid #c4c4c4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  .info {
    display: flex;
    gap: 5px;
    & .image-container {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      & .image {
        width: 100%;
        height: 100%;
        border-radius: 100%;
      }
    }
    & .userName-email {
      & .user-name {
        font-size: 18px;
        font-weight: 500;
        cursor: pointer;
        color: #212529;
      }
      & .email {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  .role-options {
    display: flex;
    gap: 5px;
    align-items: center;
    .role:first-child {
      padding: 5px 10px;
    }
    & .option-dots {
      position: relative;
      & .options {
        rotate: 90deg;
      }
    }
    & .dummyClass {
      width: 25px;
    }
  }
`;
