import { useContext, useEffect } from 'react';
import { Box, CustomButton } from 'components';
import CreateProjectSuccess from 'assests/images/createProjectSuccess.png';
import { setProgressStep } from 'context/Projects/CreateProject/createProjectAction';
import { CreateProjectContext } from 'context/Projects/CreateProject/createProjectContext';
import { useNavigate } from 'react-router-dom';
import { ProjectSuccessWrapper } from './style';
import Icon from 'utlils/Icons/Icon';

export const Success = ({ type = '', baseUrl = '' }) => {
  let navigate = useNavigate();
  const { createProjectState, createProjectDispatch } =
    useContext(CreateProjectContext);

  const handleDone = () => {
    navigate(`${baseUrl}`);
  };

  useEffect(() => {
    if (createProjectState.progressStep === 1) {
      navigate(`${baseUrl}`);
    } else {
      setProgressStep(createProjectDispatch, 6);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProjectSuccessWrapper>
      <Box mt="50px" display="flex" justifyContent="center">
        <div className="teams-created">
          <img src={CreateProjectSuccess} alt="" width="100%" />
        </div>
      </Box>
      <Box textAlign="center" mt="30px" display="flex" justifyContent="center">
        <div className="teams-created-message">
          <span className="teams-created-title">
            {`Hurray! You’ve successfully created your Project.`}
          </span>
        </div>
      </Box>
      <Box mt="50px" display="flex" justifyContent="center">
        <CustomButton customPadding="15px 25px" onClick={handleDone}>
          Go to Projects
          <Icon name="arrow-forward" color="#fff" size={20} />
        </CustomButton>
      </Box>
    </ProjectSuccessWrapper>
  );
};
