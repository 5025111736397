import { useCallback, useContext, useEffect, useState } from 'react';
import { YourNotification } from './Components/YourNotification';
import { FETCH_ALL_NOTIFICATIONS, useAxiosWrapper } from 'services';
import { NotificationContext } from 'context/Notifications/notificationContext';
import tokenService from 'services/token.service';
import { setAllNotifications } from 'context/Notifications/notificationAction';
import './notification.scss';
import notifyImg from '../../assests/images/notification.png';

export const Notification = () => {
  // const overflowValue = String(value).slice(0, 2) + '+';
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [fnWrapper] = useAxiosWrapper();
  const { notificationState, notificationDispatch } =
    useContext(NotificationContext);

  const allUnreadNotificationsLength = notificationState?.notifications?.filter(
    (i) => i?.status === 'UNREAD'
  )?.length;

  const getUserNotifications = useCallback(async () => {
    const loggedInUser = tokenService.getSession();
    const res = await fnWrapper({
      url: FETCH_ALL_NOTIFICATIONS,
      method: 'GET',
      type: 'FETCH_ALL_NOTIFICATIONS',
      params: {
        userId: loggedInUser.id,
      },
    });
    if (res.data.statusCode === 200) {
      const response = res.data.data;
      await setAllNotifications(notificationDispatch, response);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUserNotifications();
  }, [getUserNotifications]);

  const onOpenNotificationPopup = async () => {
    if (!isNotificationOpen) {
      await getUserNotifications();
    }
    setIsNotificationOpen(!isNotificationOpen);
  };

  return (
    <div className="notification-container">
      <div
        className="notification-container__wrp"
        onClick={onOpenNotificationPopup}>
        <span className="notification-container__label">Notification</span>
        <span className="notification-container__img">
          <img src={notifyImg} alt="notification" width="18px" height="20px" />
          {allUnreadNotificationsLength ? (
            <div className="notified-value-wrapper" />
          ) : null}
        </span>
      </div>
      {isNotificationOpen ? <YourNotification /> : null}
    </div>
  );
};
