import { Box } from 'components';
import { ProfileViewDropdown } from 'pages/IndividualDashboard/components/ProfileViewDropdown';
import { ENTERPRISE_PROFILE_OPTIONS } from 'pages/enterpriseDashboard/constants';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Icon from 'utlils/Icons/Icon';
import ViewEnterpriseDetails from './viewDetails';
import ViewEnterpriseLocation from './viewLocation';
import { ViewEnterpriseProfileWrapper } from './style';

const ViewProfile = ({ userProfile }) => {
  let navigate = useNavigate();
  let { step = 'details' } = useParams();
  const [profileView, setProfileView] = useState({
    id: step,
    title: ENTERPRISE_PROFILE_OPTIONS.find((ele) => ele.id === step).title,
  });
  const handleDropDown = (item) => {
    setProfileView({
      id: item.id,
      title: item.title,
    });
    navigate(`/enterprise/profile/view/${item.id}`);
  };

  const editHandler = (type) => {
    navigate(`/enterprise/profile/create/${type}`);
  };

  return (
    <ViewEnterpriseProfileWrapper>
      <div className="row mb-2 ">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <ProfileViewDropdown
            options={ENTERPRISE_PROFILE_OPTIONS}
            onClick={handleDropDown}
            title={profileView.title}
            selectedOption={profileView.id}
          />
          <div
            className="profile-edit"
            onClick={() => editHandler(profileView.id)}>
            <Icon color="#ffffff" name="edit" size={20} />
          </div>
        </Box>
      </div>
      {profileView.id === 'details' && (
        <ViewEnterpriseDetails userProfile={userProfile} />
      )}
      {profileView.id === 'location' && <ViewEnterpriseLocation />}
    </ViewEnterpriseProfileWrapper>
  );
};

export default ViewProfile;
