import styled from 'styled-components';

export const ViewEnterpriseProfileWrapper = styled.div`
  & .profile-edit {
    border-radius: 100%;
    background-color: #0abd00;
    padding: 9px 12px;
    cursor: pointer;

    svg {
      margin-bottom: 2px;
    }
  }
  .enterprise-details-container {
    .label {
      font-size: 14px;
      font-weight: 700;
      color: #404f67;
    }
    .value {
      word-break: break-word;
      font-size: 14px;
      font-weight: 500;
      color: #404f67;
    }
  }
  .empty-data-section {
    text-align: center;
    &--img {
      width: 60%;
    }
    &--text {
      font-size: 18px;
      font-weight: 600;
      color: #404f67;
      margin-top: 1rem;
    }
  }
`;
