/*Constants and helpers for priceInputComponent */
export const pricePropTypes = [
  'USD',
  'INR',
  'EUR',
  'GBP',
  'CRC',
  'ILS',
  'JPY',
  'KRW',
  'NGN',
  'PHP',
  'PLN',
  'PYG',
  'THB',
  'UAH',
  'VND',
];
export const currencyOptions = [
  { id: 'Dollar', currency: '$', value: 'USD' },
  { id: 'Rupees', currency: '₹', value: 'INR' },
  { id: 'Euro', currency: '€', value: 'EUR' },
  { id: 'Pound', currency: '£', value: 'GBP' },
  { id: 'Costa Rican Colón', currency: '₡', value: 'CRC' },
  { id: 'Israeli New Sheqel', currency: '₪', value: 'ILS' },
  { id: 'Japanese Yen', currency: '¥', value: 'JPY' },
  { id: 'South Korean Won', currency: '₩', value: 'KRW' },
  { id: 'Nigerian Naira', currency: '₦', value: 'NGN' },
  { id: 'Philippine Peso', currency: '₱', value: 'PHP' },
  { id: 'Polish Zloty', currency: 'zł', value: 'PLN' },
  { id: 'Paraguayan Guarani', currency: '₲', value: 'PYG' },
  { id: 'Thai Baht', currency: '฿', value: 'THB' },
  { id: 'Ukrainian Hryvnia', currency: '₴', value: 'UAH' },
  { id: 'Vietnamese Dong', currency: '₫', value: 'VND' },
];

//Remove when database does not have these values
const oldCurrencyOptions = [
  { id: 'Dollar', currency: '$', value: 'dollar' },
  { id: 'Rupees', currency: '₹', value: 'rupees' },
  { id: 'Euro', currency: '€', value: 'euro' },
  { id: 'Pound', currency: '£', value: 'pound' },
];
//-----------------------------------------------
export const getCurrencySymbol = (currency) => {
  let symbol = currencyOptions?.filter((item) => item?.value === currency)[0]
    ?.currency;
  if (!symbol) {
    symbol = oldCurrencyOptions?.filter((item) => item?.value === currency)[0]
      ?.currency;
  }
  return symbol;
};
