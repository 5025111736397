import DatePicker from 'react-datepicker';
import { Input } from '../inputBox';
import '../../../node_modules/react-datepicker/dist/react-datepicker.css';
import { YearMonthPickerContainer } from './styles';

export const MonthYearPicker = ({
  label,
  onDateChangeHandler,
  selected,
  required = false,
}) => {
  return (
    <YearMonthPickerContainer>
      {label !== '' && (
        <label className="error-input form-label label-style">
          {`${label}`}
          <span className="error-text">{required ? '*' : ''}</span>
        </label>
      )}
      <div className="wrapper">
        <DatePicker
          customInput={<Input type={'text'} onClick={onDateChangeHandler} />}
          onChange={(date) => onDateChangeHandler(date, 'month')}
          selected={selected?.month}
          showMonthYearPicker
          dateFormat="MM"
          wrapperClassName="month-picker"
        />
        <DatePicker
          customInput={<Input onClick={onDateChangeHandler} />}
          onChange={(date) => onDateChangeHandler(date, 'year')}
          selected={selected?.year}
          dateFormat="yyyy"
          showYearPicker
          yearItemNumber={10}
          wrapperClassName="year-picker"
        />
      </div>
    </YearMonthPickerContainer>
  );
};
