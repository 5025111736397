import React from 'react';
import styled from 'styled-components';
import iconPaths from './iconPath';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { noop } from 'lodash';

let precondition = (condition, message) => {
  if (!condition) {
    throw new Error(message);
  }
};

const Svg = styled.svg`
  & {
    color: ${({ color }) => (color ? color : '#bdbdbd')};
    cursor: pointer;
    &:hover,
    &:active,
    &:focus {
      fill: ${({ hoverColor, noHover }) =>
        noHover !== 'true' ? (hoverColor ? hoverColor : 'none') : 'none'};
      color: ${({ hoverColor, noHover }) =>
        noHover !== 'true' ? (hoverColor ? hoverColor : 'none') : 'none'};
    }
  }
  &.disabled {
    cursor: not-allowed !important;
  }
`;
// Todo
// Hover state on the background
const Background = styled.div`
  border: ${({ color }) => `1px solid ${color}`};
  width: ${({ size, padding = 10 }) => `${size + padding}px`};
  height: ${({ size, padding = 10 }) => `${size + padding}px`};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ color }) => color};
  border-radius: ${({ type }) => (type === 'rounded' ? '50%' : '4px')};
  &:hover {
    box-shadow: ${(p) =>
      p.elevated ? '0px 2px 6px rgba(10, 10, 20, 0.1);' : 'none'};
  }
`;

/**
 *  A Robust Icon for all Icon related items.
 *  Icon paths should be added to the iconPaths file.
 *  Note:
 * @param {String} name -  name of the svg ..
 * @param {number} size -  the size of the svg.
 * @param {number} width - when specified, takes precedence over size. This becomes the width of the Icon.
 * @param {number} height - when specified, takes precedence over size. This becomes the height of the Icon.
 * @param {String} color - the color of  the icon.
 * @param {String} hoverColor - Specific color of icon when hovered on. default to false.
 * @param {Object} background - Background object is used to add a backgroud to an Icon.
 *                          the Added background can be of oneOf type ['rounded', 'box'],
 *                          other Background properties include
 *                          color: this is the color of the background and NOT the svg
 *                          borderColor: this is the color of the background border.
 *                          padding: distance between the icon and the out rect.
 * @param {Boolean} noHover - Double protection against accidental Hovering.
 * @param {Boolean} elevated - when using a background mode, you can pass a Boolean to add Elevation to the botton on Hover.
 * @param {String} className = used defined class for the Icon.
 * @param {string} title = svg title for svg title tag to support a11y.
 * @param {object} props - react prop
 */
const Icon = (props) => {
  const {
    size,
    width,
    height,
    noHover,
    name,
    color,
    background,
    elevated,
    className,
    title = '',
    disabled = false,
    onClick,
    ...rest
  } = props;

  precondition(iconPaths[name] != null, `${name} is not a valid icon name `);
  const iconFallback = iconPaths['help']; // Fallback in case you pass the wrong name
  const IconObj = iconPaths[name] != null ? iconPaths[name] : iconFallback;
  const iconPath = IconObj.path;
  const iconViewBox = IconObj.viewBox != null ? IconObj.viewBox : '0 0 24 24';
  const IconDisabledHover = IconObj.noHover != null ? IconObj.noHover : false;
  let hover_is_disabled = noHover || IconDisabledHover;
  let _Svg = (
    <Svg
      className={classNames(className, disabled && 'disabled')}
      color={color}
      fill="none"
      height={`${height ? height : size}`}
      noHover={hover_is_disabled ? 'true' : 'false'}
      viewBox={iconViewBox}
      width={`${width ? width : size}`}
      xmlns="http://www.w3.org/2000/svg"
      onClick={disabled ? noop : onClick}
      {...rest}>
      {title && typeof title === 'string' ? <title>{title}</title> : ''}
      {iconPath}
    </Svg>
  );
  return (
    <>
      {isEmpty(background) ? (
        _Svg
      ) : (
        <Background
          color={background.color}
          elevated={elevated}
          padding={background.padding}
          size={background?.size || size}
          type={background.type}>
          {title && typeof title === 'string' ? <title>{title}</title> : ''}
          {_Svg}
        </Background>
      )}
    </>
  );
};

Icon.defaultProps = {
  size: 24,
  color: '#bdbdbd',
  elevated: false,
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  background: PropTypes.shape({ type: PropTypes.oneOf(['rounded', 'box']) }),
};

export default Icon;
