import styled from 'styled-components';

export const MiHintAdminWrapper = styled.div`
  .individual-dashboard-container {
    min-height: 100vh;
    background-color: #f4f7fc;
    .layout-container {
      padding: 80px 8% 20px 8%;
      .layout-sub-container {
        display: grid;
        grid-template-columns: minmax(220px, 250px) auto;
        grid-gap: 40px;
        @media (max-width: 1199px) {
          display: block;
        }
        .sub-container-child {
          @media (max-width: 1199px) {
            margin: 25px 0;
          }
        }
        .main-wrapper {
          background-color: white;
          border-radius: 12px;
          padding: 20px;
          @media (max-width: 992px) {
            padding: 15px;
          }

          .profile-edit {
            border-radius: 100%;
            background-color: #0abd00;
            padding: 9px 12px;
            cursor: pointer;

            svg {
              margin-bottom: 2px;
            }
          }

          .header-text {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 5px;
            @media only screen and (max-width: 992px) {
              display: grid;
              gap: 15px;
            }
            .heading {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 700;
              font-size: 25px;
              color: #17191d;
              & span {
                font-weight: 600;
                font-size: 23px;
                color: #c4c4c4;
              }
            }
          }

          .teams-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            row-gap: 20px;
            @media (max-width: 576px) {
              flex-direction: column;
            }
            .page-title {
              font-weight: 700;
              font-size: 30px;
              color: #17191d;
            }
          }
          .close-search-team {
            cursor: pointer;
            margin-bottom: 3px;
          }
          .team-type {
            font-weight: 500;
            font-size: 20px;
          }
          .add-team-icon {
            margin-right: 6px;
          }
          .manage-arrow {
            margin-top: 4px;
          }
        }
      }
      @media (max-width: 1600px) {
        padding: 80px 6% 20px 6%;
      }
      @media (max-width: 992px) {
        padding: 80px 4% 20px 4%;
      }
    }
  }
`;
