import styled from 'styled-components';

export const TeamSettingsWrapper = styled.div`
  .profile-setting-main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    justify-content: center;
    padding: 10px 5px;
    font-family: Poppins;
    letter-spacing: 0em;

    .page-title {
      font-size: 25px;
      font-weight: 700;
      line-height: 38px;
    }

    .info-text {
      color: #404f67;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
    }
    .upload-profile-picture-section {
      display: flex;
      gap: 6rem;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 3rem;

      .profile-picture {
        width: 120px;
        height: 120px;
        border-radius: 20rem;
      }
      .profile-picture-border {
        width: 120px;
        height: 120px;
        border-radius: 20rem;
        border: 2px dashed #219fff;
      }

      .no-profile-placeholder {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 120px;
        height: 120px;
        border-radius: 20rem;
        background-color: #e9edf4;
      }

      .upload-profile-image-button {
        width: 193px;
        height: 54px;
      }
    }
    .save-button {
      align-self: center;
      margin-bottom: 2rem;
    }

    @media (max-width: 557px) {
      align-items: center;
      .upload-profile-picture-section {
        gap: 2rem;
        flex-direction: column;
      }
    }
  }
`;
