import { useParams, useNavigate, useLocation } from 'react-router-dom';
import './selectOrganization.scss';
import { Box, CustomButton, SelectionButton, Title } from '../../components';
import bgImg from '../../assests/images/Signup.png';
import Icon from 'utlils/Icons/Icon';
import { signupItems, loginItems } from '../../constants/selectOrganization';
import { useEffect } from 'react';

const SelectOrganization = () => {
  let navigate = useNavigate();
  const location = useLocation();
  let { type } = useParams();

  const config = {
    title: type === 'signup' ? 'Sign-up as' : 'Log-in to',
    subTitle: '',
    bannerImg: bgImg,
  };

  useEffect(() => {
    const session = localStorage.getItem('session');
    let token, role, lastLoginTime;
    if (session) {
      const sessionParse = JSON.parse(session);
      token = sessionParse.access_token;
      role = sessionParse.role;
      lastLoginTime = sessionParse?.lastLoginTime;
    } else {
      token = null;
      role = null;
    }
    if (token) {
      if (role === 'SMALL_ENTERPRISE') {
        navigate('/enterprise/profile/view/details');
        return;
      }
      if (role === 'INDIVIDUAL') {
        if (lastLoginTime) {
          navigate('/individual/profile/info/basic_details');
        } else {
          navigate('/individual/profile/create/basic_details');
        }
      }
    }
  }, [navigate]);

  const onSelectOrganization = (type, path) =>
    navigate(`/${type === 'SIGNUP' ? 'signup' : 'login'}/${path}`, {
      replace: true,
      state: location.state,
    });

  return (
    <div className="container-fluid">
      <div className="row sign-up">
        <div className="col-lg-7">
          <div className="container">
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-8">
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Box className="sign-up-form">
                    {/* logo */}
                    <Box mb="78px" mt="33px">
                      <img
                        src={process.env.PUBLIC_URL + '/new-logo.png'}
                        className="img common-navbar-logo"
                        alt="logo"
                      />
                    </Box>
                    {/* title and subtitle */}
                    <Box mb="40px">
                      <Title title={config.title} subTitle={config.subTitle} />
                    </Box>
                    {/* signup list */}
                    {type === 'signup' &&
                      signupItems.map((item, key) => (
                        <Box mb="20px" key={key}>
                          <SelectionButton
                            isImage
                            rightIcon="arrow-right"
                            iconRight
                            imgSrc={item.imgSrc}
                            label={item.label}
                            subLabel={item.subLabel}
                            onClick={() =>
                              onSelectOrganization('SIGNUP', item.type)
                            }
                          />
                        </Box>
                      ))}
                    {type === 'login' &&
                      loginItems.map((item, key) => (
                        <Box mb="20px" key={key}>
                          <SelectionButton
                            isImage
                            rightIcon="arrow-right"
                            iconRight
                            imgSrc={item.imgSrc}
                            label={item.label}
                            subLabel={item.subLabel}
                            onClick={() =>
                              onSelectOrganization('LOGIN', item.type)
                            }
                          />
                        </Box>
                      ))}

                    <div className="back-to-home-btn">
                      <CustomButton
                        variant="textBtn"
                        className="back-btn"
                        customPadding="0 0"
                        onClick={() => navigate('/', { replace: true })}>
                        <Icon name="arrow-back" size={18} color="#404f67" />
                        Back to home
                      </CustomButton>
                    </div>

                    <Box className="new-account-button">
                      <Box>
                        <span className="new-account">
                          {type === 'login'
                            ? 'Don’t have an account yet?'
                            : 'Already have an account yet?'}
                        </span>
                      </Box>
                      <CustomButton
                        label={type === 'login' ? 'Create an Account' : 'Login'}
                        color="themeSecondary"
                        variant="textBtn"
                        className="text-decoration-underline"
                        customPadding="15px 0"
                        onClick={() =>
                          navigate(
                            `/${
                              type === 'login' ? 'signup' : 'login'
                            }/selectOrganization`,
                            { replace: true, state: location.state }
                          )
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
        </div>
        <div className="col-lg-5 d-none d-sm-none d-lg-block image-wrapper ">
          {/* right side image */}
          <div className="right-img"></div>
        </div>
      </div>
    </div>
  );
};

export default SelectOrganization;
