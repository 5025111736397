import { Textarea } from 'components';
import React from 'react';

export default function ProjectAquisition(props) {
  const { setCreateBiddingState, state, isDisabled } = props;

  const onChangeFaq = (key, value) => {
    setCreateBiddingState((prevState) => ({
      ...prevState,
      biddingFaq: { ...prevState.biddingFaq, [key]: value },
    }));
  };

  return (
    <div>
      {Object.keys(state.biddingFaq).map((item, index) => {
        return (
          <div className="publish-checkbox" key={index}>
            <Textarea
              rows={4}
              label={item}
              value={state.biddingFaq[item]}
              required
              onChange={(e) => onChangeFaq(item, e.target.value)}
              disabled={isDisabled}
            />
          </div>
        );
      })}
    </div>
  );
}
