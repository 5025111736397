import { useEffect, useCallback, useContext } from 'react';
import { useParams, Route, Routes, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Icon from '../../../../../utlils/Icons/Icon';
import axios from 'axios';
import ProgressBar from '../../../components/progressBar';
import { Box } from '../../../../../components';
import Information from './information';
import InviteTeamMembers from './inviteTeamMembers';
import './index.scss';
import { CreateTeamContext } from '../../../../../context/Teams/CreateTeam/createTeamContext';
import { setTeamOwnerInformation } from '../../../../../context/Teams/CreateTeam/createTeamAction';
import { SuccessStep } from './successStep';
import { progressDataCreatePersonalTeam } from '../../../constants';

const AddTeams = (props) => {
  let { teamType, isEnterpriseTeam = false } = props;
  const navigate = useNavigate();
  const { id } = useParams();
  const { createTeamState, createTeamDispatch } = useContext(CreateTeamContext);

  const progressDataCreateEnterpriseTeam = [
    {
      id: 1,
      title: 'Information',
      url: `/individual/profile/organization/enterprise/department/${id}/teams/create/information`,
    },
    {
      id: 2,
      title: 'Member',
      url: `/individual/profile/organization/enterprise/department/${id}/teams/create/inviteMember`,
    },
    {
      id: 3,
      title: 'Done',
      url: `/individual/profile/organization/enterprise/department/${id}/teams/create/success`,
    },
  ];

  const getCurrentUserProfile = useCallback(async () => {
    const session = JSON.parse(localStorage.getItem('session'));
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/getUserProfile/${session.id}`,
          {
            headers: {
              'x-access-token': `${session.access_token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.statusCode === 200) {
            const response = res.data.data;
            setTeamOwnerInformation(createTeamDispatch, response);
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log('something went wrong', err);
        });
    } catch (error) {
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const bckBtnHandler = () => {
    navigate(
      `/individual/profile/organization/enterprise/department/${id}/teams`,
      { replace: true }
    );
  };

  useEffect(() => {
    getCurrentUserProfile();
  }, [getCurrentUserProfile]);

  return (
    <Box mt="32px">
      {isEnterpriseTeam ? (
        <div className="create-team-heading mb-4">
          <span className="heading-back-btn" onClick={bckBtnHandler}>
            <Icon color="#292D32" name="arrow-back" size={30} leftIcon />
          </span>
          <span className="heading-text">Create team</span>
        </div>
      ) : (
        <>
          <div className="personal-teams-header mb-5">
            <div className="page-title">
              <span className="heading">Create Team</span>
            </div>
          </div>
          {/* <Box mt="32px" mb="20px">
            <div className="row align-items-center gy-2">
              <div className="col-lg-3">
                <span className="team-type">
                  {capitalizeFirstLetter(teamType)}
                </span>
              </div>
            </div>
          </Box> */}
        </>
      )}
      <div className="add-teams">
        <ProgressBar
          step={createTeamState.progressStep}
          progressData={
            isEnterpriseTeam
              ? progressDataCreateEnterpriseTeam
              : progressDataCreatePersonalTeam
          }
          isRoute
          isLastStepDone
        />
        <Routes>
          <Route
            element={
              <Information
                teamType={teamType}
                isEnterpriseTeam={isEnterpriseTeam}
              />
            }
            path="information"
          />
          <Route
            element={
              <InviteTeamMembers
                teamType={teamType}
                isEnterpriseTeam={isEnterpriseTeam}
              />
            }
            path="inviteMember"
          />
          <Route
            element={
              <SuccessStep
                teamType={teamType}
                isEnterpriseTeam={isEnterpriseTeam}
              />
            }
            path="success"
          />
        </Routes>
      </div>
    </Box>
  );
};

export default AddTeams;
