import Icon from 'utlils/Icons/Icon';
import { MemberInfoCardWrapper } from './style';

const MemberInfoCard = ({ name, designation, role, iconName }) => {
  return (
    <MemberInfoCardWrapper>
      <div
        className={iconName === 'group-user' ? 'card-icon-pad' : 'card-icon'}>
        <Icon color="#404F67" name={iconName} size={26} />
      </div>
      <div className="team-name-wrapper">
        <span className="team-name">{name ?? 'NA'}</span>
        <p className="team-designation">{designation ?? 'NA'}</p>
      </div>
      <div className="team-role">
        <span className="team-role-text">{role ?? 'NA'}</span>
      </div>
    </MemberInfoCardWrapper>
  );
};

export default MemberInfoCard;
