import React, { useState, useEffect } from 'react';
import { DiscussionSectionWrapper, CommentWrapper } from './style';
import { Input, ScrollableDiv } from 'components';
import Icon from 'utlils/Icons/Icon';
import {
  FETCH_PUBLICATION_COMMENTS_LIST,
  CREATE_PUBLICATION_COMMENT,
  DELETE_PUBLICATION_COMMENT,
  useAxiosWrapper,
} from 'services';
import tokenService from 'services/token.service';
import dayjs from 'dayjs';
import UserImg from 'assests/images/user.png';
import CommonDropDown from 'pages/enterpriseDashboard/components/commonDropDown';
import { toast } from 'react-toastify';

const dropDownItems = [
  {
    id: 0,
    label: 'Remove',
  },
];

const sortTypeConsts = ['DESC', 'ASC'];

export const DiscussionSection = (props) => {
  const { publicationId } = props;
  const [fnWrapper] = useAxiosWrapper();
  const [commentsList, setCommentsList] = useState([]);
  const [commentText, setCommentText] = useState('');
  const [canComment, setCanComment] = useState(true);
  const [isSortDesc, setIsSortDesc] = useState(true);

  const getCommentsList = async () => {
    const sortType = isSortDesc ? sortTypeConsts[0] : sortTypeConsts[1];
    const res = await fnWrapper({
      url: `${FETCH_PUBLICATION_COMMENTS_LIST}/${publicationId}?sort=${sortType}`,
      method: 'GET',
      type: 'FETCH_PUBLICATION_COMMENTS_LIST',
    });
    if (res.data.statusCode === 200) {
      const response = res.data.data;
      setCommentsList(response);
    }
    if (res.statusCode === 204) {
      setCommentsList([]);
    }
  };

  const postAComment = async () => {
    setCanComment(false);
    setCommentText('');
    const loggedInUser = tokenService.getSession()?.id;
    let payload = {
      publicationId,
      userId: loggedInUser,
      comment: commentText,
    };
    const res = await fnWrapper({
      url: CREATE_PUBLICATION_COMMENT,
      method: 'POST',
      type: 'CREATE_PUBLICATION_COMMENT',
      payload: payload,
    });
    if (res?.data?.statusCode === 201) {
      toast.success(res.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      getCommentsList();
    }
    setCanComment(true);
  };

  const removeComment = async (commentId) => {
    const res = await fnWrapper({
      url: `${DELETE_PUBLICATION_COMMENT}/${commentId}`,
      method: 'PUT',
      type: 'DELETE_PUBLICATION_COMMENT',
      payload: {
        userId: tokenService.getSession()?.id,
      },
    });
    if (res.data.statusCode === 200) {
      toast.success(res.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      getCommentsList();
    }
  };

  const handleInputChange = (event) => {
    setCommentText((prev) => event?.target?.value);
  };

  useEffect(() => {
    getCommentsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSortDesc]);

  return (
    <DiscussionSectionWrapper>
      <div className="title-block">
        <p>Discussion</p>
        <p>
          Sort By{' '}
          <Icon
            onClick={() => setIsSortDesc((prev) => !prev)}
            size={24}
            name={isSortDesc ? 'arrow-down-two' : 'arrow-up'}
          />
        </p>
      </div>
      <div className="input-block">
        <Input
          type="text"
          value={commentText}
          onChange={handleInputChange}
          placeholder="Share your views and feedbacks"
          iconRight={true}
          iconColor="#219fff"
          iconRightOnClickHandler={postAComment}
          disabled={!canComment}
          iconRightName="send"
          iconRightSize={30}
          handleKeyDown={(e) => {
            if (e.key === 'Enter') {
              postAComment();
            }
          }}
        />
      </div>
      <br />
      <ScrollableDiv scrollOnHeight="50vh">
        <div className="comments">
          {commentsList?.length > 0 ? (
            commentsList?.map((comment, index) => {
              return (
                <CommentComponent
                  data={comment}
                  key={index}
                  removeComment={removeComment}
                />
              );
            })
          ) : (
            <div className="no-comments">No comments yet</div>
          )}
        </div>
      </ScrollableDiv>
    </DiscussionSectionWrapper>
  );
};

const CommentComponent = ({ data, removeComment }) => {
  const canRemoveComment =
    tokenService.getSession()?.id === data?.users?.id ||
    tokenService.getSession()?.id === data?.publication?.userId;
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);

  const handleOptionsClick = (label) => {
    if (label === 'Remove') {
      setIsDropDownOpen(false);
      removeComment(data?.id);
    }
  };

  return (
    <CommentWrapper>
      {canRemoveComment && (
        <div className="comment-dots">
          <Icon
            size={20}
            name="threeDots"
            onClick={() => setIsDropDownOpen((prev) => !prev)}
          />
          <CommonDropDown
            dropItem={dropDownItems}
            active={isDropDownOpen}
            objectKey="label"
            onClick={(item) => {
              handleOptionsClick(item?.label);
            }}
            top="35px"
            right="-38px"
          />
        </div>
      )}
      <div className="comment-header">
        <div className="pfp-and-name">
          <img
            src={
              data?.users?.individual?.profilePicture
                ? data?.users?.individual?.profilePicture
                : UserImg
            }
            alt="commentor-pfp"
          />
          <p>{data?.users?.userName}</p>
        </div>
        <p className="time">{dayjs(data?.createdAt).format('DD MMM YYYY')}</p>
      </div>
      <p className="comment-content">{data?.commentMessage}</p>
    </CommentWrapper>
  );
};
