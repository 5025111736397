import { useRef } from 'react';
import Icon from 'utlils/Icons/Icon';
import { LogoSelectorWrapper } from './style';
import { toast } from 'react-toastify';

const LogoSelector = ({ enterpriseLogo, setState }) => {
  const hiddenFileInput = useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  function byteToMB(byteValue) {
    return byteValue / 1024 ** 2;
  }

  const handleFileInput = (event) => {
    if (byteToMB(event.target.files[0].size) < byteToMB(100000)) {
      const fileUploaded = event.target.files[0];
      setState((state) => {
        return {
          ...state,
          enterpriseLogo: fileUploaded,
        };
      });
      event.target.value = null;
    } else {
      toast.warning('Image size should be less than 1MB', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };
  return (
    <LogoSelectorWrapper className="col-md-6 col-lg-6 col-xl-4 col-sm-12">
      <div className="main-content">
        <div className="upload-logo-label">
          <label className="form-label label-style">
            Upload logo
            <span className="required-logo">*</span>
          </label>
          <div className="upload-div">
            <div className="upload-text-container">
              <p>
                {enterpriseLogo ? (
                  enterpriseLogo?.name ? (
                    <span>{enterpriseLogo?.name}</span>
                  ) : (
                    <span>Logo uploaded</span>
                  )
                ) : (
                  <span>No file uploaded</span>
                )}
              </p>
            </div>
            <div className="remove-logo-icon">
              {enterpriseLogo ? (
                <Icon
                  onClick={() =>
                    setState((state) => ({ ...state, enterpriseLogo: null }))
                  }
                  name="cross"
                  className="cross-icon"
                  size={28}
                />
              ) : null}
            </div>
          </div>
        </div>
        <div className="upload-button">
          <div
            onClick={!enterpriseLogo ? handleClick : null}
            className={
              !enterpriseLogo ? 'upload-icon' : 'upload-icon-disabled'
            }>
            <Icon name="upload-box" color={'#FFFFFF'} />
          </div>
          <input
            accept="image/*"
            type="file"
            ref={hiddenFileInput}
            onChange={handleFileInput}
            style={{ display: 'none' }}
            multiple={false}
          />
        </div>
      </div>
    </LogoSelectorWrapper>
  );
};

export default LogoSelector;
