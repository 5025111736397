import React from 'react';
import { Link } from 'react-router-dom';

export const NavigationalRowItem = ({
  label,
  navigateTo = '',
  isOpenInNewTab,
  customStyle,
  isIndividualUser,
}) => {
  return (
    <>
      {navigateTo === '' ? (
        <div style={customStyle}>{label}</div>
      ) : (
        <Link
          style={{
            textDecoration: `${isIndividualUser ? 'underline' : 'none'}`,
            cursor: `${isIndividualUser ? 'pointer' : 'text'}`,
            ...customStyle,
          }}
          to={navigateTo}
          target={isOpenInNewTab && '_blank'}
          rel="noopener norefferer">
          {label}
        </Link>
      )}
    </>
  );
};
