import dayjs from 'dayjs';
import { LinkedCard } from 'pages/ProjectPublic/Components';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FETCH_PUBLICATIONS_BASED_ON_USER_ID, useAxiosWrapper } from 'services';
import BgImg from 'assests/images/bgImgPublic.png';

export const IndividualPublicPublication = (props) => {
  const { isNotLoggedInUser } = props;
  const [fnWrapper] = useAxiosWrapper();
  const navigate = useNavigate();
  const [publicationsData, setPublicationsData] = useState([]);
  const { userId } = useParams();
  const getPublications = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_PUBLICATIONS_BASED_ON_USER_ID}?userId=${userId}`,
      method: 'GET',
      type: 'FETCH_PUBLICATIONS_BASED_ON_USER_ID',
    });
    if (res.data.statusCode === 200) {
      setPublicationsData(res.data.data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPublications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const viewHandler = (publicationId) => {
    return isNotLoggedInUser
      ? navigate('/login/individual')
      : window.open(
          `/public/publication/${publicationId}`,
          'rel=noopener noreferrer'
        );
  };

  return (
    <div className="main-wrapper">
      <div className="heading">Publications</div>
      <div className="content">
        {publicationsData.length > 0 ? (
          <div className="projects-list">
            {publicationsData.map((publication, index) => (
              <LinkedCard
                key={index}
                title={publication.title}
                type="publication"
                owner={
                  publication?.user?.individual
                    ? publication?.user?.individual?.name
                    : publication?.user?.smallEnterprise?.enterpriseName
                }
                profileImgSrc={
                  publication?.coverImage ? publication.coverImage : BgImg
                }
                profileIcon="publication"
                description={
                  <div
                    className="fixed-width-content"
                    dangerouslySetInnerHTML={{
                      __html: publication.description,
                    }}
                  />
                }
                features={[
                  {
                    iconName: 'shield-security',
                    iconLabel: 'Privacy',
                    label: publication?.privacy,
                  },
                  {
                    iconName: 'clock',
                    iconLabel: 'Published on',
                    label: dayjs(publication?.createdAt).format('DD MMM YYYY'),
                  },
                ]}
                viewHandler={() => viewHandler(publication.id)}
              />
            ))}
          </div>
        ) : (
          <div className="no-data-class">No Publication Found!</div>
        )}
      </div>
    </div>
  );
};
