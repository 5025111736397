import styled from 'styled-components';

export const ProjectViewWrapper = styled.div`
  position: relative;
  & .project-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin: 30px 0;

    & .project-text {
      margin-top: 50px;
      width: 339px;
      text-align: center;
      @media (max-width: 576px) {
        width: 328px;
      }

      & .text-style {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 16px;
        color: #404f67;
      }
    }

    & .project-button {
      margin-top: 30px;
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 25px;
      @media (max-width: 1200px) {
        width: 60%;
      }
      @media (max-width: 800px) {
        width: 70%;
      }
      @media (max-width: 576px) {
        flex-direction: column;
        justify-content: space-evenly;
        height: 20vh;
      }
    }
  }

  & .header-buttons-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 40px;
    gap: 50px;

    & .left-buttons {
      width: 100%;
    }
    & .right-buttons {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;

      @media (max-width: 767px) {
        grid-template-columns: 1fr;
      }
    }

    @media (max-width: 767px) {
      flex-direction: column;
      gap: 10px;
    }
  }

  .no-project {
    width: 75%;
    text-align: center;
    margin: 10% auto;
    font-weight: 500;
    color: #929daf;
  }

  & .filter-arrow {
    margin-left: 5px;
    margin-top: 6px;
  }
`;
