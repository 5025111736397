import { useState, useEffect, useContext, useCallback } from 'react';
import {
  setProjectDetails,
  setProgressStep,
  clearState,
} from 'context/Projects/CreateProject/createProjectAction';
import { useNavigate, useParams } from 'react-router-dom';
import { CreateProjectContext } from 'context/Projects/CreateProject/createProjectContext';
import ProjectCard from 'pages/IndividualDashboard/components/projectCard/projectCard';
import { StatusCard } from 'pages/IndividualDashboard/components/statusCard';
import {
  CustomButton,
  Loader,
  ModalPopup,
  ScrollableDiv,
  SearchField,
  TabGroup,
} from 'components';
import RadioGroup from 'pages/IndividualDashboard/components/radioGroup';
import { AddTeamWrapper } from './style';
import { TYPES } from 'pages/IndividualDashboard/constants';
import {
  FETCH_PUBLIC_TEAMS,
  FETCH_TEAMS_BASED_ON_ENTERPRISE,
  FETCH_EXISTING_TEAMS,
  FETCH_TEAMS_BASED_ON_PROJECT,
  FETCH_ENTERPRISE_LIST,
  FETCH_PROJECT_SERVICES,
  useAxiosWrapper,
  CREATE_PROJECT,
  UPDATE_DRAFT_PROJECT,
} from 'services';
import tokenService from 'services/token.service';

const radioEnterpriseLabels = [
  { text: 'Invite Linked Project Teams', value: '1' },
  { text: 'Invite from current organization', value: '2' },
  {
    text: 'Invite existing Teams',
    value: '3',
  },
];

const personalRadioLabels = [
  { text: 'Invite Other Teams', value: '1' },
  {
    text: 'Invite existing Teams',
    value: '3',
  },
];

const INVITE_TEAM_TAB_LIST = ['Linked Project Teams', 'Public Teams'];

export const AddTeams = ({
  onLeavingEditor,
  type = '',
  isDraft = false,
  draftProjectId = '',
  baseUrl = '',
}) => {
  const [fnWrapper, context] = useAxiosWrapper();
  const { createProjectState, createProjectDispatch } =
    useContext(CreateProjectContext);
  const [activeTab, setActiveTab] = useState(INVITE_TEAM_TAB_LIST[0]);
  const [searchTeam, setSearchTeam] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [radioType, setRadioType] = useState('1');
  const [isDraftPopupOpen, setIsDraftPopupOpen] = useState(false);
  const [customData, setCustomData] = useState([]);
  const [userEnterpriseList, setUserEnterpriseList] = useState([]);
  const [warningPopup, setWarningPopup] = useState({
    warningOpen: false,
    teamId: '',
  });
  const [previousLinkedTeams, setPreviousLinkedTeams] = useState([]);
  const { id, departmentId, projectId, action } = useParams();
  const userId = tokenService?.getSession()?.id;
  const navigate = useNavigate();

  const radioLabels =
    type === TYPES.ENTERPRISE ? radioEnterpriseLabels : personalRadioLabels;

  const tabSwitchHandler = (value) => {
    setActiveTab(value);
    if (value === INVITE_TEAM_TAB_LIST[0]) {
      getLinkedProjectTeams();
    } else {
      getPublicTeams();
    }
  };

  const searchTeamByNameHandler = (value) => {
    setSearchTeam(value);
    let temp = [];
    if (value) {
      temp = customData.filter(
        (ele) => ele.name.toLowerCase().indexOf(value.toLowerCase()) > -1
      );
      setFilteredData(temp);
    } else {
      setFilteredData(customData);
    }
  };
  const closeSearchTeamByName = () => {
    setSearchTeam('');
    setFilteredData(customData);
  };

  const onCloseHandler = () => {
    if (action && action === 'edit') {
      onLeavingEditor();
    } else {
      setIsDraftPopupOpen(true);
    }
  };

  const leaveWithoutSave = async () => {
    await clearState(createProjectDispatch);
    navigate(`${baseUrl}`, { replace: true });
  };

  const saveAndLeave = async () => {
    let formData = new FormData();
    for (var i of createProjectState.attachments) {
      formData.append('attachments', i);
    }
    formData.append('title', createProjectState.projectTitle);
    formData.append('problemStatement', createProjectState.problemStatement);
    formData.append('linkedProject', createProjectState.linkedProjectId);
    formData.append('link', createProjectState.link);
    formData.append('profilePicture', createProjectState.projectAvatar);
    formData.append('solution', createProjectState.solution);
    if (type === TYPES.ENTERPRISE) {
      const deptId = id ? id : departmentId;
      formData.append('departmentId', deptId);
    }
    formData.append('projectStatus', 'DRAFT');
    formData.append(
      'projectType',
      type === TYPES.ENTERPRISE ? 'ENTERPRISE' : 'PERSONAL'
    );
    for (var j of createProjectState.linkedTeams) {
      formData.append('teamId', j);
    }
    if (isDraft) {
      for (var f of createProjectState.linkAttachments) {
        formData.append('linkAttachments', f);
      }
      for (var k of createProjectState.deletedTeams) {
        formData.append('deleteTeamId', k);
      }
    }

    const url = isDraft
      ? `${UPDATE_DRAFT_PROJECT}/${draftProjectId}`
      : CREATE_PROJECT;

    const res = await fnWrapper({
      url: url,
      method: 'POST',
      type: 'CREATE_PROJECT',
      payload: formData,
    });

    if (res.data.statusCode === 200) {
      await clearState(createProjectDispatch);
      navigate(`${baseUrl}`, { replace: true });
    }
  };

  const getEnterpriseTeams = useCallback(async () => {
    const deptId = id ? id : departmentId;
    const res = await fnWrapper({
      url: `${FETCH_TEAMS_BASED_ON_ENTERPRISE}/${deptId}`,
      method: 'GET',
      type: 'FETCH_TEAMS_BASED_ON_ENTERPRISE',
    });

    if (res.data.statusCode === 200) {
      let enterpriseTeamList = res.data.data
        .map((team) => ({
          id: team.id,
          createdBy: {
            id:
              team.teamCategory === 'ENTERPRISE'
                ? team?.enterprise?.id
                : team?.user?.id,
            name:
              team.teamCategory === 'ENTERPRISE'
                ? team?.enterprise?.enterpriseName
                : team?.user?.individual?.name,
          },
          description: team.description,
          name: team.teamName,
          projectImgSrc: '',
          userType: team.teamCategory,
        }))
        .filter(
          (team) =>
            !(
              team?.acceptLinkRequest === 'ENTERPRISE' &&
              !userEnterpriseList.includes(team?.createdBy?.id)
            )
        );
      setCustomData(enterpriseTeamList);
      setFilteredData(enterpriseTeamList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getExistingTeams = useCallback(async () => {
    const res = await fnWrapper({
      url: FETCH_EXISTING_TEAMS,
      method: 'GET',
      type: 'FETCH_EXISTING_TEAMS',
    });

    if (res.data.statusCode === 200) {
      let existingTeamList = res.data.data
        ?.map((team) => ({
          id: team.id,
          acceptLinkRequest: team.acceptLinkRequest,
          createdBy: {
            id:
              team.teamCategory === 'ENTERPRISE'
                ? team?.enterprise?.id
                : team?.user?.id,
            name:
              team.teamCategory === 'ENTERPRISE'
                ? team?.enterprise?.enterpriseName
                : team?.user?.individual?.name,
          },
          description: team.description,
          name: team.teamName,
          projectImgSrc: '',
          userType: team.teamCategory,
        }))
        .filter((team) => team.acceptLinkRequest !== 'NO_ONE');
      setCustomData(existingTeamList);
      setFilteredData(existingTeamList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserEnterpriseList = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_ENTERPRISE_LIST}/${userId}`,
      method: 'GET',
      type: 'FETCH_ENTERPRISE_LIST',
    });

    if (res?.data?.statusCode === 200) {
      let list = res?.data?.data?.items?.map((item) => {
        return item.id;
      });
      setUserEnterpriseList(list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLinkedProjectTeams = useCallback(async () => {
    if (createProjectState.linkedProjectId !== '') {
      const res = await fnWrapper({
        url: `${FETCH_TEAMS_BASED_ON_PROJECT}/${createProjectState.linkedProjectId}`,
        method: 'GET',
        type: 'FETCH_TEAMS_BASED_ON_PROJECT',
      });

      if (res.data.statusCode === 200) {
        let linkedProjectTeamList = res.data.data[0].teams?.map((team) => ({
          id: team.id,
          createdBy: {
            id:
              team.teamCategory === 'ENTERPRISE'
                ? team?.enterprise?.id
                : team?.user?.id,
            name:
              team.teamCategory === 'ENTERPRISE'
                ? team?.enterprise?.enterpriseName
                : team?.user?.individual?.name,
          },
          description: team.description,
          name: team.teamName,
          projectImgSrc: '',
          userType: team.teamCategory,
        }));
        setCustomData(linkedProjectTeamList);
        setFilteredData(linkedProjectTeamList);
      }
    } else {
      setCustomData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPublicTeams = useCallback(async () => {
    const res = await fnWrapper({
      url: FETCH_PUBLIC_TEAMS,
      method: 'GET',
      type: 'FETCH_PUBLIC_TEAMS',
    });

    if (res.data.statusCode === 200) {
      let publicTeamList = res.data.data?.map((team) => ({
        id: team.id,
        acceptLinkRequest: team.acceptLinkRequest,
        createdBy: {
          id:
            team.teamCategory === 'ENTERPRISE'
              ? team?.enterprise?.id
              : team?.user?.id,
          name:
            team.teamCategory === 'ENTERPRISE'
              ? team?.enterprise?.enterpriseName
              : team?.user?.individual?.name,
        },
        description: team.description,
        name: team.teamName,
        projectImgSrc: '',
        userType: team.teamCategory,
      }));
      setCustomData(
        publicTeamList.filter((team) => team.acceptLinkRequest === 'ALL_USERS')
      );
      setFilteredData(
        publicTeamList.filter((team) => team.acceptLinkRequest === 'ALL_USERS')
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLinkedTeamsList = useCallback(async (projId) => {
    const res = await fnWrapper({
      url: `${FETCH_PROJECT_SERVICES}/${projId}?st=linkedTeams`,
      method: 'GET',
      type: 'FETCH_LINKED_TEAMS',
    });

    if (res.data.statusCode === 200) {
      const result = res.data.data.data[0];
      let linkedTeamsList = [];
      result.teams.map((team) => {
        const item = {
          id: team.TeamProject.teamId,
          teamName: team.teamName,
          teamImg: '',
          openCard: true,
        };
        linkedTeamsList.push(item);
        return linkedTeamsList;
      });
      setProjectDetails(
        createProjectDispatch,
        'selectedTeams',
        linkedTeamsList
      );
      const linkedTeamsIds = linkedTeamsList.map((team) => {
        return team.id;
      });
      setProjectDetails(createProjectDispatch, 'linkedTeams', linkedTeamsIds);
      setPreviousLinkedTeams(linkedTeamsIds);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRadioButtons = (e) => {
    const { value } = e.target;
    setSearchTeam('');
    setRadioType(value);
    if (value === '1') {
      if (activeTab === INVITE_TEAM_TAB_LIST[0]) {
        getLinkedProjectTeams();
      } else {
        getPublicTeams();
      }
    } else if (value === '2') {
      getEnterpriseTeams();
    } else if (value === '3') {
      getExistingTeams();
    }
  };

  const handleInviteTeam = async (team) => {
    await setProjectDetails(createProjectDispatch, 'isEdited', true);
    const newArray = [...createProjectState.linkedTeams, team.id];
    await setProjectDetails(createProjectDispatch, 'linkedTeams', newArray);
    const temp = {
      id: team.id,
      teamName: team.name,
      teamImg: team.projectImgSrc,
      openCard: true,
    };
    const invitedTeamArray = [...createProjectState.selectedTeams, temp];
    await setProjectDetails(
      createProjectDispatch,
      'selectedTeams',
      invitedTeamArray
    );
  };

  const removeHandler = async (teamId, isApproved = true) => {
    if (!isApproved) {
      setWarningPopup({
        warningOpen: true,
        teamId: teamId,
      });
    } else {
      await setProjectDetails(createProjectDispatch, 'isEdited', true);
      const newState = createProjectState.selectedTeams.filter(
        (team) => team.id !== teamId
      );
      await setProjectDetails(createProjectDispatch, 'selectedTeams', newState);
      const teamsArr = newState.map((team) => {
        return team.id;
      });
      await setProjectDetails(createProjectDispatch, 'linkedTeams', teamsArr);
    }
  };

  const continueHandler = async () => {
    setWarningPopup({
      ...warningPopup,
      warningOpen: false,
    });
    let deleted = previousLinkedTeams.filter(
      (teamId) => teamId === warningPopup.teamId
    );
    let reduced = previousLinkedTeams.filter(
      (teamId) => teamId !== warningPopup.teamId
    );
    setPreviousLinkedTeams(reduced);
    await setProjectDetails(createProjectDispatch, 'deletedTeams', [
      ...createProjectState.deletedTeams,
      ...deleted,
    ]);
    removeHandler(warningPopup.teamId, true);
  };

  const nextDisabled = createProjectState.linkedTeams.length === 0;

  useEffect(() => {
    if (action && action === 'edit') {
      getLinkedTeamsList(projectId);
    } else if (isDraft) {
      getLinkedTeamsList(draftProjectId);
    }
  }, [getLinkedTeamsList, action, isDraft, projectId, draftProjectId]);

  useEffect(() => {
    getLinkedProjectTeams();
  }, [getLinkedProjectTeams]);

  useEffect(() => {
    getUserEnterpriseList();
    if (createProjectState.progressStep === 1) {
      if (action && action === 'edit') {
        navigate(`${baseUrl}/${projectId}/edit/details`, { replace: true });
      } else {
        navigate(`${baseUrl}/create/details`, { replace: true });
      }
    } else {
      setProgressStep(createProjectDispatch, 3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AddTeamWrapper>
      <div className="team-container">
        <div className="status-container">
          {createProjectState.selectedTeams.length !== 0 &&
            createProjectState.selectedTeams.map((team, index) => (
              <StatusCard
                key={index}
                cardId={team.id}
                cardImg={team.teamImg}
                isImg={team.teamImg !== null && team.teamImg?.length !== 0}
                iconName="people"
                cardTitle={team.teamName}
                isCardOpen={team.openCard}
                onRemove={(id) =>
                  removeHandler(id, !previousLinkedTeams.includes(id))
                }
              />
            ))}
        </div>
        <RadioGroup
          currChecked={radioType}
          labels={radioLabels}
          handleChange={handleRadioButtons}
        />

        {radioType === '1' && type !== TYPES.ENTERPRISE && (
          <div className="mb-5">
            <TabGroup
              tabLists={INVITE_TEAM_TAB_LIST}
              tabClick={tabSwitchHandler}
              activeTab={activeTab}
            />
          </div>
        )}
        <div className="row">
          <div className={'col-lg-12'}>
            <SearchField
              searchField={searchTeam}
              onChangeSearchHandler={searchTeamByNameHandler}
              closeSearchHandler={closeSearchTeamByName}
              placeholder="Search by Team Name"
            />
          </div>
        </div>
        {context.busy.status ? (
          <Loader isLoading={context.busy.status} />
        ) : customData.length === 0 ? (
          <div className="m-5 p-4 text-center">
            {createProjectState.linkedProjectId === '' &&
            radioType === '1' &&
            activeTab === INVITE_TEAM_TAB_LIST[0]
              ? `Looks like you didn't link any project!`
              : `No teams available!`}
          </div>
        ) : (
          <>
            {searchTeam !== '' && filteredData.length === 0 ? (
              <div className="no-team">
                <p>
                  Oh, looks like you don’t have any Team's name starting by
                  <strong>{` ${searchTeam}.`}</strong>
                </p>
              </div>
            ) : (
              <ScrollableDiv scrollOnHeight="320px" scrollOnHeightMob="500px">
                <div className="cards-container">
                  {filteredData.map((item, index) => {
                    return (
                      <div className="card-wrapper" key={index}>
                        <ProjectCard
                          item={item}
                          buttonLabel={
                            previousLinkedTeams.includes(item.id)
                              ? 'Added'
                              : createProjectState.linkedTeams.includes(item.id)
                              ? 'Invited'
                              : 'Invite Team'
                          }
                          btnLeftIcon
                          btnTextColor={
                            previousLinkedTeams.includes(item.id)
                              ? '#ffffff'
                              : '#0abd00'
                          }
                          btnIconName={
                            previousLinkedTeams.includes(item.id)
                              ? 'tickIcon'
                              : 'add'
                          }
                          isRequestDisabled={createProjectState.linkedTeams.includes(
                            item.id
                          )}
                          requestHandler={() => handleInviteTeam(item)}
                          viewHandler={() =>
                            window.open(`/public/team/${item.id}/details`)
                          }
                          type={'Team'}
                        />
                      </div>
                    );
                  })}
                </div>
              </ScrollableDiv>
            )}
          </>
        )}

        <div className="bottom-buttons">
          <CustomButton
            label="Close"
            variant="textBtn"
            color="themeSecondary"
            onClick={onCloseHandler}
          />
          <CustomButton
            className="CustomBtnAddTeams"
            label="Next"
            color="themeSecondary"
            disabled={nextDisabled}
            onClick={async () => {
              await setProgressStep(createProjectDispatch, 4);
              if (action && action === 'edit') {
                navigate(`${baseUrl}/${projectId}/edit/streams`);
              } else {
                navigate(`${baseUrl}/create/streams`);
              }
            }}
          />
        </div>
      </div>
      {isDraftPopupOpen && (
        <ModalPopup
          open={false}
          onClose={() => setIsDraftPopupOpen(false)}
          hasCloseIcon>
          <p className="text-center mt-5 w-75 mx-auto">
            You have some unsaved changes. Do you want to save it as a draft or
            leave?
          </p>
          <div className="draft-buttons mx-auto d-flex flex-sm-row flex-column justify-content-evenly align-items-center my-5">
            <CustomButton
              label="Leave"
              variant="outline"
              onClick={leaveWithoutSave}
              color="themeTertiary"
              customPadding="16px 52px"
            />
            <CustomButton
              loading={context.busy.status}
              variant="outline"
              label="Save as Draft"
              onClick={saveAndLeave}
              customPadding="16px 30px"
            />
          </div>
        </ModalPopup>
      )}
      {warningPopup.warningOpen && (
        <ModalPopup
          open={false}
          onClose={() =>
            setWarningPopup({ ...warningPopup, warningOpen: false })
          }
          hasCloseIcon>
          <p className="text-center mt-5 w-75 mx-auto">
            This team is already linked to your project. Removing this team will
            delete it. Would you like to continue?
          </p>
          <div className="draft-buttons mx-auto d-flex flex-sm-row flex-column justify-content-evenly align-items-center my-5">
            <CustomButton
              variant="outline"
              label="Cancel"
              onClick={() =>
                setWarningPopup({ ...warningPopup, warningOpen: false })
              }
              color="themeOrange"
              customPadding="16px 30px"
            />
            <CustomButton
              loading={context.busy.status}
              variant="outline"
              label="Continue"
              onClick={continueHandler}
              customPadding="16px 30px"
            />
          </div>
        </ModalPopup>
      )}
    </AddTeamWrapper>
  );
};
