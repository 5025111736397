import React from 'react';
import { InfoModal } from '../Components/InfoModal';
import { useNavigate } from 'react-router-dom';

export const PricingPage = () => {
  const navigate = useNavigate();

  const handleCloseModal = () => {
    navigate('/');
  };

  return (
    <InfoModal
      open={true}
      title="Unlock Free Beta Access to miHint!"
      onClose={handleCloseModal}
      content="Join our exclusive beta program for free and discover powerful collaboration tools to fuel your ideas, teams, and projects. Limited spots available – sign up now!"
    />
  );
};
