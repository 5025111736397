import styled from 'styled-components';

export const ManageBoardWrapper = styled.div`
  .manage-board {
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title {
        font-weight: 500;
        margin-top: 10px;
        font-size: 20px;
        color: #404f67;
      }
      .add-field {
        svg {
          cursor: pointer;
        }
      }
    }
    &__body {
      margin-bottom: 50px;
    }
    &__footer {
      margin: 0 auto;
      width: 150px;
    }
  }
`;
