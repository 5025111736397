import { initialState } from './createGroupContext';
export const CreateGroupReducer = (state, action) => {
  switch (action.type) {
    case 'SET_GROUP_INFO':
      return {
        ...state,
        [action.payload1]: action.payload2,
      };
    case 'ADD_GROUP_QUESTIONS':
      return {
        ...state,
        questions: [...state.questions, { question: '' }],
      };
    case 'DELETE_GROUP_QUESTION':
      return {
        ...state,
        questions: state.questions.filter(
          (val, index) => index !== action.index
        ),
      };
    case 'SET_GROUP_QUESTION':
      return {
        ...state,
        [state.questions]: (state.questions[action.index].question =
          action.value),
      };
    case 'CLEAR_STATE':
      return initialState;
    case 'SET_GROUP_INFO_ON_UPDATE':
      return {
        ...state,
        groupName: action.info.groupName,
        description: action.info.description,
        category: {
          label: action.info.category,
          value: action.info.category,
        },
        groupType: action.info.groupType,
        groupProfilePicture: action.info.profilePicture,
        questions: action.info.question,
        userId: action.info.userId,
      };

    default:
      return state;
  }
};
