export const setLoading = (dispatch, status) =>
  dispatch({ type: 'SET_LOADING', payload: status });

export const setPersonalTeamRBAC = async (dispatch, res) => {
  await setLoading(dispatch, true);
  await dispatch({
    type: 'SET_PERSONAL_TEAM_RBAC',
    payload1: 'personalTeamRBAC',
    payload2: res,
  });
  await setLoading(dispatch, false);
};

export const setProjectRBAC = async (dispatch, res) => {
  await setLoading(dispatch, true);
  await dispatch({
    type: 'SET_PROJECT_RBAC',
    payload1: 'projectRBAC',
    payload2: res,
  });
  await setLoading(dispatch, false);
};

export const clearState = async (dispatch) => {
  await dispatch({
    type: 'CLEAR_STATE',
  });
};
