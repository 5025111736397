import { useState } from 'react';
import cn from 'classname';
import 'react-phone-number-input/style.css';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import './styles.scss';
import { FLAG_URL } from 'utlils/utils';

export const PhoneNumberInputBox = ({
  label,
  placeholder,
  required = false,
  value = '',
  onBlur,
  onChange,
  disabled = false,
  hasError = false,
  errorMessage,
  sm,
}) => {
  const [showError, setShowError] = useState(false);

  const onBlurHandler = () => {
    // Run custom logic passed to input on blur
    typeof onBlur === 'function' && onBlur();
    // Show error to trigger error evaluation logic
    setShowError(true);
  };

  /**
   * @author Kiran Ranjan Bihari <kiran@brenolabs.com>
   * Initialize is_error only when showError is true
   * To avoid showing on error when component mounts
   * And show only after focus blurs from the input
   * If we need extra logic, we can restructure this component later💯
   */
  const isErrors =
    // check if dynamic error state passed from parent component
    hasError ||
    // if not then proceed with internal error logic💪
    (showError ? required && !isValidPhoneNumber(String(value)) : false);

  return (
    <div className="phone-custom-input">
      <label className="form-label label-style">{`${label}`}</label>
      {required ? <span className="required">*</span> : ''}
      <div
        className={cn(
          'input-container',
          sm ? 'sm' : 'lg',
          isErrors && 'error-input',
          disabled && 'disabled'
        )}>
        <PhoneInput
          className={'phone_number__input'}
          // defaultCountry="GB"
          flagUrl={FLAG_URL}
          // countries={whitelist}
          international
          value={value}
          onChange={onChange}
          onBlur={onBlurHandler}
        />
      </div>
      {(showError || isErrors) && (
        <small className="has-error">{errorMessage}</small>
      )}
    </div>
  );
};
