import React, { useEffect, useCallback, useState } from 'react';
import { Sidebar, NavbarBrand1 } from 'components';
import { EnterpriseSettingInfoData, SettingInfoData } from './constants';
import { getProfileText } from 'utlils/utils';
import tokenService from 'services/token.service';
import { FETCH_USER_PROFILE, useAxiosWrapper } from 'services';
import { Routes, Route, useParams } from 'react-router';
import { IndividualSettingsWrapper } from './style';
import { ChangePassword } from './AccountSetting/ChangePassword';
import { DeactivateAccount } from './AccountSetting/DeactivateAccount';
import { Membership } from './Membership';
import { LinkedAccounts } from './LinkedAccounts';
import { INDIVIDUAL_DASHBOARD_NAV_LIST } from 'pages/IndividualDashboard/constants';
import { ENTERPRISE_DASHBOARD_NAV_LIST } from 'pages/enterpriseDashboard/constants';
import { BlockedAccounts } from './IndividualPrivacy/BlockedAccounts';
import { ProfileVisibility } from './IndividualPrivacy/ProfileVisibility';
import { EnterprisePrivacy } from './EnterpriseSettings/Privacy';
import { Services } from './EnterpriseSettings/Services';

export const Settings = (props) => {
  const params = useParams();
  const [leftActiveItem, setLeftActiveItem] = useState('');
  const [userProfile, setUserProfile] = useState({});
  const [fnWrapper] = useAxiosWrapper();
  const { currentLocale, handleLocaleChange } = props;
  const loggedInUser = tokenService.getSession();

  const isChangePasswordEnabled = loggedInUser.signupType === 'EMAIL';
  const isIndividualDashboard = params.dashboardType === 'individual';

  // fetch userprofile
  const getUserProfile = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_USER_PROFILE}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_USER_PROFILE',
    });
    if (res.data.statusCode === 200) {
      const response = res.data.data;
      setUserProfile({
        ...response,
        profileText: getProfileText(
          response?.role === 'INDIVIDUAL'
            ? response?.name
            : response?.enterpriseName
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUserProfile();
  }, [getUserProfile]);

  const setLeftSidebar = (type) => {
    setLeftActiveItem(type);
  };

  return (
    <>
      <IndividualSettingsWrapper>
        <NavbarBrand1
          navMenu={
            isIndividualDashboard
              ? INDIVIDUAL_DASHBOARD_NAV_LIST(userProfile?.role)
              : ENTERPRISE_DASHBOARD_NAV_LIST
          }
          currentLocale={currentLocale}
          handleLocaleChange={handleLocaleChange}
          userProfile={userProfile}
        />

        <div className="settings-dashboard-container">
          <div className="layout-container">
            <div className="layout-sub-container">
              <div className="sub-container-child">
                <div style={{ marginTop: '1.5rem' }}>
                  <Sidebar
                    sidebarData={
                      isIndividualDashboard
                        ? SettingInfoData(isChangePasswordEnabled)
                        : EnterpriseSettingInfoData
                    }
                    activeType={leftActiveItem}
                    setActiveType={setLeftSidebar}
                  />
                </div>
              </div>
              <div className="sub-container-child">
                <div className="main-wrapper">
                  <Routes>
                    <Route path="">
                      <Route
                        element={<ChangePassword />}
                        path="/account-setting/change-password"
                      />
                      <Route
                        element={<DeactivateAccount />}
                        exact
                        path="/account-setting/deactivate-account"
                      />
                      <Route
                        element={<Membership />}
                        exact
                        path="/membership"
                      />
                      <Route
                        element={<ProfileVisibility />}
                        exact
                        path="/privacy/profile-visibility"
                      />
                      <Route
                        element={<BlockedAccounts />}
                        exact
                        path="/privacy/blocked-accounts"
                      />
                      <Route
                        element={<LinkedAccounts />}
                        exact
                        path="/linked-accounts"
                      />
                      <Route
                        element={<EnterprisePrivacy />}
                        exact
                        path="/privacy"
                      />
                      <Route element={<Services />} exact path="/services" />
                    </Route>
                  </Routes>
                </div>
              </div>
            </div>
          </div>
        </div>
      </IndividualSettingsWrapper>
    </>
  );
};
