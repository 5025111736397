import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import teamsListIcon from '../../../../../assests/images/teamApproveListIcon.svg';
import TeamApprovalNextPopup from '../teams/teamApprovalNextPopup';
import './teams.scss';
import { ENTERPRISETEAMTABLEHEADERS } from '../../../constants';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { CreateTeamModal } from '../teams/createTeamModal';
import DepartmentTeamFilter from '../teams/departmentTeamFilter.js';
import dayjs from 'dayjs';
import { getPlaceholderItems } from '../../../../../utlils/utils';
import { isMobile } from '../../../../../utlils/isMobile';
import { FETCH_PENDING_DEPARTMENT_TEAMS, useAxiosWrapper } from 'services';
import { getDateTime } from 'utlils/utils';
import {
  Box,
  CustomTable,
  CustomButton,
  Input,
  ModalPopup,
  NavigationalRowItem,
  ScrollableDiv,
} from 'components';
import createInitialTeams from '../../../../../assests/images/createInitialTeams.png';
import { TeamListAction } from 'pages/IndividualDashboard/IndividualPersonalSection/individualTeams/helper';
import Icon from 'utlils/Icons/Icon';
import Loader from 'react-spinners/FadeLoader';

const DEFAULT_APPROVE_TASK_STATE = {
  isModalOpen: false,
  isLoading: false,
};

const DEFAULT_APPROVE_TASK_NEXT_PAGE_STATE = {
  isModalOpen: false,
  isLoading: false,
};

const DEFAULT_CREATEPOPUP_STATE = {
  isModalOpen: false,
  isLoading: false,
};
const DEFAULTFILTERSTATE = {
  admin: '',
  noOfMembers: {},
  fromDate: null,
  toDate: null,
};

const Teams = () => {
  const [approveTask, setApproveTask] = useState(DEFAULT_APPROVE_TASK_STATE);
  const [searchState, setSearchState] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [createTeamModal, setCreateTeamModal] = useState(
    DEFAULT_CREATEPOPUP_STATE
  );
  const [approveTaskNextPage, setApproveTaskNextPage] = useState(
    DEFAULT_APPROVE_TASK_NEXT_PAGE_STATE
  );
  const [filteredDepartmentTeam, setFilteredDepartmentTeam] =
    useState(DEFAULTFILTERSTATE);
  const [teamApprovalList, setTeamApprovalList] = useState([]);
  const [teamId, setTeamId] = useState('');
  const [userId, setUserId] = useState('');
  let { departmentId } = useParams();
  const [fnWrapper, context] = useAxiosWrapper();

  const getTeamsBasedOnDepartment = useCallback(async () => {
    const session = JSON.parse(localStorage.getItem('session'));
    setIsLoading(true);
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/getTeamsByDepartmentId/${departmentId}`,
          {
            headers: {
              'x-access-token': `${session.access_token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.statusCode === 200) {
            let tableData = res.data.data.teams.reduce((newArray, ele) => {
              let date = new Date(ele.createdAt);
              const options = {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              };
              const locale = navigator.languages;
              let adminsList = ele.teamMembers.reduce((array, ele) => {
                if (ele.role === 'ADMIN') {
                  array.push(ele.memberName);
                }
                return array;
              }, []);
              let countAcceptedTeamMembers = ele?.teamMembers.filter(
                (member) => member.isActive === 1
              );
              let modifiedData = {
                teamName: (
                  <NavigationalRowItem
                    label={ele.teamName}
                    navigateTo={`/public/team/${ele.id}/details`}
                    isOpenInNewTab
                    isIndividualUser={true}
                    customStyle={{ color: '#404f67' }}
                  />
                ),
                createdOn: date.toLocaleDateString(locale, options),
                admin: adminsList.join(','),
                members: countAcceptedTeamMembers.length,
                team_name: ele.teamName,
                action: (
                  <TeamListAction
                    teamId={ele.id}
                    role={'ADMIN'}
                    type="enterprise"
                    status={'4'}
                  />
                ),
              };
              newArray.push(modifiedData);
              return newArray;
            }, []);
            setTableData(tableData);
            setFilteredData(tableData);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          toast.error(err, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
          setIsLoading(false);
        });
    } catch (error) {
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      setIsLoading(false);
    }
  }, [departmentId]);

  const getPendingDepartmentTeamsBasedOnDepartmentId = async () => {
    let data = [];
    const res = await fnWrapper({
      url: `${FETCH_PENDING_DEPARTMENT_TEAMS}/${departmentId}`,
      method: 'GET',
      type: 'FETCH_PENDING_DEPARTMENT_TEAM',
    });

    if (res.data.statusCode === 200) {
      if (res?.data?.data?.length > 0) {
        res?.data?.data?.map((item) =>
          data.push({
            teamName: item?.teamName,
            userName: item?.user?.individual?.name,
            createdAt: item?.createdAt,
            teamId: item?.id,
            userId: item?.userId,
          })
        );
        setTeamApprovalList(data);
      }
    }
  };

  const handleApproveTeamBackBtn = () => {
    setApproveTaskNextPage({
      ...approveTaskNextPage,
      isModalOpen: false,
    });
    setApproveTask({
      ...approveTask,
      isModalOpen: true,
    });
  };

  const openTeamApprovalModalHandler = async () => {
    await getPendingDepartmentTeamsBasedOnDepartmentId();
    setApproveTask({
      ...approveTask,
      isModalOpen: true,
    });
  };

  const handleDepTeamUpdate = async () => {
    // Update the table data by fetching the latest data from the server
    await getTeamsBasedOnDepartment();
  };

  const openTaskAprrovalModalNextPageHandler = (teamId, userId) => {
    setTeamId(teamId);
    setUserId(userId);
    setApproveTask({
      ...approveTask,
      isModalOpen: false,
    });
    setApproveTaskNextPage({
      ...approveTaskNextPage,
      isModalOpen: true,
    });
  };

  const handleNextPopup = (value) => {
    setApproveTaskNextPage({
      ...approveTaskNextPage,
      isModalOpen: value,
    });
  };

  const onSearchHandler = (value) => {
    setSearchState(value);
    if (value !== '' || value.length !== 0) {
      let data = tableData.filter(
        (ele) => ele.team_name.toLowerCase().indexOf(value.toLowerCase()) > -1
      );
      setFilteredData(data);
    } else {
      getTeamsBasedOnDepartment();
    }
  };

  const onCloseHandler = () => {
    getTeamsBasedOnDepartment();
    setCreateTeamModal({
      ...createTeamModal,
      isModalOpen: false,
      isLoading: false,
    });
  };
  const teamFilterOnChangeHandler = (field = '', value = null) => {
    setFilteredDepartmentTeam({
      ...filteredDepartmentTeam,
      [field]: value,
    });
  };
  const teamFilterSaveHandler = (type) => {
    if (type === 'apply' && tableData.length !== 0) {
      setSearchState('');
      let result = tableData;
      const filterByAdmin = (item) => {
        if (filteredDepartmentTeam.admin.length !== 0) {
          return item.filter((ele) =>
            ele.admin.split(',').includes(filteredDepartmentTeam.admin)
          );
        } else {
          return item;
        }
      };

      const filterByNoOfMembers = (item) => {
        if (Object.keys(filteredDepartmentTeam.noOfMembers).length !== 0) {
          return item.filter((ele) => {
            if (filteredDepartmentTeam.noOfMembers.value === '1') {
              return ele.members < 10;
            } else if (filteredDepartmentTeam.noOfMembers.value === '2') {
              return ele.members >= 10 && ele.members <= 50;
            } else if (filteredDepartmentTeam.noOfMembers.value === '3') {
              return ele.members > 50;
            } else {
              return ele;
            }
          });
        } else {
          return true;
        }
      };

      const filterByDate = (item) => {
        if (
          filteredDepartmentTeam.fromDate !== null &&
          filteredDepartmentTeam.toDate !== null
        ) {
          return item.filter(
            (ele) =>
              new Date(dayjs(ele.createdOn).format('DD MMM YYYY')) >=
                new Date(
                  dayjs(filteredDepartmentTeam.fromDate).format('DD MMM YYYY')
                ) &&
              new Date(dayjs(ele.createdOn).format('DD MMM YYYY')) <=
                new Date(
                  dayjs(filteredDepartmentTeam.toDate).format('DD MMM YYYY')
                )
          );
        } else {
          return item;
        }
      };

      if (filteredDepartmentTeam.admin.length !== 0) {
        result = filterByAdmin(result);
      }
      if (
        Object.keys(filteredDepartmentTeam.noOfMembers).length !== 0 &&
        filteredDepartmentTeam.noOfMembers.value !== ''
      ) {
        result = filterByNoOfMembers(result);
      }
      if (
        filteredDepartmentTeam.fromDate !== null &&
        filteredDepartmentTeam.toDate !== null
      ) {
        result = filterByDate(result);
      }
      setFilteredData(result);
      setIsFilterOpen(false);
    } else if (type === 'clear') {
      setFilteredDepartmentTeam(DEFAULTFILTERSTATE);
      getTeamsBasedOnDepartment();
    } else {
      setIsFilterOpen(false);
      getTeamsBasedOnDepartment();
    }
  };
  const allTeamsData = isLoading
    ? getPlaceholderItems(
        ENTERPRISETEAMTABLEHEADERS.map(({ sortKey }) => sortKey),
        4,
        isLoading
      )
    : filteredData;

  useEffect(() => {
    getTeamsBasedOnDepartment();
  }, [getTeamsBasedOnDepartment]);

  return (
    <div className="view-teams-wrapper">
      <div className="header">
        <div className="search-wrapper">
          <Input
            height="0"
            iconLeft={searchState.length === 0}
            iconName="search"
            iconRight={searchState.length !== 0}
            iconRightName="cross"
            placeholder="Search Teams"
            value={searchState}
            onChange={(e) => onSearchHandler(e.target.value)}
            iconRightClassName="close-search-team"
            iconRightOnClickHandler={() => {
              setSearchState('');
              setFilteredData(tableData);
            }}
          />
        </div>
        <CustomButton
          onClick={() => setIsFilterOpen(!isFilterOpen)}
          color={isFilterOpen ? 'themeSecondary' : 'themeGray'}
          variant="light">
          <Icon
            name="filter"
            color={isFilterOpen ? 'rgba(33, 159, 255, 1)' : '#CCD2DD'}
            size={18}
          />
        </CustomButton>
        <CustomButton
          label="Team Approvals"
          onClick={openTeamApprovalModalHandler}
          variant={'light'}
        />
        <CustomButton
          onClick={() => {
            setCreateTeamModal({
              ...createTeamModal,
              isModalOpen: true,
              isLoading: true,
            });
          }}>
          <Icon name="add" color="#ffffff" size={18} />
          Create team
        </CustomButton>
      </div>
      {/* filter team */}
      {isFilterOpen && (
        <>
          <DepartmentTeamFilter
            filterTitle="Filter Users"
            state={filteredDepartmentTeam}
            teamFilterSaveHandler={teamFilterSaveHandler}
            teamFilterOnChangeHandler={teamFilterOnChangeHandler}
          />
        </>
      )}
      <div>
        {!isLoading && tableData.length !== 0 ? (
          <CustomTable
            isLoading={isLoading}
            rows={allTeamsData}
            headers={ENTERPRISETEAMTABLEHEADERS}
            hasCheckbox={false}
          />
        ) : (
          <>
            <Box
              mt="50px"
              display="flex"
              justifyContent="center"
              alignItems="center">
              <img
                src={createInitialTeams}
                alt=""
                width={isMobile() ? '100%' : '320px'}
                style={{ objectFit: 'cover' }}
              />
            </Box>
            <Box
              mt="30px"
              display="flex"
              textAlign="center"
              justifyContent="center">
              <Box width="70%">
                <span>
                  Oh, looks like you don’t have any Team. Create your own Team
                  now!
                </span>
              </Box>
            </Box>
            <Box mt="30px" mb="30px" display="flex" justifyContent="center">
              <CustomButton
                onClick={() => {
                  setCreateTeamModal({
                    ...createTeamModal,
                    isModalOpen: true,
                    isLoading: true,
                  });
                }}>
                <Icon name="add" color="#ffffff" size={18} />
                Create team
              </CustomButton>
            </Box>
          </>
        )}
        {searchState !== '' && filteredData.length === 0 && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            color="#929DAF">
            <p>
              Oh, looks like you don’t have any Team's name starting by
              <strong>{` ${searchState}.`}</strong>
            </p>
          </Box>
        )}
      </div>
      {createTeamModal.isModalOpen && (
        <CreateTeamModal
          createTeamModal={createTeamModal}
          setCreateTeamModal={setCreateTeamModal}
          onCloseHandler={onCloseHandler}
        />
      )}
      {approveTask.isModalOpen && (
        <ModalPopup
          open={false}
          onClose={() =>
            setApproveTask({
              ...approveTask,
              isModalOpen: false,
            })
          }
          title="Team Approvals"
          hasTitle
          hasCloseIcon
          minWidth="650px"
          maxContentWidth="650px">
          {context.busy.status ? (
            <div className=" main-wrapper d-flex justify-content-center align-items-center w-100">
              <Loader loading={context.busy.status} />
            </div>
          ) : teamApprovalList.length > 0 ? (
            <ScrollableDiv>
              {teamApprovalList.map((item, index) => (
                <TeamApprovalContainer key={index}>
                  <div className="team-approve_list-item">
                    <div className="team-approve_list-text-container">
                      <div className="team-approve_list-text-container-img">
                        <img
                          src={teamsListIcon}
                          alt="team-approval-list-icon"
                        />
                      </div>
                      <div className="team-approve_list-text-container-content">
                        <div className="team-approve_list-item__text__para">
                          <span>{item.userName}</span> requested you to approve
                          Team <span>{item.teamName}</span>
                        </div>
                        <div className="team-approve_list-item__time">
                          {getDateTime(item.createdAt)}
                        </div>
                      </div>
                    </div>
                    <div className="team-approve_list-item__btn ">
                      <CustomButton
                        className="text-decoration-underline p-0"
                        label="View team"
                        variant="textBtn"
                        color="themeSecondary"
                        onClick={() =>
                          openTaskAprrovalModalNextPageHandler(
                            item.teamId,
                            item.userId
                          )
                        }
                        loading={approveTaskNextPage.isLoading}
                      />
                    </div>
                  </div>
                </TeamApprovalContainer>
              ))}
            </ScrollableDiv>
          ) : (
            <div className="d-flex align-items-center justify-content-center mt-3">
              <div>
                <p className="no-approvals ">
                  {'Sorry! no request for team approval sent yet !'}
                </p>
                <img src={createInitialTeams} width="320px" alt="noData" />
              </div>
            </div>
          )}
        </ModalPopup>
      )}
      {approveTaskNextPage.isModalOpen && (
        <TeamApprovalNextPopup
          backBtnHandler={handleApproveTeamBackBtn}
          open={approveTaskNextPage}
          handleOpen={handleNextPopup}
          teamId={teamId}
          userId={userId}
          depTeamUpdateHandler={handleDepTeamUpdate}
        />
      )}
    </div>
  );
};

const TeamApprovalContainer = styled.div`
  padding: 15px;
  & .team-approve_list-item {
    display: grid;
    grid-template-columns: 6fr 1fr;
    column-gap: 10px;

    & .team-approve_list-text-container {
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 10px;

      & .team-approve_list-text-container-content {
        & .team-approve_list-item__text__para {
          & span {
            font-weight: 600;
          }
        }
        & .team-approve_list-item__time {
          color: #929daf;
        }
      }

      & .team-approve_list-item__text {
        display: flex;
        & img {
          padding-right: 10px;
        }
      }
    }

    & .team-approve_list-item__btn {
      padding: 0;
      /* gap: 16px; */

      @media (max-width: 400px) {
        flex-direction: column;
      }
    }

    @media (max-width: 1250px) {
      flex-direction: column;
    }
  }
`;

export default Teams;
