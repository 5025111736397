import styled from 'styled-components';

export const DiscussionSectionWrapper = styled.div`
  font-family: Poppins;
  min-width: 300px;
  max-height: 90%;
  background-color: #ffffff;
  align-self: flex-start;
  border-radius: 10px;
  padding: 16px;
  width: 25%;

  display: flex;
  flex-direction: column;
  align-items: center;

  .title-block {
    width: 100%;

    display: flex;
    justify-content: space-between;
    font-size: 16px;
    line-height: 20px;
    text-align: left;

    p:first-child {
      font-weight: 700;
    }
    p:nth-child(2) {
      font-weight: 400;
      font-size: 14px;
      color: #929daf;
    }
  }

  .input-block {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 8px;
    .send-icon {
      color: #219fff;
      height: 30px !important;
      aspect-ratio: 1/1;
    }
  }

  .comments {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding-left: 12px;

    .no-comments {
      font-size: 14px;
      color: #929daf;
      font-weight: 600;
    }
  }
`;

export const CommentWrapper = styled.div`
  background-color: #f7f6ff;
  border-radius: 5px;
  padding-block: 8px;
  padding-inline: 16px;
  min-width: 22vw;
  font-size: 14px;
  text-align: left;
  .comment-dots {
    position: relative;
    display: flex;
    justify-content: flex-end;
    padding-inline: 16px;
    margin-bottom: 8px;
  }
  .comment-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .pfp-and-name {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;

      img {
        width: 35px;
        aspect-ratio: 1/1;
        border-radius: 50%;
      }
      p {
        font-weight: 700;
        line-height: 10.77px;
        align-self: flex-end;
      }
    }
    .time {
      font-family: 'Open Sans';
      font-weight: 400;
      line-height: 24px;
      align-self: flex-end;
    }
  }

  .comment-content {
    font-weight: 400;
    line-height: 25px;
    color: #7e8f9a;
    padding-inline: 8px;
    margin-top: 8px;
  }
`;
