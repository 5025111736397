import styled from 'styled-components';

export const ExploreLayoutWrapper = styled.div`
  .main-container {
    min-height: 100vh;
    background-color: #f4f7fc;
    & .layout-container {
      padding: 50px 8% 20px 8%;
      & .layout-sub-container {
        display: grid;
        grid-template-columns: minmax(190px, 220px) auto;
        grid-gap: 40px;
        @media (max-width: 1199px) {
          display: block;
        }
        & .sub-container-child {
          @media (max-width: 1199px) {
            margin: 25px 0;
          }
          & .main-wrapper {
            border-radius: 12px;
            padding: 30px;
            background-color: white;
            position: relative;
            margin-top: 25px;
          }
          & .no--data {
            padding: 10px;
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            color: #929daf;
            flex-direction: column;
            gap: 30px;
          }
          & .explore-searchBox-container {
            display: grid;
            grid-template-columns: auto minmax(100px, 120px);
            gap: 20px;
            margin-bottom: 20px;
          }
          & .country-filter {
            position: absolute;
            top: 10;
            min-width: 400px;
            min-height: 408px;
            right: 0;
            background: #ffffff;
            border: 1px solid #f4f7fc;
            box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.06);
            border-radius: 12px;
            z-index: 1000;
            padding: 20px;
            .country-filter-button {
              margin-top: 50%;
              div {
                width: 50%;
              }
            }
          }
          & .pagination-wrapper {
            margin-top: 15px;
            display: flex;
            justify-content: center;
            border-radius: 12px;
            background-color: white;
            border-radius: 12px;
            padding: 15px 10px 0 10px;
          }
        }
      }
      @media (max-width: 1600px) {
        padding: 80px 6% 20px 6%;
      }
      @media (max-width: 992px) {
        padding: 80px 4% 20px 4%;
      }
    }
  }
`;
