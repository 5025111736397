import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import './fpEmailInstruction.scss';
import { FORGOT_PASSWORD, useAxiosWrapper } from 'services';
import Icon from 'utlils/Icons/Icon';
import { b64UnicodeDecode } from '../../../helpers';
import { Box, CustomButton, FeedBackFooter, Title } from '../../../components';

const FpEmailInstructions = () => {
  const [fnWrapper, context] = useAxiosWrapper();

  let { email } = useParams();
  const [userEmail, setUserEmail] = useState(null);

  useEffect(() => {
    setUserEmail(b64UnicodeDecode(email));
  }, [email]);

  const resendPasswordResetHandler = async () => {
    try {
      const res = await fnWrapper({
        url: FORGOT_PASSWORD,
        method: 'POST',
        type: 'FORGOT_PASSWORD',
        payload: {
          email: userEmail,
        },
      });
      if (res.data.statusCode === 200) {
        toast.success(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  return (
    <div className="container-fluid email-instruction-container">
      <div className="row">
        <div className="col-lg-7">
          <div className="container">
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-8">
                <Box>
                  {/* logo */}
                  <Box mb="78px" mt="33px">
                    <img
                      src={process.env.PUBLIC_URL + '/new-logo.png'}
                      className="img common-navbar-logo"
                      alt="logo"
                    />
                  </Box>
                  <div className="email-instruction-wrapper">
                    <Title
                      title={'Email Instructions Sent'}
                      subTitle={
                        'Please follow the instructions we sent to your inbox.'
                      }></Title>
                    <p className="user-email">{userEmail}</p>
                    <Box mt="40px">
                      <p className="email-instruction-txt">
                        Did not get a instructions email?
                      </p>
                      <p className="check-spam">Check your spam folder.</p>
                    </Box>
                    <Box width={300} mb="40px" mt="60px">
                      <CustomButton
                        onClick={resendPasswordResetHandler}
                        loading={
                          context.busy.status &&
                          context.busy.type === 'FORGOT_PASSWORD'
                        }>
                        Re-send Reset Instruction
                        <Icon
                          name="icon-right"
                          color={'#FFFFFF'}
                          size={24}
                          className="resetIcon"
                        />
                      </CustomButton>
                    </Box>
                    <FeedBackFooter />
                  </div>
                </Box>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
        </div>
        <div className="col-lg-5 d-none d-sm-none d-lg-block image-wrapper">
          {/* right side image */}
          <div className="right-img"></div>
        </div>
      </div>
    </div>
  );
};

export default FpEmailInstructions;
