import React from 'react';
import { CustomButton } from 'components';
import styled from 'styled-components';

export const AcceptButton = ({ userId, label, setWaitListStatus }) => {
  const acceptWaitlistApproval = () => {
    setWaitListStatus([userId]);
  };

  return (
    <AcceptButtonWrapper>
      <CustomButton
        label={label}
        variant="textBtn"
        onClick={acceptWaitlistApproval}
      />
    </AcceptButtonWrapper>
  );
};

const AcceptButtonWrapper = styled.div`
  text-decoration: underline;
  text-decoration-color: green;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
`;
