import styled from 'styled-components';

export const SelectedConversationWrapper = styled.div`
  .conversation-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px;
    .chats-icon {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: #263238;
      display: grid;
      place-content: center;
      @media (min-width: 992px) {
        display: none;
      }
    }
    .image-heading-container {
      display: flex;
    }
    .Conversation__img {
      @media (max-width: 992px) {
        display: none;
      }
      width: 60px;
      height: 60px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .prof-text-wrapper {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        overflow: hidden;
        border-radius: 50%;
        background-color: #d6f3f9;
        display: flex;
        justify-content: center;
        align-items: center;
        .prof-text {
          font-size: 20px;
          font-weight: 500;
        }
      }
    }

    .conversation-icon {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: #e0e0e0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .chat-options-heading {
      color: #292d32;
      font-family: Poppins;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .conversation-heading-container {
      margin: 0 10px;
      align-self: center;
      .conversation-heading {
        display: flex;
        justify-content: center;
        color: #292d32;
        font-family: Poppins;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .conversation-members {
        text-decoration: underline;
        font-size: 14px;
        cursor: pointer;
        &:hover {
          color: #219fff;
        }
      }
    }
  }
`;
