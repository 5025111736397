import React, { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { ViewJobWrapper } from './style';
import Icon from 'utlils/Icons/Icon';
import { CustomButton, CopyWithIcon, NavigationalRowItem } from 'components';
import { capitalizeFirstLetter } from 'pages/IndividualDashboard/individualOrganizations/enterprise/helper';
import { ApplyJobRadioLabels, EXPERIENCE_LEVEL } from 'pages/Explore/constants';
import {
  useAxiosWrapper,
  FETCH_JOB_DETAILS_BASED_ON_JOBID,
  FETCH_USER_PROFILE,
  CREATE_APPLICANT,
  SAVE_JOB,
} from 'services';
import { getCurrencySymbol } from 'components/PriceInput/helper';
import TokenService from 'services/token.service';
import dayjs from 'dayjs';
import { PopupApplyWithOptions } from './popupApplyWithOptions';
import axios from 'axios';

export const ViewJob = () => {
  const { jobId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState();
  const [fnWrapper] = useAxiosWrapper();
  const [applyStep, setApplyStep] = useState(null);
  const [radioType, setRadioType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [viewData, setViewData] = useState(null);
  const [isSavedJob, setIsSavedJob] = useState(false);
  // set radio buttons according to apply type to job with resume, profile etc
  const [applicantData, setApplicantData] = useState({
    resume: null,
    questionAndAnswers: '',
    specialization: [],
  });
  const getJobDetails = useCallback(async () => {
    const loggedInUser = TokenService.getSession();
    const res = await fnWrapper({
      url: `${FETCH_JOB_DETAILS_BASED_ON_JOBID}/${jobId}?userId=${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_JOB_DETAILS_BASED_ON_JOBID',
    });
    if (res.data.statusCode === 200) {
      const result = res.data.data;
      let parsedQuestion = result?.question && JSON.parse(result?.question);
      let questionAndAnswers = parsedQuestion
        ? Object.values(parsedQuestion).map((ques) => ({
            question: ques,
            answer: '',
          }))
        : [];
      setApplicantData({
        ...applicantData,
        questionAndAnswers: questionAndAnswers,
      });
      const item = {
        id: result?.id,
        userId: result?.user?.id,
        jobTitle: result?.title,
        technology: result?.technology,
        owner: result?.team?.enterprise
          ? result?.team?.enterprise?.enterpriseName
          : result?.team?.user?.individual?.name,
        profileImgSrc: result?.team?.enterprise
          ? result?.team?.enterprise?.profilePicture
          : result?.team?.user?.individual?.profilePicture,
        ownerType: result?.team?.enterprise ? 'enterprise' : 'individual',
        ownerId: result?.team?.enterprise
          ? result?.team?.enterprise?.id
          : result?.team?.user?.id,
        profileIcon: 'project',
        experience: EXPERIENCE_LEVEL.filter(
          (exp) => exp.value === result?.experience
        )[0].label,
        isJobSaved: result?.isJobSaved,
        postedTime: dayjs(result?.createdAt).format('DD MMM YYYY'),
        description: result?.jobDescription,
        isApplied: result?.isApplied,

        // data for left side section of view job design
        feature: [
          {
            iconName: 'location',
            iconName2: 'location-two',
            iconLabel: 'Job Location',
            label:
              result?.regionCountry !== null
                ? result?.regionCity
                  ? `${capitalizeFirstLetter(result?.regionCity)}${
                      result?.regionCity !== '' ? ',' : ''
                    }${result?.state ? `${result?.state},` : ''}${
                      result?.regionCountry
                    }`
                  : `${result?.regionCountry}`
                : 'N/A',
          },
          {
            iconName: 'brief-case',
            iconName2: 'brief-case-two',
            iconLabel: 'Job Type',
            label: result?.jobType,
          },
          {
            iconName: 'dollar-circle',
            iconName2: 'empty-wallet',
            iconLabel: 'Pay Range',
            label:
              result?.currencyType && result?.payRangeFrom && result?.payRangeTo
                ? `${getCurrencySymbol(result?.currencyType)}${
                    result?.payRangeFrom
                  } - ${result?.payRangeTo}`
                : 'N/A',
          },
          {
            iconName: 'clock',
            iconName2: 'calender',
            iconLabel: 'Posted on',
            label: dayjs(result?.createdAt).format('DD MMM YYYY'),
          },
        ],

        // data related to job poster for
        hiringDetails: [
          {
            iconName: 'user-two',
            label: 'Hiring for',
            valueIcon: 'project',
            valueImg: result?.project?.profilePicture
              ? result?.project?.profilePicture
              : '',
            valueTitle: result?.project?.title,
            valueSubTitle: '',
            id: result?.project?.id,
            type: 'project',
          },
          {
            iconName: 'user-two',
            label: 'Posted By',
            valueIcon: 'project',
            valueImg: result?.user?.smallEnterprise
              ? result?.user?.smallEnterprise?.profilePicture
              : result?.user?.individual?.profilePicture,
            valueTitle: result?.user?.smallEnterprise
              ? result?.user?.smallEnterprise?.enterpriseName
              : result?.user?.individual?.name,
            valueSubTitle: result?.user?.smallEnterprise
              ? result?.user?.smallEnterprise?.title
              : result?.user?.individual?.title,
            id: result?.user?.smallEnterprise
              ? result?.user?.smallEnterprise?.id
              : result?.user?.id,
            type: result?.user?.smallEnterprise ? 'enterprise' : 'individual',
          },
        ],

        // options available to user for applying to a job
        applyWith: ApplyJobRadioLabels.filter((applyWith) =>
          result?.applyWith?.includes(applyWith.id)
        ),
      };
      setIsSavedJob(result?.isJobSaved);
      setViewData(item);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserProfile = useCallback(async () => {
    const loggedInUser = TokenService.getSession();
    const res = await fnWrapper({
      url: `${FETCH_USER_PROFILE}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_USER_PROFILE',
    });
    if (res.data.statusCode === 200) {
      const response = res.data.data;
      setUser({
        id: response.userId,
        name: response.name,
        title: response.title,
        profilePicture: response.profilePicture,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getJobDetails();
    getUserProfile();
  }, [getJobDetails, getUserProfile, radioType]);

  /* single function to handle applicantdata*/
  const applicantDatahandler = ({ type, value }) => {
    if (value) {
      setApplicantData({
        ...applicantData,
        [type]: value,
      });
    }
  };

  /*post applicant data api */
  const submitApplication = async () => {
    const loggedInUser = TokenService.getSession();
    const stringifiedQues = applicantData.questionAndAnswers?.length
      ? JSON.stringify(
          applicantData.questionAndAnswers.reduce(
            (obj, item) => Object.assign(obj, { [item.question]: item.answer }),
            {}
          )
        )
      : '';
    const specialization = applicantData?.specialization?.map((i) => i?.id);
    const resume = radioType === '3' ? applicantData.resume : null;
    let formDataApplicant = new FormData();
    formDataApplicant.append('resume', resume);
    formDataApplicant.append('jobId', jobId);
    formDataApplicant.append('applicantId', loggedInUser.id);
    formDataApplicant.append('profileUrl', radioType === '1');
    formDataApplicant.append('question', stringifiedQues);
    formDataApplicant.append('specialization', JSON.stringify(specialization));
    formDataApplicant.append('eRecord', radioType === '2');
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/${CREATE_APPLICANT}`,
        formDataApplicant,
        {
          headers: {
            'x-access-token': `${TokenService.getAccessToken()}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        }
      )
      .then(async (res) => {
        if (res.data.statusCode === 200) {
          setApplyStep(3);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      });
  };

  const saveJobHandler = async () => {
    const loggedInUser = TokenService.getSession();
    const res = await fnWrapper({
      url: `${SAVE_JOB}`,
      method: 'POST',
      payload: {
        jobId: jobId,
        userId: loggedInUser.id,
      },
      type: 'SAVE_JOBS',
    });
    if (res.data.statusCode === 200) {
      setIsSavedJob(!isSavedJob);
    }
  };

  const onCloseHandler = () => {
    setRadioType(null);
    setApplyStep(null);
    setViewData(null);
    setApplicantData({
      resume: null,
      questionAndAnswers: '',
      specialization: [],
    });
  };

  return (
    <ViewJobWrapper isSameUser={viewData?.userId !== user?.id}>
      {/* job details */}
      <div className="upper-container">
        {/* heading */}
        <div className="heading">
          <div className="heading-back-btn">
            <Icon
              name="arrow-back"
              color="#17191d"
              onClick={() => navigate(`/explore/jobs/search-job`)}
            />
          </div>
          <span>Back to jobs</span>
        </div>
        {viewData ? (
          <>
            <div className="title-class">
              <div className="left-title">
                {viewData?.profileImgSrc && viewData?.profileImgSrc !== '' ? (
                  <div className="profile-pic-class">
                    <img src={viewData?.profileImgSrc} alt="" />
                  </div>
                ) : (
                  <div className="icon-style-class">
                    <Icon
                      name={viewData?.profileIcon}
                      size={24}
                      color="#404f67"
                    />
                  </div>
                )}
                <div>
                  <p className="left-title-text">{viewData?.jobTitle}</p>
                  <div className="subTitle-class">
                    <span className="right-border">
                      <NavigationalRowItem
                        label={viewData?.owner}
                        navigateTo={`/public/${viewData?.ownerType}/${
                          viewData?.ownerId
                        }/${
                          viewData?.ownerType === 'individual'
                            ? 'summary/basic-details'
                            : 'details'
                        }`}
                        isOpenInNewTab
                        isIndividualUser={true}
                        customStyle={{ color: '#040d20' }}
                      />
                    </span>
                    <span className="right-border">{viewData?.postedTime}</span>
                    <span>{viewData?.experience}</span>
                  </div>
                </div>
              </div>
              <div className="right-title">
                {viewData?.userId !== user?.id && (
                  <CustomButton
                    className="w-100"
                    disabled={viewData?.isApplied}
                    customPadding="14px 14px"
                    onClick={() => setApplyStep(1)}>
                    {!viewData?.isApplied ? 'Apply' : 'Applied'}
                    {!viewData?.isApplied && (
                      <Icon name="arrow-forward" color="#fff" />
                    )}
                  </CustomButton>
                )}
                <CustomButton variant="light" onClick={saveJobHandler}>
                  <Icon
                    name={isSavedJob ? 'archieve-saved' : 'archieve-tick'}
                    className="setting-icon-style"
                    size={20}
                    color={'#0ABD00'}
                  />
                </CustomButton>
                <CopyWithIcon
                  id={jobId}
                  textToCopy={`${process.env.REACT_APP_BASE_URL}${location.pathname}`}
                />
              </div>
            </div>
            {/* content */}
            <div className="main-content-class">
              {/* left side content */}
              <div className="leftside-class">
                <div className="main--feature">
                  {viewData?.feature.length !== 0 &&
                    viewData?.feature.map((item, index) => (
                      <div className="feature-wrapper" key={index}>
                        <div className="feature-style">
                          <Icon
                            name={item?.iconName2}
                            size={24}
                            color="#292D32"
                          />
                          <span>{item?.iconLabel}</span>
                        </div>
                        <p>{item?.label}</p>
                      </div>
                    ))}
                </div>
                <div className="main--feature">
                  {viewData?.hiringDetails?.length !== 0 &&
                    viewData?.hiringDetails?.map((item, index) => (
                      <div className="feature-wrapper" key={index}>
                        <div className="feature-style">
                          <Icon
                            name={item?.iconName}
                            size={24}
                            color="#404f67"
                          />
                          <span>{item?.label}</span>
                        </div>
                        <div className="feature-value">
                          {item?.valueImg && item?.valueImg !== '' ? (
                            <div className="value-img-class">
                              <img src={item?.valueImg} alt="" />
                            </div>
                          ) : (
                            <div className="value-icon-class">
                              <Icon
                                name={item?.valueIcon}
                                size={24}
                                color="#404f67"
                              />
                            </div>
                          )}
                          <div>
                            <p className="feature-value-text">
                              <NavigationalRowItem
                                label={item?.valueTitle}
                                navigateTo={`/public/${item?.type}/${
                                  item?.id
                                }/${
                                  item?.type === 'individual'
                                    ? 'summary/basic-details'
                                    : 'details'
                                }`}
                                isOpenInNewTab
                                isIndividualUser={true}
                                customStyle={{ color: '#040d20' }}
                              />
                            </p>
                            {item?.valueSubTitle !== '' && (
                              <div>{item?.valueSubTitle}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              {/* right side content */}
              <div
                className="rightside-class"
                dangerouslySetInnerHTML={{
                  __html: viewData?.description,
                }}></div>
            </div>
          </>
        ) : (
          <div>No jobs found</div>
        )}
      </div>
      {/* apply job with options popup */}
      {applyStep && (
        <PopupApplyWithOptions
          setRadioType={setRadioType}
          viewData={viewData}
          radioType={radioType}
          label={viewData?.applyWith}
          user={user}
          applicantDatahandler={applicantDatahandler}
          applicantData={applicantData}
          applyStep={applyStep}
          setApplyStep={setApplyStep}
          submitApplication={submitApplication}
          isLoading={loading}
          onCloseHandler={onCloseHandler}
        />
      )}
    </ViewJobWrapper>
  );
};
