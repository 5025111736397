import DatePicker from 'react-datepicker';
import { isEqual } from 'lodash';
import { CustomButton, DropDown } from 'components';
import './styles.scss';
import { VIEW_TASK_FILTERS } from 'pages/enterpriseDashboard/helper';

const DEFAULT_FILTER_STATE = {
  AssignedBy: {},
  Status: {},
  TaskUrgency: {},
  TaskPriority: {},
  AssignedToTeam: {},

  fromDate: null,
  toDate: null,
};

export const DepartmentTaskFilter = ({
  state,
  filterTitle,
  onApplyFilterHandler,
  departmentList,
  onFilterChangeHandler,
}) => {
  let isCancel = isEqual(state, DEFAULT_FILTER_STATE);
  return (
    <div className="view-task-filter-container">
      <div className="filter-title-wrapper">
        <div className="filter-title">{filterTitle}</div>
      </div>
      <div className="filter-input-container">
        <div className="row">
          <div className="col-lg-6">
            <DropDown
              label={VIEW_TASK_FILTERS.assignedByDepartment.label}
              value={state.assignedByDepartment}
              objectKey={VIEW_TASK_FILTERS.assignedByDepartment.key}
              items={departmentList}
              placeholder={VIEW_TASK_FILTERS.assignedByDepartment.placeholder}
              onClick={(e) => onFilterChangeHandler('assignedByDepartment', e)}
            />
          </div>
          <div className="col-lg-6">
            <DropDown
              label={VIEW_TASK_FILTERS.status.label}
              items={VIEW_TASK_FILTERS.status.statusType}
              objectKey={VIEW_TASK_FILTERS.status.key}
              value={state.status}
              placeholder={VIEW_TASK_FILTERS.status.placeholder}
              onClick={(e) => onFilterChangeHandler('status', e)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 filter-date-wrapper">
            <label className="filter-date-label form-label">
              Select From Date
            </label>
            <DatePicker
              dateFormat="MMM dd, yyyy"
              selected={state.taskPostedDate.from}
              onChange={(date) => onFilterChangeHandler('fromDate', date)}
              selectsStart
              placeholderText="MMM/DD/YYYY"
              className="filter-custom-date"
            />
          </div>
          <div className="col-lg-6 filter-date-wrapper">
            <label className="filter-date-label form-label">
              Select To Date
            </label>
            <DatePicker
              dateFormat="MMM dd, yyyy"
              selected={state.taskPostedDate.to}
              onChange={(date) => onFilterChangeHandler('toDate', date)}
              selectsEnd
              startDate={state.taskPostedDate.from}
              endDate={state.taskPostedDate.to}
              minDate={state.taskPostedDate.from}
              placeholderText="MMM/DD/YYYY"
              className="filter-custom-date"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <DropDown
              label={VIEW_TASK_FILTERS.taskUrgency.label}
              items={VIEW_TASK_FILTERS.taskUrgency.taskUrgencyType}
              objectKey={VIEW_TASK_FILTERS.taskUrgency.key}
              value={state.taskUrgency}
              placeholder={VIEW_TASK_FILTERS.taskUrgency.placeholder}
              onClick={(e) => onFilterChangeHandler('taskUrgency', e)}
            />
          </div>
          <div className="col-lg-6">
            <DropDown
              label={VIEW_TASK_FILTERS.taskPriority.label}
              items={VIEW_TASK_FILTERS.taskPriority.taskPriorityType}
              objectKey={VIEW_TASK_FILTERS.taskPriority.key}
              value={state.taskPriority}
              placeholder={VIEW_TASK_FILTERS.taskPriority.placeholder}
              onClick={(e) => onFilterChangeHandler('taskPriority', e)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <DropDown
              label={VIEW_TASK_FILTERS.assignedToTeam.label}
              items={VIEW_TASK_FILTERS.assignedToTeam.assignToTeamType}
              objectKey={VIEW_TASK_FILTERS.assignedToTeam.key}
              value={state.assignedToTeam}
              placeholder={VIEW_TASK_FILTERS.assignedToTeam.placeholder}
              onClick={(e) => onFilterChangeHandler('assignedToTeam', e)}
            />
          </div>
        </div>
        <div className="row gy-3">
          <div className="col-lg-6">
            <CustomButton
              className="w-100"
              label={'Apply'}
              color="themeSecondary"
              onClick={() => onApplyFilterHandler('apply')}
            />
          </div>
          <div className="col-lg-6">
            <CustomButton
              className="w-100"
              label={!isCancel ? 'Clear' : 'Close'}
              color="themeSecondary"
              variant="light"
              onClick={() =>
                onApplyFilterHandler(!isCancel ? 'clear' : 'close')
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
