import { CustomButton } from 'components';
import Icon from 'utlils/Icons/Icon';
import styled from 'styled-components';
import { noop } from 'lodash';

const TasksWrapper = styled.div`
  .task-container {
    width: 100%;
    height: 235px;
    border: 1px solid #929daf;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & .img-style {
      margin-bottom: 20px;
      & .project-img {
        object-fit: cover;
        border-radius: 50%;
        width: 48px;
        height: 48px;
      }

      & .project-alt-img {
        border-radius: 50%;
        width: 48px;
        height: 48px;
        background-color: #e9fbf6;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    & .card-title {
      margin-bottom: 20px;
      color: #404f67;
      font-weight: 500;
      text-align: center;
      text-decoration: ${({ navigateItem }) =>
        navigateItem ? 'underline' : 'unset'};
      cursor: ${({ navigateItem }) => (navigateItem ? 'pointer' : 'unset')};
      width: 200px;
    }

    & .card-tasks-no {
      margin-bottom: 20px;
      color: #219fff;
    }

    & .view-task-button {
      color: #404f67;
      border-radius: 20px;
    }
  }
`;

export const TaskCard = ({
  isImg = false,
  imgSrc = '',
  title,
  iconName,
  onBtnClickHandler,
  navigateItem,
}) => {
  return (
    <TasksWrapper navigateItem={navigateItem}>
      <div className="task-container">
        <div className="img-style">
          {isImg ? (
            <img
              src={imgSrc}
              alt="project-img"
              width="100%"
              className="project-img"
            />
          ) : (
            <div className="project-alt-img">
              <Icon name={iconName} color="#404F67" />
            </div>
          )}
        </div>
        <div
          className="card-title"
          onClick={() => (navigateItem ? window.open(navigateItem) : noop)}>
          {title}
        </div>
        <CustomButton
          variant="light"
          color="themeGray"
          onClick={() => onBtnClickHandler()}
          className="view-task-button">
          View Tasks
          <Icon name="arrow-forward" color="#404F67" size={18} />
        </CustomButton>
      </div>
    </TasksWrapper>
  );
};
