import styled from 'styled-components';

export const SelectBiddingWrapper = styled.div`
  .publish_wrp {
    & .scroll-container .scrollable-div {
      padding: 0;
    }
    & .sub-heading {
      margin: 12px 0;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #404f67;
    }
    & .popup-button {
      margin: 32px auto;
      align-items: center;
      width: 150px;
    }

    & .success-popup {
      display: flex;
      justify-content: center;
      text-align: center;
    }

    & .success-popup > div > p {
      font-size: 16px;
    }

    & .loading-bar {
      margin-top: 15%;
    }
  }
`;
