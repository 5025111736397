import styled from 'styled-components';

export const EnterprisePrivacyWrp = styled.div`
  .epwrp__ {
    margin-bottom: 50px;
    &heading {
      color: #17191d;
      font-family: Poppins;
      font-size: 25px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
    &label {
      color: #404f67;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    &icon {
      margin-left: 12px;
    }
    &hideshowwrp__ {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      align-items: center;
      &btn {
        border-left: 1px solid rgba(0, 0, 0, 0.38);
      }
      &hstext {
        color: #7e8f9a;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.25px;
        margin-left: 12px;
      }
      &show-text {
        color: #0abd00;
        font-family: Poppins;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.25px;
        margin-left: 12px;
      }
      &label {
        color: #404f67;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      &content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 18px;
      }
    }
    &submit-button {
      margin-top: 6rem;
      display: flex;
      justify-content: center;
    }
    &tooltipcontent {
      width: 250px;
    }
  }
`;
