import { useState } from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { StatusButton } from 'components';
import { jobStatus } from 'pages/Explore/constants';
import { CustomCheckBox, NavigationalRowItem, SlidingButton } from 'components';
import { truncateString } from 'utlils/utils';
import { isMobile, userScopes } from 'utlils';
import Icon from 'utlils/Icons/Icon';

const EditPostWrapper = styled.div`
  .edit-jobpost {
    color: #7e8f9a;
    cursor: pointer;
    &:hover {
      color: #0abd00;
    }
  }
`;

const ViewApplicationWrapper = styled.div`
  cursor: pointer;
  color: #7e8f9a;
  font-weight: 500;
  text-decoration: underline;
  &:hover {
    color: #219fff;
  }
`;

export const jobAcceptOrRejectStatus = {
  ACCEPTED: {
    label: 'Approved',
    bgColor: 'white',
    textColor: '#0ABD00',
  },
  PENDING: {
    label: 'Pending',
    bgColor: 'white',
    textColor: '#FE9320',
  },
  REJECTED: {
    label: 'Rejected',
    bgColor: 'white',
    textColor: '#7E8F9A',
  },
};
export const postedJobsTableData = ({
  ref,
  list,
  getApplicantList,
  duplicateJobPostHandler,
  activeApplicant = '',
  updateHiringStatus,
  editJobPostHandler,
  userType,
  setJobid,
  setOpenEnterpriseJobEdit,
  setSelectedStatus,
  permissions,
  setCurrJob,
}) => {
  let newData = [];
  if (list && list.length) {
    newData = list.map((item) => {
      return {
        id: item.id,
        projectName: (
          <NavigationalRowItem
            label={item?.project?.title}
            isOpenInNewTab
            customStyle={{
              fontWeight: 400,
              fontSize: '14px',
              color: '#404F67',
            }}
          />
        ),
        title: (
          <NavigationalRowItem
            label={item?.title}
            isOpenInNewTab
            customStyle={{
              fontWeight: 400,
              fontSize: '14px',
              color: '#404F67',
            }}
          />
        ),
        postedOn: (
          <span className="table-text">
            {dayjs(item.createdAt).format('DD MMM YYYY')}
          </span>
        ),
        applicants: (
          <span
            disabled={!permissions.includes(userScopes.ACCESS_JOB_APPLICANTS)}
            className={
              item?.applicants?.length > 0
                ? 'table-text applicant-count'
                : 'table-text'
            }
            style={{
              textDecoration: 'underline',
              color: '#219FF0',
              fontWeight: `${activeApplicant === item.id ? 'bold' : 'normal'}`,
              cursor: `${
                !permissions.includes(userScopes.ACCESS_JOB_APPLICANTS)
                  ? 'not-allowed'
                  : 'pointer'
              }`,
            }}
            onClick={async () => {
              if (permissions.includes(userScopes.ACCESS_JOB_APPLICANTS)) {
                await setSelectedStatus(null);
                await getApplicantList(item?.id);
                ref.current.scrollIntoView();
                setCurrJob(item?.title);
              }
            }}>
            {item?.applicants?.length || 0}
          </span>
        ),
        isOpen: (
          <SlidingButton
            disabled={!permissions.includes(userScopes.OPEN_CLOSE_POSTED_JOB)}
            isSliding={item.isHiringOpen}
            className="is-job-open"
            onClick={() => updateHiringStatus(item.id, !item.isHiringOpen)}
          />
        ),
        duplicate: (
          <Icon
            name="duplicate"
            color="#219FFF"
            className="duplicate-icon"
            disabled={!permissions.includes(userScopes.DUPLICATE_POSTED_JOB)}
            onClick={() => duplicateJobPostHandler(item.id)}
          />
        ),
        status: (
          <StatusButton
            status={
              !item?.status
                ? jobAcceptOrRejectStatus['PENDING']
                : jobAcceptOrRejectStatus[item?.status]
            }
          />
        ),
        edit: (
          <EditPostWrapper>
            <Icon
              disabled={!permissions.includes(userScopes.EDIT_POSTED_JOB)}
              className="edit-jobpost"
              name="edit"
              onClick={() => {
                if (userType === 'individual') {
                  editJobPostHandler(item.id);
                } else {
                  setJobid(item.id);
                  setOpenEnterpriseJobEdit(true);
                }
              }}
            />
          </EditPostWrapper>
        ),
      };
    });
  } else {
    newData = [];
  }

  return newData;
};
export const Technology = ({ technology }) => {
  const [isHover, setIsHover] = useState(false);
  return (
    <div className="applicant-technology d-flex align-items-center gap-3">
      <div className="items">
        {technology?.length === 0 || technology === null
          ? 'N/A'
          : technology[0]?.name}
      </div>
      {technology?.length > 1 && (
        <div className="count-tech-class">
          <div
            className="items count"
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}>
            {`+ ${technology?.length - 1}`}
          </div>
          {isHover && (
            <div className="techList">
              {technology
                ?.filter((tech) => tech?.id !== technology[0]?.id)
                .map((tech, index) => (
                  <div key={`${tech?.id}${index}`}>
                    {tech?.name}
                    {index !== (0 || technology?.length - 2) && (
                      <>{','} &nbsp;</>
                    )}
                  </div>
                ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export const shortlistedApplicantListTableData = ({
  list,
  shortlistCandidate,
  openOrCloseViewApplicant,
  viewContact,
  markCandidateAsContacted,
  permissions,
}) => {
  let newData = [];
  if (list?.length) {
    newData = list?.map((item) => {
      return {
        id: item.id,
        applicant_name: (
          <NavigationalRowItem
            label={truncateString(item?.applicant?.individual?.name, 12)}
            isOpenInNewTab
            customStyle={{
              fontWeight: 400,
              fontSize: '14px',
              color: '#404F67',
            }}
          />
        ),
        application: (
          <ViewApplicationWrapper
            className="view-application"
            onClick={() => {
              openOrCloseViewApplicant({
                id: item?.id,
                shouldChangeApplicantStatusToView:
                  item.status !== 'VIEWED' && item.status === 'SELECTED',
              });
            }}>
            {'View'}
          </ViewApplicationWrapper>
        ),
        technology: <Technology technology={item?.specialization} />,
        contact: (
          <div>
            <Icon
              name="sms"
              color="#7E8F9A"
              size={28}
              style={{ cursor: 'pointer', '--hover-color': '#219FFF' }}
              onClick={() => viewContact(item?.id)}
            />
          </div>
        ),
        shortlist: (
          <Icon
            name={!item.isShortlisted ? 'star' : 'star-filled'}
            color="#219FFF"
            disabled={
              item.status !== 'SELECTED' &&
              !permissions.includes(userScopes.SHORTLIST_JOB_APPLICANTS)
            }
            onClick={() => {
              if (item.status !== 'SELECTED') {
                shortlistCandidate(item?.id, item.isShortlisted);
              }
            }}
          />
        ),
        shortlistedBy: (
          <NavigationalRowItem
            label={
              item?.shortlistBy?.individual === null
                ? item?.shortlistBy?.smallEnterprise?.enterpriseName
                : item?.shortlistBy?.individual?.name
            }
            isIndividualUser
            isOpenInNewTab
            customStyle={{ color: '#404f67' }}
            navigateTo={
              item?.shortlistBy?.individual === null
                ? `/public/enterprise/${item?.shortlistBy?.smallEnterprise?.id}/details`
                : `/public/individual/${item?.shortlistBy?.id}/summary/basic-details`
            }
          />
        ),
        markAsContacted: (
          <CustomCheckBox
            disabled={
              !permissions.includes(userScopes.MARK_AS_CONTACTED_JOB_APPLICANTS)
            }
            check={item?.markAsContacted}
            onCheckBg="#0ABD00"
            tickColor="#ffffff"
            //checkBorder="1.5px solid rgba(10, 189, 0, 0.7)"
            onClick={() => markCandidateAsContacted(item?.id)}
          />
        ),
        isCheckDisabled: !item?.markAsContacted,
      };
    });
  } else {
    newData = [];
  }
  return newData;
};

export const applicantListTableData = ({
  list,
  shortlistCandidate,
  openOrCloseViewApplicant,
  popupApplicantHandler,
  permissions,
}) => {
  let newData = [];
  if (list?.length) {
    newData = list?.map((item) => {
      return {
        id: item.id,
        applicant_name: (
          <NavigationalRowItem
            label={truncateString(item?.applicant?.individual?.name, 12)}
            navigateTo={
              item?.applicant?.individual?.id
                ? `/public/individual/${item?.applicantId}/summary/basic-details`
                : ''
            }
            isOpenInNewTab
            isIndividualUser={item?.applicant?.individual?.id}
            customStyle={{
              fontWeight: 400,
              fontSize: '14px',
              color: '#404F67',
            }}
          />
        ),
        applied_on: (
          <span className="table-text">
            {dayjs(item?.createdAt).format('DD MMM YYYY')}
          </span>
        ),
        applied_by: (
          <span className="table-text">
            {appliedByHelper({
              isProfile: item?.profileUrl,
              isERecord: item?.eRecord,
              isResume: item?.resume,
            })}
          </span>
        ),
        application: (
          <span
            className="viewApplication"
            onClick={() => {
              openOrCloseViewApplicant({
                id: item?.id,
                shouldChangeApplicantStatusToView:
                  item.status === 'VIEWED' || item.status === 'SELECTED',
              });
              popupApplicantHandler({
                userId: item?.applicant?.id,
                name: item?.applicant?.individual?.name,
                eRecord: item?.eRecord,
                profileUrl: item?.profileUrl,
                question: item?.question ? item?.question : null,
                resume: item?.resume,
                technology: item?.specialization,
              });
            }}>
            {'View'}
          </span>
        ),
        technology: <Technology technology={item?.specialization} />,
        shortlist: (
          <div
            style={{
              cursor:
                item.status !== 'SELECTED' &&
                permissions.includes(userScopes.SHORTLIST_JOB_APPLICANTS)
                  ? 'pointer'
                  : 'not-allowed',
            }}>
            <Icon
              name={!item.isShortlisted ? 'star' : 'star-filled'}
              color="#219FFF"
              disabled={
                item.status !== 'SELECTED' &&
                !permissions.includes(userScopes.SHORTLIST_JOB_APPLICANTS)
              }
              onClick={() => {
                if (
                  item.status !== 'SELECTED' &&
                  permissions.includes(userScopes.SHORTLIST_JOB_APPLICANTS)
                ) {
                  shortlistCandidate(item?.id, item.isShortlisted);
                }
              }}
            />
          </div>
        ),
        status: <StatusButton status={jobStatus[item?.status]} />,
      };
    });
  } else {
    newData = [];
  }
  return newData;
};

export const arrayKeyValueReducer = (array, objKey) => {
  return JSON.stringify(
    array.reduce(
      (obj, item, index) => Object.assign(obj, { [index + 1]: item[objKey] }),
      {}
    )
  );
};

export const helperFunction = {
  getPriceNumberFormat: (numberString) => {
    return parseFloat(numberString);
  },
  getQuestion: (stringifiedQues) => {
    let parsedQuestion = JSON.parse(stringifiedQues);
    return Object.values(parsedQuestion).map((ques) => ({ question: ques }));
  },
  getCountry: (countryList, code) => {
    let countryName = countryList.filter((country) => country.code === code)[0]
      ?.name;
    return countryName
      ? {
          name: !isMobile()
            ? truncateString(countryName, 16)
            : truncateString(countryName, 30),
          code: code,
        }
      : {
          name: null,
          code: null,
        };
  },
  getDropDownValue: (array, value) => {
    return {
      label: array?.filter((val) => val?.value === value)[0].label || '',
      value: value,
    };
  },
  getIsReferAllowed: (isReferAllowed) => {
    return isReferAllowed ? 'yes' : 'no';
  },
};

export const appliedByHelper = ({ isProfile, isERecord, isResume }) => {
  return isProfile
    ? 'Profile'
    : isERecord
    ? 'E-Record'
    : isResume
    ? 'Resume'
    : null;
};
