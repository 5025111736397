import React, { useEffect, useState, useCallback } from 'react';
import Icon from '../../../../utlils/Icons/Icon';
import { Box, CustomButton, Loader, ScrollableDiv, TabGroup } from 'components';
import {
  FETCH_INVITATIONS,
  ACCEPT_OR_REJECT_TEAM_INVITATION,
  useAxiosWrapper,
  FETCH_INDIVIDUAL_INVITATIONS,
  FETCH_DEPARTMENT_INVITATIONS,
  DECLINE_INDIVIDUAL_INVITATION,
  ACCEPT_OR_REJECT_DEPARTMENT_INVITATION,
  ACCEPT_REJECT_GROUP_INVITATION,
} from 'services';
import { capitalizeFirstLetter } from '../../individualOrganizations/enterprise/helper';
import TokenService from 'services/token.service';
import CollaborationInvite from './invitationTypes/CollaborationInvite';
import BiddingInvite from './invitationTypes/BiddingInvite';
import JobInvite from './invitationTypes/JobInvite';
import { ProjectSelectBiddings } from 'pages/ProjectPublic/ProjectSelectBiddings';
import { ProjectSelectCollaborations } from 'pages/ProjectPublic/ProjectSelectCollaborations';
import tokenService from 'services/token.service';
import { INVITATION_TYPES } from 'pages/IndividualPublic/constants';
import { Link } from 'react-router-dom';
import GroupInvite from './invitationTypes/GroupInvite';
import { toast } from 'react-toastify';

const INVITATIONS_TAB_LIST = ['Enterprise', 'Personal'];

const Invitation = ({ acceptRejectHandler, type, setOpenInvitationPopup }) => {
  const [fnWrapper, context] = useAxiosWrapper();
  const [invitations, setInvitations] = useState([]);
  const [departmentInvitation, setDepartmentInvivtation] = useState([]);
  const [selectedCollaboration, setSelectedCollaboration] = useState(null);
  const [selectedBidding, setSelectedBidding] = useState(null);
  const [individualInvitations, setIndividualInvitations] = useState([]);
  const [activeTab, setActiveTab] = useState(capitalizeFirstLetter(type));

  const getInvitations = useCallback(
    async (customType) => {
      const loggedInUser = TokenService.getSession();
      const res = await fnWrapper({
        url: `${FETCH_INVITATIONS}/${loggedInUser.id}?type=${customType}`,
        method: 'GET',
        type: 'FETCH_INVITATIONS',
      });
      if (res.data.statusCode === 200) {
        setInvitations(res.data.data);
      } else {
        setInvitations([]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getIndividualInvitations = useCallback(
    async () => {
      const res = await fnWrapper({
        url: `${FETCH_INDIVIDUAL_INVITATIONS}?userId=${
          tokenService.getSession()?.id
        }`,
        method: 'GET',
        type: 'FETCH_INDIVIDUAL_INVITATIONS',
      });
      if (res?.data?.statusCode === 200) {
        if (res?.data?.data) {
          setIndividualInvitations(res?.data?.data);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getDepartmentInvitations = useCallback(
    async () => {
      const res = await fnWrapper({
        url: `${FETCH_DEPARTMENT_INVITATIONS}/${tokenService.getSession()?.id}`,
        method: 'GET',
        type: 'FETCH_DEPARTMENT_INVITATIONS',
      });
      if (res.data.statusCode === 200) {
        if (res?.data?.data) {
          setDepartmentInvivtation(res?.data?.data);
          // setIndividualInvitations(res.data.data);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const invitationHandler = async (type = 'team', memberInfo, status) => {
    const res = await fnWrapper({
      url:
        type === 'department'
          ? `${ACCEPT_OR_REJECT_DEPARTMENT_INVITATION}`
          : `${ACCEPT_OR_REJECT_TEAM_INVITATION}`,
      method: 'POST',
      payload: {
        invitationToken: memberInfo?.inviteToken,
        memberId: memberInfo?.id,
        newEmail: memberInfo?.email,
        status: status,
      },
      type:
        type === 'department'
          ? 'ACCEPT_OR_REJECT_DEPARTMENT_INVITATION'
          : 'ACCEPT_OR_REJECT_TEAM_INVITATION',
    });

    if (res?.data?.statusCode === 200) {
      acceptRejectHandler(true);
      getInvitations(activeTab.toLowerCase());
      getDepartmentInvitations();
    } else {
      acceptRejectHandler(false);
    }
  };

  const tabSwitchHandler = (value) => {
    setActiveTab(value);
    let tab = value.toLowerCase();
    getInvitations(tab);
  };

  const handleDeclineInvitation = useCallback(async (invitationId) => {
    const res = await fnWrapper({
      url: DECLINE_INDIVIDUAL_INVITATION,
      method: 'PUT',
      payload: { invitationId },
      type: 'DECLINE_INDIVIDUAL_INVITATION',
    });
    if (res.data.statusCode === 200) {
      if (res?.data?.data) {
        getIndividualInvitations();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAcceptDecline = async (type, id, answers) => {
    const loggedInUser = TokenService.getSession();
    const data = {
      userId: loggedInUser.id,
      hasAccepted: type === 'Reject' ? false : true,
      question: type === 'Reject' ? null : answers,
    };
    const res = await fnWrapper({
      url: `${ACCEPT_REJECT_GROUP_INVITATION}/${id}`,
      method: 'PUT',
      payload: data,
      type: 'ACCEPT_REJECT_GROUP_INVITATION',
    });
    if (res.data.statusCode === 200) {
      if (type === 'Reject') {
        toast.error('Group invitation rejected', {
          position: 'top-right',
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        getIndividualInvitations();
      } else {
        toast.success('Group invitation accepted', {
          position: 'top-right',
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        getIndividualInvitations();
      }
    }
  };

  useEffect(() => {
    getInvitations(type);
    getDepartmentInvitations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getIndividualInvitations();
  }, [getIndividualInvitations, selectedCollaboration, selectedBidding]);

  return (
    <div className="invitation-popup-container">
      <div>
        <TabGroup
          tabLists={INVITATIONS_TAB_LIST}
          tabClick={tabSwitchHandler}
          activeTab={activeTab}
        />
      </div>
      {context.busy.status ? (
        <Loader loading={context.busy.status} />
      ) : (
        <Box mt="30px">
          <ScrollableDiv scrollOnHeight="400px">
            {invitations && invitations.length !== 0
              ? invitations.map((team, index) => {
                  const invitedUser =
                    team.department === null
                      ? team?.teamMembers[0]?.invitedUser?.individual?.name
                      : team?.department?.enterprise?.enterpriseName;

                  return (
                    <div key={index} className="invitation-wrapper">
                      <div className="invitation-content">
                        <div className="invitation-icon-container">
                          <div className="invitation-icon-wrapper">
                            <Icon
                              name="invitation"
                              className="invitation-icon"
                              color="#0ABD00"
                            />
                          </div>
                        </div>
                        <Box width="80%">
                          <span className="invitation-highlight">{`${invitedUser} `}</span>
                          invited you to join
                          <Link
                            to={`/public/team/${team?.id}/details`}
                            className="invitation-highlight">{` ${team.teamName} Team `}</Link>
                          as {`${team.teamMembers[0].designation}`}
                        </Box>
                        <Box className="invitation-time-container">
                          <span className="invitation-time">{team.time}</span>
                        </Box>
                      </div>
                      <div className="invitation-button">
                        <Box width="115px">
                          <CustomButton
                            loading={context.busy.status}
                            label="Accept"
                            onClick={() =>
                              invitationHandler(
                                'team',
                                team.teamMembers[0],
                                '4'
                              )
                            }
                            className="w-100"
                          />
                        </Box>
                        <Box width="115px">
                          <CustomButton
                            loading={context.busy.status}
                            label="Decline"
                            color="themeTertiary"
                            variant="light"
                            className="w-100"
                            onClick={() =>
                              invitationHandler(
                                'team',
                                team.teamMembers[0],
                                '3'
                              )
                            }
                          />
                        </Box>
                      </div>
                    </div>
                  );
                })
              : null}
            {departmentInvitation &&
            departmentInvitation.length !== 0 &&
            activeTab === 'Enterprise'
              ? departmentInvitation.map((department, index) => {
                  return (
                    <div key={index} className="invitation-wrapper">
                      <div className="invitation-content">
                        <div className="invitation-icon-container">
                          <div className="invitation-icon-wrapper">
                            <Icon
                              name="invitation"
                              className="invitation-icon"
                              color="#0ABD00"
                            />
                          </div>
                        </div>
                        <Box width="80%">
                          <span className="invitation-highlight">{`${department?.enterprise?.enterpriseName} `}</span>
                          invited you to join
                          <span className="invitation-highlight">{` ${department?.departmentName} Department `}</span>
                          as {`${department?.departmentMembers[0]?.role}`}
                        </Box>
                      </div>
                      <div className="invitation-button">
                        <Box width="115px">
                          <CustomButton
                            loading={context.busy.status}
                            label="Accept"
                            onClick={() =>
                              invitationHandler(
                                'department',
                                department.departmentMembers[0],
                                '4'
                              )
                            }
                            className="w-100"
                          />
                        </Box>
                        <Box width="115px">
                          <CustomButton
                            loading={context.busy.status}
                            label="Decline"
                            color="themeTertiary"
                            variant="light"
                            className="w-100"
                            onClick={() =>
                              invitationHandler(
                                'department',
                                department.departmentMembers[0],
                                '3'
                              )
                            }
                          />
                        </Box>
                      </div>
                    </div>
                  );
                })
              : null}
            {individualInvitations && individualInvitations?.length !== 0
              ? individualInvitations
                  ?.filter((invite) => {
                    if (activeTab === 'Personal') {
                      return invite?.invitedBy?.individual !== null;
                    }
                    return invite?.invitedBy?.smallEnterprise !== null;
                  })
                  ?.map((invite, index) => (
                    <div key={index}>
                      {invite?.invitationType ===
                      INVITATION_TYPES.COLLABORATION ? (
                        <CollaborationInvite
                          context={context}
                          invite={invite}
                          collaboration={invite?.invitedCollaboration}
                          setSelectedCollaboration={setSelectedCollaboration}
                          handleDeclineInvitation={handleDeclineInvitation}
                        />
                      ) : null}
                      {invite?.invitationType === INVITATION_TYPES.BIDDING ? (
                        <BiddingInvite
                          context={context}
                          invite={invite}
                          bidding={invite?.invitedBidding}
                          setSelectedBidding={setSelectedBidding}
                          handleDeclineInvitation={handleDeclineInvitation}
                        />
                      ) : null}
                      {invite?.invitationType === INVITATION_TYPES.JOB ? (
                        <JobInvite
                          context={context}
                          invite={invite}
                          handleDeclineInvitation={handleDeclineInvitation}
                        />
                      ) : null}
                      {invite?.invitationType === INVITATION_TYPES.GROUP ? (
                        <GroupInvite
                          setOpenInvitationPopup={setOpenInvitationPopup}
                          context={context}
                          invite={invite}
                          handleGroupAcceptDecline={handleAcceptDecline}
                        />
                      ) : null}
                    </div>
                  ))
              : null}
            {invitations?.length === 0 &&
            individualInvitations?.filter((invite) => {
              if (activeTab === 'Personal') {
                return invite?.invitedBy?.individual !== null;
              }
              return invite?.invitedBy?.smallEnterprise !== null;
            })?.length === 0 ? (
              departmentInvitation?.length === 0 || activeTab === 'Personal' ? (
                <div>{`You don't have any invitation for now!`}</div>
              ) : null
            ) : null}
            {selectedCollaboration && (
              <ProjectSelectCollaborations
                setProjectInfo={() => {}}
                isInvited={selectedCollaboration}
                onClose={() => setSelectedCollaboration(null)}
                collaborations={[selectedCollaboration]}
              />
            )}
            {selectedBidding && (
              <ProjectSelectBiddings
                setProjectInfo={() => {}}
                isInvited={selectedBidding}
                onClose={() => setSelectedBidding(null)}
                biddings={[selectedBidding]}
              />
            )}
          </ScrollableDiv>
        </Box>
      )}
    </div>
  );
};

export default Invitation;
