import {
  Box,
  CustomButton,
  CustomFileUploadButton,
  DropDown,
  Input,
  ScrollableDiv,
  Textarea,
} from 'components';
import { TASK_URGENCY_LIST } from 'pages/enterpriseDashboard/constants';
import Icon from 'utlils/Icons/Icon';
import '../CreateTask/styles.scss';

export const EditTask = ({
  ctx,
  departmentList,
  onChange,
  onClose,
  onRemoveAttachments,
  onUpdateTask,
  state,
}) => {
  const isLoading =
    (ctx.busy.type === 'UPDATE_DEPARTMENT_TASKS' ||
      ctx.busy.type === 'FETCH_ALL_DEPARTMENT_TASKS') &&
    ctx.busy.status;

  return (
    <div className="create-task-container">
      <Box mb="30px">
        <span className="header">Edit Task</span>
      </Box>
      <ScrollableDiv
        scrollOnHeight="450px"
        scrollOnHeightMob="calc(100vh - 110px)">
        <div className="row">
          <div className="col-lg-12">
            <Input
              label="Task Title"
              type="text"
              required
              value={state.taskTitle}
              onChange={(value) => onChange('taskTitle', value.target.value)}
            />
          </div>
          <div className="col-lg-12">
            <Textarea
              label="Task Description"
              required
              value={state.taskDescription}
              onChange={(value) =>
                onChange('taskDescription', value.target.value)
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <DropDown
              label="Assign to"
              placeholder="Select"
              objectKey="departmentName"
              required
              items={departmentList}
              onClick={(e) => onChange('assignedToDepartment', e)}
              value={state.assignedToDepartment}
            />
          </div>
          <div className="col-lg-6">
            <DropDown
              label="Urgency"
              placeholder="Select"
              objectKey="label"
              required
              items={TASK_URGENCY_LIST}
              onClick={(e) => onChange('urgency', e)}
              value={state.urgency}
            />
          </div>
        </div>
        <Box display="flex" alignItems="baseline" mt="32px">
          <Box mr="20px">Add Attachments</Box>
          <CustomFileUploadButton
            borderWidth="0px 0px 1px 0px"
            borderStyle="solid"
            borderColor="#219FFF"
            background="none"
            fontFamily="'Poppins', 'Open Sans'"
            fontSize="15px"
            fontWeight="500"
            textColor="#219FFF"
            handleFile={(file) => onChange('attachments', file)}
            label="Add"
            iconColor="#219FFF"
            iconName="add"
            iconSize="18"
            isMultiple={true}
          />
        </Box>
        <Box mt="20px" mb="40px" className="align-attachments">
          {state?.links.length !== 0
            ? state.links.map((link, index) => (
                <div className="file-text" key={index}>
                  <a href={link} download>
                    {/[^/]*$/.exec(link)}
                  </a>
                  <span
                    className="remove-icon"
                    onClick={() => onRemoveAttachments('EDIT', 'links', link)}>
                    <Icon color="rgba(146, 157, 175, 1)" name="small-close" />
                  </span>
                </div>
              ))
            : null}
          {state?.attachments.length !== 0
            ? state.attachments.map((file, index) => (
                <div className="file-text" key={index}>
                  {file.name}
                  <span
                    className="remove-icon"
                    onClick={() =>
                      onRemoveAttachments('EDIT', 'attachments', file)
                    }>
                    <Icon color="rgba(146, 157, 175, 1)" name="small-close" />
                  </span>
                </div>
              ))
            : null}
        </Box>
      </ScrollableDiv>
      <div className="create-close-department" mt="83px">
        <CustomButton
          customPadding="16px 36px"
          label="Update"
          disabled={isLoading}
          onClick={onUpdateTask}
          loading={isLoading}
          color={'themeSecondary'}
        />
        <CustomButton
          label="Close"
          disabled={isLoading}
          onClick={onClose}
          color={'themeSecondary'}
          variant="textBtn"
        />
      </div>
    </div>
  );
};
