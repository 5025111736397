import { Loader } from 'components';
import { useState, useCallback, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAxiosWrapper, FETCH_PROFESSIONAL_DETAILS } from 'services';
import styled from 'styled-components';
import Icon from 'utlils/Icons/Icon';
import Edit from '../../../../assests/images/edit.png';
import { SummaryContentCard } from '../../Components/SummaryContentCard';

const ViewProfessionalDetailsWrapper = styled.div`
  padding-left: 15px;
  .heading-class {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin: 60px 0 50px 0;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 34px;
    color: #17191d;

    & .icon-class {
      margin-right: 10px;
    }

    & .edit-icon-class {
      cursor: pointer;
    }
  }

  & .no-data {
    width: 75%;
    text-align: center;
    margin: 2% auto;
    font-weight: 500;
    color: #929daf;
  }

  & .professional-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;

    @media (max-width: 899px) {
      display: block;

      & .professional-card-class {
        margin-top: 15px;
      }
    }
  }
`;

export const ViewProfessionalDetails = ({ taskType }) => {
  const { userId } = useParams();
  const [fnWrapper, context] = useAxiosWrapper();
  const [professionalData, setProfessionalData] = useState([]);
  const navigate = useNavigate();

  const getProfessionalDetails = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_PROFESSIONAL_DETAILS}/${userId}`,
      method: 'GET',
      type: 'FETCH_PROFESSIONAL_DETAILS',
    });

    if (res.data.statusCode === 200) {
      const result = res.data.data;
      const professionalDetails = [];
      const details = [];
      const companyNames = new Set(result.map((item) => item.companyName));
      Array.from(companyNames).map((companyName) => {
        let data = result.filter(
          (dataItem) => dataItem.companyName === companyName
        );
        professionalDetails.push(data);
        return professionalDetails;
      });
      professionalDetails.map((item) => {
        item.sort(function (a, b) {
          return new Date(b.from) - new Date(a.from);
        });
        const temp = {
          designation: item[0].role,
          companyName: item[0].companyName,
          startDate: item[0].from,
          endDate: item[0].to ? item[0].to : '',
          description: item,
        };
        details.push(temp);
        return details;
      });
      setProfessionalData(details);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getProfessionalDetails();
  }, [getProfessionalDetails]);

  return (
    <ViewProfessionalDetailsWrapper>
      <div className="heading-class">
        <div>
          <Icon
            name="brief-case"
            color="#17191d"
            size={26}
            className="icon-class"
          />
          <span>Professional Details</span>
        </div>
        {taskType === 'Private' && (
          <div
            className="edit-icon-class"
            onClick={() =>
              navigate(`/individual/profile/edit/professional_details`, {
                state: {
                  from: 'public_page',
                  redirectTo: `/public/individual/${userId}/summary/professional-details`,
                },
              })
            }>
            <img src={Edit} alt="" className="edit-icon-class" />
          </div>
        )}
      </div>
      {context.busy.status ? (
        <Loader isLoading={context.busy.status} />
      ) : professionalData.length === 0 ? (
        <div className="no-data">No data Available!</div>
      ) : (
        <div className="professional-list">
          {professionalData.map((item, index) => (
            <div className="professional-card-class" key={index}>
              <SummaryContentCard
                title={item.designation}
                subTitle={item.companyName}
                startDate={item.startDate}
                endDate={item.endDate}
                isEndDate={item.endDate !== ''}
                professionalDescription={item.description}
                iconName={'enterprise-two'}
                summaryType="professional"
                isDropDown
              />
            </div>
          ))}
        </div>
      )}
    </ViewProfessionalDetailsWrapper>
  );
};
