import Icon from 'utlils/Icons/Icon';
import { Box, CustomButton } from '../../../../components';
import './addButton.scss';
const AddButton = ({ onClick }) => {
  return (
    <Box width="100px" className="add-button">
      <CustomButton className="w-100" variant="light" onClick={onClick}>
        Add
        <Icon name="add" color="#0abd00" size={20} />
      </CustomButton>
    </Box>
  );
};

export default AddButton;
