import { useEffect, useRef, useState } from 'react';
import { SelectCollaboration } from './SelectCollaboration';
import { CollaborationDetails } from './CollaborationDetails';
import { CollaborationWrapper } from './style';
import { RequiredDetails } from './RequiredDetails';
import { InviteSent } from './InviteSent';
import { DynamicHeader } from './Components/DynamicHeader';
import { MODAL_HEADINGS } from './constants';
import { ModalPopup, ScrollableDiv } from 'components';

const parseJson = (questions) => {
  try {
    return JSON.parse(questions);
  } catch (error) {
    return null;
  }
};

export const ProjectSelectCollaborations = ({
  isInvited,
  collaborations,
  setProjectInfo,
  onClose,
}) => {
  const scrollRef = useRef();
  const [activeStep, setActiveStep] = useState(isInvited ? 1 : 0);
  const [selectedCollaboration, setSelectedCollaboration] = useState(
    isInvited ? isInvited : null
  );
  const [scrollDivHeight, setScrollDivHeight] = useState('calc(100vh - 250px)');

  useEffect(() => {
    if (scrollRef?.current) {
      scrollRef?.current?.scrollTo(0, 0);
    }
  }, [activeStep, scrollRef]);

  return (
    <ModalPopup
      open={false}
      onClose={onClose}
      minWidth={activeStep === 3 ? '600px' : '800px'}
      maxContentWidth={activeStep === 3 ? '600px' : '800px'}
      title={
        <DynamicHeader
          activeStep={activeStep}
          modalHeadings={MODAL_HEADINGS}
          setActiveStep={setActiveStep}
        />
      }>
      <CollaborationWrapper>
        <div className="publish_wrp">
          <ScrollableDiv
            ref={scrollRef}
            scrollOnHeight={
              activeStep !== 0 && activeStep !== 3 && scrollDivHeight
            }
            scrollOnHeightMob="80vh">
            {activeStep === 0 ? (
              <SelectCollaboration
                nextPage={() => setActiveStep(1)}
                selectedCollaboration={selectedCollaboration}
                setSelectedCollaboration={setSelectedCollaboration}
                collaborations={collaborations?.map((collabData) => {
                  if (collabData?.isApplied) {
                    return {
                      ...collabData,
                      text: (
                        <span>
                          {collabData?.collaborationType}
                          <span style={{ color: '#FF928E' }}>
                            {' '}
                            (You've already applied)
                          </span>
                        </span>
                      ),
                      value: collabData?.id,
                      isDisabled: collabData?.isApplied,
                    };
                  }
                  return {
                    ...collabData,
                    text: collabData?.collaborationType,
                    value: collabData?.id,
                    isDisabled: collabData?.isApplied,
                  };
                })}
              />
            ) : null}
            {activeStep === 1 ? (
              <CollaborationDetails
                setScrollDivHeight={setScrollDivHeight}
                nextPage={() => setActiveStep(2)}
                selectedCollaboration={selectedCollaboration}
              />
            ) : null}
            {activeStep === 2 ? (
              <RequiredDetails
                setProjectInfo={setProjectInfo}
                setScrollDivHeight={setScrollDivHeight}
                nextPage={() => setActiveStep(3)}
                selectedCollaboration={selectedCollaboration}
                questions={parseJson(selectedCollaboration?.question) ?? {}}
                documents={
                  selectedCollaboration?.documents?.map((doc) => ({
                    name: doc?.split('/')[doc?.split('/')?.length - 1],
                    location: doc,
                    isMandatory: true,
                  })) ?? []
                }
                collaboratorForms={
                  selectedCollaboration?.collaboratorForms?.map((collabDoc) => {
                    let parseData = parseJson(collabDoc);
                    if (parseData) {
                      return { ...parseData, name: parseData.fileName };
                    }
                    return null;
                  }) ?? []
                }
              />
            ) : null}
            {activeStep === 3 ? (
              <InviteSent selectedCollaboration={selectedCollaboration} />
            ) : null}
          </ScrollableDiv>
        </div>
      </CollaborationWrapper>
    </ModalPopup>
  );
};
