import { css } from 'styled-components';
import FadeLoader from 'react-spinners/FadeLoader';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export const Loader = ({ loading, style }) => {
  return (
    <div className="text-center" style={style}>
      <FadeLoader
        color={'#0ABD00'}
        loading={loading}
        css={override}
        height={35}
        margin={20}
      />
      <p className="mt-5">Please wait...</p>
    </div>
  );
};
