import { CustomButton, DropDown, ModalPopup } from 'components';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  FETCH_PROJECT_MEMBERS,
  UPDATE_PROJECT_ADMIN,
  useAxiosWrapper,
} from 'services';
import { ChangeProjectAdminWrapper } from './style';

export const ChangeProjectAdmin = ({ onClose, projectId }) => {
  const [fnWrapper, { busy }] = useAxiosWrapper();
  const [membersList, setMembersList] = useState([]);
  const [newAdmin, setNewAdmin] = useState(null);

  const loadMembersList = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_PROJECT_MEMBERS}/${projectId}`,
      method: 'GET',
      type: 'FETCH_PROJECT_MEMBERS',
    });
    if (res.data.statusCode === 200) {
      setMembersList(res.data.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeAdmin = useCallback(async () => {
    const updatedData = { userId: newAdmin?.memberUserId, projectId };
    const res = await fnWrapper({
      url: UPDATE_PROJECT_ADMIN,
      method: 'PUT',
      type: 'UPDATE_PROJECT_ADMIN',
      payload: updatedData,
    });
    if (res.data.statusCode === 200) {
      toast.success('Project admin changed successfully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      await onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newAdmin?.memberUserId, projectId]);

  useEffect(() => {
    loadMembersList();
  }, [loadMembersList]);

  return (
    <ChangeProjectAdminWrapper>
      <ModalPopup
        open={false}
        title={'Change admin'}
        onClose={onClose}
        maxContentWidth="700px">
        <span>Please select a new admin from the list</span>
        <div className="drop-down-wrapper">
          <DropDown
            items={membersList}
            objectKey="memberName"
            placeholder="Select"
            value={newAdmin}
            onClick={(item) => {
              setNewAdmin(item);
            }}
          />
        </div>
        <div className="withdraw-button">
          <div className="withdraw-button-wrapper">
            <CustomButton
              className="w-100"
              label="Change admin"
              disabled={busy?.status || !newAdmin}
              onClick={handleChangeAdmin}
            />
          </div>
        </div>
      </ModalPopup>
    </ChangeProjectAdminWrapper>
  );
};
