import { IntlProvider } from 'react-intl';
import Arabic from '../../assests/languages/ar.json';
import English from '../../assests/languages/en.json';
import { LOCALES } from '../../assests/languages/constant';

export const LanguageWrapper = (props) => {
  const messagePath = {
    en: English,
    ar: Arabic,
  };

  return (
    <IntlProvider
      messages={messagePath[props.currentLocale || LOCALES.ENGLISH]}
      locale={props.currentLocale || LOCALES.ENGLISH}>
      {props.children}
    </IntlProvider>
  );
};
