import { CustomButton, ModalPopup, TabGroup } from 'components';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import BgImg from 'assests/images/bgImgPublic.png';
import styled from 'styled-components';
import Icon from 'utlils/Icons/Icon';
import GroupDescription from './GroupDescription';
import GroupPublications from './GroupPublications';
import { ReactComponent as ThreeDots } from 'assests/images/three-dots.svg';
import CommonDropDown from 'pages/enterpriseDashboard/components/commonDropDown';
import noEnterprise from '../../../../assests/images/no-enterprise.png';
import {
  FETCH_ALL_MY_CONNECTED_USERS,
  GROUP_DETAILS,
  SEND_GROUP_INVITATION,
  FETCH_GROUP_MEMBER,
  useAxiosWrapper,
  LEAVE_REMOVE_GROUP,
} from 'services';
import tokenService from 'services/token.service';
import InviteMemberCard from './Components/inviteMemberCard';
import { toast } from 'react-toastify';
const MY_GROUP_INFO_ACTION_OPTIONS = [
  {
    id: '0',
    label: 'Edit',
  },
  {
    id: '1',
    label: 'Invite Members',
  },
  {
    id: '2',
    label: 'Requests',
  },
  {
    id: '3',
    label: 'Leave',
  },
];

const GROUP_TAB_LIST = ['Description', 'Publications'];
export default function GroupInfo() {
  const [activeTab, setActiveTab] = useState(GROUP_TAB_LIST[0]);
  const [optionsOpen, setOptionOpen] = useState(false);
  const [groupDetails, setGroupDetails] = useState(null);
  const [invitePopupOpen, setInvitePopup] = useState(false);
  const [fnWrapper, context] = useAxiosWrapper();
  const [userNetwork, setUserNetwork] = useState([]);
  const [inviteMembers, setInviteMembers] = useState([]);
  const [groupRole, setGroupRole] = useState(null);
  const [memberId, setMemberId] = useState(null);
  const [leavePopup, setleavePopup] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const tabSwitchHandler = (value) => {
    setActiveTab(value);
    value = value.toLowerCase();
    navigate(`/individual/profile/groups/${params.groupId}/view/${value}`);
  };

  function handleOptionsClick(item) {
    switch (item.label) {
      case 'Edit':
        navigate('/individual/profile/groups/update/information', {
          state: { id: groupDetails?.id },
        });
        break;
      case 'Invite Members':
        getUserNetwork();
        setInvitePopup(true);
        setOptionOpen(false);
        break;
      case 'Requests':
        navigate(
          `/individual/profile/groups/${params.groupId}/requests/recieved`
        );
        break;
      case 'Leave':
        setleavePopup(true);
        setOptionOpen(false);
        break;
      default:
        break;
    }
  }

  const getGroupDetails = useCallback(async () => {
    const res = await fnWrapper({
      url: `${GROUP_DETAILS}/${params.groupId}`,
      method: 'GET',
      type: 'GROUP_DETAILS',
    });
    if (res?.data?.statusCode === 200) {
      setGroupDetails(res.data.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserNetwork = useCallback(async () => {
    const loggedInUser = tokenService.getSession();
    const res = await fnWrapper({
      url: `${FETCH_ALL_MY_CONNECTED_USERS}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_GET_ALL_PUBLIC_USERS',
      payload: {
        hasPaginated: false,
      },
    });
    if (res?.data?.statusCode === 200) {
      let myNetwork = [];
      res?.data?.data?.items.forEach((network) =>
        myNetwork.push(network.connectedUsers)
      );
      setUserNetwork(myNetwork);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let leaveOrRemoveMember = useCallback(async (type, memberId) => {
    const loggedInUser = tokenService.getSession();
    const payload = { userId: loggedInUser.id, requestType: type };
    const res = await fnWrapper({
      url: `${LEAVE_REMOVE_GROUP}/${memberId}`,
      method: 'PUT',
      payload: payload,
    });
    if (res?.data?.statusCode === 200) {
      navigate('/individual/profile/groups');
      toast.success(
        `${
          type === 'leave'
            ? 'Group leaved successfully'
            : 'Member Removed Successfully'
        }`,
        {
          position: 'top-right',
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendInvitationHandler = useCallback(async (inviteMembers) => {
    const loggedInUser = tokenService.getSession();
    const data = {
      userId: loggedInUser.id,
      groupMembers: inviteMembers,
    };
    let res = await fnWrapper({
      url: `${SEND_GROUP_INVITATION}/${params.groupId}`,
      method: 'POST',
      payload: data,
      type: 'SEND_GROUP_INVITATION',
    });
    if (res.data.statusCode === 200) {
      toast.success(`Invitation sent successfully`, {
        position: 'top-right',
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      setInviteMembers([]);
      setInvitePopup(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchGroupMemberDetails = async () => {
    const loggedInUser = tokenService.getSession();
    const res = await fnWrapper({
      url: `${FETCH_GROUP_MEMBER}?groupId=${params.groupId}&userId=${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_GROUP_MEMBER',
    });

    if (res?.data?.statusCode === 200) {
      const userRole = res?.data?.data?.role;
      setMemberId(res?.data?.data?.id);
      setGroupRole(userRole);
    }
  };

  const availableForInvite = useMemo(() => {
    return userNetwork.filter(
      (item) =>
        !groupDetails?.membersInfo?.some((member) => member.userId === item.id)
    );
  }, [userNetwork, groupDetails]);

  useEffect(() => {
    getGroupDetails();
    fetchGroupMemberDetails();
    let path = location.pathname.split('/');
    GROUP_TAB_LIST.forEach((tab) => {
      if (tab.toLowerCase() === path[6]) {
        setActiveTab(tab);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GroupInfoWrapper>
      <div className="top-gray">
        <div className="header">
          <CustomButton
            color="themeGray"
            variant="textBtn"
            onClick={() => navigate('/individual/profile/groups')}>
            <Icon name="arrow-back" size={18} color="#404f67" />
            Back to group
          </CustomButton>
        </div>
        <div className="profile-picture-and-name">
          <div className="profile-picture">
            <img
              className="image"
              src={
                groupDetails?.profilePicture === null
                  ? BgImg
                  : groupDetails?.profilePicture
              }
              alt=""
            />
          </div>
          <div className="name-data">
            <div className="name">{groupDetails?.groupName}</div>
          </div>
        </div>
      </div>
      <div className="bottom-white">
        <div className="tabs mt-2">
          <TabGroup
            tabLists={GROUP_TAB_LIST}
            tabClick={tabSwitchHandler}
            activeTab={activeTab}
          />
        </div>
        <div className="tab-content mt-3">
          <Routes>
            <Route path="">
              <Route
                path="/description"
                element={
                  <GroupDescription
                    leaveOrRemoveMember={leaveOrRemoveMember}
                    groupDetails={groupDetails}
                  />
                }
              />
              <Route
                path="/publications"
                element={<GroupPublications groupRole={groupRole} />}
              />
            </Route>
          </Routes>
        </div>
      </div>
      {groupDetails?.groupName && (
        <div role="button" className="options-dots">
          <div onClick={() => setOptionOpen(!optionsOpen)}>
            <ThreeDots color={optionsOpen ? '#219fff' : '#c4c4c4'} alt="" />
          </div>

          <CommonDropDown
            dropItem={
              groupRole === 'MEMBER'
                ? MY_GROUP_INFO_ACTION_OPTIONS.slice(-1)
                : MY_GROUP_INFO_ACTION_OPTIONS
            }
            active={optionsOpen}
            objectKey="label"
            onClick={(item) => {
              handleOptionsClick(item);
            }}
            top="35px"
            right="-38px"
          />
        </div>
      )}
      {invitePopupOpen && (
        <ModalPopup
          open={false}
          onClose={() => setInvitePopup(false)}
          hasCloseIcon
          hasTitle
          title={'Invite Members'}>
          {availableForInvite.length > 0 ? (
            <>
              {availableForInvite.map((network, index) => (
                <InviteMemberCard
                  key={index}
                  network={network}
                  inviteMembers={inviteMembers}
                  setInviteMembers={setInviteMembers}
                />
              ))}

              <div className="send-invitation-handler">
                <CustomButton
                  loading={context.busy.status}
                  disabled={inviteMembers.length === 0}
                  onClick={() => sendInvitationHandler(inviteMembers)}
                  color="themeSecondary"
                  label="Send Invitation"
                />
              </div>
            </>
          ) : (
            <div className="no-content">
              <div className=" background-image">
                <img src={noEnterprise} alt="" width="320px" />
              </div>
              No users available for invite. Add connections to invite them to
              your group.
            </div>
          )}
        </ModalPopup>
      )}
      {leavePopup && (
        <ModalPopup
          open={false}
          title={'Leave'}
          onClose={() => setleavePopup(false)}>
          <div className="warning-text text-center">
            Do you want to leave this group?
          </div>
          <div className="buttons">
            <CustomButton
              customPadding="16px 52px"
              label="Yes"
              color="themeSecondary"
              onClick={() => leaveOrRemoveMember('leave', memberId)}
            />
            <CustomButton
              variant="outline"
              color="themeSecondary"
              customPadding="16px 52px"
              label="No"
              onClick={() => setleavePopup(false)}
            />
          </div>
        </ModalPopup>
      )}
    </GroupInfoWrapper>
  );
}
const GroupInfoWrapper = styled.div`
  background-color: white;
  position: relative;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  .top-gray {
    position: relative;
    padding: 25px;
    height: 160px;
    background-color: rgba(233, 238, 244, 1);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    .header {
      display: flex;
      justify-content: space-between;
    }
    .profile-picture-and-name {
      position: absolute;
      top: 70px;
      display: flex;
      gap: 30px;
      @media (max-width: 576px) {
        top: 80px;
      }
      .profile-picture {
        width: 120px;
        aspect-ratio: 1/1;
        @media (max-width: 576px) {
          width: 80px;
        }
        .image {
          border-radius: 100%;
          width: 100%;
          height: 100%;
        }
      }
      .name-data {
        position: relative;
        .name {
          width: max-content;
          color: rgba(4, 13, 32, 1);
          font-size: 23px;
          font-weight: 500;
          font-family: 'Poppins';
          position: absolute;
          top: 50px;
          @media (max-width: 576px) {
            top: 20px;
            font-size: 20px;
          }
        }
      }
    }
  }
  .options-dots {
    position: absolute;
    top: 40px;
    right: 40px;
  }
  .send-invitation-handler {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
  .no-content {
    color: #808080;
    text-align: center;
  }
  .buttons {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 20px;
  }
  .warning-text {
    font-size: 18px;
    font-weight: 400;
  }
`;
