import styled from 'styled-components';

export const ViewLinkedProjectWrapper = styled.div`
  & .view-container {
    & .table-style {
      margin-top: 30px;
    }
    & .no-linked-project {
      width: 75%;
      text-align: center;
      margin: 8% auto;
      font-weight: 500;
      color: #929daf;
    }
  }
`;
