import Icon from 'utlils/Icons/Icon';

export const TermsConditionContainer = ({ name, location }) => {
  return (
    <div className="tc-container">
      <Icon
        name="attachment"
        size={18}
        color={'#219FFF'}
        style={{ marginTop: '5px' }}
      />
      <a
        rel="noreferrer"
        target="_blank"
        href={location}
        className="sub-heading-tc">
        {name}
      </a>
    </div>
  );
};
