import styled from 'styled-components';

export const TaskManagementLayoutWrapper = styled.div`
  background-color: #f4f7fc;
  padding-bottom: 20px;
  .task-management {
    &__wrapper {
      min-height: calc(100% - 100px);
      margin: 20px 80px;
      background-color: white;
      border-radius: 8px;
      @media (max-width: 992px) {
        margin: 16px 40px;
      }
      @media (max-width: 576px) {
        margin: 16px;
      }
      &__header {
        padding: 15px 30px;
        .back_to_home {
          font-weight: 400;
          font-size: 16px;
          color: #404f67;
          text-decoration: underline;
          cursor: pointer;
        }
        .underline {
          border: 1px solid #929daf;
          width: 100%;
          margin-top: -30px;
        }
        .select-project-department {
          display: flex;
          padding-bottom: 50px;
          flex-wrap: wrap;
          margin-top: 10px;
          align-items: center;
          justify-content: space-between;
          .title {
            font-weight: 700;
            font-size: 24px;
            color: #17191d;
          }
          .task-dropdown-container {
            display: flex;
            gap: 16px;
            flex-wrap: wrap;
            align-items: baseline;
            align-items: center;
            .dropdown-wrapper {
              position: relative;
              &__title {
                min-width: 220px;
                cursor: pointer;
                padding: 12px 16px;
                background: #f4f7fc;
                border: 0.5px solid #929daf;
                border-radius: 8px;
                @media (max-width: 575px) {
                  min-width: 100%;
                  margin-top: 12px;
                }
                .heading {
                  font-weight: 500;
                  font-size: 15px;
                  line-height: 19px;
                  color: #929daf;
                }
              }
              &__option-wrapper {
                top: 62px;
                left: 4px;
              }
            }
            .task-check-filter {
              display: grid;
              justify-content: center;
              align-items: center;
              padding: 6px;
              border-radius: 10px;
            }
          }
        }
      }
      &__board {
        padding: 0 20px;
        &__no_data {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 75%;
          margin: auto;
          height: 70vh;
          & .no-data-img {
            width: 250px;
            @media (max-width: 400px) {
              width: 150px;
            }
          }
        }
        &__title--container {
          padding: 15px;
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          .title {
            font-weight: 400;
            font-size: 18px;
            text-transform: uppercase;
            color: #455a64;
          }
          .add_delete_field {
            svg {
              cursor: pointer;
            }
          }
        }
        &__cards--wrapper {
          padding: 15px;
          min-height: calc(100% - 80px);
          .custom-card {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
`;

export const BoardFieldWrapper = styled.div`
  min-width: 370px;
  max-width: 370px;
  min-height: 1400px;
  background: #e9eef4;
  border-radius: 5px;
`;
