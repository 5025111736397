import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomButton, Loader, Pagination, SearchInput } from 'components';
import {
  ExploreTeamProjectCard,
  ExploreTeamFilter,
} from 'pages/Explore/Components';
import { SearchTeamWrapper } from './style';
import {
  FETCH_ALL_PUBLIC_TEAMS,
  CREATE_SAVE_PUBLIC_TEAM,
  FETCH_ENTERPRISE_LIST,
  useAxiosWrapper,
} from 'services';
import tokenService from 'services/token.service';
import Icon from 'utlils/Icons/Icon';

const DEFAULT_FILTER_STATE = {
  filteredMembersCount: {},
  filteredTeamType: {},
  filteredCategory: {},
  filteredEnterpriseName: {},
  hiringBasedFilter: false,
  connectionBasedFilter: false,
  connectionId: '',
};

export const SearchTeam = () => {
  const [fnWrapper, context] = useAxiosWrapper();
  const [searchTerm, setSearchTerm] = useState('');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  let pageSize = 6;
  const [teamsList, setTeamsList] = useState({});
  const [filteredTeams, setFilteredTeams] = useState([]);
  const [filterState, setFilterState] = useState(DEFAULT_FILTER_STATE);
  const navigate = useNavigate();
  const [enterpriseList, setEnterpriseList] = useState([]);

  const getAllEnterpriseList = useCallback(async () => {
    const loggedInUser = await tokenService.getSession();
    const res = await fnWrapper({
      url: `${FETCH_ENTERPRISE_LIST}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_ENTERPRISE_LIST',
    });
    if (res.data.statusCode === 200) {
      if (res?.data?.data) {
        setEnterpriseList(res.data.data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllPublicTeams = useCallback(async () => {
    const loggedInUser = await tokenService.getSession();
    const params = {
      page: currentPage,
      size: pageSize,
      search: searchTerm,
      teamTypeId: Object.keys(filterState.filteredTeamType).length
        ? filterState.filteredTeamType.id
        : '',
      teamTypeCategory: Object.keys(filterState.filteredCategory).length
        ? filterState.filteredCategory.value
        : '',
      memberCount: Object.keys(filterState.filteredMembersCount).length
        ? filterState.filteredMembersCount.value
        : '',
      enterpriseId: Object.keys(filterState.filteredEnterpriseName).length
        ? filterState.filteredEnterpriseName.id
        : '',
      isHiring: filterState.hiringBasedFilter,
    };
    const res = await fnWrapper({
      url: `${FETCH_ALL_PUBLIC_TEAMS}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_GET_ALL_PUBLIC_TEAMS',
      params,
    });

    if (res.data.statusCode === 200) {
      const result = res.data.data;
      if (result.items.length === 0 && currentPage !== 1) {
        setCurrentPage(currentPage - 1);
      } else {
        setCurrentPage(result.currentPage);
        setTeamsList(result);
        const allTeams = [];
        result.items?.map((team) => {
          const item = {
            id: team?.id,
            visibilityInExplore: team?.profileVisibleInExploreTeam,
            teamName: team?.teamName,
            owner:
              team?.teamCategory === 'ENTERPRISE' && team?.enterprise
                ? team?.enterprise?.enterpriseName
                : team?.user?.individual?.name,
            type: 'team',
            profileImgSrc: '',
            profileIcon: 'people',
            description: team?.description,
            isTeamSaved: team?.isTeamSaved,
            teamFeature: [
              {
                iconName: 'people',
                label: `${team?.teamMembers.length} Members`,
              },
              {
                iconName: 'brief-case',
                label: `${team?.isHiring ? 'Hiring' : '--'}`,
              },
              {
                iconName: 'tag-user',
                label: team?.type?.title,
              },
              {
                iconName: 'user',
                label: `${team?.connections?.length} Connections`,
              },
            ],
          };
          allTeams.push(item);
          return allTeams;
        });
        setFilteredTeams(
          allTeams.filter((team) => team.visibilityInExplore === true)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    filterState.filteredCategory,
    filterState.filteredMembersCount,
    filterState.filteredTeamType,
    filterState.filteredEnterpriseName,
    filterState.hiringBasedFilter,
    pageSize,
    searchTerm,
  ]);

  const saveTeam = async (teamId) => {
    const loggedInUser = tokenService.getSession();
    const res = await fnWrapper({
      url: `${CREATE_SAVE_PUBLIC_TEAM}`,
      method: 'PUT',
      payload: {
        teamId: teamId,
        userId: loggedInUser.id,
      },
      type: 'CREATE_SAVE_PUBLIC_TEAM',
    });
    if (res.data.statusCode === 200) {
      await getAllPublicTeams();
    }
  };

  useEffect(() => {
    getAllPublicTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllPublicTeams]);
  useEffect(() => {
    getAllEnterpriseList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const teamFilterSaveHandler = async (type) => {
    if (type === 'apply' && filteredTeams.length !== 0) {
      setSearchTerm('');
      await getAllPublicTeams();
    } else if (
      type === 'cancel' &&
      JSON.stringify(filterState) !== JSON.stringify(DEFAULT_FILTER_STATE)
    ) {
      setFilterState(DEFAULT_FILTER_STATE);
      await getAllPublicTeams();
    }
    setIsFilterOpen(false);
  };

  const teamFilterOnChangeHandler = (field = '', value = null) => {
    setFilterState({
      ...filterState,
      [field]: value,
    });
  };

  const searchHandler = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value === '') {
      getAllPublicTeams();
    }
  };

  const closeSearchHandler = () => {
    setSearchTerm('');
    getAllPublicTeams();
  };

  return (
    <SearchTeamWrapper>
      <div className="search-container">
        <SearchInput
          placeHolder="Search a Team by Name"
          value={searchTerm}
          onSearchange={searchHandler}
          onCloseSearch={closeSearchHandler}
          onSearch={() => getAllPublicTeams()}
        />
        <CustomButton
          onClick={() => setIsFilterOpen(!isFilterOpen)}
          variant="light"
          color={isFilterOpen ? 'themeSecondary' : 'themeGray2'}>
          <Icon
            className="filter-arrow"
            name="filter"
            size={20}
            color={isFilterOpen ? 'rgba(33, 159, 255, 1)' : '#404f67'}
          />
          Filter
        </CustomButton>
      </div>
      <div className="main-wrapper">
        <div className="heading">
          <h5 className="teams-count">Teams ({filteredTeams.length})</h5>
        </div>
        {context.busy.status ? (
          <Loader loading={context.busy.status} />
        ) : filteredTeams?.length > 0 ? (
          <>
            <div className="cards-container">
              {filteredTeams?.map((team, index) => (
                <ExploreTeamProjectCard
                  key={index}
                  title={team.teamName}
                  type={team.type}
                  profileImgSrc={team.profileImgSrc}
                  profileIcon={team.profileIcon}
                  owner={team.owner}
                  description={team.description}
                  features={team.teamFeature}
                  viewHandler={() =>
                    navigate(`/public/team/${team.id}/details`)
                  }
                  isSaved={team.isTeamSaved}
                  onSaveHandler={() => saveTeam(team.id)}
                />
              ))}
            </div>
          </>
        ) : (
          <div className="no-data-class">No Teams Found!</div>
        )}
        {isFilterOpen && (
          <>
            <ExploreTeamFilter
              enterpriseList={enterpriseList}
              filterTitle="Filter Teams"
              state={filterState}
              filterSaveHandler={teamFilterSaveHandler}
              filterOnChangeHandler={teamFilterOnChangeHandler}
            />
          </>
        )}
      </div>
      <div className="pagination-container">
        {(filteredTeams.length >= 6 || teamsList.currentPage >= 1) && (
          <Pagination
            className="pagination-bar"
            currentPage={teamsList.currentPage ? teamsList.currentPage : 0}
            totalCount={teamsList.totalItems ? teamsList.totalItems : 0}
            pageSize={pageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        )}
      </div>
    </SearchTeamWrapper>
  );
};
