import styled from 'styled-components';

export const PublicationFormWrapper = styled.div`
  .label {
    color: #404f67;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 10px 0;
  }
  .new-publication-modal {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
    .privacy-n-category {
      width: 100%;
      padding: 10px 0;
      display: flex;
      justify-content: space-between;

      .privacy-radio-group {
        display: flex;
        margin: 10px 0;
      }
    }
    .collaborators {
      width: 100%;
    }
    .linkProject-category {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 100px;
    }
    .Upload-publication-image {
      font-size: 14px;
      font-weight: 700;
      line-height: 21px;
      color: #404f67;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      margin-top: 10px;

      .Upload-btn {
        width: 85px;
        color: #219fff;
        font-size: 15px;
      }
    }
    .add-publication-attachment {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 50px;
    }
    .btn-group {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      .update-publish-btn {
        border-radius: 10px !important;
      }
    }

    .upload-containers {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
    .uploaded-image-info-container {
      display: flex;
      width: 60%;
      justify-content: space-between;
      .image-info {
        /* width: 300px; */
        font-weight: 400;
        color: #219fff;
        font-size: 12px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
`;

export const SelectedCollaboratorsWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  .collaborator-items {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #219fff1a;
    border-radius: 10px;
    padding: 10px;
    .profile-image {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      overflow: hidden;
    }

    .profile-text {
      width: 42px;
      height: 42px;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #219fff;
      color: #fff;
      font-weight: 500;
    }

    .user-name {
      color: #219fff;
      font-size: 14px;
      font-weight: 600;
    }
  }
`;
