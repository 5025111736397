import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CreateAchievementDetails } from './CreateAchievementInfo';
import { CreateBasicDetails } from './CreateBasicInfo';
import { CreateEducationalDetails } from './CreateEducationalInfo';
import { CreateLicenseDetails } from './CreateLicenseInfo';
import { CreateProfessionalDetails } from './CreateProfessionalInfo';
import { CreateSkillsAndTechnologyInfo } from './CreateSkillsAndTechnology';
import { Box, Sidebar } from 'components';
import ProfileCard from '../../components/profileCard';
import { IndividualProfileOptions } from '../../constants';
import './index.scss';

export const CreateProfileInfo = (props) => {
  let {
    userProfile,
    profilePicUploading,
    uploadProfilePicture,
    setIsUserInfoUpdated,
  } = props;
  let { type = 'create', step = 'basic_details' } = useParams();
  let navigate = useNavigate();
  const [currType, setCurrType] = useState(step);

  const setEditPage = (value) => {
    setCurrType(value);
    navigate(`/individual/profile/${type}/${value}`);
  };

  return (
    <div className="individual-basic-detail-container">
      <div className="layout-container">
        <Box className="layout-sub-container">
          <Box className="sub-container-child">
            <ProfileCard
              isUpload
              userProfile={userProfile}
              profilePicUploading={profilePicUploading}
              uploadProfilePicture={uploadProfilePicture}
            />
            <Box mt="1.5rem">
              <Sidebar
                sidebarData={IndividualProfileOptions}
                activeType={currType}
                setActiveType={setEditPage}
              />
            </Box>
          </Box>
          <Box className="sub-container-child">
            <Box
              bg="white"
              className="main-wrapper"
              display={currType === null && 'none'}>
              {currType === 'basic_details' && (
                <CreateBasicDetails
                  setCurrType={setCurrType}
                  isEditEvent={type === 'edit'}
                  nextType="education"
                  title="Basic Details"
                  type={type}
                  setIsUserInfoUpdated={setIsUserInfoUpdated}
                />
              )}
              {currType === 'education' && (
                <CreateEducationalDetails
                  setCurrType={setCurrType}
                  isEditEvent={type === 'edit'}
                  nextType="professional_details"
                  title="Education"
                  type={type}
                />
              )}
              {currType === 'professional_details' && (
                <CreateProfessionalDetails
                  setCurrType={setCurrType}
                  isEditEvent={type === 'edit'}
                  nextType="skills_and_technology"
                  title="Professional Details"
                  type={type}
                />
              )}
              {currType === 'skills_and_technology' && (
                <CreateSkillsAndTechnologyInfo
                  title="Skills/Technology"
                  type={type}
                  isEditEvent={type === 'edit'}
                  nextType="achievements"
                  setCurrType={setCurrType}
                />
              )}
              {currType === 'achievements' && (
                <CreateAchievementDetails
                  setCurrType={setCurrType}
                  isEditEvent={type === 'edit'}
                  nextType="license_certifications"
                  title="Achievements"
                  type={type}
                />
              )}
              {currType === 'license_certifications' && (
                <CreateLicenseDetails
                  setCurrType={setCurrType}
                  isEditEvent={type === 'edit'}
                  nextType={null}
                  title="License & Certifications"
                  type={type}
                />
              )}
            </Box>
          </Box>
        </Box>
      </div>
    </div>
  );
};
