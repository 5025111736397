import { useState, useCallback, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FETCH_JOBS_BASED_ON_TEAM_ID, useAxiosWrapper } from 'services';
import dayjs from 'dayjs';
import { getCurrencySymbol } from 'components/PriceInput/helper';
import { LinkedCard } from 'pages/ProjectPublic/Components';

export const TeamPublicOpenings = (props) => {
  const { isNotLoggedInUser } = props;
  const [fnWrapper] = useAxiosWrapper();
  const [postedJobs, setPostedJobs] = useState([]);
  const { teamId } = useParams();
  const navigate = useNavigate();

  const getPostedJobList = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_JOBS_BASED_ON_TEAM_ID}/${teamId}`,
      method: 'GET',
      type: 'FETCH_JOBS_BASED_ON_PROJECT_ID',
    });

    if (res.data.statusCode === 200) {
      //console.log(res.data.data);
      const result = res.data.data;
      const allJobs = [];

      result?.map((job) => {
        let profileImg;
        if (job?.team?.enterprise) {
          profileImg =
            job?.team?.enterprise?.profilePicture !== null
              ? job?.team?.enterprise?.profilePicture
              : '';
        } else {
          profileImg =
            job?.team?.user?.individual?.profilePicture !== null
              ? job?.team?.user?.individual?.profilePicture
              : '';
        }
        const item = {
          id: job?.id,
          jobTitle: job?.title,
          owner: job?.team?.enterprise
            ? job?.team?.enterprise?.enterpriseName
            : job?.team?.user?.individual?.name,
          profileImgSrc: profileImg,
          profileIcon: 'project',
          type: 'job',
          //description: job?.jobDescription,
          summary: job?.summary,
          feature: [
            {
              iconName: 'location',
              iconLabel: 'Job Location',
              label:
                job?.regionCountry !== '' && job?.regionCountry !== null
                  ? job?.regionCountry
                  : 'N/A',
            },
            {
              iconName: 'brief-case',
              iconLabel: 'Job Type',
              label: job?.jobType,
            },
            {
              iconName: 'dollar-circle',
              iconLabel: 'Pay Range',
              label:
                job?.currencyType && job?.payRangeFrom && job?.payRangeTo
                  ? `${getCurrencySymbol(job?.currencyType)}${
                      job?.payRangeFrom
                    } - ${job?.payRangeTo}`
                  : 'N/A',
            },
            {
              iconName: 'clock',
              iconLabel: 'Posted on',
              label: dayjs(job?.createdAt).format('DD MMM YYYY'),
            },
          ],
        };
        allJobs.push(item);
        return allJobs;
      });
      setPostedJobs(allJobs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId]);

  const viewHandler = (jobId) => {
    return isNotLoggedInUser
      ? navigate('/login/individual')
      : window.open(
          `/explore/jobs/view-job/${jobId}`,
          'rel=noopener noreferrer'
        );
  };

  useEffect(() => {
    getPostedJobList();
  }, [getPostedJobList]);

  return (
    <div className="main-wrapper">
      <div className="heading">
        Openings <span>({postedJobs?.length})</span>
      </div>
      <div className="content">
        {postedJobs?.length > 0 ? (
          <div className="opening-cards-container">
            {postedJobs?.map((job, index) => (
              <LinkedCard
                key={index}
                title={job.jobTitle}
                type={job.type}
                profileImgSrc={job.profileImgSrc}
                profileIcon={job.profileIcon}
                owner={job.owner}
                description={job?.summary}
                features={job.feature}
                viewHandler={() => viewHandler(job.id)}
              />
            ))}
          </div>
        ) : (
          <div className="no-data-class">No Jobs Found!</div>
        )}
      </div>
    </div>
  );
};
