import { CustomButton } from 'components';
import { PublicationFilterWrapper } from './style';
import { useState } from 'react';
import DatePicker from 'react-datepicker';

export const PublicationFilter = (props) => {
  const { onClear, setState } = props;
  const [dates, setDates] = useState({ startDate: null, endDate: null });

  const dateFilterChangeHandler = (label, value) => {
    setDates((state) => ({
      ...state,
      [label]: value,
    }));
  };

  const dateBasedFilter = () => {
    const modifiedDates = {
      startDate: dates?.startDate?.toISOString(),
      endDate: dates?.endDate?.toISOString(),
    };
    setState(modifiedDates);
  };
  return (
    <PublicationFilterWrapper>
      <div className="publication-filter">
        <div className="filter-options">
          <div className=" filter-date-wrapper">
            <label className="filter-date-label form-label">
              Publishing Date :
            </label>
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={dates.startDate}
              onChange={(date) => dateFilterChangeHandler('startDate', date)}
              selectsStart
              placeholderText="From"
              className="filter-custom-date"
            />
          </div>
          <div className="filter-date-wrapper">
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={dates.endDate}
              onChange={(date) => dateFilterChangeHandler('endDate', date)}
              selectsEnd
              startDate={dates.startDate}
              endDate={dates.endDate}
              minDate={dates.startDate}
              placeholderText="To"
              className="filter-custom-date"
            />
          </div>
        </div>
        <div className="publication-filter-button d-flex gap-3 align-items-center">
          <CustomButton
            onClick={dateBasedFilter}
            label="Apply"
            className="w-100"
            color="themeSecondary"
          />
          <CustomButton
            label="Clear"
            className="w-100"
            color="themeSecondary"
            variant="light"
            onClick={onClear}
          />
        </div>
      </div>
    </PublicationFilterWrapper>
  );
};
