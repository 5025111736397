import React, { useEffect } from 'react';
import { useState } from 'react';
import { LinkedCard } from 'pages/ProjectPublic/Components';
import { FETCH_PUBLICATIONS, FETCH_USER_ENTERPRISE_LIST } from 'services';
import { useAxiosWrapper } from 'services';
import { useParams, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import BgImg from 'assests/images/bgImgPublic.png';
import styled from 'styled-components';
import tokenService from 'services/token.service';

export const TeamPublicPublications = (props) => {
  const { teamId } = useParams();
  const userId = tokenService.getSession()?.id;
  const navigate = useNavigate();
  const { isNotLoggedInUser } = props;
  const [publicationsList, setPublicationsList] = useState([]);
  const [fnWrapper] = useAxiosWrapper();

  const getUserEnterprises = async () => {
    const res = await fnWrapper({
      url: `${FETCH_USER_ENTERPRISE_LIST}/${userId}`,
      method: 'GET',
      type: 'FETCH_USER_ENTERPRISE_LIST',
    });
    return res?.data?.data?.items;
  };

  const getPublicationsList = async () => {
    const res = await fnWrapper({
      url: FETCH_PUBLICATIONS,
      method: 'GET',
      type: 'FETCH_PUBLICATIONS',
      params: {
        teamId: teamId,
        hasPaginated: false,
      },
    });
    return res?.data?.data?.items;
  };

  const getPublicationDetails = async () => {
    const resEnterprise = await getUserEnterprises();

    const resPublications = await getPublicationsList();

    const rez = await resPublications.filter((publication) => {
      if (publication?.privacy === 'Public' || publication?.userId === userId) {
        return publication;
      } else {
        return publication?.UserEnterprise.some((enterprise) => {
          return resEnterprise.some(
            (item) => item?.id === enterprise?.enterprise?.id
          );
        });
      }
    });
    setPublicationsList(rez);
  };

  const viewHandler = (publicationId) => {
    return isNotLoggedInUser
      ? navigate('/login/individual')
      : window.open(
          `/public/publication/${publicationId}`,
          'rel=noopener noreferrer'
        );
  };

  useEffect(() => {
    getPublicationDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="main-wrapper">
      <div className="heading">Publications</div>
      <div className="content">
        {publicationsList?.length > 0 ? (
          <div className="opening-cards-container">
            {publicationsList?.map((item, index) => (
              <LinkedCard
                key={index}
                title={item.title}
                type="publication"
                owner={
                  item?.user?.individual
                    ? item?.user?.individual?.name
                    : item?.user?.smallEnterprise?.enterpriseName
                }
                profileImgSrc={item?.coverImage ? item.coverImage : BgImg}
                profileIcon="publication"
                description={
                  <PublicationCardwrapper>
                    <div
                      className="fixed-width-card"
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  </PublicationCardwrapper>
                }
                features={[
                  {
                    iconName: 'shield-security',
                    iconLabel: 'Privacy',
                    label: item?.privacy,
                  },
                  {
                    iconName: 'clock',
                    iconLabel: 'Published on',
                    label: dayjs(item?.createdAt).format('DD MMM YYYY'),
                  },
                ]}
                viewHandler={() => viewHandler(item.id)}
              />
            ))}
          </div>
        ) : (
          <div className="no-data-class">
            It looks like you haven't published anything yet!
          </div>
        )}
      </div>
    </div>
  );
};

const PublicationCardwrapper = styled.div`
  .fixed-width-card {
    width: 250px !important;
  }
`;
