import { noop } from 'lodash';
import styled from 'styled-components';

const ContentWrapper = styled.div`
  & .content-container {
    position: relative;
    width: 57px;
    & .button-base {
      background: ${({ isSliding }) =>
        isSliding ? 'rgba(10, 189, 0, 0.25)' : 'rgba(126, 143, 154, 0.25)'};
      border-radius: 11.4px;
      width: 55.37px;
      height: 22.8px;
    }

    & .sliding-circle {
      position: absolute;
      width: 32.57px;
      height: 32.57px;
      background: ${({ isSliding }) => (isSliding ? '#0ABD00' : '#7e8f9a')};
      border-radius: 50%;
      top: -5px;
      right: ${({ isSliding }) => (isSliding ? '-5px' : '')};
    }

    &:hover {
      cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    }
  }
`;

export const SlidingButton = ({
  isSliding,
  onClick,
  className = '',
  disabled = false,
}) => {
  return (
    <ContentWrapper
      isSliding={isSliding}
      onClick={disabled ? noop : onClick}
      className={className}
      disabled={disabled}>
      <div className="content-container">
        <div className="button-base"></div>
        <div className="sliding-circle"></div>
      </div>
    </ContentWrapper>
  );
};
