export const onChangeGroupCredential = async (dispatch, fieldName, value) => {
  await dispatch({
    type: 'SET_GROUP_INFO',
    payload1: fieldName,
    payload2: value,
  });
};
export const addGroupQuestion = async (dispatch) => {
  await dispatch({
    type: 'ADD_GROUP_QUESTIONS',
  });
};
export const deleteGroupQuestion = async (dispatch, index) => {
  await dispatch({
    type: 'DELETE_GROUP_QUESTION',
    index: index,
  });
};
export const onChangeGroupQuestion = async (dispatch, value, index) => {
  await dispatch({
    type: 'SET_GROUP_QUESTION',
    value: value,
    index: index,
  });
};
export const clearState = async (dispatch) => {
  await dispatch({ type: 'CLEAR_STATE' });
};
export const setGroupInfoOnUpdate = async (dispatch, info) => {
  await dispatch({ type: 'SET_GROUP_INFO_ON_UPDATE', info: info });
};
