import React from 'react';
import styled from 'styled-components';
import BgImg from 'assests/images/bgImgPublic.png';
import { CustomButton, CustomDropDown } from 'components';
const ROLES = [
  { label: 'Co-Admin', value: 'COADMIN' },
  { label: 'Member', value: 'MEMBER' },
];

export default function InviteMemberCard(props) {
  const { network, inviteMembers, setInviteMembers } = props;
  const [role, setRole] = React.useState(null);
  const addInviteListHandler = (id) => {
    setInviteMembers((prev) => [...prev, { userId: id, role: role?.value }]);
  };
  const removeInviteMemberListHandler = (id) => {
    setInviteMembers(inviteMembers.filter((list) => list.userId !== id));
  };
  return (
    <InviteMemberCardWrapper>
      <div className="picture-email-name">
        <div className="picture-container">
          <img
            className="picture"
            src={
              network.individual.profilePicture === null
                ? BgImg
                : network?.individual?.profilePicture
            }
            alt="network-profile"
          />
        </div>
        <div className="email-name">
          <div className="name">{network?.individual?.name}</div>
          <div className="email">{network?.email}</div>
        </div>
      </div>
      <div className="action-buttons">
        <CustomDropDown
          customStyle={{ paddingRight: '12px', paddingLeft: '8px' }}
          optionLists={ROLES}
          selectedItem={role}
          setSelectedItem={(val) => setRole(val)}
          placeholder={'Select Role'}
        />
        {inviteMembers.filter((invite) => invite.userId === network.id)
          .length ? (
          <CustomButton
            onClick={() => removeInviteMemberListHandler(network.id)}
            label="Remove"
            color="themeTertiary"
            variant="light"
          />
        ) : (
          <CustomButton
            disabled={!role}
            onClick={() => addInviteListHandler(network.id)}
            label="Add"
            color="themeSecondary"
          />
        )}
      </div>
    </InviteMemberCardWrapper>
  );
}

const InviteMemberCardWrapper = styled.div`
  padding: 5px 0px;
  border-bottom: 2px solid #c4c4c4;
  display: flex;
  justify-content: space-between;
  .picture-email-name {
    display: flex;
    column-gap: 10px;
    align-items: center;
    & .picture-container {
      width: 45px;
      height: 45px;
      & .picture {
        width: 100%;
        height: 100%;
        border-radius: 100%;
      }
    }
  }
  .email-name {
    & .name {
      font-size: 17px;
      font-weight: 600;
      font-family: 'Poppins';
    }
  }
  .action-buttons {
    display: flex;
    gap: 5px;
  }
`;
