import React, { useState, useEffect, useCallback } from 'react';
import { SelectedConversationWrapper } from './styles';
import { getProfileText } from 'utlils/utils';
import {
  chatErrorMsg,
  fetchUserDetailsById,
  fetchTeamDetailsById,
} from 'services/twilioService/helper';
import classnames from 'classnames';
import { ConversationMembersListPopup } from './components/ConversationMembersListPopup';
import Icon from 'utlils/Icons/Icon';

export const SelectedConversationHeader = ({
  openChat,
  setOpenChat,
  selectedConversationDetails,
  connectionStatus,
  loggedInUser,
}) => {
  const convoAttributes = selectedConversationDetails?.attributes;

  const [userToShow, setUserToShow] = useState(null);
  const [convoParticipantsList, setConvoParticipantList] = useState(null);
  const [showTeamMembersList, setShowTeamMembersList] = useState(false);
  const isCurrentUserSender = loggedInUser?.id === convoAttributes?.senderId;
  const userToFind = isCurrentUserSender
    ? convoAttributes?.receiverId
    : convoAttributes?.senderId;

  const convoType = convoAttributes?.conversationType;
  const fetchUserData = useCallback(async () => {
    try {
      const userData = await fetchUserDetailsById(userToFind);
      setUserToShow(userData);
    } catch (error) {
      chatErrorMsg('Error fetching user details', error);
    }
  }, [userToFind]);
  const fetchTeamData = useCallback(async () => {
    try {
      const userData = await fetchTeamDetailsById(convoAttributes?.teamId);
      setUserToShow(userData);
    } catch (error) {
      chatErrorMsg('Error fetching user details', error);
    }
  }, [convoAttributes.teamId]);

  const getParticipantsInConversation = async () => {
    try {
      const participantsList =
        await selectedConversationDetails.getParticipants();
      const participantUsers = [];
      for (const participant of participantsList) {
        const userData = await fetchUserDetailsById(
          participant?.state?.identity
        );
        participantUsers.push(userData);
      }
      setConvoParticipantList(participantUsers);
    } catch (error) {
      chatErrorMsg('Error fetching participants details', error);
    }
  };

  useEffect(() => {
    if (convoAttributes && convoAttributes?.conversationType === 'ONE_TO_ONE') {
      fetchUserData();
    }
    if (convoAttributes && convoType === 'TEAM') {
      fetchTeamData();
      getParticipantsInConversation();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConversationDetails]);

  const handleModalClose = () => {
    setShowTeamMembersList(false);
  };

  const currUserNameToShow =
    convoType === 'ONE_TO_ONE'
      ? userToShow?.role === 'INDIVIDUAL'
        ? userToShow?.name
        : userToShow?.enterpriseName
      : userToShow?.teamName;

  const currUserProfilePictureToShow = userToShow?.profilePicture;
  return (
    <SelectedConversationWrapper>
      <div className="conversation-title-container">
        <span
          className="chats-icon"
          onClick={() => {
            setOpenChat(!openChat);
          }}>
          <Icon name={openChat ? 'arrow-left' : 'arrow-right'} />
        </span>
        <div className="image-heading-container">
          <div className="Conversation__img">
            {currUserProfilePictureToShow === null ||
            currUserProfilePictureToShow === '' ? (
              <div className="prof-text-wrapper">
                <span className="prof-text">
                  {getProfileText(currUserNameToShow)}
                </span>
              </div>
            ) : (
              <img alt="profile-pic" src={currUserProfilePictureToShow} />
            )}
          </div>

          {openChat ? (
            <div className="chat-options-heading">Messages</div>
          ) : (
            <div className="conversation-heading-container">
              <div className="conversation-heading">{currUserNameToShow}</div>
              {convoType === 'TEAM' && (
                <div
                  className="conversation-members"
                  onClick={() => setShowTeamMembersList(!showTeamMembersList)}>
                  {convoParticipantsList && convoParticipantsList?.length}{' '}
                  Members
                </div>
              )}
            </div>
          )}
        </div>

        <div className="conversation-status">
          <div
            className={classnames({
              'red-dot': connectionStatus !== 'You are connected',
              'green-dot': connectionStatus === 'You are connected',
            })}></div>
          <div>{connectionStatus}</div>
        </div>
      </div>
      {/* render team members list component */}
      {showTeamMembersList && (
        <ConversationMembersListPopup
          handleModalClose={handleModalClose}
          convoParticipantsList={convoParticipantsList}
        />
      )}
    </SelectedConversationWrapper>
  );
};
