import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { easingPropType } from './propTypes';

const animation = (props) => `
  animation: fadeAnimation ${props.fadeDuration}ms ${props.fadeEasing} 0s 1;
  @keyframes fadeAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

// prettier-ignore
const Base = (props) => css`
  position: absolute;
  ${props.fadeDuration && props.fadeDuration > 0 && animation(props)};
  ${props.zIndex && `z-index: ${props.zIndex}`};
`

const Top = (props) => css`
  ${Base(props)};
  bottom: 100%;
  left: 50%;
  transform: translateX(-${props.gutter}%);

  margin-bottom: ${props.offset}px;
`;

const Bottom = (props) => css`
  ${Base(props)};
  top: 100%;
  left: 50%;
  transform: translateX(-${props.gutter}%);
  margin-top: ${props.offset}px;
`;

const Left = (props) => css`
  ${Base(props)};
  right: 100%;
  top: 50%;
  transform: translateY(-${props.gutter}%);
  margin-right: ${props.offset}px;
`;

const Right = (props) => css`
  ${Base(props)};
  left: 100%;
  top: 50%;
  transform: translateY(-${props.gutter}%);
  margin-left: ${props.offset}px;
`;
const TopLeft = (props) => css`
  ${Base(props)};
  bottom: 100%;
  right: 50%;
  transform: translateX(20%);
  margin-bottom: ${props.offset}px;
`;
const BottomLeft = (props) => css`
  ${Base(props)};
  top: 100%;
  left: 50%;
  transform: translateX(-90%);
  margin-top: ${props.offset}px;
`;
const BottomRight = (props) => css`
  ${Base(props)};
  top: 100%;
  right: 50%;
  transform: translateX(85%);
  margin-top: ${props.offset}px;
`;
const BaseToolTop = ({ fn, children, ...props }) => (
  <Div propResolver={() => fn(props)}>{children}</Div>
);
const Div = styled.div`
  ${({ propResolver }) => propResolver()}
`;

BaseToolTop.propTypes = {
  fn: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  offset: PropTypes.number,
  open: PropTypes.bool,
  zIndex: PropTypes.number,
  fadeEasing: easingPropType,
  fadeDuration: PropTypes.number,
};

const tooltips = {
  left: ({ children, ...props }) =>
    BaseToolTop({ fn: Left, children, ...props }),
  top: ({ children, ...props }) => BaseToolTop({ fn: Top, children, ...props }),
  right: ({ children, ...props }) =>
    BaseToolTop({ fn: Right, children, ...props }),
  topLeft: ({ children, ...props }) =>
    BaseToolTop({ fn: TopLeft, children, ...props }),
  bottomLeft: ({ children, ...props }) =>
    BaseToolTop({ fn: BottomLeft, children, ...props }),
  bottom: ({ children, ...props }) =>
    BaseToolTop({ fn: Bottom, children, ...props }),
  bottomRight: ({ children, ...props }) =>
    BaseToolTop({ fn: BottomRight, children, ...props }),
};

const Tooltip = ({
  children,
  offset,
  open,
  placement,
  zIndex,
  fadeDuration,
  fadeEasing,
  gutter = 50,
}) => {
  const Component = tooltips[placement] || tooltips.top;
  return (
    open && (
      <Component
        offset={offset}
        gutter={gutter}
        zIndex={zIndex}
        fadeDuration={fadeDuration}
        fadeEasing={fadeEasing}>
        {children}
      </Component>
    )
  );
};

Tooltip.propTypes = {
  children: PropTypes.any.isRequired,
  offset: PropTypes.number,
  open: PropTypes.bool,
  placement: PropTypes.string,
  gutter: PropTypes.any,
  zIndex: PropTypes.number,
  fadeEasing: easingPropType,
  fadeDuration: PropTypes.number,
};

export default Tooltip;
