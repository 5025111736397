export const setLoading = (dispatch, status) =>
  dispatch({ type: 'SET_LOADING', payload: status });

export const setProgressStep = async (dispatch, step) => {
  await dispatch({ type: 'SET_PROGRESS', payload: step });
};

export const setProjectDetails = async (dispatch, fieldName, value) => {
  await dispatch({
    type: 'SET_PROJECT_DETAILS',
    field: fieldName,
    payload: value,
  });
};

export const clearState = async (dispatch) => {
  await dispatch({ type: 'CLEAR_STATE' });
};
