import { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import axios from 'axios';
import InviteImg from '../../../assests/images/building.png';
import { CustomButton, LabelWithLink, Loader, NavbarBrand1 } from 'components';
import { getProfileText } from '../../../utlils/utils';
import TokenService from 'services/token.service';
import {
  FETCH_TEAM_BASED_ON_MEMBER,
  FETCH_USER_PROFILE,
  useAxiosWrapper,
} from 'services';
import { TeamInvitationWrapper } from './styles';
import { TeamInvitationActionModal } from './teamInvitationActionModal';
import { INDIVIDUAL_DASHBOARD_NAV_LIST } from 'pages/IndividualDashboard/constants';

const DEFAULT_ACTION_STATE = {
  step: null,
  showModal: false,
};

export const TeamInvitation = ({ currentLocale, handleLocaleChange }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  let redirectUri = location.pathname + location.search;

  const [fnWrapper] = useAxiosWrapper();

  const [loading, setloading] = useState(true);
  const [userProfile, setUserProfile] = useState({});
  const [teamDetails, setTeamDetails] = useState(null);
  const [action, setAction] = useState(DEFAULT_ACTION_STATE);

  const getUserProfile = useCallback(async () => {
    const loggedInUser = TokenService.getSession();
    const res = await fnWrapper({
      url: `${FETCH_USER_PROFILE}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_USER_PROFILE',
    });
    if (res.data.statusCode === 200) {
      const response = res.data.data;
      setUserProfile({
        ...response,
        profileText: getProfileText(
          response.name ? response.name : response.enterpriseName
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTeamDetails = useCallback(async (id, inviteToken) => {
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/${FETCH_TEAM_BASED_ON_MEMBER}/${id}`,
          {
            headers: {
              'x-access-token': `${inviteToken}`,
            },
          }
        )
        .then((res) => {
          if (res?.data?.statusCode === 200) {
            setTeamDetails(res?.data?.data);
          }
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, []);

  const navigationToHandler = useCallback(
    async (paramsList) => {
      try {
        const session = TokenService.getSession();
        if (session) {
          await getUserProfile();
        }
        await getTeamDetails(paramsList.mid, paramsList.tk);
        setloading(false);
      } catch (error) {
        setloading(false);
        toast.error(error, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    },
    [getTeamDetails, getUserProfile]
  );

  useEffect(() => {
    const paramsList = Object.fromEntries([...searchParams]);
    navigationToHandler(paramsList);
  }, [navigationToHandler, searchParams]);

  const acceptOrRejectHandler = useCallback(
    async (key) => {
      const session = TokenService.getSession();
      if (session) {
        setAction({
          step: key,
          showModal: true,
        });
      } else {
        navigate('/login/individual', {
          replace: true,
          state: { redirectUri: redirectUri, type: 'INDIVIDUAL' },
        });
      }
    },
    [navigate, redirectUri]
  );

  const onCloseModal = useCallback(() => setAction(DEFAULT_ACTION_STATE), []);

  return loading ? (
    <Loader loading={loading} />
  ) : (
    <>
      <NavbarBrand1
        navMenu={INDIVIDUAL_DASHBOARD_NAV_LIST(userProfile?.role)}
        currentLocale={currentLocale}
        handleLocaleChange={handleLocaleChange}
        userProfile={userProfile}
      />
      {/* Team Invitation Code */}
      <TeamInvitationWrapper className="tiw">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7">
              <div className="tiw__main-wrapper">
                <>
                  <div className="tiw__page-title">
                    <span>Team Invitation </span>
                  </div>
                  {teamDetails && (
                    <>
                      <div className="tiw__details">
                        <img
                          src={InviteImg}
                          alt="No Data"
                          className="bg-img mb-2"
                        />
                        <div className="tiw__details__container">
                          <span className="tiw__details__container__subtitle-1">
                            {`You are Invited to`}
                          </span>
                          <div className="tiw__details__container__subtitle-2">
                            {teamDetails?.team?.teamName}
                          </div>
                          <div className="tiw__details__container__subtitle-3">
                            By{' '}
                            {teamDetails?.team?.department === null
                              ? teamDetails?.invitedUser?.individual?.name
                              : teamDetails?.team?.department?.enterprise
                                  ?.enterpriseName}
                          </div>
                          <div>
                            <span className="tiw__details__container__subtitle-6">
                              Topic:
                              <span className="tiw__details__container__subtitle-4">
                                {' '}
                                {teamDetails?.team?.topic}
                              </span>
                            </span>
                          </div>
                          <div className="tiw__details__container__subtitle-5">
                            Requested to join as {teamDetails?.role}
                          </div>
                          <div className="tiw__details__container__subtitle-5">
                            {dayjs(teamDetails?.createdAt).format('DD/MM/YYYY')}
                          </div>
                        </div>
                      </div>
                      <div className="tiw__footer">
                        <LabelWithLink
                          label="Click here to view team"
                          linkTo={`/public/team/${teamDetails?.team?.id}`}
                        />
                        <div className="tiw__footer__action">
                          <CustomButton
                            label="Accept"
                            color="themeSecondary"
                            onClick={() => acceptOrRejectHandler('ACCEPT')}
                            customPadding="10px 14px"
                          />
                          <CustomButton
                            label="Reject"
                            color="themeTertiary"
                            variant="light"
                            customPadding="10px 14px"
                            onClick={() => acceptOrRejectHandler('REJECT')}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      </TeamInvitationWrapper>
      {action.showModal && (
        <TeamInvitationActionModal
          actionState={action}
          onClose={onCloseModal}
          redirectUri={redirectUri}
          teamDetails={teamDetails}
          userSession={userProfile}
        />
      )}
    </>
  );
};
