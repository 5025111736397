import styled from 'styled-components';

export const RecieveTextMessageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 16px;

  .profile-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    background-color: #263238;
    .prof-text {
      color: white;
      font-weight: 500;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .message-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .message-text-wrapper {
      display: flex;
      align-items: center;
      .message-text {
        padding: 5px 15px;
        margin: 0;
        background-color: #263238;
        border-radius: 0 10px 10px 10px;
        color: white;
        word-break: break-word;
      }
      .message-timeStamp {
        margin-left: 10px;
        color: #7c8b9f;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .message-author {
    color: #404f67;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 3px;
  }
`;

export const AttachmentWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding: 5px 15px;
  margin: 0;
  background-color: #263238;
  border-radius: 0 10px 10px 10px;
  color: white;
  word-break: break-word;
  height: 80px;
  .attachment-icon {
    background-color: #3d5058;
    padding: 10px;
  }

  .attachment-details {
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    .attachment-filename {
      font-size: 14px;
      font-weight: 600;
    }
    .attachment-size {
      font-size: 14px;
    }
  }

  .attachment-download {
    margin-left: 10px;
    cursor: pointer;
    color: #ffffff;
    background-color: #3d5058;
    padding: 10px;
    border-radius: 50%;
  }
`;

export const ImageAttachmentWrapper = styled.div`
  background-color: #263238;
  border-radius: 0 10px 10px 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .image-download {
    margin-left: 10px;
    cursor: pointer;
    color: #ffffff;
    background-color: #3d5058;
    padding: 10px;
    border-radius: 50%;
    border: none;
    margin-top: 10px;
  }
  .image-wrapper {
    width: 400px;
    .skeleton-loader {
      width: 400px;
      height: 400px;
      background-color: #c5c5c5;
      animation-name: skeletonLoading;
      animation-iteration-count: infinite;
      animation-duration: 3s;
    }

    @keyframes skeletonLoading {
      0% {
        background-color: #bdbdc0;
      }
      50% {
        background-color: #79797d;
      }
      100% {
        background-color: #bdbdc0;
      }
    }
    img {
      max-width: 100%;
    }
  }
`;

export const VideoMessageWrapper = styled.div`
  background-color: #263238;
  border-radius: 0 10px 10px 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .video-download {
    margin-left: 10px;
    cursor: pointer;
    color: #ffffff;
    background-color: #3d5058;
    padding: 10px;
    border-radius: 50%;
    display: inline-block;
  }
  .video-wrapper {
    width: 400px;
    .recieved-video-loader {
      color: white;
      text-align: center;
    }
    img {
      max-width: 100%;
    }
  }
`;
