import { useCallback, useState } from 'react';
import Icon from 'utlils/Icons/Icon';
import { getDateTime, getProfileText } from 'utlils/utils';
import { TaskComments } from './taskComments';
import { checkForFileType } from 'pages/IndividualDashboard/individualOrganizations/enterprise/helper';
import {
  FETCH_TASK_COMMENTS,
  POST_TASK_COMMENTS,
  useAxiosWrapper,
} from 'services';
import tokenService from 'services/token.service';
import { PostTasksWrapper, SingleTaskWrapper } from './style';

export const PostTasks = ({
  tasks,
  isLinkedStream,
  isUserCommonMember = false,
  ...rest
}) => {
  return (
    <PostTasksWrapper>
      {tasks && tasks.length !== 0 ? (
        tasks.map((task, index) => {
          return (
            <SingleTask
              key={index}
              taskData={task}
              linkedStream={isLinkedStream}
              isUserCommonMember={isUserCommonMember}
              {...rest}
            />
          );
        })
      ) : (
        <div>No Tasks Available!</div>
      )}
    </PostTasksWrapper>
  );
};

const SingleTask = ({
  height,
  width,
  linkedStream = false,
  taskData,
  isUserCommonMember = false,
  isCurrentStageFinished,
  projectId,
  userProfile,
}) => {
  const [fnWrapper, context] = useAxiosWrapper();
  const [commentText, setCommentText] = useState('');
  const [showComments, setShowComments] = useState(false);
  const [taskComments, setTaskComments] = useState([]);

  const getTaskCommentsList = useCallback(async () => {
    const payload = {
      taskId: taskData?.id,
    };
    const res = await fnWrapper({
      url: `${FETCH_TASK_COMMENTS}`,
      method: 'GET',
      type: 'FETCH_TASK_COMMENTS',
      params: payload,
    });

    if (res.data.statusCode === 200) {
      const result = res.data.data;
      const commentsList = result?.map((comment) => {
        const userName =
          comment?.user?.individual === null
            ? comment?.user?.smallEnterprise?.enterpriseName
            : comment?.user?.individual?.name;
        const pfp =
          comment?.user?.individual === null
            ? comment?.user?.smallEnterprise?.profilePicture
            : comment?.user?.individual?.profilePicture;

        const mapComments = {
          id: comment.id,
          commenterPic: pfp,
          commenterText: getProfileText(userName),
          commenterName: `${userName}`,
          commentedAt: comment.createdAt,
          text: comment.commentMessage,
        };
        return mapComments;
      });
      setTaskComments(commentsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskData?.id]);

  const onPostTaskCommentHandler = async () => {
    const loggedInUser = await tokenService.getSession();

    const payload = {
      taskId: taskData?.id,
      commentMessage: commentText,
      projectId: projectId,
      taskType: 'PROJECT_TASK',
      userId: loggedInUser.id,
    };

    const res = await fnWrapper({
      url: `${POST_TASK_COMMENTS}`,
      method: 'POST',
      type: 'POST_TASK_COMMENTS',
      payload: payload,
    });

    if (res.data.statusCode === 200) {
      await getTaskCommentsList();
      setCommentText('');
    }
  };

  const getFileName = (file) => {
    const setFileName = file.split('/');
    return setFileName[setFileName.length - 1];
  };

  return (
    <SingleTaskWrapper
      height={height}
      width={width}
      showComments={showComments}>
      <div className="post-wrapper">
        {/* header */}
        <div className="posts-header">
          <div className="left-side-class">
            <div className="profile-pic-container">
              {linkedStream && !isUserCommonMember ? (
                <Icon
                  name={'user-icon'}
                  className="user-icon-class"
                  color="#292D32"
                />
              ) : (
                <>
                  {taskData.imgSrc !== '' && taskData.imgSrc !== null ? (
                    <img alt="pfp-pic" src={taskData.imgSrc} width={'100%'} />
                  ) : (
                    <div className="pfp-alt-circle">
                      <span className="pfp-alt">{taskData.profileText}</span>
                    </div>
                  )}
                </>
              )}
            </div>
            <div
              className="user-name"
              style={{ paddingTop: `${linkedStream ? '20px' : '15px'}` }}>
              <p className="user-name-style">
                {linkedStream && !isUserCommonMember
                  ? 'Anonymous'
                  : taskData.userName}
              </p>
              {(!linkedStream || isUserCommonMember) && (
                <span className="user-posted-time">
                  {getDateTime(taskData.postedTime)}
                </span>
              )}
            </div>
          </div>
        </div>

        {/* content */}

        <div className="post-content-container">
          <div className="content-heading">
            Assigned{' '}
            <span style={{ textDecoration: 'underline' }}>
              {taskData.taskName}
            </span>{' '}
            task to{' '}
            <span style={{ color: '#219fff' }}>{taskData.teamName}</span> Team.
          </div>
          <div className="content-description">{taskData.description}</div>
          {taskData?.attachments && taskData?.attachments?.length !== 0 && (
            <div className="post-attachment">
              {taskData?.attachments.map((elem, index) => {
                const fileName = getFileName(elem);
                return (
                  <div className="img-wrapper" key={index}>
                    <div className="img-container">
                      {checkForFileType(fileName) === 'others' ? (
                        <Icon name="attachment" />
                      ) : (
                        <img src={checkForFileType(fileName)} alt="word" />
                      )}
                    </div>
                    <a
                      className="img-text"
                      href={elem}
                      target="_blank"
                      rel="noreferrer">
                      {fileName}
                    </a>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        {/* footer */}
        <div className="post-footer">
          <div className="footer-button-box">
            <Icon
              name="comment-btn"
              color={showComments ? '#219fff' : '#7E8F9A'}
              size={20}
              className="icon-button"
              onClick={async () => {
                if (!showComments) {
                  await getTaskCommentsList();
                }
                setShowComments(!showComments);
              }}
            />
            <div>{taskComments.length > 0 && taskComments.length}</div>
          </div>
        </div>
        {showComments && (
          <TaskComments
            commentText={commentText}
            isLoading={
              context.busy.status && context.busy.type === 'FETCH_TASK_COMMENTS'
            }
            isCurrentStageFinished={isCurrentStageFinished}
            isUserCommonMember={isUserCommonMember}
            linkedStream={linkedStream}
            onPostTaskComment={onPostTaskCommentHandler}
            setCommentText={setCommentText}
            taskComments={taskComments}
            userProfile={userProfile}
          />
        )}
      </div>
    </SingleTaskWrapper>
  );
};
