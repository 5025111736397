import { CustomButton, Loader, ModalPopup, ScrollableDiv } from 'components';
import MemberInfoCard from '../memberInfoCard';
import { ViewEnterpriseMemberWrapper } from './style';
import { ProfilePhoto } from 'pages/Explore/Components';
import { useCallback, useEffect, useState } from 'react';
import {
  FETCH_TEAMS_BASED_ON_USER,
  FETCH_PROJECTS_BASED_ON_USER,
  useAxiosWrapper,
  REMOVE_DEPARTMENT_MEMBER,
} from 'services';
import tokenService from 'services/token.service';
import RemoveMember from '../removeMember';
import MemberInfoBox from '../memberInfoBox';
import { INFO_TEXT } from '../../constants';
import Icon from 'utlils/Icons/Icon';

const ViewMember = ({ selectedMember, onClose }) => {
  const [fnWrapper, { busy }] = useAxiosWrapper();
  const [teamList, setTeamList] = useState([]);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [projectList, setProjectList] = useState([]);

  const loadTeamList = useCallback(async () => {
    if (!selectedMember?.memberUserId) {
      return;
    }
    const res = await fnWrapper({
      url: `${FETCH_TEAMS_BASED_ON_USER}/${selectedMember?.memberUserId}`,
      params: {
        teamType: 'enterprise',
        enterpriseId: tokenService.getSession()?.enterpriseId,
      },
      method: 'GET',
      type: 'FETCH_TEAMS_BASED_ON_USER',
    });

    if (res.data.statusCode === 200) {
      setTeamList(res.data.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const loadProjectList = useCallback(async () => {
    if (!selectedMember?.memberUserId) {
      return;
    }
    const res = await fnWrapper({
      url: `${FETCH_PROJECTS_BASED_ON_USER}/${selectedMember?.memberUserId}`,
      params: {
        projectType: 'enterprise',
        enterpriseId: tokenService.getSession()?.enterpriseId,
      },
      method: 'GET',
      type: 'FETCH_PROJECTS_BASED_ON_USER',
    });
    if (res.data.statusCode === 200) {
      setProjectList(res.data.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMember?.memberUserId]);

  const handleRemoveMember = useCallback(async () => {
    const res = await fnWrapper({
      url: REMOVE_DEPARTMENT_MEMBER,
      method: 'PUT',
      type: 'REMOVE_DEPARTMENT_MEMBER',
      payload: { memberId: selectedMember?.id },
    });
    if (res.data.statusCode === 200) {
      setIsRemoveModalOpen(false);
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMember?.id]);

  useEffect(() => {
    loadTeamList();
    loadProjectList();
  }, [loadTeamList, loadProjectList]);

  return (
    <ViewEnterpriseMemberWrapper>
      <ModalPopup
        open={false}
        onClose={onClose}
        maxContentWidth="900px"
        title={
          <div className="title-header-section">
            <div>
              <ProfilePhoto
                profilePicture={
                  selectedMember?.memberUser?.individual?.profilePicture
                }
                name={selectedMember?.memberName}
                size={60}
              />
            </div>
            <div className="title-user-info">
              <p className="title-user-name">{selectedMember?.memberName}</p>
              <div className="title-user-email-remove">
                <p className="title-email-text">{selectedMember?.email}</p>
                <div className="title-remove-button">
                  <CustomButton
                    className="text-decoration-underline"
                    color="themeTertiary"
                    disabled={
                      teamList?.length !== 0 || projectList?.length !== 0
                    }
                    variant="textBtn"
                    onClick={() => setIsRemoveModalOpen(true)}>
                    Remove member
                    <Icon name="delete" color="#ff5157" size={18} />
                  </CustomButton>
                </div>
              </div>
            </div>
          </div>
        }>
        {(!busy?.status && teamList?.length !== 0) ||
        projectList?.length !== 0 ? (
          <MemberInfoBox
            text={INFO_TEXT.MEMBER_IN_TEAM_INFO}
            iconName={'info-circle'}
            type={'WARNING'}
          />
        ) : null}
        {!busy?.status &&
        teamList?.length === 0 &&
        projectList?.length === 0 ? (
          <MemberInfoBox
            text={INFO_TEXT.MEMBER_FREE_TEXT}
            iconName={'info-circle'}
            type={'SUCCESS'}
          />
        ) : null}
        {busy?.status ? (
          <Loader loading={busy?.status} />
        ) : teamList?.length !== 0 || projectList?.length !== 0 ? (
          <ScrollableDiv>
            <div className="teams-section">
              <p className="section-title">Teams ({teamList?.length})</p>
              <div className="team-info-cards">
                {teamList?.map((team, index) => (
                  <MemberInfoCard
                    iconName="group-user"
                    key={index}
                    name={team?.teamName}
                    {...team?.teamMembers?.find(
                      (member) =>
                        member?.memberUserId === selectedMember?.memberUserId
                    )}
                  />
                ))}
              </div>
            </div>
            <div className="projects-section">
              <p className="section-title">Projects ({projectList?.length})</p>
              <div className="project-info-cards">
                {projectList?.map((project, index) => (
                  <MemberInfoCard
                    iconName="project"
                    key={index}
                    name={project?.title}
                    role={project?.projectRole}
                    designation={project?.teams?.map((team, index) =>
                      +index === project?.teams?.length - 1
                        ? team?.teamName
                        : team?.teamName + ', '
                    )}
                  />
                ))}
              </div>
            </div>
          </ScrollableDiv>
        ) : null}
      </ModalPopup>
      {isRemoveModalOpen ? (
        <RemoveMember
          handleRemoveMember={handleRemoveMember}
          onClose={() => setIsRemoveModalOpen(false)}
        />
      ) : null}
    </ViewEnterpriseMemberWrapper>
  );
};

export default ViewMember;
