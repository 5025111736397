import styled from 'styled-components';
export const EnterpriseCareerWrapper = styled.div`
  & .sub-container {
    @media (max-width: 1199px) {
      margin: 25px 0;
    }
    & .main-wrapper {
      border-radius: 12px;
      padding-bottom: 28px;
      background-color: white;
      position: relative;
      & .heading {
        padding: 28px;
        border-bottom: 1px solid #e9eef4;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        color: #17191d;
        border-bottom: 1px solid #e9eef4;
        padding-bottom: 20px;
        & span {
          font-weight: 600;
          font-size: 23px;
          color: #404f67;
        }
      }
    }
  }
`;
