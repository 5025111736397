import PropTypes from 'prop-types';
import { CustomTable, ScrollableDiv } from 'components';
import { APPLICANTLISTTABLEHEADERS } from '../constant';

export const ApplicantList = (props) => {
  const { list, isLoading } = props;
  return list.length ? (
    <ScrollableDiv>
      <CustomTable
        isLoading={isLoading}
        rows={list}
        headers={APPLICANTLISTTABLEHEADERS}
        hasCheckbox={true}
        className="jobs-customTable"
      />
    </ScrollableDiv>
  ) : null;
};

ApplicantList.propTypes = {
  list: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  shortlistCandidate: PropTypes.func,
};
