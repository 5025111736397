import React from 'react';
import { TeamSettingsWrapper } from './style';
import { CustomButton, UploadButton } from 'components';
import Icon from 'utlils/Icons/Icon';
import { useState } from 'react';
import { UPDATE_TEAM_PROFILE_PICTURE, useAxiosWrapper } from 'services';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

export const ProfileSetting = (props) => {
  const { teamInfo, setTeamData } = props;
  const [profilePicture, setProfilePicture] = useState(null);
  const [displayImage, setDisplayImage] = useState(
    teamInfo?.profilePicture ? teamInfo?.profilePicture : null
  );
  const [fnWrapper, context] = useAxiosWrapper();
  let { teamId } = useParams();
  function byteToMB(byteValue) {
    return byteValue / 1024 ** 2;
  }

  const onChangeHandler = async (e) => {
    if (
      e.target.files &&
      e.target.files[0] &&
      byteToMB(e.target.files[0].size) < byteToMB(1000000)
    ) {
      setDisplayImage(URL.createObjectURL(e.target.files[0]));
      setProfilePicture(e.target.files[0]);
    } else {
      toast.warning('Image size should be less than 1MB', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  const updateProfilePicureHandler = async () => {
    const formData = new FormData();
    formData.append('profile', profilePicture);
    formData.append('teamId', teamId);

    const res = await fnWrapper({
      url: UPDATE_TEAM_PROFILE_PICTURE,
      method: 'PUT',
      type: 'UPDATE_TEAM_PROFILE_PICTURE',
      payload: formData,
    });
    if (res.data.statusCode === 200) {
      setTeamData((prevState) => ({
        ...prevState,
        profilePicture: res.data.data,
      }));
      toast.success(res.data.message, {
        position: 'top-right',
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  return (
    <TeamSettingsWrapper>
      <div className="profile-setting-main">
        <p className="page-title">Profile Settings</p>
        <p className="info-text">Update Profile Picture</p>
        <div className="upload-profile-picture-section">
          {displayImage ? (
            <img
              src={displayImage}
              alt="profile_picture"
              className={
                displayImage ? 'profile-picture-border' : 'profile-picture'
              }
            />
          ) : (
            <div className="no-profile-placeholder">
              <Icon name="people" color="#000" size={40} />
            </div>
          )}
          <UploadButton
            color="themeSecondary"
            variant="light"
            customPadding="12px 25px"
            onChange={async (e) => {
              e.stopPropagation();
              await onChangeHandler(e);
            }}>
            <Icon name="arrow-up-two" color="#219fff" />
            Upload Picture
          </UploadButton>
        </div>
        <CustomButton
          customPadding="16px 67px"
          className="save-button"
          label="Save"
          onClick={() => updateProfilePicureHandler()}
          loading={
            context.busy.type === 'UPDATE_TEAM_PROFILE_PICTURE' &&
            context.busy.status
          }
          disabled={!displayImage}
        />
      </div>
    </TeamSettingsWrapper>
  );
};
