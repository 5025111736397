import styled from 'styled-components';

export const MemberInfoBoxWrapper = styled.div`
  & .info-box {
    display: flex;
    border-radius: 5px;
    padding: 16px;
    margin: 12px 0 24px 0;
    & .info-icon-wrapper {
      margin: 0 12px 0 0;
    }
    span {
      font-style: italic;
      font-weight: 400;
      font-size: 16px;
    }
  }
`;
