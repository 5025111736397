import { CustomButton } from 'components';
import { useEffect, useRef } from 'react';
import { isMobile } from 'utlils';

export const CollaborationDetails = ({
  nextPage,
  selectedCollaboration,
  setScrollDivHeight,
}) => {
  const ref = useRef();
  useEffect(() => {
    if (ref?.current) {
      const newHeight = ref?.current?.clientHeight + 45;
      if (newHeight < 500) {
        setScrollDivHeight(newHeight);
      } else {
        setScrollDivHeight('calc(100vh - 250px)');
      }
    }
  }, [setScrollDivHeight]);
  return (
    <div ref={ref}>
      <div className="main-content">
        <div className="section">
          <p className="sub-heading">About this collaboration</p>
          <p className="text-content">
            {selectedCollaboration?.collaborationDescription}
          </p>
        </div>
        <div className="section">
          <p className="sub-heading">Collaboration offerings</p>
          <p className="text-content">
            {selectedCollaboration?.collaborationOfferings}
          </p>
        </div>
        <div className={isMobile() ? '' : 'row'}>
          <div className="table-heading col-md-4">
            <p>Collaboration type</p>
            <p className="table-element">
              {selectedCollaboration?.collaborationType}
            </p>
          </div>
          <div className="table-heading col-md-4">
            <p>Collaboration category</p>
            <p className="table-element">
              {selectedCollaboration?.collaborationCategory}
            </p>
          </div>
          <div className="table-heading col-md-4">
            <p>Goal of collaboration</p>
            <p className="table-element">
              {selectedCollaboration?.collaborationGoal}
            </p>
          </div>
        </div>
        <div className="section">
          <p className="sub-heading">Collaboration requirement</p>
          <p className="text-content">
            {selectedCollaboration?.collaborationRequirements}
          </p>
        </div>
      </div>
      <div className="popup-button">
        <CustomButton
          label={'Continue'}
          color="themeSecondary"
          onClick={nextPage}
          className="w-100"
        />
      </div>
    </div>
  );
};
