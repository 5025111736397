import { Button } from 'components';
import styled from 'styled-components';
import FeatureSubFooterImage from '../../../../assests/images/homepage/featurepage/extra/sub-footer-image.png';
import { useNavigate } from 'react-router-dom';

const FeatureSubFooterWrapper = styled.div`
  padding: 5em 0;

  & .fsfw__ {
    &wrp {
      background: #292d32;
      box-shadow: 0px 15px 30px rgba(38, 50, 56, 0.45);
      border-radius: 30px;
      padding: 28px;
      color: #ffffff;
    }
  }

  & .sub-footer-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;

    @media (max-width: 506px) {
      display: block;
    }
  }
  & .sub-footer-heading {
    text-align: center;
    font-weight: 600;
    font-size: 30px;
    @media (max-width: 991px) {
      font-size: 18px;
    }
  }
  & .image-section {
    width: 60%;
    @media (max-width: 991px) {
      margin-bottom: 20px;
      width: 100%;
    }
  }
`;

export const FeatureSubFooter = ({ content }) => {
  const navigate = useNavigate();

  return (
    <FeatureSubFooterWrapper className="fsfw">
      <div className="container">
        <div className="row">
          <div className="fsfw__wrp">
            <div className="row align-items-center">
              <div className="col-12 col-md-4">
                <img
                  src={FeatureSubFooterImage}
                  alt={'Content Box'}
                  className="image-section"
                />
              </div>
              <div className="col-12 col-md-8">
                <p className="sub-footer-heading">{content?.heading}</p>
                <div className="sub-footer-buttons">
                  <Button
                    label={'Get Started'}
                    backgroundColor="#219FFF"
                    buttonStyle={{
                      borderRadius: '10px',
                      padding: '20px',
                      marginBottom: '12px',
                    }}
                    onClick={() => navigate(content.action)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FeatureSubFooterWrapper>
  );
};
