import styled from 'styled-components';

export const PriceInputStyle = styled.div`
  max-width: 220px;
  display: flex;
  position: relative;
  font-family: 'Open Sans', 'Poppins';
  align-items: center;
  .priceInput-wrapper {
    &__currency {
      height: 52px;
      width: 53px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      background: #219fff;
      border-radius: 12px 0 0 12px;
      width: 53px;
      font-weight: 500;
      font-size: 18px;
      color: #ffffff;
      &__dropDownIcon {
        cursor: pointer;
        transform: ${({ isDropDownShow }) =>
          isDropDownShow ? 'rotate(180deg)' : ''};
        transition: 300ms transform;
        & .disabled {
          cursor: not-allowed;
        }
      }
      & .disabled {
        cursor: not-allowed;
      }
    }
    &__inputBox {
      height: 52px;
      width: calc(100% - 53px);
      background: #ffffff;
      border: 2px solid #e9eef4;
      border-width: 2px 2px 2px 0;
      border-radius: 0 12px 12px 0;
      &__input {
        width: 90%;
        height: 100%;
        margin-left: 5px;
        border: none;
        &:focus {
          outline: none;
          border: none;
        }
        &:disabled {
          cursor: not-allowed;
          margin-left: 0;
          width: 100%;
        }
      }
    }
    &__options {
      max-height: ${({ isDropDownShow }) => (isDropDownShow ? '500px' : '0px')};
      height: auto;
      transition: max-height 0.3s ease-out;
      width: 60px;
      background: white;
      z-index: 100;
      position: absolute;
      margin-top: 170px;
      box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.06);
      overflow: hidden;
      &__item {
        display: flex;
        padding: 5px 10px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:hover {
          background: #219fff;
          color: white;
        }
      }
    }
  }
`;
