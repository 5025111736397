import { useState } from 'react';
import { Input } from '../inputBox';
import styled from 'styled-components';
import { isValidName } from '../../helpers';

const DEFAULT = {
  fullName: '',
  email: '',
  password: '',
  confirmPassword: '',
  hasPasswordEntered: false,
  hasCPasswordEntered: false,
  error: {},
};

const FormExample = () => {
  const [state, setState] = useState(DEFAULT);
  const {
    fullName,
    email,
    password,
    confirmPassword,
    hasCPasswordEntered,
    hasPasswordEntered,
    error,
  } = state;

  const handleInputTextChange = (field = '', value = null) => {
    setState({
      ...state,
      [field]: value,
      error: {
        ...error,
        fullName:
          field === 'fullName' &&
          !isValidName(value) &&
          'Please enter a valid name',
      },
    });
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h4 className="text-success text-center p-5">
            Form component example
          </h4>
          <Row>
            <Input
              label="Full Name"
              placeholder="Enter full name"
              type="text"
              required
              value={fullName}
              onChange={(value) =>
                handleInputTextChange('fullName', value.target.value)
              }
              iconLeft={true}
              iconName="user"
              hasError={error?.fullName}
              errorMessage={error?.fullName}
            />
            <Input
              label="Email"
              placeholder="Enter email"
              type="email"
              required
              value={email}
              onChange={(value) =>
                handleInputTextChange('email', value.target.value)
              }
              iconLeft={true}
              iconName="user"
            />
          </Row>
          <Row>
            <Input
              label="Password"
              placeholder="Password"
              type="password"
              required
              value={password}
              onChange={(value) =>
                handleInputTextChange('password', value.target.value)
              }
              onBlur={() =>
                setState({
                  ...state,
                  hasPasswordEntered: true,
                })
              }
              iconLeft={true}
              iconName="user"
            />
            <Input
              label="Confirm Password"
              placeholder="Confirm Password"
              type="password"
              required
              value={confirmPassword}
              onChange={(value) =>
                handleInputTextChange('confirmPassword', value.target.value)
              }
              onBlur={() =>
                setState({
                  ...state,
                  hasCPasswordEntered: true,
                })
              }
              hasError={
                hasPasswordEntered &&
                hasCPasswordEntered &&
                password !== confirmPassword
              }
              errorMessage={
                !confirmPassword
                  ? ''
                  : 'Confirm password should be match with password'
              }
              iconLeft={true}
              iconName="user"
            />
          </Row>
        </div>
      </div>
    </div>
  );
};

export default FormExample;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 13rem;
  @media (max-width: 700px) {
    display: block;
  }
`;
