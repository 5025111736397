import { Box, CustomButton, Textarea } from 'components';
import { CountryCityState } from 'components/CountryCityStateDropDown';
import Icon from 'utlils/Icons/Icon';

const AddressSelect = ({
  address,
  handleRemoveOtherAddress,
  handleInputChange,
  handleAddressTextChange,
}) => {
  return (
    <div className="row">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '24px',
        }}>
        <p className="label-style-2">
          {address?.locationType === 'Primary_Location'
            ? 'Primary Location'
            : `Other Location`}
        </p>
        {address?.locationType !== 'Primary_Location' ? (
          <CustomButton
            className="align-self-start"
            onClick={() => {
              handleRemoveOtherAddress(address);
            }}
            variant="light"
            color={'themeTertiary'}>
            Remove location
            <Icon name="cross" color={'#FF8282'} size={20} />
          </CustomButton>
        ) : null}
      </div>
      <CountryCityState
        className="col-md-6 col-lg-6 col-xl-4 col-sm-12"
        isCountryRequired
        isStateRequired
        isCityRequired
        selectedCountry={address?.country}
        selectedState={address?.state}
        selectedCity={address?.city}
        onCountrySelect={(item) =>
          handleInputChange('country', item, address?.uid)
        }
        onStateSelect={(item) => handleInputChange('state', item, address?.uid)}
        onCitySelect={(item) => handleInputChange('city', item, address?.uid)}
      />
      <div className="col-md-12 col-lg-12 col-sm-12">
        <Box mt="10px">
          <Textarea
            rows={4}
            cols={50}
            label={'Address'}
            placeholder="Enter your address"
            value={address?.address}
            onChange={(e) =>
              handleInputChange('address', e.target.value, address?.uid)
            }
          />
        </Box>
      </div>
    </div>
  );
};

export default AddressSelect;
