// Recaptcha.js
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

export const ReCaptcha = ({ siteKey, onVerify }) => {
  return (
    <>
      <ReCAPTCHA sitekey={siteKey} onChange={onVerify} />
    </>
  );
};
