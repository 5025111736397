import { Box, CustomButton, Loader, Pagination, SearchInput } from 'components';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Icon from 'utlils/Icons/Icon';
import { PublicationCard } from '../IndividualGroups/ViewGroupInfo/Components/PublicationCard';
import {
  FETCH_ALL_MY_CONNECTED_USERS,
  FETCH_FEED_PUBLICATION,
  useAxiosWrapper,
} from 'services';
import tokenService from 'services/token.service';
import FeedFilter from './FeedFilter';
const DEFAULT_FILTER_STATE = {
  filteredFeedCategory: {},
};

export default function IndividualFeeds() {
  const loggedInUser = tokenService.getSession();
  const [fnWrapper, context] = useAxiosWrapper();
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [feedData, setFeedData] = useState([]);
  const [userNetworkId, setUserNetworkId] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filterState, setFilterState] = useState(DEFAULT_FILTER_STATE);
  let pageSize = 3;
  const getUserNetwork = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_ALL_MY_CONNECTED_USERS}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_GET_ALL_PUBLIC_USERS',
      payload: {
        hasPaginated: false,
      },
    });
    if (res?.data?.statusCode === 200) {
      let myNetworkId = [loggedInUser.id];
      res?.data?.data?.items.forEach((network) =>
        myNetworkId.push(network?.connectedUsers?.id)
      );
      setUserNetworkId(myNetworkId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const feedDetail = useCallback(
    async (search = '', filter = filterState) => {
      const res = await fnWrapper({
        method: 'GET',
        url: `${FETCH_FEED_PUBLICATION}?hasPaginated=true&userId=${JSON.stringify(
          userNetworkId
        )}&page=${currentPage}&size=${pageSize}&search=${search}&category=${
          Object.keys(filter.filteredFeedCategory).length
            ? filter.filteredFeedCategory.value
            : ''
        }`,
      });
      if (res?.data?.statusCode === 200) {
        let result = res?.data?.data;
        if (result.items.length === 0 && currentPage !== 1) {
          setCurrentPage(currentPage - 1);
        } else {
          setCurrentPage(result.currentPage);
          setFeedData(result);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userNetworkId, currentPage, pageSize, filterState]
  );

  const onSearchHandler = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value === '') {
      feedDetail();
    }
  };
  const onCloseSearchHandler = () => {
    setSearchTerm('');
    feedDetail();
  };

  const groupFilterOnChangeHandler = (field = '', value = null) => {
    setFilterState({
      ...filterState,
      [field]: value,
    });
  };
  const groupFilterSaveHandler = (type) => {
    if (type === 'apply') {
      setSearchTerm('');
      feedDetail('', filterState);
    } else if (
      type === 'cancel' &&
      JSON.stringify(filterState) !== JSON.stringify(DEFAULT_FILTER_STATE)
    ) {
      setFilterState(DEFAULT_FILTER_STATE);
      feedDetail('', DEFAULT_FILTER_STATE);
    }
    setIsFilterOpen(false);
  };

  useEffect(() => {
    getUserNetwork();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    feedDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userNetworkId, currentPage]);

  return (
    <IndividualFeedsWrapper>
      <Box bg={'white'} className="main-wrapper">
        <div className="header-text">
          <p className="heading">Feeds</p>
        </div>
        <div className="publication-container">
          <div className="search-publication-cards">
            <div className="search-container">
              <SearchInput
                value={searchTerm}
                onSearch={() => feedDetail(searchTerm)}
                placeholder="Search"
                onSearchange={onSearchHandler}
                onCloseSearch={onCloseSearchHandler}
              />
              <div className="filter-btn-group">
                <CustomButton
                  variant="light"
                  color={isFilterOpen ? 'themeSecondary' : 'themeGray2'}
                  onClick={(e) => {
                    setIsFilterOpen((state) => !state);
                  }}>
                  <Icon
                    name="filter"
                    size={20}
                    className="filter-arrow"
                    color={isFilterOpen ? 'rgba(33, 159, 255, 1)' : '#404f67'}
                  />
                  Filter
                </CustomButton>
              </div>
            </div>
            {context.busy.status ? (
              <Loader loading={context.busy.status} />
            ) : (
              <div className="publication-cards">
                {feedData?.items?.length > 0 ? (
                  feedData?.items.map((item, index) => {
                    return (
                      <PublicationCard
                        isFeedCard
                        key={index}
                        {...item}
                        publishedByUser={
                          item?.user?.individual
                            ? item?.user?.individual
                            : item?.user?.smallEnterprise
                        }
                      />
                    );
                  })
                ) : (
                  <div className="no-filter-data">
                    There are no publication feeds available at the moment.
                    Please connect with people to receive their live updates.
                  </div>
                )}
                {isFilterOpen && (
                  <FeedFilter
                    state={filterState}
                    filterOnChangeHandler={groupFilterOnChangeHandler}
                    filterOnSavehandler={groupFilterSaveHandler}
                    filterTitle={'Filter Feeds'}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <div className="pagination-conatiner">
          {feedData.currentPage >= 1 && (
            <Pagination
              className="pagination-bar"
              currentPage={feedData?.currentPage ? feedData?.currentPage : 0}
              totalCount={feedData?.totalItems ? feedData?.totalItems : 0}
              pageSize={pageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          )}
        </div>
      </Box>
    </IndividualFeedsWrapper>
  );
}
const IndividualFeedsWrapper = styled.div`
  .publication-container {
    padding: 0px 5px 80px 5px;
    .search-container {
      display: flex;
      gap: 5px;
      .filter-btn-group {
        display: flex;
        gap: 5px;
      }
    }
    .no-publications-img {
      width: 333px;
      height: 235.71px;
    }
    .no-publications-txt {
      color: #404f67;
      font-family: Poppins;
      font-size: 15px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: left;
    }
    .publication-cards {
      position: relative;
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      gap: 30px;
      .no-filter-data {
        color: grey;
        height: 100%;
        text-align: center;
        padding-top: 100px;
      }
    }
  }
  .pagination-conatiner {
    display: flex;
    justify-content: center;
    align-items: center;
    & .pagination-bar {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
`;
