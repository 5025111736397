import styled from 'styled-components';

export const DepartmentTeamMembersWrapper = styled.div`
  background-color: #f4f7fc;
  & .main-wrapper--class {
    background-color: white;
    position: relative;

    & .header-btns-wrapper {
      display: flex;
      justify-content: space-between;
      margin: 24px 0 0;
      gap: 30px;

      & .left-button {
        width: 100%;
      }
      & .right-button {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 10px;

        @media (max-width: 767px) {
          grid-template-columns: 1fr;
        }
      }

      @media (max-width: 767px) {
        flex-direction: column;
        gap: 10px;
      }
    }
    & .manage-arrow {
      margin-top: 4px;
    }
    & .filter-arrow {
      margin-left: 5px;
      margin-top: 6px;
    }
    .no-member {
      width: 75%;
      text-align: center;
      margin: 10% auto;
      font-weight: 500;
      color: #929daf;
    }
    @media (max-width: 992px) {
      padding: 15px;
    }
  }
`;
