export const MEMBERS_TABLE_HEADERS = [
  {
    title: 'Member Name',
    sortable: false,
    sortKey: 'memberName',
  },
  {
    title: 'Email',
    sortable: false,
    sortKey: 'email',
  },
  {
    title: 'Department',
    sortable: false,
    sortKey: 'department',
  },
  {
    title: 'Role in department',
    sortable: false,
    sortKey: 'roleInDepartment',
  },
  {
    title: 'User Status',
    sortable: false,
    sortKey: 'userStatus',
  },
  {
    title: 'Invite Status',
    sortable: false,
    sortKey: 'inviteStatus',
  },
];

export const INFO_TEXT = {
  MEMBER_IN_TEAM_INFO: ` This member is a part of below mentioned Teams & Projects. In case if
    you want to remove this member from the department you need to first
    manually remove this member from that Team & Project itself.`,
  MEMBER_FREE_TEXT: `Currently, this individual is not affiliated with any specific team or engaged in any ongoing projects.`,
};
