import styled from 'styled-components';
import { StatusButton } from 'components';
import dayjs from 'dayjs';
import Icon from 'utlils/Icons/Icon';

export const ExploreLeftSidebarData = {
  TEAMS: [
    {
      type: 'search-team',
      iconName: 'search',
      title: 'Search a Team',
      url: '/explore/teams/search-team',
      hasSubMenu: false,
    },
    {
      type: 'saved-teams',
      iconName: 'archieve-tick',
      title: 'Saved Teams',
      url: '/explore/teams/saved-teams',
      hasSubMenu: false,
    },
  ],
  GROUPS: [
    {
      type: 'search-group',
      iconName: 'search',
      title: 'Search a Group',
      url: '/explore/groups/search-groups',
      hasSubMenu: false,
    },
    // {
    //   type: 'saved-group',
    //   iconName: 'archieve-tick',
    //   title: 'Saved groups',
    //   url: '/explore/groups/saved-groups',
    //   hasSubMenu: false,
    // },
  ],
  JOBS: [
    {
      type: 'search-job',
      iconName: 'search',
      title: 'Search a Job',
      url: '/explore/jobs/search-job',
      hasSubMenu: false,
    },
    {
      type: 'my-jobs',
      iconName: 'archieve-tick',
      title: 'My Jobs',
      url: '',
      hasSubMenu: true,
      elements: [
        {
          type: 'my-jobs/applied-jobs',
          title: 'Applied Jobs',
          url: '/explore/jobs/my-jobs/applied-jobs',
        },
        {
          type: 'my-jobs/saved-jobs',
          title: 'Saved Jobs',
          url: '/explore/jobs/my-jobs/saved-jobs',
        },
      ],
    },
  ],
  PROJECTS: [
    {
      type: 'search-project',
      iconName: 'search',
      title: 'Search a Project',
      url: '/explore/projects/search-project',
      hasSubMenu: false,
    },
    {
      type: 'saved-projects',
      iconName: 'archieve-tick',
      title: 'Saved Projects',
      url: '/explore/projects/saved-projects',
      hasSubMenu: false,
    },
  ],
  NETWORKS: [
    {
      type: 'search-people',
      iconName: 'search',
      title: 'Search People',
      url: '/explore/networks/search-people',
      hasSubMenu: false,
    },
    {
      type: 'your-network',
      iconName: 'link',
      title: 'Your Network',
      url: '/explore/networks/your-network',
      hasSubMenu: false,
    },
    {
      type: 'requests',
      iconName: 'user',
      title: 'Requests',
      url: '/explore/networks/requests',
      hasSubMenu: false,
    },
  ],
};

export const JOB_TYPE = [
  { id: 1, label: 'Full-Time', value: 'Full-Time' },
  { id: 2, label: 'Part-Time', value: 'Part-Time' },
];

export const EXPERIENCE_LEVEL = [
  { id: 'BEGINNER', label: 'Beginner(0 to 2 years)', value: 'BEGINNER' },
  {
    id: 'INTERMEDIATE',
    label: 'Intermediate(3 to 4 years)',
    value: 'INTERMEDIATE',
  },
  { id: 'MASTER', label: 'Experienced(5 years and More)', value: 'MASTER' },
];

export const TEAM_CATEGORY = [
  { id: 1, label: 'Personal', value: 'personal' },
  { id: 2, label: 'Enterprise', value: 'enterprise' },
];

export const MEMBERS_COUNT = [
  { id: 1, label: '> 1 Members', value: '1' },
  { id: 2, label: '> 2 Members', value: '2' },
  { id: 3, label: '> 3 Members', value: '3' },
  { id: 4, label: '> 4 Members', value: '4' },
  { id: 5, label: '> 5 Members', value: '5' },
];

export const TEAMS_COUNT = [
  { id: 1, label: '> 1 Teams', value: '1' },
  { id: 2, label: '> 2 Teams', value: '2' },
  { id: 3, label: '> 3 Teams', value: '3' },
  { id: 4, label: '> 4 Teams', value: '4' },
  { id: 5, label: '> 5 Teams', value: '5' },
];

export const COLLAB_BID = [
  { id: 1, label: 'Collaboration', value: 'COLLABORATION' },
  { id: 2, label: 'Bidding', value: 'BIDDING' },
  { id: 3, label: 'Both Collab & Bid', value: 'BOTH' },
];

export const PROJECT_PRIVACY = [
  { id: 1, label: 'Public', value: true },
  { id: 2, label: 'Private', value: false },
];

export const EXPLORE_TEAM_FILTERS = {
  members_count: {
    label: 'No. of Members',
    dataType: MEMBERS_COUNT,
    key: 'label',
    placeholder: 'Select count',
  },
  team_type: {
    label: 'Team Type',
    placeholder: 'Select Type',
  },
  team_category: {
    label: 'Personal/Enterprise',
    dataType: TEAM_CATEGORY,
    key: 'label',
    placeholder: 'Select Category',
  },
  enterprise_name: {
    label: 'Enterprise Name',
    key: 'label',
    placeholder: 'Select Enterprise',
  },
};

export const EXPLORE_JOB_FILTERS = {
  experience_level: {
    label: 'Experience Level',
    dataType: EXPERIENCE_LEVEL,
    key: 'label',
    placeholder: 'Select level',
  },
  job_type: {
    label: 'Job Type',
    dataType: JOB_TYPE,
    key: 'label',
    placeholder: 'Select Type',
  },
  region: {
    label: 'Region',
    key: 'name',
    placeholder: 'Search Region',
  },
};

export const EXPLORE_PROJECT_FILTERS = {
  teams_count: {
    label: 'No. of Teams',
    dataType: TEAMS_COUNT,
    key: 'label',
    placeholder: 'Select count',
  },
  collab_Bid: {
    label: 'Open for Collab/Bid',
    dataType: COLLAB_BID,
    key: 'label',
    placeholder: 'Select',
  },
  privacy: {
    label: 'Privacy',
    dataType: PROJECT_PRIVACY,
    key: 'label',
    placeholder: 'Select',
  },
  project_category: {
    label: 'Personal/Enterprise',
    dataType: TEAM_CATEGORY,
    key: 'label',
    placeholder: 'Select Category',
  },
  enterprise_name: {
    label: 'Enterprise Name',
    key: 'label',
    placeholder: 'Select Enterprise',
  },
  category: {
    label: 'Project Category',
    key: 'label',
    placeholder: 'Select Project Category',
  },
};

export const connectionButtonStatus = (status) => {
  if (status === null || status === '3' || status === '5') {
    return {
      label: 'Connect',
      disabled: false,
      outlined: true,
      color: '#0ABD00',
    };
  } else if (status === '1') {
    return {
      label: 'Pending',
      bgColor: '#fff',
      color: '#FE9320',
      outlined: true,
      disabled: true,
    };
  } else if (status === '4') {
    return {
      label: 'Connected',
      bgColor: '#0ABD00',
      color: '#fff',
      disabled: true,
    };
  }
};

export const NetworkRequestsTabs = ['Received', 'Sent'];
export const ApplyJobRadioLabels = [
  { text: 'Apply with your Profile', value: '1', id: 'PROFILE' },
  { text: 'Apply with your E-record', value: '2', id: 'E-RECORD' },
  {
    text: 'Apply with your Resume',
    value: '3',
    id: 'RESUME',
  },
];

export const AppliedJobTableHeaders = [
  {
    title: 'Posted By',
    sortable: false,
    sortKey: 'postedBy',
  },
  {
    title: 'Profile',
    sortable: false,
    sortKey: 'profile',
  },
  {
    title: 'Applied on',
    sortable: true,
    sortKey: 'appliedOn',
  },
  {
    title: 'Status',
    sortable: true,
    sortKey: 'status',
  },
  {
    title: 'Review Application',
    sortKey: 'action',
  },
];

export let jobStatus = {
  NO_UPDATE: {
    label: 'No Update',
    bgColor: '#F4F7FC',
    textColor: '#929DAF',
  },
  VIEWED: {
    label: 'Viewed',
    bgColor: 'rgba(255, 164, 18, 0.15);',
    textColor: '#FFA412',
  },
  NOT_SELECTED: {
    label: 'Not-selected',
    bgColor: '#ffffff',
    textColor: '#7E8F9A',
  },
  SHORTLISTED: {
    label: 'Shortlisted',
    bgColor: '#ffffff',
    textColor: '#219FFF',
  },
  SELECTED: {
    label: 'Selected',
    bgColor: '#0ABD00',
    textColor: '#ffffff',
  },
};
const ReviewApplicationIconWrapper = styled.div`
  .review-icon {
    &:hover {
      color: #219fff;
      cursor: pointer;
    }
  }
`;
export const appliedJobsTableData = ({ data, getReviewApplicationDetails }) => {
  let newData = [];
  if (data?.length) {
    newData = data.map((item) => {
      const { appliedJob } = item;
      return {
        id: appliedJob?.id,
        postedBy: appliedJob?.user?.smallEnterpriseitem
          ? appliedJob?.user?.smallEnterprise
          : appliedJob?.team?.enterprise
          ? appliedJob?.team?.enterprise?.enterpriseName
          : appliedJob?.user?.individual?.name,
        profile: <span>{appliedJob?.title}</span>,
        appliedOn: dayjs(item?.createdAt).format('DD MMM YYYY'),
        status: <StatusButton status={jobStatus[item?.status]} />,
        action: (
          <ReviewApplicationIconWrapper>
            <Icon
              name="document-text"
              onClick={() => {
                getReviewApplicationDetails(item);
              }}
              className="review-icon"
            />
          </ReviewApplicationIconWrapper>
        ),
      };
    });
  } else {
    newData = [];
  }
  return newData;
};
