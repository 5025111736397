import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, CustomButton, Title } from 'components';
import { LicenseItem } from './licenseItem';
import AddButton from '../../../components/addButton';
import {
  CREATE_LICENSE_DETAILS,
  FETCH_LICENSE_DETAILS,
  useAxiosWrapper,
} from 'services';
import TokenService from 'services/token.service';
import Icon from 'utlils/Icons/Icon';
import { checkForSpacesInStartEnd } from 'helpers';

const DEFAULTSTATE = [
  {
    itemId: '',
    title: '',
    issuedBy: '',
    credentialLink: '',
    issuedDate: { month: new Date(), year: new Date() },
  },
];
export const CreateLicenseDetails = ({
  title,
  setCurrType,
  nextType,
  onSave,
  isEditEvent,
}) => {
  let navigate = useNavigate();
  const [fnWrapper, context] = useAxiosWrapper();
  const [state, setState] = useState(DEFAULTSTATE);
  const location = useLocation();

  const addNewItemRowHandler = () => {
    setState([
      ...state,
      {
        itemId: '',
        title: '',
        issuedBy: '',
        credentialLink: '',
        issuedDate: { month: new Date(), year: new Date() },
      },
    ]);
  };
  const deleteItemRowHandler = (index) => {
    const temp = [...state];
    temp.splice(index, 1);
    setState(temp);
  };
  const onInputChangeHandler = (value, index, type) => {
    const temp = [...state];
    if (type === 'title') {
      temp[index].title = value;
    }
    if (type === 'issuedBy') {
      temp[index].issuedBy = value;
    }
    if (type === 'credentialLink') {
      temp[index].credentialLink = value;
    }

    if (type === 'from-month') {
      temp[index].issuedDate = { ...temp[index].issuedDate, month: value };
    }
    if (type === 'from-year') {
      temp[index].issuedDate = { ...temp[index].issuedDate, year: value };
    }

    setState(temp);
  };
  const onSaveHandler = async () => {
    const loggedInUser = TokenService.getSession();
    const payload = state.map((item) => {
      let fromYear = new Date(item.issuedDate.year).toLocaleString('default', {
        year: 'numeric',
      });
      let fromMonth = new Date(item.issuedDate.month).toLocaleString(
        'default',
        {
          month: '2-digit',
        }
      );
      return {
        userId: loggedInUser.id,
        itemId: item.itemId ? item.itemId : null,
        title: item.title,
        issuedBy: item.issuedBy,
        issuedDate: new Date(fromYear, fromMonth - 1, 1),
        credentialLink: item.credentialLink,
      };
    });
    const res = await fnWrapper({
      url: CREATE_LICENSE_DETAILS,
      method: 'POST',
      type: 'CREATE_LICENSE_DETAILS',
      payload: payload,
    });

    if (res.data.statusCode === 200) {
      if (isEditEvent) {
        if (location.state !== null && location.state.from === 'public_page') {
          navigate(location.state.redirectTo);
        } else {
          navigate(`/individual/profile/info/license_certifications`);
        }
      } else {
        navigate('/individual/profile/info/basic_details');
      }
    }
  };

  const getLicenseAndCertificationDetails = useCallback(async () => {
    const loggedInUser = TokenService.getSession();
    const res = await fnWrapper({
      url: `${FETCH_LICENSE_DETAILS}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_LICENSE_INFO',
    });

    if (res.data.statusCode === 200) {
      const response = res.data.data;
      let dataArray = [];
      if (response.length) {
        response.forEach((ele) => {
          let data = {
            itemId: ele.id,
            title: ele.title,
            issuedBy: ele.title,
            credentialLink: ele.credentialLink,
            issuedDate: {
              month: new Date(ele.issuedDate),
              year: new Date(ele.issuedDate),
            },
          };
          dataArray = [...dataArray, data];
        });
      } else {
        dataArray = [
          {
            itemId: '',
            title: '',
            issuedBy: '',
            credentialLink: '',
            issuedDate: { month: new Date(), year: new Date() },
          },
        ];
      }
      setState(dataArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getLicenseAndCertificationDetails();
  }, [getLicenseAndCertificationDetails]);

  const isDisabled = () => {
    const eduError = (obj) =>
      obj.title.length === 0 ||
      checkForSpacesInStartEnd(obj.title) ||
      obj.issuedBy.length === 0 ||
      checkForSpacesInStartEnd(obj.issuedBy) ||
      obj.credentialLink.length === 0 ||
      checkForSpacesInStartEnd(obj.credentialLink) ||
      obj.issuedDate.month === null ||
      obj.issuedDate.year === null;
    return state.some(eduError);
  };
  const disable = isDisabled();

  return (
    <div className="license-detail-container">
      <Box display="flex" justifyContent="space-between">
        <Title title={title} />
        <AddButton onClick={addNewItemRowHandler} />
      </Box>
      {/*  body */}
      {state.map((item, index) => (
        <LicenseItem
          key={index}
          index={index}
          data={item}
          onDelete={deleteItemRowHandler}
          onInputChangeHandler={onInputChangeHandler}
        />
      ))}
      <div className="row mx-md-3">
        {!isEditEvent && <div className="col-md-4 col-2"></div>}
        <div className={!isEditEvent ? 'col-md-4 col-6' : 'col-md-12 col-12'}>
          <div
            className="d-flex justify-content-center align-items-center mt-5"
            style={!isEditEvent ? { gap: '10px' } : { gap: '20px' }}>
            <Box width="120px">
              <CustomButton
                className="w-100"
                label="Save"
                loading={context.busy.status}
                onClick={onSaveHandler}
                disabled={disable}
              />
            </Box>
            <Box>
              <CustomButton
                className="w-100"
                variant="textBtn"
                color="themeGray"
                onClick={() => {
                  setCurrType(nextType);
                  navigate('/individual/profile/info/basic_details');
                }}
                loading={context.busy.status}>
                Go to Home
                <Icon name="arrow-forward" />
              </CustomButton>
            </Box>
          </div>
        </div>
        {!isEditEvent && (
          <div className="col-md-4 col-4 d-flex align-items-center justify-content-end">
            <Box width="120px">
              <CustomButton
                className="w-100"
                variant="textBtn"
                color="themeGray"
                onClick={() =>
                  navigate('/individual/profile/info/basic_details')
                }>
                Skip
                <Icon name="arrow-right" size={20} color="#404F67" />
              </CustomButton>
            </Box>
          </div>
        )}
      </div>
    </div>
  );
};
