import { Client as ConversationsClient } from '@twilio/conversations';
import { FETCH_TWILIO_ACCESS_TOKEN } from 'services';
import { chatErrorMsg } from './helper';
import tokenService from 'services/token.service';
import axios from 'axios';

export const initializeTwilioConversations = async (userId) => {
  try {
    const loggedInUser = tokenService.getSession();
    const config = {
      headers: {
        'x-access-token': loggedInUser?.access_token,
      },
    };
    // Fetch a new Twilio access token when needed
    const fetchTwilioToken = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_BASE_URL}${FETCH_TWILIO_ACCESS_TOKEN}?userId=${userId}`,
          config
        );
        if (response?.data?.statusCode === 200) {
          return response?.data?.data;
        }
      } catch (error) {
        chatErrorMsg('Error fetching Twilio access token');
      }
    };

    const token = await fetchTwilioToken();
    if (!token) {
      chatErrorMsg('Error fetching Twilio access token');
    }

    // Initialize the ConversationsClient with the retrieved token
    let conversationsClientInstance = new ConversationsClient(token);
    return conversationsClientInstance;
  } catch (error) {
    chatErrorMsg('Error initializing Twilio Conversations');
  }
};
