import { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  LINKED_TEAM_LIST_HEADERS,
  linkedTeamStatus,
} from 'pages/IndividualDashboard/individualOrganizations/enterprise/helper';
import { getPlaceholderItems } from 'utlils/utils';
import { CustomTable, ScrollableDiv, StatusButton } from 'components';
import { ViewTeamsWrapper } from './style';
import { FETCH_PROJECT_SERVICES, useAxiosWrapper } from 'services';

export const ViewLinkedTeams = () => {
  const [fnWrapper, context] = useAxiosWrapper();
  const [linkedTeamsData, setLinkedTeamsData] = useState([]);
  const { projectId } = useParams();

  const getLinkedTeamsList = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_PROJECT_SERVICES}/${projectId}?st=linkedTeams`,
      method: 'GET',
      type: 'FETCH_LINKED_TEAMS',
    });

    if (res.data.statusCode === 200) {
      const result = res.data.data.data[0];
      const linkedTeamsList = [];
      result.teams.map((team) => {
        const listData = {
          teamName: `${team.teamName}`,
          members: `${team.teamMembers.length}`,
          connectedTo: `${
            team.teamCategory === 'ENTERPRISE' && team.enterprise
              ? team.enterprise.enterpriseName
              : team.user.individual.name
          }`,
          status: (
            <StatusButton
              status={linkedTeamStatus[team.TeamProject.teamStatus]}
            />
          ),
        };
        linkedTeamsList.push(listData);
        return linkedTeamsList;
      });
      setLinkedTeamsData(linkedTeamsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const projectLinkedTeamsListData = context.busy.status
    ? getPlaceholderItems(
        LINKED_TEAM_LIST_HEADERS.map(({ sortKey }) => sortKey),
        4,
        context.busy.status
      )
    : linkedTeamsData;

  useEffect(() => {
    getLinkedTeamsList();
  }, [getLinkedTeamsList]);

  return (
    <ViewTeamsWrapper>
      <div className="view-container">
        {/* table */}

        {projectLinkedTeamsListData.length === 0 ? (
          <div className="no-team">You didn't link any team!</div>
        ) : (
          <div className="table-style">
            <ScrollableDiv scrollOnHeight="420px" scrollOnHeightMob="300px">
              <CustomTable
                isLoading={context.busy.status}
                rows={projectLinkedTeamsListData}
                headers={LINKED_TEAM_LIST_HEADERS}
              />
            </ScrollableDiv>
          </div>
        )}
      </div>
    </ViewTeamsWrapper>
  );
};
