import React from 'react';
import { SentRequestsWrapper } from './styled';
import { RequestCard } from '../components/RequestCard';
import { GET_GROUP_INVITES_LIST, useAxiosWrapper } from 'services';
import { useParams } from 'react-router-dom';
import tokenService from 'services/token.service';
import { ScrollableDiv } from 'components';

export const SentRequests = () => {
  const [sentRequestsList, setSentRequestsList] = React.useState([]);
  const [fnWrapper] = useAxiosWrapper();
  const params = useParams();

  const getSentRequestsList = React.useCallback(async () => {
    const userId = tokenService.getSession()?.id;
    const res = await fnWrapper({
      url: `${GET_GROUP_INVITES_LIST}/${params.groupId}?userId=${userId}`,
      method: 'GET',
      type: 'GET_GROUP_INVITES_LIST',
    });
    if (res?.data?.statusCode === 200) {
      setSentRequestsList(res?.data?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    getSentRequestsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const RenderSentRequestsList = () => {
    if (sentRequestsList?.length > 0) {
      return sentRequestsList.map((request, index) => {
        return (
          <RequestCard
            key={index}
            type="sent"
            userName={request?.invitedToUser?.individual?.name}
            userId={request?.invitedToUser?.id}
            requestStatus={request?.status?.toLowerCase()}
          />
        );
      });
    }
  };
  return (
    <SentRequestsWrapper>
      <ScrollableDiv scrollOnHeight="calc(100vh - 55vh)">
        <br />
        {sentRequestsList?.length > 0 ? (
          <div className="sent-requests-list">
            <RenderSentRequestsList />
          </div>
        ) : (
          <p className="no-requests">
            No Requests sent.
            <br />
            They will appear here when you request someone to join the group.
          </p>
        )}
        <br />
      </ScrollableDiv>
    </SentRequestsWrapper>
  );
};
