import { UpcomingFeature } from 'components/UpcomingFeature';
import React from 'react';

export const LinkedAccounts = () => {
  return (
    <div>
      <UpcomingFeature />
    </div>
  );
};
