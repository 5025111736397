import styled from 'styled-components';

export const NoDataWrapper = styled.div`
  .empty-data-section {
    text-align: center;
    &--img {
      width: 60%;
    }
    &--text {
      font-size: 18px;
      font-weight: 600;
      color: #404f67;
      margin-top: 1rem;
    }
  }
`;
