import { useState, useCallback, useEffect } from 'react';
import { Loader } from 'components';
import { PROJECT_ACCESS } from '../constants';
import { useParams, useNavigate } from 'react-router-dom';
import { FETCH_PUBLIC_PROJECT_SERVICE, useAxiosWrapper } from 'services';
import { LinkedCard } from '../Components';
import SearchEngineBro from 'assests/images/search-engine-bro.png';

export const ProjectPublicLinkedTeams = (props) => {
  const { access, isNotLoggedInUser } = props;
  const [fnWrapper, context] = useAxiosWrapper();
  const { projectId } = useParams();
  const [linkedTeams, setLinkedTeams] = useState([]);
  const navigate = useNavigate();

  const getLinkedTeamsList = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_PUBLIC_PROJECT_SERVICE}/${projectId}?st=linkedTeams`,
      method: 'GET',
      type: 'FETCH_LINKED_TEAMS',
    });

    if (res.data.statusCode === 200) {
      const result = res.data.data.data[0];
      const linkedTeamsList = result.teams
        .map((team) => ({
          id: team?.TeamProject?.teamId,
          teamName: team?.teamName,
          owner:
            team?.teamCategory === 'ENTERPRISE' && team?.enterprise
              ? team?.enterprise?.enterpriseName
              : team?.user?.individual?.name,
          type: 'team',
          profileImgSrc: '',
          profileIcon: 'people',
          description: team?.description,
          teamFeature: [
            {
              iconName: 'people',
              label: `${
                team?.teamMembers.filter((member) => member.status === '4')
                  .length
              } Members`,
            },
            {
              iconName: 'brief-case',
              label: team?.isHiring ? 'Hiring' : 'N/A',
            },
            {
              iconName: 'tag-user',
              label: team?.type?.title,
            },
            {
              iconName: 'user',
              label: `${team?.connections?.length} Connections`,
            },
          ],
        }))
        .filter((item) => item?.TeamProject?.teamStatus === '3'); // Only accepted team list will visible
      setLinkedTeams(linkedTeamsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    getLinkedTeamsList();
  }, [getLinkedTeamsList]);

  return (
    <div className="main-wrapper">
      <div className="heading">
        Linked Teams{' '}
        {access === PROJECT_ACCESS.PUBLIC && (
          <span>({linkedTeams.length})</span>
        )}
      </div>
      {access !== PROJECT_ACCESS.PUBLIC ? (
        <div className="no-content-wrapper">
          <div className="no-access-img">
            <img src={SearchEngineBro} alt="" />
          </div>
          <div className="no-access-title">You are not authorised to view</div>
          <div className="no-access-text">
            Due to its private nature, it could only be accessible to a few
            people
          </div>
        </div>
      ) : context.busy.status ? (
        <Loader loading={context.busy.status} />
      ) : (
        <div className="content">
          {linkedTeams?.length > 0 ? (
            <div className="cards-container">
              {linkedTeams?.map((team, index) => (
                <LinkedCard
                  key={index}
                  title={team.teamName}
                  profileImgSrc={team.profileImgSrc}
                  profileIcon={team.profileIcon}
                  type={team.type}
                  owner={team.owner}
                  description={team.description}
                  features={team.teamFeature}
                  viewHandler={() =>
                    navigate(`/public/team/${team.id}/details`)
                  }
                  hideActionButton={isNotLoggedInUser}
                />
              ))}
            </div>
          ) : (
            <div className="no-data-class">No Linked Teams Found!</div>
          )}
        </div>
      )}
    </div>
  );
};
