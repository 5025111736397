import styled from 'styled-components';

export const NetworkProfileCardStyle = styled.div`
  width: 100%;
  min-height: 350px;
  padding-bottom: 10px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-width: 0 1px 1px 1px;
  border-radius: 10px;
  position: relative;
  .cover-photo {
    background-image: ${({ coverProfilePicture }) =>
      `url(${coverProfilePicture})`};
    background-size: cover;
    border-top: 1px solid #c4c4c4;
  }
  & .networkProfile-card {
    &__bg {
      width: 100%;
      height: 71px;
      background-color: ${({ coverProfilePicture }) =>
        coverProfilePicture ? '' : '#C4C4C4'};
      border-radius: 10px 10px 0 0;
    }
    &__profile--img {
      position: absolute;
      top: 26px;
      z-index: 100;
      left: calc(50% - 45px);
    }
    &__details {
      margin-top: 55px;
      text-align: center;
      padding: 0 10px 0 10px;
      & .overflow-eclipses {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      & .network--name {
        color: #17191d;
        font-weight: 500;
        font-size: 18px;
      }
      & .network--designation {
        margin-top: 5px;
        color: #404f67;
        font-weight: 400;
        font-size: 14px;
      }
      & .network--country {
        font-weight: 400;
        font-size: 14px;
        color: #404f67;
        margin-top: 10px;
        .network--city {
          margin-left: 5px;
        }
      }
      & .mutual-connection {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        bottom: 80px;
        left: 10px;
        right: 10px;
        color: #404f67;
        font-weight: 400;
        font-size: 13px;
        position: absolute;
      }
      .mutual-connection > svg {
        margin-top: 3px;
      }
      & .connect-button {
        position: absolute;
        bottom: 15px;
        right: 10px;
        left: 10px;
      }
    }
  }
`;
