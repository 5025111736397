import { Route, Routes } from 'react-router-dom';
import { AppliedJobs } from './AppliedJobs';
import { SavedJobs } from './SavedJobs';

export const MyJobs = () => {
  return (
    <Routes>
      <Route element={<AppliedJobs />} path="applied-jobs" />
      <Route element={<SavedJobs />} path="saved-jobs" />
    </Routes>
  );
};
