import { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { pricePropTypes } from 'components/PriceInput/helper';
import { PriceInput } from 'components/PriceInput';
import { PriceRangeStyle } from './style';

export const PriceRange = ({
  label = 'Pay Range',
  range = { maxRange: null, minRange: null, currency: 'USD' },
  onRangeChange,
  className,
  hasError,
  onBlur,
  minValue = 100,
  labelRequired = true,
  disabled = false,
  ...rest
}) => {
  /*selectedCurrency is mandatory to be passed as props since both the priceInput components here share the same state */
  const { maxRange, minRange, currency } = range;
  const [showError, setShowError] = useState(false);

  const onBlurHandler = (e) => {
    // Run custom logic passed to input on blur
    typeof onBlur === 'function' && onBlur(e);
    // Show error to trigger error evaluation logic
    setShowError(true);
  };

  const isError =
    // check if dynamic error state passed from parent component
    hasError ||
    // if not then proceed with internal error logic💪
    (showError ? minRange < minValue || maxRange < minRange : false);

  return (
    <PriceRangeStyle
      className={cn('priceRange', className, disabled && 'disabled')}>
      <div className="priceRange__label">{labelRequired ? label : null}</div>
      <div className="priceRange__inputBox">
        <PriceInput
          value={minRange}
          onPriceChange={(value) => {
            onRangeChange({
              minRange: value.value,
              maxRange: maxRange,
              currency: value.currency,
            });
          }}
          selectedCurrency={currency}
          onBlur={onBlurHandler}
          minValue={minValue}
          disabled={disabled}
        />
        <div>-</div>
        <PriceInput
          value={maxRange}
          onPriceChange={(value) => {
            onRangeChange({
              minRange: minRange,
              maxRange: value.value,
              currency: value.currency,
            });
          }}
          selectedCurrency={currency}
          onBlur={onBlurHandler}
          minValue={minValue}
          disabled={disabled}
        />
      </div>
      {isError && (
        <p className="priceRange__errorMessage">
          {`Min price can not be less than ${minValue}, Max price cannot be less than min price`}
        </p>
      )}
    </PriceRangeStyle>
  );
};

PriceRange.propTypes = {
  range: PropTypes.shape({
    minRange: PropTypes.number,
    maxRange: PropTypes.number,
    currency: PropTypes.oneOf(pricePropTypes).isRequired,
  }).isRequired,
  onRangeChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};
