import { useEffect, useRef, useState } from 'react';
import { SelectBiddingWrapper } from './style';
import { DynamicHeader } from '../ProjectSelectCollaborations/Components/DynamicHeader';
import { MODAL_HEADINGS } from './constants';
import { ModalPopup, ScrollableDiv } from 'components';
import { SelectBidding } from './SelectBidding';
import { BiddingDetails } from './BiddingDetails';
import { RequiredBiddingDetails } from './RequiredDetails';
import { parseJson } from './utils';
import { InviteSent } from './InviteSent';
import { replaceUnderscoresWithSpaces } from './utils';

export const ProjectSelectBiddings = ({
  isInvited,
  biddings,
  setProjectInfo,
  onClose,
}) => {
  const scrollRef = useRef();
  const ref = useRef();
  const [activeStep, setActiveStep] = useState(isInvited ? 1 : 0);
  const [selectedBidding, setSelectedBidding] = useState(
    isInvited ? isInvited : null
  );
  const [scrollDivHeight, setScrollDivHeight] = useState('calc(100vh - 250px)');

  useEffect(() => {
    if (scrollRef?.current) {
      scrollRef?.current?.scrollTo(0, 0);
    }
    if (ref?.current) {
      const newHeight = ref?.current?.clientHeight + 45;
      if (newHeight < 500) {
        setScrollDivHeight(newHeight);
      } else {
        setScrollDivHeight('calc(100vh - 250px)');
      }
    }
  }, [activeStep]);

  return (
    <ModalPopup
      open={false}
      onClose={onClose}
      minWidth={activeStep === 3 ? '600px' : '800px'}
      maxContentWidth={activeStep === 3 ? '600px' : '800px'}
      title={
        <DynamicHeader
          activeStep={activeStep}
          modalHeadings={MODAL_HEADINGS}
          setActiveStep={setActiveStep}
        />
      }>
      <SelectBiddingWrapper>
        <div className="publish_wrp">
          <ScrollableDiv
            ref={scrollRef}
            scrollOnHeight={
              activeStep !== 0 && activeStep !== 3 && scrollDivHeight
            }
            scrollOnHeightMob="80vh">
            {activeStep === 0 ? (
              <SelectBidding
                nextPage={() => setActiveStep(1)}
                selectedBidding={selectedBidding}
                setSelectedBidding={setSelectedBidding}
                biddings={biddings?.map((singleBid) => {
                  if (singleBid?.isApplied) {
                    return {
                      ...singleBid,
                      text: (
                        <span>
                          {replaceUnderscoresWithSpaces(singleBid?.biddingType)}
                          <span style={{ color: '#FF928E' }}>
                            {' '}
                            (You've already applied)
                          </span>
                        </span>
                      ),
                      value: singleBid?.id,
                      isDisabled: singleBid?.isApplied,
                    };
                  }
                  return {
                    ...singleBid,
                    text: replaceUnderscoresWithSpaces(singleBid?.biddingType),
                    value: singleBid?.id,
                    isDisabled: singleBid?.isApplied,
                  };
                })}
              />
            ) : null}
            {activeStep === 1 ? (
              <div ref={ref}>
                <BiddingDetails
                  setScrollDivHeight={setScrollDivHeight}
                  nextPage={() => setActiveStep(2)}
                  selectedBidding={selectedBidding}
                />
              </div>
            ) : null}
            {activeStep === 2 ? (
              <div ref={ref}>
                <RequiredBiddingDetails
                  setProjectInfo={setProjectInfo}
                  setScrollDivHeight={setScrollDivHeight}
                  nextPage={() => setActiveStep(3)}
                  selectedBidding={selectedBidding}
                  questionsToBidder={selectedBidding?.questionsToBidder ?? {}}
                  rulesAndDocuments={
                    selectedBidding?.rulesAndDocuments?.map((doc) => ({
                      name: doc?.split('/')[doc?.split('/')?.length - 1],
                      location: doc,
                      isMandatory: true,
                    })) ?? []
                  }
                  signedDocuments={
                    selectedBidding?.signedDocuments?.map((biddingDocument) => {
                      let parseData = parseJson(biddingDocument);
                      if (parseData) {
                        return { ...parseData, name: parseData.fileName };
                      }
                      return null;
                    }) ?? []
                  }
                />
              </div>
            ) : null}
            {activeStep === 3 ? <InviteSent /> : null}
          </ScrollableDiv>
        </div>
      </SelectBiddingWrapper>
    </ModalPopup>
  );
};
