import Icon from '../../../../utlils/Icons/Icon';
import {
  CustomButton,
  NavigationalRowItem,
  ScrollableDiv,
} from '../../../../components';

import styled from 'styled-components';

const ProjectCardWrapper = styled.div`
  .card-container {
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 270.44px;
    box-sizing: border-box;
    padding: 15px;
    margin-top: 20px;
    font-family: 'Poppins';
    font-style: normal;

    & .card-heading {
      display: flex;
      justify-content: flex-start;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #17191d;

      & .project-alt-img {
        border-radius: 50%;
        width: 48px;
        height: 48px;
        background-color: #f4f7fc;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      & .project-img {
        object-fit: cover;
        border-radius: 50%;
        width: 48px;
        height: 48px;
        background: #c4c4c4;
      }

      & span {
        margin-top: 14px;
        margin-left: 20px;
      }
    }

    & .card-subHeading {
      display: flex;
      justify-content: space-between;
      font-weight: 400;
      font-size: 14px;
      margin-top: 15px;
      text-align: justify;
      text-decoration-line: underline;

      color: #404f67;

      & .subHeading-style {
        align-items: center;
        gap: 12px;
        margin-bottom: 10px;
        display: flex;
      }

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }

    & .card-text {
      margin-top: 5px;
      font-weight: 400;
      font-size: 14px;
      text-align: justify;

      color: #404f67;
    }

    & .link-btn {
      padding: 13px;
      background-color: #0abd00;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        cursor: pointer;
      }
    }

    @media (max-width: 1001px) {
      height: auto;
    }
  }
`;

const ProjectCard = (props) => {
  const {
    item,
    buttonLabel = '',
    btnLeftIcon = false,
    btnIconName = 'link',
    viewHandler,
    requestHandler,
    isRequestDisabled,
    btnTextColor,
    type,
  } = props;
  return (
    <ProjectCardWrapper>
      <div className="card-container">
        <div className="card-heading">
          {item.projectImgSrc !== null && item.projectImgSrc !== '' ? (
            <img
              src={item.projectImgSrc}
              alt="project-img"
              width="100%"
              className="project-img"
            />
          ) : (
            <div className="project-alt-img">
              <Icon
                name={type === 'Project' ? 'project' : 'people'}
                color="#404F67"
              />
            </div>
          )}
          <span>{item?.name}</span>
        </div>

        <div className="card-subHeading">
          <div className="subHeading-style">
            <Icon
              name={item?.userType !== 'PERSONAL' ? 'department' : 'user-icon'}
              color="#929DAF"
            />
            <div>
              <NavigationalRowItem
                label={item?.createdBy?.name}
                navigateTo={
                  item?.userType !== 'PERSONAL'
                    ? `/public/enterprise/${item?.createdBy?.id}/details`
                    : `/public/individual/${item?.createdBy?.id}/summary/basic-details`
                }
                isIndividualUser={item?.userType !== 'PERSONAL'}
                isOpenInNewTab
                customStyle={{ color: '#17191d', cursor: 'pointer' }}
              />
            </div>
          </div>
        </div>

        <ScrollableDiv scrollOnHeight="60px">
          <div className="card-text">
            {item?.description === ''
              ? 'No description available'
              : item?.description}
          </div>
        </ScrollableDiv>
        <div className="row g-3 mt-1">
          <div className="col-lg-6">
            <CustomButton
              className="w-100"
              label="View"
              onClick={viewHandler}
              variant="outline"
            />
          </div>
          <div className="col-lg-6">
            <CustomButton
              className="w-100"
              onClick={requestHandler}
              disabled={isRequestDisabled}
              variant="light">
              {btnLeftIcon && (
                <Icon name={btnIconName} color={btnTextColor} size={20} />
              )}
              {buttonLabel}
            </CustomButton>
          </div>
        </div>
      </div>
    </ProjectCardWrapper>
  );
};

export default ProjectCard;
