import { useState, useCallback, useEffect } from 'react';
import { BreadCrumb, ToggleSwitch } from 'components';
import { TEAMS_PROJECTS_TOGGLE_LABEL } from 'pages/enterpriseDashboard/constants';
import { TaskPage } from './tasks';
import styled from 'styled-components';
import { Career } from './career';
import {
  useLocation,
  useNavigate,
  useParams,
  Routes,
  Route,
} from 'react-router-dom';
import {
  getBreadCrumbList,
  breadCrumbListStorage,
  capitalizeFirstLetter,
} from '../helper';
import { TeamList } from './teams/teamList';
import { Permissions } from './permissions';
import AddTeams from '../../../IndividualPersonalSection/individualTeams/addTeams';
import { TYPES } from 'pages/IndividualDashboard/constants';
import {
  ProjectList,
  CreateProject,
  EditProject,
  ViewProject,
} from 'pages/Projects';
import { CreateProjectProvider } from 'context/Projects/CreateProject/createProjectContext';
import { CreateTeamProvider } from 'context/Teams/CreateTeam/createTeamContext';
import { FETCH_DEPARTMENT_DETAILS, useAxiosWrapper } from 'services';
import tokenService from 'services/token.service';

const TeamListWrapper = styled.div`
  .header-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
    font-size: 25px;
    color: #17191d;
    padding: 5px;
    @media only screen and (max-width: 992px) and (min-width: 320px) {
      display: grid;
      gap: 15px;
    }
  }
  .add-team-icon {
    margin-right: 6px;
    @media only screen and (max-width: 1204px) and (min-width: 999px) {
      margin-right: 2px;
      margin-left: -7px;
    }
  }
`;

const Department = ({ isListUpdate }) => {
  const [fnWrapper] = useAxiosWrapper();
  const [taskType, setTaskType] = useState(TEAMS_PROJECTS_TOGGLE_LABEL.TEAMS);
  const [departmentName, setDepartmentName] = useState('Department');
  const [enterpriseId, setEnterpriseId] = useState('');
  const [breadCrumb, setBreadCrumb] = useState([]);
  const [teamApprovalPermission, setTeamApprovalPermission] = useState(false);
  const [userDepRole, setUserDepRole] = useState(null);
  const { id, ...rest } = useParams();
  let currentRoute = rest['*'];
  const userId = tokenService.getSession().id;

  let showToggle =
    currentRoute?.toLowerCase() === 'teams' ||
    currentRoute?.toLowerCase() === 'projects';

  const getDepartmentName = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_DEPARTMENT_DETAILS}/${id}`,
      method: 'GET',
      type: 'FETCH_DEPARTMENT_DETAILS',
    });

    if (res.data.statusCode === 200) {
      const result = res.data.data;
      setDepartmentName(result?.departmentName);
      setEnterpriseId(result?.enterpriseId);

      const loggedInDepartmentMember = result?.departmentMembers.find(
        (member) => member?.memberUserId === userId
      );
      if (loggedInDepartmentMember) {
        setTeamApprovalPermission(
          loggedInDepartmentMember?.teamApprovalPermission
        );
        setUserDepRole(loggedInDepartmentMember?.role);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getDepartmentName();
  }, [getDepartmentName]);

  let location = useLocation();
  let navigate = useNavigate();

  const onToggleHandler = (value) => {
    setTaskType(value);
    getBreadCrumb(value);
    const type = value.toLowerCase();
    navigate(
      `/individual/profile/organization/enterprise/department/${id}/${type}`
    );
  };

  const getBreadCrumb = useCallback(
    async (value) => {
      let crumbs = getBreadCrumbList();
      let newCrumbs = [
        ...crumbs,
        {
          id: 3,
          label: value,
          url: location.pathname,
        },
      ];
      breadCrumbListStorage([...new Set(crumbs)]);
      setBreadCrumb([...new Set(newCrumbs)]);
    },
    [location]
  );

  useEffect(() => {
    let pathItems = location.pathname.split('/');
    setTaskType(capitalizeFirstLetter(pathItems[7]));
    getBreadCrumb(capitalizeFirstLetter(pathItems[7]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBreadCrumb]);

  let itemOnClickHandler = (url, index) => {
    let crumbs = getBreadCrumbList();
    crumbs = crumbs.slice(0, index + 1);
    breadCrumbListStorage(crumbs);
    navigate(url);
  };

  return (
    <TeamListWrapper>
      <div>
        <BreadCrumb
          crumbs={breadCrumb}
          seperator={'>'}
          itemOnClickHandler={itemOnClickHandler}
        />
      </div>
      {showToggle ? (
        <div className="header-text">
          {departmentName}
          <ToggleSwitch
            activeLabel={taskType}
            onClick={onToggleHandler}
            toggleLabel={[
              TEAMS_PROJECTS_TOGGLE_LABEL.TEAMS,
              TEAMS_PROJECTS_TOGGLE_LABEL.PROJECTS,
            ]}
            width="16rem"
          />
        </div>
      ) : null}
      <Routes>
        <Route path="">
          <Route element={<Permissions />} path="permissions" />
          <Route
            element={<TaskPage enterpriseId={enterpriseId} />}
            path="tasks"
          />
          <Route
            element={<Career enterpriseId={enterpriseId} />}
            path="career/*"
          />
          <Route
            element={
              <TeamList
                isTeamListUpdate={isListUpdate}
                teamApprovalPermission={teamApprovalPermission}
                userDepRole={userDepRole}
              />
            }
            path="teams"
          />
          <Route
            element={
              <ProjectList
                type={TYPES.ENTERPRISE}
                baseUrl={`/individual/profile/organization/enterprise/department/${id}/projects`}
                userDepRole={userDepRole}
              />
            }
            path=":type"
          />
          <Route
            element={
              <ViewProject
                type={TYPES.ENTERPRISE}
                baseUrl={`/individual/profile/organization/enterprise/department/${id}/projects`}
              />
            }
            path=":type/:projectId/view/*"
          />
          <Route
            element={
              <CreateProjectProvider>
                <CreateProject
                  type={TYPES.ENTERPRISE}
                  baseUrl={`/individual/profile/organization/enterprise/department/${id}/projects`}
                />
              </CreateProjectProvider>
            }
            path=":type/create/*"
          />
          <Route
            element={
              <CreateProjectProvider>
                <EditProject
                  type={TYPES.ENTERPRISE}
                  baseUrl={`/individual/profile/organization/enterprise/department/${id}/projects`}
                />
              </CreateProjectProvider>
            }
            path=":type/:projectId/:action/*"
          />
          <Route
            element={
              <CreateTeamProvider>
                <AddTeams teamType={'ENTERPRISE'} isEnterpriseTeam={true} />
              </CreateTeamProvider>
            }
            path="teams/create/*"
          />
        </Route>
      </Routes>
    </TeamListWrapper>
  );
};

export default Department;
