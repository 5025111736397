import React from 'react';
import {
  PublicationFormWrapper,
  SelectedCollaboratorsWrapper,
} from 'pages/TeamDashboard/TeamPublications/Components/PublicationForm/style';
import {
  ModalPopup,
  CustomButton,
  ScrollableDiv,
  Input,
  Editor,
  CustomFileUploadButton,
  SearchBoxWithDropDown,
  DropDown,
} from 'components';
import Icon from 'utlils/Icons/Icon';
import RadioGroup from 'pages/IndividualDashboard/components/radioGroup';
import { useState } from 'react';
import { PUBLICATION_CATEGORY_OPTIONS } from 'pages/TeamDashboard/TeamPublications/constants';
import { GROUP_PUBLICATION_PRIVACY_OPTIONS } from './constants';
import { SEARCH_USER, useAxiosWrapper } from 'services';
import { getProfileText } from 'utlils/utils';
import { toast } from 'react-toastify';

const DEFAULT_PUBLICATION_STATE = {
  name: '',
  description: '',
  privacy: GROUP_PUBLICATION_PRIVACY_OPTIONS[1]?.value,
  referenceLink: '',
  coverImage: null,
  attachments: [],
  category: PUBLICATION_CATEGORY_OPTIONS[3],
  selectedCollaboratorMembers: [],
};

export const PublicationsPopUp = (props) => {
  const {
    beingEdited = false,
    initialProps = DEFAULT_PUBLICATION_STATE,
    closePopup = {},
    onPublish,
    onUpdate,
  } = props;

  const [publicationData, setPublicationData] = useState({
    ...DEFAULT_PUBLICATION_STATE,
    ...initialProps,
  });
  const [searchMember, setSearchMember] = useState('');
  const [filteredMemberData, setFilterMemberData] = useState([]);
  const [isPublicationCreated, setIsPublicationCreated] = useState(false);

  const [fnWrapper, context] = useAxiosWrapper();
  const session = JSON.parse(localStorage.getItem('session'));

  const inputChangeHandler = (label = '', value = '') => {
    setPublicationData((state) => ({
      ...state,
      [label]: value,
    }));
  };

  const isBtnDisabled = () => {
    return (
      (!publicationData?.name?.length > 0 && !publicationData?.description) ||
      //  the below code checks for blank or whitespace strings and return true if they are present,
      //   the replace function replaces all html elements with a string of 0 length
      /^\s*$/.test(publicationData?.description.replace(/<\/?[^>]+(>|$)/g, ''))
    );
  };

  const radioBtnChangeHandler = (value = '') => {
    setPublicationData((state) => ({
      ...state,
      privacy: value,
    }));
  };

  const addAttachments = (newAttachments) => {
    // Add to publicationData.attachments
    setPublicationData((prev) => ({
      ...prev,
      attachments: [...(prev.attachments || []), ...newAttachments],
    }));
  };

  const removeAttachment = (removedItem) => {
    const remainingAttachments = publicationData?.attachments.filter((item) => {
      // Check if removedItem is a string or File object (AWS URL)
      if (typeof removedItem === 'string') {
        return item !== removedItem;
      } else if (typeof removedItem === 'object' && removedItem.name) {
        return item?.name !== removedItem.name;
      }
      return true;
    });

    setPublicationData((prev) => ({
      ...prev,
      attachments: remainingAttachments,
    }));
  };

  const handleAttachmentChange = (label, value, actionType) => {
    if (label === 'attachments') {
      // Handle attachments
      // update attachments based on value (FileList or removed item)
      if (
        value instanceof FileList &&
        value.length > 0 &&
        actionType === 'add'
      ) {
        // Add new attachments
        addAttachments(Array.from(value));
      } else {
        // Remove attachment
        removeAttachment(value);
      }
    } else if (label === 'coverImage') {
      // Handle cover image
      // Check if the cover image size is within the limit
      const maxSizeInBytes = 1 * 1024 * 1024; // 1MB
      const fileSize = value[0]?.size;
      if (fileSize > maxSizeInBytes) {
        toast.warn('Cover image size cannot be greater than 1MB.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });

        return;
      }

      // Handle cover image
      setPublicationData((prev) => ({ ...prev, coverImage: value }));
    }
  };

  const resetBtnHandler = () => {
    setPublicationData(initialProps);
  };

  const formSubmissionHandler = () => {
    setIsPublicationCreated(false);
    const linkedFileAttachments =
      publicationData?.attachments &&
      publicationData?.attachments.filter(
        (item) => typeof item === 'string' && item.includes('amazonaws.com')
      );
    const attachments =
      publicationData?.attachments &&
      publicationData?.attachments.filter((item) => typeof item !== 'string');
    const payload = {
      ...publicationData,
      attachments: attachments,
      linkedAttachments: linkedFileAttachments,
    };
    if (beingEdited) {
      onUpdate(payload);
    } else {
      onPublish(publicationData);
    }
    setIsPublicationCreated(true);
  };

  const onSelectSearchMember = async (member) => {
    let searchedMember = {
      userId: member?.userId,
      memberName: member?.name,
      profilePicture: member?.profilePicture,
      profileText: member?.profileText,
    };

    // Check if the member with the same userId already exists
    const isMemberExists = publicationData?.selectedCollaboratorMembers.some(
      (ele) => ele.userId === searchedMember.userId
    );

    if (!isMemberExists) {
      let finalArray = [
        ...publicationData.selectedCollaboratorMembers,
        searchedMember,
      ].filter((ele) => ele.memberName !== '');

      setPublicationData({
        ...publicationData,
        selectedCollaboratorMembers: finalArray,
      });
    }

    setSearchMember('');
  };

  const onChangeSearchMember = async (value) => {
    setSearchMember(value);
    if (value.length >= 2) {
      let data = {
        q: value,
      };
      const res = await fnWrapper({
        url: SEARCH_USER,
        method: 'POST',
        type: 'SEARCH_USER',
        payload: data,
      });

      if (res.data.statusCode === 200) {
        const searchData = res.data.data;
        let finalArray = [];
        if (searchData.length) {
          finalArray = searchData
            .map((ele) => {
              return {
                id: ele.id,
                userId: ele.userId,
                name: ele.name,
                email: ele.user.email,
                userName: ele.user.userName,
                profilePicture: ele.profilePicture,
                profileText: getProfileText(ele.name),
              };
            })
            .filter((ele) => ele.userId !== session.id);
        }
        setFilterMemberData(finalArray);
      } else {
        setFilterMemberData([]);
      }
    }
  };
  const onRemoveSelectedItemHandler = (removedCollaborator) => {
    // Filter out the removed collaborator from the state
    const updatedMembers = publicationData?.selectedCollaboratorMembers.filter(
      (collaborator) => collaborator !== removedCollaborator
    );

    // Update the state with the new array of collaborators
    setPublicationData({
      ...publicationData,
      selectedCollaboratorMembers: updatedMembers,
    });
  };

  return (
    <PublicationFormWrapper>
      <ModalPopup
        open={false}
        minWidth="400px"
        maxContentWidth="900px"
        title={'Publish Publication'}
        onClose={closePopup}>
        <ScrollableDiv>
          <div className="new-publication-modal">
            <Input
              label="Name"
              value={publicationData?.name}
              required
              onChange={(e) => inputChangeHandler('name', e?.target?.value)}
            />
            <Editor
              label="Description"
              placeholder="Add a description for your publication"
              value={publicationData?.description}
              required
              onChange={(e) => {
                inputChangeHandler('description', e);
              }}
            />
            <div className="privacy-n-category">
              <label>
                <div className="label">Privacy</div>
                <RadioGroup
                  handleChange={(e) => radioBtnChangeHandler(e?.target?.value)}
                  customClassName="privacy-radio-group"
                  currChecked={publicationData?.privacy}
                  labels={GROUP_PUBLICATION_PRIVACY_OPTIONS}
                />
              </label>
            </div>

            <div className="collaborators">
              <div className="member-collaborators">
                <label className="label">Member Collaborators (if any)</label>
                <SearchBoxWithDropDown
                  searchInputValue={searchMember}
                  placeholder="Search for member"
                  searchData={filteredMemberData}
                  onSelectSearchItem={onSelectSearchMember}
                  onInputChangeHandler={onChangeSearchMember}
                />
                <SelectedCollaboratorsWrapper className="selected-collaborators-list">
                  {publicationData?.selectedCollaboratorMembers &&
                    publicationData?.selectedCollaboratorMembers.map(
                      (collaborator, index) => (
                        <div className="collaborator-items" key={index}>
                          {collaborator?.profilePicture ? (
                            <img
                              className="profile-image"
                              src={collaborator?.profilePicture}
                              alt={collaborator?.memberName}
                            />
                          ) : (
                            <div className="profile-text">
                              {collaborator?.profileText}
                            </div>
                          )}
                          <div className="user-name">
                            {collaborator?.memberName}
                          </div>
                          <Icon
                            name="cross"
                            color="#219fff"
                            onClick={() =>
                              onRemoveSelectedItemHandler(collaborator)
                            }
                          />
                        </div>
                      )
                    )}
                </SelectedCollaboratorsWrapper>
              </div>
            </div>

            <div className="linkProject-category">
              <div className="category">
                <DropDown
                  label="Category"
                  placeholder="Select"
                  objectKey="label"
                  required
                  items={PUBLICATION_CATEGORY_OPTIONS}
                  onClick={(category) =>
                    inputChangeHandler('category', category)
                  }
                  value={publicationData.category}
                />
              </div>
            </div>
            <Input
              label="Link for reference (If any)"
              placeholder="Paste or type your link here"
              value={publicationData?.referenceLink}
              onChange={(e) =>
                inputChangeHandler('referenceLink', e?.target?.value)
              }
            />
            <div className="upload-containers">
              <div className="Upload-publication-image">
                <div className="d-flex flex-column">Upload Cover Image</div>
                <div className="Upload-btn text-underline">
                  <CustomFileUploadButton
                    iconColor="#219FFF"
                    iconSize={20}
                    textColor="#219fff"
                    iconName="upload-box"
                    borderStyle="none"
                    label="Upload"
                    handleFile={(e) =>
                      handleAttachmentChange('coverImage', e, 'add')
                    }
                  />
                </div>
              </div>
              <p className="image-info">
                {publicationData?.coverImage
                  ? publicationData?.coverImage instanceof FileList
                    ? publicationData?.coverImage[0]?.name
                    : typeof publicationData?.coverImage === 'string'
                    ? publicationData?.coverImage?.split('/')[6] || ''
                    : ''
                  : ''}
              </p>
            </div>
            <div className="upload-containers">
              <div className="add-publication-attachment">
                <div className=" label d-flex flex-column">Add Attachment</div>
                <span className="text-underline">
                  <CustomFileUploadButton
                    handleFile={(e) =>
                      handleAttachmentChange('attachments', e, 'add')
                    }
                    iconColor="#219FFF"
                    iconSize={17}
                    textColor="#219fff"
                    iconName="add"
                    borderStyle="none"
                    isMultiple={true}
                    label="Add"
                  />
                </span>
              </div>
              {publicationData?.attachments &&
                publicationData?.attachments.map((item, index) => (
                  <div key={index} className="uploaded-image-info-container">
                    <p className="image-info">
                      {typeof item === 'string'
                        ? item?.split('/')[5]
                        : item?.name}
                    </p>
                    <Icon
                      name="cross"
                      color="#bdbdbd"
                      onClick={() =>
                        handleAttachmentChange('attachments', item, 'remove')
                      }
                    />
                  </div>
                ))}
            </div>
            <div className="btn-group">
              <CustomButton
                onClick={resetBtnHandler}
                label="Reset"
                disabled={isPublicationCreated}
                variant="textBtn"
                color="themeGray"
              />
              <CustomButton
                label={beingEdited ? 'Update' : 'Publish'}
                customPadding="16px 33px"
                onClick={formSubmissionHandler}
                disabled={isBtnDisabled()}
                loading={
                  (!beingEdited &&
                    context.busy.status &&
                    context.busy.type === 'CREATE_PUBLICATION') ||
                  isPublicationCreated
                }
                className="update-publish-btn"
              />
            </div>
          </div>
        </ScrollableDiv>
      </ModalPopup>
    </PublicationFormWrapper>
  );
};
