import { MediaCardWrapper } from './style';

const MediaCard = ({ mediaItem }) => {
  return (
    <MediaCardWrapper>
      <a
        download={mediaItem?.fileName}
        href={mediaItem?.location}
        title={mediaItem?.fileName}>
        {mediaItem?.type === 'image' ? (
          <img
            className="media-card-content"
            alt={mediaItem?.fileName}
            src={mediaItem?.location}
          />
        ) : (
          <div className="image-container">
            <div className="media-card-content black-image" />
            <div className="play-button"></div>
          </div>
        )}
      </a>
    </MediaCardWrapper>
  );
};

export default MediaCard;
