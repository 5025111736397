import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, CustomButton, Title } from 'components';
import { ProfItem } from './profItem.js';
import AddButton from '../../../components/addButton';
import { useAxiosWrapper } from 'services/useAxiosWrapper.js';
import {
  CREATE_PROFESSIONAL_DETAILS,
  FETCH_PROFESSIONAL_DETAILS,
} from 'services';
import TokenService from 'services/token.service';
import Icon from 'utlils/Icons/Icon.js';
import { checkForSpacesInStartEnd } from 'helpers/index.js';

const DEFAULTSTATE = [
  {
    itemId: '',
    companyName: '',
    role: '',
    description: '',
    location: '',
    isPersue: false,
    from: { month: new Date(), year: new Date() },
    to: { month: new Date(), year: new Date() },
  },
];

export const CreateProfessionalDetails = ({
  title,
  setCurrType,
  nextType,
  isEditEvent,
  type,
}) => {
  let navigate = useNavigate();
  const [fnWrapper, context] = useAxiosWrapper();
  const [state, setState] = useState(DEFAULTSTATE);
  const location = useLocation();

  const addNewItemRowHandler = () => {
    setState([
      ...state,
      {
        itemId: '',
        companyName: '',
        role: '',
        location: '',
        isPersue: false,
        description: '',
        from: { month: new Date(), year: new Date() },
        to: { month: new Date(), year: new Date() },
      },
    ]);
  };
  const deleteItemRowHandler = (index) => {
    const temp = [...state];
    temp.splice(index, 1);
    setState(temp);
  };

  const onSaveHandler = async () => {
    const loggedInUser = TokenService.getSession();
    const payload = state.map((item) => {
      let fromMonth = new Date(item.from.month).toLocaleString('default', {
        month: '2-digit',
      });
      let toMonth = new Date(item.to.month).toLocaleString('default', {
        month: '2-digit',
      });
      let fromYear = new Date(item.from.year).toLocaleString('default', {
        year: 'numeric',
      });
      let toYear = new Date(item.to.year).toLocaleString('default', {
        year: 'numeric',
      });

      return {
        userId: loggedInUser.id,
        itemId: item.itemId ? item.itemId : null,
        companyName: item.companyName,
        role: item.role,
        location: item.location,
        description: item.description,
        from: new Date(fromYear, fromMonth - 1, 1),
        to: item.isPersue ? null : new Date(toYear, toMonth - 1, 1),
      };
    });
    const res = await fnWrapper({
      url: CREATE_PROFESSIONAL_DETAILS,
      method: 'POST',
      type: 'CREATE_PROFESSIONAL_DETAILS',
      payload: payload,
    });

    if (res.data.statusCode === 200) {
      if (isEditEvent) {
        if (location.state !== null && location.state.from === 'public_page') {
          navigate(location.state.redirectTo);
        } else {
          navigate(`/individual/profile/info/professional_details`);
        }
      } else {
        setCurrType(nextType);
      }
    }
  };

  const getProfessionalDetails = useCallback(async () => {
    const loggedInUser = TokenService.getSession();
    const res = await fnWrapper({
      url: `${FETCH_PROFESSIONAL_DETAILS}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_PROFESSIONAL_INFO',
    });
    if (res.data.statusCode === 200) {
      const response = res.data.data;
      let dataArray = [];
      if (response.length) {
        response.forEach((ele) => {
          let data = {
            itemId: ele.id,
            companyName: ele.companyName,
            role: ele.role,
            description: ele.description,
            location: ele.location,
            isPersue: ele.to === null ? true : false,
            from: { month: new Date(ele.from), year: new Date(ele.from) },
            to:
              ele.to === null
                ? { month: new Date(), year: new Date() }
                : { month: new Date(ele.to), year: new Date(ele.to) },
          };
          dataArray = [...dataArray, data];
        });
      } else {
        dataArray = [
          {
            itemId: '',
            companyName: '',
            role: '',
            description: '',
            location: '',
            isPersue: false,
            from: { month: new Date(), year: new Date() },
            to: { month: new Date(), year: new Date() },
          },
        ];
      }
      setState(dataArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onInputChangeHandler = (value, index, type) => {
    const temp = [...state];
    if (type === 'companyName') {
      temp[index].companyName = value;
    }
    if (type === 'role') {
      temp[index].role = value;
    }
    if (type === 'location') {
      temp[index].location = value;
    }
    if (type === 'description') {
      temp[index].description = value;
    }
    if (type === 'isPersue') {
      temp[index].isPersue = !temp[index].isPersue;
    }

    if (type === 'from-month') {
      temp[index].from = { ...temp[index].from, month: value };
    }
    if (type === 'from-year') {
      temp[index].from = { ...temp[index].from, year: value };
    }
    if (type === 'to-month') {
      temp[index].to = { ...temp[index].to, month: value };
    }
    if (type === 'to-year') {
      temp[index].to = { ...temp[index].to, year: value };
    }
    setState(temp);
  };

  useEffect(() => {
    getProfessionalDetails();
  }, [getProfessionalDetails]);

  /* Disable button */
  const isDisabled = () => {
    const eduError = (obj) =>
      obj.companyName.length === 0 ||
      checkForSpacesInStartEnd(obj.companyName) ||
      obj.role.length === 0 ||
      checkForSpacesInStartEnd(obj.role) ||
      obj.location.length === 0 ||
      checkForSpacesInStartEnd(obj.location) ||
      obj.description.length === 0 ||
      checkForSpacesInStartEnd(obj.description) ||
      obj.from.month === null ||
      obj.from.year === null ||
      (!obj.isPersue && (obj.to.month === null || obj.to.year === null));
    return state.some(eduError);
  };
  const disable = isDisabled();

  return (
    <div className="prof-detail-container">
      <Box display="flex" justifyContent="space-between">
        <Title title={title} />
        <AddButton onClick={addNewItemRowHandler} />
      </Box>
      {state.map((item, index) => (
        <ProfItem
          index={index}
          key={index}
          data={item}
          onDelete={deleteItemRowHandler}
          onInputChangeHandler={onInputChangeHandler}
        />
      ))}
      <div className="row mx-md-3">
        {!isEditEvent && <div className="col-md-4 col-2"></div>}
        <div className={!isEditEvent ? 'col-md-4 col-6' : 'col-md-12 col-12'}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap="15px"
            mt="30px">
            <Box width="120px">
              <CustomButton
                className="w-100"
                label="Save"
                onClick={onSaveHandler}
                disabled={disable}
                loading={context.busy.status}
              />
            </Box>
            <Box width="120px">
              <CustomButton
                className="w-100"
                variant="textBtn"
                color="themeGray"
                onClick={() => {
                  setCurrType(nextType);
                  navigate(`/individual/profile/${type}/${nextType}`);
                }}
                loading={context.busy.status}>
                Next
                <Icon name="arrow-forward" />
              </CustomButton>
            </Box>
          </Box>
        </div>
        {!isEditEvent && (
          <div className="col-md-4 col-4 d-flex align-items-center justify-content-end">
            <Box width="120px">
              <CustomButton
                className="w-100"
                variant="textBtn"
                color="themeGray"
                onClick={() => setCurrType(nextType)}>
                Skip
                <Icon name="arrow-right" size={20} color="#404F67" />
              </CustomButton>
            </Box>
          </div>
        )}
      </div>
    </div>
  );
};
