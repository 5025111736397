const termsAndConditionContent = `
__MiHint Technologies LLC Terms and Conditions__

Last Updated: December 3rd, 2023

Purpose and Scope

MiHint Technologies LLC (“we,” “us” or “our”) is an innovative startup that aims to revolutionize the professional networking, collaboration, and project management industry. We want to partner with you and power you to reach your challenging compliance goals so you can achieve assurance that your business is operating as you intended: securely and effectively. There Terms and Conditions ("Terms") explain how we govern your access to and use of MiHint.com any other websites and applications owned by MiHint Technologies LLC (together “Platform”).  
The Platform is conditioned upon your acceptance of and compliance with these Terms. These Terms apply to all visitors, users and others who wish to access or use the Platform.

By accessing or using the Platform you agree to be bound by these Terms. If you disagree with any part of the Terms, then you do not have permission to access the Platform.

Please read these Terms carefully before using the Platform.

Intellectual Property

The Platform and its original content, features and functionality are and will remain the exclusive property of MiHint Technologies LLC and its licensors. The Platform is protected by copyright, trademark, patent, trade secret and other applicable intellectual property laws of both the United States and foreign countries. It is prohibited to use our intellectual property, including trademarks and trade dress, in connection with any product or service without the prior written consent of MiHint Technologies LLC. 
Links to Other Websites
Our Platform may contain links to third-party websites or third-party services that are not owned or controlled by MiHint Technologies LLC.
MiHint Technologies LLC has no control over and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites or third-party services. We do not warrant the offerings of any of these entities/individuals or their websites.
You acknowledge and agree that MiHint Technologies LLC shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such third-party websites or third-party services.
We strongly advise you to read the terms and conditions and privacy policies of any third-party websites or third-party services that you visit.
Registration
To use certain features of the Platform, you may be required to register with MiHint Technologies LLC on the form provided on the Platform. You agree (a) to provide true, accurate, current and complete information about yourself as prompted by the registration form;(b) to maintain and promptly update the information you provide to keep it true, accurate, current and complete; and (c) that you are not a minor. You agree not to: (i) select a username that, to your knowledge, is already used by another person; (ii) use a username in which another person has rights without such person’s authorization; or (iii) use a username or password that MiHint Technologies, in its sole discretion, deems offensive or inappropriate. MiHint Technologies LLC has no obligation to verify the authenticity of any registration information, but reserves the right to refuse to create your account, to suspend or terminate your account, and/or to refuse any future account use if we are unable to verify the authenticity of your registration information or have reasonable grounds to suspect that any information provided by you is untrue, inaccurate, not current or incomplete. You are solely responsible for maintaining the confidentiality of your registration information and passwords. MiHint Technologies LLC will have the right to rely upon any information received from any person using a password assigned to you and will incur no liability arising out of such reliance. Our use of any personally identifying information you provide to us as part of the registration process is governed by the Terms of our Privacy Policy.
Termination
We may cancel, suspend, or terminate your access to the Platform immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including, but not limited to, a breach of the Terms. In the event of cancellation, suspension, or termination, you are no longer authorized to access the part of the Platform affected by such cancellation, suspension or termination.
All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.
Privacy
MiHint Technologies LLC is committed to protecting the privacy and security of your Personal Information. Our Privacy Policy explains how we Process Personal Information in accordance with applicable data protection laws, including, but not limited to, U.S. state and federal privacy laws In addition, it sets out individuals' rights related to our Processing of such Personal Information.

For more information about MiHint Technologies’ commitment to privacy and security, how we Process Personal Information in accordance with applicable data protection laws and/or your rights related to our Processing of your Personal Information, please see our privacy policy.

Data Usage

We will maintain certain data that you transmit to the Platform for the purpose of managing the performance of the Platform, as well as data relating to your use of the Platform. Although we perform regular routine backups of data, you are solely responsible for all data that you transmit or that relates to any activity you have undertaken using the Platform. You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby waive any right of action against us arising from any such loss or corruption of such data.

Limitation of Liability
In no event shall MiHint Technologies, nor its licensees, licensors, employees, contractors, agents, officers, directors, partners, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the Platform; (ii) any conduct or content of any third-party on the Platform; (iii) any content obtained from the Platform; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage and even if a remedy set forth herein is found to have failed of its essential purpose.
Indemnification

You agree to defend, indemnify and hold harmless MiHint Technologies LLC and its licensees, licensors, employees, contractors, agents, officers, directors, partners, suppliers, or affiliates,  from and against any and all claims, damages, obligations, losses, liabilities, costs or debt and expenses (including, but not limited to, attorney’s fees), resulting from or arising out of a) your use and access of the Platform, or b) a breach of these Terms.

Notice to All Credit Card Issuers and Users: When you issue and use credit cards, you assume the risk that the security of card data will be compromised. Although MiHint Technologies LLC may assist its merchant clients with assessing PCI security, credit card data security cannot be assured. MiHint Technologies LLC may make mistakes. MiHint Technologies LLC assumes no liability to you.

Disclaimer of Warranties
THIS PLATFORM IS PROVIDED "AS IS" AND "AS AVAILABLE," WITHOUT WARRANTY OF ANY KIND. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, MIHINT TECHNOLOGIES LLC DISCLAIMS ALL WARRANTIES, EXPRESSED OR IMPLIED, INCLUDING WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. MIHINT TECHNOLOGIES LLC DOES NOT WARRANT THAT THE FUNCTIONALITY OF THE PLATFORM WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT ANY PART OF THE PLATFORM, OR THE SERVERS THAT MAKE THE PLATFORM AVAILABLE, ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. PLEASE NOTE THAT SOME JURISDICTIONS MAY NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO SOME OF THE FOREGOING EXCLUSIONS MAY NOT APPLY TO YOU. CHECK YOUR LOCAL LAWS FOR RESTRICTIONS OR LIMITATIONS REGARDING THE EXCLUSION OF IMPLIED WARRANTIES. 
MiHint Technologies LLC shall not be responsible for any loss, damage, liability or expense that may accompany or result from your use of or access to this Platform.
General Provisions

These Terms will be governed by and construed in accordance with the laws of the [Delaware], without reference to its conflicts of laws rules. You agree that any action of whatever nature arising from or relating to these Terms of Use or the Platform, to the extent arbitration is not legally available, will be filed only in the state or federal courts located in [Delaware]. You consent and submit to the personal jurisdiction of such courts for the purposes of any claim or action. Any provision of these Terms of Use that is held to be invalid or unenforceable will be deleted and replaced by a valid and enforceable provision which achieves, as far as possible, the same objectives as the severed provision was intended to achieve. The remaining provisions of these Terms of Use shall continue in full force and effect. Neither these Terms of Use, nor any terms contained herein, shall be construed as creating or constituting a partnership, joint venture or agency relationship between the parties. No failure of MiHint Technologies LLC to exercise or enforce any of its rights hereunder will act as a waiver of such rights. Except as otherwise expressly provided herein, all remedies provided for herein shall be cumulative and in addition to and not in lieu of any other remedies available to either party at law, in equity or otherwise.

Changes

Because the internet is an evolving medium, we may need to change these Terms or impose new conditions on the use of the Platform from time to time. We reserve the right, at our sole discretion, to modify or replace these Terms at any time, in which case we will post the revised Terms on this Platform. Please check these Terms periodically to inform yourself of any changes.
By continuing to access or use our Platform after any revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, you are no longer authorized to use the Platform. 
Contacting Us
If there are any questions regarding this privacy policy or to exercise the rights afforded to you over your Personal Information, you may contact us using the information below.
Email Address: __Legal@mihint.ai__
`;

export default termsAndConditionContent;
