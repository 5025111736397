import { Box } from 'components';
import { useEffect } from 'react';
import { useState } from 'react';

const ViewEnterpriseDetails = ({ userProfile }) => {
  const [state, setState] = useState({
    enterpriseName: null,
    enterpriseType: null,
    category: null,
    website: null,
    email: null,
    contact: null,
    about: null,
  });

  useEffect(() => {
    setState({
      ...userProfile,
    });
  }, [userProfile]);
  return (
    <div className="row mb-2 enterprise-details-container">
      <div className="row">
        <div className="col-xl-6 col-xxl-5 col-sm-6 col-12">
          <Box mt="10px">
            <p className="label">Enterprise Name</p>
            <p className="value">
              {state?.enterpriseName ? state?.enterpriseName : 'NA'}
            </p>
          </Box>
        </div>
        <div className="col-xl-6 col-xxl-4 col-sm-6 col-12">
          <Box mt="10px">
            <p className="label">Enterprise Type</p>
            <p className="value">
              {state?.enterpriseType?.title
                ? state?.enterpriseType?.title
                : 'NA'}
            </p>
          </Box>
        </div>
        <div className="col-xl-6 col-xxl-3 col-sm-6 col-12">
          <Box mt="10px">
            <p className="label">Enterprise Category</p>
            <p className="value">
              {state?.enterpriseCategory?.title
                ? state?.enterpriseCategory?.title
                : 'NA'}
            </p>
          </Box>
        </div>
        <div className="col-xl-6 col-xxl-5 col-sm-6 col-12">
          <Box mt="10px">
            <p className="label">Website</p>
            <p className="value">{state?.link ? state?.link : 'NA'}</p>
          </Box>
        </div>
        <div className="col-xl-6 col-xxl-4 col-sm-6 col-12">
          <Box mt="10px">
            <p className="label">Email</p>
            <p className="value">{state?.email ? state?.email : 'N/A'}</p>
          </Box>
        </div>
        <div className="col-xl-6 col-xxl-3 col-sm-6 col-12">
          <Box mt="10px">
            <p className="label">Contact Number</p>
            <p className="value">
              {state?.contactNumber ? state?.contactNumber : 'NA'}
            </p>
          </Box>
        </div>
        <div className="col-12">
          <Box mt="30px">
            <Box mb="22px">
              <h5 className="label">About Enterprise</h5>
            </Box>
            <p className="value">
              {state?.aboutEnterprise ? state?.aboutEnterprise : 'NA'}
            </p>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default ViewEnterpriseDetails;
