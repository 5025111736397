import styled from 'styled-components';

export const PriceRangeStyle = styled.div`
  font-family: 'Poppins';
  .priceRange {
    &__label {
      font-size: 14px;
      color: #404f67;
      font-weight: 600;
    }
    & .disabled {
      cursor: not-allowed;
    }
    &__inputBox {
      display: flex;
      gap: 10px;
      align-items: center;
      margin-top: 8px;
    }
    &__errorMessage {
      margin-top: 10px;
      color: #ff1e26;
      font-size: 14px;
      font-weight: 400;
    }
  }
`;
