/**
 * @description All the supported screen sizes (screen width, height)
 */
const SCREEN = Object.freeze({
  height: Object.freeze({
    /**
     * All large sized screens
     */
    large1080: window.innerHeight <= 1080,
    large1024: window.innerHeight <= 1024,
    large900: window.innerHeight <= 900,
    large896: window.innerHeight <= 896,
    large864: window.innerHeight <= 864,
    /**
     * All medium-sized screens
     */
    medium812: window.innerHeight <= 812,
    medium800: window.innerHeight <= 800,
    medium780: window.innerHeight <= 780,
    medium768: window.innerHeight <= 768,
    medium760: window.innerHeight <= 760,
    medium720: window.innerHeight <= 720,
    /**
     * All small sized screens
     */
    small667: window.innerHeight <= 667,
    small600: window.innerHeight <= 600,
  }),
  width: Object.freeze({
    /**
     * All large sized screens
     */
    large1920: window.innerWidth <= 1920,
    large1600: window.innerWidth <= 1680, // Mac 15 inch
    large1536: window.innerWidth <= 1536, // Common PC Laptop
    large1460: window.innerWidth <= 1460,
    large1440: window.innerWidth <= 1440, // Mac 13 inch
    large1366: window.innerWidth <= 1366,
    large1280: window.innerWidth <= 1280,
    large1024: window.innerWidth <= 1024,
    /**
     * All medium-sized screens
     */
    medium962: window.innerWidth <= 962,
    medium810: window.innerWidth <= 810,
    medium800: window.innerWidth <= 800,
    medium768: window.innerWidth <= 768,
    medium700: window.innerWidth <= 700,
    medium600: window.innerWidth <= 600,
    /**
     * All small sized screens
     */
    small414: window.innerWidth <= 414,
    small375: window.innerWidth <= 375,
    small360: window.innerWidth <= 360,
  }),
});

export { SCREEN };
