import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader } from 'components';
import { ExploreTeamProjectCard } from 'pages/Explore/Components';
import { SavedJobWrapper } from './style';
import {
  useAxiosWrapper,
  FETCH_SAVED_JOBS_BASED_ON_USERID,
  SAVE_JOB,
} from 'services';
import tokenService from 'services/token.service';
import dayjs from 'dayjs';
import { getCurrencySymbol } from 'components/PriceInput/helper';

export const SavedJobs = () => {
  const [fnWrapper, context] = useAxiosWrapper();
  const [jobList, setJobList] = useState([]);
  const navigate = useNavigate();

  const viewButtonHandler = (index) => {
    const data = jobList.filter((item) => item.id === index);
    navigate(`/explore/jobs/view-job/${data[0].id}`);
  };

  const getSavedJobs = useCallback(async () => {
    const loggedInUser = await tokenService.getSession();
    const res = await fnWrapper({
      url: `${FETCH_SAVED_JOBS_BASED_ON_USERID}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_SAVED_JOBS_BASED_ON_USER',
    });
    if (res.data.statusCode === 200) {
      const result = res.data.data;
      //console.log(result);
      const allJobs = [];
      result?.map((job) => {
        let profileImg;
        if (job?.job?.team?.enterprise) {
          profileImg =
            job?.job?.team?.enterprise?.profilePicture !== null
              ? job?.job?.team?.enterprise?.profilePicture
              : '';
        } else {
          profileImg =
            job?.job?.team?.user?.individual?.profilePicture !== null
              ? job?.job?.team?.user?.individual?.profilePicture
              : '';
        }
        const item = {
          id: job?.job?.id,
          jobTitle: job?.job?.title,
          owner: job?.job?.team?.enterprise
            ? job?.job?.team?.enterprise?.enterpriseName
            : job?.job?.team?.teamName,
          profileImgSrc: profileImg,
          profileIcon: 'project',
          // isJobSaved: job?.job?.isJobSaved,
          type: 'job',
          postedTime: dayjs(job?.job?.createdAt).format('DD MMM YYYY'),
          description: job?.job?.jobDescription,
          summary: job?.job?.summary,
          feature: [
            {
              iconName: 'location',
              iconName2: 'location-two',
              iconLabel: 'Job Location',
              label:
                job?.job?.regionCountry !== '' &&
                job?.job?.regionCountry !== null
                  ? job?.job?.regionCountry
                  : 'N/A',
            },
            {
              iconName: 'brief-case',
              iconName2: 'brief-case-two',
              iconLabel: 'Job Type',
              label: job?.job?.jobType,
            },
            {
              iconName: 'dollar-circle',
              iconName2: 'empty-wallet',
              iconLabel: 'Pay Range',
              label:
                job?.currencyType && job?.payRangeFrom && job?.payRangeTo
                  ? `${getCurrencySymbol(job?.job?.currencyType)}${
                      job?.job?.payRangeFrom
                    } - ${job?.job?.payRangeTo}`
                  : 'N/A',
            },
            {
              iconName: 'clock',
              iconName2: 'calender',
              iconLabel: 'Posted on',
              label: dayjs(job?.job?.createdAt).format('DD MMM YYYY'),
            },
          ],
        };
        allJobs.push(item);
        return allJobs;
      });
      setJobList(allJobs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const unsaveJob = async (jobId) => {
    const loggedInUser = tokenService.getSession();
    const res = await fnWrapper({
      url: `${SAVE_JOB}`,
      method: 'POST',
      payload: {
        jobId: jobId,
        userId: loggedInUser.id,
      },
      type: 'SAVE_JOBS',
    });
    if (res.data.statusCode === 200) {
      await getSavedJobs();
    }
  };

  useEffect(() => {
    getSavedJobs();
  }, [getSavedJobs]);

  return (
    <SavedJobWrapper>
      <div className="main-jobs-wrapper">
        <div className="heading">
          <h5 className="jobs-count">Saved Jobs ({jobList?.length})</h5>
        </div>
        {context.busy.status ? (
          <Loader loading={context.busy.status} />
        ) : jobList?.length > 0 ? (
          <>
            <div className="cards-container">
              {jobList?.map((job, index) => (
                <ExploreTeamProjectCard
                  key={index}
                  title={job.jobTitle}
                  type={job.type}
                  profileIcon={job.profileIcon}
                  owner={job.owner}
                  description={job.summary}
                  features={job.feature}
                  viewHandler={() => viewButtonHandler(job.id)}
                  isSaved={true}
                  onSaveHandler={() => unsaveJob(job.id)}
                />
              ))}
            </div>
          </>
        ) : (
          <div className="no-data-class">
            Looks like you didn't save any Job!
          </div>
        )}
      </div>
    </SavedJobWrapper>
  );
};
