export const ExperienceOptions = [
  {
    id: 'Beginner',
    label: 'Beginner (0 to 2 years)',
    value: 'BEGINNER',
  },
  {
    id: 'Intermediate',
    label: 'Intermediate (3 to 4 years)',
    value: 'INTERMEDIATE',
  },
  {
    id: 'Experienced',
    label: 'Experienced (5 years and more)',
    value: 'MASTER',
  },
];

export const WorkEnvironment = [
  {
    id: 'Remote',
    label: 'Remote',
    value: 'REMOTE',
  },
  {
    id: 'Hybrid',
    label: 'Hybrid',
    value: 'HYBRID',
  },
  {
    id: 'In-office',
    label: 'In-office',
    value: 'OFFICE',
  },
];

export const JobType = [
  {
    id: 'FullTime',
    label: 'Full-Time',
    value: 'Full-Time',
  },
  {
    id: 'PartTime',
    label: 'Part-Time',
    value: 'Part-Time',
  },
];

export const Recommendation = [
  {
    id: 'Yes',
    value: 'yes',
    text: 'Yes',
  },
  {
    id: 'No',
    value: 'no',
    text: 'No',
  },
];

export const DEFAULT_CREATE_JOB_STATE = {
  project: '',
  title: '',
  experience: '',
  jobType: '',
  workEnvironment: '',
  regionCountry: '',
  regionState: '',
  regionCity: '',
  currencyType: 'USD',
  payRangeFrom: null,
  payRangeTo: null,
  summary: '',
  jobDescription: '',
  technology: [],
  questions: [
    {
      question: '',
    },
  ],
  applyWith: [],
  isReferAllowed: null,
  designation: '',
  status: '',
};

export const DEFAULT_JOB_FILTER_STATE = {
  search: '',
  project: '',
  isHiring: '',
  startDate: null,
  endDate: null,
};

export const Technologies = [
  {
    id: 'React',
    title: 'React Js',
    value: 'React Js',
  },
  {
    id: 'Angular',
    title: 'Angular Js',
    value: 'Angular',
  },
  {
    id: 'UI/UX',
    title: 'UI-UX',
    value: 'UI-UX',
  },
  {
    id: 'QA',
    title: 'Quality Assurance',
    value: 'QA',
  },
];

export const postedTableHeaders = (args) => {
  const { userType, isCareerEnterprise } = args || {};
  const tableData = [
    {
      title: 'Hiring for Project',
      sortable: false,
      sortKey: 'projectName',
    },
    {
      title: 'Title',
      sortable: false,
      sortKey: 'title',
    },
    {
      title: 'Posted on',
      sortable: false,
      sortKey: 'postedOn',
    },
    {
      title: 'Applicants',
      sortable: false,
      sortKey: 'applicants',
    },
    {
      title: 'Close/Open',
      sortable: false,
      sortKey: 'isOpen',
    },
    { title: 'Edit', sortable: false, sortKey: 'edit' },
  ];
  if (userType === 'individual') {
    return [
      ...tableData,
      { title: 'Duplicate', sortable: false, sortKey: 'duplicate' },
      { title: 'Status', sortable: false, sortKey: 'status' },
    ];
  } else {
    if (!isCareerEnterprise) {
      return [
        ...tableData,
        { title: 'Status', sortable: false, sortKey: 'status' },
      ];
    } else {
      return tableData;
    }
  }
};

export const APPLICANTLISTTABLEHEADERS = [
  {
    title: 'Applicant',
    sortable: false,
    sortKey: 'applicant_name',
  },
  {
    title: 'Applied On',
    sortable: false,
    sortKey: 'applied_on',
  },
  {
    title: 'Applied By',
    sortable: false,
    sortKey: 'applied_by',
  },
  {
    title: 'Application',
    sortable: false,
    sortKey: 'application',
  },
  {
    title: 'Technology/skills',
    sortable: false,
    sortKey: 'technology',
  },
  {
    title: 'Shortlist',
    sortable: false,
    sortKey: 'shortlist',
  },
  {
    title: 'Status',
    sortable: false,
    sortKey: 'status',
  },
];

export const SHORTLISTED_APPLICANT_LIST_TABLE_HEADERS = [
  {
    title: 'Applicant',
    sortable: false,
    sortKey: 'applicant_name',
  },
  {
    title: 'Application',
    sortable: false,
    sortKey: 'application',
  },
  {
    title: 'Technology/skills',
    sortable: false,
    sortKey: 'technology',
  },
  {
    title: 'Contact Info',
    sortable: false,
    sortKey: 'contact',
  },
  {
    title: 'Shortlist',
    sortable: false,
    sortKey: 'shortlist',
  },
  {
    title: 'Shortlisted by',
    sortable: false,
    sortKey: 'shortlistedBy',
  },
  {
    title: 'Mark as Contacted',
    sortable: false,
    sortKey: 'markAsContacted',
  },
];

export const APPLICATIONLISTDROPDOWNOPTIONS = [
  {
    label: 'No update',
    value: 'NO_UPDATE',
  },
  {
    label: 'Selected',
    value: 'SELECTED',
  },
  {
    label: 'Viewed',
    value: 'VIEWED',
  },
  {
    label: 'Shortlisted',
    value: 'SHORTLISTED',
  },
  {
    label: 'Not selected',
    value: 'NOT_SELECTED',
  },
];

export const CANDIDATE_ROLE = [
  {
    id: '1',
    title: 'Admin',
    value: 'ADMIN',
  },
  {
    id: '2',
    title: 'Co-Admin',
    value: 'CO-ADMIN',
  },
  {
    id: '3',
    title: 'Member',
    value: 'MEMBER',
  },
];

export const STATUS_LIST = [
  {
    label: 'Selected',
    value: 'SELECTED',
  },
  {
    label: 'Viewed',
    value: 'VIEWED',
  },
  {
    label: 'No update',
    value: 'NO_UPDATE',
  },
];
