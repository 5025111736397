import styled from 'styled-components';
import Icon from '../../../../utlils/Icons/Icon';

const StatusCardWrapper = styled.div`
  & .card-container {
    width: 100%;
    padding: 10px 15px;
    margin: 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    background: rgba(33, 159, 255, 0.1);
    border-radius: 10px;
    color: #219fff;

    & .card-alt-img {
      border-radius: 50%;
      width: 35px;
      height: 35px;
      background-color: #f4f7fc;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & .card-img {
      object-fit: cover;
      border-radius: 50%;
      width: 35px;
      height: 35px;
      background: #c4c4c4;
    }
    & .card-title-style {
      margin: auto 0px;
    }

    & .close-cross {
      margin: auto 0px;
      &:hover {
        cursor: pointer;
      }
    }
  }
`;

export const StatusCard = ({
  cardId,
  cardImg = '',
  cardTitle = '',
  isImg = false,
  iconName = 'project',
  isCardOpen,
  onRemove,
}) => {
  const handleClose = () => {
    onRemove(cardId);
  };

  return (
    <>
      {isCardOpen && (
        <StatusCardWrapper>
          <div className="card-container">
            {isImg ? (
              <img
                src={cardImg}
                alt="project-img"
                width="100%"
                className="card-img"
              />
            ) : (
              <div className="card-alt-img">
                <Icon name={iconName} className="card-icon" color="#404F67" />
              </div>
            )}
            <p className="card-title-style">{cardTitle}</p>
            <div className="close-cross">
              <Icon name="cross" color="#219fff" onClick={handleClose} />
            </div>
          </div>
        </StatusCardWrapper>
      )}
    </>
  );
};
