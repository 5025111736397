import { Input } from 'components';
import { CURRENCY_SYMBOLS } from 'constants/constants';

export const BiddingRate = ({
  maxBidAmountApplied,
  currencyType,
  bidRateType,
  fixedBidRate,
  minBidRange,
  maxBidRange,
  handleBidRateInput,
  value,
}) => {
  return (
    <div className="doc-container-main">
      <p className="sub-heading">1. Bidding rate</p>
      {fixedBidRate || bidRateType === 'Fixed rate' ? (
        <div>
          <div className="bid-rate col-3">
            <p className="bid-rate-text">
              {CURRENCY_SYMBOLS[currencyType]}
              {fixedBidRate} (Fixed Rate)
            </p>
          </div>
        </div>
      ) : (
        <div>
          <div style={{ display: 'flex' }}>
            <div className="bid-rate col-3">
              <p className="bid-rate-text">
                {CURRENCY_SYMBOLS[currencyType]}
                {minBidRange} (Min range)
              </p>
            </div>
            <p className="range-separator">-</p>
            <div className="bid-rate col-3">
              <p className="bid-rate-text">
                {CURRENCY_SYMBOLS[currencyType]}
                {maxBidRange} (Max range)
              </p>
            </div>
            <div className="bid-rate col-3" style={{ marginLeft: '45px' }}>
              <Input
                placeholder="Enter your Bid"
                type="number"
                required
                value={value}
                onChange={(e) => {
                  handleBidRateInput(e, null, 'BID_RATE');
                }}
                hasError={value < minBidRange || value > maxBidRange}
              />
            </div>
          </div>
          {maxBidAmountApplied ? (
            <div className="last-accepted">
              <p>
                {CURRENCY_SYMBOLS[currencyType]}
                {maxBidAmountApplied} was the last bid accepted by admin
              </p>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};
