import styled from 'styled-components';
export const IndividualPublicWrapper = styled.div`
  .teams-container {
    min-height: 100vh;
    background-color: #f4f7fc;
    & .layout-container {
      padding: 40px 8% 20px 8%;
      & .layout-upper-container {
        display: grid;
        grid-template-columns: auto minmax(420px, 450px);
        grid-gap: 40px;
        @media (max-width: 1299px) {
          display: block;
        }
        & .upper-container-child {
          border-radius: 12px;
          padding: 30px;
          background-color: white;
          @media (max-width: 1299px) {
            margin: 25px 0;
          }
        }
      }
      @media (max-width: 1600px) {
        padding: 40px 6% 20px 6%;
      }
      @media (max-width: 992px) {
        padding: 40px 4% 20px 4%;
      }

      & .layout-lower-container {
        margin-top: 40px;
        display: grid;
        grid-template-columns: minmax(200px, 220px) auto;
        grid-gap: 40px;
        @media (max-width: 1199px) {
          display: block;
        }

        & .sub-container {
          @media (max-width: 1199px) {
            margin: 25px 0;
          }
          & .main-wrapper {
            border-radius: 12px;
            padding: 30px;
            background-color: white;
            position: relative;

            & .heading {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 700;
              font-size: 25px;
              color: #17191d;
              border-bottom: 1px solid #e9eef4;
              padding-bottom: 16px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              & span {
                font-weight: 600;
                font-size: 23px;
                color: #404f67;
              }
              & p {
                font-style: normal;
                font-weight: 700;
                font-size: 25px;
                margin: 0;
              }
              & .drop-down-class {
                width: 160px;
                @media (max-width: 499px) {
                  width: 100%;
                }
              }
              @media (max-width: 499px) {
                flex-direction: column;
              }
            }

            & .content {
              padding: 30px 0;
              & .cards-container {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 20px;

                @media (min-width: 900px) and (max-width: 1499px) {
                  grid-template-columns: repeat(2, 1fr);
                }

                @media (max-width: 899px) {
                  grid-template-columns: 1fr;
                }
              }
              & .no-data-class {
                width: 50%;
                margin: 50px auto;
                text-align: center;
                color: #404f67;
              }
            }

            & .sub-heading-wrapper {
              display: flex;
              align-items: center;
              justify-content: space-between;
              & .sub-heading {
                margin: 20px 0;
                font-weight: 600;
                font-size: 20px;
                line-height: 34px;
                color: #17191d;

                & span {
                  padding-left: 8px;
                  font-weight: 400;
                  font-size: 15px;
                  color: #7e8f9a;
                }
              }

              & .sub-heading-tooltip {
                display: flex;
                gap: 8px;
                & .tooltip-card-item {
                  margin-top: 16px;
                  &-heading {
                    font-size: 14px;
                    font-weight: 500;
                    color: #404f67;
                  }
                  &-text {
                    font-size: 12px;
                    font-weight: 400;
                    color: #404f67;
                  }
                }
              }

              @media (max-width: 400px) {
                flex-direction: column;
                align-items: flex-start;
                margin-bottom: 8px;
              }
            }

            & .teams-list {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              grid-gap: 20px;

              @media (max-width: 899px) {
                display: block;

                & .team-card-class {
                  margin-top: 12px;
                }
              }
            }

            & .projects-list {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              grid-gap: 20px;

              @media (max-width: 899px) {
                display: block;

                & .team-card-class {
                  margin-top: 12px;
                }
              }
            }

            & .private-team-list {
              display: flex;
              flex-direction: column;
              row-gap: 16px;
            }

            & .private-project-list {
              display: flex;
              flex-direction: column;
              row-gap: 16px;
            }

            & .no-data {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              width: 75%;
              text-align: center;
              margin: 2% auto;
              font-weight: 500;
              color: #929daf;
              object-fit: cover;
              gap: 12px;

              @media (max-width: 500px) {
                & img {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }

  .popup-class {
    position: relative;
    padding: 8px 4px;

    & .popup-header {
      display: flex;
      justify-content: space-between;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 25px;
      color: #17191d;
      border-bottom: 2px solid #c4c4c4;
      margin-bottom: 30px;
      & .close-cross {
        position: absolute;
        top: 35px;
        right: 25px;
        &:hover {
          cursor: pointer;
        }
      }
    }

    & .edit-items {
      margin: 10px 0;
    }

    & .contact-checkbox {
      font-weight: 600;
      font-size: 15px;
      color: #404f67;
      margin-bottom: 40px;
    }

    & .bg-image-upload {
      margin-bottom: 20px;
      & .bg-image-container {
        padding-top: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;

        & .bg-image-preview {
          border: 2px dashed #c4c4c4;
          border-radius: 12px;
          width: 80%;
          height: 85px;

          & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 12px;
          }

          @media (max-width: 1199px) {
            width: 100%;
          }
        }

        & .bg-image-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          border: 2px dashed #c4c4c4;
          border-radius: 12px;
          width: 80%;
          height: 85px;
        }

        & .upload-image-style {
          & .upload-image-label-style {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #219fff;
            text-decoration: underline;
          }
        }

        @media (max-width: 1199px) {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }

    & .image-upload {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .image-upload-span {
      font-weight: 600;
      font-size: 15px;
      color: #404f67;
      padding-bottom: 20px;
    }

    & .pop-up-btn {
      margin: 12px auto;
      width: 100px;
    }

    & .inviteType-drop-down {
      & .invite-text {
        font-weight: 500;
        font-size: 17px;
        line-height: 30px;
        color: #404f67;
      }
      & .invite-class {
        width: 160px;
      }
    }

    & .invite-user-class {
      margin-top: 20px;
      font-weight: 500;
      font-size: 17px;
      color: #404f67;

      & .invite-user-text {
        font-weight: 700;
      }
      & .invite-drop-down-group {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 16px;

        @media (max-width: 647px) {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }

    & .invite-popup-btn {
      margin: 12px auto;
      width: 160px;
    }

    & .private-contact-class {
      padding: 20px 0;
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      justify-content: center;
      align-items: center;
    }

    & .public-contact-class {
      padding: 20px 0;
      display: flex;
      flex-direction: column;
      row-gap: 12px;
      justify-content: flex-start;
      align-items: flex-start;

      & .public-info-style {
        display: flex;
        gap: 12px;
      }
    }
  }
`;
