import styled from 'styled-components';

export const TaskManageCardWrapper = styled.div`
  width: 100%;
  background: #ffffff;
  padding: 10px;
  box-shadow: 0px 2px 10px rgba(73, 73, 73, 0.3);
  border-radius: 10px;
  opacity: ${({ isDragging }) => (isDragging ? '0' : '1')};
  display: grid;
  align-items: center;
  row-gap: 12px;
  &:hover {
    cursor: pointer;
    background: #219fff00;
  }
  .task-card-wrapper {
    &__taskStage-button {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 171px;
      padding: 10px 15px;
      background: rgba(33, 159, 255, 0.2);
      border-radius: 5px;
      color: #219fff;
      font-weight: 500;
      font-size: 14px;
    }
    &__three-dots {
      cursor: pointer;
    }
    &__tasktitle {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #292d32;
    }
    &__assigned {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      gap: 12px;
    }
    &__row-3 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &__ticket {
        display: flex;
        align-items: center;
        column-gap: 4px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #7e8f9a;
      }
    }
  }
`;
