import { CustomButton, DropDown } from 'components';
import { PUBLICATION_CATEGORY_OPTIONS } from 'pages/TeamDashboard/TeamPublications/constants';
import React from 'react';
import styled from 'styled-components';

export default function FeedFilter(props) {
  const { filterTitle, state, filterOnChangeHandler, filterOnSavehandler } =
    props;
  return (
    <FeedFilterWrapper>
      <div className="filter-title-wrapper">
        <div className="filter-title">{filterTitle}</div>
      </div>
      <div className="filter-input-container">
        <div className="row">
          <div className="col-lg-12">
            <DropDown
              value={state.filteredFeedCategory}
              label={'category'}
              items={PUBLICATION_CATEGORY_OPTIONS}
              placeholder={'Select category'}
              onClick={(e) => filterOnChangeHandler('filteredFeedCategory', e)}
            />
          </div>
        </div>
        <div className="row mt-4 gy-2">
          <div className="col-lg-6">
            <CustomButton
              label="Apply"
              className="w-100"
              color="themeSecondary"
              onClick={() => filterOnSavehandler('apply')}
            />
          </div>
          <div className="col-lg-6">
            <CustomButton
              label="Cancel"
              variant="light"
              className="w-100"
              color="themeSecondary"
              onClick={() => filterOnSavehandler('cancel')}
            />
          </div>
        </div>
      </div>
    </FeedFilterWrapper>
  );
}
const FeedFilterWrapper = styled.div`
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.06);
  right: 0;
  top: 0;
  position: absolute;
  z-index: 22;
  width: 40%;
  @media (max-width: 1400px) and (min-width: 1200px) {
    width: 50%;
  }
  @media (max-width: 1199px) {
    width: 60%;
  }
  @media (max-width: 991px) {
    width: 75%;
  }

  .filter-title-wrapper {
    border-bottom: 1.5px solid #e9eef4;
    height: 60px;

    .filter-title {
      padding: 20px 30px;
      font-weight: 500;
      font-size: 16px;
      color: #17191d;
    }
  }

  .filter-input-container {
    padding: 20px 30px;

    .filter-input-fields {
      display: flex;
      align-items: center;
      gap: 15px;
    }

    @media (max-width: 576px) {
      padding: 15px 10px;
    }
  }
`;
