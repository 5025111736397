import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import UserImg from 'assests/images/user.png';
import Icon from 'utlils/Icons/Icon';
import { CustomButton, ModalPopup } from 'components';
import GroupMemberCard from './Components/groupMemberCard';

export default function GroupDescription(props) {
  const { groupDetails, leaveOrRemoveMember } = props;
  const [showGroupMemberList, setShowGroupMemberList] = useState(false);
  const [removeMemberPopup, setRemovePopup] = useState(false);
  const [removeMemberDetails, setRemoveMemberDetails] = useState({});
  const groupMembers = () => {
    return groupDetails?.membersInfo?.filter((item) => {
      return item?.status === '4';
    });
  };
  const removePopupHandler = (member) => {
    setRemoveMemberDetails(member);
    setRemovePopup(true);
  };
  const compareByString = (a, b) => {
    if (a.role < b.role) {
      return -1;
    } else if (a.role > b.role) {
      return 1;
    } else {
      return 0;
    }
  };
  const groupMember = groupMembers()?.sort(compareByString);
  const creatorProfilePic =
    groupDetails?.user?.individual?.profilePicture || UserImg;
  return (
    <DescriptionWrapper>
      <div>{groupDetails?.description}</div>
      <div className="group-details">
        <div className="box-container category">
          <div className="title logo-title">
            <Icon name="message" color="black" size={15} />
            Category
          </div>
          <div className="content category-name">{groupDetails?.category}</div>
        </div>
        <div className="box-container image-created-by">
          <div className="title">
            <Icon name="user" color="black" size={15} /> Created By
          </div>
          <div className="image-details">
            <div className="image-container">
              <img
                className="image"
                src={creatorProfilePic}
                alt="group-creator"
              />
            </div>
            <div className="created-by-name-link">
              <div className="content name">
                {groupDetails?.user?.individual?.name}
              </div>
              <Link
                to={`/public/individual/${groupDetails?.user?.id}/summary/basic-details`}
                target="_blank"
                className="title link">
                View Profile
              </Link>
            </div>
          </div>
        </div>
        <div className="box-container group-type">
          <div className="title type">
            <Icon name="global" color="black" size={15} />
            Group Type
          </div>
          <div className="content name">{groupDetails?.groupType}</div>
        </div>
        <div className="box-container no-of-members">
          <div className="title members">
            <Icon name="people" color="black" size={15} />
            Members
          </div>
          <div
            onClick={() => setShowGroupMemberList(true)}
            className="content size group-member">
            {groupMember?.length} Members
          </div>
        </div>
      </div>
      {showGroupMemberList && (
        <ModalPopup
          open={false}
          title={'Members'}
          onClose={() => setShowGroupMemberList(false)}>
          {groupMember.map((member, index) => (
            <GroupMemberCard
              groupDetails={groupDetails}
              removePopupHandler={removePopupHandler}
              key={index}
              member={member}
            />
          ))}
        </ModalPopup>
      )}
      {removeMemberPopup && (
        <ModalPopup
          title={'Remove'}
          open={false}
          onClose={() => setRemovePopup(false)}>
          <div className="warning-text text-center">
            {`Are you sure you want to remove '${removeMemberDetails?.user?.individual?.name}' from the group?`}
          </div>
          <div className="buttons">
            <CustomButton
              customPadding="16px 60px"
              label="Yes"
              color="themeSecondary"
              onClick={() =>
                leaveOrRemoveMember('remove', removeMemberDetails.id)
              }
            />
            <CustomButton
              variant="outline"
              color="themeSecondary"
              customPadding="16px 52px"
              label="No"
              onClick={() => {
                setRemoveMemberDetails({});
                setRemovePopup(false);
              }}
            />
          </div>
        </ModalPopup>
      )}
    </DescriptionWrapper>
  );
}
const DescriptionWrapper = styled.div`
  padding: 10px;
  .group-details {
    margin-top: 30px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: space-between;
    .box-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 10px;
      border: 1px solid #a9a9a9;
      border-radius: 5px;
      flex: 1;
      min-width: 100px;
      gap: 6px;
      flex-wrap: wrap;
      margin: 10px 0;
    }
  }
  .title {
    display: flex;
    gap: 3px;
    justify-content: flex-start;
    font-size: 11px;
    font-weight: 500;
  }
  .content {
    font-size: 17px;
    font-weight: 600;
    font-family: 'Poppins';
  }
  .link {
    color: rgba(64, 79, 103, 1);
    place-content: center;
  }
  .image-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    justify-items: center;
    gap: 3px;
    .image-container {
      padding: 3px;
      width: 40px;
      height: 40px;
      .image {
        width: 100%;
        height: 100%;
        border-radius: 100%;
      }
    }
  }
  .group-member {
    text-decoration: underline;
    cursor: pointer;
  }
`;
