import { CustomButton, Input } from 'components';
import { DEPARTMENT_MEMBER_FILTERS } from 'pages/enterpriseDashboard/helper';
import { MembersFilterWrapper } from './style';

export const MembersFilter = ({
  state,
  filterTitle,
  memberFilterSaveHandler,
  memberFilterOnChangeHandler,
}) => {
  return (
    <MembersFilterWrapper>
      <div className="filter-title-wrapper">
        <div className="filter-title">{filterTitle}</div>
      </div>
      <div className="filter-input-container">
        <div className="row">
          <div className="col-lg-6">
            <Input
              label={DEPARTMENT_MEMBER_FILTERS.Designation.label}
              value={state.filteredDesignation}
              onChange={(e) =>
                memberFilterOnChangeHandler(
                  'filteredDesignation',
                  e.target.value
                )
              }
            />
          </div>
          <div className="col-lg-6">
            <Input
              label={DEPARTMENT_MEMBER_FILTERS.TeamName.label}
              value={state.filteredTeamName}
              onChange={(e) =>
                memberFilterOnChangeHandler('filteredTeamName', e.target.value)
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <Input
              label={DEPARTMENT_MEMBER_FILTERS.ProjectName.label}
              value={state.filteredProjectName}
              onChange={(e) =>
                memberFilterOnChangeHandler(
                  'filteredProjectName',
                  e.target.value
                )
              }
            />
          </div>
        </div>
        <div className="row gy-3">
          <div className="col-lg-6">
            <CustomButton
              className="w-100"
              label={'Apply'}
              color="themeSecondary"
              onClick={() => memberFilterSaveHandler('apply')}
            />
          </div>
          <div className="col-lg-6">
            <CustomButton
              className="w-100"
              label={'Cancel'}
              color="themeSecondary"
              variant="light"
              onClick={() => memberFilterSaveHandler('cancel')}
            />
          </div>
        </div>
      </div>
    </MembersFilterWrapper>
  );
};
