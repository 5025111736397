import { CustomButton, DropDown } from 'components';
import { EXPLORE_JOB_FILTERS } from 'pages/Explore/constants';
import { ExploreJobFilterWrapper } from './style';
import { getData } from 'country-list';

export const ExploreJobFilter = ({
  state,
  filterTitle,
  filterSaveHandler,
  filterOnChangeHandler,
}) => {
  const countryList = getData();

  return (
    <ExploreJobFilterWrapper>
      <div className="filter-title-wrapper">
        <div className="filter-title">{filterTitle}</div>
      </div>
      <div className="filter-input-container">
        <DropDown
          label={EXPLORE_JOB_FILTERS.experience_level.label}
          value={state.filteredExperienceLevel}
          objectKey={EXPLORE_JOB_FILTERS.experience_level.key}
          items={EXPLORE_JOB_FILTERS.experience_level.dataType}
          placeholder={EXPLORE_JOB_FILTERS.experience_level.placeholder}
          onClick={(e) => filterOnChangeHandler('filteredExperienceLevel', e)}
        />
        <DropDown
          label={EXPLORE_JOB_FILTERS.job_type.label}
          value={state.filteredJobType}
          objectKey={EXPLORE_JOB_FILTERS.job_type.key}
          items={EXPLORE_JOB_FILTERS.job_type.dataType}
          placeholder={EXPLORE_JOB_FILTERS.job_type.placeholder}
          onClick={(e) => filterOnChangeHandler('filteredJobType', e)}
        />
        <DropDown
          label={EXPLORE_JOB_FILTERS.region.label}
          items={countryList}
          objectKey={EXPLORE_JOB_FILTERS.region.key}
          placeholder={EXPLORE_JOB_FILTERS.region.placeholder}
          onClick={(e) => filterOnChangeHandler('filteredRegion', e)}
          value={state.filteredRegion}
          hasSearchBox
        />
        <div className="row mt-4 gy-2">
          <div className="col-lg-6">
            <CustomButton
              label="Apply"
              className="w-100"
              color="themeSecondary"
              onClick={() => filterSaveHandler('apply')}
            />
          </div>
          <div className="col-lg-6">
            <CustomButton
              label="Cancel"
              className="w-100"
              variant="light"
              color="themeSecondary"
              onClick={() => filterSaveHandler('cancel')}
            />
          </div>
        </div>
      </div>
    </ExploreJobFilterWrapper>
  );
};
