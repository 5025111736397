import {
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ApplicantList } from './applicantList';
import { PostedJobList } from './postedJobList';
import { CreateJob } from '../CreateJobs';
import {
  FETCH_POSTED_JOBS,
  FETCH_APPLICANT_DETAILS_BASED_ON_JOBID,
  UPDATE_HIRING_STATUS,
  useAxiosWrapper,
  UPDATE_SHORTLIST_CANDIDATE,
  FETCH_POSTED_JOBS_BASEDON_ENTERPRISEID_AND_DEPARTMENTID,
  FETCH_PROJECT_TASKS,
  GET_RBAC_FOR_TEAM,
  FETCH_TEAMS_BASED_ON_DEPARTMENT_ID,
} from 'services';
import {
  CustomButton,
  Pagination,
  Loader,
  ScrollableDiv,
  SearchInput,
  CustomDropDown,
} from 'components';
import createInitialTeams from 'assests/images/createInitialTeams.png';
import { getPlaceholderItems } from 'utlils/utils';
import {
  postedTableHeaders,
  APPLICANTLISTTABLEHEADERS,
  DEFAULT_JOB_FILTER_STATE,
  STATUS_LIST,
} from '../constant';
import { postedJobsTableData, applicantListTableData } from '../helper';
import TokenService from 'services/token.service';
import { ViewApplicant } from './ViewApplicant/viewApplicant';
import { SelectDepartmentAndTeam } from './selectDepartmentAndTeam';
import { ModalPopup } from 'components';
import { ViewJobApproval } from './viewJobApproval';
import { FilterPostedJobs } from './FilterPostedJobs';
import { RoleBasedAccessContext } from 'context/RoleBasedAccess/roleBasedAccessContext';
import Icon from 'utlils/Icons/Icon';
import { setPersonalTeamRBAC } from 'context/RoleBasedAccess/roleBasedAccessAction';
import tokenService from 'services/token.service';
import styled from 'styled-components';

export const PostedJobs = (props) => {
  const { roleBasedAccessState, roleBasedAccessDispatch } = useContext(
    RoleBasedAccessContext
  );
  const loggedInUser = useMemo(() => tokenService.getSession(), []);

  const [postedJobs, setPostedJobs] = useState({});
  const { userType } = props;
  const isCareerEnterprise = true;
  const [userProfile] = useState({});
  const [activeApplicant, setActiveApplicant] = useState('');
  const [applicantList, setApplicantList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { teamId } = useParams();
  const departmentRoute = useParams();
  const [viewApplicantPopup, setViewApplicantPopup] = useState(false);
  const [viewJobApproval, setViewJobApproval] = useState(false);
  const [jobApprovals, setJobApprovals] = useState([]);
  const [fnWrapper, context] = useAxiosWrapper();
  const [teamList, setTeamList] = useState(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [openEnterpriseJobEdit, setOpenEnterpriseJobEdit] = useState(false);
  const [filterJob, setFilterJob] = useState(DEFAULT_JOB_FILTER_STATE);

  const [currDepartmentAndTeam, setCurrDepartmentAndTeam] = useState({
    department: '',
    team: '',
  });
  const [currJob, setCurrJob] = useState('');
  const [projectList, setProjectList] = useState([]);
  const navigate = useNavigate();
  const ref = useRef(null);
  const [applicantDetails, setApplicationDetails] = useState(null);
  const [jobId, setJobid] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const team_id = currDepartmentAndTeam?.team?.id
    ? currDepartmentAndTeam.team.id
    : teamId;
  let pageSize = 10;
  const filterButtonDisable =
    !filterJob.search &&
    !filterJob.startDate &&
    !filterJob.endDate &&
    filterJob.isHiring === '' &&
    !filterJob.project;

  const getTeamListBasedonDepartment = async () => {
    const res = await fnWrapper({
      url: `${FETCH_TEAMS_BASED_ON_DEPARTMENT_ID}/${departmentRoute?.id}`,
      method: 'GET',
      type: 'FETCH_TEAMS_BASED_ON_DEPARTMENT_ID',
    });

    if (res.data.statusCode === 200) {
      const filteredTeams = res.data.data.teams
        .filter((item) => item?.status !== 'REJECTED')
        .map((item) => ({
          enterpriseId: item.enterpriseId,
          id: item.id,
          label: item.teamName,
          value: item.teamName,
        }));

      setTeamList(filteredTeams);
      setPostedJobs({});
      setActiveApplicant('');
      setApplicantList([]);
      setCurrentPage(1);
    } else {
      setTeamList(null);
    }
  };

  const getPendingJobsBasedOnEnterpriseAndTeamId = async (id) => {
    let list = [];
    const res = await fnWrapper({
      url: `${FETCH_POSTED_JOBS_BASEDON_ENTERPRISEID_AND_DEPARTMENTID}/${currDepartmentAndTeam.team.enterpriseId}?departmentId=${departmentRoute?.id}&teamId=${currDepartmentAndTeam.team.id}`,
      method: 'GET',
      type: 'FETCH_POSTED_JOBS_BASEDON_ENTERPRISEID_AND_DEPARTMENTID',
    });
    if (res.data.statusCode === 200) {
      res?.data?.data?.length >= 1 &&
        res?.data?.data?.map((item) =>
          list.push({
            name: item?.user?.smallEnterprise
              ? item?.user?.smallEnterprise?.enterpriseName
              : item?.team?.teamName,
            title: item?.title,
            project: item?.project?.title,
            jobId: item?.id,
          })
        );
      setJobApprovals(list);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    getTeamListBasedonDepartment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /* --------------    */

  /* Get posted jobs API request ----- */
  const getPostedJobsList = useCallback(
    async (args) => {
      setActiveApplicant('');
      const { teamIdFromprops, filters } = args || {};
      const team_id =
        typeof teamIdFromprops === 'string'
          ? teamIdFromprops
          : teamId
          ? teamId
          : currDepartmentAndTeam?.team?.id;
      const is_hiring = filters?.isHiring
        ? filters?.isHiring === 'isHiring'
          ? true
          : false
        : '';
      if (team_id) {
        const teamRbacRes = await fnWrapper({
          url: `${GET_RBAC_FOR_TEAM}`,
          method: 'GET',
          type: 'GET_RBAC_FOR_TEAM',
          params: {
            teamId: team_id,
            userId: loggedInUser.id,
          },
        });
        if (teamRbacRes.data.statusCode === 200) {
          await setPersonalTeamRBAC(
            roleBasedAccessDispatch,
            teamRbacRes.data.data
          );
        }
        const payload = {
          page: currentPage,
          size: pageSize,
          projectId: filters?.project?.value ? filters?.project?.value : '',
          startDate: filters?.startDate ? filters?.startDate : '',
          endDate: filters?.endDate ? filters?.endDate : '',
          isHiring: is_hiring,
          search: filters?.search ? filters?.search?.toLowerCase() : '',
          isEnterpriseCareerJobs: isCareerEnterprise ? true : false,
        };
        const res = await fnWrapper({
          url: `${FETCH_POSTED_JOBS}/${team_id}`,
          method: 'GET',
          type: 'FETCH_POSTED_JOBS',
          payload: payload,
        });
        if (res.data.statusCode === 200) {
          setPostedJobs(res.data.data);
        } else {
          setPostedJobs([]);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      teamId,
      currDepartmentAndTeam?.team?.id,
      loggedInUser.id,
      currentPage,
      pageSize,
      isCareerEnterprise,
      roleBasedAccessDispatch,
    ]
  );
  useEffect(() => {
    getPostedJobsList();
  }, [getPostedJobsList]);
  /* --------------    */

  /* get Applicant List */
  const getApplicantList = useCallback(
    async (jobId) => {
      setActiveApplicant(jobId);
      let payload = {
        jobId: jobId,
        status: null,
      };
      const res = await fnWrapper({
        url: `${FETCH_APPLICANT_DETAILS_BASED_ON_JOBID}`,
        method: 'GET',
        params: payload,
        type: 'FETCH_APPLICANT_DETAILS_BASED_ON_JOBID',
      });
      if (res.data.statusCode === 200) {
        setApplicantList(res.data.data);
      } else {
        setApplicantList([]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedStatus]
  );

  /* get Applicant List */
  const getApplicantListBasedOnStatus = useCallback(
    async (jobId) => {
      setActiveApplicant(jobId);
      let payload = {
        jobId: jobId,
        status:
          selectedStatus && selectedStatus.value ? selectedStatus.value : null,
      };
      const res = await fnWrapper({
        url: `${FETCH_APPLICANT_DETAILS_BASED_ON_JOBID}`,
        method: 'GET',
        params: payload,
        type: 'FETCH_APPLICANT_DETAILS_BASED_ON_JOBID',
      });
      if (res.data.statusCode === 200) {
        setApplicantList(res.data.data);
      } else {
        setApplicantList([]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedStatus]
  );

  /*--------------*/

  /*Update hiring status API*/
  const updateHiringStatus = async (jobId, hiringStatus) => {
    const payload = {
      isHiring: hiringStatus,
    };
    const res = await fnWrapper({
      url: `${UPDATE_HIRING_STATUS}/${jobId}`,
      method: 'PUT',
      type: `UPDATE_HIRING_STATUS`,
      payload: payload,
    });
    if (res.data.statusCode === 200) {
      toast.success('Updated hiring status succesfully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      getPostedJobsList();
    }
  };
  /* --------------    */

  /* duplicate job post */
  const duplicateJobPostHandler = (jobId) => {
    navigate(
      `/${userType}/team-dashboard/${teamId}/career/create-job/${jobId}`,
      {
        replace: true,
      }
    );
  };
  /* ------------ */

  const editJobPostHandler = (jobId) => {
    navigate(`/${userType}/team-dashboard/${teamId}/career/edit-job/${jobId}`, {
      replace: true,
    });
  };
  const onBoardingRouteHandler = () => {
    navigate(
      `/individual/profile/organization/enterprise/department/${departmentRoute?.id}/career/shortlisted?teamName=${currDepartmentAndTeam?.team?.label}&teamId=${team_id}&jobName=${currJob}&jobId=${activeApplicant}`
    );
  };

  /* posted jobs table headers */
  const postedJobsData =
    context.busy.type === 'FETCH_POSTED_JOBS' ||
    context.busy.type === 'UPDATE_HIRING_STATUS'
      ? getPlaceholderItems(
          postedTableHeaders({
            userType: userType,
            isCareerEnterprise: isCareerEnterprise,
          }).map(({ sortKey }) => sortKey),
          6,
          context.busy.type === 'FETCH_POSTED_JOBS' || 'UPDATE_HIRING_STATUS'
        )
      : postedJobsTableData({
          list: postedJobs?.items,
          getApplicantList,
          duplicateJobPostHandler,
          updateHiringStatus,
          editJobPostHandler,
          activeApplicant,
          userType,
          setJobid,
          ref,
          setOpenEnterpriseJobEdit,
          setSelectedStatus,
          setCurrJob,
          permissions: roleBasedAccessState.personalTeamRBAC,
        });

  /* ------------ */

  /* shortlist applicant */
  const shortlistCandidate = async (id, isShortlisted) => {
    const loggedInUser = TokenService.getSession();
    const payload = {
      status: 'SHORTLISTED',
      shortlistedBy: loggedInUser.id,
    };
    const res = await fnWrapper({
      url: `${UPDATE_SHORTLIST_CANDIDATE}/${id}`,
      method: 'PUT',
      type: `UPDATE_SHORTLIST_STATUS`,
      payload: payload,
    });
    if (res.data.statusCode === 200) {
      toast.success(
        `${
          !isShortlisted ? 'Shortlisted applicant' : 'Removed from shortlist'
        }`,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        }
      );
      getApplicantList(activeApplicant);
    }
  };
  /* ---- */

  /*-view applicant-- */
  const viewApplicant = async (id) => {
    const loggedInUser = TokenService.getSession();
    const payload = {
      status: 'VIEWED',
      shortlistedBy: loggedInUser.id,
    };
    const res = await fnWrapper({
      url: `${UPDATE_SHORTLIST_CANDIDATE}/${id}`,
      method: 'PUT',
      type: `UPDATE_SHORTLIST_STATUS`,
      payload: payload,
    });
    if (res.data.statusCode === 200) {
      toast.success(`Applicant viewed`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      getApplicantList(activeApplicant);
    }
  };
  /* --- */
  const applicantListData =
    context.busy.type === 'FETCH_APPLICANT_DETAILS_BASED_ON_JOBID' ||
    context.busy.type === 'UPDATE_SHORTLIST_STATUS'
      ? getPlaceholderItems(
          APPLICANTLISTTABLEHEADERS.map(({ sortKey }) => sortKey),
          6,
          context.busy.type === 'FETCH_APPLICANT_DETAILS_BASED_ON_JOBID' ||
            'UPDATE_SHORTLIST_STATUS'
        )
      : applicantListTableData({
          list: applicantList,
          shortlistCandidate,
          viewApplicant,
          openOrCloseViewApplicant: ({
            id,
            shouldChangeApplicantStatusToView,
          }) => {
            if (!shouldChangeApplicantStatusToView) {
              viewApplicant(id);
            }
            setViewApplicantPopup(!viewApplicantPopup);
          },
          popupApplicantHandler: (item) => {
            setApplicationDetails(item);
          },
          permissions: roleBasedAccessState.personalTeamRBAC,
        });

  /* get project list API */
  const getProjectListByTeamId = useCallback(
    async () => {
      if (team_id) {
        const res = await fnWrapper({
          url: `${FETCH_PROJECT_TASKS}/${team_id}`,
          method: 'GET',
          type: 'FETCH_PROJECT_TASKS_BY_TEAMID',
        });
        if (res.data.statusCode === 200) {
          let list = [...projectList];
          res?.data?.data[0]?.projects.map((project) =>
            list.push({ label: project.title, value: project.id })
          );
          setProjectList(list);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currDepartmentAndTeam, teamId]
  );
  useEffect(() => {
    getProjectListByTeamId();
  }, [getProjectListByTeamId]);

  useEffect(() => {
    if (selectedStatus !== null && selectedStatus?.value) {
      getApplicantListBasedOnStatus(activeApplicant);
    }
  }, [activeApplicant, getApplicantListBasedOnStatus, selectedStatus]);
  /* ---------- */

  return context.busy.type === 'FETCH_POSTED_JOBS' ? (
    <div className=" main-wrapper">
      <Loader loading={context.busy.type === 'FETCH_POSTED_JOBS'} />
    </div>
  ) : (
    <PostedJobsWrapper>
      {isCareerEnterprise && (
        <div className="main-wrapper">
          <SelectDepartmentAndTeam
            teamList={teamList ?? []}
            departmentAndTeam={currDepartmentAndTeam}
            setDepartmentAndTeam={setCurrDepartmentAndTeam}
            getPostedJobsOnTeamClick={getPostedJobsList}
            context={context}
          />
        </div>
      )}
      <>
        <hr />
        <div className="main-wrapper mb-3">
          <div
            className="main-wrapper__postedJobs__postedList"
            style={{ minHeight: isFilterOpen ? '600px' : 'auto' }}>
            <>
              <div className="d-flex justify-content-between align-items-center header flex-wrap pb-2 mb-3">
                <span className="posted-Jobs-text">{'Posted Jobs'}</span>
                <div className="d-flex gap-3 align-items-center flex-wrap position-relative">
                  {team_id && (
                    <CustomButton
                      variant="light"
                      color={!isFilterOpen ? 'themeGray' : 'themeSecondary'}
                      onClick={() => setIsFilterOpen(!isFilterOpen)}>
                      Filter
                      <Icon
                        name="filter"
                        color={!isFilterOpen ? '929DAF' : '209FFF'}
                        size={18}
                      />
                    </CustomButton>
                  )}

                  {isFilterOpen && (
                    <FilterPostedJobs
                      filterJob={filterJob}
                      setFilterJob={setFilterJob}
                      onApplyFilter={(type) => {
                        getPostedJobsList({
                          filters:
                            type === 'apply'
                              ? filterJob
                              : DEFAULT_JOB_FILTER_STATE,
                        });
                        setIsFilterOpen(false);
                      }}
                      filterButtonDisable={
                        isCareerEnterprise
                          ? !currDepartmentAndTeam?.team?.id ||
                            filterButtonDisable
                          : filterButtonDisable
                      }
                      projectList={projectList}
                    />
                  )}
                  {isCareerEnterprise && (
                    <CustomButton
                      label="Job Approvals"
                      onClick={() => {
                        setViewJobApproval(true);
                        getPendingJobsBasedOnEnterpriseAndTeamId();
                      }}
                      disabled={!currDepartmentAndTeam.team}
                    />
                  )}
                </div>
              </div>
              <div className="posted-job-list">
                {postedJobsData.length >= 1 && (
                  <PostedJobList
                    list={postedJobsData}
                    isLoading={
                      context.busy.type === 'UPDATE_HIRING_STATUS' ||
                      'FETCH_POSTED_JOBS'
                    }
                    userType={userType}
                    isCareerEnterprise={isCareerEnterprise}
                    postedTableHeaders={postedTableHeaders({
                      userType: userType,
                      isCareerEnterprise: isCareerEnterprise,
                    })}
                  />
                )}
              </div>

              {postedJobs?.totalItems > pageSize && (
                <div className="d-flex gap-3 align-items-center justify-content-center postedJobs-pagination">
                  <Pagination
                    currentPage={postedJobs?.currentPage}
                    totalCount={postedJobs.totalItems}
                    pageSize={pageSize}
                    onPageChange={(page) => setCurrentPage(page)}
                  />
                </div>
              )}
            </>
          </div>
        </div>
      </>
      {!postedJobs?.totalItems >= 1 &&
        (!isCareerEnterprise ? (
          <div className="main-wrapper no--data mt-4">
            <div>{`No jobs created yet ! Please create a job first!`}</div>
            <img src={createInitialTeams} width="320px" alt="noData" />
          </div>
        ) : (
          <div className="main-wrapper d-flex justify-content-center align-items-center">
            <div>
              <div>
                {!currDepartmentAndTeam?.team?.id
                  ? `Please choose appropriate team!`
                  : `No Jobs present for this particular team!`}
              </div>
              {!currDepartmentAndTeam?.team?.id && (
                <img src={createInitialTeams} width="320px" alt="noData" />
              )}
            </div>
          </div>
        ))}

      {context.busy.type === 'FETCH_APPLICANT_DETAILS_BASED_ON_JOBID' ? (
        <div className=" main-wrapper d-flex justify-content-center align-items-center w-100">
          <Loader
            loading={
              context.busy.status === 'FETCH_APPLICANT_DETAILS_BASED_ON_JOBID'
            }
          />
        </div>
      ) : (
        activeApplicant && (
          <div className="main-wrapper">
            <hr />
            <div className="postedJobs-applicantList">
              <div className="posted-Jobs-text">
                {`${currJob} >> Applicants`}
              </div>

              <div className="applicationList-search-container">
                <div ref={ref} style={{ flexBasis: 'auto', flexGrow: 1 }}>
                  <SearchInput placeHolder="Search.." />
                </div>
                <CustomDropDown
                  optionLists={STATUS_LIST}
                  selectedItem={selectedStatus}
                  setSelectedItem={setSelectedStatus}
                  height="50px"
                  customStyle={{
                    backgroundColor: '#EBEBEB',
                    color: '#404F67',
                    padding: '25px 10px 25px 10px',
                    whiteSpace: 'normal',
                  }}
                  width="150px"
                  margin="0"
                  placeholder={'Select status'}
                />
                <CustomButton
                  label="Onboarding"
                  onClick={onBoardingRouteHandler}
                />
              </div>

              <div className="applicant-table-list">
                {applicantList.length >= 1 ? (
                  <div>
                    <ApplicantList
                      list={applicantListData}
                      isLoading={
                        context.busy.type === 'UPDATE_SHORTLIST_STATUS'
                      }
                    />
                  </div>
                ) : (
                  activeApplicant && (
                    <div className="main-wrapper d-flex align-items-center justify-content-center flex-column p-5 mt-4">
                      {`No applicant has applied for job yet!`}
                      <img
                        src={createInitialTeams}
                        width="320px"
                        alt="noData"
                      />
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        )
      )}
      {viewApplicantPopup && (
        <ModalPopup
          open={false}
          maxContentWidth={'900px'}
          title={'Applicant Details'}
          popupTitleClass={'popup-title-class'}
          onClose={() => {
            setViewApplicantPopup(false);
          }}>
          <ViewApplicant applicantDetails={applicantDetails} />
        </ModalPopup>
      )}

      {viewJobApproval && (
        <ModalPopup
          open={true}
          maxContentWidth={'900px'}
          title={'Job Approvals'}
          popupTitleClass={'popup-title-class'}
          onClose={() => {
            setViewJobApproval(false);
          }}>
          <ViewJobApproval
            jobApprovalList={jobApprovals}
            userType={userType}
            userProfile={userProfile}
            context={context}
            jobId={jobId}
            setJobid={setJobid}
            setViewJobApproval={setViewJobApproval}
            teamIdFromProps={currDepartmentAndTeam?.team?.id}
            getPostedJobsList={getPostedJobsList}
          />
        </ModalPopup>
      )}
      {openEnterpriseJobEdit && (
        <ModalPopup
          open={false}
          maxContentWidth={'900px'}
          title={'Edit Job'}
          popupTitleClass={'popup-title-class'}
          onClose={() => {
            setOpenEnterpriseJobEdit(false);
          }}>
          <ScrollableDiv>
            <CreateJob
              userProfile={userProfile}
              userType={userType}
              jobIdFromProps={jobId}
              teamIdFromProps={currDepartmentAndTeam.team.id}
              getPostedJobsList={getPostedJobsList}
              isEnterpriseEdit={openEnterpriseJobEdit}
              setOpenEnterpriseJobEdit={setOpenEnterpriseJobEdit}
            />
          </ScrollableDiv>
        </ModalPopup>
      )}
    </PostedJobsWrapper>
  );
};

const PostedJobsWrapper = styled.div`
  .posted-Jobs-text {
    color: #17191d;
    font-weight: 700;
    font-size: 22px;
  }
  .onboading-status-button-container {
    display: flex;
    gap: 8px;
    float: right;
  }
  .posted-job-list {
    width: 100%;
    @media (min-width: 1200px) and (max-width: 1600px) {
      width: 800px;
    }
  }
  .main-wrapper {
    .postedJobs-applicantList {
      .applicationList-search-container {
        display: flex;
        margin-top: 16px;
        gap: 16px;
        @media (max-width: 768px) {
          flex-wrap: wrap;
        }
        .application-button {
          width: 100%;
        }
      }
      .applicant-table-list {
        width: 100%;
        @media (min-width: 1200px) and (max-width: 1600px) {
          width: 800px;
        }
      }
    }
  }
`;
