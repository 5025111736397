import { useCallback } from 'react';
import cx from 'classname';
import { bool, func, string } from 'prop-types';
import { isMobile } from 'utlils';
import { ClickOutside } from 'components/ClickOutside';
import { PopUpTitle } from 'components/popUpTitle';
import { ModalPopupContainer, ModalPopupWrp } from './styles';

export const ModalPopup = (props) => {
  const {
    children,
    hasCloseIcon = true,
    hasTitle = true,
    popupTitleClass,
    popupWrapperClass,
    minWidth = '600px',
    maxContentWidth = '600px',
    onClose,
    open = false,
    title,
  } = props;

  const onCloseModal = useCallback(() => {
    if (typeof onClose === 'function') {
      onClose();
    }
  }, [onClose]);

  return (
    <ModalPopupWrp>
      {!isMobile() && (
        <ModalPopupContainer
          className={cx('mpc', popupWrapperClass)}
          minWidth={minWidth}
          maxContentWidth={maxContentWidth}>
          <ClickOutside
            style={{ margin: '0 auto' }}
            active={open}
            onClickOutside={onCloseModal}>
            <div className="mpc__main-wrapper">
              {hasTitle && (
                <div className="mpc__main-wrapper__title">
                  <PopUpTitle
                    popupTitleClass={popupTitleClass}
                    hasCloseIcon={hasCloseIcon}
                    onCloseHandler={onCloseModal}
                    title={title}
                  />
                </div>
              )}
              <div className="mpc__main-wrapper__content-area">{children}</div>
            </div>
          </ClickOutside>
        </ModalPopupContainer>
      )}
      {isMobile() && (
        <ModalPopupContainer className={cx('mpc', popupWrapperClass)}>
          <div className="mpc__main-wrapper">
            {hasTitle && (
              <div className="mpc__main-wrapper__title">
                <PopUpTitle
                  popupTitleClass={popupTitleClass}
                  hasCloseIcon={true}
                  onCloseHandler={onCloseModal}
                  title={title}
                />
              </div>
            )}
            <div className="mpc__main-wrapper__content-area">{children}</div>
          </div>
        </ModalPopupContainer>
      )}
    </ModalPopupWrp>
  );
};

ModalPopup.propTypes = {
  hasCloseIcon: bool,
  hasTitle: bool,
  popupTitleClass: string,
  popupWrapperClass: string,
  minWidth: string,
  maxContentWidth: string,
  open: bool.isRequired,
  onClose: func,
};
