import AirPlane from 'assests/images/paper-plane.png';

export const InviteSent = () => {
  return (
    <div className="success-popup">
      <div>
        <img src={AirPlane} alt="plane" height={'200px'} />
        <p>
          Your application for bidding is sent to the user. You can check the
          status of your application from here
        </p>
      </div>
    </div>
  );
};
