import styled from 'styled-components';

export const PrivacyWrapper = styled.div`
  .privacy-section {
    font-family: Poppins;
    letter-spacing: 0em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;

    .page-heading {
      font-size: 25px;
      font-weight: 700;
      line-height: 38px;
      text-align: left;
    }

    .radio-buttons-collection {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      width: 100%;

      .radio-options-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: #404f67;
        font-size: 16px;
        line-height: 24px;
        .options-title-style {
          font-weight: 600;
        }

        .radio-buttons {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 1rem;
          font-weight: 400;
        }
      }
    }
    .submit-button {
      margin-top: 10px;
      align-self: center;
    }

    .horizontal-line {
      align-self: center;
      border: 1px solid #404f6733;
      width: 100%;
    }
  }
`;
