import styled from 'styled-components';

export const TeamDashboardWrp = styled.div`
  .team-dashboard-container {
    min-height: 100vh;
    background-color: #f4f7fc;
    & .layout-container {
      padding: 80px 8% 20px 8%;
      & .layout-sub-container {
        display: grid;
        grid-template-columns: minmax(190px, 220px) auto;
        grid-gap: 40px;
        @media (max-width: 1199px) {
          display: block;
        }
        & .sub-container-child {
          @media (max-width: 1199px) {
            margin: 25px 0;
          }
          .no--data {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            gap: 30px;
          }
          & .main-wrapper {
            border-radius: 12px;
            padding: 30px;
            background-color: white;
            position: relative;

            @media (max-width: 992px) {
              padding: 15px;
            }

            .superadmin-approval-pending {
              width: 100%;
              padding: 16px 18px;
              font-weight: 500;
              background-color: #f4e7db;
              color: #af814e;
              display: flex;
              gap: 8px;
              font-size: 14px;
              border-radius: 10px;
              margin-bottom: 8px;
            }

            & .header-text {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 700;
              font-size: 25px;
              color: #17191d;
              margin-bottom: 40px;
            }
            & .heading {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 700;
              font-size: 25px;
              color: #17191d;
              & span {
                font-weight: 600;
                font-size: 23px;
                color: #404f67;
              }
            }
            & .header-buttons-wrapper {
              display: flex;
              justify-content: space-between;
              margin: 20px 0px;
              gap: 50px;

              & .left-buttons {
                width: 100%;
              }
              & .right-buttons {
                display: flex;
                justify-content: right;
                grid-template-columns: repeat(1, 1fr);
                gap: 12px;

                & .remove-button {
                  color: rgba(255, 30, 38, 0.5);
                }

                @media (max-width: 767px) {
                  grid-template-columns: 1fr;
                }
              }

              @media (min-width: 1200px) and (max-width: 1560px) {
                display: grid;
                grid-template-columns: 0.5fr 1fr;
                gap: 20px;
              }

              @media (max-width: 767px) {
                flex-direction: column;
                gap: 10px;
              }
            }
            & .manage-arrow {
              margin-top: 4px;
            }
            & .filter-arrow {
              margin-left: 5px;
              margin-top: 6px;
            }
            & .no-member {
              width: 75%;
              text-align: center;
              margin: 10% auto;
              font-weight: 500;
              color: #929daf;
            }
            & .pagination-bar {
              width: 100%;
              justify-content: center;
              padding-top: 2rem;
            }
            .span-asterisk {
              color: #e66657;
            }
            .createJob-wrapper {
              height: 100%;
              &__title {
                font-weight: 700;
                font-size: 25px;
                &__underline {
                  border: 1px solid #e9eef4;
                }
              }
              &__form {
                margin-top: 30px;
                &__applyWith {
                  font-weight: 600;
                  font-size: 15px;
                  color: #404f67;
                  &__checkBox {
                    .checkbox-title {
                      font-family: 'Poppins';
                      font-style: normal;
                      font-weight: 500;
                      font-size: 16px;
                      color: #404f67;
                    }
                  }
                }
                &__askQuestion {
                  span {
                    font-weight: 600;
                    font-size: 15px;
                    color: #404f67;
                  }
                  &__icon {
                    cursor: pointer;
                  }
                }
                &__recommendation {
                  font-weight: 600;
                  font-size: 15px;
                  color: #404f67;
                  &__radioButton {
                    font-family: 'Poppins';
                    .radio-buttons {
                      display: flex;
                      justify-content: start;
                      gap: 60px;
                      .radio-label {
                        font-weight: 400;
                        font-size: 16px;
                        color: #404f67;
                      }
                    }
                  }
                }
              }
              &__button {
                margin-top: 20px;
                width: 200px;
              }
              &__no--project {
                padding: 10px;
                width: 100%;
                text-align: center;
                color: #929daf;
              }
            }
            &__postedJobs {
              &__postedList {
                .is-job-open {
                  margin-left: 20px;
                }
                .table-text {
                  font-weight: 400;
                  font-size: 14px;
                  cursor: pointer;
                }
                .duplicate-icon {
                  cursor: pointer;
                }
                .applicant-count {
                  text-decoration: underline;
                }
                .buttons {
                  width: 150px;
                }
                .header {
                  border-bottom: 1px solid #e9eef4;
                  .text {
                    font-weight: 700;
                    font-size: 25px;
                    color: #17191d;
                  }
                }
              }
              &__applicantList {
                .dropdown-container {
                  min-height: 0;
                }
                .buttons {
                  width: 150px;
                }
                .table-text {
                  font-weight: 400;
                  font-size: 14px;
                  color: #404f67;
                  cursor: pointer;
                }
                .header {
                  .text {
                    font-weight: 600;
                    font-size: 18px;
                    color: #404f67;
                  }
                }
                &__table {
                  width: 100%;
                  .viewApplication {
                    color: #0abd00;
                    text-decoration: underline;
                    cursor: pointer;
                  }
                  .applicant-technology {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 5px;
                    font-weight: 400;
                    font-size: 14px;

                    & .items {
                      max-width: 200px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      background: rgba(33, 159, 255, 0.1);
                      border-radius: 3px;
                      color: #219fff;
                      padding: 5px 17px;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      white-space: nowrap;
                    }
                    & .count-tech-class {
                      position: relative;
                      .count {
                        cursor: pointer;
                        &:hover {
                          color: white;
                          background: #219fff;
                        }
                      }
                      .techList {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 8px 16px;
                        background: #ffffff;
                        position: absolute;
                        z-index: 99;
                        color: #404f67;
                        font-weight: 400;
                        font-size: 14px;
                        bottom: -40px;
                        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
                        border-radius: 6px;
                        left: 50% !important;
                        right: auto !important;
                        text-align: center !important;
                        transform: translate(-50%, 0) !important;
                      }
                    }
                  }
                }
              }
            }
            &__shortlisted {
              .header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #e9eef4;
                @media (max-width: 575px) {
                  display: block;
                }
                .text {
                  font-weight: 700;
                  font-size: 25px;
                  color: #17191d;
                }
                .dropdown-wrapper {
                  position: relative;
                  &__title {
                    min-width: 300px;
                    cursor: pointer;
                    padding: 12px 20px;
                    background: #f4f7fc;
                    border-radius: 8px;
                    @media (max-width: 575px) {
                      min-width: 100%;
                      margin-top: 12px;
                    }
                    .heading {
                      font-weight: 500;
                      font-size: 15px;
                      line-height: 19px;
                      color: #929daf;
                    }
                  }
                  &__option-wrapper {
                    top: 52px;
                    left: 10px;
                  }
                }
              }
              &__content {
                min-height: 60vh;
                &__header {
                  display: grid;
                  grid-template-columns: auto minmax(200px, 220px);
                  gap: 24px;
                  @media (max-width: 700px) {
                    display: flex;
                    flex-direction: column;
                    column-gap: 12px;
                  }
                }
                &__table {
                  width: 100%;
                  .viewApplication {
                    color: #0abd00;
                    text-decoration: underline;
                    cursor: pointer;
                  }
                  .applicant-technology {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 5px;
                    font-weight: 400;
                    font-size: 14px;

                    & .items {
                      max-width: 200px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      background: rgba(33, 159, 255, 0.1);
                      border-radius: 3px;
                      color: #219fff;
                      padding: 5px 17px;
                      text-overflow: ellipsis;
                      overflow: hidden;
                      white-space: nowrap;
                    }
                    & .count-tech-class {
                      position: relative;
                      .count {
                        cursor: pointer;
                        &:hover {
                          color: white;
                          background: #219fff;
                        }
                      }
                      .techList {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 8px 16px;
                        background: #ffffff;
                        position: absolute;
                        z-index: 99;
                        color: #404f67;
                        font-weight: 400;
                        font-size: 14px;
                        bottom: -40px;
                        box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
                        border-radius: 6px;
                        left: 50% !important;
                        right: auto !important;
                        text-align: center !important;
                        transform: translate(-50%, 0) !important;
                      }
                    }
                  }
                }
                & .contact-popup {
                  margin: 20px 0;

                  &-content {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    gap: 16px;
                    margin: 16px 0;
                  }
                }

                & .success-popup {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: center;
                }
                &__no-applicants {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  gap: 20px;
                  height: 60vh;
                }
              }
            }
          }
        }
      }
      @media (max-width: 1600px) {
        padding: 80px 6% 20px 6%;
      }
      @media (max-width: 992px) {
        padding: 80px 4% 20px 4%;
      }
    }
  }
  .jobs-customTable {
    padding-bottom: 50px !important;
  }
  .postedJobs-pagination {
    margin-top: 40px;
  }
`;
