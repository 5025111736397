import './index.scss';
import Icon from '../../../../utlils/Icons/Icon';
import {
  Box,
  CustomButton,
  CustomCheckBox,
  DropDown,
  Input,
  ScrollableDiv,
} from '../../../../components';
import cx from 'classname';
import { checkForSpacesInStartEnd, isValidEmail } from 'helpers';

const CreateDepartment = ({
  createOrCloseDepartmenthandler,
  state,
  createDepartmentInputChangeHandler,
  createDepartmentCheckHandler,
  createDepartmentAddAdminChangeHandler,
  addAdminHandler,
  deleteAdminHandler,
  roleTypes,
  departmentTypes,
  isEdit,
  context,
}) => {
  const disableBtn = () => {
    const departmentError = (obj) =>
      obj.memberName === '' ||
      checkForSpacesInStartEnd(obj.memberName) ||
      obj.email === '' ||
      !isValidEmail(obj.email) ||
      Object.keys(obj.role).length === 0;
    return state.departmentMembers.some(departmentError);
  };
  const disabled =
    state.departmentName === '' ||
    checkForSpacesInStartEnd(state.departmentName) ||
    Object.keys(state.departmentType).length === 0 ||
    disableBtn();

  return (
    <div className="create-department-container">
      <Box mb="30px">
        <span className="header">
          {isEdit ? 'Edit Department' : 'Create Department'}
        </span>
      </Box>
      <ScrollableDiv
        scrollOnHeight="450px"
        scrollOnHeightMob="calc(100vh - 220px)">
        <div className="row">
          <div className="col-lg-6">
            <Input
              label="Department Name"
              type="text"
              required
              value={state.departmentName}
              onChange={(e) =>
                createDepartmentInputChangeHandler(
                  'departmentName',
                  e.target.value
                )
              }
            />
          </div>
          <div className="col-lg-6">
            <DropDown
              label="Department Type"
              required
              placeholder="Select Department"
              items={departmentTypes}
              onClick={(value) =>
                createDepartmentInputChangeHandler('departmentType', value)
              }
              objectKey="title"
              value={state.departmentType}
            />
          </div>
        </div>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <div>
            <span className="label">Add Admin/Co-admin*</span>
          </div>
          <div>
            <Icon
              name="add"
              color="#0ABD00"
              size={28}
              onClick={() => addAdminHandler()}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </Box>
        <Box mt="20px" mb="20px">
          {state.departmentMembers &&
            state.departmentMembers.map((item, index) => {
              return (
                item.isActive === 1 && (
                  <>
                    <div className="row" key={index}>
                      <div className="col-lg-4">
                        <Input
                          label="Name"
                          type="text"
                          required
                          value={item?.memberName}
                          onChange={(value) =>
                            createDepartmentAddAdminChangeHandler(
                              'memberName',
                              value.target.value,
                              index
                            )
                          }
                        />
                      </div>
                      <div className="col-lg-4">
                        <Input
                          label="Email"
                          type="email"
                          required
                          value={item?.email}
                          onChange={(value) =>
                            createDepartmentAddAdminChangeHandler(
                              'email',
                              value.target.value,
                              index
                            )
                          }
                        />
                      </div>
                      <div
                        className={cx('col-lg-3', {
                          'col-lg-4': isEdit,
                        })}>
                        <DropDown
                          label="Role"
                          placeholder="Select Role"
                          objectKey="label"
                          required
                          items={roleTypes}
                          value={item.role}
                          onClick={(value) => {
                            createDepartmentAddAdminChangeHandler(
                              'role',
                              value,
                              index
                            );
                          }}
                        />
                      </div>
                      {!isEdit && (
                        <div className="col-lg-1 align-self-center">
                          <Icon
                            name="delete"
                            onClick={() => {
                              item.isActive = 0;
                              deleteAdminHandler(index, item);
                            }}
                            style={{ cursor: 'pointer', color: '#ff5157' }}
                          />
                        </div>
                      )}
                    </div>
                    <span className="label">Allow access to</span>
                    <Box mt="10px" mb="40px" className="row gy-2">
                      <div className="col-lg-3">
                        <Box display="flex" alignItems="center" colGap="7px">
                          <CustomCheckBox
                            onCheckBg="rgba(33, 159, 255, 0.1)"
                            check={item.careerPermission}
                            tickColor="#219FFF"
                            onClick={() => {
                              createDepartmentCheckHandler(
                                'careerPermission',
                                item.careerPermission,
                                'membersPermissions',
                                index
                              );
                            }}
                            checkBorder="1px solid #219FFF"
                          />
                          <div>
                            <span className="label">Career</span>
                          </div>
                        </Box>
                      </div>
                      <div className="col-lg-3">
                        <Box display="flex" alignItems="center" colGap="7px">
                          <CustomCheckBox
                            onCheckBg="rgba(33, 159, 255, 0.1)"
                            tickColor="#219FFF"
                            checkBorder="1px solid #219FFF"
                            check={item.taskPermission}
                            onClick={() =>
                              createDepartmentCheckHandler(
                                'taskPermission',
                                item.taskPermission,
                                'membersPermissions',
                                index
                              )
                            }
                          />
                          <div>
                            <span className="label">Task</span>
                          </div>
                        </Box>
                      </div>
                      <div className="col-lg-3">
                        <Box display="flex" alignItems="center" colGap="7px">
                          <CustomCheckBox
                            onCheckBg="rgba(33, 159, 255, 0.1)"
                            tickColor="#219FFF"
                            checkBorder="1px solid #219FFF"
                            check={item.teamApprovalPermission}
                            onClick={() =>
                              createDepartmentCheckHandler(
                                'teamApprovalPermission',
                                item.teamApprovalPermission,
                                'membersPermissions',
                                index
                              )
                            }
                          />
                          <div>
                            <span className="label">Team Approval</span>
                          </div>
                        </Box>
                      </div>
                    </Box>
                  </>
                )
              );
            })}
        </Box>
        <span className="label">Department settings</span>
        <Box mt="10px" mb="40px" className="row gy-2">
          <div className="col-lg-3">
            <Box display="flex" alignItems="center" colGap="7px">
              <CustomCheckBox
                onCheckBg="rgba(33, 159, 255, 0.1)"
                tickColor="#219FFF"
                checkBorder="1px solid #219FFF"
                check={state.departmentSettings.canHire}
                onClick={() =>
                  createDepartmentCheckHandler(
                    'canHire',
                    state.departmentSettings.canHire,
                    'settings'
                  )
                }
              />
              <div>
                <span className="label">Can Hire</span>
              </div>
            </Box>
          </div>
          <div className="col-lg-3">
            <Box display="flex" alignItems="center" colGap="7px">
              <CustomCheckBox
                onCheckBg="rgba(33, 159, 255, 0.1)"
                tickColor="#219FFF"
                checkBorder="1px solid #219FFF"
                check={state.departmentSettings.canPublish}
                onClick={() =>
                  createDepartmentCheckHandler(
                    'canPublish',
                    state.departmentSettings.canPublish,
                    'settings'
                  )
                }
              />
              <div>
                <span className="label">Can publish</span>
              </div>
            </Box>
          </div>
        </Box>
      </ScrollableDiv>
      <div className="create-close-department">
        <CustomButton
          customPadding="16px 30px"
          label={isEdit ? 'Update' : 'Create'}
          disabled={disabled}
          onClick={() => {
            createOrCloseDepartmenthandler(isEdit ? 'update' : 'create');
          }}
          color={'themeSecondary'}
          loading={
            (context.busy.status &&
              context.busy.type === 'CREATE_DEPARTMENT') ||
            context.busy.type === 'UPDATE_DEPARTMENT'
          }
        />
        <CustomButton
          label={'Close'}
          onClick={() => {
            createOrCloseDepartmenthandler('close');
          }}
          color={'themeSecondary'}
          variant="textBtn"
        />
      </div>
    </div>
  );
};

export default CreateDepartment;
