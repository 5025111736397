import './searchField.scss';
import { Input } from '../inputBox';

export const SearchField = ({
  searchField = '',
  onChangeSearchHandler,
  closeSearchHandler,
  placeholder = 'Select',
}) => {
  return (
    <div className="custom-search-field">
      <Input
        iconLeft={searchField.length === 0}
        iconName="search"
        iconRight={searchField.length !== 0}
        iconRightName="cross"
        placeholder={placeholder}
        customStyle={{ height: '53px' }}
        value={searchField}
        onChange={(e) => onChangeSearchHandler(e.target.value)}
        iconRightClassName="close-search"
        iconRightOnClickHandler={closeSearchHandler}
      />
    </div>
  );
};
