import { CustomButton, Input } from 'components';
import {
  addGroupQuestion,
  clearState,
  deleteGroupQuestion,
  onChangeGroupQuestion,
} from 'context/Groups/CreateGroup/createGroupAction';
import { CreateGroupContext } from 'context/Groups/CreateGroup/createGroupContext';
import { arrayKeyValueReducer } from 'pages/TeamDashboard/TeamCareer/helper';
import React, { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CREATE_GROUP, UPDATE_GROUP_DETAILS, useAxiosWrapper } from 'services';
import tokenService from 'services/token.service';
import styled from 'styled-components';
import Icon from 'utlils/Icons/Icon';

export default function GroupQuestions(props) {
  const { groupId } = props;
  const { createGroupState, createGroupDispatch } =
    useContext(CreateGroupContext);
  const params = useParams();
  const navigate = useNavigate();
  const [fnWrapper, context] = useAxiosWrapper();

  const disabled =
    createGroupState?.questions
      .map((ques) => ques.question.trim())
      .includes('') ||
    createGroupState?.questions
      .map((ques) => ques.question)
      .filter((value) => value !== value.trim()).length > 0;

  const createGroupHandler = async () => {
    const formData = new FormData();
    const loggedInUser = tokenService.getSession();
    const questionArray =
      createGroupState.questions[0].question !== ''
        ? arrayKeyValueReducer(createGroupState.questions, 'question')
        : null;
    formData.append('groupName', createGroupState.groupName);
    formData.append('userId', loggedInUser.id);
    formData.append('description', createGroupState.description);
    formData.append('groupType', createGroupState.groupType);
    formData.append('category', createGroupState.category.value);
    formData.append('profilePicture', createGroupState.groupProfilePicture);
    formData.append('question', questionArray);

    let url =
      params.action === 'create'
        ? CREATE_GROUP
        : `${UPDATE_GROUP_DETAILS}/${groupId}`;
    let method = params.action === 'create' ? 'POST' : 'PUT';
    let type =
      params.action === 'create' ? 'CREATE_GROUP' : 'UPDATE_GROUP_DETAILS';
    const res = await fnWrapper({
      url: url,
      method: method,
      payload: formData,
      type: type,
    });
    if (res?.data?.status === 'success') {
      toast.success(
        `Group ${
          params.action === 'create' ? 'created' : 'updated'
        } successfully`,
        {
          position: 'top-right',
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        }
      );
      navigate(`/individual/profile/groups/${params.action}/success`);
      clearState(createGroupDispatch);
    }
  };
  return (
    <GroupQuestionsWrapper>
      <div className="questions">
        <div className="sub-heading">
          {'> Ask question to user who are willing to join your group'}
        </div>
        <div className="question-inputs mt-3">
          {createGroupState?.questions.map((ques, index) => (
            <div className="question my-2" key={index + 1}>
              <Input
                value={createGroupState?.questions[index]?.question}
                onChange={(e) =>
                  onChangeGroupQuestion(
                    createGroupDispatch,
                    e.target.value,
                    index
                  )
                }
              />
              {index === 0 ? (
                <Icon
                  name="add"
                  color="#0ABD00"
                  onClick={() => addGroupQuestion(createGroupDispatch)}></Icon>
              ) : (
                <Icon
                  disabeld
                  name="delete"
                  color="red"
                  onClick={() =>
                    deleteGroupQuestion(createGroupDispatch, index)
                  }></Icon>
              )}
              {}
            </div>
          ))}
        </div>
      </div>
      <div className="action-buttons">
        <CustomButton
          color={'themeSecondary'}
          variant={'textBtn'}
          label={'Back'}
          onClick={() =>
            navigate(
              `/individual/profile/groups/${params.action}/profile-picture`
            )
          }
        />
        <CustomButton
          customPadding="16px 38px"
          color="themeSecondary"
          label={params.action === 'create' ? 'Finish' : 'Update'}
          onClick={createGroupHandler}
          loading={context.busy.status}
          disabled={disabled}
        />
      </div>
    </GroupQuestionsWrapper>
  );
}

const GroupQuestionsWrapper = styled.div`
  .questions {
    .sub-heading {
      margin-top: 20px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #404f67;
    }
    .question-inputs {
      .question {
        display: flex;
        gap: 10px;
      }
    }
  }
  .action-buttons {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
`;
