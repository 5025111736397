export const getLeftSideData = (userId) => {
  return [
    {
      type: 'summary',
      iconName: 'text-align-left',
      title: 'Summary',
      url: `/public/individual/${userId}/summary/basic-details`,
      hasSubMenu: false,
    },
    {
      type: 'teams',
      iconName: 'people',
      title: 'Teams',
      url: `/public/individual/${userId}/teams`,
      hasSubMenu: false,
    },
    {
      type: 'projects',
      iconName: 'project',
      title: 'Projects',
      url: `/public/individual/${userId}/projects`,
      hasSubMenu: false,
    },
    {
      type: 'job-posts',
      iconName: 'brief-case',
      title: 'Job Posts',
      url: `/public/individual/${userId}/job-posts`,
      hasSubMenu: false,
    },
    {
      type: 'publications',
      iconName: 'publications',
      title: 'Publications',
      url: `/public/individual/${userId}/publications`,
      hasSubMenu: false,
    },
  ];
};

export const TEAM_AND_PROJECT_TYPE = [
  // { id: 1, label: 'All', value: '0' },
  { id: 1, label: 'Enterprise', value: '1' },
  { id: 2, label: 'Personal', value: '2' },
];

// export const INVITE_TYPE = [
//   { id: 0, label: 'Job', value: '0' },
//   { id: 1, label: 'Freelancing', value: '1' },
//   { id: 2, label: 'Collaboration', value: '2' },
//   { id: 3, label: 'Partnership', value: '3' },
// ];

export const INVITE_TYPE = [
  { id: 0, label: 'Job', value: '0' },
  { id: 1, label: 'Collaboration', value: '1' },
  { id: 2, label: 'Bidding', value: '2' },
];

export const INVITE_TEAM_PROJECT = [
  { id: 0, label: 'Team', value: '0' },
  { id: 1, label: 'Project', value: '1' },
];

export const SUMMARY_TAB_LIST = [
  'Basic Details',
  'Education',
  'Professional Details',
  'Achievements',
  'License & Certifications',
];

export const PUBLIC_PRIVATE_TOGGLE_LABEL = {
  PUBLIC: 'Public',
  PRIVATE: 'Private',
};

export const INVITATION_TYPES = {
  JOB: 'JOB',
  COLLABORATION: 'COLLABORATION',
  BIDDING: 'BIDDING',
  GROUP: 'GROUP',
};
