import styled from 'styled-components';

export const AddTeamMemberWrappper = styled.div`
  .add-field-section {
    text-decoration: underline;
    color: #0abd00;
  }
  .add-members-header {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #404f67;
    margin-bottom: 17px;
  }
  .underline-tag {
    height: 2.5px;
    color: #404f67;
  }
  .delete-member {
    border-bottom: 1.5px dashed #c4c4c4;
    color: #c4c4c4;
    padding: 10px 0;
  }

  .search-content {
    padding-left: 1.5%;
    padding-right: 3%;
  }
  .add-teamMember {
    display: flex;
    justify-content: center;
    &-button {
      width: 150px;
    }
  }
`;
