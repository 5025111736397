import { useMemo, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import ViewDepartment from './departments/viewDepartment';
import { Box, NavbarBrand1, Sidebar } from '../../components';
import { ENTERPRISE_DASHBOARD_NAV_LIST, enterpriseInfoData } from './constants';
import Departments from './departments';
import './index.scss';
import { PostedJobs } from 'pages/TeamDashboard/TeamCareer/PostedJobs';
import { Shortlisted } from 'pages/TeamDashboard/TeamCareer/Shortlisted';
import ProfileCard from 'pages/IndividualDashboard/components/profileCard';
import EnterpriseProfile from './profile';
import { useEffect } from 'react';
import { useCallback } from 'react';
import tokenService from 'services/token.service';
import {
  CREATE_FCM_TOKEN,
  FETCH_USER_PROFILE,
  useAxiosWrapper,
} from 'services';
import { getProfileText } from 'utlils/utils';
import People from './people';
import { generateFcmToken } from 'firebaseConfig/generateToken';

const EnterpriseHomePage = ({ currentLocale, handleLocaleChange }) => {
  const [fnWrapper] = useAxiosWrapper();
  const location = useLocation();
  const loggedInUserSession = useMemo(() => tokenService.getSession(), []);

  const [userProfile, setUserProfile] = useState({});
  const [leftActiveItem, setLeftActiveItem] = useState('');
  const isProfileEditPage =
    location.pathname.includes('/enterprise/profile/edit/') ||
    location.pathname.includes('/enterprise/profile/create/');

  const setLeftSidebar = (type) => {
    setLeftActiveItem(type);
  };

  const getUserProfile = useCallback(async () => {
    const loggedInUser = tokenService.getSession();
    const res = await fnWrapper({
      url: `${FETCH_USER_PROFILE}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_USER_PROFILE',
    });
    if (res.data.statusCode === 200) {
      const response = res.data.data;
      setUserProfile({
        ...response,
        profileText: getProfileText(
          response?.role === 'INDIVIDUAL'
            ? response?.name
            : response?.enterpriseName
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUserProfile();
  }, [getUserProfile]);

  // create fcm token
  const createFcmToken = useCallback(async () => {
    // Call generateFcmToken this will call that function and stores fcm token in local Storage
    const generateFcmTokenInfo = await generateFcmToken();
    if (generateFcmTokenInfo) {
      let data = {
        userId: loggedInUserSession.id,
        fcmToken: generateFcmTokenInfo?.fcmToken,
        deviceName: generateFcmTokenInfo?.deviceInfo?.deviceName,
        deviceType: 'WEB_BROWSER',
      };
      await fnWrapper({
        url: `${CREATE_FCM_TOKEN}`,
        method: 'POST',
        type: 'CREATE_FCM_TOKEN',
        payload: data,
      });
    } else {
      // eslint-disable-next-line no-console
      console.log('Show error popup');
    }
    // if needed then we can update the fcm token in local storage here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loggedInUserSession) {
      createFcmToken();
    }
  }, [createFcmToken, loggedInUserSession]);

  return (
    <>
      <NavbarBrand1
        navMenu={ENTERPRISE_DASHBOARD_NAV_LIST}
        currentLocale={currentLocale}
        handleLocaleChange={handleLocaleChange}
        userProfile={userProfile}
      />
      {isProfileEditPage ? (
        <Routes>
          <Route path="">
            <Route
              element={
                <EnterpriseProfile
                  userProfile={userProfile}
                  setUserProfile={setUserProfile}
                />
              }
              path="profile/*"
            />
          </Route>
        </Routes>
      ) : (
        <div className="enterprise-profile-container">
          <div className="layout-container">
            <div className="layout-sub-container">
              <div className="sub-container-child">
                <ProfileCard
                  userProfile={{
                    ...userProfile,
                    name: userProfile.enterpriseName,
                  }}
                />
                <div style={{ marginTop: '1.5rem' }}>
                  <Sidebar
                    sidebarData={enterpriseInfoData}
                    activeType={leftActiveItem}
                    setActiveType={setLeftSidebar}
                  />
                </div>
              </div>
              <Box bg="white" className="main-wrapper">
                <Routes>
                  <Route path="">
                    <Route element={<Departments />} path="departments" />
                    <Route element={<People />} path="people" />
                    <Route
                      path="departments/view/:departmentId/*"
                      element={<ViewDepartment />}
                    />
                    <Route
                      element={
                        <EnterpriseProfile
                          userProfile={userProfile}
                          setUserProfile={setUserProfile}
                        />
                      }
                      path="profile/*"
                    />
                    <Route
                      path="career/postedJobs"
                      element={<PostedJobs isCareerEnterprise />}
                    />
                    <Route
                      path="career/shortlisted"
                      element={<Shortlisted isCareerEnterprise />}
                    />
                  </Route>
                </Routes>
              </Box>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EnterpriseHomePage;
