import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Arrow } from './Arrow';
import Tooltip from './tooltipKit';
import Bubble from './Bubble';
import { easingPropType } from './propTypes';

const ContainerCss = css`
  position: relative;
  display: inline-block;
`;

class Wrapper extends React.Component {
  constructor() {
    super();

    this.state = {
      open: false,
    };

    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleTouch = this.handleTouch.bind(this);
  }

  handleMouseEnter() {
    this.setState({ open: true });
  }

  handleMouseLeave() {
    this.setState({ open: false });
  }

  handleTouch() {
    const isOpen = this.state.open;
    this.setState({ open: !isOpen });
  }

  render() {
    const { open } = this.state;
    const {
      arrow,
      background,
      border,
      children,
      color,
      content,
      customCss,
      fadeDuration,
      fadeEasing,
      fixed,
      fontFamily,
      fontSize,
      offset,
      padding,
      placement,
      radius,
      zIndex,
      gutter,
      arrow_position,
      ...props
    } = this.props;
    const hasTrigger = children !== undefined && children !== null;
    const tooltipElement = (
      <Tooltip
        open={!hasTrigger || fixed ? true : open}
        placement={placement}
        offset={offset + arrow}
        zIndex={zIndex}
        fadeEasing={fadeEasing}
        fadeDuration={fadeDuration}
        gutter={gutter}>
        <Bubble
          background={background}
          border={border}
          color={color}
          radius={radius}
          fontFamily={fontFamily}
          fontSize={fontSize}
          padding={padding}>
          <Arrow
            width={arrow}
            background={background}
            border={border}
            color={color}
            placement={placement}
            arrow_position={arrow_position}
          />
          <div
            style={{
              width: 'fit-content',
              minWidth: 100,
              textAlign: 'center',
              fontSize: '12px',
            }}>
            {content}
          </div>
        </Bubble>
      </Tooltip>
    );
    return hasTrigger ? (
      <Div
        onMouseEnter={!fixed ? this.handleMouseEnter : undefined}
        onMouseLeave={!fixed ? this.handleMouseLeave : undefined}
        onTouchEnd={!fixed ? this.handleTouch : undefined}
        customCss={css`
          ${ContainerCss};
          ${customCss}
        `}
        {...props}>
        {children}
        {tooltipElement}
      </Div>
    ) : (
      <Div
        customCss={css`
          ${ContainerCss};
          ${customCss}
        `}
        {...props}>
        {tooltipElement}
      </Div>
    );
  }
}

const Div = styled.div`
  ${({ customCss }) => customCss}
  display: flex;
  align-items: center;
`;

Wrapper.propTypes = {
  arrow: PropTypes.number,
  background: PropTypes.string,
  border: PropTypes.string,
  children: PropTypes.any,
  color: PropTypes.string,
  content: PropTypes.any.isRequired,
  customCss: PropTypes.any,
  fadeDuration: PropTypes.number,
  fadeEasing: easingPropType,
  fixed: PropTypes.bool,
  fontFamily: PropTypes.string,
  fontSize: PropTypes.string,
  offset: PropTypes.number,
  padding: PropTypes.number,
  placement: PropTypes.oneOf([
    'left',
    'top',
    'right',
    'bottom',
    'topLeft',
    'bottomLeft',
    'bottomRight',
    // 'topRight',
  ]),
  radius: PropTypes.number,
  zIndex: PropTypes.number,
  gutter: PropTypes.number,
  arrow_position: PropTypes.number,
};

Wrapper.defaultProps = {
  arrow: 8,
  background: '#000',
  border: '#000',
  children: null,
  color: '#fff',
  fadeDuration: 0,
  fadeEasing: 'linear',
  fixed: false,
  fontFamily: 'inherit',
  fontSize: 'inherit',
  offset: 0,
  padding: 16,
  placement: 'top',
  radius: 0,
  zIndex: 1,
  gutter: 50,
  arrow_position: 50,
};

Wrapper.displayName = 'Tooltip.Wrapper';
Tooltip.displayName = 'Tooltip';
Bubble.displayName = 'Tooltip.Bubble';
Arrow.displayName = 'Tooltip.Arrow';

export default Wrapper;
