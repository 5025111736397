import { CustomButton } from 'components/button/button';
import { noop } from 'lodash';
import React from 'react';
import { useState } from 'react';
import Icon from 'utlils/Icons/Icon';

export const CopyWithIcon = (props) => {
  const {
    id,
    iconConfig = {
      name: 'link-two',
      className: 'setting-icon-style',
      position: 'LEFT',
    },
    className,
    label = '',
    textToCopy,
  } = props;
  const [copyId, setCopyId] = useState(null);

  const handleCopyText = async (e) => {
    await copyValue(e, textToCopy);
    // to highlight which clipboard is active
    // set_clipboardId({ clipboardId: clipboardID });
  };

  const copyValue = async (e, textToCopy) => {
    if (e) {
      // stop default click propagation
      e.preventDefault();
      e.stopPropagation();
    }

    const is_clipboard_api_supported = navigator.clipboard;
    if (is_clipboard_api_supported) {
      await is_clipboard_api_supported.writeText(textToCopy);
    } else {
      // only for older browsers ~1%!!
      // creat a input & copy the text and remove it
      let inp = document.createElement('INPUT');
      inp.value = textToCopy;
      document.body.appendChild(inp);
      inp.select();
      document.execCommand('copy');
      document.body.removeChild(inp);
    }
    setCopyId(id);
  };

  return (
    <>
      {copyId === null ? (
        <CustomButton
          color="themeSecondary"
          onClick={handleCopyText}
          customPadding="12px"
          className={className}>
          {iconConfig.position === 'LEFT' && (
            <Icon
              name={iconConfig.name}
              size={24}
              color="#ffffff"
              className={iconConfig.className}
            />
          )}
          {label}
          {iconConfig.position === 'RIGHT' && (
            <Icon
              name={iconConfig.name}
              size={24}
              color="#ffffff"
              className={iconConfig.className}
            />
          )}
        </CustomButton>
      ) : (
        <CustomButton
          label={'Copied'}
          color="themeSecondary"
          className={className}
          onClick={noop}
          disabled
          customPadding="12px"
        />
      )}
    </>
  );
};
