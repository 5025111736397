import { CustomButton, ModalPopup } from 'components';
import { useState } from 'react';
import { initializeTwilioConversations } from 'services/twilioService/twilioClient';
import { createOrJoinConversation } from 'services/twilioService/helper';
import tokenService from 'services/token.service';
import { useNavigate } from 'react-router-dom';

export const MessageOrRemoveButtons = ({
  connectedUserId,
  onRemoveConnectionHandler,
  className,
}) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const openPopupHandler = () => {
    setIsOpen(true);
  };

  const onClosePopupHandler = () => {
    setIsOpen(false);
  };

  const MessageBtnHandler = async () => {
    setLoading(true);
    const loggedInUser = tokenService.getSession();
    const client = await initializeTwilioConversations(loggedInUser.id);
    const senderId = loggedInUser?.id;
    const receiverId = connectedUserId;
    const friendlyName = `one-to-one-${senderId}--${receiverId}`;
    const uniqueName = `${senderId}--${receiverId}`;
    const participantIdentity = connectedUserId;
    const attributes = {
      senderId: senderId,
      receiverId: receiverId,
      conversationType: 'ONE_TO_ONE',
    };

    let res = await createOrJoinConversation(
      client,
      participantIdentity,
      friendlyName,
      uniqueName,
      attributes
    );
    setLoading(false);
    navigate(`/individual/messages/conversation/${res?.sid}`);
  };

  return (
    <>
      <div className={className}>
        <CustomButton
          loading={loading}
          label="Message"
          className="w-50"
          variant="outline"
          onClick={MessageBtnHandler}
        />
        <CustomButton
          label="Remove"
          className="w-50"
          variant="textBtn"
          color="themeGray"
          onClick={openPopupHandler}
        />
      </div>
      {isOpen ? (
        <ModalPopup
          open={false}
          hasCloseIcon={false}
          minWidth="700px"
          maxContentWidth="700px"
          hasTitle
          title="Remove Connection">
          <div className="connection_modal_wrp">
            <p className="connection_modal_wrp__text text-center mt-4">
              You are going to remove this connection from your network
            </p>
            <p className="connection_modal_wrp__text text-center mt-4">
              Do you really want to remove it ?
            </p>
            <div className="connection_modal_wrp__btn-wrp">
              <CustomButton
                label="No"
                variant="textBtn"
                onClick={onClosePopupHandler}
              />
              <CustomButton
                label="Yes"
                variant="solid"
                onClick={onRemoveConnectionHandler}
                customPadding="16px 30px"
              />
            </div>
          </div>
        </ModalPopup>
      ) : null}
    </>
  );
};
