import { Box, Pagination } from 'components';
import { TaskListItems } from './TaskList';

export const TaskListWrapper = (props) => {
  const {
    taskList,
    taskType,
    pageSize,
    onPageChangeHandler,
    activeTaskAction,
    onOpenTaskAction,
    onSelectTaskAction,
    onViewTaskHandler,
  } = props;

  return (
    <div>
      {taskList?.items?.length === 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt="80px"
          mb="60px">
          <span className="back-btn">
            {taskType === 'My Tasks'
              ? 'No Problems are Assigned to you'
              : 'No Problems are Published by you'}
          </span>
        </Box>
      ) : (
        <>
          <TaskListItems
            list={taskList?.items}
            activeTaskAction={activeTaskAction}
            onOpenTaskAction={onOpenTaskAction}
            onSelectTaskAction={onSelectTaskAction}
            taskType={taskType}
            onViewTaskHandler={onViewTaskHandler}
          />
          {taskList &&
            (taskList.items.length >= 5 || taskList.currentPage >= 1) && (
              <div className="d-flex justify-content-center my-4">
                <Pagination
                  className="pagination-bar"
                  currentPage={taskList.currentPage ? taskList.currentPage : 0}
                  totalCount={taskList.totalItems ? taskList.totalItems : 0}
                  pageSize={pageSize}
                  onPageChange={(page) => onPageChangeHandler(page)}
                />
              </div>
            )}
        </>
      )}
    </div>
  );
};

export { CreateTask } from './CreateTask';
export { EditTask } from './EditTask';
export { DepartmentTaskFilter } from './Filter';
export { TaskListItems } from './TaskList';
export { ViewTaskPopUp } from './ViewTask';
