import React from 'react';
import styled from 'styled-components';
import Icon from '../../utlils/Icons/Icon';
// Style the Button component
const Button = styled.button`
  border-style: ${({ borderStyle }) => borderStyle};
  border-width: ${({ borderWidth }) => borderWidth};
  border-color: ${({ borderColor }) => borderColor};
  background: ${({ background }) => background};

  & .upload-txt {
    font-family: ${({ fontFamily }) => fontFamily};
    font-weight: ${({ fontWeight }) => fontWeight};
    font-size: ${({ fontSize }) => fontSize};
    color: ${({ textColor }) => textColor};
  }
  &:disabled {
    cursor: not-allowed;
  }
`;
export const CustomFileUploadButton = ({
  borderWidth,
  borderStyle,
  textColor,
  borderColor,
  iconColor,
  fontFamily,
  label,
  handleFile,
  iconName,
  fontSize,
  fontWeight,
  iconSize,
  isMultiple,
  disabled = false,
}) => {
  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    const fileUploaded = event.target.files;
    handleFile(fileUploaded);
  };
  return (
    <>
      <Button
        borderWidth={borderWidth}
        borderStyle={borderStyle}
        borderColor={borderColor}
        background="none"
        fontFamily={fontFamily}
        fontSize={fontSize}
        fontWeight={fontWeight}
        textColor={textColor}
        onClick={handleClick}
        disabled={disabled}>
        <span className="upload-txt" style={{ display: 'flex' }}>
          {label}
          <Icon color={iconColor} size={iconSize} name={iconName} />
        </span>
      </Button>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
        multiple={isMultiple}
      />
    </>
  );
};
