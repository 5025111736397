import styled from 'styled-components';

export const SingleTaskWrapper = styled.div`
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ height }) => (height ? height : '100%')};
  margin-bottom: 25px;
  padding: 20px;
  width: 100%;
  background: rgba(196, 196, 196, 0.06);
  border-radius: 10px;
  border: 1px solid #c4c4c4;

  @media (max-width: 576px) {
    padding: 10px;
  }

  & .post-wrapper {
    padding-bottom: 10px;
    & .posts-header {
      display: flex;
      justify-content: space-between;

      & .left-side-class {
        display: grid;
        grid-template-columns: 50px 100%;
        gap: 10px;
        & .profile-pic-container,
        .profile-pic-container img {
          width: 40px;
          height: 40px;
          object-fit: cover;
          background: #d0e5ff;
          border-radius: 50%;
          & .pfp-alt-circle {
            border-radius: 50%;
            width: 40px;
            height: 40px;
            background-color: #d6f3f9;
            display: flex;
            align-items: center;
            justify-content: center;
            & .pfp-alt {
              border-radius: 50%;
              font-weight: bold;
            }
          }

          & .user-icon-class {
            margin-top: 10px;
            margin-left: 8px;
          }
        }

        & .user-name {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 1px;
          color: #040d20;

          & .user-name-style {
            @media (max-width: 767px) {
              margin-bottom: 1px;
            }
          }

          & .user-posted-time {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 1px;
            color: #929daf;
          }
          @media (max-width: 767px) {
            line-height: normal;
            padding-top: 0px;
          }
        }

        @media (max-width: 767px) {
          grid-template-columns: 1fr;
        }
      }

      & .options-class {
        padding: 0 5px 10px 5px;
        align-items: flex-end;
        cursor: pointer;
      }
    }

    & .post-content-container {
      margin-top: 30px;
      gap: 30px;
      font-family: 'Poppins';
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;

      & .content-heading {
        padding-bottom: 20px;
        font-style: normal;
        color: #404f67;
      }

      & .content-description {
        font-style: italic;
        color: #929daf;
      }

      & .post-attachment {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px 20px;

        & .img-wrapper {
          display: flex;
          width: 100%;
          word-break: break-all;

          & a {
            color: #17191d;
          }

          & .img-container {
            width: 30px;
            height: 30px;
            margin-top: 10px;
            &:hover {
              cursor: pointer;
            }
          }

          & .img-text {
            margin-top: 10px;
            text-decoration: underline;

            &:hover {
              cursor: pointer;
            }
          }
        }

        @media (max-width: 767px) {
          grid-template-columns: 1fr;
          gap: 5px;
        }
      }
    }

    & .post-footer {
      margin: ${({ showComments }) => (showComments ? '16px 0' : '16px 0 0')};
      padding-bottom: ${({ showComments }) => (showComments ? '16px' : 0)};
      border-bottom: ${({ showComments }) =>
        showComments ? '1px solid #c4c4c4' : 'none'};
      display: flex;
      align-items: flex-start;
      gap: 20px;
      & .footer-button-box {
        display: flex;
        align-items: center;
        color: #929daf;
        & .icon-button {
          margin: 5px;
          &:hover {
            cursor: pointer;
            color: #219fff;
          }
        }
      }
    }
  }
`;

export const PostTasksWrapper = styled.div`
  padding: 25px;
  width: 95%;
  margin: 0 auto;
  background: rgba(196, 196, 196, 0.06);
  border-radius: 0 0 18.1281px 18.1281px;
  border: 1px solid #c4c4c4;
  border-top: none;
`;
