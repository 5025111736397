import React, { useState } from 'react';
import { PrivacyWrapper } from './style';
import { CustomButton } from 'components';
import {
  LINK_REQUEST_OPTIONS,
  EXPLORE_TEAM_PAGE_VISIBILITY_OPTIONS,
  ENTERPRISE_TEAM_LINK_REQUEST_OPTIONS,
} from '../../constants';
import RadioGroup from 'pages/IndividualDashboard/components/radioGroup';
import { UPDATE_TEAM_PRIVACY, useAxiosWrapper } from 'services';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

export const Privacy = (props) => {
  const { teamInfo, setTeamData } = props;
  const [profileVisibilityOption, setProfileVisibilityOption] = useState(
    teamInfo?.profileVisibleInExploreTeam ? 'true' : 'false'
  );
  const [linkRequestOption, setRequestOption] = useState(
    teamInfo?.acceptLinkRequest
  );
  const [fnWrapper, context] = useAxiosWrapper();
  let { teamId } = useParams();
  const onChangeHandler = (type, value) => {
    if (type === 'profile') {
      setProfileVisibilityOption(value);
    } else {
      setRequestOption(value);
    }
  };

  const updateProfileSettings = async () => {
    const res = await fnWrapper({
      url: UPDATE_TEAM_PRIVACY,
      method: 'PUT',
      type: 'UPDATE_TEAM_PRIVACY',
      params: { teamId: teamId },
      payload: {
        profileVisibleInExploreTeam: profileVisibilityOption,
        acceptLinkRequest: linkRequestOption,
      },
    });
    if (res.data.statusCode === 200) {
      toast.success(res.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
    setTeamData((state) => ({
      ...state,
      acceptLinkRequest: linkRequestOption,
    }));
  };

  return (
    <PrivacyWrapper>
      <div className="privacy-section">
        <div className="page-heading">
          <span>Privacy</span>
        </div>
        <div className="radio-buttons-collection">
          <div className="radio-options-section">
            <div className="options-title-style">
              <p>Make my profile visible in Explore Team’s page</p>
            </div>
            <div className="radio-buttons">
              <RadioGroup
                handleChange={(e) => onChangeHandler('profile', e.target.value)}
                labels={EXPLORE_TEAM_PAGE_VISIBILITY_OPTIONS}
                currChecked={profileVisibilityOption}
              />
            </div>
          </div>
          <hr className="horizontal-line my-4" />
          <div className="radio-options-section">
            <div className="options-title-style">
              <p>Accept link request from-</p>
            </div>
            <div className="radio-buttons">
              <RadioGroup
                handleChange={(e) => onChangeHandler('link', e.target.value)}
                labels={
                  teamInfo?.enterpriseId
                    ? ENTERPRISE_TEAM_LINK_REQUEST_OPTIONS
                    : LINK_REQUEST_OPTIONS
                }
                currChecked={linkRequestOption}
              />
            </div>
          </div>
        </div>
        <div className="submit-button">
          <CustomButton
            customPadding="16px 67px"
            label="Save"
            variant="solid"
            color="themePrimary"
            loading={
              context.busy.type === 'UPDATE_TEAM_PRIVACY' && context.busy.status
            }
            onClick={() => updateProfileSettings()}
          />
        </div>
      </div>
    </PrivacyWrapper>
  );
};
