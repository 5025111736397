import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { ClickOutside, OptionWrapper } from 'components';
import Icon from 'utlils/Icons/Icon';
import { arrayOf, func, shape, string } from 'prop-types';

export const ProfileViewDropdown = ({
  options,
  onClick,
  title,
  selectedOption,
}) => {
  const [open, setOpen] = useState(false);

  const onClickOptionHandler = useCallback(
    (item) => {
      onClick(item);
      setOpen(false);
    },
    [onClick]
  );

  return (
    <ClickOutside active={open} onClickOutside={() => setOpen(false)}>
      <Wrp className="pvd">
        <div className="pvd__header" onClick={() => setOpen(!open)}>
          <div className="pvd__header_text">{title}</div>
          <Icon
            name={open ? 'arrow-drop-up' : 'arrow-down'}
            color="#17191d"
            size={open ? 20 : 24}
          />
        </div>
        {open && (
          <OptionWrapper
            setOpenDropdown={setOpen}
            className="pvd__option-wrapper"
            options={options}
            selectedOption={selectedOption}
            onClick={onClickOptionHandler}
          />
        )}
      </Wrp>
    </ClickOutside>
  );
};

ProfileViewDropdown.propTypes = {
  onClick: func,
  options: arrayOf(
    shape({
      id: string.isRequired,
      icon: shape({
        color: string,
        name: string,
        size: string,
      }),
      title: string.isRequired,
    })
  ).isRequired,
  selectedOption: string,
};

const Wrp = styled.div`
  position: relative;
  & .pvd__ {
    &header {
      position: relative;
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 2px;
      cursor: pointer;
      @media (max-width: 576px) {
        max-width: 260px;
      }

      &_text {
        color: #17191d;
        font-size: 24px;
        font-weight: 700;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &option-wrapper {
      width: max-content;
    }
  }
`;
