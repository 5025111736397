import styled from 'styled-components';

export const FileAttachmentPreviewWrapper = styled.div`
  position: absolute;
  display: inline-block;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 10px 10px 10px 0;
  padding: 20px;
  margin-bottom: 200px;
  position: absolute;
  left: 0px;

  .filePreview__container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    .attachment-icon {
      background-color: #d3d8d6;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto 10px;
      padding: 10px;
    }

    .attachment-details {
      text-align: center;
      margin-bottom: 10px;
      .attachment-title {
        font-size: 16px;
        font-weight: 600;
      }
      .attachment-size {
        font-size: 14px;
      }
      .attachment-max-size {
        color: red;
        font-size: 14px;
      }
    }
  }

  .attachment-action-btns {
    display: flex;
    justify-content: space-between;
  }

  &::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 20px solid #f0f0f0;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
  }
`;
