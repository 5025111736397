import { useState } from 'react';
import { toast } from 'react-toastify';
import {
  CustomDropDown,
  ToggleSwitch,
  ModalPopup,
  CustomButton,
} from 'components';
import {
  STATUS_DROPDOWN_LIST,
  TASK_STATUS,
  TASK_TOGGLE_LABEL,
} from 'pages/enterpriseDashboard/constants';

import styled from 'styled-components';
import { isMobile } from 'utlils';
import { ManageBoard } from 'pages/TaskManagement/components';
import { useAxiosWrapper, UPDATE_DEPARTMENT_TASK_STATUS } from 'services';
import Icon from 'utlils/Icons/Icon';

export const FilterRow = (props) => {
  const {
    filterStatus,
    taskType,
    onTaskStatusChange,
    onToggleHandler,
    onOpenCreateTaskModal,
    setIsFilterOpen,
    isFilterOpen,
    onSortHandler,
    isToggleSort,
    departmentId,
  } = props;
  const [fnWrapper, context] = useAxiosWrapper();
  const [isShowTaskField, setShowTaskField] = useState(false);

  const DROPDOWN_STYLE = {
    [TASK_STATUS.IN_PROGRESS]: {
      backgroundColor: 'rgba(255, 164, 18, 0.2)',
      color: 'rgba(255, 164, 18, 1)',
    },
    [TASK_STATUS.COMPLETED]: {
      backgroundColor: 'rgba(10, 189, 0, 0.2)',
      color: 'rgba(10, 189, 0, 1)',
    },
    [TASK_STATUS.DECLINED]: {
      backgroundColor: 'rgba(230, 102, 87, 0.3)',
      color: 'rgba(230, 102, 87, 1)',
    },
    [TASK_STATUS.ALL]: {
      backgroundColor: '#f4f7fc',
      color: '#929daf',
    },
    [TASK_STATUS.PENDING]: {
      backgroundColor: '#f4f7fc',
      color: '#929daf',
    },
  };

  const updateDepartmentTaskStatus = async (fields) => {
    const payload = {
      departmentId: departmentId,
      status: fields,
    };
    const res = await fnWrapper({
      url: `${UPDATE_DEPARTMENT_TASK_STATUS}`,
      method: 'PUT',
      type: `UPDATE_DEPARTMENT_TASK_STATUS`,
      payload: payload,
    });
    if (res.data.statusCode === 200) {
      toast.success('Updated task status successfully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  return (
    <FilterRowWrp>
      <div className="header-toogle">
        Tasks
        <ToggleSwitch
          activeLabel={taskType}
          onClick={onToggleHandler}
          toggleLabel={[
            TASK_TOGGLE_LABEL.MY_TASKS,
            TASK_TOGGLE_LABEL.OTHER_TASKS,
          ]}
          width={isMobile() ? '12rem' : '16rem'}
          style={{ margin: '0 auto' }}
        />
      </div>
      <div>
        <div className="option-settings">
          <CustomButton
            onClick={() => setShowTaskField(!isShowTaskField)}
            color={'themeGray'}
            variant="light">
            <Icon name="manageTask" color={'#CCD2DD'} size={18} />
          </CustomButton>
          <CustomButton
            onClick={onSortHandler}
            color={isToggleSort ? 'themeSecondary' : 'themeGray'}
            variant="light">
            <Icon
              name="sort"
              color={isToggleSort ? 'rgba(33, 159, 255, 1)' : '#CCD2DD'}
              size={18}
            />
          </CustomButton>
          <CustomButton
            onClick={() => setIsFilterOpen(!isFilterOpen)}
            color={isFilterOpen ? 'themeSecondary' : 'themeGray'}
            variant="light">
            <Icon
              name="filter"
              color={isFilterOpen ? 'rgba(33, 159, 255, 1)' : '#CCD2DD'}
              size={18}
            />
          </CustomButton>
          <CustomDropDown
            optionLists={STATUS_DROPDOWN_LIST}
            selectedItem={filterStatus}
            setSelectedItem={(status) => onTaskStatusChange(status)}
            placeholder={'Status'}
            height="54px"
            customStyle={{
              ...DROPDOWN_STYLE[filterStatus?.value],
              padding: '18px',
            }}
            width={'100%'}
          />
          {taskType !== TASK_TOGGLE_LABEL.MY_TASKS && (
            <CustomButton onClick={onOpenCreateTaskModal} variant="light">
              <Icon name="add" color="#0ABD00" size={18} />
              Create
            </CustomButton>
          )}
        </div>
      </div>
      {isShowTaskField && (
        <ModalPopup
          open={false}
          onClose={() => setShowTaskField(false)}
          minWidth="650px"
          maxContentWidth="650px"
          hasTitle
          title="Manage Board">
          <ManageBoard
            departmentId={departmentId}
            isUpdating={
              context.busy.status &&
              context.busy.type === 'UPDATE_DEPARTMENT_TASK_STATUS'
            }
            onSaveFields={updateDepartmentTaskStatus}
            type="DEPARTMENT"
          />
        </ModalPopup>
      )}
    </FilterRowWrp>
  );
};

const FilterRowWrp = styled.div`
  padding-top: 10px;
  width: 100%;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;

  @media (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 0.6rem;
    margin: 0 auto;
  }
  .header-toogle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
    font-size: 25px;
    color: #17191d;
    padding: 5px;
    @media only screen and (max-width: 992px) and (min-width: 320px) {
      display: grid;
      gap: 15px;
    }
  }
  .option-settings {
    width: 50%;
    float: inline-end;
    display: flex;
    align-items: center;
    gap: 5px;
    @media only screen and (max-width: 992px) and (min-width: 320px) {
      float: inline-start;
    }
  }
`;
