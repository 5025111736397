import styled from 'styled-components';
export const YourNetworkOrRequestStyle = styled.div`
  display: flex;
  justify-content: space-between;
  & .yourNetworkRequest {
    &__network--details {
      display: flex;
      gap: 25px;
      align-items: end;
      &__profile {
        min-width: 60px;
      }
      &__name--title {
        display: flex;
        flex-direction: column;
        width: 180px;
        @media (max-width: 992px) {
          align-items: center;
          text-align: center;
        }
        .title {
          color: #404f67;
          font-weight: 400;
          font-size: 15px;
        }
      }
      .mutual-connection {
        display: flex;
        align-items: center;
        gap: 4px;
        color: #404f67;
        font-weight: 400;
        font-size: 15px;
        margin-left: 30px;
      }
      @media (max-width: 992px) {
        flex-direction: column;
        align-items: center;
      }
    }
  }
  @media (max-width: 992px) {
    flex-direction: column;
    align-items: center;
  }
`;
