import { Route, Routes } from 'react-router-dom';
import { SavedProjects } from './SavedProjects';
import { SearchProject } from './SearchProject';

export const ExploreProjects = () => {
  return (
    <Routes>
      <Route path="">
        <Route element={<SearchProject />} path="search-project" />
        <Route element={<SavedProjects />} path="saved-projects" />
      </Route>
    </Routes>
  );
};
