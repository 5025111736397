import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Base = (props) => css`
  position: absolute;
  width: ${props.width}px;
  height: ${props.width}px;
  background: ${props.background};
`;

const Up = (props) => css`
  ${Base(props)};
  transform: translateX(-50%) translateY(50%) rotateZ(45deg);
  bottom: 100%;
  left: ${props.arrow_position}%;
  border-left: 1px solid ${props.border};
  border-top: 1px solid ${props.border};
`;
const Down = (props) => css`
  ${Base(props)};
  transform: translateX(-50%) translateY(-50%) rotateZ(45deg);
  top: 100%;
  left: ${props.arrow_position}%;
  border-right: 1px solid ${props.border};
  border-bottom: 1px solid ${props.border};
`;
const Left = (props) => css`
  ${Base(props)};
  transform: translateX(50%) translateY(-50%) rotateZ(45deg);
  right: 100%;
  top: ${props.arrow_position}%;
  border-left: 1px solid ${props.border};
  border-bottom: 1px solid ${props.border};
`;

const Right = (props) => css`
  ${Base(props)};
  transform: translateX(-50%) translateY(-50%) rotateZ(45deg);
  left: 100%;
  top: ${props.arrow_position}%;
  border-right: 1px solid ${props.border};
  border-top: 1px solid ${props.border};
`;
const TopLeft = (props) => css`
  ${Base(props)};
  transform: translateX(-50%) translateY(-50%) rotateZ(45deg);
  top: 100%;
  left: 80%;
  border-right: 1px solid ${props.border};
  border-bottom: 1px solid ${props.border};
`;
const UpLeft = (props) => css`
  ${Base(props)};
  transform: translateX(-50%) translateY(50%) rotateZ(45deg);
  bottom: 100%;
  left: 90%;
  border-left: 1px solid ${props.border};
  border-top: 1px solid ${props.border};
`;
const BottomRight = (props) => css`
  ${Base(props)};
  transform: translateX(-50%) translateY(50%) rotateZ(45deg);
  bottom: 100%;
  left: 10%;
  border-left: 1px solid ${props.border};
  border-top: 1px solid ${props.border};
`;

const BaseArrow = ({ fn, ...props }) => <Div propResolver={() => fn(props)} />;

const Div = styled.div`
  ${({ propResolver }) => propResolver()}
`;

BaseArrow.propTypes = {
  fn: PropTypes.func.isRequired,
  background: PropTypes.string.isRequired,
  border: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  arrow_position: PropTypes.number,
};

const arrows = {
  left: (props) => BaseArrow({ fn: Right, ...props }),
  top: (props) => BaseArrow({ fn: Down, ...props }),
  right: (props) => BaseArrow({ fn: Left, ...props }),
  bottom: (props) => BaseArrow({ fn: Up, ...props }),
  topLeft: (props) => BaseArrow({ fn: TopLeft, ...props }),
  bottomLeft: (props) => BaseArrow({ fn: UpLeft, ...props }),
  bottomRight: (props) => BaseArrow({ fn: BottomRight, ...props }),
};

export const Arrow = ({
  background,
  border,
  placement,
  width,
  arrow_position,
}) => {
  const Component = arrows[placement] || arrows.top;
  return (
    width > 0 && (
      <Component
        background={background}
        border={border}
        width={width}
        arrow_position={arrow_position}
      />
    )
  );
};

Arrow.propTypes = {
  background: PropTypes.string.isRequired,
  border: PropTypes.string.isRequired,
  placement: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  arrow_position: PropTypes.number,
};
