import React from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

const PolicyWrapper = styled.div`
  padding: 50px 80px;
  overflow: hidden;
`;

const Title = styled.div`
  font-size: 30px;
  font-weight: bold;
  text-decoration: underline;
  color: #219fff;
`;

const Content = styled.div`
  padding: 20px 0;
  color: #455a64;
  font-size: 18px;
`;

export const Policy = ({ title, content }) => {
  return (
    <PolicyWrapper>
      <Title>{title}</Title>
      <Content>
        <ReactMarkdown>{content}</ReactMarkdown>
      </Content>
    </PolicyWrapper>
  );
};
