import { useCallback, useEffect, useState } from 'react';
import cx from 'classname';
import { FETCH_LICENSE_DETAILS, useAxiosWrapper } from 'services';
import { Box, Loader } from 'components';
import NoDataImg from '../../../../../assests/images/nodata.png';
import './licenseDetailsView.scss';
import TokenService from 'services/token.service';

const DEFAULTSTATE = [
  {
    title: '',
    issuedBy: '',
    issuedDate: '',
    credentialLink: '',
    about: '',
  },
];

export const LicenseDetailsView = ({ title, nextType, onSave }) => {
  const [state, setState] = useState(DEFAULTSTATE);
  const [fnWrapper, context] = useAxiosWrapper();

  const getLicenseAndCertificationDetails = useCallback(async () => {
    const loggedInUser = TokenService.getSession();
    const res = await fnWrapper({
      url: `${FETCH_LICENSE_DETAILS}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_LICENSE_INFO',
    });
    if (res.data.statusCode === 200) {
      const response = res.data.data;
      let dataArray = [];
      response.forEach((ele) => {
        let data = {
          itemId: ele.id,
          title: ele.title,
          issuedBy: ele.issuedBy,
          credentialLink: ele.credentialLink,
          issuedDate: ele.issuedDate,
          about: ele.about,
        };
        dataArray = [...dataArray, data];
      });
      setState(dataArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getLicenseAndCertificationDetails();
  }, [getLicenseAndCertificationDetails]);

  return (
    <>
      {context.busy.status ? (
        <Loader loading={context.busy.status} />
      ) : (
        <div className="row mb-2 profile-view-container">
          {state.length === 0 ? (
            <div className="empty-data-section">
              <img
                src={NoDataImg}
                alt="No Data"
                className="empty-data-section--img"
              />
              <div className="empty-data-section--text">No data available</div>
            </div>
          ) : (
            state.map((ele, index) => {
              const options = { year: 'numeric', month: 'short' };
              let issuedDate = new Date(ele.issuedDate).toLocaleString(
                'default',
                options
              );
              return (
                <div
                  key={index}
                  className={cx(
                    'content-wrapper',
                    state.length !== index + 1 ? 'border-below' : ''
                  )}>
                  <div className="row">
                    <div className="col-md-6 col-lg-6 col-6">
                      <Box mt="10px">
                        <p className="label">Title</p>
                        <p className="value">{ele.title}</p>
                      </Box>
                    </div>
                    <div className="col-md-6 col-lg-6 col-6">
                      <Box mt="10px">
                        <p className="label">Issued Date</p>
                        <p className="value">{issuedDate}</p>
                      </Box>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 col-lg-6 col-6">
                      <Box mt="10px">
                        <p className="label">Issued by</p>
                        <p className="value">{ele.issuedBy}</p>
                      </Box>
                    </div>
                    <div className="col-md-6 col-lg-6 col-6">
                      <Box mt="10px">
                        <p className="label">Credential Link</p>
                        <a
                          className="value"
                          href={ele && ele.credentialLink}
                          target="_blank"
                          rel="noreferrer">
                          {ele.credentialLink}
                        </a>
                      </Box>
                    </div>
                  </div>
                </div>
              );
            })
          )}
        </div>
      )}
    </>
  );
};
