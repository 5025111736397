import cn from 'classname';
import { React, useState } from 'react';
import Icon from '../../../../../utlils/Icons/Icon';
import { INVITEMEMBERROLE } from '../../../constants';
import { checkForSpacesInStartEnd, isValidEmail } from '../../../../../helpers';
import {
  Box,
  CustomButton,
  DropDown,
  Input,
  SearchBoxWithDropDown,
} from 'components';
import AddButton from 'pages/IndividualDashboard/components/addButton';
import { getProfileText } from 'utlils/utils';
import { SEARCH_USER, useAxiosWrapper } from 'services';

export const InviteTeamMembers = ({
  saveButtonHandler,
  createTeamState,
  setCreateTeamState,
  createTeamStateHandler,
}) => {
  const [fnWrapper] = useAxiosWrapper();
  const [searchMember, setSearchMember] = useState('');
  const [filteredMemberData, setFilterMemberData] = useState([]);
  const session = JSON.parse(localStorage.getItem('session'));
  const DEFAULT_TEAM_OWNER_DETAILS = {
    memberName: session.name,
    role: 'ADMIN',
    designation: '',
    email: session.email,
    invitedByUserId: session.id,
  };
  const [teamOwnerDetails, setTeamOwnerDetails] = useState(
    DEFAULT_TEAM_OWNER_DETAILS
  );

  const disableTeamMembers = () => {
    const teamMemberError = (obj) =>
      obj.memberName.length === 0 ||
      obj.designation.length === 0 ||
      checkForSpacesInStartEnd(obj.memberName) ||
      checkForSpacesInStartEnd(obj.designation) ||
      obj.email.length === 0 ||
      !isValidEmail(obj.email) ||
      obj.role.length === 0;
    return createTeamState.teamMembers.some(teamMemberError);
  };

  let buttonDisabled =
    teamOwnerDetails.designation === '' ||
    checkForSpacesInStartEnd(teamOwnerDetails.designation) ||
    disableTeamMembers();

  const addMemberFieldsHandler = () => {
    const temp = [...createTeamState.teamMembers];
    temp.push({
      memberName: '',
      role: {},
      designation: '',
      email: '',
      invitedByUserId: session.id,
    });

    setCreateTeamState({ ...createTeamState, teamMembers: temp });
  };

  const teamMemberHandler = (type, value, index) => {
    const temp = [...createTeamState.teamMembers];
    temp[index][type] = value;
    let newValue = [...temp];
    setCreateTeamState({ ...createTeamState, teamMembers: newValue });
  };

  const onDeleteInviteMembers = async (index) => {
    const temp = [...createTeamState.teamMembers];
    temp.splice(index, 1);
    setCreateTeamState({ ...createTeamState, teamMembers: temp });
  };

  const onChangeSearchMember = async (value) => {
    setSearchMember(value);
    if (value.length >= 2) {
      let data = {
        q: value,
      };
      const res = await fnWrapper({
        url: SEARCH_USER,
        method: 'POST',
        type: 'SEARCH_USER',
        payload: data,
      });

      if (res.data.statusCode === 200) {
        const searchData = res.data.data;
        let finalArray = [];
        if (searchData.length) {
          finalArray = searchData
            .map((ele) => {
              return {
                id: ele.id,
                userId: ele.userId,
                name: ele.name,
                email: ele.user.email,
                userName: ele.user.userName,
                profilePicture: ele.profilePicture,
                profileText: getProfileText(ele.name),
              };
            })
            .filter((ele) => ele.userId !== session.id);
        }
        setFilterMemberData(finalArray);
      } else {
        setFilterMemberData([]);
      }
    }
  };

  const onSelectSearchMember = async (member) => {
    let searchedMmeber = {
      memberName: member.name,
      designation: '',
      role: {},
      email: member.email,
      invitedByUserId: session.id,
    };
    let newElement = [];
    newElement = [...createTeamState.teamMembers, searchedMmeber];
    //removed empty object and add new searched object
    let finalArray = newElement.filter(
      (ele) => ele.memberEmail !== '' && ele.memberName !== ''
    );
    createTeamStateHandler('teamMembers', finalArray);
    setSearchMember('');
  };

  return (
    <>
      <Box mt="16px">
        <span className="add-members">Your information</span>
      </Box>
      <Box mt="16px">
        <Box>
          <div>
            <div className="row">
              <div className="col-lg-6">
                <Input
                  label="Name"
                  type="text"
                  required
                  value={teamOwnerDetails.memberName}
                  disabled
                />
              </div>
              <div className="col-lg-6">
                <Input
                  label="Email"
                  type="email"
                  required
                  value={teamOwnerDetails.email}
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <Input
                  label="Role"
                  type="text"
                  required
                  value={teamOwnerDetails.role}
                  disabled
                />
              </div>
              <div className="col-lg-6">
                <Input
                  label="Designation"
                  type="text"
                  required
                  value={teamOwnerDetails.designation}
                  onChange={(evt) => {
                    setTeamOwnerDetails({
                      ...teamOwnerDetails,
                      designation: evt.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </Box>
      </Box>
      <Box
        display="flex"
        mt="16px"
        justifyContent="space-between"
        alignItems="center">
        <div>
          <span className="add-members">Add Members</span>
        </div>
        {/* <CustomButton>
          <Icon name="export" color={'#ffffff'} size={22} />
          Upload csv
        </CustomButton> */}
      </Box>
      <Box mt="24px">
        <SearchBoxWithDropDown
          searchInputValue={searchMember}
          placeholder="Search for members in your organization"
          searchData={filteredMemberData}
          onSelectSearchItem={onSelectSearchMember}
          onInputChangeHandler={onChangeSearchMember}
        />
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <span className="add-members">Invite Members</span>
        <AddButton onClick={() => addMemberFieldsHandler()} />
      </Box>
      <Box mt="32px">
        {createTeamState.teamMembers.map((item, index) => (
          <Box mb="30px">
            <div
              className={cn(
                'add-teams-item',
                createTeamState.teamMembers.length > 1 && 'add-teams-border'
              )}>
              <div className="row">
                <div className="col-lg-6">
                  <Input
                    label="Name"
                    type="text"
                    required
                    value={item.memberName}
                    onChange={(evt) =>
                      teamMemberHandler('memberName', evt.target.value, index)
                    }
                  />
                </div>
                <div className="col-lg-6">
                  <Input
                    label="Email"
                    type="email"
                    required
                    value={item.email}
                    onChange={(evt) =>
                      teamMemberHandler('email', evt.target.value, index)
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <DropDown
                    label="Role"
                    placeholder="Select Role"
                    objectKey="label"
                    required
                    items={INVITEMEMBERROLE}
                    value={item.role}
                    onClick={(value) => teamMemberHandler('role', value, index)}
                  />
                </div>
                <div className="col-lg-6">
                  <Input
                    label="Designation"
                    type="text"
                    required
                    value={item.designation}
                    onChange={(evt) =>
                      teamMemberHandler('designation', evt.target.value, index)
                    }
                  />
                </div>
              </div>
              <div className="row">
                {
                  <Box cursor="pointer">
                    <Icon
                      name="delete"
                      onClick={() => onDeleteInviteMembers(index)}
                    />
                  </Box>
                }
              </div>
            </div>
          </Box>
        ))}
      </Box>
      <Box
        mt="32px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        colGap="15px">
        <CustomButton
          customPadding="16px 38px"
          label={'Finish'}
          disabled={buttonDisabled}
          onClick={() => saveButtonHandler(teamOwnerDetails)}
          color="themeSecondary"
        />
      </Box>
    </>
  );
};
