export const INDIVIDUAL_DASHBOARD_NAV_LIST = (role) => [
  {
    id: 'home',
    title: 'Home',
    url:
      role === 'individual'
        ? '/individual/profile/info/basic_details'
        : '/enterprise/profile/view/details',
    isDropdown: false,
  },
  role === 'individual' && {
    id: 'explore',
    title: 'Explore',
    isDropdown: true,
    dropDownItems: [
      {
        id: 'explore_teams',
        title: 'Explore Teams',
        url: '/explore/teams/search-team',
      },
      {
        id: 'explore_projects',
        title: 'Explore Projects',
        url: '/explore/projects/search-project',
      },
      {
        id: 'explore_jobs',
        title: 'Explore Jobs',
        url: '/explore/jobs/search-job',
      },
      {
        id: 'explore_networks',
        title: 'Explore Networks',
        url: '/explore/networks/search-people',
      },
      {
        id: 'explore_groups',
        title: 'Explore Groups',
        url: '/explore/groups/search-groups',
      },
    ],
  },
  {
    id: 'messages',
    title: 'Messages',
    url:
      role === 'INDIVIDUAL'
        ? '/individual/messages/conversation/all'
        : '/enterprise/messages/conversation/all',
    isDropdown: false,
    isIcon: true,
    iconName: 'message',
    iconColor: '#2AC173',
  },
];
