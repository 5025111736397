import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Icon from 'utlils/Icons/Icon';

const AllFeaturesWrapper = styled.div`
  padding: 5em 0 0;
  & .afw__ {
    &header {
      display: grid;
      justify-items: center;
      gap: 12px;
      &__sub-heading {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        color: #7e8f9a;
        @media (max-width: 991px) {
          font-size: 16px;
        }
      }
      &__heading {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 35px;
        color: #219fff;
        text-align: center;
        @media (max-width: 991px) {
          font-size: 26px;
        }
      }
    }
    &item-wrp {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      background: linear-gradient(252.69deg, #001542 -0.53%, #070f1f 101.52%);
      border-radius: 17.9115px;
      gap: 24px;
      padding: 50px;
      margin-top: 45px;
      @media (max-width: 991px) {
        padding: 36px 12px;
        grid-template-columns: auto;
      }
      &__card {
        background: #ffffff;
        box-shadow: 4px 4px 45px rgba(126, 143, 154, 0.2);
        border-radius: 20px;
        padding: 20px;
        position: relative;
        &--iconBg {
          border-radius: 100%;
          background: rgba(33, 159, 255, 0.15);
          display: inline-block;
          padding: 10px;
          margin-bottom: 20px;
        }
        &--heading {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          color: #292d32;
          @media (max-width: 991px) {
            font-size: 16px;
          }
        }
        &--description {
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 32px;
          color: rgba(41, 45, 50, 0.5);
          padding-bottom: 70px;
          margin: 0;
          @media (max-width: 991px) {
            font-size: 14px;
          }
        }
        &--footer {
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          background: rgba(33, 159, 255, 0.1);
          border-radius: 0px 0px 20px 20px;
          width: 100%;
          padding: 12px;
          cursor: pointer;
          &__wrp {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 8px;
            &__label {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 32px;
              color: #219fff;
            }
          }
        }
      }
    }
  }
`;

export const AllFeatures = ({ content }) => {
  const navigate = useNavigate();
  return (
    <AllFeaturesWrapper className="afw">
      <div className="container">
        <div className="row">
          <div className="afw__header">
            <Icon name={content.header_icon} color="#219fff" size={35} />
            <div className="afw__header__sub-heading">{content.subHeading}</div>
            <div className="afw__header__heading">{content.heading}</div>
          </div>
          <div className="afw__item-wrp">
            {content.items.map((item, index) => (
              <div className="afw__item-wrp__card" key={index}>
                <div className="afw__item-wrp__card--iconBg">
                  <Icon name={item.iconName} color="#219fff" size={25} />
                </div>
                <div className="afw__item-wrp__card--heading">
                  {item?.heading}
                </div>
                <ul className="afw__item-wrp__card--description">
                  {item?.description.map((list, index) => (
                    <li key={index}>{list}</li>
                  ))}
                </ul>
                <div
                  className="afw__item-wrp__card--footer"
                  onClick={() => navigate(item?.action)}>
                  <div className="afw__item-wrp__card--footer__wrp">
                    <div className="afw__item-wrp__card--footer__wrp__label">
                      {item?.actionTitle}
                    </div>
                    <Icon name="arrow-right" color="#219fff" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </AllFeaturesWrapper>
  );
};
