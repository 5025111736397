import React from 'react';
import { default as TooltipReact } from './index';
export class Tooltip extends React.Component {
  // ["left","top","right","bottom","topLeft", "bottomLeft", 'bottomRight']
  render() {
    const {
      content,
      children,
      placement = 'top',
      radius = 4,
      padding = 10,
      offset = 5,
      background = '#2C2C2C',
      zIndex = 11,
      noBorder,
      borderColor = '#E0E0E0',
      gutter,
      arrow_position,
      no_tooltip,
      ...props
    } = this.props;

    if (no_tooltip) return children;
    return (
      <TooltipReact
        arrow={10}
        background={background}
        border={noBorder ? '' : borderColor}
        color="#fff"
        content={content}
        fadeDuration={10}
        arrow_position={arrow_position}
        fadeEasing="linear"
        fixed={false}
        fontFamily="inherit"
        fontSize="inherit"
        offset={offset}
        gutter={gutter}
        padding={padding}
        placement={placement}
        radius={radius}
        zIndex={zIndex}
        {...props}>
        {children}
      </TooltipReact>
    );
  }
}
