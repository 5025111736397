export const getProjectPublicLeftSideData = (projectId) => {
  return [
    {
      type: 'details',
      iconName: 'text-align-left',
      title: 'Details',
      url: `/public/project/${projectId}/details`,
      hasSubMenu: false,
    },
    {
      type: 'linked-teams',
      iconName: 'people',
      title: 'Linked Teams',
      url: `/public/project/${projectId}/linked-teams`,
      hasSubMenu: false,
    },
    {
      type: 'linked-projects',
      iconName: 'project',
      title: 'Linked Projects',
      url: `/public/project/${projectId}/linked-projects`,
      hasSubMenu: false,
    },
    {
      type: 'publications',
      iconName: 'book',
      title: 'Publications',
      url: `/public/project/${projectId}/publications`,
      hasSubMenu: false,
    },
    {
      type: 'openings',
      iconName: 'brief-case',
      title: 'Openings',
      url: `/public/project/${projectId}/openings`,
      hasSubMenu: false,
    },
  ];
};

export const PROJECT_ACCESS = {
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE',
};
