import { useState, useCallback, useEffect } from 'react';
import { YourNetworkWrapperStyle } from './style';
import { YourNetworkOrRequestList } from 'pages/Explore/Components';
import { MessageOrRemoveButtons } from './messageOrRemoveNetwork';
import { FETCH_ALL_MY_CONNECTED_USERS, useAxiosWrapper } from 'services';
import TokenService from 'services/token.service';
import { Loader, Pagination } from 'components';
import { CREATE_USER_CONNECTION } from 'services';
import createInitialTeams from 'assests/images/createInitialTeams.png';
import { SearchInput } from 'components';
export const YourNetworkWrapper = () => {
  const [connectedUsers, setConnectedUsers] = useState([]);
  const [fnWrapper, context] = useAxiosWrapper();
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  let pageSize = 15;
  const getAllConnectedUsers = useCallback(
    async () => {
      const loggedInUser = TokenService.getSession();
      const res = await fnWrapper({
        url: `${FETCH_ALL_MY_CONNECTED_USERS}/${loggedInUser.id}`,
        method: 'GET',
        type: 'FETCH_GET_ALL_PUBLIC_USERS',
        payload: {
          page: currentPage,
          size: pageSize,
          hasPaginated: true,
        },
      });
      if (res.data.statusCode === 200) {
        setConnectedUsers(res.data.data);
      } else {
        setConnectedUsers([]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage, pageSize]
  );
  useEffect(() => {
    getAllConnectedUsers();
  }, [getAllConnectedUsers]);

  const onRemoveConnectionHandler = async (connectWithId) => {
    const payload = {
      connectWithId: connectWithId,
      type: 'DISCONNECT',
    };
    const res = await fnWrapper({
      url: `${CREATE_USER_CONNECTION}`,
      method: 'POST',
      type: 'REMOVE_CONNECTION_REQUEST',
      payload: payload,
    });
    if (res.data.statusCode === 200) {
      if (connectedUsers?.items?.length === 1 && currentPage > 1) {
        setCurrentPage(currentPage - 1);
      } else getAllConnectedUsers();
    }
  };

  const searchHandler = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value === '') {
      getAllConnectedUsers();
    }
  };

  const closeSearchHandler = () => {
    setSearchTerm('');
    getAllConnectedUsers();
  };

  return (
    <>
      {connectedUsers?.items?.length >= 1 && (
        <div className="explore-searchBox">
          <SearchInput
            value={searchTerm}
            onSearchange={searchHandler}
            onCloseSearch={closeSearchHandler}
            onSearch={getAllConnectedUsers}
          />
        </div>
      )}
      <div className="main-wrapper">
        {context.busy.status ? (
          <Loader loading={context.busy.status} />
        ) : connectedUsers?.items?.length >= 1 ? (
          <>
            <YourNetworkWrapperStyle className="yourNetwork-list">
              <div className="yourNetwork-list__totalPeople">
                {`${connectedUsers?.totalItems} People in your network`}{' '}
                <span className="count"></span>
              </div>
              <div className="yourNetwork-list__table">
                {connectedUsers.items.map((item) => (
                  <div className="yourNetwork-list__table__row" key={item.id}>
                    <YourNetworkOrRequestList
                      networkDetails={item?.connectedUsers}
                      mutualConnections={item?.mutualConnections?.length}
                      footerElement={
                        <MessageOrRemoveButtons
                          connectedUserId={item?.connectedUsers?.id}
                          className="yourNetwork-list__table__footer--button"
                          onRemoveConnectionHandler={() =>
                            onRemoveConnectionHandler(
                              item?.connectedUsers.userConnectionsId
                            )
                          }
                        />
                      }
                    />
                  </div>
                ))}
              </div>
            </YourNetworkWrapperStyle>
          </>
        ) : (
          <div className="no--data">
            {`Sorry ! you have no networks yet`}
            <img src={createInitialTeams} width="320px" alt="noData" />
          </div>
        )}
      </div>
      {connectedUsers?.totalItems > pageSize && (
        <div className="pagination-wrapper">
          <Pagination
            currentPage={connectedUsers?.currentPage}
            totalCount={connectedUsers?.totalItems}
            pageSize={pageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      )}
    </>
  );
};
