import {
  CustomButton,
  CustomDropDown,
  CustomTable,
  NavigationalRowItem,
  StatusButton,
} from 'components';
import dayjs from 'dayjs';
import { startCase } from 'lodash';
import {
  BIDDING_APPLICANTS_TABLE_HEADERS,
  applicantStatus,
  biddingApplicantStatus,
  currencyLists,
} from 'pages/Projects/ViewProject/ProjectCollaboration/constant';
import { ViewApplicationWrapper } from 'pages/Projects/ViewProject/ProjectCollaboration/style';
import React from 'react';
import { useEffect, useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  GET_BIDDING_APPLICANTS_LIST,
  GET_PROJECT_BIDDING_BY_ID,
  useAxiosWrapper,
} from 'services';
import Icon from 'utlils/Icons/Icon';
import { getPlaceholderItems } from 'utlils/utils';
import ViewBidderApplication from '../ViewBidderApplication/ViewBidderApplication';

export default function ViewBiddingApplicants(props) {
  const [header, setHeaders] = useState(null);
  const navigate = useNavigate();
  let { id, projectId, type } = useParams();
  const [selectedItem, setSelectedItem] = useState(null);
  const [fnWrapper, context] = useAxiosWrapper();
  const [applicantsTableData, setApplicantsTableData] = useState([]);
  const [viewApplication, setViewApplication] = useState(false);
  const [viewApplicationData, setViewApplicationData] = useState(null);

  const getBiddingDetailsById = useCallback(async () => {
    const res = await fnWrapper({
      url: `${GET_PROJECT_BIDDING_BY_ID}/${id}`,
      method: 'GET',
      type: 'GET_PROJECT_BIDDING_BY_ID',
    });
    if (res.data.statusCode === 200) {
      setHeaders(
        startCase(res.data.data?.biddingType.split('_').join(' ').toLowerCase())
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBiddingApplicantList = useCallback(async (status) => {
    let payload = {
      biddingId: id,
      status: status,
    };
    const res = await fnWrapper({
      url: `${GET_BIDDING_APPLICANTS_LIST}/`,
      method: 'GET',
      type: 'GET_BIDDING_APPLICANT_LIST',
      params: payload,
    });
    if (res.data.statusCode === 200) {
      const result = res.data.data;
      setApplicantsTableData(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getBiddingDetailsById();
    getBiddingApplicantList();
  }, [getBiddingApplicantList, getBiddingDetailsById]);

  const openPopupHandler = (applicationData) => {
    setViewApplicationData(applicationData);
    setViewApplication(true);
  };

  const applicantsListTableData = (data) => {
    let newData = [];
    if (data.length) {
      newData = data.map((item) => {
        const isIndividualUser = item.biddingApplicant?.individual !== null;
        return {
          id: item.id,
          name: (
            <NavigationalRowItem
              label={
                isIndividualUser
                  ? item.biddingApplicant?.individual?.name
                  : item.biddingApplicant?.smallEnterprise?.enterpriseName
              }
              navigateTo={
                isIndividualUser
                  ? `/public/individual/${item?.biddingApplicant?.id}/summary/basic-details`
                  : ''
              }
              isOpenInNewTab
              isIndividualUser={isIndividualUser}
              customStyle={{ color: '#404f67' }}
            />
          ),
          appliedOn: dayjs(item.createdAt).format('DD MMM YYYY'),
          bidderAmount:
            currencyLists[item.currencyType] + '' + item.bidderAmount,
          application: (
            <div className="view-btn">
              <CustomButton
                label={'View'}
                customPadding="0 0"
                variant="textBtn"
                onClick={() => openPopupHandler(item)}
                className="view-btn-font"
              />
            </div>
          ),
          status: <StatusButton status={biddingApplicantStatus[item.status]} />,
          action: (
            <Icon
              name="message-icon"
              color={
                item.status === 'ACCEPTED' ? 'rgb(33, 159, 255)' : '#C2C2C2'
              }
            />
          ),
        };
      });
    } else {
      newData = [];
    }

    return newData;
  };

  const allApplicantsData =
    context.busy.status && context.busy.type === 'GET_BIDDING_APPLICANT_LIST'
      ? getPlaceholderItems(
          BIDDING_APPLICANTS_TABLE_HEADERS.map(({ sortKey }) => sortKey),
          6,
          context.busy.status &&
            context.busy.type === 'GET_BIDDING_APPLICANT_LIST'
        )
      : applicantsListTableData(applicantsTableData);

  return (
    <div>
      <ViewApplicationWrapper>
        <div className="applicants-container">
          <div className="d-flex justify-content-between">
            <div className="applicants-container__header">
              <span className="heading-back-btn">
                <Icon
                  color="#292D32"
                  name="arrow-back"
                  size={30}
                  leftIcon
                  onClick={() => {
                    navigate(`${props.baseUrl}/${projectId}/view/collab-bids`, {
                      state: { type: type.toUpperCase() },
                    });
                  }}
                />
              </span>
              <span className="heading-text">{header}</span>
            </div>
            <div>
              <CustomDropDown
                optionLists={applicantStatus}
                selectedItem={selectedItem}
                setSelectedItem={async (item) => {
                  setSelectedItem(item);
                  await getBiddingApplicantList(item?.value);
                }}
                height="36px"
                customStyle={{
                  backgroundColor: '#EBEBEB',
                  color: '#404F67',
                  padding: '10px 8px 10px 16px',
                  whiteSpace: 'normal',
                  width: '200px',
                }}
                width={'100%'}
                placeholder={'Status'}
              />
            </div>
          </div>
        </div>
        {!applicantsTableData.length ? (
          <div className="no-records">No Records Found!</div>
        ) : (
          <div className="table-style">
            <CustomTable
              isLoading={context.busy.status}
              rows={allApplicantsData}
              headers={BIDDING_APPLICANTS_TABLE_HEADERS}
            />
          </div>
        )}
        {viewApplication && (
          <ViewBidderApplication
            setViewApplication={setViewApplication}
            setViewApplicationData={viewApplicationData}
            getBiddingApplicantList={getBiddingApplicantList}
          />
        )}
      </ViewApplicationWrapper>
    </div>
  );
}
