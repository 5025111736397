import classname from 'classname';
import { useState } from 'react';
import styled from 'styled-components';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { arrayOf, func, shape, string } from 'prop-types';
import Icon from 'utlils/Icons/Icon';

export const OptionMenuWithDot = (props) => {
  const {
    alignment = 'row',
    arrowPosition = { top: '', left: '', right: '' },
    className,
    onSelectMenu,
    optionMenu,
    wrapperPosition = { top: '', left: '', right: '' },
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const ref = useDetectClickOutside({ onTriggered: () => setIsOpen(!isOpen) });

  const onSelectHandler = async (e, option) => {
    e.preventDefault();
    e.stopPropagation();
    if (typeof onSelectHandler === 'function') {
      await onSelectMenu(option);
    }
    setIsOpen(!isOpen);
  };

  return (
    <Wrp
      className={classname('omwd', className)}
      alignment={alignment}
      arrowPosition={arrowPosition}
      wrapperPosition={wrapperPosition}>
      <div className="omwd__three-dot" onClick={() => setIsOpen(!isOpen)}>
        <Icon name="threeDots" size={30} color="#929daf" />
      </div>
      {isOpen && optionMenu?.length && (
        <div className="omwd__paper" ref={ref}>
          {optionMenu.map((option, index) => (
            <div
              className="omwd__options"
              key={option?.id + index}
              onClick={(e) => onSelectHandler(e, option)}>
              <div className="omwd__options--label">{option.label}</div>
              {index !== optionMenu.length - 1 && (
                <div className="omwd__options--underline" />
              )}
            </div>
          ))}
        </div>
      )}
    </Wrp>
  );
};

const Wrp = styled.div`
  position: relative;
  & .omwd__ {
    &three-dot {
      display: flex;
      align-items: center;
      gap: 5px;
      height: 100%;
      flex-direction: ${({ alignment }) => alignment};
      cursor: pointer;
    }
    &paper {
      position: absolute;
      left: ${({ wrapperPosition }) => wrapperPosition.left};
      right: ${({ wrapperPosition }) => wrapperPosition.right};
      bottom: ${({ wrapperPosition }) => wrapperPosition.bottom};
      top: ${({ wrapperPosition }) => wrapperPosition.top};
      width: max-content;
      background: #fff;
      z-index: 11;
      padding: 4px 12px;
      border: 1px solid #f4f7fc;
      box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.06);
      border-radius: 6px;
      border-top: 6px solid #219fff;
      &::before {
        content: '';
        position: absolute;
        top: ${({ arrowPosition }) => arrowPosition.top};
        bottom: ${({ arrowPosition }) => arrowPosition.bottom};
        left: ${({ arrowPosition }) => arrowPosition.left};
        right: ${({ arrowPosition }) => arrowPosition.right};
        border: 10px solid transparent;
        border-bottom-color: #219fff;
      }
    }
    &options {
      cursor: pointer;
      &--label {
        padding: 8px 0;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #404f67;
      }
      &--underline {
        background: #e9eef4;
        width: 100%;
        height: 1px;
      }
    }
  }
`;

OptionMenuWithDot.propTypes = {
  alignment: string,
  arrowPosition: shape({
    top: string,
    bottom: string,
    left: string,
    right: string,
  }),
  className: string,
  onSelectMenu: func.isRequired,
  optionMenu: arrayOf(
    shape({
      id: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  wrapperPosition: shape({
    top: string,
    bottom: string,
    left: string,
    right: string,
  }),
};
