import { CustomButton, ModalPopup, Textarea } from 'components';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Icon from 'utlils/Icons/Icon';

export default function GroupInvite(props) {
  const { invite, context, setOpenInvitationPopup, handleGroupAcceptDecline } =
    props;
  const [answers, setAnswers] = useState({});
  const navigate = useNavigate();
  const [questionsPopup, setQuestionsPopup] = useState(false);

  const handleAnswerChange = (question, newValue) => {
    setAnswers({
      ...answers,
      [question]: newValue,
    });
  };

  useEffect(() => {
    let questions =
      invite?.group?.question !== 'null' || null
        ? Object.values(JSON.parse(invite?.group?.question))
        : [];
    if (questions) {
      const initialAnswers = questions.reduce((acc, question) => {
        acc[question] = '';
        return acc;
      }, {});
      setAnswers(initialAnswers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="invitation-wrapper">
      <div className="invitation-content">
        <div className="invitation-icon-container">
          <div className="invitation-icon-wrapper">
            <Icon
              name="brief-case"
              className="invitation-icon"
              color="#0ABD00"
            />
          </div>
        </div>
        <div className="invitation-highlight-wrapper">
          <span className="invitation-highlight">
            {`${
              invite?.invitedBy?.individual
                ? invite?.invitedBy?.individual?.name
                : invite?.invitedBy?.smallEnterprise?.enterpriseName
            } `}
          </span>
          invited you to join{' '}
          <span
            role="button"
            className="invitation-highlight text-decoration-underline"
            onClick={() => {
              navigate(
                `/individual/profile/groups/${invite?.group?.id}/view/description`
              );
              setOpenInvitationPopup(false);
            }}>
            {invite?.group?.groupName}
          </span>{' '}
        </div>
      </div>
      <div className="invitation-button">
        <div className="apply-button">
          <CustomButton
            loading={context.busy.status}
            label="Accept"
            className="w-100"
            onClick={
              JSON.parse(invite.group.question) === null
                ? () => handleGroupAcceptDecline('Accept', invite?.id)
                : () => setQuestionsPopup(true)
            }
          />
        </div>
        <div className="apply-button">
          <CustomButton
            color="themeTertiary"
            variant="light"
            loading={context.busy.status}
            label="Reject"
            onClick={() => handleGroupAcceptDecline('Reject', invite?.id)}
            className="w-100"
          />
        </div>
      </div>
      <div>
        {questionsPopup && (
          <ModalPopup
            maxContentWidth={'570px'}
            open={false}
            onClose={() => setQuestionsPopup(false)}
            title={'Questions'}>
            {Object.values(JSON.parse(invite.group.question)).map(
              (ques, index) => (
                <Textarea
                  rows={4}
                  key={index + 1}
                  value={answers[index + 1]}
                  onChange={(e) => handleAnswerChange(ques, e.target.value)}
                  label={`${index + 1}: ${ques}`}
                  required
                />
              )
            )}
            <CustomButton
              disabled={Object.values(answers).includes('')}
              className="w-100"
              color="themeSecondary"
              label="Submit"
              onClick={() =>
                handleGroupAcceptDecline('Accept', invite?.id, answers)
              }
              loading={context.busy.status}
            />
          </ModalPopup>
        )}
      </div>
    </div>
  );
}
