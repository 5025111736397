import ProtectedRoute from 'components/protectedRoute';
import { Route, Routes, useParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { PostedJobs } from './PostedJobs';
import { Shortlisted } from './Shortlisted';

export const Career = (props) => {
  const { userProfile, enterpriseId } = props;
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!enterpriseId) {
      navigate(
        `/individual/profile/organization/enterprise/department/${id}/permissions`
      );
    }
  }, [navigate, id, enterpriseId]);
  return (
    <Routes>
      <Route
        element={
          <ProtectedRoute>
            <PostedJobs
              userProfile={userProfile}
              userType="Individual"
              enterpriseId={enterpriseId}
            />
          </ProtectedRoute>
        }
        path="postedJobs"
      />
      <Route
        element={
          <ProtectedRoute>
            <Shortlisted />
          </ProtectedRoute>
        }
        path="shortlisted"
      />
      <Route
        element={
          <ProtectedRoute>
            <PostedJobs
              userProfile={userProfile}
              userType="Individual"
              enterpriseId={enterpriseId}
            />
          </ProtectedRoute>
        }
        path="*"
      />
    </Routes>
  );
};
