import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loader, ModalPopup } from 'components';
import tokenService from 'services/token.service';
import {
  TASK_TOGGLE_LABEL,
  TASK_URGENCY_LIST,
} from 'pages/enterpriseDashboard/constants';
import { isValidTaskAttachmentFile } from 'pages/enterpriseDashboard/helper';
import { FilterRow } from './FilterRow';
import {
  CreateTask,
  DepartmentTaskFilter,
  EditTask,
  TaskListWrapper,
  ViewTaskPopUp,
} from './Tasks';
import {
  CREATE_DEPARTMENT_TASKS,
  FETCH_ALL_DEPARTMENT_LIST,
  FETCH_ALL_DEPARTMENT_TASKS,
  UPDATE_DEPARTMENT_TASKS,
  DELETE_TASKS,
  useAxiosWrapper,
} from 'services';

const DEFAULT_CREATE_TASK_STATE = {
  taskTitle: '',
  taskDescription: '',
  assignTo: {},
  urgency: {},
  taskAttachments: [],
  dueDate: '',
  isModalOpen: false,
};
const DEFAULT_EDIT_TASK_STATE = {
  id: '',
  taskTitle: '',
  taskDescription: '',
  assignedToDepartment: {},
  assignedByDepartment: {},
  urgency: {},
  links: [],
  attachments: [],
  dueDate: '',
  isModalOpen: false,
};
const DEFAULT_FILTER_STATE = {
  assignedByDepartment: {},
  status: {},
  taskUrgency: {},
  taskPriority: {},
  assignedToTeam: {},
  taskPostedDate: {
    from: null,
    to: null,
  },
};
export const PublishedProblems = (props) => {
  const { enterpriseId } = props;
  const [fnWrapper, context] = useAxiosWrapper();

  const [currentPage, setCurrentPage] = useState(1);
  const [taskType, setTaskType] = useState(TASK_TOGGLE_LABEL.MY_TASKS);
  const [taskList, setTaskList] = useState(null);
  const [departmentList, setDepartmentList] = useState([]);
  const [isTaskLoading, setIsTaskLoading] = useState(true);
  const [filter, setFilter] = useState(DEFAULT_FILTER_STATE);
  const [createTaskState, setCreateTaskState] = useState(
    DEFAULT_CREATE_TASK_STATE
  );
  const [editTaskState, setEditTaskState] = useState(DEFAULT_EDIT_TASK_STATE);
  const [isToggleSort, setIsToggleSort] = useState(false);
  const [activeTaskAction, setActiveTaskAction] = useState(null);
  const [filterStatus, setFilterStatus] = useState(null);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [viewTask, setViewTask] = useState({
    isDisplay: false,
    item: {},
  });
  let { departmentId } = useParams();
  let pageSize = 3;

  const onOpenTaskAction = (taskId) => {
    if (activeTaskAction !== taskId) {
      setActiveTaskAction(taskId);
    } else {
      setActiveTaskAction(null);
    }
  };

  const removeTask = async (taskId) => {
    const res = await fnWrapper({
      url: `${DELETE_TASKS}/${taskId}`,
      method: 'DELETE',
      type: 'REMOVE TASK',
    });

    if (res.data.statusCode === 200) {
      setActiveTaskAction(null);
      fetchAllTasksList(queryPayload);
    }
  };

  const onSelectTaskAction = (actionItem, taskItem) => {
    if (actionItem.id === 'EDIT') {
      setEditTaskState({
        ...editTaskState,
        isModalOpen: true,
        id: taskItem?.id,
        taskTitle: taskItem?.taskTitle,
        taskDescription: taskItem?.taskDescription,
        assignedToDepartment: taskItem?.assignedToDepartment,
        assignedByDepartment: taskItem?.assignedByDepartment,
        dueDate: taskItem?.dueDate,
        urgency: TASK_URGENCY_LIST.find(
          (item) => item.value === taskItem?.urgency
        ),
        links: taskItem?.attachments === null ? [] : taskItem?.attachments,
      });
      setActiveTaskAction(null);
    }
    if (actionItem.id === 'REMOVE') {
      removeTask(taskItem?.id);
    }
  };

  let queryPayload = {
    page: currentPage,
    size: pageSize,
    departmentId: departmentId,
    isFilterByTaskType: true,
    isPaginated: true,
    taskType: taskType === TASK_TOGGLE_LABEL.MY_TASKS ? 'myTask' : 'otherTask',
    sort: isToggleSort,
  };

  const fetchAllTasksList = useCallback(
    async (payload) => {
      const res = await fnWrapper({
        url: `${FETCH_ALL_DEPARTMENT_TASKS}`,
        method: 'GET',
        type: 'FETCH_ALL_DEPARTMENT_TASKS',
        params: payload,
      });

      if (res.data.statusCode === 200) {
        setTaskList(res.data.data);
        setIsTaskLoading(false);
      }
    },
    [fnWrapper]
  );

  const fetchDepartmentList = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_ALL_DEPARTMENT_LIST}/${enterpriseId}`,
      method: 'GET',
      type: 'FETCH_ALL_DEPARTMENT_LIST',
    });

    if (res.data.statusCode === 200) {
      setDepartmentList(res?.data?.data);
    }
  }, [enterpriseId, fnWrapper]);

  useEffect(() => {
    fetchAllTasksList(queryPayload);
    fetchDepartmentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onToggleHandler = async (value) => {
    setTaskType(value);
    setFilterStatus(null);
    setIsToggleSort(false);
    setFilter(DEFAULT_FILTER_STATE);
    queryPayload = {
      ...queryPayload,
      taskType: value === TASK_TOGGLE_LABEL.MY_TASKS ? 'myTask' : 'otherTask',
    };
    await fetchAllTasksList(queryPayload);
  };

  const onSortHandler = async () => {
    setIsToggleSort(!isToggleSort);
    queryPayload = {
      ...queryPayload,
      sort: !isToggleSort,
    };
    await fetchAllTasksList(queryPayload);
  };

  const onFilterChangeHandler = (field = '', value = null) => {
    if (field === 'fromDate' || field === 'toDate') {
      setFilter({
        ...filter,
        taskPostedDate: {
          ...filter.taskPostedDate,
          [field === 'fromDate' ? 'from' : 'to']: value,
        },
      });
    } else {
      setFilter({
        ...filter,
        [field]: value,
      });
    }
  };

  const onApplyFilterHandler = async (type) => {
    let DateString = (date) => {
      var DateTime = new Date(date);
      return DateTime.toLocaleDateString();
    };
    if (type === 'apply') {
      let query = {};

      if (Object.keys(filter.assignedByDepartment).length !== 0) {
        query = {
          ...query,
          assignedByDepartment: filter.assignedByDepartment.id,
        };
      }
      if (Object.keys(filter.status).length !== 0) {
        query = {
          ...query,
          status: filter.status.value,
        };
      }
      if (Object.keys(filter.taskPriority).length !== 0) {
        query = {
          ...query,
          priority: filter.taskPriority.value,
        };
      }
      if (Object.keys(filter.taskUrgency).length !== 0) {
        query = {
          ...query,
          urgency: filter.taskUrgency.value,
        };
      }
      if (
        filter.taskPostedDate.from !== null &&
        filter.taskPostedDate.to !== null
      ) {
        query = {
          ...query,
          taskPostedDate: {
            from: DateString(filter.taskPostedDate.from),
            to: DateString(filter.taskPostedDate.to),
          },
        };
      }
      let filterPayload = {
        ...queryPayload,
        query,
      };
      await fetchAllTasksList(filterPayload);
      setIsFilterOpen(false);
      // setCurrentPage(currentPage - 1);
    } else if (type === 'clear') {
      setFilter(DEFAULT_FILTER_STATE);
    } else {
      setIsFilterOpen(false);
    }
  };

  const onStatusFilterChangeHandler = async (status) => {
    setFilterStatus(status);
    if (status.value !== '0') {
      queryPayload = {
        ...queryPayload,
        query: {
          status: status.value,
        },
      };
    }
    await fetchAllTasksList(queryPayload);
  };

  const openCreateTaskModalHandler = async () => {
    setCreateTaskState({
      ...createTaskState,
      isModalOpen: true,
    });
  };

  const onCreateTaskChangeHandler = (field = '', value = null) => {
    if (field === 'taskAttachments') {
      Array.from(value).forEach((ele) => {
        if (isValidTaskAttachmentFile(ele)) {
          setCreateTaskState({
            ...createTaskState,
            taskAttachments: [
              ...createTaskState.taskAttachments,
              ...Array.from(value),
            ],
          });
        }
      });
    } else {
      setCreateTaskState({
        ...createTaskState,
        [field]: value,
      });
    }
  };

  const onEditTaskChangeHandler = (field = '', value = null) => {
    if (field === 'attachments') {
      Array.from(value).forEach((ele) => {
        if (isValidTaskAttachmentFile(ele)) {
          setEditTaskState({
            ...editTaskState,
            attachments: [...editTaskState.attachments, ...Array.from(value)],
          });
        }
      });
    } else {
      setEditTaskState({
        ...editTaskState,
        [field]: value,
      });
    }
  };

  const onRemoveAttachmentsHandler = (type, field, file) => {
    if (type === 'CREATE') {
      setCreateTaskState({
        ...createTaskState,
        [field]: createTaskState.taskAttachments.filter((e) => e !== file),
      });
    }
    if (type === 'EDIT') {
      setEditTaskState({
        ...editTaskState,
        [field]: editTaskState[field].filter((e) => e !== file),
      });
    }
  };

  const onViewTaskCloseHandler = async () => {
    setViewTask({ isDisplay: false, item: {} });
    await fetchAllTasksList(queryPayload);
  };

  const onCreateTaskHandler = async () => {
    const loggedInUser = await tokenService.getSession();
    var formData = new FormData();
    for (var i of createTaskState.taskAttachments) {
      formData.append('attachments', i);
    }
    formData.append('taskTitle', createTaskState.taskTitle);
    formData.append('taskDescription', createTaskState.taskDescription);
    formData.append('urgency', createTaskState.urgency.value);
    formData.append('assignedToDeptId', createTaskState.assignTo.id);
    formData.append('assignedByDeptId', departmentId);
    formData.append('createdByUserId', loggedInUser.id);
    formData.append('dueDate', createTaskState.dueDate);

    const res = await fnWrapper({
      url: `${CREATE_DEPARTMENT_TASKS}`,
      method: 'POST',
      type: 'CREATE_DEPARTMENT_TASKS',
      payload: formData,
    });

    if (res.data.statusCode === 200) {
      setFilterStatus(null);
      setIsToggleSort(false);
      setFilter(DEFAULT_FILTER_STATE);
      await fetchAllTasksList(queryPayload);
      setCreateTaskState(DEFAULT_CREATE_TASK_STATE);
    }
  };

  const onUpdateTaskHandler = async () => {
    var formData = new FormData();
    for (var i of editTaskState.attachments) {
      formData.append('attachments', i);
    }
    formData.append('links', editTaskState.links);
    formData.append('taskTitle', editTaskState.taskTitle);
    formData.append('taskDescription', editTaskState.taskDescription);
    formData.append('urgency', editTaskState.urgency.value);
    formData.append('assignedToDeptId', editTaskState.assignedToDepartment.id);
    formData.append('assignedByDeptId', departmentId);
    formData.append('taskId', editTaskState.id);
    formData.append('dueDate', createTaskState.dueDate);

    for (var j of editTaskState.links) {
      formData.append('links[]', j);
    }

    const res = await fnWrapper({
      url: `${UPDATE_DEPARTMENT_TASKS}`,
      method: 'PUT',
      type: 'UPDATE_DEPARTMENT_TASKS',
      payload: formData,
    });

    if (res.data.statusCode === 200) {
      setFilterStatus(null);
      setIsToggleSort(false);
      setFilter(DEFAULT_FILTER_STATE);
      await fetchAllTasksList(queryPayload);
      setEditTaskState(DEFAULT_EDIT_TASK_STATE);
    }
  };

  const onPageChangeHandler = async (page) => {
    setCurrentPage(page);
    let query = {
      ...queryPayload,
      page: page,
    };
    await fetchAllTasksList(query);
  };

  const onViewTaskHandler = (item) => {
    setViewTask({
      isDisplay: true,
      item: item,
    });
  };

  return (
    <>
      <FilterRow
        filterStatus={filterStatus}
        taskType={taskType}
        onToggleHandler={onToggleHandler}
        onOpenCreateTaskModal={openCreateTaskModalHandler}
        onTaskStatusChange={onStatusFilterChangeHandler}
        setIsFilterOpen={setIsFilterOpen}
        isFilterOpen={isFilterOpen}
        onSortHandler={onSortHandler}
        isToggleSort={isToggleSort}
        departmentId={departmentId}
      />
      {isFilterOpen && (
        <DepartmentTaskFilter
          filterTitle="Filter Task"
          state={filter}
          departmentList={departmentList}
          onApplyFilterHandler={onApplyFilterHandler}
          onFilterChangeHandler={onFilterChangeHandler}
        />
      )}
      {isTaskLoading ? (
        <Loader loading style={{ marginTop: '2rem' }} />
      ) : (
        <TaskListWrapper
          taskList={taskList}
          taskType={taskType}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChangeHandler={onPageChangeHandler}
          activeTaskAction={activeTaskAction}
          onOpenTaskAction={onOpenTaskAction}
          onSelectTaskAction={onSelectTaskAction}
          toggleSort={isToggleSort}
          onViewTaskHandler={onViewTaskHandler}
        />
      )}
      {viewTask.isDisplay && (
        <ViewTaskPopUp
          departmentList={departmentList}
          taskType={taskType}
          viewTask={viewTask}
          viewTaskCloseHandler={onViewTaskCloseHandler}
        />
      )}
      {createTaskState.isModalOpen && (
        <ModalPopup hasTitle={false} maxContentWidth="750px">
          <CreateTask
            state={createTaskState}
            departmentList={departmentList}
            onCreateTask={onCreateTaskHandler}
            onChange={onCreateTaskChangeHandler}
            onClose={() => setCreateTaskState(DEFAULT_CREATE_TASK_STATE)}
            isLoading={
              (context.busy.type === 'CREATE_DEPARTMENT_TASKS' ||
                context.busy.type === 'FETCH_ALL_DEPARTMENT_TASKS') &&
              context.busy.status
            }
            onRemoveAttachments={onRemoveAttachmentsHandler}
          />
        </ModalPopup>
      )}

      {editTaskState.isModalOpen && (
        <ModalPopup
          open={false}
          onClose={() => setEditTaskState(DEFAULT_EDIT_TASK_STATE)}
          hasTitle={false}>
          <EditTask
            ctx={context}
            departmentList={departmentList}
            onChange={onEditTaskChangeHandler}
            onClose={() => setEditTaskState(DEFAULT_EDIT_TASK_STATE)}
            onRemoveAttachments={onRemoveAttachmentsHandler}
            onUpdateTask={onUpdateTaskHandler}
            state={editTaskState}
          />
        </ModalPopup>
      )}
    </>
  );
};
