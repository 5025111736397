import { Route, Routes } from 'react-router-dom';
import { ViewProjectCollaboration } from './components/ViewProjectCollaboration';
import { ViewCollaborationApplicants } from './components/ViewCollaborationApplicants';

export const ProjectCollaboration = (props) => {
  return (
    <Routes>
      <Route path="" element={<ViewProjectCollaboration {...props} />} />
      <Route
        path=":type/applicants/:id"
        element={<ViewCollaborationApplicants {...props} />}
      />
    </Routes>
  );
};
