import styled from 'styled-components';
import { TaskCard } from 'pages/TeamDashboard/components';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader } from 'components';

const TasksListWrapper = styled.div`
  & .tasks-list {
    margin: 40px 0 60px 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    & .task-style {
      margin: 10px 2%;
    }
    @media (max-width: 600px) {
      display: block;
    }

    & .no-tasks {
      width: 75%;
      text-align: center;
      margin: 10% auto;
      font-weight: 500;
      color: #929daf;
    }
  }
`;

export const TasksList = ({ loading, data, taskType, setOpenedCard }) => {
  const navigate = useNavigate();
  const { teamId, userType } = useParams();

  const onBtnClickHandler = (cardId, title) => {
    setOpenedCard({ title: title, id: cardId });
    navigate(
      `/${userType}/team-dashboard/${teamId}/tasks/${taskType}/${cardId}/view`
    );
  };

  return (
    <TasksListWrapper>
      {loading ? (
        <Loader isLoading={loading} />
      ) : (
        <div className="tasks-list">
          {data.length === 0 ? (
            <div className="no-tasks">No Tasks assigned!</div>
          ) : (
            data.map((task, index) => (
              <div className="task-style" key={index}>
                <TaskCard
                  id={task?.id}
                  title={task?.title}
                  navigateItem={task?.navigateItem}
                  isImg={task?.isImg}
                  imgSrc={task?.profilePicture}
                  iconName={task?.iconName}
                  onBtnClickHandler={() =>
                    onBtnClickHandler(task?.id, task?.title)
                  }
                />
              </div>
            ))
          )}
        </div>
      )}
    </TasksListWrapper>
  );
};
