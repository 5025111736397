import styled from 'styled-components';

export const ConversationViewerWrapper = styled.div`
  position: relative;
  height: 100%;
  background: #fdfdfd;
  box-shadow: 2px -10px 15px 0px rgba(0, 0, 0, 0.05) inset;
  height: 80vh;
  padding: 20px 20px;
  flex-shrink: 0;
  position: relative;
  .chats-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #292d32;
    display: grid;
    place-content: center;
    @media (min-width: 992px) {
      display: none;
    }
  }

  .conversation-body {
    margin-bottom: 50px;
    .sending-message-loading {
      text-align: right;
      margin-right: 50px;
      font-size: 18px;
    }
  }
  .no-conversation-message {
    text-align: center;
    color: #7c8b9f;
  }

  .no-conversation-selected {
    text-align: center;
    color: #7c8b9f;
    display: flex;
    justify-content: space-between;
  }
  .empty-div-for-center {
    @media (max-width: 992px) {
      display: none;
    }
  }
  .no-conversation-heading {
    display: flex;
    align-items: center !important;
    margin: 0px !important;
  }

  .conversation-status {
    font-size: 12px;
    font-size: 14px;
    color: #777;
    transform: translateY(-5%);
    display: flex;
    justify-content: center;
    align-items: center;

    .red-dot {
      width: 8px;
      height: 8px;
      background-color: red;
      border-radius: 50%;
      margin-right: 10px;
    }
    .green-dot {
      width: 8px;
      height: 8px;
      background-color: #41d37e;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
  .chat-options {
    margin-top: 1rem;
  }
  .search-people-from-icon {
    @media (min-width: 992px) {
      display: none;
    }
  }
`;

export const SendMessagesInputWrapper = styled.div`
  background: #fff;
  box-shadow: 0px -4px 15px 0px rgba(0, 0, 0, 0.05);
  margin: 0;
  padding: 0;
  width: 100%;
  height: 88px;
  flex-shrink: 0;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  left: 0;
  right: 0;

  .send-message {
    display: flex;
    width: 100%;
    gap: 40px;

    .icon-section {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
    }

    .text-input-box {
      flex: 1;
      input {
        border-radius: 25px;
        background: #f4f7fc;
        width: 100%;
        height: 50px;
        flex-shrink: 0;
        border: none;
        font-size: 14px;
        padding: 0px 20px;
      }

      input:focus {
        outline: none;
        border: none;
      }
    }
  }
`;
