import styled from 'styled-components';

export const YearMonthPickerContainer = styled.div`
  & .label-style {
    font-weight: 700;
    font-size: 14px;
    color: #404f67;
    .error-text {
      color: rgb(255, 30, 38);
    }
  }
  & .wrapper {
    display: flex;
    align-items: center;
    column-gap: 8px;
    & .react-datepicker-popper {
      margin-top: -18px;
      & .react-datepicker__navigation {
        top: 14px;
        & .react-datepicker__navigation-icon::before {
          border-color: #17191c !important;
          border-width: 2px 2px 0 0 !important;
          height: 10px !important;
          top: 5px !important;
          width: 10px !important;
        }
      }
      & .react-datepicker {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color: #404f67;
        border: 1px solid #dddddd !important;

        & .react-datepicker__header {
          background-color: #f4f7fc !important;
          color: #17191c !important;
          font-family: 'Open Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 25px;
          padding: 16px 0 !important;
          border-bottom: unset !important;
        }
      }
    }
    & .react-datepicker-popper[data-placement^='top'] {
      & .react-datepicker__triangle::before {
        border-top-color: #dddddd !important;
      }
    }
    & .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected,
    .react-datepicker__year-text--selected {
      background-color: #0abd00 !important;
    }
    & .react-datepicker__year .react-datepicker__year-text {
      width: 5rem !important;
    }
  }
`;
