import './selectionButton.scss';
import Icon from '../../utlils/Icons/Icon';
import classname from 'classname';

export const SelectionButton = ({
  label,
  subLabel,
  onClick,
  iconRight,
  iconLeft = false,
  leftIcon = '',
  rightIcon = '',
  isImage = false,
  imgSrc,
  className,
}) => {
  return (
    <div
      className={classname('selection-btn-container', className)}
      onClick={onClick}>
      <div className="inner-container">
        {iconLeft && (
          <Icon className="mt-1" color="#021899" name={leftIcon} size={35} />
        )}
        {isImage && <img src={imgSrc} className="leftImg" alt="" />}
        <div className="label ">
          <div>{label}</div>
          {subLabel && <div className="sub_label">{subLabel}</div>}
        </div>
      </div>
      {iconRight && <Icon color="#929DAF" name={rightIcon} size={20} />}
    </div>
  );
};
