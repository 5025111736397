import Icon from 'utlils/Icons/Icon';
import { StaticHomeCard } from '../StaticHomeCard';
import { BorderCardWrapper } from './style';
import { Button } from 'components';
import { useNavigate } from 'react-router-dom';

export const BorderCard = ({ content, noSmMb = false }) => {
  const navigate = useNavigate();

  return (
    <BorderCardWrapper noSmMb={noSmMb}>
      <div className="container">
        <div className="row">
          <div className="wrapper">
            <div className="circle">
              <span>
                <Icon name={content.header_icon} color="#FFFFFF" size={30} />
              </span>
            </div>
            <div className="line"></div>
            <div className="circle-top"></div>
            <div className="btn-bottom">
              <Button
                label={'Know More'}
                rightIcon
                iconName="arrow-forward"
                size="lg"
                iconClass="icon-align"
                backgroundColor="#219FFF"
                onClick={() => navigate(content.action)}
              />
            </div>
            <p className="main-heading">{content?.heading}</p>
            <div className={'card-list'}>
              {content?.items &&
                content?.items?.map((item, index) => {
                  return (
                    <StaticHomeCard content={item} index={index} key={index} />
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </BorderCardWrapper>
  );
};
