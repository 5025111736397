import styled from 'styled-components';
export const EnterpriseTeamsWrapper = styled.div`
  & .sub-container {
    @media (max-width: 1199px) {
      margin: 25px 0;
    }
    & .main-wrapper {
      border-radius: 12px;
      padding-bottom: 28px;
      background-color: white;

      & .heading {
        padding: 28px;
        border-bottom: 1px solid #e9eef4;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        color: #17191d;
        & span {
          font-weight: 500;
          font-size: 20px;
          color: #7e8f9a;
        }
      }
      & .content {
        padding: 28px;
        & .cards-container {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 20px;

          @media (min-width: 900px) and (max-width: 1499px) {
            grid-template-columns: repeat(2, 1fr);
          }

          @media (max-width: 899px) {
            grid-template-columns: repeat(1, 1fr);
          }
        }
        & .no-data-class {
          width: 50%;
          margin: 50px auto;
          text-align: center;
          color: #404f67;
        }
      }
    }
  }
`;
