import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getPlaceholderItems } from '../../../../../utlils/utils';
import { teamsTableData, TEAMTABLEHEADERS } from '../helper';
import createInitialTeams from '../../../../../assests/images/createInitialTeams.png';
import {
  Box,
  CustomButton,
  CustomTable,
  Input,
  ScrollableDiv,
} from '../../../../../components';
import TeamsFilter from '../../../components/teamFilter';
import WithDrawTeams from './withDrawTeams';
import dayjs from 'dayjs';
import styled from 'styled-components';
import Icon from 'utlils/Icons/Icon';

const TeamListViewWrapper = styled.div`
  position: relative;
  & .header-buttons-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 40px;
    gap: 50px;

    & .left-buttons {
      width: 100%;
    }
    & .right-buttons {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 10px;

      @media (max-width: 767px) {
        grid-template-columns: 1fr;
      }
    }

    @media (max-width: 767px) {
      flex-direction: column;
      gap: 10px;
    }
    & .filter-arrow {
      margin-left: 5px;
      margin-top: 6px;
    }
  }
  & .team-button-container {
    display: flex;
    justify-content: center;
    & .team-buttons {
      margin-top: 30px;
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 25px;
      @media (max-width: 1200px) {
        width: 60%;
      }
      @media (max-width: 800px) {
        width: 70%;
      }
      @media (max-width: 576px) {
        flex-direction: column;
        justify-content: space-evenly;
        height: 20vh;
      }
    }
  }
`;

export const ViewTeams = ({ userProfile, isTeamListUpdate }) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const navigate = useNavigate();
  const [searchTeams, setSearchTeams] = useState('');
  const [teamsData, setTeamsData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRow, setSelectedRow] = useState([]);
  const [withDrawStep, setWithdrawStep] = useState('');
  const [isWithdrawPopupOpen, setWithdrawPopupOpen] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [applicationData, setApplicationData] = useState([
    {
      designation: 'Software engineer',
      company: 'Amazon',
    },
    {
      designation: 'Software developer',
      company: 'google',
    },
    {
      designation: 'UX designer',
      company: 'google',
    },
  ]);
  const [filteredTeams, setFilteredTeams] = useState({
    filteredTeamName: '',
    filteredDate: {},
    filteredRole: {},
    filteredStatus: {},
    fromDate: null,
    toDate: null,
  });

  const getTeamsByUser = useCallback(async () => {
    setIsLoading(true);
    const session = JSON.parse(localStorage.getItem('session'));
    try {
      await axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/getTeamsByUserId/${session.id}`,
          {
            params: {
              type: 'personal',
            },
            headers: {
              'x-access-token': `${session.access_token}`,
            },
          }
        )
        .then((res) => {
          if (res.data.statusCode === 200) {
            let newData = [];
            const filteredData = [];
            const uniqueIds = new Set();
            const teamList = res.data.data;

            // Filter : if user rejects more that one request then show only its latest request
            if (teamList.length > 0) {
              teamList.sort(
                (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
              );
              teamList.forEach((item) => {
                if (!uniqueIds.has(item.id)) {
                  uniqueIds.add(item.id);
                  filteredData.push(item);
                }
              });
            }
            if (filteredData.length) {
              newData = filteredData.map((item) => {
                item?.teamMembers.sort(
                  (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                );
                let currentUserData = item.teamMembers.find(
                  (ele) => ele.memberUserId === session.id
                );
                return {
                  id: item.id,
                  teamName: item.teamName,
                  teamMembers: item.teamMembers,
                  createdAt: item.createdAt,
                  designation: currentUserData.designation,
                  role: currentUserData.role,
                  status: currentUserData.status,
                  currentMemberId: currentUserData.id,
                };
              });
            } else {
              newData = [];
            }
            setTeamsData(newData);
            setFilteredData(newData);
            setIsLoading(false);
          }
        })
        .catch((err) => {
          setTeamsData([]);
          setIsLoading(false);
          toast.error(err, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        });
    } catch (error) {
      setTeamsData([]);
      setIsLoading(false);
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  }, []);

  const onRowCheckedHandler = (element) => {
    if (selectedRow.length) {
      const index = selectedRow.filter((ele) => ele.id === element.id);
      if (index.length) {
        const filteredItems = selectedRow.filter(
          (ele) => ele.id !== element.id
        );
        setSelectedRow(filteredItems);
      } else {
        setSelectedRow([...selectedRow, element]);
      }
    } else {
      setSelectedRow([...selectedRow, element]);
    }
  };

  const onAllRowCheckedHandler = (element) => {
    setSelectedRow(element);
  };
  const allTeamsData = isLoading
    ? getPlaceholderItems(
        TEAMTABLEHEADERS.map(({ sortKey }) => sortKey),
        4,
        isLoading
      )
    : teamsTableData(filteredData);

  const withDrawTeamsHandler = async () => {
    const session = JSON.parse(localStorage.getItem('session'));
    const memberIds = selectedRow.map((row) => row.currentMemberId);
    let data = {
      memberIds: memberIds,
    };
    try {
      await axios
        .put(`${process.env.REACT_APP_API_BASE_URL}/leaveTeam`, data, {
          headers: {
            'x-access-token': `${session.access_token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          if (res.data.statusCode === 200) {
            setWithdrawPopupOpen(false);
            getTeamsByUser();
            setSelectedRow([]);
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
          setWithdrawPopupOpen(false);
          setSelectedRow([]);
        });
    } catch (error) {
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      setWithdrawPopupOpen(false);
      setSelectedRow([]);
    }
  };
  const closeWithdrawPopup = () => {
    setSelectedRow([]);
    setWithdrawPopupOpen(false);
  };
  const openWithdrawPopup = () => {
    setWithdrawStep(1);
    setWithdrawPopupOpen(true);
  };

  const isFilterDisabled =
    !Object.keys(filteredTeams.filteredRole).length &&
    !Object.keys(filteredTeams.filteredStatus).length &&
    !Object.keys(filteredTeams.filteredDate).length &&
    !filteredTeams.filteredTeamName;

  const isLeft = selectedRow.filter((ele) => ele.currentMemberStatus === '2');

  const teamFilterOnChangeHandler = (field = '', value = null) => {
    setFilteredTeams({
      ...filteredTeams,
      [field]: value,
    });
  };

  const teamFilterSaveHandler = (type) => {
    if (type === 'apply' && teamsData.length !== 0) {
      setSearchTeams('');
      let result = teamsData;
      let filterByTeamName = (item) => {
        if (filteredTeams.filteredTeamName === '') {
          return item;
        } else {
          return item.filter(
            (ele) => ele.teamName === filteredTeams.filteredTeamName
          );
        }
      };
      let filterByRole = (item) => {
        if (Object.keys(filteredTeams.filteredRole).length === 0) {
          return item;
        } else {
          return item.filter(
            (ele) => ele.role === filteredTeams.filteredRole.value
          );
        }
      };
      let filterByStatus = (item) => {
        if (Object.keys(filteredTeams.filteredStatus).length === 0) {
          return item;
        } else {
          return item.filter(
            (ele) => ele.status === filteredTeams.filteredStatus.value
          );
        }
      };
      let filterByDate = (item) => {
        if (Object.keys(filteredTeams.filteredDate).length === 0) {
          return item;
        }
        if (filteredTeams.filteredDate.value === 'YD') {
          return item.filter(
            (ele) =>
              dayjs(ele.createdAt).format('DD MMM YYYY') ===
              dayjs(new Date()).subtract(1, 'day').format('DD MMM YYYY')
          );
        }
        if (filteredTeams.filteredDate.value === 'TD') {
          return item.filter(
            (ele) =>
              dayjs(ele.createdAt).format('DD MMM YYYY') ===
              dayjs(new Date()).format('DD MMM YYYY')
          );
        }
        if (filteredTeams.filteredDate.value === 'CD') {
          return item.filter(
            (ele) =>
              new Date(dayjs(ele.createdAt).format('DD MMM YYYY')) >=
                new Date(dayjs(filteredTeams.fromDate).format('DD MMM YYYY')) &&
              new Date(dayjs(ele.createdAt).format('DD MMM YYYY')) <=
                new Date(dayjs(filteredTeams.toDate).format('DD MMM YYYY'))
          );
        }
      };
      if (filteredTeams.filteredTeamName !== '') {
        result = filterByTeamName(result);
      }
      if (Object.keys(filteredTeams.filteredDate).length) {
        result = filterByDate(result);
      }
      if (Object.keys(filteredTeams.filteredRole).length) {
        result = filterByRole(result);
      }
      if (Object.keys(filteredTeams.filteredStatus).length) {
        result = filterByStatus(result);
      }
      setFilteredData(result);
      setIsFilterOpen(false);
    } else if (type === 'cancel') {
      setFilteredTeams({
        filteredTeamName: '',
        filteredDate: {},
        filteredRole: {},
        filteredStatus: {},
        fromDate: null,
        toDate: null,
      });
      setFilteredData(teamsData);
      setIsFilterOpen(false);
    }
  };

  const searchTeamByNameHandler = (value) => {
    setSearchTeams(value);
    let temp = [];
    if (value) {
      setIsFilterOpen(false);
      temp = teamsData.filter(
        (ele) => ele.teamName.toLowerCase().indexOf(value.toLowerCase()) > -1
      );
      setFilteredData(temp);
    } else {
      setFilteredData(teamsData);
    }
  };
  const closeSearchTeamByName = () => {
    setSearchTeams('');
    setFilteredData(teamsData);
  };
  useEffect(() => {
    getTeamsByUser();
  }, [getTeamsByUser]);

  useEffect(() => {
    if (isTeamListUpdate) {
      getTeamsByUser();
    }
  }, [isTeamListUpdate, getTeamsByUser]);

  return (
    <TeamListViewWrapper>
      <div className="header-buttons-wrapper">
        <div className="left-buttons">
          <Input
            iconLeft={searchTeams.length === 0}
            iconName="search"
            iconRight={searchTeams.length !== 0}
            iconRightName="cross"
            placeholder="Search Teams"
            value={searchTeams}
            customStyle={{ height: '53px' }}
            onChange={(e) => searchTeamByNameHandler(e.target.value)}
            iconRightClassName="close-search-team"
            iconRightOnClickHandler={closeSearchTeamByName}
          />
        </div>
        <div className="right-buttons">
          <CustomButton
            className="w-100"
            variant="light"
            color="themeSecondary"
            onClick={() =>
              navigate(`/individual/profile/personal/teams/create/information`)
            }>
            <Icon name="add" color="#219FFF" size={20} />
            New Team
          </CustomButton>
          <CustomButton
            className="w-100"
            variant="light"
            color="themeTertiary"
            disabled={selectedRow.length === 0 || isLeft.length > 0}
            onClick={openWithdrawPopup}>
            <Icon name="log-out" color="rgba(255, 30, 38, 0.5)" size={20} />
            Withdraw
          </CustomButton>
          {isWithdrawPopupOpen && (
            <WithDrawTeams
              isPopupOpen={isWithdrawPopupOpen}
              closePopupHandler={closeWithdrawPopup}
              withDrawStep={withDrawStep}
              withDrawTeamsHandler={withDrawTeamsHandler}
              selectedRows={selectedRow}
              setWithdrawStep={setWithdrawStep}
            />
          )}
          <CustomButton
            className="w-100"
            variant="light"
            color={isFilterOpen ? 'themeSecondary' : 'themeGray'}
            disabled={teamsData.length === 0 && isFilterDisabled}
            onClick={() => setIsFilterOpen(!isFilterOpen)}>
            <Icon
              name="filter"
              className="filter-arrow"
              color={isFilterOpen ? '#219FFF' : '#929DAF'}
              size={20}
            />
            Filter
          </CustomButton>
        </div>
      </div>
      {isFilterOpen && (
        <>
          <TeamsFilter
            filterTitle="Filter Teams"
            state={filteredTeams}
            teamFilterSaveHandler={teamFilterSaveHandler}
            teamFilterOnChangeHandler={teamFilterOnChangeHandler}
          />
        </>
      )}

      {teamsData.length === 0 ? (
        <>
          <Box
            mt="50px"
            display="flex"
            justifyContent="center"
            alignItems="center">
            <img src={createInitialTeams} alt="" width="320px" />
          </Box>
          <Box
            mt="30px"
            display="flex"
            textAlign="center"
            justifyContent="center">
            <Box width="45%">
              <span>
                Oh, looks like you don’t have any Team. Create your team or
                explore new team now!
              </span>
            </Box>
          </Box>
          <div className="team-button-container">
            <div className="team-buttons">
              <CustomButton
                label="Create Team"
                onClick={() =>
                  navigate(
                    `/individual/profile/personal/teams/create/information`
                  )
                }
              />
              <CustomButton
                label="Explore Teams"
                variant="light"
                onClick={() => navigate(`/explore/teams/search-team`)}
              />
            </div>
          </div>
        </>
      ) : (
        <Box mt="22px">
          <ScrollableDiv scrollOnHeight="378px">
            {searchTeams !== '' && filteredData.length === 0 ? (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                color="#929DAF">
                <p>
                  Oh, looks like you don’t have any Team's name starting by
                  <strong>{` ${searchTeams}.`}</strong>
                </p>
              </Box>
            ) : (
              <CustomTable
                isLoading={isLoading}
                rows={allTeamsData}
                headers={TEAMTABLEHEADERS}
                hasCheckbox={true}
                selectedRow={selectedRow}
                rowCheckedHandler={(val) => onRowCheckedHandler(val)}
                allRowCheckedHandler={(val) => onAllRowCheckedHandler(val)}
              />
            )}
          </ScrollableDiv>
        </Box>
      )}
    </TeamListViewWrapper>
  );
};
