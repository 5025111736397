import { createContext, useReducer } from 'react';
import { CreateGroupReducer } from './createGroupReducer';

export const initialState = {
  groupName: '',
  description: '',
  category: '',
  groupType: '',
  questions: [{ question: '' }],
  groupProfilePicture: null,
};
export const CreateGroupContext = createContext({
  createGroupState: initialState,
  createGroupDispatch: () => null,
});

export const CreateGroupProvider = ({ children }) => {
  const [createGroupState, createGroupDispatch] = useReducer(
    CreateGroupReducer,
    initialState
  );
  return (
    <CreateGroupContext.Provider
      value={{ createGroupState, createGroupDispatch }}>
      {children}
    </CreateGroupContext.Provider>
  );
};
