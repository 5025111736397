import { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classname';
import Icon from 'utlils/Icons/Icon';
import { PriceInputStyle } from './style';
import { pricePropTypes, currencyOptions, getCurrencySymbol } from './helper';
import { ClickOutside } from 'components/ClickOutside';

export const PriceInput = ({
  className = '',
  value,
  onPriceChange,
  step = 2,
  selectedCurrency,
  onBlur,
  minValue = 100,
  disabled = false,
  ...rest
}) => {
  const [localCurrency, setLocalCurrency] = useState('USD');
  const [isDropDown, setIsDropDown] = useState(false);
  const currency = selectedCurrency ? selectedCurrency : localCurrency;
  return (
    <ClickOutside
      onClickOutside={() => setIsDropDown(false)}
      active={isDropDown}>
      <PriceInputStyle
        className={cn('priceInput-wrapper', className, disabled && 'disabled')}
        disabled={disabled}
        isDropDownShow={disabled ? false : isDropDown}>
        <div className="priceInput-wrapper__currency">
          <span>{getCurrencySymbol(currency)}</span>
          <Icon
            name="arrow-down-three"
            color="white"
            size={10}
            className={cn(
              'priceInput-wrapper__currency__dropDownIcon',
              className,
              disabled && 'disabled'
            )}
            onClick={() => setIsDropDown(!isDropDown)}
          />
        </div>
        <div className="priceInput-wrapper__inputBox">
          <input
            type="number"
            className="priceInput-wrapper__inputBox__input"
            value={value?.toString()}
            min={minValue}
            step={step}
            onBlur={onBlur}
            disabled={disabled}
            onChange={(e) =>
              onPriceChange({
                value: e.target.value ? parseFloat(e.target.value) : null,
                currency: currency,
              })
            }
            onFocus={() => setIsDropDown(false)}
          />
        </div>
        <div className="priceInput-wrapper__options">
          {currencyOptions
            .filter((item) => item.value !== currency)
            .map((item) => (
              <div
                key={item.id}
                className="priceInput-wrapper__options__item"
                onClick={() => {
                  setIsDropDown(false);
                  selectedCurrency
                    ? onPriceChange({
                        value: value ? parseFloat(value) : null,
                        currency: item.value,
                      })
                    : setLocalCurrency(item.value);
                  onPriceChange({
                    value: value ? parseFloat(value) : null,
                    currency: item.value,
                  });
                }}>
                {item.currency}
              </div>
            ))}
        </div>
      </PriceInputStyle>
    </ClickOutside>
  );
};

PriceInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number,
  onPriceChange: PropTypes.func.isRequired,
  onCurrencyChange: PropTypes.func,
  step: PropTypes.number,
  selectedCurrency: PropTypes.oneOf(pricePropTypes),
};
