import { useState } from 'react';
import cn from 'classname';
import { checkForSpacesInStartEnd, isValidEmail } from '../../helpers';
import Icon from '../../utlils/Icons/Icon';
import './input.scss';

export const Input = ({
  label = '',
  placeholder,
  required = false,
  type,
  value = '',
  onBlur,
  onChange,
  onClick,
  iconLeft,
  iconName,
  disabled = false,
  hasError = false,
  showErrorBorderOnly = false,
  errorMessage,
  customStyle,
  iconRight,
  iconRightName,
  iconRightClassName,
  iconRightOnClickHandler,
  sm,
  autoFocus,
  rightText,
  isSkipDefaultError = false,
  marginBottom = '12px',
  iconColor = '#CCD2DD',
  iconRightSize = '20px',
  iconLeftSize = '25px',
  handleKeyDown,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [inputType, setInputType] = useState(type);
  const [showError, setShowError] = useState(false);

  const togglePassword = () => {
    showPassword ? setInputType('password') : setInputType('text');
    setShowPassword(!showPassword);
  };

  const onBlurHandler = (e) => {
    // Run custom logic passed to input on blur
    typeof onBlur === 'function' && onBlur(e);
    // Show error to trigger error evaluation logic
    setShowError(true);
  };

  /**
   * @author Kiran Ranjan Bihari <kiran@brenolabs.com>
   * Initialize is_error only when showError is true
   * To avoid showing on error when component mounts
   * And show only after focus blurs from the input
   * If we need extra logic, we can restructure this component later💯
   */

  const isErrors =
    // check if dynamic error state passed from parent component
    (showError && hasError) ||
    // if not then proceed with internal error logic💪
    (showError && !isSkipDefaultError
      ? type === 'email'
        ? required && !isValidEmail(value)
        : required &&
          (value === '' || value === null || checkForSpacesInStartEnd(value))
      : false);

  return (
    <div
      className="custom-input"
      style={{ marginBottom: marginBottom, ...customStyle }}>
      {label !== '' && (
        <label className="form-label label-style">
          {label}
          <span style={{ color: '#FF1E26' }}>{`${required ? '*' : ''}`}</span>
        </label>
      )}
      <div
        className={cn(
          'input-container',
          sm ? 'sm' : 'lg',
          (isErrors || showErrorBorderOnly) && 'error-input',
          disabled && 'disabled'
        )}
        style={customStyle}>
        {iconLeft && (
          <Icon color={iconColor} name={iconName} size={iconLeftSize} />
        )}
        <input
          type={inputType}
          value={value}
          placeholder={placeholder}
          onClick={onClick}
          onChange={onChange}
          onBlur={onBlurHandler}
          disabled={disabled}
          autoFocus={autoFocus}
          onKeyDown={handleKeyDown}
        />
        {iconRight && (
          <Icon
            color={iconColor}
            name={iconRightName}
            size={iconRightSize}
            onClick={iconRightOnClickHandler}
            className={iconRightClassName}
          />
        )}
        {type === 'password' && (
          <Icon
            color={iconColor}
            name={showPassword ? 'eye-slash' : 'eye'}
            onClick={togglePassword}
            size={25}
            style={{ cursor: 'pointer' }}
          />
        )}
        {rightText ? rightText : ''}
      </div>
      {/* Error code section */}
      {((required && value === '' && showError && !isSkipDefaultError) ||
        isErrors) &&
        type !== 'email' && (
          <small className="has-error">
            {errorMessage
              ? errorMessage
              : checkForSpacesInStartEnd(value)
              ? 'The value should not have leading or trailing whitespace. Please remove any extra spaces.'
              : label && typeof label === 'string'
              ? `Please enter ${label.toLowerCase()}`
              : 'Please enter a valid value'}
          </small>
        )}
      {required && type === 'email' && isErrors && (
        <small className="has-error">
          {errorMessage ? errorMessage : `Please enter valid email`}
        </small>
      )}
    </div>
  );
};
