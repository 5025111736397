import Icon from 'utlils/Icons/Icon';
import { useCallback, useEffect, useState } from 'react';
import { ViewBasicDetailsWrapper } from './style';
import { useParams, useNavigate } from 'react-router-dom';
import { FETCH_BASIC_DETAILS, useAxiosWrapper } from 'services';
import { Loader } from 'components';
import Edit from '../../../../assests/images/edit.png';
import { PUBLIC_PRIVATE_TOGGLE_LABEL } from 'pages/IndividualPublic/constants';

export const ViewBasicDetails = ({
  taskType,
  refreshBasicDetails,
  isContactInfoEnabled,
}) => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [fnWrapper, context] = useAxiosWrapper();
  const [basicDetailsData, setBasicDetailsData] = useState(null);

  const getBasicDetails = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_BASIC_DETAILS}/${userId}`,
      method: 'GET',
      type: 'FETCH_BASIC_DETAILS',
    });

    if (res.data.statusCode === 200) {
      const result = res.data.data;
      const name = result.name.trim().split(' ');
      const detailsData = {
        'First Name':
          name.length === 1
            ? name[0]
            : name.filter((n, idx) => idx !== name.length - 1).join(' '),
        'Last Name': name.length === 1 ? '' : name[name.length - 1],
        Email: result.email,
        Location:
          result?.country === null
            ? 'N/A'
            : `${result?.city}, ${result?.state}, ${result?.country}`,
        Languages: result.languages === null ? 'N/A' : result.languages,
        'Linkteamsup Link': !result.linkTeamsUpLink
          ? 'N/A'
          : result.linkTeamsUpLink,
        Link: result.link.length === 0 ? 'N/A' : result.link,
        'About Me': result.about === null ? 'N/A' : result.about,
      };
      setBasicDetailsData(detailsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshBasicDetails]);

  useEffect(() => {
    getBasicDetails();
  }, [getBasicDetails]);

  return (
    <ViewBasicDetailsWrapper>
      <div className="heading-class">
        <div>
          <Icon name="user" color="#17191d" size={26} className="icon-class" />
          <span>Basic Details</span>
        </div>
        {taskType === 'Private' && (
          <div
            className="edit-icon-class"
            onClick={() =>
              navigate(`/individual/profile/edit/basic_details`, {
                state: {
                  from: 'public_page',
                  redirectTo: `/public/individual/${userId}/summary/basic-details`,
                },
              })
            }>
            <img src={Edit} alt="" />
          </div>
        )}
      </div>
      {context.busy.status ? (
        <Loader isLoading={context.busy.status} />
      ) : (
        <>
          <div className="content-wrapper">
            {basicDetailsData !== null &&
              Object.entries(basicDetailsData)
                .filter((item) => item[0] !== 'About Me' && item[0] !== 'Link')
                .map((data, index) => {
                  if (data[0] === 'Email') {
                    if (
                      taskType === PUBLIC_PRIVATE_TOGGLE_LABEL.PRIVATE ||
                      isContactInfoEnabled
                    ) {
                      return (
                        <div className="content-class" key={index}>
                          <p className="content-title">{data[0]}</p>
                          <div className="content-value">{data[1]}</div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  } else {
                    return (
                      <div className="content-class" key={index}>
                        <p className="content-title">{data[0]}</p>
                        <div className="content-value">{data[1]}</div>
                      </div>
                    );
                  }
                })}
          </div>
          {basicDetailsData !== null && (
            <>
              {typeof basicDetailsData['Link'] === 'string' ? (
                <div className="content-class">
                  <p className="content-title">Link</p>
                  <div className="content-value">
                    {basicDetailsData['Link']}
                  </div>
                </div>
              ) : (
                basicDetailsData['Link'].map((item, index) => (
                  <div className="content-class" key={index}>
                    <p className="content-title">Link {index + 1} </p>
                    <a
                      className="content-value"
                      href={item}
                      target="_blank"
                      rel="noreferrer">
                      {item}
                    </a>
                  </div>
                ))
              )}
              <div className="content-class">
                <p className="content-title">About Me</p>
                <div className="content-value">
                  {basicDetailsData['About Me']}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </ViewBasicDetailsWrapper>
  );
};
