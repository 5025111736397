export const TASK_MANAGEMENT_NAVBAR_LIST = (type, teamId) => {
  return [
    {
      id: 'home',
      title: 'Home',
      url:
        type === 'enterprise'
          ? '/enterprise/departments'
          : '/individual/profile/info/basic_details',
      isDropdown: false,
    },
    {
      id: 'dashboard',
      title: 'Go To Dashboard',
      url: `/${type}/team-dashboard/${teamId}/members`,
      isDropdown: false,
    },
  ];
};

export const BOARD_FIELDS = [
  {
    id: 'BACK_LOG',
    value: 'BACK_LOG',
    title: 'BACKLOG',
    isActive: true,
  },
  {
    id: 'TO_DO',
    value: 'TO_DO',
    title: 'TO DO',
    isActive: true,
  },
  {
    id: 'IN_PROGRESS',
    value: 'IN_PROGRESS',
    title: 'IN PROGRESS',
    isActive: true,
  },
  {
    id: 'COMPLETED',
    value: 'COMPLETED',
    title: 'COMPLETED',
    isActive: true,
  },
];

export const TASK_TYPE_FILTER_ITEMS = [
  {
    id: 'PROJECT_TASK',
    title: 'Project task',
  },
  {
    id: 'DEPARTMENT_TASK',
    title: 'Department task',
  },
];
