import './index.scss';

export const BreadCrumb = ({ crumbs, seperator, itemOnClickHandler }) => {
  const isLastIndex = (index) => {
    return index === crumbs.length - 1;
  };
  let items = crumbs.map(({ id, url, label }, index) => {
    return !isLastIndex(index) ? (
      <div
        className="item"
        key={label}
        onClick={() => itemOnClickHandler(url, index)}>
        <span>{label}</span>
      </div>
    ) : (
      <div className="item-last" key={label}>
        <span>{label}</span>
      </div>
    );
  });

  items = items.reduce((acc, child, index) => {
    if (!isLastIndex(index)) {
      acc.push(
        child,
        <div key={index} className="seperator">
          {seperator}
        </div>
      );
    } else {
      acc.push(child);
    }
    return acc;
  }, []);

  return <div className="breadCrumb-wrapper">{items}</div>;
};
