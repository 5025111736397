import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classname';
import PropTypes from 'prop-types';
import { SidebarWrapper } from './style.js';
import Icon from '../../utlils/Icons/Icon';
import { SubMenu } from './subMenu.js';

export const Sidebar = ({
  sidebarData,
  activeType,
  setActiveType,
  accessiblePermissions = [],
  ...rest
}) => {
  const navigate = useNavigate();
  const [activeSidebarMenuId, setActiveSidebarMenuId] = useState('');

  const currentLocation = window.location.href;
  const selectedMenu = sidebarData.find((menu) =>
    currentLocation.includes(menu.type)
  );

  useEffect(() => {
    if (activeType === '' || activeType === null) {
      setActiveType(selectedMenu?.type);
      if (selectedMenu?.hasSubMenu) {
        setActiveSidebarMenuId(selectedMenu?.type);
      }
    }
  }, [activeType, selectedMenu?.hasSubMenu, selectedMenu?.type, setActiveType]);

  /* --- 
  This side bar is designed with the assumption that all sidebar objects should have a 'type' key 
  as mandatory and 'url' key is not mandatory,
  all sub data of sidebar main data should not have any `type` key,and only url key should be passed
   ---*/

  return (
    <SidebarWrapper className="sidebar">
      <div className="sidebar__wrapper">
        {sidebarData.length >= 1 &&
          sidebarData
            .filter((item) =>
              item?.permission
                ? accessiblePermissions.includes(item?.permission)
                : true
            )
            .map((data, index) => (
              <div
                key={`${data.type}${index}`}
                className="sidebar__wrapper__container">
                <div
                  onClick={() => {
                    setActiveType(data?.type);
                    if (!data.hasSubMenu) {
                      setActiveSidebarMenuId('');
                      if (data?.url) {
                        navigate(data?.url);
                      }
                    } else {
                      setActiveSidebarMenuId(data?.type);
                    }
                  }}
                  className={cn(
                    'sidebar__wrapper__container__button d-flex align-items-center gap-2',
                    `${
                      activeType === data?.type
                        ? 'activeButton'
                        : 'disActiveButton'
                    }`
                  )}>
                  <Icon
                    name={data?.iconName}
                    color={activeType === data?.type ? '#17191D' : '#404F67'}
                  />
                  <div>{data?.title}</div>
                  {data.hasSubMenu && (
                    <Icon
                      color="#404F67"
                      name={
                        activeSidebarMenuId === data?.type
                          ? 'arrow-drop-up'
                          : 'arrow-down'
                      }
                      size={23}
                      className="icon-dropdown"
                    />
                  )}
                </div>

                {activeSidebarMenuId === data?.type && data.hasSubMenu && (
                  <SubMenu
                    subMenuData={data.elements}
                    accessiblePermissions={accessiblePermissions}
                  />
                )}
              </div>
            ))}
      </div>
    </SidebarWrapper>
  );
};

Sidebar.propTypes = {
  sidebarData: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      iconName: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      url: PropTypes.string,
      hasSubMenu: PropTypes.bool,
      elements: PropTypes.array,
    })
  ).isRequired,
  setActiveType: PropTypes.func.isRequired,
  activeType: PropTypes.string,
};
