import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import {
  FETCH_USER_PROFILE,
  FETCH_TEAM_DETAILS_BASED_ON_TEAM_ID,
  useAxiosWrapper,
  GET_RBAC_FOR_TEAM,
} from 'services';
import TokenService from 'services/token.service';
import { getProfileText } from '../../utlils/utils';
import { TeamMembers } from './TeamMembers';
import { TeamProjects } from './TeamProjects';
import { TeamRequests } from './TeamRequests';
import { TeamMessaging } from './TeamMessaging';
import { TeamPublications } from './TeamPublications';
import { TeamTasks } from './TeamTasks';
import { TeamSettings } from './TeamSettings';
import { Loader, NavbarBrand1, Sidebar } from 'components';
import { getLeftSideData, TEAM_DASHBOARD_NAV_LIST } from './constants';
import { TeamCareer } from './TeamCareer';
import { TeamDashboardWrp } from './styles';
import { TeamProfileCard } from './components';
import { RoleBasedAccessContext } from 'context/RoleBasedAccess/roleBasedAccessContext';
import { setPersonalTeamRBAC } from 'context/RoleBasedAccess/roleBasedAccessAction';
import { IsPermitted, userScopes } from 'utlils';
import ProtectedRoute from 'components/protectedRoute';
import Icon from 'utlils/Icons/Icon';

export const TeamDashboard = ({ currentLocale, handleLocaleChange }) => {
  const [fnWrapper] = useAxiosWrapper();
  const [userProfile, setUserProfile] = useState({});
  const [teamData, setTeamData] = useState({});
  const [loading, setLoading] = useState(true);
  const loggedInUser = useMemo(() => TokenService.getSession(), []);

  const { roleBasedAccessState, roleBasedAccessDispatch } = useContext(
    RoleBasedAccessContext
  );

  const teamPermissions = roleBasedAccessState.personalTeamRBAC;

  const { teamId, userType } = useParams();
  const [leftActiveItem, setLeftActiveItem] = useState('');

  const setLeftSidebar = (type) => {
    setLeftActiveItem(type);
  };

  const getUserProfile = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_USER_PROFILE}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_USER_PROFILE',
    });
    if (res.data.statusCode === 200) {
      const response = res.data.data;
      setUserProfile({
        ...response,
        profileText: getProfileText(
          response?.role === 'INDIVIDUAL'
            ? response?.name
            : response?.enterpriseName
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTeamDetails = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_TEAM_DETAILS_BASED_ON_TEAM_ID}/${teamId}`,
      method: 'GET',
      type: 'FETCH_TEAM_DETAILS_BASED_ON_TEAM_ID',
    });
    if (res.data.statusCode === 200) {
      const teamDetails = {
        teamId: res?.data?.data?.id,
        teamName: res?.data?.data?.teamName,
        profilePicture: res?.data?.data?.profilePicture,
        enterpriseId: res?.data?.data?.enterpriseId,
        profileVisibleInExploreTeam:
          res?.data?.data?.profileVisibleInExploreTeam,
        acceptLinkRequest: res?.data?.data?.acceptLinkRequest,
        teamCategory: res?.data?.data?.teamCategory,
        status: res?.data?.data?.status,
      };
      const teamRbacRes = await fnWrapper({
        url: `${GET_RBAC_FOR_TEAM}`,
        method: 'GET',
        type: 'GET_RBAC_FOR_TEAM',
        params: {
          teamId,
          userId: loggedInUser.id,
        },
      });
      if (teamRbacRes.data.statusCode === 200) {
        await setPersonalTeamRBAC(
          roleBasedAccessDispatch,
          teamRbacRes.data.data
        );
      }
      setTeamData(teamDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUser.id, roleBasedAccessDispatch, teamId]);

  const getTeamData = useCallback(async () => {
    setLoading(true);
    await getUserProfile();
    await getTeamDetails();
    setLoading(false);
  }, [getTeamDetails, getUserProfile]);

  useEffect(() => {
    getTeamData();
  }, [getTeamData]);

  const componentsArray = [
    {
      pathName: 'members',
      permissions: [userScopes.ACCESS_MEMBER_PAGE],
      components: <TeamMembers userProfile={userProfile} />,
    },
    {
      pathName: 'projects',
      permissions: [userScopes.ACCESS_PROJECT_PAGE],
      components: <TeamProjects />,
    },
    {
      pathName: 'tasks',
      permissions: [userScopes.ACCESS_CAREER_PAGE],
      components: <TeamTasks />,
    },
    {
      pathName: 'requests',
      permissions: [userScopes.ACCESS_REQUEST_PAGE],
      components: <TeamRequests />,
    },
    {
      pathName: 'messaging',
      permissions: [userScopes.ACCESS_MEMBER_PAGE],
      components: (
        <TeamMessaging userProfile={userProfile} teamInfo={teamData} />
      ),
    },
    {
      pathName: 'career',
      permissions: [userScopes.ACCESS_CAREER_PAGE],
      components: <TeamCareer userProfile={userProfile} teamInfo={teamData} />,
    },
    {
      pathName: 'publications',
      permissions: [userScopes.ACCESS_PUBLICATION_PAGE],
      components: <TeamPublications teamDetails={teamData} />,
    },
    {
      pathName: 'teamSettings',
      permissions: [userScopes.ACCESS_TEAM_SETTINGS],
      components: (
        <TeamSettings teamInfo={teamData} setTeamData={setTeamData} />
      ),
    },
  ];

  const renderComponents = () => {
    return componentsArray.map((item) => (
      <Route
        element={
          <ProtectedRoute
            allowedPermissions={item.permissions}
            key={item.pathName}>
            <IsPermitted permissions={item.permissions}>
              {item.components}
            </IsPermitted>
          </ProtectedRoute>
        }
        path={`${item.pathName}/*`}
        key={item.pathName}
      />
    ));
  };

  return loading ? (
    <Loader loading={loading} />
  ) : (
    <>
      <NavbarBrand1
        navMenu={TEAM_DASHBOARD_NAV_LIST(userType)}
        currentLocale={currentLocale}
        handleLocaleChange={handleLocaleChange}
        userProfile={userProfile}
      />
      <TeamDashboardWrp>
        <div className="team-dashboard-container">
          <div className="layout-container">
            <div className="layout-sub-container">
              <div className="sub-container-child">
                <TeamProfileCard
                  teamInfo={teamData}
                  userProfile={userProfile}
                />
                <div style={{ marginTop: '1.5rem' }}>
                  <Sidebar
                    accessiblePermissions={[...teamPermissions]}
                    sidebarData={getLeftSideData(userType, teamId)}
                    activeType={leftActiveItem}
                    setActiveType={setLeftSidebar}
                  />
                </div>
              </div>
              <div className="sub-container-child">
                <div className="main-wrapper">
                  {teamData?.status === 'PENDING' &&
                    teamData?.teamCategory === 'ENTERPRISE' && (
                      <div className="superadmin-approval-pending">
                        <Icon name="info-circle" color="#af814e" size={20} />
                        Please note that team access requires approval from
                        either a 'super admin' or an 'admin' before it can be
                        activated for use.
                      </div>
                    )}
                  <Routes>
                    <Route path="">{renderComponents()}</Route>
                  </Routes>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TeamDashboardWrp>
    </>
  );
};
