import styled from 'styled-components';

export const ViewBasicDetailsWrapper = styled.div`
  padding-left: 15px;
  & .heading-class {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin: 30px 0;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 34px;
    color: #17191d;

    & .icon-class {
      margin-right: 10px;
    }

    & .edit-icon-class {
      cursor: pointer;
    }
  }

  & .content-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
    align-items: flex-start;

    @media (max-width: 999px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 699px) {
      display: block;
    }
  }
  .content-class {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 20px;

    & .content-title {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      color: #404f67;
    }

    & .content-value {
      width: 100%;
      min-height: 56px;
      text-align: left;
      padding: 16px;
      background: #e9eef4;
      border-radius: 10px;
      word-wrap: break-word;
    }
  }
`;
