import styled from 'styled-components';

export const YourNetworkWrapperStyle = styled.div`
  .yourNetwork-list {
    &__totalPeople {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      border-bottom: 1px solid #929daf;
      padding-bottom: 18px;
      color: #17191d;
      .count {
        color: #7e8f9a;
      }
    }
    &__table {
      margin-top: 40px;
      &__row {
        padding: 25px 0;
        border-bottom: 1px solid #929daf;
        &:last-child {
          border: none;
        }
        .connection_modal_wrp__ {
          &text {
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
            color: #404f67;
          }
          &btn-wrp {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 30px;
          }
        }
      }
      &__footer--button {
        display: flex;
        align-items: center;
        gap: 10px;
        > div {
          width: 160px;
        }
        @media (max-width: 992px) {
          margin-top: 30px;
          width: 100%;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          > div {
            width: 40%;
          }
        }
        @media (max-width: 600px) {
          > div {
            width: 60%;
          }
        }
      }
    }
  }
`;
