import { useContext, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import RouterComponent from './routes';
import { LOCALES } from './assests/languages/constant';
import 'react-toastify/dist/ReactToastify.css';
import { LanguageWrapper } from './components';
import { RoleBasedAccessProvider } from 'context/RoleBasedAccess/roleBasedAccessContext';
import tokenService from 'services/token.service';
import { NotificationToaster } from 'firebaseConfig/notificationToaster';
import { onMessageListener } from 'firebaseConfig';
import { updateNewNotifications } from 'context/Notifications/notificationAction';
import { NotificationDispatchContext } from 'context/Notifications/notificationContext';

const DEFAULT_NOTIFICATION_STATE = {
  title: '',
  body: '',
  isShow: false,
};

const App = () => {
  const loggedInUserSession = tokenService.getSession();
  const [notification, setNotification] = useState(DEFAULT_NOTIFICATION_STATE);
  const notificationDispatch = useContext(NotificationDispatchContext);

  const getInitialLocal = () => {
    const savedLocale = localStorage.getItem('locale');
    return savedLocale || 'ENGLISH';
  };
  const [currentLocale, setCurrentLocale] = useState(getInitialLocal());
  const handleLocaleChange = (value) => {
    setCurrentLocale(value);
    localStorage.setItem('locale', value);
  };

  onMessageListener()
    .then(async (payload) => {
      // eslint-disable-next-line no-console
      console.log('payload firebase/notification:   ', payload);
      await updateNewNotifications(
        notificationDispatch,
        JSON.parse(payload?.data?.notificationData)
      );
      setNotification({
        title: payload?.notification?.title,
        body: payload?.notification?.body,
        isShow: true,
      });
    })
    // eslint-disable-next-line no-console
    .catch((err) => console.error('failed: ', err));

  const onCloseNotification = () => {
    setNotification(DEFAULT_NOTIFICATION_STATE);
  };

  return (
    // <ConversationInstanceProvider>
    <RoleBasedAccessProvider>
      <LanguageWrapper currentLocale={LOCALES[currentLocale]}>
        <div className="App">
          <ToastContainer />
          {loggedInUserSession && notification.isShow ? (
            <div className="d-none">
              {NotificationToaster({
                notification: notification,
                onClose: onCloseNotification,
              })}
            </div>
          ) : null}
          <RouterComponent
            currentLocale={currentLocale}
            handleLocaleChange={handleLocaleChange}
          />
        </div>
      </LanguageWrapper>
    </RoleBasedAccessProvider>
    // </ConversationInstanceProvider>
  );
};

export default App;
