import { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FETCH_PROJECT_TASKS, useAxiosWrapper } from 'services';
import { LinkedCard } from 'pages/ProjectPublic/Components';
import { getDateTime } from 'utlils/utils';
import { useNavigate } from 'react-router-dom';

export const TeamPublicProjects = () => {
  const [fnWrapper] = useAxiosWrapper();
  const [teamProjectsData, setTeamProjectsData] = useState([]);
  const { teamId } = useParams();
  const navigate = useNavigate();

  const getProjectsList = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_PROJECT_TASKS}/${teamId}`,
      method: 'GET',
      type: 'FETCH_PROJECTS_LIST_BASED_ON_TEAM',
    });

    if (res.data.statusCode === 200) {
      const projectsList = [];
      res.data.data[0]?.projects?.map((item) => {
        const newProjectData = {
          id: item.id,
          projectName: item.title,
          imgSrc: item.profilePicture,
          profileIcon: 'project',
          connectedTo:
            item.enterprise !== null
              ? item.enterprise?.enterpriseName
              : item.user?.individual?.name,

          type: 'project',
          isPublished: item.projectStatus === 'PUBLISHED',
          projectFeature: [
            {
              iconName: 'people',
              label: `${item.teams?.length} Teams`,
            },
            {
              iconName: 'clock',
              label: getDateTime(item.TeamProject?.updatedAt),
            },
          ],
          problemStatement: item.problemStatement,
        };
        item.projectSettings !== null &&
          newProjectData.projectFeature.push({
            iconName: 'shield-security',
            label:
              item.projectSettings?.accessibility === true
                ? 'Public'
                : 'Private',
          });
        item.projectSettings !== null &&
          item.projectSettings?.accessibility === true &&
          newProjectData.projectFeature.push({
            iconName: 'tag-user',
            label:
              item.projectSettings?.isBidding === true &&
              item.projectSettings?.isCollaboration === true
                ? 'Open for Collab & Bid'
                : item.projectSettings?.isBidding
                ? 'Open for Bid'
                : 'Open for Collab',
          });
        projectsList.push(newProjectData);
        return projectsList;
      });
      setTeamProjectsData(projectsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getProjectsList();
  }, [getProjectsList]);

  return (
    <div className="main-wrapper">
      <div className="heading">
        Projects <span>({teamProjectsData.length})</span>
      </div>
      {teamProjectsData.length !== 0 ? (
        <div className="projects-list">
          {teamProjectsData.map((item, index) => (
            <div className="team-card-class" key={index}>
              <LinkedCard
                title={item.projectName}
                profileImgSrc={item.imgSrc}
                profileIcon={item.profileIcon}
                type={item.type}
                owner={item.connectedTo}
                features={item.projectFeature}
                description={item.problemStatement}
                isPublished={item.isPublished}
                viewHandler={() =>
                  navigate(`/public/project/${item.id}/details`)
                }
              />
            </div>
          ))}
        </div>
      ) : (
        <div className="no-data-class">No Linked Project Found!</div>
      )}
    </div>
  );
};
