import { React, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ModalPopup, ScrollableDiv } from 'components';
import { progressData } from '../../../constants';
import './createTeamModal.scss';
import { Finish } from './finish';
import { Information } from './information';
import { InviteTeamMembers } from './inviteTeamMembers';
import { toast } from 'react-toastify';
import Loader from 'react-spinners/FadeLoader';
import axios from 'axios';
import ProgressBar from 'pages/IndividualDashboard/components/progressBar';

export const CreateTeamModal = ({
  createTeamModal,
  setCreateTeamModal,
  onCloseHandler,
}) => {
  let { departmentId } = useParams();
  const session = JSON.parse(localStorage.getItem('session'));

  const DEFAULT_CREATE_TEAM_STATE = {
    teamName: '',
    topic: '',
    description: '',
    teamSize: {},
    teamType: {},
    departmentId: departmentId,
    teamMembers: [],
    teamCategory: 'Enterprise',
    progressStep: 1,
    isLoading: false,
  };

  const [createTeamState, setCreateTeamState] = useState(
    DEFAULT_CREATE_TEAM_STATE
  );

  const createTeamStateHandler = (field = '', value = null) => {
    setCreateTeamState({
      ...createTeamState,
      [field]: value,
    });
  };

  const saveButtonHandler = async (teamOwnerDetails) => {
    //Check for duplicates
    let hasDuplicate = false;
    const emailSet = new Set();
    createTeamState.teamMembers.forEach((member) => {
      if (emailSet.has(member?.email)) {
        hasDuplicate = true;
        return; // Exit the loop early if duplicates are found
      }
      emailSet.add(member?.email);
    });

    if (hasDuplicate) {
      toast.error('Please remove duplicate members from the list', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      return;
    }

    createTeamState.teamMembers.push(teamOwnerDetails);
    setCreateTeamState({
      ...createTeamState,
      isLoading: true,
    });
    let teamMembers = createTeamState.teamMembers.reduce((newArray, ele) => {
      if (ele.email !== session.email) {
        let newEle = {
          memberName: ele.memberName,
          designation: ele.designation,
          role: ele.role.value,
          email: ele.email,
          invitedByUserId: ele.invitedByUserId,
        };
        newArray.push(newEle);
      } else if (ele.email !== '' && ele.memberName !== '') {
        newArray.push(ele);
      }
      return newArray;
    }, []);
    let data = {
      userId: session.id,
      teamName: createTeamState.teamName,
      topic: createTeamState.topic,
      description: createTeamState.description,
      teamSizeId: createTeamState.teamSize.id,
      teamTypeId: createTeamState.teamType.id,
      teamCategory: 'Enterprise',
      teamMembers: teamMembers,
      departmentId: departmentId,
    };
    try {
      await axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/createTeam`, data, {
          headers: {
            'x-access-token': `${session.access_token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then(async (res) => {
          if (res.data.statusCode === 200) {
            setCreateTeamState({
              ...createTeamState,
              progressStep: 3,
              isLoading: false,
            });
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        });
    } catch (error) {
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };
  const onClickProgressHandler = (step) => {
    setCreateTeamState({
      ...createTeamState,
      progressStep: step,
    });
  };

  return (
    <div className="create-team-modal-wrapper">
      {createTeamModal.isModalOpen && (
        <ModalPopup
          open={false}
          title="Create Team"
          onClose={onCloseHandler}
          hasTitle
          hasCloseIcon
          minWidth="650px"
          maxContentWidth="650px">
          <ScrollableDiv
            scrollOnHeight="550px"
            scrollOnHeightMob="calc(100vh - 110px)">
            <div className="progress-bar-wrap">
              <ProgressBar
                step={createTeamState.progressStep}
                progressData={progressData}
                onClickProgressHandler={onClickProgressHandler}
                isRoute={false}
              />
              {createTeamState.progressStep === 1 && (
                <Information
                  createTeamState={createTeamState}
                  createTeamStateHandler={createTeamStateHandler}
                  onCloseHandler={onCloseHandler}
                />
              )}
              {createTeamState.progressStep === 2 && (
                <InviteTeamMembers
                  createTeamState={createTeamState}
                  createTeamStateHandler={createTeamStateHandler}
                  setCreateTeamState={setCreateTeamState}
                  saveButtonHandler={saveButtonHandler}
                />
              )}
              {createTeamState.isLoading ? (
                <Loader loading={createTeamState.isLoading} />
              ) : (
                createTeamState.progressStep === 3 && (
                  <Finish setCreateTeamModal={setCreateTeamModal} />
                )
              )}
            </div>
          </ScrollableDiv>
        </ModalPopup>
      )}
    </div>
  );
};
