import styled from 'styled-components';

export const ViewColabWrapper = styled.div`
  & .view-colab-container {
    & .table-style {
      margin-top: 30px;
    }
    &__header {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: #17191d;
    }
    &__add-btn {
      height: 36px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      border-radius: 6px;
      padding-left: 12px;
      padding-right: 12px;
    }
    & .no-colab {
      width: 75%;
      text-align: center;
      margin: 8% auto;
      font-weight: 500;
      color: #929daf;
    }
    & .nav-img {
      cursor: pointer;
    }

    &__dropdwn-content {
      gap: 10px;
    }
    .edit-bidding {
      color: #7e8f9a;
      cursor: pointer;
      &:hover {
        color: #0abd00;
      }
    }
  }
  .publish_wrp {
    & .sub-heading {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #404f67;
    }

    & .radio-btn-label {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      color: #404f67;
    }
    & .post-attachment {
      margin-bottom: 30px;

      & .img-wrapper {
        display: flex;
        width: 100%;
        word-break: break-all;
        .disable-content {
          pointer-events: none;
          cursor: not-allowed;
        }

        & span {
          color: #929daf;
        }

        & .img-container {
          width: 30px;
          height: 30px;
          margin-top: 10px;
        }

        & .img-text {
          margin-top: 10px;
          text-decoration: underline;
        }

        & .remove-icon {
          padding-left: 2px;
          cursor: pointer;
          align-self: center;
        }
      }

      @media (max-width: 767px) {
        grid-template-columns: 1fr;
        gap: 5px;
      }
    }
    & .mandatory-field {
      text-align: right;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      color: #7e8f9a;
      margin-bottom: 20px;
      text-decoration: underline;
    }

    & .popup-button {
      margin: 0px auto;
      align-items: center;
      width: 150px;
      margin-top: 50px;
    }
    .question-wrapper {
      height: 100%;
      &__title {
        font-weight: 700;
        font-size: 25px;
        &__underline {
          border: 1px solid #e9eef4;
        }
      }
      &__form {
        margin-top: 30px;
        &__applyWith {
          font-weight: 600;
          font-size: 15px;
          color: #404f67;
          &__checkBox {
            .checkbox-title {
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              color: #404f67;
            }
          }
        }
        &__askQuestion {
          margin-top: 10px;
          span {
            font-weight: 600;
            font-size: 15px;
            color: #404f67;
          }
          &__icon {
            margin-bottom: 44px;
            cursor: pointer;
          }
        }
        &__recommendation {
          font-weight: 600;
          font-size: 15px;
          color: #404f67;
          &__radioButton {
            font-family: 'Poppins';
            .radio-buttons {
              display: flex;
              justify-content: start;
              gap: 60px;
              .radio-label {
                font-weight: 400;
                font-size: 16px;
                color: #404f67;
              }
            }
          }
        }
      }
      &__button {
        margin-top: 20px;
        width: 200px;
      }
      &__no--project {
        padding: 10px;
        width: 100%;
        text-align: center;
        color: #929daf;
      }
    }
    .bidding-header {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #404f67;
    }
    .bidding-header-fw {
      font-weight: 500;
    }
    .radio-payment {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 12px;
      margin-top: 0;
    }
    .icon-mw {
      margin-left: 10px;
    }
    .bidding-date {
      background: #f8f8f8;
      height: 52px;
      border-radius: 12px;
      border: 2px solid #e9eef4;
      padding: 17px;
      width: 200px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #7e8f9a;
    }
    .publish-checkbox {
      .textarea-wrapper {
        .label-style {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 20px;
          margin-top: 20px;
        }
      }
    }
    .pb-textarea {
      .dd-wrapper__list {
        width: 200px;
      }
    }
    .input-content {
      .custom-input {
        height: 86px;
      }
    }
    .mb-text {
      margin-top: 30px;
    }
  }
  .is-job-open {
    margin-left: 20px;
  }
  .no-colaboration-found {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #404f67;
  }
  .collaboration-success-image {
    margin: 25px auto;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
      width: 45%;
    }
  }
  .collaboration-popup-button-text {
    display: grid;
    justify-items: center;
    gap: 24px;
    padding: 8px;
    text-align: center;
    word-wrap: break-word;

    & a {
      color: #219fff;
      text-decoration: underline;
      font-style: italic;
    }
  }

  .edit-wrapper {
    &__confirm-content {
      gap: 20px;
      margin-top: 60px;
    }
    &__text {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 30px;
      color: #404f67;
    }
    &__yes-btn {
      width: 100px;
    }
  }
`;

export const EditCollaborationWrapper = styled.div`
  .edit-jobpost {
    color: #7e8f9a;
    cursor: pointer;
    &:hover {
      color: #0abd00;
    }
  }
`;

export const ViewApplicationWrapper = styled.div`
  .copy-link {
    margin-right: 10px;
    padding: 6px;
  }
  .no-records {
    width: 75%;
    text-align: center;
    margin: 10% auto;
    font-weight: 500;
    color: #929daf;
  }
  .view-btn {
    text-decoration: underline;
    color: #0abd00;
    display: flex;
    justify-content: center;
    &-font {
      font-size: 14px;
    }
  }
  .file-not-uploaded {
    pointer-events: none;
    color: #7e8f9a;
    margin-top: 10px;
  }
  .applicants-container__header {
    & .heading-back-btn {
      padding-right: 10px;
      &:hover {
        cursor: pointer;
      }
    }
    & .heading-text {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      color: $gray_1;
    }
  }
  .applicants-wrapper {
    & .question_wrapper {
      margin-bottom: 15px;
    }
    & .sub-heading {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #404f67;
    }
    & .button-content {
      display: flex;
      justify-content: center;
      gap: 20px;
      margin-top: 50px;
      align-items: center;
    }
    & .reject-btn {
      width: 190px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }
    & .approve-btn {
      width: 190px;
      border-radius: 10px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }

    & .bid-item {
      background: #f4f7fc;
      border-radius: 10px;
      width: 220px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: left;
      padding-left: 20px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: rgba(64, 79, 103, 0.8);
      margin-bottom: 25px;
      margin-top: 20px;
    }
    & .post-attachment {
      margin-bottom: 30px;

      & .img-wrapper {
        display: flex;
        width: 100%;
        word-break: break-all;

        & span {
          color: #929daf;
        }

        & .img-container {
          width: 30px;
          height: 30px;
          margin-top: 10px;
        }

        & .img-text {
          margin-top: 10px;
          text-decoration: underline;
        }

        & .remove-icon {
          padding-left: 2px;
          cursor: pointer;
          align-self: center;
        }
        & .icon-content {
          display: flex;
          align-items: center;
          margin-left: 25px;
        }
      }
    }
  }
`;
