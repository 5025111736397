import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import Information from './information';
import { Box, CustomButton } from 'components';
import Icon from 'utlils/Icons/Icon';
import SuccessStep from './SuccessStep';
import GroupProfilePicture from './GroupProfilePicture';
import GroupQuestions from './GroupQuestions';
import { GROUP_DETAILS, useAxiosWrapper } from 'services';
import { CreateGroupContext } from 'context/Groups/CreateGroup/createGroupContext';
import { setGroupInfoOnUpdate } from 'context/Groups/CreateGroup/createGroupAction';

export default function AddGroup() {
  const { createGroupDispatch } = useContext(CreateGroupContext);
  const params = useParams();
  const [fnWrapper] = useAxiosWrapper();
  const location = useLocation();
  const [groupId, setGroupId] = useState(location.state?.id);
  const navigate = useNavigate();
  const getGroupDetails = useCallback(async (id) => {
    const res = await fnWrapper({
      url: `${GROUP_DETAILS}/${id}`,
      method: 'GET',
      type: 'GROUP_DETAILS',
    });
    if (res?.data?.statusCode === 200) {
      setGroupId(res.data.data.id);
      if (res.data.data.question !== 'null') {
        let questions = Object.values(JSON.parse(res.data.data.question)).map(
          (value) => ({ question: value })
        );
        res.data.data.question = questions;
        setGroupInfoOnUpdate(createGroupDispatch, res.data.data);
      } else {
        res.data.data.question = [{ question: '' }];
        setGroupInfoOnUpdate(createGroupDispatch, res.data.data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (params.action === 'update') {
      getGroupDetails(groupId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Box bg="white" className="main-wrapper">
        <div className="header-text">
          <p className="heading">
            {params.action === 'update'
              ? 'Update your group'
              : 'Create a Group'}
          </p>
        </div>
        <div>
          <CustomButton
            color="themeGray"
            variant="textBtn"
            onClick={() =>
              navigate(
                params.action === 'update'
                  ? `/individual/profile/groups/${groupId}/view/description`
                  : '/individual/profile/groups'
              )
            }>
            <Icon name="arrow-back" size={18} color="#404f67" />
            Back to group
          </CustomButton>
        </div>
        <Routes>
          <Route path="information" element={<Information />} />
          <Route path="profile-picture" element={<GroupProfilePicture />} />
          <Route
            path="group-questions"
            element={<GroupQuestions groupId={groupId} />}
          />
          <Route path="success" element={<SuccessStep />} />
        </Routes>
      </Box>
    </div>
  );
}
