import styled from 'styled-components';
import Icon from 'utlils/Icons/Icon';
import { ReactComponent as ThreeDotsNew } from 'assests/images/three-dots-2.svg';
import CommonDropDown from 'pages/enterpriseDashboard/components/commonDropDown';
import { CustomButton } from 'components';
import { useState } from 'react';
import { TaskContentWrapper } from './style';
import { useNavigate, useParams } from 'react-router-dom';
import { TASK_CARD_ACTION_OPTIONS } from 'pages/TeamDashboard/helper';
import { getDateTime } from 'utlils/utils';
import { IsPermitted, userScopes } from 'utlils';

const TaskDropDownContentWrapper = styled.div`
  margin: 0 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 2px solid #e9eef4;
  border-right: 2px solid #e9eef4;
  border-left: 2px solid #e9eef4;
  border-radius: 0px 0px 10px 10px;
  .header {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #404f67;
    margin: 20px 0;
  }

  .task-detail {
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #929daf;
    margin-bottom: 20px;
  }
`;

export const TaskCardContent = ({
  isImg,
  imgSrc,
  userName,
  taskType,
  postedTime,
  assignedFor,
  taskTitle,
  taskDescription,
  taskId,
  isActive,
  onSelectTask,
  popUpHandler,
  isTaskAssigned,
  isCompleted,
  completedBy,
  optionsHandler,
}) => {
  const [expandTask, setExpandTask] = useState(false);

  return (
    <>
      <TaskContentWrapper
        expandTask={expandTask}
        isCompleted={isCompleted}
        isTaskAssigned={isTaskAssigned}>
        <div className="task-wrapper">
          <div className="tasks-header">
            <div className="left-side-class">
              <div className="profile-pic-container">
                {!isImg ? (
                  <div className="pfp-alt-circle">
                    <Icon
                      name={'user-icon'}
                      className="user-icon-class"
                      color="#292D32"
                    />
                  </div>
                ) : (
                  <img alt="" src={imgSrc} width={'100%'} />
                )}
              </div>
              <div className="user-name">
                <p className="user-name-style">
                  <span className="admin-style">{userName}</span> assigned your
                  team a {taskType === 'project-tasks' && 'task under '}
                  <span
                    className="stage-style"
                    style={{ color: `${assignedFor.textColor}` }}>
                    {assignedFor.label}
                  </span>
                  {taskType === 'project-tasks' ? ' stage.' : ' task.'}
                </p>
                <span className="user-posted-time">
                  {getDateTime(postedTime)}
                </span>
              </div>
            </div>

            <div className="right-side-class">
              {isCompleted ? (
                <div className="completed-class">
                  <span>Completed by</span>
                  <div className="user-class">
                    {completedBy && completedBy.length !== 0 ? (
                      <img alt="" src={completedBy} width={'100%'} />
                    ) : (
                      <Icon
                        name={'user-icon'}
                        className="user-icon-class"
                        color="red"
                      />
                    )}
                  </div>
                </div>
              ) : isTaskAssigned ? (
                <div
                  className="assigned-class"
                  onClick={() => popUpHandler(taskId, true)}>
                  <span>Task Assigned</span>
                  <div className="user-class">
                    <Icon
                      name={'user-icon'}
                      className="user-icon-class"
                      color="#292D32"
                    />
                  </div>
                </div>
              ) : (
                <IsPermitted
                  permissions={[userScopes.ASSIGN_TASK_TO_TEAM_MEMBER]}>
                  <CustomButton
                    variant="outline"
                    label="Assign to Member"
                    className="assign-button"
                    onClick={() => popUpHandler(taskId, true)}
                  />
                </IsPermitted>
              )}
              <div className="rightmost-class">
                <Icon
                  name={expandTask ? 'arrow-circle-up' : 'arrow-circle-down'}
                  color="#404F67"
                  style={{ cursor: 'pointer' }}
                  size="36"
                  onClick={() => {
                    setExpandTask(!expandTask);
                  }}
                />
                <IsPermitted
                  permissions={[userScopes.ASSIGN_TASK_TO_TEAM_MEMBER]}>
                  <div style={{ position: 'relative' }}>
                    <div
                      className="options-class"
                      onClick={
                        isCompleted || !isTaskAssigned ? null : onSelectTask
                      }>
                      <ThreeDotsNew
                        color={isActive ? '#219fff' : '#c4c4c4'}
                        alt=""
                      />
                    </div>
                    <CommonDropDown
                      dropItem={TASK_CARD_ACTION_OPTIONS}
                      active={isActive}
                      onClick={(data) => {
                        optionsHandler(data, taskId, taskType);
                      }}
                      objectKey="label"
                      top="50px"
                      right="-35px"
                    />
                  </div>
                </IsPermitted>
              </div>
            </div>
          </div>
        </div>
      </TaskContentWrapper>
      {expandTask && (
        <TaskContentDropDown
          taskId={taskId}
          taskTitle={taskTitle}
          taskDescription={taskDescription}
          taskType={taskType}
        />
      )}
    </>
  );
};

const TaskContentDropDown = ({
  taskId,
  taskTitle,
  taskDescription,
  taskType,
}) => {
  const navigate = useNavigate();
  const { teamId, cardId, userType } = useParams();
  return (
    <TaskDropDownContentWrapper>
      <div className="header">{taskTitle}</div>
      <div className="task-detail">{taskDescription}</div>
      {taskType === 'project-tasks' && (
        <CustomButton
          color="themeSecondary"
          variant="textBtn"
          onClick={() =>
            navigate(
              `/${userType}/team-dashboard/${teamId}/projects/${cardId}/view/streams`
            )
          }>
          Go to Post
          <Icon name="arrow-forward" color="#219FFF" size={18} />
        </CustomButton>
      )}
    </TaskDropDownContentWrapper>
  );
};
