import styled from 'styled-components';
import { isMobile } from '../../../../../../../utlils/isMobile';

export const TaskCardWrapper = styled.div`
  display: ${isMobile() ? 'flex' : 'grid'};
  align-items: center;
  border: 2px solid #e9eef4;
  width: 100%;
  height: auto;
  border-radius: 14px;
  margin-top: 12px;
  & .task-card__ {
    &header-wrapper {
      border-bottom: 2px solid #e9eef4;
      width: 100%;
      height: auto;
      padding: 20px;
      display: grid;
      grid-template-columns: repeat(2, 1fr) 1.5fr 2fr 1fr;
      align-items: center;
      grid-column-gap: 20px;
      font-family: 'Poppins', 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #929daf;
      &__menu {
        text-align: end;
        cursor: pointer;
      }
      &__icon-with-label {
        align-items: center;
        display: grid;
        grid-template-columns: 0.2fr 2fr;
        column-gap: 10px;
      }
    }
    &mobile-header-wrapper {
      border-bottom: 2px solid #e9eef4;
      padding: 20px;
      display: grid;
      align-items: center;
      row-gap: 8px;
      font-family: 'Poppins', 'Open Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #929daf;
      &__row {
        &--1 {
          display: grid;
          align-items: center;
          column-gap: 8px;
          grid-template-columns: repeat(2, 1fr) 0.4fr;
          & .dropdown-wrapper {
            &:before {
              left: unset;
              right: 21px;
            }
          }
        }
        &--2 {
          display: grid;
          align-items: center;
          column-gap: 8px;
          grid-template-columns: 1fr 0.7fr;
        }
      }
      &__menu {
        text-align: end;
        cursor: pointer;
      }
      &__icon-with-label {
        align-items: center;
        display: grid;
        grid-template-columns: 0.2fr 2fr;
        column-gap: 10px;
      }
    }
    &content-wrapper {
      padding: 20px;

      &__header {
        font-family: 'Poppins', 'Open Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: #17191d;
        margin-bottom: 14px;
      }
      &__description {
        font-family: 'Poppins', 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #929daf;
        margin-bottom: 18px;
        &__attachments {
          display: flex;
          & a {
            padding: 5px;
          }
        }
        &__priority {
          padding-top: 10px;
          padding-bottom: 10px;
        }
      }

      &__footer {
        margin: auto 0;

        &--action-wrapper {
          display: grid;
          grid-template-columns: ${isMobile() ? '1fr 1fr' : ''};
          width: ${isMobile() ? '100%' : ''};
          grid-column-gap: 15px;
          gap: 10px;
          @media (max-width: 400px) {
            grid-template-columns: repeat(1, 1fr);
          }
        }
      }
    }
  }
`;
