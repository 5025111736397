import { CustomButton, ModalPopup } from 'components';

const RemoveMember = ({ onClose, handleRemoveMember }) => {
  return (
    <ModalPopup
      open={false}
      onClose={onClose}
      maxContentWidth="600px"
      title="Remove member">
      <div>
        <div>Are you sure you want to remove this member?</div>
        <div
          style={{
            marginTop: '50px',
            display: 'flex',
            justifyContent: 'center',
            gap: '20px',
          }}>
          <CustomButton label={'No'} variant="textBtn" onClick={onClose} />
          <CustomButton
            customPadding="16px 38px"
            label={'Yes'}
            onClick={handleRemoveMember}
          />
        </div>
      </div>
    </ModalPopup>
  );
};

export default RemoveMember;
