import { SlidingButton } from 'components';
import Icon from 'utlils/Icons/Icon';
import { PrivateTeamProjectCardWrapper } from './style';

export const PrivateTeamProjectCard = ({
  isImg,
  imgSrc = '',
  profileIcon,
  title,
  subTitle,
  type,
  typeIcon,
  cardType,
  isItemHidden,
  slidingButtonHandler,
}) => {
  return (
    <PrivateTeamProjectCardWrapper>
      <div className="content-header-class">
        {isImg ? (
          <div className="profile-pic-class">
            <img src={imgSrc} alt="" />
          </div>
        ) : (
          <div className="icon-style-class">
            <Icon name={profileIcon} size={24} color="#404f67" />
          </div>
        )}
        <div className="card-heading">
          <div className="title-class">{title}</div>
          <div className="sub-title-wrapper">
            <div className="sub-title-class">
              <Icon name="people" color="#404F67" size={22} />
              <span>
                {subTitle}
                {cardType === 'project' ? ' Teams' : ' Members'}
              </span>
            </div>
            <div className="sub-title-class">
              <Icon name={typeIcon} color="#404F67" size={22} />
              <span>{type}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="toggle-button">
        <SlidingButton
          isSliding={isItemHidden}
          onClick={slidingButtonHandler}
        />
      </div>
    </PrivateTeamProjectCardWrapper>
  );
};
