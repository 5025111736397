import { useContext, useEffect } from 'react';
import { Box, CustomButton } from '../../../../../components';
import TeamsCreated from '../../../../../assests/images/teamCreated.png';
import { setProgressStep } from '../../../../../context/Teams/CreateTeam/createTeamAction';
import { CreateTeamContext } from '../../../../../context/Teams/CreateTeam/createTeamContext';
import { useNavigate, useParams } from 'react-router-dom';
import Icon from 'utlils/Icons/Icon';

export const SuccessStep = ({ teamType, isEnterpriseTeam }) => {
  let navigate = useNavigate();
  const { id } = useParams();
  const { createTeamState, createTeamDispatch } = useContext(CreateTeamContext);

  const handleDone = () => {
    if (isEnterpriseTeam) {
      navigate(
        `/individual/profile/organization/enterprise/department/${id}/teams`
      );
    } else {
      navigate(`/individual/profile/personal/teams`);
    }
  };

  useEffect(() => {
    if (createTeamState.progressStep === 1) {
      if (isEnterpriseTeam) {
        navigate(
          `/individual/profile/organization/enterprise/department/${id}/teams`
        );
      } else {
        navigate(`/individual/profile/personal/teams`);
      }
    } else {
      setProgressStep(createTeamDispatch, 4);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Box mt="50px" display="flex" justifyContent="center">
        <div className="teams-created">
          <img src={TeamsCreated} alt="" width="100%" />
        </div>
      </Box>
      <Box textAlign="center" mt="30px" display="flex" justifyContent="center">
        <div className="teams-created-message">
          <span className="teams-created-title">
            {!isEnterpriseTeam
              ? `Congrats, you have successfully created your Team.`
              : `Team needs to be approved`}
          </span>
          <br />
          <span className="teams-created-subtitle">
            {!isEnterpriseTeam
              ? ` Team joining request is sent to the fellow members`
              : ` Team joining request will sent to the fellow members after approval`}
          </span>
        </div>
      </Box>
      <Box mt="50px" display="flex" justifyContent="center">
        <CustomButton
          variant="textBtn"
          customPadding="0 15px"
          color="themeSecondary"
          onClick={handleDone}>
          Go to Teams
          <Icon name="arrow-forward" color="#219FFF" size={20} />
        </CustomButton>
      </Box>
    </>
  );
};
