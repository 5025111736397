import { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, CustomButton, Title } from 'components';
import { EducationFieldItems } from './educationFieldItems';
import AddButton from '../../../components/addButton';
import {
  CREATE_EDUCATIONAL_DETAILS,
  FETCH_EDUCATIONAL_DETAILS,
  useAxiosWrapper,
} from 'services';
import TokenService from 'services/token.service';
import Icon from 'utlils/Icons/Icon';
import { checkForSpacesInStartEnd } from 'helpers';

const DEFAULTSTATE = [
  {
    itemId: '',
    instituteName: '',
    degree: {},
    course: '',
    location: '',
    isPersue: false,
    from: { month: new Date(), year: new Date() },
    to: { month: new Date(), year: new Date() },
  },
];

const DEGREE_TYPE = [
  {
    id: 1,
    label: 'Matriculation',
    value: 'Matriculation',
  },
  {
    id: 2,
    label: 'Intermediate',
    value: 'Intermediate',
  },
  {
    id: 3,
    label: 'Bachelor’s',
    value: 'Bachelors',
  },
  {
    id: 4,
    label: 'Master’s',
    value: 'Masters',
  },
  {
    id: 5,
    label: 'Certificate',
    value: 'Certificate',
  },
  {
    id: 6,
    label: 'Diploma',
    value: 'Diploma',
  },
  {
    id: 7,
    label: 'Apprenticeship',
    value: 'Apprenticeship',
  },
  {
    id: 8,
    label: 'Others',
    value: 'Others',
  },
];

export const CreateEducationalDetails = ({
  title,
  setCurrType,
  nextType,
  isEditEvent,
  type,
}) => {
  let navigate = useNavigate();
  const [fnWrapper, context] = useAxiosWrapper();
  const [state, setState] = useState(DEFAULTSTATE);
  const location = useLocation();

  const addNewItemRowHandler = () => {
    setState([
      ...state,
      {
        instituteName: '',
        degree: {},
        course: '',
        location: '',
        isPersue: false,
        from: { month: new Date(), year: new Date() },
        to: { month: new Date(), year: new Date() },
      },
    ]);
  };
  const deleteItemRowHandler = (index) => {
    const temp = [...state];
    temp.splice(index, 1);
    setState(temp);
  };

  const onInputChangeHandler = (value, index, type) => {
    const temp = [...state];
    if (type === 'instituteName') {
      temp[index].instituteName = value;
    }
    if (type === 'degree') {
      temp[index].degree = value;
    }
    if (type === 'course') {
      temp[index].course = value;
    }
    if (type === 'isPersue') {
      temp[index].isPersue = !temp[index].isPersue;
    }
    if (type === 'location') {
      temp[index].location = value;
    }
    if (type === 'from-month') {
      temp[index].from = { ...temp[index].from, month: value };
    }
    if (type === 'from-year') {
      temp[index].from = { ...temp[index].from, year: value };
    }
    if (type === 'to-month') {
      temp[index].to = { ...temp[index].to, month: value };
    }
    if (type === 'to-year') {
      temp[index].to = { ...temp[index].to, year: value };
    }
    setState(temp);
  };

  const onSaveHandler = async () => {
    const loggedInUser = TokenService.getSession();
    const payload = state.map((item) => {
      let fromMonth = new Date(item.from.month).toLocaleString('default', {
        month: '2-digit',
      });
      let toMonth = new Date(item.to.month).toLocaleString('default', {
        month: '2-digit',
      });
      let fromYear = new Date(item.from.year).toLocaleString('default', {
        year: 'numeric',
      });
      let toYear = new Date(item.to.year).toLocaleString('default', {
        year: 'numeric',
      });

      return {
        userId: loggedInUser.id,
        itemId: item.itemId ? item.itemId : null,
        instituteName: item.instituteName,
        degreeType: item.degree.value,
        course: item.course,
        location: item.location,
        from: new Date(fromYear, fromMonth - 1, 1),
        to: item.isPersue ? null : new Date(toYear, toMonth - 1, 1),
      };
    });
    const res = await fnWrapper({
      url: CREATE_EDUCATIONAL_DETAILS,
      method: 'POST',
      type: 'CREATE_EDUCATIONAL_DETAILS',
      payload: payload,
    });

    if (res.data.statusCode === 200) {
      if (isEditEvent) {
        if (location.state !== null && location.state.from === 'public_page') {
          navigate(location.state.redirectTo);
        } else {
          navigate(`/individual/profile/info/education`);
        }
      } else {
        setCurrType(nextType);
      }
    }
  };

  const getEducationDetails = useCallback(async () => {
    const loggedInUser = TokenService.getSession();
    const res = await fnWrapper({
      url: `${FETCH_EDUCATIONAL_DETAILS}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_EDUCATIONAL_INFO',
    });

    if (res.data.statusCode === 200) {
      const response = res.data.data;
      let dataArray = [];
      if (response.length) {
        response.forEach((ele) => {
          let data = {
            itemId: ele.id,
            instituteName: ele.instituteName,
            degree: DEGREE_TYPE.find(
              (degree) => degree.value === ele.degreeType
            ),
            course: ele.course,
            location: ele.location,
            isPersue: ele.to === null ? true : false,
            from: { month: new Date(ele.from), year: new Date(ele.from) },
            to:
              ele.to === null
                ? { month: new Date(), year: new Date() }
                : { month: new Date(ele.to), year: new Date(ele.to) },
          };
          dataArray = [...dataArray, data];
        });
      } else {
        dataArray = [
          {
            itemId: '',
            instituteName: '',
            degree: {},
            course: '',
            location: '',
            isPersue: false,
            from: { month: new Date(), year: new Date() },
            to: { month: new Date(), year: new Date() },
          },
        ];
      }
      setState(dataArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getEducationDetails();
  }, [getEducationDetails]);

  const isDisabled = () => {
    const eduError = (obj) =>
      obj.course.length === 0 ||
      checkForSpacesInStartEnd(obj.course) ||
      obj.instituteName.length === 0 ||
      checkForSpacesInStartEnd(obj.instituteName) ||
      Object.keys(obj.degree).length === 0 ||
      obj.location.length === 0 ||
      checkForSpacesInStartEnd(obj.location) ||
      obj.from.month === null ||
      obj.from.year === null ||
      (!obj.isPersue && (obj.to.month === null || obj.to.year === null));
    return state.some(eduError);
  };
  const disable = isDisabled();

  return (
    <div className="edu-detail-container">
      <Box display="flex" justifyContent="space-between">
        <Title title={title} />
        <AddButton onClick={addNewItemRowHandler} />
      </Box>
      {/*  body */}
      {state.map((item, index) => (
        <EducationFieldItems
          key={index}
          data={item}
          index={index}
          onInputChangeHandler={onInputChangeHandler}
          onDeleteRow={deleteItemRowHandler}
          degrees={DEGREE_TYPE}
        />
      ))}

      <div className="row mx-md-3">
        {!isEditEvent && <div className="col-md-4 col-2"></div>}
        <div className={!isEditEvent ? 'col-md-4 col-6' : 'col-md-12 col-12'}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap="15px"
            mt="30px">
            <Box width="120px">
              <CustomButton
                className="w-100"
                label="Save"
                onClick={onSaveHandler}
                disabled={disable}
                loading={context.busy.status}
              />
            </Box>
            <Box width="120px">
              <CustomButton
                className="w-100"
                variant="textBtn"
                color="themeGray"
                onClick={() => {
                  setCurrType(nextType);
                  navigate(`/individual/profile/${type}/${nextType}`);
                }}
                loading={context.busy.status}>
                Next
                <Icon name="arrow-forward" />
              </CustomButton>
            </Box>
          </Box>
        </div>
        {!isEditEvent && (
          <div className="col-md-4 col-4 d-flex align-items-center justify-content-end">
            <Box width="120px">
              <CustomButton
                className="w-100"
                variant="textBtn"
                color="themeGray"
                onClick={() => setCurrType(nextType)}>
                Skip
                <Icon name="arrow-right" size={20} color="#404F67" />
              </CustomButton>
            </Box>
          </div>
        )}
      </div>
    </div>
  );
};
