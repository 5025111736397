import styled from 'styled-components';

export const ExploreProjectFilterWrapper = styled.div`
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.06);
  right: 0;
  top: 0;
  position: absolute;
  z-index: 22;
  width: 40%;
  @media (max-width: 1400px) and (min-width: 1200px) {
    width: 50%;
  }
  @media (max-width: 1199px) {
    width: 60%;
  }
  @media (max-width: 991px) {
    width: 75%;
  }

  .filter-title-wrapper {
    border-bottom: 1.5px solid #e9eef4;
    height: 60px;

    .filter-title {
      padding: 20px 30px;
      font-weight: 500;
      font-size: 16px;
      color: #17191d;
    }
  }

  .filter-input-container {
    padding: 20px 30px;

    .filter-input-fields {
      display: flex;
      align-items: center;
      gap: 15px;
    }

    @media (max-width: 576px) {
      padding: 15px 10px;
    }
  }

  .date-label {
    font-weight: 600;
    font-size: 14px;
    color: #404f67;
    margin-bottom: 12px;
  }

  .custom-date {
    height: 52px;
    border-radius: 12px;
    border: 2px solid #e9eef4;
    padding: 17px;
    width: 100%;

    @media (max-width: 991px) {
      margin-bottom: 12px;
    }
  }

  .custom-date:focus {
    outline: none;
  }
`;
