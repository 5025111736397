import './uploadImage.scss';
import { useState, useEffect } from 'react';
import Icon from '../../utlils/Icons/Icon';
import { toast } from 'react-toastify';

export const UploadImage = ({ iconName, setImage, currentImage }) => {
  const [displayImage, setDisplayImage] = useState(false);
  function byteToMB(byteValue) {
    return byteValue / 1024 ** 2;
  }

  const handleImage = (event) => {
    if (
      event.target.files &&
      event.target.files[0] &&
      byteToMB(event.target.files[0].size) < byteToMB(1000000)
    ) {
      setDisplayImage(URL.createObjectURL(event.target.files[0]));
      setImage(event.target.files[0]);
    } else {
      toast.warning('Image size should be less than 1MB', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  useEffect(() => {
    if (currentImage) {
      if (typeof currentImage === 'string') {
        setDisplayImage(currentImage);
      } else {
        setDisplayImage(URL.createObjectURL(currentImage));
      }
    }
  }, [currentImage]);

  return (
    <div>
      {!displayImage ? (
        <div className="upload-image-div">
          <label className="upload-image-label" htmlFor="file-input">
            <Icon size={30} name={iconName} color="#7E8F9A" />
          </label>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="file-input"
            onChange={handleImage}
            type="file"></input>
        </div>
      ) : (
        <div
          className="display-image-div"
          onClick={() => {
            setDisplayImage(false);
            setImage(null);
          }}>
          <label htmlFor="file-input-2">
            <img alt="" className="display-image" src={displayImage}></img>
          </label>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="file-input-2"
            onChange={handleImage}
            type="file"></input>
        </div>
      )}
    </div>
  );
};
