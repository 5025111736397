import styled from 'styled-components';

export const AllNotificationsWrapper = styled.div`
  background-color: #f4f7fc;
  min-height: 100vh;
  .unread-block {
    background-color: #404f670a;
  }
  .scrollable-div__wrp {
    height: 100%;
  }
  .all-notifications-wrp {
    position: relative;
    &.content__ {
      border-radius: 12px;
      border: #e9eef4;
      background: #fff;
      padding: 16px 0px 10px 0px;
      margin-top: 20px;
      width: 70%;
      &header {
        color: #17191d;
        font-size: 30px;
        font-family: Poppins;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-left: 5%;
      }
      &btn {
        gap: 12px;
        margin-right: 5%;
      }
      &line {
        width: 100%;
        background-color: #e9eef4;
        height: 1.5px;
      }
    }
    .time-duration {
      color: #929daf;
      font-size: 14px;
      font-family: Open Sans;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin-top: 5px;
    }
    .notification-card:hover {
      background-color: #f4f7fc;
    }
    .notify-items {
      color: #17191d;
      font-family: Poppins;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
    }
    .notification-title {
      color: #404f67;
      font-size: 14px;
      font-family: Poppins;
      font-style: normal;
      font-weight: 400;
    }
    &.settings_wrp__ {
      position: absolute;
      display: block;
      height: 55vh;
      top: 23%;
      right: 16.8%;
      bottom: 0;
      z-index: 2;
      cursor: pointer;
      width: 370px;
      border: 1px solid #f4f7fc;
      border-top: 7px solid #219fff;
      border-radius: 8px;
      background: #fff;
      box-shadow: 0px 10px 36px 0px rgba(0, 0, 0, 0.08);
      padding: 12px;
      ::before {
        content: '';
        position: absolute;
        top: -27px;
        right: 13px;
        border: 11px solid transparent;
        border-bottom-color: #219fff;
      }
      &header {
        color: #17191d;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 8px;
      }
      &line {
        height: 1.5px;
        background-color: #e7eff0;
      }
      &st-content {
        padding: 5px;
        margin-top: 8px;
      }
      &setting-types {
        color: #404f67;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }
    .no-all-notification-section {
      display: grid;
      justify-items: center;
      height: 100%;
      align-content: center;
      gap: 20px;
      font-family: Open Sans;
      font-size: 19px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0em;
      color: #929daf;

      .No-notification-image {
        height: 107%;
      }
    }
  }
`;
