import React, { useEffect, useState } from 'react';
import { CustomButton } from 'components';
import { useParams, useNavigate } from 'react-router-dom';
import { createOrJoinConversation } from '../../../services/twilioService/helper';
import { FETCH_TEAM_MEMBERS, useAxiosWrapper } from 'services';
import Icon from 'utlils/Icons/Icon';
import styled from 'styled-components';
import { initializeTwilioConversations } from 'services/twilioService/twilioClient';
import tokenService from 'services/token.service';

const TeamMessagingWrapper = styled.div`
  .conversation-exists-loader {
    margin-top: 20px;
    color: #a4a0a6;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
  }
  .conversation-container {
    margin-top: 20px;
  }
  .conversation-btn {
    padding: 20px 0;
  }
`;

export const TeamMessaging = ({ teamInfo }) => {
  const { teamId } = useParams();
  const [fnWrapper] = useAxiosWrapper();
  const [conversation, setConversation] = useState(null);
  const [loading, setLoading] = useState({
    fetchConversation: false,
    createConversation: false,
  });
  const navigate = useNavigate();
  const loggedInUser = tokenService.getSession();
  const { userType } = useParams();

  const getMembersList = async () => {
    const res = await fnWrapper({
      url: `${FETCH_TEAM_MEMBERS}/${teamId}`,
      method: 'GET',
      type: 'FETCH_TEAM_MEMBERS_LIST',
    });

    if (res.data.statusCode === 200) {
      const membersIds = [];
      const membersList = res.data.data[0].teamMembers;

      for (const member of membersList) {
        if (member?.status === '4') {
          membersIds.push(member?.memberUserId);
        }
      }

      return membersIds;
    }

    return [];
  };

  useEffect(() => {
    const checkConversationAlreadyExist = async () => {
      setLoading((state) => ({
        ...state,
        fetchConversation: true,
      }));
      const friendlyName = `${teamId}--${teamInfo.teamName}`;
      const client = await initializeTwilioConversations(loggedInUser.id);
      const conversations = await client.getSubscribedConversations();
      const existingConversation = conversations.items.find(
        (conv) => conv.friendlyName === friendlyName
      );

      setConversation(existingConversation);
      getMembersList();
      setLoading((state) => ({
        ...state,
        fetchConversation: false,
      }));
    };
    checkConversationAlreadyExist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateConversation = async () => {
    setLoading((state) => ({
      ...state,
      createConversation: true,
    }));
    const client = await initializeTwilioConversations(loggedInUser.id);
    const membersList = await getMembersList();
    const friendlyName = `${teamId}--${teamInfo.teamName}`;
    const uniqueName = teamId;
    const participantIdentity = membersList.filter(
      (item) => item !== loggedInUser.id
    );
    const attributes = {
      teamId: teamId,
      conversationType: 'TEAM',
      isEnterprise: userType === 'enterprise' ? true : false,
    };

    const newConversation = await createOrJoinConversation(
      client,
      participantIdentity,
      friendlyName,
      uniqueName,
      attributes
    );

    setConversation(newConversation);
    setLoading((state) => ({
      ...state,
      createConversation: false,
    }));
  };

  return (
    <TeamMessagingWrapper>
      {!loading?.fetchConversation ? (
        <>
          <div className="heading">
            {conversation
              ? 'Conversation Already Exists'
              : 'Start Conversation'}
          </div>
          <div className="conversation-container">
            <div className="title">
              {conversation
                ? 'You can click below to go to the existing conversation.'
                : 'Welcome to the messaging tab. To start a conversation, simply click the "Start conversation" button below.'}
            </div>
            <div className="conversation-btn">
              <CustomButton
                label={
                  conversation ? 'Go to Conversation' : 'Start Conversation'
                }
                onClick={
                  conversation
                    ? () => {
                        navigate(
                          `/${
                            teamInfo?.enterpriseId === null
                              ? 'individual'
                              : 'enterprise'
                          }/messages/conversation/${conversation?.sid}`,
                          { replace: true }
                        );
                      }
                    : handleCreateConversation
                }
                loading={loading?.createConversation}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="conversation-exists-loader">
          <Icon color="#7c8b9f" name="loader" size={42} />
          Checking if the conversation already exists
        </div>
      )}
    </TeamMessagingWrapper>
  );
};
