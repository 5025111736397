import { useState, useEffect } from 'react';
import {
  Box,
  CustomButton,
  FeedBackFooter,
  Input,
  Title,
} from '../../components';
import { isValidPassword } from '../../helpers';
import './resetPassword.scss';
import Icon from 'utlils/Icons/Icon';
import { RESET_PASSWORD, useAxiosWrapper } from 'services';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const DEFAULTSTATE = {
  password: null,
  confirmPassword: null,
  error: {},
};
const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [fnWrapper, context] = useAxiosWrapper();
  const [searchParams] = useSearchParams();

  const [inputBoxState, setInputBoxState] = useState(DEFAULTSTATE);
  const [urlState, setUrlState] = useState({});
  const { password, confirmPassword, error } = inputBoxState;

  useEffect(() => {
    const paramsList = Object.fromEntries([...searchParams]);
    setUrlState(paramsList);
  }, [searchParams]);

  const handleInputChange = (field = '', value = null) => {
    setInputBoxState({
      ...inputBoxState,
      [field]: value,
      error: {
        ...error,
        password:
          field === 'password' &&
          !isValidPassword(value) &&
          'Password should contains atleast 8 charaters and containing uppercase, lowercase, special characters and numbers',
        confirmPassword:
          field === 'confirmPassword' && value !== password
            ? 'Password and confirm password should be same.'
            : '',
      },
    });
  };

  const onSubmitHandler = async () => {
    try {
      const res = await fnWrapper({
        url: RESET_PASSWORD,
        method: 'POST',
        type: 'RESET_PASSWORD',
        payload: {
          authenticationToken: urlState.tk,
          hashKey: urlState.hsh,
          userId: urlState.id,
          newPassword: password,
        },
      });
      if (res.data.statusCode === 200) {
        navigate('/login/selectOrganization', {
          replace: true,
          state: location?.state,
        });
        toast.success(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        navigate('/forgot-password', {
          replace: true,
          state: location?.state,
        });
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
      navigate('/forgot-password', {
        replace: true,
        state: location?.state,
      });
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  return (
    <div className="container-fluid forgot-password-container">
      <div className="row">
        <div className="col-lg-7">
          <div className="container">
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-8">
                <Box>
                  {/* logo */}
                  <Box mb="78px" mt="33px" ml="80px">
                    <img
                      src={process.env.PUBLIC_URL + '/new-logo.png'}
                      className="img common-navbar-logo"
                      alt="logo"
                    />
                  </Box>
                  <div className="forgot-password-wrapper">
                    <Title title={'Reset Your Password'}></Title>
                    <Box mt="40px">
                      <Input
                        type="password"
                        label="New Password"
                        placeholder={'Enter new password'}
                        iconLeft={true}
                        iconName="lock"
                        value={password}
                        onChange={(value) => {
                          handleInputChange('password', value.target.value);
                        }}
                        hasError={error?.password}
                        errorMessage={error?.password}
                        required
                      />
                    </Box>
                    <Box mt="20px">
                      <Input
                        type="password"
                        label="Confirm New Password"
                        placeholder={'Re-type new password'}
                        iconLeft={true}
                        iconName="lock"
                        value={confirmPassword}
                        onChange={(value) => {
                          handleInputChange(
                            'confirmPassword',
                            value.target.value
                          );
                        }}
                        hasError={error?.confirmPassword}
                        errorMessage={error?.confirmPassword}
                        required
                      />
                    </Box>
                    <Box width={250} mb="40px" mt="60px">
                      <CustomButton
                        onClick={onSubmitHandler}
                        className="w-100"
                        loading={
                          context.busy.status &&
                          context.busy.type === 'RESET_PASSWORD'
                        }>
                        Reset Password
                        <Icon
                          name="icon-right"
                          size={24}
                          color="#ffffff"
                          className="resetIcon"
                        />
                      </CustomButton>
                    </Box>
                    <FeedBackFooter />
                  </div>
                </Box>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
        </div>

        <div className="col-lg-5 d-none d-sm-none d-lg-block image-wrapper">
          {/* right side image */}
          <div className="right-img"></div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
