import { ButtonWrapper } from './buttonStyle';
import cx from 'classname';
import Icon from 'utlils/Icons/Icon';
import { oneOf, bool, func, string } from 'prop-types';

// noinspection JSCommentMatchesSignature
/**
 * @summary Button component
 * @description use the button with label or children
 * if you are using children then don't send label props
 * for width and height try to adjust padding top, left, right and bottom
 * if you need to add the icon then send the icon in children
 * for color and variant option, check the prop-types which is defined below line no. 107
 *
 * @author Kiran Ranjan Bihari <kiran@brenolabs.com>
 * @param {string} className custom class name pass from the parent.
 * @param {string} color color should be in your color option e<g. themePrimary.
 * @param {string} customPadding custom padding of the button in px eg. 10px, 12px 15px.
 * @param {object} children Elements to be wrapped (react elements).
 * @param {string} label button label text. eg 'Submit' or 'Search'.
 * @param {boolean} disabled to make disable a button
 * @param {boolean} loading to add loader in action triggered and disable the button at the time of action process
 * @param {object} props spread other props
 * @param {function} onClick button click event
 * @param {string} variant variant should be the type of button UI. eg.'solid', 'light', 'outline', 'textBtn'
 * @return {React.ReactElement} CustomButton
 */

export const CustomButton = (props) => {
  const {
    children,
    className = '',
    color = 'themePrimary',
    customPadding = '16px 14px',
    disabled = false,
    label = '',
    loading = false,
    onClick,
    variant = 'solid',
  } = props;
  const baseStyles = {
    solid: {
      themePrimary: 'btn solid__themePrimary',
    },
    light: {
      themePrimary: 'btn light__themePrimary',
    },
    outline: {
      themePrimary: 'btn outline__themePrimary',
    },
    textBtn: {
      themePrimary: 'btn textBtn__themePrimary',
    },
  };

  const variantStyles = {
    solid: {
      themePrimary: 'btn solid__themePrimary',
      themeSecondary: 'btn solid__themeSecondary',
      themeTertiary: 'btn solid__themeTertiary',
      themeOrange: 'btn solid__themeOrange',
    },
    light: {
      themePrimary: 'btn light__themePrimary',
      themeSecondary: 'btn light__themeSecondary',
      themeGray: 'btn light__themeGray',
      themeGray2: 'btn light__themeGray-2',
      themeTertiary: 'btn light__themeTertiary',
    },
    outline: {
      themePrimary: 'btn outline__themePrimary',
      themeSecondary: 'btn outline__themeSecondary',
      themeGray: 'btn outline__themeGray',
      themeTertiary: 'btn outline__themeTertiary',
      themeOrange: 'btn outline__themeOrange',
    },
    textBtn: {
      themePrimary: 'btn textBtn__themePrimary',
      themeSecondary: 'btn textBtn__themeSecondary',
      themeTertiary: 'btn textBtn__themeTertiary',
      themeOrange: 'btn textBtn__themeOrange',
      themeGray: 'btn textBtn__themeGray',
    },
  };

  const newClassName = cx(
    baseStyles[variant][color],
    variantStyles[variant][color],
    className
  );

  return (
    <ButtonWrapper
      className={newClassName}
      customPadding={customPadding}
      disabled={disabled || loading}
      onClick={onClick}>
      {loading ? (
        <>
          <Icon color={cx('#ffffff')} name={'loader'} size={20} />
          <span>Please wait</span>
        </>
      ) : label ? (
        label
      ) : (
        children
      )}
    </ButtonWrapper>
  );
};

CustomButton.propTypes = {
  className: string,
  color: oneOf([
    'themePrimary',
    'themeSecondary',
    'themeGray',
    'themeGray2',
    'themeTertiary',
    'themeOrange',
  ]),
  customPadding: string,
  disabled: bool,
  label: string,
  loading: bool,
  onClick: func,
  variant: oneOf(['solid', 'light', 'outline', 'textBtn']),
};
