export const PUBLICATION_PRIVACY_OPTIONS = [
  { text: 'Enterprise', value: 'Enterprise' },
  { text: 'Public', value: 'Public' },
];
export const PUBLICATION_CATEGORY_OPTIONS = [
  {
    label: 'Article',
    value: 'ARTICLE',
  },
  {
    label: 'Problem',
    value: 'PROBLEM',
  },
  {
    label: 'Solution',
    value: 'SOLUTION',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
];
