import classname from 'classname';
import styled from 'styled-components';

const StaticHomeCardWrapper = styled.div`
  &.main-container {
    display: grid;
    grid-template-columns: 275px 1fr;
    align-items: center;
    background: #ffffff;
    border: 1px solid #b1b1b1;
    border-radius: 20px;
    gap: 16px;
    @media (max-width: 991px) {
      grid-template-columns: auto;
    }
    @media (min-width: 991px) {
      &.next-row-child {
        justify-self: center;
        grid-column: span 2;
        width: 586px;
      }
    }

    & .image-style {
      border-radius: 20px 0px 0px 20px;
      background-image: url(${({ imagePath }) => imagePath});
      background-color: #f0f0f0;
      height: 220px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 70%;
      @media (max-width: 991px) {
        border-radius: 20px;
        background-size: 60%;
      }
    }

    & .content {
      padding-right: 16px;
      @media (max-width: 991px) {
        padding: 0 16px 16px;
      }
    }

    & .heading {
      font-weight: 600;
      font-size: 24px;
      line-height: 36px;
      color: #17191d;
      @media (max-width: 991px) {
        font-size: 16px;
        margin: 0;
      }
    }

    & .description {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: #404f67;
      @media (max-width: 991px) {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
`;

export const StaticHomeCard = ({ content, index }) => {
  return (
    <StaticHomeCardWrapper
      className={classname('main-container', index === 2 && 'next-row-child')}
      imagePath={content?.imgSrc}>
      <div className="image-style" />
      <div className="content">
        {content?.heading && <p className="heading">{content.heading}</p>}
        <div className="description">{content.description}</div>
      </div>
    </StaticHomeCardWrapper>
  );
};
