import React from 'react';
import Icon from 'utlils/Icons/Icon';
import tokenService from 'services/token.service';
import { initializeTwilioConversations } from 'services/twilioService/twilioClient';
import { createOrJoinConversation } from 'services/twilioService/helper';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const LoaderIcon = (props) => {
  const { name, color, applicantId, isEnterprise, ...rest } = props;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const redirectUserToConvo = async () => {
    const loggedInUser = tokenService.getSession();
    const client = await initializeTwilioConversations(loggedInUser.id);
    const senderId = loggedInUser?.id;
    const receiverId = applicantId;
    const friendlyName = `one-to-one-${senderId}--${receiverId}`;
    const uniqueName = `${senderId}--${receiverId}`;
    const participantIdentity = applicantId;
    const attributes = {
      senderId: senderId,
      receiverId: receiverId,
      conversationType: 'ONE_TO_ONE',
      isEnterprise: isEnterprise ? true : false,
    };

    let res = await createOrJoinConversation(
      client,
      participantIdentity,
      friendlyName,
      uniqueName,
      attributes
    );
    setLoading(false);
    navigate(`/individual/messages/conversation/${res?.sid}`);
  };

  const MessageBtnHandler = async () => {
    setLoading(true);
    await redirectUserToConvo();
    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <Icon name="loader" />
      ) : (
        <Icon onClick={MessageBtnHandler} name={name} color={color} />
      )}
    </>
  );
};
