import Icon from 'utlils/Icons/Icon';
import { checkForFileType } from 'pages/IndividualDashboard/individualOrganizations/enterprise/helper';
import CommonDropDown from 'pages/enterpriseDashboard/components/commonDropDown';
import SendIcon from 'assests/images/send.png';
import { ReactComponent as ThreeDots } from 'assests/images/three-dots.svg';
import { useState, useCallback, useEffect, useContext } from 'react';
import { isMobile } from 'utlils/isMobile';
import { PostCardWrapper } from './style';
import { PostTasks } from '../PostTasks';
import { CustomButton, Loader } from 'components';
import {
  FETCH_COMMENTS_LIST,
  FETCH_POST_LIKES,
  CREATE_POST_COMMENT,
  CREATE_POST_LIKE,
  useAxiosWrapper,
  FETCH_PROJECT_TASKS_BASED_ON_POST,
} from 'services';
import { getDateTime, getProfileText } from 'utlils/utils';
import tokenService from 'services/token.service';
import { userScopes } from 'utlils';
import { RoleBasedAccessContext } from 'context/RoleBasedAccess/roleBasedAccessContext';

const MY_TASK_ACTION_OPTIONS = [
  {
    id: 0,
    label: 'Publish Task',
    permission: userScopes.PROJECT_PUBLISH_TASK,
  },
  // {
  //   id: 1,
  //   label: 'Publish Publication',
  //   permission: userScopes.PROJECT_PUBLISH_PUBLICATION,
  // },
  {
    id: 2,
    label: 'Copy link',
    permission: userScopes.PROJECT_TASK_COPY_LINK,
  },
  // {
  //   id: 3,
  //   label: 'Share on Slack',
  //   permission: userScopes.PROJECT_TASK_SHARE_ON_SLACK,
  // },
];

export const PostCard = ({
  isCurrentStageFinished = false,
  isPublished = false,
  height,
  width,
  linkedStream = false,
  isUserCommonMember = false,
  postData,
  optionsHandler,
  isActive,
  onSelectPost,
  isPopup,
  projectId,
  userProfile,
}) => {
  const [fnWrapper, context] = useAxiosWrapper();
  const { roleBasedAccessState } = useContext(RoleBasedAccessContext);
  const projectPermissions = roleBasedAccessState.projectRBAC;

  const [currentComment, setCurrentComment] = useState('');
  const [comments, setComments] = useState([]);
  const [likesCount, setLikesCount] = useState({});
  const [showComments, setShowComments] = useState(false);
  const [showTasks, setShowTasks] = useState(false);
  const [tasks, setTasks] = useState([]);
  let DateTime = getDateTime(postData?.postedTime);
  const loggedInUser = tokenService.getSession();

  const getCommentsList = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_COMMENTS_LIST}/${postData.id}`,
      method: 'GET',
      type: 'FETCH_COMMENTS_LIST',
    });

    if (res.data.statusCode === 200) {
      const result = res.data.data;
      const commentsList = [];
      result.comments?.map((comment) => {
        const userName =
          comment.users.individual === null
            ? comment.users.smallEnterprise.enterpriseName
            : comment.users.individual.name;
        const pfp =
          comment.users.individual === null
            ? comment.users.smallEnterprise.profilePicture
            : comment.users.individual.profilePicture;

        const mapComments = {
          id: comment.id,
          commenterPic: pfp,
          commenterText: getProfileText(userName),
          commenterName: `${userName}`,
          commentedAt: comment.createdAt,
          text: comment.commentMessage,
        };
        commentsList.push(mapComments);
        return commentsList;
      });
      setComments(commentsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postData.id]);

  const getPublishedTasksList = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_PROJECT_TASKS_BASED_ON_POST}/${postData.id}`,
      method: 'GET',
      type: 'FETCH_PROJECT_TASKS_BASED_ON_POST',
    });

    if (res.data.statusCode === 200) {
      const result = res.data.data;

      const tasksList = [];
      result.map((task) => {
        const userName =
          task?.createdByUser?.individual === null
            ? task?.createdByUser?.smallEnterprise?.enterpriseName
            : task?.createdByUser?.individual.name;
        const pfp =
          task?.createdByUser?.individual === null
            ? task?.createdByUser?.smallEnterprise?.profilePicture
            : task?.createdByUser?.individual?.profilePicture;

        const taskDetails = {
          id: task?.id,
          imgSrc: pfp,
          profileText: getProfileText(userName),
          userName: userName,
          postedTime: task?.createdAt,
          taskName: task?.taskTitle,
          teamName: task?.assignedToTeam?.teamName,
          description: task?.taskDescription,
          attachments: task?.attachments,
        };

        tasksList.push(taskDetails);
        return tasksList;
      });
      setTasks(tasksList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postData.id, showTasks]);

  const postComment = async (text) => {
    const res = await fnWrapper({
      url: `${CREATE_POST_COMMENT}/${postData.id}`,
      method: 'POST',
      payload: {
        commentMessage: text,
      },
      type: 'CREATE_POST_COMMENT',
    });

    if (res.data.statusCode === 200) {
      setCurrentComment('');
      getCommentsList();
    }
  };

  const createPostLike = async () => {
    const res = await fnWrapper({
      url: CREATE_POST_LIKE,
      method: 'POST',
      payload: {
        postId: postData.id,
      },
      type: 'CREATE_POST_LIKE',
    });

    if (res.data.statusCode === 200) {
      getPostLikes();
    }
  };

  const getPostLikes = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_POST_LIKES}/${postData.id}`,
      method: 'GET',
      type: 'FETCH_POST_LIKES',
    });

    if (res.data.statusCode === 200) {
      const result = res.data.data;
      const mapLikes = {
        count: result.count,
        users: result.rows,
      };
      setLikesCount(mapLikes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postData.id]);

  const getFileName = (file) => {
    const setFileName = file.split('/');
    return setFileName[setFileName.length - 1];
  };

  const likeBtnColorHandler = () => {
    const isCurrentUserLiked = likesCount.users?.filter(
      (user) => user.userId === loggedInUser.id
    );
    if (isCurrentUserLiked?.length !== 0) return true;
    return false;
  };

  useEffect(() => {
    getCommentsList();
    getPostLikes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postData.id]);

  return (
    <>
      <PostCardWrapper
        height={height}
        width={width}
        showComments={showComments}
        showTasks={showTasks}>
        <div className="post-wrapper">
          {/* header */}
          <div className="posts-header">
            <div className="left-side-class">
              <div className="profile-pic-container">
                {linkedStream && !isUserCommonMember ? (
                  <Icon
                    name={'user-icon'}
                    className="user-icon-class"
                    color="#292D32"
                  />
                ) : (
                  <>
                    {postData.imgSrc !== '' && postData.imgSrc !== null ? (
                      <img alt="pfp-pic" src={postData.imgSrc} width={'100%'} />
                    ) : (
                      <div className="pfp-alt-circle">
                        <span className="pfp-alt">{postData.profileText}</span>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div
                className="user-name"
                style={{
                  paddingTop: `${
                    linkedStream && !isUserCommonMember ? '20px' : '15px'
                  }`,
                }}>
                <p className="user-name-style">
                  {linkedStream && !isUserCommonMember
                    ? 'Anonymous'
                    : postData.userName}
                </p>
                {(!linkedStream || isUserCommonMember) && (
                  <span className="user-posted-time">{DateTime}</span>
                )}
              </div>
            </div>
            {!isPopup && !linkedStream && !isCurrentStageFinished && (
              <div style={{ position: 'relative' }}>
                <div className="options-class" onClick={() => onSelectPost()}>
                  <ThreeDots color={isActive ? '#219fff' : '#c4c4c4'} alt="" />
                </div>
                <CommonDropDown
                  accessiblePermissions={projectPermissions}
                  dropItem={MY_TASK_ACTION_OPTIONS}
                  active={isActive}
                  objectKey="label"
                  onClick={(data) => {
                    optionsHandler(data, postData);
                  }}
                  top="35px"
                  right="-21px"
                />
              </div>
            )}
          </div>

          {/* content */}

          <div className="post-content-container">
            <div>{postData.text}</div>
            {postData.attachments.length !== 0 && (
              <div className="post-attachment">
                {postData.attachments.map((elem, index) => {
                  const fileName = getFileName(elem);
                  return (
                    <div className="img-wrapper" key={index}>
                      <div className="img-container">
                        {checkForFileType(fileName) === 'others' ? (
                          <Icon name="attachment" />
                        ) : (
                          <img src={checkForFileType(fileName)} alt="word" />
                        )}
                      </div>
                      <a
                        className="img-text"
                        href={elem}
                        target="_blank"
                        rel="noreferrer">
                        {fileName}
                      </a>
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          {/* footer */}
          <div className="post-footer">
            <div className="footer-buttons">
              <div className="footer-button-box">
                <Icon
                  name="like-btn"
                  size={20}
                  color={likeBtnColorHandler() ? '#219fff' : '#7E8F9A'}
                  className={
                    isCurrentStageFinished || linkedStream
                      ? 'disabled'
                      : 'icon-button'
                  }
                  onClick={
                    !linkedStream && !isCurrentStageFinished
                      ? createPostLike
                      : undefined
                  }
                />
                <div>{likesCount?.count > 0 && likesCount?.count}</div>
              </div>
              <div className="footer-button-box">
                <Icon
                  name="comment-btn"
                  color={showComments ? '#219fff' : '#7E8F9A'}
                  size={20}
                  className="icon-button"
                  onClick={() => {
                    if (!showComments) {
                      getCommentsList(postData.id);
                    }
                    setShowComments(!showComments);
                  }}
                />
                <div>{comments.length > 0 && comments.length}</div>
              </div>
            </div>
            <CustomButton
              variant="textBtn"
              color="themeSecondary"
              className="PostCardTasksBtn"
              onClick={async () => {
                await getPublishedTasksList();
                setShowTasks(!showTasks);
              }}>
              <Icon
                name={showTasks ? 'eye-slash' : 'eye-2'}
                size={20}
                color="#219fff"
              />
              {showTasks ? 'Hide tasks' : 'Show tasks'}
            </CustomButton>
          </div>
        </div>
        {showComments && (
          <div className="comments-wrapper">
            {!linkedStream && !isCurrentStageFinished && (
              <div className="comment-input_area">
                <div>
                  {userProfile.profilePicture !== null &&
                  userProfile.profilePicture !== '' ? (
                    <img
                      src={userProfile.profilePicture}
                      alt="pfp"
                      width={'100%'}
                      className="commenter-profile-pic"
                    />
                  ) : (
                    <div className="pfp-alt-circle">
                      <span className="pfp-alt">
                        {userProfile?.profileText}
                      </span>
                    </div>
                  )}
                </div>
                <div className="comment-input_box">
                  <input
                    value={currentComment}
                    onChange={(e) => setCurrentComment(e.target.value)}
                    className="comment-input"
                    placeholder={
                      isMobile() ? 'Comment here..' : 'Type your comment here'
                    }
                  />
                  <img
                    src={SendIcon}
                    alt="send"
                    className="send-comment-icon"
                    onClick={() => postComment(currentComment)}
                  />
                </div>
              </div>
            )}
            {context.busy.status ? (
              <Loader isLoading={context.busy.status} />
            ) : comments.length > 0 ? (
              comments.map((comment, index) => {
                return (
                  <div className="display-comment-wrapper">
                    <div className="display-comment-header">
                      <div className="commenter-info">
                        {linkedStream && !isUserCommonMember ? (
                          <Icon
                            name={'user-icon'}
                            className="user-icon-class"
                            color="#292D32"
                          />
                        ) : comment.commenterPic !== null &&
                          comment.commenterPic !== '' ? (
                          <img
                            src={comment.commenterPic}
                            alt="pfp"
                            className="commenter-pic"
                          />
                        ) : (
                          <div className="pfp-text-circle">
                            <span className="pfp-text">
                              {comment.commenterText}
                            </span>
                          </div>
                        )}
                        <span className="commenter-name">
                          {linkedStream && !isUserCommonMember
                            ? 'Anonymous'
                            : comment.commenterName}
                        </span>
                      </div>
                      {(!linkedStream || isUserCommonMember) && (
                        <div className="comment-options">
                          <span>{getDateTime(comment.commentedAt)}</span>
                          {!linkedStream && !isCurrentStageFinished && (
                            <ThreeDots color="#929daf" />
                          )}
                        </div>
                      )}
                    </div>
                    <div className="display-comment-content">
                      {comment.text}
                    </div>
                  </div>
                );
              })
            ) : (
              <div style={{ color: '#929daf' }}>
                {linkedStream || isCurrentStageFinished
                  ? 'No comments to show!'
                  : 'No comments. Post the first comment now!'}
              </div>
            )}
          </div>
        )}
      </PostCardWrapper>
      {showTasks && (
        <PostTasks
          tasks={tasks}
          isLinkedStream={linkedStream}
          isCurrentStageFinished={isCurrentStageFinished}
          isUserCommonMember={isUserCommonMember}
          projectId={projectId}
          userProfile={userProfile}
        />
      )}
    </>
  );
};
