import React from 'react';
import { Input, CustomButton } from 'components';
import { DeactivateAccountWrapper } from './style';
import { useState } from 'react';
import {
  DEACTIVATE_ACCOUNT,
  DELETE_ACCOUNT,
  FETCH_PROJECTS_BASED_ON_USER,
  FETCH_TEAMS_BASED_ON_USER,
  VERIFY_USER_EMAIL,
  useAxiosWrapper,
} from 'services';
import tokenService from 'services/token.service';
import { toast } from 'react-toastify';
import { AdminDetailsPopup } from './AdminDetailsPopup';
import { DeleteAccountModal } from './DeleteAccountModal';

const DEFAULT_ACCOUNT_STATE = {
  isModalOpen: false,
  step: null,
};

export const DeactivateAccount = () => {
  const [fnWrapper, context] = useAxiosWrapper();
  const loggedInUser = tokenService.getSession();
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [projectAdminList, setProjectAdminList] = useState([]);
  const [teamAdminList, setTeamAdminList] = useState([]);
  const [deactivateAccount, setDeactivateAccount] = useState(
    DEFAULT_ACCOUNT_STATE
  );
  const [deleteAccount, setDeleteAccount] = useState(DEFAULT_ACCOUNT_STATE);

  const onVerifyEmailHandler = async () => {
    const res = await fnWrapper({
      url: VERIFY_USER_EMAIL,
      method: 'GET',
      type: 'VERIFY_USER_EMAIL',
      params: {
        userId: loggedInUser.id,
        email: loggedInUser?.email,
      },
    });
    if (res.data.statusCode === 200) {
      setIsEmailVerified(true);
      toast.success(res.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  const getTeamList = async () => {
    const res = await fnWrapper({
      url: `${FETCH_TEAMS_BASED_ON_USER}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_TEAMS_BASED_ON_USER',
    });

    if (res.data.statusCode === 200) {
      let teamAdminData = [];
      await res.data?.data?.forEach((team) => {
        team?.teamMembers?.forEach((tm) => {
          if (tm.memberUserId === loggedInUser?.id && tm.role === 'ADMIN') {
            teamAdminData = [
              ...teamAdminList,
              { ...tm, teamName: team.teamName },
            ];
          }
        });
      });
      setTeamAdminList(teamAdminData);
      return teamAdminData;
    }
    return [];
  };

  const getProjectList = async () => {
    const res = await fnWrapper({
      url: `${FETCH_PROJECTS_BASED_ON_USER}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_PROJECTS_BASED_ON_USER',
      params: { projectType: 'all' },
    });

    if (res.data.statusCode === 200) {
      const adminProjectList = res?.data?.data?.filter(
        (item) =>
          item.projectRole === 'ADMIN' && loggedInUser.id === item.userId
      );
      setProjectAdminList(adminProjectList);
      return adminProjectList;
    }
    return [];
  };

  const deactivateOpenPopupHandler = () => {
    setDeactivateAccount({ isModalOpen: true, step: 'CONFIRMATION' });
  };

  const deactivateClosePopupHandler = async () => {
    setProjectAdminList([]);
    setTeamAdminList([]);
    setDeactivateAccount(DEFAULT_ACCOUNT_STATE);
  };

  const deleteAccountOpenHandler = () => {
    setDeleteAccount({ isModalOpen: true, step: 'CONFIRMATION' });
  };

  const deleteAccountCloseHandler = async () => {
    setProjectAdminList([]);
    setTeamAdminList([]);
    setDeleteAccount(DEFAULT_ACCOUNT_STATE);
  };

  const deleteAccountVerificationContinue = async () => {
    setDeleteAccount((prevState) => ({
      ...prevState,
      step: 'NEED_TO_EMAIL_VERIFY',
    }));
  };

  const onDeactivateAccountHandler = async () => {
    const res = await fnWrapper({
      url: DEACTIVATE_ACCOUNT,
      method: 'PUT',
      type: 'DEACTIVATE_ACCOUNT',
      params: {
        userId: loggedInUser.id,
        email: loggedInUser?.email,
      },
    });
    if (res.data.statusCode === 200) {
      toast.success(res.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      await tokenService.onLogout();
      await deactivateClosePopupHandler();
      await window.location.replace('/login/selectOrganization');
    }
  };

  const onDeleteAccountHandler = async () => {
    const res = await fnWrapper({
      url: DELETE_ACCOUNT,
      method: 'PUT',
      type: 'DELETE_ACCOUNT',
      params: {
        userId: loggedInUser.id,
      },
    });
    if (res.data.statusCode === 200) {
      toast.success(res.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      await tokenService.onLogout();
      await deleteAccountCloseHandler();
      window.location.replace('/login/selectOrganization');
    }
  };

  const onDeactivateDeleteHandler = async (type) => {
    const teamAdminListInfo = await getTeamList();
    const projectListInfo = await getProjectList();
    if (teamAdminListInfo?.length > 0 || projectListInfo?.length > 0) {
      if (type === 'deactivate') {
        setDeactivateAccount((prevState) => ({
          ...prevState,
          step: 'ADMIN_LIST_FOUND',
        }));
      } else {
        setDeleteAccount((prevState) => ({
          ...prevState,
          step: 'ADMIN_LIST_FOUND',
        }));
      }
    } else {
      if (type === 'deactivate') {
        await onDeactivateAccountHandler();
      } else {
        await onDeleteAccountHandler();
      }
    }
  };

  const isDeactivatingOrDeleting =
    context?.busy?.status &&
    (context?.busy?.type === 'FETCH_TEAMS_BASED_ON_USER' ||
      context?.busy?.type === 'FETCH_PROJECTS_BASED_ON_USER' ||
      context?.busy?.type === 'DEACTIVATE_ACCOUNT' ||
      context?.busy?.type === 'DELETE_ACCOUNT');

  return (
    <DeactivateAccountWrapper>
      <div className="heading">
        <span>Deactivate Account</span>
        <span className="link" onClick={deleteAccountOpenHandler}>
          Delete Account
        </span>
      </div>
      <p className="deactivate-summary">{`If you deactivate your account, others will not be able to see your profile.It is a temporary deletion of the profile, once you fully log in again, you can reactivate it.`}</p>
      <div className="deactivate-form">
        <div className="col-12">
          <Input
            label="Enter your email id to deactivate your account"
            placeholder="Enter your mail id"
            type="text"
            required
            value={loggedInUser?.email}
            disabled
            rightText={
              <span
                className={
                  !isEmailVerified
                    ? 'verify-email-text'
                    : 'verify-email-text disable-btn'
                }
                onClick={onVerifyEmailHandler}>
                {isEmailVerified ? 'Verified' : 'Verify'}
              </span>
            }
          />
        </div>
        <div className="deactivate-button-container mt-4">
          <CustomButton
            className="w-100 h-100"
            label="Deactivate Account"
            disabled={!isEmailVerified}
            onClick={deactivateOpenPopupHandler}
          />
        </div>
      </div>
      {deactivateAccount.isModalOpen && (
        <AdminDetailsPopup
          isDeactivatingOrDeleting={isDeactivatingOrDeleting}
          deactivateAccountState={deactivateAccount}
          onClose={deactivateClosePopupHandler}
          onDeactivateDeleteHandler={onDeactivateDeleteHandler}
          teamAdminList={teamAdminList}
          projectAdminList={projectAdminList}
        />
      )}
      {deleteAccount.isModalOpen && (
        <DeleteAccountModal
          isDeactivatingOrDeleting={isDeactivatingOrDeleting}
          deleteAccountState={deleteAccount}
          onClose={deleteAccountCloseHandler}
          onContinueVerification={deleteAccountVerificationContinue}
          onDeactivateDeleteHandler={onDeactivateDeleteHandler}
          teamAdminList={teamAdminList}
          projectAdminList={projectAdminList}
        />
      )}
    </DeactivateAccountWrapper>
  );
};
