import { Loader, ScrollableDiv } from 'components';
import { BoardFieldWrapper } from 'pages/TaskManagement/style';
import { DragWrapper } from '../DragWrapper';
import { TaskManagementCard } from '../TaskManageCard';
import noData from 'assests/images/noProjects.png';

export const TaskBoard = (props) => {
  const { boardFields, ctx, onMoveTaskHandler, ...rest } = props;

  return (
    <>
      {ctx.busy.status ? (
        <Loader loading={ctx.busy.status} />
      ) : (
        <div className="task-management__wrapper__board">
          <ScrollableDiv
            scrollOnHeight="calc(100vh - 250px)"
            scrollOnHeightMob="calc(100vh - 400px)">
            <div className="d-flex gap-2">
              {Object.keys(boardFields).length === 0 ? (
                <div className="task-management__wrapper__board__no_data">
                  <img src={noData} alt="no-data" className="no-data-img" />
                  <div className="py-4 text-center">
                    Select appropriate options from the dropdown menus
                  </div>
                </div>
              ) : (
                Object.keys(boardFields).map((field) => (
                  <BoardFieldWrapper key={field}>
                    <>
                      <div className="task-management__wrapper__board__title--container">
                        <div className="title">
                          {field.replace(/_/g, ' ').toUpperCase()} (
                          {boardFields[field].length})
                        </div>
                      </div>
                      <DragWrapper
                        onDrop={onMoveTaskHandler}
                        status={field}
                        className="task-management__wrapper__board__cards--wrapper">
                        {boardFields[field].map((task) => (
                          <div className="custom-card" key={task?.id}>
                            <TaskManagementCard
                              boardFields={boardFields}
                              task={task}
                              {...rest}
                            />
                          </div>
                        ))}
                      </DragWrapper>
                    </>
                  </BoardFieldWrapper>
                ))
              )}
            </div>
          </ScrollableDiv>
        </div>
      )}
    </>
  );
};
