import React from 'react';
import styled from 'styled-components';
import TeamsCreated from '../../../../assests/images/teamCreated.png';
import { useParams } from 'react-router-dom';

export default function SuccessStep() {
  const params = useParams();
  return (
    <SuccessStepWrapper>
      <div className="group-created-image">
        <img src={TeamsCreated} alt="" width="100%" />
      </div>
      <div>
        <div className="group-created-message">
          {params.action === 'create'
            ? `Congrats, you have successfully created your group.`
            : `Group details updated successfully`}
        </div>
      </div>
    </SuccessStepWrapper>
  );
}
const SuccessStepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .group-created-image {
    width: 50%;
    @media (max-width: 992px) {
      width: 60%;
    }
    @media (max-width: 576px) {
      width: 80%;
    }
  }
  .group-created-message {
    margin-top: 2rem;
    font-weight: 400;
    font-size: 17px;
    text-align: center;
  }
`;
