import styled from 'styled-components';

export const ActionModalWrp = styled.div`
  &.amw-assign-team {
    & .close-btn {
      width: 100px;
      margin: 12px auto 0;
    }
  }
  & .amw-assign-team__action {
    display: grid;
    grid-template-columns: 120px 120px;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }
`;
