import styled from 'styled-components';

export const ViewProjectWrapper = styled.div`
  & .view-project-container {
    padding: 5px;
    & .team-dashboard-heading {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 38px;
      color: #17191d;
    }
    & .view-project-heading {
      display: flex;
      justify-content: space-between;
      gap: 20px;
      padding: 30px 0px 20px 0px;
      border-bottom: 2px dashed #929daf;

      & .heading-left {
        display: flex;
        padding-top: 15px;

        & .heading-back-btn {
          padding-right: 10px;
          &:hover {
            cursor: pointer;
          }
        }
        & .heading-text {
          font-weight: 500;
          font-size: 20px;
          color: #17191d;
          word-break: break-word;
        }
      }
      & .right-buttons {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;
        .small-button {
          width: 54px;
        }
      }
      @media (max-width: 767px) {
        flex-direction: column;
        gap: 20px;
      }
    }
  }

  & .manage-board--title {
    .wrp__container__label {
      font-size: 30px;
    }
  }

  & .publish-popup {
    position: relative;

    & .popup-header {
      display: flex;
      justify-content: space-between;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 25px;
      color: #17191d;
      border-bottom: 2px solid #c4c4c4;
      margin-bottom: 30px;
      & .close-cross {
        position: absolute;
        top: 35px;
        right: 25px;
        &:hover {
          cursor: pointer;
        }
      }
    }

    & .publish-sub-heading {
      margin: 20px 0 60px 0;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #404f67;
    }

    & .publish-textarea {
      margin: 25px 0;
    }

    & .publish-checkbox {
      font-weight: 600;
      font-size: 15px;
      color: #404f67;
      margin-bottom: 40px;

      & .checkbox-style {
        display: grid;
        grid-template-columns: repeat(3, 0.5fr);
        align-items: flex-start;
        gap: 20px;

        & .checkbox-position {
          display: flex;
          justify-content: flex-start;
          gap: 10px;
          & span {
            margin: auto 0px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            color: #404f67;
          }
        }

        @media (max-width: 768px) {
          grid-template-columns: 1fr;
        }
      }
    }

    & .popup-button {
      margin: 16px auto 0 auto;
      align-items: center;
      width: 150px;
    }

    & .popup-success-img {
      margin: 25px auto;
      display: flex;
      justify-content: center;
      align-items: center;

      & img {
        width: 45%;
      }
    }

    & .popup-bottom-text {
      display: grid;
      justify-items: center;
      gap: 24px;
      padding: 8px;
      text-align: center;
      word-wrap: break-word;

      & a {
        color: #219fff;
        text-decoration: underline;
        font-style: italic;
      }
    }

    & .setting-popup-content {
      margin: 40px 0px;
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 20px;

      & .setting-text {
        display: flex;
        align-items: center;
        gap: 8px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        color: #404f67;
        margin-bottom: 0px;
        & .tooltip-card-item {
          margin-top: 16px;
          &-heading {
            font-size: 14px;
            font-weight: 500;
            color: #404f67;
          }
          &-text {
            font-size: 12px;
            font-weight: 400;
            color: #404f67;
          }
        }
      }

      & .setting-toggle-btn {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        border-left: 2px solid rgba(0, 0, 0, 0.38);
        padding-left: 20px;
        align-items: center;

        & .btn-text-style {
          font-weight: 500;
          letter-spacing: 0.25px;
        }
      }

      @media (max-width: 1200px) {
        grid-template-columns: 1fr 1fr;
      }
      @media (max-width: 485px) {
        grid-template-columns: 1fr;
      }
    }

    & .public-options {
      margin: 8px;
      & .public-options-item {
        margin: 16px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        &-show {
          color: #404f67;
        }
        &-disabled {
          opacity: 0.3;
          cursor: not-allowed;
        }
      }
    }

    & .sync-text-style {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      color: #404f67;
      margin-top: 50px;
    }

    & .settings-btn {
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 576px) {
        align-items: center;
      }
    }
  }

  & .sync-class {
    margin-left: 30px;
  }
`;
