import { TeamPublicWrapper } from './style';
import { useState, useEffect, useCallback } from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import {
  FETCH_USER_PROFILE,
  FETCH_TEAM_PUBLIC_PROFILE_DETAILS,
  useAxiosWrapper,
} from 'services';
import TokenService from 'services/token.service';
import { getProfileText } from 'utlils/utils';
import { TeamPublicDetails } from './TeamPublicDetails';
import { TeamPublicMembers } from './TeamPublicMembers';
import { TeamPublicProjects } from './TeamPublicProjects';
import { TeamPublicPublications } from './TeamPublicPublications';
import { TeamPublicOpenings } from './TeamPublicOpenings';
import { TeamPublicCard } from './Components';
import { capitalizeFirstLetter } from 'pages/IndividualDashboard/individualOrganizations/enterprise/helper';
import { NavbarBrand1, Sidebar } from 'components';
import { INDIVIDUAL_DASHBOARD_NAV_LIST } from 'pages/IndividualDashboard/constants';
import Icon from 'utlils/Icons/Icon';
import { getTeamPublicLeftSideData } from './constants';

const DEFAULT_TEAM_INFO = {
  teamName: '',
  teamType: '',
  teamOwner: '',
  profileImg: '',
  teamCategory: '',
  teamMembers: 0,
};

export const DEFAULT_TEAM_DETAILS = {
  'Team Topic': '',
  Description: '',
};
export const TeamPublic = ({ currentLocale, handleLocaleChange }) => {
  const [fnWrapper] = useAxiosWrapper();
  const [userProfile, setUserProfile] = useState({});
  const { teamId } = useParams();
  const [teamInfo, setTeamInfo] = useState(DEFAULT_TEAM_INFO);
  const [teamDetails, setTeamDetails] = useState(DEFAULT_TEAM_DETAILS);
  const [isNotLoggedInUser, setNotLoggedInUser] = useState(false);
  const [leftActiveItem, setLeftActiveItem] = useState('');

  const setLeftSidebar = (type) => {
    setLeftActiveItem(type);
  };

  const getUserProfile = useCallback(async () => {
    const loggedInUser = TokenService.getSession();
    const res = await fnWrapper({
      url: `${FETCH_USER_PROFILE}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_USER_PROFILE',
    });
    if (res.data.statusCode === 200) {
      const response = res.data.data;
      setUserProfile({
        ...response,
        profileText: getProfileText(
          response?.role === 'INDIVIDUAL'
            ? response?.name
            : response?.enterpriseName
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTeamDetails = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_TEAM_PUBLIC_PROFILE_DETAILS}/${teamId}`,
      method: 'GET',
      type: 'FETCH_TEAM_PUBLIC_PROFILE_DETAILS',
    });
    if (res.data.statusCode === 200) {
      const response = res.data.data;
      const item = {
        teamName: response?.teamName,
        teamType: capitalizeFirstLetter(response?.type?.title),
        teamCategory: response?.teamCategory,
        teamOwner:
          response?.enterprise !== null
            ? response?.enterprise?.enterpriseName
            : response?.user?.individual?.name,
        profileImg: '',
        teamMembers: response?.teamMembers?.length,
      };
      setTeamInfo(item);
      setTeamDetails({
        'Team Topic': response?.topic,
        Description: response?.description,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const loggedInUser = TokenService.getSession();
    if (loggedInUser) {
      setNotLoggedInUser(false);
      getUserProfile(loggedInUser);
    } else {
      setNotLoggedInUser(true);
    }
  }, [getUserProfile]);

  useEffect(() => {
    getTeamDetails();
  }, [getTeamDetails, teamId]);

  const btns = [
    // {
    //   label: 'Request Link',
    //   bgColor: '#fff',
    //   color: '#219fff',
    //   iconName: 'link',
    //   buttonStyle: {
    //     padding: '0 20px',
    //   },
    //   onClick: () => {
    //     // editBtnHandler(!isEditPopupOpen);
    //     // updateOnProfileEdit(false);
    //   },
    // },
  ];

  const infoItems = [
    {
      label: 'Website',
      value: teamInfo.teamType,
      iconName: 'tag-user',
    },
    {
      label: 'Members',
      value: `${teamInfo.teamMembers} Members`,
      iconName: 'people',
    },
  ];

  return (
    <>
      <NavbarBrand1
        currentLocale={currentLocale}
        handleLocaleChange={handleLocaleChange}
        userProfile={userProfile}
        navMenu={INDIVIDUAL_DASHBOARD_NAV_LIST(userProfile?.role)}
      />
      <TeamPublicWrapper>
        <div className="main-container">
          <div className="layout-container">
            <div className="layout-upper-container">
              <div className="upper-container-child">
                <TeamPublicCard
                  teamName={teamInfo.teamName}
                  teamOwner={teamInfo.teamOwner}
                  teamCategory={teamInfo.teamCategory}
                  profileImgSrc={teamInfo.profileImg}
                  buttons={btns}
                  infoItems={infoItems}
                />
              </div>
              <div className="upper-container-child">
                <div className="d-flex align-items-center">
                  <Icon name="brief-case" color="#404f67" size={24} />
                  <span className="px-2" style={{ color: '#404f67' }}>
                    <strong>Hiring</strong>
                  </span>
                </div>
                <div className="d-flex justify-content-center align-items-center h-100 flex-column">
                  <Icon name="warning" size={40} color="#929daf" />
                  <div className="py-3 text-center">
                    It looks like they are not hiring at the moment
                  </div>
                </div>
              </div>
            </div>
            <div className="layout-lower-container">
              <div className="sub-container">
                <Sidebar
                  sidebarData={getTeamPublicLeftSideData(teamId)}
                  activeType={leftActiveItem}
                  setActiveType={setLeftSidebar}
                />
              </div>
              <div className="sub-container">
                <Routes>
                  <Route path="">
                    <Route
                      element={
                        <TeamPublicDetails
                          currentLocale={currentLocale}
                          handleLocaleChange={handleLocaleChange}
                          teamDetails={teamDetails}
                        />
                      }
                      path="details/*"
                    />
                    <Route
                      element={
                        <TeamPublicMembers userRole={userProfile?.role} />
                      }
                      path="members/*"
                    />
                    <Route
                      element={
                        <TeamPublicProjects
                          currentLocale={currentLocale}
                          handleLocaleChange={handleLocaleChange}
                        />
                      }
                      path="projects/*"
                    />
                    <Route
                      element={
                        <TeamPublicPublications
                          currentLocale={currentLocale}
                          handleLocaleChange={handleLocaleChange}
                        />
                      }
                      path="publications/*"
                    />
                    <Route
                      element={
                        <TeamPublicOpenings
                          currentLocale={currentLocale}
                          handleLocaleChange={handleLocaleChange}
                          isNotLoggedInUser={isNotLoggedInUser}
                        />
                      }
                      path="openings/*"
                    />
                  </Route>
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </TeamPublicWrapper>
    </>
  );
};
