import { NavigationalRowItem } from 'components';
import Icon from 'utlils/Icons/Icon';
import styled from 'styled-components';
import { TASK_ATTACHMENTS_TYPES } from './constants';
import { toast } from 'react-toastify';

const DepartmentMemberNameWrapper = styled.div`
  & .member-class {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;

    & .profile-pic-class,
    .profile-pic-class img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
    & .icon-style-class {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: #e9fbf6;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const INVITEMEMBERROLE = [
  { id: 1, label: 'Admin', value: 'ADMIN' },
  { id: 2, label: 'Co-Admin', value: 'COADMIN' },
];
export const DEPARTMENTTYPE = [
  { id: 1, label: 'IT', value: 'IT' },
  { id: 2, label: 'Networking', value: 'Networking' },
  { id: 3, label: 'Recruitment', value: 'Recruitment' },
];

export const DEPARTMENTPERMISSION = {
  PEOPLE: '1',
  CAREER: '2',
  PUBLICATIONS: '3',
  EVENTS: '4',
};
export const DEPARTMENTSETTINGS = {
  CAN_HIRE: '1',
  CAN_PUBLISH: '2',
};

export const taskUrgency = {
  1: {
    label: 'High',
    bgColor: 'rgba(230, 102, 87, 0.25)',
    textColor: '#E66657',
    borderRadius: '10px',
    border: '1px solid #E66657',
  },
  2: {
    label: 'Medium',
    bgColor: 'rgba(255, 164, 18, 0.25)',
    textColor: '#FFA412',
    borderRadius: '10px',
    border: '1px solid #FFA412',
  },
  3: {
    label: 'Low',
    bgColor: 'rgba(126, 143, 154, 0.25)',
    textColor: '#7E8F9A',
    borderRadius: '10px',
    border: '1px solid #7E8F9A',
  },
};

export const TASK_STATUS_TYPE = [
  { id: 1, label: 'Pending', value: '1' },
  { id: 2, label: 'In Progress', value: '2' },
  { id: 3, label: 'Declined', value: '3' },
  { id: 4, label: 'Completed', value: '4' },
];

export const TASK_PRIORITY = [
  { id: 2, label: 'High', value: '1' },
  { id: 4, label: 'Medium', value: '2' },
  { id: 3, label: 'Low', value: '3' },
];
export const TASK_URGENCY = [
  { id: 2, label: 'High', value: '1' },
  { id: 4, label: 'Medium', value: '2' },
  { id: 3, label: 'Low', value: '3' },
];
export const VIEW_TASK_FILTERS = {
  assignedByDepartment: {
    label: 'Assigned by',
    key: 'departmentName',
    placeholder: 'Select',
  },
  status: {
    label: 'Status',
    statusType: TASK_STATUS_TYPE,
    key: 'label',
    placeholder: 'Select Status',
  },
  taskUrgency: {
    label: 'Task Urgency',
    taskUrgencyType: TASK_URGENCY,
    key: 'label',
    placeholder: 'Select',
  },
  taskPriority: {
    label: 'Task Priority',
    taskPriorityType: TASK_PRIORITY,
    key: 'label',
    placeholder: 'Select',
  },
  assignedToTeam: {
    label: 'Assigned to Team',
    assignToTeamType: INVITEMEMBERROLE,
    key: 'label',
    placeholder: 'Select',
  },
};
export const TEAMFILTERS = {
  Team: {
    label: 'Team',
  },
  Date: {
    label: 'Date',
    // dateType: FILTERDATETYPE,
    key: 'label',
    placeholder: 'Select Date',
  },
  Role: {
    label: 'Role',
    roleType: INVITEMEMBERROLE,
    key: 'label',
    placeholder: 'Select Role',
  },
  Status: {
    label: 'Status',
    // statusType: TEAMSTATUSTYPE,
    key: 'label',
    placeholder: 'Select Status',
  },
};
export const NOOFMEMBERS = [
  { id: 1, label: 'Less than 10', value: '1' },
  { id: 2, label: 'Between 10 to 50', value: '2' },
  { id: 3, label: 'More than 50', value: '3' },
  { id: 4, label: 'None', value: '' },
];
export const DEPARTMENTTEAMFILTER = {
  Admin: {
    label: 'Admin',
  },
  NoOfMembers: {
    label: 'No Of Members',
    memberType: NOOFMEMBERS,
    key: 'label',
    placeholder: 'Select',
  },
  From: {
    label: 'From',
    key: 'label',
    placeholder: 'DD/MM/YYYY',
  },
  To: {
    label: 'To',
    key: 'label',
    placeholder: 'DD/MM/YYYY',
  },
};

export const MEMBERSTABLEHEADERS = [
  {
    title: 'Member Name',
    sortable: false,
    sortKey: 'memberName',
  },
  {
    title: 'Designation',
    sortable: false,
    sortKey: 'designation',
  },
  {
    title: 'Team Name',
    sortable: false,
    sortKey: 'teamName',
  },
  {
    title: 'Project Name',
    sortable: false,
    sortKey: 'projectName',
  },
  {
    title: '',
    sortKey: 'action',
  },
];

export const membersTableData = (data) => {
  let newData = [];
  if (data.length) {
    newData = data.map((item) => {
      return {
        id: item.id,
        memberName: (
          <MemberNamePicture
            label={item.memberName}
            profilePicture={item.profilePicture}
            isIndividual={item.isIndividual}
            memberUserId={item.memberUserId}
            userType={item.userType}
          />
        ),
        designation:
          typeof item.designation !== 'string' && item.designation[0] !== null
            ? item.designation[0]
            : 'N/A',
        teamName: (
          <NavigationalRowItem
            label={item.teams[0]?.teamName}
            navigateTo={
              item.isIndividual && item.teams[0]?.teamName !== 'N/A'
                ? `/public/team/${item.teams[0]?.id}/details`
                : ''
            }
            isOpenInNewTab
            isIndividualUser={item.isIndividual}
            customStyle={{ color: '#404f67' }}
          />
        ),
        projectName: (
          <NavigationalRowItem
            label={item.projects[0]?.title}
            navigateTo={
              item.isIndividual && item.projects[0]?.title !== 'N/A'
                ? `/public/project/${item.projects[0]?.id}/details`
                : ''
            }
            isOpenInNewTab
            isIndividualUser={item.isIndividual}
            customStyle={{ color: '#404f67' }}
          />
        ),
        action: item.expandable ? <ExpandRowAction memberId={item.id} /> : '',
      };
    });
  } else {
    newData = [];
  }

  return newData;
};

export const ExpandRowAction = ({ memberId }) => {
  return <Icon name="arrow-down-three" size={18} />;
};

export const MemberNamePicture = ({
  label,
  profilePicture,
  isIndividual,
  memberUserId,
  userType,
}) => {
  return (
    <DepartmentMemberNameWrapper>
      <div className="member-class">
        {profilePicture !== null && profilePicture !== '' ? (
          <div className="profile-pic-class">
            <img src={profilePicture} alt="" />
          </div>
        ) : (
          <div className="icon-style-class">
            <Icon name="user-icon" color="#292D32" />
          </div>
        )}
        <NavigationalRowItem
          label={label}
          navigateTo={
            isIndividual
              ? `${userType}` === 'individual'
                ? `/public/individual/${memberUserId}/summary/basic-details`
                : `/public/enterprise/${memberUserId}/details`
              : ''
          }
          isOpenInNewTab
          isIndividualUser={isIndividual}
          customStyle={{ color: '#404f67' }}
        />
      </div>
    </DepartmentMemberNameWrapper>
  );
};

export const DEPARTMENT_MEMBER_FILTERS = {
  Designation: {
    label: 'Designation',
  },
  ProjectName: {
    label: 'Project Name',
  },
  TeamName: {
    label: 'Team Name',
  },
};

export const isValidTaskAttachmentFile = (file) => {
  return TASK_ATTACHMENTS_TYPES.includes(file.type)
    ? true
    : toast.error(`${file.type} is not a valid file`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
};
