import { useState, useEffect, useCallback } from 'react';
import { CustomButton, SearchAndAdd, Title, CustomTable } from 'components';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  CREATE_SKILLS_AND_TECHNOILOGY,
  FETCH_TECHNOLOGY_STACKS,
  FETCH_ERECORD_TECH_STACK,
  useAxiosWrapper,
} from 'services';
import tokenService from 'services/token.service';
import Icon from 'utlils/Icons/Icon';
import './skillAndTech.scss';
import { SKILLS_TECHNOLOGY_HEADERS } from 'pages/IndividualDashboard/IndividualPersonalSection/individualTeams/helper';

export const CreateSkillsAndTechnologyInfo = ({
  title,
  type,
  nextType,
  setCurrType,
  isEditEvent,
}) => {
  const navigate = useNavigate();
  const [techStacks, setTechStacks] = useState([]);
  const [fnWrapper, context] = useAxiosWrapper();
  const [selectedTech, setSelectedTech] = useState({ technology: [] });
  const [selectedRadios, setSelectedRadios] = useState([]);

  const location = useLocation();
  const loggedInUser = tokenService.getSession();

  const getTechnologyStacks = useCallback(
    async () => {
      const res = await fnWrapper({
        url: `${FETCH_TECHNOLOGY_STACKS}`,
        method: 'GET',
        type: 'FETCH_TECHNOLOGY_STACKS',
      });
      if (res.data.statusCode === 200 && Array.isArray(res.data.data)) {
        const stacks = res.data.data.map((item) => ({
          id: item?.id,
          title: item?.name,
          value: item?.id,
        }));
        setTechStacks(stacks);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const onSave = async () => {
    const res = await fnWrapper({
      url: CREATE_SKILLS_AND_TECHNOILOGY,
      method: 'POST',
      type: 'CREATE_SKILLS_AND_TECHNOLOGY',
      payload: {
        userId: loggedInUser.id,
        technologyIds: selectedRadios,
      },
    });
    if (res.data.statusCode === 200) {
      if (isEditEvent) {
        if (location.state !== null && location.state.from === 'public_page') {
          navigate(location.state.redirectTo);
        } else {
          navigate(`/individual/profile/info/skills_and_technology`);
        }
      } else {
        setCurrType(nextType);
      }
    }
  };

  const getERecordTechnologyList = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_ERECORD_TECH_STACK}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_ERECORD_TECH_STACK',
    });
    if (res.data.statusCode === 200) {
      let result = [];

      if (res.data.data.technology) {
        for (const item of res.data.data.technology) {
          const obj = {
            id: item?.id,
            title: item?.title,
            value: item?.id,
            experience: item?.experience,
          };
          result.push(obj);
        }
      }
      setSelectedTech({ technology: result });
      setSelectedRadios(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const radioCheckHandler = (item, header) => {
    const isChecked = selectedRadios.some(
      (radio) => radio.id === item.id && radio.experience === header.title
    );

    return isChecked;
  };

  const handleSearchAndAdd = (selectedItems) => {
    //filter duplicateItems
    const uniqueSelectedItems = selectedItems.filter(
      (item, index, self) => index === self.findIndex((t) => t.id === item.id)
    );

    // Update the selected data
    setSelectedTech({
      ...selectedTech,
      technology: uniqueSelectedItems,
    });

    const selectedItemIds = new Set(uniqueSelectedItems.map((item) => item.id));

    // Update the selectedRadios state to remove the deleted item
    const updatedSelectedRadios = selectedRadios.filter((radio) =>
      selectedItemIds.has(radio.id)
    );

    setSelectedRadios(updatedSelectedRadios);
  };

  const handleRadioSelection = (item, header) => {
    // Create a new radio button object
    const newClickedRadios = {
      id: item.id,
      title: item.technologyName,
      experience: header.title,
    };

    // Filter out any previously clicked radio buttons with the same ID
    const updatedClickedRadios = selectedRadios.filter(
      (radio) => radio.id !== item.id
    );

    // Add the new radio button to the filtered array
    setSelectedRadios([...updatedClickedRadios, newClickedRadios]);
  };

  useEffect(() => {
    getTechnologyStacks();
    getERecordTechnologyList();
  }, [getTechnologyStacks, getERecordTechnologyList]);

  return (
    <div className="skills-tech-container">
      <div className="title">
        <Title title={title} />
      </div>
      {/* body  */}
      <div className="skill-technology-input-field">
        <SearchAndAdd
          label="Enter technologies/skills you are specialised in"
          itemList={techStacks}
          required
          selectedItems={selectedTech?.technology}
          onSearchAndAddHandler={handleSearchAndAdd}
        />
        {selectedTech.technology.length > 0 ? (
          <div>
            <CustomTable
              isLoading={context.busy.status}
              rows={selectedTech.technology.map((item) => ({
                id: item?.id,
                technologyName: item?.title,
              }))}
              headers={SKILLS_TECHNOLOGY_HEADERS}
              handleRadioSelection={handleRadioSelection}
              selectedRadios={selectedRadios}
              radioCheckHandler={radioCheckHandler}
            />
          </div>
        ) : null}
        <div className="row mx-md-3">
          {!isEditEvent && <div className="col-md-4 col-2"></div>}
          <div className={!isEditEvent ? 'col-md-4 col-6' : 'col-md-12 col-12'}>
            <div className="save-and-next-btn">
              <div className="save-btn">
                <CustomButton
                  className="w-100"
                  label="Save"
                  onClick={onSave}
                  disabled={
                    selectedTech.technology.length === 0 ||
                    selectedRadios.length !== selectedTech.technology.length
                  }
                  loading={context.busy.status}
                />
              </div>
              <div className="next-btn">
                <CustomButton
                  className="w-100"
                  variant="textBtn"
                  color="themeGray"
                  onClick={() => {
                    setCurrType(nextType);
                    navigate(`/individual/profile/${type}/${nextType}`);
                  }}
                  loading={context.busy.status}>
                  Next
                  <Icon name="arrow-forward" />
                </CustomButton>
              </div>
            </div>
          </div>
          {!isEditEvent && (
            <div className="col-md-4 col-4 d-flex align-items-center justify-content-end">
              <div className="skip-btn">
                <CustomButton
                  className="w-100"
                  variant="textBtn"
                  color="themeGray"
                  onClick={() => setCurrType(nextType)}>
                  Skip
                  <Icon name="arrow-right" size={20} color="#404F67" />
                </CustomButton>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
