import { useState, useCallback, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  CopyWithIcon,
  CustomButton,
  CustomDropDown,
  CustomTable,
  ModalPopup,
  SlidingButton,
} from 'components';
import {
  GET_PROJECT_BIDDING_LIST,
  GET_PROJECT_COLLABORATION,
  UPDATE_COLLABORATION_STATUS,
  useAxiosWrapper,
} from 'services';
import { EditCollaborationWrapper, ViewColabWrapper } from '../style';
import Icon from 'utlils/Icons/Icon';
import { CreateCollaboration } from './CreateCollaboration';
import { getPlaceholderItems } from 'utlils/utils';
import { COLLABORATION_TABLE_HEADERS, projectAccessType } from '../constant';
import dayjs from 'dayjs';
import arrowCircle from '../../../../../assests/images/arrow-circle-right.png';
import collaborationSuccessImg from 'assests/images/collaborationPopup.png';
import SearchEngineBro from 'assests/images/search-engine-bro.png';
import { toast } from 'react-toastify';
import { capitalize } from 'lodash';
import { CreateBidding } from '../../ProjectBidding/components/CreateBidding';
import BiddingList from '../../ProjectBidding/components/BiddingList/BiddingList';
import { isMobile } from 'utlils';

const DEFAULT_COLLABORATION_ACTION = {
  type: '',
  isModalOpen: false,
  data: null,
};

const DEFAULT_BIDDING_ACTION = {
  type: '',
  isModalOpen: false,
  data: null,
};

const projectTypes = [
  { label: 'Collaboration', value: projectAccessType.COLLABORATION },
  { label: 'Bidding', value: projectAccessType.BIDDING },
];

export const ViewProjectCollaboration = (props) => {
  const { isProjectPublished, projectSettings } = props;
  const navigate = useNavigate();
  let location = useLocation();

  const [fnWrapper, context] = useAxiosWrapper();
  const [collaboartionTableData, setCollaborationTableData] = useState([]);
  const [isCollaborationSuccess, setIsCollaborationSuccess] = useState(false);
  const [id, setId] = useState(null);
  const [biddingTableData, setBiddingTableData] = useState([]);
  const [collaborationAction, setCollaborationAction] = useState(
    DEFAULT_COLLABORATION_ACTION
  );
  const [biddingAction, setBiddingAction] = useState(DEFAULT_BIDDING_ACTION);
  const [selectedItem, setSelectedItem] = useState(
    location?.state?.type
      ? projectTypes.find((ele) => ele.value === location?.state?.type)
      : null
  );
  const { projectId } = useParams();

  const renderList = () => {
    if (projectSettings?.isBidding && projectSettings?.isCollaboration) {
      return projectTypes;
    }
    if (projectSettings?.isBidding && !projectSettings.isCollaboration) {
      return projectTypes.filter(
        (ele) => ele.value === projectAccessType.BIDDING
      );
    }
    if (!projectSettings?.isBidding && projectSettings?.isCollaboration) {
      return projectTypes.filter(
        (ele) => ele.value === projectAccessType.COLLABORATION
      );
    }
    return [];
  };

  const getCollaborationProjectsList = useCallback(async () => {
    const res = await fnWrapper({
      url: `${GET_PROJECT_COLLABORATION}`,
      method: 'GET',
      params: { projectId: projectId },
      type: 'GET_PROJECT_COLLABORATION',
    });
    if (res.data.statusCode === 200) {
      const result = res.data.data;
      setCollaborationTableData(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const getBiddingProjectsList = useCallback(async () => {
    const res = await fnWrapper({
      url: `${GET_PROJECT_BIDDING_LIST}/${projectId}`,
      method: 'GET',
      type: 'GET_PROJECT_BIDDING_LIST',
    });
    if (res.data.statusCode === 200) {
      const result = res.data.data;
      setBiddingTableData(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (
      selectedItem &&
      selectedItem?.value === projectAccessType.COLLABORATION
    ) {
      getCollaborationProjectsList();
    }
    if (selectedItem && selectedItem?.value === projectAccessType.BIDDING) {
      getBiddingProjectsList();
    }
  }, [getBiddingProjectsList, getCollaborationProjectsList, selectedItem]);

  const openPopupHandler = (type, data) => {
    if (type === 'EDIT-COLLABORATION' || type === 'ADD-COLLABORATION') {
      setCollaborationAction({
        data: data,
        isModalOpen: true,
        type: type,
      });
    } else {
      setBiddingAction({
        data: data,
        isModalOpen: true,
        type: type,
      });
    }
  };

  const collaborationTableData = (data) => {
    let newData = [];
    if (data.length) {
      newData = data.map((item) => {
        return {
          id: item.id,
          collaborationType: item.collaborationType,
          collaborationCategory: item.collaborationCategory,
          openDate: dayjs(item.openDate).format('DD MMM YYYY'),
          requests:
            item?.collaborationApplicants?.length === 0
              ? 0
              : item?.collaborationApplicants?.filter(
                  (applicant) =>
                    applicant?.ProjectCollaborationApplicant?.isActive !== 0
                )?.length,
          edit: (
            <EditCollaborationWrapper
              onClick={() =>
                openPopupHandler(`EDIT-${selectedItem.value}`, item)
              }>
              <Icon className="edit-jobpost" name="edit" />
            </EditCollaborationWrapper>
          ),
          isOpen: (
            <SlidingButton
              isSliding={item.isOpen}
              className="is-job-open"
              onClick={() => onUpdateCollaborationStatus(item.id, !item.isOpen)}
            />
          ),
          action: (
            <img
              key={projectId}
              src={arrowCircle}
              alt="arrow-right"
              className="nav-img"
              onClick={() => {
                navigate(
                  `${selectedItem.value.toLowerCase()}/applicants/${item.id}`
                );
              }}
            />
          ),
        };
      });
    } else {
      newData = [];
    }

    return newData;
  };

  const onUpdateCollaborationStatus = async (id, status) => {
    const res = await fnWrapper({
      url: UPDATE_COLLABORATION_STATUS,
      method: 'PUT',
      type: 'UPDATE_COLLABORATION_STATUS',
      payload: { collaborationId: id, isOpen: status },
    });
    if (res.data.statusCode === 200) {
      toast.success(res.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      getCollaborationProjectsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const allCollaborationData =
    context.busy.status && context.busy.type === 'GET_PROJECT_COLLABORATION'
      ? getPlaceholderItems(
          COLLABORATION_TABLE_HEADERS.map(({ sortKey }) => sortKey),
          4,
          context.busy.status &&
            context.busy.type === 'GET_PROJECT_COLLABORATION'
        )
      : collaborationTableData(collaboartionTableData);
  return (
    <ViewColabWrapper>
      {isProjectPublished ? (
        <div className="view-colab-container">
          <div className="d-flex justify-content-between">
            <div className="view-colab-container__header">
              {selectedItem ? capitalize(selectedItem?.value) : null}
            </div>
            <div className="view-colab-container__dropdwn-content d-flex">
              {selectedItem !== null && (
                <>
                  <CustomButton
                    className="view-colab-container__add-btn"
                    variant="light"
                    color="themeSecondary"
                    onClick={() =>
                      openPopupHandler(`ADD-${selectedItem.value}`, null)
                    }>
                    <Icon name="add" color="#219FFF" size={20} />
                    {selectedItem && selectedItem.value === 'COLLABORATION'
                      ? 'New Collaboration'
                      : 'New Bidding'}
                  </CustomButton>
                </>
              )}
              <CustomDropDown
                optionLists={renderList()}
                selectedItem={selectedItem}
                setSelectedItem={setSelectedItem}
                height="36px"
                customStyle={{
                  backgroundColor: '#EBEBEB',
                  color: '#404F67',
                  padding: '10px 8px 10px 16px',
                  whiteSpace: 'normal',
                }}
                placeholder={'Select Collab/Bid'}
              />
            </div>
          </div>
          {/* table */}
          {selectedItem === null ? (
            <div className="no-colab">
              Select collaboration or bidding option from the dropdown to see
              the active collaboration or bidding list
            </div>
          ) : selectedItem.value === 'COLLABORATION' ? (
            collaboartionTableData.length === 0 ? (
              <div className="no-colab">
                Seems like you don’t have any collaboration yet. Create a new
                collaboration now.
              </div>
            ) : (
              <div className="table-style">
                <CustomTable
                  isLoading={
                    context.busy.status &&
                    context.busy.type === 'GET_PROJECT_COLLABORATION'
                  }
                  rows={allCollaborationData}
                  headers={COLLABORATION_TABLE_HEADERS}
                />
              </div>
            )
          ) : (
            <BiddingList
              getBiddingProjectsList={getBiddingProjectsList}
              projectId={projectId}
              context={context}
              biddingTableData={biddingTableData}
              selectedItem={selectedItem}
            />
          )}
        </div>
      ) : (
        <div className="no-colaboration-found">
          <img src={SearchEngineBro} alt="" />
          <div className="mt-3">
            Your project is not open for collaboration or bidding You can change
            it under the settings.
          </div>
        </div>
      )}

      {collaborationAction.isModalOpen && (
        <ModalPopup
          open={false}
          onClose={() => setCollaborationAction(DEFAULT_COLLABORATION_ACTION)}
          minWidth="1000px"
          maxContentWidth="1000px"
          hasTitle
          title={
            collaborationAction.type === 'ADD-COLLABORATION'
              ? 'New Collaboration'
              : 'Edit Collaboration'
          }>
          <CreateCollaboration
            setIsCollaborationSuccess={setIsCollaborationSuccess}
            collaborationActionState={collaborationAction}
            projectId={projectId}
            setId={setId}
            onClose={() => setCollaborationAction(DEFAULT_COLLABORATION_ACTION)}
            getCollaborationProjectsList={getCollaborationProjectsList}
          />
        </ModalPopup>
      )}
      {isCollaborationSuccess && (
        <ModalPopup
          open={false}
          onClose={() => setIsCollaborationSuccess(false)}
          minWidth="650px"
          maxContentWidth="650px"
          hasTitle
          title="New Collaboration">
          <div className="collaboration-popup">
            <div className="collaboration-success-image">
              <img
                src={collaborationSuccessImg}
                alt="publish-img"
                width={isMobile() ? '300px' : ''}
              />
            </div>
            <div className="collaboration-popup-button-text">
              <p className="m-0">
                Your collaboration is live now. You can check it out here.
              </p>
              <a
                href={`${process.env.REACT_APP_BASE_URL}/public/project/${projectId}/details?collaborationId=${id}`}
                target="_blank"
                rel="noreferrer">
                {`${process.env.REACT_APP_BASE_URL}/public/project/${projectId}/details?collaborationId=${id}`}
              </a>
              <div>
                <CopyWithIcon
                  id={projectId}
                  label={'Copy collaboration link'}
                  textToCopy={`${process.env.REACT_APP_BASE_URL}/public/project/${projectId}/details?collaborationId=${id}`}
                />
              </div>
            </div>
          </div>
        </ModalPopup>
      )}
      {biddingAction.isModalOpen && (
        <CreateBidding
          biddingActionState={biddingAction}
          projectId={projectId}
          onClose={() => setBiddingAction(DEFAULT_BIDDING_ACTION)}
          getBiddingProjectsList={getBiddingProjectsList}
        />
      )}
    </ViewColabWrapper>
  );
};
