import axios from 'axios';
import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import axiosInstance from './interceptor';

export const useAxiosWrapper = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState('');
  const [busy, setBusy] = useState({ status: false, type: '' });
  const controllerRef = useRef(new AbortController());

  const axiosFunctionWrapper = async ({
    url,
    method,
    payload,
    type = '',
    params,
    isSkippedInterceptor = false,
  }) => {
    setBusy({ status: true, type: type });
    try {
      let fnType = isSkippedInterceptor ? axios : axiosInstance;
      const response = await fnType.request({
        data: payload,
        signal: controllerRef.current.signal,
        method,
        url,
        params,
      });
      setData(response);
      setBusy({ status: false, type: '' });
      return response;
    } catch (error) {
      setError(error?.response?.data.message || error.message);
      setBusy({ status: false, type: '' });
      toast.error(error?.response?.data.message || error.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    } finally {
      setBusy({ status: false, type: '' });
    }
  };

  return [axiosFunctionWrapper, { data, error, busy }];
};
