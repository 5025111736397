import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { VERIFY_EMAIL, useAxiosWrapper } from 'services';
import axios from 'axios';
import { Box, OtpBox, Title } from 'components';
import './emailConfirmation.scss';

const EmailConfirmation = () => {
  let navigate = useNavigate();
  const [fnWrapper, context] = useAxiosWrapper();

  let location = useLocation();

  const [userDetails, setUserDetails] = useState(null);
  const [otpState, setOtpState] = useState({
    otp1: '',
    otp2: '',
    otp3: '',
    otp4: '',
  });

  useEffect(() => {
    const data = localStorage.getItem('signup-user-details');
    setUserDetails(JSON.parse(data));
  }, []);

  const inputFocus = (element, otp) => {
    const re = /^[0-9\b]+$/;
    if (element.key === 'Delete' || element.key === 'Backspace') {
      setOtpState({
        ...otpState,
        [otp]: '',
      });
      const next = element.target.tabIndex - 2;
      if (next > -1) {
        element.target.form.elements[next].focus();
      }
    } else {
      if (element.key === 'ArrowLeft') {
        const next = element.target.tabIndex - 2;
        if (next > -1) {
          element.target.form.elements[next].focus();
        }
      } else {
        const next = element.target.tabIndex;
        if (next < 4 && re.test(element.target.value)) {
          element.target.form.elements[next].focus();
        }
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await fnWrapper({
        url: VERIFY_EMAIL,
        method: 'POST',
        type: 'VERIFY_EMAIL',
        payload: {
          otp: otpState.otp1 + otpState.otp2 + otpState.otp3 + otpState.otp4,
          authenticationToken: userDetails.emailAuthenticationToken,
          hashKey: userDetails.hashKey,
          userId: userDetails.id,
          isVerifyByLink: false,
        },
      });

      if (res.data.statusCode === 200) {
        navigate('/login/selectOrganization', {
          replace: true,
          state: location.state,
        });
        localStorage.removeItem('signup-user-details');
        toast.success('Your email is verified successfully.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        if (res.data.message === 'User has been already verified') {
          navigate('/login/selectOrganization', {
            replace: true,
            state: location.state,
          });
          localStorage.removeItem('signup-user-details');
        }
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  const onChange = (e, otp) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setOtpState({
        ...otpState,
        [otp]: e.target.value,
      });
    }
  };

  const resendEmailHandler = async () => {
    try {
      const data = {
        id: userDetails.id,
      };
      await axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/resendEmailVerification`,
          data
        )
        .then((data) => {
          if (data.data.statusCode === 200) {
            setUserDetails(data.data.data);
            localStorage.setItem(
              'signup-user-details',
              JSON.stringify(data.data.data)
            );
            toast.success('Email sent successfully', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored',
            });
          }
        })
        .catch((err) => {
          if (
            err.response.data.message === 'Your email has been already verified'
          ) {
            navigate('/login/selectOrganization', {
              replace: true,
              state: location.state,
            });
            localStorage.removeItem('signup-user-details');
          }
          toast.error(err.response.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        });
    } catch (error) {
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  return (
    <Box className="container-fluid ">
      <div className="row confirm-email-container">
        <div className=" col-lg-7 ">
          {/* new code */}
          <div className="container">
            <div className="row">
              <div className="col-md-3 "></div>
              <div className="col-md-8 ">
                <Box className="confirm-email-wrapper">
                  {/* logo */}
                  <Box mb="78px" mt="33px">
                    <img
                      src={process.env.PUBLIC_URL + '/new-logo.png'}
                      className="img common-navbar-logo"
                      alt="logo"
                    />
                  </Box>
                  {/* title and subtitle */}
                  <Box mb="40px">
                    <Title
                      title="Confirm the email & a link to sign in"
                      subTitle={[
                        'We emailed a confirmation link to  ',
                        <strong className="confirm-email-txt">
                          {userDetails?.email}.
                        </strong>,
                        ' Check your email for a link to sign in.',
                      ]}
                    />
                  </Box>

                  <h6>
                    <span className="confirm-email-txt">
                      Did not get a confirmation email?
                      <span className="resend-txt" onClick={resendEmailHandler}>
                        {' '}
                        &nbsp;Resend{' '}
                      </span>
                    </span>
                    <p className="spam-txt">Check your spam folder.</p>
                  </h6>
                  {/* OTP */}
                  <h6 className="enter-code-txt">Enter code</h6>
                  <Box width="320px">
                    <OtpBox
                      otpState={otpState}
                      inputFocus={inputFocus}
                      handleSubmit={(e) => handleSubmit(e)}
                      onChange={onChange}
                      context={context}
                    />
                  </Box>
                  <h6>
                    <span className="help-txt">Need help</span>
                    <p className="feedback-txt">
                      Please send and feedback or bug info to{' '}
                      <span className="confirm-email-txt">
                        info@linkteamsup.com
                      </span>
                    </p>
                  </h6>
                </Box>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
          {/* new code end */}
        </div>
        <div className="col-lg-5 d-none d-sm-none d-lg-block image-wrapper ">
          {/* right side image */}
          <div className="right-img"></div>
        </div>
      </div>
    </Box>
  );
};

export default EmailConfirmation;
