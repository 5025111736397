import React from 'react';
import styled from 'styled-components';
import Icon from 'utlils/Icons/Icon';
import { CustomButton } from 'components';
import { useNavigate } from 'react-router-dom';
import { cropText } from 'helpers';

export default function ExploreGroupCard(props) {
  const { groupDetails } = props;
  const croppedDescription = cropText(groupDetails?.description, 18);

  const navigate = useNavigate();
  return (
    <ExploreGroupCardWrapper>
      <div className="group-intro">
        <div className="image">
          {groupDetails?.profilePicture ? (
            <div className="group-picture">
              <img className="img" src={groupDetails?.profilePicture} alt="" />
            </div>
          ) : (
            <div className="icon">
              <Icon color="black" name={'project'} />
            </div>
          )}
        </div>
        <div className="info">
          <div className="group-name">{groupDetails?.groupName}</div>
          <div className="group-creator-name">
            {groupDetails?.user?.individual?.name}
          </div>
        </div>
      </div>
      <div className="category-type">
        <div className="group-category">{groupDetails?.category}</div>
        <div className="group-type">{'PUBLIC'}</div>
      </div>
      <div className="description">{croppedDescription}</div>
      <div className="view-save-button">
        <CustomButton
          className="w-100"
          label="View"
          variant="outline"
          onClick={() =>
            navigate(`/explore/groups/view-group/${groupDetails?.id}`)
          }
        />
      </div>
    </ExploreGroupCardWrapper>
  );
}

const ExploreGroupCardWrapper = styled.div`
  width: 30%;
  height: 275px;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  padding: 15px;
  @media (min-width: 900px) and (max-width: 1499px) {
    width: 45%;
  }

  @media (max-width: 899px) {
    width: 100%;
  }
  .group-intro {
    display: flex;
    gap: 5px;
    align-items: center;
    & .image {
      width: 45px;
      height: 45px;
      & .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background-color: #e9fbf6;
      }
      & .group-picture {
        width: 100%;
        height: 100%;
        & .img {
          width: 100%;
          height: 100%;
          border-radius: 100%;
        }
      }
    }
    & .info {
      & .group-name {
        font-size: 16px;
        font-weight: 500;
      }
      & .group-creator-name {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  .category-type {
    padding-top: 15px;
    display: flex;
    & .group-category {
      padding-right: 10px;
      border-right: 2px solid #404f6733;
    }
    & .group-type {
      padding-left: 10px;
    }
  }
  .description {
    font-size: 14px;
    padding-top: 10px;
    font-weight: 400;
    height: 100px;
    overflow: hidden;
  }
  .view-save-button {
    display: flex;
    align-items: center;
    gap: 5px;
  }
`;
