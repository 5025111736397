import { useEffect, useState } from 'react';
import { getMonthNameFromNumber } from '../utils';
import { sortByDate } from 'helpers';
import MediaCard from './Components/MediaCard';
import { ViewMediaWrapper } from './style';

const ViewMedia = ({ mediaList }) => {
  const [groupedData, setGroupedData] = useState({});

  useEffect(() => {
    if (mediaList) {
      setGroupedData(
        mediaList?.reduce((acc, obj) => {
          const year = obj.date.year;
          const month = obj.date.month;
          const key = `${year}-${month}`;
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(obj);
          return acc;
        }, {})
      );
    }
  }, [mediaList]);

  return (
    <ViewMediaWrapper>
      {groupedData &&
        Object.keys(groupedData)
          .sort(sortByDate)
          .map((yearMonth) => (
            <div key={yearMonth} className="main-content">
              <p className="month-label">{getMonthNameFromNumber(yearMonth)}</p>
              <div className="media-list">
                {groupedData[yearMonth]?.map((mediaItem, index) => (
                  <MediaCard key={index} mediaItem={mediaItem} />
                ))}
              </div>
            </div>
          ))}
    </ViewMediaWrapper>
  );
};

export default ViewMedia;
