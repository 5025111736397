import dayjs from 'dayjs';
import { LoadingSkeleton } from '../components/LoadingSkeleton';

export const dummyFn = (skip_call) => {
  if (!skip_call) {
    // eslint-disable-next-line no-console
    console.error(
      '😵 No Function was provided to handler, running dummy function. 😵'
    );
  }
};

const mockUUID = () => {
  // Note: We need mockUUID inside UI as using from common is circular
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : r & (0x3 | 0x8);

    return v.toString(16);
  });
};
export const getPlaceholderItems = (headers = [], count = 0, loading) => {
  return Array.from(
    { length: count ? count : Math.floor(Math.random() * 6) + 4 },
    (v, i) => i
  ).map(() => {
    const row_item = {};
    headers.forEach((header) => {
      row_item.id = mockUUID();
      row_item[header] = (
        <LoadingSkeleton speed={'3.5s'} width={loading ? '100%' : ''}>
          <span className={`${header} skeleton`} key={row_item.id} />
        </LoadingSkeleton>
      );
    });
    return row_item;
  });
};

export const getProfileText = (name = '') => {
  const items = name
    .split(' ')
    .map((item) => item.toUpperCase().substring(0, 1));
  const first_letter = items[0];
  const last_letter = items[items.length - 1];
  if (items.length === 1) {
    return first_letter;
  } else {
    const res = [first_letter, last_letter].join('');
    return res;
  }
};

export const getDateTime = (time) => {
  let DateTime = dayjs(new Date(time)).format('D MMM, YYYY h:mm A');
  return DateTime;
};

export const truncateString = (string, length = 15) => {
  if (typeof string === 'string' && typeof length === 'number') {
    return string.length > length
      ? string.substring(0, length - 3) + '...'
      : string;
  } else {
    return null;
  }
};
export const replaceSpaceWithUnderscore = (value) => {
  if (typeof value === 'string') {
    return value.toUpperCase().replace(' ', '_');
  } else return '';
};
export const FLAG_URL =
  'https://purecatamphetamine.github.io/country-flag-icons/3x2/{XX}.svg';
