import { CustomButton, Loader, ModalPopup, ScrollableDiv } from 'components';
import React from 'react';
import { ViewApplicationListWrapper } from './style';
import { useCallback } from 'react';
import {
  GET_APPLICATION_LIST,
  WITHDRAW_BIDDING_APPLICATION,
  WITHDRAW_COLLABORATION_APPLICATION,
  useAxiosWrapper,
} from 'services';
import tokenService from 'services/token.service';
import { useEffect } from 'react';
import { useState } from 'react';
import dayjs from 'dayjs';
import applicationimg from '../../../assests/images/application-img.png';
import { toast } from 'react-toastify';
import ViewCollaborationApplication from './ViewCollaborationApplication';
import ViewBiddingApplication from './ViewBiddingApplication';

export default function ViewApplicationListPopup() {
  const [fnWrapper, context] = useAxiosWrapper();
  const [applicationListData, setApplicationListData] = useState([]);
  const [
    showViewCollaborationApplication,
    setShowViewCollaborationApplication,
  ] = useState(false);
  const [showViewBiddingApplication, setShowViewBiddingApplication] =
    useState(false);
  const [applicantId, setApplicantId] = useState(null);

  const getAllApplicationList = useCallback(async () => {
    const loggedInUser = tokenService.getSession();
    const res = await fnWrapper({
      url: GET_APPLICATION_LIST,
      method: 'GET',
      type: 'GET_APPLICATION_LIST',
      params: { userId: loggedInUser.id },
    });
    if (res.data.statusCode === 200) {
      const result = res.data.data;
      setApplicationListData(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onWithdrawApplication = async (type, applicantId) => {
    let biddingPayload = {
      bidderId: applicantId,
    };
    let collaborationPayload = {
      applicantId: applicantId,
    };
    const res = await fnWrapper({
      url:
        type === 'COLLABORATION'
          ? WITHDRAW_COLLABORATION_APPLICATION
          : WITHDRAW_BIDDING_APPLICATION,
      method: 'PUT',
      type:
        type === 'COLLABORATION'
          ? 'WITHDRAW_COLLABORATION_APPLICATION'
          : 'WITHDRAW_BIDDING_APPLICATION',
      payload: type === 'COLLABORATION' ? collaborationPayload : biddingPayload,
    });

    if (res.data.statusCode === 200) {
      await getAllApplicationList();
      toast.success(res.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  useEffect(() => {
    getAllApplicationList();
  }, [getAllApplicationList]);

  const onOpenViewApplication = (type, id) => {
    setApplicantId(id);
    if (type === 'COLLABORATION') {
      setShowViewCollaborationApplication(true);
    } else {
      setShowViewBiddingApplication(true);
    }
  };

  const onApplicationPopupClose = (type) => {
    if (type === 'COLLABORATION') {
      setShowViewCollaborationApplication(false);
    } else {
      setShowViewBiddingApplication(false);
    }
  };

  return (
    <ViewApplicationListWrapper>
      <div className="application-list-popup-content">
        <ScrollableDiv
          scrollOnHeight="calc(100vh - 320px)"
          scrollOnHeightMob="300px">
          {context.busy.status ? (
            <Loader loading={context.busy.status} />
          ) : (
            <div className="list-content">
              {applicationListData?.length ? (
                applicationListData?.map((elem, index) => {
                  return (
                    <div key={index}>
                      <div className="d-flex justify-content-between">
                        <div className="d-flex gap-1 text-alignment">
                          <img
                            src={applicationimg}
                            alt="Logo"
                            className="application-img"
                          />
                          <div className="content-text">
                            Your application to{' '}
                            {elem.type === 'COLLABORATION'
                              ? 'collaborate'
                              : 'bid'}{' '}
                            with{' '}
                            <span className="text-bold">
                              {elem.type === 'COLLABORATION'
                                ? elem?.appliedCollaboration?.projects?.title
                                : elem?.appliedBidding?.project?.title}
                            </span>
                          </div>
                        </div>
                        <div className="date-text">
                          {dayjs(elem.createdAt).format('DD MMM YYYY')}
                        </div>
                      </div>
                      <div className="button-content d-flex gap-2">
                        <div className="popup-btn">
                          <CustomButton
                            label={'View Application'}
                            color="themeSecondary"
                            onClick={() =>
                              onOpenViewApplication(elem.type, elem?.id)
                            }
                            className="w-100"
                          />
                        </div>
                        <div className="popup-btn w-btn">
                          <CustomButton
                            label={'Withdraw'}
                            color="themeTertiary"
                            variant="light"
                            onClick={() =>
                              onWithdrawApplication(elem.type, elem?.id)
                            }
                            className="w-100"
                          />
                        </div>
                      </div>
                      <hr
                        className={
                          applicationListData?.length - 1 === index
                            ? ''
                            : 'underline'
                        }
                      />
                    </div>
                  );
                })
              ) : (
                <div>No application available</div>
              )}
            </div>
          )}
        </ScrollableDiv>
      </div>
      {showViewCollaborationApplication && (
        <ModalPopup
          open={false}
          onClose={() => onApplicationPopupClose('COLLABORATION')}
          title="View application"
          minWidth="800px"
          maxContentWidth="800px">
          <ViewCollaborationApplication
            applicantId={applicantId}></ViewCollaborationApplication>
        </ModalPopup>
      )}
      {showViewBiddingApplication && (
        <ModalPopup
          open={false}
          onClose={() => onApplicationPopupClose('BIDDING')}
          title="View application"
          minWidth="800px"
          maxContentWidth="800px">
          <ViewBiddingApplication
            applicantId={applicantId}></ViewBiddingApplication>
        </ModalPopup>
      )}
    </ViewApplicationListWrapper>
  );
}
