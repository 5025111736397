import { OnBoardPopUp } from './OnBoardPopUp';
import {
  CustomButton,
  CustomTable,
  Loader,
  ModalPopup,
  OptionWrapper,
  SearchInput,
} from 'components';
import React, { useCallback, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  UPDATE_SHORTLIST_CANDIDATE,
  FETCH_POSTED_JOBS,
  FETCH_SHORTLISTED_APPLICANTS,
  UPDATE_CANDIDATE_CONTACTED_STATUS,
  SEND_INVITE_TO_SELECTED_JOB_APPLICANT,
  FETCH_DEPARTMENT_NAMES,
  FETCH_TEAMS_BASED_ON_DEPARTMENT_ID,
  useAxiosWrapper,
} from 'services';
import Icon from 'utlils/Icons/Icon';
import noDataImg from 'assests/images/noProjects.png';
import AirPlane from 'assests/images/paper-plane.png';
import { SHORTLISTED_APPLICANT_LIST_TABLE_HEADERS } from '../constant';
import { getPlaceholderItems } from 'utlils/utils';
import { shortlistedApplicantListTableData } from '../helper';
import tokenService from 'services/token.service';
import { toast } from 'react-toastify';
import { SelectDepartmentAndTeam } from '../PostedJobs/selectDepartmentAndTeam';
import { ViewApplicant } from '../PostedJobs/ViewApplicant/viewApplicant';
import { useContext } from 'react';
import { RoleBasedAccessContext } from 'context/RoleBasedAccess/roleBasedAccessContext';
import { IsPermitted, userScopes } from 'utlils';

export const Shortlisted = ({ isCareerEnterprise = false }) => {
  const { roleBasedAccessState } = useContext(RoleBasedAccessContext);

  const [fnWrapper, context] = useAxiosWrapper();
  const [dropItems, setDropItems] = useState([]);
  const [applicantList, setApplicantList] = useState([]);
  const [viewApplicantPopup, setViewApplicantPopup] = useState({
    isModalOpen: false,
    data: null,
  });
  const [isOnboardingPopup, setIsOnboardingPopup] = useState(false);
  const [viewContactPopup, setViewContactPopup] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isDropdown, setIsDropdown] = useState(false);
  const [selectedApplicants, setSelectedApplicants] = useState([]);
  const [applicantsFromOnboard, setApplicantsFromOnboard] = useState([]);
  const [contactDetails, setContactDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currDepartmentAndTeam, setCurrDepartmentAndTeam] = useState({
    department: '',
    team: '',
  });

  let pageSize = 6;
  const [selectedDropItem, setSelectedDropdownItem] = useState({});
  const { teamId } = useParams();
  const [departmentList, setDepartmentList] = useState(null);
  const [teamList, setTeamList] = useState(null);

  const onRowCheckedHandler = (element) => {
    if (selectedRow.length) {
      const index = selectedRow.filter((ele) => ele.id === element.id);
      if (index.length) {
        const filteredItems = selectedRow.filter(
          (ele) => ele.id !== element.id
        );
        const filteredApplicants = selectedApplicants.filter(
          (appl) => appl.id !== element.id
        );
        setSelectedRow(filteredItems);
        setSelectedApplicants(filteredApplicants);
      } else {
        setSelectedRow([...selectedRow, element]);
        const current = applicantList.filter((a) => a.id === element.id);
        setSelectedApplicants([...selectedApplicants, ...current]);
      }
    } else {
      const current = applicantList.filter((a) => a.id === element.id);
      setSelectedApplicants([...selectedApplicants, ...current]);
      setSelectedRow([...selectedRow, element]);
    }
  };

  const hasItem = (arr, item) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].id === item.id) return true;
    }
    return false;
  };

  const onAllRowCheckedHandler = (element) => {
    const data = element.filter((a) => a.isCheckDisabled !== true);
    const currentApplicants = applicantList.filter((a) => hasItem(data, a));
    setSelectedRow(data);
    setSelectedApplicants(currentApplicants);
  };

  const getJobsPerTeam = useCallback(async (args) => {
    const { teamIdFromProps } = args || {};
    let team_id = teamIdFromProps ? teamIdFromProps : teamId;
    let item = {};
    if (team_id) {
      const res = await fnWrapper({
        url: `${FETCH_POSTED_JOBS}/${team_id}?page=${currentPage}&size=${pageSize}`,
        method: 'GET',
        type: 'FETCH_JOBS_BASED_ON_TEAM',
      });
      if (res.data.statusCode === 200) {
        const result = res.data.data;
        let jobs = [];
        result?.items?.map((job) => {
          item = {
            id: job?.id,
            title: job?.title,
          };
          if (isCareerEnterprise) {
            item = { ...item, teamId: job?.teamId };
          }
          jobs.push(item);
          return jobs;
        });
        setDropItems(jobs);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //console.log(dropItems);

  useEffect(() => {
    if (!isCareerEnterprise) {
      getJobsPerTeam();
    }
  }, [getJobsPerTeam, isCareerEnterprise]);

  const getShortlistedApplicantListHelper = () => {
    if (selectedDropItem.id) {
      if (isCareerEnterprise) {
        getShortlistedApplicantList({
          jobId: selectedDropItem.id,
          teamIdFromProps: currDepartmentAndTeam?.team?.id,
        });
      } else {
        getShortlistedApplicantList({
          jobId: selectedDropItem.id,
        });
      }
    }
  };

  /* get department with teams --- */
  const getDepartmentsList = useCallback(async () => {
    const loggedInUser = tokenService.getSession();
    let list = [];
    if (isCareerEnterprise) {
      const res = await fnWrapper({
        url: `${FETCH_DEPARTMENT_NAMES}/${loggedInUser.id}`,
        method: 'GET',
        type: 'FETCH_DEPARTMENT_NAMES',
      });
      if (res.data.statusCode === 200) {
        res.data.data.map((item) =>
          list.push({
            id: item.id,
            label: item.departmentName,
            value: item.departmentName,
          })
        );
        setDepartmentList(list);
        setDropItems([]);
      } else {
        setDepartmentList(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTeamListBasedonDepartment = async (id) => {
    let list = [];
    if (isCareerEnterprise) {
      const res = await fnWrapper({
        url: `${FETCH_TEAMS_BASED_ON_DEPARTMENT_ID}/${id}`,
        method: 'GET',
        type: 'FETCH_TEAMS',
      });
      if (res.data.statusCode === 200) {
        res.data.data.teams.map((item) =>
          list.push({
            enterpriseId: item.enterpriseId,
            id: item.id,
            label: item.teamName,
            value: item.teamName,
          })
        );
        setTeamList(list);
      }
    }
  };

  useEffect(() => {
    getDepartmentsList();
  }, [getDepartmentsList]);

  const handleDropItemClick = async (item) => {
    if (isCareerEnterprise) {
      await getShortlistedApplicantList({
        jobId: item.id,
        teamIdFromProps: currDepartmentAndTeam?.team?.id,
      });
    } else {
      await getShortlistedApplicantList({
        jobId: item?.id,
      });
    }
    setSelectedDropdownItem(item);
    setIsDropdown(false);
  };

  /* get Applicant List */
  const getShortlistedApplicantList = useCallback(
    async (args) => {
      const { jobId, teamIdFromProps, search } = args || {};
      const team_id = teamIdFromProps ? teamIdFromProps : teamId;
      const res = await fnWrapper({
        url: `${FETCH_SHORTLISTED_APPLICANTS}/${team_id}?jobId=${jobId}&page=${currentPage}&size=${pageSize}&search=${
          search ? search : ''
        }`,
        method: 'GET',
        type: 'FETCH_APPLICANT_DETAILS_BASED_ON_JOBID',
      });
      if (res.data.statusCode === 200) {
        const result = res.data.data;
        if (result.items.length === 0 && currentPage !== 1) {
          setCurrentPage(currentPage - 1);
        } else {
          setCurrentPage(result.currentPage);
          const newResult = result?.items?.sort((a, b) =>
            a.applicant?.userName > b.applicant?.userName ? 1 : -1
          );
          setApplicantList(newResult);
        }
      } else {
        setApplicantList([]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage, pageSize]
  );

  /* shortlist applicant */
  const shortlistCandidate = async (id, isShortlisted) => {
    const loggedInUser = tokenService.getSession();
    const payload = {
      status: 'SHORTLISTED',
      shortlistedBy: loggedInUser.id,
    };
    const res = await fnWrapper({
      url: `${UPDATE_SHORTLIST_CANDIDATE}/${id}`,
      method: 'PUT',
      type: `UPDATE_SHORTLIST_STATUS`,
      payload: payload,
    });
    if (res.data.statusCode === 200) {
      toast.success(
        `${
          !isShortlisted ? 'Shortlisted applicant' : 'Removed from shortlist'
        }`,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        }
      );
      getShortlistedApplicantListHelper();
    }
  };
  /* ---- */

  // Update condidate mark as contacted status
  const markCandidateAsContacted = async (id) => {
    const res = await fnWrapper({
      url: `${UPDATE_CANDIDATE_CONTACTED_STATUS}/${id}`,
      method: 'PUT',
      type: `UPDATE_CONTACTED_STATUS`,
    });
    if (res.data.statusCode === 200) {
      getShortlistedApplicantListHelper();
      const data = applicantList.filter((ele) => ele.id === id)[0];
      if (!data?.markAsContacted) {
        const filtereddata = selectedRow.filter((ele) => ele.id !== data.id);
        const filteredApplicants = selectedApplicants.filter(
          (appl) => appl.id !== data.id
        );
        setSelectedRow(filtereddata);
        setSelectedApplicants(filteredApplicants);
      } else {
        const filtereddata = selectedRow.filter((ele) => ele.id !== data.id);
        const filteredApplicants = selectedApplicants.filter(
          (appl) => appl.id !== data.id
        );
        setSelectedRow(filtereddata);
        setSelectedApplicants(filteredApplicants);
      }
    }
  };

  /*-view applicant-- */
  const viewApplicant = async (id) => {
    const loggedInUser = tokenService.getSession();
    const payload = {
      status: 'VIEWED',
      shortlistedBy: loggedInUser.id,
    };
    const res = await fnWrapper({
      url: `${UPDATE_SHORTLIST_CANDIDATE}/${id}`,
      method: 'PUT',
      type: `UPDATE_SHORTLIST_STATUS`,
      payload: payload,
    });
    if (res.data.statusCode === 200) {
      getShortlistedApplicantListHelper();
    }
  };

  /* send invite to selected job applicants*/
  const sendInvite = async (jobId) => {
    const loggedInUser = tokenService.getSession();

    let selectedCandidate = [];
    applicantsFromOnboard.map((ele) => {
      const obj = {
        id: ele?.id,
        designation: ele?.designation,
        role: ele?.selectedDropItem?.title,
      };
      selectedCandidate.push(obj);
      return selectedCandidate;
    });
    const payload = {
      applicantData: selectedCandidate,
      selectedBy: loggedInUser.id,
    };
    const res = await fnWrapper({
      url: `${SEND_INVITE_TO_SELECTED_JOB_APPLICANT}/${jobId}`,
      method: 'PUT',
      type: `SEND_INVITE_TO_SELECTED_JOB_APPLICANT`,
      payload: payload,
    });
    if (res.data.statusCode === 200) {
      setSuccessPopup(false);
      getShortlistedApplicantListHelper();
      setSelectedApplicants([]);
      setSelectedRow([]);
      toast.success('Applicants on boarded successfully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  const applicantListData =
    context.busy.type === 'FETCH_JOBS_BASED_ON_TEAM' ||
    context.busy.type === 'UPDATE_SHORTLIST_STATUS'
      ? getPlaceholderItems(
          SHORTLISTED_APPLICANT_LIST_TABLE_HEADERS.map(
            ({ sortKey }) => sortKey
          ),
          6,
          context.busy.type === 'FETCH_JOBS_BASED_ON_TEAM' ||
            'UPDATE_SHORTLIST_STATUS'
        )
      : shortlistedApplicantListTableData({
          list: applicantList,
          shortlistCandidate,
          openOrCloseViewApplicant: ({
            id,
            shouldChangeApplicantStatusToView,
          }) => {
            if (shouldChangeApplicantStatusToView) {
              viewApplicant(id);
            }
            const applicantData = applicantList.map((applicant) => {
              if (applicant.id === id) {
                return {
                  userId: applicant?.applicant?.id,
                  name: applicant?.applicant?.individual?.name,
                  eRecord: applicant?.eRecord,
                  profileUrl: applicant?.profileUrl,
                  question: applicant?.question ? applicant?.question : null,
                  resume: applicant?.resume,
                  technology: applicant?.specialization,
                };
              }
              return null;
            });
            setViewApplicantPopup((prevState) => ({
              isModalOpen: !prevState.isModalOpen,
              data: applicantData ? applicantData[0] : null,
            }));
          },
          viewContact: (id) => {
            const data = applicantList.filter(
              (applicant) => applicant.id === id
            );
            const dataObj = [
              {
                iconName: 'sms',
                label: 'Email',
                value:
                  data[0]?.applicant?.email !== null &&
                  data[0]?.applicant?.email !== ''
                    ? data[0]?.applicant?.email
                    : 'N/A',
              },
              {
                iconName: 'contact',
                label: 'Contact',
                value:
                  data[0]?.applicant?.individual?.contact !== null
                    ? data[0]?.applicant?.individual?.contact
                    : 'N/A',
              },
            ];
            setContactDetails(dataObj);
            setViewContactPopup(!viewContactPopup);
          },
          markCandidateAsContacted,
          permissions: roleBasedAccessState.personalTeamRBAC,
        });

  const searchHandler = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value === '') {
      getShortlistedApplicantListHelper();
    }
  };

  const closeSearchHandler = () => {
    setSearchTerm('');
    getShortlistedApplicantListHelper();
  };

  const onSubmitHandler = () => {
    setSuccessPopup(true);
    setIsOnboardingPopup(false);
    sendInvite(selectedDropItem?.id);
  };

  return (
    <>
      <div className="main-wrapper mb-4">
        <div className="main-wrapper__shortlisted">
          <div className="header pb-2 mb-3">
            <span className="text">{'Shortlisted'}</span>
            {!isCareerEnterprise && (
              <div className="dropdown-wrapper">
                <div
                  onClick={() => setIsDropdown((prev) => !prev)}
                  className="d-flex justify-content-between align-items-center dropdown-wrapper__title">
                  <span className="heading">
                    {Object.keys(selectedDropItem).length !== 0
                      ? selectedDropItem.title
                      : 'Select Job Post'}
                  </span>
                  <Icon
                    name={isDropdown ? 'arrow-drop-up-two' : 'arrow-down-three'}
                    color="#404F67"
                    size={15}
                  />
                </div>
                {isDropdown && (
                  <OptionWrapper
                    className="dropdown-wrapper__option-wrapper"
                    onClick={handleDropItemClick}
                    options={dropItems}
                    selectedOption={selectedDropItem.id}
                  />
                )}
              </div>
            )}

            {isCareerEnterprise && (
              <>
                <SelectDepartmentAndTeam
                  title=""
                  departmentList={departmentList ?? []}
                  teamList={teamList ?? []}
                  departmentAndTeam={currDepartmentAndTeam}
                  setDepartmentAndTeam={setCurrDepartmentAndTeam}
                  getTeamsOnDepartmentClick={getTeamListBasedonDepartment}
                  getPostedJobsOnTeamClick={getJobsPerTeam}
                  context={context}
                />
                <div className="dropdown-wrapper w-50">
                  <div
                    onClick={() => setIsDropdown((prev) => !prev)}
                    className="d-flex justify-content-between align-items-center dropdown-wrapper__title">
                    <span className="heading">
                      {Object.keys(selectedDropItem).length !== 0
                        ? selectedDropItem.title
                        : 'Select Job Post'}
                    </span>
                    <Icon
                      name={isDropdown ? 'arrow-up' : 'arrow-down-three'}
                      color="#404F67"
                      size={15}
                    />
                  </div>
                  {isDropdown && (
                    <OptionWrapper
                      className="dropdown-wrapper__option-wrapper"
                      onClick={handleDropItemClick}
                      options={dropItems}
                      selectedOption={selectedDropItem.id}
                    />
                  )}
                </div>
              </>
            )}
          </div>

          <div className="main-wrapper__shortlisted__content">
            <div className="main-wrapper__shortlisted__content__header">
              <SearchInput
                value={searchTerm}
                onSearchange={searchHandler}
                onCloseSearch={closeSearchHandler}
                onSearch={() => {
                  getShortlistedApplicantList({
                    jobId: selectedDropItem.id,
                    search: searchTerm,
                    teamIdFromProps: selectedDropItem?.teamId,
                  });
                }}
              />
              <IsPermitted permissions={[userScopes.ONBOARD_JOB_APPLICANTS]}>
                <CustomButton
                  disabled={selectedRow.length === 0}
                  onClick={() => setIsOnboardingPopup(true)}>
                  Onboard Applicants
                  <Icon name="arrow-forward" color="#ffffff" />
                </CustomButton>
              </IsPermitted>
            </div>
            {context.busy.status ? (
              <Loader loading={context.busy.status} />
            ) : selectedDropItem && applicantList.length !== 0 ? (
              <>
                <div className="main-wrapper__shortlisted__content__table">
                  <CustomTable
                    isLoading={context.busy.status}
                    rows={applicantListData}
                    headers={SHORTLISTED_APPLICANT_LIST_TABLE_HEADERS}
                    hasCheckbox={roleBasedAccessState.personalTeamRBAC.includes(
                      userScopes.MARK_AS_CONTACTED_JOB_APPLICANTS
                    )}
                    isAllCheckDisabled={
                      !roleBasedAccessState.personalTeamRBAC.includes(
                        userScopes.MARK_AS_CONTACTED_JOB_APPLICANTS
                      )
                    }
                    selectedRow={selectedRow}
                    rowCheckedHandler={(val) => onRowCheckedHandler(val)}
                    allRowCheckedHandler={(val) => onAllRowCheckedHandler(val)}
                  />
                </div>
                <OnBoardPopUp
                  applicants={selectedApplicants}
                  isOpen={isOnboardingPopup}
                  onSubmitHandler={onSubmitHandler}
                  setSelectedApplicants={setApplicantsFromOnboard}
                  setIsOpen={() => setIsOnboardingPopup(false)}
                />
                {viewApplicantPopup.isModalOpen && (
                  <ModalPopup
                    open={false}
                    maxContentWidth={'650px'}
                    title={'Applicant Details'}
                    onClose={() => {
                      setViewApplicantPopup({
                        isModalOpen: false,
                        data: null,
                      });
                    }}>
                    <ViewApplicant applicantDetails={viewApplicantPopup.data} />
                  </ModalPopup>
                )}
                {viewContactPopup && (
                  <ModalPopup
                    open={false}
                    maxContentWidth={'650px'}
                    title={'Contact Info'}
                    onClose={() => {
                      setViewContactPopup(false);
                    }}>
                    <div className="contact-popup">
                      {contactDetails?.map((contact) => (
                        <div className="contact-popup-content">
                          <Icon name={contact?.iconName} color="#263238" />
                          <span>{contact?.value}</span>
                        </div>
                      ))}
                    </div>
                  </ModalPopup>
                )}
                {successPopup && (
                  <ModalPopup
                    open={false}
                    maxContentWidth={'650px'}
                    title={'Invitation Sent'}
                    onClose={() => {
                      setSuccessPopup(false);
                    }}>
                    <div className="success-popup">
                      <img src={AirPlane} alt="plane" height={'200px'} />
                      <p>
                        Team joining invitation is sent to the selected
                        candidates
                      </p>
                    </div>
                  </ModalPopup>
                )}
              </>
            ) : (
              Object.keys(selectedDropItem).length !== 0 &&
              applicantList.length === 0 && (
                <div className="main-wrapper__shortlisted__content__no-applicants">
                  <img src={noDataImg} alt="no-applicants" height="300px" />
                  <p>No Candidates Available</p>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};
