import { CustomButton, CustomCheckBox, Loader } from 'components';
import { useState } from 'react';
import { DocumentContainer } from '../Components/DocumentContainer';
import { QuestionContainer } from '../../ProjectSelectCollaborations/Components/QuestionContainer';
import { CREATE_BIDDING_APPLICANT, useAxiosWrapper } from 'services';
import { TermsConditionContainer } from '../../ProjectSelectCollaborations/Components/TermsConditionContainer';
import { useCallback } from 'react';
import { isMobile } from 'utlils';
import { RequiredDetailsWrapper } from './style';
import { BiddingRate } from '../Components/BiddingRate';
import tokenService from 'services/token.service';

export const RequiredBiddingDetails = ({
  nextPage,
  selectedBidding,
  questionsToBidder,
  rulesAndDocuments,
  signedDocuments,
  setProjectInfo,
}) => {
  const [fnWrapper, { busy }] = useAxiosWrapper();
  const [dataToSubmit, setDataToSubmit] = useState({
    bidRate: selectedBidding?.fixedBidRate ? selectedBidding?.fixedBidRate : '',
    questionsToBidder: {},
    termsAccepted: false,
    signedDocuments: [],
  });

  const isSubmitDisabled = () => {
    //Add more condition if needed to disable button
    if (
      !dataToSubmit?.bidRate ||
      parseFloat(dataToSubmit?.bidRate) <
        parseFloat(selectedBidding?.minBidRange) ||
      parseFloat(dataToSubmit?.bidRate) >
        parseFloat(selectedBidding?.maxBidRange) ||
      dataToSubmit?.termsAccepted === false ||
      (questionsToBidder &&
        Object.keys(questionsToBidder)?.length !==
          Object.keys(dataToSubmit?.questionsToBidder)?.length &&
        questionsToBidder[1] !== '') ||
      dataToSubmit?.signedDocuments?.filter((doc) => doc?.isMandatory)
        ?.length !== signedDocuments?.filter((doc) => doc?.isMandatory)?.length
    ) {
      return true;
    }
    return false;
  };

  //Check if any questions are asked
  const questionsExist = () => {
    if (
      questionsToBidder &&
      Object.keys(questionsToBidder)?.length !== 0 &&
      questionsToBidder[1] !== ''
    ) {
      return true;
    }
    return false;
  };

  const handleSubmission = async () => {
    let formData = new FormData();
    let bidderSignedDocuments = [];
    let fileNames = new Set();
    for (let i of dataToSubmit.signedDocuments) {
      if (i?.file) {
        formData.append('signedDocuments', i?.file);
        fileNames.add(i?.docName);
        bidderSignedDocuments.push({
          bidderFileName: i?.file?.name,
          fileName: i?.docName,
          location: i?.location,
          isMandatory: i?.isMandatory,
        });
      }
    }
    if (fileNames?.size !== signedDocuments?.length) {
      for (let i of signedDocuments) {
        if (!fileNames?.has(i?.name)) {
          // Check if file name is missing
          bidderSignedDocuments.push({
            bidderFileName: null,
            fileName: i?.name,
            location: i?.location,
            isMandatory: i?.isMandatory,
          });
        }
      }
    }
    formData.append(
      'bidderSignedDocuments',
      JSON.stringify(bidderSignedDocuments)
    );
    formData.append('bidderAmount', dataToSubmit?.bidRate);
    formData.append('currencyType', selectedBidding?.currencyType);
    formData.append('applicantId', tokenService?.getSession()?.id);
    formData.append('biddingId', selectedBidding?.id);
    formData.append('biddingType', selectedBidding?.biddingType);
    if (
      dataToSubmit?.questionsToBidder &&
      Object.keys(dataToSubmit?.questionsToBidder).length > 0
    ) {
      formData.append(
        'questionsToBidder',
        JSON.stringify(dataToSubmit?.questionsToBidder)
      );
    }
    //Send the form data
    const res = await fnWrapper({
      url: CREATE_BIDDING_APPLICANT,
      method: 'POST',
      payload: formData,
      type: 'CREATE_BIDDING_APPLICANT',
    });
    if (res.data.statusCode === 200) {
      //Update the main list to show check is applied
      setProjectInfo((projectInfo) => {
        return {
          ...projectInfo,
          activeProjectBiddings: projectInfo.activeProjectBiddings.map(
            (activeBidding) => {
              if (activeBidding.id === selectedBidding?.id) {
                return { ...activeBidding, isApplied: true };
              }
              return activeBidding;
            }
          ),
        };
      });
      nextPage();
    }
  };

  //Handle change in question answer and bid rate
  const handleInput = useCallback((e, question, type) => {
    if (type === 'BID_RATE') {
      setDataToSubmit((dataToSubmit) => ({
        ...dataToSubmit,
        bidRate: e.target.value,
      }));
      return;
    }

    if (e.target.value === '') {
      setDataToSubmit((data) => {
        const { [`${question}`]: removed, ...newQuestions } =
          data.questionsToBidder;
        return { ...data, questionsToBidder: newQuestions };
      });
      return;
    }

    setDataToSubmit((data) => ({
      ...data,
      questionsToBidder: {
        ...data.questionsToBidder,
        [`${question}`]: e.target.value,
      },
    }));
  }, []);

  return (
    <>
      {busy?.status === true ? (
        <div className="loading-bar">
          <Loader loading={true} />
        </div>
      ) : (
        <RequiredDetailsWrapper>
          <div>
            <div>
              <p className="sub-heading">
                Please upload the required signed documents and place your bid
                as per bidding rate-
              </p>
            </div>
            <div>
              <BiddingRate
                maxBidAmountApplied={selectedBidding?.maxBidAmountApplied}
                currencyType={selectedBidding?.currencyType}
                bidRateType={selectedBidding?.bidRateType}
                fixedBidRate={parseFloat(selectedBidding?.fixedBidRate)}
                minBidRange={parseFloat(selectedBidding?.minBidRange)}
                maxBidRange={parseFloat(selectedBidding?.maxBidRange)}
                value={dataToSubmit?.bidRate}
                handleBidRateInput={handleInput}
              />
            </div>
            {questionsExist() ? (
              <div>
                <p className="sub-heading">2. Please answer these questions</p>
                <div>
                  {Object?.keys(questionsToBidder)?.map((questionKey) => (
                    <QuestionContainer
                      key={questionKey}
                      value={
                        dataToSubmit?.questionsToBidder[
                          questionsToBidder[questionKey]
                        ]
                      }
                      question={questionsToBidder[questionKey]}
                      handleAnswerInput={handleInput}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <div>
                <p className="sub-heading">2. Please answer these questions</p>
                <p className="sub-heading">No questions asked</p>
              </div>
            )}
            {signedDocuments && signedDocuments?.length > 0 ? (
              <div
                className={
                  questionsExist()
                    ? 'doc-container-main'
                    : 'doc-container-main-nq'
                }>
                <p className="sub-heading">
                  3. Sign these documents and upload them
                </p>
                <div className="document-list">
                  {signedDocuments?.map((singleBiddingDocument, idx) =>
                    singleBiddingDocument ? (
                      <DocumentContainer
                        key={idx}
                        document={singleBiddingDocument}
                        addedDocuments={dataToSubmit?.signedDocuments}
                        setDataToSubmit={setDataToSubmit}
                      />
                    ) : null
                  )}
                </div>
              </div>
            ) : (
              <div className="doc-container-main">
                <p className="sub-heading">
                  3. Sign these documents and upload them
                </p>
                <p className="sub-heading">No documents required</p>
              </div>
            )}
            {rulesAndDocuments && rulesAndDocuments?.length > 0 ? (
              <div className="tc-container-main">
                <p className="sub-heading">
                  4. Read the rules & documents carefully
                </p>
                {rulesAndDocuments.map((document) => (
                  <TermsConditionContainer key={document?.name} {...document} />
                ))}
              </div>
            ) : null}
            <div className="accept-terms">
              <CustomCheckBox
                customClass={
                  isMobile() ? 'accept-checkbox-mobile' : 'accept-checkbox'
                }
                onClick={(e) =>
                  setDataToSubmit((data) => ({
                    ...data,
                    termsAccepted: !data?.termsAccepted,
                  }))
                }
                check={dataToSubmit?.termsAccepted}
                title={
                  <p className="accept-checkbox-text">
                    I have read all the terms and conditions carefully and
                    uploaded the required signed documents.
                  </p>
                }
              />
            </div>
          </div>
          <div className="popup-button">
            <CustomButton
              disabled={isSubmitDisabled()}
              label={'Submit request'}
              color="themeSecondary"
              onClick={handleSubmission}
            />
          </div>
        </RequiredDetailsWrapper>
      )}
    </>
  );
};
