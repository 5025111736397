import styled from 'styled-components';
import Icon from 'utlils/Icons/Icon';

const ManageInputWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 55px;
  background: #f4f7fc;
  border: 1px solid #ccd2dd;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  color: #5f6b7c;
  margin-bottom: 10px;
  justify-content: space-between;
  cursor: grab;
  svg {
    cursor: pointer;
    margin-right: 15px;
  }
  .manage-input {
    &__inputbox {
      width: 60%;
      height: 90%;
      margin-left: 10px;
      border: none;
      outline: none;
      background-color: transparent;
      color: #5f6b7c;
      &:disabled {
        cursor: not-allowed;
      }
    }
  }
`;
export const ManageBoardInput = ({
  isActiveField,
  field,
  onChangeField,
  deleteFieldHandler,
  handleDragStart,
  handleDrag,
  handleDragEnd,
  onDragEnter,
  shouldAutoFocus,
}) => {
  return (
    <ManageInputWrapper
      className="manage-input"
      draggable
      onDragStart={handleDragStart}
      onDrag={handleDrag}
      onDragEnd={handleDragEnd}
      onDragEnter={onDragEnter}>
      <input
        className="manage-input__inputbox"
        value={field?.label?.toUpperCase()}
        onChange={onChangeField}
        disabled={isActiveField}
        autoFocus={shouldAutoFocus}
      />
      {!isActiveField && (
        <Icon name="cross" color="#404F67" onClick={deleteFieldHandler} />
      )}
    </ManageInputWrapper>
  );
};
