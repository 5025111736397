import { Box } from '../../../../components';
import EnterpriseImg from '../../../../assests/images/enterpriseImg.png';
import DotActive from '../../../../assests/images/dotActive.png';
import DotDisable from '../../../../assests/images/dotDisable.png';
import './enterpriseList.scss';
import CommonDropDown from '../commonDropDown/index';
import { ENTERPRISE_ACTION_OPTIONS } from '../../constants';

const EnterPriseList = ({
  list,
  onSelectEnterprise,
  active = null,
  editOrRemoveHandler,
  viewDepartmentHandler,
}) => {
  return (
    <div className="enterprise-list-container">
      <div className="row enterprise-list-wrapper">
        {list.length !== 0 &&
          list.map((item, index) => (
            <div className="col-lg-6 col-md-6  my-2" key={index}>
              <Box position="relative">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  p="10px 20px"
                  border="1px solid #CCD2DD"
                  bRadius="12px">
                  <Box
                    cursor="pointer"
                    display="flex"
                    alignItems="center"
                    width={'100%'}
                    onClick={() => viewDepartmentHandler(item.id)}>
                    <img
                      src={EnterpriseImg}
                      alt=""
                      style={{ marginRight: '20px' }}
                    />
                    <div>{item.departmentName}</div>
                  </Box>

                  <Box
                    cursor="pointer"
                    onClick={() => onSelectEnterprise(item.id)}>
                    <img
                      src={active === item.id ? DotActive : DotDisable}
                      alt=""
                    />
                  </Box>
                </Box>
                <Box mt="15px">
                  <CommonDropDown
                    dropItem={ENTERPRISE_ACTION_OPTIONS}
                    active={active === item.id}
                    objectKey="label"
                    onClick={(evt) => editOrRemoveHandler(evt, item)}
                    top="50px"
                    right="-18px"
                    mobileTop="50px"
                    mobileRight="0px"
                  />
                </Box>
              </Box>
            </div>
          ))}
      </div>
    </div>
  );
};
export default EnterPriseList;
