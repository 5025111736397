import { CustomButton, UploadButton } from 'components';
import { onChangeGroupCredential } from 'context/Groups/CreateGroup/createGroupAction';
import { CreateGroupContext } from 'context/Groups/CreateGroup/createGroupContext';
import React, { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import Icon from 'utlils/Icons/Icon';

export default function GroupProfilePicture() {
  const { createGroupState, createGroupDispatch } =
    useContext(CreateGroupContext);
  const params = useParams();
  const [displayImage, setDisplayImage] = useState(
    createGroupState?.groupProfilePicture || ''
  );
  const navigate = useNavigate();
  function byteToMB(byteValue) {
    return byteValue / 1024 ** 2;
  }
  const onChangeHandler = (event) => {
    if (
      event.target.files &&
      event.target.files[0] &&
      byteToMB(event.target.files[0].size) < byteToMB(1000000)
    ) {
      setDisplayImage(URL.createObjectURL(event.target.files[0]));
      onChangeGroupCredential(
        createGroupDispatch,
        'groupProfilePicture',
        event.target.files[0]
      );
    } else {
      toast.warning('Image size should be less than 1MB', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };
  return (
    <GroupProfilePictureWrapper>
      <div>
        <div className="profile-picture-header">
          Give your group a profile picture
        </div>
        <div className="upload-profile-picture-section">
          {displayImage ? (
            <img
              src={displayImage}
              alt="profile_picture"
              className={
                displayImage ? 'profile-picture-border' : 'profile-picture'
              }
            />
          ) : (
            <div className="no-profile-placeholder">
              <Icon name="people" color="#000" size={40} />
            </div>
          )}
          <div className="upload-button">
            <UploadButton
              color="themeSecondary"
              variant="light"
              onChange={(e) => {
                e.stopPropagation();
                onChangeHandler(e);
              }}
              customPadding="12px 25px">
              <Icon name="arrow-up-two" color="#219fff" />
              Upload Picture
            </UploadButton>
          </div>
          <div className="action-buttons">
            <CustomButton
              color={'themeSecondary'}
              variant={'textBtn'}
              label={'Back'}
              onClick={() =>
                navigate(
                  `/individual/profile/groups/${params.action}/information`
                )
              }
            />
            <CustomButton
              customPadding="16px 38px"
              color="themeSecondary"
              label="Continue"
              onClick={() =>
                navigate(
                  `/individual/profile/groups/${params.action}/group-questions`
                )
              }
            />
          </div>
        </div>
      </div>
    </GroupProfilePictureWrapper>
  );
}

const GroupProfilePictureWrapper = styled.div`
  .profile-picture-header {
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    font-family: 'Poppins';
  }
  .upload-profile-picture-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;

    .profile-picture {
      width: 120px;
      height: 120px;
      border-radius: 20rem;
    }
    .profile-picture-border {
      width: 120px;
      height: 120px;
      border-radius: 20rem;
      border: 2px dashed #219fff;
    }

    .no-profile-placeholder {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 120px;
      border-radius: 20rem;
      background-color: #e9edf4;
    }
    .upload-button {
      margin-top: 2rem;
      width: 193px;
      height: 54px;
    }
  }
  .action-buttons {
    margin-top: 2rem;
    display: flex;
    gap: 10px;
  }
`;
