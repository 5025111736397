import cn from 'classname';
import {
  Box,
  CustomButton,
  DropDown,
  Input,
  ModalPopup,
  ScrollableDiv,
  SearchBoxWithDropDown,
} from 'components';
import React, { useCallback } from 'react';
import { useState } from 'react';
import { AddTeamMemberWrappper } from './style';
import { CREATE_TEAM_MEMBER, SEARCH_USER, useAxiosWrapper } from 'services';
import tokenService from 'services/token.service';
import { getProfileText } from 'utlils/utils';
import { INVITEMEMBERROLE } from 'pages/IndividualDashboard/IndividualPersonalSection/individualTeams/helper';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isValidEmail } from 'helpers';
import Icon from 'utlils/Icons/Icon';

export const AddTeamMember = (props) => {
  const { getMembersList } = props;
  const [isClose, setIsClose] = useState(false);
  const [fnWrapper] = useAxiosWrapper();
  const [searchMember, setSearchMember] = useState('');
  const [filteredMemberData, setFilterMemberData] = useState([]);
  const closePopupHandler = () => {
    setIsClose(true);
  };
  const loggedInUser = tokenService.getSession();

  const DEFAULT_MEMBER_STATE = {
    memberName: '',
    role: {},
    designation: '',
    email: '',
    invitedByUserId: loggedInUser.id,
  };
  const { teamId } = useParams();

  const [addTeamMemberState, setAddTeamMemberState] = useState({
    teamMembers: [DEFAULT_MEMBER_STATE],
  });

  const divScrollHeight =
    addTeamMemberState.teamMembers.length >= 2
      ? 'calc(100vh - 400px)'
      : 'calc(100vh - 450px)';

  const teamMemberHandler = (type, value, index) => {
    let temp = [...addTeamMemberState.teamMembers];
    temp[index][type] = value;
    let newValue = [...temp];
    setAddTeamMemberState({ ...addTeamMemberState, teamMembers: newValue });
  };

  const onDeleteInviteMembers = async (index) => {
    const temp = [...addTeamMemberState.teamMembers];
    temp.splice(index, 1);
    setAddTeamMemberState({ ...addTeamMemberState, teamMembers: temp });
  };

  const addMemberFieldsHandler = () => {
    const temp = [...addTeamMemberState.teamMembers];
    temp.push({
      memberName: '',
      role: null,
      designation: '',
      email: '',
      invitedByUserId: loggedInUser.id,
    });

    setAddTeamMemberState({ ...addTeamMemberState, teamMembers: temp });
  };

  const onChangeSearchMember = async (value) => {
    setSearchMember(value);
    if (value.length >= 2) {
      let data = {
        q: value,
      };
      const res = await fnWrapper({
        url: SEARCH_USER,
        method: 'POST',
        type: SEARCH_USER,
        payload: data,
      });
      if (res.data.statusCode === 200) {
        const searchData = res.data.data;
        let finalArray = [];
        if (searchData.length) {
          finalArray = searchData
            .map((ele) => {
              return {
                id: ele.id,
                userId: ele.userId,
                name: ele.name,
                email: ele.user.email,
                userName: ele.user.userName,
                profilePicture: ele.profilePicture,
                profileText: getProfileText(ele.name),
              };
            })
            .filter((ele) => ele.userId !== loggedInUser.id);
        }
        setFilterMemberData(finalArray);
      }
    }
  };

  const onSelectSearchMember = async (member) => {
    let searchedMmeber = {
      memberName: member.name,
      designation: '',
      role: {},
      email: member.email,
      invitedByUserId: loggedInUser.id,
    };
    let newElement = [];
    newElement = [...addTeamMemberState.teamMembers, searchedMmeber];
    //removed empty object and add new searched object
    let finalArray = newElement.filter(
      (ele) => ele.memberEmail !== '' && ele.memberName !== ''
    );
    addTeamStateHandler('teamMembers', finalArray);
    setSearchMember('');
  };

  const addTeamStateHandler = (field = '', value = null) => {
    setAddTeamMemberState({
      ...addTeamMemberState,
      [field]: value,
    });
  };

  const disableTeamMembers = () => {
    const teamMemberError = (obj) =>
      obj.memberName === '' ||
      obj.designation === '' ||
      obj.email === '' ||
      !isValidEmail(obj.email) ||
      Object.keys(obj.role).length === 0;
    return addTeamMemberState.teamMembers.some(teamMemberError);
  };

  let buttonDisabled = disableTeamMembers();

  const onCreateTeamMember = useCallback(async () => {
    let teamData = addTeamMemberState.teamMembers;
    const finalData = teamData.map((ele) => {
      if (ele.role) {
        ele = {
          ...ele,
          role: ele.role.value,
        };
      }
      return ele;
    });
    const data = {
      teamId: teamId,
      teamMembers: finalData,
    };
    const res = await fnWrapper({
      url: CREATE_TEAM_MEMBER,
      method: 'POST',
      type: 'CREATE_TEAM_MEMBER',
      payload: data,
    });

    if (res.data.statusCode === 200) {
      await getMembersList();
      closePopupHandler();
      toast.success(res.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addTeamMemberState, teamId]);

  return (
    <div>
      {!isClose ? (
        <ModalPopup
          open={false}
          onClose={closePopupHandler}
          minWidth="850px"
          maxContentWidth="850px"
          hasTitle
          title={'Add Members'}>
          <AddTeamMemberWrappper>
            <Box mt="16px">
              <div className="add-members-header d-flex align-items-center justify-content-between">
                <span className="add-members">Invite Members</span>
                <div className="add-field-section">
                  <CustomButton
                    variant="textBtn"
                    onClick={() => addMemberFieldsHandler()}>
                    <Icon name="add" size={18} color="primary" />
                    Add Field
                  </CustomButton>
                </div>
              </div>
              <hr className="underline-tag" />
            </Box>
            <div className="search-content">
              <SearchBoxWithDropDown
                searchInputValue={searchMember}
                searchData={filteredMemberData}
                placeholder="Search for members in your organization"
                onSelectSearchItem={onSelectSearchMember}
                onInputChangeHandler={onChangeSearchMember}
              />
            </div>
            <Box>
              <ScrollableDiv
                scrollOnHeight={divScrollHeight}
                scrollOnHeightMob="300px">
                {addTeamMemberState.teamMembers.map((item, index) => (
                  <Box mb="30px" key={index}>
                    {index >= 1 && addTeamMemberState.teamMembers.length > 1 ? (
                      <div className="d-flex align-items-center justify-content-between delete-member mb-3">
                        <div className="member-count">Member {index + 1}</div>
                        <CustomButton
                          variant="textBtn"
                          color="themeTertiary"
                          onClick={() => onDeleteInviteMembers(index)}>
                          <Icon name="delete" size={18} color="#ff5157" />
                          Delete Member
                        </CustomButton>
                      </div>
                    ) : null}
                    <div
                      className={cn(
                        'add-teams-item',
                        addTeamMemberState.teamMembers.length > 1 &&
                          'add-teams-border'
                      )}>
                      <div className="row">
                        <div className="col-lg-6">
                          <Input
                            label="Name"
                            type="text"
                            required
                            value={item.memberName}
                            onChange={(evt) =>
                              teamMemberHandler(
                                'memberName',
                                evt.target.value,
                                index
                              )
                            }
                          />
                        </div>
                        <div className="col-lg-6">
                          <Input
                            label="Email"
                            type="email"
                            required
                            value={item.email}
                            onChange={(evt) =>
                              teamMemberHandler(
                                'email',
                                evt.target.value,
                                index
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <Input
                            label="Designation"
                            type="text"
                            required
                            value={item.designation}
                            onChange={(evt) =>
                              teamMemberHandler(
                                'designation',
                                evt.target.value,
                                index
                              )
                            }
                          />
                        </div>
                        <div className="col-lg-6">
                          <DropDown
                            label="Role"
                            placeholder="Select Role"
                            objectKey="label"
                            required
                            items={INVITEMEMBERROLE}
                            value={item.role}
                            onClick={(value) =>
                              teamMemberHandler('role', value, index)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </Box>
                ))}
                <div className="add-teamMember">
                  <CustomButton
                    label="Add Member"
                    color="themeSecondary"
                    onClick={onCreateTeamMember}
                    disabled={buttonDisabled}
                    className="add-teamMember-button"
                  />
                </div>
              </ScrollableDiv>
            </Box>
          </AddTeamMemberWrappper>
        </ModalPopup>
      ) : null}
    </div>
  );
};
