import axios from 'axios';
import TokenService from './token.service';
import tokenService from './token.service';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = TokenService.getAccessToken();
    if (accessToken) {
      config.headers['x-access-token'] = accessToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== '/auth/signin' && err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await axiosInstance.post('/refresh', {
            refreshToken: TokenService.getRefreshToken(),
          });
          const newAccessToken = rs.data.data;
          TokenService.setAccessToken(newAccessToken);

          return axiosInstance(originalConfig);
        } catch (_error) {
          if (
            _error?.response?.status === 400 ||
            _error?.response?.status === 401
          ) {
            await tokenService.onLogout();
            window.location.replace('/login/selectOrganization');
          }
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default axiosInstance;
