import dayjs from 'dayjs';
import React, { useCallback, useState } from 'react';
import Icon from 'utlils/Icons/Icon';
import { CustomTable, SlidingButton } from 'components';
import arrowCircle from '../../../../../../assests/images/arrow-circle-right.png';
import { UPDATE_PROJECT_BIDDING_STATUS, useAxiosWrapper } from 'services';
import { getPlaceholderItems } from 'utlils/utils';
import { BIDDING_TABLE_HEADERS } from '../../../ProjectCollaboration/constant';
import { startCase } from 'lodash';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { CreateBidding } from '../CreateBidding';

export default function BiddingList(props) {
  const { getBiddingProjectsList, projectId, biddingTableData, selectedItem } =
    props;
  const DEFAULT_BIDDING_ACTION = {
    type: '',
    isModalOpen: false,
    data: null,
  };
  const [biddingAction, setBiddingAction] = useState(DEFAULT_BIDDING_ACTION);
  const [fnWrapper, context] = useAxiosWrapper();
  const navigate = useNavigate();

  const openPopupHandler = useCallback((type, data) => {
    setBiddingAction({
      data: data,
      isModalOpen: true,
      type: type,
    });
  }, []);
  const biddingTableListData = (biddingTableData) => {
    let newData = [];
    if (biddingTableData.length) {
      newData = biddingTableData.map((item) => {
        return {
          id: item.id,
          biddingType: startCase(
            item.biddingType.split('_').join(' ').toLowerCase()
          ),
          bidRateType: item.bidRateType,
          openDate: dayjs(item.openDate).format('DD MMM YYYY'),
          requests:
            item?.biddingApplicants?.length === 0
              ? 0
              : item?.biddingApplicants?.filter(
                  (applicant) =>
                    applicant?.ProjectBiddingApplicant?.isActive !== 0
                )?.length,
          edit: (
            <Icon
              className="edit-bidding"
              name="edit"
              onClick={() => openPopupHandler(`EDIT-BIDDING`, item)}
            />
          ),
          isOpen: (
            <SlidingButton
              isSliding={item.isOpen}
              className="is-job-open"
              onClick={() => onUpdateBiddingStatus(item.id, !item.isOpen)}
            />
          ),
          action: (
            <img
              key={projectId}
              src={arrowCircle}
              alt="arrow-right"
              className="nav-img"
              onClick={() => {
                navigate(
                  `${selectedItem.value.toLowerCase()}/applicants/${item.id}`
                );
              }}
            />
          ),
        };
      });
    } else {
      newData = [];
    }

    return newData;
  };

  const allBiddingData =
    context.busy.status && context.busy.type === 'GET_PROJECT_BIDDING_LIST'
      ? getPlaceholderItems(
          BIDDING_TABLE_HEADERS.map(({ sortKey }) => sortKey),
          7,
          context.busy.status &&
            context.busy.type === 'GET_PROJECT_BIDDING_LIST'
        )
      : biddingTableListData(biddingTableData);

  const onUpdateBiddingStatus = async (id, status) => {
    const res = await fnWrapper({
      url: UPDATE_PROJECT_BIDDING_STATUS,
      method: 'PUT',
      type: 'UPDATE_PROJECT_BIDDING_STATUS',
      payload: { biddingId: id, isOpen: status },
    });
    if (res.data.statusCode === 200) {
      await getBiddingProjectsList();
      toast.success(res.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return (
    <div>
      {biddingTableData.length ? (
        <div className="table-style">
          <CustomTable
            isLoading={
              context.busy.status &&
              context.busy.type === 'GET_PROJECT_BIDDING_LIST'
            }
            rows={allBiddingData}
            headers={BIDDING_TABLE_HEADERS}
          />
        </div>
      ) : (
        <div className="no-colab">
          Seems like you don’t have any open bidding yet. Create a new bidding
          now.
        </div>
      )}
      {biddingAction.isModalOpen && (
        <CreateBidding
          biddingActionState={biddingAction}
          projectId={projectId}
          getBiddingProjectsList={getBiddingProjectsList}
          onClose={() => setBiddingAction(DEFAULT_BIDDING_ACTION)}
        />
      )}
    </div>
  );
}
