import cx from 'classname';
import { bool, func, string } from 'prop-types';
import styled from 'styled-components';
import Icon from 'utlils/Icons/Icon';

export const PopUpTitle = ({
  popupTitleClass,
  hasCloseIcon = true,
  onCloseHandler,
  title,
}) => {
  return (
    <Wrp className={cx('wrp', popupTitleClass)}>
      <div className="wrp__container">
        <span className="wrp__container__label">{title}</span>
        {hasCloseIcon && (
          <div onClick={onCloseHandler}>
            <Icon
              name="cross"
              color="#292D32"
              className="wrp__container__cross-button"
            />
          </div>
        )}
      </div>
      <div className="wrp__down-border" />
    </Wrp>
  );
};

const Wrp = styled.div`
  & .wrp__ {
    &container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__label {
        font-family: 'Poppins', 'Open Sans';
        font-size: 20px;
        font-weight: 500;
        line-height: 16px;
        color: #040d20;
      }
      &__cross-button {
        margin-top: -12px;
        cursor: pointer;
        margin-right: 10px;
      }
    }
    &down-border {
      width: 100%;
      height: 1px;
      background: #404f67;
      margin-top: 4px;
    }
  }
`;

PopUpTitle.propTypes = {
  hasCloseIcon: bool,
  popupTitleClass: string,
  onCloseHandler: func,
};
