import React from 'react';
import { ProjectPublicCardWrapper } from './style';
import Icon from 'utlils/Icons/Icon';
import { CustomButton } from 'components';
import { TYPES } from 'pages/IndividualDashboard/constants';

export const ProjectPublicCard = ({
  projectName,
  projectType,
  projectOwner,
  bgImgSrc,
  profileImgSrc = '',
  buttons,
  infoItems,
  isNotLoggedInUser,
}) => {
  return (
    <ProjectPublicCardWrapper bgImgSrc={bgImgSrc} numBtns={buttons.length}>
      <div className="upper-image-container" />
      <div className="content-container">
        <div className="sub-container">
          <div className="level-wrapper">
            <div className="level--sub-wrapper">
              <div className="profile-img-wrapper">
                {profileImgSrc && profileImgSrc.length > 0 ? (
                  <img alt="" src={profileImgSrc} />
                ) : (
                  <div className="profile-icon">
                    <Icon name={'project'} color="#404F67" size={40} />
                  </div>
                )}
              </div>
              <div className="level-btn">
                <span>Project</span>
              </div>
            </div>
          </div>
        </div>
        <div className="sub-container">
          <div className="info-wrapper">
            <div className="upper-half">
              <div className="title-class">
                <div className="title-name">{projectName}</div>
                <div className="sub-title-wrapper">
                  <Icon
                    name={
                      projectType === TYPES.ENTERPRISE
                        ? 'enterprise-two'
                        : 'user'
                    }
                    color="#404F67"
                    size={20}
                  />
                  <div className="sub-title-name">{projectOwner}</div>
                </div>
              </div>
              {/* Rasa asked to remove this button as it has no functionality currently */}
              {/* <div className={buttons.length === 1 ? '' : 'buttons-wrapper'}>
                {buttons &&
                  buttons.length > 0 &&
                  !isNotLoggedInUser &&
                  buttons.map((btn, index) => (
                    <div style={{ width: '100%' }} key={index}>
                      <CustomButton
                        key={index}
                        color={btn.color}
                        variant={btn.variant}
                        onClick={btn.onClick}
                        className="w-100">
                        <Icon
                          name={btn.iconName}
                          size={18}
                          color={btn.iconColor}
                        />
                        {btn.label}
                      </CustomButton>
                    </div>
                  ))}
              </div> */}
            </div>
            <div className="lower-half">
              {infoItems?.map((item, index) => (
                <div className="info-item" key={index}>
                  <Icon
                    name={item.iconName}
                    color={item.iconColor ? item.iconColor : '#929DAF'}
                  />
                  <div
                    onClick={item.onClick}
                    style={item.style ? item.style : null}>
                    {item.value}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </ProjectPublicCardWrapper>
  );
};
