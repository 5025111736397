import styled from 'styled-components';
export const EnterpriseDetailsWrapper = styled.div`
  & .sub-container {
    @media (max-width: 1199px) {
      margin: 25px 0;
    }
    & .main-wrapper {
      border-radius: 12px;
      padding-bottom: 28px;
      background-color: white;
      position: relative;

      & .heading {
        padding: 20px;
        border-bottom: 1px solid #e9eef4;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        color: #17191d;
        margin-bottom: 20px;
      }
      & .content {
        padding: 0 20px;

        .content-heading {
          font-size: 20px;
          font-weight: 700;
          line-height: 30px;
        }

        .content-content {
          margin: 20px 0px;
          padding: 2px 5px;
          text-align: left;
          color: #404f67;
          white-space: pre-wrap;
        }
        & .details-item {
          margin: 12px 0;
          & .details-item-label {
            font-weight: 700;
            font-size: 14px;
            color: #404f67;
            margin-bottom: 0;
          }
          & .details-item-value {
            color: #404f67;
            text-align: left;
            word-wrap: break-word;
          }
        }
      }
      & .location--wrapper {
        padding: 20px;
        & .location-title {
          font-weight: 600;
          font-size: 20px;
          line-height: 34px;
          color: #17191d;
        }
        & .location-content {
          padding: 10px 0px 10px 15px;

          & .location-text-style {
            font-weight: 600;
            font-size: 16px;
            line-height: 28px;
            color: #17191d;
            margin-bottom: 8px;
          }
          & .map-location-class {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }

          & .other-location {
            & .other-location-content {
              display: flex;
              flex-wrap: wrap;
              gap: 20px 80px;
            }
          }

          @media (max-width: 1250px) {
            grid-template-columns: 1fr;
          }
        }
      }
    }
  }
`;
