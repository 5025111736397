/* eslint-disable no-console */
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FORGOT_PASSWORD, useAxiosWrapper } from 'services';
import {
  Box,
  CustomButton,
  FeedBackFooter,
  Input,
  Title,
} from '../../components';
import Icon from 'utlils/Icons/Icon';
import { b64UnicodeEncode, isValidEmail } from '../../helpers';
import './forgotPassword.scss';

const DEFAULT_STATE = {
  email: '',
};

const ForgotPassword = () => {
  const [fnWrapper, context] = useAxiosWrapper();
  let navigate = useNavigate();
  const location = useLocation();
  const [emailInput, setEmailInput] = useState(DEFAULT_STATE);
  const { email } = emailInput;

  const handleInputChange = (field = '', value = null) => {
    setEmailInput({
      ...value,
      [field]: value,
    });
  };

  const disabled = !email || !isValidEmail(email);

  const onSubmitHandler = async () => {
    const encryptEmail = await b64UnicodeEncode(email);
    try {
      const res = await fnWrapper({
        url: FORGOT_PASSWORD,
        method: 'POST',
        type: 'FORGOT_PASSWORD',
        payload: {
          email: email,
        },
      });
      if (res.data.statusCode === 200) {
        navigate(`/resetpassword-instructions/${encryptEmail}`, {
          replace: true,
          state: location?.state,
        });
      } else {
        toast.error(res.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      }
    } catch (error) {
      console.log('error', error);
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  return (
    <div className="container-fluid forgot-password-container">
      <div className="row">
        <div className="col-lg-7">
          <div className="container">
            <div className="row">
              <div className="col-md-3"></div>
              <div className="col-md-8">
                <Box>
                  {/* logo */}
                  <Box mb="78px" mt="33px">
                    <img
                      src={process.env.PUBLIC_URL + '/new-logo.png'}
                      className="img common-navbar-logo"
                      alt="logo"
                    />
                  </Box>
                  <div className="back-to-prev-page">
                    <CustomButton
                      variant="textBtn"
                      onClick={() => navigate(-1, { state: location?.state })}>
                      <Icon name="arrow-back" color={'#404F67'} size={24} />
                      Back to previous page
                    </CustomButton>
                  </div>
                  <div className="forgot-password-wrapper">
                    <Title
                      title={'Forgot Password?'}
                      subTitle={
                        'Please entered your registered email address. We’ll send instructions  to help reset your password.'
                      }
                    />
                    <Box mt="40px">
                      <Input
                        type="email"
                        label="Email Address"
                        placeholder={'Enter your email address'}
                        iconLeft={true}
                        iconName="sms"
                        value={email}
                        onChange={(value) => {
                          handleInputChange('email', value.target.value);
                        }}
                        required
                      />
                    </Box>
                    <Box width={250} mb="40px" mt="40px">
                      <CustomButton
                        disabled={disabled}
                        onClick={onSubmitHandler}
                        loading={
                          context.busy.status &&
                          context.busy.type === 'FORGOT_PASSWORD'
                        }>
                        Send Reset Instruction
                        <Icon
                          name="icon-right"
                          size={24}
                          color="#ffffff"
                          className="resetIcon"
                        />
                      </CustomButton>
                    </Box>
                    <FeedBackFooter />
                  </div>
                </Box>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
        </div>
        <div className="col-lg-5 d-none d-sm-none d-lg-block image-wrapper">
          {/* right side image */}
          <div className="right-img"></div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
