import axios from 'axios';
import {
  lowerCaseLetters,
  numbers,
  specialCharacters,
  upperCaseLetters,
} from '../constants/constants';

export const checkForSpacesInStartEnd = (str) => {
  if (str) {
    return str.startsWith(' ') || str.endsWith(' ');
  }
};

export const isValidEmail = (email) =>
  /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/.test(
    email
  );
export const isValidName = (name) => /^[a-zA-Z ]+$/.test(name);
export const isValidPassword = (password) =>
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/.test(
    password
  );

export const b64UnicodeEncode = async (str) => btoa(encodeURIComponent(str));

export const b64UnicodeDecode = (str) => decodeURIComponent(atob(str));

export const isEmailExist = async (value) => {
  const data = {
    email: value,
  };
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/emailExist`,
    data
  );
  return res.data.message.isEmailExist;
};

export const isUserNameExist = async (value) => {
  const data = {
    userName: value,
  };
  const res = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/userNameExist`,
    data
  );
  return res.data.message.isUserNameExist;
};

/**
 * Create Auto Generate Password function
 * @param {number} passwordLength - how many characters you want in password
 * @returns {string} auto generate password (lower case + special char + numbers + upper case)
 */
export const autoGeneratePassword = (passwordLength) => {
  let characterList =
    lowerCaseLetters + specialCharacters + numbers + upperCaseLetters;

  let password = '';
  const characterListLength = characterList.length;

  for (let i = 0; i < passwordLength; i++) {
    const characterIndex = Math.round(Math.random() * characterListLength);
    password = password + characterList?.charAt(characterIndex);
  }

  return password;
};

export const sortByDate = (a, b) => {
  if (isNaN(new Date(a)) && isNaN(new Date(b))) {
    return 0; // "a" and "b" are both NaN, so they are considered equal
  }
  if (isNaN(new Date(a))) {
    return 1; // "a" is NaN, so place it at the bottom
  }
  if (isNaN(new Date(b))) {
    return -1; // "b" is NaN, so place it at the bottom
  }
  return new Date(b) - new Date(a);
};

export const cropText = (text, maxWords) => {
  const words = text?.split(' ') || [];
  if (words.length > maxWords) {
    return words.slice(0, maxWords).join(' ') + '...';
  }
  return text;
};
