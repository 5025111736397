import {
  CustomTable,
  DropDown,
  Loader,
  ScrollableDiv,
  SearchField,
} from 'components';
import { PeopleWrapper } from './style';
import { useCallback, useEffect, useState } from 'react';
import { MEMBERS_TABLE_HEADERS } from './constants';
import { membersTableData } from './utils';
import ViewMember from './Components/viewMember';
import {
  FETCH_ENTERPRISE_MEMBERS,
  FETCH_DEPARTMENT_NAMES,
  useAxiosWrapper,
  REINVITE_DEPARTMENT_MEMBER,
} from 'services';
import tokenService from 'services/token.service';
import NoDataImg from '../../../assests/images/nodata.png';
import { toast } from 'react-toastify';

const People = () => {
  const [fnWrapper, { busy }] = useAxiosWrapper();
  const [searchMember, setSearchMember] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [searchLoading, setSearchLoading] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState({
    label: 'All Departments',
    value: 'ALL',
    id: 'ALL',
  });
  const [departmentList, setDepartmentList] = useState([
    { label: 'All Departments', value: 'ALL', id: 'ALL' },
  ]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [membersList, setMembersList] = useState([]);

  const loadDepartmentList = useCallback(async () => {
    const loggedInUser = tokenService.getSession();
    const res = await fnWrapper({
      url: `${FETCH_DEPARTMENT_NAMES}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_DEPARTMENT_NAMES',
    });
    if (res.data.statusCode === 200) {
      setDepartmentList((departmentList) => [
        ...departmentList,
        ...res.data.data.map((item) => {
          return {
            id: item.id,
            label: item.departmentName,
            value: item.departmentName,
          };
        }),
      ]);
    } else {
      setDepartmentList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reinviteDepartmentMember = useCallback(
    async (id) => {
      const res = await fnWrapper({
        url: REINVITE_DEPARTMENT_MEMBER,
        method: 'PUT',
        payload: { memberId: id },
        type: 'REINVITE_DEPARTMENT_MEMBER',
      });
      if (res?.data?.statusCode === 200) {
        toast.success(`User re-invited successfully`, {
          position: 'top-right',
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
        if (selectedDepartment?.id !== 'ALL') {
          loadMemberList(selectedDepartment?.id, debouncedSearch);
          return;
        }
        loadMemberList(null, debouncedSearch);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedDepartment?.id]
  );

  const loadMemberList = useCallback(
    async (departmentId, searchMember) => {
      const loggedInUser = tokenService.getSession();
      let params = {
        enterpriseId: loggedInUser?.enterpriseId,
        departmentId: departmentId ?? null,
        search: searchMember,
      };
      const res = await fnWrapper({
        url: FETCH_ENTERPRISE_MEMBERS,
        method: 'GET',
        type: 'FETCH_ENTERPRISE_MEMBERS',
        params: params,
      });
      if (res.data.statusCode === 200) {
        setMembersList(
          membersTableData(
            res.data.data,
            setSelectedMember,
            reinviteDepartmentMember
          )
        );
      } else {
        setMembersList([]);
      }
      setSearchLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reinviteDepartmentMember]
  );

  useEffect(() => {
    loadDepartmentList();
  }, [loadDepartmentList]);

  useEffect(() => {
    setSearchLoading(true);
    const timeOut = setTimeout(() => {
      setDebouncedSearch(searchMember);
    }, 1000);
    let timeOut2;
    if (!searchMember) {
      timeOut2 = setTimeout(() => {
        setSearchLoading(false);
      }, 1000);
    }
    return () => {
      clearTimeout(timeOut);
      clearTimeout(timeOut2);
    };
  }, [searchMember]);

  useEffect(() => {
    if (selectedDepartment?.id !== 'ALL') {
      loadMemberList(selectedDepartment?.id, debouncedSearch);
      return;
    }
    loadMemberList(null, debouncedSearch);
  }, [loadMemberList, selectedDepartment?.id, selectedMember, debouncedSearch]);

  return (
    <PeopleWrapper>
      <div className="main-wrapper--class">
        <div className="people-main">
          <div>
            <span className="title">People</span>
          </div>
        </div>
        <div>
          <div className="search-container">
            <div className="search-text-box">
              <SearchField
                placeholder="Search Member"
                searchField={searchMember}
                onChangeSearchHandler={(value) => setSearchMember(value)}
                closeSearchHandler={() => setSearchMember('')}
              />
            </div>
            <div className="select-department-box">
              <DropDown
                items={departmentList}
                objectKey="label"
                placeholder="Select Department"
                onClick={(item) =>
                  setSelectedDepartment({
                    id: item.id,
                    label: item.label,
                    value: item.value,
                  })
                }
                value={selectedDepartment}
                hasSearchBox
              />
            </div>
          </div>
          {membersList?.length === 0 && !busy?.status && !searchLoading ? (
            <div className="empty-data-section">
              <img
                src={NoDataImg}
                alt="No Data"
                className="empty-data-section--img"
              />
              <div className="empty-data-section--text">No data available</div>
            </div>
          ) : (
            <div>
              {busy?.status || searchLoading ? (
                <Loader loading={busy?.status || searchLoading} />
              ) : (
                <div>
                  <ScrollableDiv
                    scrollOnHeight="500px"
                    scrollOnHeightMob="300px">
                    <CustomTable
                      isLoading={false}
                      rows={membersList}
                      headers={MEMBERS_TABLE_HEADERS}
                    />
                  </ScrollableDiv>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {selectedMember ? (
        <ViewMember
          onClose={() => setSelectedMember(null)}
          selectedMember={selectedMember}
        />
      ) : null}
    </PeopleWrapper>
  );
};

export default People;
