import styled from 'styled-components';

export const LogoSelectorWrapper = styled.div`
  & .main-content {
    margin-top: 10px;
    display: flex;
  }
  & .upload-logo-label {
    min-width: 90%;
    max-width: 90%;
  }
  & .required-logo {
    color: #ff1e26;
  }
  & .upload-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border: 2px solid #e9eef4;
    border-radius: 12px;
    height: 52px;
  }

  & .upload-text-container {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 14px;
    width: 100%;
    margin-left: 5px;
    border: none;
    outline: none;
    background-color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #404f67;
  }

  & .cross-icon {
    cursor: pointer;
  }
  & .label-style {
    font-weight: 600;
    font-size: 14px;
    color: #404f67;
  }

  & .upload-button {
    margin: 36px 0 0 4px;
  }

  & .upload-icon {
    background: #219fff;
    padding: 8px;
    border-radius: 10px;
    cursor: pointer;
  }

  & .upload-icon-disabled {
    background: rgba(33, 159, 255, 0.5);
    padding: 8px;
    border-radius: 10px;
    cursor: not-allowed;
  }

  & .remove-logo-icon {
    margin: 12px;
  }
`;
