import styled from 'styled-components';

export const FilterPostedJobsWrapper = styled.div`
  position: absolute;
  right: 0;
  min-width: 350px;
  min-height: 499px;
  background: #ffffff;
  border: 1px solid #f4f7fc;
  box-shadow: 0px 10px 12px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  z-index: 100;
  top: 80px;
  @media (min-width: 991px) {
    min-width: 450px;
  }
  .title {
    font-weight: 500;
    font-size: 16px;
    color: #17191d;
    margin: 20px 0 10px 25px;
  }
  .underline {
    width: 100%;
    border: 1px solid #e9eef4;
  }
  .filtered_postedjobs {
    &__wrapper {
      padding: 20px 25px;
      .custom-date {
        height: 52px;
        border-radius: 12px;
        border: 2px solid #e9eef4;
        padding: 17px;
        width: 100%;
        @media (max-width: 991px) {
          margin-bottom: 12px;
        }
      }
      .isHiring {
        @media (min-width: 991px) {
          margin-top: 50px;
        }
      }
    }
  }
`;
