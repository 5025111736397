import React from 'react';
import { Box } from '../../../../../components';
import TeamsCreated from '../../../../../assests/images/teamCreated.png';

export const Finish = () => {
  return (
    <>
      <Box mt="50px" display="flex" justifyContent="center">
        <div className="teams-created">
          <img src={TeamsCreated} alt="" width="100%" />
        </div>
      </Box>
      <Box textAlign="center" mt="30px" display="flex" justifyContent="center">
        <div className="teams-created-message">
          <span className="teams-created-title">
            {`Congrats, you have successfully created your Team.`}
          </span>
          <span className="teams-created-subtitle">{` Team joining request is sent to the fellow members`}</span>
        </div>
      </Box>
    </>
  );
};
