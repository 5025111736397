import styled from 'styled-components';
import { Country, State, City } from 'country-state-city';
import classname from 'classname';
import { DropDown } from 'components/dropDown';
import { useEffect, useState } from 'react';

export const CountryCityState = (props) => {
  const {
    className,
    countryLabel = 'Country',
    cityLabel = 'City',
    stateLabel = 'State',
    isCountryRequired = false,
    isStateRequired = false,
    isCityRequired = false,
    onCountrySelect,
    onStateSelect,
    onCitySelect,
    selectedCountry,
    selectedState,
    selectedCity,
  } = props;
  const allCountryLists = Country.getAllCountries();
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  useEffect(() => {
    if (!selectedCountry) {
      return;
    }
    const stateList = State.getStatesOfCountry(selectedCountry?.value);
    if (stateList.length === 0) {
      if (selectedState?.value !== 'N/A') {
        onStateSelect({
          name: 'N/A',
          value: 'N/A',
        });
      }
      setStateList([]);
      return;
    }
    setStateList(stateList);
  }, [selectedCountry, onStateSelect, selectedState?.value]);

  useEffect(() => {
    if (!selectedCountry || !selectedState) {
      return;
    }
    if (selectedState?.value === 'N/A') {
      if (selectedCity?.value !== 'N/A') {
        onCitySelect({
          name: 'N/A',
          value: 'N/A',
        });
      }
      setCityList([]);
      return;
    }
    const cityList = City.getCitiesOfState(
      selectedCountry?.value,
      selectedState?.value
    );
    if (cityList.length === 0) {
      if (selectedCity?.value !== 'N/A') {
        onCitySelect({
          name: 'N/A',
          value: 'N/A',
        });
      }
      setCityList([]);
      return;
    }
    setCityList(cityList);
  }, [selectedState, selectedCountry, onCitySelect, selectedCity?.value]);

  return (
    <>
      <Wrp className={classname('country-wrp', className)}>
        <DropDown
          label={countryLabel}
          items={allCountryLists}
          objectKey="name"
          placeholder="Select"
          onClick={(item) => {
            onCountrySelect({
              name: item.name,
              value: item.isoCode,
            });
          }}
          value={selectedCountry}
          hasSearchBox
          required={isCountryRequired}
        />
      </Wrp>
      <Wrp className={classname('city-wrp', className)}>
        <DropDown
          disabled={!selectedCountry}
          label={stateLabel}
          items={stateList}
          objectKey="name"
          placeholder="Select"
          onClick={(item) => {
            onStateSelect({
              name: item.name,
              value: item.isoCode,
            });
          }}
          value={selectedState}
          hasSearchBox
          required={isStateRequired}
        />
      </Wrp>
      <Wrp className={classname('city-wrp', className)}>
        <DropDown
          disabled={!selectedState}
          label={cityLabel}
          items={cityList}
          objectKey="name"
          placeholder="Select"
          onClick={(item) => {
            onCitySelect({
              name: item.name,
              value: item.name,
            });
          }}
          value={selectedCity}
          hasSearchBox
          required={isCityRequired}
        />
      </Wrp>
    </>
  );
};

const Wrp = styled.div`
  margin-top: 10px;
`;
