import React, { useState, useEffect } from 'react';
import { NavbarBrand1, ScrollableDiv, CopyWithIcon } from 'components';
import { INDIVIDUAL_DASHBOARD_NAV_LIST } from './constants';
import TokenService from 'services/token.service';
import {
  useAxiosWrapper,
  FETCH_TEAM_DETAILS_BASED_ON_TEAM_ID,
  FETCH_PUBLICATION_BASED_ON_TEAMID,
} from 'services';
import { FETCH_USER_PROFILE } from 'services';
import { getProfileText } from 'utlils/utils';
import {
  PublicationBlockWrapper,
  CoverImageWrapper,
  Title,
  Description,
  ContentWrapper,
  PublicationViewerWrapper,
  CollaboratorsWrapper,
  PublicationContentWrapper,
} from './style';
import { useParams, useNavigate } from 'react-router-dom';
import Icon from 'utlils/Icons/Icon';
import dayjs from 'dayjs';
import BgImg from 'assests/images/bgImgPublic.png';
import { DiscussionSection } from './components/DiscussionSection';

export const ViewPublication = (props) => {
  const { currentLocale, handleLocaleChange } = props;
  const [fnWrapper] = useAxiosWrapper();
  const { publicationId } = useParams();
  const navigate = useNavigate();
  const [publicationData, setPublicationData] = useState();
  const userType =
    publicationData?.user?.individual?.name?.length > 0
      ? 'individual'
      : 'enterprise';

  const [userProfile, setUserProfile] = useState({});
  const [collaboratorMembers, setCollaboratorMembers] = useState([]);
  const [collaboratorTeams, setCollaboratorTeams] = useState([]);

  const getUserProfile = async () => {
    const loggedInUser = TokenService.getSession();
    const res = await fnWrapper({
      url: `${FETCH_USER_PROFILE}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_USER_PROFILE',
    });
    if (res.data.statusCode === 200) {
      const response = res.data.data;
      setUserProfile({
        ...response,
        profileText: getProfileText(
          response?.role === 'INDIVIDUAL'
            ? response?.name
            : response?.enterpriseName
        ),
      });
    }
  };

  const getPublicationData = async () => {
    const res = await fnWrapper({
      url: `${FETCH_PUBLICATION_BASED_ON_TEAMID}?publicationId=${publicationId}`,
      method: 'GET',
      type: 'FETCH_USER_PROFILE',
    });
    if (res?.data?.statusCode === 200) {
      const { collaboratorMemberId, collaboratorTeamId } = res.data.data;

      if (collaboratorMemberId !== null && collaboratorMemberId.length > 0) {
        const membersList = await Promise.all(
          collaboratorMemberId.map(async (userId) => {
            const userRes = await fnWrapper({
              url: `${FETCH_USER_PROFILE}/${userId}`,
              method: 'GET',
              type: 'FETCH_USER_PROFILE',
            });

            if (userRes.data.statusCode === 200) {
              const userResponse = userRes.data.data;
              return {
                memberName: userResponse?.name,
                profilePicture: userResponse?.profilePicture,
                profileText: getProfileText(userResponse?.name),
                userId: userResponse?.userId,
              };
            }
          })
        );
        setCollaboratorMembers(membersList);
      }

      if (collaboratorTeamId && collaboratorTeamId.length > 0) {
        const teamsList = await Promise.all(
          collaboratorTeamId.map(async (teamId) => {
            const teamRes = await fnWrapper({
              url: `${FETCH_TEAM_DETAILS_BASED_ON_TEAM_ID}/${teamId}`,
              method: 'GET',
              type: 'FETCH_TEAM_DETAILS_BASED_ON_TEAM_ID',
            });

            if (teamRes.data.statusCode === 200) {
              const teamResponse = teamRes.data.data;
              return {
                id: teamResponse?.id,
                title: teamResponse?.teamName,
                value: teamResponse?.id,
              };
            }
          })
        );
        setCollaboratorTeams(teamsList);
      }
      setPublicationData(res?.data?.data);
    }
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  const navigateToPublicPageHandler = (
    collaboratorDetails,
    collaboratorType
  ) => {
    if (collaboratorType === 'INDIVIDUAL') {
      navigate(
        `/public/individual/${collaboratorDetails?.userId}/summary/basic-details`
      );
    }
    if (collaboratorType === 'TEAM') {
      navigate(`/public/team/${collaboratorDetails?.id}/details`);
    }
  };

  useEffect(() => {
    getUserProfile();
    getPublicationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <NavbarBrand1
        navMenu={INDIVIDUAL_DASHBOARD_NAV_LIST(userType)}
        currentLocale={currentLocale}
        handleLocaleChange={handleLocaleChange}
        userProfile={userProfile}
      />
      <PublicationBlockWrapper>
        <PublicationContentWrapper>
          <PublicationViewerWrapper>
            <CoverImageWrapper>
              <img
                src={
                  publicationData?.coverImage
                    ? publicationData?.coverImage
                    : BgImg
                }
                alt="coverImage"
              />
              <Title>{publicationData?.title}</Title>
              <div className="copy-link-category">
                <div className="category">{publicationData?.category}</div>
                <div className="copy-link">
                  <CopyWithIcon
                    id={publicationId}
                    textToCopy={`${process.env.REACT_APP_BASE_URL}/public/publication/${publicationId}`}
                  />
                </div>
              </div>
            </CoverImageWrapper>

            <ScrollableDiv scrollOnHeight="calc(100vh - 400px)">
              <Description>
                <div
                  dangerouslySetInnerHTML={{
                    __html: publicationData?.description,
                  }}
                />
              </Description>
            </ScrollableDiv>
          </PublicationViewerWrapper>

          {/* Attachments Block */}
          {publicationData?.attachments?.length > 0 && (
            <PublicationViewerWrapper>
              <ContentWrapper>
                <div className="title">Attachments</div>
                <div className="content">
                  {publicationData?.attachments.map((att, index) => (
                    <a href={att} key={index}>
                      <Icon name="attachment" />
                      {truncateText(att, 100)}
                    </a>
                  ))}
                </div>
              </ContentWrapper>
            </PublicationViewerWrapper>
          )}

          {/* collaborator Members Block */}
          {collaboratorMembers && collaboratorMembers.length > 0 && (
            <PublicationViewerWrapper>
              <ContentWrapper>
                <div className="title">Collaborator Members</div>
                <div className="content">
                  <CollaboratorsWrapper className="selected-collaborators-list">
                    {collaboratorMembers.map((collaborator, index) => (
                      <div
                        className="collaborator-items"
                        key={index}
                        onClick={() =>
                          navigateToPublicPageHandler(
                            collaborator,
                            'INDIVIDUAL'
                          )
                        }>
                        {collaborator?.profilePicture ? (
                          <img
                            className="profile-image"
                            src={collaborator?.profilePicture}
                            alt={collaborator?.memberName}
                          />
                        ) : (
                          <div className="profile-text">
                            {collaborator?.profileText}
                          </div>
                        )}
                        <div className="user-name">
                          {collaborator?.memberName}
                        </div>
                      </div>
                    ))}
                  </CollaboratorsWrapper>
                </div>
              </ContentWrapper>
            </PublicationViewerWrapper>
          )}

          {/* collaborators teams */}
          {collaboratorTeams && collaboratorTeams.length > 0 && (
            <PublicationViewerWrapper>
              <ContentWrapper>
                <div className="title">Collaborator Teams</div>
                <div className="content">
                  <CollaboratorsWrapper className="selected-collaborators-list">
                    {collaboratorTeams.map((collaborator, index) => (
                      <div
                        className="collaborator-items"
                        key={index}
                        onClick={() =>
                          navigateToPublicPageHandler(collaborator, 'TEAM')
                        }>
                        <div className="user-name">{collaborator?.title}</div>
                      </div>
                    ))}
                  </CollaboratorsWrapper>
                </div>
              </ContentWrapper>
            </PublicationViewerWrapper>
          )}

          {/* Links Block */}
          {publicationData?.referenceLink && (
            <PublicationViewerWrapper>
              <ContentWrapper>
                <div className="title">Learn More</div>
                <div className="content">
                  <a href={publicationData?.referenceLink}>
                    {publicationData?.referenceLink}
                  </a>
                </div>
              </ContentWrapper>
            </PublicationViewerWrapper>
          )}

          {/* Publisher Details Block */}
          <PublicationViewerWrapper>
            <ContentWrapper>
              <p className="title">Published by</p>
              <div className="publisher-content">
                <div className="publisher-image-name">
                  {publicationData?.teams ? (
                    publicationData?.teams?.profilePicture !== null ? (
                      <PublisherImage
                        src={publicationData?.teams?.profilePicture}
                      />
                    ) : (
                      <NoPublisherImage />
                    )
                  ) : publicationData?.group ? (
                    publicationData?.group?.profilePicture !== null ? (
                      <PublisherImage
                        src={publicationData?.group?.profilePicture}
                      />
                    ) : (
                      <NoPublisherImage />
                    )
                  ) : (
                    <NoPublisherImage />
                  )}
                  <div className="publisher-details">
                    <div className="published-by-team">
                      {publicationData?.teams
                        ? publicationData?.teams?.teamName
                        : publicationData?.group
                        ? publicationData?.group?.groupName
                        : ''}
                    </div>
                    <div className="published-by-user">
                      {publicationData?.user?.individual
                        ? publicationData?.user?.individual?.name
                        : publicationData?.user?.smallEnterprise
                            ?.enterpriseName}
                    </div>
                  </div>
                </div>
                <div className="published-time">
                  {dayjs(publicationData?.createdAt).format('DD MMM YYYY')}
                </div>
              </div>
            </ContentWrapper>
          </PublicationViewerWrapper>
        </PublicationContentWrapper>
        <DiscussionSection publicationId={publicationId}>
          <NoPublisherImage />
        </DiscussionSection>
      </PublicationBlockWrapper>
    </>
  );
};

const PublisherImage = ({ src, alt = 'publisher-image' }) => {
  return (
    <div className="profile-pic-class">
      <img src={src} alt={alt} />
    </div>
  );
};

const NoPublisherImage = () => {
  return (
    <div className="icon-style-class">
      <Icon name="people" size={24} color="#404f67" />
    </div>
  );
};
