import { ProjectPublicWrapper } from './style';
import { useState, useEffect, useCallback } from 'react';
import { Route, Routes, useParams, useSearchParams } from 'react-router-dom';
import {
  FETCH_USER_PROFILE,
  useAxiosWrapper,
  FETCH_PUBLIC_PROJECT_DETAILS,
} from 'services';
import TokenService from 'services/token.service';
import { getProfileText } from 'utlils/utils';
import { PROJECT_ACCESS, getProjectPublicLeftSideData } from './constants';
import { ProjectPublicDetails } from './ProjectPublicDetails';
import { ProjectPublicLinkedTeams } from './ProjectPublicLinkedTeams';
import { ProjectPublicLinkedProjects } from './ProjectPublicLinkedProjects';
import { ProjectPublicPublications } from './ProjectPublicPublications';
import { ProjectPublicOpenings } from './ProjectPublicOpenings';
import { ProjectPublicCard } from './Components';
import {
  INDIVIDUAL_DASHBOARD_NAV_LIST,
  TYPES,
} from 'pages/IndividualDashboard/constants';
import { capitalizeFirstLetter } from 'pages/IndividualDashboard/individualOrganizations/enterprise/helper';
import Icon from 'utlils/Icons/Icon';
import { NavbarBrand1, Sidebar } from 'components';
import { ProjectSelectCollaborations } from './ProjectSelectCollaborations';
import { ProjectSelectBiddings } from './ProjectSelectBiddings';

const DEFAULT_PROJECT_INFO = {
  projectName: '',
  projectType: '',
  projectOwner: '',
  profileImg: '',
  link: '',
  accessibility: 'PRIVATE',
  isBidding: false,
  isCollaboration: false,
  activeProjectCollaborations: [],
  activeProjectBiddings: [],
};

export const ProjectPublic = ({ currentLocale, handleLocaleChange }) => {
  const [fnWrapper] = useAxiosWrapper();
  const [userProfile, setUserProfile] = useState({});
  const { projectId } = useParams();
  const [projectInfo, setProjectInfo] = useState(DEFAULT_PROJECT_INFO);
  const [btns, setBtns] = useState([]);
  const [isNotLoggedInUser, setNotLoggedInUser] = useState(false);
  const [showCollaborateModal, setShowCollaborateModal] = useState(false);
  const [isCopyLink, setCopyLink] = useState(null);
  const [showBiddingModal, setShowBiddingModal] = useState(false);
  const [leftActiveItem, setLeftActiveItem] = useState('');
  const [searchParams] = useSearchParams();

  const setLeftSidebar = (type) => {
    setLeftActiveItem(type);
  };

  useEffect(() => {
    const collaborationId = searchParams.get('collaborationId');
    if (collaborationId) {
      setCopyLink(
        projectInfo?.activeProjectCollaborations?.find((collaboration) => {
          return collaboration?.id === searchParams?.get('collaborationId');
        })
      );
    }
  }, [searchParams, projectInfo?.activeProjectCollaborations]);

  useEffect(() => {
    if (isCopyLink) {
      setShowCollaborateModal(true);
    }
  }, [isCopyLink]);

  const getUserProfile = useCallback(async (loggedInUser) => {
    const res = await fnWrapper({
      url: `${FETCH_USER_PROFILE}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_USER_PROFILE',
    });
    if (res.data.statusCode === 200) {
      const response = res.data.data;
      setUserProfile({
        ...response,
        profileText: getProfileText(
          response?.role === 'INDIVIDUAL'
            ? response?.name
            : response?.enterpriseName
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProjectDetails = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_PUBLIC_PROJECT_DETAILS}/${projectId}?userId=${
        TokenService.getSession().id
      }`,
      method: 'GET',
      type: 'FETCH_PUBLIC_PROJECT_DETAILS',
    });
    if (res.data.statusCode === 200) {
      const response = res.data.data;
      const item = {
        projectName: response?.title,
        projectType: response?.projectType,
        projectOwner:
          response?.projectType === TYPES.ENTERPRISE
            ? response?.enterprise?.enterpriseName
            : response?.user?.individual?.name,
        profileImg: response?.profilePicture,
        link: response?.link,
        accessibility: response?.projectSettings?.accessibility
          ? PROJECT_ACCESS.PUBLIC
          : PROJECT_ACCESS.PRIVATE,
        isBidding: response?.projectSettings?.isBidding,
        isCollaboration: response?.projectSettings?.isCollaboration,
        activeProjectCollaborations: response?.projectCollaborations?.filter(
          (collabData) => collabData?.isOpen === true
        ),
        activeProjectBiddings: response?.projectBiddings?.filter(
          (biddingData) => biddingData?.isOpen === true
        ),
      };
      setProjectInfo(item);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const loggedInUser = TokenService.getSession();
    if (loggedInUser) {
      setNotLoggedInUser(false);
      getUserProfile(loggedInUser);
    } else {
      setNotLoggedInUser(true);
    }
  }, [getUserProfile]);

  useEffect(() => {
    getProjectDetails();
  }, [getProjectDetails, projectId]);

  const getButtons = useCallback(() => {
    let temp = [];
    // if (projectInfo.accessibility === PROJECT_ACCESS.PRIVATE) {
    //       temp.push({
    //         label: 'Request Link',
    //         variant: 'outline',
    //         color: 'themeSecondary',
    //         iconColor: '#219fff',
    //         iconName: 'link',
    //       });
    //       setBtns(temp);
    //     } else {
    temp = [];
    if (projectInfo?.activeProjectBiddings?.length > 0) {
      temp.push({
        label: 'Bid Project',
        variant: 'outline',
        color: 'themeSecondary',
        iconName: 'judge',
        iconColor: '#219fff',
        onClick: () => setShowBiddingModal(true),
      });
      // }
      if (projectInfo?.activeProjectCollaborations?.length > 0) {
        temp.push({
          label: 'Collaborate',
          variant: 'outline',
          color: 'themeSecondary',
          iconName: 'celo',
          iconColor: '#219fff',
          onClick: () => setShowCollaborateModal(true),
        });
      }
      setBtns(temp);
    }
  }, [projectInfo]);

  useEffect(() => {
    getButtons();
  }, [getButtons]);

  const infoItems = [
    {
      label: 'Website',
      value: projectInfo.link !== '' ? projectInfo.link : 'N/A',
      iconName: 'global',
    },
    {
      label: 'Access',
      value: capitalizeFirstLetter(projectInfo.accessibility.toLowerCase()),
      iconName:
        projectInfo.accessibility === PROJECT_ACCESS.PRIVATE
          ? 'lock-two'
          : 'eye-2',
    },
  ];

  return (
    <>
      <NavbarBrand1
        navMenu={INDIVIDUAL_DASHBOARD_NAV_LIST(userProfile?.role)}
        currentLocale={currentLocale}
        handleLocaleChange={handleLocaleChange}
        userProfile={userProfile}
      />
      <ProjectPublicWrapper>
        <div className="main-container">
          <div className="layout-container">
            <div className="layout-upper-container">
              <div className="upper-container-child">
                <ProjectPublicCard
                  projectName={projectInfo.projectName}
                  projectOwner={projectInfo.projectOwner}
                  projectType={projectInfo.projectType}
                  profileImgSrc={projectInfo.profileImg}
                  buttons={btns}
                  infoItems={infoItems}
                  isNotLoggedInUser={isNotLoggedInUser}
                />
              </div>
              <div className="upper-container-child">
                <div className="d-flex align-items-center">
                  <Icon name="brief-case" color="#404f67" size={24} />
                  <span className="px-2" style={{ color: '#404f67' }}>
                    <strong>Hiring</strong>
                  </span>
                </div>
                <div className="d-flex justify-content-center align-items-center h-100 flex-column">
                  <Icon name="warning" size={40} color="#929daf" />
                  <div className="py-3 text-center">
                    It looks like they are not hiring at the moment
                  </div>
                </div>
              </div>
            </div>
            <div className="layout-lower-container">
              <div className="sub-container">
                <Sidebar
                  sidebarData={getProjectPublicLeftSideData(projectId)}
                  activeType={leftActiveItem}
                  setActiveType={setLeftSidebar}
                />
              </div>
              <div className="sub-container">
                <Routes>
                  <Route path="">
                    <Route
                      element={
                        <ProjectPublicDetails
                          access={projectInfo.accessibility}
                          isNotLoggedInUser={isNotLoggedInUser}
                        />
                      }
                      path="details/*"
                    />
                    <Route
                      element={
                        <ProjectPublicLinkedTeams
                          access={projectInfo.accessibility}
                          isNotLoggedInUser={isNotLoggedInUser}
                        />
                      }
                      path="linked-teams/*"
                    />
                    <Route
                      element={
                        <ProjectPublicLinkedProjects
                          access={projectInfo.accessibility}
                          isNotLoggedInUser={isNotLoggedInUser}
                        />
                      }
                      path="linked-projects/*"
                    />
                    <Route
                      element={
                        <ProjectPublicPublications
                          isNotLoggedInUser={isNotLoggedInUser}
                        />
                      }
                      path="publications/*"
                    />
                    <Route
                      element={
                        <ProjectPublicOpenings
                          currentLocale={currentLocale}
                          handleLocaleChange={handleLocaleChange}
                          access={projectInfo.accessibility}
                          isNotLoggedInUser={isNotLoggedInUser}
                        />
                      }
                      path="openings/*"
                    />
                  </Route>
                </Routes>
              </div>
            </div>
          </div>
        </div>
        {showCollaborateModal && (
          <ProjectSelectCollaborations
            isInvited={isCopyLink}
            setProjectInfo={setProjectInfo}
            onClose={() => {
              setShowCollaborateModal(false);
              setCopyLink(null);
            }}
            collaborations={projectInfo.activeProjectCollaborations}
          />
        )}
        {showBiddingModal && (
          <ProjectSelectBiddings
            setProjectInfo={setProjectInfo}
            onClose={() => setShowBiddingModal(false)}
            biddings={projectInfo.activeProjectBiddings}
          />
        )}
      </ProjectPublicWrapper>
    </>
  );
};
