import styled from 'styled-components';

export const SelectBiddingWrapper = styled.div`
  & .radio-btn-label {
    margin-top: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #404f67;
  }
  & .radio-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  & .radio-group-label {
    margin: 12px 0;
  }
`;
