import styled from 'styled-components';

export const InfoCardWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  height: 58px;
  background: #ffffff;
  border: 1px solid #ccd2dd;
  border-radius: 12px;

  & .div-style {
    display: flex;
    justify-content: flex-start;
    margin-left: 15px;
    align-items: center;
    width: 100%;

    & .text-style {
      margin-left: 30px;
      text-align: center;
      font-family: 'Poppins';
      font-style: normal;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      color: #040d20;
    }
  }
`;
