import styled from 'styled-components';

export const RequiredDetailsWrapper = styled.div`
  & .big-heading {
    margin: 12px 0;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }
  & .doc-container-main {
    margin: 32px 0;
  }

  & .doc-container-main-nq {
    margin: 12px 0;
  }
  & .document-list {
    margin: 12px 0;
  }
  & .tc-container-main {
    margin: 32px 0;
  }
  & .accept-terms {
    margin: 40px 0;
  }
  & .accept-checkbox-mobile {
    justify-content: start;
    flex-direction: column;
  }
  & .accept-checkbox {
    justify-content: start;
  }

  & .accept-checkbox-text {
    margin-left: 12px;
    margin-top: 12px;
  }
  & .document-container {
    margin-top: 12px;
    display: flex;
    justify-content: space-between;
  }

  & .document-container-mobile {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
  }

  & .document-container-buttons {
    display: flex;
    & .download-link {
      text-decoration: none;
      & .download-btn {
        font-size: 14px;
      }
    }
    & .upload-btn {
      font-size: 14px;
    }
  }
  & .sub-heading-document {
    color: #404f67;
    margin-left: 12px;
    font-size: 14px;
  }
  & .attached-doc-text {
    font-size: 14px;
    color: #219fff;
  }
  & .tc-container {
    margin: 12px 0;
    display: flex;
  }

  & .sub-heading-tc {
    margin-left: 12px;
    font-size: 14px;
  }

  & .bid-rate {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #404f67;
    .bid-rate-text {
      border: 1px solid #219fff;
      font-size: 14px;
      border-radius: 6px;
      padding: 16px;
      text-align: center;
      background: #219fff33;
      color: #219fff;
    }
  }
  & .range-separator {
    margin: 16px;
    color: #219fff;
  }
  & .last-accepted {
    color: #929daf;
    font-style: italic;
  }
`;
