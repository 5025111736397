import { CustomButton } from 'components';
import RadioGroup from 'pages/IndividualDashboard/components/radioGroup';

export const SelectCollaboration = ({
  collaborations,
  nextPage,
  selectedCollaboration,
  setSelectedCollaboration,
}) => {
  const handleSelectCollaborationOption = (e) => {
    const { value } = e.target;
    setSelectedCollaboration(
      collaborations.find((collabData) => collabData.id === value)
    );
  };

  return (
    <div>
      <div>
        <p className="sub-heading">
          Please select one of the active collaboration types you would like to
          continue with-
        </p>
      </div>
      <div className="radio-btn-label">
        <RadioGroup
          customClassName="radio-group"
          labelClassName="radio-group-label"
          labels={collaborations}
          currChecked={selectedCollaboration?.id}
          handleChange={(e) => {
            handleSelectCollaborationOption(e);
          }}
        />
      </div>
      <div className="popup-button">
        <CustomButton
          disabled={!selectedCollaboration}
          label={'Continue'}
          color="themeSecondary"
          className="w-100"
          backgroundColor={'#219FFF'}
          onClick={nextPage}
        />
      </div>
    </div>
  );
};
