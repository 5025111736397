import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CustomButton } from 'components';
import Icon from 'utlils/Icons/Icon';
import { ERecordWrapper } from './style';
import { ViewErecordPopup } from './ViewErecordPopup';
import { getProfileText } from 'utlils/utils';
import {
  FETCH_ERECORD_DETAILS,
  FETCH_USER_PROFILE,
  FETCH_ERECORD_TECH_STACK,
  useAxiosWrapper,
} from 'services';

const recordData = [
  { label: 'No. of Teams enrolled', value: 0, key: 'teamsEnrolled' },
  { label: 'No. of Projects enrolled', value: 0, key: 'projectsEnrolled' },
  { label: 'Rate of Task completion', value: 0, key: 'rateOfTaskCompletion' },
  { label: 'On-time completed tasks', value: 0, key: 'onTimeCompletedTasks' },
];

export const ERecord = ({ taskType, hideERecord }) => {
  const DEFAULT_ERECORD_ACTION = {
    type: '',
    isModalOpen: false,
    data: null,
  };
  const [publicUserProfile, setPublicUserProfile] = useState({});
  // State to store the lengths
  const [enrolledTeamsCount, setEnrolledTeamsCount] = useState(0);
  const [managedTeamsCount, setManagedTeamsCount] = useState(0);
  const [enrolledProjectsCount, setEnrolledProjectsCount] = useState(0);
  const [managedProjectsCount, setManagedProjectsCount] = useState(0);
  const [taskStatisticsData, setTaskStatisticsData] = useState({});
  const [eRecordTechnologyList, setERecordTechnologyList] = useState([]);

  const [erecordAction, setErecordAction] = useState(DEFAULT_ERECORD_ACTION);
  const { userId } = useParams();
  const [fnWrapper] = useAxiosWrapper();

  const getUserPublicProfile = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_USER_PROFILE}/${userId}`,
      method: 'GET',
      type: 'FETCH_USER_PROFILE',
    });
    if (res.data.statusCode === 200) {
      const response = res.data.data;
      setPublicUserProfile({
        ...response,
        profileText: getProfileText(
          response?.role === 'INDIVIDUAL'
            ? response?.name
            : response?.enterpriseName
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // api to fetch eRecords details
  const fetchErecordDetails = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_ERECORD_DETAILS}/${userId}`,
      method: 'GET',
      type: 'FETCH_ERECORD_DETAILS',
    });

    if (res.data.statusCode === 200) {
      const result = res.data.data;
      setEnrolledTeamsCount(result.enrolledTeamList.length);
      setEnrolledProjectsCount(result.enrolledProjectList.length);
      setTaskStatisticsData(result?.taskStatistics);

      // Calculate managed teams count
      const managedTeams = result.enrolledTeamList.filter((team) =>
        team?.teamMembers.some(
          (member) => member.memberUserId === userId && member.role === 'ADMIN'
        )
      );
      setManagedTeamsCount(managedTeams.length);

      // Calculate managed projects count
      const managedProjects = result.enrolledProjectList.filter(
        (project) => project?.userId === userId
      );
      setManagedProjectsCount(managedProjects.length);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // api to fetch e record technology list
  const fetchErecordTechnology = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_ERECORD_TECH_STACK}/${userId}`,
      method: 'GET',
      type: 'FETCH_ERECORD_TECH_STACK',
    });

    if (res.data.statusCode === 200) {
      const result = res.data.data;
      if (result && result.technology && result.technology.length > 0) {
        setERecordTechnologyList(result.technology);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchErecordDetails();
    getUserPublicProfile();
    fetchErecordTechnology();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updatedRecordData = recordData.map((record) => {
    if (record.key === 'teamsEnrolled') {
      return { ...record, value: enrolledTeamsCount };
    } else if (record.key === 'projectsEnrolled') {
      return { ...record, value: enrolledProjectsCount };
    } else if (record.key === 'rateOfTaskCompletion') {
      return { ...record, value: taskStatisticsData.rateOfTaskCompletion };
    } else if (record.key === 'onTimeCompletedTasks') {
      return {
        ...record,
        value: taskStatisticsData.rateOfOnTimeTaskCompletion,
      };
    }
    return record;
  });

  // check
  const allValuesAreZero = updatedRecordData.every((item) => item.value === 0);

  const openPopupHandler = () => {
    setErecordAction({
      data: {},
      isModalOpen: true,
      type: {},
    });
  };

  return (
    <ERecordWrapper>
      <div className="heading">
        <Icon name="stats" color="#404f67" size={18} />
        <span>E-Record</span>
      </div>
      {taskType === 'Public' && hideERecord ? (
        <div className="stats-not-availabe">
          <Icon name="lock" size={50} />
          <p>This E-Record is set as Private</p>
        </div>
      ) : taskType === 'Public' && allValuesAreZero ? (
        <div className="stats-not-availabe">
          <Icon name="warning" size={50} />
          <p>Stats Not Available</p>
        </div>
      ) : (
        <div>
          <div className="record-content-container">
            {updatedRecordData?.map((record, index) => (
              <div className="record-class" key={index}>
                <p className="record-title">{record.label}</p>
                <div className="record-stats">
                  {record.key === 'rateOfTaskCompletion' ||
                  record.key === 'onTimeCompletedTasks' ? (
                    <span className="record-percent">%</span>
                  ) : (
                    <Icon name="people" color="#219FFF" />
                  )}
                  <span>{record.value} </span>
                </div>
              </div>
            ))}
          </div>
          {taskType === 'Private' ? (
            <CustomButton
              className="w-100"
              variant="outline"
              onClick={openPopupHandler}>
              <Icon name="edit" color="#0ABD00" />
              Edit E-Record
            </CustomButton>
          ) : (
            <CustomButton className="w-100" onClick={openPopupHandler}>
              View Full E-Record
              <Icon name="arrow-forward" color="#fff" />
            </CustomButton>
          )}
          {erecordAction.isModalOpen && (
            <ViewErecordPopup
              onClose={() => setErecordAction(DEFAULT_ERECORD_ACTION)}
              erecordAction={erecordAction}
              enrolledTeamsCount={enrolledTeamsCount}
              enrolledProjectsCount={enrolledProjectsCount}
              managedTeamsCount={managedTeamsCount}
              managedProjectsCount={managedProjectsCount}
              publicUserProfile={publicUserProfile}
              taskType={taskType}
              taskStatisticsData={taskStatisticsData}
              eTechnology={eRecordTechnologyList}
            />
          )}
        </div>
      )}
    </ERecordWrapper>
  );
};
