import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Icon from 'utlils/Icons/Icon';
import { isMobile } from 'utlils/isMobile';
import { getDateTime } from 'utlils/utils';
import { taskUrgency } from 'pages/enterpriseDashboard/helper';
import tokenService from 'services/token.service';

import { AssignToTeamModal } from './assignToTeamModal';
import { SetPriorityModal } from './setPriorityModal';
import { ReAssignDepartmentModal } from './reAssignDepartmentModal';
import { ActionSection } from './actionSection';

import CommonDropDown from 'pages/enterpriseDashboard/components/commonDropDown';
import { TaskCardWrapper } from '../TaskList/style';
import './styles.scss';
import DotActive from '../../../../../../../../assests/images/dotActive.png';
import DotDisable from '../../../../../../../../assests/images/dotDisable.png';
import Send from '../../../../../../../../assests/images/send.png';

import {
  Box,
  Loader,
  ModalPopup,
  ScrollableDiv,
  StatusButton,
} from 'components';
import {
  STATUS_DROPDOWN_LIST_VIEW_TASK,
  TASK_PRIORITY_VALUES,
  TASK_STATUS,
  TASK_TOGGLE_LABEL,
  VIEW_TASK_POPUP_MENU_OPTIONS,
} from '../../../../constants';
import {
  ASSIGN_TASK_TO_TEAM,
  FETCH_TASK_BY_ID,
  FETCH_TEAM_BY_DEPT_ID,
  POST_TASK_COMMENTS,
  SET_TASK_PRIORITY,
  TASK_REASSIGN_DEPARTMENT,
  UPDATE_TASK_STATUS,
  useAxiosWrapper,
} from 'services';
import { noop } from 'lodash';

const DEFAULT_ASSIGN_TO_TEAM_STATE = {
  isModalOpen: false,
  team: {},
  type: '',
  selectedTeam: '',
};
const DEFAULT_SET_PRIORITY_STATE = {
  isModalOpen: false,
  remarkPriority: '',
  priority: {},
};
const DEFAULT_REASSIGN_DEPARTMENT_STATE = {
  isModalOpen: false,
  selectedDepartment: '',
  reAssignedDepartment: {},
};
const DEFAULT_DROPDOWN_STATE = {
  backgroundColor: '#f4f7fc',
  color: '#929daf',
};

export const ViewTaskPopUp = ({
  departmentList,
  taskType,
  viewTask,
  viewTaskCloseHandler,
}) => {
  const taskId = viewTask?.item?.id;
  const [fnWrapper, context] = useAxiosWrapper();
  let { id } = useParams();

  const [task, setTask] = useState(null);
  const [isTaskFetching, setIsTaskFetching] = useState(true);
  const [taskStatus, setTaskStatus] = useState();
  const [taskComment, setTaskComment] = useState('');
  const [teamList, setTeamList] = useState();

  const [assignToTeam, setAssignToTeam] = useState(
    DEFAULT_ASSIGN_TO_TEAM_STATE
  );
  const [priorityState, setPriorityState] = useState(
    DEFAULT_SET_PRIORITY_STATE
  );
  const [reAssignDepartment, setReAssignDepartment] = useState(
    DEFAULT_REASSIGN_DEPARTMENT_STATE
  );

  const [popupMenuOptions, setPopupMenuOptions] = useState();
  const [isPopMenuActive, setIsPopMenuActive] = useState(false);
  const [dropDownStyle, setDropDownStyle] = useState(DEFAULT_DROPDOWN_STATE);

  const dropDownStyleHandler = useCallback(async (status) => {
    if (status === TASK_STATUS.IN_PROGRESS) {
      setDropDownStyle({
        backgroundColor: 'rgba(255, 164, 18, 0.2)',
        color: 'rgba(255, 164, 18, 1)',
      });
    } else if (status === TASK_STATUS.COMPLETED) {
      setDropDownStyle({
        backgroundColor: 'rgba(10, 189, 0, 0.2)',
        color: 'rgba(10, 189, 0, 1)',
      });
    } else if (status === TASK_STATUS.DECLINED) {
      setDropDownStyle({
        backgroundColor: 'rgba(230, 102, 87, 0.3)',
        color: 'rgba(230, 102, 87, 1)',
      });
    }
  }, []);

  const popupMenuOptionsHandler = useCallback(
    async (task) => {
      switch (taskType) {
        case TASK_TOGGLE_LABEL.MY_TASKS:
          task?.assignedToTeam === null
            ? setPopupMenuOptions([
                VIEW_TASK_POPUP_MENU_OPTIONS[0],
                VIEW_TASK_POPUP_MENU_OPTIONS[2],
              ])
            : setPopupMenuOptions(VIEW_TASK_POPUP_MENU_OPTIONS);
          break;
        case TASK_TOGGLE_LABEL.OTHER_TASKS:
          setPopupMenuOptions(VIEW_TASK_POPUP_MENU_OPTIONS.slice(2));
          break;
        default:
          break;
      }
    },
    [taskType]
  );

  const getTaskById = useCallback(async () => {
    const payload = {
      taskId: taskId,
      taskType: 'DEPARTMENT_TASK',
    };
    const res = await fnWrapper({
      url: `${FETCH_TASK_BY_ID}`,
      method: 'GET',
      type: 'FETCH_TASK_BY_ID',
      params: payload,
    });

    if (res.data.statusCode === 200) {
      await dropDownStyleHandler(res.data.data.status);
      popupMenuOptionsHandler(res.data.data);

      let statusObject = STATUS_DROPDOWN_LIST_VIEW_TASK.filter(
        (ele) => ele.value === res.data.data.status
      );
      setTaskStatus(statusObject[0]);
      setTask(res.data.data);
      setIsTaskFetching(false);
    }
  }, [dropDownStyleHandler, fnWrapper, popupMenuOptionsHandler, taskId]);

  useEffect(() => {
    getTaskById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeDropdownHandler = async (event) => {
    if (event.id === 'SET_PRIORITY') {
      setPriorityState({
        ...priorityState,
        isModalOpen: true,
      });
    }
    if (event.id === 'CHANGE_TEAM') {
      await getTeamListByDepartmentId();
      setAssignToTeam({
        ...assignToTeam,
        isModalOpen: true,
        type: 'CHANGE_TEAM',
        selectedTeam: task?.assignedToTeam,
      });
    }
    setIsPopMenuActive(false);
  };

  const getTeamListByDepartmentId = async () => {
    const res = await fnWrapper({
      url: `${FETCH_TEAM_BY_DEPT_ID}/${id}`,
      method: 'GET',
      type: 'FETCH_TEAM_BY_DEPT_ID',
    });

    if (res.data.statusCode === 200) {
      setTeamList(res.data.data.teams);
    }
  };

  const onClickAssignToTeamButton = async () => {
    await getTeamListByDepartmentId();
    setAssignToTeam({
      ...assignToTeam,
      isModalOpen: true,
      type: 'ASSIGN_TEAM',
    });
  };

  const onAssignToTeamHandler = async () => {
    const loggedInUser = await tokenService.getSession();
    const payload = {
      taskId: taskId,
      assignedToTeamId: assignToTeam.team.id,
      assignedTeamByUserId: loggedInUser.id,
    };
    const res = await fnWrapper({
      url: `${ASSIGN_TASK_TO_TEAM}`,
      method: 'PUT',
      type: 'ASSIGN_TASK_TO_TEAM',
      payload: payload,
    });

    if (res.data.statusCode === 200) {
      await getTaskById();
      await popupMenuOptionsHandler();
      setAssignToTeam(DEFAULT_ASSIGN_TO_TEAM_STATE);
    }
  };

  const onCloseActionModal = (type) => {
    if (type === 'ASSIGN_TO_TEAM') {
      setAssignToTeam(DEFAULT_ASSIGN_TO_TEAM_STATE);
    }
    if (type === 'SET_PRIORITY') {
      setPriorityState(DEFAULT_SET_PRIORITY_STATE);
    }
    if (type === 'TASK_REASSIGN_DEPARTMENT') {
      setReAssignDepartment(DEFAULT_REASSIGN_DEPARTMENT_STATE);
    }
  };

  const onChangeHandler = (type, field = '', value = null) => {
    if (type === 'ASSIGN_TEAM') {
      setAssignToTeam({
        ...assignToTeam,
        [field]: value,
      });
    }
    if (type === 'SET_PRIORITY') {
      setPriorityState({
        ...priorityState,
        [field]: value,
      });
    }
    if (type === 'TASK_REASSIGN_DEPARTMENT') {
      setReAssignDepartment({
        ...reAssignDepartment,
        [field]: value,
      });
    }
  };

  const onSetTaskPriorityHandler = async () => {
    const payload = {
      taskId: taskId,
      priority: priorityState.priority.value,
      remark: priorityState.remarkPriority,
    };
    const res = await fnWrapper({
      url: `${SET_TASK_PRIORITY}`,
      method: 'PUT',
      type: 'SET_TASK_PRIORITY',
      payload: payload,
    });

    if (res.data.statusCode === 200) {
      await getTaskById();
      setPriorityState(DEFAULT_SET_PRIORITY_STATE);
    }
  };

  const onClickReAssignDepartmentButton = async () => {
    setReAssignDepartment({
      ...reAssignDepartment,
      isModalOpen: true,
      selectedDepartment: task?.assignedToDepartment,
    });
  };

  const onTaskReAssignDepartmentHandler = async () => {
    const payload = {
      taskId: taskId,
      assignedToDeptId: reAssignDepartment.reAssignedDepartment.id,
    };
    const res = await fnWrapper({
      url: `${TASK_REASSIGN_DEPARTMENT}`,
      method: 'PUT',
      type: 'TASK_REASSIGN_DEPARTMENT',
      payload: payload,
    });

    if (res.data.statusCode === 200) {
      await getTaskById();
      setReAssignDepartment(DEFAULT_REASSIGN_DEPARTMENT_STATE);
    }
  };

  const popupMenuClickHandler = () => {
    setIsPopMenuActive(!isPopMenuActive);
  };

  const updateTaskStatusHandler = async (status) => {
    const payload = {
      taskId: task.id,
      status: status,
    };

    const res = await fnWrapper({
      url: `${UPDATE_TASK_STATUS}`,
      method: 'PUT',
      type: 'UPDATE_TASK_STATUS',
      payload: payload,
    });

    if (res.data.statusCode === 200) {
      let statusObject = STATUS_DROPDOWN_LIST_VIEW_TASK.filter(
        (ele) => ele.value === status
      );
      setTaskStatus(statusObject[0]);
      await getTaskById();
    }
  };

  const onChangeCommentsHandler = (e) => setTaskComment(e.target.value);

  const sendCommentHandler = async () => {
    const loggedInUser = await tokenService.getSession();

    const payload = {
      taskId: task.id,
      commentMessage: taskComment,
      departmentId: id,
      taskType: 'DEPARTMENT_TASK',
      userId: loggedInUser.id,
    };

    const res = await fnWrapper({
      url: `${POST_TASK_COMMENTS}`,
      method: 'POST',
      type: 'POST_TASK_COMMENTS',
      payload: payload,
    });

    if (res.data.statusCode === 200) {
      await getTaskById();
      setTaskComment('');
    }
  };

  return (
    task && (
      <div className="view-task-popup-wrapper">
        <ModalPopup
          open={viewTask.isDisplay}
          onClose={viewTaskCloseHandler}
          maxContentWidth="750px"
          title="View Task">
          {isTaskFetching ? (
            <Loader loading style={{ marginTop: '2rem' }} />
          ) : (
            <>
              <TaskCardWrapper className="task-card card p-0">
                {isMobile() ? (
                  <MobileTaskCardHeader
                    task={task}
                    taskType={taskType}
                    isPopMenuActive={isPopMenuActive}
                    popupMenuOptions={popupMenuOptions}
                    popupMenuClickHandler={popupMenuClickHandler}
                    onChangeDropdownHandler={onChangeDropdownHandler}
                  />
                ) : (
                  <DesktopTaskCardHeader
                    task={task}
                    taskType={taskType}
                    isPopMenuActive={isPopMenuActive}
                    popupMenuOptions={popupMenuOptions}
                    popupMenuClickHandler={popupMenuClickHandler}
                    onChangeDropdownHandler={onChangeDropdownHandler}
                  />
                )}
                <div className="task-card__content-wrapper row">
                  <div className="col-md-9 col-lg-8 col-xl-8 col-xxl-9">
                    <div className="task-card__content-wrapper__header">
                      {task?.taskTitle}
                    </div>
                    <div className="task-card__content-wrapper__description">
                      <div>{task?.taskDescription}</div>
                      <div className="task-card__content-wrapper__description__attachments">
                        {task?.attachments !== null &&
                          task?.attachments.map((ele, index) => {
                            return (
                              <a key={index} href={ele}>
                                {/[^/]*$/.exec(ele)}
                              </a>
                            );
                          })}
                      </div>
                      <div className="task-card__content-wrapper__description__priority">
                        <div>
                          <strong>
                            <i>{TASK_PRIORITY_VALUES[task?.priority]}</i>
                          </strong>
                        </div>
                        <div>{task?.remarkPriority}</div>
                      </div>
                    </div>
                  </div>

                  <ActionSection
                    ctx={context}
                    dropDownStyle={dropDownStyle}
                    onClickAssignToTeamButton={onClickAssignToTeamButton}
                    onClickReAssignDepartmentButton={
                      onClickReAssignDepartmentButton
                    }
                    taskStatus={taskStatus}
                    taskType={taskType}
                    task={task}
                    updateTaskStatusHandler={updateTaskStatusHandler}
                  />
                </div>
              </TaskCardWrapper>
              <div className="comment_wrapper">
                <div className="comment_area">
                  <textarea
                    placeholder={'Write your queries or issues, if any...'}
                    label="Comments"
                    className="comment-textarea"
                    value={taskComment}
                    onChange={(e) => onChangeCommentsHandler(e)}
                  />
                  <div
                    onClick={taskComment ? sendCommentHandler : noop}
                    className={'comment_area__action-btn'}
                    style={{
                      cursor: `${
                        taskComment === '' ? 'not-allowed' : 'pointer'
                      }`,
                    }}>
                    <img src={Send} alt="send" width="30px" />
                  </div>
                </div>
                <div className="comments_list">
                  <ScrollableDiv
                    scrollOnHeight={`${
                      task?.taskComments.length > 2 ? `240px` : ''
                    }`}
                    scrollOnHeightMob={`${
                      task?.taskComments.length > 2 ? `200px` : ''
                    }`}>
                    {task?.taskComments.length !== 0 &&
                      task?.taskComments.map(
                        (
                          { Department, user, commentMessage, createdAt },
                          index
                        ) => {
                          var DateTime = new Date(createdAt);
                          let date = DateTime.toLocaleDateString();
                          let time = DateTime.toLocaleTimeString();
                          return (
                            <div className="comment" key={index}>
                              <Box
                                display="flex"
                                justifyContent="space-between">
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center">
                                  <Icon
                                    color="rgba(33, 211, 160, 1)"
                                    name="department"
                                    size={30}
                                    leftIcon
                                    className="department-style"
                                  />
                                  <b className="department-text">
                                    {Department?.departmentName}
                                  </b>
                                  {user?.individual && (
                                    <span className="department-sub-text">
                                      ({user?.individual.name})
                                    </span>
                                  )}
                                  {user?.smallEnterprise && (
                                    <span className="department-sub-text">
                                      ({user?.smallEnterprise.enterpriseName})
                                    </span>
                                  )}
                                </Box>
                                <Box
                                  display="flex"
                                  justifyContent="end"
                                  alignItems="center">
                                  <span className="department-style">
                                    {time}
                                  </span>
                                  <span className="department-style">
                                    {date}
                                  </span>
                                  <img
                                    src={DotDisable}
                                    alt=""
                                    style={{ marginLeft: '20px' }}
                                  />
                                </Box>
                              </Box>
                              <p className="department-description">
                                {commentMessage}
                              </p>
                            </div>
                          );
                        }
                      )}
                  </ScrollableDiv>
                </div>
              </div>
            </>
          )}
        </ModalPopup>

        {assignToTeam.isModalOpen && (
          <AssignToTeamModal
            assignToTeam={assignToTeam}
            ctx={context}
            onAssignToTeam={onAssignToTeamHandler}
            onClose={onCloseActionModal}
            onChange={onChangeHandler}
            teamList={teamList}
          />
        )}
        {priorityState.isModalOpen && (
          <SetPriorityModal
            ctx={context}
            onClose={onCloseActionModal}
            onChange={onChangeHandler}
            onSetPriority={onSetTaskPriorityHandler}
            priorityState={priorityState}
          />
        )}
        {reAssignDepartment.isModalOpen && (
          <ReAssignDepartmentModal
            ctx={context}
            departmentList={departmentList}
            onClose={onCloseActionModal}
            onChange={onChangeHandler}
            onReAssign={onTaskReAssignDepartmentHandler}
            state={reAssignDepartment}
          />
        )}
      </div>
    )
  );
};

const DesktopTaskCardHeader = ({
  task,
  taskType,
  popupMenuOptions,
  isPopMenuActive,
  popupMenuClickHandler,
  onChangeDropdownHandler,
}) => {
  return (
    <div className="task-card__header-wrapper">
      <StatusButton status={taskUrgency[task?.urgency]} />
      <div className="task-card__header-wrapper__icon-with-label">
        <Icon color="rgba(146, 157, 175, 1)" name="ticket" size={25} leftIcon />
        <div>{task?.ticketId}</div>
      </div>
      <div>Posted {getDateTime(task?.createdAt)}</div>
      <div className="task-card__header-wrapper__icon-with-label">
        <Icon
          color="rgba(146, 157, 175, 1)"
          name="department"
          size={30}
          leftIcon
        />
        {taskType === TASK_TOGGLE_LABEL.MY_TASKS ? (
          <div>Assigned by-{task.assignedByDepartment.departmentName}</div>
        ) : (
          <div>Assigned to-{task.assignedToDepartment.departmentName}</div>
        )}
      </div>
      <div>
        <div
          className="task-card__header-wrapper__menu"
          onClick={() => popupMenuClickHandler()}>
          <img src={isPopMenuActive ? DotActive : DotDisable} alt="" />
        </div>
        <CommonDropDown
          dropItem={popupMenuOptions}
          objectKey="label"
          active={isPopMenuActive}
          onClick={(event) => {
            onChangeDropdownHandler(event);
          }}
          top="65px"
          right="-32px"
        />
      </div>
    </div>
  );
};

const MobileTaskCardHeader = ({
  task,
  taskType,
  isPopMenuActive,
  popupMenuOptions,
  popupMenuClickHandler,
  onChangeDropdownHandler,
}) => {
  return (
    <div className="task-card__mobile-header-wrapper">
      <div className="task-card__mobile-header-wrapper__row--1">
        <StatusButton status={taskUrgency[task.urgency]} />
        <div className="task-card__mobile-header-wrapper__icon-with-label">
          <Icon
            color="rgba(146, 157, 175, 1)"
            name="ticket"
            size={25}
            leftIcon
          />
          <div>{task.ticketId}</div>
        </div>
        <div>
          <div
            className="task-card__mobile-header-wrapper__menu"
            onClick={() => popupMenuClickHandler(task.id)}>
            <img src={isPopMenuActive ? DotActive : DotDisable} alt="" />
          </div>
          <CommonDropDown
            dropItem={popupMenuOptions}
            objectKey="label"
            active={isPopMenuActive}
            onClick={(evt) => onChangeDropdownHandler(evt)}
            mobileTop="70px"
            mobileRight="0px"
          />
        </div>
      </div>
      <div className="task-card__mobile-header-wrapper__row--2">
        <div className="task-card__mobile-header-wrapper__icon-with-label">
          <Icon
            color="rgba(146, 157, 175, 1)"
            name="department"
            size={30}
            leftIcon
          />
          {taskType === TASK_TOGGLE_LABEL.MY_TASKS ? (
            <div>Assigned by-{task.assignedByDepartment.departmentName}</div>
          ) : (
            <div>Assigned to-{task.assignedToDepartment.departmentName}</div>
          )}
        </div>
        <div>Posted {getDateTime(task.createdAt)}</div>
      </div>
    </div>
  );
};
