import { useState } from 'react';
import PropTypes from 'prop-types';
import { useDrag } from 'react-dnd';
import { truncateString } from 'utlils/utils.js';
import { itemTypes } from 'utlils/itemTypes.js';
import Icon from 'utlils/Icons/Icon.js';
import { MultiUserProfile, OptionMenuWithDot } from 'components';
import { PriorityBlock } from './priorityBlock.js';
import { DueDate } from './dueDate.js';
import { TaskManageCardWrapper } from './style.js';
import { StageBlock } from './stageBlock.js';
import { AssignToMemberPopUp } from 'pages/TeamDashboard/components/index.js';
import { ViewTaskPopup } from '../TaskBoard/viewTaskPopup.js';

const optionMenu = [
  {
    id: 'ASSIGN_TO_MEMBER',
    label: 'Assign to member',
  },
  {
    id: 'COPY_LINK',
    label: 'Copy link',
  },
];

export const TaskManagementCard = (props) => {
  const {
    boardFields,
    getProjectBoardFields,
    getDepartmentBoardFields,
    selectedTaskType,
    selectedTaskTypeValue,
    setBoardFields,
    copyLinkHandler,
    task,
    openTaskId,
    ...rest
  } = props;
  const [isAssignToMemberOpen, setIsAssignToMemberOpen] = useState(false);
  const [isViewTaskPopupOpen, setIsViewTaskPopupOpen] = useState(
    openTaskId === task?.id
  );

  const [{ isDragging }, drag] = useDrag({
    type: itemTypes.CARD,
    item: task,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const taskAssignedBy = task?.assignedTeamByUser?.individual
    ? task?.assignedTeamByUser?.individual
    : task?.assignedTeamByUser?.smallEnterprise;

  const onOptionMenuClicked = async (item) => {
    if (item?.id === 'COPY_LINK') {
      copyLinkHandler(task?.id);
    }
    if (item?.id === 'ASSIGN_TO_MEMBER') {
      setIsAssignToMemberOpen(true);
    }
  };

  const onCloseAssignToMemberModal = async () => {
    if (selectedTaskType.id === 'PROJECT_TASK') {
      await getProjectBoardFields(selectedTaskTypeValue?.id);
    } else {
      await getDepartmentBoardFields(selectedTaskTypeValue?.id);
    }
    setIsAssignToMemberOpen(false);
  };

  const onCloseViewTaskModal = async (boardFields) => {
    await setBoardFields(boardFields);
    if (selectedTaskType.id === 'PROJECT_TASK') {
      await getProjectBoardFields(selectedTaskTypeValue?.id);
    } else {
      await getDepartmentBoardFields(selectedTaskTypeValue?.id);
    }
    setIsViewTaskPopupOpen(!isViewTaskPopupOpen);
  };

  return (
    <>
      <TaskManageCardWrapper
        className="task-card-wrapper"
        ref={drag}
        isDragging={isDragging}
        onClick={(e) => {
          // if we click on the three dot then don't need to open popup
          if (!e?.target?.className.includes('omwd')) {
            setIsViewTaskPopupOpen(!isViewTaskPopupOpen);
          }
        }}>
        <div className="d-flex align-items-center justify-content-between">
          {task?.taskCategory === 'PROJECT_TASK' && (
            <StageBlock stage={task?.posts?.stages?.stageName} />
          )}
          <PriorityBlock priority={task?.priority} />
          <div className="task-card-wrapper__three-dots">
            <OptionMenuWithDot
              arrowPosition={{ left: '50%', right: '50%', top: '-25px' }}
              onSelectMenu={onOptionMenuClicked}
              optionMenu={optionMenu}
              wrapperPosition={{ left: '-72px', top: '30px' }}
            />
          </div>
        </div>
        <div
          className="mt-2 task-card-wrapper__tasktitle"
          title={task?.taskTitle || ''}>
          {truncateString(task?.taskTitle, 80)}
        </div>
        <div className="task-card-wrapper__assigned">
          <MultiUserProfile usersInfo={[taskAssignedBy]} />
          <MultiUserProfile usersInfo={task?.assignToMemberDetails} />
        </div>
        <div className="task-card-wrapper__row-3">
          <div className="task-card-wrapper__row-3__ticket">
            <Icon color="#7E8F9A" name="ticket" size={16} />
            <span className="task-card-wrapper__row-3__ticket-no">
              {task?.ticketId}
            </span>
          </div>
          <DueDate dueDate={task?.dueDate} label={'Due by'} />
        </div>
      </TaskManageCardWrapper>
      {isAssignToMemberOpen && (
        <AssignToMemberPopUp
          onClose={onCloseAssignToMemberModal}
          taskId={task?.id}
          teamId={task?.assignedToTeamId}
          type={
            task?.taskCategory === 'PROJECT_TASK'
              ? 'project-tasks'
              : 'department-tasks'
          }
        />
      )}
      {isViewTaskPopupOpen && (
        <ViewTaskPopup
          activeBoardFields={boardFields}
          onCloseViewTaskModal={onCloseViewTaskModal}
          selectedTaskTypeValue={selectedTaskTypeValue}
          taskId={task?.id}
          taskType={task?.taskCategory}
          ticketNo={task?.ticketId}
          copyLinkHandler={copyLinkHandler}
          {...rest}
        />
      )}
    </>
  );
};

TaskManagementCard.propTypes = {
  task: PropTypes.object.isRequired,
};
