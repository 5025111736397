import { Box, CustomButton, SearchInput } from 'components';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import createInitialTeams from '../../../../assests/images/createInitialTeams.png';
import styled from 'styled-components';
import Icon from 'utlils/Icons/Icon';
import GroupCard from 'components/groupCard';
import {
  GET_GROUP_CREATED_BY_USER,
  GET_USER_GROUPS,
  useAxiosWrapper,
} from 'services';
import tokenService from 'services/token.service';
import ViewGroupsFilter from './ViewGroupFilter';
const DEFAULT_FILTER_STATE = {
  filteredGroupType: {},
};

export default function ViewGroups(props) {
  const [groupData, setGroupData] = useState([]);
  const navigate = useNavigate();
  const [fnWrapper] = useAxiosWrapper();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filterState, setFilterState] = useState(DEFAULT_FILTER_STATE);
  const [searchTerm, setSearchTerm] = useState('');
  const userGroups = useCallback(
    async (search = '', filter = filterState) => {
      const loggedInUser = tokenService.getSession();
      let url =
        Object.keys(filter.filteredGroupType).length &&
        filter.filteredGroupType.value === 'yourgroup'
          ? `${GET_GROUP_CREATED_BY_USER}?userId=${loggedInUser.id}`
          : `${GET_USER_GROUPS}/${loggedInUser.id}?search=${search}`;
      let res = await fnWrapper({
        url: url,
        method: 'GET',
        type: 'GET_USER_GROUPS',
      });
      if (res?.data?.statusCode === 200) {
        if (
          Object.keys(filter.filteredGroupType).length &&
          filter.filteredGroupType.value === 'yourgroup'
        ) {
          let groupDetails = [];
          res?.data?.data?.items.forEach((group) =>
            groupDetails.push({ group: group })
          );
          setGroupData(groupDetails);
        } else {
          setGroupData(res?.data?.data?.items);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterState]
  );
  const searchHandler = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value === '') {
      userGroups();
    }
  };
  const closeSearchHandler = () => {
    setSearchTerm('');
    userGroups();
  };
  const groupFilterOnChangeHandler = (field = '', value = null) => {
    setFilterState({
      ...filterState,
      [field]: value,
    });
  };
  const groupFilterSaveHandler = (type) => {
    if (type === 'apply') {
      setSearchTerm('');
      userGroups('', filterState);
    } else if (
      type === 'cancel' &&
      JSON.stringify(filterState) !== JSON.stringify(DEFAULT_FILTER_STATE)
    ) {
      setFilterState(DEFAULT_FILTER_STATE);
      userGroups('', DEFAULT_FILTER_STATE);
    }
    setIsFilterOpen(false);
  };

  useEffect(() => {
    userGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IndividualGroupWrapper>
      <Box bg="white" className="main-wrapper">
        <div className="group-header">
          <div className="header-text">
            <p className="heading">Groups</p>
          </div>
          {groupData.length !== 0 && (
            <div className="group-header-buttons d-flex">
              <CustomButton
                variant="light"
                onClick={() =>
                  navigate('/individual/profile/groups/create/information')
                }>
                <Icon name="add" color={'#0abd00'} /> Create
              </CustomButton>
              <CustomButton
                onClick={() => navigate('/explore/groups/search-groups')}>
                <Icon name="global" color={'white'} />
                Discover
              </CustomButton>
            </div>
          )}
        </div>
        <div className="search-container">
          <SearchInput
            onSearchange={searchHandler}
            value={searchTerm}
            placeHolder="Search a group by name"
            onCloseSearch={closeSearchHandler}
            onSearch={() => userGroups(searchTerm)}
          />
          <CustomButton
            color={isFilterOpen ? 'themeSecondary' : 'themeGray2'}
            variant="light"
            onClick={() => setIsFilterOpen(!isFilterOpen)}>
            <Icon
              name="filter"
              size={20}
              color={isFilterOpen ? 'rgba(33, 159, 255, 1)' : '#404f67'}
              className="filter-arrow"
            />
            Filter
          </CustomButton>
        </div>
        {groupData.length === 0 ? (
          <div>
            <div className="no-data-container">
              <div className="image">
                <img src={createInitialTeams} alt="" width="320px" />
              </div>
              <div className="no-data-text">
                Oh, looks like you are not part of any Group. Be a part of Group
                now!
              </div>
              <div className="buttons">
                <CustomButton
                  onClick={() =>
                    navigate('/individual/profile/groups/create/information')
                  }
                  label="Create Group"
                />
                <CustomButton
                  onClick={() => navigate('/explore/groups/search-groups')}
                  variant="light"
                  label="Explore Groups"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="group-cards">
            {groupData.map((group, index) => (
              <GroupCard key={index + 1} groupData={group} />
            ))}
            {isFilterOpen && (
              <ViewGroupsFilter
                filterOnChangeHandler={groupFilterOnChangeHandler}
                filterOnSavehandler={groupFilterSaveHandler}
                state={filterState}
              />
            )}
          </div>
        )}
      </Box>
    </IndividualGroupWrapper>
  );
}

const IndividualGroupWrapper = styled.div`
  .group-header {
    display: flew;
    justify-content: space-between;
    .group-header-buttons {
      display: flex;
      gap: 10px;
    }
  }
  .search-container {
    padding: 15px 0px;
    display: grid;
    grid-template-columns: auto minmax(100px, 120px);
    gap: 10px;
    margin-bottom: 20px;
  }
  .no-data-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .image {
      margin-top: 50px;
    }
    .no-data-text {
      margin-top: 30px;
      text-align: center;
    }
    .buttons {
      display: flex;
      gap: 10px;
      margin-top: 30px;
    }
  }
  .group-cards {
    margin-top: 15px;
    display: flex;
    row-gap: 6px;
    column-gap: 6px;
    flex-wrap: wrap;
    position: relative;
  }
`;
