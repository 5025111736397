import { InfoCardWrapper } from './infoCardStyle';

export const InfoCard = ({ label, leftIcon = false, iconName, onClick }) => {
  return (
    <InfoCardWrapper>
      <div className="div-style" onClick={onClick}>
        {leftIcon && <img src={iconName} alt="enterpriseIcon" />}
        <div className="text-style">{label}</div>
      </div>
    </InfoCardWrapper>
  );
};
