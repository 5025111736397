import { useState, useCallback, useEffect } from 'react';
import { Loader } from 'components';
import { PROJECT_ACCESS } from '../constants';
import { useParams, useNavigate } from 'react-router-dom';
import { FETCH_PUBLIC_PROJECT_SERVICE, useAxiosWrapper } from 'services';
import SearchEngineBro from 'assests/images/search-engine-bro.png';
import { LinkedCard } from '../Components';
import { TYPES } from 'pages/IndividualDashboard/constants';
import { getDateTime } from 'utlils/utils';

export const ProjectPublicLinkedProjects = (props) => {
  const { access, isNotLoggedInUser } = props;
  const [fnWrapper, context] = useAxiosWrapper();
  const { projectId } = useParams();
  const [linkedProject, setLinkedProject] = useState({});
  const navigate = useNavigate();

  const getLinkedProjectsList = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_PUBLIC_PROJECT_SERVICE}/${projectId}?st=linkedProjects`,
      method: 'GET',
      type: 'FETCH_LINKED_PROJECTS',
    });

    if (res.data.statusCode === 200) {
      const result = res.data.data;
      if (result.data[0]) {
        const item = {
          id: result.data[0]?.id,
          projectName: result.data[0]?.title,
          connectedTo:
            result.data[0]?.projectType === TYPES.ENTERPRISE
              ? result.data[0]?.enterprise?.enterpriseName
              : result.data[0]?.user?.individual?.name,
          profileImgSrc: result.data[0]?.profilePicture,
          profileIcon: 'project',
          problemStatement: result.data[0]?.problemStatement,
          type: 'project',
          isPublished: result.data[0]?.projectStatus === 'PUBLISHED',
          projectFeature: [
            {
              iconName: 'people',
              label: `${result.data[0]?.teams?.length} Teams`,
            },
            {
              iconName: 'clock',
              label: getDateTime(result.data[0]?.updatedAt),
            },
          ],
        };
        result.data[0]?.projectSettings !== null &&
          item.projectFeature.push({
            iconName: 'shield-security',
            label:
              result.data[0]?.projectSettings?.accessibility === true
                ? 'Public'
                : 'Private',
          });
        result.data[0]?.projectSettings !== null &&
          result.data[0]?.projectSettings?.accessibility === true &&
          item.projectFeature.push({
            iconName: 'tag-user',
            label:
              result.data[0]?.projectSettings?.isBidding === true &&
              result.data[0]?.projectSettings?.isCollaboration === true
                ? 'Open for Collab & Bid'
                : result.data[0]?.projectSettings?.isBidding
                ? 'Open for Bid'
                : 'Open for Collab',
          });
        setLinkedProject(item);
      } else {
        setLinkedProject({});
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    getLinkedProjectsList();
  }, [getLinkedProjectsList]);

  return (
    <div className="main-wrapper">
      <div className="heading">
        Linked Projects{' '}
        {access === PROJECT_ACCESS.PUBLIC && (
          <span>
            (
            {linkedProject && Object.keys(linkedProject).length !== 0
              ? '1'
              : '0'}
            )
          </span>
        )}
      </div>
      {access !== PROJECT_ACCESS.PUBLIC ? (
        <div className="no-content-wrapper">
          <div className="no-access-img">
            <img src={SearchEngineBro} alt="" />
          </div>
          <div className="no-access-title">You are not authorised to view</div>
          <div className="no-access-text">
            Due to its private nature, it could only be accessible to a few
            people
          </div>
        </div>
      ) : context.busy.status ? (
        <Loader loading={context.busy.status} />
      ) : (
        <div className="content">
          {linkedProject && Object.keys(linkedProject).length !== 0 ? (
            <div className="cards-container">
              <LinkedCard
                title={linkedProject.projectName}
                profileImgSrc={linkedProject.profileImgSrc}
                profileIcon={linkedProject.profileIcon}
                type={linkedProject.type}
                owner={linkedProject.connectedTo}
                description={linkedProject.problemStatement}
                features={linkedProject.projectFeature}
                isPublished={linkedProject.isPublished}
                viewHandler={() =>
                  navigate(`/public/project/${linkedProject.id}/details`)
                }
                hideActionButton={isNotLoggedInUser}
              />
            </div>
          ) : (
            <div className="no-data-class">No Linked Project Found!</div>
          )}
        </div>
      )}
    </div>
  );
};
