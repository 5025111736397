import React, { useEffect } from 'react';
import { PublicationCardWrapper } from './style';
import Icon from 'utlils/Icons/Icon';
import { CustomButton } from 'components';
import { ReactComponent as ThreeDotsNew } from 'assests/images/three-dots.svg';
import { PublicationForm } from '../PublicationForm';
import { useState } from 'react';
import { PUBLICATION_CARD_ACTION_OPTIONS } from 'pages/TeamDashboard/helper';
import CommonDropDown from 'pages/enterpriseDashboard/components/commonDropDown';
import { useNavigate } from 'react-router-dom';
import {
  FETCH_USER_PROFILE,
  useAxiosWrapper,
  FETCH_TEAM_DETAILS_BASED_ON_TEAM_ID,
} from 'services';
import BgImg from 'assests/images/bgImgPublic.png';
import { getProfileText } from 'utlils/utils';
import { PUBLICATION_CATEGORY_OPTIONS } from '../../constants';

const DEFAULT_SHOW_PUBLICATION_EDIT_DATA = {
  selectedCollaboratorMembers: [],
  selectedEnterpriseTeams: [],
  project: {},
  isDataFetched: false,
};

export const PublicationCard = (props) => {
  const {
    id,
    title,
    description,
    privacy,
    category,
    referenceLink,
    coverImage,
    attachments,
    collaboratorMemberId,
    collaboratorTeamId,
    projectId,
    publishedByUser,
    project,
    onUpdate,
    onRemove,
    teamCategory,
    enterpriseTeamList,
    projectList,
  } = props;

  const navigate = useNavigate();

  const [isActive, setIsActive] = useState(false);
  const [isEditPublicationOpen, setIsEditPublicationOpen] = useState(false);
  const [fnWrapper] = useAxiosWrapper();
  const [showPublicationEditData, setShowPublicationEditData] = useState({
    ...DEFAULT_SHOW_PUBLICATION_EDIT_DATA,
  });

  const fetchEditPublicationData = async () => {
    let updatedPublicationData = { ...DEFAULT_SHOW_PUBLICATION_EDIT_DATA };

    if (projectId) {
      updatedPublicationData.project = {
        projectName: project?.title,
        id: project?.id,
      };
    }

    if (category !== '') {
      const findCategory = PUBLICATION_CATEGORY_OPTIONS.find(
        (item) => item?.value === category
      );
      updatedPublicationData.category = findCategory;
    }

    if (collaboratorMemberId && collaboratorMemberId.length > 0) {
      const usersList = await Promise.all(
        collaboratorMemberId.map(async (userId) => {
          const res = await fnWrapper({
            url: `${FETCH_USER_PROFILE}/${userId}`,
            method: 'GET',
            type: 'FETCH_USER_PROFILE',
          });

          if (res.data.statusCode === 200) {
            const response = res.data.data;
            return {
              memberName: response?.name,
              profilePicture: response?.profilePicture,
              profileText: getProfileText(response?.name),
              userId: response?.userId,
            };
          }
        })
      );

      updatedPublicationData.selectedCollaboratorMembers = usersList;
    }

    if (
      teamCategory === 'ENTERPRISE' &&
      collaboratorTeamId &&
      collaboratorTeamId.length > 0
    ) {
      const teamList = await Promise.all(
        collaboratorTeamId.map(async (teamId) => {
          const res = await fnWrapper({
            url: `${FETCH_TEAM_DETAILS_BASED_ON_TEAM_ID}/${teamId}`,
            method: 'GET',
            type: 'FETCH_TEAM_DETAILS_BASED_ON_TEAM_ID',
          });

          if (res.data.statusCode === 200) {
            const response = res.data.data;
            return {
              id: response?.id,
              title: response?.teamName,
              value: response?.id,
            };
          }
        })
      );

      updatedPublicationData.selectedEnterpriseTeams = teamList;
    }

    return { ...updatedPublicationData, isDataFetched: true };
  };

  useEffect(() => {
    const fetchData = async () => {
      if (isEditPublicationOpen) {
        const res = await fetchEditPublicationData();
        if (res.isDataFetched) {
          setShowPublicationEditData(res);
        }
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isEditPublicationOpen,
    projectId,
    category,
    collaboratorMemberId,
    teamCategory,
    collaboratorTeamId,
  ]);

  const onSelectPublication = () => {
    setIsActive((curr) => !curr);
  };

  const onUpdatePublication = async (publicationData) => {
    onUpdate(publicationData);
    setIsEditPublicationOpen(false);
  };

  const removePublicationHandler = async () => {
    onRemove(id);
  };

  const optionsHandler = (data) => {
    if (data.label === 'Edit') {
      setShowPublicationEditData((prev) => ({ ...prev, isDataFetched: false }));

      setIsEditPublicationOpen(true);
      setIsActive(false);
    }
    if (data.label === 'Remove') {
      removePublicationHandler();
      setIsActive(false);
    }
  };

  const viewBtnClickHandler = () => {
    navigate(`/public/publication/${id}`);
  };
  return (
    <PublicationCardWrapper>
      <div className="publication-header">
        <div className="header-image-and-title">
          <div className="image-container">
            <img
              src={coverImage && coverImage !== '' ? coverImage : BgImg}
              className="publication-image"
              alt="publication"
            />
          </div>
          <h6 className="publication-title">{title}</h6>
        </div>
        <div className="publication-card-options">
          <div style={{ position: 'relative' }}>
            <div className="options-class" onClick={onSelectPublication}>
              <ThreeDotsNew color={isActive ? '#219fff' : '#c4c4c4'} alt="" />
            </div>
            <CommonDropDown
              dropItem={PUBLICATION_CARD_ACTION_OPTIONS}
              active={isActive}
              onClick={(data) => {
                optionsHandler(data);
              }}
              objectKey="label"
              top="50px"
              right="-35px"
            />
          </div>
          {showPublicationEditData?.isDataFetched && isEditPublicationOpen && (
            <PublicationForm
              closePopup={() => setIsEditPublicationOpen(false)}
              beingEdited={true}
              initialProps={{
                id: id,
                name: title,
                description: description,
                privacy: privacy,
                referenceLink: referenceLink,
                coverImage: coverImage,
                attachments: attachments,
                selectedCollaboratorMembers:
                  showPublicationEditData?.selectedCollaboratorMembers,
                selectedEnterpriseTeams:
                  showPublicationEditData.selectedEnterpriseTeams,
                project: showPublicationEditData.project,
                category: showPublicationEditData.category,
              }}
              onUpdate={onUpdatePublication}
              teamCategory={teamCategory}
              enterpriseTeamList={enterpriseTeamList}
              projectList={projectList}
            />
          )}
        </div>
      </div>
      <p
        className="publication-card-description"
        dangerouslySetInnerHTML={{
          __html: description,
        }}></p>
      <div className="publication-card-footer">
        <div className="publisher-info">
          <p>Published By -</p>
          <div className="publisher-identity">
            {publishedByUser?.profilePicture &&
            publishedByUser?.profilePicture !== '' ? (
              <div className="profile-pic-class">
                <img src={publishedByUser?.profilePicture} alt="" />
              </div>
            ) : (
              <div className="publisher-image">
                <Icon name="user-icon" color="#404f67" size={24} />
              </div>
            )}
            {publishedByUser?.name
              ? publishedByUser?.name
              : publishedByUser?.enterpriseName}
          </div>
        </div>
        <div className="navigational-btns">
          <CustomButton
            className="view-btn"
            variant="light"
            color="themeSecondary"
            customPadding="10px 45px"
            onClick={viewBtnClickHandler}>
            View
            <Icon name="arrow-right" size={18} color="#219fff" />
          </CustomButton>
        </div>
      </div>
    </PublicationCardWrapper>
  );
};
