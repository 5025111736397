import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomButton, Loader, Pagination, SearchInput } from 'components';
import {
  ExploreTeamProjectCard,
  ExploreProjectFilter,
} from 'pages/Explore/Components';
import { SearchProjectWrapper } from './style';
import {
  FETCH_ALL_PUBLIC_PROJECTS,
  CREATE_SAVE_PUBLIC_PROJECT,
  FETCH_ENTERPRISE_LIST,
  useAxiosWrapper,
} from 'services';
import tokenService from 'services/token.service';
import { getDateTime } from 'utlils/utils';
import Icon from 'utlils/Icons/Icon';

const DEFAULT_FILTER_STATE = {
  filteredTeamsCount: {},
  filteredCollabBid: {},
  filteredCategory: {},
  filteredPrivacy: {},
  filteredEnterpriseName: {},
  filteredCategoryName: {},
  fromDate: '',
  toDate: '',
};

export const SearchProject = () => {
  const [fnWrapper, context] = useAxiosWrapper();
  const [searchTerm, setSearchTerm] = useState('');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  let pageSize = 6;
  const [projectsList, setProjectsList] = useState({});
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [filterState, setFilterState] = useState(DEFAULT_FILTER_STATE);
  const navigate = useNavigate();
  const [enterpriseList, setEnterpriseList] = useState([]);

  const getAllEnterpriseList = useCallback(async () => {
    const loggedInUser = await tokenService.getSession();
    const res = await fnWrapper({
      url: `${FETCH_ENTERPRISE_LIST}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_ENTERPRISE_LIST',
    });
    if (res.data.statusCode === 200) {
      if (res?.data?.data) {
        setEnterpriseList(res.data.data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getAllPublicProjects = useCallback(
    async (search = '', filter = filterState) => {
      const loggedInUser = await tokenService.getSession();
      const res = await fnWrapper({
        url: `${FETCH_ALL_PUBLIC_PROJECTS}/${
          loggedInUser.id
        }?page=${currentPage}&size=${pageSize}&search=${search}&type=${
          Object.keys(filter.filteredCategory).length
            ? filter.filteredCategory.value
            : ''
        }&privacy=${
          Object.keys(filter.filteredPrivacy).length
            ? filter.filteredPrivacy.value
            : ''
        }&enterpriseId=${
          Object.keys(filter.filteredEnterpriseName).length
            ? filter.filteredEnterpriseName.id
            : ''
        }&category=${
          Object.keys(filter.filteredCategoryName).length
            ? filter.filteredCategoryName.value
            : ''
        }&isBidding=${
          Object.keys(filter.filteredCollabBid).length
            ? filter.filteredCollabBid.value !== 'COLLABORATION'
              ? true
              : false
            : ''
        }&isCollaboration=${
          Object.keys(filter.filteredCollabBid).length
            ? filter.filteredCollabBid.value !== 'BIDDING'
              ? true
              : false
            : ''
        }&teamCount=${
          Object.keys(filter.filteredTeamsCount).length
            ? filter.filteredTeamsCount.value
            : ''
        }&startDate=${filter.fromDate}&endDate=${filter.toDate}`,
        method: 'GET',
        type: 'FETCH_ALL_PUBLIC_PROJECTS',
      });

      if (res.data.statusCode === 200) {
        const result = res.data.data;
        if (result.items.length === 0 && currentPage !== 1) {
          setCurrentPage(currentPage - 1);
        } else {
          setCurrentPage(result.currentPage);
          setProjectsList(result);
          const allProjects = [];
          result.items?.map((project) => {
            const item = {
              id: project?.id,
              title: project?.title,
              owner:
                project?.projectType === 'ENTERPRISE' && project?.enterprise
                  ? project?.enterprise?.enterpriseName
                  : project?.user?.individual?.name,
              type: 'project',
              profileImgSrc: project?.profilePicture,
              profileIcon: 'project',
              description: project?.problemStatement,
              isProjectSaved: project?.isProjectSaved,
              isPublished: project?.projectStatus === 'PUBLISHED',
              projectFeature: [
                {
                  iconName: 'people',
                  label: `${project?.teams.length} Teams`,
                },
                {
                  iconName: 'clock',
                  label: getDateTime(project?.publishProjects?.updatedAt),
                },
              ],
            };
            project?.projectSettings !== null &&
              item.projectFeature.push({
                iconName: 'shield-security',
                label:
                  project?.projectSettings?.accessibility === true
                    ? 'Public'
                    : 'Private',
              });
            project?.projectSettings !== null &&
              project?.projectSettings?.accessibility === true &&
              item.projectFeature.push({
                iconName: 'tag-user',
                label:
                  project?.projectSettings?.isBidding === true &&
                  project?.projectSettings?.isCollaboration === true
                    ? 'Open for Collab & Bid'
                    : project?.projectSettings?.isBidding
                    ? 'Open for Bid'
                    : 'Open for Collab',
              });
            allProjects.push(item);
            return allProjects;
          });
          setFilteredProjects(allProjects);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage, pageSize, filterState]
  );

  const saveProject = async (projectId) => {
    const loggedInUser = tokenService.getSession();
    const res = await fnWrapper({
      url: `${CREATE_SAVE_PUBLIC_PROJECT}`,
      method: 'PUT',
      payload: {
        projectId: projectId,
        userId: loggedInUser.id,
      },
      type: 'CREATE_SAVE_PUBLIC_PROJECT',
    });
    if (res.data.statusCode === 200) {
      await getAllPublicProjects();
    }
  };

  useEffect(() => {
    getAllEnterpriseList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getAllPublicProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllPublicProjects]);

  const projectFilterSaveHandler = (type) => {
    if (type === 'apply' && filteredProjects.length !== 0) {
      setSearchTerm('');
      getAllPublicProjects('', filterState);
    } else if (
      type === 'cancel' &&
      JSON.stringify(filterState) !== JSON.stringify(DEFAULT_FILTER_STATE)
    ) {
      setFilterState(DEFAULT_FILTER_STATE);
      getAllPublicProjects('', DEFAULT_FILTER_STATE);
    }
    setIsFilterOpen(false);
  };

  const projectFilterOnChangeHandler = (field = '', value = null) => {
    setFilterState({
      ...filterState,
      [field]: value,
    });
  };

  const searchHandler = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value === '') {
      getAllPublicProjects();
    }
  };

  const closeSearchHandler = () => {
    setSearchTerm('');
    getAllPublicProjects();
  };

  return (
    <SearchProjectWrapper>
      <div className="search-container">
        <SearchInput
          placeHolder="Search a Project by Name"
          value={searchTerm}
          onSearchange={searchHandler}
          onCloseSearch={closeSearchHandler}
          onSearch={() => getAllPublicProjects(searchTerm)}
        />
        <CustomButton
          color={isFilterOpen ? 'themeSecondary' : 'themeGray2'}
          variant="light"
          onClick={() => setIsFilterOpen(!isFilterOpen)}>
          <Icon
            name="filter"
            size={20}
            color={isFilterOpen ? 'rgba(33, 159, 255, 1)' : '#404f67'}
            className="filter-arrow"
          />
          Filter
        </CustomButton>
      </div>
      <div className="main-wrapper">
        <div className="heading">
          <h5 className="teams-count">Projects ({projectsList.totalItems})</h5>
        </div>
        {context.busy.status ? (
          <Loader loading={context.busy.status} />
        ) : filteredProjects?.length > 0 ? (
          <>
            <div className="cards-container">
              {filteredProjects?.map((project, index) => (
                <ExploreTeamProjectCard
                  key={index}
                  title={project.title}
                  type={project.type}
                  profileImgSrc={project.profileImgSrc}
                  profileIcon={project.profileIcon}
                  owner={project.owner}
                  description={project.description}
                  features={project.projectFeature}
                  isPublished={project.isPublished}
                  viewHandler={() =>
                    navigate(`/public/project/${project.id}/details`)
                  }
                  isSaved={project.isProjectSaved}
                  onSaveHandler={() => saveProject(project.id)}
                />
              ))}
            </div>
          </>
        ) : (
          <div className="no-data-class">No Projects Found!</div>
        )}
        {isFilterOpen && (
          <>
            <ExploreProjectFilter
              enterpriseList={enterpriseList}
              filterTitle="Filter Projects"
              state={filterState}
              filterSaveHandler={projectFilterSaveHandler}
              filterOnChangeHandler={projectFilterOnChangeHandler}
            />
          </>
        )}
      </div>
      <div className="pagination-container">
        {(filteredProjects.length >= 6 || projectsList.currentPage >= 1) && (
          <Pagination
            className="pagination-bar"
            currentPage={
              projectsList.currentPage ? projectsList.currentPage : 0
            }
            totalCount={projectsList.totalItems ? projectsList.totalItems : 0}
            pageSize={pageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        )}
      </div>
    </SearchProjectWrapper>
  );
};
