export const TeamPublicDetails = ({ teamDetails }) => {
  return (
    <div className="main-wrapper">
      <div className="heading">Details</div>
      {teamDetails !== null &&
        Object.entries(teamDetails).map((data, index) => {
          return (
            <div className="content-class" key={index}>
              <p className="content-title">{data[0]}</p>
              <div className="content-value">{data[1]}</div>
            </div>
          );
        })}
    </div>
  );
};
