import { CustomButton, DropDown, Textarea } from 'components';
import { replaceUnderscoresWithSpaces } from 'pages/ProjectPublic/ProjectSelectBiddings/utils';
import { useCallback, useEffect, useState } from 'react';
import { GET_PROJECT_BIDDING_LIST, useAxiosWrapper } from 'services';
import tokenService from 'services/token.service';
import { getDepartmentsList, getProjectList } from '../Common';
import { INVITATION_TYPES } from 'pages/IndividualPublic/constants';

export const InviteBidding = ({ handleSendInvite }) => {
  const [fnWrapper, { busy }] = useAxiosWrapper();
  const [projectData, setProjectData] = useState([]);
  const [biddingData, setBiddingData] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [selectedBidding, setSelectedBidding] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [description, setDescription] = useState('');
  const { role } = tokenService.getSession();

  const getBiddingProjectsList = useCallback(async (projectId) => {
    const res = await fnWrapper({
      url: `${GET_PROJECT_BIDDING_LIST}/${projectId}`,
      method: 'GET',
      type: 'GET_PROJECT_BIDDING_LIST',
    });
    if (res.data.statusCode === 200) {
      const result = res.data.data;
      setBiddingData(
        result.map((bidding) => ({
          ...bidding,
          label: replaceUnderscoresWithSpaces(bidding?.biddingType),
          value: bidding?.id,
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Get list of departments (if enterprise), for individual get projects
  useEffect(() => {
    if (role !== 'INDIVIDUAL') {
      getDepartmentsList(fnWrapper).then((departmentData) =>
        setDepartmentData(departmentData)
      );
      return;
    }
    getProjectList(fnWrapper, null).then((projectData) =>
      setProjectData(projectData)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  //Get list of projects on department change
  useEffect(() => {
    if (selectedDepartment) {
      getProjectList(fnWrapper, selectedDepartment).then((projectData) =>
        setProjectData(projectData)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDepartment]);

  //Get list of project biddings on project change
  useEffect(() => {
    if (selectedProject) {
      setSelectedBidding(null);
      getBiddingProjectsList(selectedProject?.id);
    }
  }, [selectedProject, getBiddingProjectsList]);

  //Send the invite
  const handleSubmit = async () => {
    const data = {
      invitationType: INVITATION_TYPES.BIDDING,
      projectId: selectedProject?.id,
      biddingId: selectedBidding?.id,
      description: description,
      invitedByUserId: tokenService.getSession()?.id,
    };
    await handleSendInvite(data);
  };

  const isDisabled = () => {
    if (role === 'INDIVIDUAL') {
      if (!selectedProject || !selectedBidding) {
        return true;
      }
      return false;
    }
    if (!selectedDepartment || !selectedProject || !selectedBidding) {
      return true;
    }
    return false;
  };

  return (
    <>
      {role !== 'INDIVIDUAL' ? (
        <div className="drop-down-item">
          <DropDown
            items={departmentData}
            label="Department"
            objectKey="label"
            placeholder={busy?.status ? 'Loading' : 'Select'}
            value={selectedDepartment}
            required
            onClick={(department) => {
              setSelectedDepartment(department);
            }}
          />
        </div>
      ) : null}
      <div className="drop-down-item">
        <DropDown
          items={projectData}
          label="Project"
          objectKey="label"
          placeholder={busy?.status ? 'Loading' : 'Select'}
          value={selectedProject}
          required
          onClick={(project) => {
            setSelectedProject(project);
          }}
        />
      </div>
      <div className={role !== 'INDIVIDUAL' ? 'designation' : 'drop-down-item'}>
        <DropDown
          items={biddingData}
          label="Bidding"
          objectKey="label"
          placeholder={busy?.status ? 'Loading' : 'Select'}
          value={selectedBidding}
          required
          onClick={(bidding) => {
            setSelectedBidding(bidding);
          }}
        />
      </div>
      <div className="describe-text">
        <Textarea
          rows={4}
          cols={50}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          label="Description"
          placeholder="Describe the invite"
        />
      </div>
      <div className="invite-popup-btn">
        <CustomButton
          disabled={isDisabled() || busy?.status}
          label={busy?.status ? 'Working on it' : 'Send Invitation'}
          className="w-100"
          onClick={handleSubmit}
        />
      </div>
    </>
  );
};
