import { CustomButton, CustomCheckBox, Loader } from 'components';
import { useState, useEffect, useRef } from 'react';
import { DocumentContainer } from '../Components/DocumentContainer';
import { QuestionContainer } from '../Components/QuestionContainer';
import { CREATE_COLLABORATION_APPLICANT, useAxiosWrapper } from 'services';
import tokenService from 'services/token.service';
import { TermsConditionContainer } from '../Components/TermsConditionContainer';
import { useCallback } from 'react';
import { isMobile } from 'utlils';

export const RequiredDetails = ({
  nextPage,
  selectedCollaboration,
  questions,
  documents,
  collaboratorForms,
  setScrollDivHeight,
  setProjectInfo,
}) => {
  const ref = useRef();
  const [fnWrapper, { busy }] = useAxiosWrapper();
  const [dataToSubmit, setDataToSubmit] = useState({
    question: {},
    termsAccepted: false,
    documents: [],
    collaboratorForms: [],
  });

  useEffect(() => {
    if (ref?.current) {
      const newHeight = ref?.current?.clientHeight + 45;
      if (newHeight < 500) {
        setScrollDivHeight(newHeight);
      } else {
        setScrollDivHeight('calc(100vh - 250px)');
      }
    }
  }, [setScrollDivHeight]);

  const isSubmitDisabled = () => {
    //Add more condition if needed to disable button
    if (
      dataToSubmit?.termsAccepted === false ||
      (questions &&
        Object.keys(questions)?.length !==
          Object.keys(dataToSubmit?.question)?.length &&
        questions[1] !== '') ||
      dataToSubmit?.collaboratorForms?.filter((doc) => doc?.isMandatory)
        ?.length !==
        collaboratorForms?.filter((doc) => doc?.isMandatory)?.length
    ) {
      return true;
    }
    return false;
  };

  //Check if any questions are asked
  const questionsExist = () => {
    if (
      questions &&
      Object.keys(questions)?.length !== 0 &&
      questions[1] !== ''
    ) {
      return true;
    }
    return false;
  };

  const handleSubmission = async () => {
    let formData = new FormData();
    let applicantData = [];
    let fileNames = new Set();
    for (let i of dataToSubmit.collaboratorForms) {
      if (i?.file) {
        formData.append('collaboratorForms', i?.file);
        fileNames.add(i?.docName);
        applicantData.push({
          applicantFileName: i?.file?.name,
          fileName: i?.docName,
          location: i?.location,
          isMandatory: i?.isMandatory,
        });
      }
    }
    if (fileNames?.size !== collaboratorForms?.length) {
      for (let i of collaboratorForms) {
        if (!fileNames?.has(i?.name)) {
          // Check if file name is missing
          applicantData.push({
            applicantFileName: null,
            fileName: i?.name,
            location: i?.location,
            isMandatory: i?.isMandatory,
          });
        }
      }
    }
    formData.append('applicantForms', JSON.stringify(applicantData));
    formData.append('applicantId', tokenService?.getSession()?.id);
    formData.append('collaborationId', selectedCollaboration?.id);
    formData.append(
      'collaborationType',
      selectedCollaboration?.collaborationType
    );
    if (
      dataToSubmit?.question &&
      Object.keys(dataToSubmit?.question).length > 0
    ) {
      formData.append('question', JSON.stringify(dataToSubmit?.question));
    }
    formData.append('projectId', selectedCollaboration?.projectId);

    //Send the form data
    const res = await fnWrapper({
      url: CREATE_COLLABORATION_APPLICANT,
      method: 'POST',
      payload: formData,
      type: 'CREATE_COLLABORATION_APPLICANT',
    });
    if (res.data.statusCode === 200) {
      //Update the main list to show check is applied
      setProjectInfo((projectInfo) => {
        return {
          ...projectInfo,
          activeProjectCollaborations:
            projectInfo.activeProjectCollaborations.map(
              (activeCollaboration) => {
                if (activeCollaboration.id === selectedCollaboration?.id) {
                  return { ...activeCollaboration, isApplied: true };
                }
                return activeCollaboration;
              }
            ),
        };
      });
      nextPage();
    }
  };

  //Handle change in question answer
  const handleAnswerInput = useCallback((e, question) => {
    if (e.target.value === '') {
      setDataToSubmit((data) => {
        const { [`${question}`]: removed, ...newQuestions } = data.question;
        return { ...data, question: newQuestions };
      });
      return;
    }
    setDataToSubmit((data) => ({
      ...data,
      question: {
        ...data.question,
        [`${question}`]: e.target.value,
      },
    }));
  }, []);

  if (busy?.status === true) {
    return (
      <div className="loading-bar">
        <Loader loading={true} />
      </div>
    );
  }

  return (
    <div ref={ref}>
      <div>
        {questionsExist() ? (
          <div>
            <p className="big-heading">Please fill out these details-</p>
            <div>
              {Object?.keys(questions)?.map((questionKey) => (
                <QuestionContainer
                  key={questionKey}
                  value={dataToSubmit?.question[questions[questionKey]]}
                  question={questions[questionKey]}
                  handleAnswerInput={handleAnswerInput}
                />
              ))}
            </div>
          </div>
        ) : null}
        {collaboratorForms && collaboratorForms?.length > 0 ? (
          <div
            className={
              questionsExist() ? 'doc-container-main' : 'doc-container-main-nq'
            }>
            <p className="sub-heading">Sign these documents and upload them</p>
            <div className="document-list">
              {collaboratorForms?.map((collabDocument, idx) =>
                collabDocument ? (
                  <DocumentContainer
                    key={idx}
                    document={collabDocument}
                    addedDocuments={dataToSubmit?.collaboratorForms}
                    setDataToSubmit={setDataToSubmit}
                  />
                ) : null
              )}
            </div>
          </div>
        ) : null}
        {documents && documents?.length > 0 ? (
          <div className="tc-container-main">
            <p className="sub-heading">Read the rules & documents carefully</p>
            {documents.map((document) => (
              <TermsConditionContainer key={document?.name} {...document} />
            ))}
          </div>
        ) : null}
        <div className="accept-terms">
          <CustomCheckBox
            customClass={
              isMobile() ? 'accept-checkbox-mobile' : 'accept-checkbox'
            }
            onClick={(e) =>
              setDataToSubmit((data) => ({
                ...data,
                termsAccepted: !data?.termsAccepted,
              }))
            }
            check={dataToSubmit?.termsAccepted}
            title={
              <p className="accept-checkbox-text">
                I have read all the terms and conditions carefully and uploaded
                the required signed documents.
              </p>
            }
          />
        </div>
      </div>
      <div className="popup-button">
        <CustomButton
          disabled={isSubmitDisabled()}
          label={'Submit Request'}
          color="themeSecondary"
          onClick={handleSubmission}
        />
      </div>
    </div>
  );
};
