import { CustomButton } from 'components';
import { noop } from 'lodash';

export const membersTableData = (
  data,
  setSelectedMember,
  reinviteDepartmentMember
) => {
  let newData = [];
  if (data.length) {
    newData = data.map((item) => {
      return {
        id: item.id,
        memberName: <span>{item?.memberName}</span>,
        email: <span>{item?.email}</span>,
        department: <span>{item?.department?.departmentName}</span>,
        roleInDepartment: <span>{item?.role}</span>,
        userStatus: item?.memberUserId ? (
          <CustomButton
            className="w-100"
            customPadding="8px 12px"
            label={'View'}
            color="themeSecondary"
            variant="outline"
            onClick={() => setSelectedMember(item)}
          />
        ) : (
          <CustomButton
            className="w-100"
            customPadding="8px 12px"
            label={'Pending'}
            color="themeOrange"
            variant="outline"
            disabled
            onClick={noop}
          />
        ),
        inviteStatus:
          item?.status === '1' && item?.isInvitationExpired === true ? (
            <CustomButton
              className="w-100"
              customPadding="8px 12px"
              label={'Re-invite'}
              color="themePrimary"
              variant="outline"
              onClick={() => reinviteDepartmentMember(item.id)}
            />
          ) : item.status === '1' ? (
            <CustomButton
              className="w-100"
              customPadding="8px 12px"
              label={'Pending'}
              color="themeOrange"
              variant="outline"
              disabled
              onClick={noop}
            />
          ) : item?.status === '2' ? (
            <CustomButton
              className="w-100"
              customPadding="8px 12px"
              label={'Left'}
              color="themeTertiary"
              variant="outline"
              disabled
              onClick={noop}
            />
          ) : item?.status === '3' ? (
            <CustomButton
              className="w-100"
              customPadding="8px 12px"
              label={'Rejected'}
              color="themeTertiary"
              variant="outline"
              disabled
              onClick={noop}
            />
          ) : item?.status === '4' ? (
            <CustomButton
              className="w-100"
              customPadding="8px 12px"
              label={'Accepted'}
              color="themePrimary"
              variant="outline"
              disabled
              onClick={noop}
            />
          ) : (
            <CustomButton
              className="w-100"
              customPadding="8px 12px"
              label={'Removed'}
              color="themeTertiary"
              variant="outline"
              disabled
              onClick={noop}
            />
          ),
      };
    });
  } else {
    newData = [];
  }

  return newData;
};

// onst teamMemberStatus = {
//   PENDING: '1',
//   LEFT: '2',
//   REJECTED: '3',
//   ACCEPTED: '4',
//   REMOVED: '5',
// }
