import { useState, useEffect, useRef } from 'react';
import Icon from '../../utlils/Icons/Icon';
import { Input } from 'components/inputBox';
import './dropDown.scss';
import cn from 'classname';

export const DropDown = ({
  label,
  items = [],
  objectKey = 'label',
  placeholder,
  onClick,
  value = null,
  required,
  disabled = false,
  onBlur,
  hasSearchBox = false,
}) => {
  const ref = useRef();
  const [drop, setDrop] = useState(false);
  const [showError, setShowError] = useState(false);
  const [searchString, setSearchString] = useState('');
  useEffect(() => {
    document.body.addEventListener('click', (event) => {
      if (ref?.current?.contains(event.target)) {
        return;
      }
      setDrop(false);
    });
  }, []);
  const isDropdown = () => {
    // Run custom logic passed to input on blur
    typeof onBlur === 'function' && onBlur();
    // Show error to trigger error evaluation logic
    setShowError(true);
    if (disabled) {
      setDrop(false);
    } else {
      setDrop(!drop);
    }
  };

  const isError = required && (value === '' || value === null) && showError;
  const filteredOptions = items.length
    ? items?.filter((item) =>
        hasSearchBox && searchString
          ? item[objectKey].toLowerCase().match(searchString.toLowerCase())
          : item
      )
    : [];
  return (
    <div className="dropdown-container">
      <span className="dropdown-label">
        {label}
        <span style={{ color: '#FF1E26' }}>{`${required ? '*' : ''}`}</span>
      </span>
      <div ref={ref}>
        <div
          className={cn(
            'select-container',
            isError && 'error-input',
            disabled && 'disabled'
          )}>
          <div className="select" onClick={isDropdown}>
            {!value || Object.keys(value)?.length === 0
              ? placeholder
              : value[objectKey]}
            {!disabled && <Icon color="black" name="arrow-down" size={18} />}
          </div>
        </div>
        <div className={cn(drop ? 'options' : 'd-none')}>
          {drop && (
            <>
              {hasSearchBox && items?.length > 3 && (
                <Input
                  value={searchString}
                  onChange={(e) => setSearchString(e.target.value)}
                  customStyle={{ height: '48px', marginBottom: '10px' }}
                  placeholder="Search..."
                  iconLeft
                  iconName="search"
                  autoFocus
                />
              )}
              {filteredOptions?.length ? (
                filteredOptions?.map((item, id) => (
                  <div
                    className="option-item"
                    onClick={() => {
                      onClick(item);
                      setDrop(false);
                    }}
                    key={id}>
                    {item[objectKey]}
                  </div>
                ))
              ) : (
                <div className="no__options">
                  No {label ? label?.toLowerCase() : 'options'} available
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {isError && <small className="has-error">Please enter {label}</small>}
    </div>
  );
};
