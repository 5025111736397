import React from 'react';
import { SectionCardWrapper } from './styles';
import { CustomCheckBox } from 'components';
import Icon from 'utlils/Icons/Icon';
import { Link } from 'react-router-dom';

export const SectionCard = (props) => {
  const {
    cardTitle = 'CardTitle',
    permissionsList = [
      { title: 'option1', check: true },
      { title: 'option2', check: false },
      { title: 'option3', check: true },
    ],
    path = '/',
  } = props;

  return (
    <SectionCardWrapper>
      <h2 className="card-title">{cardTitle}</h2>
      <div className="permissions-lists">
        <div className="permission-checkboxes">
          {permissionsList.map((item, index) => {
            return (
              <CustomCheckBox
                key={index}
                customClass="permissions-context"
                onCheckBg="#F4F7FC"
                tickColor="#929DAF"
                checkBorder="1px solid #929DAF"
                check={item?.isChecked}
                title={item?.label}
              />
            );
          })}
        </div>

        <Link className="arrow-btn" to={path}>
          <Icon name="arrow-right" color="#0abd00" size={16} />
        </Link>
      </div>
    </SectionCardWrapper>
  );
};
