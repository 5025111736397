import { CustomButton } from 'components';
import { isMobile } from 'utlils';
import { BiddingDetailsWrapper } from './style';
import { TermsConditionContainer } from 'pages/ProjectPublic/ProjectSelectCollaborations/Components/TermsConditionContainer';
import { parseJson, replaceUnderscoresWithSpaces } from '../utils';

export const BiddingDetails = ({ nextPage, selectedBidding }) => {
  const biddingSectionsList = parseJson(selectedBidding?.bidTypeFAQ);

  return (
    <BiddingDetailsWrapper>
      <div className="main-content">
        <div className="section">
          <p className="sub-heading">About this Bidding</p>
          <p className="text-content">{selectedBidding?.biddingDescription}</p>
        </div>
        <div className="section">
          <p className="sub-heading">Bidding offerings</p>
          <p className="text-content">{selectedBidding?.biddingOfferings}</p>
        </div>
        <div className={isMobile() ? '' : 'row'}>
          <div className="table-heading col-md-4">
            <p>Bidding type</p>
            <p className="table-element">
              {replaceUnderscoresWithSpaces(selectedBidding?.biddingType)}
            </p>
          </div>
          {selectedBidding?.serviceFrequency ? (
            <div className="table-heading col-md-4">
              <p>Bidding category</p>
              <p className="table-element">
                {replaceUnderscoresWithSpaces(selectedBidding?.biddingCategory)}
              </p>
            </div>
          ) : null}
          {selectedBidding?.serviceFrequency ? (
            <div className="table-heading col-md-4">
              <p>Frequency of service</p>
              <p className="table-element">
                {selectedBidding?.serviceFrequency}
              </p>
            </div>
          ) : null}
        </div>
        {biddingSectionsList &&
          Object.entries(biddingSectionsList).map(([key, value]) => {
            return (
              <div className="section" key={key}>
                <p className="sub-heading">{key}</p>
                <p className="text-content">{value}</p>
              </div>
            );
          })}
        {selectedBidding?.videoAttachment ? (
          <div className="section">
            <p className="sub-heading">Demo of Product</p>
            <TermsConditionContainer
              name={
                selectedBidding?.videoAttachment?.split('/')[
                  selectedBidding?.videoAttachment?.split('/')?.length - 1
                ]
              }
              location={selectedBidding?.videoAttachment}
            />
          </div>
        ) : null}
        {selectedBidding?.projectLastUpdatedDate ? (
          <div className="section">
            <p className="sub-heading">
              In this copy, you will find updates to the project as of this
              date:
            </p>
            <div className="section-date col-md-3">
              <p className="date-text">
                {new Date(
                  selectedBidding?.projectLastUpdatedDate
                ).toLocaleDateString()}
              </p>
            </div>
          </div>
        ) : null}
      </div>
      <div className="popup-button">
        <CustomButton
          label={'Continue'}
          color="themeSecondary"
          onClick={nextPage}
          className="w-100"
        />
      </div>
    </BiddingDetailsWrapper>
  );
};
