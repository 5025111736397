import { initialState } from './createProjectContext';

export const CreateProjectReducer = (state, action) => {
  switch (action.type) {
    case 'SET_PROGRESS':
      return {
        ...state,
        progressStep: action.payload,
      };
    case 'SET_PROJECT_DETAILS':
      return {
        ...state,
        [action.field]: action.payload,
      };
    case 'CLEAR_STATE':
      return initialState;
    default:
      return state;
  }
};
