import React from 'react';
import NetIssueIcon from '../../assests/images/network-issue.svg';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  background-color: #f4f7fc;
  & .not-found_container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 22px;
    @media (max-width: 1200px) {
      flex-direction: column;
    }
    & img {
      width: 50%;
      height: auto;
      @media (max-width: 1200px) {
        padding: 3vh 0 0 0;
      }
    }
    & .not-found_container__text {
      width: 40%;
      @media (max-width: 1200px) {
        width: 75%;
        margin: auto;
      }
      & p {
        line-height: 30px;
        padding: 20px 0;
      }
      & .linkpara {
        line-height: 34px;
        padding: 34px 0;
        & a {
          color: #404f67;
          font-style: italic;
        }
      }
    }
  }
`;

export const Unauthorized = () => {
  return (
    <StyledWrapper>
      <div className="not-found_container">
        <img src={NetIssueIcon} alt="404-not-found" />
        <div className="not-found_container__text">
          <p>Unauthorized.</p>
          <p>
            Oh! You are not authorized to access this page. Kindly contact Admin
            if this incorrect..
          </p>
          <div className="linkpara">
            If this issue doesn’t resolve, please let us know at &nbsp;
            <a href="mailto:info@linkteamsup.com">info@linkteamsup.com</a>
          </div>
        </div>
      </div>
    </StyledWrapper>
  );
};
