import styled from 'styled-components';

export const StreamsWrapper = styled.div`
  .customBtnUnderlineStreams {
    text-decoration: underline;
  }
  .customBtnStreams {
    width: 109px !important;
    margin: 0 20px !important;
  }
  .main-container {
    & .edit-stream-heading {
      padding: 40px 0 0 10px;
      & .your-stream {
        font-weight: bold;
        font-size: 20px;
      }
    }
    & .stream-container {
      padding-top: 20px;
      .no-stream {
        width: 75%;
        text-align: center;
        margin: 8% auto;
        font-weight: 500;
        color: #929daf;
      }
      .project-name-text {
        text-align: center;
        font-style: italic;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        justify-content: center;
        gap: 20px;
        color: #929daf;
        @media (max-width: 575px) {
          flex-direction: column;
          align-items: center;
        }
        & .dropdown-wrapper {
          width: 260px;
          @media (max-width: 300px) {
            width: 200px;
          }
          & .select {
            position: relative;
            background-color: #f4f7fc;
            padding: 10px;
            border-radius: 8px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            &:hover {
              cursor: pointer;
            }
          }
          & .options {
            z-index: 10;
            width: 260px;
            margin-top: 5px;
            position: absolute;
            border: 1px solid #929daf99;
            background-color: white;
            border-radius: 8px;
            padding: 10px;
            @media (max-width: 300px) {
              width: 200px;
            }
            & .option-item {
              padding: 10px 0;
              font-style: normal;
              &:hover {
                background-color: #f1f7fc;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
    & .bottom-buttons {
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 576px) {
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        height: 20vh;
      }
    }
  }
  .draft-popup-container {
    position: relative;
    padding: 20px 0;
    .close-cross {
      position: absolute;
      top: 15px;
      right: 25px;
      &:hover {
        cursor: pointer;
      }
    }
    .draft-buttons {
      width: 50%;
      @media (max-width: 1199px) {
        width: 60%;
      }
      @media (max-width: 992px) {
        width: 70%;
      }
      @media (max-width: 576px) {
        gap: 15px;
      }
    }
  }
`;
