import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from 'components';
import Icon from 'utlils/Icons/Icon';
import { INDIVIDUAL_PROFILE_OPTIONS } from '../../constants';
import { BasicDetailsView } from './ViewBasicInfo';
import { EducationDetailsView } from './ViewEducationalInfo';
import { ProfessionalDetailsView } from './ViewProfessionalInfo';
import { AchievementDetailsView } from './ViewAchievementInfo';
import { LicenseDetailsView } from './ViewLicenseInfo';
import { ProfileViewDropdown } from 'pages/IndividualDashboard/components/ProfileViewDropdown';
import { ViewSkillsAndTechnology } from './ViewSkillsAndTechnology';

export const ViewProfileInfo = () => {
  let navigate = useNavigate();
  let { step = 'basic_details' } = useParams();
  const [profileView, setProfileView] = useState({
    id: step,
    title: INDIVIDUAL_PROFILE_OPTIONS.find((ele) => ele.id === step).title,
  });

  const handleDropDown = (item) => {
    setProfileView({
      id: item.id,
      title: item.title,
    });
    navigate(`/individual/profile/info/${item.id}`);
  };

  const editHandler = (type) => {
    navigate(`/individual/profile/edit/${type}`);
  };

  return (
    <Box
      bg="white"
      className="main-wrapper"
      display={profileView.id === null && 'none'}>
      <div className="row mb-2 ">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <ProfileViewDropdown
            options={INDIVIDUAL_PROFILE_OPTIONS}
            onClick={handleDropDown}
            title={profileView.title}
            selectedOption={profileView.id}
          />
          <div
            className="profile-edit"
            onClick={() => editHandler(profileView.id)}>
            <Icon color="#ffffff" name="edit" size={20} />
          </div>
        </Box>
      </div>
      {profileView.id === 'basic_details' && (
        <BasicDetailsView
          setCurrType={profileView.id}
          nextType="education"
          title="Basic Details"
        />
      )}
      {profileView.id === 'education' && (
        <EducationDetailsView
          setCurrType={profileView.id}
          nextType="professional_details"
          title="Education"
        />
      )}
      {profileView.id === 'professional_details' && (
        <ProfessionalDetailsView
          setCurrType={profileView.id}
          nextType="skills_and_technology"
          title="Professional Details"
        />
      )}
      {profileView.id === 'skills_and_technology' && (
        <ViewSkillsAndTechnology
          setCurrType={profileView.id}
          nextType="achievements"
          title="Skills/Technology Details"
        />
      )}
      {profileView.id === 'achievements' && (
        <AchievementDetailsView
          setCurrType={profileView.id}
          nextType="license_certifications"
          title="Achievements"
        />
      )}
      {profileView.id === 'license_certifications' && (
        <LicenseDetailsView
          setCurrType={profileView.id}
          nextType={null}
          title="License & Certifications"
        />
      )}
    </Box>
  );
};
