import styled from 'styled-components';

export const AppliedJobWrapper = styled.div`
  .main-jobs-wrapper {
    border-radius: 12px;
    padding: 30px;
    background-color: white;
    margin-bottom: 20px;
    position: relative;
    & .heading {
      padding-bottom: 30px;
      border-bottom: 1px solid #e9eef4;
      & .jobs-count {
        margin: 0;
      }
    }
    .no-jobs-message {
      margin-top: 30px;
      text-align: center;
      color: #929daf;
      .click-here {
        color: #0abd00;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    & .cards-container {
      padding-top: 30px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 20px;

      @media (min-width: 900px) and (max-width: 1499px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: 899px) {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }
    & .no-data-class {
      width: 50%;
      margin: 50px auto;
      text-align: center;
      color: #404f67;
    }
  }
  .view-application-wrapper {
    font-weight: 500;
    font-size: 16px;
    color: #404f67;
    margin-top: 20px;
    & .apply-with-class {
      width: 70%;
      margin-bottom: 24px;
    }
    & .content-class {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding-bottom: 20px;
      & .content-value {
        width: 100%;
        min-height: 56px;
        text-align: left;
        padding: 16px;
        word-wrap: break-word;
        background: #ffffff;
        border: 2px solid #e9eef4;
        border-radius: 12px;
        color: #7e8f9a;
      }
    }
  }
`;
