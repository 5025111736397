import { CustomButton } from 'components/button/button';
import React, { useState } from 'react';
import styled from 'styled-components';
import BgImg from 'assests/images/bgImgPublic.png';
import CommonDropDown from 'pages/enterpriseDashboard/components/commonDropDown';
import { useNavigate } from 'react-router-dom';

const MY_GROUP_CARD_ACTION_OPTIONS = [
  {
    id: 0,
    label: 'Edit',
  },
  {
    id: 1,
    label: 'Leave',
  },
];
export default function GroupCard(props) {
  const { groupData } = props;
  const navigate = useNavigate();
  const [optionOpen, setOptionOpen] = useState(false);
  function handleOptionsClick(item) {
    switch (item.id) {
      case 0:
        navigate('/individual/profile/groups/update/information', {
          state: { id: groupData?.group?.id },
        });
        break;
      case 1:
        console.log(1);
        break;
      default:
    }
  }
  const clickHandler = (id) => {
    navigate(`/individual/profile/groups/${id}/view/description`);
  };
  return (
    <GroupCardWrapper>
      <div className="parent">
        <div className="top-gray-part">
          <div
            role="button"
            className="option-dots"
            onClick={() => setOptionOpen(!optionOpen)}>
            {/* <div onClick={() => setOptionOpen(!optionOpen)}>
              <ThreeDots color={optionOpen ? '#219fff' : '#c4c4c4'} alt="" />
            </div> */}
            <CommonDropDown
              dropItem={MY_GROUP_CARD_ACTION_OPTIONS}
              active={optionOpen}
              objectKey="label"
              onClick={(item) => {
                handleOptionsClick(item);
              }}
              top="25px"
              right="-40px"
            />
          </div>
        </div>
        <div className="bottom-white-part">
          <div className="content">
            <div className="group-name">{groupData?.group?.groupName}</div>
            <div className="group-details">
              <div className="group-category">{groupData?.group?.category}</div>
              <div className="group-type">{groupData?.group?.groupType}</div>
            </div>
            <div className="view-updates-button">
              <CustomButton
                onClick={(id) => clickHandler(groupData?.group?.id)}
                className={'w-100'}
                label={'View Updates'}
              />
            </div>
          </div>
        </div>
        <div className="group-image">
          <img
            className="image"
            src={
              groupData?.group?.profilePicture === null
                ? BgImg
                : groupData?.group?.profilePicture
            }
            alt=""
          />
        </div>
      </div>
    </GroupCardWrapper>
  );
}

const GroupCardWrapper = styled.div`
  width: 234px;
  max-height: 260px;
  position: relative;
  @media (min-width: 900px) and (max-width: 1499px) {
    width: 45%;
  }

  @media (max-width: 899px) {
    width: 100%;
  }
  .parent {
    position: relative;
    .top-gray-part {
      border: 2px solid #c4c4c4;
      border-bottom: none;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      height: 65px;
      background-color: rgba(233, 238, 244, 1);
      position: relative;
      .option-dots {
        position: absolute;
        right: 15px;
        height: 15px;
        top: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .bottom-white-part {
      height: 195px;
      border: 2px solid #c4c4c4;
      border-top: none;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      padding: 10px;
      .content {
        padding-top: 40px;
        .group-name {
          font-size: 18px;
          font-weight: 500;
          font-family: 'Poppins';
        }
        .group-details {
          display: flex;
          .group-category {
            padding-right: 10px;
            border-right: 2px solid #404f6733;
          }
          .group-type {
            padding-left: 10px;
          }
        }
        .view-updates-button {
          margin-top: 27px;
        }
      }
    }
    .group-image {
      position: absolute;
      top: 25px;
      left: 10px;
      width: 80px;
      height: 80px;
      .image {
        border-radius: 100%;
        height: 100%;
        width: 100%;
      }
    }
  }
  .options {
    top: 45px;
    right: 18px;
    border: 1px solid #c4c4c4;
    border-radius: 10px;
    position: absolute;
    width: 200px;
    .top-blue {
      height: 8px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background-color: #219fff;
    }
    .option-content {
      padding: 10px;
      background-color: white;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      .option-border {
        border-bottom: 1px solid rgba(64, 79, 103, 0.5);
      }
      .option {
        padding: 10px;
      }
    }
  }
`;
