import React, { useEffect, useState } from 'react';
import { CustomButton } from '../../../../components';
import cn from 'classname';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { checkForSpacesInStartEnd } from 'helpers';

const StreamStagesWrapper = styled.div`
  padding: 2rem 0 2rem 0.5rem;
  @media (min-width: 2500px) {
    padding: 3rem 4rem;
  }
  .uneditable {
    display: block !important;
    width: 60%;
    margin: 0 auto;
    @media (min-width: 2200px) {
      width: 800px;
    }
    @media (max-width: 575px) {
      width: 75%;
    }
  }
  .stage-item-container {
    display: grid;
    grid-template-columns: minmax(135px, 3fr) 2fr;
    grid-gap: 30px;
    @media (min-width: 2200px) {
      grid-template-columns: minmax(135px, 800px) auto;
    }
    @media (max-width: 575px) {
      grid-gap: 40px;
    }
    & .stage-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & .stage-input {
        width: 100%;
        text-align: center;
        padding: 10px;
        border-radius: 6px;
        @media (max-width: 575px) {
          padding: 30px 10px;
        }
      }
      & .disabled {
        cursor: not-allowed;
        color: #219fff;
        border: 1px solid #219fff;
        background: #e9f5ff;
      }
      & .enabled {
        color: #929daf;
        border: 1px solid #929daf;
        background: #f7f6ff;
        cursor: auto;
        &:focus {
          outline-color: #929dafbb;
          background: #f7f6ff;
        }
        &::placeholder {
          opacity: 0.5;
        }
      }
      & .vertical-dashed-line {
        border-left: 4px dashed #219fffcc;
        height: 35px;
        @media (max-width: 575px) {
          height: 90px;
        }
      }
      & .grey-line {
        border-left-color: #929dafcc;
      }
      & .blue-line {
        border-left-color: #219fffcc;
      }
      @media (max-width: 575px) {
        justify-content: start;
      }
    }
    & .buttons-group {
      display: flex;
      justify-content: center;
      align-items: start;
      gap: 30px;
      @media (min-width: 2200px) {
        justify-content: space-evenly;
      }
      @media (max-width: 575px) {
        flex-direction: column;
        justify-content: flex-start;
        gap: 2px;
      }
    }
  }
`;

const StreamStages = ({
  stagesList,
  addHandler,
  removeHandler,
  onChangeHandler,
  currentStage,
  isEditable,
}) => {
  const [currentEditStage, setCurrentEditStage] = useState(currentStage);

  const handleChange = (event, num) => {
    const { value } = event.target;
    onChangeHandler(value, num);
  };

  useEffect(() => {
    setCurrentEditStage(currentStage);
  }, [currentStage]);

  const editSaveHandler = (number, value) => {
    if (number === currentEditStage) {
      if (!value || checkForSpacesInStartEnd(value)) {
        toast.error('Stage name cannot be empty!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        setCurrentEditStage(0);
      }
    } else {
      setCurrentEditStage(number);
    }
  };

  const handleAdd = (number, value) => {
    if (number === currentEditStage) {
      if (!value || checkForSpacesInStartEnd(value)) {
        toast.error('Please specify current stage to add a new one!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        addHandler(number);
      }
    } else {
      addHandler(number);
    }
  };

  return (
    <StreamStagesWrapper>
      {stagesList.map((stage, index) => {
        const num =
          stage.number === 1
            ? '1st'
            : stage.number === 2
            ? '2nd'
            : stage.number === 3
            ? '3rd'
            : `${stage.number}th`;
        return (
          <div
            className={cn(!isEditable && 'uneditable', 'stage-item-container')}
            key={index}>
            <div className="stage-item">
              <input
                className={cn(
                  'stage-input',
                  stage.number === currentEditStage || stage.value === ''
                    ? 'enabled'
                    : 'disabled'
                )}
                disabled={stage.number !== currentEditStage}
                placeholder={`Enter ${num} Stage`}
                value={stage.value && stage.value}
                onChange={(e) => handleChange(e, stage.number)}
              />
              {(stage.number !== stagesList.length || stage.number === 1) && (
                <div
                  className={cn(
                    'vertical-dashed-line',
                    stage.number === currentEditStage || stage.value === ''
                      ? 'grey-line'
                      : 'blue-line'
                  )}></div>
              )}
            </div>
            {isEditable && (
              <div className="buttons-group">
                <CustomButton
                  className="customBtnUnderlineStreams"
                  label="Add"
                  variant="textBtn"
                  onClick={() => handleAdd(stage.number, stage.value)}
                  customPadding="12px 0 0 0"
                  disabled={
                    !stage?.value || checkForSpacesInStartEnd(stage.value)
                  }
                />
                {stagesList.length > 1 && (
                  <CustomButton
                    className="customBtnUnderlineStreams"
                    label="Remove"
                    variant="textBtn"
                    color="themeTertiary"
                    onClick={() => removeHandler(stage.number)}
                    customPadding="12px 0 0 0"
                  />
                )}
                <CustomButton
                  className="customBtnUnderlineStreams"
                  label={stage.number === currentEditStage ? 'Save' : 'Edit'}
                  variant="textBtn"
                  color="themeSecondary"
                  onClick={() => editSaveHandler(stage.number, stage.value)}
                  customPadding="12px 0 0 0"
                  disabled={
                    stage.number === currentEditStage &&
                    (!stage?.value || checkForSpacesInStartEnd(stage.value))
                  }
                />
              </div>
            )}
          </div>
        );
      })}
    </StreamStagesWrapper>
  );
};

export default StreamStages;
