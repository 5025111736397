import React from 'react';
import { RecievedRequestsWrapper } from './styles';
import { RequestCard } from '../components/RequestCard';
import { CustomButton, ModalPopup, ScrollableDiv } from 'components';
import { toast } from 'react-toastify';
import {
  GET_GROUP_JOIN_REQUESTS,
  ACCEPT_DENY_GROUP_JOIN_REQUEST,
  useAxiosWrapper,
} from 'services';
import { useParams } from 'react-router-dom';
import tokenService from 'services/token.service';

export const RecievedRequests = () => {
  const [openRequestPopup, setOpenRequestPopup] = React.useState(false);
  const [requestsList, setRequestsList] = React.useState([]);
  const [activeRequestId, setActiveRequestId] = React.useState(null);
  const params = useParams();
  const [fnWrapper] = useAxiosWrapper();

  const getRecievedRequests = React.useCallback(async () => {
    const res = await fnWrapper({
      url: `${GET_GROUP_JOIN_REQUESTS}/${params.groupId}`,
      method: 'GET',
      type: 'GET_GROUP_JOIN_REQUESTS',
    });
    if (res?.data?.statusCode === 200) {
      setRequestsList(res?.data?.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const acceptOrRejectRequest = async (hasAccepted) => {
    const userId = tokenService.getSession()?.id;
    const payload = {
      adminUserId: userId,
      hasAccepted: hasAccepted,
    };
    const groupUserId = requestsList[activeRequestId]?.id;
    const res = await fnWrapper({
      url: `${ACCEPT_DENY_GROUP_JOIN_REQUEST}/${groupUserId}`,
      method: 'PUT',
      type: 'ACCEPT_DENY_GROUP_JOIN_REQUEST',
      payload: payload,
    });
    if (res?.data?.statusCode === 200) {
      toast.success(res?.data?.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      setOpenRequestPopup(false);
      getRecievedRequests();
    }
  };

  React.useEffect(() => {
    getRecievedRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openRequestPopUp = (id) => {
    setActiveRequestId((prev) => id);
    setOpenRequestPopup(true);
  };

  const RequestCardsList = () => {
    const groupQuestions = requestsList[activeRequestId]?.question;

    let res = Object.keys(groupQuestions).map((question, index) => {
      return (
        <div className="request-popup" key={index}>
          <div className="question">Q. {question}</div>
          <div className="answer">{groupQuestions[question]}</div>
        </div>
      );
    });
    return res;
  };

  return (
    <RecievedRequestsWrapper>
      {openRequestPopup && (
        <ModalPopup
          open={false}
          onClose={() => setOpenRequestPopup(false)}
          title="User Response">
          <ScrollableDiv scrollOnHeight="35vh">
            <RequestCardsList />
          </ScrollableDiv>
          <div className="modal-btns-container">
            <CustomButton
              className="modal-btn"
              label="Reject"
              variant="outline"
              color="themeTertiary"
              onClick={() => acceptOrRejectRequest(false)}
            />
            <CustomButton
              className="modal-btn"
              label="Accept"
              color="themeSecondary"
              onClick={() => acceptOrRejectRequest(true)}
            />
          </div>
        </ModalPopup>
      )}
      <br />
      <ScrollableDiv scrollOnHeight="calc(100vh - 55vh)">
        {requestsList?.length > 0 ? (
          <div className="request-card-container">
            {requestsList?.map((request, index) => {
              return (
                <RequestCard
                  key={index}
                  userName={request?.user?.individual?.name}
                  userId={request?.user?.id}
                  onClick={() => openRequestPopUp(index)}
                />
              );
            })}
          </div>
        ) : (
          <p className="no-requests">
            No Requests recieved.
            <br />
            They will appear here when someone requests to join the group.
          </p>
        )}
      </ScrollableDiv>
      <br />
    </RecievedRequestsWrapper>
  );
};
