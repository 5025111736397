import { useState, useCallback, useEffect } from 'react';
import { getPlaceholderItems } from '../../../../utlils/utils';
import '../../../../utlils/Icons/Icon';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { BreadCrumb } from '../../../../components/breadCrumb';
import { useNavigate } from 'react-router-dom';
import { getBreadCrumbList } from './helper';
import { CustomTable } from '../../../../components';
import {
  departmentTableData,
  ENTERPRISE_DEPARTMENT_TABLE_HEADERS,
} from 'pages/IndividualDashboard/IndividualPersonalSection/individualTeams/helper';

const DepartmentListWrapper = styled.div`
  .header-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
    font-size: 25px;
    color: #17191d;
    padding: 5px;
  }
`;
export const DepartmentList = ({ isDepartmentListUpdate }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [breadCrumb, setBreadCrumb] = useState([]);
  const [enterpriseName, setEnterpriseName] = useState();

  let { enterpriseId } = useParams();
  let navigate = useNavigate();

  const getDepartmentList = useCallback(async () => {
    const session = JSON.parse(localStorage.getItem('session'));
    setIsLoading(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/getDepartmentList?enterpriseId=${enterpriseId}`,
        {
          headers: {
            'x-access-token': `${session.access_token}`,
          },
        }
      );

      if (response.data.statusCode === 200) {
        const departmentTableList = response.data.data?.departmentList
          .filter(
            (department) =>
              department?.isActive === 1 && department?.status === '4'
          )
          .map((department) => ({
            id: `${department?.id}`,
            departmentName: `${department?.department?.departmentName}`,
            dateAdded: `${department?.department?.createdAt}`,
            role: `${department?.role}`,
            action: department?.department?.id,
          }));

        setTableData(departmentTableList);
        setEnterpriseName(response?.data?.data?.enterpriseName);
      } else {
        throw new Error(response?.data?.message);
      }
    } catch (error) {
      toast.error(error.message || 'An error occurred', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    } finally {
      setIsLoading(false);
    }
  }, [enterpriseId]);
  const getBreadCrumb = useCallback(async () => {
    let crumbs = getBreadCrumbList();

    setBreadCrumb(crumbs);
  }, []);
  useEffect(() => {
    getBreadCrumb();
    getDepartmentList();
  }, [getDepartmentList, getBreadCrumb]);

  useEffect(() => {
    if (isDepartmentListUpdate) {
      getDepartmentList();
    }
  }, [isDepartmentListUpdate, getDepartmentList]);

  const onRowCheckedHandler = (element) => {
    if (selectedRow.length) {
      const index = selectedRow.filter((ele) => ele.id === element.id);
      if (index.length) {
        const filteredItems = selectedRow.filter(
          (ele) => ele.id !== element.id
        );
        setSelectedRow(filteredItems);
      } else {
        setSelectedRow([...selectedRow, element]);
      }
    } else {
      setSelectedRow([...selectedRow, element]);
    }
  };

  const onAllRowCheckedHandler = (element) => {
    setSelectedRow(element);
  };
  const departmentListData = isLoading
    ? getPlaceholderItems(
        ENTERPRISE_DEPARTMENT_TABLE_HEADERS.map(({ sortKey }) => sortKey),
        4,
        isLoading
      )
    : departmentTableData(tableData);

  const breadCrumbOnClick = (url, index) => {
    navigate(url);
  };
  return (
    <DepartmentListWrapper>
      <div>
        <BreadCrumb
          crumbs={breadCrumb}
          seperator={'>'}
          itemOnClickHandler={breadCrumbOnClick}
        />
      </div>
      <div className="header-text">{enterpriseName}</div>
      {!isLoading && tableData.length !== 0 ? (
        <CustomTable
          isLoading={isLoading}
          rows={departmentListData}
          headers={ENTERPRISE_DEPARTMENT_TABLE_HEADERS}
          hasCheckbox={false}
          selectedRow={selectedRow}
          rowCheckedHandler={(val) => onRowCheckedHandler(val)}
          allRowCheckedHandler={(val) => onAllRowCheckedHandler(val)}
        />
      ) : (
        <div className="d-flex justify-content-center align-items-center mt-4">
          <div width="70%">
            <span>
              Oh, looks like you don’t have any Team. Create your own Team now!
            </span>
          </div>
        </div>
      )}
    </DepartmentListWrapper>
  );
};
