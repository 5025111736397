import React from 'react';
import QRCode from 'react-qr-code';

export const GenerateQRCode = ({ redirectUrl, size }) => {
  return (
    <>
      <QRCode value={redirectUrl} size={size} />
    </>
  );
};
