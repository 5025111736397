import React from 'react';
import { useDrop } from 'react-dnd';
import { itemTypes } from 'utlils/itemTypes';

export const DragWrapper = ({ onDrop, status, children, className }) => {
  const [{ isOver }, drop] = useDrop({
    accept: itemTypes.CARD,
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
    drop: (item, monitor) => onDrop(item, status),
  });
  return (
    <div
      ref={drop}
      className={className}
      style={{
        backgroundColor: isOver ? '#219fff22' : '',
      }}>
      {children}
    </div>
  );
};
