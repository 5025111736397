import styled from 'styled-components';

export const AddTeamWrapper = styled.div`
  .CustomBtnAddTeams {
    width: 109px;
    margin: 0 20px;
  }
  .team-container {
    & .status-container {
      margin-top: 30px;
      display: flex;
      flex-wrap: wrap;
      column-gap: 12px;
    }
    .no-team {
      width: 75%;
      text-align: center;
      margin: 10% auto;
      font-weight: 500;
      color: #929daf;
    }
    & .bottom-buttons {
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: 576px) {
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        height: 20vh;
      }
    }
    & .cards-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 24px;
      @media (min-width: 1921px) {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
      }
      & .card-wrapper {
        @media (min-width: 1921px) {
          min-width: 420px;
          max-width: 450px;
        }
      }
    }
  }
  .draft-popup-container {
    position: relative;
    padding: 20px 0;
    .close-cross {
      position: absolute;
      top: 15px;
      right: 25px;
      &:hover {
        cursor: pointer;
      }
    }
    .draft-buttons {
      width: 50%;
      @media (max-width: 1199px) {
        width: 60%;
      }
      @media (max-width: 992px) {
        width: 70%;
      }
      @media (max-width: 576px) {
        gap: 15px;
      }
    }
  }
`;
