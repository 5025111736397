import { useState, useCallback, useEffect } from 'react';
import { CustomDropDown, CustomTooltip, Loader } from 'components';
import {
  TEAM_AND_PROJECT_TYPE,
  PUBLIC_PRIVATE_TOGGLE_LABEL,
} from '../constants';
import { useParams, useNavigate } from 'react-router-dom';
import {
  FETCH_TEAMS_BASED_ON_USER,
  UPDATE_HIDDEN_TEAM_STATUS,
  useAxiosWrapper,
} from 'services';
import { TeamProjectCard } from '../Components';
import TokenService from 'services/token.service';
import NoData from 'assests/images/noData2.png';
import { PrivateTeamProjectCard } from '../Components/PrivateTeamProjectCard';

const tooltipData = [
  {
    label: 'Show',
    text: 'The selected Teams/Projects would be visible to everyone.',
  },
  {
    label: 'Hide',
    text: 'The selected Teams/Projects wouldn’t be visible to anyone.',
  },
];

export const IndividualPublicTeams = ({
  taskType,
  hiddenTeams,
  updateHiddenTeams,
}) => {
  const [fnWrapper, context] = useAxiosWrapper();
  const { userId } = useParams();
  const [teamsData, setTeamsData] = useState([]);
  const [publicTeamsData, setPublicTeamsData] = useState([]);
  const navigate = useNavigate();

  const [selectedDropDown, setSelectedDropDown] = useState(
    TEAM_AND_PROJECT_TYPE[0]
  );

  const updateDropDownHandler = async (status) => {
    let statusObject = TEAM_AND_PROJECT_TYPE.filter(
      (ele) => ele.value === status
    );
    setSelectedDropDown(statusObject[0]);
  };

  const updateTeamStatus = async (teamId) => {
    const loggedInUser = TokenService.getSession();
    const data = {
      teamId: teamId,
      userId: loggedInUser.id,
    };

    const res = await fnWrapper({
      url: `${UPDATE_HIDDEN_TEAM_STATUS}`,
      method: 'PUT',
      type: 'UPDATE_HIDDEN_TEAM_STATUS',
      payload: data,
    });
    if (res.data.statusCode === 200) {
      updateHiddenTeams();
      getTeamList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const getTeamList = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_TEAMS_BASED_ON_USER}/${userId}?teamType=${selectedDropDown.label.toLowerCase()}`,
      method: 'GET',
      type: 'FETCH_TEAMS_BASED_ON_USER',
    });

    if (res.data.statusCode === 200) {
      const result = res.data.data;
      const teamDetails = [];
      result.map((team) => {
        const details = {
          id: team.id,
          isImg: false,
          imgSrc: '',
          profileIcon: team.enterprise !== null ? 'enterprise-two' : 'people',
          teamName: team.teamName,
          num_members: team?.teamMembers.filter(
            (member) => member.status === '4'
          ).length,
          type:
            team.enterprise !== null
              ? team.enterprise.enterpriseName
              : team.user.individual.name,
          typeIcon: team.enterprise === null ? 'user' : 'enterprise-two',
          isTeamHidden: hiddenTeams.includes(team.id),
        };
        teamDetails.push(details);
        return teamDetails;
      });
      setTeamsData(teamDetails);
      setPublicTeamsData(
        teamDetails.filter((team) => team.isTeamHidden === false)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDropDown, hiddenTeams]);

  useEffect(() => {
    getTeamList();
  }, [getTeamList, selectedDropDown]);

  return (
    <div className="main-wrapper">
      <div className="heading">
        <p>Teams</p>
        <div className="drop-down-class">
          <CustomDropDown
            optionLists={TEAM_AND_PROJECT_TYPE}
            selectedItem={selectedDropDown}
            setSelectedItem={(val) => updateDropDownHandler(val.value)}
            placeholder={TEAM_AND_PROJECT_TYPE[0].label}
            customStyle={{
              backgroundColor: '#E9EEF4',
              border: '1px solid rgba(64, 79, 103, 0.7)',
              color: '#17191d',
              padding: '10px',
              justifyContent: 'space-between',
              whiteSpace: 'normal',
            }}
            width={'100%'}
          />
        </div>
      </div>
      <div className="sub-heading-wrapper">
        <div className="sub-heading">
          {selectedDropDown.label}
          <span>
            (
            {PUBLIC_PRIVATE_TOGGLE_LABEL.PRIVATE === taskType
              ? teamsData.length
              : publicTeamsData.length}
            )
          </span>
        </div>
        {PUBLIC_PRIVATE_TOGGLE_LABEL.PRIVATE === taskType && (
          <div className="sub-heading-tooltip">
            <div>Show/Hide</div>
            <CustomTooltip
              onHoverColor="#219fff"
              iconSize={18}
              top="32px"
              left="-160px"
              mobileLeft="-80px">
              {tooltipData.map((item, i) => {
                return (
                  <div className="tooltip-card-item" key={i}>
                    <div className="tooltip-card-item-heading">
                      {item.label}
                    </div>
                    <p className="tooltip-card-item-text">{item.text}</p>
                  </div>
                );
              })}
            </CustomTooltip>
          </div>
        )}
      </div>
      {(publicTeamsData.length === 0 &&
        taskType === PUBLIC_PRIVATE_TOGGLE_LABEL.PUBLIC) ||
      (teamsData.length === 0 && PUBLIC_PRIVATE_TOGGLE_LABEL.PRIVATE) ? (
        <div className="no-data">
          <img src={NoData} alt="" />
          <p>Nothing to show here right now!</p>
        </div>
      ) : context.busy.status ? (
        <Loader isLoading={context.busy.status} />
      ) : PUBLIC_PRIVATE_TOGGLE_LABEL.PRIVATE === taskType ? (
        <div className="private-team-list">
          {teamsData.map((item, index) => (
            <div key={index}>
              <PrivateTeamProjectCard
                isImg={item.isImg}
                imgSrc={item.imgSrc}
                profileIcon={item.profileIcon}
                title={item.teamName}
                subTitle={item.num_members}
                type={item.type}
                typeIcon={item.typeIcon}
                cardType="team"
                isItemHidden={!item.isTeamHidden}
                slidingButtonHandler={() => updateTeamStatus(item.id)}
              />
            </div>
          ))}
        </div>
      ) : (
        <div className="teams-list">
          {publicTeamsData.map((item, index) => (
            <div className="team-card-class" key={index}>
              <TeamProjectCard
                isImg={item.isImg}
                imgSrc={item.imgSrc}
                profileIcon={item.profileIcon}
                title={item.teamName}
                subTitle={item.num_members}
                type={item.type}
                typeIcon={item.typeIcon}
                cardType="team"
                viewHandler={() => navigate(`/public/team/${item.id}/details`)}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
