import { FETCH_PUBLICATION_BASED_ON_PUBLICATIONID } from 'services';
import { useParams, useNavigate } from 'react-router-dom';
import { useAxiosWrapper } from 'services';
import { useState, useEffect } from 'react';
import { LinkedCard } from '../Components';
import BgImg from 'assests/images/bgImgPublic.png';
import dayjs from 'dayjs';
import { ProjectPublicPublicationWrapper } from './style';

export const ProjectPublicPublications = (props) => {
  const { isNotLoggedInUser } = props;
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [fnWrapper] = useAxiosWrapper();
  const [publicationsList, setPublicationsList] = useState([]);

  const getProjectPublications = async () => {
    const res = await fnWrapper({
      url: `${FETCH_PUBLICATION_BASED_ON_PUBLICATIONID}`,
      method: 'GET',
      type: 'FETCH_PUBLICATION_BASED_ON_PUBLICATIONID',
      params: {
        projectId: projectId,
      },
    });
    setPublicationsList(res?.data?.data);
  };

  const viewHandler = (publicationId) => {
    return isNotLoggedInUser
      ? navigate('/login/individual')
      : window.open(
          `/public/publication/${publicationId}`,
          'rel=noopener noreferrer'
        );
  };

  useEffect(() => {
    getProjectPublications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ProjectPublicPublicationWrapper>
      <div className="main-wrapper">
        <div className="heading">Publications</div>
        <div className="content">
          {publicationsList?.length > 0 ? (
            <div className="opening-cards-container">
              {publicationsList?.map((item, index) => (
                <LinkedCard
                  key={index}
                  title={item.title}
                  type="publication"
                  owner={
                    item?.user?.individual
                      ? item?.user?.individual?.name
                      : item?.user?.smallEnterprise?.enterpriseName
                  }
                  profileImgSrc={item?.coverImage ? item.coverImage : BgImg}
                  profileIcon="publication"
                  description={
                    <div
                      className="fixed-width-content"
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  }
                  features={[
                    {
                      iconName: 'shield-security',
                      iconLabel: 'Privacy',
                      label: item?.privacy,
                    },
                    {
                      iconName: 'clock',
                      iconLabel: 'Published on',
                      label: dayjs(item?.createdAt).format('DD MMM YYYY'),
                    },
                  ]}
                  viewHandler={() => viewHandler(item.id)}
                />
              ))}
            </div>
          ) : (
            <div className="no-data-class">
              There are no publications linked to this project
            </div>
          )}
        </div>
      </div>
    </ProjectPublicPublicationWrapper>
  );
};
