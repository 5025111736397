import { Loader, ScrollableDiv, Textarea } from 'components';
import React from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import {
  GET_BIDDING_APPLICANTS_DETAILS_BY_ID,
  useAxiosWrapper,
} from 'services';
import { checkForFileType } from '../individualOrganizations/enterprise/helper';
import Icon from 'utlils/Icons/Icon';
import { startCase } from 'lodash';
import { currencyLists } from 'pages/Projects/ViewProject/ProjectCollaboration/constant';

export default function ViewBiddingApplication(props) {
  const [fnWrapper, context] = useAxiosWrapper();
  const [viewApplicationData, setViewApplicationData] = useState(null);

  const getApplicationDetails = useCallback(async () => {
    const res = await fnWrapper({
      url: `${GET_BIDDING_APPLICANTS_DETAILS_BY_ID}/${props.applicantId}`,
      method: 'GET',
      type: 'GET_BIDDING_APPLICANTS_DETAILS_BY_ID',
    });
    if (res.data.statusCode === 200) {
      const result = res.data.data;
      setViewApplicationData(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getApplicationDetails();
  }, [getApplicationDetails]);

  const getFileName = (file) => {
    const setFileName = file.split('/');
    return setFileName[setFileName.length - 1];
  };

  return (
    <div>
      <ScrollableDiv
        scrollOnHeight="calc(100vh - 260px)"
        scrollOnHeightMob="300px">
        {context.busy.status ? (
          <Loader loading={context.busy.status} />
        ) : (
          <div className="applicants-wrapper">
            <div className="sub-heading mb-3 mt-2">Selected bid type</div>
            <div className="bidding-type">
              {startCase(
                viewApplicationData?.biddingType
                  .split('_')
                  .join(' ')
                  .toLowerCase()
              )}
            </div>
            <div className="sub-heading mb-3">Bid Placed</div>
            <div className="bid-item">
              {currencyLists[viewApplicationData?.currencyType] +
                Number(viewApplicationData?.bidderAmount)}
            </div>
            {viewApplicationData?.questionsToBidder
              ? Object.keys(viewApplicationData?.questionsToBidder).map(
                  (key, index) => (
                    <div className="question_wrapper" key={index}>
                      <div className="sub-heading">{key}</div>
                      <div className="pb-textarea">
                        <Textarea
                          rows={4}
                          disabled={true}
                          value={viewApplicationData?.questionsToBidder[key]}
                        />
                      </div>
                    </div>
                  )
                )
              : null}
            {viewApplicationData?.signedDocuments?.length ? (
              <div className="sub-heading mt-3 mb-3">Attached documents</div>
            ) : null}
            <div className="pb-textarea">
              {
                <div className="post-attachment">
                  {viewApplicationData?.signedDocuments?.length
                    ? viewApplicationData?.signedDocuments?.map(
                        (elem, index) => {
                          const element = JSON.parse(elem);
                          const fileName = getFileName(element.location);
                          const applicantFileName = element.applicantLocation
                            ? getFileName(element.applicantLocation)
                            : '';
                          return (
                            <div className="mb-2" key={index}>
                              <div className="img-wrapper">
                                <div className="img-container">
                                  {checkForFileType(fileName) === 'others' ? (
                                    <Icon
                                      name="attachment"
                                      color=" rgba(64, 79, 103, 0.8)"
                                    />
                                  ) : (
                                    <img
                                      src={checkForFileType(fileName)}
                                      alt="attachment"
                                    />
                                  )}
                                </div>
                                <div className="img-text" rel="noreferrer">
                                  {fileName}
                                  <span>
                                    {element.isMandatory ? (
                                      <span className="text-danger">*</span>
                                    ) : null}
                                  </span>
                                </div>
                              </div>
                              {element.applicantLocation ? (
                                <a
                                  className={'img-text-file'}
                                  href={element.applicantLocation}
                                  target="_blank"
                                  rel="noreferrer">
                                  {applicantFileName ? applicantFileName : 'NA'}
                                </a>
                              ) : null}
                            </div>
                          );
                        }
                      )
                    : null}
                </div>
              }
            </div>
          </div>
        )}
      </ScrollableDiv>
    </div>
  );
}
