import React from 'react';
import { ModernFeatureContentWrapper } from './style';
import Icon from 'utlils/Icons/Icon';

export const ModernFeature = ({ content }) => {
  return (
    <ModernFeatureContentWrapper className="mfcw">
      <div className="container">
        <div className="row">
          <div className="mfcw__wrp">
            <Icon name={content.header_icon} color="#219fff" size={35} />
            <div className="mfcw__wrp__sub-heading">
              Features that make people love us
            </div>
            <div className="mfcw__wrp__heading">
              Most Unique & Modern Features
            </div>
          </div>
        </div>
      </div>
    </ModernFeatureContentWrapper>
  );
};
