import Icon from 'utlils/Icons/Icon';

export const DynamicHeader = ({ activeStep, setActiveStep, modalHeadings }) => {
  return (
    <>
      {activeStep !== 0 && activeStep !== 3 ? (
        <Icon
          className="back-button"
          name="arrow-back"
          color={'#7E8F9A'}
          size={28}
          style={{
            position: 'relative',
            top: '-1px',
            left: '-5px',
            cursor: 'pointer',
          }}
          onClick={() => setActiveStep((activeStep) => activeStep - 1)}
        />
      ) : null}
      {modalHeadings[activeStep] ?? 'N/A'}
    </>
  );
};
