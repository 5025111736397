import { useState, useEffect, useCallback } from 'react';
import { RequestNetworkWrapperStyle } from './style';
import { YourNetworkOrRequestList } from 'pages/Explore/Components';
import { AcceptOrDenyRequest } from './acceptDenyRequest';
import { WithDrawRequest } from './withDrawRequest';
import { TabGroup } from 'components';
import { NetworkRequestsTabs } from 'pages/Explore/constants';
import {
  FETCH_CONNECTION_REQUEST_SENT_RECEIVED,
  ACCEPT_DENY_CONNECTION_REQUEST,
  WITHDRAW_CONNECTION_REQUEST,
  useAxiosWrapper,
} from 'services';
import TokenService from 'services/token.service';
import createInitialTeams from 'assests/images/createInitialTeams.png';
import { Loader } from 'components';

export const Requests = () => {
  const [activeTab, setActiveTab] = useState(NetworkRequestsTabs[0]);
  const [receivedOrSentRequest, setReceivedOrSentRequest] = useState([]);
  const [fnWrapper, context] = useAxiosWrapper();
  const tabSwitchHandler = (value) => {
    setActiveTab(value);
  };
  const getRequestSentReceived = useCallback(
    async () => {
      const loggedInUser = TokenService.getSession();
      const res = await fnWrapper({
        url: `${FETCH_CONNECTION_REQUEST_SENT_RECEIVED}/${
          loggedInUser.id
        }?reqType=${activeTab.toLowerCase()}`,
        method: 'GET',
        type: 'FETCH_CONNECTION_REQUEST_SENT_RECEIVED',
      });
      if (res.data.statusCode === 200) {
        setReceivedOrSentRequest(res.data.data);
      } else {
        setReceivedOrSentRequest([]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeTab]
  );

  useEffect(() => {
    getRequestSentReceived();
  }, [getRequestSentReceived]);

  const acceptOrDenyRequestHandler = async (requestType, connectWithId) => {
    const loggedInUser = TokenService.getSession();
    const payload = {
      connectedWithId: connectWithId,
      status: requestType,
    };
    const res = await fnWrapper({
      url: `${ACCEPT_DENY_CONNECTION_REQUEST}/${loggedInUser.id}`,
      method: 'PUT',
      type: 'ACCEPT_DENY_CONNECTION_REQUEST',
      payload: payload,
    });
    if (res.data.statusCode === 200) {
      getRequestSentReceived();
    }
  };
  const withdrawRequestHandler = async (connectWithId) => {
    const loggedInUser = TokenService.getSession();
    const payload = {
      connectedWithId: connectWithId,
    };
    const res = await fnWrapper({
      url: `${WITHDRAW_CONNECTION_REQUEST}/${loggedInUser.id}`,
      method: 'PUT',
      type: 'WITHDRAW_CONNECTION_REQUEST',
      payload: payload,
    });
    if (res.data.statusCode === 200) {
      getRequestSentReceived();
    }
  };
  return (
    <div className="main-wrapper">
      {context.busy.status ? (
        <Loader loading={context.busy.status} />
      ) : (
        <RequestNetworkWrapperStyle className="request-network">
          <div className="request-network__tabGroup">
            <TabGroup
              tabLists={NetworkRequestsTabs}
              activeTab={activeTab}
              tabClick={tabSwitchHandler}
            />
          </div>
          {receivedOrSentRequest?.length >= 1 ? (
            <div className="request-network__table">
              {receivedOrSentRequest.map((item) => (
                <div className="request-network__table__row" key={item.id}>
                  <YourNetworkOrRequestList
                    networkDetails={
                      activeTab === 'Sent'
                        ? item?.connectWithUsers
                        : item?.users
                    }
                    userId={
                      activeTab === 'Sent' ? item.connectWith : item.userId
                    }
                    mutualConnections={item?.mutualConnections?.length}
                    footerElement={
                      activeTab === 'Sent' ? (
                        <WithDrawRequest
                          className="request-network__table__row__footer--button"
                          withdrawRequestHandler={withdrawRequestHandler}
                          withdrawId={item.connectWith}
                        />
                      ) : (
                        <AcceptOrDenyRequest
                          className="request-network__table__row__footer--button"
                          acceptOrDenyRequestHandler={
                            acceptOrDenyRequestHandler
                          }
                          connectWithId={item.userId}
                        />
                      )
                    }
                  />
                </div>
              ))}
            </div>
          ) : (
            <div className="no--data">
              {`No request  ${
                activeTab === 'Sent' ? 'sent' : 'received'
              } yet !`}
              <img src={createInitialTeams} width="320px" alt="noData" />
            </div>
          )}
        </RequestNetworkWrapperStyle>
      )}
    </div>
  );
};
