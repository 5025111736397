import styled from 'styled-components';

export const ContentCardWrapper = styled.div`
  background: #e9eef4;
  border-radius: 10px;
  padding: 15px;
  border-bottom: ${({ isDropDown }) =>
    isDropDown ? ' 2px groove #404f6755' : 'none'};

  & .content-class {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & .content-header-class {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      & .icon-style-class {
        align-self: flex-start;
        margin-top: 12px;
        background: rgba(255, 255, 255, 0.82);
        border-radius: 32.5px;
        padding: 8px;
      }

      & .card-heading {
        padding-left: 15px;
        color: #404f67;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        & .title-class {
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
        }

        & .sub-title-class {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
        }

        & .total-duration-class {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
        }

        & .content-description {
          margin-top: 7px;
        }
        & .content-link {
          margin-top: 7px;
          color: #219fff;
        }
      }
    }
  }
`;
