import { CustomButton, Loader, Pagination, SearchInput } from 'components';
import ExploreGroupCard from 'pages/Explore/Components/ExploreGroupCard/Index';
import ExploreGroupsFilter from 'pages/Explore/Components/ExploreGroupsFilter';
import React, { useCallback, useEffect, useState } from 'react';
import { GET_PUBLIC_GROUPS, useAxiosWrapper } from 'services';
import noEnterprise from '../../../../assests/images/no-enterprise.png';
import styled from 'styled-components';
import Icon from 'utlils/Icons/Icon';
const DEFAULT_FILTER_STATE = {
  filteredGroupCategory: {},
};

export default function SearchGroups() {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [publicGroups, setPublicGroups] = useState({});
  const [fnWrapper, context] = useAxiosWrapper();
  const [currentPage, setCurrentPage] = useState(1);
  const [filterState, setFilterState] = useState(DEFAULT_FILTER_STATE);
  let pageSize = 6;
  const getPublicGroups = useCallback(
    async (search = '', filter = filterState) => {
      const res = await fnWrapper({
        method: 'GET',
        url: `${GET_PUBLIC_GROUPS}?hasPaginated=true&page=${currentPage}&search=${search}&size=${pageSize}&category=${
          Object.keys(filter.filteredGroupCategory).length
            ? filter.filteredGroupCategory.value
            : ''
        }`,
        type: `GET_PUBLIC_GROUPS`,
      });
      if (res?.data?.statusCode === 200) {
        const result = res?.data?.data;
        if (result.items.length === 0 && currentPage !== 1) {
          setCurrentPage(currentPage - 1);
        } else {
          setCurrentPage(result.currentPage);
          setPublicGroups(result);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage, filterState]
  );

  const groupFilterOnChangeHandler = (field = '', value = null) => {
    setFilterState({
      ...filterState,
      [field]: value,
    });
  };

  const groupFilterSaveHandler = (type) => {
    if (type === 'apply' && publicGroups.totalItems !== 0) {
      setSearchTerm('');
      getPublicGroups('', filterState);
    } else if (
      type === 'cancel' &&
      JSON.stringify(filterState) !== JSON.stringify(DEFAULT_FILTER_STATE)
    ) {
      setFilterState(DEFAULT_FILTER_STATE);
      getPublicGroups('', DEFAULT_FILTER_STATE);
    }
    setIsFilterOpen(false);
  };

  const searchHandler = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value === '') {
      getPublicGroups();
    }
  };

  const closeSearchHandler = () => {
    setSearchTerm('');
    getPublicGroups();
  };

  useEffect(() => {
    getPublicGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getPublicGroups]);

  return (
    <SearchGroupsWrapper>
      <div className="search-container">
        <SearchInput
          onSearchange={searchHandler}
          value={searchTerm}
          placeHolder="Search a group by name"
          onCloseSearch={closeSearchHandler}
          onSearch={() => getPublicGroups(searchTerm)}
        />
        <CustomButton
          color={isFilterOpen ? 'themeSecondary' : 'themeGray2'}
          variant="light"
          onClick={() => setIsFilterOpen(!isFilterOpen)}>
          <Icon
            name="filter"
            size={20}
            color={isFilterOpen ? 'rgba(33, 159, 255, 1)' : '#404f67'}
            className="filter-arrow"
          />
          Filter
        </CustomButton>
      </div>
      <div className="main-wrapper">
        <div className="heading">
          <h5 className="group-count">Groups ({publicGroups.totalItems})</h5>
        </div>
        {context.busy.status ? (
          <Loader loading={context.busy.status} />
        ) : (
          <div className="cards-container">
            {publicGroups?.items?.map((group, index) => (
              <ExploreGroupCard key={index + 1} groupDetails={group} />
            ))}
          </div>
        )}
        {isFilterOpen && (
          <ExploreGroupsFilter
            state={filterState}
            filterTitle={'Filter Groups'}
            filterOnChangeHandler={groupFilterOnChangeHandler}
            filterOnSavehandler={groupFilterSaveHandler}
          />
        )}
        <div className="pagination-conatiner">
          {publicGroups.currentPage >= 1 && (
            <Pagination
              className="pagination-bar"
              currentPage={
                publicGroups.currentPage ? publicGroups.currentPage : 0
              }
              totalCount={publicGroups.totalItems ? publicGroups.totalItems : 0}
              pageSize={pageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          )}
        </div>
        {publicGroups && publicGroups?.totalItems === 0 && (
          <div className="no-content">
            <div className=" background-image">
              <img src={noEnterprise} alt="" width="320px" />
            </div>
            <p>
              It looks like you don't have any groups yet. <br />
              Creating a group lets you collaborate, share content, and work
              with others.
            </p>
          </div>
        )}
      </div>
    </SearchGroupsWrapper>
  );
}

const SearchGroupsWrapper = styled.div`
  .search-container {
    display: grid;
    grid-template-columns: auto minmax(100px, 120px);
    gap: 20px;
    margin-bottom: 20px;
  }
  .main-wrapper {
    border-radius: 12px;
    padding: 30px;
    background-color: white;
    margin-bottom: 20px;
    position: relative;
    & .heading {
      padding-bottom: 30px;
      border-bottom: 1px solid #e9eef4;
      & .teams-count {
        margin: 0;
      }
    }
    & .cards-container {
      padding-top: 30px;
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
    }
    & .no-content {
      text-align: center;
      color: #808080;
      p {
        padding-top: 10px;
      }
    }
    & .pagination-conatiner {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 16px;
      background: white;
      border-radius: 12px;
      & .pagination-bar {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
`;
