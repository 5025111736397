import { initialState } from './notificationContext';

export const NotificationReducer = (state, action) => {
  switch (action.type) {
    case 'SET_DEFAULT_DATA':
      return {
        ...state,
        defaultData: action.defaultData,
      };
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.payload,
      };
    case 'CLEAR_STATE':
      return initialState;
    case 'SET_NOTIFICATIONS':
      return {
        ...state,
        notifications: action.payload,
      };
    case 'UPDATE_NEW_NOTIFICATIONS':
      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
      };
    default:
      return state;
  }
};
