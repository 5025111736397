import { CustomButton } from 'components';
import React from 'react';
import Icon from 'utlils/Icons/Icon';
import { ExploreTeamCardWrapper } from './style';

export const ExploreTeamProjectCard = ({
  profileImgSrc,
  profileIcon,
  title,
  owner,
  type,
  description,
  viewHandler,
  features = [],
  isPublished,
  isSaved,
  onSaveHandler,
}) => {
  return (
    <ExploreTeamCardWrapper type={type}>
      <div className="content-header">
        {profileImgSrc && profileImgSrc !== '' ? (
          <div className="profile-pic-class">
            <img src={profileImgSrc} alt="Profile" />
          </div>
        ) : (
          <div className="icon-style-class">
            <Icon name={profileIcon} size={24} color="#404f67" />
          </div>
        )}
        <div className="title-wrapper">
          <div className="title-wrapper-title">{title}</div>
          <div className="title-wrapper-subtitle">
            <span className="title-wrapper-subtitle-text">{owner}</span>
          </div>
        </div>
      </div>
      <div className="feature-class">
        {features.length !== 0 &&
          features.map((feature, index) => (
            <div className="feature-style" key={index}>
              <Icon name={feature.iconName} size={18} color="#404f67" />
              <span>{feature.label}</span>
            </div>
          ))}
      </div>
      <div className="description">{description}</div>
      <div className="view-button-wrapper">
        <CustomButton
          customPadding="10px 14px"
          variant="outline"
          onClick={viewHandler}
          color={
            isPublished || type === 'team' || type === 'job'
              ? 'themePrimary'
              : 'themeGray'
          }
          disabled={!isPublished && type === 'project'}>
          {!isPublished && type === 'project' && (
            <Icon
              name="lock-two"
              color={
                isPublished || type === 'team' || type === 'job'
                  ? '#0abd00'
                  : '#929DAF'
              }
            />
          )}
          {!isPublished && type === 'project' ? 'Private' : 'View'}
        </CustomButton>
        <div className="save-button" onClick={onSaveHandler}>
          <Icon
            name={isSaved ? 'archieve-saved' : 'archieve-tick'}
            color="#0ABD00"
            size={35}
          />
        </div>
      </div>
    </ExploreTeamCardWrapper>
  );
};
