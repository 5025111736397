import { useState } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import AddTeams from './individualTeams/addTeams';
import {
  CreateProject,
  EditProject,
  ProjectList,
  ViewProject,
} from 'pages/Projects';
import { TEAMS_PROJECTS_TOGGLE_LABEL } from 'pages/enterpriseDashboard/constants';
import { ViewTeams } from './individualTeams/viewTeams';
import { CreateTeamProvider } from 'context/Teams/CreateTeam/createTeamContext';
import { Box, ToggleSwitch } from 'components';
import { TYPES } from '../constants';
import { CreateProjectProvider } from 'context/Projects/CreateProject/createProjectContext';
import { useEffect } from 'react';
import { capitalizeFirstLetter } from '../individualOrganizations/enterprise/helper';
import { isMobile } from 'utlils';

export const IndividualPersonalSection = (props) => {
  const [taskType, setTaskType] = useState(TEAMS_PROJECTS_TOGGLE_LABEL.TEAMS);
  let { userProfile, isUpdateList } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const onToggleHandler = (value) => {
    setTaskType(value);
    const type = value.toLowerCase();
    navigate(`/individual/profile/personal/${type}`);
  };

  useEffect(() => {
    let pathItems = location.pathname.split('/');
    setTaskType(capitalizeFirstLetter(pathItems[4]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Box bg="white" className="main-wrapper">
      <div className="header-text">
        <p className="heading">
          Personal/
          <span>{taskType}</span>
        </p>
        <ToggleSwitch
          activeLabel={taskType}
          onClick={onToggleHandler}
          toggleLabel={[
            TEAMS_PROJECTS_TOGGLE_LABEL.TEAMS,
            TEAMS_PROJECTS_TOGGLE_LABEL.PROJECTS,
          ]}
          width={isMobile() ? '14rem' : '16rem'}
        />
      </div>
      <Routes>
        <Route path="">
          <Route
            element={
              <ViewTeams
                isTeamListUpdate={isUpdateList}
                userProfile={userProfile}
              />
            }
            path="teams"
          />
          <Route
            element={
              <CreateTeamProvider>
                <AddTeams teamType={'PERSONAL'} userProfile={userProfile} />
              </CreateTeamProvider>
            }
            path="teams/create/*"
          />
          <Route
            element={
              <ProjectList
                type={TYPES.PERSONAL}
                baseUrl={`/individual/profile/personal/projects`}
              />
            }
            path=":type"
          />
          <Route
            element={
              <ViewProject
                type={TYPES.PERSONAL}
                baseUrl={`/individual/profile/personal/projects`}
              />
            }
            path=":type/:projectId/view/*"
          />
          <Route
            element={
              <CreateProjectProvider>
                <EditProject
                  type={TYPES.PERSONAL}
                  baseUrl={`/individual/profile/personal/projects`}
                />
              </CreateProjectProvider>
            }
            path=":type/:projectId/:action/*"
          />
          <Route
            element={
              <CreateProjectProvider>
                <CreateProject
                  type={TYPES.PERSONAL}
                  baseUrl={`/individual/profile/personal/projects`}
                />
              </CreateProjectProvider>
            }
            path=":type/create/*"
          />
        </Route>
      </Routes>
    </Box>
  );
};
