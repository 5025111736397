import React, { useState, useEffect } from 'react';
import { ModalPopup, Textarea, CustomButton } from 'components';
import styled from 'styled-components';
import { ScrollableDiv } from 'components/scrollableDiv';

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
`;

const FullWidthButton = styled(CustomButton)`
  width: 48%;
  text-align: center;
`;

const QuestionWrapper = styled.div`
  margin-bottom: 16px;
`;

export const QuestionsModal = ({ isOpen, questions, onClose, onSubmit }) => {
  const [answers, setAnswers] = useState({});

  useEffect(() => {
    if (questions) {
      const initialAnswers = questions.reduce((acc, question) => {
        acc[question] = '';
        return acc;
      }, {});
      setAnswers(initialAnswers);
    }
  }, [questions]);

  const handleAnswerChange = (question, newValue) => {
    setAnswers({
      ...answers,
      [question]: newValue,
    });
  };

  const isAllAnswered = !Object.values(answers).some((answer) => answer === '');

  return (
    <ModalPopup
      open={isOpen}
      onClose={onClose}
      title="Answer the Questions"
      maxContentWidth="600px">
      <ScrollableDiv scrollOnHeight="35vh">
        {questions &&
          questions.map((question, index) => (
            <QuestionWrapper key={index}>
              <Textarea
                label={`Q${index + 1}: ${question}`}
                rows={3}
                value={answers[question]}
                onChange={(e) => handleAnswerChange(question, e.target.value)}
              />
            </QuestionWrapper>
          ))}
      </ScrollableDiv>

      <Footer>
        <FullWidthButton
          onClick={onClose}
          variant={'outline'}
          color="themeTertiary">
          Cancel
        </FullWidthButton>
        <FullWidthButton
          onClick={() => onSubmit(answers)}
          color="themeSecondary"
          disabled={!isAllAnswered}>
          Submit
        </FullWidthButton>
      </Footer>
    </ModalPopup>
  );
};
