import { checkForFileType } from 'pages/IndividualDashboard/individualOrganizations/enterprise/helper';
import { DocCardWrapper } from './style';
import Icon from 'utlils/Icons/Icon';

const DocCard = ({ docItem }) => {
  return (
    <DocCardWrapper>
      {checkForFileType(docItem?.fileName) === 'others' ? (
        <Icon name="attachment" color=" rgba(64, 79, 103, 0.8)" />
      ) : (
        <img src={checkForFileType(docItem?.fileName)} alt="attachment" />
      )}
      <a className="doc-link" href={docItem?.location}>
        {docItem?.fileName}
      </a>
    </DocCardWrapper>
  );
};

export default DocCard;
