import { useRef } from 'react';
import {
  FeatureHeroContainer,
  FeatureJourney,
  FeatureSubFooter,
  HomeFooter,
} from '../Components';
import { HOME_PAGE_DATA } from '../constant';
import { FEATURE_PAGE_DATA } from './constant';

export const FeaturesPage = () => {
  const ref = useRef();
  const handleScroll = () => {
    if (ref?.current) {
      ref.current.scrollIntoView();
    }
  };
  return (
    <div>
      <FeatureHeroContainer
        handleScroll={handleScroll}
        content={FEATURE_PAGE_DATA.heroContent}
      />
      <div ref={ref}>
        <FeatureJourney content={FEATURE_PAGE_DATA.journeyContent} />
      </div>
      <FeatureJourney content={FEATURE_PAGE_DATA.journeyContent2} />
      <FeatureJourney content={FEATURE_PAGE_DATA.journeyContent3} />
      <FeatureJourney content={FEATURE_PAGE_DATA.journeyContent4} />
      <FeatureJourney content={FEATURE_PAGE_DATA.journeyContent5} />
      <FeatureJourney content={FEATURE_PAGE_DATA.journeyContent6} />
      <FeatureSubFooter content={FEATURE_PAGE_DATA.subFooter} />
      <HomeFooter options={HOME_PAGE_DATA.footerData} />
    </div>
  );
};
