import React, { useState, useEffect } from 'react';
import { GroupPublicationsWrapper } from './style';
import { SearchInput, CustomButton } from 'components';
import Icon from 'utlils/Icons/Icon';
import { PublicationsPopUp } from '../Components/PublicationsPopUp';
import { PublicationCard } from '../Components/PublicationCard';
import ImgSrc from '../../../../../assests/images/createInitialTeams.png';
import {
  FETCH_PUBLICATIONS,
  CREATE_PUBLICATION,
  UPDATE_PUBLICATION,
  DELETE_PUBLICATION,
  useAxiosWrapper,
} from 'services';
import { Pagination } from 'components';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import tokenService from 'services/token.service';
import { PublicationFilter } from 'pages/TeamDashboard/TeamPublications/Components/PublicationsFilter';

const DEFAULT_PAGINATION_DATA = {
  size: 3,
  totalPages: 5,
  totalItems: 3,
};

const DFAULT_SEARCH_AND_QUERY_STATE = {
  startDate: '',
  endDate: '',
  search: '',
};

export default function GroupPublications(props) {
  const { groupRole } = props;
  const [publicationsList, setPublicationsList] = useState([]);
  const [filteredPublicationsList, setFilteredPublicationsList] = useState([]);
  const [searchAndFilterQuery, setSearchAndFilterQuery] = useState(
    DFAULT_SEARCH_AND_QUERY_STATE
  );
  const [paginationData, setPaginationData] = useState(DEFAULT_PAGINATION_DATA);
  const [currentPage, setCurrentPage] = useState(1);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [fnWrapper] = useAxiosWrapper();
  const params = useParams();
  const userId = tokenService.getSession()?.id;

  const getPublicationDetails = async () => {
    const res = await fnWrapper({
      url: FETCH_PUBLICATIONS,
      method: 'GET',
      type: 'FETCH_PUBLICATIONS',
      params: {
        groupId: params.groupId,
        size: paginationData?.size,
        page: currentPage,
        hasPaginated: true,
      },
    });

    setPaginationData((state) => ({
      ...state,
      totalItems: res?.data?.data?.totalItems,
      totalPages: res?.data?.data?.totalPages,
    }));
    setPublicationsList(res?.data?.data?.items);
    setFilteredPublicationsList(res?.data?.data?.items);
  };

  const createPublication = async (publicationData) => {
    const publicationAttachments = publicationData?.attachments;
    const selectedCollaboratorMembers =
      publicationData?.selectedCollaboratorMembers;
    const form = new FormData();
    form.append('title', publicationData?.name);
    form.append('description', publicationData?.description);
    form.append('privacy', publicationData?.privacy);
    form.append('referenceLink', publicationData?.referenceLink);
    form.append(
      'coverImage',
      publicationData?.coverImage && publicationData?.coverImage[0]
    );

    for (const key in publicationAttachments) {
      form.append('attachments', publicationAttachments[key]);
    }
    if (selectedCollaboratorMembers && selectedCollaboratorMembers.length > 0) {
      for (const member of selectedCollaboratorMembers) {
        form.append('collaboratorMemberId', member?.userId);
      }
    }
    form.append('userId', userId);
    form.append('groupId', params.groupId);
    form.append('category', publicationData?.category?.value);

    const res = await fnWrapper({
      url: `${CREATE_PUBLICATION}`,
      method: 'POST',
      type: 'CREATE_PUBLICATION',
      payload: form,
    });

    if (res.data.statusCode === 200) {
      toast.success(res.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }

    setIsPopUpOpen(false);
    getPublicationDetails();
  };

  const updatePublication = async (publicationData) => {
    const { id } = publicationData;
    const publicationAttachments = publicationData?.attachments || [];
    const linkAttachments = publicationData?.linkedAttachments || [];
    const selectedCollaboratorMembers =
      publicationData?.selectedCollaboratorMembers || [];
    const form = new FormData();
    form.append('title', publicationData?.name);
    form.append('description', publicationData?.description);
    form.append('privacy', publicationData?.privacy);
    form.append('referenceLink', publicationData?.referenceLink);
    form.append('category', publicationData?.category?.value);

    if (publicationData?.coverImage && publicationData?.coverImage[0]) {
      form.append('coverImage', publicationData?.coverImage[0]);
    }

    if (selectedCollaboratorMembers.length > 0) {
      for (const member of selectedCollaboratorMembers) {
        form.append('collaboratorMemberId', member?.userId);
      }
    }
    // Attachments
    if (publicationAttachments.length > 0) {
      for (const key in publicationAttachments) {
        form.append('attachments', publicationAttachments[key]);
      }
    }

    // Link Attachments
    if (linkAttachments.length > 0) {
      for (const key in linkAttachments) {
        form.append('linkAttachments', linkAttachments[key]);
      }
    }

    const res = await fnWrapper({
      url: `${UPDATE_PUBLICATION}/${id}`,
      method: 'PUT',
      type: 'UPDATE_PUBLICATION',
      payload: form,
    });

    if (res.data.statusCode === 200) {
      toast.success(res.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }

    getPublicationDetails();
  };

  const removePublication = async (id) => {
    const res = await fnWrapper({
      url: `${DELETE_PUBLICATION}/${id}`,
      method: 'PUT',
      type: 'DELETE_PUBLICATION',
    });
    if (res.data.statusCode === 200) {
      toast.success(res.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
    getPublicationDetails();
  };

  const searchQueryHandler = (value = '', label = 'search') => {
    setSearchAndFilterQuery((state) => ({
      ...state,
      [label]: value,
    }));
  };

  const onFilterApply = (dates) => {
    setSearchAndFilterQuery((state) => ({
      ...state,
      ...dates,
    }));
    setCurrentPage(1);
  };

  const clearFilterHanlder = () => {
    setSearchAndFilterQuery(DFAULT_SEARCH_AND_QUERY_STATE);
    setFilteredPublicationsList(publicationsList);
    setIsFilterOpen(false);
    setCurrentPage(1);
  };

  const onSearchHandler = async () => {
    const hasSearchParam =
      searchAndFilterQuery?.search.length > 0
        ? searchAndFilterQuery?.search
        : null;

    const hasStartDate =
      searchAndFilterQuery?.startDate.length > 0
        ? searchAndFilterQuery?.startDate
        : null;

    const hasEndDate =
      searchAndFilterQuery?.endDate.length > 0
        ? searchAndFilterQuery?.endDate
        : null;

    const res = await fnWrapper({
      url: FETCH_PUBLICATIONS,
      method: 'GET',
      type: 'FETCH_PUBLICATIONS',
      params: {
        groupId: params.groupId,
        size: paginationData?.size,
        page: currentPage,
        hasPaginated: true,
        search: hasSearchParam ? hasSearchParam : null,
        startDate: hasStartDate ? hasStartDate : null,
        endDate: hasEndDate ? hasEndDate : null,
      },
    });

    setFilteredPublicationsList(res?.data?.data?.items);

    // Update totalItems and totalPages based on the filtered data
    setPaginationData((state) => ({
      ...state,
      totalItems: res?.data?.data?.totalItems,
      totalPages: res?.data?.data?.totalPages,
    }));
  };

  const onCloseSearchHandler = () => {
    setFilteredPublicationsList(publicationsList);
    setSearchAndFilterQuery((state) => ({ ...state, search: '' }));

    // Reset pagination based on the backend's default pagination data
    setCurrentPage(1);
    getPublicationDetails();
  };

  const onPageChange = async (page) => {
    if (currentPage !== page) {
      setCurrentPage(page);
    }
  };

  useEffect(() => {
    getPublicationDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onSearchHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentPage,
    searchAndFilterQuery.startDate,
    searchAndFilterQuery.endDate,
  ]);

  return (
    <GroupPublicationsWrapper>
      {groupRole === 'MEMBER' && (
        <div className="no-permission">
          <Icon name="info-circle" color="#af814e" />
          Creating publications is a privilege reserved for admins and
          co-admins. As a member, you don't have the necessary permissions for
          this action.
        </div>
      )}
      <div className={'search-container'}>
        <SearchInput
          value={searchAndFilterQuery?.search}
          onSearch={onSearchHandler}
          placeholder="Search Publications"
          onSearchange={(e) => searchQueryHandler(e?.target?.value)}
          onCloseSearch={onCloseSearchHandler}
        />
        <div className="filter-btn-group">
          <CustomButton
            variant="light"
            color={isFilterOpen ? 'themeSecondary' : 'themeGray2'}
            onClick={(e) => {
              setIsFilterOpen((state) => !state);
            }}>
            <Icon
              name="filter"
              size={20}
              className="filter-arrow"
              color={isFilterOpen ? 'rgba(33, 159, 255, 1)' : '#404f67'}
            />
          </CustomButton>
          {groupRole !== 'MEMBER' && (
            <CustomButton
              className="d-flex gap-3"
              onClick={() => setIsPopUpOpen(true)}>
              <Icon name="add" color="#fff" size={22} />
              New Publication
            </CustomButton>
          )}
          {isFilterOpen && (
            <PublicationFilter
              setState={onFilterApply}
              onClear={clearFilterHanlder}
              isFilterOpen={isFilterOpen}
            />
          )}
        </div>
      </div>
      <div className="publications-section">
        {publicationsList?.length > 0 ? (
          <div className="publication-cards">
            {filteredPublicationsList?.length ? (
              filteredPublicationsList?.map((item, index) => (
                <PublicationCard
                  key={index}
                  {...item}
                  publishedByUser={
                    item?.user?.individual
                      ? item?.user?.individual
                      : item?.user?.smallEnterprise
                  }
                  onUpdate={updatePublication}
                  onRemove={removePublication}
                />
              ))
            ) : (
              <div className="no-filter-data">No data found</div>
            )}
          </div>
        ) : (
          <div className="no-publications">
            <img src={ImgSrc} alt="no-publications" />
            <br />
            Currently, there are no publications available.
          </div>
        )}

        {isPopUpOpen && (
          <PublicationsPopUp
            open={true}
            title="Create Publication"
            closePopup={() => setIsPopUpOpen(false)}
            onPublish={createPublication}
          />
        )}
      </div>
      {paginationData?.totalItems > paginationData?.size && (
        <div className="pagination-wrapper">
          <Pagination
            currentPage={currentPage}
            totalCount={paginationData?.totalItems}
            pageSize={paginationData?.size}
            onPageChange={(page) => onPageChange(page)}
          />
        </div>
      )}
    </GroupPublicationsWrapper>
  );
}
