import AirPlane from '../../../../assests/images/paper-plane.png';
export const Success = ({ viewApplicationHandler }) => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className="successfully-applied">
        <img src={AirPlane} alt="airplane" height={200} />
        <div className="success-message">
          {'You have successfully applied .'}
        </div>
        <span className="success-message">{'Click '}</span>
        <span
          className="click-here"
          onClick={viewApplicationHandler}
          style={{ cursor: 'pointer' }}>
          {'here '}
        </span>
        <span className="success-message">
          {'here to view your application'}
        </span>
      </div>
    </div>
  );
};
