import { useContext, useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import Icon from 'utlils/Icons/Icon';
import { useNavigate, useParams } from 'react-router-dom';
import {
  setProgressStep,
  setProjectDetails,
  clearState,
} from 'context/Projects/CreateProject/createProjectAction';
import { CreateProjectContext } from 'context/Projects/CreateProject/createProjectContext';
import {
  CustomButton,
  CustomFileUploadButton,
  DropDown,
  Input,
  ModalPopup,
  Textarea,
  UploadImage,
} from 'components';
import { AttachmentsTypes, TYPES } from 'pages/IndividualDashboard/constants';
import { checkForFileType } from 'pages/IndividualDashboard/individualOrganizations/enterprise/helper';
import { DetailsWrapper } from './style';
import {
  CREATE_PROJECT,
  UPDATE_DRAFT_PROJECT,
  FETCH_PROJECT_SERVICES,
  useAxiosWrapper,
} from 'services';
import { checkForSpacesInStartEnd } from 'helpers';

export const Details = ({
  projectCategories,
  onLeavingEditor,
  type = '',
  isDraft = false,
  draftProjectId = '',
  baseUrl = '',
}) => {
  const [fnWrapper, context] = useAxiosWrapper();
  const { createProjectState, createProjectDispatch } =
    useContext(CreateProjectContext);
  const [isDraftPopupOpen, setIsDraftPopupOpen] = useState(false);
  const navigate = useNavigate();
  const [editAttachments, setEditAttachments] = useState([]);
  const { id, departmentId, projectId, action } = useParams();

  const getProjectDetails = useCallback(async (projId) => {
    const res = await fnWrapper({
      url: `${FETCH_PROJECT_SERVICES}/${projId}?st=details`,
      method: 'GET',
      type: 'FETCH_PROJECT_DETAILS',
    });

    if (res.data.statusCode === 200) {
      const result = res.data.data.data;
      let categoryData = result.category;
      result.category = {
        name: categoryData,
        label: categoryData,
        value: categoryData,
      };
      setProjectDetails(createProjectDispatch, 'projectTitle', result.title);
      setProjectDetails(
        createProjectDispatch,
        'problemStatement',
        result.problemStatement
      );
      setProjectDetails(createProjectDispatch, 'solution', result.solution);
      setProjectDetails(createProjectDispatch, 'category', result.category);
      setProjectDetails(createProjectDispatch, 'link', result.link);
      setProjectDetails(
        createProjectDispatch,
        'linkAttachments',
        result.attachments !== null ? result.attachments : []
      );
      const arr = result.attachments?.map((file) => {
        return getFileName(file);
      });
      setEditAttachments(arr ? arr : []);
      setProjectDetails(
        createProjectDispatch,
        'projectAvatar',
        result.profilePicture
      );
      setProjectDetails(createProjectDispatch, 'attachments', []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nextStepHandler = async () => {
    await setProgressStep(createProjectDispatch, 2);
    if (action && action === 'edit') {
      navigate(`${baseUrl}/${projectId}/edit/link-project`);
    } else {
      navigate(`${baseUrl}/create/link-project`);
    }
  };

  const onCloseHandler = async () => {
    if (action && action === 'edit') {
      onLeavingEditor();
    } else {
      if (createProjectState.projectTitle !== '') {
        setIsDraftPopupOpen(true);
      } else {
        await clearState(createProjectDispatch);
        navigate(`${baseUrl}`, { replace: true });
      }
    }
  };

  const leaveWithoutSave = async () => {
    await clearState(createProjectDispatch);
    navigate(`${baseUrl}`, { replace: true });
  };

  const saveAndLeave = async () => {
    let formData = new FormData();
    for (var i of createProjectState.attachments) {
      formData.append('attachments', i);
    }
    formData.append('title', createProjectState.projectTitle);
    formData.append('category', createProjectState.category.value);
    formData.append('problemStatement', createProjectState.problemStatement);
    formData.append('linkedProject', createProjectState.linkedProjectId);
    formData.append('link', createProjectState.link);
    formData.append('profilePicture', createProjectState.projectAvatar);
    formData.append('solution', createProjectState.solution);
    if (type === TYPES.ENTERPRISE) {
      const deptId = id ? id : departmentId;
      formData.append('departmentId', deptId);
    }
    formData.append('projectStatus', 'DRAFT');
    formData.append(
      'projectType',
      type === TYPES.ENTERPRISE ? 'ENTERPRISE' : 'PERSONAL'
    );
    for (var j of createProjectState.linkedTeams) {
      formData.append('teamId', j);
    }
    if (isDraft) {
      for (var f of createProjectState.linkAttachments) {
        formData.append('linkAttachments', f);
      }
      for (var k of createProjectState.deletedTeams) {
        formData.append('deleteTeamId', k);
      }
    }

    const url = isDraft
      ? `${UPDATE_DRAFT_PROJECT}/${draftProjectId}`
      : CREATE_PROJECT;

    const res = await fnWrapper({
      url: url,
      method: 'POST',
      type: 'CREATE_PROJECT',
      payload: formData,
    });

    if (res.data.statusCode === 200) {
      await clearState(createProjectDispatch);
      navigate(`${baseUrl}`, { replace: true });
    }
  };

  const attachmentsHandler = async (files) => {
    const isCorrectFileType = [...files].reduce(
      (prev, curr) => prev && AttachmentsTypes.includes(curr.type),
      true
    );
    if (isCorrectFileType) {
      await setProjectDetails(createProjectDispatch, 'isEdited', true);
      const temp = [...createProjectState.attachments, ...files];
      await setProjectDetails(createProjectDispatch, 'attachments', temp);
      if (action === 'edit' || isDraft) {
        const names = Array.from(files).map((file) => {
          return file.name;
        });
        setEditAttachments([...editAttachments, ...names]);
      }
    } else {
      toast.error('Type of File/Files uploaded are not supported.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  const removeAttachmentsHandler = async (file) => {
    await setProjectDetails(createProjectDispatch, 'isEdited', true);
    if ((action && action === 'edit') || isDraft) {
      const t1 = createProjectState.linkAttachments.filter(
        (f) => getFileName(f) !== file
      );
      const t2 = createProjectState.attachments.filter((f) => f.name !== file);
      let temp = [...t1, ...t2];
      const new_edit = temp.map((f) => {
        if (typeof f === 'string') {
          return getFileName(f);
        } else {
          return f.name;
        }
      });
      setEditAttachments(new_edit);
      await setProjectDetails(createProjectDispatch, 'linkAttachments', t1);
      await setProjectDetails(createProjectDispatch, 'attachments', t2);
    } else {
      let temp1 = createProjectState.attachments.filter((e) => e !== file);
      await setProjectDetails(createProjectDispatch, 'attachments', temp1);
    }
  };

  const handleAvatar = async (value) => {
    await setProjectDetails(createProjectDispatch, 'isEdited', true);
    await setProjectDetails(createProjectDispatch, 'projectAvatar', value);
  };

  const getFileName = (file) => {
    const setFileName = file.split('/');
    return setFileName[setFileName.length - 1];
  };

  const buttonDisabled =
    !createProjectState.projectTitle ||
    !createProjectState.category.value ||
    checkForSpacesInStartEnd(createProjectState.projectTitle);

  useEffect(() => {
    setProgressStep(createProjectDispatch, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (action && action === 'edit') {
      getProjectDetails(projectId);
    } else if (isDraft) {
      getProjectDetails(draftProjectId);
    }
  }, [getProjectDetails, action, isDraft, draftProjectId, projectId]);

  return (
    <DetailsWrapper>
      <div className="create-container">
        <Input
          label="Project Title"
          type="text"
          required
          sm
          value={createProjectState.projectTitle}
          onChange={(e) => {
            setProjectDetails(createProjectDispatch, 'isEdited', true);
            setProjectDetails(
              createProjectDispatch,
              'projectTitle',
              e.target.value
            );
          }}
        />

        <Textarea
          label="Problem Statement"
          customStyle={{ height: '110px', marginBottom: '20px' }}
          value={createProjectState.problemStatement}
          onChange={(e) => {
            setProjectDetails(createProjectDispatch, 'isEdited', true);
            setProjectDetails(
              createProjectDispatch,
              'problemStatement',
              e.target.value
            );
          }}
        />
        <Textarea
          label="Specify Your Solution"
          customStyle={{ height: '110px', marginBottom: '20px' }}
          value={createProjectState.solution}
          onChange={(e) => {
            setProjectDetails(createProjectDispatch, 'isEdited', true);
            setProjectDetails(
              createProjectDispatch,
              'solution',
              e.target.value
            );
          }}
        />
        <Input
          label="Link (if any)"
          type="text"
          sm
          value={createProjectState.link}
          onChange={(e) => {
            setProjectDetails(createProjectDispatch, 'isEdited', true);
            setProjectDetails(createProjectDispatch, 'link', e.target.value);
          }}
        />
        <div className="file-upload">
          <span className="text-style">Add Image/Video/Attachment</span>
          <CustomFileUploadButton
            borderWidth="0px 0px 1px 0px"
            borderStyle="solid"
            borderColor="#219FFF"
            background="none"
            fontFamily="'Poppins', 'Open Sans'"
            fontSize="15px"
            fontWeight="500"
            textColor="#219FFF"
            handleFile={(file) => attachmentsHandler(file)}
            label="Add"
            iconColor="#219FFF"
            iconName="add"
            iconSize="18"
            isMultiple={true}
          />
        </div>
        {(action && action === 'edit') || isDraft ? (
          <div className="post-attachment">
            {editAttachments &&
              editAttachments.length !== 0 &&
              editAttachments.map((file, index) => {
                return (
                  <div className="img-wrapper" key={index}>
                    <div className="img-container">
                      {checkForFileType(file) === 'others' ? (
                        <Icon name="attachment" />
                      ) : (
                        <img src={checkForFileType(file)} alt="attachment" />
                      )}
                    </div>
                    <span className="img-text">{file}</span>
                    <span
                      className="remove-icon"
                      onClick={() => removeAttachmentsHandler(file)}>
                      <Icon color="rgba(146, 157, 175, 1)" name="small-close" />
                    </span>
                  </div>
                );
              })}
          </div>
        ) : (
          <div className="post-attachment">
            {createProjectState.attachments.length !== 0
              ? createProjectState.attachments.map((file, index) => {
                  return (
                    <div className="img-wrapper" key={index}>
                      <div className="img-container">
                        {checkForFileType(file.name) === 'others' ? (
                          <Icon name="attachment" />
                        ) : (
                          <img
                            src={checkForFileType(file.name)}
                            alt="attachment"
                          />
                        )}
                      </div>
                      <span className="img-text">{file.name}</span>
                      <span
                        className="remove-icon"
                        onClick={() => removeAttachmentsHandler(file)}>
                        <Icon
                          color="rgba(146, 157, 175, 1)"
                          name="small-close"
                        />
                      </span>
                    </div>
                  );
                })
              : null}
          </div>
        )}
        <div>
          <DropDown
            hasSearchBox
            required
            label={projectCategories.project_category.label}
            placeholder={projectCategories.project_category.placeholder}
            items={projectCategories.project_category.dataType}
            objectKey={projectCategories.project_category.key}
            value={createProjectState.category}
            onClick={(e) => {
              setProjectDetails(createProjectDispatch, 'isEdited', true);
              setProjectDetails(createProjectDispatch, 'category', e);
            }}
          />
        </div>
        <div className="image-upload">
          <span>Give your Project an Avatar</span>
          <UploadImage
            iconName="gallery-add"
            setImage={handleAvatar}
            currentImage={createProjectState.projectAvatar}
          />
        </div>
        <div className="project-buttons">
          <CustomButton
            label="Close"
            onClick={onCloseHandler}
            variant="textBtn"
            color="themeSecondary"
            customPadding="0 20px"
          />
          <CustomButton
            className="customBtnStyleDetails"
            label="Next"
            disabled={buttonDisabled}
            onClick={nextStepHandler}
            color="themeSecondary"
          />
        </div>
      </div>
      {isDraftPopupOpen && (
        <ModalPopup
          open={false}
          onClose={() => setIsDraftPopupOpen(false)}
          hasCloseIcon>
          <p className="text-center mt-5 w-75 mx-auto">
            You have some unsaved changes. Do you want to save it as a draft or
            leave?
          </p>
          <div className="draft-buttons mx-auto d-flex flex-sm-row flex-column justify-content-evenly align-items-center my-5">
            <CustomButton
              label="Leave"
              variant="outline"
              onClick={leaveWithoutSave}
              color="themeTertiary"
              customPadding="16px 52px"
            />
            <CustomButton
              loading={context.busy.status}
              variant="outline"
              label="Save as Draft"
              onClick={saveAndLeave}
              customPadding="16px 30px"
            />
          </div>
        </ModalPopup>
      )}
    </DetailsWrapper>
  );
};
