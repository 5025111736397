import styled from 'styled-components';

export const SearchAndAddWrapper = styled.div`
  font-family: 'Poppins';
  .searchAdd-wrapper {
    &__options {
      position: relative;
      &__list {
        top: 0px;
        width: 230px;
      }
    }
    &__selected {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      &__value {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        min-width: 129px;
        background: #219fff;
        padding: 8px 12px;
        border-radius: 7px;
        font-weight: 500;
        font-size: 14px;
        color: #ffffff;
        > svg {
          cursor: pointer;
        }
      }
    }
  }
`;
