import { useState, useCallback, useEffect, useContext } from 'react';
import {
  SearchField,
  CustomTable,
  ScrollableDiv,
  CustomButton,
} from 'components';
import { MembersFilter } from '../components';
import { MEMBERSTABLEHEADERS, membersTableData } from '../helper';
import { getPlaceholderItems } from 'utlils/utils';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { capitalizeFirstLetter } from 'pages/IndividualDashboard/individualOrganizations/enterprise/helper';
import {
  FETCH_TEAM_MEMBERS,
  UPDATE_TEAM_MEMBERS,
  useAxiosWrapper,
} from 'services';
import { RemoveMembers } from '../components';
import { IsPermitted, userScopes } from 'utlils';
import { AddTeamMember } from '../AddTeamMember';
import Icon from 'utlils/Icons/Icon';
import { RoleBasedAccessContext } from 'context/RoleBasedAccess/roleBasedAccessContext';

export const TeamMembers = (props) => {
  let { userProfile } = props;
  const { roleBasedAccessState } = useContext(RoleBasedAccessContext);
  const teamPermissions = roleBasedAccessState.personalTeamRBAC;
  const [fnWrapper, context] = useAxiosWrapper();
  const [searchMember, setSearchMember] = useState('');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [membersData, setMembersData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isRemovePopupOpen, setRemovePopupOpen] = useState(false);
  const [filteredMembers, setFilteredMembers] = useState({
    filteredDesignation: '',
    filteredDate: {},
    filteredStatus: {},
    fromDate: null,
    toDate: null,
  });

  //check input fields
  const isAllInputsEmpty =
    filteredMembers.filteredDesignation === '' &&
    Object.keys(filteredMembers.filteredDate).length === 0 &&
    Object.keys(filteredMembers.filteredStatus).length === 0 &&
    filteredMembers.fromDate === null &&
    filteredMembers.toDate === null;

  const { teamId } = useParams();

  const [isAddTeamMember, setIsAddTeamMember] = useState(false);

  const getMembersList = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_TEAM_MEMBERS}/${teamId}`,
      method: 'GET',
      type: 'FETCH_TEAM_MEMBERS_LIST',
    });

    if (res.data.statusCode === 200) {
      const membersList = [];
      res.data.data[0].teamMembers?.map((item) => {
        const newMemberData = {
          id: item.id,
          memberName: item.memberName,
          emailId: item.email,
          designation: capitalizeFirstLetter(item.designation),
          joinedOn: item.createdAt,
          role: item.role,
          status: item.status,
          memberUserId: item.memberUserId,
          isIndividual:
            item?.memberUser?.smallEnterprise === null &&
            item?.memberUser?.individual !== null,
        };
        membersList.push(newMemberData);
        return membersList;
      });
      setMembersData(membersList);
      setFilteredData(membersList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchMemberByNameHandler = (value) => {
    setSearchMember(value);
    let temp = [];
    if (value) {
      temp = membersData.filter(
        (ele) => ele.memberName.toLowerCase().indexOf(value.toLowerCase()) > -1
      );
      setFilteredData(temp);
    } else {
      setFilteredData(membersData);
    }
  };
  const closeSearchMemberByName = () => {
    setSearchMember('');
    setFilteredData(membersData);
  };

  const onRowCheckedHandler = (element) => {
    if (selectedRow.length) {
      const index = selectedRow.filter((ele) => ele.id === element.id);
      if (index.length) {
        const filteredItems = selectedRow.filter(
          (ele) => ele.id !== element.id
        );
        setSelectedRow(filteredItems);
      } else {
        setSelectedRow([...selectedRow, element]);
      }
    } else {
      setSelectedRow([...selectedRow, element]);
    }
  };

  const onAllRowCheckedHandler = (element) => {
    setSelectedRow(element);
  };

  const memberFilterSaveHandler = (type) => {
    if (type === 'apply' && membersData.length !== 0) {
      setSearchMember('');
      let result = membersData;

      let filterByDesignation = (item) => {
        if (filteredMembers.filteredDesignation === '') {
          return item;
        } else {
          return item.filter(
            (ele) =>
              ele.designation.toLowerCase() ===
              filteredMembers.filteredDesignation.toLowerCase()
          );
        }
      };

      let filterByStatus = (item) => {
        if (Object.keys(filteredMembers.filteredStatus).length === 0) {
          return item;
        } else {
          return item.filter(
            (ele) => ele.status === filteredMembers.filteredStatus.value
          );
        }
      };
      let filterByDate = (item) => {
        if (Object.keys(filteredMembers.filteredDate).length === 0) {
          return item;
        }
        if (filteredMembers.filteredDate.value === 'YD') {
          return item.filter(
            (ele) =>
              dayjs(ele.joinedOn).format('DD MMM YYYY') ===
              dayjs(new Date()).subtract(1, 'day').format('DD MMM YYYY')
          );
        }
        if (filteredMembers.filteredDate.value === 'TD') {
          return item.filter(
            (ele) =>
              dayjs(ele.joinedOn).format('DD MMM YYYY') ===
              dayjs(new Date()).format('DD MMM YYYY')
          );
        }
        if (filteredMembers.filteredDate.value === 'CD') {
          return item.filter(
            (ele) =>
              new Date(dayjs(ele.joinedOn).format('DD MMM YYYY')) >=
                new Date(
                  dayjs(filteredMembers.fromDate).format('DD MMM YYYY')
                ) &&
              new Date(dayjs(ele.joinedOn).format('DD MMM YYYY')) <=
                new Date(dayjs(filteredMembers.toDate).format('DD MMM YYYY'))
          );
        }
      };
      if (filteredMembers.filteredDesignation !== '') {
        result = filterByDesignation(result);
      }
      if (Object.keys(filteredMembers.filteredDate).length) {
        result = filterByDate(result);
      }
      if (Object.keys(filteredMembers.filteredStatus).length) {
        result = filterByStatus(result);
      }
      setFilteredData(result);
      setIsFilterOpen(false);
    } else if (type === 'cancel') {
      setFilteredMembers({
        filteredDesignation: '',
        filteredDate: {},
        filteredStatus: {},
        fromDate: null,
        toDate: null,
      });
      setFilteredData(membersData);
      setIsFilterOpen(false);
    }
  };

  const membersFilterOnChangeHandler = (field = '', value = null) => {
    setFilteredMembers({
      ...filteredMembers,
      [field]: value,
    });
  };

  const closeRemovePopup = () => {
    setSelectedRow([]);
    setRemovePopupOpen(false);
  };

  const handleRemove = () => {
    const isLoggedInUser = selectedRow.filter(
      (user) => user.memberUserId === userProfile.userId
    );

    const removeOnlyAcceptedStatus = selectedRow.filter(
      (member) => member.status.props.status.label !== 'Accepted'
    );
    if (removeOnlyAcceptedStatus.length !== 0) {
      toast.error(
        `You cannot remove a member who has not accepted Team invitation or has already been removed`,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        }
      );
      setSelectedRow([]);
    }
    if (isLoggedInUser.length !== 0) {
      toast.error(`You cannot remove yourself`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      setSelectedRow([]);
    }

    if (isLoggedInUser.length === 0 && removeOnlyAcceptedStatus.length === 0) {
      setRemovePopupOpen(true);
    }
  };

  const removeMembersHandler = async () => {
    const memberIds = selectedRow.map((member) => member.id);
    const data = {
      memberIds: memberIds,
      teamId: teamId,
    };
    const res = await fnWrapper({
      url: `${UPDATE_TEAM_MEMBERS}`,
      method: 'PUT',
      type: 'REMOVE_MEMBERS',
      payload: data,
    });

    if (res.data.statusCode === 200) {
      setRemovePopupOpen(false);
      getMembersList();
      setSelectedRow([]);
    }
  };

  const membersListData = context.busy.status
    ? getPlaceholderItems(
        MEMBERSTABLEHEADERS.map(({ sortKey }) => sortKey),
        6,
        context.busy.status
      )
    : membersTableData(filteredData);

  useEffect(() => {
    getMembersList();
  }, [getMembersList]);

  return (
    <>
      <div className="heading">
        Members<span>({membersData.length})</span>
      </div>
      <div className="header-buttons-wrapper">
        <div className="left-buttons">
          <SearchField
            searchField={searchMember}
            onChangeSearchHandler={searchMemberByNameHandler}
            closeSearchHandler={closeSearchMemberByName}
            placeholder="Search Member"
          />
        </div>

        <div className="right-buttons">
          <IsPermitted permissions={[userScopes.ADD_TEAM_MEMBERS]}>
            <CustomButton
              variant="solid"
              customPadding="14px 14px"
              onClick={() => setIsAddTeamMember(!isAddTeamMember)}>
              <Icon name="add" color="#FFFFFF" size={18} />
              Add Member
            </CustomButton>
          </IsPermitted>
          <CustomButton
            variant="light"
            color={isFilterOpen ? 'themeSecondary' : 'themeGray'}
            customPadding="14px 40px"
            onClick={() => setIsFilterOpen(!isFilterOpen)}
            className={!isFilterOpen && 'filter-button'}>
            <Icon
              name="filter"
              color={isFilterOpen ? 'rgba(33, 159, 255, 1)' : '#929DAF'}
              size={18}
            />
            Filter
          </CustomButton>
          <IsPermitted permissions={[userScopes.REMOVE_TEAM_MEMBERS]}>
            <CustomButton
              variant="light"
              color="themeTertiary"
              customPadding="14px 36px"
              disabled={selectedRow.length === 0}
              onClick={handleRemove}
              className="remove-button">
              Remove
              <Icon name="log-out" color="rgba(255, 30, 38, 0.5)" size={18} />
            </CustomButton>
          </IsPermitted>
          {isRemovePopupOpen && (
            <RemoveMembers
              totalMembers={membersData.filter(
                (member) => member.role !== 'ADMIN'
              )}
              totalAdmins={
                membersData.filter((member) => member.role === 'ADMIN').length
              }
              isPopupOpen={isRemovePopupOpen}
              closePopupHandler={closeRemovePopup}
              removeMembersHandler={removeMembersHandler}
              selectedRows={selectedRow}
            />
          )}
        </div>
      </div>
      {isFilterOpen && (
        <>
          <MembersFilter
            filterTitle="Filter Members"
            state={filteredMembers}
            disabled={isAllInputsEmpty}
            memberFilterSaveHandler={memberFilterSaveHandler}
            memberFilterOnChangeHandler={membersFilterOnChangeHandler}
          />
        </>
      )}
      {searchMember !== '' && filteredData.length === 0 ? (
        <div className="no-member">
          <p>
            Oh, looks like you don’t have any Member's name starting by
            <strong>{` ${searchMember}.`}</strong>
          </p>
        </div>
      ) : (
        <ScrollableDiv scrollOnHeight="420px" scrollOnHeightMob="300px">
          <CustomTable
            isLoading={context.busy.status}
            rows={membersListData}
            headers={MEMBERSTABLEHEADERS}
            hasCheckbox={teamPermissions.includes(
              userScopes.REMOVE_TEAM_MEMBERS
            )}
            selectedRow={selectedRow}
            rowCheckedHandler={(val) => onRowCheckedHandler(val)}
            allRowCheckedHandler={(val) => onAllRowCheckedHandler(val)}
          />
        </ScrollableDiv>
      )}
      {isAddTeamMember ? (
        <AddTeamMember getMembersList={getMembersList}></AddTeamMember>
      ) : null}
    </>
  );
};
