import PropTypes from 'prop-types';
import { getData } from 'country-list';
import { NetworkProfileCardStyle } from './style';
import { CustomButton, NavigationalRowItem } from 'components';
import Icon from 'utlils/Icons/Icon';
import { ProfilePhoto } from '../ProfilePhoto';
import { capitalizeFirstLetter } from 'pages/IndividualDashboard/individualOrganizations/enterprise/helper';
import { connectionButtonStatus } from 'pages/Explore/constants';
import { truncateString } from 'utlils/utils';

export const NetworkProfileCard = ({
  networkProfileDetails,
  connectWithProfileHandler,
  status,
  ...rest
}) => {
  const {
    userId,
    name,
    title,
    mutualConnections,
    profilePicture,
    coverProfilePicture,
    country,
    city,
  } = networkProfileDetails;
  const countryList = getData();

  const countryName = countryList.find((item) => item.code === country);

  return (
    <NetworkProfileCardStyle
      className="networkProfile-card"
      coverProfilePicture={coverProfilePicture}
      hasProfilePhoto={profilePicture !== null}>
      {!coverProfilePicture ? (
        <div className="networkProfile-card__bg" />
      ) : (
        <div className="networkProfile-card__bg cover-photo" />
      )}
      <div className="networkProfile-card__details">
        <div className="networkProfile-card__profile--img">
          <ProfilePhoto profilePicture={profilePicture} name={name} size={90} />
        </div>
        <div
          className="network--name overflow-eclipses"
          title={name ? `${capitalizeFirstLetter(name)}` : ''}>
          <NavigationalRowItem
            label={name ? `${capitalizeFirstLetter(name)}` : ''}
            customStyle={{
              fontWeight: 500,
              fontSize: '18px',
              color: '#17191D',
              cursor: 'pointer',
            }}
            navigateTo={`/public/individual/${userId}/summary/basic-details`}
          />
        </div>
        {title && (
          <div
            className="network--designation overflow-eclipses"
            title={`${capitalizeFirstLetter(title)}`}>
            {capitalizeFirstLetter(title)}
          </div>
        )}
        {country && (
          <div
            className="network--country"
            title={`${countryName?.name} ${city}`}>
            {countryName?.name &&
              capitalizeFirstLetter(truncateString(countryName?.name, 15))}

            <span className="network--city">
              {city && (
                <>
                  {','} {capitalizeFirstLetter(truncateString(city, 15))}
                </>
              )}
            </span>
          </div>
        )}
        <div
          className="mutual-connection"
          title={`${mutualConnections.length} mutual connections`}>
          <Icon name="connection" color="#404F67" />
          <div className="overflow-eclipses">{`${mutualConnections.length} mutual connections`}</div>
        </div>
        <div className="connect-button">
          <CustomButton
            label={`${connectionButtonStatus(status)?.label}`}
            className="w-100"
            variant={
              connectionButtonStatus(status)?.outlined ? 'outline' : 'solid'
            }
            color={
              connectionButtonStatus(status)?.color === '#FE9320'
                ? 'themeOrange'
                : 'themePrimary'
            }
            onClick={() => connectWithProfileHandler(userId)}
            disabled={connectionButtonStatus(status)?.disabled}
          />
        </div>
      </div>
    </NetworkProfileCardStyle>
  );
};

NetworkProfileCard.propTypes = {
  networkProfileDetails: PropTypes.shape({
    userId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    mutualConnections: PropTypes.array,
    profilePicture: PropTypes.string,
    coverProfilePicture: PropTypes.string,
  }),
  connectWithProfileHandler: PropTypes.func.isRequired,
  status: PropTypes.string,
};
