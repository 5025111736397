import { SlidingButton } from 'components';
import React from 'react';
import Icon from 'utlils/Icons/Icon';

export const SettingsFilter = (props) => {
  return (
    <div className="all-notifications-wrp settings_wrp__">
      <div className="all-notifications-wrp settings_wrp__header">
        Notification Settings
      </div>
      <div className="all-notifications-wrp settings_wrp__line"></div>
      <div className="all-notifications-wrp settings_wrp__st-content">
        <div className="row">
          <div className="col-sm-2 col-lg-2 col-md-2">
            <Icon name="question_ans" size={28} />
          </div>
          <div className="col-sm-7 col-lg-7 col-md-7">
            <div className="all-notifications-wrp settings_wrp__setting-types">
              Question/Answers
            </div>
          </div>
          <div className="col-sm-3 col-lg-3 col-md-3">
            <SlidingButton isSliding={false} />
          </div>
        </div>
      </div>
    </div>
  );
};
