import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { NavbarBrand1, Sidebar } from 'components';
import { ExploreJobs } from './ExploreJobs';
import { ExploreNetworks } from './ExploreNetworks';
import { ExploreProjects } from './ExploreProjects';
import { ExploreTeams } from './ExploreTeams';
import { ExploreLeftSidebarData } from './constants';
import { Route, Routes } from 'react-router-dom';
import { FETCH_USER_PROFILE, useAxiosWrapper } from 'services';
import tokenService from 'services/token.service';
import { getProfileText } from 'utlils/utils';
import { ExploreLayoutWrapper } from './style';
import { INDIVIDUAL_DASHBOARD_NAV_LIST } from 'pages/IndividualDashboard/constants';
import ExploreGroups from './Explore Groups';

export const Explore = ({ currentLocale, handleLocaleChange }) => {
  const [fnWrapper] = useAxiosWrapper();
  const location = useLocation();
  const [userProfile, setUserProfile] = useState({});
  const [leftActiveItem, setLeftActiveItem] = useState('');

  const pageId = location.pathname
    .replace('/explore/', '')
    .split('/')[0]
    .toUpperCase();

  useEffect(() => {
    if (!window.location.href.includes(leftActiveItem)) {
      setLeftActiveItem(ExploreLeftSidebarData[pageId][0].type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId]);

  /*---- user profile api ----*/
  const getUserProfile = useCallback(async () => {
    const loggedInUser = tokenService.getSession();
    const res = await fnWrapper({
      url: `${FETCH_USER_PROFILE}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_USER_PROFILE',
    });
    if (res.data.statusCode === 200) {
      const response = res.data.data;
      setUserProfile({
        ...response,
        profileText: getProfileText(response.name),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUserProfile();
  }, [getUserProfile]);

  return (
    <>
      <NavbarBrand1
        navMenu={INDIVIDUAL_DASHBOARD_NAV_LIST(userProfile?.role)}
        currentLocale={currentLocale}
        handleLocaleChange={handleLocaleChange}
        userProfile={userProfile}
      />
      <ExploreLayoutWrapper>
        <div className="main-container">
          <div className="layout-container ">
            <div className="layout-sub-container container">
              <div className="sub-container-child">
                <Sidebar
                  sidebarData={ExploreLeftSidebarData[pageId]}
                  activeType={leftActiveItem}
                  setActiveType={(type) => setLeftActiveItem(type)}
                />
              </div>
              <div className="sub-container-child">
                <Routes>
                  <Route path="">
                    <Route
                      element={
                        <ExploreJobs
                          currentLocale={currentLocale}
                          handleLocaleChange={handleLocaleChange}
                          userProfile={userProfile}
                        />
                      }
                      path="jobs/*"
                    />
                    <Route
                      element={
                        <ExploreNetworks
                          currentLocale={currentLocale}
                          handleLocaleChange={handleLocaleChange}
                          userProfile={userProfile}
                        />
                      }
                      path="networks/*"
                    />
                    <Route
                      element={
                        <ExploreProjects
                          currentLocale={currentLocale}
                          handleLocaleChange={handleLocaleChange}
                          userProfile={userProfile}
                        />
                      }
                      path="projects/*"
                    />
                    <Route
                      element={
                        <ExploreTeams
                          currentLocale={currentLocale}
                          handleLocaleChange={handleLocaleChange}
                          userProfile={userProfile}
                        />
                      }
                      path="teams/*"
                    />
                    <Route path="groups/*" element={<ExploreGroups />} />
                  </Route>
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </ExploreLayoutWrapper>
    </>
  );
};
