import './otpBox.scss';
import { Box } from '../box';
import { CustomButton } from 'components/button/button';
import Icon from 'utlils/Icons/Icon';

export const OtpBox = ({
  otpState,
  handleSubmit,
  inputFocus,
  onChange,
  context,
}) => {
  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="otp-container">
          <div className="otp-input-wrapper">
            <input
              type="text"
              className="otp-input"
              value={otpState.otp1}
              onChange={(e) => onChange(e, 'otp1')}
              tabIndex="1"
              maxLength="1"
              onKeyUp={(e) => inputFocus(e, 'otp1')}
              onFocus={(e) => e.target.select()}
            />
            <input
              type="text"
              className="otp-input"
              value={otpState.otp2}
              onChange={(e) => onChange(e, 'otp2')}
              tabIndex="2"
              maxLength="1"
              onKeyUp={(e) => inputFocus(e, 'otp2')}
              onFocus={(e) => e.target.select()}
            />
            <input
              type="text"
              className="otp-input"
              value={otpState.otp3}
              onChange={(e) => onChange(e, 'otp3')}
              tabIndex="3"
              maxLength="1"
              onKeyUp={(e) => inputFocus(e, 'otp3')}
              onFocus={(e) => e.target.select()}
            />
            <input
              type="text"
              className="otp-input"
              value={otpState.otp4}
              onChange={(e) => onChange(e, 'otp4')}
              tabIndex="4"
              maxLength="1"
              onKeyUp={(e) => inputFocus(e, 'otp4')}
              onFocus={(e) => e.target.select()}
            />
          </div>
          {/* continue */}
          <Box width={136} mb="40px" mt="60px">
            <CustomButton
              onClick={handleSubmit}
              loading={
                context.busy.status && context.busy.type === 'VERIFY_EMAIL'
              }
              customPadding="12px 18px">
              Continue
              <Icon
                name="icon-right"
                color={'#FFFFFF'}
                size={24}
                className="resetIcon"
              />
            </CustomButton>
          </Box>
        </div>
      </form>
    </>
  );
};
