import { CustomButton, DropDown, Input } from 'components';
import { DEPARTMENTTEAMFILTER } from '../../../helper';
import './departmentTeamFilter.scss';
import DatePicker from 'react-datepicker';
import { isEqual } from 'lodash';
const DepartmentTeamFilter = ({
  state,
  filterTitle,
  teamFilterSaveHandler,
  teamFilterOnChangeHandler,
}) => {
  const DEFAULTFILTERSTATE = {
    admin: '',
    noOfMembers: {},
    fromDate: null,
    toDate: null,
  };
  let isCloseButton = isEqual(state, DEFAULTFILTERSTATE);

  return (
    <div className="department-teams-filter-container">
      <div className="filter-title-wrapper">
        <div className="filter-title">{filterTitle}</div>
      </div>
      <div className="filter-input-container">
        <div className="row">
          <div className="col-lg-6">
            <Input
              label={DEPARTMENTTEAMFILTER.Admin.label}
              value={state.admin}
              onChange={(e) =>
                teamFilterOnChangeHandler('admin', e.target.value)
              }
            />
          </div>
          <div className="col-lg-6">
            <DropDown
              label={DEPARTMENTTEAMFILTER.NoOfMembers.label}
              value={state.noOfMembers}
              objectKey={DEPARTMENTTEAMFILTER.NoOfMembers.key}
              items={DEPARTMENTTEAMFILTER.NoOfMembers.memberType}
              placeholder={DEPARTMENTTEAMFILTER.NoOfMembers.placeholder}
              onClick={(e) => teamFilterOnChangeHandler('noOfMembers', e)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 filter-date-wrapper">
            <label className="filter-date-label form-label">From Date</label>
            <DatePicker
              dateFormat="MMM dd, yyyy"
              selected={state.fromDate}
              onChange={(date) => teamFilterOnChangeHandler('fromDate', date)}
              selectsStart
              placeholderText="MMM/DD/YYY"
              className="filter-custom-date"
            />
          </div>
          <div className="col-lg-6 filter-date-wrapper">
            <label className="filter-date-label form-label">To Date</label>
            <DatePicker
              dateFormat="MMM dd, yyyy"
              selected={state.toDate}
              onChange={(date) => teamFilterOnChangeHandler('toDate', date)}
              selectsEnd
              startDate={state.fromDate}
              endDate={state.toDate}
              minDate={state.fromDate}
              placeholderText="MMM/DD/YYYY"
              className="filter-custom-date"
            />
          </div>
        </div>

        <div className="row gy-3">
          <div className="col-lg-6">
            <CustomButton
              className="w-100"
              label={'Apply'}
              color="themeSecondary"
              onClick={() => teamFilterSaveHandler('apply')}
            />
          </div>
          <div className="col-lg-6">
            <CustomButton
              className="w-100"
              label={!isCloseButton ? 'Clear' : 'Cancel'}
              color="themeSecondary"
              variant="light"
              onClick={() =>
                teamFilterSaveHandler(!isCloseButton ? 'clear' : 'close')
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepartmentTeamFilter;
