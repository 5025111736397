import { CustomButton } from 'components';
export const WithDrawRequest = ({
  className,
  withdrawRequestHandler,
  withdrawId,
}) => {
  return (
    <div className={className}>
      <CustomButton
        className="outline-request-card"
        variant="textBtn"
        customPadding="0px"
        label="Withdraw Request"
        color="themeGray"
        onClick={() => withdrawRequestHandler(withdrawId)}
      />
    </div>
  );
};
