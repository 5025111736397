import { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Route, Routes, useLocation, useSearchParams } from 'react-router-dom';
import { IndividualPersonalSection } from './IndividualPersonalSection';
import IndividualProfileOrganization from './individualOrganizations/index';
import {
  CREATE_FCM_TOKEN,
  FETCH_USER_PROFILE,
  useAxiosWrapper,
} from 'services';
import TokenService from 'services/token.service';
import { CreateProfileInfo, ViewProfileInfo } from './IndividualProfileInfo';
import { getProfileText } from '../../utlils/utils';
import {
  INDIVIDUAL_DASHBOARD_NAV_LIST,
  individualInfoData,
  rightSidebarData,
} from './constants';
import { ModalPopup, NavbarBrand1, Sidebar } from 'components';
import ProfileCard from './components/profileCard';
import Invitation from './IndividualPersonalSection/individualTeams/invitation';
import './styles.scss';
import ViewApplicationListPopup from './Applications/ViewApplicationListPopup';
import { generateFcmToken } from 'firebaseConfig/generateToken';
import tokenService from 'services/token.service';
import { initializeTwilioConversations } from 'services/twilioService/twilioClient';
import IndividualGroups from './IndividualGroups';
import IndividualFeeds from './IndividualFeeds';

export const IndividualProfile = (props) => {
  const location = useLocation();
  const { currentLocale, handleLocaleChange } = props;
  const [fnWrapper] = useAxiosWrapper();
  const [isImgUploading, setImgUploading] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [leftActiveItem, setLeftActiveItem] = useState('');
  const [rightActiveItem, setRightActiveItem] = useState('');
  const [openInvitationPopup, setOpenInvitationPopup] = useState(false);
  const [isUpdateList, setIsUpdateList] = useState(false);
  const [openApplicationPopup, setOpenApplicationPopup] = useState(false);
  const [openReferencePopup, setOpenReferencePopup] = useState(false);
  const [isUserInfoUpdated, setIsUserInfoUpdated] = useState(false);
  const [searchParam] = useSearchParams();

  const loggedInUserSession = useMemo(() => tokenService.getSession(), []);

  const isProfileEditPage =
    location.pathname.includes('/individual/profile/edit/') ||
    location.pathname.includes('/individual/profile/create/');

  const setLeftSidebar = (type) => {
    setLeftActiveItem(type);
  };
  function byteToMB(bytevalue) {
    return bytevalue / 1024 ** 2;
  }

  const setRightSidebar = (type) => {
    setRightActiveItem(type);
    if (type === 'invitations') {
      setOpenInvitationPopup(true);
    }
    if (type === 'applications') {
      setOpenApplicationPopup(true);
    }
    if (type === 'reference') {
      setOpenReferencePopup(true);
    }
  };

  const onInvitationPopupClose = (isAction) => {
    setRightActiveItem('');
    setOpenInvitationPopup(false);
    setIsUpdateList(isAction);
  };

  const onApplicationPopupClose = () => {
    setRightActiveItem('');
    setOpenApplicationPopup(false);
  };
  const onReferencePopupClose = () => {
    setRightActiveItem('');
    setOpenReferencePopup(false);
  };

  const uploadProfilePictureHandler = async (e) => {
    try {
      setImgUploading(true);
      const session = JSON.parse(localStorage.getItem('session'));
      const formData = new FormData();
      if (byteToMB(e.target.files[0].size) > byteToMB(1000000)) {
        setImgUploading(false);
        toast.warning('Image size should be less than 1MB', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
        });
      } else {
        formData.append('profilePicture', e.target.files[0]);
        await axios
          .post(
            `${process.env.REACT_APP_API_BASE_URL}/upload/profilePicture/${userProfile.userId}`,
            formData,
            {
              headers: {
                'x-access-token': `${session.access_token}`,
              },
            }
          )
          .then((res) => {
            if (res.data.statusCode === 200) {
              setImgUploading(false);
              setUserProfile({
                ...userProfile,
                profilePicture: res.data.data,
              });
              toast.success(res.data.message, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
              });
            }
          })
          .catch((err) => {
            setImgUploading(false);
            toast.error(err.response.data.message, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'colored',
            });
          });
      }
    } catch (error) {
      setImgUploading(false);
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  const getUserProfile = useCallback(async () => {
    const loggedInUser = TokenService.getSession();
    const res = await fnWrapper({
      url: `${FETCH_USER_PROFILE}/${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_USER_PROFILE',
    });
    if (res.data.statusCode === 200) {
      const response = res.data.data;
      setUserProfile({
        ...response,
        profileText: getProfileText(
          response?.role === 'INDIVIDUAL'
            ? response?.name
            : response?.enterpriseName
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUserProfile();
  }, [getUserProfile]);

  useEffect(() => {
    if (isUserInfoUpdated) {
      getUserProfile();
      setIsUserInfoUpdated(false);
    }
  }, [getUserProfile, isUserInfoUpdated]);

  // create fcm token
  const createFcmToken = useCallback(async () => {
    // Call generateFcmToken this will call that function and stores fcm token in local Storage
    const generateFcmTokenInfo = await generateFcmToken();
    if (generateFcmTokenInfo) {
      let data = {
        userId: loggedInUserSession.id,
        fcmToken: generateFcmTokenInfo?.fcmToken,
        deviceName: generateFcmTokenInfo?.deviceInfo?.deviceName,
        deviceType: 'WEB_BROWSER',
      };
      await fnWrapper({
        url: `${CREATE_FCM_TOKEN}`,
        method: 'POST',
        type: 'CREATE_FCM_TOKEN',
        payload: data,
      });
    } else {
      // eslint-disable-next-line no-console
      console.log('Show error popup');
    }
    // if needed then we can update the fcm token in local storage here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loggedInUserSession) {
      createFcmToken();
    }
  }, [createFcmToken, loggedInUserSession]);

  useEffect(() => {
    if (loggedInUserSession) {
      initializeTwilioConversations(loggedInUserSession.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUserSession]);

  useEffect(() => {
    if (searchParam.get('invitationTab')) {
      setOpenInvitationPopup(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <NavbarBrand1
        navMenu={INDIVIDUAL_DASHBOARD_NAV_LIST(userProfile?.role)}
        currentLocale={currentLocale}
        handleLocaleChange={handleLocaleChange}
        userProfile={userProfile}
      />
      {isProfileEditPage ? (
        <Routes>
          <Route path="">
            <Route
              element={
                <CreateProfileInfo
                  currentLocale={currentLocale}
                  handleLocaleChange={handleLocaleChange}
                  userProfile={userProfile}
                  profilePicUploading={isImgUploading}
                  uploadProfilePicture={uploadProfilePictureHandler}
                  setIsUserInfoUpdated={setIsUserInfoUpdated}
                />
              }
              path=":type/:step"
            />
          </Route>
        </Routes>
      ) : (
        <div className="individual-dashboard-container">
          <div className="layout-container">
            <div className="layout-sub-container">
              <div className="sub-container-child">
                <ProfileCard userProfile={userProfile} />
                <div style={{ marginTop: '1.5rem' }}>
                  <Sidebar
                    sidebarData={individualInfoData}
                    activeType={leftActiveItem}
                    setActiveType={setLeftSidebar}
                  />
                </div>
              </div>
              <div className="sub-container-child">
                <Routes>
                  <Route path="">
                    <Route
                      element={
                        <CreateProfileInfo
                          userProfile={userProfile}
                          profilePicUploading={isImgUploading}
                          uploadProfilePicture={uploadProfilePictureHandler}
                          setIsUserInfoUpdated={setIsUserInfoUpdated}
                        />
                      }
                      path=""
                    />
                    <Route
                      element={
                        <CreateProfileInfo
                          userProfile={userProfile}
                          profilePicUploading={isImgUploading}
                          uploadProfilePicture={uploadProfilePictureHandler}
                          setIsUserInfoUpdated={setIsUserInfoUpdated}
                        />
                      }
                      path=":type/:step"
                    />
                    <Route
                      element={<ViewProfileInfo />}
                      exact
                      path="info/:step"
                    />
                    <Route
                      element={
                        <IndividualPersonalSection
                          userProfile={userProfile}
                          isUpdateList={isUpdateList}
                        />
                      }
                      path="personal/*"
                    />
                    <Route
                      element={
                        <IndividualProfileOrganization
                          isUpdateList={isUpdateList}
                        />
                      }
                      path="organization/*"
                    />
                    <Route element={<IndividualGroups />} path="groups/*" />
                    <Route element={<IndividualFeeds />} path="feeds" />
                  </Route>
                </Routes>
              </div>
              <div className="sub-container-child">
                <div>
                  <Sidebar
                    sidebarData={rightSidebarData}
                    activeType={rightActiveItem}
                    setActiveType={setRightSidebar}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {openInvitationPopup && (
        <ModalPopup
          open={false}
          onClose={() => onInvitationPopupClose(true)}
          title="Invitations"
          minWidth="800px"
          maxContentWidth="800px">
          <Invitation
            setOpenInvitationPopup={setOpenInvitationPopup}
            acceptRejectHandler={(isAction) => onInvitationPopupClose(isAction)}
            type={
              leftActiveItem !== 'organization'
                ? searchParam.get('invitationTab') || 'personal'
                : searchParam.get('invitationTab') || 'enterprise'
            }
          />
        </ModalPopup>
      )}
      {openApplicationPopup && (
        <ModalPopup
          open={false}
          onClose={() => onApplicationPopupClose(true)}
          title="Applications"
          minWidth="800px"
          maxContentWidth="800px">
          <ViewApplicationListPopup></ViewApplicationListPopup>
        </ModalPopup>
      )}
      {openReferencePopup && (
        <ModalPopup
          open={false}
          onClose={() => onReferencePopupClose(true)}
          title="Reference"
          minWidth="500px"
          maxContentWidth="500px">
          <div>Exciting Update Coming Soon! Stay tuned for more details.</div>
        </ModalPopup>
      )}
    </>
  );
};
