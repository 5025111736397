import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import cn from 'classname';
import { checkForSpacesInStartEnd, isValidEmail } from '../../../../../helpers';
import { INVITEMEMBERROLE } from '../helper';
import {
  Box,
  CustomButton,
  DropDown,
  Input,
  SearchBoxWithDropDown,
} from '../../../../../components';
import { isMobile } from '../../../../../utlils/isMobile';
import { CreateTeamContext } from '../../../../../context/Teams/CreateTeam/createTeamContext';

import {
  onAddNewMembers,
  onAddSearchedMembers,
  onChangeInviteMembers,
  onChangeTeamsCredential,
  onDeleteMembers,
  clearState,
  setProgressStep,
} from '../../../../../context/Teams/CreateTeam/createTeamAction';
import { getProfileText } from 'utlils/utils';
import Icon from 'utlils/Icons/Icon';

const InviteTeamMembers = ({ teamType, isEnterpriseTeam }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [searchMember, setSearchMember] = useState('');
  const [filteredMemberData, setFilterMemberData] = useState([]);
  const { createTeamState, createTeamDispatch } = useContext(CreateTeamContext);

  const createTeamsHandler = async () => {
    await setProgressStep(createTeamDispatch, 3);
    const session = JSON.parse(localStorage.getItem('session'));

    let teamMemberArray = [];
    let teamOwnerInfo = {
      memberName: createTeamState.teamOwnerName,
      designation: createTeamState.teamOwnerDesignation,
      role: createTeamState.teamOwnerRole,
      email: createTeamState.teamOwnerEmail,
      invitedByUserId: session.id,
    };

    createTeamState.teamMembers.forEach((ele) => {
      ele = {
        ...ele,
        email: ele.memberEmail,
        role: ele.role.value,
        invitedByUserId: session.id,
      };
      teamMemberArray.push(ele);
    });

    //Check for duplicates
    let hasDuplicate = false;
    const emailSet = new Set();
    teamMemberArray.forEach((member) => {
      if (emailSet.has(member?.email)) {
        hasDuplicate = true;
        return; // Exit the loop early if duplicates are found
      }
      emailSet.add(member?.email);
    });

    if (hasDuplicate) {
      toast.error('Please remove duplicate members from the list', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
      return;
    }
    if (isEnterpriseTeam) {
      navigate(
        `/individual/profile/organization/enterprise/department/${id}/teams/create/success`
      );
    } else {
      navigate(`/individual/profile/personal/teams/create/success`);
    }
    teamMemberArray = [teamOwnerInfo, ...teamMemberArray];

    let finalArray = teamMemberArray.filter(
      (ele) => ele.memberEmail !== '' && ele.memberName !== ''
    );

    let data = isEnterpriseTeam
      ? {
          userId: session.id,
          teamName: createTeamState.teamName,
          teamSizeId: createTeamState.teamSize.id,
          teamTypeId: createTeamState.teamType.id,
          topic: createTeamState.topic,
          description: createTeamState.description,
          teamCategory: 'Enterprise',
          teamMembers: finalArray,
          departmentId: id,
        }
      : {
          userId: session.id,
          teamName: createTeamState.teamName,
          teamSizeId: createTeamState.teamSize.id,
          teamTypeId: createTeamState.teamType.id,
          topic: createTeamState.topic,
          description: createTeamState.description,
          teamCategory: teamType,
          teamMembers: finalArray,
        };

    try {
      await axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/createTeam`, data, {
          headers: {
            'x-access-token': `${session.access_token}`,
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        .then(async (res) => {
          if (res.data.statusCode === 200) {
            await clearState(createTeamDispatch);
            await setProgressStep(createTeamDispatch, 4);
            if (isEnterpriseTeam) {
              navigate(
                `/individual/profile/organization/enterprise/department/${id}/teams/create/success`
              );
            } else {
              navigate(`/individual/profile/personal/teams/create/success`);
            }
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        });
    } catch (error) {
      toast.error(error, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  const onChangeTeamsMembers = async (type, value, index) => {
    const temp = [...createTeamState.teamMembers];
    temp[index][type] = value;
    let newValue = [...temp];
    await onChangeInviteMembers(createTeamDispatch, newValue);
  };

  const onAddInviteMembers = async () => {
    const temp = [...createTeamState.teamMembers];
    temp.push({
      memberName: '',
      designation: '',
      role: {},
      memberEmail: '',
    });
    await onAddNewMembers(createTeamDispatch, temp);
  };

  const onDeleteInviteMembers = async (index) => {
    const temp = [...createTeamState.teamMembers];
    temp.splice(index, 1);
    await onDeleteMembers(createTeamDispatch, temp);
  };

  const onChangeSearchMember = async (value) => {
    setSearchMember(value);
    if (value.length >= 2) {
      let data = {
        q: value,
      };
      try {
        const session = JSON.parse(localStorage.getItem('session'));
        await axios
          .post(`${process.env.REACT_APP_API_BASE_URL}/searchUsers`, data, {
            headers: {
              'x-access-token': `${session.access_token}`,
            },
          })
          .then((res) => {
            if (res.data.statusCode === 200) {
              const searchData = res.data.data;
              let finalArray = [];
              if (searchData.length) {
                finalArray = searchData
                  .map((ele) => {
                    return {
                      id: ele.id,
                      userId: ele.userId,
                      name: ele.name,
                      email: ele.user.email,
                      userName: ele.user.userName,
                      profilePicture: ele.profilePicture,
                      profileText: getProfileText(ele.name),
                    };
                  })
                  .filter((ele) => ele.userId !== session.id);
              }
              setFilterMemberData(finalArray);
            }
          })
          .catch((err) => {
            setFilterMemberData([]);
          });
      } catch (error) {
        setFilterMemberData([]);
      }
    }
  };

  const onSelectSearchMember = async (member) => {
    let searchedMmeber = {
      memberName: member.name,
      designation: '',
      role: {},
      memberEmail: member.email,
    };
    let newElement = [];
    newElement = [...createTeamState.teamMembers, searchedMmeber];
    //removed empty object and add new searched object
    let finalArray = newElement.filter(
      (ele) => ele.memberEmail !== '' && ele.memberName !== ''
    );
    await onAddSearchedMembers(createTeamDispatch, finalArray);
  };

  const disableTeamMembers = () => {
    const teamMemberError = (obj) =>
      obj.memberName.length === 0 ||
      obj.designation.length === 0 ||
      obj.memberEmail.length === 0 ||
      checkForSpacesInStartEnd(obj.memberName) ||
      checkForSpacesInStartEnd(obj.designation) ||
      !isValidEmail(obj.memberEmail) ||
      Object.keys(obj.role).length === 0;
    return createTeamState.teamMembers.some(teamMemberError);
  };

  let buttonDisabled =
    !createTeamState.teamOwnerDesignation ||
    checkForSpacesInStartEnd(createTeamState.teamOwnerDesignation) ||
    disableTeamMembers();

  useEffect(() => {
    if (createTeamState.progressStep === 1) {
      if (isEnterpriseTeam) {
        navigate(
          `/individual/profile/organization/enterprise/department/${id}/teams/create/information`
        );
      } else {
        navigate(`/individual/profile/personal/teams/create/information`);
      }
    } else {
      setProgressStep(createTeamDispatch, 2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="add-members-header mt-3">
        <div className="add-members">Your information</div>
      </div>
      <Box mt="16px">
        <Box>
          <div className={cn('add-teams-item')}>
            <div className="row">
              <div className="col-lg-6">
                <Input
                  label="Name"
                  type="text"
                  required
                  value={createTeamState.teamOwnerName}
                  disabled
                />
              </div>
              <div className="col-lg-6">
                <Input
                  label="Email"
                  type="email"
                  required
                  value={createTeamState.teamOwnerEmail}
                  disabled
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <Input
                  label="Role"
                  type="text"
                  required
                  value={createTeamState.teamOwnerRole}
                  disabled
                />
              </div>
              <div className="col-lg-6">
                <Input
                  label="Designation"
                  type="text"
                  required
                  value={createTeamState.teamOwnerDesignation}
                  onChange={(e) =>
                    onChangeTeamsCredential(
                      createTeamDispatch,
                      'teamOwnerDesignation',
                      e.target.value
                    )
                  }
                />
              </div>
            </div>
          </div>
        </Box>
      </Box>
      <Box mt="16px">
        <div className="add-members-header d-flex align-items-center justify-content-between">
          <span className="add-members">Invite Members</span>
          <div className="upload-add d-flex align-items-center justify-content-between">
            {/* <CustomButton className="w-100" variant="textBtn" color="themeGray">
              <Icon name="export" size={20} color="#929DAF" />
              Upload csv
            </CustomButton> */}
            <CustomButton
              className="w-100"
              variant="textBtn"
              onClick={onAddInviteMembers}>
              <Icon name="add" color="#0ABD00" />
              Add Member
            </CustomButton>
          </div>
        </div>
      </Box>
      <Box mt="24px">
        <SearchBoxWithDropDown
          searchInputValue={searchMember}
          searchData={filteredMemberData}
          placeholder="Search for members in your organization"
          onSelectSearchItem={onSelectSearchMember}
          onInputChangeHandler={onChangeSearchMember}
        />
      </Box>
      <Box>
        {createTeamState.teamMembers.map((item, index) => (
          <Box mb="30px" key={index}>
            <Box
              className="d-flex justify-content-between align-items-center delete-member"
              mb="20px">
              <Box size="15px">
                <div>Member {index + 1}</div>
              </Box>
              <CustomButton
                color="themeOrange"
                variant="textBtn"
                onClick={() => onDeleteInviteMembers(index)}>
                <Icon name="delete" color="#fe9320" size={20} />
                {isMobile() ? '' : 'Delete Member'}
              </CustomButton>
            </Box>
            <div
              className={cn(
                'add-teams-item',
                createTeamState.teamMembers.length > 1 && 'add-teams-border'
              )}>
              <div className="row">
                <div className="col-lg-6">
                  <Input
                    label="Name"
                    type="text"
                    required
                    value={item.memberName}
                    onChange={(e) =>
                      onChangeTeamsMembers('memberName', e.target.value, index)
                    }
                  />
                </div>
                <div className="col-lg-6">
                  <Input
                    label="Email"
                    type="email"
                    required
                    value={item.memberEmail}
                    onChange={(e) =>
                      onChangeTeamsMembers('memberEmail', e.target.value, index)
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <DropDown
                    label="Role"
                    placeholder="Select Role"
                    objectKey="label"
                    required
                    items={INVITEMEMBERROLE}
                    value={item.role}
                    onClick={(value) => {
                      onChangeTeamsMembers('role', value, index);
                    }}
                  />
                </div>
                <div className="col-lg-6">
                  <Input
                    label="Designation"
                    type="text"
                    required
                    value={item.designation}
                    onChange={(e) =>
                      onChangeTeamsMembers('designation', e.target.value, index)
                    }
                  />
                </div>
              </div>
            </div>
          </Box>
        ))}
      </Box>
      <Box
        mt="32px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        colGap="15px">
        <Box width="150px">
          <CustomButton
            className="w-100"
            label="Save"
            disabled={buttonDisabled}
            color="themeSecondary"
            onClick={async () => {
              createTeamsHandler();
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default InviteTeamMembers;
