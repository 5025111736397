import styled from 'styled-components';

export const SidebarWrapper = styled.div`
  background: white;
  border-radius: 16px;
  box-shadow: -1px 0px 10px 3px rgb(0 0 0 / 3%);
  min-height: 100px;
  .activeButton {
    border-left: 3px solid #0abd00;
    font-weight: 600;
    color: #17191d;
  }
  .disActiveButton {
    font-weight: 500;
    color: #404f67;
  }
  .activeSubButton {
    background-color: #e2ffe0;
    color: #0abd00;
    padding: 15px 20px;
  }
  .sidebar {
    &__wrapper {
      padding: 16px 0;
      &__container {
        position: relative;
        padding: 16px 0;
        .icon-dropdown {
          position: absolute;
          top: 15px;
          right: 20px;
          cursor: pointer;
        }
        &__button {
          font-size: 16px;
          padding-left: 25px;
          cursor: pointer;
        }
        &__subData {
          margin-left: 20px;
          margin-top: 10px;
          margin-bottom: -10px;
          &__sub-button {
            font-size: 16px;
            padding: 12px 20px;
            width: 90%;
            cursor: pointer;
          }
        }
      }
    }
  }
`;
