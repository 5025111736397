import styled from 'styled-components';
import { Box, CustomButton, DropDown, ModalPopup } from 'components';
import { useState } from 'react';
import { useAxiosWrapper } from 'services';

const RemoveMemberWrapper = styled.div`
  .withdraw-popup-layout {
    padding: 40px;
    text-align: center;
  }
  .withdraw-button {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    @media (max-width: 576px) {
      flex-direction: column;
    }
  }
`;

export const RemoveMembers = ({
  totalMembers,
  totalAdmins,
  isPopupOpen = false,
  closePopupHandler,
  removeMembersHandler,
  selectedRows = [],
}) => {
  const [fnWrapper] = useAxiosWrapper();
  const [showChangeAdmin, setShowChangeAdmin] = useState(false);
  const [newAdmin, setNewAdmin] = useState(null);
  const selectedMember = selectedRows.map((ele) => ({
    name: ele.memberName.props.label,
    role: ele.role.props.status.label,
  }));
  const proceedToRemoveMember = async () => {
    const adminList = selectedMember.filter(
      (member) => member.role === 'Admin'
    );
    if (totalAdmins === adminList?.length) {
      setShowChangeAdmin(true);
      return;
    }
    closePopupHandler();
    await removeMembersHandler();
  };
  const proceedToChangeAdmin = async () => {
    let data = {
      items: [{ memberId: newAdmin?.id, role: 'ADMIN' }],
    };
    const res = await fnWrapper({
      url: `changeRole`,
      method: 'PUT',
      type: 'CHANGE_TEAM_MEMBER_ROLE',
      payload: data,
    });
    if (res.data.statusCode === 200) {
      await removeMembersHandler();
      closePopupHandler();
    }
  };

  return (
    <RemoveMemberWrapper>
      {isPopupOpen && (
        <ModalPopup open={false} hasTitle={false}>
          <div className="withdraw-popup-layout">
            <div>
              <span>
                Are you sure you want to Remove{' '}
                <strong>{`${selectedMember
                  .map((member) => member.name)
                  .toString()}`}</strong>{' '}
                from your Team?
              </span>
            </div>
            <div className="withdraw-button">
              <CustomButton
                label="Yes"
                customPadding="14px 20px"
                variant="outline"
                onClick={proceedToRemoveMember}
              />
              <CustomButton
                label="No"
                customPadding="14px 20px"
                variant="textBtn"
                onClick={closePopupHandler}
              />
            </div>
          </div>
        </ModalPopup>
      )}
      {showChangeAdmin ? (
        <ModalPopup open={false} onClose={closePopupHandler}>
          <div className="withdraw-popup-layout">
            <Box>
              <Box mb="30px">
                <span>
                  Looks like you are trying to remove all the admins from this
                  Team. Please select another admin before removing
                </span>
              </Box>
              <Box mt="30px" mb="30px" p="20px">
                <DropDown
                  items={totalMembers}
                  objectKey="memberName"
                  placeholder="Select"
                  onClick={(item) => {
                    setNewAdmin(item);
                  }}
                  value={newAdmin}
                />
              </Box>
            </Box>
            <div className="withdraw-button">
              <CustomButton
                label="Done"
                customPadding="16px 52px"
                disabled={!newAdmin}
                onClick={proceedToChangeAdmin}
              />
            </div>
          </div>
        </ModalPopup>
      ) : null}
    </RemoveMemberWrapper>
  );
};
