import {
  ModalPopup,
  PublicProfileCard,
  CustomButton,
  Input,
  ScrollableDiv,
  UploadImage,
} from 'components';
import { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  FETCH_BASIC_DETAILS,
  FETCH_INDIVIDUAL_PUBLIC_USER_DETAILS,
  UPDATE_USER_PROFILE,
  CREATE_USER_CONNECTION,
  useAxiosWrapper,
} from 'services';
import BgImg from 'assests/images/bgImgPublic.png';
import TokenService from 'services/token.service';
import Icon from 'utlils/Icons/Icon';
import RadioGroup from 'pages/IndividualDashboard/components/radioGroup';
import { PUBLIC_PRIVATE_TOGGLE_LABEL } from '../../constants';
import cn from 'classname';
import { InviteIndividual } from '../InviteIndividual';
import { toast } from 'react-toastify';

const EditProfileItems = [
  {
    id: 1,
    label: 'Name',
    value: '',
  },
  {
    id: 2,
    label: 'Title',
    value: '',
  },
];

const radioLabels = [
  { text: 'Public', value: 'public' },
  { text: 'Private', value: 'private' },
];

export const IndividualPublicCard = ({
  onToggleHandler,
  taskType,
  updateOnProfileEdit,
  isContactInfoEnabled,
  userRole,
}) => {
  const isIndividualUser = userRole === 'INDIVIDUAL';

  const [fnWrapper, context] = useAxiosWrapper();
  const [isLoggedInUser, setIsLoggedInUser] = useState(false);
  const [publicProfileData, setPublicProfileData] = useState({});
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [isInvitePopupOpen, setIsInvitePopupOpen] = useState(false);
  const [isContactPopUpOpen, setIsContactPopupOpen] = useState(false);
  const [radioType, setRadioType] = useState('public');
  const [editItems, setEditItems] = useState(EditProfileItems);
  const [displayCoverImage, setDisplayCoverImage] = useState('');
  const [updateProfilePicture, setUpdateProfilePicture] = useState('');
  const [updateBgImage, setUpdateBgImage] = useState('');
  const [connectionDetails, setConnectionDetails] = useState({
    connectionStatus: null,
  });
  const { userId } = useParams();

  useEffect(() => {
    const loggedInUser = TokenService.getSession();
    setIsLoggedInUser(loggedInUser.id === userId);
  }, [userId]);

  const editBtnHandler = (value) => {
    setIsEditPopupOpen(value);
  };

  const inviteBtnHandler = (value) => {
    setIsInvitePopupOpen(value);
  };

  const connectBtnHandler = () => {
    createUserConnection('CONNECT');
  };

  const onClickContactHandler = (value) => {
    setIsContactPopupOpen(value);
  };

  const onLinkedInClick = (link) => {
    let linkedInLinkHasHttps = link.split('//')[0] === 'https:';
    let href = !linkedInLinkHasHttps ? `https://${link}` : link;
    window.open(href, '_blank');
  };

  const handleEditItems = (event, editItemId) => {
    let temp = editItems.map((item) => {
      if (item.id === editItemId) {
        return {
          ...item,
          value: event.target.value,
        };
      } else {
        return { ...item };
      }
    });
    setEditItems(temp);
  };

  const handleRadioButtons = (e) => {
    const { value } = e.target;
    setRadioType(value);
  };

  const optionsHandler = (data) => {
    switch (data.id) {
      case 0:
        createUserConnection('DISCONNECT');
        break;
      case 1:
        if (data.label.toLowerCase() === 'follow') {
          createUserConnection('FOLLOW');
        } else {
          createUserConnection('UNFOLLOW');
        }
        break;
      case 2:
        break;
      default:
        break;
    }
  };

  const handleAvatar = (value) => {
    // console.log(value);
    setUpdateProfilePicture(value);
  };
  function byteToMB(byteValue) {
    return byteValue / 1024 ** 2;
  }
  const handleCoverImage = (event) => {
    // console.log('Cover image clicked');
    if (
      event.target.files &&
      event.target.files[0] &&
      byteToMB(event.target.files[0].size) < byteToMB(1000000)
    ) {
      setDisplayCoverImage(URL.createObjectURL(event.target.files[0]));
      setUpdateBgImage(event.target.files[0]);
    } else {
      toast.warning('Image size should be less than 1MB', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }
  };

  const getPublicProfileDetails = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_BASIC_DETAILS}/${userId}`,
      method: 'GET',
      type: 'FETCH_BASIC_DETAILS',
    });

    if (res.data.statusCode === 200) {
      const result = res.data.data;
      const detailsData = {
        userName: result.name,
        email: result.email,
        contact: result.contact,
        designation: result.title === null ? '' : result.title,
        profilePicture:
          result.profilePicture !== null ? result.profilePicture : '',
        bgImage:
          result.coverProfilePicture !== null
            ? result.coverProfilePicture
            : BgImg,
        location: result.country === null ? 'N/A' : result.country,
        languages: result.languages === null ? 'N/A' : result.languages,
        linkTeamsUpLink: !result.linkTeamsUpLink
          ? 'N/A'
          : result.linkTeamsUpLink,
        infoItems: [
          {
            label: 'Location',
            value:
              result?.country !== null
                ? result?.city === null
                  ? result?.country
                  : `${result?.country},${result?.city}`
                : 'N/A',
            iconName: 'location',
          },
          {
            label: 'Link',
            value: !result.link ? 'N/A' : result.link,
            iconName: 'global',
          },
          {
            label: 'Languages',
            value: result.languages === null ? 'N/A' : result.languages,
            iconName: 'language',
          },
          {
            label: 'Contact info',
            onClick: () => onClickContactHandler(true),
            value: 'Contact Info',
            iconName: 'lock-two',
            iconColor: '#219fff',
            style: {
              color: '#219fff',
              textDecoration: 'underline',
              cursor: 'pointer',
            },
          },
        ],
      };

      if (result?.linkedInLink) {
        detailsData?.infoItems.push({
          label: 'LinkedIn account link',
          onClick: () => onLinkedInClick(result.linkedInLink),
          value: 'LinkedIn Account',
          iconName: 'linkedIn',
          iconColor: '#219fff',
          style: {
            color: '#219fff',
            textDecoration: 'underline',
            cursor: 'pointer',
          },
        });
      }
      setPublicProfileData(detailsData);
      setDisplayCoverImage(detailsData.bgImage);
      editItems[0].value = detailsData.userName;
      editItems[1].value = detailsData.designation;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateUserProfile = async () => {
    const loggedInUser = TokenService.getSession();

    let formData = new FormData();
    for (var item of editItems) {
      formData.append(item.label.toLowerCase(), item.value);
    }
    formData.append('coverImage', updateBgImage);
    formData.append('profilePicture', updateProfilePicture);
    formData.append('contactInfoEnabled', radioType === 'public');

    const res = await fnWrapper({
      url: `${UPDATE_USER_PROFILE}/${loggedInUser.id}`,
      method: 'PUT',
      type: 'UPDATE_USER_PROFILE',
      payload: formData,
    });
    if (res.data.statusCode === 200) {
      setIsEditPopupOpen(false);
      getPublicProfileDetails();
      updateOnProfileEdit(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const createUserConnection = async (connectionType) => {
    const connectWith = {
      connectWithId: userId,
      type: connectionType,
    };

    const res = await fnWrapper({
      url: `${CREATE_USER_CONNECTION}`,
      method: 'POST',
      type: 'CREATE_USER_CONNECTION',
      payload: connectWith,
    });
    if (res.data.statusCode === 200) {
      //console.log(res.data.data);
      getConnectionDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const getConnectionDetails = useCallback(async () => {
    const loggedInUser = TokenService.getSession();
    const res = await fnWrapper({
      url: `${FETCH_INDIVIDUAL_PUBLIC_USER_DETAILS}?profileUserId=${userId}&loggedInUserId=${loggedInUser.id}`,
      method: 'GET',
      type: 'FETCH_USER_DETAILS',
    });

    if (res.data.statusCode === 200) {
      const result = res.data.data;
      const detailsData = {
        connectionStatus: result?.connectionStatus ?? null,
        followStatus: result?.followStatus ?? null,
      };
      setConnectionDetails(detailsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPublicProfileDetails();
  }, [getPublicProfileDetails]);

  useEffect(() => {
    getConnectionDetails();
  }, [getConnectionDetails]);

  useEffect(() => {
    setRadioType(isContactInfoEnabled ? 'public' : 'private');
  }, [isContactInfoEnabled]);

  const obj = {
    bgColor: '#FE932033',
    textColor: '#FE9320',
    name: 'NOVICE',
    iconName: 'people',
    iconColor: '#FE9320',
  };

  const connectionButtonStatus = (status) => {
    if (status === null) {
      return {
        label: 'Connect',
        bgColor: '#fff',
        color: '#219fff',
        iconName: 'profile-add',
        onClick: () => connectBtnHandler(),
        disabled: false,
      };
    } else if (status === 'PENDING') {
      return {
        label: 'Pending',
        bgColor: '#fff',
        color: '#929DAF',
        iconName: 'profile-add',
        disabled: true,
      };
    } else if (status === 'ACCEPTED') {
      return {
        label: 'Connected',
        bgColor: '#fff',
        color: '#929DAF',
        iconName: 'profile-add',
        disabled: true,
      };
    }
  };

  const btns = !isLoggedInUser
    ? [
        isIndividualUser &&
          connectionButtonStatus(connectionDetails?.connectionStatus),
        {
          label: 'Invite',
          bgColor: '#fff',
          color: '#219fff',
          iconName: 'add',
          onClick: () => inviteBtnHandler(!isInvitePopupOpen),
        },
      ].filter(Boolean)
    : [];

  const editBtn = [
    {
      label: 'Edit',
      bgColor: '#fff',
      color: '#219fff',
      iconName: 'edit',
      buttonStyle: {
        padding: '0 20px',
      },
      onClick: () => {
        editBtnHandler(!isEditPopupOpen);
        updateOnProfileEdit(false);
      },
    },
  ];

  const defaultMenuItems = [
    {
      id: 0,
      label: 'Remove Connection',
    },
    {
      id: 1,
      label: connectionDetails?.followStatus === null ? 'Follow' : 'Unfollow',
    },
    {
      id: 2,
      label: 'Message',
    },
  ];

  const menuItems = () => {
    let temp = defaultMenuItems;
    if (connectionDetails?.connectionStatus === 'ACCEPTED') {
      return temp;
    } else {
      temp = temp.filter((item) => item.id !== 0);
      return temp;
    }
  };

  return (
    <>
      <PublicProfileCard
        isLoggedInUser={isLoggedInUser}
        bgImgSrc={publicProfileData.bgImage}
        level={obj}
        profileImgSrc={publicProfileData.profilePicture}
        profileIcon="user-icon"
        title={publicProfileData.userName}
        subTitle={publicProfileData.designation}
        buttons={
          taskType === PUBLIC_PRIVATE_TOGGLE_LABEL.PRIVATE ? editBtn : btns
        }
        infoItems={publicProfileData.infoItems}
        dropMenuItems={menuItems()}
        taskType={taskType}
        onToggleHandler={onToggleHandler}
        taskLabels={PUBLIC_PRIVATE_TOGGLE_LABEL}
        optionsHandler={optionsHandler}
        userRole={userRole}
      />
      {/* contact Info pop up */}
      {isContactPopUpOpen && (
        <ModalPopup
          open={false}
          onClose={() => setIsContactPopupOpen(false)}
          hasCloseIcon
          hasTitle
          title="Contact Info">
          <div className="popup-class">
            <div>
              {isContactInfoEnabled ? (
                <div className="public-contact-class">
                  {publicProfileData?.email !== null &&
                    publicProfileData?.email !== '' && (
                      <div className="public-info-style">
                        <Icon name="sms" />
                        <span>{publicProfileData.email}</span>
                      </div>
                    )}
                  {publicProfileData?.contact !== null && (
                    <div className="public-info-style">
                      <Icon name="phone" />
                      <span>{publicProfileData.contact}</span>
                    </div>
                  )}
                </div>
              ) : (
                <div className="private-contact-class">
                  <Icon name="lock-two" />
                  <p>
                    This info is set as confidential. Other’s can not view it.
                  </p>
                </div>
              )}
            </div>
          </div>
        </ModalPopup>
      )}
      {/* invite pop pop up */}
      {isInvitePopupOpen && (
        <ModalPopup
          open={false}
          onClose={() => setIsInvitePopupOpen(false)}
          hasTitle
          minWidth={'800px'}
          maxContentWidth={'800px'}
          title="Invite">
          <InviteIndividual
            userId={userId}
            onClose={() => setIsInvitePopupOpen(false)}
          />
        </ModalPopup>
      )}
      {/* edit button pop up */}
      {isEditPopupOpen && (
        <ModalPopup
          open={false}
          onClose={() => setIsEditPopupOpen(false)}
          title="Edit Profile"
          hasTitle>
          <div className="popup-class">
            {/* <div className="popup-header">
            <p>Edit Profile</p>
            <div
              className="close-cross"
              onClick={() => setIsEditPopupOpen(!isEditPopupOpen)}>
              <Icon name="cross" color="#040D20" className="cross-button" />
            </div>
          </div> */}
            <ScrollableDiv scrollOnHeight="500px" scrollOnHeightMob="350px">
              <div>
                {editItems.map((item, index) => {
                  return (
                    <div className="edit-items" key={index}>
                      <Input
                        label={item.label}
                        value={item.value}
                        onChange={(e) => handleEditItems(e, item.id)}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="contact-checkbox">
                <p>Contact Info</p>
                <div>
                  <RadioGroup
                    isBoldLabel={false}
                    currChecked={radioType}
                    labels={radioLabels}
                    handleChange={handleRadioButtons}
                  />
                </div>
              </div>
              <div className="bg-image-upload">
                <span className="image-upload-span">Upload Cover Image</span>
                <div className="bg-image-container">
                  <div
                    className={cn(
                      displayCoverImage !== ''
                        ? 'bg-image-preview'
                        : 'bg-image-icon'
                    )}>
                    {displayCoverImage !== '' ? (
                      <img src={displayCoverImage} alt="" />
                    ) : (
                      <Icon name="add" />
                    )}
                  </div>
                  <div className="upload-image-style">
                    <label
                      className="upload-image-label-style"
                      htmlFor="bg-input">
                      <span>Upload</span>
                      <Icon name={'export'} color="#219fff" />
                    </label>
                    <input
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="bg-input"
                      onChange={handleCoverImage}
                      type="file"
                    />
                  </div>
                </div>
              </div>
              <div className="image-upload">
                <span className="image-upload-span">
                  Upload Profile Picture
                </span>
                <UploadImage
                  iconName="gallery-add"
                  setImage={handleAvatar}
                  currentImage={publicProfileData.profilePicture}
                />
              </div>
            </ScrollableDiv>
            <div className="pop-up-btn">
              <CustomButton
                label="Save"
                onClick={() => updateUserProfile()}
                loading={context.busy.status}
                customPadding="16px 30px"
              />
            </div>
          </div>
        </ModalPopup>
      )}
    </>
  );
};
