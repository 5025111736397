import { Box } from '../box';
import './index.scss';
import { dummyFn } from '../../utlils/utils';
import Icon from '../../utlils/Icons/Icon';
import cn from 'classname';

export const CustomCheckBox = ({
  check = false,
  onClick,
  disabled = false,
  onCheckBg = 'rgba(33, 159, 255, 0.2)',
  tickColor = '#219FFF',
  customClass,
  checkBorder = '1.5px solid #FFFFFF',
  title,
}) => {
  return (
    <div
      className={cn('custom-check', customClass)}
      onClick={!disabled ? onClick : dummyFn}>
      <Box
        width="30px"
        cursor={disabled ? 'not-allowed' : 'pointer'}
        height="30px"
        bRadius="5px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        border={check ? checkBorder : '1.5px solid #CCD2DD'}
        bg={check ? onCheckBg : '#FFFFFF'}>
        {check && (
          <Icon
            name="tickIcon"
            color={tickColor}
            className="tick-icon"
            size={18}
          />
        )}
      </Box>
      {title && <div className="custom-check__title">{title}</div>}
    </div>
  );
};
