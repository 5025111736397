import './teamProfileCard.scss';
import { CustomButton } from 'components';
import Icon from 'utlils/Icons/Icon';
import { useNavigate, useParams } from 'react-router-dom';

const UserProfileIcon = ({ profilePicture, profileText }) => {
  return (
    <>
      {profilePicture !== null && profilePicture !== '' ? (
        <div className="profile-round-img">
          <img src={profilePicture} alt="" className="prof-img" />
        </div>
      ) : (
        <div className="profile-round-img">
          <span className="prof-txt">{profileText}</span>
        </div>
      )}
    </>
  );
};

export const TeamProfileCard = ({ teamInfo, userProfile }) => {
  const navigate = useNavigate();
  const { teamId } = useParams();
  return (
    <div>
      <div className="profile-card-container">
        {teamInfo.profilePicture !== null && teamInfo.profilePicture !== '' ? (
          <div className="team-round-img">
            <img src={teamInfo.profilePicture} alt="" className="team-img" />
            <UserProfileIcon
              profilePicture={userProfile.profilePicture}
              profileText={userProfile.profileText}
            />
          </div>
        ) : (
          <div className="team-round-img">
            <Icon name="people" size={40} color="#404F67" />
            <UserProfileIcon
              profilePicture={userProfile.profilePicture}
              profileText={userProfile.profileText}
            />
          </div>
        )}

        <div className="profile-card">
          <p className="profile-name">{teamInfo?.teamName}</p>
          <div className="profile-btn-container">
            <CustomButton
              variant="light"
              color="themeGray"
              onClick={() => navigate(`/public/team/${teamId}/details`)}
              className="view-btn">
              View Profile
              <Icon name="export-2" color="#404F67" size={18} />
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
};
