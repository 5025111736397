import styled from 'styled-components';

export const MiHintAdminDashBoardWrapper = styled.div`
  font-family: Poppins;
  .waitlist-toggle-section {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .toggle-text {
      font-size: 26px;
      font-weight: 600;
      line-height: 39px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
  .waitlist-text {
    text-align: center;
    padding: 10px;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  .enabled {
    background: #0abd001a;
    color: #0abd00;
  }

  .disabled {
    background: #404f671a;
  }

  .header {
    display: flex;
    margin: 20px 5px 0px 5px;
    gap: 10px;

    .search-wrapper {
      flex: 18%;
    }

    .add-icon {
      position: relative;
      top: 1px;
    }

    @media (max-width: 600px) {
      flex-direction: column;
    }

    .accept-all-btn {
      height: 100%;
    }
  }

  .search-result-nill {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    color: #929daf;
  }
`;
