import React, { useState } from 'react';
import { PublicProfileCardWrapper } from './style';
import { ToggleSwitch } from 'components/Switch';
import Icon from 'utlils/Icons/Icon';
import { CustomButton } from 'components/button/button';
import { isMobile } from 'utlils';
import { ReactComponent as ThreeDots } from 'assests/images/three-dots.svg';
import CommonDropDown from 'pages/enterpriseDashboard/components/commonDropDown';

export const PublicProfileCard = ({
  isLoggedInUser,
  bgImgSrc,
  profileImgSrc = '',
  profileIcon,
  level,
  title,
  subTitle,
  subIcon = '',
  buttons,
  infoItems,
  optionsHandler,
  dropMenuItems,
  taskType = '',
  onToggleHandler,
  taskLabels,
}) => {
  const [isDotsActive, setIsDotsActive] = useState(false);

  const dotsHandler = () => {
    setIsDotsActive(!isDotsActive);
  };

  return (
    <PublicProfileCardWrapper
      levelBgColor={level.bgColor}
      bgImgSrc={bgImgSrc}
      numBtns={buttons.length}
      isDropMenu={dropMenuItems && dropMenuItems.length > 0}
      levelTextColor={level.textColor}
      isDotsActive={isDotsActive}>
      <div className="upper-image-container">
        {isLoggedInUser && (
          <div className="toggle-btn">
            <ToggleSwitch
              activeLabel={taskType}
              onClick={onToggleHandler}
              toggleLabel={[taskLabels.PUBLIC, taskLabels.PRIVATE]}
              width={isMobile() ? '11rem' : '14rem'}
            />
          </div>
        )}
      </div>
      <div className="content-container">
        <div className="sub-container">
          <div className="level-wrapper">
            <div className="level--sub-wrapper">
              <div className="profile-img-wrapper">
                {profileImgSrc && profileImgSrc.length > 0 ? (
                  <img alt="" src={profileImgSrc} />
                ) : (
                  <div className="profile-icon">
                    <Icon name={profileIcon} color="#404F67" size={40} />
                  </div>
                )}
              </div>
              <div className="level-btn">
                {level.iconName && level.iconName !== '' && (
                  <Icon name={level.iconName} color={level.iconColor} />
                )}
                <span>{level.name}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="sub-container">
          <div className="info-wrapper">
            <div className="upper-half">
              <div className="title-class">
                <div className="title-name">{title}</div>
                <div className="sub-title-wrapper">
                  {subIcon && subIcon.length !== 0 && (
                    <Icon name={subIcon} color="#404F67" />
                  )}
                  <div className="sub-title-name">{subTitle}</div>
                </div>
              </div>
              <div
                className={
                  taskType === taskLabels.PRIVATE ? '' : 'buttons-wrapper'
                }>
                <>
                  {buttons &&
                    buttons.length > 0 &&
                    buttons.map((btn, index) => (
                      <div style={{ width: '100%' }} key={index}>
                        <CustomButton
                          key={index}
                          className="w-100"
                          customPadding="10px 14px"
                          color={
                            btn?.color === '#219fff'
                              ? 'themeSecondary'
                              : btn.color === '#ff5157'
                              ? 'themeTertiary'
                              : btn?.color === '#e2ffe0'
                              ? 'themePrimary'
                              : 'themeGray'
                          }
                          variant="outline"
                          onClick={() => {
                            if (typeof btn?.onClick === 'function') {
                              btn.onClick();
                            }
                          }}
                          disabled={btn?.disabled}>
                          <Icon name={btn?.iconName} color={btn?.color} />
                          {btn?.label}
                        </CustomButton>
                      </div>
                    ))}
                  {dropMenuItems &&
                    dropMenuItems.length !== 0 &&
                    taskType !== taskLabels.PRIVATE &&
                    !isLoggedInUser && (
                      <div style={{ position: 'relative' }}>
                        <div
                          className="options-wrapper"
                          onClick={() => dotsHandler()}>
                          <ThreeDots
                            color={isDotsActive ? '#219fff' : '#c4c4c4'}
                            alt=""
                          />
                        </div>
                        <CommonDropDown
                          dropItem={dropMenuItems}
                          active={isDotsActive}
                          objectKey="label"
                          onClick={(data) => {
                            optionsHandler(data);
                          }}
                          top="60px"
                          right={
                            dropMenuItems[0].id === 0 && !isMobile()
                              ? '-90px'
                              : '-42px'
                          }
                          className={
                            dropMenuItems[0].id === 0 && !isMobile()
                              ? 'wide'
                              : ''
                          }
                        />
                      </div>
                    )}
                </>
              </div>
            </div>
            <div className="lower-half">
              {infoItems?.map((item, index) => (
                <div className="info-item" key={index}>
                  <Icon
                    name={item.iconName}
                    color={item.iconColor ? item.iconColor : '#929DAF'}
                  />
                  <div
                    onClick={item.onClick}
                    style={item.style ? item.style : null}>
                    {item.value}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </PublicProfileCardWrapper>
  );
};
