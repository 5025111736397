import { useState } from 'react';
import Location from './Components/Location';
import { useCallback } from 'react';
import { GET_ENTERPRISE_LOCATION, useAxiosWrapper } from 'services';
import tokenService from 'services/token.service';
import { useEffect } from 'react';
import { Loader } from 'components';
import { sortLocations } from '../../utils';
import { Country } from 'country-state-city';
import NoDataImg from '../../../../../assests/images/nodata.png';

const ViewEnterpriseLocation = () => {
  const [locations, setLocations] = useState([]);
  const [fnWrapper, { busy }] = useAxiosWrapper();

  const loadEnterpriseLocation = useCallback(async () => {
    const res = await fnWrapper({
      url: `${GET_ENTERPRISE_LOCATION}?userId=${tokenService.getSession().id}`,
      method: 'GET',
      type: 'GET_ENTERPRISE_LOCATION',
    });
    if (res.data.statusCode === 200) {
      const data = res?.data?.data;
      if (data.length > 0) {
        setLocations(
          data.sort(sortLocations).map((address) => ({
            ...address,
            uid: address?.id,
            country: Country.getCountryByCode(address?.country)?.name,
            state: address?.state,
            city: address?.city,
          }))
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadEnterpriseLocation();
  }, [loadEnterpriseLocation]);

  if (busy?.status === true) {
    return (
      <div>
        <Loader loading={true} />
      </div>
    );
  }

  return (
    <div>
      {locations.length === 0 ? (
        <div className="empty-data-section">
          <img
            src={NoDataImg}
            alt="No Data"
            className="empty-data-section--img"
          />
          <div className="empty-data-section--text">No data available</div>
        </div>
      ) : (
        <div>
          {locations?.map((address, index) => (
            <Location key={index} address={address} />
          ))}
        </div>
      )}
    </div>
  );
};

export default ViewEnterpriseLocation;
