import Icon from 'utlils/Icons/Icon';
import {
  Box,
  CustomCheckBox,
  Input,
  MonthYearPicker,
  Textarea,
} from 'components';

export const ProfItem = ({ data, index, onDelete, onInputChangeHandler }) => {
  return (
    <div className="row">
      {index !== 0 && (
        <Box
          cursor="pointer"
          display="flex"
          justifyContent="space-between"
          alignItems="center">
          <div
            style={{ width: '90%', borderBottom: '1px solid #929DAF' }}></div>
          <Icon name="delete" onClick={() => onDelete(index)} />
        </Box>
      )}
      <div className="row">
        <div className="col-md-6 col-lg-6 col-xl-4 col-12">
          <Box mt="10px">
            <Input
              label="Organisation Name"
              iconLeft={true}
              iconName="building"
              placeholder="Name of the organisation"
              type="text"
              required
              onChange={(e) =>
                onInputChangeHandler(e.target.value, index, 'companyName')
              }
              value={data?.companyName}
            />
          </Box>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-4 col-12">
          <Box mt="10px">
            <Input
              label="Designation"
              placeholder="Enter your Designation"
              type="text"
              required
              onChange={(e) =>
                onInputChangeHandler(e.target.value, index, 'role')
              }
              value={data?.role}
            />
          </Box>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-4 col-12">
          <Box mt="10px">
            <Input
              label="Location"
              iconLeft={true}
              iconName="location"
              placeholder="Location"
              type="text"
              required
              onChange={(e) =>
                onInputChangeHandler(e.target.value, index, 'location')
              }
              value={data?.location}
            />
          </Box>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-4 col-12">
          <Box
            display="flex"
            width="100%"
            alignItems="base"
            colGap="10px"
            mt="10px">
            <MonthYearPicker
              label={'From'}
              required
              onDateChangeHandler={(date, type) => {
                onInputChangeHandler(date, index, `from-${type}`);
              }}
              index={index}
              selected={data?.from}
            />
          </Box>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-4 col-12">
          {!data?.isPersue && (
            <Box
              display="flex"
              width="100%"
              alignItems="base"
              colGap="10px"
              mt="10px">
              <MonthYearPicker
                label={'To'}
                required
                onDateChangeHandler={(date, type) => {
                  onInputChangeHandler(date, index, `to-${type}`);
                }}
                index={index}
                selected={data?.to}
              />
            </Box>
          )}
        </div>
        <div className="col-md-6 col-lg-6 col-xl-4 col-12">
          <Box height="66px">
            <Box display="flex" alignItems="center" colGap="7px" mt="50px">
              <CustomCheckBox
                onCheckBg="white"
                check={data.isPersue}
                tickColor="#0abd00"
                onClick={(e) =>
                  onInputChangeHandler(e.target.value, index, 'isPersue')
                }
                checkBorder="1px solid #0abd00"
              />
              <Box ml="20px">
                <span className="label">I am currently working here</span>
              </Box>
            </Box>
          </Box>
        </div>
      </div>
      <Box mb="40px">
        <Textarea
          rows={4}
          cols={50}
          required
          label="Describe your Roles/Responsibilities"
          value={data?.description}
          onChange={(e) =>
            onInputChangeHandler(e.target.value, index, 'description')
          }
        />
      </Box>
    </div>
  );
};
