import styled from 'styled-components';

export const SentRequestsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;

  .sent-requests-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;
