export const getEnterprisePublicLeftSideData = (
  enterpriseId,
  profileSettings
) => {
  let allInfo = [
    {
      type: 'details',
      iconName: 'text-align-left',
      title: 'Details',
      url: `/public/enterprise/${enterpriseId}/details`,
      hasSubMenu: false,
    },
    {
      type: 'teams',
      iconName: 'people',
      title: 'Teams',
      url: `/public/enterprise/${enterpriseId}/teams`,
      hasSubMenu: false,
    },
    {
      type: 'projects',
      iconName: 'project',
      title: 'Projects',
      url: `/public/enterprise/${enterpriseId}/projects`,
      hasSubMenu: false,
    },
    {
      type: 'people',
      iconName: 'user',
      title: 'People',
      url: `/public/enterprise/${enterpriseId}/people`,
      hasSubMenu: false,
    },
    {
      type: 'publications',
      iconName: 'book',
      title: 'Publications',
      url: `/public/enterprise/${enterpriseId}/publications`,
      hasSubMenu: false,
    },
    {
      type: 'career',
      iconName: 'brief-case',
      title: 'Career',
      url: `/public/enterprise/${enterpriseId}/career`,
      hasSubMenu: false,
    },
  ];

  if (!profileSettings?.isShowTeams) {
    allInfo = allInfo.filter((ele) => ele.type !== 'teams');
  }

  if (!profileSettings?.isShowProjects) {
    allInfo = allInfo.filter((ele) => ele.type !== 'projects');
  }

  if (!profileSettings?.isShowPeople) {
    allInfo = allInfo.filter((ele) => ele.type !== 'people');
  }
  return allInfo;
};
