import { CustomButton, DropDown, Input } from '../../../../components';
import { TEAMFILTERS } from '../../IndividualPersonalSection/individualTeams/helper';
import './teamFilter.scss';
import DatePicker from 'react-datepicker';
const TeamsFilter = ({
  state,
  filterTitle,
  teamFilterSaveHandler,
  teamFilterOnChangeHandler,
}) => {
  return (
    <div className="teams-filter-container">
      <div className="filter-title-wrapper">
        <div className="filter-title">{filterTitle}</div>
      </div>
      <div className="filter-input-container">
        <div className="row">
          <div className="col-lg-6">
            <Input
              label={TEAMFILTERS.Team.label}
              value={state.filteredTeamName}
              onChange={(e) =>
                teamFilterOnChangeHandler('filteredTeamName', e.target.value)
              }
            />
          </div>
          <div className="col-lg-6">
            <DropDown
              label={TEAMFILTERS.Date.label}
              value={state.filteredDate}
              objectKey={TEAMFILTERS.Date.key}
              items={TEAMFILTERS.Date.dateType}
              placeholder={TEAMFILTERS.Date.placeholder}
              onClick={(e) => teamFilterOnChangeHandler('filteredDate', e)}
            />
          </div>
        </div>
        {state.filteredDate.value === 'CD' && (
          <div className="row">
            <div className="col-lg-6 filter-date-wrapper">
              <label className="filter-date-label form-label">
                Select From Date
              </label>
              <DatePicker
                dateFormat="MMM dd, yyyy"
                selected={state.fromDate}
                onChange={(date) => teamFilterOnChangeHandler('fromDate', date)}
                selectsStart
                placeholderText="From"
                className="filter-custom-date"
              />
            </div>
            <div className="col-lg-6 filter-date-wrapper">
              <label className="filter-date-label form-label">
                Select To Date
              </label>
              <DatePicker
                dateFormat="MMM dd, yyyy"
                selected={state.toDate}
                onChange={(date) => teamFilterOnChangeHandler('toDate', date)}
                selectsEnd
                startDate={state.fromDate}
                endDate={state.toDate}
                minDate={state.fromDate}
                placeholderText="To"
                className="filter-custom-date"
              />
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-lg-6">
            <DropDown
              label={TEAMFILTERS.Role.label}
              items={TEAMFILTERS.Role.roleType}
              objectKey={TEAMFILTERS.Role.key}
              value={state.filteredRole}
              placeholder={TEAMFILTERS.Role.placeholder}
              onClick={(e) => teamFilterOnChangeHandler('filteredRole', e)}
            />
          </div>
          <div className="col-lg-6">
            <DropDown
              label={TEAMFILTERS.Status.label}
              items={TEAMFILTERS.Status.statusType}
              objectKey={TEAMFILTERS.Status.key}
              value={state.filteredStatus}
              placeholder={TEAMFILTERS.Status.placeholder}
              onClick={(e) => teamFilterOnChangeHandler('filteredStatus', e)}
            />
          </div>
        </div>
        <div className="row gy-3">
          <div className="col-lg-6">
            <CustomButton
              className="w-100"
              label="Apply"
              color="themeSecondary"
              onClick={() => teamFilterSaveHandler('apply')}
            />
          </div>
          <div className="col-lg-6">
            <CustomButton
              className="w-100"
              label="Cancel"
              variant="light"
              color="themeSecondary"
              onClick={() => teamFilterSaveHandler('cancel')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamsFilter;
