import React from 'react';
import FeatureImg from '../../assests/images/upcoming-feature.png';
import { UpcomingFeatureWrapper } from './style';

export const UpcomingFeature = () => {
  return (
    <UpcomingFeatureWrapper>
      <div className="d-flex justify-content-center">
        <img
          src={FeatureImg}
          alt=""
          style={{
            maxWidth: '100%',
          }}
        />
      </div>
      <div className="title text-center mt-4 mb-4">
        This feature will be available in upcoming versions
      </div>
    </UpcomingFeatureWrapper>
  );
};
