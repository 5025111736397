import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { isValidEmail, isValidName } from 'helpers';
import {
  Box,
  CustomButton,
  DropDown,
  Input,
  PhoneNumberInputBox,
  Textarea,
  Title,
} from 'components';
import { DEFAULT_CREATE_DETAILS_STATE } from '../../constants';
import {
  UPDATE_ENTERPRISE_DETAILS,
  useAxiosWrapper,
  FETCH_ENTERPRISE_CATEGORY,
} from 'services';
import { getProfileText } from 'utlils/utils';
import LogoSelector from './Components/LogoSelector';
import tokenService from 'services/token.service';
import Icon from 'utlils/Icons/Icon';

const CreateDetails = ({ userProfile, setUserProfile, setEditPage }) => {
  const [state, setState] = useState(DEFAULT_CREATE_DETAILS_STATE);
  const [enterpriseCategories, setEnterpriseCategory] = useState([]);
  const [enterpriseTypes, setEnterpriseTypes] = useState([]);
  const [fnWrapper, { busy }] = useAxiosWrapper();
  const {
    enterpriseName,
    enterpriseType,
    enterpriseCategory,
    link,
    contactNumber,
    email,
    enterpriseLogo,
    aboutEnterprise,
    error,
  } = state;
  const navigate = useNavigate();
  useEffect(() => {
    setState({
      ...userProfile,
      enterpriseLogo: userProfile?.enterpriseLogo ?? null,
      contactNumber: userProfile?.contactNumber ?? '',
      enterpriseType: {
        type: userProfile?.enterpriseType,
        value: userProfile?.enterpriseType,
      },
      enterpriseCategory: {
        type: userProfile?.enterpriseCategory ?? 'Select',
        value: userProfile?.enterpriseCategory ?? 'Select',
      },
    });
  }, [userProfile]);
  const onPhoneInputBlur = () => {
    setState({
      ...state,
      error: {
        ...error,
        contactNumber:
          (!contactNumber || !isValidPhoneNumber(contactNumber)) &&
          'Please enter a valid mobile phone number.',
      },
    });
  };

  const onSubmit = async (type) => {
    const submissionData = new FormData();
    submissionData.append('enterpriseId', state?.id);
    submissionData.append('userId', tokenService.getSession()?.id);
    submissionData.append('enterpriseName', state?.enterpriseName);
    submissionData.append('type', state?.enterpriseType?.value?.id);
    submissionData.append(
      'enterpriseCategory',
      state?.enterpriseCategory?.value?.id
    );
    submissionData.append('link', state?.link);
    submissionData.append('contactNumber', state?.contactNumber);
    if (state?.aboutEnterprise) {
      submissionData.append('aboutEnterprise', state?.aboutEnterprise);
    }
    submissionData.append('enterpriseLogo', state?.enterpriseLogo);
    const res = await fnWrapper({
      url: UPDATE_ENTERPRISE_DETAILS,
      method: 'PUT',
      type: 'UPDATE_ENTERPRISE_DETAILS',
      payload: submissionData,
    });
    if (res.data.statusCode === 200) {
      //Update the user profile (this will also update the left side profile)
      setUserProfile({
        ...state,
        enterpriseLogo: Array.isArray(res?.data?.data)
          ? state?.enterpriseLogo
          : res?.data?.data,
        enterpriseCategory: state?.enterpriseCategory?.value,
        enterpriseType: state?.enterpriseType?.value,
        profileText: getProfileText(state?.enterpriseName),
      });
      if (type === 'SAVE') {
        navigate('/enterprise/profile/view/details');
      } else {
        setEditPage('location');
      }
    }
  };

  const handleInputChange = (field = '', value = null) => {
    setState((state) => ({
      ...state,
      [field]: field === 'contactNumber' ? value || '' : value,
      error: {
        ...error,
        [field]:
          (field === 'enterpriseName' &&
            !isValidName(value) &&
            'Please enter a valid enterprise name') ||
          (field === 'contactNumber' &&
            (!contactNumber || !isValidPhoneNumber(value || '')) &&
            'Please enter a valid mobile phone number.'),
      },
    }));
  };
  const getEnterpriseType = useCallback(async () => {
    const response = await fnWrapper({
      url: `${FETCH_ENTERPRISE_CATEGORY}?type=ENTERPRISE`,
      method: 'GET',
      type: 'FETCH_ENTERPRISE_CATEGORY',
    });
    if (response.data.statusCode === 200) {
      setEnterpriseTypes(response.data.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getEnterpriseCategory = useCallback(async () => {
    const res = await fnWrapper({
      url: `${FETCH_ENTERPRISE_CATEGORY}?type=ENTERPRISE_CATEGORY`,
      method: 'GET',
      type: 'FETCH_ENTERPRISE_CATEGORY',
    });
    if (res.data.statusCode === 200) {
      setEnterpriseCategory(res.data.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getEnterpriseType();
    getEnterpriseCategory();
  }, [getEnterpriseType, getEnterpriseCategory]);
  /* Disable button */
  const disabled =
    !email ||
    !enterpriseName ||
    !contactNumber ||
    !isValidPhoneNumber(contactNumber) ||
    !isValidEmail(email) ||
    Object.keys(enterpriseType).length === 0 ||
    Object.keys(enterpriseCategory).length === 0 ||
    enterpriseCategory?.value === 'Select' ||
    !link ||
    !enterpriseLogo;
  return (
    <div>
      <Title title={'Details'} />
      <div className="row">
        <div className="col-md-6 col-lg-6 col-xl-4 col-sm-12">
          <Box mt="10px">
            <Input
              label="Enterprise Name"
              iconLeft
              iconName="enterprise"
              placeholder="Enterprise Name"
              type="text"
              required
              value={enterpriseName}
              onChange={(value) =>
                handleInputChange('enterpriseName', value.target.value)
              }
              hasError={error?.enterpriseName}
              errorMessage={error?.enterpriseName}
            />
          </Box>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-4 col-sm-12">
          <Box mt="10px">
            <DropDown
              label="Enterprise Type"
              placeholder="Select"
              objectKey="title"
              items={enterpriseTypes}
              required
              onClick={(value) =>
                handleInputChange('enterpriseType', { value: value })
              }
              value={enterpriseType?.value}
            />
          </Box>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-4 col-sm-12">
          <Box mt="10px">
            <DropDown
              label="Enterprise Category"
              placeholder="Select"
              objectKey="title"
              items={enterpriseCategories}
              required
              onClick={(value) =>
                handleInputChange('enterpriseCategory', { value: value })
              }
              value={enterpriseCategory?.value}
            />
          </Box>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-4 col-sm-12">
          <Box mt="10px">
            <Input
              label="Website"
              iconLeft
              iconName="global"
              placeholder="Website"
              type="text"
              required
              value={link}
              onChange={(value) =>
                handleInputChange('link', value.target.value)
              }
            />
          </Box>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-4 col-sm-12">
          <Box mt="10px">
            <Input
              label="Email"
              iconLeft
              iconName="sms"
              placeholder="Email"
              type="text"
              disabled
              required
              value={email}
              onChange={(value) =>
                handleInputChange('email', value.target.value)
              }
            />
          </Box>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-4 col-sm-12">
          <Box mt="10px">
            <PhoneNumberInputBox
              label={'Contact Number'}
              placeholder="Contact Number"
              required
              value={contactNumber}
              onChange={(value) => handleInputChange('contactNumber', value)}
              onBlur={onPhoneInputBlur}
              hasError={error?.contactNumber}
              errorMessage={error?.contactNumber}
            />
          </Box>
        </div>
        <LogoSelector enterpriseLogo={enterpriseLogo} setState={setState} />
        <div className="col-md-12 col-lg-12 col-sm-12">
          <Box mt="25px">
            <Textarea
              rows={4}
              cols={50}
              label="About Enterprise"
              placeholder="Tell others about yourself"
              value={aboutEnterprise}
              onChange={(value) => {
                handleInputChange('aboutEnterprise', value.target.value);
              }}
            />
          </Box>
        </div>
      </div>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap="15px"
        mt="30px">
        <Box width="120px" mr="12px">
          <CustomButton
            className="w-100"
            label={busy?.status ? 'Saving' : 'Save'}
            onClick={() => onSubmit('SAVE')}
            disabled={disabled || busy?.status}
          />
        </Box>

        <CustomButton
          disabled={disabled || busy?.status}
          onClick={() => onSubmit('NEXT')}
          variant="textBtn"
          color={'themePrimary'}>
          Next
          <Icon name="arrow-forward" color={'#0ABD00'} size={20} />
        </CustomButton>
      </Box>
    </div>
  );
};

export default CreateDetails;
