import styled from 'styled-components';

export const PostCardWrapper = styled.div`
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ height }) => (height ? height : '100%')};
  margin-top: 10px;
  padding: 20px;
  width: 100%;
  background: #f4f7fc;
  border-radius: 18.1281px;
  border-bottom: ${({ showTasks }) =>
    showTasks ? '5px groove #929daf1f' : 'none'};

  @media (max-width: 576px) {
    padding: 10px;
  }

  .PostCardTasksBtn {
    text-decoration: underline !important;
    font-weight: 400 !important;
  }

  & .post-wrapper {
    padding-bottom: ${({ showComments }) => (showComments ? '20px' : '10px')};
    border-bottom: ${({ showComments }) =>
      showComments ? '1px solid #c4c4c4' : 'none'};
    & .posts-header {
      display: flex;
      justify-content: space-between;

      & .left-side-class {
        display: grid;
        grid-template-columns: 50px 100%;
        gap: 10px;
        & .profile-pic-container,
        .profile-pic-container img {
          width: 40px;
          height: 40px;
          object-fit: cover;
          background: #d0e5ff;
          border-radius: 50%;
          & .pfp-alt-circle {
            border-radius: 50%;
            width: 40px;
            height: 40px;
            background-color: #d6f3f9;
            display: flex;
            align-items: center;
            justify-content: center;
            & .pfp-alt {
              border-radius: 50%;
              font-weight: bold;
            }
          }

          & .user-icon-class {
            margin-top: 10px;
            margin-left: 8px;
          }
        }

        & .user-name {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 1px;
          color: #040d20;

          & .user-name-style {
            @media (max-width: 767px) {
              margin-bottom: 1px;
            }
          }

          & .user-posted-time {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 1px;
            color: #929daf;
          }
          @media (max-width: 767px) {
            line-height: normal;
            padding-top: 0px;
          }
        }

        @media (max-width: 767px) {
          grid-template-columns: 1fr;
        }
      }

      & .options-class {
        margin: 0 20px 5px 0;
        padding: 5px;
        align-items: flex-end;
        cursor: pointer;
      }
    }

    & .post-content-container {
      margin-top: 30px;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #404f67;
      gap: 30px;

      & .post-attachment {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-top: 15px;
        gap: 10px 20px;

        & .img-wrapper {
          display: flex;
          width: 100%;
          word-break: break-all;

          & a {
            color: #17191d;
          }

          & .img-container {
            width: 30px;
            height: 30px;
            margin-top: 10px;
            &:hover {
              cursor: pointer;
            }
          }

          & .img-text {
            margin-top: 10px;
            text-decoration: underline;

            &:hover {
              cursor: pointer;
            }
          }
        }

        @media (max-width: 767px) {
          grid-template-columns: 1fr;
          gap: 5px;
        }
      }
    }

    & .post-footer {
      margin-top: 25px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 575px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
      }
      & .footer-buttons {
        display: flex;
        align-items: flex-start;
        gap: 20px;
        & .footer-button-box {
          display: flex;
          align-items: center;
          color: #929daf;
          & .icon-button {
            margin: 5px;
            &:hover {
              cursor: pointer;
              color: #219fff;
            }
          }
          & .disabled {
            margin: 5px;
            &:hover {
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }
  & .comments-wrapper {
    margin: 0px 8px;
    & .comment-input_area {
      margin-top: 15px;
      display: flex;
      align-items: center;
      gap: 20px;
      padding-bottom: 25px;

      & .pfp-alt-circle {
        border-radius: 50%;
        width: 45px;
        height: 45px;
        background-color: #d6f3f9;
        display: flex;
        align-items: center;
        justify-content: center;
        & .pfp-alt {
          border-radius: 50%;
          font-weight: bold;
        }
      }

      & .commenter-profile-pic {
        object-fit: cover;
        border-radius: 50%;
        width: 45px;
        height: 45px;
        background: #c4c4c4;
      }

      & .comment-input_box {
        width: 100%;
        padding: 2px 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #e9eef4;
        border-radius: 60px;

        & .comment-input {
          flex-grow: 1;
          color: #929daf;
          font-size: 14px;
          font-weight: 400;
          border: none;
          background: none;
          padding: 0 10px;
          &:focus {
            outline: none;
          }
          @media (max-width: 576px) {
            width: 100%;
          }
        }
        & .send-comment-icon {
          &:hover {
            cursor: pointer;
          }
        }
        @media (max-width: 576px) {
          width: 85%;
        }
      }
      @media (max-width: 576px) {
        gap: 2px;
      }
    }
    @media (max-width: 576px) {
      margin: 0 2px;
    }
    & .display-comment-wrapper {
      padding: 15px;
      margin: 10px 0;
      background: #e9eef4;
      border-radius: 10px;
      & .display-comment-header {
        display: flex;
        align-items: center;
        @media (max-width: 576px) {
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
        }
        & .commenter-info {
          flex-grow: 1;
          display: flex;
          align-items: center;
          gap: 20px;
          justify-content: flex-start;
          & .pfp-text-circle {
            border-radius: 50%;
            width: 45px;
            height: 45px;
            background-color: #d6f3f9;
            display: flex;
            align-items: center;
            justify-content: center;
            & .pfp-text {
              border-radius: 50%;
              font-weight: bold;
            }
          }
          & .commenter-pic {
            object-fit: cover;
            border-radius: 50%;
            width: 35px;
            height: 35px;
            background: #c4c4c4;
          }
          & .commenter-name {
            color: #17191d;
            font-weight: 600;
            font-size: 14px;
          }
        }
        & .comment-options {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: 20px;
          color: '#929daf';
          font-size: 12px;
          @media (max-width: 576px) {
            width: 100%;
          }
        }
      }
      & .display-comment-content {
        padding: 15px 10px 10px 10px;
        color: #929daf;
      }
    }
  }
`;
