import styled from 'styled-components';
import { isMobile } from 'utlils';

export const MemberCardWrapper = styled.div`
  min-width: ${isMobile() ? 'none' : '250px'};
  position: relative;
  border-radius: 10px;
  border: 1px solid #c4c4c4;

  & .member-wrapper {
    max-height: 370px;
    height: 300px;
  }
  & .cover-image {
    background: ${({ bgImgSrc }) => `url(${bgImgSrc})`}, #4d496f;
    border-radius: 10px 10px 0 0;
    height: 72px;
    background-repeat: no-repeat;
    background-size: cover;
  }
  & .profile-image-container {
    position: absolute;
    left: calc(50% - 45px);
    top: 27px;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background: #e3e2ed;
    display: flex;
    justify-content: center;
    align-items: center;
    & img {
      width: 90px;
      height: 90px;
      border-radius: 50%;
    }
  }

  & .info-wrapper {
    margin-top: 45px;
    padding: 12px 12px 0 12px;
    display: grid;
    gap: 16px;
    & .user-name {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: #17191d;
    }

    & .user-designation {
      margin: 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #404f67;
    }

    & .sub-info-wrapper {
      margin-bottom: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      & span {
        font-size: 13px;
        line-height: 18px;
        color: #404f67;
      }
    }
  }
  & .connect-button {
    margin: 12px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;
