import styled from 'styled-components';

export const StausButtonWrapper = styled.div`
  background: ${({ status }) => status?.bgColor};
  border-radius: ${({ status }) =>
    status?.borderRadius ? status.borderRadius : '18.375px'};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: ${({ status }) =>
    status?.fontWeight ? status.fontWeight : '400'};
  font-size: ${({ status }) => (status?.fontSize ? status.fontSize : '12px')};
  line-height: 24px;
  color: ${({ status }) => status?.textColor};
  padding: ${({ status }) =>
    status?.bgColor === 'transparent'
      ? '0'
      : `${status?.padding ? status?.padding : '5px'}`};
  text-align: center;
  border: ${({ status }) => (status?.border ? status.border : '')};
`;
