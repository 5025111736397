import styled from 'styled-components';

export const ChangeProjectAdminWrapper = styled.div`
  & .drop-down-wrapper {
    margin-top: 30px;
    padding: 20px;
  }
  & .withdraw-button-wrapper {
    width: 150px;
  }
`;
