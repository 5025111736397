import styled from 'styled-components';

export const PrivacyProfileVisibility = styled.div`
  & .heading {
    color: #17191d;
    font-family: Poppins;
    font-size: 25px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
  }
  & .options-text {
    color: #404f67;
  }
  & .radio-buttons {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    align-items: start;
    color: #404f67;
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
  }
  & .horizontal-line {
    border: 1px solid #404f6733;
  }
  & .hide-option-container {
    display: flex;
    justify-content: space-between;
  }
  & .hide-show {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #404f67;
  }
  & .switch-tab {
    border-left: 3px solid #404f6733;
    display: flex;
    gap: 10px;
    align-items: center;
  }
  & .hide-text {
    margin-left: 15px;
  }
  & .submit-button {
    margin-top: 3rem;
    display: flex;
    justify-content: center;
  }
`;
