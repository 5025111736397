import {
  CustomButton,
  ModalPopup,
  NavigationalRowItem,
  OptionWrapper,
} from 'components';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import Icon from 'utlils/Icons/Icon';
import { CANDIDATE_ROLE } from '../constant';
import PropTypes from 'prop-types';
import { truncateString } from 'utlils/utils';

export const OnBoardPopUp = ({
  applicants,
  isOpen,
  setIsOpen,
  onSubmitHandler,
  setSelectedApplicants,
}) => {
  const [candidates, setCandidates] = useState([]);

  useEffect(() => {
    if (applicants.length > 0) {
      let clist = [];
      applicants.map((applicant) => {
        const item = {
          id: applicant?.id,
          name:
            applicant?.applicant?.individual === null
              ? applicant?.applicant?.smallEnterprise?.enterpriseName
              : applicant?.applicant?.individual?.name,
          profilePicture:
            applicant?.applicant?.individual === null
              ? applicant?.applicant?.smallEnterprise?.profilePicture
              : applicant?.applicant?.individual?.profilePicture,
          profileIcon: 'user-icon',
          designation: applicant?.appliedJob?.designation,
          isDropdown: false,
          selectedDropItem: {},
        };
        clist.push(item);
        return clist;
      });
      setCandidates(clist);
    }
  }, [applicants]);

  const handleDropdown = (id) => {
    const data = candidates.map((val) => {
      if (val.id === id) {
        return {
          ...val,
          isDropdown: !val.isDropdown,
        };
      } else {
        return val;
      }
    });
    setCandidates(data);
    setSelectedApplicants(data);
  };

  const handleDropItemClick = (item, id) => {
    const data = candidates.map((val) => {
      if (val.id === id) {
        return {
          ...val,
          isDropdown: false,
          selectedDropItem: {
            ...val.selectedDropItem,
            id: item.id,
            title: item.title,
          },
        };
      } else {
        return val;
      }
    });
    setCandidates(data);
    setSelectedApplicants(data);
  };

  return (
    <PopUpWrapper>
      {isOpen && (
        <ModalPopup
          open={false}
          maxContentWidth="650px"
          minWidth="650px"
          hasTitle
          title={'Onboard Candidates'}
          onClose={setIsOpen}>
          <div className="modal-container">
            <p className="popup-heading">
              The selected candidates will be added to the Team. Assign them
              their roles & send an invitation to join the Team.
            </p>
            <div className="row-heading">
              <span></span>
              <span className="row-heading-content">Role </span>
              <span className="row-heading-content">Designation </span>
            </div>
            <div>
              {candidates.length > 0 &&
                candidates.map((candidate, index) => (
                  <div key={index} className="row-wrapper">
                    <div className="row-wrapper__left-class">
                      {candidate?.profilePicture !== null &&
                      candidate?.profilePicture !== '' ? (
                        <div className="profile-pic-class">
                          <img src={candidate?.profilePicture} alt="Profile" />
                        </div>
                      ) : (
                        <div className="icon-style-class">
                          <Icon
                            name={candidate?.profileIcon}
                            size={22}
                            color="#404f67"
                          />
                        </div>
                      )}
                      <NavigationalRowItem
                        label={candidate?.name}
                        navigateTo={`/public/individual/${candidate?.id}/summary/basic-details`}
                        isOpenInNewTab
                        customStyle={{ color: '#404f67', cursor: 'pointer' }}
                      />
                    </div>

                    <div className="dropdown-wrapper">
                      <div
                        onClick={() => handleDropdown(candidate?.id)}
                        className="d-flex justify-content-between align-items-center dropdown-wrapper__title">
                        <span className="role-heading">
                          {Object.keys(candidate?.selectedDropItem).length > 0
                            ? candidate?.selectedDropItem?.title
                            : 'Select'}
                        </span>
                        <Icon
                          name={
                            candidate?.isDropdown
                              ? 'arrow-drop-up'
                              : 'arrow-down'
                          }
                          color="#404F67"
                          size={15}
                        />
                      </div>
                      {candidate?.isDropdown && (
                        <OptionWrapper
                          className="dropdown-wrapper__option-wrapper"
                          onClick={(item) =>
                            handleDropItemClick(item, candidate?.id)
                          }
                          options={CANDIDATE_ROLE}
                          selectedOption={candidate?.selectedDropItem?.id}
                        />
                      )}
                    </div>
                    <div className="row-wrapper__left-class">
                      <div className="designation-container">
                        {truncateString(candidate?.designation, 22)}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            <div className="invite-button-wrapper">
              <CustomButton
                label={'Send Invite'}
                onClick={onSubmitHandler}
                customPadding="16px 36px"
              />
            </div>
          </div>
        </ModalPopup>
      )}
    </PopUpWrapper>
  );
};

OnBoardPopUp.prototypes = {
  applicants: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

const PopUpWrapper = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #404f67;
  .modal-container {
    height: 50vh;
  }
  .popup-heading {
    text-align: center;
    margin: 36px 0;
  }

  .row-heading {
    font-weight: 600;
    border-bottom: 1px solid #929daf;
    display: grid;
    justify-content: center;
    grid-template-columns: 1.2fr 1fr 1fr;

    & .row-heading-content {
      display: flex;
      justify-content: center;
    }
  }
  .row-wrapper {
    display: grid;
    grid-template-columns: 1.2fr 1fr 1fr;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 20px 0;

    &__left-class {
      display: flex;
      align-items: center;
      gap: 16px;

      & .designation-container {
        background: #f4f7fc;
        border-radius: 8px;
        text-align: center;
        width: 100%;
        padding: 8px 4px;
        color: #929daf;
      }

      & .profile-pic-class,
      .profile-pic-class img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
      & .icon-style-class {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #e9fbf6;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    & .dropdown-wrapper {
      position: relative;
      &__title {
        min-width: 100px;
        cursor: pointer;
        padding: 12px 20px;
        background: #f4f7fc;
        border-radius: 8px;
        @media (max-width: 575px) {
          min-width: 100%;
          margin-top: 12px;
        }
        .role-heading {
          font-weight: 500;
          font-size: 15px;
          line-height: 19px;
          color: #929daf;
        }
      }
      &__option-wrapper {
        top: 52px;
        left: 10px;
      }
    }
  }

  .invite-button-wrapper {
    margin: 56px auto 28px auto;
    width: 160px;
  }
`;
