import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Icon from 'utlils/Icons/Icon';
import DotActive from 'assests/images/dotActive.png';
import DotDisable from 'assests/images/dotDisable.png';

import { TaskCardWrapper } from './style';
import './taskList.scss';
import { isMobile } from 'utlils/isMobile';
import { CustomButton, StatusButton } from 'components';
import { getDateTime } from 'utlils/utils';
import { taskUrgency } from 'pages/enterpriseDashboard/helper';
import {
  MY_TASK_ACTION_OPTIONS,
  OTHER_TASK_ACTION_OPTIONS,
  otherTasksStatus,
  TASK_TOGGLE_LABEL,
} from 'pages/enterpriseDashboard/constants';
import CommonDropDown from 'pages/enterpriseDashboard/components/commonDropDown';
import tokenService from 'services/token.service';

dayjs.extend(relativeTime);

export const TaskListItems = ({
  list,
  onOpenTaskAction,
  activeTaskAction = null,
  onSelectTaskAction,
  taskType,
  onViewTaskHandler,
}) => {
  let { departmentId } = useParams();

  return (
    <div className="row task-list-wrapper">
      {list.map((item, index) => (
        <TaskCardWrapper key={index} className="task-card card p-0">
          {isMobile() ? (
            <MobileTaskCardHeader
              item={item}
              taskType={taskType}
              activeTaskAction={activeTaskAction}
              onOpenTaskAction={onOpenTaskAction}
              departmentId={departmentId}
              onSelectTaskAction={onSelectTaskAction}
            />
          ) : (
            <DesktopTaskCardHeader
              item={item}
              taskType={taskType}
              activeTaskAction={activeTaskAction}
              onOpenTaskAction={onOpenTaskAction}
              departmentId={departmentId}
              onSelectTaskAction={onSelectTaskAction}
            />
          )}
          <div className="task-card__content-wrapper row ">
            <div className="col-lg-9 col-md-9 col-xl-10">
              <div className="task-card__content-wrapper__header">
                {item?.taskTitle}
              </div>
              <div className="task-card__content-wrapper__description">
                {item?.taskDescription}
              </div>
            </div>
            <div className="task-card__content-wrapper__footer col-md-3 col-lg-3 col-xl-2">
              <div className="task-card__content-wrapper__footer--action-wrapper">
                <CustomButton
                  customPadding="10px"
                  label="View Task"
                  onClick={() => onViewTaskHandler(item)}
                  color={'themeSecondary'}
                  variant="outline"
                />
                <StatusButton status={otherTasksStatus[item?.status]} />
              </div>
            </div>
          </div>
        </TaskCardWrapper>
      ))}
    </div>
  );
};

const DesktopTaskCardHeader = ({
  item,
  taskType,
  activeTaskAction,
  onOpenTaskAction,
  departmentId,
  onSelectTaskAction,
}) => {
  let userId = tokenService.getSession().id;

  return (
    <div className="task-card__header-wrapper">
      <StatusButton status={taskUrgency[item?.urgency]} />
      <div className="task-card__header-wrapper__icon-with-label">
        <Icon color="rgba(146, 157, 175, 1)" name="ticket" size={25} leftIcon />
        <div>{item?.ticketId}</div>
      </div>
      <div>Posted {getDateTime(item?.createdAt)}</div>
      <div className="task-card__header-wrapper__icon-with-label">
        <Icon
          color="rgba(146, 157, 175, 1)"
          name="department"
          size={30}
          leftIcon
        />
        {taskType === TASK_TOGGLE_LABEL.MY_TASKS ? (
          <div>Assigned by-{item?.assignedByDepartment?.departmentName}</div>
        ) : (
          <div>Assigned to-{item?.assignedToDepartment?.departmentName}</div>
        )}
      </div>
      <div
        className="task-card__header-wrapper__menu"
        onClick={() => onOpenTaskAction(item.id)}>
        <img
          src={activeTaskAction === item.id ? DotActive : DotDisable}
          alt="three-dot"
        />
      </div>
      <CommonDropDown
        dropItem={
          item?.createdByUserId === userId
            ? OTHER_TASK_ACTION_OPTIONS
            : MY_TASK_ACTION_OPTIONS
        }
        active={activeTaskAction === item.id}
        objectKey="label"
        onClick={(actionItem) => {
          onSelectTaskAction(actionItem, item);
        }}
        top="65px"
        right="-32px"
      />
    </div>
  );
};

const MobileTaskCardHeader = ({
  item,
  taskType,
  activeTaskAction,
  onOpenTaskAction,
  departmentId,
  onSelectTaskAction,
}) => {
  return (
    <div className="task-card__mobile-header-wrapper">
      <div className="task-card__mobile-header-wrapper__row--1">
        <StatusButton status={taskUrgency[item?.urgency]} />
        <div className="task-card__mobile-header-wrapper__icon-with-label">
          <Icon
            color="rgba(146, 157, 175, 1)"
            name="ticket"
            size={25}
            leftIcon
          />
          <div>{item?.ticketId}</div>
        </div>
        <div
          className="task-card__mobile-header-wrapper__menu"
          onClick={() => onOpenTaskAction(item.id)}>
          <img
            src={activeTaskAction === item.id ? DotActive : DotDisable}
            alt=""
          />
        </div>
        <CommonDropDown
          dropItem={
            item?.assignedByDepartment?.id === departmentId
              ? OTHER_TASK_ACTION_OPTIONS
              : MY_TASK_ACTION_OPTIONS
          }
          active={activeTaskAction === item.id}
          objectKey="label"
          onClick={(data) => {
            onSelectTaskAction(data, item);
          }}
          mobileTop="70px"
          mobileRight="0px"
        />
      </div>
      <div className="task-card__mobile-header-wrapper__row--2">
        <div className="task-card__mobile-header-wrapper__icon-with-label">
          <Icon
            color="rgba(146, 157, 175, 1)"
            name="department"
            size={30}
            leftIcon
          />
          {taskType === TASK_TOGGLE_LABEL.MY_TASKS ? (
            <div>Assigned by-{item?.assignedByDepartment?.departmentName}</div>
          ) : (
            <div>Assigned to-{item?.assignedToDepartment?.departmentName}</div>
          )}
        </div>
        <div>Posted {getDateTime(item?.createdAt)}</div>
      </div>
    </div>
  );
};
