import { Textarea } from 'components';

export const QuestionContainer = ({ question, handleAnswerInput, value }) => {
  return (
    <Textarea
      rows={5}
      cols={50}
      value={value}
      onChange={(e) => handleAnswerInput(e, question)}
      label={
        <p className="sub-heading">
          {question} <span style={{ color: '#FF1E26' }}>*</span>
        </p>
      }
      placeholder="Type your answer here"
    />
  );
};
