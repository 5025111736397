import cn from 'classname';
import { Box } from '../box';
import './card.scss';

export const Card = ({
  title,
  imgUrl,
  bg,
  bRadius,
  imgHeight,
  color,
  border,
  boxShadow,
  isLeftAligned,
}) => {
  return (
    <div className="individual-card-container">
      <Box
        bg={bg}
        display="flex"
        justifyContent="center"
        bRadius={bRadius}
        border={border}
        weight="800"
        p={!isLeftAligned ? '1.5rem' : '0'}
        size="24px"
        height="100%"
        color={color}
        boxShadow={boxShadow}>
        <div className={cn(isLeftAligned ? 'is-left-align' : '')}>
          <Box textAlign="center">
            <p className="card-title">{title}</p>
          </Box>
          <img
            src={imgUrl}
            alt=""
            style={{
              maxWidth: '100%',
              overflow: 'hidden',
              marginTop: !isLeftAligned ? '30px' : '',
              marginBottom: !isLeftAligned ? '30px' : '',
              height: `${imgHeight}`,
            }}
          />
        </div>
      </Box>
    </div>
  );
};
