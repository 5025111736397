import React from 'react';
import styled, { css } from 'styled-components';
import { bool, string } from 'prop-types';

const Skeleton = styled.div`
  .skeleton {
    &:empty:after,
    &_shape__heart:empty:after,
    &_shape__heart:empty:before,
    &_shape__round:empty:after {
      border-radius: ${({ border = '2px' }) => border};
      content: '';
      display: inline-block;
      background: linear-gradient(
        to right,
        #f0f0f0 4%,
        #f8f8f8 25%,
        #f0f0f0 36%
      );
      background-size: 1000px 400%;
      animation: pulse-wave ${({ speed }) => speed} ease-in-out infinite;
      ${({ height }) =>
        height
          ? css`
              height: ${height} !important;
            `
          : css`
              height: 24px;
            `};
      ${({ width }) =>
        width
          ? css`
              width: ${width} !important;
            `
          : ''};
    }

    &_shape {
      &__heart:empty {
        background: ${({ translucent }) =>
          translucent
            ? css`linear-gradient(to right, #C1C1C1 4%, #F8F8F8 25%, #C1C1C1 36%)`
            : css`linear-gradient(to right, #F0F0F0 4%, #F8F8F8 25%, #F0F0F0 36%)`};
        background: #f3f3f3;
        display: inline-block;
        height: 10px;
        position: relative;
        top: 0;
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
        width: 10px;

        &:before,
        &:after {
          border-radius: 50%;
          height: 10px;
          position: absolute;
          width: 10px;
        }
        &:before {
          top: -7px;
          left: 0;
        }
        &:after {
          left: 7px;
          top: 0;
        }
      }

      &__round:empty:after {
        width: 22px !important;
        height: 22px !important;
        border-radius: 50%;
      }
    }

    &.checkbox:empty:after {
      width: 20px !important;
      height: 20px !important;
    }
  }
  @keyframes pulse-wave {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }
`;
const LoadingSkeletonPropTypes = {
  border: string,
  speed: string,
  translucent: bool,
  width: string,
};

// noinspection JSCommentMatchesSignature
/**
 * @summary Wrapper component for adding graceful loading skeleton
 * @description Wrap the element with LoadingSkeleton and add .skeleton class
 * to element where loading skeleton is needed + PROVIDE THE WIDTH AND HEIGHT to
 * .skeleton:empty:after {height:,width:} without width and loader will not show up.
 *
 * @author Kiran Ranjan Bihari <kiran@brenolabs.com>
 * @param {string} border border radius with unit.
 * @param {string} height height of the box in px e<g. 10px.
 * @param {string} width width of the box in px eg. 10px.
 * @param {object} children Elements to be wrapped (react elements).
 * @param {string} speed animation speed in seconds / milliseconds eg '3.5s' or '2500'.
 * @param {boolean} translucent to make skeleton translucent on darker pages
 * @param {object} props spread other props
 * @return {React.ReactElement} LoadingSkeleton
 */
export const LoadingSkeleton = (props) => {
  const { border, children, speed, translucent = false, ...rest } = props;
  return (
    <Skeleton border={border} speed={speed} translucent={translucent} {...rest}>
      {children}
    </Skeleton>
  );
};
LoadingSkeleton.propTypes = LoadingSkeletonPropTypes;
LoadingSkeleton.defaultProps = {
  speed: '2.5s',
};
