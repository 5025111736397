import { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box, CustomButton, Input, Title } from 'components';
import { LOGIN, useAxiosWrapper } from 'services';
import TokenService from 'services/token.service';
import './login.scss';
import Icon from 'utlils/Icons/Icon';

const DEFAULTSTATE = {
  userOrEmail: '',
  password: '',
};

export const MiHintAdminLogin = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const [fnWrapper, context] = useAxiosWrapper();
  const [state, setState] = useState(DEFAULTSTATE);
  const { userOrEmail, password } = state;

  const handleInputChange = (field = '', value = null) => {
    setState({
      ...state,
      [field]: value,
    });
  };

  /* Disable button */
  const disabled = !userOrEmail || !password;

  /* Sign in with email and password handler */
  const onContinueButtonHandler = async () => {
    const res = await fnWrapper({
      isSkippedInterceptor: true,
      url: LOGIN,
      method: 'POST',
      type: 'EMAIL_LOGIN',
      payload: {
        email: userOrEmail,
        password: password,
        loginType: 'email',
      },
    });

    if (res.data.statusCode === 200) {
      TokenService.setSession(res.data.data);
      if (location.state !== null && location?.state?.redirectUri) {
        navigate(location.state?.redirectUri, {
          replace: true,
        });
      } else {
        if (res.data.data.role === 'MIHINT_SUPERADMIN') {
          navigate('/miHintAdmin/waitlist');
        } else {
          toast.error('You are not authorized to access this feature', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        }
      }
    }
  };

  return (
    <Box className="container-fluid">
      <div className="row sign-in-container">
        <div className=" col-lg-7 ">
          <div className="container">
            <div className="row">
              <div className="col-md-3 "></div>
              <div className="col-md-8 ">
                <Box>
                  <Box className="sign-in-wrapper">
                    {/* logo */}
                    <Box mb="78px" mt="33px">
                      <img
                        src={process.env.PUBLIC_URL + '/new-logo.png'}
                        className="img common-navbar-logo"
                        alt="logo"
                      />
                    </Box>
                    {/* title and subtitle */}
                    <Box mb="40px">
                      <Title title="Welcome" />
                    </Box>
                    <div className="row">
                      <div className="col-md-10 col-lg-10 col-12">
                        <Box mt="20px">
                          <Input
                            label="Email Address"
                            iconLeft={true}
                            iconName="sms"
                            placeholder="Enter your email address"
                            type="text"
                            required
                            value={userOrEmail}
                            onChange={(value) =>
                              handleInputChange(
                                'userOrEmail',
                                value.target.value
                              )
                            }
                          />
                        </Box>
                        <Box>
                          <Input
                            label="Password"
                            iconLeft={true}
                            iconName="lock"
                            placeholder="Enter your password"
                            type="password"
                            required
                            value={password}
                            onChange={(value) =>
                              handleInputChange('password', value.target.value)
                            }
                          />
                        </Box>
                      </div>
                      <div className="col-md- col-lg-2 col-12"></div>
                    </div>
                    {/* Sign-In-Button */}

                    <Box width={136} mb="40px" mt="40px">
                      <CustomButton
                        className="w-100"
                        customPadding="10px 14px"
                        disabled={disabled}
                        onClick={onContinueButtonHandler}
                        loading={
                          context.busy.status &&
                          context.busy.type === 'EMAIL_LOGIN'
                        }>
                        Sign in
                        <Icon
                          name="icon-right"
                          color="#fff"
                          className="icon-align"
                          size={20}
                        />
                      </CustomButton>
                    </Box>
                  </Box>
                </Box>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
        </div>
        <div className="col-lg-5 d-none d-sm-none d-lg-block image-wrapper ">
          {/* right side image */}
          <div className="right-img"></div>
        </div>
      </div>
    </Box>
  );
};
