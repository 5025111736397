import styled from 'styled-components';

export const ChatContainer = styled.div`
  margin: 25px 50px;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 1fr auto;
  @media (max-width: 992px) {
    grid-template-columns: 1fr 0fr;
  }
  .left-section {
    @media (max-width: 992px) {
      display: none;
    }
  }
`;

export const ChatWrapper = styled.div`
  background: #f4f7fc;
  height: 100vh;
  display: flex;
  flex-direction: column; /* Added this line to make it a flex container */
`;
