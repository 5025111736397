import { RoleBasedAccessContext } from 'context/RoleBasedAccess/roleBasedAccessContext';
import React, { useContext } from 'react';

export let IsPermitted = ({ permissions = [], customMessage, children }) => {
  const { roleBasedAccessState } = useContext(RoleBasedAccessContext);
  const teamPermissions = roleBasedAccessState.personalTeamRBAC;
  const projectPermissions = roleBasedAccessState.projectRBAC;

  const allPermissions = [...teamPermissions, ...projectPermissions];

  let renderBasedOnPermissions = (permissions) => {
    return permissions.length === 0 ||
      permissions?.some((p) => allPermissions?.includes(p))
      ? children
      : customMessage
      ? customMessage
      : null;
  };
  return (
    <React.Fragment>
      {typeof permissions === 'string'
        ? renderBasedOnPermissions([permissions.trim()])
        : renderBasedOnPermissions(permissions)}
    </React.Fragment>
  );
};
