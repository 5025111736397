import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  CustomButton,
  ModalPopup,
  ScrollableDiv,
  SlidingButton,
  GenerateQRCode,
} from 'components';
import Icon from 'utlils/Icons/Icon';
import { SemiCircleContainer, CircleContainer } from './style';
import tokenService from 'services/token.service';
import ErecordAsPdf from './ErecordAsPdf';
import { PDFDownloadLink } from '@react-pdf/renderer';

const eTeams = [
  { label: 'No. of Teams managed', value: 0, key: 'teamsManaged' },
  { label: 'No. of Teams enrolled', value: 0, key: 'teamsEnrolled' },
];

const eProjects = [
  { label: 'No. of Projects managed', value: 0, key: 'projectsManaged' },
  { label: 'No. of Projects enrolled', value: 0, key: 'projectsEnrolled' },
];

const statisticsTasks = [
  {
    label: 'Tasks assigned',
    value: 0,
    circleType: 'semi-circle',
    color: '#219FFF',
    key: 'taskAssigned',
  },
  {
    label: 'Tasks Successfully Completed',
    value: 0,
    circleType: 'circle',
    color: '#0ABD00',
    key: 'completedTasks',
  },
  {
    label: 'Tasks completed On-Time',
    value: 0,
    circleType: 'circle',
    color: '#404F67',
    key: 'taskCompletedOnTime',
  },
  {
    label: 'Tasks Delayed',
    value: 0,
    circleType: 'circle',
    color: '#E66657',
    key: 'taskDelayed',
  },
];

export const ViewErecordPopup = (props) => {
  const {
    publicUserProfile,
    onClose,
    erecordAction,
    enrolledTeamsCount,
    enrolledProjectsCount,
    managedTeamsCount,
    managedProjectsCount,
    taskType,
    taskStatisticsData,
    eTechnology,
  } = props;

  const loggedInUserSession = tokenService.getSession();
  const { userId } = useParams();

  const [isShowTaskStatistics, setIsShowTaskStatistics] = useState(true);

  const eTeamsData = eTeams.map((record) => {
    if (record.key === 'teamsManaged') {
      return { ...record, value: managedTeamsCount };
    } else if (record.key === 'teamsEnrolled') {
      return { ...record, value: enrolledTeamsCount };
    }
    return record;
  });

  const eProjectsData = eProjects.map((record) => {
    if (record.key === 'projectsManaged') {
      return { ...record, value: managedProjectsCount };
    } else if (record.key === 'projectsEnrolled') {
      return { ...record, value: enrolledProjectsCount };
    }
    return record;
  });

  const taskStatisticsDetails = statisticsTasks.map((stats) => {
    if (stats.key === 'taskAssigned') {
      return { ...stats, value: taskStatisticsData.taskAssigned };
    } else if (stats.key === 'completedTasks') {
      return { ...stats, value: taskStatisticsData.completedTasks };
    } else if (stats.key === 'taskCompletedOnTime') {
      return { ...stats, value: taskStatisticsData.taskCompletedOnTime };
    } else if (stats.key === 'taskDelayed') {
      return { ...stats, value: taskStatisticsData.taskDelayed };
    }
    return stats;
  });

  // check task statistics data present or not
  const isTaskStatisticsAvailable = taskStatisticsDetails.every(
    (item) => item.value === 0
  );

  // const shareQRCodeHandler = () => {
  //   // eslint-disable-next-line no-console
  //   console.log('share qr code');
  // };

  const erecordAsPdfProps = {
    eProjectsData,
    eTeamsData,
    loggedInUserSession,
    userId,
    taskStatisticsDetails,
    publicUserProfile,
    eTechnology,
  };

  return (
    <>
      {erecordAction?.isModalOpen ? (
        <ModalPopup
          open={false}
          minWidth="1300px"
          maxContentWidth="1300px"
          hasTitle={false}>
          <div className="d-flex justify-content-between align-items-center mb-4">
            <div className="mt-2">
              <span className="ewrp__title">
                E-Record <span className="ewrp__name">of </span>
              </span>
              <span className="ewrp__name text-decoration-underline">
                {publicUserProfile?.name}
              </span>
            </div>
            <div className="d-flex justify-content-between align-items-center gap-4">
              <PDFDownloadLink
                className="text-decoration-none"
                document={
                  <ErecordAsPdf
                    userId={userId}
                    baseUrl={process.env.REACT_APP_BASE_URL}
                    erecordAsPdfProps={erecordAsPdfProps}
                  />
                }
                fileName="E-Record.pdf">
                <CustomButton customPadding="12px 14px">
                  Export as PDF
                  <Icon name="export" color="#FFFFFF" />
                </CustomButton>
              </PDFDownloadLink>
              <Icon
                name="cross"
                color="#292D32"
                className="ewrp__cross-button"
                size={30}
                onClick={onClose}
              />
            </div>
          </div>
          <ScrollableDiv
            scrollOnHeight="calc(100vh - 210px)"
            scrollOnHeightMob="300px">
            <div className="ewrp__">
              <div className="ewrp__technology-qr-container">
                <div className="technology__">
                  <div className="technology__title">
                    Skills & technologies -
                  </div>
                  <div className="technology__list">
                    {eTechnology && eTechnology.length > 0 ? (
                      eTechnology.map((tech, index) => (
                        <div className="technology__list-container" key={index}>
                          {tech.title}
                        </div>
                      ))
                    ) : (
                      <div className="technology__list-empty">
                        You have not added any technology yet!
                      </div>
                    )}
                  </div>
                </div>
                <div className="qr__">
                  <div className="qr__code">
                    <GenerateQRCode
                      redirectUrl={`${window.location.origin}/public/individual/e-record/${userId}`}
                      size={200}
                    />
                    {/* <div className="qr__code-share-btn">
                      <CustomButton
                        color="themeSecondary"
                        variant="textBtn"
                        customPadding="0 0"
                        onClick={shareQRCodeHandler}>
                        Share QR code
                        <Icon name="share" color="#219FFF" />
                      </CustomButton>
                    </div> */}
                  </div>
                </div>
              </div>
              <hr className="ewrp__horizontal-line" />
              <div className="ewrp__projects-teams-container">
                <div className="teams__">
                  <div className="teams__title">Teams</div>
                  <div className="teams__eTeams">
                    {eTeamsData.map((team, index) => (
                      <div className="teams__eTeams-wrp" key={index}>
                        <div className="teams__eTeams-wrp-title">
                          <Icon name="people" color="#404F67" size={30} />
                          <p>{team.label}</p>
                        </div>
                        <div className="teams__eTeams-wrp-count">
                          <span>{team.value}</span> Teams
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* Vertical line  */}
                <div className="vertical-line"></div>

                <div className="projects__">
                  <div className="projects__title">Projects</div>

                  <div className="projects__eProjects">
                    {eProjectsData.map((project, index) => (
                      <div className="projects__eProjects-wrp" key={index}>
                        <div className="projects__eProjects-wrp-title">
                          <Icon name="project" color="#404F67" size={30} />
                          <p>{project.label}</p>
                        </div>
                        <div className="projects__eProjects-wrp-count">
                          <span>{project.value}</span> projects
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <hr className="ewrp__horizontal-line" />
              <div className="ewrp__statistics-task-container">
                <div className="statistics-header">
                  <p className="statistics-title">Task Statistics</p>
                  {taskType === 'Private' &&
                  loggedInUserSession.id === userId ? (
                    <div className="show-hide-button">
                      <p className="button-title">Hide/Show</p>
                      <SlidingButton
                        onClick={() => setIsShowTaskStatistics((curr) => !curr)}
                        isSliding={isShowTaskStatistics}
                      />
                    </div>
                  ) : null}
                </div>
                {!isTaskStatisticsAvailable ? (
                  <div className="statistics-circles-container">
                    {taskStatisticsDetails.map((task, index) => (
                      <div key={index}>
                        {task.circleType === 'semi-circle' ? (
                          <SemiCircleContainer color={task.color}>
                            <div className="semi-circle-count">
                              {task.value}
                            </div>
                            <div className="semi-circle-label">
                              {task.label}
                            </div>
                          </SemiCircleContainer>
                        ) : (
                          <CircleContainer color={task.color}>
                            <div className="count">{task.value}</div>
                            <div className="label">{task.label}</div>
                          </CircleContainer>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="no-taskStatistics">
                    <Icon name="warning" color="#929DAF"></Icon>
                    <p>No data available</p>
                  </div>
                )}
              </div>
            </div>
          </ScrollableDiv>
        </ModalPopup>
      ) : null}
    </>
  );
};
