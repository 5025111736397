import styled from 'styled-components';

export const ERecordWrapper = styled.div`
  height: 100%;
  & .heading {
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 24px;
    color: #404f67;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
  }

  & .record-content-container {
    margin: 40px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    & .record-class {
      padding: 20px;
      background: #ffffff;
      border: 1px solid #ccd2dd;
      border-radius: 10px;

      & .record-title {
        text-align: left;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #404f67;
      }

      & .record-stats {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;

        & .record-percent {
          font-weight: 400;
          font-size: 25px;
        }

        & span {
          font-weight: 600;
          font-size: 40px;
          line-height: 40px;
          color: #219fff;
        }
      }
    }

    @media (max-width: 465px) {
      grid-template-columns: 1fr;
    }
  }

  & .stats-not-availabe {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 16px;
    p {
      color: #929daf;
    }
  }
  .ewrp__ {
    margin: 15px 20px;

    &title {
      color: #219fff;
      font-family: Poppins;
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
    }
    &name {
      color: #404f67;
      font-family: Poppins;
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
    }

    &horizontal-line {
      background-color: #929daf;
      width: 100%;
      height: 2px;
    }

    &technology-qr-container {
      display: grid;
      grid-template-columns: 3fr 1fr;
      column-gap: 20px;
      margin-top: 50px;
      position: relative;
      .technology__ {
        &title {
          color: #404f67;
          font-size: 18px;
          font-weight: 700;
          text-decoration-line: underline;
        }
        &list {
          margin: 50px 0;
          &-container {
            margin: 10px 5px;
            padding: 14px;
            border-radius: 12px;
            background-color: rgba(33, 159, 255, 0.15);
            color: #219fff;
            display: inline-block;
            white-space: nowrap;
            text-align: center;
            font-size: 16px;
            font-weight: 500;
          }
          &-empty {
            padding: 50px 14px;
            border-radius: 12px;
            background-color: #f4f7fc;
            color: #404f67;
            font-size: 16px;
            font-weight: 600;
            width: 100%;

            text-align: center;
          }
        }
      }
      .qr__ {
        display: grid;
        justify-self: flex-end;

        &code {
          &-share-btn {
            margin-top: 10px;
            color: #219fff;
            font-size: 16px;
            font-weight: 500;
            text-decoration-line: underline;
            display: flex;
            justify-content: center;
          }
        }
      }
    }
    &projects-teams-container {
      margin: 50px 0;

      position: relative;
      display: flex;
      align-items: stretch;

      .teams__ {
        &title {
          color: #404f67;
          font-size: 18px;
          font-weight: 700;
          text-decoration-line: underline;
        }
        &eTeams {
          display: flex;
          gap: 16px;
          margin-top: 30px;
          &-wrp {
            width: 280px;
            height: 160px;
            flex-shrink: 0;
            border-radius: 12px;
            border: 1px solid #ccd2dd;
            background: #fff;
            padding: 16px;
            &-title {
              display: flex;
              gap: 16px;
              color: #404f67;
              font-size: 16px;
              font-weight: 600;
            }
            &-count {
              color: #219fff;
              span {
                width: 75px;
                height: 50px;
                flex-shrink: 0;
                color: #219fff;
                font-size: 60px;
                font-weight: 600;
              }
            }
          }
        }
      }
      .vertical-line {
        align-self: flex-end;
        width: 1px;
        height: 165px;
        background-color: #929daf;
        margin: 0 20px;
      }
      .projects__ {
        &title {
          color: #404f67;
          font-size: 18px;
          font-weight: 700;
          text-decoration-line: underline;
        }
        &eProjects {
          display: flex;
          gap: 16px;
          margin-top: 30px;
          &-wrp {
            width: 280px;
            height: 160px;
            flex-shrink: 0;
            border-radius: 12px;
            border: 1px solid #ccd2dd;
            background: #fff;
            padding: 16px;
            &-title {
              display: flex;
              gap: 16px;
              color: #404f67;
              font-size: 16px;
              font-weight: 600;
            }
            &-count {
              color: #219fff;
              span {
                width: 75px;
                height: 50px;
                flex-shrink: 0;
                color: #219fff;
                font-size: 60px;
                font-weight: 600;
              }
            }
          }
        }
      }
    }
    &statistics-task-container {
      margin: 50px 0;
      .statistics-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .statistics-title {
          color: #404f67;
          font-size: 18px;
          font-weight: 700;
          text-decoration-line: underline;
        }
        .show-hide-button {
          .button-title {
            color: #7e8f9a;
            font-size: 13px;
            font-weight: 500;
          }
        }
      }
      .statistics-circles-container {
        margin-top: 50px;
        padding-right: 50px;
        border-radius: 12px;
        border: 2px solid #ccd2dd;
        width: 100%;
        height: 500px;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .no-taskStatistics {
        display: flex;
        justify-content: center;
        gap: 10px;
        padding-top: 20px;
        color: #929daf;
      }
    }
  }
`;

export const SemiCircleContainer = styled.div`
  background-color: ${(props) => props.color};
  width: 250px;
  height: 500px;
  border-radius: 12px 250px 250px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .semi-circle-count {
    color: #fff;
    font-size: 52.235px;
    font-weight: 600;
  }

  .semi-circle-label {
    color: #fff;
    font-size: 14.924px;
    font-weight: 400;
  }
`;

export const CircleContainer = styled.div`
  background-color: ${(props) => props.color};
  width: 260px;
  height: 260px;
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .count {
    color: #fff;
    font-size: 44.41px;
    font-weight: 600;
  }

  .label {
    color: #fff;
    font-size: 12.689px;
    font-weight: 400;
  }
`;
