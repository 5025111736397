import React, { useState, useLayoutEffect } from 'react';
import { PostCard } from '../PostCard';
import { PublishTaskPopup } from '../PublishTaskPopup';
import { ModalPopup, ScrollableDiv } from 'components';
import { PostListWrapper } from './style';

export const PostList = ({
  scrollTo,
  isCurrentStageFinished,
  isPublished,
  posts,
  activePostHandler,
  activePost,
  optionsHandler,
  isTaskOpen,
  setIsTaskOpen,
  userProfile,
  isLinkedStream = false,
  isUserCommonMember = false,
  teamsList,
  projectId,
}) => {
  const [currentPost, setCurrentPost] = useState(activePost);

  const currentPostHandler = (id) => {
    activePostHandler(id);
    if (currentPost === id) {
      setCurrentPost(null);
    } else {
      setCurrentPost(id);
    }
  };

  useLayoutEffect(() => {
    if (scrollTo) {
      const element = document.getElementById(scrollTo);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [scrollTo]);

  return (
    <PostListWrapper>
      {posts.length !== 0 ? (
        <ScrollableDiv scrollOnHeight="600px" scrollOnHeightMob="400px">
          {posts.map((post, index) => {
            return (
              <div key={post?.id} id={post?.id}>
                <PostCard
                  isCurrentStageFinished={isCurrentStageFinished}
                  linkedStream={isLinkedStream}
                  isUserCommonMember={isUserCommonMember}
                  isPublished={isPublished}
                  key={index}
                  userProfile={userProfile}
                  postData={post}
                  projectId={projectId}
                  onSelectPost={() => currentPostHandler(post.id)}
                  isActive={currentPost === post.id}
                  optionsHandler={optionsHandler}
                  isPopup={isTaskOpen}
                />
              </div>
            );
          })}
        </ScrollableDiv>
      ) : (
        <div className="no-update">No updates available!</div>
      )}
      {isTaskOpen && (
        <ModalPopup
          open={false}
          onClose={() => setIsTaskOpen(false)}
          hasTitle
          title="Publish Task"
          minWidth="650px"
          maxContentWidth="650px">
          <PublishTaskPopup
            teamList={teamsList}
            closePopupHandler={setIsTaskOpen}
            activePost={currentPost}
            closeMenuHandler={currentPostHandler}
            projectId={projectId}
          />
        </ModalPopup>
      )}
    </PostListWrapper>
  );
};
