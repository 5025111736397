import './profileCard.scss';
import { Box, CustomButton, UploadButton } from '../../../../components';
import { useNavigate } from 'react-router-dom';
import Icon from 'utlils/Icons/Icon';

const ProfileCard = ({
  isUpload,
  userProfile,
  profilePicUploading = false,
  uploadProfilePicture,
}) => {
  const navigate = useNavigate();
  const isSmallEnterpriseRole = userProfile?.role === 'SMALL_ENTERPRISE';
  return (
    <div>
      <div className="profile-card-container">
        {userProfile.profilePicture !== null &&
        userProfile.profilePicture !== '' ? (
          <div className="profile-round-img">
            <img src={userProfile.profilePicture} alt="" className="prof-img" />
          </div>
        ) : (
          <div className="profile-round-img">
            <span className="prof-txt">{userProfile?.profileText}</span>
          </div>
        )}

        <div className="profile-card">
          <p className="profile-name">{userProfile?.name}</p>
          <p className="profile-email">{userProfile?.email}</p>

          <Box mt="20px" className="upload-button">
            <Box width="90%">
              {isUpload ? (
                <UploadButton
                  id={userProfile?.id}
                  onChange={async (e) => {
                    e.stopPropagation();
                    await uploadProfilePicture(e);
                  }}
                  label={'Upload Image'}
                  isLoading={profilePicUploading}
                />
              ) : (
                <Box width="100%">
                  <CustomButton
                    variant="light"
                    color="themeGray"
                    // disabled={disabled}
                    className="w-100 viewProfileCustomBtn"
                    onClick={() => {
                      navigate(
                        isSmallEnterpriseRole
                          ? `/public/enterprise/${userProfile.id}/details`
                          : `/public/individual/${userProfile.userId}/summary/basic-details`
                      );
                    }}>
                    View Profile
                    <Icon name="export-2" color="#404F67" size={20} />
                  </CustomButton>
                </Box>
              )}
              {profilePicUploading ? (
                <p className="upload-verfication-text">
                  *Image verification is under process.
                </p>
              ) : (
                ''
              )}
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
